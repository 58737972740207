import React, { useEffect, useState } from "react";
import ekasut from "../../../api/ekasut";
import { Label } from "semantic-ui-react";
import { Button, Input } from "antd";
import downloadFile from "../../GroupRiskLocomotives/downloadFile";
import styles from "../ConservationOrdersCandidates/ConservationOrdersCandidates.module.scss";

const ConservationСonfirmList = ({ initialEditingData }) => {
  const [orderPredLocs, setOrderPredLocs] = useState(null);

  const [openFile, setOpenFile] = useState(null);
  const [currentKeyForFile, setCurrentKeyForFile] = useState(null);
  const [isFileExist, setIsFileExist] = useState(false);

  const sendFileToServer = async () => {
    try {
      const fileId = await ekasut.file.postFile(openFile);
      setCurrentKeyForFile(fileId?.data);
      const orderData = await ekasut.locomotivesConservation.orderConfirm(
        initialEditingData.orderId,
        { fileId: fileId?.data }
      );
    } catch (error) {
      console.log(console.error());
    }
  };

  const getOrdersLoc = async () => {
    try {
      const locomotives = await ekasut.locomotivesConservation.getOrderLocs(
        initialEditingData.orderId
      );

      setOrderPredLocs(locomotives?.data.orderInfo);
      setIsFileExist(locomotives?.data.fileId && locomotives?.data.fileId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrdersLoc();
  }, []);

  return (
    <div className={styles.locOrders_container}>
      <h1>Состав заказа</h1>
      {orderPredLocs &&
        orderPredLocs.map((e) => (
          <div style={{ marginBottom: "50px" }}>
            {e.orderItemDtos[0] ? (
              <h3>{e.predName}</h3>
            ) : (
              <h3>{e.predName} - локомотивы не добавлены</h3>
            )}
            {e.orderItemDtos.map((innerE) => (
              <ul className={styles.added_loco_in_orders}>
                <li className={styles.added_loco_li}>
                  <Label className={styles.locomotives_label} basic>
                    Серия локомотива
                  </Label>
                  <div style={{ textAlign: "center" }}>
                    {innerE.locSeriesId}
                  </div>
                </li>
                <li className={styles.added_loco_li}>
                  <Label className={styles.locomotives_label} basic>
                    № локомотива
                  </Label>
                  <div style={{ textAlign: "center" }}>{innerE.locNumber}</div>
                </li>
                <li className={styles.added_loco_li}>
                  <Label className={styles.locomotives_label} basic>
                    Секция локомотива
                  </Label>
                  <div style={{ textAlign: "center" }}>
                    {innerE.locSectionId}
                  </div>
                </li>
              </ul>
            ))}
          </div>
        ))}

      {!currentKeyForFile ||
        (!isFileExist && (
          <label
            className="download order"
            style={{
              background: "#e0e1e2",
              color: "rgba(0,0,0,.8)",
              padding: "11px 21px",
              backgroundColor: "#cacbcd",
              borderRadius: ".28571429rem",
              fontWeight: "700",
            }}
          >
            Загрузить pdf
            <Input
              style={{ visibility: "hidden", position: "absolute" }}
              className="sender"
              type="file"
              accept=".pdf"
              onChange={(e) => {
                const fr = new FileReader();
                const file = document.querySelector(".sender").files[0];
                fr.readAsArrayBuffer(file);
                fr.onload = function () {
                  const blob = new Blob([fr.result], {
                    type: "text/plain",
                  });
                  // let reader = new FileReader();
                  // reader.readAsDataURL(blob); // конвертирует Blob в base64 и вызывает onload
                  console.log(blob);
                  setOpenFile(blob);
                };
              }}
            />
          </label>
        ))}

      {currentKeyForFile ||
        (isFileExist && (
          <Button
            style={{ marginTop: "20px" }}
            onClick={() =>
              downloadFile(
                isFileExist || currentKeyForFile
                  ? isFileExist
                  : currentKeyForFile,
                "Док"
              )
            }
          >
            Просмотреть файл
          </Button>
        ))}
      <Button
        style={{ marginTop: "20px", marginLeft: "20px" }}
        onClick={sendFileToServer}
      >
        Отправить
      </Button>
    </div>
  );
};

export default ConservationСonfirmList;
