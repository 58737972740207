import React from 'react';
import {runInAction} from "mobx";
import {observer, useLocalStore} from "mobx-react-lite";
import FormField from "../components/FormField";
import DelayedSearch from "../../../NsiWeightLength/DelayedSearch";
import {formOptions} from "../../../../helpers/utils";

const NetworkSearch = observer(({label, itemParser, displayFieldName, onChange, apiMethod}) => {
    const ls = useLocalStore(() => ({
        list: [],
        loading: false,
        error: null,

        clearList() {
            this.list.clear()
        },
        fetchList(text) {
            this.loading = true
            this.error = null
            this.list = []
            apiMethod(text)
                .then(({data}) => {
                    runInAction(() => {
                        this.loading = false
                        this.list = data.map(itemParser)
                    })
                }, error => {
                    runInAction(() => {
                        this.loading = false
                        this.error = error
                    })
                })
        }
    }))

    return (
        <FormField label={label}>
            <DelayedSearch
                fluid
                minCharacters={3}
                delay={1000}
                options={formOptions(ls.list, displayFieldName)}
                typeEnd={ls.fetchList}
                loading={ls.loading}
                onChange={(e, {value}) => {
                    onChange(ls.list[value])
                }}
                onSearchChange={() => {
                    ls.clearList()
                }}
            />
        </FormField>
    );
});
export default NetworkSearch;