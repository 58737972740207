import React from 'react';
import style from './MainTab.module.scss';
import {DataGrid} from '@mui/x-data-grid';
import { Pie } from '@ant-design/charts';

export const MainTab = () => {

    const columns = [
        {field: 'index', headerName: 'Показатель', },
        {field: 'year', headerName: 'Год', },
        {field: 'start', headerName: 'С начала года', },
        {field: 'current', headerName: 'Текущий месяц', },
    ]

    const rows = [
        {
            id:1,
            index:'Дисциплинарные взыскания',
            year:' ',
            start:' ',
            current:' ',
        },
        {
            id:2,
            index:'Снижение премии',
            year:' ',
            start:' ',
            current:' ',
        },
        {
            id:3,
            index:'Выявленные нарушения',
            year:' ',
            start:' ',
            current:' ',
        },
    ]


    const data = [
        {
            type: '分类一',
            value: 27,
        },
        {
            type: '分类二',
            value: 25,
        },
        {
            type: '分类三',
            value: 18,
        },
        {
            type: '分类四',
            value: 15,
        },
        {
            type: '分类五',
            value: 10,
        },
        {
            type: '其他',
            value: 5,
        },
    ];
    const config = {
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        width:200,
        height:150,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 18,
                },
                content: 'AntV\nG2Plot',
            },
        },
    };

    return (
        <div className={style.wrap}>
            <div className={style.column}>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Общая информация
                    </div>
                    <div className={style.rowItem}>
                        <h4>Дата рождения</h4>
                        <p>-</p>
                    </div>
                    <div className={style.rowItem}>
                        <h4>Дата назначения на должность</h4>
                        <p>-</p>
                    </div>
                    <div className={style.rowItem}>
                        <h4>Стаж работы в дирекции</h4>
                        <p>-</p>
                    </div>
                </div>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Образование
                    </div>
                    <div className={style.rowItem}>
                        <h4>Вид образования</h4>
                        <p>-</p>
                    </div>
                    <div className={style.rowItem}>
                        <h4>Учебное заведение</h4>
                        <p>-</p>
                    </div>
                    <div className={style.rowItem}>
                        <h4>Специальность</h4>
                        <p>-</p>
                    </div>
                </div>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Допуски к работе
                    </div>
                    <div className={style.rowItem}>
                        <h4>Заключения на серии</h4>
                        <p>-</p>
                    </div>
                    <div className={style.rowItem}>
                        <h4>Заключения на места и виды работ</h4>
                        <p>-</p>
                    </div>
                    <div className={style.rowItem}>
                        <h4>Группа по ЭБ</h4>
                        <p>-</p>
                    </div>
                </div>
            </div>
            <div className={style.column}>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Взыскания и нарушения
                    </div>
                    {/*<DataGrid*/}
                    {/*    rows={rows}*/}
                    {/*    columns={columns}*/}
                    {/*    hideFooter*/}
                    {/*/>*/}
                </div>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Поощрения
                    </div>
                </div>
            </div>
            <div className={style.column}>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Нарушения
                    </div>
                    {/*<Pie {...config} />*/}
                </div>
                <div className={style.itemColumn}>
                    <div className={style.title}>
                        Лицевой счет
                    </div>
                </div>
            </div>
        </div>
    )
}