import React, {useEffect, useMemo} from 'react';
import {Button, Dropdown, Header, Label, List} from "semantic-ui-react";
import DropdownField from "../../../shared/fields/DropdownField";
import './ProcessSelector.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import {toJS} from "mobx";
import ekasut from "../../../api/ekasut";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

const ProcessSelector = observer(({onChange, processes}) => {
    const ls = useLocalStore(() => ({
        processesList: [{processId: null, key: 0}],
        counter: 0
    }))

    const flatProcesses = useMemo(() => {
        const flat = []
        if (processes === null) {
            return []
        }
        const recursiveAddProcesses = (branch, flat, level = 0) => {
            if (level !== 0) {
                flat.push({
                    processId: branch.processId,
                    processName: branch.processName,
                    level: level
                })
            }

            if (branch.childrens) {
                branch.childrens.forEach((childrenBranch) => {
                    recursiveAddProcesses(childrenBranch, flat, level + 1)
                })
            }
        }
        recursiveAddProcesses({childrens: processes}, flat)
        return flat
    }, [processes])

    const options = useMemo(() => flatProcesses.map((process) =>
        ({
            text: process.processName,
            key: process.processId,
            value: process.processId,
            className: `level-${process.level}`
        })), [flatProcesses])

    return (
        <div className="app audit-control process-selector">
            <div className="picked-processes-header">Список процессов</div>
            <div className="processes-list">
                {ls.processesList.map((process, index) => (
                    <Dropdown
                        key={process.key}
                        label=""
                        className="processes-dropdown dropdown-underline dropdown-wrap-menu"
                        options={options}
                        value={process.processId}
                        clearable={ls.processesList.length > 1}
                        fluid
                        onChange={(e, {value}) => {
                            // clear button pressed
                            if (value === "") {
                                ls.processesList.splice(index, 1)
                            } else {
                                Object.assign(process, flatProcesses.find(pr => pr.processId === value))
                            }
                            const uniqueIds = [...new Set(ls.processesList.map(pr => pr.processId))].filter(id => id !== null)

                            onChange(uniqueIds)
                        }}
                    />
                ))}
            </div>
            <Button
                content="Добавить" basic
                onClick={() => {
                    ls.processesList.push({
                        key: ++ls.counter,
                        processId: null
                    })
                }}
            />
        </div>
    );
})

export default ProcessSelector;