import React, {useState, useEffect} from 'react'
import {Map} from "react-leaflet";
import style from './RegDepotOnScheme.module.scss'
import appStyles from '../../css/App.module.scss'
import LayerSelector, {layerNames} from "../../shared/LayerSelector";
import useMapRef from "../../hooks/useMapRef";
import useMapZoom from "../../hooks/useMapZoom";
import useZoomLevel, {zoomLevels} from "../../hooks/useZoomLevel";
import {Header, Segment} from "semantic-ui-react";
import Control from 'react-leaflet-control'
import useApiMethod from "../../hooks/useApiMethod";
import DepotMarker from "./DepotMarker/DepotMarker";
import ekasut from '../../api/ekasut'
import {Button, Select} from "antd";
import {downloadBlob} from "../../helpers/utils";
import FloatButton from "../../shared/FloatButton";

const {Option} = Select;

export function RegDepotOnScheme({hideSidebars}) {
    const [mapRef, onMapRefChangeHandler] = useMapRef()
    const [zoom] = useMapZoom(mapSettings.zoom, mapRef)
    const [excelShow, setExcelShow] = useState(false)
    const [excelOptions, setExcelOptions] = useState([])
    const [zoomLevel] = useZoomLevel(zoom,
        [mapSettings.minZoom, 5],
        [6, mapSettings.maxZoom]
    )
    const [depotMarkers, setDepotMarkers] = useState([])

    const [sendGetDepots] = useApiMethod(ekasut.regDepotsOnScheme.getDepots, undefined, [],
        ({data}) => {
            setExcelOptions(data)
            return data.map(depot => ({
                lat: depot.lat ? depot.lat : 1,
                lon: depot.lon ? depot.lon : 1,
                name: depot.depoPripSName,
                id: depot.depoPrip,
                dorPrip:depot?.dorPrip
            }))
        }
    )

    const downloadExcel = () => {
        ekasut.regDepotsOnScheme.getExcelDepotInfo()
            .then(({data}) => {
                downloadBlob("locs_reg_depo.xlsx", data)
            })
    }

    useEffect(() => {
        sendGetDepots()
            .then(depots => {
                setDepotMarkers(createMarkers(depots))
            }).catch(() => {
        })
    }, [sendGetDepots])

    return (
        <div>
            <Map
                className={appStyles.map}
                {...mapSettings}
                ref={onMapRefChangeHandler}
                onClick={hideSidebars}
            >

                <Control position={'topright'}>
                    <Segment>
                        <Header as={'h4'}>
                            Депо приписки на схеме полигона
                        </Header>
                    </Segment>
                </Control>

                <FloatButton main={{
                    rotate: false,
                    text: "Скачать Excel",
                    icon: "file excel outline",
                    iconColor: "#ffffff",
                    onClick: () => {
                        downloadExcel()
                    }
                }}/>
                <LayerSelector checkedLayerDef={layerNames.rzd}/>
                {depotMarkers}
            </Map>

        </div>
    )
}

const createMarkers = (points) => {
    return points.map((point, index) => {
        return (
            <DepotMarker
                index={index}
                key={index}
                pointName={point.name}
                id={point.id}
                dorPrip={point?.dorPrip}
                position={[point.lat, point.lon]}
            />
        )
    })
}
const mapSettings = {
    center: [57.778288, 79.126642],
    zoom: 4,
    minZoom: 4,
    maxZoom: 16,
    zoomControl: false
}