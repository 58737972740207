import React, {useEffect} from 'react';
import styles from "./AddPurchaseView.module.scss"
import {Checkbox, Header, Modal, Button, Grid} from "semantic-ui-react";
import InputField from "../../shared/fields/InputField";
import DropdownField from "../../shared/fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import moment from 'moment'
import cn from 'classnames'
import DateField from "../../shared/fields/DateField";
import Widget from "../../shared/Widget";
import ProcessViewButtons from "./ProcessViewButtons";
import {formatPrice, parsePrice, regex} from "../../helpers/utils";

const PlanPurchaseView = observer((
    {
        open, purchaseTypes, onSaveOrEnd, purchase,
        onClose = () => null, purchaseTypesLoading
    }
) => {
    const defaultSave = {
        purchaseType: undefined,
        purchaseName: "",
        objProvider: "",
        planDocDate: moment(),
        priceLimit: ""
    }

    const ls = useLocalStore(() => ({
        save: defaultSave,
    }))

    useEffect(() => {
        if (open && purchase) {
            ls.save = {
                purchaseType: purchase.typeId,
                purchaseName: purchase.purchaseName,
                objProvider: purchase.objProvider,
                planDocDate: moment(purchase.planDocDate),
                priceLimit: formatPrice(purchase.priceLimit)
            }
        }
    }, [open])

    const closeHandler = () => {
        ls.save = defaultSave
        onClose()
    }

    const saveHandler = (purchase, isEnd) => {
        onSaveOrEnd(
            {
                ...ls.save,
                priceLimit: parsePrice(ls.save.priceLimit)
            },
            purchase,
            isEnd
        )
    }

    const NAME_MAX_LENGTH = 300

    return (
        <Modal
            open={open} size="tiny"
            className={cn(styles.component, "alt-semantic")}
            onClose={closeHandler}
        >
            <Widget
                header="Планирование закупки"
                footer={(
                    <ProcessViewButtons
                        onSave={() => saveHandler(purchase, false)}
                        onEnd={() => {
                            saveHandler(purchase, true);
                            ls.save = defaultSave
                        }}
                        onClose={closeHandler}
                        disabledEnd={
                            ls.save.purchaseType === undefined
                            || ls.save.purchaseName === ""
                            || ls.save.objProvider === ""
                            || ls.save.priceLimit === ""
                            || !ls.save.planDocDate.isValid()
                        }
                        disabledSave={false}
                    />
                )}
            >
                <div className="add-properties">
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <DropdownField
                                    label="Тип"
                                    dropdownClassName="dropdown-underline"
                                    loading={purchaseTypesLoading}
                                    clearable
                                    value={ls.save.purchaseType}
                                    options={purchaseTypes.map((item, index) => ({
                                        text: item.name,
                                        key: item.code,
                                        value: item.code
                                    }))}
                                    onChange={(e, {value}) => {
                                        ls.save.purchaseType = purchaseTypes.find(item => item.code === value).code
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <InputField
                                    label="Наименование"
                                    inputClassName="input-underline"
                                    value={ls.save.purchaseName}
                                    onChange={(e, {value}) => {
                                        if (value.length <= NAME_MAX_LENGTH) {
                                            ls.save.purchaseName = value
                                        }
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <InputField
                                    label="Поставщик"
                                    inputClassName="input-underline"
                                    value={ls.save.objProvider}
                                    onChange={(e, {value}) => {
                                        ls.save.objProvider = value
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <DateField
                                    label="Планируемая дата подачи документов" closable
                                    inputClassName="date-underline"
                                    date={ls.save.planDocDate || ""}
                                    minDate={moment().format()}
                                    onChange={(date) => {
                                        if (moment(date.format()).diff(moment().format(), 'days') < 0) {
                                            ls.save.planDocDate = moment()
                                        } else {
                                            ls.save.planDocDate = date
                                        }
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <InputField
                                    label="Лимит закупки, руб"
                                    inputClassName="input-underline"
                                    value={ls.save.priceLimit}
                                    onChange={(e, {value}) => {
                                        const number = parsePrice(value)
                                        if (number !== null) {
                                            ls.save.priceLimit = formatPrice(number)
                                        }
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Widget>
        </Modal>
    );
})

export default PlanPurchaseView;
