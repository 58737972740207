/* import { useSnapshot } from "valtio";
import { state } from "../state"; */

import {proxy} from "valtio";

const board = proxy({
    boardName: null,
    allWGTasks: false,
    viewList: 'board',
    userListItems: false,
    boardItems: null,
    loading: false,
    userListLoading: false,
    workGruopId: null,
    workGruopRoleId: null,
    groupType: 'status',
    sortBordType: null,
    fullResponse: null,
    categoryListId: null,
    sortListId: null,
});

export {board};