// import ekasut from "../api/ekasut";
//
// const TOKEN_KEY = "token"
//
// const auth = {
//     /** get token or null if not exists */
//     getToken: () => localStorage.getItem(TOKEN_KEY),
//     /** save token directly */
//     setToken: (token) => {
//         localStorage.setItem(TOKEN_KEY, token)
//     },
//     /** try to parse token */
//     parseToken: (token) => {
//         // jwt token has three main sections separated by '.': header, payload and verify signature
//         const payload = JSON.parse(atob(token.split(".")[1]))
//         // sub is variable that holds another jwt (with invalid signature, has only payload)
//         const sub = JSON.parse(atob(payload.sub))
//
//         return {
//             grants: payload.role,
//             exp: parseInt(payload.exp),
//             isSld: sub.isSld || false,
//             isTche: sub.isTche || false,
//             kodFirm: sub.kodFirm,
//             userTabNum: sub.tabNum,
//             userId: sub.userId,
//             accessLevel: sub.accessLevel
//         }
//     },
//     getTokenExp: () => localStorage.exp,
//     getSettings: () => localStorage.settings,
//     /** Parse token and save properties to localStorage
//      * @return boolean - true if token applied successful, false otherwise */
//     applyToken: (token) => {
//         if (!token) {
//             return false
//         }
//
//         let props
//         try {
//             props = auth.parseToken(token)
//         } catch {
//             return false
//         }
//         auth.setToken(token)
//         for (const key in props) {
//             localStorage.setItem(key, props[key])
//         }
//
//         return true
//     },
//     /** Request additional information about user, should be call after api initialization */
//     loadUserSettings: () => {
//         return ekasut.users.getRolesConfig()
//             .then((response) => {
//                 if (response.status !== 200) {
//                     throw new Error(`Response config roles status: ${response.status}`)
//                 }
//                 localStorage.setItem('settings', JSON.stringify(response.data));
//             })
//     },
//     // todo: try to improve code below
//     getUser() {
//         if (localStorage.isTche === 'true') {
//             return user.TCHE
//         }
//         if (localStorage.isSld === 'true') {
//             return user.SLD
//         }
//         // todo: add CT and T
//         return user.ANON
//     },
//     loggedInAs() {
//         if (auth.getIsTche()) {
//             return auth.user.TCHE
//         } else if (auth.getIsSld()) {
//             return auth.user.SLD
//         } else {
//             return auth.user.ANY
//         }
//     },
//     getIsSld() {
//         return localStorage.isSld === 'true'
//     },
//     getIsTche() {
//         return localStorage.isTche === 'true'
//     },
//     getAccessLevel: () => parseInt(localStorage.accessLevel),
//     getGrants() {
//         return localStorage.grants ? localStorage.grants.split(",").map(item => item.trim()) : []
//     },
//     logout(cb) {
//         for (let key in localStorage) {
//             if (!key.startsWith("user-")) {
//                 localStorage.removeItem(key)
//             }
//         }
//         if (cb) cb()
//         window.location = `${window.backendUrl}/Auth/Logout?fromUrl=${window.location.href}`
//     },
//
//     loggedIn() {
//         return !!localStorage.token && !!localStorage.settings
//     },
//     getKodFirm() {
//         return localStorage.kodFirm
//     },
//     getUserTabNum() {
//         return localStorage.userTabNum
//     },
//     setPolygonSchemeTemplates(templates) {
//         try {
//             localStorage.setItem("user-" + auth.getUserTabNum() + ".templates", JSON.stringify(templates))
//         } catch {
//             console.error("unexpected error, wrong JSON object", templates)
//         }
//     },
//     clearPolygonSchemeTemplates() {
//         localStorage.setItem("user-" + auth.getUserTabNum() + ".templates", "")
//     },
//     getPolygonSchemeTemplates() {
//         let templates
//         try {
//             templates = JSON.parse(localStorage.getItem("user-" + auth.getUserTabNum() + ".templates"))
//         } catch {
//             auth.clearPolygonSchemeTemplates()
//             templates = {}
//         }
//         return templates === "" || templates === null ? {} : templates
//     }
// }
// const ACCESS_LEVELS = {
//     CT: 0,
//     T: 1,
//     tcheSld: 2,
// }
// const user = {
//     TCHE: 'tche',
//     SLD: 'sld',
//     CT: 'ct',
//     T: 't',
//     ANON: 'anon'
// }
//
// export default auth
// export {ACCESS_LEVELS, user}

import ekasut from "../api/ekasut";

const TOKEN_KEY = "token";

const auth = {
  /** get token or null if not exists */
  getToken: () => localStorage.getItem(TOKEN_KEY),
  /** save token directly */
  setToken: (token) => {
    localStorage.setItem(TOKEN_KEY, token);
  },
  /** try to parse token */
  parseToken: (token) => {
    // jwt token has three main sections separated by '.': header, payload and verify signature
    const payload = JSON.parse(atob(token.split(".")[1]));
    // sub is variable that holds another jwt (with invalid signature, has only payload)
    const sub = JSON.parse(atob(payload.sub));
    const claims = sub.claims;

    return {
      //old
      grants: payload.role,
      exp: parseInt(payload.exp),
      isSld: sub?.isSld || false,
      isTche: sub?.isTche || false,
      kodFirm: sub?.kodFirm,
      userTabNum: sub?.tabNum,
      userId: sub?.user_id,
      accessLevel: sub?.accessLevel,
      fio: sub?.fio,

      //new
      // grants: payload.role,
      // exp: parseInt(payload.exp),
      // isSld: sub.isSld || false,
      // isTche: sub.isTche || false,
      // kodFirm: claims.filter((e) => e.type === "kod_firm")[0].value,
      // userTabNum: claims.filter((e) => e.type === "tab_num")[0].value,
      // userId: claims.filter((e) => e.type === "user_id")[0].value,
      // accessLevel: claims.filter((e) => e.type === "pred_gr_id")[0].value,
      // fio: claims.filter((e) => e.type === "fio")[0].value,
    };
  },
  getTokenExp: () => localStorage.exp,
  getSettings: () => localStorage.settings,
  /** Parse token and save properties to localStorage
   * @return boolean - true if token applied successful, false otherwise */
  applyToken: (token) => {
    if (!token) {
      return false;
    }
    let props;
    try {
      props = auth.parseToken(token);
    } catch {
      return false;
    }
    auth.setToken(token);
    for (const key in props) {
      localStorage.setItem(key, props[key]);
    }

    return true;
  },
  /** Request additional information about user, should be call after api initialization */
  loadUserSettings: () => {
    return ekasut.users.getRolesConfig().then((response) => {
      if (response.status !== 200) {
        throw new Error(`Response config roles status: ${response.status}`);
      }
      localStorage.setItem("settings", JSON.stringify(response.data));
    });
  },
  // todo: try to improve code below
  getUser() {
    if (localStorage.isTche === "true") {
      return user.TCHE;
    }
    if (localStorage.isSld === "true") {
      return user.SLD;
    }
    // todo: add CT and T
    return user.ANON;
  },
  loggedInAs() {
    if (auth.getIsTche()) {
      return auth.user.TCHE;
    } else if (auth.getIsSld()) {
      return auth.user.SLD;
    } else {
      return auth.user.ANY;
    }
  },
  getIsSld() {
    return localStorage.isSld === "true";
  },
  getIsTche() {
    return localStorage.isTche === "true";
  },
  getAccessLevel: () => parseInt(localStorage.accessLevel),
  getGrants() {
    return localStorage.grants
      ? [...localStorage.grants.split(",").map((item) => item.trim()), ""]
      : [];
  },
  logout(cb) {
    for (let key in localStorage) {
      if (!key.startsWith("user-")) {
        localStorage.removeItem(key);
      }
    }
    if (cb) cb();
    window.location = `${window.backendUrl}/Auth/Logout?fromUrl=${window.location.href}`;
  },

  loggedIn() {
    return !!localStorage.token && !!localStorage.settings;
  },
  getKodFirm() {
    return localStorage.kodFirm;
  },
  getUserTabNum() {
    return localStorage.userTabNum;
  },
  setPolygonSchemeTemplates(templates) {
    try {
      localStorage.setItem(
        "user-" + auth.getUserTabNum() + ".templates",
        JSON.stringify(templates)
      );
    } catch {
      console.error("unexpected error, wrong JSON object", templates);
    }
  },
  clearPolygonSchemeTemplates() {
    localStorage.setItem("user-" + auth.getUserTabNum() + ".templates", "");
  },
  getPolygonSchemeTemplates() {
    let templates;
    try {
      templates = JSON.parse(
        localStorage.getItem("user-" + auth.getUserTabNum() + ".templates")
      );
    } catch {
      auth.clearPolygonSchemeTemplates();
      templates = {};
    }
    return templates === "" || templates === null ? {} : templates;
  },
};
const ACCESS_LEVELS = {
  CT: 0,
  T: 1,
  tcheSld: 2,
};
const user = {
  TCHE: "tche",
  SLD: "sld",
  CT: "ct",
  T: "t",
  ANON: "anon",
};

export default auth;
export { ACCESS_LEVELS, user };
