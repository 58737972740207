import React, {useEffect} from 'react'
import {Button, Icon, Message, Modal} from "semantic-ui-react";
import {observer, useLocalStore} from "mobx-react-lite";
import moment from "moment";
import ekasut from "../../api/ekasut";
import AreaHeader from "../../shared/headers/AreaHeader";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import PlanPurchaseView from "./PlanPurchaseView"
import './Main.scss'
import {observable, toJS} from 'mobx';
import PurchasesTable from "./PurchasesTable";
import AddPurchaseDetailsServiceView from "./AddPurchaseDetailsServiceView";
import AddResponsibleView from "./AddResponsibleView";
import AddResultSubmitView from "./AddResultSubmitView";
import AddResultRejectView from "./AddResultRejectView";
import AddPurchaseDetailsProductView from "./AddPurchaseDetailsProductView";
import DateField from "../../shared/fields/DateField";
import {purchaseStatuses} from "./constants";
import FilterModal from "../../shared/FilterModal";
import {usePrompt} from "../../globalStore/useGlobalStore";
import auth from "../../helpers/auth";
import DropdownField from "../../shared/fields/DropdownField";
import apiWorker from "../../api/apiWorker";
import {downloadBlob} from "../../helpers/utils";

const Main = observer(() => {
    const prompt = usePrompt()
    const roles = auth.getGrants()
    const ls = useLocalStore(() => ({
        purchases: null,
        filteredPurchases: null,
        purchasesLoading: false,
        purchaseTypes: [],
        purchaseTypesLoading: false,
        from: moment().startOf("day").subtract(1, "month"),
        to: moment().startOf("day").add(1, "day"),
        typeId: '',
        planPurchaseView: {open: false, purchase: undefined},
        excelDownloadView: {open: false},
        addDetailsServiceView: {open: false, purchase: undefined, edit: false, editable: false},
        addDetailsProductView: {open: false, purchase: undefined, edit: false, editable: false},
        addResponsibleView: {open: false, purchase: undefined},
        addResultSubmitView: {open: false, purchase: undefined, isPreliminary: false},
        addResultRejectView: {open: false, purchase: undefined},
        filterModal: {open: false, filter: null},
        filter: observable.map(),
    }))

    const updateAvailablePurchases = () => {
        ls.purchases = null
        ls.purchasesLoading = true
        if (ls.from.isValid() && ls.to.isValid()) {
            ekasut.purchases.getPurchasesList({fromDate: ls.from, toDate: ls.to})
                .then(({data}) => {
                    ls.purchases = data
                    ls.filteredPurchases = filterData(data)
                    ls.purchasesLoading = false
                })
                .catch((err) => {
                    ls.purchasesLoading = false
                    return catchNetworkErrors(err)
                })
        }
    }

    useEffect(() => {
        updateAvailablePurchases()
    }, [ls.from, ls.to])

    const updatePurchaseTypes = () => {
        ls.purchaseTypesLoading = true
        ekasut.purchases.getNsiTypes()
            .then(({data}) => {
                ls.purchaseTypes = data
                ls.purchaseTypesLoading = false
            })
            .catch(catchNetworkErrors)
    }

    useDidFirstRender(() => {
        updatePurchaseTypes()
    })

    const PURCHASE_TYPE_SERVICE = 1
    const PURCHASE_TYPE_PRODUCT = 2

    const saveStep = (saveRequest, submitRequest, onSuccess, submit) => {
        prompt.show("Создание закупки...", "", {loading: true})
        saveRequest()
            .then(({data}) => {
                if (submitRequest && submit) {
                    submitRequest(data)
                        .then(() => {
                            prompt.close()
                            onSuccess()
                            updateAvailablePurchases()
                        })
                        .catch((error) => {
                            prompt.close()
                            return catchNetworkErrors(error)
                        })
                } else {
                    prompt.close()
                    onSuccess()
                    updateAvailablePurchases()
                }
            })
            .catch((error) => {
                prompt.close()
                return catchNetworkErrors(error)
            })
    }

    const exportServiceExcel = () => {
        // todo: export excel file to user
    }

    const exportProductExcel = () => {
        // todo: export excel file to user
    }

    const filterData = (data) => {
        let filteredData = data
        ls.filter.forEach((values, property) => {
            filteredData = filteredData.filter(
                (purchase) => values.length === 0 ? true : values.some(value => value === purchase[property])
            )
        })
        return filteredData
    }

    return (<div className="app procurement-planning main alt-semantic">
        <AreaHeader content="Ведение процессов ценообразования"/>
        <FilterModal
            open={ls.filterModal.open}
            onClose={() => ls.filterModal.open = false}
            data={ls.purchases || []}
            header="Фильтр заявок"
            filter={ls.filter}
            onApply={(filter) => {
                ls.filter = filter
                ls.filteredPurchases = filterData(ls.purchases)
                ls.filterModal.open = false

            }}
            groups={[
                {title: "Наименование закупки", property: "purchaseName"},
                {title: "Тип закупки", property: "typeName"},
                {title: "Поставщик", property: "objProvider"},
                {title: "Статус", property: "statusName"},
                {title: "Ответственный за подачу", property: "buyerFio"},
                {title: "Ответственных за рассмотрение", property: "responsibleFio"},
            ]}
        />
        <div className="controls">
            <div className="filters">
                <DateField
                    label="Дата, от"
                    date={ls.from}
                    inputClassName="date-underline"
                    onChange={(date) => ls.from = date}
                    closable
                />
                <DateField
                    label="Дата, до"
                    date={ls.to}
                    inputClassName="date-underline"
                    onChange={(date) => ls.to = date}
                    closable
                />
                <Button
                    content="Фильтр"
                    icon="filter"
                    basic={ls.filter.size === 0}
                    onClick={() => ls.filterModal.open = true}
                />
                {ls.filteredPurchases !== null && (
                    roles.includes('start_price_formation') ?
                        <Button
                            className="plan-button"
                            content='Запланировать закупку'
                            basic icon='plus'
                            onClick={() => {
                                ls.planPurchaseView = {open: true, purchase: undefined}
                            }}
                        /> : null

                )}
                {ls.filteredPurchases !== null &&
                <Button
                    className="plan-button"
                    content='Скачать Excel'
                    basic icon="file excel outline"
                    onClick={() => {
                        ls.excelDownloadView.open = true
                    }}
                />}
            </div>
            <div className="filter-export">
                {/*<Button
                    content="Товары"
                    icon="file excel"
                    basic
                    onClick={exportProductExcel}
                />
                <Button
                    content="Услуги"
                    icon="file excel"
                    onClick={exportServiceExcel}
                    basic
                />*/}
            </div>
        </div>
        {ls.filteredPurchases !== null && ls.filteredPurchases.length === 0 && (
            <Message content="Нет записей за выбранный период"/>
        )}
        {ls.filteredPurchases !== null && ls.filteredPurchases.length > 0 && (
            <PurchasesTable
                roles={roles}
                purchases={ls.filteredPurchases}
                onRowClick={(purchase) => {
                    if (purchase.typeId === PURCHASE_TYPE_SERVICE) {
                        ls.addDetailsServiceView = {open: true, purchase, editable: false}
                    }
                    if (purchase.typeId === PURCHASE_TYPE_PRODUCT) {
                        ls.addDetailsProductView = {open: true, purchase, editable: false}
                    }
                }}
                onReject={(purchase) => {
                    ls.addResultRejectView = {open: true, purchase}
                }}
                onAddResponsible={(purchase) => {
                    ls.addResponsibleView = {open: true, purchase}
                }}
                onAddPurchase={(purchase) => {
                    if (purchase.typeId === PURCHASE_TYPE_SERVICE) {
                        ls.addDetailsServiceView = {open: true, purchase, editable: true}
                    }
                    if (purchase.typeId === PURCHASE_TYPE_PRODUCT) {
                        ls.addDetailsProductView = {open: true, purchase, editable: true}
                    }
                }}
                onPreliminaryCompletion={(purchase) => {
                    ls.addResultSubmitView = {open: true, purchase, isPreliminary: true}
                }}
                onComplete={(purchase) => {
                    ls.addResultSubmitView = {open: true, purchase, isPreliminary: false}
                }}
                onPlanPurchase={(purchase) => {
                    ls.planPurchaseView = {open: true, purchase}
                }}
            />
        )}

        {/* Добавить закупку */}
        <PlanPurchaseView
            open={ls.planPurchaseView.open}
            purchaseTypes={ls.purchaseTypes}
            purchase={ls.planPurchaseView.purchase}
            purchaseTypesLoading={ls.purchaseTypesLoading}
            onSaveOrEnd={(values, purchase, isEnd) => {
                saveStep(
                    () => ekasut.purchases.postCreatePurchase({
                        purchaseId: purchase && purchase.purchaseId,
                        typeId: values.purchaseType,
                        purchaseName: values.purchaseName,
                        objProvider: values.objProvider,
                        planDocDate: values.planDocDate.format(),
                        priceLimit: values.priceLimit
                    }),
                    (purchaseId) => ekasut.purchases.postSubmitPurchase({purchaseId}),
                    () => {
                        ls.planPurchaseView.open = false;
                    },
                    isEnd,
                )
            }}
                onClose={() => {
                ls.planPurchaseView.open = false
            }}
                />

            {/* Окно скачивания Excel */}
                <Modal
                size={'mini'}
                open={ls.excelDownloadView.open}
                onClose={() => {
                ls.excelDownloadView.open = false
            }}
                >
                <Modal.Header>Скачать Excel</Modal.Header>
                <Modal.Content>
                <DateField
                label="Дата, от"
                date={ls.from}
                inputClassName="date-underline"
                onChange={(date) => ls.from = date}
                closable fluid
                />
                <DateField
                label="Дата, до"
                date={ls.to}
                inputClassName="date-underline"
                onChange={(date) => ls.to = date}
                closable fluid
                />
                <DropdownField
                label={'Тип закупки'}
                placeholder="Не выбрано"
                fluid selection search
                options={[{text: "Услуга", key: "Услуга", value: "1"}, {
                text: "Товар",
                key: "Товар",
                value: "2"
            }]}
                value={ls.typeId}
                onChange={(e, {value}) => {
                ls.typeId = value
            }}
                />
                </Modal.Content>
                <Modal.Actions>
                <Button onClick={() => {
                ls.excelDownloadView.open = false
            }}>
                Отмена
                </Button>
                <Button primary disabled={ls.typeId === ""} onClick={() => {

                apiWorker(ekasut.purchases.getExcelPurchase).fetch({
                fromDate: ls.from.format(''),
                toDate: ls.to.format(''),
                typeId: ls.typeId
            })
                .then((res) => {
                downloadBlob("procurement_planning.xlsx", res)
            })
                .catch(catchNetworkErrors)
                .finally(() => {

            })
            }}>
                Скачать
                </Button>
                </Modal.Actions>
                </Modal>

            {/* Добавление заявки (Услуги) */}
                <AddPurchaseDetailsServiceView
                updateAvailablePurchases={updateAvailablePurchases}
            {...ls.addDetailsServiceView}
                onSaveOrEnd={(values, purchase, isEnd) => {
                saveStep(
                () => {
                const notUploadedFiles = values.files.filter(file => !file.isUploaded && file.file !== null)
                // Upload all not uploaded files to server
                return Promise.all(notUploadedFiles.map(file => ekasut.file.postFile(file.file)))
                .then(responses => {
                // Attach uploaded files to purchase
                return Promise.all(responses.map(({data: fileId}, index) => ekasut.purchases.postFile({
                purchaseId: purchase.purchaseId,
                fileId,
                ftId: notUploadedFiles[index].typeId
            })))
            })
                // I will be here only if files are uploaded
                .then(() => ekasut.purchases.postCreatePurchaseService({
                purchaseId: purchase.purchaseId,
                purchaseInfo: {
                executant: values.executant,
                basis: values.basis,
                description: values.description,
                buyerFio: values.responsible
            }
            }
                ))
            },
                () => ekasut.purchases.postSubmitPurchaseService({purchaseId: purchase.purchaseId}),
                () => ls.addDetailsServiceView.open = false,
                isEnd
                )
            }}
                onClose={() => {
                ls.addDetailsServiceView.open = false
            }}
                />

            {/* Добавление заявки (Товара) */}
                <AddPurchaseDetailsProductView
                updateAvailablePurchases={updateAvailablePurchases}
            {...ls.addDetailsProductView}
                onClose={() => ls.addDetailsProductView.open = false}

                onSaveOrEnd={(values, purchase, isEnd) => {
                saveStep(
                () => {
                const notUploadedFiles = values.files.filter(file => !file.isUploaded && file.file !== null)
                // Upload all not uploaded files to server
                return Promise.all(notUploadedFiles.map(file => ekasut.file.postFile(file.file)))
                .then(responses => {
                // Attach uploaded files to purchase
                return Promise.all(responses.map(({data: fileId}, index) => ekasut.purchases.postFile({
                purchaseId: purchase.purchaseId,
                fileId,
                ftId: notUploadedFiles[index].typeId
            })))
            })
                .then(() => ekasut.purchases.postCreatePurchaseProduct({
                purchaseId: purchase.purchaseId,
                purchaseInfo: {
                description: values.description,
                codeSkmtr: values.codeSkmtr,
                planCount: values.planCount,
                contractNum: values.contractNum,
                contractor: values.contractor,
                draft: values.draft,
                isGroup: values.isGroup,
                letterDate: values.letterDate && values.letterDate.format(),
                letterNum: values.letterNum,
                priceOffer: values.priceOffer,
                priceSum: values.priceSum,
                productName: values.productName,
                productProvider: values.productProvider,
                buyerFio: values.responsible
            }
            }))
            },
                () => ekasut.purchases.postSubmitPurchaseProduct({purchaseId: purchase.purchaseId}),
                () => ls.addDetailsProductView.open = false,
                isEnd
                )
            }}
                />

            {/* Выбор ответственного */}
                <AddResponsibleView
                open={ls.addResponsibleView.open}
                purchase={ls.addResponsibleView.purchase}
                onSaveOrEnd={(values, purchase, isEnd) => {
                saveStep(
                () => ekasut.purchases.postCreatePurchaseResponsible({
                purchaseId: purchase.purchaseId,
                purchaseInfo: {
                responsibleFio: values.responsibleFio,
            }
            }),
                undefined,
                () => ls.addResponsibleView.open = false,
                isEnd
                )
            }}

                onClose={() => {
                ls.addResponsibleView.open = false
            }}
                />

            {/* Завершение обработки / Предварительное завершение */}
                <AddResultSubmitView
                open={ls.addResultSubmitView.open}
                purchase={ls.addResultSubmitView.purchase}
                isPreliminary={ls.addResultSubmitView.isPreliminary}
                onSaveOrEnd={(values, purchase, isEnd) => {
                saveStep(
                () => ekasut.purchases.postCreatePurchaseResult({
                purchaseId: purchase.purchaseId,
                purchaseInfo: {
                description: values.description,
                docDate: values.docDate,
                docNum: values.docNum,
                statusId: values.isPreliminary ? purchaseStatuses.preliminaryCompletion : purchaseStatuses.completed,
            }
            })
                .then(() => values.file.fileId === null ? ekasut.file.postFile(values.file.file) : values.file.fileId)
                .then(({data: fileId}) => ekasut.purchases.postResultFile({
                purchaseId: purchase.purchaseId,
                fileId
            })),
                () => ekasut.purchases.postSubmitPurchaseResult({purchaseId: purchase.purchaseId}),
                () => ls.addResultSubmitView.open = false,
                isEnd
                )
            }}
                onClose={() => {
                ls.addResultSubmitView.open = false
            }}
                />

            {/* Отклониие заявки */}
                <AddResultRejectView
                open={ls.addResultRejectView.open}
                purchase={ls.addResultRejectView.purchase}
                onSaveOrEnd={(values, purchase, isEnd) => {
                saveStep(
                () => ekasut.purchases.postCreatePurchaseResult({
                purchaseId: purchase.purchaseId,
                purchaseInfo: {
                description: values.description,
                docDate: values.docDate,
                docNum: values.docNum,
                statusId: purchaseStatuses.rejectPurchase,
            }
            }),
                () => ekasut.purchases.postSubmitPurchaseResult({purchaseId: purchase.purchaseId}),
                () => ls.addResultRejectView.open = false,
                isEnd
                )
            }}
                onClose={() => {
                ls.addResultRejectView.open = false
            }}
                />

                </div>)
            })

export default Main