import React, {useMemo} from 'react';
import {observer} from "mobx-react-lite";
import DropdownField from "../../shared/fields/DropdownField";
import styles from './PredDropdown.module.scss'

/*

data:
{
  "predId": 12077,
  "predName": "ЦТР ОАО \"РЖД\"",
  "childrens": [
    {
      "predId": 9601,
      "predName": "Т СЕВ",
      "childrens": [
        {
          "predId": 15988,
          "predName": "ТЧ-95 БТС-ЛР",
          "childrens": []
        },
        {
 */

const PredDropdown = observer(({label, preds, fluid = false, loading, value, onChange = () => null, editable}) => {

    const [options, plain] = useMemo(() => {
        if (preds === undefined) {
            return [[], []]
        }
        const plain = []
        for (let i = 0; i < preds.childrens.length; i++) {
            const road = preds.childrens[i]
            plain.push({predId: road.predId, predName: road.predName, level: 1})
            for (let j = 0; j < road.childrens.length; j++) {
                const pred = road.childrens[j]
                plain.push({predId: pred.predId, predName: pred.predName, level: 2, roadPred: road.predId})
            }
        }

        const options = plain.map((item, index) => ({
            key: item.predId,
            value: index,
            text: item.predName,
            className: item.level === 1 ? "road" : "pred",
        }))

        return [options, plain]
    }, [preds])

    const [pred, predIndex] = useMemo(() => {
        if (value !== undefined) {
            const predIndex = plain.findIndex(p => p.predId === value)
            if (predIndex !== -1) {
                return [plain[predIndex], predIndex]
            }
        }
        return [undefined, undefined]
    }, [plain, value])

    return (
        <DropdownField
            className={styles.component}
            dropdownClassName="dropdown-underline"
            editable={editable}
            noEditValue={pred && pred.predName}
            label={label}
            loading={loading}
            options={options}
            fluid={fluid}
            clearable
            value={predIndex}
            onChange={(e, {value}) => {
                onChange(plain[value])
            }}
        />
    );
})

export default PredDropdown;