import React from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from "../StoreProvider";
import {Table} from "semantic-ui-react";
import TableRowsLoader from '../../../../shared/TableRowsLoader';
import ModalError from "../../../../shared/ModalError";
import {dateFormats} from "../../../../Dictionaries";


const OperativeTable = observer(() => {
    const store = useStore()

    return (
        <Table celled structured collapsing unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>n/n</Table.HeaderCell>
                    <Table.HeaderCell>Депо приписки</Table.HeaderCell>
                    <Table.HeaderCell>Серия</Table.HeaderCell>
                    <Table.HeaderCell>Номер / Кол-во секций</Table.HeaderCell>
                    <Table.HeaderCell>Место проведения Сервисного обслуживания (ремонтное предприятие)</Table.HeaderCell>
                    <Table.HeaderCell>Пробег на дату составления</Table.HeaderCell>
                    <Table.HeaderCell>Вид ремонта</Table.HeaderCell>
                    <Table.HeaderCell>Время постановки</Table.HeaderCell>
                    <Table.HeaderCell>Дислокация локомотива</Table.HeaderCell>
                    <Table.HeaderCell>Текущее состояние</Table.HeaderCell>
                    <Table.HeaderCell>Дата состояния</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <TableRowsLoader
                    messageIfEmpty={str.noPlans}
                    messageIfLoading={str.loading}
                    colSpan={12}
                    loading={store.planAndLocomotivesLoading}
                    rows={store.locomotives.map((loc, index) => (
                        <Table.Row
                            style={{cursor: 'pointer'}}
                            active={index === store.selectedRow}
                            key={index}
                            onClick={() => {
                                store.selectedRow = index
                                store.selectedPlanItem = loc
                                store.isLocomotiveSelected = true
                                // onSelect(locomotives[index])
                            }}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{loc.depoPripName}</Table.Cell>
                            <Table.Cell>{loc.serName}</Table.Cell>
                            <Table.Cell>{loc.locNum ? `${loc.locNum}${loc.prsCharLoc || ''}` : loc.secCount}</Table.Cell>
                            <Table.Cell>{loc.planRepPredName}</Table.Cell>
                            <Table.Cell>{loc.probeg}</Table.Cell>
                            <Table.Cell>{loc.repairName}</Table.Cell>
                            <Table.Cell>{loc.repDatePlan ? loc.repDatePlan.format(dateFormats.upToMinute) : null}</Table.Cell>
                            <Table.Cell>{loc.locLocation}</Table.Cell>
                            <Table.Cell>{loc.naimSost}</Table.Cell>
                            <Table.Cell>{loc.dateOp ? loc.dateOp.format(dateFormats.upToMinute) : null}</Table.Cell>
                        </Table.Row>
                    ))}
                />
            </Table.Body>
        </Table>
    )
})

export const str = {
    date: "Дата",
    fio: "ФИО",
    noPlans: "Для выбранных ТЧЭ и СЛД не было добавлено ни одного локомотива в план оперативного графика",
    loading: "Загрузка оперативного плана"
}


export default OperativeTable;