import React, {useState, useEffect} from 'react'
import {Route, useHistory, useRouteMatch, Switch} from "react-router-dom";
import CalendarView from "./CalendarView";
import useApiMethod from "../../hooks/useApiMethod";
import PlanForm from "./PlanForm";
import ekasut from "../../api/ekasut";
import dbSync from '../../api/dbSync'
import {Button, Modal} from "semantic-ui-react";
import styles from './index.module.scss'
import {dateFormats} from "../../Dictionaries";
import auth from "../../helpers/auth";
import ResultForm2 from "./results/ResultForm2";
import GeoLocation from "./GeoLocation";
import AddResultForm1 from './results/AddResultForm1';
import ViewResultForm1 from "./results/ViewResultForm1";
import moment from 'moment'
import axios from 'axios'
import RegisterPersTechTraining from './results/RegisterPersTechTraining'
import LoadingLabel from '../../shared/LoadingLabel'
import LocRemarks from "./locRemarks";
import useQueryParams from "../../hooks/useQueryParams";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {usePrompt} from "../../globalStore/useGlobalStore";

const texts = {
    planSuccessRecord: 'Мероприятие успешно сохранено',
    resultSuccessRecord: 'Реузльтаты мероприятия успешно сохранены',
    notAllParams: 'Не все параметры заполнены',
    deleteAction: 'Вы действительно хотите удалить запись?',
    deleteSuccess: 'Удаление выполнено успешно',
    deleteLoading: 'Удаление плана...',
    getAskDeleteFormular: d => `Вы действительно хотите удалить формуляр ${d.format(dateFormats.upToMinute)}`,
    deleteFormularLoading: 'Удаление формуляра...',
    deleteFormularSuccess: 'Формуляр удален',
    getSaveFormularLoading: d => `Сохранение формуляра ${d.format(dateFormats.upToDay)}`,
    getSaveFormularSuccess: d => `Формуляр ${d.format(dateFormats.upToMinute)} успешно сохранен`,
    putRates: 'Проставление оценок сотрудникам',
    savingResults: 'Сохранение результатов...',
    successSavedResults: 'Результаты мероприятия успешно сохранены'
}

export default function PlanWorkBrigades({hideSidebars, rootPath}) {
    const prompt = usePrompt()

    const [date, setDate] = useState(undefined)
    const [event, setEvent] = useState({})
    const history = useHistory()
    const [selectedMonth, setSelectedMonth] = useState(moment().month())
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const path = rootPath

    const [sendGetEmployees, employeesLoading, employees] = useApiMethod(ekasut.tchmiNsi.getPeople, undefined, [],
        ({data}) => {
            return data.map(item => ({
                ...item,
                text: `${item.lastName} ${item.firstName} ${item.patrName}`,
            }))
        }
    )
    const [sendGetDepartureSt, departureStLoading, departureSt] = useApiMethod(ekasut.ekasutData.getNsiStan, undefined, [],
        ({data}) => {
            return data.map(item => ({...item, id: item.stanId, text: item.vname}))
        }
    )
    const [sendGetArrivalSt, arrivalStLoading, arrivalSt] = useApiMethod(ekasut.ekasutData.getNsiStan, undefined, [],
        ({data}) => {
            return data.map(item => ({...item, id: item.stanId, text: item.vname}))
        }
    )
    const [sendGetControlSt, controlStLoading, controlSt] = useApiMethod(ekasut.ekasutData.getNsiStan, undefined, [],
        ({data}) => {
            return data.map(item => ({...item, id: item.stanId, text: item.vname}))
        }
    )

    const [sendGetGroups, groupsLoading, groups] = useApiMethod(ekasut.persData.getPersonDict, undefined, [],
        ({data}) => {
            return data.map(item => ({text: item}))
        }
    )
    const [sendGetFormulars, formularsLoading, formulars] = useApiMethod(ekasut.tchmi.getFormular, undefined, [],
        ({data}) => data.map(item => ({
            ...item,
            beginCheck: item.beginCheck !== null ? moment(item.beginCheck) : undefined,
            endCheck: item.endCheck !== null ? moment(item.endCheck) : undefined,
            dateZakl: item.dateZakl !== null ? moment(item.dateZakl) : undefined,
            targets: item.targets.map(tg => ({text: tg.targetName, id: tg.targetId})),
            zamech: item.zamech.map(zm => ({text: zm.noteName, id: zm.noteId})),
            mery: item.mery.map(mr => ({text: mr.measureName, id: mr.measureId}))
        })))
    const [sendGetRemarks, remarksLoading, remarks] = useApiMethod(ekasut.tchmi.getKp, undefined, [],
        ({data}) => {
            return data
        }
    )

    const [sendSaveFormular] = useApiMethod(dbSync.worksPlan.putFormular, undefined)
    const [sendDeleteAction] = useApiMethod(dbSync.worksPlan.deleteAction, undefined)
    const [sendEditAction, editActionLoading] = useApiMethod(dbSync.worksPlan.postEditAction, undefined, null)
    const [sendDeleteFormular] = useApiMethod(dbSync.worksPlan.deleteFormular, undefined)

    const [askModal, setAskModal] = useState({open: false})

    const resetPath = () => history.push(path)

    useEffect(() => {
        sendGetGroups()
    }, [sendGetGroups])

    useEffect(() => {
        if (date === undefined && (path !== (path + paths.calendar))) {
            history.push(path + paths.calendar)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])


    const getGroupsForView = () => {
        let list = []
        let defIndexes = []
        if (event.cols !== undefined) {
            event.cols.forEach((col, index) => {
                list.push({text: col})
                defIndexes.push(index)
            })
        }
        return {list, defIndexes}
    }

    const onSave = (values, eventId, eventRd) => {
        /*
        actionId, actionRd, tchmiTabNom, actionType, planDate, beginDate, endDate,
        persAsutrId, stCnsiBegin, stCnsiEnd, stCnsiCtl,
        theme, zachet, orderDate, orderText, cols
        */
        const controlStId = values.controlSt === undefined ? null : values.controlSt.id
        let promise
        if (values.eventType.formVersion === 0) {
            promise = sendEditAction(
                eventId, eventRd, auth.getUserTabNum(), values.eventType.id, date, null, null,
                values.employee.asutrId, values.departureSt.id, values.arrivalSt.id, controlStId,
                values.comment, null, null, null, null
            )
        }
        if (values.eventType.formVersion === 1) {
            promise = sendEditAction(
                eventId, eventRd, auth.getUserTabNum(), values.eventType.id, date, null, null,
                !values.isGroups ? values.employee.asutrId : null, null, null, null,
                values.comment, values.accepted, null, null, values.isGroups ? values.groups : null
            )
        }
        if (values.eventType.formVersion === 2) {
            promise = sendEditAction(
                eventId, eventRd, auth.getUserTabNum(), values.eventType.id, date, null, null,
                null, null, null, null,
                values.comment, null, null, null, values.groups
            )
        }
        promise.then(() => {
            prompt.show(texts.planSuccessRecord, "", {onClose: resetPath, ok: true})
        }).catch(catchNetworkErrors)
    }


    const params = useQueryParams()

    return (
        <div onClick={hideSidebars}>
            <Switch onClick={hideSidebars}>

                {/* Календарь событий событий */}
                <Route exact path={path + paths.calendar}>
                    <CalendarView
                        month={selectedMonth}
                        year={selectedYear}
                        onDateChange={(year, month) => {
                            setSelectedMonth(month)
                            setSelectedYear(year)
                        }}
                        onAdd={(date) => {
                            setDate(date)
                            history.push(path + paths.addPlan)
                        }}
                        onClick={(event) => {
                            setEvent(event)
                            setDate(moment(event.planDate))
                            if (
                                [1, 2, 3].includes(event.actionType)
                            ) {
                                sendGetFormulars(event.persAsutrId, event.rd)
                                sendGetRemarks(event.actionId, event.rd)
                            }

                            history.push(path + paths.viewPlan)
                        }}
                    />
                </Route>

                {/* Добавление плана */}
                <Route exact path={path + paths.addPlan}>
                    <div className={styles.topHeader}>
                        Добавление плана на {date !== undefined ? date.format(dateFormats.upToDay) : ''}
                    </div>
                    <div className={styles.mobileContainer} key={paths.addPlan}>
                        <PlanForm
                            editable={true}
                            employee={{
                                list: employees,
                                loading: employeesLoading,
                                typeEnd: text => sendGetEmployees(text),
                            }}
                            departureSt={{
                                list: departureSt,
                                loading: departureStLoading,
                                typeEnd: text => sendGetDepartureSt(text),
                            }}
                            arrivalSt={{
                                list: arrivalSt,
                                loading: arrivalStLoading,
                                typeEnd: text => sendGetArrivalSt(text)
                            }}
                            controlSt={{
                                list: controlSt,
                                loading: controlStLoading,
                                typeEnd: text => sendGetControlSt(text)
                            }}
                            groups={{
                                list: groups,
                                loading: groupsLoading,
                            }}

                            onSave={values => onSave(values, null, null)}
                            onNotAllParams={() => {
                                prompt.show(texts.notAllParams, "", {ok: true})
                            }}
                            loading={editActionLoading}
                        />
                    </div>
                </Route>

                {/* Просмотр результатов */}
                <Route exact path={path + paths.viewResults}>
                    <div className={styles.topHeader}>
                        Просмотр результатов плана на {date !== undefined ? date.format(dateFormats.upToDay) : ''}
                    </div>
                    <div className={styles.mobileContainer} key={paths.viewResults}>
                        {(
                            [1, 2, 3].includes(event.actionType) ||
                            ([5].includes(event.actionType) && (event.persAsutrId !== null) && (event.persAsutrId !== undefined))
                        ) && (
                            <ViewResultForm1
                                employee={{fio: event.fio, asutrId: event.persAsutrId}}
                                eventType={{id: event.actionType}}
                                formulars={formulars}
                                onFormularDelete={(formular) => {
                                    setAskModal({
                                        open: true,
                                        message: texts.getAskDeleteFormular(formular.beginCheck),
                                        action: () => {
                                            setAskModal({open: false})
                                            prompt.show(texts.deleteFormularLoading, "", {loading: true})
                                            sendDeleteFormular(event.rd, formular.id)
                                                .then(() => {
                                                    prompt.show(texts.deleteFormularSuccess, "", {onClose: resetPath, ok: true})
                                                })
                                                .catch((error) => {
                                                    prompt.close()
                                                    return catchNetworkErrors(error)
                                                })
                                        }
                                    })
                                }}
                            />
                        )}
                    </div>
                </Route>

                {/* Просмотр плана */}
                <Route path={path + paths.viewPlan}>
                    <div className={styles.topHeader}>
                        Просмотр плана на {date !== undefined ? date.format(dateFormats.upToDay) : ''}
                    </div>

                    <div className={styles.mobileContainer} key={paths.viewPlan}>
                        <PlanForm
                            editable={false}
                            eventTypeId={event.actionType}
                            employee={{
                                list: [{text: event.fio}],
                                defIndex: 0,
                                loading: false,
                            }}
                            departureSt={{
                                list: [{text: event.stBeginStr}],
                                defIndex: 0,
                                loading: false,
                            }}
                            arrivalSt={{
                                list: [{text: event.stEndStr}],
                                defIndex: 0,
                                loading: false,
                            }}
                            controlSt={{
                                list: [{text: event.stCtrlStr}],
                                defIndex: 0,
                                loading: false,
                            }}
                            comment={{
                                defValue: event.theme
                            }}
                            groups={getGroupsForView()}
                            defIsGroups={event.persId === null}
                        />
                        <div className={styles.buttons}>
                            <Button
                                basic
                                onClick={() => {
                                    history.push(path + paths.editPlan)
                                }}
                            >
                                Редактировать план
                            </Button>
                            <Button
                                content='Удалить план'
                                basic color='red'
                                onClick={() => {
                                    setAskModal({
                                        open: true,
                                        message: texts.deleteAction,
                                        action: () => {
                                            prompt.show(texts.deleteLoading, "", {loading: true})
                                            setAskModal({open: false})
                                            sendDeleteAction(event.rd, event.actionId, auth.getUserTabNum())
                                                .then(() => {
                                                    prompt.show(texts.deleteSuccess, "", {ok: true, onClose: resetPath})
                                                })
                                                .catch((error) => {
                                                    prompt.close()
                                                    return catchNetworkErrors(error)
                                                })
                                        }
                                    })
                                    /**/
                                }}
                            />
                            {(
                                [1, 2, 3].includes(event.actionType)
                            ) && (<>
                                {remarksLoading ? (
                                    <LoadingLabel
                                        style={{marginTop: 14, marginRight: 10}}
                                        label="Загрузка замечаний"
                                    />
                                ) : (
                                    <>
                                        {remarks.length === 0 && (
                                            <Button
                                                content="Зарегестрировать замечания по локомотиву"
                                                basic
                                                onClick={() => {
                                                    history.push(path + paths.addLocRemark)
                                                }}
                                            />
                                        )}
                                        {remarks.length > 0 && (
                                            <Button
                                                basic
                                                content="Посмотреть замечания по локомотиву"
                                                onClick={() => {
                                                    history.push(path + paths.viewLocRemark)
                                                }}
                                            />
                                        )}
                                    </>
                                )}

                                {formularsLoading ? (
                                    <LoadingLabel
                                        style={{marginTop: 14}}
                                        label='Загрузка формуляра'
                                    />
                                ) : (
                                    <>
                                        <Button
                                            content='Просмотр зарегестрированных результатов'
                                            basic
                                            onClick={() => {
                                                history.push(path + paths.viewResults)
                                            }}
                                        />
                                        <Button
                                            content='Зарегестрировать результаты'
                                            basic color='orange'
                                            onClick={() => {
                                                history.push(path + paths.addResults)
                                            }}
                                        />
                                    </>
                                )}
                            </>)}
                        </div>

                        {[5, 6].includes(event.actionType) && (<>
                            <div className={styles.subHeader}>Зарегестрированные результаты</div>
                            {((event.persAsutrId === null) || (event.persAsutrId === undefined)) ? (
                                <ResultForm2
                                    cols={event.cols}
                                    defApplyToSelectedGroup={true}
                                    event={event}
                                    onApply={employees => {
                                        prompt.show(texts.putRates, "", {loading: true})
                                        axios.all(employees.map(empl => dbSync.worksPlan.putRate(event.rd, event.actionId, empl.asutrId, empl.rateId)))
                                            .then(results => {
                                                prompt.show("Оценки проставлены", "", {ok: true})
                                            })
                                            .catch((err) => {
                                                prompt.close()
                                                return catchNetworkErrors(err)
                                            })
                                    }}
                                />
                            ) : (
                                <RegisterPersTechTraining
                                    event={event}
                                />
                            )}
                        </>)}
                    </div>
                </Route>

                {/* Редактирование плана */}
                <Route exact path={path + paths.editPlan}>
                    <div className={styles.topHeader}>
                        Редактирование плана на {date !== undefined ? date.format(dateFormats.upToDay) : ''}
                    </div>
                    <div className={styles.mobileContainer} key={paths.editPlan}>
                        <PlanForm
                            editable={true}
                            eventTypeId={event.actionType}
                            employee={{
                                list: employees,
                                defList: event.persId !== null ? [{text: event.fio, asutrId: event.persAsutrId}] : [],
                                defIndex: 0,
                                loading: employeesLoading,
                                typeEnd: text => sendGetEmployees(text)
                            }}
                            departureSt={{
                                list: departureSt,
                                /*defList: [{text: event.stBeginStr}],
                                defIndex: 0,*/
                                loading: departureStLoading,
                                typeEnd: text => sendGetDepartureSt(text)
                            }}
                            arrivalSt={{
                                list: arrivalSt,
                                /*defList: [{text: event.stEndStr}],
                                defIndex: 0,*/
                                loading: arrivalStLoading,
                                typeEnd: text => sendGetArrivalSt(text)
                            }}
                            controlSt={{
                                list: controlSt,
                                /*defList: [{text: event.stCtrlStr}],
                                defIndex: 0,*/
                                loading: controlStLoading,
                                typeEnd: text => sendGetControlSt(text)
                            }}
                            defIsGroups={event.persId === null}
                            groups={{
                                list: groups,
                                loading: groupsLoading,
                                defIndexes: findIndexes(groups.map(item => item.text), event.cols !== undefined ? event.cols : []),
                            }}
                            comment={{
                                defValue: event.theme
                            }}
                            freezeEventType={true}
                            loading={editActionLoading}
                            onSave={value => {
                                onSave(value, event.actionId, event.rd)
                            }}
                            onNotAllParams={() => {
                                prompt.show(texts.notAllParams, "", {ok: true})
                            }}
                        />
                    </div>
                </Route>

                {/* Добавление реузультатов */}
                <Route exact path={path + paths.addResults} key={paths.addResults}>
                    <div className={styles.topHeader}>
                        Регистрация результатов
                    </div>
                    <div className={styles.mobileContainer}>
                        {[1, 2, 3].includes(event.actionType) && (
                            <AddResultForm1
                                employee={{fio: event.fio, asutrId: event.persAsutrId}}
                                eventType={{id: event.actionType}}
                                defYavkaFrom={date}
                                onSave={(val) => {
                                    prompt.show(texts.getSaveFormularLoading(val.workBeginDate), "", {loading: true})
                                    /*
                                        (rd, id, routeRD, routeID, trainNum, persRD, tabMash, tabTchmi,
                                        typeKip, beginCheck, endCheck, stCnsiBegin, stCnsiEnd, removeTalon,
                                        targets, text, mery, dopWork, targetsList, textList, meryList)
                                    */
                                    sendSaveFormular(
                                        null, null, val.yavka.routeRd, val.yavka.routeId, val.train, null, val.employee.asutrId, event.tchmiAsutrId,
                                        event.actionType, val.workBeginDate, val.workEndDate, val.stationBegin.id, val.stationEnd.id, val.talonTakenAway,
                                        val.goalsComment, val.remarksComment, val.recommendationsComment, val.rightDepartureDate,
                                        val.goals.map(it => it.id), val.remarks.map(it => it.id), val.recommendations.map(it => it.id)
                                    )
                                        .then(() => {
                                            prompt.show(texts.getSaveFormularSuccess(val.workBeginDate), "", {ok: true, onClose: resetPath})
                                        })
                                        .catch((error) => {
                                            prompt.close()
                                            return catchNetworkErrors(error)
                                        })
                                }}
                                onNotAllFill={() => {
                                    prompt.show(texts.notAllParams, "", {ok: true})
                                }}
                            />
                        )}
                        {[5].includes(event.actionType) && (
                            <RegisterPersTechTraining
                                onNotFillParams={() => {
                                    prompt.show(texts.notAllParams, "", {ok: true})
                                }}
                                onStartSaving={() => {
                                    prompt.show(texts.savingResults, "", {loading: true})
                                }}
                                onSaved={() => {
                                    prompt.show(texts.successSavedResults, "", {ok: true, onClose: resetPath})
                                }}
                                onFail={() => {
                                    prompt.close()
                                }}
                            />
                        )}
                        {[6].includes(event.actionType) && (
                            null
                        )}
                    </div>
                </Route>
                <Route path={path + paths.viewLocRemark}>
                    <LocRemarks
                        mode="view"
                        remarksData={remarks}
                        actionId={event.actionId}
                        actionRd={event.rd}
                        asutrId={event.tchmiAsutrId}
                    />
                </Route>
                <Route path={path + paths.addLocRemark}>
                    <LocRemarks
                        mode={params.has("preview") ? "preview" : "add"}
                        actionId={event.actionId}
                        actionRd={event.rd}
                        asutrId={event.tchmiAsutrId}
                    />
                </Route>
            </Switch>
            <Modal
                open={askModal.open}
                onClose={() => setAskModal({open: false})}
            >
                <Modal.Content>
                    {askModal.message}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        onClick={() => setAskModal({open: false})}
                    >
                        Отменить
                    </Button>
                    <Button
                        basic
                        color='red'
                        onClick={() => {
                            if (askModal.action !== undefined) {
                                askModal.action()
                            }
                        }}
                    >
                        Удалить
                    </Button>
                </Modal.Actions>
            </Modal>
            <GeoLocation />
        </div>
    )
}

const paths = {
    calendar: '/',
    addPlan: '/add-plan',
    viewPlan: '/view-event',
    editPlan: '/edit-plan',
    viewResults: '/view-results',
    editResults: '/edit-results',
    addResults: '/add-results',
    addLocRemark: '/add-loc-remark',
    viewLocRemark: '/view-loc-remark'
}
const findIndexes = (mainArr, subArr) => {
    const indexes = []
    subArr.forEach((sVal) => {
        const index = mainArr.findIndex(mVal => mVal === sVal)
        if (index !== -1) {
            indexes.push(index)
        }
    })
    return indexes
}