import React, {useState} from 'react'
import {Button, Header} from 'semantic-ui-react'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";

const MonthViolationResult = ({details, setResultOpen}) => {
    const [resultValue, setResultValue] = useState(details.investigationResult || '')

    const [sendPostOverwork, overworkLoading] =
        useApiMethod(ekasut.incidents.postOverwork, undefined, [],
            ({data}) => {
                setResultOpen(false)
                return data
            })

    let handleResult = (e) => {
        setResultValue(e.target.value)
    }

    return (
        <>
            <Header as={'h4'}>Добавление расследования</Header>
            <Header as={'h5'}>{details.depot}, {details.driver} ({details.number})</Header>
            <Header as={'h5'}>{details.post}</Header>
            <textarea style={{width: '100%'}}
                      maxLength="1000"
                      placeholder={"результат расследования"}
                      value={resultValue}
                      onChange={handleResult}
            />
            <Button
                loading={overworkLoading}
                onClick={() => {
                    sendPostOverwork({
                        'depotOkdl6': details.depotId,
                        'tabnomEkasutr': details.number,
                        'reportDateYear': details.period.year,
                        'reportDateMonth': details.period.month,
                        'investigationResult': resultValue
                    })
                }}
            >
                Отправить
            </Button>
        </>
    )
}

export default MonthViolationResult