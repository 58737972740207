import React, {useEffect} from 'react'
import DriverInstructorForm from "./DriveInstructorForm";
import useApiMethod from "../../../hooks/useApiMethod";
import ekasut from "../../../api/ekasut";
import moment from 'moment'
import GeoLocation from "../../PlanWorkBrigades/GeoLocation";

export default function DriverInstructorProfile({hideSidebars}) {

    const [sendGetPerson, personLoading, person] = useApiMethod(ekasut.tchmi.getPlan, undefined, {},
        ({data}) => {
            data.fio = `${data.tchmiLastName} ${data.tchmiFirstName} ${data.tchmiPatrName}`
            data.tchmiPostDate = moment(data.tchmiPostDate)
            return data
        }
    )

    useEffect(() => {
        sendGetPerson()
    }, [sendGetPerson])

    return (
        <div className='route-container' onClick={hideSidebars}>
            <DriverInstructorForm
                loading={personLoading}
                data={person}
            />
            <GeoLocation/>
        </div>
    )
}