import React from 'react';
import {Tab} from "semantic-ui-react";
import GroupRiskLocomotives from "./GroupRiskLocomotives";
import {LocomotivesOnProbation} from "./LocomotivesOnProbation";
import {WithdrawnLocomotives} from "./WithdrawnLocomotives";

export const GroupRisk = () => {

    const panes = [
        { menuItem: 'Локомотивы в группе риска', render: () => <Tab.Pane><GroupRiskLocomotives/></Tab.Pane> },
        { menuItem: 'Локомотивы на испытательном сроке', render: () => <Tab.Pane><LocomotivesOnProbation/></Tab.Pane> },
        { menuItem: 'Выведенные локомотивы из группы риска', render: () => <Tab.Pane><WithdrawnLocomotives/></Tab.Pane> },
    ]

    return (
        <Tab panes={panes} />
    )
}