import React from "react";
import {board} from "../TaskListState";
import ekasut from "../../../api/ekasut";


export const moveTask = (taskId, source, destination, activeBoard) => {
    if (destination?.droppableId === source?.droppableId && destination?.index === source?.index) {
        return
    }

    if (destination && activeBoard) {
        const modifBoard = [...board.boardItems]
        const fromSection = modifBoard.find(section => String(section.fieldId) === source.droppableId);
        const toSection = modifBoard.find(section => String(section.fieldId) === destination.droppableId);
        fromSection.taskPage.totalRecords -= 1
        toSection.taskPage.totalRecords += 1
        const taskToMoveIndex = fromSection.taskPage.data.findIndex(task => String(task.taskNumber) === taskId);
        const [task] = fromSection.taskPage.data.splice(taskToMoveIndex, 1);
        toSection.taskPage.data.splice(destination.index, 0, task);

        if (board.groupType === 'status' && destination.droppableId !== source.droppableId) {
            ekasut.taskboard.updateTaskStatus(board.workGruopId, taskId, destination.droppableId)
                .then(({data}) => {
                    toSection.taskPage.data.splice(destination.index, 1, data);
                })
                .catch(() => {
                    toSection.taskPage.data.splice(destination.index, 1);
                    fromSection.taskPage.data.splice(source.index, 0, task);
                    fromSection.taskPage.totalRecords += 1
                    toSection.taskPage.totalRecords -= 1
                })
        }
        if (board.groupType === 'priority' && destination.droppableId !== source.droppableId) {
            ekasut.taskboard.updateTaskPriority(board.workGruopId, taskId, destination.droppableId)
                .then(({data}) => {
                    toSection.taskPage.data.splice(destination.index, 1, data);
                })
                .catch(() => {
                    toSection.taskPage.data.splice(destination.index, 1);
                    fromSection.taskPage.data.splice(source.index, 0, task);
                    fromSection.taskPage.totalRecords += 1
                    toSection.taskPage.totalRecords -= 1
                })
        }

    }
}

export const moreTasks = (fieldId, workGruopId, sortBy, groupBy, pageNumber) => {
    const modifBoard = [...board.boardItems]
    const section = modifBoard.find(section => section.fieldId === fieldId);

    let queryString = `?${groupBy ? 'groupBy=' + groupBy : ''}&${sortBy ? 'sortBy=' + sortBy : ''}&${pageNumber ? 'pageNumber=' + pageNumber : ''}&${fieldId ? 'fieldId=' + fieldId : ''}`

    ekasut.taskboard.getBoard(workGruopId, queryString)
        .then(({data}) => {
            section.taskPage.data.push(...data[0].taskPage.data)
        })
}

export const addNewTaskBoard = (sectionId, task) => {
    let modifBoard = board.boardItems
    const section = modifBoard.find(section => section.fieldId === sectionId);
    section.taskPage.totalRecords += 1
    section.taskPage.data.unshift(task);
}

export const initBoard = ({
                              id,
                              nameBoard,
                              groupBy = null,
                              sortBy = null,
                              pageNumber = null,
                              fieldId = null,
                              workGruopRoleId = null
                          }) => {

    board.loading = true

    let queryString = `?${groupBy ? 'groupBy=' + groupBy : ''}&${sortBy ? 'sortBy=' + sortBy : ''}&${pageNumber ? 'pageNumber=' + pageNumber : ''}&${fieldId ? 'fieldId=' + fieldId : ''}`

    ekasut.taskboard.getBoard(id, queryString)
        .then(({data}) => {
            board.userListItems = false
            board.viewList = 'board'
            board.boardName = nameBoard
            board.boardItems = null
            board.boardItems = data
            board.sortBordType = sortBy ? sortBy : null
            board.loading = false
            board.workGruopId = id
            if (workGruopRoleId) {
                board.workGruopRoleId = workGruopRoleId
            }
            board.groupType = groupBy ? groupBy : 'status'
        })
        .catch((err) => {
            board.loading = false
        })
}

export const initList = ({
                             id, nameBoard, pageNumber = null, sortBy = null, categoryId = null,
                             workGruopRoleId = null
                         }) => {
    board.loading = true

    let queryString = `?${pageNumber ? 'pageNumber=' + pageNumber : ''}&${sortBy ? 'sortBy=' + sortBy : ''}&${categoryId ? 'categoryId=' + categoryId : ''}`

    ekasut.taskboard.getList(id, queryString)
        .then(({data}) => {
            board.userListItems = false
            board.viewList = 'list'
            board.categoryListId = categoryId
            board.boardName = nameBoard
            board.boardItems = null
            board.boardItems = data.taskPage.data
            board.loading = false
            board.fullResponse = data
            board.workGruopId = id
            if (workGruopRoleId) {
                board.workGruopRoleId = workGruopRoleId
            }
        })
        .catch((err) => {
            board.loading = false
        })
}


export const getUsersTasks = ({categoryId = 1, pageNumber = 1}) => {

    board.userListLoading = true

    ekasut.taskboard.getUsersTasks({categoryId, pageNumber})
        .then(({data}) => {
            board.boardItems = null
            board.userListItems = data
            board.userListLoading = false
        })
        .catch((err) => {
            board.userListLoading = false
        })
}