import React from "react";
import { Checkbox, Icon, Table } from "semantic-ui-react";
import TableRowsLoader from "../../../shared/TableRowsLoader";
import cn from "classnames";
import { formatMoment } from "../../../helpers/utils";
import { dateFormats } from "../../../Dictionaries";
import styles from "./PredLocomotivesTable.module.scss";

const IsLocVSC = ({ loc }) => {
  if (localStorage.getItem("isLocVSC") === "true" && loc.complVsc === 1) {
    return <Checkbox />;
  } else if (
    localStorage.getItem("isLocVSC") === "true" &&
    loc.complVsc === 0
  ) {
    return (
      <>
        <Icon disabled name="close" color="red" size="big" />
      </>
    );
  } else if (
    localStorage.getItem("isLocVSC") === "false" &&
    loc.complVsc === 2
  ) {
    return "-";
  }

  return "";
};

const data = [
  {
    id: 1,
    total: 45,
    pending: 35,
    washed: 30,
    inProgress: 5,
    pendingProgress: 3,
    washedProgress: 3,
    averageWashTime: 73,
  },
];

const PredLocomotivesTable = ({
  locomotives,
  header,
  loading,
  onCellClick,
}) => {
  const getClassRerunCell = (probType) =>
    probType === 1
      ? styles.warning
      : probType === 2
      ? styles.negative
      : styles.positive;

  return (
    <Table celled structured striped unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="6">Локомотивы на ТО-2</Table.HeaderCell>
          <Table.HeaderCell rowSpan="3">Ср Т обмывки (мин)</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell colSpan="3">За сутки</Table.HeaderCell>
          <Table.HeaderCell colSpan="3">На текущий момент</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Всего</Table.HeaderCell>
          <Table.HeaderCell>Подлежит обмывке</Table.HeaderCell>
          <Table.HeaderCell>Обмыто</Table.HeaderCell>
          <Table.HeaderCell>Всего</Table.HeaderCell>
          <Table.HeaderCell>Подлежит обмывке</Table.HeaderCell>
          <Table.HeaderCell>Обмыто</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <TableRowsLoader
          loading={loading}
          rows={data.map((row) => (
            <Table.Row key={row.id}>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.total}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.pending}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.washed}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.total}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.pending}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.washed}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(row)}>
                {row.averageWashTime}
              </Table.Cell>
            </Table.Row>
          ))}
        />
      </Table.Body>
    </Table>
  );
};
PredLocomotivesTable.defaultProps = {
  loading: false,
};

export default PredLocomotivesTable;
