import React, {useState} from 'react'
import {Header, Segment, Table} from 'semantic-ui-react'
import styles from './PersDataByBrigadeType.module.scss'
import cn from "classnames"
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import PersonDetails from "./PersonDetails";
import AppModal from "../../shared/AppModal";
import {PersonDetailsNew} from "./PersoneDetailsNew/PersonDetailsNew";

const PersDataByBrigadeType = ({depoName, brigadeType, persDataInfo}) => {
    const [personInfoOpen, setPersonInfoOpen] = useState(false)
    const [parentalPersonInfo, setParentalPersonInfo] = useState(false)

    const [sendGetPersonInfo, personInfoLoading, personInfo] = useApiMethod(ekasut.persData.getPersonInfo, undefined, {})

    return (
        <>
            <h1>{depoName}, {brigadeType.name}, {brigadeType.count}</h1>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ФИО</Table.HeaderCell>
                        <Table.HeaderCell>Таб номер ЕКАСУТР</Table.HeaderCell>
                        <Table.HeaderCell>Должность</Table.HeaderCell>
                        <Table.HeaderCell>Вид движения</Table.HeaderCell>
                        <Table.HeaderCell>Статус работника</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {persDataInfo
                        ? persDataInfo.map((x, index) => (
                            <Table.Row key={index}
                                       className={cn(styles.selectedWorker)}
                                       onClick={() => {
                                           setPersonInfoOpen(true)
                                           setParentalPersonInfo(x)
                                           sendGetPersonInfo(x.id, /*x.rd*/)
                                       }}>
                                <Table.Cell>{x.lastName} {x.firstName} {x.patrName}</Table.Cell>
                                <Table.Cell>{x.asutrId}</Table.Cell>
                                <Table.Cell>{x.postStr}</Table.Cell>
                                <Table.Cell>{x.moveStr}</Table.Cell>
                                <Table.Cell>{settings.workerState[x.sost]}</Table.Cell>
                            </Table.Row>
                        ))
                        : null}
                </Table.Body>
            </Table>

            <AppModal
                open={personInfoOpen}
                onClose={() => setPersonInfoOpen(false)}
                loading={personInfoLoading}
                // size={'small'}
                className={styles.modalPerson}
            >
                {/*<Segment.Group>*/}
                {/*    <Segment>*/}
                {/*        <Header as='h4'>*/}
                {/*            {personInfo.fio} ТН {parentalPersonInfo.asutrId} {parentalPersonInfo.postStr}*/}
                {/*        </Header>*/}
                {/*    </Segment>*/}
                <Segment style={{borderRadius: '14px', padding: 0,height:'100%'}}>
                    <PersonDetailsNew
                        info={personInfo}
                        additional={parentalPersonInfo}
                    />
                </Segment>
                {/*</Segment.Group>*/}
            </AppModal>
        </>
    )
}
export default PersDataByBrigadeType

const settings = {
    workerState: {
        1: 'В работе (поездке)',
        2: 'На отвлечении',
        3: 'На отдыхе',
        4: 'На вызове'
    }
}