import React from 'react'
import {Modal, Button, Header, Icon, Accordion} from 'semantic-ui-react'
import './ModalError.scss'
import {observer, useLocalStore} from "mobx-react-lite";

const ModalError = observer(({error, onClose}) => {
    const ls = useLocalStore(() => ({
        showDetails: false
    }))

    const errorData = error && error.response && error.response.data

    if (errorData && (errorData["$type"] || errorData.hResult || errorData.source)) {
        errorData.showError = true
        errorData.title = null
    }

    if (!errorData || errorData.showError !== true) {
        return null
    }

    return (
        <Modal
            open={errorData}
            basic
            onClose={() => onClose()}
        >
            <Header icon='warning' content={errorData.title === null ? "Ошибка при запросе данных у сервера" : errorData.title}/>
            <Modal.Content>
                {error && (<>
                    {errorData.message && <h3>{errorData.message}</h3>}
                    {errorData.details && (
                        <Accordion>
                            <Accordion.Title content="Детали ошибки" active={ls.showDetails} onClick={() => ls.showDetails = !ls.showDetails}/>
                            <Accordion.Content active={ls.showDetails}>
                                {errorData.details}
                            </Accordion.Content>
                        </Accordion>
                    )}
                </>)}
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => onClose()}>
                    <Icon name='checkmark'/> Закрыть
                </Button>
            </Modal.Actions>
        </Modal>
    )
})


export default ModalError
