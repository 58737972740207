import React from 'react';
import style from './TimingTab.module.scss';
import moment from "moment";

export const TimingTab = ({info}) => {
    return (
        <div className={style.wrap}>
            <div className={style.rowItem}>
                <h4>Экзамен по ОТ</h4>
                <p>{info?.checkOt ? moment(info?.checkOt).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Срок действия права выезда на обслуживаемые участки</h4>
                <p>{info?.dopWorkPolygons ? moment(info?.dopWorkPolygons).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Дата следующей КП</h4>
                <p>{info?.nextKip ? moment(info?.nextKip).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Психолог</h4>
                <p>{info?.nextPsih ? moment(info?.nextPsih).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Медкомиссия</h4>
                <p>{info?.nextMed ? moment(info?.nextMed).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Удостоверение по ЭБ</h4>
                <p>{info?.nextEb ? moment(info?.nextEb).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Повт. инструктаж по ОТ и ТБ</h4>
                <p>{info?.nextTb ? moment(info?.nextTb).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Зачет по ПТМ</h4>
                <p>{info?.ptmDateTo ? moment(info?.ptmDateTo).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Крайний срок тестирования в АСПТ</h4>
                <p>{info?.testAsptDate ? moment(info?.testAsptDate).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Аттестация</h4>
                <p>{info?.attestNextCheck ? moment(info?.attestNextCheck).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
            <div className={style.rowItem}>
                <h4>Повышение квалификации</h4>
                <p>{info?.kvalNextDate ? moment(info?.kvalNextDate).format('DD.MM.YYYY HH:mm'):'-'}</p>
            </div>
        </div>
    )
}