import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addSolidGauge from 'highcharts/modules/solid-gauge'
import addHighchartsMore from 'highcharts/highcharts-more'
import './HalfCircleProgressBar.scss'

addHighchartsMore(Highcharts)
addSolidGauge(Highcharts)


const RangeCircleBar = (
    {
        min, max, from, to, color, value, description, secondValue
    }
) => {
    // Protect gauge from values outside boundaries
    if (from > to) {
        let temp = from
        from = to
        to = temp
    }
    if (from < min) {
        from = min
    }
    if (to > max) {
        to = max
    }
    if (from > max) {
        from = max
    }
    if (to < min) {
        to = min
    }

    const addOptions = {
        yAxis: {
            min,
            max,
            plotBands: [{
                from,
                to,
                color,
                thickness: '24%'
            }],
            title: {
                text: (secondValue === 0 || secondValue === '0') ? '0' : secondValue, // Highcharts doesn't display number or string 0
            }
        }
    }
    const options = Highcharts.merge(initOptions, addOptions)

    return (
        <div className="half-circle-progress-bar">
            <div className="hc-value-container">
                <div className="hc-value">{value}</div>
                <div className="hc-value-descr">{description}</div>
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
RangeCircleBar.defaultProps = {}
export default RangeCircleBar

const initOptions = {
    chart: {
        type: 'solidgauge',
        backgroundColor: 'rgba(0,0,0,0)',
        height: 130,
        width: 180,
        margin: 0,
        animation: false,
    },
    series: [{
        name: 'esf',
        data: [],
        animation: false,
    }],
    credits: {
        enabled: false
    },
    title: null,
    pane: {
        center: ['50%', '67%'],
        size: '115%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
            innerRadius: '76%',
            outerRadius: '100%',
            shape: 'solid',
        }
    },
    exporting: {
        enabled: false
    },
    tooltip: {
        enabled: false
    },
    // the value axis
    yAxis: {
        lineWidth: 0,
        minorTickInterval: null,
        tickPositions:[],
        tickAmount: 1,
        title: {
            y: -40
        },
        labels: {
            format: '{value: } ',
        },
    },
};