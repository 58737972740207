import React, { useEffect, useState } from "react";
import style from "../index.module.scss";
import { Dropdown } from "semantic-ui-react";
import { Switch, Menu } from "antd";
import { initBoard, initList } from "../dashboardsLogic/board";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { board } from "../TaskListState";

export const TaskboardHeader = ({ activeBrd }) => {
  const [isCheck, setIsCheck] = useState(false);
  const [sortValue, setSortValue] = useState("updated_on_desc");
  const [groupValue, setGroupValue] = useState("status");
  const [subtaskValue, setSubtaskValue] = useState(1);
  const [catsValue, setCatsValue] = useState(4);
  const [listMode, setListMode] = useState("board");

  const optionSort = [
    { key: 1, text: "Дата обновления 🠗", value: "updated_on_desc" },
    { key: 2, text: "Дата обновления 🠕", value: "updated_on" },
    { key: 4, text: "Дата создания 🠗", value: "added_on_desc" },
    { key: 3, text: "Дата создания 🠕", value: "added_on" },
    { key: 6, text: "Плановая дата начала 🠗", value: "plan_start_date_desc" },
    { key: 5, text: "Плановая дата начала 🠕", value: "plan_start_date" },
    { key: 8, text: "Плановая дата завершения 🠗", value: "plan_end_date_desc" },
    { key: 7, text: "Плановая дата завершения 🠕", value: "plan_end_date" },
    { key: 9, text: "Прикрепленные файлы", value: "has_files" },
    { key: 10, text: "Наличие комментариев", value: "has_comments" },
  ];
  const optionGroup = [
    { key: 1, text: "Статус", value: "status" },
    { key: 2, text: "Приоритет", value: "priority" },
  ];

  const optionCats = [
    { key: 1, text: "Активные", value: 1 },
    { key: 2, text: "Выполненные", value: 2 },
    { key: 3, text: "Закрытые", value: 3 },
    { key: 4, text: "Все", value: 4 },
  ];

  const optionSubtasks = [
    { key: 1, text: "Скрыть", value: 1 },
    { key: 2, text: "Показать", value: 2 },
  ];

  useEffect(() => {
    // if (listMode === 'list' && activeBrd.viewList === 'board') {
    //     setListMode('board')
    // }
    setListMode(activeBrd.viewList);
    if (
      sortValue !== "updated_on_desc" ||
      groupValue !== "status" ||
      subtaskValue !== 1
    ) {
      setSubtaskValue(1);
      setSortValue("updated_on_desc");
      setGroupValue("status");
      setCatsValue(4);
    }
  }, [activeBrd.viewList]);

  useEffect(() => {
    if (
      sortValue !== "updated_on_desc" ||
      groupValue !== "status" ||
      subtaskValue !== 1
    ) {
      setSubtaskValue(1);
      setSortValue("updated_on_desc");
      setGroupValue("status");
      setCatsValue(4);
    }
  }, [activeBrd.boardName]);

  const updateBoardOrList = (
    sortValue,
    groupValue,
    subtaskValue,
    catsValue
  ) => {
    if (
      (sortValue || groupValue || subtaskValue) &&
      activeBrd.viewList === "board"
    ) {
      initBoard({
        id: activeBrd.workGruopId,
        nameBoard: activeBrd.boardName,
        groupBy: groupValue,
        sortBy: sortValue,
      });
    }
    if ((sortValue || catsValue) && activeBrd.viewList === "list") {
      console.log(sortValue, catsValue);
      initList({
        id: activeBrd.workGruopId,
        nameBoard: activeBrd.boardName,
        sortBy: sortValue,
        categoryId: catsValue !== 4 ? catsValue : null,
      });
    }
  };

  return (
    <div className={style.taskBoardHeader}>
      <span className={style.boardName}>{activeBrd.boardName}</span>
      <div className={style.listMode}>
        <div className={style.modeTabs}>
          <Menu mode="horizontal" selectedKeys={[listMode]}>
            <Menu.Item
              key="board"
              onClick={({ key }) => {
                setListMode(key);
                initBoard({
                  id: activeBrd.workGruopId,
                  nameBoard: activeBrd.boardName,
                });
              }}
            >
              Доска
            </Menu.Item>
            <Menu.Item
              key="list"
              onClick={({ key }) => {
                setListMode(key);
                initList({
                  id: activeBrd.workGruopId,
                  nameBoard: activeBrd.boardName,
                });
              }}
            >
              Список
            </Menu.Item>
            <Menu.Item
              key="analytics"
              onClick={({ key }) => {
                setListMode(key);
                board.viewList = "analytics";
              }}
            >
              Аналитика
            </Menu.Item>
          </Menu>
        </div>
      </div>
      {listMode !== "analytics" ? (
        <span className={style.sortDropAria}>
          Сортировка:{" "}
          <Dropdown
            inline
            options={optionSort}
            value={sortValue}
            className={style.sortDrop}
            onChange={(event, data) => {
              if (document.querySelector("#listAriaTopBar")) {
                document.querySelector("#listAriaTopBar").scrollIntoView();
              }
              setSortValue(data.value);
              updateBoardOrList(
                data.value,
                groupValue,
                subtaskValue,
                catsValue === 4 ? null : catsValue
              );
            }}
          />
        </span>
      ) : null}
      {listMode === "board" ? (
        <span>
          Группировка:{" "}
          <Dropdown
            inline
            options={optionGroup}
            value={groupValue}
            className={style.groupDrop}
            onChange={(event, data) => {
              setGroupValue(data.value);
              updateBoardOrList(sortValue, data.value, subtaskValue, null);
            }}
          />
        </span>
      ) : null}
      {/*{listMode === 'board' ? <span>*/}
      {/*    Подзадачи:{' '}*/}
      {/*    <Dropdown inline options={optionSubtasks} value={subtaskValue}*/}
      {/*              className={style.subtaskDrop} onChange={(event, data) => {*/}
      {/*        setSubtaskValue(data.value)*/}
      {/*        updateBoardOrList(sortValue, groupValue, data.value, null)*/}
      {/*    }}/>*/}
      {/*    </span> : null}*/}
      {listMode === "list" ? (
        <span>
          Категория:{" "}
          <Dropdown
            inline
            options={optionCats}
            value={catsValue}
            className={style.subtaskDrop}
            onChange={(event, data) => {
              if (document.querySelector("#listAriaTopBar")) {
                document.querySelector("#listAriaTopBar").scrollIntoView();
              }
              setCatsValue(data.value);
              updateBoardOrList(sortValue, null, null, data.value);
            }}
          />
        </span>
      ) : null}
    </div>
  );
};
