import React, { useState, useCallback } from "react";
import styles from "./Inspection.module.scss";
import {
  Dimmer,
  Loader,
  Segment,
  Table,
} from "semantic-ui-react";
import AppModal from "../../../shared/AppModal";
import { AddProt } from "./AddProt/AddProt";
import { useLocalStore } from "mobx-react-lite";
import ekasut from "../../../api/ekasut";
import { useEffect } from "react";
import { ConfigProvider } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import {LocoDetails} from "./LocoDetails/LocoDetails";


const workMark = (bool) => {
  if (bool) {
    return (
      <span className={styles.workMark} style={{ background: "#4BAF50" }}>
        Выполнено
      </span>
    );
  } else {
    return (
      <span className={styles.workMark} style={{ background: "#FF5622" }}>
        Не выполнено
      </span>
    );
  }
};

const Inspection = ({}) => {
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const onStartLoad = useCallback(() => setLoading(true), []);
  const onLoaded = useCallback(() => setLoading(false), []);
  const [locoId, setLocoId] = useState({
    serLoc: null,
    znsLoc: null,
    prsLoc: null,
  });

  const ls = useLocalStore(() => ({
    expand: false,
  }));

  const [openLocoDetails, setOpenLocoDetails] = useState(false);
  const [addProt, setAddProt] = useState(false);
  const [locoInfo, setLocoInfo] = useState(null);
  const [sldPark, setSldPark] = useState([]);
  const [currentObjId, setCurrentObjId] = useState(null);
  const [currentSostName, setCurrentSostName] = useState(null);
  const [currentSostKod, setCurrentSostKod] = useState(null);
  const [currentDateOp, setCurrentDateOp] = useState(null);
  const [currentIsDate, setCurrentIsDate] = useState(null);

  const getSld = async () => {
    const res = await ekasut.commisionInspection.getSldPark(
      localStorage.kodFirm
    );
    setSldPark(res?.data);
    setLoadingTable(false);
  };

  useEffect(() => {
    getSld();
  }, []);

  return (
    <div className={styles.divInspection}>
      <span className={styles.nameSpace}>
        Локомотивы на комиссионном осмотре
      </span>
      <Dimmer
        active={loadingTable}
        inverted
        style={{
          width: "100%",
          height: 100,
          background: "none",
          position: "inherit",
        }}
      >
        <Loader content="Загрузка" style={{ fontSize: 14 }} />
      </Dimmer>

      {loadingTable === false && (
        <Table celled className={styles.inspTable}>
          <Table.Header>
            <Table.Row textAlign={"center"}>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Серия</Table.HeaderCell>
              <Table.HeaderCell>Номер</Table.HeaderCell>
              <Table.HeaderCell>Депо приписки</Table.HeaderCell>
              <Table.HeaderCell>Депо операпции</Table.HeaderCell>
              <Table.HeaderCell>Состояние</Table.HeaderCell>
              <Table.HeaderCell>Начало состояния</Table.HeaderCell>
              <Table.HeaderCell>Продолжительность</Table.HeaderCell>
              {/* <Table.HeaderCell>
                Отметка о проведении <br /> цикловых работ
              </Table.HeaderCell> */}
              <Table.HeaderCell>
                Отметка о проведении <br /> доп. работ
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {sldPark?.map((e, i) => (
              <Table.Row
                key={i}
                positive={e.signOcenka === 0 ? true : false}
                textAlign={"center"}
                onClick={() => {
                  setLocoInfo(e);
                  setOpenLocoDetails(true);
                  setLocoId({
                    serLoc: e.serLoc,
                    znsLoc: e.znsLoc,
                    prsLoc: e.prsLoc,
                  });
                  setCurrentObjId(e?.idObject);
                  setCurrentSostKod(e?.kodSost);
                  setCurrentSostName(e?.naimSost);
                  setCurrentDateOp(e?.dateOp);
                  setCurrentIsDate(e?.isDate);
                }}
              >
                <Table.Cell>
                  <div className={styles.trainPhoto}>
                    {e.nomSec.slice(0, 2)}
                  </div>
                </Table.Cell>
                <Table.Cell>{e.serName}</Table.Cell>
                <Table.Cell>{e.nomSec}</Table.Cell>
                <Table.Cell>{e.firmName}</Table.Cell>
                <Table.Cell>{e.naimDepo}</Table.Cell>
                <Table.Cell>{e.naimSost}</Table.Cell>
                <Table.Cell>{e.isDate}</Table.Cell>
                <Table.Cell>{e.cont}</Table.Cell>
                <Table.Cell>{workMark(e.signAxwork)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}

      <AppModal
        open={openLocoDetails}
        onClose={() => setOpenLocoDetails(false)}
        className={styles.modalLoco}
      >
        <Segment style={{borderRadius: '14px', padding: 0, height: '100%'}}>
          <LocoDetails
            info={locoInfo}
            setAddProt={setAddProt}
          />
        </Segment>
      </AppModal>

      <AppModal
        open={addProt}
        onClose={() => setAddProt(false)}
        //loading={personInfoLoading}
        className={styles.modalAddProt}
      >
        <Segment
          style={{
            borderRadius: "14px",
            padding: 0,
            height: "100%",
            width: "1200px",
            left: "-250px",
          }}
        >
          <ConfigProvider locale={locale}>
            <AddProt
              info={locoInfo}
              currentObjId={currentObjId}
              currentSostKod={currentSostKod}
              currentSostName={currentSostName}
              setAddProt={setAddProt}
              addProt={addProt}
              currentDateOp={currentDateOp}
              currentIsDate={currentIsDate}
              getSld={getSld}
            />
          </ConfigProvider>
        </Segment>
      </AppModal>
    </div>
  );
};

export default Inspection;
