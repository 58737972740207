import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addSolidGauge from 'highcharts/modules/solid-gauge'
import addHighchartsMore from 'highcharts/highcharts-more'
import './HalfCircleProgressBar.scss'

addHighchartsMore(Highcharts)
addSolidGauge(Highcharts)


export default function HalfCircleProgressBar({value, min, max, valueDescr, onClick, className, colorStops}) {
	const gaugeOptions = {
		chart: {
			className: className, 
			type: 'solidgauge',
			backgroundColor: 'rgba(0,0,0,0)',
			height: 130,
			width: 180,
			margin: 0,
			animation: false,
			// style: {
			// 	cursor: 'pointer',
			// },
			events: {
				click: onClick
			}
		},

		credits: {
			enabled: false
		},
		title: null,
		pane: {
			center: ['50%', '67%'],
			size: '115%',
			startAngle: -90,
			endAngle: 90,
			background: {
				backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
				innerRadius: '76%',
				outerRadius: '100%',
				shape: 'arc',
			}
		},
		exporting: {
			enabled: false
		},
		tooltip: {
			enabled: false
		},
		// the value axis
		yAxis: {
			stops: colorStops,
			lineWidth: 0,
			minorTickInterval: null,
			tickAmount: 1,
			title: {
				y: -54 // chart title
			},
			labels: {
				format: '{value: } ',
				y: 16
			},
		},

		plotOptions: {
			series: {
				// cursor: 'pointer',
				point: {
					events: {
						click: onClick
					}
				}
			},

			solidgauge: {
				innerRadius: '76%',
				dataLabels: {
					y: 5,
					borderWidth: 0,
					useHTML: true
				}
			}
		}
	};
	const chartOptions = Highcharts.merge(gaugeOptions, {
		yAxis: {
			min: min,
			max: max,
		},
	
		series: [{
			name: '',
			data: [value],
			animation: false,
			dataLabels: {
				format: ''
			},
			tooltip: {
				valueSuffix: ' revolutions/min'
			}
		}]
	
	});

	return (
		<div className="half-circle-progress-bar">
			<div className="hc-value-container">
				<div className="hc-value">{value}</div>
				<div className="hc-value-descr">{valueDescr}</div>
			</div>
			<HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
				className={className}
			/>
		</div>
	)
}
HalfCircleProgressBar.defaultProps = {
	value: 'value',
	max: 500,
	min: -500,
	valueDescr: 'Value',
	colorStops: [
		[0, '#db2828'], // red
		[0.3, '#db2828'], // red
		[0.3, '#fbbd08'], // yellow
		[0.5, '#fbbd08'], // yellow
		[0.5, '#21ba45'] // green
	],
}