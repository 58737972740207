import React, {useEffect, useState, useCallback} from 'react'
import ReactDOM from 'react-dom'
import RailwayTree from "./RailwayTree"
import dbSync from '../../../api/dbSync'
import LogInfoTable from "./LogInfoTable";
import './index.scss'
import SaveChangesButton from "./SaveChangesButton";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

export default function LogControlManagement({hideSidebars}) {
    const [tree, setTree] = useState(null)
    const [loadedInfo, setLoadedInfo] = useState(null)
    // Performance optimisation: i don't want to clone whole map on each change
    const [userChanges, setUserChanges] = useState({changes: new Map()})
    const [infoLoading, setInfoLoading] = useState(false)
    const [showMsgBeforeStart, setShowMsgBeforeStart] = useState(true)
    const [saveProcess, setSaveProcess] = useState(false)
    const [selected, setSelected] = useState({id: -1, level: -1, name: null})

    useEffect(() => {
        //dbSync.system.getCheckAccess().catch(catchNetworkErrors);
    }, []);

    useEffect(function loadMenuTree() {
        dbSync.logControl.cancel()
        dbSync.logControl.getPreds()
            .then(response => {
                // in response root is object, but children is array
                setTree([response.data])
            })
            .catch(catchNetworkErrors)
    }, [])

    useEffect(function closeOpenConnectionsBeforeUnmount() {
        return () => {
            dbSync.logControl.cancel()
        }
    }, [])

    const handleSelect = (id, level, name) => {
        setSelected({id, level, name})
        setShowMsgBeforeStart(false)
        setInfoLoading(true)
        setLoadedInfo(null)
        dbSync.logControl.cancel()
        dbSync.logControl.getChecks(level, id)
            .then(response => {
                ReactDOM.unstable_batchedUpdates(() => {
                    setLoadedInfo(response.data)
                    setUserChanges({changes: new Map()})
                    setInfoLoading(false)
                })
            })
            .catch(catchNetworkErrors)
    }

    // Keep reference to function, because it used in props LogInfoTable
    const handleValueOnChange = useCallback((value, typeUseId, index, defaultValue) => {
        setUserChanges(prevUserChanges => {
            const newUserChanges = {...prevUserChanges}
            if (value === defaultValue) {
                newUserChanges.changes.delete(index)
            } else {
                newUserChanges.changes.set(index, {
                    useId: typeUseId,
                    useName: value
                })
            }
            return newUserChanges
        })
    }, [])

    const updateInfo = (info, changes) => {
        let newInfo = [...info],
            changedIndexes = Array.from(changes.keys())

        changes.forEach((value, index) => {
            newInfo[index].typeUseName = value.useName
            newInfo[index].typeUseId = value.useId
        })

        // only updated elements:
        return newInfo.filter((item, index) => changedIndexes.includes(index))
    }

    const handleSaveButtonClick = () => {
        if (!saveProcess) {
            setSaveProcess(true)
            const updatedInfo = updateInfo(loadedInfo, userChanges.changes)
            const bodyData = []
            updatedInfo.forEach(item => {
                bodyData.push({
                    checkId: item.checkId,
                    isBrig: item.isBrig,
                    typeUseId: item.typeUseId
                })
            })
            dbSync.logControl.cancel()
            dbSync.logControl.putChecks(selected.level, selected.id, bodyData)
                .then(() => {
                    setSaveProcess(false)
                    setUserChanges({changes: new Map()})
                    handleSelect(selected.id, selected.level, selected.name)
                })
                .catch(catchNetworkErrors)
        }
    }

    return (
        <div className='log-control-management' onClick={hideSidebars}>
            <div className="railway-container">
                <RailwayTree
                    className="railway-tree"
                    tree={tree}
                    onSelect={handleSelect}
                />
            </div>
            <div className="table-with-button">
                <div className="save-button-container">
                    <h4>{selected.name}</h4>
                    <SaveChangesButton
                        disabled={userChanges.changes.size === 0 || infoLoading}
                        className="save-changes-button"
                        loading={saveProcess}
                        onClick={handleSaveButtonClick}
                    />
                </div>
                <div className="table-container">
                    <LogInfoTable
                        info={loadedInfo}
                        showMessage={showMsgBeforeStart}
                        message="Для загрузки данных выделите пункт из списка"
                        className="log-info-table"
                        loading={infoLoading}
                        valueOnChange={handleValueOnChange}
                    />
                </div>

            </div>
        </div>
    )
}