import styles from "../index.module.scss";
import {TextArea} from "semantic-ui-react";
import React, {useState} from "react";

export default function({label, editable, defValue, onChange, value}) {

    const [text, setText] = useState(defValue === null ? '' : defValue)
    return (
        <div className={styles.formField}>
            <label>{label}</label>
            <TextArea
                readOnly={!editable}
                placeholder={editable ? 'Текст (не более 1000 символов)' : ''}
                value={value !== undefined ? value : text}
                onChange={(e, {value}) => {
                    if (value.length < 1000 && editable) {
                        setText(value)
                        onChange(value)
                    }
                }}
            />
        </div>
    )
}
