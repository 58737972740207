import React, { useEffect, useState } from "react";
import { Dimmer, Grid, Header, Loader, Segment } from "semantic-ui-react";
import styles from "../../OrganizationInspection.module.scss";
import DateField from "../../../../../shared/fields/DateField";
import moment from "moment";
import { BubbleChart } from "@material-ui/icons";
import style from "./Planning.module.scss";
import ekasut from "../../../../../api/ekasut";
import TextField from "../../../../../shared/fields/TextField";
import { Button } from "semantic-ui-react";
import { Input } from "antd";

export const Planning = ({
  periodFilter,
  year,
  season,
  // datesPlan,
  // setDatesPlan,
}) => {
  const [dates, setDates] = useState({
    dateBegCosm: null,
    dateBegPodgCo: null,
    dateBegResCo: null,
    dateEndCosm: null,
    dateEndPodgCo: null,
    dateEndResCo: null,
  });
  const [resason, setReason] = useState("");
  const [isDates, setIsDates] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datesPlan, setDatesPlan] = useState({});

  const sendDates = async () => {
    const response = await ekasut.commisionInspection.addPlan({
      pYear: year,
      pTypeCo: season,
      pReasonCo: datesPlan?.reasonCo,
      pBegPodg: moment(datesPlan?.dateBegPodgCo)?.format("YYYY-MM-DD"),
      pEndPodg: moment(datesPlan?.dateEndPodgCo)?.format("YYYY-MM-DD"),
      pBegCOsm: moment(datesPlan?.dateBegCosm)?.format("YYYY-MM-DD"),
      pEndCOsm: moment(datesPlan?.dateEndCosm)?.format("YYYY-MM-DD"),
      pBegRes: moment(datesPlan?.dateBegResCo)?.format("YYYY-MM-DD"),
      pEndRes: moment(datesPlan?.dateEndResCo)?.format("YYYY-MM-DD"),
    });
  };

  // useEffect(() => {
  //     if (periodFilter.yearCO) {
  //         ekasut.commisionInspection.getPlan({pTypeCo: periodFilter.typeCO, pYear: periodFilter.yearCO})
  //             .then(({data}) => {
  //                 setDates({
  //                     dateBegCosm: moment(data[0]?.dateBegCosm),
  //                     dateBegPodgCo: moment(data[0]?.dateBegPodgCo),
  //                     dateBegResCo: moment(data[0]?.dateBegResCo),
  //                     dateEndCosm: moment(data[0]?.dateEndCosm),
  //                     dateEndPodgCo: moment(data[0]?.dateEndPodgCo),
  //                     dateEndResCo: moment(data[0]?.dateEndResCo),
  //                 })
  //                 setLoading(false)
  //                 if (data.length === 0) {
  //                     setIsDates(true)
  //                 }
  //             })
  //     }
  // }, [periodFilter])

  useEffect(() => {
    const getDates = async () => {
      const result = await ekasut.commisionInspection.getPlan(season, year);
      setDatesPlan(result?.data[0]);
    };

    year && getDates();
  }, [year]);

  console.log();

  return (
    <Segment className={style.Segment}>
      <Dimmer active={loading} inverted>
        <Loader inverted>Загрузка</Loader>
      </Dimmer>
      {/* <Dimmer active={!loading && isDates} inverted>
                    Комиссионный осмотр не запланирован
                </Dimmer> */}
      <Grid className={styles.genInfoGrid}>
        <Grid.Row className={styles.infoRowGrid}>
          <Grid.Column>
            <Header
              as="h3"
              content="Планирование комиссионного осмотра"
              className={styles.infoHeader}
            />

            <Header
              content="Подготовка комиссионного осмотра"
              className={styles.planKOheader}
            />
            <Grid.Row className={styles.rowPlanKO}>
              <DateField
                editable={true}
                label="Начало"
                inputClassName="date-underline"
                dateFormat={"DD.MM.YYYY"}
                date={moment(datesPlan.dateBegPodgCo)}
                closable
                onChange={(e) =>
                  setDatesPlan({ ...datesPlan, dateBegPodgCo: e })
                }
              />
              <DateField
                editable={true}
                label="Окончание"
                inputClassName="date-underline"
                dateFormat={"DD.MM.YYYY"}
                date={moment(datesPlan.dateEndPodgCo)}
                closable
                onChange={(e) =>
                  setDatesPlan({ ...datesPlan, dateEndPodgCo: e })
                }
              />
            </Grid.Row>
            <Header
              content="Проведение комиссионного осмотра"
              className={styles.planKOheader}
            />
            <Grid.Row className={styles.rowPlanKO}>
              <DateField
                editable={true}
                label="Начало"
                inputClassName="date-underline"
                dateFormat={"DD.MM.YYYY"}
                date={moment(datesPlan.dateBegCosm)}
                closable
                onChange={(e) => setDatesPlan({ ...datesPlan, dateBegCosm: e })}
              />
              <DateField
                editable={true}
                label="Окончание"
                inputClassName="date-underline"
                dateFormat={"DD.MM.YYYY"}
                date={moment(datesPlan.dateEndCosm)}
                closable
                onChange={(e) => setDatesPlan({ ...datesPlan, dateEndCosm: e })}
              />
            </Grid.Row>
            <Header
              content="Анализ результатов комиссионного осмотра"
              className={styles.planKOheader}
            />
            <Grid.Row className={styles.rowPlanKO}>
              <DateField
                editable={true}
                label="Начало"
                inputClassName="date-underline"
                dateFormat={"DD.MM.YYYY"}
                date={moment(datesPlan.dateBegResCo)}
                closable
                onChange={(e) => setDatesPlan({ ...datesPlan, dateBegResCo: e })}
              />
              <DateField
                editable={true}
                label="Окончание"
                inputClassName="date-underline"
                dateFormat={"DD.MM.YYYY"}
                date={moment(datesPlan.dateEndResCo)}
                closable
                onChange={(e) => setDatesPlan({ ...datesPlan, dateEndResCo: e })}
              />
            </Grid.Row>
            <Grid.Row>
              <Input
                placeholder="Причина КО"
                style={{ width: "220px" }}
                value={datesPlan.reasonCo}
                onChange={(e) => setDatesPlan({...datesPlan, reasonCo: e.target.value})}
              />
              <Button
                style={{ marginLeft: "22px", width: "220px" }}
                onClick={() => sendDates()}
              >
                Добавить
              </Button>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
