import React, {useEffect} from 'react';
import AuditStepsWidget from "./AuditStepsWidget";
import {Accordion, Button, Header, Icon, Modal, Table} from "semantic-ui-react";
import ekasut from "../../api/ekasut";
import './DevelopRecommendations.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {extractQuestions, getProcessQuestions, processesParser} from "./functions";
import TextAreaAutosize from "react-textarea-autosize";
import {runInAction, toJS} from "mobx";
import Widget from "../../shared/Widget";
import AddRecommendationsModal from "./AddRecommendationsModal";
import {chrome} from "leaflet/src/core/Browser";
import {usePrompt} from "../../globalStore/useGlobalStore";

const DevelopRecommendations = observer(({onClose, onComplete, audit = {}, onView, editable, step, viewStep, display}) => {

    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        advices: [],
        activeAdvice: null,
        addRecommendationModal: {open: false},
        questionIds: null,
        checkedQuestionIds: {},
        wasFirstDisplay: false
    }))

    useEffect(() => {
        if (display && !ls.wasFirstDisplay) {
            ls.wasFirstDisplay = true
            ekasut.audit.getAdvices({auditId: audit.auditId})
                .then(({data}) => {
                    ls.advices = data.map(advice => ({...advice, questions: [], questionsLoaded: false}))
                })
                .catch(catchNetworkErrors)
        }
    }, [display, ls.wasFirstDisplay])

    const save = (complete) => {
        prompt.show("Сохранение рекомендаций", "", {loading: true})

        const notSavedAdvices = ls.advices.filter(advice => advice.code === null)

        Promise.all(notSavedAdvices.map(advice => ekasut.audit.postAdvice({
            auditId: audit.auditId, adviceName: advice.name, questions: advice.questions.map(q => q.questionId)
        })))
            .then((responses) => {
                responses.forEach(({data}, index) => notSavedAdvices[index].code = data)
                if (complete) {
                    onComplete()
                } else {
                    prompt.show("Рекомендации сохранены", "", {ok: true})
                }
            })
            .catch((err) => {
                prompt.close()
                return catchNetworkErrors(err)
            })
    }

    const checkForm = () => true /*{
        // Process not loaded
        if (ls.questionIds === null) {
            return false
        }

        for (const id in ls.questionIds) {
            if (ls.checkedQuestionIds[id] === undefined) {
                return false
            }
        }

        return true
    }*/

    const findCheckedQuestionIdsInAdvices = () => {
        const checkedQuestionIds = {}
        ls.advices.forEach(advice => advice.questions.forEach(question => checkedQuestionIds[question.questionId] = true))
        return checkedQuestionIds
    }

    if (!display) {
        return false
    }

    return (
        <AuditStepsWidget
            className="app develop-recommendations"
            onClose={onClose}
            onCancel={onClose}
            onView={onView}
            step={step}
            viewStep={viewStep}
            displayButtons={{cancel: true, exportDoc: false, save: true, end: true}}
            onSave={() => {
                save(false)
            }}
            onEnd={() => {
                if (checkForm()) {
                    prompt.show("Вы действительно хотите завершить этап?", "",
                        {yesNo: true, onApply: () => save(true)}
                    )
                } else {
                    prompt.show("Завершение этапа не выполнено", "Не все рекомендации указаны", {ok: true})
                }
            }}
        >
            <Header as="h2" className="table-header" content="Необработанные несоответствия"/>
            <Accordion>
                {ls.advices.map((advice, index) => (<React.Fragment key={index}>
                    <Accordion.Title
                        active={ls.activeAdvice === index}
                        index={index}
                        onClick={() => {
                            ls.activeAdvice = ls.activeAdvice === index ? null : index
                            if (ls.activeAdvice !== null && !advice.questionsLoaded) {
                                ekasut.audit.getQuestionsForAdvice({adviceId: advice.code})
                                    .then(({data}) => {
                                        advice.questions = extractQuestions(data)
                                        advice.questionsLoaded = true
                                    })
                            }
                        }}
                    >
                        <Icon name="dropdown"/>
                        {advice.name}
                    </Accordion.Title>
                    <Accordion.Content active={ls.activeAdvice === index}>
                        {advice.questions && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell content="Критерий"/>
                                        <Table.HeaderCell content="Способ оценки"/>
                                        <Table.HeaderCell content="Выполнения критерия"/>
                                        <Table.HeaderCell content="Несоответствия"/>
                                        <Table.HeaderCell content="Оценка"/>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {advice.questions.map((question, index) => (
                                        <Table.Row key={question.questionId}>
                                            <Table.Cell>{question.criterionName}</Table.Cell>
                                            <Table.Cell>{question.question}</Table.Cell>
                                            <Table.Cell>{question.description}</Table.Cell>
                                            <Table.Cell>{question.reasonName}</Table.Cell>
                                            <Table.Cell>{question.gradeName}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                    </Accordion.Content>
                </React.Fragment>))}
            </Accordion>
            {editable && (
                <Button
                    content="Добавить рекомендации"
                    basic icon="plus"
                    className="button-basic-without-borders add-recommendation-button"
                    onClick={() => {
                        const advicesWithNotLoadedQuestions = ls.advices.filter(advice => !advice.questionsLoaded)
                        Promise.all(advicesWithNotLoadedQuestions.map(advice => ekasut.audit.getQuestionsForAdvice({adviceId: advice.code})))
                            .then((responses) => {
                                runInAction(() => {
                                    responses.forEach(({data}, index) => {
                                        advicesWithNotLoadedQuestions[index].questions = extractQuestions(data)
                                        advicesWithNotLoadedQuestions[index].questionsLoaded = true
                                    })
                                    ls.checkedQuestionIds = findCheckedQuestionIdsInAdvices(ls.advices)
                                    ls.addRecommendationModal = {open: true}
                                })
                            })
                            .catch(catchNetworkErrors)
                    }}
                />
            )}
            <AddRecommendationsModal
                open={ls.addRecommendationModal.open}
                audit={audit}
                onApply={(advice, selectedQuestions) => {
                    ls.advices.push({code: null, name: advice, questions: selectedQuestions, questionsLoaded: true})
                    ls.addRecommendationModal.open = false
                    ls.checkedQuestionIds = findCheckedQuestionIdsInAdvices(ls.advices)
                }}
                highlightQuestionIds={ls.checkedQuestionIds}
                onLoadProcesses={(processes) => {
                    const ids = {}
                    processes.forEach(process => process.questions.forEach(question => ids[question.questionId] = true))
                    ls.questionIds = ids
                }}
                onCancel={() => {
                    ls.addRecommendationModal.open = false
                }}
            />
        </AuditStepsWidget>
    );
})

export default DevelopRecommendations;