import React from "react";
import DayView from "./DayView";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import AddAuditView from "./AddAuditView";
import AreaHeader from "../../shared/headers/AreaHeader";
import CalendarForYear from "../../shared/CalendarForYear";
import PredDropdown from "./PredDropdown";
import { Button, Checkbox, Dropdown, Segment } from "semantic-ui-react";
import FieldHeader from "../../shared/headers/FieldHeader";
import FilterAuditTypes from "./FilterAuditTypes";
import { useLocalStore, observer } from "mobx-react-lite";
import moment from "moment";
import apiWorker from "../../api/apiWorker";
import ekasut from "../../api/ekasut";
import { getAuditColor } from "./AuditColors";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import { usePrompt } from "../../globalStore/useGlobalStore";

const PlanAudits = observer(() => {
  const prompt = usePrompt();

  const ls = useLocalStore(() => ({
    from: moment().startOf("year"),
    to: moment().endOf("year"),

    audits: [],
    filterAudits: [],
    showUnplannedAudits: true,
    pred: undefined, // {predId, predName, level}
    preds: undefined,
    auditTypes: [],
    showHierarchy: false,

    approveAuditApi: apiWorker(ekasut.audit.postApproveAudit),
    createAuditApi: apiWorker(ekasut.audit.postCreateAudit),
    auditTypesApi: apiWorker(ekasut.audit.getNsiTypes),
    predsApi: apiWorker(ekasut.audit.getNsiPreds),
    auditsApi: apiWorker(ekasut.audit.getAuditList),
    removeAuditApi: apiWorker(ekasut.audit.deleteAudit),

    dayView: {
      open: false,
      date: undefined,
      audits: [],
      selectedAudit: undefined,
      selectedAuditIndex: undefined,
    },
    addView: { open: false, date: undefined },
  }));

  const updateFilterAudits = () => {
    const prefFilter = (audit) => {
      // If pred isn't selected than include all pred
      let result;
      if (ls.pred) {
        if (ls.showHierarchy && ls.pred.level === 1 && ls.preds) {
          // find selected road with it children
          const selectedRoad = ls.preds.childrens.find(
            (ch) => ch.predId === ls.pred.predId
          );
          // test if audit presented in that road
          result =
            ls.pred.predId === audit.predId ||
            selectedRoad.childrens.some((r) => r.predId === audit.predId);
        } else {
          result = ls.pred.predId === audit.predId;
        }
      } else {
        result = true;
      }

      return result;
    };

    ls.filterAudits = ls.audits.filter((audit) => {
      const foundAuditType = ls.auditTypes.find(
        (auditType) => audit.typeId === auditType.code
      );
      return (
        (ls.showUnplannedAudits || audit.isPlan) &&
        foundAuditType &&
        foundAuditType.active &&
        prefFilter(audit)
      );
    });
  };

  const updateCalendar = () => {
    ls.auditsApi
      .fetch({ fromDate: ls.from, toDate: ls.to })
      .then((data) => {
        ls.audits = data.map((item) => ({
          day: moment(item.auditDate),
          fill: getAuditColor(item.typeId),
          ...item,
        }));
        updateFilterAudits();
        if (ls.dayView.open) {
          ls.dayView.selectedAudit = undefined;
          ls.dayView.selectedAuditIndex = undefined;
          ls.dayView.audits = ls.filterAudits.filter((audit) =>
            audit.day.isSame(ls.dayView.date, "day")
          );
        }
      })
      .catch(catchNetworkErrors);
  };

  useDidFirstRender(() => {
    ls.auditTypesApi
      .fetch()
      .then((data) => {
        ls.auditTypes = data.map((item) => ({
          name: item.name,
          code: item.code,
          color: getAuditColor(item.code),
          active: true,
        }));
        updateFilterAudits();
      })
      .catch(catchNetworkErrors);
    updateCalendar();
    ls.predsApi
      .fetch()
      .then((data) => {
        ls.preds = data;
      })
      .catch(catchNetworkErrors);
  });

  return (
    <>
      <DayView
        open={ls.dayView.open}
        date={ls.dayView.date}
        audits={ls.dayView.audits}
        selectedAuditIndex={ls.dayView.selectedAuditIndex}
        auditOnClick={(audit, index) => {
          ls.dayView.selectedAudit = audit;
          ls.dayView.selectedAuditIndex = index;
        }}
        auditTypes={ls.auditTypes}
        onAdd={() => {
          ls.addView = { open: true, date: ls.dayView.date };
        }}
        onRemove={(audit) => {
          prompt.show("Удаление аудита", "", { loading: true });
          ls.removeAuditApi
            .fetch({ auditId: audit.auditId })
            .then(() => {
              prompt.close();
              updateCalendar();
            })
            .catch((err) => {
              prompt.close();
              return catchNetworkErrors(err);
            });
        }}
        onClose={() => {
          ls.dayView.open = false;
        }}
      />
      <AddAuditView
        open={ls.addView.open}
        date={ls.addView.date}
        auditTypes={ls.auditTypes}
        auditTypesLoading={ls.auditTypesApi.loading}
        preds={ls.preds}
        predsLoading={ls.predsApi.loading}
        onSave={(data) => {
          prompt.show("Создание плана аудита...", "", { loading: true });
          ls.createAuditApi
            .fetch({
              auditInfo: {
                isPlan: data.isPlan,
                typeId: data.auditType.code,
                auditDate: ls.addView.date,
                predId: data.pred.predId,
                managerFio: data.fio,
                processes: data.processIds,
              },
            })
            .then(() => {
              ls.addView.open = false;
              prompt.show(
                "Сохранено",
                "Аудит успешно сохранен в график проведения аудитов",
                { ok: true }
              );
              updateCalendar();
            })
            .catch((error) => {
              prompt.close();
              return catchNetworkErrors(error);
            });
        }}
        onClose={() => {
          ls.addView.open = false;
        }}
      />
      <div className="calendar-container">
        <AreaHeader content="График проведения аудитов" />
        <Segment className="widget">
          <CalendarForYear
            year={ls.from}
            dayOnClick={(day, options) => {
              ls.dayView = {
                open: true,
                date: day,
                audits: options,
                selectedAudit: undefined,
                selectedAuditIndex: undefined,
              };
            }}
            daysOptions={ls.filterAudits}
          />
        </Segment>
      </div>
      <div className="controls">
        <div className="filter-container">
          <AreaHeader content="Фильтры" />
          <PredDropdown
            label="Предприятие"
            preds={ls.preds}
            loading={ls.predsApi.loading}
            onChange={(pred) => {
              ls.pred = pred;
              updateFilterAudits();
            }}
          />
          <Checkbox
            checked={ls.showHierarchy}
            className="hierarchy"
            label="Показать по иерархии"
            onChange={() => {
              ls.showHierarchy = !ls.showHierarchy;
              updateFilterAudits();
            }}
          />
          {ls.auditTypes.length > 0 && (
            <FieldHeader content="Категории аудита" />
          )}
          <FilterAuditTypes
            auditTypes={ls.auditTypes}
            onChange={(auditType) => {
              auditType.active = !auditType.active;
              updateFilterAudits();
            }}
          />
          <Checkbox
            label="Показывать внеплановые аудиты"
            checked={ls.showUnplannedAudits}
            onChange={() => {
              ls.showUnplannedAudits = !ls.showUnplannedAudits;
              updateFilterAudits();
            }}
          />
        </div>

        <div className="control-buttons">
          {/* <Button content="Экспорт в PDF" className={cn("export-pdf", "button-link")}/> */}

          <Button.Group>
            <Button
              content="Утвердить план"
              onClick={() => {
                const approvePromises = ls.filterAudits.map((audit) =>
                  ls.approveAuditApi.fetch({
                    auditId: audit.auditId,
                    isApproved: true,
                  })
                );
                prompt.show("Утверждение аудитов", "", { loading: true });
                Promise.all(approvePromises)
                  .then(() => {
                    prompt.show("Аудиты утверждены", "", { ok: true });
                  })
                  .catch((err) => {
                    prompt.close();
                    return catchNetworkErrors(err);
                  });
              }}
            />
            <Dropdown
              className="button icon dropdown-underline"
              floating
              options={[
                {
                  key: "edit",
                  text: "Снять утверждение с плана",
                  value: "edit",
                  onClick: () => {
                    const approvePromises = ls.filterAudits.map((audit) =>
                      ls.approveAuditApi.fetch({
                        auditId: audit.auditId,
                        isApproved: false,
                      })
                    );
                    prompt.show("Снятие утверждений с аудитов", "", {
                      loading: true,
                    });
                    Promise.all(approvePromises)
                      .then(() => {
                        prompt.show("С аудитов сняты утверждения", "", {
                          ok: true,
                        });
                      })
                      .catch((err) => {
                        prompt.close();
                        return catchNetworkErrors(err);
                      });
                  },
                },
              ]}
              trigger={<></>}
            />
          </Button.Group>
        </div>
      </div>
    </>
  );
});

export default PlanAudits;
