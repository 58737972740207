import React, { PureComponent } from "react";
import axios from "axios";
import auth from "../../helpers/auth";

import {
  Button,
  Dimmer,
  Dropdown,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react";

import * as moment from "moment";

moment.locale("ru");

class LocomotivesInCategories extends PureComponent {
  state = {
    params: {
      requestPeriod: null,
    },
    fromDate: "",
    toDate: "",
  };

  constructor(props) {
    super(props);
    //this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.submitFilters = this.submitFilters.bind(this);
    // this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    let me = this;

    // open filters
    me.props.toggleVisibilityFilters();

    axios
      .get(`${window.dbsyncUrl}/api/service/checkaccess`)
      .then(function (response) {
        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/request-period`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }

            let result = [];

            Object.keys(response.data).forEach(function eachKey(key) {
              result.push({
                value: key,
                key: key,
                text: response.data[key],
              });
            });

            let { params } = me.state;

            me.setState({
              requestPeriod: result,
              params: { ...params, requestPeriod: result[0].key },
            });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
          });

        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/traction-type`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }

            let result = [];

            Object.keys(response.data).forEach(function eachKey(key) {
              result.push({
                value: key,
                key: key,
                text: response.data[key],
              });
            });

            let { params } = me.state;

            me.setState({
              tractionType: result,
              params: { ...params, tractionType: result[0].key },
            });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
            // console.log('Error :-S', err);
          });

        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/service-type`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }

            let result = [];

            Object.keys(response.data).forEach(function eachKey(key) {
              result.push({
                value: key,
                key: key,
                text: response.data[key],
              });
            });

            me.setState({ serviceType: result });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
            // console.log('Error :-S', err);
          });

        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/park-category`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }
            let result = [];

            Object.keys(response.data).forEach(function eachKey(key) {
              result.push({
                value: key,
                key: key,
                text: response.data[key],
              });
            });

            me.setState({ parkCategory: result });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
            // console.log('Error :-S', err);
          });

        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/roads`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }

            me.setState({
              roads: response.data
                .sort((a, b) => a.code - b.code)
                .map((element) => ({
                  value: element.code,
                  key: element.code,
                  text: element.name,
                })),
            });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
            // console.log('Error :-S', err);
          });

        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/series`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }

            me.setState({
              series: response.data.map((element) => ({
                value: element.code,
                key: element.code,
                text: element.name,
              })),
            });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
            // console.log('Error :-S', err);
          });

        axios
          .get(`${window.dbsyncUrl}/api/reports/dictionary/sosts`)
          .then(function (response) {
            if (response.status !== 200) {
              return;
            }

            me.setState({
              sosts: response.data.map((element) => ({
                value: element.code,
                key: element.code,
                text: element.name,
              })),
            });
          })
          .catch(function (err) {
            if (
              err.response.data["$type"].includes("UnauthorizedAccessException")
            ) {
              auth.logout();
            }
            // console.log('Error :-S', err);
          });
      })
      .catch(function (err) {
        // only if error body exists:
        if (
          err.response &&
          err.response.data &&
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }

        // if ERR_CERT_AUTHORITY_INVALID
        // FIXME: (if dbsync dead we are in mess)
        if (err.message === "Network Error") {
          const current = document.location.href;

          window.location = `${window.dbsyncUrl}/api/service/checkaccess?fromUrl=${current}`;
        }
      });
  }

  getDetails(cell) {
    const me = this;
    const {
      requestPeriod,
      tractionType,
      serviceType,
      parkCategory,
      sosts,
      fromDate,
      toDate,
    } = this.state.submittedFilters;
    let { code, serCode } = cell;

    let params = {
      id: code,
      series: serCode,
      requestPeriod,
      tractionType,
      serviceType,
      parkCategory,
      sosts,
      fromDate,
      toDate,
    };

    if (code === "sumBySer") {
      delete params.id;
    }

    let queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined)
      .map((key) => key + "=" + params[key])
      .join("&");

    this.props.hideSidebars();
    me.setState({ isLoading: true });

    axios
      .get(
        `${window.dbsyncUrl}/api/reports/loc-count-by-dislocation/detail?${queryString}`
      )
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({ detailsInfo: response.data, isLoading: false });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        me.setState({
          isLoading: false,
          errorOccured: true,
          errorInfo: err.response,
        });
      });
  }

  dtProcess = (dt) =>
    moment(dt, "DD-MM-YYYY HH:mm").format("YYYY-MM-DDTHH:mm:ss");

  submitFilters() {
    const me = this;

    const { params, fromDate, toDate } = this.state;
    let preprocessed = null;

    if (
      (params.series === undefined || params.series == null) &&
      params.tractionType === "*"
    ) {
      me.setState({
        errorOccured: true,
        errorInfo: {
          status: "",
          data: { message: "Укажите конкретный вид тяги или серию локомотива" },
        },
      });
      return;
    }

    switch (params.requestPeriod) {
      case "1":
        preprocessed = params;
        break;
      case "*":
        preprocessed = { ...params, fromDate: me.dtProcess(fromDate) };
        break;
      case "2":
        preprocessed = {
          ...params,
          fromDate: me.dtProcess(fromDate),
          toDate: me.dtProcess(toDate),
        };
        break;
      default:
        break;
    }

    let queryString = Object.keys(preprocessed)
      .map((key) => key + "=" + preprocessed[key])
      .join("&");

    this.props.hideSidebars();
    me.setState({ isLoading: true });

    axios
      .get(
        `${window.dbsyncUrl}/api/reports/loc-count-by-dislocation?${queryString}`
      )
      .then(function (response) {
        if (response.status !== 200) {
          me.setState({
            isLoading: false,
            locsCountInfo: {
              title: [[{ code: "0", name: "Инфо" }]],
              body: [[{ code: "0", name: "Нет данных" }]],
            },
          });
          return;
        }

        me.setState({
          locsCountInfo: response.data,
          activePage: 1,
          isLoading: false,
          submittedFilters: preprocessed,
        });
      })
      .catch(function (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        me.setState({
          isLoading: false,
          errorOccured: true,
          errorInfo: err.response,
        });
      });
  }

  handleClose = () => this.setState({ modalOpen: false });
  closeErrorModal = () => this.setState({ errorOccured: false });
  handleDetailsClose = () => this.setState({ detailsInfo: null });
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  getDictionaryValue(dictionary, code) {
    // console.log(typeof code)
    let result = dictionary
      .filter((obj) => {
        return typeof code === "string"
          ? obj.value === code
          : code.includes(obj.value);
      })
      .map((x) => x.text)
      .join(", ");

    return result;
  }

  getReadableFilters() {
    const me = this;
    const {
      submittedFilters,
      requestPeriod,
      tractionType,
      serviceType,
      parkCategory,
      roads,
      series,
      sosts,
    } = this.state;

    return Object.keys(submittedFilters)
      .map(function (key, index) {
        let value = "";
        switch (key) {
          case "requestPeriod":
            value = `Период: ${me.getDictionaryValue(
              requestPeriod,
              submittedFilters[key]
            )}`;
            break;
          case "toDate":
            value = `До: ${moment(submittedFilters[key]).format(
              "YY.MM.DD HH:mm"
            )}`;
            break;
          case "fromDate":
            value = `От: ${moment(submittedFilters[key]).format(
              "YY.MM.DD HH:mm"
            )}`;
            break;
          case "tractionType":
            value = `Вид тяги: ${me.getDictionaryValue(
              tractionType,
              submittedFilters[key]
            )}`;
            break;
          case "serviceType":
            value = `Род службы: ${me.getDictionaryValue(
              serviceType,
              submittedFilters[key]
            )}`;
            break;
          case "parkCategory":
            value = `Категория парка: ${me.getDictionaryValue(
              parkCategory,
              submittedFilters[key]
            )}`;
            break;
          case "roadCode":
            value = `Дорога: ${me.getDictionaryValue(
              roads,
              submittedFilters[key]
            )}`;
            break;
          case "series":
            value = `Серия: ${me.getDictionaryValue(
              series,
              submittedFilters[key]
            )}`;
            break;
          case "sosts":
            value = `Состояние: ${me.getDictionaryValue(
              sosts,
              submittedFilters[key]
            )}`;
            break;
          default:
            value = "";
            break;
        }
        // return submittedFilters[key]
        return value;
      })
      .join("; ");
  }

  render() {
    const { hideSidebars } = this.props;
    const {
      requestPeriod,
      tractionType,
      serviceType,
      parkCategory,
      roads,
      series,
      sosts,
      locsCountInfo,
      params,
      detailsInfo,
      submittedFilters,
      errorInfo,
    } = this.state;
    let header = [];
    let cellsCodesOrder = [];
    let body = [];

    if (locsCountInfo) {
      locsCountInfo.title.forEach((item, index) => {
        header.push(
          <Table.HeaderCell className="center aligned" key={index}>
            {item.name}
          </Table.HeaderCell>
        );

        cellsCodesOrder.push(item.code);
      });

      locsCountInfo.body.forEach((item, index) => {
        body.push(
          <Table.Row key={index}>
            {cellsCodesOrder.map((el, i) => {
              if (i === 0) {
                return <Table.Cell key={i}>{item[0].name}</Table.Cell>;
              } else {
                let result = item.find((cell) => cell.code === el);
                return (
                  <Table.Cell
                    key={i}
                    className={result ? "clickable center aligned" : null}
                    onClick={() => (result ? this.getDetails(result) : null)}
                  >
                    {result ? parseInt(result.name, 10) : null}
                  </Table.Cell>
                );
              }
            })}
          </Table.Row>
        );
      });
    }

    return (
      <div onClick={hideSidebars}>
        {/*<Sidebar as={Menu} animation='overlay' width='wide' direction='right'
                         visible={this.props.visibleFilters} vertical inverted className="filters-sidebar">*/}
        <Form>
          <Form.Field>
            <label>Период запроса</label>
            <Dropdown
              placeholder="Период запроса"
              fluid
              search
              selection
              options={requestPeriod}
              value={params.requestPeriod}
              onChange={(e, { value }) => {
                this.setState({ params: { ...params, requestPeriod: value } });
              }}
            />
          </Form.Field>
          {params.requestPeriod !== "1" ? (
            <Form.Field>
              <label>Начало запроса</label>
              <DateTimeInput
                name="fromDate"
                placeholder="Дата Время"
                value={this.state.fromDate}
                iconPosition="left"
                onChange={this.handleChange}
              />
            </Form.Field>
          ) : null}
          {params.requestPeriod !== "1" && params.requestPeriod !== "*" ? (
            <Form.Field>
              <label>Окончание запроса</label>
              <DateTimeInput
                name="toDate"
                placeholder="Дата Время"
                value={this.state.toDate}
                iconPosition="left"
                onChange={this.handleChange}
              />
            </Form.Field>
          ) : null}

          <Form.Field>
            <label>Вид тяги</label>
            <Dropdown
              placeholder="Вид тяги"
              fluid
              search
              selection
              options={tractionType}
              value={params.tractionType}
              onChange={(e, { value }) => {
                let additional = {};
                if (value === "1" || value === "2") {
                  additional.series = [];
                }

                this.setState({
                  params: { ...params, ...additional, tractionType: value },
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Род службы</label>
            <Dropdown
              placeholder="Род службы"
              fluid
              multiple
              search
              selection
              options={serviceType}
              onChange={(e, { value }) => {
                this.setState({ params: { ...params, serviceType: value } });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Категория парка</label>
            <Dropdown
              placeholder="Категория парка"
              fluid
              multiple
              search
              selection
              options={parkCategory}
              onChange={(e, { value }) => {
                this.setState({ params: { ...params, parkCategory: value } });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Дорога</label>
            <Dropdown
              placeholder="Дорога"
              fluid
              multiple
              search
              selection
              options={roads}
              onChange={(e, { value }) => {
                this.setState({ params: { ...params, roadCode: value } });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Серия</label>
            <Dropdown
              placeholder="Серия"
              fluid
              multiple
              search
              selection
              options={series}
              value={params.series}
              disabled={
                params.tractionType === "1" || params.tractionType === "2"
              }
              onChange={(e, { value }) => {
                this.setState({ params: { ...params, series: value } });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Состояние</label>
            <Dropdown
              placeholder="Состояние"
              fluid
              multiple
              search
              selection
              options={sosts}
              onChange={(e, { value }) => {
                this.setState({ params: { ...params, sosts: value } });
              }}
            />
          </Form.Field>
          <Button type="submit" onClick={this.submitFilters}>
            Применить
          </Button>
        </Form>
        <Segment
          basic
          className={`report-container ${
            this.state.isLoading ? "loading-report" : ""
          }`}
        >
          {this.state.isLoading ? (
            <Dimmer active inverted>
              <Loader inverted size="massive" content="Загрузка" />
            </Dimmer>
          ) : (
            <Table celled>
              {submittedFilters ? (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={header.length}>
                      {this.getReadableFilters()}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              ) : null}
              <Table.Header>
                <Table.Row>{header}</Table.Row>
              </Table.Header>

              <Table.Body>{body}</Table.Body>
            </Table>
          )}

          {errorInfo ? (
            <Modal
              open={this.state.errorOccured}
              closeOnDimmerClick={false}
              basic
              size="small"
            >
              <Header
                icon="warning sign"
                content={
                  errorInfo.status ? `Ошибка ${errorInfo.status}` : "Внимание!"
                }
              />
              <Modal.Content>
                {errorInfo && errorInfo.data ? errorInfo.data.message : null}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.closeErrorModal} inverted>
                  <Icon name="checkmark" /> Закрыть
                </Button>
              </Modal.Actions>
            </Modal>
          ) : null}
        </Segment>

        {detailsInfo ? (
          <Modal
            open={detailsInfo !== null && detailsInfo !== undefined}
            className=""
            closeIcon
            size="large"
            onClose={this.handleDetailsClose}
          >
            <Modal.Content>
              <Modal.Description>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Серия</Table.HeaderCell>
                      <Table.HeaderCell>Номер</Table.HeaderCell>
                      <Table.HeaderCell>Депо приписка</Table.HeaderCell>
                      <Table.HeaderCell>Состояние</Table.HeaderCell>
                      <Table.HeaderCell>
                        {submittedFilters.requestPeriod === "2"
                          ? "Кол-во локомотиво-часов"
                          : "Дата состояния"}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {detailsInfo.map((row) => (
                      <Table.Row>
                        <Table.Cell>{row[1]}</Table.Cell>
                        <Table.Cell>{row[2]}</Table.Cell>
                        <Table.Cell>{row[6]}</Table.Cell>
                        <Table.Cell>{row[8]}</Table.Cell>
                        <Table.Cell>{row[9]}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        ) : null}
      </div>
    );
  }
}

LocomotivesInCategories.defaultProps = {
  hideSidebars: () => null,
  toggleVisibilityFilters: () => null,
};

export default LocomotivesInCategories;
