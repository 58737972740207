import React, {useState} from 'react'
import {Button, Form} from "semantic-ui-react";
import DropdownField from "../fields/DropdownListField";
import {eventTypes} from "../EventTypes";
import {formOptions} from "../../../helpers/utils";
import TextField from "../fields/TextField";
import DatePickerField from "../fields/DatePickerField";
import DelayedSearchField from "../fields/DelayedSearchField";
import MultipleSelectList from "../fields/MultipleSelectList";
import CommentField from "../fields/CommentField";
import {dateFormats} from "../../../Dictionaries";
import styles from '../index.module.scss'

export default function ViewResultForm1({employee, eventType, formulars, onFormularDelete}) {

    const [selFormular, setSelectedFormular] = useState(null)
    return (
        <Form>
            <DropdownField
                label='Тип мероприятия'
                editable={false}
                list={eventTypes}
                defaultValue={eventTypes.findIndex(type => type.id === eventType.id)}
            />
            <DropdownField
                label='Сотрудник'
                editable={false}
                list={[{text: employee.fio}]}
                defaultValue={0}
            />
            <DropdownField
                style={{border: '1px solid #96c8da'}}
                label='Формуляр'
                editable={true}
                placeholder='Выберите формуляр'
                list={formulars}
                options={formOptions(formulars.map(item => item.beginCheck.format(dateFormats.upToMinute)))}
                onChange={(value) => {
                    setSelectedFormular(value)
                }}
            />
            {selFormular !== null && <>
                <TextField
                    editable={false}
                    label='Номер поезда'
                    value={selFormular.trainNum}
                />
                <DatePickerField
                    label='Начало работы'
                    editable={false}
                    value={selFormular.beginCheck}
                />
                <DatePickerField
                    label='Окончание работы'
                    editable={false}
                    value={selFormular.endCheck}
                />
                <DelayedSearchField
                    label='Станция начала работы'
                    editable={false}
                    defValue={{text: selFormular.stBeginStr}}
                />
                <DelayedSearchField
                    label='Станция окончания работы'
                    editable={false}
                    defValue={{text: selFormular.stEndStr}}
                />
                {/*<CheckboxField
                    editable={false}
                    label='Признак изъятия талона'
                    value={false}
                />*/}
                <DatePickerField
                    label='Срок действия права выезда на обслуживаемые участки'
                    editable={false}
                    value={selFormular.dateZakl}
                />
                <MultipleSelectList
                    label='Цели (основные)'
                    editable={false}
                    hideBoxes={true}
                    list={selFormular.targets}
                    values={selFormular.targets.map((item, index) => index)}
                />
                <CommentField
                    label='Цели (дополнительные)'
                    editable={false}
                    value={selFormular.targetsStr}
                />
                <MultipleSelectList
                    label='Замечания (основные)'
                    editable={false}
                    hideBoxes={true}
                    list={selFormular.zamech}
                    values={selFormular.zamech.map((item, index) => index)}
                />
                <CommentField
                    label='Замечания (дополнительные)'
                    editable={false}
                    value={selFormular.zamechStr}
                />
                <MultipleSelectList
                    label='Рекомендации (основные)'
                    className={styles.boxShadowLabelNone}
                    hideBoxes={true}
                    editable={false}
                    list={selFormular.mery}
                    values={selFormular.mery.map((item, index) => index)}
                />
                <CommentField
                    label='Рекомендации (дополнительные)'
                    editable={false}
                    value={selFormular.meryStr}
                />
                <Button
                    content='Удалить выбранный формуляр'
                    className={styles.bottomButton}
                    basic color='red'
                    onClick={() => {
                        onFormularDelete(selFormular)
                    }}
                />
            </>}
        </Form>
    )
}