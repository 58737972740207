import React, { useEffect, useState } from "react";
import locomotivesConservationStore from "../locomotivesConservationStore";
import { observer } from "mobx-react-lite";
import { getOrders } from "../locomotivesConservationUtils";
import styles from "./LocomotivesOnConservationTable.module.scss";
import { Table } from "antd";
import ekasut from "../../../api/ekasut";
import { Button } from "semantic-ui-react";
import downloadFile from "../../GroupRiskLocomotives/downloadFile";

const LocomotivesOnConservationTable = observer(() => {
  const [monitoringLoco, setMonitoringLoco] = useState([]);

  const getMonitoring = async () => {
    try {
      const monitoring = await ekasut.locomotivesConservation.getMonitoring();
      setMonitoringLoco(monitoring.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "Депо приписки",
      dataIndex: "depoPrep",
      sorter: (a, b) => a.depoPrep - b.depoPrep,
    },
    {
      title: "Серия",
      dataIndex: "series",
      sorter: (a, b) => a.series - b.series,
    },
    {
      title: "Номер",
      dataIndex: "number",
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: "Секция",
      dataIndex: "section",
      sorter: (a, b) => a.section - b.section,
    },
    {
      title: "Плановая постановка на ремонт",
      dataIndex: "sheduleRepair",
      sorter: (a, b) => a.sheduleRepair - b.sheduleRepair,
    },
    {
      title: "Фактическая постановка на ремонт",
      dataIndex: "factOfRepair",
      sorter: (a, b) => a.factOfRepair - b.factOfRepair,
    },
    {
      title: "Плановая постановка на консервацию",
      dataIndex: "planToConservation",
      sorter: (a, b) => a.planToConservation - b.planToConservation,
    },
    {
      title: "Фактическая постановка на консервацию",
      dataIndex: "factOfConservation",
      sorter: (a, b) => a.factOfConservation - b.factOfConservation,
    },
    {
      title: "Текущее состояние",
      dataIndex: "currentState",
      sorter: (a, b) => a.currentState - b.currentState,
    },
    {
      title: "📁",
      dataIndex: "file",
    },
  ];

  const data = monitoringLoco.map((e, i) => {
    return {
      key: i,
      depoPrep: e.predName,
      series: e.locSeriesName,
      number: e.locNumber,
      section: e.locSection,
      sheduleRepair: e.planRepDate,
      factOfRepair: e.factRepDate,
      planToConservation: e.planConservEndDate,
      factOfConservation: e.conservFactDate,
      currentState: e.stateName,
      file: (
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => downloadFile(e.orderFile.fileId, "Док")}
        >
          Просмотреть файл
        </Button>
      ),
    };
  });

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  useEffect(() => {
    getMonitoring();
  }, []);

  return <Table columns={columns} dataSource={data} onChange={onChange} />;
});

export default LocomotivesOnConservationTable;
