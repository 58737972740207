import React from 'react'
import { Divider, Icon } from 'semantic-ui-react'
import './CollabsibleWrap.scss'

export default function CollapsibleWrap({children, title, isCollapse, onClick}) {
	return (
		<>
			<div className='collabsible-wrap'>
				<Divider className={isCollapse ? 'minimized-divider' : ''} horizontal={!isCollapse}>
					{!isCollapse ? title : ''}
				</Divider>
				<Icon className='minimize-button' 
					name={isCollapse ? 'angle down' : 'angle up'} size='big'
					onClick={() => onClick(isCollapse)}
				/>
			</div>

			<div style={{display: isCollapse ? 'none' : 'block'}}>
				{children}
			</div>
		</> 
	)
}
