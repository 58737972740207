import React from 'react'
import {Segment, Grid} from 'semantic-ui-react'
import moment from 'moment'

const OperationalIncidentDetails = ({details}) => {
    return (
        <Segment>
            {details.map((detail, index) => (
                <Info key={index} detail={detail}/>
            ))}
        </Segment>
    )
}
export default OperationalIncidentDetails

const Info = ({detail}) => {

    let labelHelper = (key, label) => {
        return detail[key] ? (
            <Grid.Row>
                <Grid.Column textAlign='left' width={8} style={{fontWeight: 'bold', color: 'black'}}>{label}:</Grid.Column>
                <Grid.Column width={8} style={{color: 'black'}}>{detail[key]}</Grid.Column>
            </Grid.Row>
        ) : null
    }

    let labelHelperDate = (key, label) => {
        return detail[key] ? (
            <Grid.Row>
                <Grid.Column textAlign='left' width={8} style={{fontWeight: 'bold', color: 'black'}}>{label}:</Grid.Column>
                <Grid.Column width={8} style={{color: 'black'}}>{detail[key] ? moment(detail[key]).format("DD.MM.YYYY HH:mm") : null}</Grid.Column>
            </Grid.Row>
        ) : null
    }

    return (
        <Grid columns={2} className='section-base-info'>
            {labelHelper('yavkPlace', 'Наименование места явки')}
            {labelHelper('sdachPlace', 'Наименование места сдачи')}
            {labelHelper('ripWorkTime', 'рабочее время за поездку в минутах')}
            {labelHelper('stOtpr', 'Наименование станции отправления')}
            {labelHelper('stPrib', 'Наименование станции прибытия')}
            {labelHelper('stCtl', 'Наименование контрольной станция')}
            {labelHelper('track', 'Наименование вида тяги')}
            {labelHelperDate('t1', 'Дата и время явки в пункте отправления')}
            {labelHelper('beginTeamWork', 'Начало работы бригады')}
            {labelHelperDate('kpOtpr', 'Время прохождения КП при отправлении')}
            {labelHelperDate('otpr', 'Время отправления')}
            {labelHelperDate('prib', 'Время прибытия в пункт назначения')}
            {labelHelperDate('kpPrib', 'Время прохождения КП по прибытию')}
            {labelHelperDate('t2', 'Дата и время сдачи в пункте назначения')}
            {labelHelper('routeNum', 'Порядковый номер маршрута в поездке')}
            {labelHelper('routeRabForKRest', 'Непрерывное рабочее время маршрута до отдыха в ПО')}
            {labelHelper('restOuter', 'Фактическое время отдыха в пунтке оборота')}
            {labelHelper('trainNum', 'Номер поезда')}
            {labelHelper('tpe', 'Серия и номер локомотива')}
            {labelHelper('tpeAceptPlace', 'Место приёмки')}
            {labelHelper('tpeRejectPlace', 'Место сдачи ТПЕ ')}
            {labelHelper('tpeSectionsCount', 'Количество секций')}
            {labelHelper('t1Tpe', 'Приёмка ТПЕ')}
            {labelHelper('t2Tpe', 'Сдача ТПЕ')}
            {labelHelper('rpdtaId', 'Номер РПДА')}
            {labelHelper('alienDepot', 'Депо явки. При явке в стороннеe депо')}
            {labelHelper('routeRab', 'Рабочее время за маршрут в минутах')}
            {labelHelperDate('overRest', 'Время превышения отдыха (переотдыха) в пункте оборота')}
            {labelHelper('violation', 'Признак наличия нарушения в использовании бригады: 1- да, иначе – 0.')}
            {labelHelper('extremelyEvent', 'Признак работы в период чрезвычайной ситуации. 1 – если поездка пересекается по времени с периодом ЧС')}
            {labelHelper('zakr', 'Тип закрепления бригады')}
        </Grid>

    )
}