import React, { useState } from "react";
import style from "./FooterAddProt.module.scss";
import { Dropdown, Menu } from "semantic-ui-react";
import { Button } from "antd";
import moment from "moment";
import ekasut from "../../../../../api/ekasut";

export const FooterAddProt = ({
  setAddProt,
  addProt,
  sendingMarks,
  sendingAddWorkStatus,
  sendingProtocolNumber,
  protocolNum,
  currentSostName,
  currentDateOp,
  commisarMarks,
  setProtocolNum,
  currentIsDate,
  currentSostKod,
  currentProtDate,
  setCurrentProtDate,
  getSld,
  getSecProtAxwork,
  getSecProtInfo,
  secProtAwinfo,
  sendFileToServer,
  currentKeyForFile,
  setCurrentKeyForFile,
  openFile,
  setOpenFile,
  isRegistrProt
}) => {
  const onClose = () => {
    setAddProt(!addProt);
  };

  const [response, setResponse] = useState(null)

  const postFile = async() => {
    const response = await ekasut.file.postFile(openFile)
    setResponse(response)
    setCurrentKeyForFile(response?.data)
  }

  const onSave = async (actual) => {
    
    const sendFileToServer = async() => {
      
      openFile && postFile()
      
      // console.log(сгкку);
      response ? await ekasut.commisionInspection.postProtocol({
        pRGSection: commisarMarks[0].rgSec,
        pNumProt:
          commisarMarks[0].numProt === null
            ? protocolNum
            : commisarMarks[0].numProt,
        pDateProt: secProtAwinfo.length && secProtAwinfo[0].dateProt
        ? moment(secProtAwinfo[0].dateProt).format("YYYY-MM-DD")
        : moment(currentProtDate).format("YYYY-MM-DD"),
        pKodRepair: currentSostKod,
        pDateRepair: moment(currentDateOp).format("YYYY-MM-DD"),
        pRegistr:
          actual === undefined || actual === 0 ? 0 : 1,
        pRewrite: 0,
        pFileName: response.data,
        pFirmRepair: localStorage.kodFirm,
      }) : 
      await ekasut.commisionInspection.postProtocol({
        pRGSection: commisarMarks[0].rgSec,
        pNumProt:
          commisarMarks[0].numProt === null
            ? protocolNum
            : commisarMarks[0].numProt,
        pDateProt: secProtAwinfo.length && secProtAwinfo[0].dateProt
        ? moment(secProtAwinfo[0].dateProt).format("YYYY-MM-DD")
        : moment(currentProtDate).format("YYYY-MM-DD"),
        pKodRepair: currentSostKod,
        pDateRepair: moment(currentDateOp).format("YYYY-MM-DD"),
        pRegistr:
          actual === undefined || actual === 0 ? 0 : 1,
        pRewrite: 1,
        pFirmRepair: localStorage.kodFirm,
      });
      
      const res = await ekasut.commisionInspection.updSecInfo({
        pRGSec: commisarMarks[0].rgSec,
        pAction: actual === undefined || actual === 0 ? 0 : 1,
        pKodRepair: currentSostKod,
        pDateRepair: moment(currentDateOp).format("YYYY-MM-DD"),
        pIdReason: 0,
        pYearSpis: 0,
        pSignYear: 0,
        // pWhoUpdate:
      });

      getSld();
      getSecProtAxwork();
      getSecProtInfo();
    }
    
    sendFileToServer()
  };

  const marks = commisarMarks.map(e => e.ocenka)
  
  const found = marks.includes(0 || null)

  const postSubscribe = async() => {
    
  }

  return (
    <div className={style.footer}>
      <div className={style.mark}>
        <Button disabled={isRegistrProt == 1 ? true : false} onClick={() => onSave()}>Сохранить</Button>
        <Button disabled={found || isRegistrProt == 1} onClick={() => onSave(1)}>Подписать</Button>
        <Button disabled={isRegistrProt == 1 ? false : true} onClick={() => onSave()}>Запрос на снятие подписи</Button>
        <Button onClick={() => onClose()}>Отменить</Button>
      </div>
    </div>
  );
};
