import React from "react";
import createStore from "./createStore";
import { useLocalStore } from "mobx-react-lite";

export const storeContext = React.createContext(undefined);

export const StoreProvider = ({ children, initOptions }) => {
  const store = useLocalStore(() => createStore(initOptions));
  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};
StoreProvider.defaultProps = {
  initOptions: {},
};

export const useStore = () => React.useContext(storeContext);
