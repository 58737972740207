import React, {useEffect} from 'react';
import AuditStepsWidget from "../AuditStepsWidget";
import {Button, Grid, Header, List, Message, Modal} from "semantic-ui-react";
import './index.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import CreateCriterionModal from "./CreateCriterionModal";
import CreateQuestionModal from "./CreateQuestionModal";
import catchNetworkErrors from "../../../api/catchNetworkErrors";
import ekasut from "../../../api/ekasut";
import {usePrompt} from "../../../globalStore/useGlobalStore";

const FormCheckList = observer(({audit, onClose = () => null, onComplete = () => null, onView, editable, step, viewStep, display}) => {
    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        initialProcesses: null,
        processes: null,
        activeProcess: null,
        process: null,
        activeCriterion: null,
        criterion: null,
        activeQuestion: null,
        createCriterionModal: {open: false},
        createQuestionModal: {open: false},
        wasFirstDisplay: false,
        criterionCount: 0,
        questionCount: 0,
    }))

    const updateQuestions = () => {
        ls.criterionCount = 0
        ls.questionCount = 0
        ls.processes = []
        ls.initialProcesses = []
        ekasut.audit.getQuestions({auditId: audit.auditId})
            .then(({data}) => {
                ls.initialProcesses = data
                ls.processes = data
            })
            .catch(catchNetworkErrors)
    }

    useEffect(() => {
        if (display && !ls.wasFirstDisplay) {
            ls.wasFirstDisplay = true
            updateQuestions()
        }
    }, [audit, display, ls.wasFirstDisplay])

    const selectCriterion = (criterion, index) => {
        ls.activeCriterion = index
        ls.criterion = criterion
        ls.activeQuestion = null
    }

    const removeCriterion = (criterion, index) => {
        ls.process.criterions.splice(index, 1)
        ls.activeQuestion = null
        ls.criterion = null
        ls.activeCriterion = null
    }

    const selectProcess = (process, index) => {
        ls.activeProcess = index
        ls.process = process
        ls.activeCriterion = null
        ls.criterion = null
        ls.activeQuestion = null
    }

    const selectQuestion = (index) => {
        ls.activeQuestion = index
    }

    const removeQuestion = (question, index) => {
        ls.activeQuestion = null
        ls.criterion.questions.splice(index, 1)
    }

    const addCriterionToProcess = (criterion) => {
        ls.createCriterionModal.open = false
        ls.process.criterions.push({
            key: 'created-criterion-' + ls.criterionCount++,
            criterionId: null,
            criterionName: criterion,
            questions: []
        })
    }

    const addQuestionToCriterion = (question) => {
        ls.createQuestionModal.open = false
        ls.criterion.questions.push({
            key: 'created-questions-' + ls.questionCount++,
            questionId: null,
            question
        })
    }

    const saveAndComplete = (complete) => {
        const removeCriterionIds = []
        ls.initialProcesses.forEach((initProcess, processIndex) => {
            const process = ls.processes[processIndex]
            initProcess.criterions.forEach(initCriterion => {
                if (process.criterions.find(criterion => initCriterion.criterionId === criterion.criterionId) === undefined) {
                    removeCriterionIds.push(initCriterion.criterionId)
                }
            })
        })

        const editCriterionsParams = []

        ls.processes.forEach(process => process.criterions.forEach(criterion => {
            editCriterionsParams.push({
                auditId: audit.auditId,
                processId: process.processId,
                data: {
                    criterionId: criterion.criterionId,
                    criterionName: criterion.criterionName,
                    questions: criterion.questions.map(question => ({
                        questionId: question.questionId,
                        question: question.question
                    }))
                }
            })
        }))

        prompt.show("Сохранение критериев и вопросов", "", {loading: true})
        Promise.all(removeCriterionIds.map(id => ekasut.audit.deleteCriterion({criterionId: id, auditId: audit.auditId})))
            .then(() => Promise.all(editCriterionsParams.map(params => ekasut.audit.postCriterion(params))))
            .then(() => {
                if (complete) {
                    onComplete()
                } else {
                    updateQuestions()
                    prompt.show("Мероприятия сохранены", "", {ok: true})
                }
            })
            .catch((err) => {
                prompt.close()
                return catchNetworkErrors(err)
            })


    }

    const checkForm = () => true

    if (!display) {
        return false
    }
    return (
        <AuditStepsWidget
            displayButtons={{cancel: true, exportDoc: false, save: true, end: true}}
            onClose={onClose}
            onCancel={onClose}
            onView={onView}
            step={step}
            viewStep={viewStep}
            className="app audit-control form-check-list"
            onSave={() => {
                saveAndComplete(false)
            }}
            onEnd={() => {
                if (checkForm()) {
                    prompt.show("Вы действительно хотите завершить этап?", "", {yesNo: true, onApply: () => saveAndComplete(true)})
                } else {
                    prompt.show("Завершение этапа не выполнено", "Не все обязательные поля заполнены", {ok: true})
                }
            }}
        >
            <CreateCriterionModal
                open={ls.createCriterionModal.open}
                onClose={() => ls.createCriterionModal.open = false}
                process={ls.process}
                onCreate={(criterion) => addCriterionToProcess(criterion)}
            />
            <CreateQuestionModal
                open={ls.createQuestionModal.open}
                onClose={() => ls.createQuestionModal.open = false}
                process={ls.process}
                criterion={ls.criterion}
                onCreate={(question) => addQuestionToCriterion(question)}
            />
            <Grid columns={5} divided>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Header as="h4" content="Процессы"/>
                        {ls.processes && ls.processes.length > 0 && (
                            <List selection className="list">
                                {ls.processes.map((process, index) => (
                                    <List.Item
                                        key={process.processId}
                                        content={process.processName}
                                        onClick={() => selectProcess(process, index)}
                                        active={ls.activeProcess === index}
                                    />
                                ))}
                            </List>
                        )}
                        {ls.processes && audit.processes.length === 0 && (
                            <Message warning content="Процессы не выбраны"/>
                        )}
                    </Grid.Column>
                    <Grid.Column>
                        <Header as="h4" content="Критерии"/>
                        {ls.process !== null && (<>
                            <List selection className="list">
                                {ls.process.criterions.map((criterion, index) => (
                                    <List.Item
                                        key={criterion.criterionId === null ? criterion.key : criterion.criterionId}
                                        onClick={(e) => e.target.classList.contains("close")
                                            ? removeCriterion(criterion, index)
                                            : selectCriterion(criterion, index)}
                                        active={ls.activeCriterion === index}
                                        className="item-with-button"
                                    >
                                        <Button
                                            content={criterion.criterionName}
                                            basic className="button-basic-without-borders"
                                            icon={editable ? "close" : undefined}
                                            labelPosition={editable ? "right" : undefined}
                                        />
                                    </List.Item>
                                ))}
                            </List>
                            {editable && (
                                <Button
                                    content="Добавить критерий"
                                    basic icon="plus" labelPosition="left" className="button-basic-without-borders"
                                    onClick={() => ls.createCriterionModal.open = true}
                                />
                            )}
                        </>)}
                        {ls.process === null && (
                            <Message content="Выберите процесс"/>
                        )}
                    </Grid.Column>
                    <Grid.Column>
                        <Header as="h4" content="Вопросы"/>
                        {ls.process !== null && ls.criterion !== null && (<>
                            <List selection className="list">
                                {ls.criterion.questions.map((question, index) => (
                                    <List.Item
                                        key={question.questionId === null ? question.key : question.questionId}
                                        active={ls.activeQuestion === index}
                                        onClick={(e) => e.target.classList.contains("close")
                                            ? removeQuestion(question, index)
                                            : selectQuestion(index)
                                        }
                                        className="item-with-button"
                                    >
                                        <Button
                                            content={question.question}
                                            basic className="button-basic-without-borders"
                                            icon={editable ? "close" : undefined}
                                            labelPosition={editable ? "right" : undefined}
                                        />
                                    </List.Item>
                                ))}
                            </List>
                            {editable && (
                                <Button
                                    content="Добавить вопрос"
                                    basic icon="plus" labelPosition="left" className="button-basic-without-borders"
                                    onClick={() => {
                                        ls.createQuestionModal.open = true
                                    }}
                                />
                            )}
                        </>)}
                        {ls.criterion === null && (
                            <Message content="Выберите критерий"/>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </AuditStepsWidget>
    );
})

export default FormCheckList;

