import React, {useEffect, useState} from 'react'
import HalfCircleProgressBar from '../../shared/HalfCircleProgressBar'
import './FleetStats.scss'
import ProgressBarWithPopup from '../../shared/ProgressBarWithPopup';
import locomotiveServices from './LocomotiveServices'
import axios from 'axios'
import ekasut from '../../api/ekasut'
import catchNetworkErrors from "../../api/catchNetworkErrors";

const initTypeStats = {
	ep: 0,
	rp: 0,
	epToRp: 0,
	delta: 0
}

const initStats = {
	freight: initTypeStats,
	passenger: initTypeStats,
	switcher: initTypeStats
}

export function useFleetStats(filter, stats) {
	const [fleetStats, setStats] = useState(stats !== undefined ? stats : initStats)

	useEffect(function loadFleetStats() {
		axios.all([
			ekasut.epRpAnalytics.getRzd(filter),
			ekasut.to38Analytics.getRzd(filter),
			ekasut.to36Analytics.getRzd(filter)
		]).then(results => {
			setStats({
				freight: results[0].data,
				passenger: results[1].data,
				switcher: results[2].data
			})
		}).catch(catchNetworkErrors)

		return () => {
			ekasut.to36Analytics.cancel('passenger stats loading closed')
			ekasut.epRpAnalytics.cancel('eprp stats loading closed')
			ekasut.to38Analytics.cancel('switching stats loading closed')
		};
	}, [filter])

	return [fleetStats]
}

export default function FleetStats({
	stats, serviceOnChange, service
}) {
	const {freight, switcher, passenger} = stats
	const progressColorPicker = (percent, value, maxValue) => {
		let color
		if (value < 1.4154) {
			color = '#21ba45'
		} else if (value < 1.4828) {	
			color = '#fbbd08'
		} else {
			color = '#db2828'
		}
		return color
	}

	const handleChange = (service) => {
		if (serviceOnChange !== undefined) {
			serviceOnChange(service)
		}
	}

	const getTitleClass = (titleService) => {
		return (`progress-title 
			${service === titleService && 'selected-service'} 
			${serviceOnChange === undefined ? '' : 'cursor-pointer'}`)
	}

	return (
		<div className='fleet-stats'>
			<div>
				<h4 style={{borderBottom:'none'}} className={getTitleClass(locomotiveServices.FREIGHT)}
					onClick={() => handleChange(locomotiveServices.FREIGHT)}
				>
					ГРУЗ
				</h4>
			</div>
			{/*<div>*/}
			{/*	<h4 className={getTitleClass(locomotiveServices.PASSENGER)}*/}
			{/*		onClick={() => handleChange(locomotiveServices.PASSENGER)}*/}
			{/*	>*/}
			{/*		ПАСС (ОТКЛ. ОТ ПЛАНА)*/}
			{/*	</h4>*/}
			{/*</div>*/}
			{/*<div>*/}
			{/*	<h4 className={getTitleClass(locomotiveServices.SWITCHING)}*/}
			{/*		onClick={() => handleChange(locomotiveServices.SWITCHING)}*/}
			{/*	>*/}
			{/*		МАНЕВ (ОТКЛ. ОТ ПЛАНА)*/}
			{/*	</h4>*/}
			{/*</div>*/}
			<div className='half-circle-container'>
				<HalfCircleProgressBar
					min={-5000}
					max={5000}
					valueDescr={'ЛОКОМОТИВЫ'}
					value={freight.delta}
				/>
			</div>
			{/*<div className='half-circle-container'>*/}
			{/*	<HalfCircleProgressBar*/}
			{/*		min={-5000}*/}
			{/*		max={5000}*/}
			{/*		valueDescr={'ЛОКОМОТИВЫ'}*/}
			{/*		value={passenger.delta}*/}
			{/*	/>*/}
			{/*</div>*/}
			{/*<div className='half-circle-container'>*/}
			{/*	<HalfCircleProgressBar*/}
			{/*		min={-5000}*/}
			{/*		max={5000}*/}
			{/*		valueDescr={'ЛОКОМОТИВЫ'}*/}
			{/*		value={switcher.delta}*/}
			{/*	/>*/}
			{/*</div>*/}
			<div className='progress-bar-container'>
				<ProgressBarWithPopup 
					value={freight.epToRp}
					maxValue={2.5} 
					popupContent={
						<p>
							ЭП/РП: {freight.epToRp} <br/>
							ЭП: {freight.ep} <br/>
							РП: {freight.rp} <br/>
						</p>
					}
					symbol={''}
					width={156}
					height={18}
					fontSize={16}
					colorPicker={progressColorPicker}
				/>
				<p className='progress-descr'>ЭП/РП</p>
			</div>
			{/*<div className='progress-bar-container'>*/}
			{/*	<ProgressBarWithPopup */}
			{/*		value={passenger.epToRp}*/}
			{/*		maxValue={2.5} */}
			{/*		popupContent={*/}
			{/*			<p>*/}
			{/*				ЭП/РП: {passenger.epToRp} <br/>*/}
			{/*				ЭП: {passenger.ep} <br/>*/}
			{/*				РП: {passenger.rp} <br/>*/}
			{/*			</p>*/}
			{/*		}*/}
			{/*		symbol={''}*/}
			{/*		width={156}*/}
			{/*		height={18}*/}
			{/*		fontSize={16}*/}
			{/*		colorPicker={progressColorPicker}*/}
			{/*	/>*/}
			{/*	<p className='progress-descr'>ЭП/РП</p>*/}
			{/*</div>*/}
			{/*<div className='progress-bar-container'>*/}
			{/*	<ProgressBarWithPopup */}
			{/*		value={switcher.epToRp}*/}
			{/*		maxValue={2.5} */}
			{/*		popupContent={*/}
			{/*			<p>*/}
			{/*				ЭП/РП: {switcher.epToRp} <br/>*/}
			{/*				ЭП: {switcher.ep} <br/>*/}
			{/*				РП: {switcher.rp} <br/>*/}
			{/*			</p>*/}
			{/*		}*/}
			{/*		symbol={''}*/}
			{/*		width={156}*/}
			{/*		height={18}*/}
			{/*		fontSize={16}*/}
			{/*		colorPicker={progressColorPicker}*/}
			{/*	/>*/}
			{/*	<p className='progress-descr'>ЭП/РП</p>*/}
			{/*</div>*/}
		</div>
	)
}
FleetStats.defaultProps = {
	stats: initStats
}