import React, {useEffect} from 'react';
import TopHeader from "../../../notUsed/PlanWorkMobX/components/TopHeader";
import Content from "../../../notUsed/PlanWorkMobX/components/Content";
import ViewLocRemarks from "../components/ViewLocRemarks";
import {useStore} from "../StoreProvider";
import {parseSelectedForRead} from "../createStore";
import {useObserver} from "mobx-react-lite";
import catchNetworkErrors from "../../../../api/catchNetworkErrors";

// I'm not make whole component as an observer despite the fact that i'm read store values in non-observable part
const ViewLocRemarksForm = () => {
    // non-observable part
    const store = useStore()

    useEffect(() => {
        // these store values are predefined and doesn't change in compontent's lifetime
        store.fetchRemarks(store.actionId, store.actionRd)
            .then(() => {
                // Asynchronously read store values on demand (not as reaction)
                store.selected = parseSelectedForRead(store.selectedData, store.remarks)
            }).catch(catchNetworkErrors)
    }, [store])

    // only this part is observed
    return useObserver(() => <>
        <TopHeader header="Просмотр замечаний к локомотиву"/>
        <Content loading={!store.remarksLoaded} showContent={store.remarksLoaded}>
            <ViewLocRemarks
                remarks={store.remarks}
                selected={Object.values(store.selected)}
            />
        </Content>
    </>)
}



export default ViewLocRemarksForm;