import React, {useEffect, useRef, useState} from 'react';
import DropdownField from "../fields/DropdownField";
import {Button, Header, Message, Modal, Segment} from "semantic-ui-react";
import './MovementHistory.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import Widget from "../Widget";
import {CircleMarker, Map, Popup} from "react-leaflet";
import LayerSelector, {layerNames} from "../LayerSelector";
import TextField from "../fields/TextField";
import ekasut from "../../api/ekasut";
import moment from 'moment';
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {toJS} from "mobx";
import {dateFormats} from "../../Dictionaries";
import Control from 'react-leaflet-control'
import useMapRef from "../../hooks/useMapRef";
import L from 'leaflet'

const MovementHistory = observer(({detail}) => {
    const ls = useLocalStore(() => ({
        period: null,
        movementMap: {open: false, period: null}
    }))
    return (
        <div className="app movement-history">
            <DropdownField
                label="За период" selection
                options={historyPeriods} clearable
                className="for-period"
                onChange={(e, {value}) => {
                    if (historyPeriods[value]) {
                        ls.period = historyPeriods[value].period
                    } else {
                        ls.period = null
                    }
                }}
            />
            <Button
                content="Показать на карте"
                disabled={ls.period === null}
                onClick={() => {
                    ls.movementMap = {open: true, period: ls.period}
                }}
            />
            <MovementMapModal
                open={ls.movementMap.open}
                period={ls.period}
                onClose={() => ls.movementMap.open = false}
                detail={detail}
            />
        </div>
    );
})

const MovementMapModal = observer(({open, onClose, period, detail}) => {
    const mapRef = useRef()

    const ls = useLocalStore(() => ({
        // [] - received 0 coordinates, null - not received coords data yet
        coords: null,
        from: null,
        to: null,
    }))

    useEffect(() => {
        if (open) {
            ls.coords = null
            const to = moment()
            const from = moment(to).subtract(...period)
            const diffDays = moment().diff(from, "days")
            ls.from = from
            ls.to = to
            ekasut.map.getPathHistory({daysBack: diffDays, prsLoc: detail.prsLoc, serLoc: detail.serLoc, znsLoc: detail.znsLoc})
                .then(({data}) => {
                    if (data !== "") {
                        ls.coords = data.history
                        const bounds = L.featureGroup(data.history.map(({lat, lon}) => L.marker([lat, lon]))).getBounds()
                        mapRef.current.leafletElement.fitBounds(bounds, {maxZoom: 6})
                    } else {
                        ls.coords = []
                    }
                })
                .catch(catchNetworkErrors)
        }
    }, [open])


    return (
        <Modal
            onClose={onClose} open={open}
            className="app movement-map-modal"
            size="large"

        >
            <Widget onClose={onClose} closeIcon header="История перемещений">
                <div className="group-info">
                    <TextField label="Состав" content={detail.sostavn}/>
                    <TextField
                        label="Период"
                        content={ls.from && ls.to ? `${ls.from.format(dateFormats.upToDay)} -  ${ls.to.format(dateFormats.upToDay)}` : ""}

                    />
                </div>
                <Map
                    className="movement-map"
                    center={[57.778288, 79.126642]}
                    zoom={4}
                    minZoom={3}
                    maxZoom={16}
                    ref={ref => mapRef.current = ref}
                >
                    <LayerSelector checkedLayerDef={layerNames.rzd} position="topright"/>
                    {ls.coords !== null && ls.coords.map((coord, index) => (
                        <CircleMarker
                            key={index}
                            center={[coord.lat, coord.lon]}
                            color="#03a648"
                            fillOpacity={0.8}
                            radius={3}
                        >
                            <Popup>{moment(coord.navTime).format(dateFormats.upToMinute)}</Popup>
                        </CircleMarker>
                    ))}
                    {ls.coords !== null && ls.coords.length === 0 && (
                        <Control position="topleft">
                            <Message warning content="Нет записей"/>
                        </Control>
                    )}
                </Map>
            </Widget>
        </Modal>
    )
})

const historyPeriods = [{
    name: "3 суток", period: [3, "days"]
}, {
    name: "Неделя", period: [1, "week"]
}, {
    name: "Месяц", period: [1, "month"]
}, {
    name: "Квартал", period: [3, "months"]
}, {
    name: "Полугодие", period: [6, "months"]
}].map((periods, index) => ({...periods, text: periods.name, value: index, key: index}))

export default MovementHistory;