import React, { useEffect, useMemo } from "react";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Input,
  Label,
  Modal,
  Segment,
} from "semantic-ui-react";
import InputDigits from "../../shared/InputDigits";
import "./OrderWeightLengthModal.scss";
import { DateInput } from "semantic-ui-calendar-react/dist/inputs";
import { observer, useLocalStore } from "mobx-react-lite";
import Widget from "../../shared/Widget";
import moment from "moment";
import { dateFormats } from "../../Dictionaries";
import AttachFileButton from "../../shared/AttachFileButton";

const OrderWeightLengthModal = observer(
  ({ open, details, onClose, onApply }) => {
    const ls = useLocalStore(() => ({
      weight: "",
      length: "",
      regDate: "",
      beginDate: "",
      endDate: "",
      file: null,
      fileAttached: false,
    }));

    useEffect(() => {
      if (open) {
        ls.weight = "";
        ls.length = "";
        ls.regDate = "";
        ls.beginDate = "";
        ls.endDate = "";
        ls.file = null;
        ls.fileAttached = false;
      }
    }, [open]);

    return (
      <Modal
        open={open}
        className="app order-weight-length-modal"
        size="tiny"
        onClose={onClose}
      >
        <Widget
          header="Приказ на изменение норматива"
          closeIcon
          onClose={onClose}
          footer={
            <div className="order-buttons">
              <Button
                content="Применить"
                disabled={
                  ls.weight === "" ||
                  ls.length === "" ||
                  !moment(ls.regDate, dateFormats.upToDay).isValid() ||
                  !moment(ls.beginDate, dateFormats.upToDay).isValid() ||
                  !moment(ls.endDate, dateFormats.upToDay).isValid() ||
                  ls.file === null
                }
                onClick={() => {
                  onApply({
                    weight: ls.weight,
                    length: ls.length,
                    regDate: moment(ls.regDate, dateFormats.upToDay),
                    beginDate: moment(ls.beginDate, dateFormats.upToDay),
                    endDate: moment(ls.endDate, dateFormats.upToDay),
                    file: ls.file,
                  });
                }}
              />
            </div>
          }
        >
          {/* {Object.keys(details).length} */}
          <div className="order-field">
            <label>Дата регистрации приказа</label>
            <DateInput
              closable
              value={ls.regDate}
              dateFormat={dateFormats.upToDay}
              onChange={(e, { value }) => (ls.regDate = value)}
            />
          </div>
          <div className="order-field">
            <label>Дата начала действия приказа</label>
            <DateInput
              closable
              value={ls.beginDate}
              dateFormat={dateFormats.upToDay}
              onChange={(e, { value }) => {
                ls.beginDate = value;
              }}
            />
          </div>
          <div className="order-field">
            <label>Дата окончания действия приказа</label>
            <DateInput
              closable
              value={ls.endDate}
              dateFormat={dateFormats.upToDay}
              onChange={(e, { value }) => (ls.endDate = value)}
            />
          </div>
          <div className="order-field">
            <label>Норма веса</label>
            <InputDigits
              className="allow-length-input"
              value={ls.weight}
              onChange={(e, { value }) => (ls.weight = value)}
            />
          </div>
          <div className="order-field">
            <label>Допустимая длина</label>
            <InputDigits
              className="allow-length-input"
              value={ls.length}
              onChange={(e, { value }) => (ls.length = value)}
            />
          </div>

          <AttachFileButton
            className="attach-order-button"
            file={ls.file}
            onAttached={(e, file) => {
              ls.file = file;
            }}
          />
        </Widget>
      </Modal>
    );
  }
);

export default OrderWeightLengthModal;
