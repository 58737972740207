import {useEffect, useState} from "react";
import ekasut from "../../../../../api/ekasut";


export const useGetOnBoardDiagnostics = ({info}) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)

  const locser = info.serLoc;
  const locnum = info.znsLoc;
  const locsec = info.prsLoc;


  useEffect(() => {

    let ignore = false;
    const getEp = async () => {
      let res;
      try {
        setLoading(true)
        res = await ekasut.ekasutData.getTpeDetailBySection(locser, locnum, locsec);
        if (!ignore) {
          setData(res.data)
          setLoading(false)
        }
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    }

    getEp()

    return () => {
      ignore = true
    }
  }, [locser, locnum, locsec])



  return {
    loading,
    data,
    error
  };
};