import { Menu } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";

const TopMenu = ({ mainMenu }) => {
  console.log(mainMenu);

  const hiddenTurner = (arr) =>
    arr.filter((e) => e.show || e.show == undefined);

  const remadeMenu = [{ label: "Новости", key: "news" }].concat(
    hiddenTurner(mainMenu).map((title) => {
      return {
        label: title.header,
        key: title.header,
        children: hiddenTurner(title.groups).map((group) => {
          return {
            label: group.groupName,
            key: group.groupName,
            children: hiddenTurner(group.views).map((view) => {
              return {
                label: <Link to={view.to ? view.to : ""}>{view.viewName}</Link>,
                key: view.viewName,
              };
            }),
          };
        }),
      };
    })
  );

  console.log(remadeMenu);

  return <Menu mode="horizontal" items={remadeMenu} />;
};

export default TopMenu;
