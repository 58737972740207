import {useEffect, useState} from 'react'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
};

export default function GeoLocation() {
    const [intervalId, setIntervalId] = useState(-1)
    const [sendPutLocation] = useApiMethod(ekasut.persData.putLocation, undefined)

    useEffect(() => {
        const sendGeolocation = () => {
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    sendPutLocation(pos.coords.latitude, pos.coords.longitude)
                },
                (err) => {
                    console.warn(`geolocation service error ${err.code}: ${err.message}`)
                    clearInterval(intervalId)
                },
                options
            )
        }

        sendGeolocation()
        setIntervalId(
            setInterval(() => {
                sendGeolocation()
            }, 30000)
        )
        return () => {
            clearInterval(intervalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}