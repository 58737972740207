import React, {useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, Dimmer, Loader, Pagination, Table} from "semantic-ui-react";
import moment from 'moment'
import styles from './TrafficStandardsTable.module.scss'
import {downloadBlob, formatMoment} from "../../helpers/utils";
import {dateFormats} from "../../Dictionaries";
import ekasut from "../../api/ekasut";
import useFileDownload from "../../hooks/useFileDownload";

const TrafficStandardsTable = React.memo((
    {
        data = null, rowsInPage = 10, onSelectItem, activeIndex, onPageChanged, activePage, loading, onCheckRow, checkedRows
    }
) => {
    const [saveFileToUser, DownloadInput] = useFileDownload()

    const totalCols = 13

    let totalPages
    if (data !== null) {
        if ((data.length % rowsInPage) === 0) {
            totalPages = data.length / rowsInPage
        } else {
            totalPages = Math.ceil(data.length / rowsInPage)
        }
    } else {
        totalPages = 0
    }


    const allowedValue = (item, value, orderValue) => {

        return item.orderId === null ? value : <span><span className={styles.changedByOrder}>{orderValue}</span>{` (${value})`}</span>
    }

    const DataRows = () => {
        const rows = []
        for (let i = (activePage - 1) * rowsInPage; (i < data.length) && (i < ((activePage - 1) * rowsInPage + rowsInPage)); i++) {
            const item = data[i]
            rows.push(
                <Table.Row
                    style={{cursor: 'pointer'}}
                    key={`page=1,row=${i}`}
                    active={i === activeIndex}
                >
                    <Table.Cell
                        onClick={() => {
                            onCheckRow(item, i)
                        }}
                        content={(
                            <Checkbox
                                checked={checkedRows[i] !== undefined}
                            />
                        )}
                    />
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.uolBeginName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.uolEndName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.uolWeight}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.uolLength}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.beginName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.endName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.serName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.tjagaName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{item.vdvigName}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{allowedValue(item, item.weightN, item.orderWeightN)}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{allowedValue(item, item.lengthN, item.orderLengthN)}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)}>{moment(item.dateChanged).format('DD.MM.YYYY')}</Table.Cell>
                    <Table.Cell onClick={() => onSelectItem(i)} content={formatMoment(item.orderDateBegin, dateFormats.upToDay)}/>
                    <Table.Cell onClick={() => onSelectItem(i)} content={formatMoment(item.orderDateEnd, dateFormats.upToDay)}/>
                    <Table.Cell onClick={() => onSelectItem(i)}
                        className="alt-semantic"
                        content={item.orderFileId && (
                            <Button
                                content="Скачать"
                                className="button-link"
                                onClick={() => {
                                    ekasut.file.getFile(item.orderFileId)
                                        .then(({data}) => {
                                            saveFileToUser(data)
                                        })
                                }}
                            />
                        )}
                    />
                </Table.Row>
            )
        }
        return rows
    }

    const BodyContent = () => {
        if ((data === null) || (data.length === 0)) {
            return (
                <Table.Row>
                    <Table.Cell colSpan={totalCols} className={styles.dataIsEmpty}>
                        Нет результатов
                    </Table.Cell>
                </Table.Row>
            )
        }
        return (
            <DataRows/>
        )
    }

    return (
        <div>
            <DownloadInput />
            <Table celled compact selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell rowSpan={2}/>
                        <Table.HeaderCell colSpan={4} content="Перегон"/>
                        <Table.HeaderCell colSpan={2} content="Участок"/>
                        <Table.HeaderCell rowSpan={2} content="Серия"/>
                        <Table.HeaderCell rowSpan={2} content="Тяга"/>
                        <Table.HeaderCell rowSpan={2} content="Вид движения"/>
                        <Table.HeaderCell rowSpan={2} content="Допустимый вес"/>
                        <Table.HeaderCell rowSpan={2} content="Допустимая длина"/>
                        <Table.HeaderCell rowSpan={2} content="Дата изменения"/>
                        <Table.HeaderCell colSpan={3} content="Приказ на изменение норм"/>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell content="Станция начала"/>
                        <Table.HeaderCell content="Станция конца"/>
                        <Table.HeaderCell content="Допустимый вес"/>
                        <Table.HeaderCell content="Допустимая длина"/>
                        <Table.HeaderCell content="Станция начала"/>
                        <Table.HeaderCell content="Станция конца"/>
                        <Table.HeaderCell content="Дата начала действия"/>
                        <Table.HeaderCell content="Дата окончания действия"/>
                        <Table.HeaderCell content="Электронный документ"/>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {loading ? (
                        <Table.Row>
                            <Table.Cell colSpan={totalCols}>
                                <Dimmer inverted active className={styles.loadingDimmer}>
                                    <Loader size='medium'>Загрузка...</Loader>
                                </Dimmer>
                            </Table.Cell>
                        </Table.Row>
                    ) : (
                        <BodyContent/>
                    )}

                </Table.Body>

                {data !== null && data.length !== 0 && !loading && (
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell textAlign={'center'} colSpan={totalCols}>
                                <Pagination
                                    totalPages={totalPages}
                                    activePage={activePage}
                                    onPageChange={(e, {activePage}) => {
                                        onPageChanged(activePage)
                                    }}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                )}
            </Table>

        </div>
    )
})
export default TrafficStandardsTable