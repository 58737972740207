import React, {useEffect, useState} from 'react';
import style from './AnalyticalAria.module.scss';
import {TasksCounter} from "./TasksCounter/TasksCounter";
import {AssigneeChart} from "./AssigneeChart/AssigneeChart";
import {StatusAndPriorityChart} from "./StatusAndPriorityChart/StatusAndPriorityChart";
import ekasut from "../../../../api/ekasut";
import {board} from "../../TaskListState";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import {AnalyticsList} from "./AnalyticsList/AnalyticsList";
import {analyticsState} from "./AnalyticsState";

const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

export const AnalyticalAria = ({}) => {

    const [analyticsData, setAnalyticsData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        loadAnalyticsBoard()
        return () => {
            analyticsState.loadingList = false
            analyticsState.taskList = null
            analyticsState.id = null
            analyticsState.filterType = null
            analyticsState.categoryId = null
            analyticsState.name = null
        }
    }, [])

    const loadAnalyticsBoard = (category = null) => {
        let query = `${category ? '?categoryId=' + category : ''}`
        ekasut.taskboard.analytics(board.workGruopId, query)
            .then(({data}) => {
                setAnalyticsData(data)
                setLoading(false)
            })
    }

    return (
        <div className={style.analyticalAria}>
            <div className={style.chartPart}>
                <div className={style.itemOne}>
                    <h3>Задач в работе</h3>
                    {loading || !analyticsData ? <div className={style.loader}>
                            <Spin indicator={antIcon}/>
                        </div> :
                        <TasksCounter analyticsData={analyticsData} loading={loading}
                                      loadAnalyticsBoard={loadAnalyticsBoard}/>}
                </div>
                <div className={style.itemTwo}>
                    <h3>Исполнители</h3>
                    {loading || !analyticsData ? <div className={style.loader}>
                            <Spin indicator={antIcon}/>
                        </div> :
                        <AssigneeChart analyticsData={analyticsData}/>}
                </div>
                <div className={style.itemThree}>
                    <h3>Статусы</h3>
                    {loading || !analyticsData ? <div className={style.loader}>
                            <Spin indicator={antIcon}/>
                        </div> :
                        <StatusAndPriorityChart analyticsData={analyticsData}/>}
                </div>
            </div>
            <div className={style.listPart}>
                <AnalyticsList/>
            </div>
        </div>
    );
};