import React, { PureComponent, useState } from "react";
import auth from "../../helpers/auth";
import { Icon, Table, Menu, Checkbox } from "semantic-ui-react";
import { Segment, Button } from "semantic-ui-react";
import { Input, Dropdown } from "semantic-ui-react";
import { Form } from "semantic-ui-react";
import { Modal, Header, Progress } from "semantic-ui-react";
import { Dimmer, Loader, Image } from "semantic-ui-react";
import { Pagination } from "semantic-ui-react";
import FloatButton from "../../shared/FloatButton";
import SkeletonPage from "../../shared/SkeletonPage/SkeletonPage";

import * as moment from "moment";
import "moment/locale/ru";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import { downloadBlob, getAppGrants } from "../../helpers/utils";
import storeContext from "../../globalStore/storeContext";
import { AddLocoModal } from "./AddLocoModal";
import { message } from "antd";
import { ChangeLocoModal } from "./ChangeLocoModal";
import useFileDownload from "../../hooks/useFileDownload";
import SaveIcon from "@material-ui/icons/Save";

const FileLoader = (data) => {
  const [open, setOpen] = useState(false);
  const [fileObject, setFileObject] = useState([]);
  const [download, InputDownload] = useFileDownload();
  const [loader, setLoader] = useState(false);
  const [fileId, setFileId] = useState(null);

  const saveFile = async (fileId, name) => {
    setLoader(true);
    const res = await ekasut.file.getFile(fileId);
    download(res?.data, name);
    setLoader(false);
  };

  console.log(data?.data?.fileName);

  return (
    <div style={{ textAlign: "center" }}>
      <InputDownload />
      {data?.data?.fileName ? (
        <Button
          key={fileObject.fileId}
          disabled={loader}
          className="save-btn"
          style={{ textTransform: "none", marginRight: 10, padding: 5 }}
          color="primary"
          size="small"
          download={fileObject.fileName}
          onClick={() => {
            saveFile(data?.data?.fileId, data?.data?.fileName);
          }}
          target={"_blank"}
          startIcon={<SaveIcon />}
        >
          {loader ? "Загрузка ..." : ""} <SaveIcon />
          {/* {data?.data?.fileName} */}
        </Button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

class GroupRiskLocomotives extends PureComponent {
  state = {
    activePage: 1,
    modalOpen: false,
    errorOccured: false,
    sectionsResp: [],
    isLoading: false,
    modalLoading: false,
    params: {},
    series: [],
    depos: [],
    repairDepos: [],
    repairTypes: [],
    filterShow: false,
    allTable: [],
    onPageLimit: 100,
    currentLoco: [],
    visibleModal: false,
    loadSickRemission: false,
    loadDelSick: false,
    changeLocoModalVisible: false,
    activeTypeChangeLocoModal: 0,
  };

  constructor(props) {
    super(props);

    this.filterClear = this.filterClear.bind(this);
    this.filterStateChange = this.filterStateChange.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.changePage = this.changePage.bind(this);
    this.addSickRemission = this.addSickRemission.bind(this);
    this.delSick = this.delSick.bind(this);
  }

  filterStateChange() {
    this.setState({ filterShow: !this.state.filterShow });
  }

  filterClear() {
    this.setState({
      sectionsResp: this.state.allTable,
      params: {},
      onPageLimit: 100,
    });
  }

  getData() {
    const me = this;
    let params = {};

    let queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    ekasut.oldReqs
      .grSick(queryString)
      .then(function (response) {
        if (response.status !== 200) {
          me.setState({
            isLoading: false,
            errorOccured: true,
            errorCode: response.status,
          });

          return;
        }
        me.setState({
          sectionsResp: response.data,
          isLoading: false,
          allTable: response.data,
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }

        me.setState({
          isLoading: false,
          errorOccured: true,
          errorCode: err.response.data.message,
        });
      });

    // console.log(ekasut.commisionInspection.getDeposForFilter());
  }

  componentDidMount() {
    const me = this;

    me.setState({ isLoading: true });

    document
      .querySelector(".content-container")
      .addEventListener("scroll", () => {
        if (
          document.querySelector(".content-container").scrollTop +
            document.querySelector(".content-container").clientHeight >=
          document.querySelector(".content-container").scrollHeight
        ) {
          this.setState({ onPageLimit: this.state.onPageLimit + 100 });
        }
      });

    this.getData();

    ekasut.locomotivesNsi
      .getSerTps()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({ series: response.data });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });

    ekasut.locomotivesNsi
      .getTypes()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({ repairTypes: response.data });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });

    ekasut.ekasutData
      .getCtTree()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({
          depos: response.data.map((pred) => ({
            value: pred.value,
            key: pred.key,
            text: pred.text,
            className:
              pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
          })),
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });

    ekasut.ekasutData
      .getServiceTree()
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({
          repairDepos: response.data.map((pred) => ({
            value: pred.value,
            key: pred.key,
            text: pred.text,
            className:
              pred.level === 0 ? "root-pred" : "child-pred-" + pred.level,
          })),
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        console.log("Error :-S", err);
      });
  }

  componentDidUpdate() {}

  handleClose = () => this.setState({ modalOpen: false });

  submitFilters() {
    const me = this;
    const { params } = this.state;

    let queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    me.setState({ isLoading: true });

    ekasut.oldReqs
      .grSick(queryString)
      .then(function (response) {
        if (response.status !== 200) {
          return;
        }

        me.setState({
          sectionsResp: response.data,
          activePage: 1,
          isLoading: false,
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }
        me.setState({
          isLoading: false,
          errorOccured: true,
          errorCode: err.response.data.message,
        });
      });
  }

  changePage(event, data) {
    this.setState({ activePage: data.activePage });
  }

  closeErrorModal = () => this.setState({ errorOccured: false });
  closeRepairModal = () => this.setState({ repairOpened: false });
  closeKasantModal = () => this.setState({ kasantOpened: false });

  getNrInfo(meta) {
    const me = this;

    me.setState({ modalLoading: true });

    ekasut.oldReqs
      .sickDetailNr(`${meta.locSer}/${meta.locNum}/${meta.locSec}`)
      .then(function (response) {
        if (response.status !== 200) {
          me.setState({
            modalLoading: false,
            errorOccured: true,
            errorCode: response.status,
          });

          return;
        }

        me.setState({
          repairResp: response.data,
          modalLoading: false,
          repairOpened: true,
          repairDetails: meta,
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }

        me.setState({
          modalLoading: false,
          errorOccured: true,
          errorCode: err,
        });
      });
  }

  getKasantInfo(meta, only1Kind) {
    const me = this;

    me.setState({ modalLoading: true });

    ekasut.oldReqs
      .sickDetailKas(
        `${meta.locSer}/${meta.locNum}/${meta.locSec}?only1Kind=${only1Kind}`
      )
      .then(function (response) {
        if (response.status !== 200) {
          me.setState({
            modalLoading: false,
            errorOccured: true,
            errorCode: response.status,
          });

          return;
        }

        me.setState({
          kasantResp: response.data,
          modalLoading: false,
          kasantOpened: true,
          kasantDetails: meta,
        });
      })
      .catch(function (err) {
        if (
          err.response.data["$type"].includes("UnauthorizedAccessException")
        ) {
          auth.logout();
        }

        me.setState({
          modalLoading: false,
          errorOccured: true,
          errorCode: err,
        });
      });
  }

  addSickRemission(filesData) {
    this.setState({
      loadSickRemission: true,
    });
    return ekasut.oldReqs
      .addSickRemission(
        this.state.currentLoco.map((el) => ({
          locSer: el.value.locSer,
          locNum: el.value.locNum,
          locSec: el.value.locSec,
          fileId: filesData.find((f) => f.id === el.value.idObject)?.fileId
            ? filesData.find((f) => f.id === el.value.idObject)?.fileId
            : "",
        }))
      )
      .then((r) => {
        if (r.status === 200) {
          message.success(
            "Локомотивы успешно переведенны на испытательный срок"
          );
          this.setState({
            currentLoco: [],
            sectionsResp: [],
            allTable: [],
            loadSickRemission: false,
          });
          this.getData();
        } else {
          this.setState({
            loadSickRemission: false,
          });
          message.error("Что то пошло не так");
        }
      })
      .catch((err) => {
        this.setState({
          loadSickRemission: false,
        });
        message.error("Что то пошло не так");
      });
  }

  delSick(filesData) {
    this.setState({
      loadDelSick: true,
    });
    ekasut.oldReqs
      .delSick({
        locSer: this.state.currentLoco[0].value.locSer,
        locNum: this.state.currentLoco[0].value.locNum,
        locSec: this.state.currentLoco[0].value.locSec,
        fileId: filesData.find(
          (f) => f.id === this.state.currentLoco[0].value.idObject
        )?.fileId
          ? filesData.find(
              (f) => f.id === this.state.currentLoco[0].value.idObject
            )?.fileId
          : "",
      })
      .then((r) => {
        if (r.status === 200) {
          message.success("Локомотив успешно выведен из группы риска");
          this.setState({
            currentLoco: [],
            sectionsResp: [],
            allTable: [],
            loadDelSick: false,
          });
          this.getData();
        } else {
          this.setState({
            loadDelSick: false,
          });
          message.error("Что то пошло не так");
        }
      })
      .catch((err) => {
        this.setState({
          loadDelSick: false,
        });
        message.error("Что то пошло не так");
      });
  }

  static contextType = storeContext;

  render() {
    const toggleCurrentLoco = (value) => (e, data) => {
      if (data.checked) {
        this.setState((prevSate) => ({
          ...prevSate,
          currentLoco: [...prevSate.currentLoco, { label: data.label, value }],
        }));
      } else {
        this.setState((prevSate) => ({
          ...prevSate,
          currentLoco: prevSate.currentLoco.filter(
            (el) => el.label !== data.label
          ),
        }));
      }
    };

    const onCloseModal = () => {
      this.setState({
        visibleModal: false,
      });
    };

    const me = this,
      lis = [];

    if (this.state.sectionsResp.length > 0) {
      let target = this.state.sectionsResp.slice(
        (this.state.activePage - 1) * this.state.onPageLimit,
        this.state.activePage * this.state.onPageLimit
      );

      target.forEach(function (item, index) {
        lis.push(
          <Table.Row key={index}>
            <Table.Cell className="center aligned">
              <Checkbox
                onChange={toggleCurrentLoco(item)}
                label={
                  (me.state.activePage - 1) * me.state.onPageLimit + (index + 1)
                }
              />
            </Table.Cell>
            <Table.Cell className="center aligned">{item.serName}</Table.Cell>
            <Table.Cell className="center aligned">
              {item.locNum}
              {item.prsCharLoc}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.depoPripName}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.dateRepairFinish
                ? moment(item.dateRepairFinish).format("DD.MM.YYYY HH:mm")
                : null}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.repairKindName}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.hasKo ? (
                <Icon size="large" name="check circle outline" />
              ) : null}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.neplanCount && item.neplanCount > 0 ? (
                <span
                  className="repair-href"
                  onClick={(e) => {
                    me.getNrInfo(item);
                  }}
                >
                  {item.neplanCount}
                </span>
              ) : (
                item.neplanCount
              )}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.kasantCount && item.kasantCount > 0 ? (
                <span
                  className="repair-href"
                  onClick={(e) => {
                    me.getKasantInfo(item, false);
                  }}
                >
                  {item.kasantCount}
                </span>
              ) : (
                item.kasantCount
              )}
            </Table.Cell>
            <Table.Cell className="center aligned">
              {item.kind1Count && item.kind1Count > 0 ? (
                <span
                  className="repair-href"
                  onClick={(e) => {
                    me.getKasantInfo(item, true);
                  }}
                >
                  {item.kind1Count}
                </span>
              ) : (
                item.kind1Count
              )}
            </Table.Cell>
            <Table.Cell>
              <FileLoader data={target[index]} />
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    const {
      params,
      repairDetails,
      repairResp,
      repairOpened,
      kasantDetails,
      kasantResp,
      kasantOpened,
    } = this.state;

    const canAddLoco = getAppGrants().includes("risk_group_edit");
    return (
      <div>
        {/*<div style={{margin: '15px'}}>*/}
        {/*    <span style={{fontSize: '14pt', color: '#C1C8D8'}}>Группа риска локомотивов</span>*/}
        {/*</div>*/}
        <Button
          primary
          disabled={!canAddLoco}
          onClick={() =>
            this.setState({
              visibleModal: true,
            })
          }
        >
          Добавить
        </Button>
        <Button
          primary
          loading={this.state.loadSickRemission}
          disabled={!this.state.currentLoco.length || !canAddLoco}
          onClick={() =>
            this.setState({
              changeLocoModalVisible: true,
              activeTypeChangeLocoModal: 1,
            })
          }
        >
          Перевести на испытательный срок
        </Button>
        <Button
          primary
          disabled={this.state.currentLoco.length !== 1 || !canAddLoco}
          loading={this.state.loadDelSick}
          onClick={() =>
            this.setState({
              changeLocoModalVisible: true,
              activeTypeChangeLocoModal: 2,
            })
          }
        >
          Вывести из группы риска
        </Button>
        {me.state.filterShow ? (
          <Modal
            closeIcon
            size="mini"
            open={this.state.filterShow}
            onClose={me.filterStateChange}
          >
            <Modal.Content>
              <Modal.Description>
                <Form
                  onSubmit={() => {
                    this.submitFilters();
                    me.filterStateChange();
                  }}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      this.submitFilters();
                      me.filterStateChange();
                    }
                  }}
                >
                  <Form.Field>
                    <label>№ локомотива</label>
                    <Input
                      placeholder="№ локомотива"
                      value={this.state.params.num ? this.state.params.num : ""}
                      onChange={(e, { value }) => {
                        var num = parseInt(value, 10);
                        if (num) {
                          this.setState({ params: { ...params, num: num } });
                        } else {
                          delete params.num;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Серия</label>
                    <Dropdown
                      placeholder="Серия"
                      fluid
                      multiple
                      search
                      selection
                      options={this.state.series}
                      value={this.state.params.ser ? this.state.params.ser : []}
                      onChange={(e, { value }) => {
                        this.setState({ params: { ...params, ser: value } });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Депо приписки</label>
                    <Dropdown
                      placeholder="Депо приписки"
                      fluid
                      multiple
                      search
                      selection
                      options={this.state.depos}
                      value={
                        this.state.params.pripDepo
                          ? this.state.params.pripDepo
                          : []
                      }
                      onChange={(e, { value }) => {
                        this.setState({
                          params: { ...params, pripDepo: value },
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>НР более</label>
                    <Input
                      placeholder="НР более"
                      value={params.nrMore}
                      value={
                        this.state.params.nrMore ? this.state.params.nrMore : ""
                      }
                      onChange={(e, { value }) => {
                        let nrMore = parseInt(value, 10);
                        if (
                          nrMore !== null &&
                          nrMore !== undefined &&
                          !isNaN(nrMore)
                        ) {
                          this.setState({
                            params: { ...params, nrMore: nrMore },
                          });
                        } else {
                          delete params.nrMore;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>отказов более</label>
                    <Input
                      placeholder="отказов более"
                      value={params.kasantMore}
                      value={
                        this.state.params.kasantMore
                          ? this.state.params.kasantMore
                          : ""
                      }
                      onChange={(e, { value }) => {
                        let kasantMore = parseInt(value, 10);
                        if (
                          kasantMore !== null &&
                          kasantMore !== undefined &&
                          !isNaN(kasantMore)
                        ) {
                          this.setState({
                            params: { ...params, kasantMore: kasantMore },
                          });
                        } else {
                          delete params.kasantMore;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>отказов 1кат более</label>
                    <Input
                      placeholder="отказов 1кат более"
                      value={params.kasant1More}
                      value={
                        this.state.params.kasant1More
                          ? this.state.params.kasant1More
                          : ""
                      }
                      onChange={(e, { value }) => {
                        let kasant1More = parseInt(value, 10);
                        if (
                          kasant1More !== null &&
                          kasant1More !== undefined &&
                          !isNaN(kasant1More)
                        ) {
                          this.setState({
                            params: { ...params, kasant1More: kasant1More },
                          });
                        } else {
                          delete params.kasant1More;
                          this.setState({ params: { ...params } });
                        }
                      }}
                    />
                  </Form.Field>
                  <div
                    style={{
                      marginBottom: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button className="button-link" onClick={me.filterClear}>
                      Сброс
                    </Button>
                    <Button
                      className="button-primary"
                      style={{ marginLeft: 15 }}
                      // onClick={() => {
                      //   this.submitFilters();
                      //   me.filterStateChange();
                      // }}
                      type="submit"
                    >
                      Применить
                    </Button>
                  </div>
                </Form>
              </Modal.Description>{" "}
            </Modal.Content>
          </Modal>
        ) : (
          <div>
            {!me.state.isLoading ? (
              <FloatButton
                main={{
                  text: "",
                  rotate: true,
                  icon: "angle left",
                }}
                actions={[
                  {
                    text: "Excel",
                    icon: "file excel outline",
                    onClick: () => {
                      this.context.prompt.show("Загрузка отчета...", "", {
                        loading: true,
                      });
                      const params = new URLSearchParams(
                        Object.keys(this.state.params).map((key) => [
                          key,
                          this.state.params[key],
                        ])
                      );

                      ekasut.ekasutData
                        .getSickExcel(params, false)
                        .then((res) => {
                          downloadBlob("locomotives_report.xlsx", res.data);
                        })
                        .catch(catchNetworkErrors)
                        .finally(() => {
                          this.context.prompt.close();
                        });
                    },
                  },
                  {
                    text: "Настройка фильтров",
                    icon: "filter",
                    onClick: () => {
                      me.filterStateChange();
                    },
                  },
                ]}
              />
            ) : null}
          </div>
        )}

        <Segment
          basic
          className={`report-container ${
            this.state.isLoading ? "loading-report" : ""
          }`}
        >
          {this.state.modalLoading ? (
            <SkeletonPage view={"ModalGroupRisk"} />
          ) : null}

          {this.state.isLoading ? (
            <SkeletonPage view={"GroupRisk"} />
          ) : (
            <Table celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="center aligned" colSpan="11">
                    Секции группы риска
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell className="center aligned">
                    №
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Серия
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Секция
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Приписка
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Дата окончания планового ремонта
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Вид планового ремонта
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Признак проведения КО
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Количество НР
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Количество отказов
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Количество отказов первой категории
                  </Table.HeaderCell>
                  <Table.HeaderCell className="center aligned">
                    Прикрепленный файл
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {lis.length > 0 ? (
                  lis
                ) : (
                  <Table.Row>
                    <Table.Cell className="center aligned" colSpan="11">
                      Нет элементов удовлетворяющих условиям поиска
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          )}

          <Modal
            open={this.state.errorOccured}
            closeOnDimmerClick={false}
            basic
            size="small"
          >
            <Header
              icon="warning sign"
              content={`Ошибка ${this.state.errorCode}`}
            />
            {/*<Modal.Content></Modal.Content>*/}
            <Modal.Actions>
              <Button onClick={this.closeErrorModal} inverted>
                <Icon name="checkmark" /> Закрыть
              </Button>
            </Modal.Actions>
          </Modal>
        </Segment>

        {repairDetails && repairOpened ? (
          <Modal
            centered={false}
            className={"employee-repairs"}
            open={repairOpened}
            closeIcon
            closeOnDimmerClick={true}
            onClose={this.closeRepairModal}
          >
            <Header
              content={`${repairDetails.serName} ${repairDetails.locNum}${repairDetails.prsCharLoc}`}
            />
            <Modal.Content>
              <Table celled structured striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="center aligned">
                      Депо
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Локомотив
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Вид
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Начало
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Окончание
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Причина
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {repairResp.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell className="center aligned">
                        {item.predName}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.serName} {item.znsLoc}
                        {item.prsCharLoc}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.sostName}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {moment(item.dateRepairBegin).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.dateRepairFinish
                          ? moment(item.dateRepairFinish).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          : null}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.investigationResult}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Modal.Content>
          </Modal>
        ) : null}

        {kasantDetails && kasantOpened ? (
          <Modal
            centered={false}
            className={"employee-repairs"}
            open={kasantOpened}
            closeIcon
            size={"large"}
            closeOnDimmerClick={true}
            onClose={this.closeKasantModal}
          >
            <Header
              content={`${kasantDetails.serName} ${kasantDetails.locNum}${kasantDetails.prsCharLoc}`}
            />
            <Modal.Content scrolling>
              <Table celled structured striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      className="center aligned"
                      style={{ width: 130 }}
                    >
                      Локомотив
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="center aligned"
                      style={{ width: 150 }}
                    >
                      Дата отказа
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Категория отказа
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Причина отказа
                    </Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">
                      Последствия отказа
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {kasantResp.map((item, index) => (
                    <Table.Row key={index}>
                      <Table.Cell className="center aligned">
                        {item.serName} {item.znsLoc}
                        {item.prsCharLoc}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {moment(item.dtNotification).format("DD.MM.YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.characterName}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.reasonName}
                      </Table.Cell>
                      <Table.Cell className="center aligned">
                        {item.consequenceName}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Modal.Content>
          </Modal>
        ) : null}

        <AddLocoModal
          visible={this.state.visibleModal}
          onClose={onCloseModal}
          series={this.state.series}
          getData={this.getData}
          data={this.state.sectionsResp.slice(
            (this.state.activePage - 1) * this.state.onPageLimit,
            this.state.activePage * this.state.onPageLimit
          )}
        />
        <ChangeLocoModal
          visible={this.state.changeLocoModalVisible}
          onClose={() => {
            this.setState({
              changeLocoModalVisible: false,
              activeTypeChangeLocoModal: 0,
            });
          }}
          list={this.state.currentLoco.map((el) => el.value)}
          typeModal={this.state.activeTypeChangeLocoModal}
          addSickRemission={this.addSickRemission}
          loadSickRemission={this.state.loadSickRemission}
          delSick={this.delSick}
          loadDelSick={this.loadDelSick}
        />
      </div>
    );
  }
}

export default GroupRiskLocomotives;
