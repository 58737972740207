import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Button, Form, Checkbox, Segment, Header, Divider} from "semantic-ui-react";
import useApiMethod from "../../../hooks/useApiMethod";
import DropdownField from "../fields/DropdownListField";
import ekasut from "../../../api/ekasut";
import axios from 'axios';
import EmployeesInColField from "../fields/EmployeesInColField";
import LoadingContent from "../../RatingsWorkLocomotives/NepLayout/LoadingContent";
import styles from '../index.module.scss'
import {Route, useHistory, useRouteMatch} from 'react-router-dom'
import PersInColsInfo from "./PersInColsInfo";
import ModalList from "../fields/ModalList";
import AppModal from "../../../shared/AppModal";
import dbSync from '../../../api/dbSync'
import catchNetworkErrors from "../../../api/catchNetworkErrors";

export default function ResultForm2({defApplyToSelectedGroup, cols, onApply, event}) {

    const [applyToSelectedGroup] = useState(defApplyToSelectedGroup)
    const [sendGetEmplRates] = useApiMethod(dbSync.worksPlan.getRates, undefined, [])
    const [sendGetRateList, rateListLoading, rateList] = useApiMethod(ekasut.tchmi.getRates, undefined, [],
        ({data}) => data.map(item => ({text: item.name, id: item.code})))
    const [employeesInCols, setEmployeesInCols] = useState([])
    const [savedEmployeesInCols, setSavedEmployeesInCols] = useState([])
    const [employeesInColsLoading, setEmployeesInColsLoading] = useState(true)
    const [selectedRate, setSelectedRate] = useState(null)
    const history = useHistory()
    const {path} = useRouteMatch()
    const [employee, setEmployee] = useState(null)
    const [applyRateToAllEmployees, setApplyRateToAllEmployees] = useState(false)

    const [modalRates, setModalRates] = useState({open: false})
    const [emplInfoModal, setEmplInfoModal] = useState({open: false})

    useEffect(() => {
        axios.all([
            ...cols.map(col => ekasut.persData.getSearchByCol(col)),
            sendGetRateList()
        ]).then(results => {
            const rateList = results[results.length - 1]
            const emplResults = results.slice(0, results.length - 1)
            const employees = []
            emplResults.forEach(({data}) => data.forEach(item => employees.push(item)))
            employees.forEach(empl => {
                if (empl.asutrId === null) {
                    empl.rateText = '[ Ошибка: не получен табельный номер ]'
                }
            })
            sendGetEmplRates(event.actionId, event.rd)
                .then((emplRates) => {
                    applyRates(employees, emplRates, rateList)
                    const emplInCols = convertToInCols(employees)
                    ReactDOM.unstable_batchedUpdates(() => {
                        setEmployeesInCols(emplInCols)
                        setSavedEmployeesInCols(cloneEmplInCols(emplInCols))
                        setSelectedRate(rateList[0])
                        setEmployeesInColsLoading(false)
                    })
                })
                .catch(() => {
                    setEmployeesInColsLoading(false)
                })
        }).catch((e) => {
            setEmployeesInColsLoading(false)
            return catchNetworkErrors(e)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const cloneEmplInCols = (cols) => (
        cols.map(col => ({...col, employees: col.employees.map(empl => ({...empl}))}))
    )

    return (<>
        <Form>
            <RateGroupsCheckbox
                onChange={(checked) => {
                    setApplyRateToAllEmployees(checked)
                    let next = cloneEmplInCols(savedEmployeesInCols)
                    if (checked) {
                        next.forEach(col => col.employees.forEach(empl => {
                            if (empl.asutrId !== null) {
                                empl.rateId = selectedRate.id
                                empl.rateText = selectedRate.text
                                empl.checked = true
                            }
                        }))
                    }
                    setEmployeesInCols(next)
                }}
                disabled={employeesInColsLoading && rateListLoading}
            />
            <DropdownField
                label={''}
                disabled={!applyRateToAllEmployees}
                list={rateList}
                loading={rateListLoading}
                placeholder='Выбрать оценку'
                defaultValue={0}
                onChange={(value) => {
                    if (applyToSelectedGroup) {
                        const next = cloneEmplInCols(employeesInCols)
                        next.forEach((col, colIndex) => {
                            col.employees.forEach((empl, emplIndex) => {
                                empl.rateText = empl.checked ? value.text : savedEmployeesInCols[colIndex].employees[emplIndex].rateText
                                empl.rateId = empl.checked ? value.id : savedEmployeesInCols[colIndex].employees[emplIndex].rateId
                            })
                        })
                        setEmployeesInCols(next)
                        setSelectedRate(value)
                    }
                }}
            />
            <LoadingContent
                inverted={true}
                loaderClassName={styles.loader}
                loadingMessage='Загрузка списков сотрудников в колоннах'
                showLoader={employeesInColsLoading}
            >
                {employeesInCols.map((item, index) => (
                    <EmployeesInColField
                        key={index}
                        employees={item.employees}
                        colNumber={item.colNumber}
                        isEditIcons={!applyToSelectedGroup}
                        onChange={(employees) => {
                            const next = [...employeesInCols]
                            next[index].employees = employees
                            if (selectedRate !== null) {
                                next[index].employees.forEach((empl, emplIndex) => {
                                    empl.rateText = empl.checked ? selectedRate.text : savedEmployeesInCols[index].employees[emplIndex].rateText
                                    empl.rateId = empl.checked ? selectedRate.id : savedEmployeesInCols[index].employees[emplIndex].rateId
                                })
                            }
                            setEmployeesInCols(next)
                        }}
                        onInfo={(employee) => {
                            setEmployee(employee)
                            setEmplInfoModal({open: true})
                            history.push(path + '/person')
                        }}
                        onEdit={(employee) => {
                            if (!applyRateToAllEmployees) {
                                setModalRates({open: true})
                                setEmployee(employee)
                            }
                        }}
                    />
                ))}
            </LoadingContent>
            <Button
                basic primary
                content='Применить оценки к сотрудникам'
                onClick={() => {
                    const checkedInCols = getCheckedEmployees(employeesInCols)
                    const checkedEmployees = convertToPlain(checkedInCols)
                    const validEmployees = filterVaildEmployees(checkedEmployees)
                    onApply(validEmployees)
                }}
            />
            <ModalList
                open={modalRates.open}
                onClose={() => setModalRates({open: false})}
                list={rateList}
                label={'Выберите оценку'}
                onClick={(rate) => {
                    setModalRates({open: false})
                    if (employee.asutrId === null) {
                        return
                    }
                    const next = cloneEmplInCols(employeesInCols)
                    const col = next.find(item => item.colNumber === employee.colNum)
                    const empl = col.employees.find(item => employee.asutrId === item.asutrId)
                    empl.rateId = rate.id
                    empl.checked = true
                    empl.rateText = rate.text
                    setEmployeesInCols(next)
                }}
            />
        </Form>

        <Route exact path={path + '/person'}>
            <AppModal
                open={emplInfoModal.open}
                onClose={() => {
                    history.goBack()
                    setEmplInfoModal({open: false})
                }}
            >

                <Segment>
                    <Header as='h3' textAlign='center'>
                        Информация о сотруднике в колонне {employee && employee.colNum}
                    </Header>
                    <Divider/>
                    <PersInColsInfo
                        employee={employee}
                    />
                </Segment>
            </AppModal>
        </Route>
    </>)
}
ResultForm2.defaultProps = {
    defApplyToSelectedGroup: true,
    cols: [],
}
const RateGroupsCheckbox = ({onChange, ...checkboxProps}) => {
    return (
        <div className={styles.formField}>
            <Checkbox
                label='Применить ко всем сотрудникам'
                onChange={(e, {checked}) => {
                    onChange(checked)
                }}
                {...checkboxProps}
            />
        </div>
    )
}
const filterVaildEmployees = (employees) => {
    const valid = []
    employees.forEach(empl => {
        if (empl.asutrId !== undefined && empl.asutrId !== null) {
            valid.push(empl)
        }
    })
    return valid
}
const getCheckedEmployees = (cols, checked = true) => {
    const checkedCols = cols.map(col => {
        const employees = []
        col.employees.forEach(empl => {
            if (empl.checked === checked) {
                employees.push(empl)
            }
        })
        return {...col, employees}
    })
    const result = []
    checkedCols.forEach(col => {
        if (col.employees.length !== 0) {
            result.push(col)
        }
    })
    return result
}
const convertToPlain = (inCols) => {
    const plain = []
    inCols.forEach(col => {
        col.employees.forEach(empl => {
            plain.push(empl)
        })
    })
    return plain
}
const convertToInCols = (plain) => {
    const map = new Map()
    plain.forEach(empl => {
        if (!map.has(empl.colNum)) {
            map.set(empl.colNum, [])
        }
        map.get(empl.colNum).push(empl)
    })
    const inCols = []
    map.forEach((value, key) => {
        inCols.push({
            employees: value,
            colNumber: key
        })
    })
    return inCols
}
const applyRates = (employees, emplRates, rateList) => {
    const mapEmpl = new Map()
    const mapEmplRates = new Map()
    const mapRates = new Map()

    rateList.forEach(rate => mapRates.set(rate.id, rate))
    employees.forEach(empl => mapEmpl.set(empl.asutrId, empl))
    emplRates.forEach(rate => mapEmplRates.set(rate.asutrID, rate))

    mapEmpl.forEach((empl, asutrId) => {
        if (asutrId !== null) {
            const emplRate = mapEmplRates.get(asutrId)
            const rate = emplRate !== undefined ? mapRates.get(emplRate.rateID) : undefined

            if (rate !== undefined) {
                empl.rateId = rate.id
                empl.rateText = rate.text
            } else {
                empl.rateId = undefined
                empl.rateText = '[ Оценка не проставлена ]'
            }
        }
    })
}