import React, { useState, useEffect } from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import { message, Table } from "antd";
import { DropzoneArea, DropzoneAreaBase } from "material-ui-dropzone";
import style from "./ChangeLocoModal.module.scss";
import ekasut from "../../api/ekasut";

export const ChangeLocoModal = ({
  visible,
  onClose,
  list,
  typeModal,
  addSickRemission,
  loadSickRemission,
  loadDelSick,
  delSick,
}) => {
  const [arrFiles, setArrFiles] = useState([]);

  const [safeOperation, setSafeOperation] = useState(true);

  useEffect(() => {
    if (list.length) {
      setArrFiles(
        list.map((el) => ({
          id: el.idObject,
          file: [],
        }))
      );
    }
  }, [list]);

  const columns = [
    {
      title: "Депо приписки",
      dataIndex: "depoPripName",
    },
    {
      title: "Серия локомотива",
      dataIndex: "serName",
    },
    {
      title: "Секция",
      children: [
        {
          title: "Номер",
          dataIndex: "locNum",
        },
        {
          title: "Индекс",
          dataIndex: "prsCharLoc",
        },
      ],
    },
    {
      title: "Прикрепленные файлы",
      width: 100,
      render: (text, row) => (
        <DropzoneArea
          filesLimit={1}
          fileObjects={
            arrFiles.find((el) => el.idObject === row.idObject)?.file
          }
          onChange={(file) => {
            setArrFiles((prevState) =>
              prevState.map((el) => {
                if (el.id === row.idObject) {
                  const newEl = {
                    ...el,
                    file,
                  };
                  return newEl;
                }
                return el;
              })
            );
          }}
          useChipsForPreview
          maxFileSize={30000000}
          dropzoneText={""}
          showAlerts={false}
          dropzoneClass={style.dropzone}
        />
      ),
    },
  ];

  const confirm = async () => {
    if (
      arrFiles.filter((el) => el.file.length > 0).length === arrFiles.length
    ) {
      const filesData = await Promise.all(
        arrFiles.map(async (el) => {
          if (el.file.length) {
            const res = await ekasut.file.postFile(el.file[0]);
            return {
              id: el.id,
              fileId: res?.data,
            };
          } else {
            return {
              id: el.id,
              fileId: "",
            };
          }
        })
      );
      await delSick(filesData);
      onClose();
    } else {
      message.warning("Обязательно прикрепите файлы");
    }
  };

  const transferTrialPeriod = async () => {
    if (
      arrFiles.filter((el) => el.file.length > 0).length === arrFiles.length
    ) {
      const filesData = await Promise.all(
        arrFiles.map(async (el) => {
          if (el.file.length) {
            const res = await ekasut.file.postFile(el.file[0]);
            return {
              id: el.id,
              fileId: res?.data,
            };
          } else {
            return {
              id: el.id,
              fileId: "",
            };
          }
        })
      );
      await addSickRemission(filesData);
      onClose();
    } else {
      message.warning("Обязательно прикрепите файлы");
    }
  };

  return (
    <Modal
      open={visible}
      onClose={() => onClose()}
      closeOnDimmerClick={false}
      closeIcon
      size={"small"}
    >
      <Modal.Header style={{ textAlign: "center" }}>
        {typeModal === 1
          ? "Перевод на испытательный срок"
          : "Вывод локомотива из группы риска"}
      </Modal.Header>
      <Modal.Content style={{ textAlign: "center" }}>
        <Table
          columns={columns}
          dataSource={list}
          pagination={false}
          bordered
          size={"small"}
          rowKey={"idObject"}
        />
        {typeModal !== 1 && (
          <div style={{ marginTop: 15, textAlign: "left" }}>
            <Checkbox
              checked={safeOperation}
              onChange={(e, data) => {
                setSafeOperation(data.checked);
              }}
              label={"Гарантирую безопасную эксплуатацию локомотива"}
            />
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>Отмена</Button>
        {typeModal === 1 ? (
          <Button
            onClick={transferTrialPeriod}
            primary
            loading={loadSickRemission}
          >
            Перевести на испытательный срок
          </Button>
        ) : (
          <Button
            disabled={!safeOperation}
            primary
            loading={loadDelSick}
            onClick={confirm}
          >
            Подтвердить
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
