import React from "react";
import {Button, Modal} from "semantic-ui-react";

export default function ModalInfo({header, content, onOk, ...modalProps}) {
    return (
        <Modal
            {...modalProps}
            size='tiny'
        >
            {header !== undefined && (
                <Modal.Header>
                    {header}
                </Modal.Header>
            )}
            {content !== undefined && (
                <Modal.Content>
                    {content}
                </Modal.Content>
            )}
            <Modal.Actions>
                <Button
                    basic
                    onClick={() => onOk === undefined ? modalProps.onClose() : onOk}
                >
                    Ок
                </Button>
            </Modal.Actions>
        </Modal>
    )
}