import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, {useState, useEffect} from "react";
import moment from "moment";
import {convertRotationToSeconds, convertSecondsToRotation, formatRotation} from "./functions";

const FullRotationDynamicChart = ({details = [], name}) => {
    const [options, setOptions] = useState(initOptions)

    useEffect(() => {
        const data = details.map((detail) => {
            const date = moment(detail.planDate)
            return {
                x: date.valueOf(),
                y: convertRotationToSeconds(formatRotation(detail.turnFullFact)),
                turnFullFact: detail.turnFullFact,
                date: date,
            }
        })

        const newOptions = {
            title: {
               text: `${name}, Динамика оборота локомотивных бригад`
            },
            series: [{
                name: 'Полный оборот',
                data,
            }]
        }
        setOptions(Highcharts.merge(initOptions, newOptions))
    }, [details, name])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}
export default FullRotationDynamicChart

const initOptions = {
    chart: {
        type: 'line',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    xAxis: {
        title: {
            text: 'Время'
        },
        type: 'datetime',
        labels: {
            formatter: function () {
                return moment(this.value).format('DD.MM');
            },
            rotation: 0 // -34
        },
    },
    yAxis: {
        title: {
            text: 'Часы'
        },
        labels: {
            formatter: function() {
                return convertSecondsToRotation(this.value)
            }
        }

    },
    legend: {
        enabled: false,
    },
    plotOptions: {
        series: {
            animation: false
        },
    },
    tooltip: {
        formatter: function () {
            return (
                `${this.series.name}: ${formatRotation(this.point.turnFullFact)} ч.<br />
                 Дата: ${moment(this.x).format('DD.MM.YYYY')}`
            )
        }
    }
}