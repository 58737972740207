import React, {useState, useEffect, useReducer} from 'react'
import EventPicker from "./EventPicker";
import {Button, Dropdown, Form, TextArea} from "semantic-ui-react";
import DelayedSearch from "../NsiWeightLength/DelayedSearch";
import cn from 'classnames'
import appStyles from '../../css/App.module.scss'
import {formOptions, isAllDefined} from "../../helpers/utils";
import ToggleButton from "../../shared/ToggleButton";
import styles from './PlanForm.module.scss'
import mainStyles from './index.module.scss'

export default function PlanForm(
    {
        editable, eventTypeId, onSave, onNotAllParams,
        // {list, defIndex, loading, typeEnd, defList}
        employee, departureSt, arrivalSt, controlSt,
        // {defValue}
        comment, groups, defIsGroups,
        freezeEventType,
        loading
    }
) {
    const defEventTypeIndex = eventTypes.findIndex(item => item.id === eventTypeId)
    const defEventType = eventTypes[defEventTypeIndex]

    const [eventType, setEventType] = useState(defEventType === undefined ? {} : defEventType)


    const getObjFromDefList = (obj) => {
        return obj.defList !== undefined ? obj.defList[obj.defIndex] : undefined
    }

    const [saveValues, setSaveValues] = useState({
        eventType: defEventType,
        employee: getObjFromDefList(employee),
        departureSt: getObjFromDefList(departureSt),
        arrivalSt: getObjFromDefList(arrivalSt),
        controlSt: getObjFromDefList(controlSt),
        comment: comment.defValue === undefined ? '' : comment.defValue,
        isGroups: defIsGroups,
        groups: getValuesByIndexes(groups.list.map(item => item.text), groups.defIndexes)
    })

    const updateSaveValues = (values) => {
        setSaveValues({
            ...saveValues,
            ...values,
        })
    }

    return (
        <Form loading={loading}>
            <div className={mainStyles.formField}>
            <EventPicker
                editable={freezeEventType === false ? editable : false}
                label='Тип мероприятия'
                defaultIndex={defEventTypeIndex}
                list={eventTypes}
                onChange={(value, index) => {
                    setEventType(value)

                    setSaveValues({
                        eventType: value,
                        isGroups: false,
                        comment: '',
                    })
                }}
            />
            </div>
            {eventType.formVersion === 0 && (
                <FormV0
                    editable={editable}
                    employee={employee}
                    departureSt={departureSt}
                    arrivalSt={arrivalSt}
                    controlSt={controlSt}
                    comment={comment}
                    onChange={value => {
                        updateSaveValues({...value})
                    }}
                />
            )}
            {eventType.formVersion === 1 && (
                <FormV1
                    isGroups={saveValues.isGroups}
                    editable={editable}
                    comment={comment}
                    groups={groups}
                    employee={employee}
                    onChange={value => {
                        updateSaveValues({...value})
                    }}
                />
            )}
            {eventType.formVersion === 2 && (
                <FormV2
                    editable={editable}
                    comment={comment}
                    groups={groups}
                    onChange={value => {
                        updateSaveValues({...value})
                    }}
                />
            )}
            {editable && (
                <Button
                    className={styles.bottomButton}
                    primary floated='right'
                    disabled={loading}
                    onClick={() => {
                        const sv = saveValues

                        switch (eventType.formVersion) {
                            case 0: {
                                isAllDefined(
                                    sv.eventType, sv.employee, sv.departureSt, sv.arrivalSt, sv.comment
                                ) ? onSave(sv) : onNotAllParams()
                                break
                            }
                            case 1: {
                                isAllDefined(
                                    sv.eventType, sv.comment, sv.isGroups ? sv.groups : sv.employee,
                                ) ? onSave(sv) : onNotAllParams()
                                break
                            }
                            case 2: {
                                isAllDefined(
                                    sv.eventType, sv.comment, sv.groups
                                ) ? onSave(sv) : onNotAllParams()
                                break
                            }
                            default: {
                                onNotAllParams()
                            }
                        }

                    }}
                >
                    Сохранить
                </Button>
            )}
        </Form>
    )
}
PlanForm.defaultProps = {
    eventTypeId: null,
    onSave: () => {
    },
    comment: {},
    groups: {},
    freezeEventType: false,
    onNotAllParams: () => {}
}
const eventTypes = [
    {text: 'Контрольно-инструкторная поездка', formVersion: 0, id: 2},
    {text: 'Контрольно-заключительная поездка', formVersion: 0, id: 1},
    {text: 'Целевая поездка', formVersion: 0, id: 3},
    {text: 'Техническое занятие', formVersion: 1, id: 5},
    {text: 'Производственное совещание', formVersion: 2, id: 6},
]
const minChars = 3
const searchDelay = 1000

const getValuesByIndexes = (allValues, indexes) => {
    if (indexes === undefined) {
        return []
    }
    const values = []
    indexes.forEach(index => {
        if (allValues[index] !== undefined) {
            values.push(allValues[index])
        }
    })
    return values
}

function DelayedSearchPlanReducer(state, action) {
    if (action.type === 'updateOptions') {
        if (state.defList !== undefined && !state.isListChanged) {
            return {
                options: formOptions(state.defList, 'text'),
                isListChanged: true
            }
        } else {
            return {
                options: formOptions(action.list, 'text'),
                isListChanged: true,
            }
        }
    }
}

const DelayedSearchPlan = (
    {
        editable, label, typeEnd, list, loading, onChange, defIndex,
        defList, placeholder
    }
) => {
    const [state, dispatch] = useReducer(DelayedSearchPlanReducer,
        {
            options: [],
            isListChanged: false,
            defList: defList
        }
    )

    useEffect(() => {
        dispatch({type: 'updateOptions', list: list})
    }, [list])

    return (
        <div className={mainStyles.formField}>
            <label>{label}</label>
            <DelayedSearch
                fluid
                placeholder={placeholder}
                minCharacters={minChars}
                delay={searchDelay}
                className={cn(!editable && appStyles.fixedDropdown)}
                disabled={!editable}
                options={state.options}
                typeEnd={typeEnd}
                loading={loading}
                defaultValue={defIndex}
                onChange={(e, {value: index}) => {
                    onChange(list[index])
                }}
            />
        </div>
    )
}
const Comment = ({editable, defText, onChange}) => {
    const [text, setText] = useState(defText === null ? '' : defText)
    return (
        <div className={mainStyles.formField}>
            <label>Содержание</label>
            <TextArea
                readOnly={!editable}
                placeholder={editable ? 'Текст (не более 1000 символов)' : ''}
                value={text}
                onChange={(e, {value}) => {
                    if (value.length < 1000 && editable) {
                        setText(value)
                        onChange(value)
                    }
                }}
            />
        </div>
    )
}
const MultipleList = ({list, onChange, label, defIndexes, editable, placeholder}) => {
    return (
        <div className={mainStyles.formField}>
            <label>{label}</label>
            <Dropdown
                placeholder={placeholder}
                disabled={!editable}
                className={cn(!editable && appStyles.fixedDropdown)}
                fluid multiple selection
                options={formOptions(list, 'text')}
                onChange={(e, {value}) => {
                    const values = getValuesByIndexes(list.map(item => item.text), value)
                    onChange(values.length > 0 ? values : undefined)
                }}
                defaultValue={defIndexes}
            />
        </div>
    )
}
MultipleList.defaultProps = {
    list: [],
    defIndexes: [],
}
const EmployeeOrGroupToggle = ({onEmployee, onGroup, isGroups}) => {

    return (
        <ToggleButton
            value={isGroups ? 'groups' : 'emp'}
            className={cn(appStyles.displayBlock, styles.formToggleButton)}
            groupProps={{
                size: 'small',
            }}
            buttons={[
                {
                    content: 'Сотрудник',
                    key: 'emp'
                },
                {
                    content: 'Список колонн',
                    key: 'groups'
                }
            ]}
            onToggle={(btn) => {
                if (btn.key === 'emp') {
                    onEmployee(btn)
                } else {
                    onGroup(btn)
                }
            }}
        />
    )
}
const FormV0 = (
    {
        editable, onChange,
        employee, departureSt, arrivalSt, controlSt,
        comment
    }
) => {
    return (
        <>
            <DelayedSearchPlan
                {...employee}
                label='Сотрудник'
                editable={editable}
                onChange={(value) => {
                    onChange({employee: value})
                }}
            />
            <DelayedSearchPlan
                {...departureSt}
                label='Станция начала участка'
                editable={editable}
                onChange={(value) => {
                    onChange({departureSt: value})
                }}
            />
            <DelayedSearchPlan
                {...arrivalSt}
                label='Станция конца участка'
                editable={editable}
                onChange={(value) => {
                    onChange({arrivalSt: value})
                }}
            />
            <DelayedSearchPlan
                {...controlSt}
                label='Контрольная станция участка'
                editable={editable}
                onChange={(value) => {
                    onChange({controlSt: value})
                }}
            />
            <Comment
                editable={editable}
                defText={comment.defValue}
                onChange={(value) => {
                    onChange({comment: value})
                }}
            />
        </>
    )
}
const FormV1 = ({editable, groups, comment, onChange, employee, isGroups}) => {
    return (
        <>
            {editable && (
                <EmployeeOrGroupToggle
                    isGroups={isGroups}
                    onEmployee={() => {
                        onChange({
                            isGroups: false, employee: employee.list[employee.defIndex],
                            groups: undefined
                        })
                    }}
                    onGroup={() => {
                        onChange({
                            isGroups: true, employee: employee.list[employee.defIndex],
                            groups: getValuesByIndexes(groups.list.map(item => item.text), groups.defIndexes)
                        })
                    }}
                />
            )}
            {!isGroups && (
                <DelayedSearchPlan
                    {...employee}
                    label='Сотрудник'
                    placeholder='Сотрудник'
                    editable={editable}
                    onChange={(value) => {
                        onChange({employee: value})
                    }}
                />
            )}
            {isGroups && (
                <MultipleList
                    editable={editable}
                    label='Список колонн'
                    placeholder='Список колонн'
                    list={groups.list}
                    loading={groups.loading}
                    defIndexes={groups.defIndexes}
                    onChange={(value) => {
                        onChange({groups: value})
                    }}
                />
            )}
            <Comment
                editable={editable}
                defText={comment.defValue}
                onChange={(value) => {
                    onChange({comment: value})
                }}
            />
        </>
    )
}
const FormV2 = ({editable, comment, onChange, groups}) => {
    return (
        <>
            <MultipleList
                editable={editable}
                label='Список колонн'
                list={groups.list}
                defIndexes={groups.defIndexes}
                loading={groups.loading}
                onChange={(value) => {
                    onChange({groups: value})
                }}
            />
            <Comment

                editable={editable}
                defText={comment.defValue}
                onChange={(value) => {
                    onChange({comment: value})
                }}
            />
        </>
    )
}

