import React, {useState} from 'react'
import {Button, Header} from 'semantic-ui-react'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";

const OperationalIncidentResult = ({details, setResultOpen}) => {
    const [resultValue, setResultValue] = useState(details.detailDescription || '')

    const [sendPostEkasutDetail, incidentLoading] =
        useApiMethod(ekasut.incidents.postEkasutDetail, undefined, [],
            ({data}) => {
                setResultOpen(false)
                return data
            })

    let handleResult = (e) => {
        setResultValue(e.target.value)
    }

    return (
        <>
            <Header as={'h4'}>Добавление расследования</Header>
            <Header as={'h5'}>
                {details.depot} &nbsp;
                <span dangerouslySetInnerHTML={{__html: details.meta.replace(/\n/gi, '<br/>').replace(/ /gi, '&nbsp;')}}></span>
            </Header>

            <textarea style={{width: '100%'}}
                      maxLength="1000"
                      placeholder={"результат расследования"}
                      value={resultValue}
                      onChange={handleResult}
            />

            <Button
                loading={incidentLoading}
                onClick={()=> {
                sendPostEkasutDetail({
                    'routeId': details.routeId,
                    'routeRd': details.routeRd,
                    'incidentType': details.typeId,
                    'description': resultValue
                })
            }}>
                Отправить
            </Button>
        </>
    )
}

export default OperationalIncidentResult