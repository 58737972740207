import React from 'react';
import {Button, Icon, Label, Popup, Table} from "semantic-ui-react";
import AttachFileButton from "../../shared/AttachFileButton";
import './FileList.scss'

const FilesList = ({files, editable}) => {
    return (
        <div className="app procurement-planning file-list">
            <Table unstackable>
                <Table.Body>
                    {files.sort((a, b) => a.typeId > b.typeId ? 1 : -1).filter((a) => a.typeId < 10).map((file, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>
                                {file.typeId !== 7 && file.typeId !== 6 && file.typeId !== 9 ?
                                    <Popup size='small' wide='very' position='top center'
                                           trigger={<span style={{color: 'red',cursor:'default'}}>*</span>}><Popup.Content>Обязательное
                                        поле</Popup.Content></Popup> : null} {file.description}
                                <Popup size='small' wide='very' position='top center'
                                       trigger={<Icon style={{marginLeft: "5px"}} color='black'
                                                      name='info circle'/>}><Popup.Content>{file.note}</Popup.Content></Popup>
                            </Table.Cell>
                            <Table.Cell>
                                {(editable || (!editable && file.fileId)) && (
                                    <AttachFileButton
                                        fileName={file.name}
                                        file={file.file}
                                        fileId={file.fileId}
                                        onAttached={file.onAttached}
                                        isAttachAnotherFile={editable}
                                    />
                                )}
                                {(!editable && !file.fileId) && (
                                    <div className="not-uploaded-text">Документ не загружен</div>
                                )}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default FilesList;