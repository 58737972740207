import React from 'react'
import styles from '../index.module.scss'
import {Dropdown} from "semantic-ui-react";
import {formOptions} from "../../../helpers/utils";
import appStyles from "../../../css/App.module.scss";
import cn from 'classnames'

export default function DropdownField({label, list, onChange, textField, editable, ...dropdownProps}) {

    return (
        <div className={styles.formField}>
            <label>{label}</label>
            <Dropdown
                fluid selection
                disabled={!editable}
                className={cn(!editable && appStyles.fixedDropdown)}
                options={formOptions(list, textField)}
                onChange={(e, {value: index}) => {
                    onChange(list[index], index)
                }}
                {...dropdownProps}
            />
        </div>
    )
}
DropdownField.defaultProps = {
    editable: true,
    textField: 'text'
}