import React from 'react'
import {Button} from 'semantic-ui-react'

export default function SaveChangesButton({className, disabled, loading, onClick}) {


    return (
        <Button
            className={"lcm-save-changes-button " + className}
            primary={!disabled} disabled={disabled}
            loading={loading}
            onClick={onClick}
        >
            {disabled ? 'Сохранено' : 'Отправить изменения'}
        </Button>
    );
}