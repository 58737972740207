import React, {useEffect} from 'react';
import AuditStepsWidget from "./AuditStepsWidget";
import PrepareOrderForm from "./PrepareOrderForm";
import {observer, useLocalStore} from "mobx-react-lite";
import apiWorker from "../../api/apiWorker";
import ekasut from "../../api/ekasut";
import moment from 'moment'
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {usePrompt} from "../../globalStore/useGlobalStore";


const PrepareOrder = observer(({onClose = () => null, onComplete, audit, onView, editable, step, viewStep, display}) => {
    const prompt = usePrompt()
    const LEADER_ROLE = 1
    const DEF_TEAM_MEMBER = {
        fio: "", position: "", description: "", predId: undefined,
        predName: undefined, roleId: undefined, employeeId: undefined
    }
    const DEF_LEADER = {
        fio: "", position: "", description: "", predId: undefined,
        predName: undefined, roleId: LEADER_ROLE, employeeId: undefined
    }

    const ls = useLocalStore(() => ({
        roles: [], // [{code, name}]
        preds: {childrens: []},
        formValues: {
            processes: [],
            orderDate: null,
            orderNum: "",
            leader: DEF_LEADER,
            team: [DEF_TEAM_MEMBER]
        },
        wasFirstDisplay: false,
        auditInfo: undefined,
        apiAuditInfo: apiWorker(ekasut.audit.getAuditInfo),
        apiCreateOrder: apiWorker(ekasut.audit.postAuditOrder),
    }))

    useEffect(() => {
        if (display && !ls.wasFirstDisplay) {
            ls.wasFirstDisplay = true
            if (audit.hasOrder) {
                ls.apiAuditInfo.fetch({auditId: audit.auditId}).then((data) => {
                    ls.auditInfo = data
                    const leaderIndex = data.employees.findIndex(employee => employee.roleId === LEADER_ROLE)
                    const leader = leaderIndex === -1 ? DEF_LEADER : data.employees[leaderIndex]
                    let foundTeam = [...data.employees]
                    if (leaderIndex > -1) {
                        foundTeam.splice(leaderIndex, 1)
                    }
                    const team = foundTeam.length > 0 ? foundTeam : [DEF_TEAM_MEMBER]

                    ls.formValues = {
                        /*processes: data.processes,*/
                        orderDate: moment(data.orderDate),
                        orderNum: data.orderNum,
                        leader, team
                    }
                }).catch(catchNetworkErrors)
            }
            ekasut.audit.getNsiRoles().then(({data}) => ls.roles = data).catch(catchNetworkErrors)
            ekasut.audit.getNsiPreds().then(({data}) => ls.preds = data).catch(catchNetworkErrors)
        }
    }, [display, ls.wasFirstDisplay])

    const checkForm = () => true /* !(
        ls.formValues.orderNum === ""
        || (ls.formValues.orderDate === null || !ls.formValues.orderDate.isValid())
        || ls.formValues.leader.fio === ""
        || ls.formValues.leader.position === ""
        || ls.formValues.leader.predId === undefined
        || ls.formValues.leader.roleId === undefined
        || ls.formValues.team.some((member) => (
            member.fio === "" || member.position === "" || member.predId === undefined || member.roleId === undefined)
        )
    )*/

    const save = (completeStep = false) => {
        const addEmployees = () => {
            const employees = [ls.formValues.leader, ...ls.formValues.team]

            // Delete all existed employees first, i don't have api methods for edit
            Promise.all(employees.filter(employee => employee.employeeId !== undefined).map(employee =>
                ekasut.audit.deleteEmployee({employeeId: employee.employeeId})
            )).then(() => {
                Promise.all(employees.map(employee =>
                    ekasut.audit.postEmployee({
                        auditId: audit.auditId,
                        data: {
                            ...employee,
                            predName: undefined,
                            roleName: undefined
                        }
                    })
                )).then((res) => {
                    if (completeStep) {
                        onComplete()
                    } else {
                        prompt.show("Состояние этапа сохранено", "", {ok: true})
                    }
                }).catch((err) => {
                    prompt.close()
                    return catchNetworkErrors(err)
                })
            }).catch((err) => {
                prompt.close()
                return catchNetworkErrors(err)
            })
        }

        prompt.show("Сохранение состояния этапа", "", {loading: true})
        if (!audit.hasOrder) {
            ls.apiCreateOrder.fetch({
                auditId: audit.auditId,
                data: {orderDate: ls.formValues.orderDate, orderNum: ls.formValues.orderNum, subunit: undefined}
            }).then(() => {
                addEmployees()
            }).catch((err) => {
                prompt.close()
                return catchNetworkErrors(err)
            })
        } else {
            addEmployees()
        }
    }

    if (!display) {
        return false
    }

    return (
        <AuditStepsWidget
            step={step}
            viewStep={viewStep}
            onClose={onClose}
            onCancel={onClose}
            /*exportButtonText="Экспорт группы аудита в Word"*/
            displayButtons={{cancel: true, exportDoc: false, save: true, end: true}}
            onView={onView}
            onSave={() => {
                if (checkForm()) {
                    save(false)
                } else {
                    prompt.show("Сохранение аудита не может быть выполнено", "Не все обязательные поля заполнены", {ok: true})
                }
            }}
            onEnd={() => {
                if (checkForm()) {
                    prompt.show("Вы действительно хотите завершить этап?", "", {yesNo: true, onApply: () => save(true)})
                } else {
                    prompt.show("Завершение этапа не выполнено", "Не все обязательные поля заполнены", {ok: true})
                }
            }}
        >
            <PrepareOrderForm
                roles={ls.roles}
                preds={ls.preds}
                processes={audit.processes}
                orderDate={ls.formValues.orderDate}
                orderNum={ls.formValues.orderNum}
                leader={ls.formValues.leader}
                team={ls.formValues.team}
                auditInfoLoading={ls.apiAuditInfo.loading}
                editable={editable}
                onChange={(data) => {
                    /*{processes, orderDate, orderNum, auditMembers: ls.auditMembers}*/
                    ls.formValues = data
                }}
            />
        </AuditStepsWidget>
    );
})

export default PrepareOrder