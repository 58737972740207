import React from 'react';
import {Tag} from "antd";
import style from './LocoDetailsHeaderItem.module.scss'

const LocoDetailsHeaderItem = ({info, index, activeItem, changeActiveItem}) => {


  const newClassName = activeItem === index ? style.nameInfoActive : style.nameInfo

  return (
    <div
      className={newClassName}
      onClick={() => changeActiveItem(index)}
    >
      <div className={style.fioWrap}>
        <h2>{info.nameSec}</h2>
      </div>
      <div className={style.tags}>
        <Tag color="#FFC107">Группа риска</Tag>
      </div>
    </div>
  );
};

export default LocoDetailsHeaderItem;