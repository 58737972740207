import React, {useCallback, useEffect, useState} from 'react';
import {Checkbox, Header, Modal, Button, Icon} from "semantic-ui-react";
import InputField from "../../shared/fields/InputField";
import {observer, useLocalStore} from "mobx-react-lite";
import cn from 'classnames'
import {runInAction, toJS} from 'mobx';
import ekasut from "../../api/ekasut";
import Widget from "../../shared/Widget";
import ProcessViewButtons from "./ProcessViewButtons";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import FilesList from "./FilesList";
import TextField from "../../shared/fields/TextField";
import './AddPurchaseDetailsView.scss'
import {formatPrice} from "../../helpers/utils";
import moment from "moment";
import UrgentlySubmiteModal from "./UrgentlySubmiteModal";

const AddPurchaseDetailsServiceView = observer((
    {
        open, purchase = {}, onSaveOrEnd = () => null, onClose = () => null, editable = true, updateAvailablePurchases
    }
) => {
    const defaultSave = {
        executant: "", // Исполнитель
        basis: "", // Основание закупки
        description: "", // Комментарий
        responsible: "" // Ответственный
    }

    const [openUrgentlySubmit, setOpenUrgentlySubmit] = useState(false)

    const ls = useLocalStore(() => ({
        save: defaultSave,
        files: [],
        fetchFileTypesStarted: false,
        fetchFileTypesLoaded: false,
    }))

    const updateFileList = () => {
        const updateFiles = () => {
            ekasut.purchases.getFiles({purchaseId: purchase.purchaseId})
                .then(({data}) => {
                    runInAction(() => {
                        data.forEach((item) => {
                            const file = ls.files.find(file => file.typeId === item.ftId)
                            // File with that file type is exist and do not uploaded by user
                            if (file && file.file === null) {
                                file.attached = true
                                file.file = null
                                file.fileId = item.files[0].fileId
                                file.isUploaded = true
                                file.name = item.files[0].fileName
                            }
                        })
                    })
                })
        }
        if (ls.fetchFileTypesLoaded) {
            updateFiles()
        }
        if (!ls.fetchFileTypesStarted) {
            ls.fetchFileTypesStarted = true
            ekasut.purchases.getFileTypes()
                .then(({data}) => {
                    runInAction(() => {
                        ls.fetchFileTypesLoaded = true
                        ls.files = data.map(item => ({
                            // Don't change
                            description: item.name,
                            note: item.description,
                            typeId: item.code,
                            // Will change
                            attached: false,
                            file: null,
                            fileId: null,
                            isUploaded: false,
                            name: "",
                        }))
                    })
                })
                .then(updateFiles)
                .catch(catchNetworkErrors)
        }
    }

    useEffect(() => {
        if (open) {
            ls.save = defaultSave
            // reset files
            ls.files.forEach(file => {
                file.attached = false
                file.file = null
                file.fileId = null
                file.isUploaded = false
                file.name = ""
            })

            updateFileList()

            if (purchase.hasDetail) {
                ekasut.purchases.getPurchaseService({purchaseId: purchase.purchaseId})
                    .then(({data}) => {
                        ls.save = {
                            basis: data.basis,
                            executant: data.executant,
                            description: data.description,
                            responsible: data.buyerFio || ""
                        }
                    })
                    .catch((err) => {
                        return catchNetworkErrors(err)
                    })
            }
        }

    }, [open])

    const closeHandler = () => {
        ls.save = defaultSave
        onClose()
    }


    return (
        <Modal
            open={open}
            className={cn("app procurement-planning add-purchase-details-view", "alt-semantic")}
            onClose={closeHandler}
        >
            <UrgentlySubmiteModal openUrgentlySubmit={openUrgentlySubmit}
                                  setOpenUrgentlySubmit={setOpenUrgentlySubmit}
                                  purchase={purchase}
                                  closeHandler={closeHandler}
                                  updateAvailablePurchases={updateAvailablePurchases}
            />
            <Widget
                header={editable ? "Добавление заявки (Услуги)" : "Просмотр заявки (Услуги)"}
                closeIcon
                onClose={closeHandler}
                footer={editable && (
                    <ProcessViewButtons
                        showUrgSub
                        urgentlySubmit={() => {
                            setOpenUrgentlySubmit(true)
                        }}
                        disabledSave={ls.files.length === 0}
                        disabledEnd={
                            ls.save.executant === ""
                            || ls.save.basis === ""
                            || moment(purchase.planDocDate) > moment().endOf('day')
                            //|| moment(purchase.planDocDate).diff(moment().format(), 'days') < 0
                            || ls.save.description === ""
                            || ls.save.responsible === ""
                            || ls.files.length === 0
                            // if at least one item have not file getting by user or from server
                            || ls.files.filter((file) => file.typeId !== 7 && file.typeId !== 6 && file.typeId !== 9).some(file => !(file.fileId !== null || file.file !== null))
                        }
                        onClose={closeHandler}
                        onSave={() => {
                            onSaveOrEnd({...ls.save, files: ls.files}, purchase, false)
                        }}
                        onEnd={() => {
                            onSaveOrEnd({...ls.save, files: ls.files}, purchase, true)
                        }}
                    />
                )}
            >
                <div className="content-container">
                    <div className="input-fields">
                        <TextField
                            label="Категория"
                            content={purchase.isPlan ? 'Плановая' : 'Внеплановая'}
                        />
                        <TextField
                            label="Наименование"
                            classNameContent="field-width"
                            content={purchase.purchaseName}
                        />
                        <TextField
                            label="Лимит закупки, руб"
                            content={formatPrice(purchase.priceLimit)}
                        />
                        <InputField
                            label="Исполнитель"
                            editable={editable}
                            textFieldPlaceholder="Не определен"
                            inputClassName="input-underline"
                            value={ls.save.executant}
                            onChange={(e, {value}) => {
                                ls.save.executant = value
                            }}
                        />
                        <InputField
                            label="Основание закупки"
                            textFieldPlaceholder="Не оределено"
                            editable={editable}
                            inputClassName="input-underline"
                            value={ls.save.basis}
                            onChange={(e, {value}) => {
                                ls.save.basis = value
                            }}
                        />
                        <InputField
                            label="Комментарий"
                            textFieldPlaceholder="Не оределен"
                            editable={editable}
                            inputClassName="input-underline"
                            value={ls.save.description}
                            onChange={(e, {value}) => {
                                ls.save.description = value
                            }}
                        />
                        <InputField
                            label="Ответственный"
                            textFieldPlaceholder="Не оределен"
                            inputClassName="input-underline"
                            editable={editable}
                            value={ls.save.responsible}
                            onChange={(e, {value}) => {
                                ls.save.responsible = value
                            }}
                        />
                    </div>
                    <div className="file-list-container">
                        <Header as="h4" content="Список файлов"/>
                        <FilesList
                            editable={editable}
                            files={ls.files.map(item => ({
                                ...item,
                                onAttached: (e, file) => {
                                    item.attached = true
                                    item.file = file
                                    item.fileId = null
                                    item.isUploaded = false
                                    item.name = file.name
                                }
                            }))}
                        />
                    </div>
                </div>
            </Widget>
        </Modal>
    );
})

export default AddPurchaseDetailsServiceView;
