import React, {useState, useEffect} from 'react';
import {BoardArea} from "./dashboardsComponents/BoardArea/BoardArea";
import style from "./index.module.scss"
import {CatsNSI} from "./dashboardsComponents/modalViews/CatsNSI";
import {SideBarBoard} from "./Sidebar/SideBarBoard";
import {useSnapshot} from "valtio";
import {board} from "./TaskListState";
import {UserTaskList} from "./dashboardsComponents/UserTaskList/UserTaskList";
import {TaskboardHeader} from "./dashboardsComponents/TaskboardHeader";
import {ListArea} from "./dashboardsComponents/ListArea/ListArea";
import {AnalyticalAria} from "./dashboardsComponents/AnalyticalAria/AnalyticalAria";
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

export const Taskboard = ({}) => {

    const [nsiOpen, setNsiOpen] = useState(false)
    const activeBrd = useSnapshot(board)

    useEffect(() => {
        let prevState = {
            w1: document.querySelector('.app.app-menu .content-container > div').style.width,
            h1: document.querySelector('.app.app-menu .content-container > div').style.height,
            of1: document.querySelector('.app.app-menu .content-container > div').style.overflow,
            w2: document.querySelector('.content-container').style.width,
            of2: document.querySelector('.content-container').style.overflow
        }
        document.querySelector('.app.app-menu .content-container > div').style.width = '100%'
        document.querySelector('.app.app-menu .content-container > div').style.height = '100%'
        document.querySelector('.app.app-menu .content-container > div').style.overflow = 'hidden'
        document.querySelector('.content-container').style.width = '98%'
        document.querySelector('.content-container').style.overflow = 'hidden'
        return () => {
            document.querySelector('.app.app-menu .content-container > div').style.width = prevState.w1
            document.querySelector('.app.app-menu .content-container > div').style.height = prevState.h1
            document.querySelector('.app.app-menu .content-container > div').style.overflow = prevState.of1
            document.querySelector('.content-container').style.width = prevState.w2
            document.querySelector('.content-container').style.overflow = prevState.of2
        }
    }, [])

    return (
        <div
            className={style.sidebarBox}>
            <SideBarBoard setNsiOpen={setNsiOpen}/>

            {!Array.isArray(activeBrd.boardItems) && !activeBrd.userListItems && (activeBrd.loading || activeBrd.userListLoading) ? <div className={style.loadingAria}>
                <Spin indicator={antIcon} tip='Загрузка...'/>
            </div> : null}

            {Array.isArray(activeBrd.boardItems) ? <div className={style.taskboardArea}>
                <TaskboardHeader activeBrd={activeBrd}/>
                {activeBrd.viewList === 'list' ?
                    <ListArea activeBoard={activeBrd} loadingList={activeBrd.loading}/> : activeBrd.viewList === 'analytics' ? <AnalyticalAria/> :
                        <BoardArea activeBoard={activeBrd} loadingTasksBoard={activeBrd.loading}/>}
            </div> : null}
            {activeBrd.userListItems ?
                <UserTaskList listItems={activeBrd.userListItems}
                              loadingTasksList={activeBrd.userListLoading}/> : null}
            <CatsNSI nsiOpen={nsiOpen} setNsiOpen={setNsiOpen}/>
        </div>
    );
}