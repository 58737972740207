import React, {useState, useEffect} from 'react'
import {Icon, Table} from "semantic-ui-react";
import styles from './index.module.scss'
import cn from 'classnames'

/** @deprecated too complicate, just use semantic Table */
export default function AppTreeTable(
    {
        data, headers, collapseAllByDef, paddingLevel, paddingLeft,
        onGroupNameClick, groupDataRowClassNameCreator
    }
) {
    const [collapseChildrenRows, setCollapseChildrenRows] = useState(new Set())
    const [bodyRows, setBodyRows] = useState([])

    useEffect(() => {
        const openerClickHandler = (index) => {
            const nextCR = new Set(collapseChildrenRows)
            if (nextCR.has(index)) {
                nextCR.delete(index)
            } else {
                nextCR.add(index)
            }
            setCollapseChildrenRows(nextCR)
        }

        // [{groupName, groupData, isGroup, level, clickable}]
        const plainStructure = convertToPlainStructure(data)
        const nextBodyRows = []
        let collapseHigherLevel = Infinity
        for (let index = 0; index < plainStructure.length; index++) {
            const info = plainStructure[index]

            if (info.level > collapseHigherLevel) {
                continue
            } else {
                if (collapseAllByDef) {
                    // invert logic !
                    collapseHigherLevel = collapseChildrenRows.has(index) ? Infinity : info.level
                } else {
                    // Rows all children will collapse if rows index has in collapseChildrenRows
                    collapseHigherLevel = collapseChildrenRows.has(index) ? info.level : Infinity
                }
            }

            if (info.isGroup) {
                const iconName = collapseChildrenRows.has(index) ? 'minus square outline' : 'plus square outline'
                const groupNameClickHandler = info.clickable ? (e) => {
                    onGroupNameClick(info)
                    e.stopPropagation()
                } : undefined

                nextBodyRows.push(
                    <Table.Row
                        key={`r${index}`}
                        onClick={() => openerClickHandler(index)}
                        className={styles.group}
                    >
                        <Table.Cell
                            colSpan={headers.length}
                            style={info.level === 0 ? {paddingLeft} : {paddingLeft: paddingLeft + info.level * paddingLevel}}
                        >
                            <Icon name={iconName} onClick={() => openerClickHandler(index)}/>
                            <span
                                onClick={groupNameClickHandler}
                                className={cn(info.clickable && styles.clickableGroupName)}
                            >
                                {info.groupName}
                            </span>
                        </Table.Cell>
                    </Table.Row>
                )
            } else {

                nextBodyRows.push(
                    <Table.Row key={`r${index}`} className={groupDataRowClassNameCreator(info.groupData)}>
                        <Table.Cell key={`r${index}_c0`} className={styles.firstEmptyCell}/>
                        {info.groupData.values.map((value, i) => (
                            <Table.Cell key={`r${index}_c${i + 1}`}>{value}</Table.Cell>
                        ))}
                    </Table.Row>
                )
            }

        }
        setBodyRows(nextBodyRows)
    }, [
        data, headers.length, collapseChildrenRows, collapseAllByDef, paddingLeft, paddingLevel, onGroupNameClick, groupDataRowClassNameCreator
    ])

    return (
        <Table celled selectable>
            <Table.Header>
                <Table.Row>
                    {headers.map((header, index) => (
                        <Table.HeaderCell
                            key={`h-${index}`}
                            {...header.props}
                        >
                            {header.value}
                        </Table.HeaderCell>
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {bodyRows}
            </Table.Body>
        </Table>
    )
}
AppTreeTable.defaultProps = {
    paddingLeft: 10,
    paddingLevel: 25,
    collapseAllByDef: true,
    onGroupNameClick: () => {
    },
    groupDataRowClassNameCreator: () => ('')
}
const convertToPlainStructure = (data) => {
    const plainStructure = []

    const getRestObjectsFromBranch = (branch) => {
        const {children, groupData, clickable, groupName, ...objects} = branch
        return objects
    }
    const recursiveFillPlainStructure = (branch, level) => {
        branch.forEach(childBranch => {
            const nextLevel = level + 1

            plainStructure.push({
                groupName: childBranch.groupName,
                level,
                isGroup: true,
                clickable: childBranch.clickable,
                ...getRestObjectsFromBranch(childBranch)
            })
            if (childBranch.children !== undefined) {
                recursiveFillPlainStructure(childBranch.children, nextLevel)
            }
            if (childBranch.groupData !== undefined) {
                childBranch.groupData.forEach(data => {
                    // consider group data as children, it may collapse
                    plainStructure.push({
                        groupData: data,
                        groupName: childBranch.groupName,
                        level: nextLevel,
                        isGroup: false
                    })
                })
            }
        })
    }

    recursiveFillPlainStructure(data, 0)
    return plainStructure
}