import React, {useReducer, useMemo, useState, useEffect} from 'react';
import {Header, Table} from "semantic-ui-react";
import styles from "../TableFirstCard/TableFirstCard.module.scss";
import {filterData, sortReducer} from "../TableFirstCard/TableFirstCard";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {replaceNullStateWithNoDataString} from "../TableFourthCard/TableFourthCard";

const TableSecondCardDay = ({data: tableData}) => {
    const [state, dispatch] = useReducer(sortReducer, {
        column: null,
        data: replaceNullStateWithNoDataString(tableData),
        direction: null,
    })
    const { column, data, direction } = state
    const [filteredData, setFilteredData] = useState(data);

    const filterData1 = useMemo(() => filterData(data, "roadName"), []);
    const filterData2 = useMemo(() => filterData(data, "depoName"), []);
    const filterData3 = useMemo(() => filterData(data, "serName"), []);
    const filterData4 = useMemo(() => filterData(data, "nomSec"), []);
    const filterData5 = useMemo(() => filterData(data, "txtDateNr"), []);
    const filterData6 = useMemo(() => filterData(data, "nameNr"), []);
    const filterData7 = useMemo(() => filterData(data, "firmNameRepair"), []);


    const [selectedRowsInFilters, setSelectedRowsInFilters] = useState({
        roadName: filterData1,
        depoName: filterData2,
        serName: filterData3,
        nomSec: filterData4,
        txtDateNr: filterData5,
        nameNr: filterData6,
        firmNameRepair: filterData7,
    });

    useEffect(() => {
        // if (filteredData !== data) {
        //     setFilteredData(data);
        // }
        const newFilteredData = data
            .filter(item => selectedRowsInFilters.roadName.includes(item.roadName))
            .filter(item => selectedRowsInFilters.depoName.includes(item.depoName))
            .filter(item => selectedRowsInFilters.serName.includes(item.serName))
            .filter(item => selectedRowsInFilters.nomSec.includes(item.nomSec))
            .filter(item => selectedRowsInFilters.txtDateNr.includes(item.txtDateNr))
            .filter(item => selectedRowsInFilters.nameNr.includes(item.nameNr))
            .filter(item => selectedRowsInFilters.firmNameRepair.includes(item.firmNameRepair))
        setFilteredData(newFilteredData);
    }, [data, selectedRowsInFilters]);

    const changeSelectedRows = (colName, newData) => {
        setSelectedRowsInFilters(prevState => ({
            ...prevState,
            [colName]: newData,
        }));
    }


    return (
        <div>
            <Header as='h2'>Количество неплановых ремонтов после проведения комиссионного осмотра за сутки</Header>
            <div>
                <Header as='h3'>Фильтрация</Header>
                <div style={{display: "flex", gap: "5px"}} className={styles.filtersWrapper}>
                    <ColumnFilter colName='roadName' title="Дорога" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData1}/>
                    <ColumnFilter colName='depoName' title="Депо" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData2}/>
                    <ColumnFilter colName='serName' title="Серия" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData3}/>
                    <ColumnFilter colName='nomSec' title="Номер" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData4}/>
                    <ColumnFilter colName='txtDateNr' title="Дата НР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData5}/>
                    <ColumnFilter colName='nameNr' title="Причина НР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData6}/>
                    <ColumnFilter colName='firmNameRepair' title="Предприятие ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData7}/>
                </div>
            </div>

            <Table celled>
                <Table.Header className={styles.header}>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell
                            sorted={column === 'roadName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'roadName' })}
                        >Дорога приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'depoName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'depoName' })}
                        >Депо приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'serName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serName' })}
                        >Серия</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'nomSec' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nomSec' })}
                        >Номер</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'txtDateNr' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'txtDateNr' })}
                        >Дата НР</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'nameNr' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nameNr' })}
                        >Причина НР</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'firmNameRepair' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmNameRepair' })}
                        >Предприятие ремонта</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {filteredData?.map((item, index) => (
                        <Table.Row
                            key={index}
                            textAlign={"center"}
                            onClick={() => {

                            }}
                        >
                            <Table.Cell>{item.roadName}</Table.Cell>
                            <Table.Cell>{item.depoName}</Table.Cell>
                            <Table.Cell>{item.serName}</Table.Cell>
                            <Table.Cell>{item.nomSec}</Table.Cell>
                            <Table.Cell>{item.txtDateNr}</Table.Cell>
                            <Table.Cell>{item.nameNr}</Table.Cell>
                            <Table.Cell>{item.firmNameRepair}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TableSecondCardDay;