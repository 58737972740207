import CardContent from '@material-ui/core/CardContent';
import {Typography} from '@material-ui/core';
import React from 'react';
import style from '../boardComponents.module.scss'
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import moment from "moment";

export const Task = ({task}) => {
    let period = task.planStartDate && task.planEndDate ? moment(task.planStartDate).format('DD.MM.YYYY') + ' - ' + moment(task.planEndDate).format('DD.MM.YYYY') : false

    const checkPriority = (value) => {
        return value === 1 ? '#4eb02d' : value === 2 ? '#efec33' : value === 3 ? '#ff1616' : null
    }

    return (
        <CardContent className={style.taskContent}>
            {task.workGroupUserRoleId === 3 ?
                <Typography color="textSecondary" gutterBottom className={style.guestTask}>
                    Гость
                </Typography> : null}
            {/*<User user={task.assignee}/>*/}
            {task.priorityId !== 0 ?
                <PriorityHighIcon style={{fontSize: '1.1em', color: `${checkPriority(task.priorityId)}`}}/> : null}
            {task.hasFiles ? <AttachFileIcon style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}
            {task.hasComments ? <ChatBubbleIcon style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}
            {period ? <span className={style.cardPeriod}>
               {period}
            </span> : null}
        </CardContent>
    );
}
