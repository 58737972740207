import React from 'react';
import cn from "classnames";
import {Modal} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import Widget from "../Widget";

const CommonModal = ({isOpened, setIsOpened, content, header = ""}) => {

  return (
    <Modal
      open={isOpened}
      onClose={() => setIsOpened(false)}
      style={{minWidth: "95%"}}
      className={cn(
        "app shared locomotive-modal",
        "expand-loc-modal"
      )}
    >
      <Widget
        header={header}
        closeIcon
        onClose={() => setIsOpened(false)}
        content={<div style={{minHeight: "300px"}}>{content}</div>}
      />
    </Modal>
  );
};

export default observer(CommonModal);