import React from 'react';
import {Button, Header, List, Modal} from "semantic-ui-react";
import Widget from "../../shared/Widget";
import './AddEventForInconsistencies.scss'
import DateField from "../../shared/fields/DateField";
import TimeField from "../../shared/fields/TimeField";
import moment from 'moment';
import {observer, useLocalStore} from "mobx-react-lite";
import TextAreaField from "../../shared/fields/TextAreaField";
import InputField from "../../shared/fields/InputField";

const AddEventForInconsistencies = observer(({open, onClose, advices, onAdd}) => {
    const ls = useLocalStore(() => ({
        date: moment().startOf("seconds"),
        eventName: "",
        result: "",
        fio: "",
    }))
    return (
        <Modal
            className="app add-event-for-inconsistencies alt-semantic"
            basic open={open} onClose={onClose} size="tiny"
        >
            <Widget
                header="Добавление мероприятия"
                closeIcon
                onClose={onClose}
                footer={(
                    <div className="buttons">
                        <Button
                            className="button-link"
                            content="Добавить"
                            onClick={() => {
                                onAdd({...ls, advices})
                            }}
                        />
                    </div>
                )}
            >
                <TextAreaField
                    label="Мероприятие"
                    fluid
                    value={ls.eventName}
                    onChange={(e) => ls.eventName = e.currentTarget.value}
                />
                <TextAreaField
                    label="Результат"
                    fluid
                    value={ls.result}
                    onChange={(e) => ls.result = e.currentTarget.value}
                />

                <InputField
                    label="Ф.И.О."
                    inputClassName="input-underline" fluid
                    value={ls.fio}
                    onChange={(e, {value}) => ls.fio = value}
                />

                <div className="container">
                    <div className="left-container">
                        <DateField
                            date={ls.date}
                            closable fluid
                            label="Дата"
                            className="date-underline"
                            onChange={(date) => {
                                ls.date = moment(date).hours(ls.date.hours()).minutes(ls.date.minutes())
                            }}
                        />
                    </div>
                    <div className="right-container">
                        <TimeField
                            label="Время"
                            hours={ls.date.hours()}
                            minutes={ls.date.minutes()}
                            fluid
                            onChange={(hours, minutes) => {
                                ls.date = moment(ls.date).hours(hours).minutes(minutes)
                            }}
                        />
                    </div>
                </div>
                <Header as="h4" content="К рекомендациям"/>
                <List
                    divided
                    items={advices.map((advice, index) => ({content: advice.name, key: index}))}
                />
            </Widget>
        </Modal>
    );
})

export default AddEventForInconsistencies;