import React, {useEffect} from 'react';
import MultipleSelectList from "../../fields/MultipleSelectList";
import {observer} from "mobx-react-lite";
import {useStore} from "../StoreProvider";

const MultiSelRemarks = observer(({label, selKey}) => {
    const store = useStore()

    useEffect(() => {
        store.fetchRemarksIfNeed()
    }, [store])

    return (
        <MultipleSelectList
            label={label}
            loading={!store.remarksLoaded}
            editable={true}
            list={store.remarks}
            hideBoxes={true}
            values={store.selected[selKey].remarks}
            onChange={(indexes) => {
                store.selected[selKey].remarks = indexes
            }}
        />
    );
})

export default MultiSelRemarks;