import React from 'react';
import './index.scss'
import {Link} from "react-router-dom";

const PageNotFound = () => {
    return (
        <div className="app views page-not-found">
            <div className="page-not-found-text">Страница не найдена</div>
            <Link to="/" className="back-to-main-link">Вернуться на главную</Link>
        </div>
    );
};

export default PageNotFound;