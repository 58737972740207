import React from "react";
import { Checkbox, Icon, Table } from "semantic-ui-react";
import TableRowsLoader from "../../shared/TableRowsLoader";
import cn from "classnames";
import { formatMoment } from "../../helpers/utils";
import { dateFormats } from "../../Dictionaries";
import styles from "./PredLocomotivesTable.module.scss";

const IsLocVSC = ({ loc }) => {
  if (localStorage.getItem("isLocVSC") === "true" && loc.complVsc === 1) {
    return <Checkbox />;
  } else if (
    localStorage.getItem("isLocVSC") === "true" &&
    loc.complVsc === 0
  ) {
    return (
      <>
        <Icon disabled name="close" color="red" size="big" />
      </>
    );
  } else if (
    localStorage.getItem("isLocVSC") === "false" &&
    loc.complVsc === 2
  ) {
    return "-";
  }

  return "";
};

const PredLocomotivesTable = ({
  locomotives,
  header,
  loading,
  onCellClick,
}) => {
  const getClassRerunCell = (probType) =>
    probType === 1
      ? styles.warning
      : probType === 2
      ? styles.negative
      : styles.positive;

  return (
    <Table celled structured striped unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="center aligned" colSpan={8}>
            {header}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell className="center aligned" style={{ width: 25 }}>
            №
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 120 }}>
            ТПЕ
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 150 }}>
            Приписка
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 80 }}>
            Перепробег
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 150 }}>
            Дислокация
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 115 }}>
            Состояние
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 115 }}>
            Дата
          </Table.HeaderCell>
          <Table.HeaderCell className="center aligned" style={{ width: 115 }}>
            Комплексная готовность к ВСЦ
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <TableRowsLoader
          loading={loading}
          colSpan={7}
          rows={locomotives.map((loc, index) => (
            <Table.Row key={index}>
              <Table.Cell onClick={() => onCellClick(loc)}>
                {index + 1}
              </Table.Cell>
              <Table.Cell
                onClick={() => onCellClick(loc)}
                style={{ width: 165 }}
              >
                {loc.tpeName.replace(/ /gi, "")}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(loc)}>
                {loc.depoPrip}
              </Table.Cell>
              <Table.Cell
                onClick={() => onCellClick(loc)}
                className={cn(
                  getClassRerunCell(loc.probType),
                  "center aligned"
                )}
              >
                {loc.pereprobegRem}
              </Table.Cell>
              <Table.Cell
                onClick={() => onCellClick(loc, true)}
                className={cn(loc.kodDepo && styles.depoLink)}
              >
                {loc.dislocName}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(loc)}>
                {loc.sostName}
              </Table.Cell>
              <Table.Cell onClick={() => onCellClick(loc.tpeId)}>
                {formatMoment(loc.dateOp, dateFormats.upToMinute)}
              </Table.Cell>
              <Table.Cell>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IsLocVSC loc={loc} />
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        />
      </Table.Body>
    </Table>
  );
};
PredLocomotivesTable.defaultProps = {
  loading: false,
};

export default PredLocomotivesTable;
