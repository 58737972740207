
/*
    1- В работе (поездке),
    3- На отдыхе,
    4- На вызове.
*/

const obj = (text, id) => {
    return {id, text}
}
const getPropById = (obj, id) => {
    for (let propName in obj) {
        if (obj[propName].id === id) {
            return obj[propName]
        }
    }
    return {
        id: -1,
        text: '-'
    }
}

export const workerStatus = {
    work: obj('В работе (поездке)', 1),
    rest: obj('На отдыхе', 3),
    onCall: obj('На вызове', 4),
    getById: (id) => getPropById(workerStatus, id)
}