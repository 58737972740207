import React from 'react'
import {Form} from 'semantic-ui-react'
import EventPicker, {FORM_VERSION} from "./EventPicker";
import {observer, useLocalStore} from "mobx-react-lite";
import SearchEmployee from "./SearchEmployee";
import SearchStation from "./SearchStation";
import TextField from "./TextField";

const createStore = () => {
    return {
        formVersion: "",
        eventTypeId: null,
        setEventType(et) {
            this.formVersion = et.formVersion
            this.eventTypeId = et.id
        },
    }
}

const AddPlan = observer(() => {
    const ls = useLocalStore(createStore)

    return (
        <Form loading={false}>
            <EventPicker
                label="Тип мероприятия"
                onChange={(eventType) => {
                    ls.setEventType(eventType)
                }}
            />
            {ls.formVersion === FORM_VERSION.employee && <EmployeeAddForm />}
        </Form>
    )
})

const EmployeeAddForm = () => (<>
    <SearchEmployee label="Сотрудник"/>
    <SearchStation label="Станция начала участка"/>
    <SearchStation label="Станция конца участка"/>
    <SearchStation label="Контрольная станция участка"/>
    <TextField label="Содержание"/>
</>)

export default AddPlan

