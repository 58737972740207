import React from 'react';
import Skeleton from 'react-loading-skeleton'
import { Table } from 'semantic-ui-react'
import { Modal, Grid, Header } from 'semantic-ui-react'
import { Statistic } from 'semantic-ui-react'

const SkeletonPage = (view) => {
    if (view.view === 'GroupRisk') {
        return (
            <Table celled striped>
                <Table.Header  >
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'>Секции группы риска</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned">№</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Серия</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Секция</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Приписка</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Дата окончания планового ремонта</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Вид планового ремонта</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Признак проведения КО</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Количество НР</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Количество отказов</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Количество отказов первой категории</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body >
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='11'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                </Table.Body>
            </Table>)
    } else if (view.view === 'TransferTo') {
        return (
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" style={{ width: 60 }} rowSpan='2'>Приоритет&nbsp;&nbsp;
                        </Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='4'>Локомотив</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='8'>Состояние</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='5'>Плановый ремонт</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='2'>Ближайшее место ремонта&nbsp;
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned">Серия</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Номер</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 125 }}>Приписка</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 70 }}>Вид движения</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 110 }}>Дислокация</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Состояние</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 70 }}>Дата</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Простой<br />в&nbsp;ОЖ.РЕМ</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Критерий<br />простоя&nbsp;&nbsp;
                        </Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Перепробег</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Замечания по тех. состоянию&nbsp;
                        </Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Группа риска</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 70 }}>Дата</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Вид</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">СЛД</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 120 }}>Загрузка</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 70 }}>Разрешит. док-ты
                                        </Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">СЛД</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" style={{ width: 120 }}>Загрузка</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                </Table.Body>
            </Table>)
    } else if (view.view === 'TransferFrom') {
        return (
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" style={{ width: 60 }} rowSpan='2'>№</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='4'>Локомотив</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='10'>Ремонт</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='5'>Приемка</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned">Серия</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Номер</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Приписка</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Вид движения</Table.HeaderCell>

                        <Table.HeaderCell className="center aligned">Депо</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Ремонтная позиция</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Вид</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Простой в ожидании</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Дата начала</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Простой в ремонте</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Норма простоя, ч</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Перепростой</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Причина перепростоя</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Прогнозируемая дата выдачи</Table.HeaderCell>

                        <Table.HeaderCell className="center aligned">Готовность к приемке</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Первое предъявление</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Общее время устранения замечаний</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Общее время ожидания приемки</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Количество предъявлений</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='20'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                </Table.Body>
            </Table>)
    } else if (view.view === 'ModalTransfer') {
        return (
            <Modal className='sld-loading' open={true}>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid centered padded columns='two'>
                            <Grid.Row textAlign='center'>
                                <Grid.Column width={3}>
                                    <Header as='h3'><Skeleton height={10} /></Header>
                                    <Header as='h3'><Skeleton height={10} /></Header>
                                    <Statistic.Group>
                                        <Statistic horizontal>
                                            <Statistic.Value><Skeleton height={10} /></Statistic.Value>
                                            <Statistic.Label><Skeleton height={10} /></Statistic.Label>
                                        </Statistic>
                                        <Statistic horizontal>
                                            <Statistic.Value><Skeleton height={10} /></Statistic.Value>
                                            <Statistic.Label><Skeleton height={10} /></Statistic.Label>
                                        </Statistic>
                                    </Statistic.Group>
                                </Grid.Column>
                                <Grid.Column width={13} style={{ maxWidth: '1000px' }}>
                                    <Grid columns='equal' className='ditches-grid'>
                                        <Grid.Row textAlign='center'>
                                            <Grid.Column textAlign='right' style={{ width: 90, padding: '14px 5px' }}><Skeleton height={10} /></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign='center'>
                                            <Grid.Column textAlign='right' style={{ width: 90, padding: '14px 5px' }}><Skeleton height={10} /></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row textAlign='center'>
                                            <Grid.Column textAlign='right' style={{ width: 90, padding: '14px 5px' }}><Skeleton height={10} /></Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    } else if (view.view === 'ModalGroupRisk') {
        return (
            <Modal centered={false} className={'employee-repairs'} open={true}>
                <Header content={<Skeleton height={10} width={120} />} />
                <Modal.Content scrolling>
                    <Table celled structured striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="center aligned" style={{ width: 130 }}><Skeleton height={10} /></Table.HeaderCell>
                                <Table.HeaderCell className="center aligned" style={{ width: 150 }}><Skeleton height={10} /></Table.HeaderCell>
                                <Table.HeaderCell className="center aligned"><Skeleton height={10} /></Table.HeaderCell>
                                <Table.HeaderCell className="center aligned"><Skeleton height={10} /></Table.HeaderCell>
                                <Table.HeaderCell className="center aligned"><Skeleton height={10} /></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body >
                            <Table.Row >
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                                <Table.Cell className="center aligned"><Skeleton height={10} /></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>)
    } else if (view.view === 'ReceiverRating') {
        return (
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" rowSpan='2'>Дирекция</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" rowSpan='2'>Приёмщик</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" rowSpan='2'>Стаж работы, лет</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='2'>Кол-во неплановых
                                        ремонтов</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='2'>Кол-во отказов</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='2'>Кол-во плановых ремонтов</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='2'>Соотношение НР к ТР</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" colSpan='2'>Соотношение отказов к ТР</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" rowSpan='2'>Рейтинг по НР</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned" rowSpan='2'>Рейтинг по отказам</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned">пред. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">текущ. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">пред. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">текущ. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">пред. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">текущ. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">пред. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">текущ. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">пред. период</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">текущ. период</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='15'><Skeleton height={10} /></Table.HeaderCell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    }

}

export default SkeletonPage;