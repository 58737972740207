import React from 'react'
import {Grid, Checkbox} from 'semantic-ui-react'

export default function TractionFilterSelector(
    {onChange, filter, groupElectricLocomotives, disabled, showAcDc}
) {
	let columns
	if (groupElectricLocomotives) {
		columns = 2
	} else {
		if (showAcDc) {
			columns = 4
		} else {
			columns = 3
		}
	}
    return (
        <Grid>
            <Grid.Row columns={columns} textAlign='center'>
                <Grid.Column>
                    <Checkbox
                        label='Тепловозы'
                        checked={filter.ice}
                        disabled={disabled}
                        onChange={() => {
                            onChange({...filter, ...{ice: !filter.ice}})
                        }}
                    />
                </Grid.Column>
                {groupElectricLocomotives ?
                    <Grid.Column>
                        <Checkbox
                            label='Электровозы'
                            disabled={disabled}
                            checked={filter.electric}
                            onChange={() => {
                                onChange({...filter, ...{electric: !filter.electric}})
                            }}
                        />
                    </Grid.Column>
                    :
                    <>
                        <Grid.Column>
                            <Checkbox
                                label='Эл. переменного тока'
                                disabled={disabled}
                                checked={filter.ac}
                                onChange={() => {
                                    onChange({...filter, ...{ac: !filter.ac}})
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox
                                label='Эл. постоянного тока'
                                disabled={disabled}
                                checked={filter.dc}
                                onChange={() => {
                                    onChange({...filter, ...{dc: !filter.dc}})
                                }}
                            />
                        </Grid.Column>
                        {showAcDc && (
                            <Grid.Column>
                                <Checkbox
                                    label='Эл. двойного питания'
                                    disabled={disabled}
                                    checked={filter.acDc}
                                    onChange={() => {
                                        onChange({...filter, ...{acDc: !filter.acDc}})
                                    }}
                                />
                            </Grid.Column>
                        )}
                    </>
                }
            </Grid.Row>
        </Grid>
    )
}
