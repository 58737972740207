import React from "react";
import ComponentMarker from "../../shared/ComponentMarker";
import ButtonPopupRow from "../../shared/ButtonPopupRow";
import styles from './BindMarker.module.scss'
import appStyles from "../../css/App.module.scss";
import TableForPopup from "../RatingsWorkLocomotives/TableForPopup";
import cn from 'classnames'
/*  общее кол-во явок - jyPlan
 *  кол-во явок запланированных автоподбором - jyAutoPlan
 *  кол-во отказов от предложенной явки - jyRefuse
 *  количество ошибок при автоподборе явок - jyAutoError
 */
export default function BindMarker(
    {
        pointName, plan, autoPlan, refuse, autoError, onAutoErrorClick,
        onAutoPlanClick, ...markerProps
    }
) {
    return (
        <ComponentMarker
            {...markerProps}
        >
            <ButtonPopupRow
                className={styles.buttonRow}
                arrSettings={[
                    {
                        value: plan,
                        callback: undefined,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    data={[
                                        {
                                            text: 'Общее кол-во явок',
                                            unit: 'ед.',
                                            value: plan
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: autoPlan,
                        callback: onAutoPlanClick,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    data={[
                                        {
                                            text: 'Кол-во явок запланированных автоподбором',
                                            unit: 'ед.',
                                            value: autoPlan
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: refuse,
                        callback: undefined,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    data={[
                                        {
                                            text: 'Кол-во отказов от предложенной явки',
                                            unit: 'ед.',
                                            value: refuse
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: autoError,
                        callback: onAutoErrorClick,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    data={[
                                        {
                                            text: 'Количество ошибок при автоподборе явок',
                                            unit: 'ед.',
                                            value: autoError
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                ]}
            />
        </ComponentMarker>
    )
}