import React, {useState, useEffect} from 'react'
import {observer, useLocalStore} from 'mobx-react-lite'
import {useStore} from '../StoreProvider'
import {Modal} from 'semantic-ui-react'
import moment from "moment";
import EditRepair from "../../components/EditRepair";
import {toJS} from "mobx";
import catchNetworkErrors from "../../../../api/catchNetworkErrors";

const TO_2 = 15

const EditPlanModal = observer(({startDate, endDate}) => {
    const store = useStore()
    const selPlan = store.selectedPlanItem
    const [secCount, setSecCount] = useState(null)

    const ls = useLocalStore(() => ({
        date: moment(),
        repairType: {id: null, name: ""}
    }))

    useEffect(() => {
        if (store.showEditModal && selPlan) {
            ls.date = selPlan.repDatePlan
            ls.repairType = {id: selPlan.repairTypeId, name: selPlan.repairName}
            if (ls.repairType.id !== TO_2) {
                store.fetchRepairTypesIfNeed()
            }
        }
    }, [toJS(store.showEditModal)])

    return (
        <Modal
            open={store.showEditModal}
            closeIcon size='small' style={{top: '6%'}}
            onClose={() => store.showEditModal = false}
        >
            <Modal.Content>
                <Modal.Description>
                    <EditRepair
                        loading={store.addLocomotiveLoading}
                        date={ls.date}
                        dateOnChange={(date) => ls.date = date}
                        startDate={startDate}
                        endDate={endDate}
                        sld={{
                            id: selPlan.planRepairPredKod,
                            name: selPlan.planRepPredName
                        }}
                        repairType={ls.repairType}
                        repairTypes={ls.repairType.id !== TO_2 ?
                            // Pass to repair type dropdown all types except TO_2
                            store.repairTypes.filter(rt => rt.key !== TO_2) :
                            // Disable repair type dropdown if repairType is TO_2
                            undefined}
                        repairTypeOnChange={(repairType) => ls.repairType = repairType}
                        series={{
                            id: selPlan.locSer,
                            name: selPlan.serName
                        }}
                        sections={{
                            count: secCount ? secCount : selPlan.secCount,
                            onChange: setSecCount,
                        }}
                        onSave={(sld, repairType, date, sections, series) => {
                            store.fetchAddLocomotive(
                                date,
                                selPlan.locoPlanId,
                                {
                                    /*planDate: date,*/
                                    repairTypeId: repairType.id,
                                    secCount: sections.count,
                                    locSer: series.id,
                                    locNum: selPlan.locNum,
                                    locSec: selPlan.prsLoc,
                                    probeg: selPlan.probeg,
                                    planRepairPredKod: sld.id,
                                }
                            )
                                .then(() => {
                                    store.showEditModal = false
                                    store.refreshTable()
                                    store.selectedRow = null
                                })
                                .catch(catchNetworkErrors)
                        }}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
})

export default EditPlanModal