
export const normalizeData = ({data}) => {

  let newData = []

  const map = {
    lvl1: null,
    lvl2: null,
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i]['lvl'] === 1) {

      if ('lvl1' in map) {
        map.lvl2 = null;
      }

      let normalizeData;
      const formatDate = data[i].remVpskDate
        ? new Date(data[i].remVpskDate).toLocaleString()
        : null

      if(data[i+1] && data[i+1]['lvl'] && data[i+1]['lvl'] === 2) {
        normalizeData = {...data[i], remVpskDate: formatDate, key: i, children: []}
      } else {
        normalizeData = {...data[i], remVpskDate: formatDate, key: i}
      }

      map.lvl1 = newData.push(normalizeData) - 1;
    }

    if(data[i]['lvl'] === 2) {
      let normalizeData;

      const formatDate = data[i].remVpskDate
        ? new Date(data[i].remVpskDate).toLocaleString()
        : null

      if (data[i+1] && data[i+1]['lvl'] && data[i+1]['lvl'] === 3 ) {



        normalizeData = {...data[i], remVpskDate: formatDate, key: i, children: []}
      } else {
        normalizeData = {...data[i], remVpskDate: formatDate, key: i}
      }

      map.lvl2 = newData[map.lvl1].children.push(normalizeData) - 1;
    }

    if (data[i]['lvl'] === 3) {

      const formatDate = data[i].remVpskDate
        ? new Date(data[i].remVpskDate).toLocaleString()
        : null

      const normalizeData = {...data[i], remVpskDate: formatDate, key: i}
      newData[map.lvl1].children[map.lvl2].children.push(normalizeData)
    }
  }

  return newData;
}