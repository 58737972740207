import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import style from "./DownloadModal.module.scss";
import ekasut from "../../../../../../api/ekasut";

export const DownloadModal = ({
  openDownload,
  setOpenDownload,
  files,
  taskData,
  setTaskData,
}) => {
  const [loading, setLoading] = useState(false);
  const [allIds, setAllIds] = useState([]);
  const [fileObjects, setFileObjects] = useState(
    files.length
      ? files.map((el) => ({
          file: {
            name: el.fileName,
          },
          data: el.fileId,
          fileId: el.fileId,
        }))
      : []
  );

  console.log(fileObjects);

  const downloadFile = async (filesArr) => {
    let filesData = await Promise.all(
      filesArr.map(async (el) => {
        const findEl = files.find((initEl) => initEl.data === el.data);
        if (findEl) {
          return findEl.fileId;
        } else {
          try {
            const fileId = await ekasut.file.postFile(el?.file);
            console.log(el?.file);
            return fileId.data;
          } catch (e) {
            console.log(e);
            return el;
          }
        }
      })
    );
    updateTaskFiles(filesData);
  };

  const updateTaskFiles = (ids) => {
    setLoading(true);
    ekasut.taskboard
      .updateTaskFiles(taskData.workGroupId, taskData.taskNumber, ids)
      .then(({ data }) => {
        setOpenDownload(false);
        setLoading(false);
        setTaskData(data);
      });
  };

  return (
    <Dialog
      open={openDownload}
      onClose={() => {
        setOpenDownload(false);
      }}
    >
      <DialogTitle>Загрузка и удаление файлов</DialogTitle>
      <DialogContent>
        <DropzoneAreaBase
          filesLimit={10}
          getFileAddedMessage={(fileName) => {
            return `Файл ${fileName} успешно загружен`;
          }}
          getFileRemovedMessage={(fileName) => {
            return `Файл ${fileName} успешно удален`;
          }}
          useChipsForPreview
          dropzoneText={"Перетащите файлы или кликните сюда"}
          onAdd={(fileObjs) => {
            if (!fileObjects.find((el) => el.data === fileObjs[0].data)) {
              setFileObjects((prevState) => [...prevState, ...fileObjs]);
            }
          }}
          onDelete={(fileObj) => {
            setFileObjects((prevState) =>
              prevState.filter((el) => el.data !== fileObj.data)
            );
          }}
          fileObjects={fileObjects}
          dropzoneClass={style.dropFileClass}
        />
      </DialogContent>
      <DialogActions className={style.actionBtns}>
        <Button
          type="text"
          onClick={() => {
            setOpenDownload(false);
          }}
        >
          Отмена
        </Button>
        <Button
          loading={loading}
          type="primary"
          style={{ marginRight: "16px" }}
          ghost
          onClick={() => {
            downloadFile(fileObjects);
          }}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
