import React, { useRef } from "react";

/** Save file to user. Try to use AttachFileButton first <br/>
 *
 * @return HiddenLink - should be rendered <br/>
 *         download - save data as file to user  */
export default function useFileDownload() {
  const ref = useRef(null);
  const HiddenLink = () => (
    <a
      href="/#"
      ref={ref}
      style={{ display: "none" }}
      onClick={(e) => e.stopPropagation()}
    >
      hidden download link
    </a>
  );

  const download = (data, name, type = "application/octet-stream") => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    ref.current.href = url;
    ref.current.download = name;
    ref.current.click();
    window.URL.revokeObjectURL(url);
  };

  return [download, HiddenLink];
}
