import React, { useEffect, useMemo } from "react";
import Widget from "../../../shared/Widget";
import "./PolygonFilter.scss";
import { Button, Checkbox, Grid, Header } from "semantic-ui-react";
import DropdownField from "../../../shared/fields/DropdownField";
import { observer, useLocalStore } from "mobx-react-lite";
import useDidFirstRender from "../../../hooks/useDidFirstRender";
import ekasut from "../../../api/ekasut";
import catchNetworkErrors from "../../../api/catchNetworkErrors";
import InputField from "../../../shared/fields/InputField";
import { regex, replaceNull } from "../../../helpers/utils";
import {
  mileageOptions,
  motionModeOptions,
  motionTypeOptions,
  parkTypeEpOptions,
  parkTypeNepOptions,
  parkTypes,
  sections,
  typeOfJobOptions,
  weightStandardOptions,
  workOnWaitList,
} from "../../../Dictionaries";
import coordsSearch from "./dictionary";
import DateField from "../../../shared/fields/DateField";
import { observable, toJS } from "mobx";
import auth from "../../../helpers/auth";
import TemplateModal from "./TemplateModal";
import moment from "moment";
// import DepotField from "../../shared/DepotField";
import MultiplePredsField from "../../../shared/MultiplePredsField";

const PolygonFilter = observer(({ onApply, show, onMap, onTable }) => {
  const initFilter = () => ({
    coordsSearch: coordsSearch.gipAsoup.id, // 0(по умолчанию) - искать по АСОУП и по ГИП, 1 - искать только по АСОУП, 2 - искать только по ГИП
    stanName: "", // Имя станции или перегона
    locNum: "", // Номера локомотивов
    locSer: [], // Серии локомотивов
    depoPrip: [], // Депо приписки
    parkTypeId: "all",
    parkTypeEp: [],
    parkTypeNep: [],
    tyagaType: [], // Виды тяги
    vidDv: [], // Виды движения
    probeg: [], // Виды пробегов
    repairType: [], // Виды ремонтов
    secCount: [], // Массив кол-ва секций для выборки
    vidOtklNorm: [], // Массив соответствия весовому нормативу
    workOnWait: [], // Код состояния локомотивов расходующие топливо в простое
    isSick: false, // Если true - показывает локомотивы, находящиеся в списке больных
    notInOperativeRepair: false, // Признак нахождения в оперативном ремонте
    waitMoreThen: "",
    isLocVSC: "",

    typeOfJob: [],
    requestDate: "",

    showServiceCompaniesInfo: false,
    showOnMap: true,
    showComments: false,
    showUseType: false,
  });

  const getFilterParams = (filter) =>
    toJS(
      {
        CoordsSearch: filter.coordsSearch,
        stanName: filter.stanName,
        Limit: undefined, // Ограничение по кол-ву локомотивов в результате
        LocNum: filter.locNum,
        LocSer: filter.locSer,
        DepoPrip: filter.depoPrip,
        Park: (() => {
          if (filter.parkTypeId === "all") {
            return null;
          } else if (filter.parkTypeId === "nep") {
            const jobNep = [];
            if (filter.typeOfJob.length > 0 && filter.parkTypeNep.length > 0) {
              filter.typeOfJob.forEach((toj) => {
                filter.parkTypeNep.forEach((ptn) =>
                  jobNep.push(`${toj}${ptn}`)
                );
              });
            } else if (
              filter.typeOfJob.length > 0 &&
              filter.parkTypeNep.length === 0
            ) {
              filter.typeOfJob.forEach((toj) => jobNep.push(`${toj}`));
            } else if (
              filter.parkTypeNep.length > 0 &&
              filter.typeOfJob.length === 0
            ) {
              filter.parkTypeNep.forEach((ptn) => jobNep.push(`${ptn}`));
              console.log();
            } else {
              typeOfJobOptions.forEach((toj) => {
                parkTypeNepOptions.forEach((ptn) =>
                  jobNep.push(`${toj.value}${ptn.value}`)
                );
              });
            }
            return jobNep;
          } else {
            const jobEp = [];
            if (filter.parkTypeEp.length > 0) {
              filter.parkTypeEp.forEach((pte) => jobEp.push(`${pte}`));
            } else {
              parkTypeEpOptions.filter((allPte) =>
                jobEp.push(`${allPte.value}`)
              );
            }
            return jobEp;
          }
        })(),
        TyagaType: filter.tyagaType,
        VidDv: filter.vidDv,
        Probeg: filter.probeg,
        RepairType: filter.repairType,
        SecCount: filter.secCount,
        VidOtklNorm: filter.vidOtklNorm,
        KodSost: filter.workOnWait,
        isLongWait: undefined, // Если локомотив стоит больше 3х часов
        isSick: filter.isSick,
        IsOverWeight: undefined, // Признак перевеса на перегоне
        isWorkOnWait: filter.workOnWait.length > 0,
        notInOperativeRepair: filter.notInOperativeRepair,
        WaitMoreThen: filter.waitMoreThen,
        IsLocVSC: filter.isLocVSC,
      },
      { recurseEverything: true }
    );

  const ls = useLocalStore(() => ({
    seriesOptions: null,
    predOptions: null,
    needForRepairOptions: null,
    templateModal: { open: false, template: "" },
    templateOptions: [],
    templates: observable.map(auth.getPolygonSchemeTemplates()),
    filter: initFilter(),
  }));

  const templateOptions = useMemo(() => {
    const options = [];
    ls.templates.forEach((value, key) => {
      options.push({ text: key, value: key, key });
    });
    return options;
  }, [ls.templates.size]);
  const workOnWaitOptions = useMemo(
    () =>
      workOnWaitList.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.text,
      })),
    []
  );
  const parkTypesOptions = useMemo(
    () =>
      parkTypes.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.text,
      })),
    []
  );
  const sourceDislocationOptions = useMemo(
    () =>
      [coordsSearch.gip, coordsSearch.asoup, coordsSearch.gipAsoup].map(
        (item) => ({ text: item.name, key: item.id, value: item.id })
      ),
    []
  );

  useDidFirstRender(() => {
    ekasut.locomotivesNsi
      .getSerTps()
      .then(({ data }) => (ls.seriesOptions = data))
      .catch(catchNetworkErrors);
    ekasut.locomotivesNsi
      .getTypes()
      .then(({ data }) => (ls.needForRepairOptions = data))
      .catch(catchNetworkErrors);
    ekasut.ekasutData.getCtTree().then(
      ({ data }) =>
        (ls.predOptions = data.map((pred) => ({
          value: pred.value,
          key: pred.key,
          text: pred.text,
          className:
            pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
        })))
    );
  });

  if (show !== true) {
    return null;
  }

  return (
    <Widget
      closeIcon
      onClose={() => {
        onMap();
      }}
      className="app polygon-scheme polygon-filter"
      footer={
        <div className="control-buttons">
          <Button
            content="Сброс"
            className="button-link"
            onClick={() => {
              ls.filter = initFilter();
              ls.templateModal.template = "";
            }}
          />
          <Button
            content="Сохранить шаблон (локально)"
            className="button-link"
            onClick={() => {
              ls.templateModal.open = true;
            }}
          />
          <Button
            content="Применить фильтр"
            className="button-primary"
            onClick={() => {
              onApply(getFilterParams(ls.filter), {
                showOnMap: ls.filter.showOnMap,
              });
              localStorage.setItem("isLocVSC", ls.filter.isLocVSC);
            }}
          />
        </div>
      }
    >
      <TemplateModal
        open={ls.templateModal.open}
        onClose={() => {
          ls.templateModal.open = false;
        }}
        templateName={ls.templateModal.template}
        onSave={(templateName) => {
          ls.templateModal.open = false;
          ls.templateModal.template = templateName;
          ls.templates.set(
            templateName,
            toJS(ls.filter, { recurseEverything: true })
          );
          auth.setPolygonSchemeTemplates(
            toJS(ls.templates.toJSON(), { recurseEverything: true })
          );
        }}
      />
      <Grid columns={4} padded className="filter-grid" stackable>
        <Grid.Column>
          <Header
            as="h4"
            content="Параметры запроса"
            className="filter-header"
          />
          <DropdownField
            label="Серия локомотива"
            multiple
            search
            fluid
            clearable
            options={[...replaceNull(ls.seriesOptions, [])]}
            value={[...ls.filter.locSer]}
            onChange={(e, { value }) => {
              ls.filter.locSer = value;
            }}
          />
          <InputField
            label="Номер локомотива"
            fluid
            value={ls.filter.locNum}
            onChange={(e, { value }) => {
              if (regex.naturalNumber.test(value)) {
                ls.filter.locNum = value;
              }
            }}
          />
          <InputField
            value={ls.filter.stanName}
            label="Станция"
            fluid
            onChange={(e, { value }) => {
              ls.filter.stanName = value;
            }}
          />
          <MultiplePredsField
            label="Организация приписки"
            fluid
            value={[...ls.filter.depoPrip]}
            onChange={(e, { value }) => {
              ls.filter.depoPrip = value;
            }}
          />
          <DropdownField
            label="Вид тяги"
            multiple
            search
            fluid
            clearable
            value={[...ls.filter.tyagaType]}
            options={motionTypeOptions}
            onChange={(e, { value }) => {
              ls.filter.tyagaType = value;
            }}
          />
          <DropdownField
            label="Секционность"
            multiple
            search
            fluid
            clearable
            options={sections}
            value={[...ls.filter.secCount]}
            onChange={(e, { value }) => {
              ls.filter.secCount = value;
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Header
            as="h4"
            content="Атрибуты локомотива"
            className="filter-header"
          />
          <DropdownField
            label="Локомотивы с ВСЦ"
            fluid
            value={ls.filter.isLocVSC}
            options={[
              {
                key: 1,
                value: "",
                text: "Ничего",
              },
              {
                key: 2,
                value: "false",
                text: "Не оборудован",
              },
              {
                key: 3,
                value: "true",
                text: "Оборудован",
              },
            ]}
            onChange={(e, { value }) => {
              ls.filter.isLocVSC = value;
            }}
          />
          <DropdownField
            label="Вид движения"
            multiple
            search
            fluid
            clearable
            options={motionModeOptions}
            value={[...ls.filter.vidDv]}
            onChange={(e, { value }) => {
              ls.filter.vidDv = value;
            }}
          />
          <DropdownField
            label="Потребность в ремонте"
            multiple
            search
            fluid
            clearable
            options={[...replaceNull(ls.needForRepairOptions, [])]}
            value={[...ls.filter.repairType]}
            onChange={(e, { value }) => {
              ls.filter.repairType = value;
            }}
          />
          <DropdownField
            label="Пробег"
            multiple
            search
            fluid
            clearable
            options={mileageOptions}
            value={[...ls.filter.probeg]}
            onChange={(e, { value }) => {
              ls.filter.probeg = value;
            }}
          />
          <DropdownField
            label="Расходующие топливо"
            multiple
            search
            fluid
            clearable
            options={workOnWaitOptions}
            value={[...ls.filter.workOnWait]}
            onChange={(e, { value }) => {
              ls.filter.workOnWait = value;
            }}
          />
          <InputField
            label="Простой более, ч."
            fluid
            maxLength={3}
            value={ls.filter.waitMoreThen}
            onChange={(e, { value }) => {
              if (regex.naturalNumber.test(value)) {
                ls.filter.waitMoreThen = value;
              }
            }}
          />
          <DropdownField
            label="Соответствие весовому нормативу"
            multiple
            search
            fluid
            clearable
            options={weightStandardOptions}
            value={[...ls.filter.vidOtklNorm]}
            onChange={(e, { value }) => {
              ls.filter.vidOtklNorm = value;
            }}
          />
          <Checkbox
            label="Группа риска"
            checked={ls.filter.isSick}
            style={{ display: "block" }}
            onChange={(e, { checked }) => {
              ls.filter.isSick = checked;
            }}
          />
          <Checkbox
            label="Локомотивы с отклонением от графика ремонтов"
            // checked={ls.filter.notInOperativeRepair}
            // style={{ display: "block" }}
            // onChange={(e, { checked }) => {
            //     ls.filter.notInOperativeRepair = checked
            // }}
          />

          {/*<InputField
                        label="Обслуживающая организация" fluid
                    />*/}
        </Grid.Column>
        <Grid.Column>
          <Header as="h4" content="Атрибуты парка" className="filter-header" />
          <DropdownField
            label="Вид парка"
            fluid
            value={ls.filter.parkTypeId}
            options={parkTypesOptions}
            onChange={(e, { value }) => {
              ls.filter.parkTypeId = value;
            }}
          />
          {ls.filter.parkTypeId === "ep" && (
            <DropdownField
              label="Категория парка"
              multiple
              search
              fluid
              clearable
              options={parkTypeEpOptions}
              value={[...ls.filter.parkTypeEp]}
              onChange={(e, { value }) => {
                ls.filter.parkTypeEp = value;
              }}
            />
          )}
          {ls.filter.parkTypeId === "nep" && (
            <>
              <DropdownField
                label="Категория парка"
                multiple
                search
                fluid
                clearable
                options={parkTypeNepOptions}
                value={[...ls.filter.parkTypeNep]}
                onChange={(e, { value }) => {
                  ls.filter.parkTypeNep = value;
                }}
              />
              <DropdownField
                label="Род работы"
                multiple
                search
                fluid
                clearable
                options={typeOfJobOptions}
                value={[...ls.filter.typeOfJob]}
                onChange={(e, { value }) => {
                  ls.filter.typeOfJob = value;
                }}
              />
            </>
          )}
        </Grid.Column>
        <Grid.Column>
          <Header
            as="h4"
            content="Дополнительные параметры"
            className="filter-header"
          />
          <DropdownField
            label="Шаблоны запросов"
            fluid
            clearable
            value={ls.templateModal.template}
            options={templateOptions}
            placeholder="Выбрать шаблон"
            onChange={(e, { value }) => {
              ls.templateModal.template = value;
              if (ls.templates.get(value)) {
                ls.filter = {
                  ...toJS(ls.templates.get(value), { recurseEverything: true }),
                  requestDate:
                    ls.templates.get(value).requestDate === ""
                      ? ""
                      : moment(ls.templates.get(value).requestDate),
                };
              }
            }}
          />
          <DropdownField
            label="Источник данных по дислокации"
            fluid
            options={sourceDislocationOptions}
            value={ls.filter.coordsSearch}
            onChange={(e, { value }) => {
              ls.filter.coordsSearch = value;
            }}
          />
          <DateField
            label="Дата запроса"
            date={ls.filter.requestDate}
            fluid
            closable
            onChange={(date) => {
              ls.filter.requestDate = date;
            }}
          />
          <Checkbox
            label="Отображать на карте"
            checked={ls.filter.showOnMap}
            onChange={(e, { checked }) => {
              ls.filter.showOnMap = checked;
            }}
          />
          {/*<Checkbox
                        label="Показывать сведения о сервисных компаниях"
                        checked={ls.filter.showServiceCompaniesInfo}
                        onChange={(e, {checked}) => {
                            ls.showServiceCompaniesInfo = checked
                        }}
                    />
                    <Checkbox
                        label="Показывать комментарии"
                        checked={ls.filter.showComments}
                        onChange={(e, {checked}) => {
                            ls.showComments = checked
                        }}
                    />
                    <Checkbox
                        label="Показывать вид использования"
                        checked={ls.filter.showUseType}
                        onChange={(e, {checked}) => {
                            ls.showUseType = checked
                        }}
                    />*/}
        </Grid.Column>
      </Grid>
    </Widget>
  );
});

export default PolygonFilter;
