import React from 'react'
import AppTable, {convertToDate} from "../../shared/AppTable";

export default function ErrorDetailTable({data}) {
    return (
        <AppTable
            structure={structure}
            data={data}
        />
    )
}

const structure = [
    {header: 'Депо приписки', field: 'depotFullName'},
    {header: 'Дата автоподбора явки', field: 'dateAutoYavk', converter: convertToDate},
    {header: 'Табельный номер', field: 'asutrId'},
    {header: 'ФИО', field: 'mashStr'},
    {header: 'Вид движения машиниста', field: 'moveStr'},
    {header: 'Текст причины/ошибки', field: 'reasonStr'},
]