import React, { useEffect, useRef } from 'react';
import { Button, Label, Modal, Segment, Table, Pagination, Header, Accordion, Icon, Message } from "semantic-ui-react";
import styles from './AnalyticalReferenceModal.module.scss'
import { observer, useLocalStore } from "mobx-react-lite";
import JustifyContainer from "../../shared/JustifyContainer";
import LabeledDropdown from "../../shared/LabeledDropdown";
import LabeledDateInput from "../../shared/LabeledDateInput";
import moment from 'moment'
import { formatMoment, formOptions, padStartZero, replaceNull, resetHMS } from "../../helpers/utils";
import apiWorker from "../../api/apiWorker";
import dbSync from "../../api/dbSync";
import ekasut from '../../api/ekasut'
import useDidFirstRender from "../../hooks/useDidFirstRender";
import TableRowsLoader from "../../shared/TableRowsLoader";
import { dateFormats } from "../../Dictionaries";
import appStyles from '../../css/App.module.scss'
import cn from 'classnames'
import Widget from "../../shared/Widget";
import DateField from "../../shared/fields/DateField";
import TimeField from "../../shared/fields/TimeField";
import InputField from "../../shared/fields/InputField";
import DepotField from "../../shared/DepotField";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import { runInAction, toJS } from "mobx";
import LoaderMessage from "../../shared/LoaderMessage";
import { downloadBlob } from "../../helpers/utils";

const AnalyticalReferenceModal = observer(({ open, onClose }) => {
    const ls = useLocalStore(() => ({
        showLocResvTime: false,
        showLocResvTimeWithMileage: false,
        showViolChain: false,
        showRemToNr: false,
        showCountCases: false,
        showCargoUsedAsPass: false,
        showLocOnRailways: false,
        openCountCases: details => {
            ls.countCasesDetails = details
            ls.showCountCases = true
        },
        roads: [],
        countCasesDetails: [],
        seriesOptions: [],
        depotsOptions: [],
        activeTabs: {
            nep: false,
            ep: false,
        },
        showNepToNp: false,
        roadsApi: apiWorker(dbSync.reports.getRoads, {
            onSuccess: data => ls.roads = data,
        }),
        seriesApi: apiWorker(ekasut.locomotivesNsi.getSerTps, {
            onSuccess: data => ls.seriesOptions = data
        }),
        depotsApi: apiWorker(ekasut.ekasutData.getCtTree, {
            onSuccess: data => {
                ls.depotsOptions = data.map(pred => ({
                    value: pred.value,
                    key: pred.key,
                    text: pred.text,
                    className: pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
                    disabled: pred.level === 1
                }))
            },
        }),
    }))

    const handleTabClick = (e, titleProps) => {
        ls.activeTabs[titleProps.index] = !ls.activeTabs[titleProps.index]
    }

    return (<>
        <Modal
            open={open}
            onClose={onClose}
            className={styles.modal}
            centered
        >
            <Segment padded style={{ marginTop: 0 }}>
                <Label attached="top" content="Аналитическая справка" size="large" style={{ color: '#ffffff', background: '#709ac2' }} />
                <Accordion>
                    <Accordion.Title active={ls.activeTabs.nep} onClick={handleTabClick} index="nep">
                        <Icon name='dropdown' />
                        Неэксплуатируемый парк
                    </Accordion.Title>
                    <Accordion.Content active={ls.activeTabs.nep}>
                        <Segment.Group>
                            <Segment
                                content="Справка по учету времени нахождения локомотива в резерве ЦТ"
                                onClick={() => ls.showLocResvTime = true}
                            />
                            <Segment
                                content="Справка по локомотивам в резерве ЦТ с перепробегами"
                                onClick={() => ls.showLocResvTimeWithMileage = true}
                            />
                            <Segment
                                content="Справка по учету случаев нарушений порядка постановки локомотивов в резерв ЦТ"
                                onClick={() => ls.showViolChain = true}
                            />
                            <Segment
                                content="Справка по учету случаев постановки локомотивов на неплановый ремонт после проведения им планового ТО, ТР."
                                onClick={() => ls.showRemToNr = true}
                            />
                            <Segment
                                content="Справка о многократном переводе из НЭП в ЭП"
                                onClick={() => ls.showNepToNp = true}
                            />
                        </Segment.Group>
                    </Accordion.Content>
                    <Accordion.Title active={ls.activeTabs.ep} onClick={handleTabClick} index="ep">
                        <Icon name="dropdown" />
                        Эксплуатируемый парк
                    </Accordion.Title>
                    <Accordion.Content active={ls.activeTabs.ep}>
                        <Segment.Group>
                            <Segment
                                content="Справка о грузовых локомотивах выданных под пассажирские поезда"
                                onClick={() => ls.showCargoUsedAsPass = true}
                            />
                            <Segment
                                content="Учет нахождения частных локомотивов на путях ТЧЭ"
                                onClick={() => ls.showLocOnRailways = true}
                            />
                        </Segment.Group>
                    </Accordion.Content>
                </Accordion>
            </Segment>
        </Modal>
        <LocReserveTime
            open={ls.showLocResvTime}
            onClose={() => ls.showLocResvTime = false}
            roadsApi={ls.roadsApi}
            roads={ls.roads}
        />
        <LocReserveTimeWithMileage
            open={ls.showLocResvTimeWithMileage}
            onClose={() => ls.showLocResvTimeWithMileage = false}
            roadsApi={ls.roadsApi}
            roads={ls.roads}
        />
        <ViolationCases
            open={ls.showViolChain}
            onClose={() => ls.showViolChain = false}
            roadsApi={ls.roadsApi}
            roads={ls.roads}
        />
        <RemToNr
            open={ls.showRemToNr}
            onClose={() => ls.showRemToNr = false}
            roadsApi={ls.roadsApi}
            roads={ls.roads}
            onRowClick={(item) => ls.openCountCases(item.details)}
        />
        <NepToNp
            open={ls.showNepToNp}
            onClose={() => ls.showNepToNp = false}
            roadsApi={ls.roadsApi}
            roads={ls.roads}
            onRowClick={(item) => ls.openCountCases(item.details)}
        />
        <CargoUsedAsPass
            open={ls.showCargoUsedAsPass}
            onClose={() => ls.showCargoUsedAsPass = false}
            roadsApi={ls.roadsApi}
            roads={ls.roads}
        />
        <CountCases
            open={ls.showCountCases}
            onClose={() => {
                ls.showCountCases = false
                ls.countCasesDetails = []
            }}
            details={ls.countCasesDetails}
        />
        <LocOnRailways
            open={ls.showLocOnRailways}
            onClose={() => {
                ls.showLocOnRailways = false
            }}
        />
    </>)
})


const LocReserveTime = observer(({ open, onClose, roadsApi, roads }) => {
    const ls = useLocalStore(() => ({
        api: apiWorker(ekasut.nepReports.getReserv)
    }))

    const modalRef = useRef(null)

    return (
        <ARModal
            header="Справка по учету времени нахождения локомотива в резерве ЦТ"
            open={open}
            modalRef={modalRef}
            onClose={onClose}
        >
            <ARForm
                pageLength={30}
                filters={[{
                    name: "Депо приписки",
                    fieldId: "predPrip",
                    fieldName: "predPripName"
                }, {
                    name: "Серия",
                    fieldId: "serLoc",
                    fieldName: "serName"
                }]}
                roadsApi={roadsApi}
                roads={roads}
                headers={[
                    "Депо приписки",
                    "Номер локомотива",
                    "Серия локомотива",
                    "Номер секции",
                    "Дата постановки в резерв",
                    "Дата выдачи из резерва",
                    "Время в резерве, сут."
                ]}
                converterToRowContent={(item) => ([
                    item.predPripName,
                    item.znsLoc,
                    item.serName,
                    item.prsLoc,
                    formatMoment(item.reservBegin, dateFormats.upToMinute),
                    formatMoment(item.reservEnd, dateFormats.upToMinute),
                    item.reservDays,
                ])}
                onFetchData={({ road, from, to }) => ls.api.fetch({
                    roadId: road.id,
                    fromDate: from.format("YYYY-MM-DD"),
                    toDate: to.format("YYYY-MM-DD")
                })}
                fetchDataLoading={ls.api.loading}
                onPageChange={() => {
                    scrollTo(modalRef, 0, 0)
                }}
            />
        </ARModal>
    )
})

const LocReserveTimeWithMileage = observer(({ open, onClose, roadsApi, roads }) => {
    const ls = useLocalStore(() => ({
        api: apiWorker(ekasut.nepReports.getReservWithOverrun)
    }))

    const modalRef = useRef(null)

    return (
        <ARModal
            header="Справка по локомотивам в резерве ЦТ с перепробегами"
            open={open}
            modalRef={modalRef}
            onClose={onClose}
        >
            <ARForm
                resWithMileage={true}
                pageLength={30}
                roadsApi={roadsApi}
                roads={roads}
                headers={[
                    "Депо приписки",
                    "Номер локомотива",
                    "Серия локомотива",
                    "Номер секции",
                    "Дата постановки в резерв",
                    "Перепробег",
                ]}
                converterToRowContent={(item) => ([
                    item.depoPripName,
                    item.znsLoc,
                    item.serLocName,
                    item.prsLoc,
                    formatMoment(item.dateOp, dateFormats.upToMinute),
                    item.repairOverrun
                ])}

                onFetchData={({ road, from }) => ls.api.fetch({
                    dorId: road.id,
                    date: from.format("YYYY-MM-DD"),
                })}
                fetchDataLoading={ls.api.loading}
                onPageChange={() => {
                    scrollTo(modalRef, 0, 0)
                }}
            />
        </ARModal>
    )
})

const ViolationCases = observer(({ open, onClose, roadsApi, roads }) => {
    const ls = useLocalStore(() => ({
        api: apiWorker(ekasut.nepReports.getViolChain)
    }))

    const modalRef = useRef(null)

    return (
        <ARModal
            header="Справка по учету случаев нарушений порядка постановки локомотивов в резерв ЦТ"
            open={open}
            modalRef={modalRef}
            onClose={onClose}
        >
            <ARForm
                pageLength={30}
                filters={[{
                    name: "Депо приписки",
                    fieldId: "depoPredId",
                    fieldName: "depoName"
                }, {
                    name: "Серия",
                    fieldId: "serLoc",
                    fieldName: "serName"
                }]}
                roadsApi={roadsApi}
                roads={roads}
                headers={[
                    "Депо приписки",
                    "Номер локомотива",
                    "Серия локомотива",
                    "Номер секции",
                    "Дата постановки в резерв",
                    "Состояние до постановки в резерв",
                    "Дата выдачи из резерва",
                    "Состояние после выдачи из резерва",
                ]}
                converterToRowContent={(item) => ([
                    item.depoName,
                    item.znsLoc,
                    item.serName,
                    item.prsLoc,
                    formatMoment(item.reservDate, dateFormats.upToMinute),
                    item.beforeSostName,
                    formatMoment(item.afterDate, dateFormats.upToMinute),
                    item.afterSostName,
                ])}
                onFetchData={({ road, from, to }) => ls.api.fetch({
                    roadId: road.id,
                    fromDate: from.format("YYYY-MM-DD"),
                    toDate: to.format("YYYY-MM-DD")
                })}
                fetchDataLoading={ls.api.loading}
                onPageChange={() => {
                    scrollTo(modalRef, 0, 0)
                }}
            />
        </ARModal>
    )
})

const RemToNr = observer(({ open, onClose, roadsApi, roads, onRowClick }) => {
    const ls = useLocalStore(() => ({
        api: apiWorker(ekasut.nepReports.getRemToNr)
    }))

    const modalRef = useRef(null)

    return (
        <ARModal
            header="Справка по учету случаев постановки локомотивов на неплановый ремонт после проведения им планового ТО, ТР."
            open={open}
            modalRef={modalRef}
            onClose={onClose}
        >
            <ARForm
                pageLength={30}
                seriesFields={{ id: "locSer", name: "locSer" }}
                depotFields={{ id: "predId", name: "predName" }}
                filters={[{
                    name: "Депо приписки",
                    fieldId: "predId",
                    fieldName: "predName"
                }, {
                    name: "Серия",
                    fieldId: "locSer",
                    fieldName: "locSer"
                }]}
                roadsApi={roadsApi}
                roads={roads}
                headers={[
                    "Депо приписки",
                    "Серия локомотива",
                    "Номер локомотива",
                    "Номер секции",
                    "Случаи перевода в НР после ТО/ТР",
                ]}
                converterToRowContent={(item) => ([
                    item.predName,
                    item.locSer,
                    item.locZns,
                    item.locPrs,
                    item.count,
                ])}
                onFetchData={({ road, from, to }) => ls.api.fetch({
                    roadId: road.id,
                    fromDate: from.format("YYYY-MM-DD"),
                    toDate: to.format("YYYY-MM-DD")
                })}
                fetchDataLoading={ls.api.loading}
                onPageChange={() => {
                    scrollTo(modalRef, 0, 0)
                }}
                clickable={true}
                onRowClick={onRowClick}
            />
        </ARModal>
    )
})

const NepToNp = observer(({ open, onClose, roadsApi, roads, onRowClick }) => {
    const ls = useLocalStore(() => ({
        api: apiWorker(ekasut.nepReports.getNepToNpChains)
    }))

    const modalRef = useRef(null)

    return (
        <ARModal
            header="Справка о многократных переводах из НЭП в ЭП"
            open={open}
            modalRef={modalRef}
            onClose={onClose}
        >
            <ARForm
                pageLength={30}
                roadsApi={roadsApi}
                roads={roads}
                clickable
                headers={[
                    "ТЧЭ/СЛД дислокация",
                    "Депо приписки",
                    "Серия",
                    "Номер/секция",
                    "Количество случаев переводов из НЭП в ЭП без выхода из депо",
                ]}
                converterToRowContent={(item) => ([
                    item.pripPredName,
                    item.predName,
                    item.locSer,
                    item.locNum,
                    item.count
                ])}
                onFetchData={({ road, from, to }) => ls.api.fetch({
                    roadId: road.id,
                    fromDate: from.format("YYYY-MM-DD"),
                    toDate: to.format("YYYY-MM-DD")
                })}
                filters={[{
                    name: "ТЧЭ/СЛД дислокация",
                    fieldId: "pripPredId",
                    fieldName: "pripPredName"
                }, {
                    name: "Депо приписки",
                    fieldId: "predId",
                    fieldName: "predName"
                }]}
                onRowClick={onRowClick}
                fetchDataLoading={ls.api.loading}
                onPageChange={() => {
                    scrollTo(modalRef, 0, 0)
                }}
            />
        </ARModal>
    )
})

const CargoUsedAsPass = observer(({ open, onClose, roadsApi, roads }) => {
    const ls = useLocalStore(() => ({
        api: apiWorker(ekasut.epReports.getCargoUsedAsPass)
    }))

    const modalRef = useRef(null)

    return (
        <ARModal
            header="Справка о грузовых локомотивах выданных под пассажирские поезда"
            open={open}
            modalRef={modalRef}
            onClose={onClose}
        >
            <ARForm
                pageLength={30}
                roadsApi={roadsApi}
                roads={roads}
                headers={[
                    "Депо выдачи локомотива",
                    "Депо приписки локомотива",
                    "Серия",
                    "Номер/секция",
                    "Дата выдачи локомотива",
                ]}
                converterToRowContent={(item) => ([
                    item.predName,
                    item.depoPrip,
                    item.locSer,
                    item.locNum,
                    formatMoment(item.dateOp, dateFormats.upToMinute)
                ])}
                onFetchData={({ road, from, to }) => ls.api.fetch({
                    roadId: road.id,
                    fromDate: from.format("YYYY-MM-DD"),
                    toDate: to.format("YYYY-MM-DD"),
                })}
                filters={[{
                    name: "Депо выдачи",
                    fieldId: "predName",
                    fieldName: "predName"
                }, {
                    name: "Депо приписки",
                    fieldId: "depoPrip",
                    fieldName: "depoPrip"
                }]}
                fetchDataLoading={ls.api.loading}
                onPageChange={() => {
                    scrollTo(modalRef, 0, 0)
                }}
            />
        </ARModal>
    )
})

/**
 * @param headers {[string]}          Headers content
 * @param clickable {boolean}         Make table rows clickable and apply styles
 * @param rowsCreator                 Manually create rows (suitable for complex tables with rowSpan cells)
 * @param converterToRowContent       Function convert page data to content for cells
 * @param fetchData                   Request data for table (should return Promise)
 * @param filters                     Filters description for table
 */
const ARForm = observer((
    {
        resWithMileage = false, roadsApi, roads, headers = [], onFetchData, onPageChange, onRowClick, clickable = false,
        converterToRowContent, pageLength = 30, fetchDataLoading,
        rowsCreator, filters = []
    }
) => {
    const ls = useLocalStore(() => ({
        depotOptions: [],
        seriesOptions: [],
        depotId: null,
        seriesId: null,
        page: 1,
        filteredData: [],
        pageData: [],
        data: [],
        filters: filters.map(f => ({
            ...f,
            options: [],
            value: null,
        }))
    }))

    const getPageData = (data, page) => data.slice((page - 1) * pageLength, page * pageLength)

    const filterData = (data, filters) => data.filter((item) => {
        let include = true
        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i]
            // If all filters are not selected: include item
            // If at least one of filters are selected: include item only if found one or more matches with filters
            include &= (filter.value !== null && filter.value !== "") ? (filter.value === item[filter.fieldId]) : true
        }
        return include
    })

    const getOptions = (data, { id, name }) =>
        extractValues(data, id).map(v => ({ key: v[id], value: v[id], text: v[name] }))

    return (<>
        {resWithMileage ? <RoadDateForm
            resWithMileage={resWithMileage}
            roadsApi={roadsApi}
            roads={roads}
            formLoading={fetchDataLoading}
            onForm={({ road, from }) => {
                onFetchData({ road, from })
                    .then((data) => {
                        ls.page = 1
                        ls.data = data
                        data ? ls.filteredData = filterData(data, ls.filters) : ls.filteredData = []
                        ls.pageData = getPageData(ls.filteredData, 1)
                        /*  ls.filters.forEach(filter => {
                             filter.options = getOptions(ls.pageData, { id: filter.fieldId, name: filter.fieldName })
                         }) */
                    })
            }}
        /> : <RoadDateForm
            resWithMileage={resWithMileage}
            roadsApi={roadsApi}
            roads={roads}
            formLoading={fetchDataLoading}
            onForm={({ road, from, to }) => {
                onFetchData({ road, from, to })
                    .then((data) => {
                        ls.page = 1
                        ls.data = data
                        ls.filteredData = filterData(data, ls.filters)
                        ls.pageData = getPageData(ls.filteredData, 1)
                        ls.filters.forEach(filter => {
                            filter.options = getOptions(ls.pageData, { id: filter.fieldId, name: filter.fieldName })
                        })
                    })
            }}
        />}
        {ls.filters && (
            <div style={{ paddingTop: 20 }}>
                {!resWithMileage ? <Header content="Фильтры" /> : null}
                <JustifyContainer gapRight="norm" justifyContent="flex-start">
                    {ls.filters.map((filter, index) => (
                        <div style={filter.style} key={"filter" + index}>
                            <LabeledDropdown
                                label={filter.name}
                                search selection clearable withoutContainer
                                value={filter.value}
                                options={filter.options.slice()}
                                onChange={(e, { value }) => {
                                    filter.value = value
                                    ls.filteredData = filterData(ls.data, ls.filters)
                                    ls.pageData = getPageData(ls.filteredData, ls.page)
                                }}
                            />
                        </div>
                    ))}
                </JustifyContainer>
            </div>
        )}
        <Table unstackable className={cn(clickable && ls.filteredData.length > 0 && appStyles.selectableTable)}>
            <Table.Header>
                <Table.Row>
                    {headers.map((header, index) => (
                        <Table.HeaderCell content={header} key={`header-${index}`} />
                    ))}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <TableRowsLoader
                    colSpan={headers.length}
                    loading={fetchDataLoading}
                    messageIfLoading="Загрузка справочной информации"
                    messageIfEmpty="Нет данных"
                    rows={rowsCreator ? rowsCreator(ls.pageData) : ls.pageData.map((item, index) => (
                        <Table.Row key={`row-${index}`} onClick={clickable ? () => onRowClick(item) : undefined}>
                            {converterToRowContent(item).map((content, i) => (
                                <Table.Cell content={content} key={`cell-${index}-${i}`} />
                            ))}
                        </Table.Row>
                    ))}
                />
            </Table.Body>
            {ls.filteredData.length > pageLength && (
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'center'} colSpan={headers.length}>
                            <PaginationData
                                activePage={ls.page}
                                dataLength={ls.filteredData.length}
                                pageLength={pageLength}
                                onPageChange={(page) => {
                                    ls.page = page
                                    ls.pageData = getPageData(ls.filteredData, page)
                                    // Fix: After user scroll down and click on page where significantly
                                    // less content scroll position would be too high (modal even can be placed
                                    // outside of viewport)
                                    /*scrollTo(modalRef, 0, 0)*/
                                    onPageChange(page)
                                }}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            )}
        </Table>
    </>)
})

const ARModal = ({ children, header, modalRef, ...modalProps }) => {
    return (
        <Modal
            size="large"
            ref={modalRef}
            {...modalProps}
        >
            <Segment padded style={{ marginTop: 0 }}>
                <Label
                    attached="top"
                    content={header}
                    size="large"
                />
                {children}
            </Segment>
        </Modal>
    )
}

const CountCases = observer(({ open, onClose, details }) => (
    <Modal
        size="large"
        open={open}
        onClose={onClose}
    >
        <Segment padded style={{ marginTop: 0 }}>
            <Label
                attached="top"
                content="Количество случаев"
                size="large"
            />
            <Table unstackable className={styles.cases}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell content="№ п.п" />
                        <Table.HeaderCell content="Дата смены состояния" />
                        <Table.HeaderCell content="Состояние" />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <TableRowsLoader
                        colSpan={3}
                        loading={false}
                        messageIfLoading="Загрузка справочной информации"
                        messageIfEmpty="Нет данных"
                        rows={(() => {
                            const rows = []
                            details.forEach((group, index) => {
                                // first row
                                rows.push(
                                    <Table.Row key={index}>
                                        <Table.Cell
                                            rowSpan={group.length}
                                            content={`№ ${index + 1}`}
                                            className={styles.groupCell}
                                        />
                                        <Table.Cell content={formatMoment(group[0].dateOp, dateFormats.upToMinute)}
                                            className={styles.smallCell} />
                                        <Table.Cell content={group[0].sostName} className={styles.smallCell} />
                                    </Table.Row>
                                )
                                // rest rows
                                for (let i = 1; i < group.length; i++) {
                                    const loc = group[i]
                                    rows.push(
                                        <Table.Row key={`${index}_${i}`}>
                                            <Table.Cell content={formatMoment(loc.dateOp, dateFormats.upToMinute)}
                                                className={styles.smallCell} />
                                            <Table.Cell content={loc.sostName} className={styles.smallCell} />
                                        </Table.Row>
                                    )
                                }
                            })
                            return rows
                        })()}
                    />
                </Table.Body>
            </Table>
        </Segment>
    </Modal>
))

const LocOnRailways = observer(({ open, onClose, details }) => {
    const ls = useLocalStore(() => ({
        arriveAfterDay: null,
        arriveBeforeDay: null,
        stayMoreThanHrs: null, // фильтр локомотивов прибывших в депо после указанного времени (часов)
        stayLessThanHrs: null,
        depot: null,
        ser: null, // серия
        prs: null, // номер
        zns: null, // индекс
        openFirstTime: true,
        depotsOptions: null,
        waitingInDepot: null,
        waitingInDepotPage: null,
        waitingInDepotLoading: false,
        pageLength: 10,
        activePage: 1,
    }))

    const getValidHours = (strHours) => {
        if (strHours === "") {
            return null
        }
        const hours = parseInt(strHours)

        if (hours >= 0 && hours <= 24) {
            return hours
        }
        if (hours > 24) {
            return 24
        }
        if (hours < 24) {
            return 0
        }

        return 0
    }

    const getPageData = (data, page) => data.slice((page - 1) * ls.pageLength, page * ls.pageLength)
    const emptyStrIfNull = value => value === null ? "" : value

    return (
        <ARModal
            header="Учет нахождения частных локомотивов на путях ТЧЭ"
            open={open}
            onClose={onClose}
            className={styles.locOnRailways}
        >
            <div className="row-form">
                <DepotField
                    label="Депо"
                    value={ls.depot === null ? null : ls.depot.id}
                    onChange={(e, { value }) => {
                        console.log(value)
                        ls.depot = value
                    }}
                />
                <DateField
                    label="Прибыл в депо, после"
                    date={ls.arriveAfterDay}
                    closable
                    onChange={(date) => {
                        ls.arriveAfterDay = date
                    }}
                    dateFormat={dateFormats.upToDay}
                    maxDate={ls.arriveBeforeDay !== null ? ls.arriveBeforeDay.format(dateFormats.upToDay) : undefined}
                />
                <DateField
                    label="Прибытие в депо, до"
                    date={ls.arriveBeforeDay}
                    closable
                    onChange={(date) => {
                        ls.arriveBeforeDay = date
                    }}
                    dateFormat={dateFormats.upToDay}
                    minDate={ls.arriveAfterDay !== null ? ls.arriveAfterDay.format(dateFormats.upToDay) : undefined}
                />
                <Button
                    basic icon="erase"
                    onClick={() => {
                        ls.arriveAfterDay = null
                        ls.arriveBeforeDay = null
                        ls.depot = null
                    }}
                />
            </div>
            <div className="row-form">
                <InputField
                    label="В сумме пробыл меньше часов чем"
                    value={emptyStrIfNull(ls.stayLessThanHrs)}
                    onChange={(e, { value }) => {
                        ls.stayLessThanHrs = getValidHours(value)
                    }}
                />
                <InputField
                    label="В сумме пробыл больше часов чем"
                    value={emptyStrIfNull(ls.stayMoreThanHrs)}
                    onChange={(e, { value }) => {
                        ls.stayMoreThanHrs = getValidHours(value)
                    }}
                />
            </div>
            <div className="row-form">
                <InputField
                    label="Серия"
                    value={replaceNull(ls.ser)}
                    onChange={(e, { value }) => {
                        ls.ser = value
                    }}
                />
                <InputField
                    label="Номер"
                    value={replaceNull(ls.prs)}
                    onChange={(e, { value }) => {
                        ls.prs = value
                    }}
                />
                <InputField
                    label="Индекс"
                    value={replaceNull(ls.zns)}
                    onChange={(e, { value }) => {
                        ls.zns = value
                    }}
                />
            </div>
            <div className="button-row">
                <Button
                    content="Применить"
                    disabled={ls.waitingInDepotLoading}
                    loading={ls.waitingInDepotLoading}
                    onClick={() => {
                        const undefIfEmpty = val => val === null || val === "" ? undefined : val
                        ls.waitingInDepotLoading = true
                        ls.waitingInDepot = null
                        ekasut.epReports.getWaitingInDepo({
                            SerLoc: undefIfEmpty(ls.ser),
                            ZnsLoc: undefIfEmpty(ls.zns),
                            PrsLoc: undefIfEmpty(ls.prs),
                            DepoName: undefIfEmpty(ls.depot !== null ? ls.depot.name : null),
                            FromDate: undefIfEmpty(ls.arriveAfterDay),
                            ToDate: undefIfEmpty(ls.arriveBeforeDay),
                            MoreHours: undefIfEmpty(ls.stayMoreThanHrs),
                            LessHours: undefIfEmpty(ls.stayLessThanHrs),
                        })
                            .then(({ data }) => {
                                runInAction(() => {
                                    ls.waitingInDepot = data
                                    ls.waitingInDepotPage = getPageData(data, 1)
                                    ls.activePage = 1
                                    ls.waitingInDepotLoading = false
                                })
                            })
                            .catch(catchNetworkErrors)
                    }}
                />
            </div>
            {ls.waitingInDepotLoading && <LoaderMessage content="Загрузка справочной информации" />}
            {ls.waitingInDepot !== null && !ls.waitingInDepotLoading && (<>
                {ls.waitingInDepot.length !== 0 && (
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content="Серия" />
                                <Table.HeaderCell content="Номер" />
                                <Table.HeaderCell content="Индекс" />
                                {/*<Table.HeaderCell content="Депо приписки"/>*/}
                                <Table.HeaderCell content="Дата" />
                                <Table.HeaderCell content="Время ожидания, ч" />
                                <Table.HeaderCell content="Наименование депо" />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {ls.waitingInDepotPage.map((loc, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell content={loc.serLocName} />
                                    <Table.Cell content={loc.znsLoc} />
                                    <Table.Cell content={loc.prsLocChar} />
                                    {/*<Table.Cell content={loc.depoOpName}/>*/}
                                    <Table.Cell content={formatMoment(loc.dateOp, dateFormats.upToMinute)} />
                                    <Table.Cell content={`${padStartZero(loc.hoursWaiting)}:${padStartZero(loc.minutesWaiting)}`} />
                                    <Table.Cell content={loc.depoOpName} />
                                </Table.Row>
                            ))}
                        </Table.Body>
                        {ls.waitingInDepot.length > ls.pageLength && (
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={'center'} colSpan={7}>
                                        <PaginationData
                                            activePage={ls.activePage}
                                            dataLength={ls.waitingInDepot.length}
                                            pageLength={ls.pageLength}
                                            onPageChange={(page) => {
                                                ls.activePage = page
                                                ls.waitingInDepotPage = getPageData(ls.waitingInDepot, page)
                                                // Fix: After user scroll down and click on page where significantly
                                                // less content scroll position would be too high (modal even can be placed
                                                // outside of viewport)
                                                /*scrollTo(modalRef, 0, 0)*/
                                                /*onPageChange(page)*/
                                            }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        )}
                    </Table>
                )}
                {ls.waitingInDepot.length === 0 && (
                    <Message content="По запросу не найдено ни одной записи" />
                )}
            </>)}
        </ARModal>
    )
})

const RoadDateForm = observer(({ roadsApi, roads, onForm, formLoading, resWithMileage }) => {
    const ls = useLocalStore(() => ({
        road: { id: null, name: "" },
        from: resetHMS(moment()),
        to: resetHMS(moment(), true),
        isDateCorrect: () => {
            const diffYears = ls.to.diff(ls.from, "years", true)
            return diffYears >= 0 && diffYears <= 1
        }
    }))

    const [loading, setLoading] = React.useState(false)

    useDidFirstRender(() => {
        roadsApi.fetchIfNeed()
    })

    return (
        <JustifyContainer
            justifyContent="space-between"
            gapRight="norm"
        >
            <JustifyContainer gapRight="norm">
                <LabeledDropdown
                    label="Дорога"
                    search selection
                    options={formOptions(roads, "name")}
                    onChange={(e, { value }) => {
                        ls.road = { id: roads[value].code, name: roads[value].name }
                    }}
                />
                {resWithMileage ?
                    <LabeledDateInput
                        label="Дата"
                        date={ls.from}
                        closable
                        onChange={date => ls.from = date}
                    />

                    : <LabeledDateInput
                        label="Дата, от"
                        date={ls.from}
                        closable
                        onChange={date => ls.from = date}
                    />}
                {!resWithMileage ? <LabeledDateInput
                    label="Дата, до"
                    date={ls.to}
                    closable
                    onChange={date => ls.to = date}
                /> : null}
            </JustifyContainer>
            <JustifyContainer alignItems="flex-end  ">
                <Button
                    content="Сформировать"
                    /*loading={formLoading}*/
                    disabled={formLoading || !ls.isDateCorrect() || !ls.road.id || loading}
                    onClick={() => {
                        onForm({
                            road: ls.road,
                            from: ls.from,
                            to: ls.to
                        })
                    }}
                />
                {resWithMileage ?
                    <Button
                        content={loading ? 'Загрузка отчета...' : "Excel"}
                        disabled={formLoading || !ls.isDateCorrect() || !ls.road.id || loading}
                        onClick={() => {
                            setLoading(true)
                            apiWorker(ekasut.nepReports.getOverrunExcel).fetch({
                                dorId: ls.road.id,
                                date: ls.from.format('YYYY-MM-DD'),
                            }).then((res) => {
                                downloadBlob("locomotives_overrun_report.xlsx", res)
                            })
                                .catch(catchNetworkErrors)
                                .finally(() => {
                                    setLoading(false)
                                })
                        }}
                    /> : null}
            </JustifyContainer>
        </JustifyContainer>
    )
})


const PaginationData = ({ dataLength, onPageChange, pageLength = 10, activePage }) => (
    <Pagination
        activePage={activePage}
        onPageChange={(e, { activePage }) => {
            onPageChange(activePage)
        }}
        totalPages={Math.ceil(dataLength / pageLength)}
    />
)


const extractValues = (list, fieldName) => {
    const map = new Map()
    list.forEach(item => {
        if (item[fieldName] !== undefined && item[fieldName] !== null && !map.has(item[fieldName])) {
            map.set(item[fieldName], item)
        }
    })
    return Array.from(map.values())
}

const scrollTo = (ref, x = 0, y = 0) => {
    if (ref.current && ref.current.ref.current) {
        ref.current.ref.current.parentNode.scroll(x, y)
    }
}

export default AnalyticalReferenceModal