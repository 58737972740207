import React from 'react';
import {Table, Loader, Dimmer} from 'semantic-ui-react'
import DropdownBoldChanges from './DropdownBoldChanges'
import './LogInfoTable.scss'
import * as moment from 'moment';
import 'moment/locale/ru';

// Order is matter, index === useTypeId
const dropdownValues = [
    'Отключен',
    'Жесткий',
    'Сигнальный',
    'Различный'
]
const dropdownOptions = dropdownValues.map(value => ({
    key: value,
    text: value,
    value: value,
    disabled: value === 'Различный'
}))

// React memo keep this component from waste rendering if parent just render and don't change any props
const LogInfoTable = React.memo(({info, className, loading, showMessage, message, valueOnChange}) => {
    let tableBody

    if (showMessage) {
        tableBody = (
            <Table.Row className="lit-message">
                <Table.Cell colSpan={4}>
                    {message}
                </Table.Cell>
            </Table.Row>
        )
    } else {
        if (loading) {
            tableBody = (
                <Table.Row>
                    <Table.Cell colSpan={4}>
                        <Dimmer inverted active className="lit-loader">
                            <Loader size='medium'>Загрузка...</Loader>
                        </Dimmer>
                    </Table.Cell>
                </Table.Row>
            )
        } else {
            if (info.length === 0) {
                tableBody = (
                    <Table.Row className="lit-message">
                        <Table.Cell colSpan={4}>
                            Данные отсутствуют
                        </Table.Cell>
                    </Table.Row>
                )
            } else {
                const getUseId = (name) => {
                    return dropdownValues.findIndex((el) => el === name)
                }
                tableBody = (info.map((item, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>{item.checkName}</Table.Cell>
                        <Table.Cell collapsing>
                            <div>
                                <DropdownBoldChanges
                                    upward={info.length > 10 ? (index > (info.length - 5)) : false}
                                    direction="left"
                                    options={dropdownOptions}
                                    defaultValue={item.typeUseName}
                                    onChange={(value, defaultValue) => valueOnChange(value, getUseId(value),index, defaultValue)}
                                />
                            </div>

                        </Table.Cell>
                        <Table.Cell>{formatDate(item.updateDate)}</Table.Cell>
                        <Table.Cell>{item.updateUser}</Table.Cell>
                    </Table.Row>
                )))
            }
        }
    }

    return (
        <Table className={"lcm-log-info-table " + className} compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        Наименование
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Значение
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Дата
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Пользователь
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            
            <Table.Body>
                {tableBody}
            </Table.Body>
        </Table>
    );
})

export default LogInfoTable;
const formatDate = (str) => {
    if (typeof str === 'string') {
        return moment(str).format("YYYY.MM.DD HH:mm:ss")
    } else {
        return '-'
    }
}