import React, {useEffect} from 'react';
import {Accordion, Button, Checkbox, Icon, Modal, Table} from "semantic-ui-react";
import Widget from "../../shared/Widget";
import './AddRecommendationsModal.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import ekasut from "../../api/ekasut";
import {processesParser} from "./functions";
import cn from 'classnames'
import TextAreaAutosize from 'react-textarea-autosize'
import {usePrompt} from "../../globalStore/useGlobalStore";

const AddRecommendationsModal = observer((
    {open, audit, onApply = () => null, onCancel = () => null, onLoadProcesses, highlightQuestionIds}
) => {

    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        questionsLoaded: false,
        processes: [],
        initialProcesses: [],
        activeProcess: null,
        advice: ""
    }))

    useEffect(() => {
        if (open) {
            ls.processes.forEach(process => process.questions.forEach(q => q.checked = false))
        }
    }, [open])

    useEffect(() => {
        if (open && !ls.questionsLoaded) {
            ls.questionsLoaded = true
            ekasut.audit.getQuestions({auditId: audit.auditId, forAdvices: false})
                .then(({data}) => {
                    const restructuredData = processesParser(data)
                    // Add fields
                    restructuredData.forEach(item => {
                        item.questions.forEach(question => {
                            question.checked = false
                        })
                    })
                    ls.processes = restructuredData
                    ls.initialProcesses = restructuredData
                    onLoadProcesses(restructuredData)
                })
                .catch(catchNetworkErrors)
        }
    })

    const getSelectedQuestions = () => {
        let questions = []
        ls.processes.forEach(process => {
            const selectedQuestions = process.questions.filter(question => question.checked)
            if (selectedQuestions.length > 0) {
                questions.push(...selectedQuestions)
            }
        })
        return questions
    }

    const apply = () => {
        onApply(ls.advice, getSelectedQuestions())
    }
    const cancel = () => {
        prompt.show("Отменить применение рекомендации?", "", {
            yesNo: true,
            onApply: () => {
                ls.processes = ls.initialProcesses
                ls.activeProcess = null
                ls.advice = ""
                prompt.close()
                onCancel()
            }
        })
    }


    return (
        <Modal
            basic
            size="large"
            open={open}
            className="app add-recommendation-modal alt-semantic"
            onClose={cancel}
        >
            <Modal.Content>
                <Widget
                    closeIcon
                    onClose={cancel}
                    header="Применение рекомендации"
                    footer={(
                        <div className="buttons">
                            <Button content="Отмена" className="button-link" onClick={cancel}/>
                            <Button content="Добавить" className="button-link" onClick={apply}/>
                        </div>
                    )}
                >
                    <TextAreaAutosize
                        // on focus out
                        onBlur={(e) => {
                            ls.advice = e.currentTarget.value
                        }}
                        minRows={1}
                        placeholder="Рекомендация" className="advice-textarea"
                        maxRows={10}
                        defaultValue=""
                    />
                    <Accordion>
                        {ls.processes.map((process, index) => (<React.Fragment key={index}>
                            <Accordion.Title
                                active={ls.activeProcess === index}
                                index={index}
                                onClick={() => ls.activeProcess = ls.activeProcess === index ? null : index}
                                className={cn(process.questions.every(question => highlightQuestionIds[question.questionId]) && "highlight-recommendation")}
                            >
                                <Icon name="dropdown"/>
                                {process.processName}
                            </Accordion.Title>
                            <Accordion.Content active={ls.activeProcess === index}>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell content="Критерий"/>
                                            <Table.HeaderCell content="Способ оценки"/>
                                            <Table.HeaderCell content="Выполнения критерия"/>
                                            <Table.HeaderCell content="Несоответствия"/>
                                            <Table.HeaderCell content="Оценка"/>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {ls.activeProcess !== null && ls.processes[ls.activeProcess].questions.map((question, index) => (
                                            <Table.Row
                                                key={index}
                                                className={cn(question.checked && "checked-question-row", highlightQuestionIds[question.questionId] && "highlight-question")}
                                                onClick={() => question.checked = !question.checked}
                                            >
                                                <Table.Cell>
                                                    <Checkbox
                                                        label={question.criterionName}
                                                        checked={question.checked}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>{question.question}</Table.Cell>
                                                <Table.Cell>{question.description}</Table.Cell>
                                                <Table.Cell>{question.reasonName}</Table.Cell>
                                                <Table.Cell>{question.gradeName}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>
                        </React.Fragment>))}
                    </Accordion>

                </Widget>
            </Modal.Content>
        </Modal>
    );
})

export default AddRecommendationsModal;