import React from 'react';
import {Button, Header, List, Message, Modal, Popup} from 'semantic-ui-react'
import {Table} from 'semantic-ui-react'
import {getAuditColor} from "./AuditColors";
import moment from 'moment'
import cn from 'classnames'
import {toJS} from "mobx";
import './DayView.scss'

/**
 *
 * @param open - show/hide modal
 * @param date
 * @param audits - [{auditPredName, auditManagerFio, typeId}]
 * @param onAdd
 * @param onClosed
 * @returns {*}
 * @constructor
 */
const DayView = ({open, date = moment(), audits = [], onAdd, onClose, selectedAuditIndex, auditOnClick, onRemove, auditTypes}) => {

    return (
        <Modal open={open} size="small" className="app audit-control day-view alt-semantic" onClose={onClose}>
            <Header>
                <span className="day-month">{`${date.format("DD MMMM")} `}</span>
                <span className="year">{`${date.years()} г.`}</span>
            </Header>
            <Modal.Content>
                <Table className="audit-table" unstackable>
                    <Table.Header>
                    </Table.Header>
                    <Table.Body>
                        {audits.map((audit, index) => {
                            const auditType = auditTypes && auditTypes.find(type => type.code === audit.typeId)
                            return (
                                <Popup
                                    key={index}
                                    className="app audit-control day-view processes-popup"
                                    trigger={(
                                        <Table.Row
                                            className={cn(`row-fill-${getAuditColor(audit.typeId)}`, selectedAuditIndex === index && "selected", (index === audits.length - 1) && "last-row")}
                                            onClick={() => {
                                                auditOnClick(audit, index)
                                            }}
                                        >
                                            <Table.Cell className="pred-name">{audit.predName}</Table.Cell>
                                            <Table.Cell>{audit.isPlan ? "Запланирован" : "Не запланирован"}</Table.Cell>
                                            <Table.Cell>{auditType ? auditType.name : undefined}</Table.Cell>
                                            <Table.Cell collapsing className="fio">{audit.managerFio}</Table.Cell>
                                        </Table.Row>
                                    )}
                                >
                                    <Header as="h4" content="Список процессов"/>
                                    {audit.processes.length === 0 && (
                                        <Message content="Процессы не выбраны" warning/>
                                    )}
                                    {audit.processes.length > 0 && (
                                        <List divided>
                                            {audit.processes.map(process => (
                                                <List.Item key={process.processId} content={process.processName}/>
                                            ))}
                                        </List>
                                    )}
                                </Popup>
                            )
                        })}
                    </Table.Body>
                </Table>
                <div className="buttons">
                    <Button
                        content="Добавить аудит"
                        basic icon="plus"
                        onClick={() => onAdd(date)}

                    />
                    <Button
                        content="Удалить аудит"
                        disabled={selectedAuditIndex === undefined}
                        basic icon="trash"
                        onClick={() => onRemove(audits[selectedAuditIndex])}
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default DayView;