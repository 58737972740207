import React from 'react';
import {Icon, Label, Message, Popup, Table} from "semantic-ui-react";
import * as moment from "moment";
import styles from './RouteList.module.scss'
import appStyles from '../../css/App.module.scss'
import cn from 'classnames'
import RouteDetailModal from "./RouteDetailModal";
import {observer, useLocalStore} from "mobx-react-lite";
import SectionsTab from "./SectionsTab";
import {toJS} from "mobx";

const RouteList = observer(({tab, serLoc, znsLoc, prsLoc}) => {
    const ls = useLocalStore(() => ({
        details: {open: false, yavkaDate: undefined, closedRouteDate: undefined, mashTabNum: undefined}
    }))

    return (
        <>
            {tab.routeListMessage ? <Message content={tab.routeListMessage}/> : (
                <Table celled structured striped className={cn(styles.table, appStyles.selectableTable)}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className="center aligned">№</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Предприятие явки</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">ТН машиниста</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Дата явки</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Станция явки</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Дата закрытия</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">Станция закрытия</Table.HeaderCell>
                            <Table.HeaderCell className="center aligned">{/* flag */}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tab.routeList.map((item, index) => (
                            <Table.Row
                                key={index}
                                onClick={() => {
                                    /*open, tabNum, serLoc, znsLoc, prsLoc, yavkaDate, closedRouteDate*/
                                    ls.details = {
                                        open: true,
                                        yavkaDate: moment(item.beginDate),
                                        closedRouteDate: moment(item.endDate),
                                        mashTabNum: item.machTabNum
                                    }
                                }}
                            >
                                <Table.Cell className="center aligned">{item.routeNum}</Table.Cell>
                                <Table.Cell>{item.predName}</Table.Cell>
                                <Table.Cell className="center aligned">{item.machTabNum}</Table.Cell>
                                <Table.Cell className="center aligned">{moment(item.beginDate).format("DD.MM HH:mm")}</Table.Cell>
                                <Table.Cell>{item.beginStan}</Table.Cell>
                                <Table.Cell className="center aligned">{moment(item.endDate).format("DD.MM HH:mm")}</Table.Cell>
                                <Table.Cell>{item.endStan}</Table.Cell>
                                <Table.Cell>
                                    {item.files.map((file, i) => (
                                        <Popup
                                            className='stall-info' key={i}
                                            trigger={
                                                <Label
                                                    onClick={() => openNBD2File(file.fileId)}
                                                    style={{cursor: 'pointer'}}
                                                >
                                                    {file.fileTypeName.charAt(0)}
                                                </Label>}>
                                            <Popup.Header>{file.fileTypeName}</Popup.Header>
                                            <Popup.Content>
                                                <p><b>Размер:</b> {file.byteSize} байт</p>
                                                <p><b>Создан:</b> {moment(file.createDt).format("DD.MM.YYYY HH:mm:ss")}
                                                </p>
                                            </Popup.Content>
                                        </Popup>
                                    ))}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
            <RouteDetailModal
                open={ls.details.open}
                onClose={() => ls.details.open = false}
                tabNum={ls.details.mashTabNum}
                serLoc={serLoc}
                znsLoc={znsLoc}
                prsLoc={prsLoc}
                yavkaDate={ls.details.yavkaDate}
                closedRouteDate={ls.details.closedRouteDate}
            />
            <Message warning>
                <Icon name='help'/>
                Для просмотра файла маршрутов требуется установка специализированной
                &nbsp;
                <a href='http://nbd2-app.orw.oao.rzd:8080/nbd2-shell/nbd2-shell-prod-setup.exe'>
                    оболочки АСУТ НБД-2
                </a>.
            </Message>
        </>
    );
})

const openNBD2File = (fileId) => {
    // alert(fileId)
    window.open(`nbd2://openfile:${fileId}/`, '_blank');
}

export default RouteList;