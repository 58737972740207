import React, {useEffect, useState} from 'react'
import {Checkbox, Input, List} from "semantic-ui-react";
import './SearchCheckboxList.scss'

const SearchCheckboxList = ({options, columns, height, style, inputPlaceholder, holdSelected, changeOption}) => {
    const [listItems, setListItems] = useState([])
    const [inputValue, setInputValue] = useState('')

    // set list items
    useEffect(() => {
        // start with and ignore case
        const pattern = new RegExp('^' + inputValue.trim(), 'i')
        const filteredOptions = options.filter((option) => {
            if (holdSelected && option.checked) {
                return true
            }
            return pattern.test(option.text)
        })

        const newListItems = []
        for (let i = 0; i < filteredOptions.length; i += columns) {
            const rowIndex = i / columns
            const gridColumns = []
            for (let colIndex = 0; (colIndex < columns) && (i + colIndex < filteredOptions.length); colIndex++) {
                const currOption = filteredOptions[i + colIndex]
                if (!(currOption.text === '' || currOption.text === null || currOption.text === undefined)) {
                    gridColumns.push(
                        <Checkbox
                            key={currOption.key}
                            style={{flexBasis: '100%'}}
                            label={currOption.text}
                            checked={currOption.checked}
                            onChange={(e, {checked}) => {
                                // there will be something, filtered array is subset of array
                                const index = options.findIndex((option) => option.key === currOption.key)
                                changeOption(index, checked)
                            }}
                        />
                    )
                }
            }
            for (let colIndex = filteredOptions.length; colIndex < i + columns; colIndex++) {
                gridColumns.push(
                    <div key={`dummy-cell_${rowIndex}_${colIndex}`} style={{flexBasis: '100%'}}/>
                )
            }
            newListItems.push(
                <List.Item key={`list-item-${rowIndex}`}>
                    <div style={{display: 'flex'}}>
                        {gridColumns}
                    </div>
                </List.Item>
            )
        }
        setListItems(newListItems)
    }, [options, inputValue, holdSelected, changeOption, columns])

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    return (
        <div className="search-checkbox-list" style={style}>
            <Input
                className='transparent-input'
                placeholder={inputPlaceholder}
                fluid
                value={inputValue}
                onChange={handleInputChange}
            />
            <List style={{height, overflowY: 'auto', overflowX: 'none'}}>
                {listItems}
            </List>
        </div>
    )
}

SearchCheckboxList.defaultProps = {
    inputPlaceholder: "Enter your text...",
    height: 150,
    columns: 3,
    options: [],
    holdSelected: false
}

export default SearchCheckboxList