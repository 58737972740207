import React from 'react'
import {Icon, Label, Segment} from "semantic-ui-react";
import styles from './EventsBlock.module.scss'

// events: [{name, ...}]
export default function EventsBlock({events, onClick, onAdd, date}) {
    return (
        <Segment padded className={styles.container}>
            <Label attached='top' className={styles.day}>{date.format('DD, dddd')}</Label>
            <Label attached='top right' className={styles.addButton} onClick={onAdd}><Icon size='small' name='add'/></Label>
            {events.map((event, index) => {
                return (
                    <Label as='a' color='teal' key={index} onClick={() => onClick(event)}>{event.actionTypeStr}</Label>
                )
            })}
        </Segment>
    )
}
