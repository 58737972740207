import React, {useState, useCallback} from 'react'
import {StoreProvider} from './StoreProvider'
import SectionsTab from "./SectionsTab"
import {Modal} from "semantic-ui-react";
import Widget from "../Widget";
import LoaderMessage from "../LoaderMessage";
import {observer, useLocalStore} from "mobx-react-lite";
import cn from 'classnames'
import './index.scss'

/** Locomotive Card (Modal) with detailed info. Locomotive can be defined either by locId or group of props: seriesId, number, sectionId */
const LocomotiveModal = observer(({locId, seriesId, number, sectionId, open, onClose, onError}) => {
    const ls = useLocalStore(() => ({
        expand: false
    }))

    const [loading, setLoading] = useState(true)

    const onStartLoad = useCallback(() => setLoading(true), [])
    const onLoaded = useCallback(() => setLoading(false), [])

    return (
        <Modal
            size={ls.expand ? "fullscreen" : 'small'}
            open={open}
            onClose={onClose}
            className={cn("app shared locomotive-modal", ls.expand && "expand-loc-modal")}
        >
            <Widget header="" closeIcon expandIcon onExpand={() => ls.expand = !ls.expand} onClose={onClose}>
                {loading && (<>
                    <div style={{height: 30}}></div>
                    <LoaderMessage content="Загрузка карточки локомотива" basic/>
                </>)}
                <StoreProvider props={{locId}}>
                  
                    <SectionsTab
                        // className="widget-content"
                        onStartLoad={onStartLoad}
                        onLoaded={onLoaded}
                    />
                </StoreProvider>
            </Widget>
        </Modal>
    )
})
LocomotiveModal.defaultProps = {
    open: false,
    onClose: () => {
    }
}

export default LocomotiveModal