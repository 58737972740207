import React from 'react'
import ButtonPopupRow from "../../shared/ButtonPopupRow";
import TableForPopup from "../RatingsWorkLocomotives/TableForPopup";
import appStyles from '../../css/App.module.scss'
import cn from 'classnames'
import styles from './PointInfo.module.scss'
import {formatRotation, isDeclineWithinPercent, convertRotationToSeconds} from "./functions";

/**
 * @param props - props
 *
 * @param props.leadTimePlan - Выполнение заказа Д по плану
 * @param props.leadTimeActual - Выполнение заказа Д по факту
 * @param props.planBrigadesCurrentDay - Запланировано бригад до 18 часов на текущие сутки
 *
 * @param props.fullRotationActual - Оборот сут.
 * @param props.fullRotationPlan - Норма оборота
 * @param props.fullRotationAverage - Оборот средний
 * @param props.rotationDecline - +/- к норме
 *
 * @param props.usedRest - Использ. по сокр. отдыху
 * @param props.usedFromRotation - Использ. с оборота
 * @param props.canceledBrigades - Отмена ЛБ
 * @param props.departureAsPassenger - Отправление пассажиром
 * @param props.departureWithStops - Отправление с пересидкой
 * @param props.secondRest - Второй отдых
 * @param props.prolongationWorkTime - Продление рабочего времени
 * @param props.prolongationRest - Продление отдыха
 * @param props.workStaff - Явочный состав по дирекции
 * @param props.workStaffWithErrors - Явочный состав по дирекции с нарушениями
 */
const PointInfo = (
    {
        pointName,
        leadTimePlan, leadTimeActual, planBrigadesCurrentDay,
        fullRotationPlan, fullRotationActual, fullRotationAverage, rotationDecline,
        usedRest, usedFromRotation, canceledBrigades, departureAsPassenger, departureWithStops,
        secondRest, prolongationWorkTime, prolongationRest, workStaff, workStaffWithErrors,
        leadTimePlanOnClick, planBrigadesCurrentDayOnClick, leadTimeActualOnClick, fullRotationActualOnClick,
        workStaffOnClick
    }
) => {



    return (
        <div className={styles.columnOrder}>
            <ButtonPopupRow
                className={cn(styles.gap, styles.buttonRow)}
                arrSettings={[
                    {
                        value: leadTimePlan,
                        callback: leadTimePlanOnClick,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    className={styles.customPopup}
                                    data={[
                                        {
                                            text: 'Заказ Д',
                                            unit: '',
                                            value: leadTimePlan
                                        },
                                        {
                                            text: 'Норма оборота',
                                            unit: 'ч.',
                                            value: formatRotation(fullRotationPlan),
                                        },
                                        {
                                            text: 'Оборот сут.',
                                            unit: 'ч.',
                                            value: formatRotation(fullRotationActual)
                                        },
                                        {
                                            text: 'Оборот средний',
                                            unit: 'ч.',
                                            value: formatRotation(fullRotationAverage)
                                        },
                                        {
                                            text: '+/- к норме',
                                            unit: 'ч.',
                                            value: formatRotation(rotationDecline)
                                        }
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: planBrigadesCurrentDay,
                        callback: planBrigadesCurrentDayOnClick,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    className={styles.customPopup}
                                    data={[
                                        {
                                            text: 'Заставлено',
                                            unit: '',
                                            value: planBrigadesCurrentDay
                                        },
                                        {
                                            text: 'Использ. по сокр. отдыху',
                                            unit: '',
                                            value: usedRest,
                                        },
                                        {
                                            text: 'Использ. с оборота',
                                            unit: '',
                                            value: usedFromRotation,
                                        },
                                        {
                                            text: 'Отмена ЛБ',
                                            unit: '',
                                            value: canceledBrigades,
                                        },
                                        {
                                            text: 'Отправление пассажиром',
                                            unit: '',
                                            value: departureAsPassenger,
                                        },
                                        {
                                            text: 'Отправление с пересидкой',
                                            unit: '',
                                            value: departureWithStops,
                                        },
                                        {
                                            text: 'Второй отдых',
                                            unit: '',
                                            value: secondRest,
                                        },
                                        {
                                            text: 'Продление рабочего времени',
                                            unit: '',
                                            value: prolongationWorkTime,
                                        },
                                        {
                                            text: 'Продление отдыха',
                                            unit: '',
                                            value: prolongationRest,
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: leadTimeActual,
                        callback: leadTimeActualOnClick,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    className={styles.customPopup}
                                    data={[
                                        {
                                            text: 'Уехало',
                                            unit: '',
                                            value: leadTimeActual
                                        }
                                    ]}
                                />
                            )
                        }
                    },
                ]}
            />
            <ButtonPopupRow
                className={cn(
                    styles.gap,
                    styles.buttonRow,
                    isDeclineWithinPercent(5, convertRotationToSeconds(fullRotationPlan), convertRotationToSeconds(fullRotationActual)) ?
                        styles.ok : styles.wentBeyond
                )}
                arrSettings={[
                    {
                        value: formatRotation(fullRotationActual),
                        callback: fullRotationActualOnClick,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={pointName}
                                    className={styles.customPopup}
                                    data={[
                                        {
                                            text: 'Полный оборот',
                                            unit: 'ч.',
                                            value: formatRotation(fullRotationActual)
                                        },
                                        {
                                            text: 'Норма оборота',
                                            unit: 'ч.',
                                            value: formatRotation(fullRotationPlan)
                                        }
                                    ]}
                                />
                            )
                        }
                    }
                ]}
            />
            {!(((workStaff === 0) || (workStaff === null)) && ((workStaffWithErrors === 0) || (workStaffWithErrors === null))) && (
                <ButtonPopupRow
                    className={styles.buttonRow}
                    rowProps={{
                        onClick: workStaffOnClick
                    }}
                    arrSettings={[
                        {
                            value: workStaff,
                        },
                        {
                            value: workStaffWithErrors,
                        }
                    ]}
                    rowPopupProps={{
                        className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                        content: (
                            <TableForPopup
                                title={pointName}
                                data={[
                                    {
                                        text: 'Явочный состав',
                                        unit: 'чел.',
                                        value: workStaff
                                    },
                                    {
                                        text: 'Из них с нарушениями контрольных сроков',
                                        unit: 'чел.',
                                        value: workStaffWithErrors
                                    }
                                ]}
                            />
                        )
                    }}
                />
            )}
        </div>
    )
}
export default PointInfo