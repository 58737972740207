import axios from 'axios'
import unauthorizedAccess from "./interceptors/unauthorizedAccecss";
import dbSyncCertAuthorityInvalid from './interceptors/dbSyncCertAuthorityInvalid'
import ssbDbExceptionCutStackTrace from "./interceptors/ssbDbExceptionCutStackTrace";
import momentParamsFormatter from "./interceptors/momentFormatter";
import dateFormats from "./dateFormats";
import appendToken from "./interceptors/appendToken";
const CancelToken = axios.CancelToken;

let a = undefined

const dbSync = {
    addErrorListener: (onError) => a.interceptors.response.use(null, (error) => {
        onError(error)
        return Promise.reject(error)
    }),
    init: ({baseURL, token}) => {
        a = axios.create({baseURL})
        a.interceptors.request.use(appendToken(token), null)
        a.interceptors.response.use(null, unauthorizedAccess)
        a.interceptors.response.use(null, dbSyncCertAuthorityInvalid)
        a.interceptors.response.use(null, ssbDbExceptionCutStackTrace)
        a.interceptors.request.use(momentParamsFormatter)
    },
    isInitialized: () => !!a,
    reports: {
        getRoads: () => a.get(`/api/reports/dictionary/roads`)
    },
    logControl: {
        cancelGetPreds: () => null,
        cancelGetChecks: () => null,
        cancelPutChecks: () => null,
        cancel: () => {
            dbSync.logControl.cancelGetPreds()
            dbSync.logControl.cancelGetChecks()
            dbSync.logControl.cancelPutChecks()
        },
        getPreds: () => a.get(`/api/log-control/preds`,
            {cancelToken: new CancelToken((c) => dbSync.logControl.cancelGetPreds = c)}),
        getChecks: (level, id) => a.get(`/api/log-control/checks/${level}/${id}`,
            {cancelToken: new CancelToken((c) => dbSync.logControl.cancelGetChecks = c)}),
        putChecks: (level, id, data) => a.put(`/api/log-control/checks/${level}/${id}`, data,
            {cancelToken: new CancelToken((c) => dbSync.logControl.cancelPutChecks = c)}),
        getChecksRoadMap: () => a.get(`/api/log-control/checks/road/map`)
    },
    worksPlan: {
        getRates: (actionId, actionRd) => a.get(`/api/works-plan/rates/${actionRd}/${actionId}`),
        postEditAction: (
            actionId, actionRd, tchmiTabNom, actionType, planDate, beginDate, endDate,
            persAsutrId, stCnsiBegin, stCnsiEnd, stCnsiCtl, theme, zachet, orderDate, orderText, cols
        ) => a.post(`/api/works-plan/action/edit`, {
            actionId, actionRd, tchmiTabNom, actionType, planDate: planDate.format('YYYY-MM-DD'),
            beginDate, endDate, persAsutrId, stCnsiBegin, stCnsiEnd, stCnsiCtl, theme, zachet, orderDate, orderText, cols
        }),
        putFormular: (
            rd, id, routeRD, routeID, trainNum, persRD, tabMash, tabTchmi,
            actionType, beginCheck, endCheck, stCnsiBegin, stCnsiEnd, removeTalon,
            targets, text, mery, dopWork, targetsList, textList, meryList
        ) => a.put(`/api/works-plan/formular`, {
            rd, id, routeRD, routeID, trainNum, persRD, tabMash, tabTchmi, actionType,
            stCnsiBegin, stCnsiEnd, removeTalon, targets, text, mery, targetsList, textList, meryList,
            beginCheck: beginCheck.format(), endCheck: endCheck.format(), dopWork: dopWork.format(),
        }),
        deleteAction: (actionRD, actionId, tabNom) => a.delete(`/api/works-plan/action/${actionRD}/${actionId}/${tabNom}/delete`),
        deleteFormular: (rd, formularId) => a.delete(`/api/works-plan/formular/${rd}/${formularId}`),
        putRate: (actionRD, actionId, tabNom, rateId) => a.put(`/api/works-plan/rates/${actionRD}/${actionId}/${tabNom}/${rateId}`)
    },
    system: {
        getCheckAccess: (fromUrl) => a.get(`/api/service/checkaccess`, {params: {fromUrl}})
    }
}

export default dbSync