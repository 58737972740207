import React from 'react';
import {Header, List, Message, Popup, Table} from "semantic-ui-react";
import {formatMoment} from "../../helpers/utils";
import styles from './AvailableAuditsTable.module.scss';
import cn from "classnames";
import {getAuditColor} from "./AuditColors";

const AvailableAuditsTable = ({audits, auditsLoading, onClick = () => null}) => {
    return (
        <Table textAlign="center" className={styles.component} unstackable selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell content="Место проведения"/>
                    <Table.HeaderCell content="Тип аудита"/>
                    <Table.HeaderCell content="Вид аудита"/>
                    <Table.HeaderCell content="Срок"/>
                    <Table.HeaderCell content="Ответственный"/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {audits.map((audit, index) => (
                    <Popup
                        key={index}
                        position="bottom center"
                        className="app audit-control day-view processes-popup"
                        trigger={(
                            <Table.Row onClick={() => onClick(audit)}>
                                <Table.Cell content={`${audit.predName}, ${audit.parentPredName}`}/>
                                <Table.Cell content={audit.typeName}/>
                                <Table.Cell content={audit.isPlanName}/>
                                <Table.Cell content={formatMoment(audit.auditDate, "DD MMM YYYY г.")}/>
                                <Table.Cell content={`${audit.managerFio}, ${audit.auditManagerPost}`}/>
                            </Table.Row>
                        )}
                    >
                        <Header as="h4" content="Список процессов"/>
                        {audit.processes.length === 0 && (
                            <Message content="Процессы не выбраны" warning/>
                        )}
                        {audit.processes.length > 0 && (
                            <List divided>
                                {audit.processes.map(process => (
                                    <List.Item key={process.processId} content={process.processName}/>
                                ))}
                            </List>
                        )}
                    </Popup>

                ))}
            </Table.Body>
        </Table>
    );
};

export default AvailableAuditsTable;