import React, {useEffect} from 'react'
import DepotsMap from './DepotsMap'
import ekasut from "../../api/ekasut";
import useApiMethod from "../../hooks/useApiMethod";

const CardLocomotiveDepot = ({hideSidebars}) => {
    const [sendGetDepots, , depots] = useApiMethod(ekasut.checks.getDepots, undefined, [])

    // Load points
    useEffect(() => {
        sendGetDepots()
    }, [sendGetDepots])

    return (
        <div onClick={hideSidebars}>
            <DepotsMap depots={depots}/>
        </div>
    )
}
export default CardLocomotiveDepot