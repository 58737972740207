import React, {useState, useEffect} from 'react'
import {eventTypes} from '../EventTypes'
import {Form, Button} from 'semantic-ui-react'
import DropdownField from '../fields/DropdownListField'
import YavkaRange from '../fields/YavkaRange'
import moment from 'moment'
import useApiMethod from '../../../hooks/useApiMethod'
import ekasut from '../../../api/ekasut'
import TextField from '../fields/TextField'
import DatePickerField from '../fields/DatePickerField'
import CheckboxField from '../fields/CheckboxField'
import MultipleSelectList from '../fields/MultipleSelectList'
import CommentField from '../fields/CommentField'
import styles from '../index.module.scss'
import {isAllDefined} from "../../../helpers/utils";
import DateRangeField from "../fields/DateRangeField";

export default function AddResultForm1(
    {
        employee, eventType, onSave, onNotAllFill, defYavkaFrom
    }
) {

    const [values, setValues] = useState({
        eventType: eventType,
        employee: employee,
        train: '',
        yavka: undefined,
        workBeginDate: undefined,
        workEndDate: undefined,
        talonTakenAway: false,
        rightDepartureDate: undefined,
        remarks: [],
        remarksComment: '',
        recommendations: [],
        recommendationsComment: '',
        goals: [],
        goalsComment: '',
        stationBegin: null,
        stationEnd: null
    })

    const [sendGetYavkaList, yavkaListLoading, yavkaList, setYavkaList] = useApiMethod(ekasut.jy.getYavkaList, undefined, [],
        ({data}) => data.map(item => ({...item, t1: moment(item.t1)})))
    const [sendGetGoals, goalsLoading, goalsList] = useApiMethod(ekasut.tchmiNsi.getKip, undefined, [], classifierConverter)
    const [sendGetRemarks, remarksLoading, remarksList] = useApiMethod(ekasut.tchmiNsi.getNotes, undefined, [], classifierConverter)
    const [sendGetRecommendations, recommendationsLoading, recommendationsList] = useApiMethod(ekasut.tchmiNsi.getMeasures, undefined, [], classifierConverter)

    const updateValues = (editValues) => setValues({...values, ...editValues})

    useEffect(() => {
        sendGetGoals()
        sendGetRemarks()
        sendGetRecommendations()
    }, [sendGetGoals, sendGetRemarks, sendGetRecommendations])

    return (
        <Form>
            <DropdownField
                label='Тип мероприятия'
                editable={false}
                list={eventTypes}
                defaultValue={eventTypes.findIndex(type => type.id === eventType.id)}
            />
            <DropdownField
                label='Сотрудник'
                editable={false}
                list={[{text: employee.fio}]}
                defaultValue={0}
            />
            <YavkaRange
                label='Выбор явки'
                defFrom={defYavkaFrom}
                defTo={moment(defYavkaFrom).add(13, 'day')}
                yavkaList={yavkaList}
                yavkaListLoading={yavkaListLoading}
                onRefresh={(from, to) => {
                    setYavkaList([])
                    sendGetYavkaList(employee.asutrId, from, to)
                }}
                onYavkaChange={(yavka) => {
                    if (yavka === null) {
                        updateValues({yavka: null, stationBegin: null, stationEnd: null})
                    } else {
                        updateValues({
                            yavka: yavka,
                            stationBegin: {id: yavka.stOtprIdCnsi, name: yavka.stOtpr},
                            stationEnd: {id: yavka.stPribIdCnsi, name: yavka.stPrib}
                        })
                    }
                }}
            />
            <DropdownField
                label='Станция начала работы'
                editable={false}
                list={values.stationBegin === null ? [] : [{text: values.stationBegin.name}]}
                value={values.stationBegin === null ? null : 0}
                placeholder='Выберите явку'
            />
            <DropdownField
                label='Станция окончания работы'
                editable={false}
                list={values.stationEnd === null ? [] : [{text: values.stationEnd.name}]}
                value={values.stationEnd === null ? null : 0}
                placeholder='Выберите явку'
            />
            <TextField
                editable={true}
                label='Номер поезда'
                value={values.train}
                onChange={(value) => {
                    updateValues({train: value})
                }}
            />
            <DateRangeField
                label='Даты начала и окончания работ'
                editable={true}
                startDate={values.workBeginDate}
                endDate={values.workEndDate}
                onChange={(startDate, endDate) => {
                    updateValues({workBeginDate: startDate, workEndDate: endDate})
                }}
            />
            <CheckboxField
                editable={true}
                label='Признак изъятия талона'
                value={values.talonTakenAway}
                onChange={(value) => {
                    updateValues({talonTakenAway: value})
                }}
            />
            <DatePickerField
                label='Срок действия права выезда на обслуживаемые участки'
                editable={true}
                value={values.rightDepartureDate}
                onChange={(value) => {
                    updateValues({rightDepartureDate: value})
                }}
            />
            <MultipleSelectList
                label='Цели (основные)'
                editable={true}
                list={goalsList}
                loading={goalsLoading}
                hideBoxes={true}
                onChange={(value) => {
                    updateValues({goals: value})
                }}
            />
            <CommentField
                label='Цели (дополнительные)'
                editable={true}
                onChange={(value) => {
                    updateValues({goalsComment: value})
                }}
            />
            <MultipleSelectList
                label='Замечания (основные)'
                editable={true}
                hideBoxes={true}
                list={remarksList}
                loading={remarksLoading}
                onChange={(value) => {
                    updateValues({remarks: value})
                }}
            />
            <CommentField
                label='Замечания (дополнительные)'
                editable={true}
                onChange={(value) => {
                    updateValues({remarksComment: value})
                }}
            />
            <MultipleSelectList
                label='Рекомендации (основные)'
                editable={true}
                hideBoxes={true}
                list={recommendationsList}
                loading={recommendationsLoading}
                onChange={(value) => {
                    updateValues({recommendations: value})
                }}
            />
            <CommentField
                label='Рекомендации (дополнительные)'
                editable={true}
                onChange={(value) => {
                    updateValues({recommendationsComment: value})
                }}
            />
            <Button
                content='Сохранить'
                floated='right' primary
                className={styles.bottomButton}
                onClick={() => {
                    if (!isAllDefined(values.yavka, values.workBeginDate, values.workEndDate,
                        values.rightDepartureDate) && (values.train === '')) {
                        onNotAllFill()
                    } else {
                        onSave(values)
                    }
                }}
            />
        </Form>
    )
}
AddResultForm1.defaultProps = {
    onSave: () => {
    }
}
const classifierConverter = ({data}) => data.map(item => ({text: item.name, id: item.code}))