import React, { useEffect, useState } from "react";
import {
  Dimmer,
  Loader,
  Segment,
  Button,
  Grid,
  Header,
  Icon,
} from "semantic-ui-react";
import ekasut from "../../../../api/ekasut";
import styles from "../OrganizationInspection.module.scss";
import { Checkbox, Input, Radio, Space, Table, Tag } from "antd";
import { Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useRef } from "react";
import Highlighter from 'react-highlight-words';
const { Option } = Select;

const InventoryPark = ({ pred, periodFilter }) => {

  const { yearCO, typeCO } = periodFilter;
  const [chartData, setChartData] = useState([{}, {}]);
  const [loading, setLoading] = useState(false);
  const [editView, setEditView] = useState(false);
  const [sections, setSections] = useState([]);
  const [reason, setReason] = useState([])
  const [orderUUID, setOrderUUID] = useState(null)
  const [sectionUUID, setSectionUUID] = useState(null)
  const [objectID, setObjectID] = useState(null)
  const [resonID, setResonID] = useState(null)
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Серия",
      dataIndex: "ser",
      key: "ser",
    },
    {
      title: "№ секции",
      dataIndex: "numSec",
      key: "numSec",
      ...getColumnSearchProps('numSec')
    },
    {
      title: "Причина исключениях",
      dataIndex: "reason",
      key: "reason",
    },
  ];

  const init = async () => {
    const orderSection = await ekasut.commisionInspection.getOrderSection(
      localStorage.kodFirm,
      localStorage.seasonYear,
      localStorage.season,
      0
    );
    setSections(orderSection?.data);

    const reasonEXCL = await ekasut.commisionInspection.getReasonExcl(
      localStorage.kodFirm,
      localStorage.seasonYear,
      localStorage.season,
      0
    );
    setReason(reasonEXCL?.data);

    
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const postObjectReason = async() => {
      await ekasut.commisionInspection.postObjectReason(orderUUID, sectionUUID, objectID, resonID)

      const orderSection = await ekasut.commisionInspection.getOrderSection(
        localStorage.kodFirm,
        localStorage.seasonYear,
        localStorage.season,
        0
      );
      setSections(orderSection?.data);
    } 

    postObjectReason()
  }, [resonID])

  const data = sections.map((section) => {
    return {
      key: section?.idObject,
      ser: section?.serName,
      numSec: section?.nomSec,
      reason: (
        <Select
          placeholder='Выберите ...'
          style={{
            width: '100%',
          }}
          onChange={(e) => {
            setOrderUUID(section?.rgOrder)
            setSectionUUID(section?.rgSec)
            setObjectID(section?.idObject)
            setResonID(e)
          }}
          defaultValue={section?.nameReason}
        >
          {reason?.map(reas => (
            <Option 
              key={reas?.id} 
              value={reas?.id}
            >
              {reas?.refName}
            </Option>
          ))}
        </Select>
      ),
    };
  });

  return (
    <div>
      <span className={styles.nameSpace}>Инвентарный парк в приказе</span>

      <Table
        columns={columns}
        pagination={{
          position: "bottomRight",
        }}
        dataSource={data}
        rowKey="key"
      />
    </div>
  );
};

export default InventoryPark;
