import React, { useEffect, useState } from "react";
import "./index.scss";
import { observer, useLocalStore } from "mobx-react-lite";
import cn from "classnames";
import {
  Accordion,
  Dropdown,
  Header,
  Icon,
  Image,
  List,
} from "semantic-ui-react";
import logoIcon from "../../../assets/images/logo.png";
import { Link, useHistory } from "react-router-dom";
import TopMenu from "./TopMenu";

const AppMenu = observer(
  ({
    children,
    mainMenu,
    userMenu,
    routeLogoTo,
    routeBellTo,
    hasNewNotifications,
    onExit,
    notificationChat,
    updateNotificationsStatus,
    notificationTask,
  }) => {
    const [current, setCurrent] = useState("mail");

    const ls = useLocalStore(() => ({
      expandSidebar: false,
      persistSidebarOpen: false,
      // activeGroup: {sectionIndex: null, groupIndex: null},
      selectedGroup: { sectionIndex: null, groupIndex: null },
      currentView: { sectionIndex: null, groupIndex: null, viewIndex: null },
    }));

    const history = useHistory(); // location, pathname
    let counter = 0;
    const [delayHandler, setDelayHandler] = useState(null);

    const [userInfo, setUserInfo] = useState({
      fio: "Пользователь",
      icon: false,
    });

    useEffect(() => {
      const fio = decodeURIComponent(escape(localStorage.getItem("fio")));
      const icon = localStorage.getItem("icon");
      setUserInfo((prevState) => ({
        fio: fio ? fio : prevState.fio,
        icon: icon ? icon : prevState.icon,
      }));
    }, []);

    const handleMouseEnter = (event) => {
      if (counter < 4) {
        setDelayHandler(
          setTimeout(() => {
            if (event === "clickSideMenu") {
              ls.expandSidebar = true;
              clearTimeout(delayHandler);
            } else {
              counter += 1;
            }
            if (!ls.persistSidebarOpen && counter === 3) {
              ls.expandSidebar = true;
              clearTimeout(delayHandler);
            }
            handleMouseEnter();
          }, 300)
        );
      }
    };

    useEffect(() => {
      let foundPath = false;
      mainMenu.every((section, sectionIndex) => {
        return section.groups === undefined
          ? true
          : section.groups.every((group, groupIndex) => {
              return group.views === undefined
                ? true
                : group.views.every((view, viewIndex) => {
                    if (view.to === history.location.pathname) {
                      ls.currentView = { sectionIndex, groupIndex, viewIndex };
                      foundPath = true;
                      return false;
                    }
                    return true;
                  });
            });
      });

      if (!foundPath) {
        ls.currentView = {
          sectionIndex: null,
          groupIndex: null,
          viewIndex: null,
        };
      }
    }, [history.location.pathname]);

    const [showMainContainer, setShowMainContainer] = useState(true);
    const [showFullScreenMenuState, setShowFullScreenMenuState] =
      useState(false);

    const showFullScreenMenu = (_) => {
      setShowFullScreenMenuState(!showFullScreenMenuState);
    };

    return (
      <div className="app app-menu">
        <div className="header-block">
          <div className="header-container">
            <div className="header-part left">
              <MenuButton
                name="sidebar"
                onClick={() => {
                  ls.persistSidebarOpen = !ls.persistSidebarOpen;
                  ls.expandSidebar = ls.persistSidebarOpen;
                  setShowMainContainer(!showMainContainer);
                }}
              />
              <Link to={routeLogoTo}>
                <Image src={logoIcon} className="logo-icon" />
              </Link>
              <TopMenu mainMenu={mainMenu} />
            </div>
            <div className="header-part">
              {/*<MenuButton name="tasks"/>*/}
              <NotificationBell isNewMessages={hasNewNotifications} to="" />

              <Message
                isNewMessages={hasNewNotifications}
                to="/chats"
                notificationChat={notificationChat}
              />
              <TaskboardList
                isNewMessages={hasNewNotifications}
                to={routeBellTo}
                notificationTask={notificationTask}
                updateNotificationsStatus={updateNotificationsStatus}
              />
              <span className="header-divider" />
              {/* incorrect coding from token in userInfo?.fio*/}
              {/* <Dropdown className="user-name" text={userInfo?.fio} icon="angle down" item pointing="top right"> */}
              <Dropdown
                className="user-name"
                text="Пользователь"
                icon="angle down"
                item
                pointing="top right"
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    content="Мой профиль"
                    onClick={() =>
                      history.push(`/profile/${localStorage.userId}`)
                    }
                  />
                  {userMenu.map(
                    (item, itemIndex) =>
                      (item.show || item.show === undefined) &&
                      (item.divider ? (
                        <Dropdown.Divider key={itemIndex} />
                      ) : (
                        /*: <Dropdown.Item key={itemIndex} content={item.content}
                                                         onClick={() => item.onClick && item.onClick()}/>*/
                        <Dropdown.Item key={itemIndex} className="menu-link">
                          <Link to={item.to}>{item.content}</Link>
                        </Dropdown.Item>
                      ))
                  )}
                  {userMenu.some(
                    (item) => item.show || item.show === undefined
                  ) && <Dropdown.Divider />}
                  <Dropdown.Item content="Выход" onClick={onExit} />
                </Dropdown.Menu>
              </Dropdown>
              {/*<div className="avatar">
                            ФИ
                        </div>*/}
            </div>
          </div>
        </div>
        <div
          className={cn("sidebar-block", ls.expandSidebar && "sidebar-expand")}
          onMouseEnter={() => {
            handleMouseEnter();
          }}
          onMouseLeave={() => {
            if (counter !== 3) {
              clearTimeout(delayHandler);
            }
            if (!ls.persistSidebarOpen) {
              ls.expandSidebar = false;
              ls.selectedGroup = { sectionIndex: null, groupIndex: null };
            }
          }}
          style={{ display: showMainContainer ? "none" : "flex" }}
        >
          {/* <div style={{ marginLeft: "4px", marginTop: "20px", width: "300px" }}>
            <Icon name={"newspaper"} className="group-icon" />
            <a
              href="/"
              style={{
                marginLeft: "4px",
                fontSize: "15px",
                display: "none",
                textTransform: "uppercase",
                color: "#607D8B",
                fontWeight: 600,
              }}
            >
              Новости
            </a>
          </div> */}
          {mainMenu.map((menuSection, sectionIndex) => {
            return (
              (menuSection.show || menuSection.show === undefined) && (
                <React.Fragment key={sectionIndex}>
                  <Header
                    as="h4"
                    content={menuSection.header.toUpperCase()}
                    className="section-header"
                  />
                  {menuSection.groups && (
                    <List selection className="group-list">
                      {menuSection.groups.map((group, groupIndex) => {
                        const isGroupSelected =
                          ls.selectedGroup.groupIndex === groupIndex &&
                          ls.selectedGroup.sectionIndex === sectionIndex;
                        const isCurrentGroup =
                          ls.currentView.groupIndex === groupIndex &&
                          ls.currentView.sectionIndex === sectionIndex;
                        return (
                          (group.show || group.show === undefined) && (
                            <List.Item
                              style={{ display: "flex", alignItems: "center" }}
                              key={groupIndex}
                              className={cn(
                                "group-item",
                                isGroupSelected && "active",
                                isCurrentGroup && "active"
                              )}
                              onClick={(e) => {
                                handleMouseEnter("clickSideMenu");
                                ls.selectedGroup = isGroupSelected
                                  ? { sectionIndex: null, groupIndex: null }
                                  : { sectionIndex, groupIndex };
                              }}
                            >
                              <Icon name={group.icon} className="group-icon" />
                              <Accordion className="group-accordion">
                                <Accordion.Title
                                  icon=""
                                  className="group-title"
                                  content={group.groupName}
                                  active={true}
                                  index={groupIndex}
                                />
                                {group.views && (
                                  <Accordion.Content
                                    className="view-list"
                                    active={true}
                                  >
                                    <List selection>
                                      {group.views.map(
                                        (view, viewIndex) =>
                                          (view.show === true ||
                                            view.show === undefined) && (
                                            <React.Fragment key={viewIndex}>
                                              {view.to && (
                                                <Link
                                                  to={view.to}
                                                  className={cn(
                                                    "view-title",
                                                    isCurrentGroup &&
                                                      ls.currentView
                                                        .viewIndex ===
                                                        viewIndex &&
                                                      "active-view"
                                                  )}
                                                  onClick={() => {
                                                    ls.currentView = {
                                                      sectionIndex,
                                                      groupIndex,
                                                      viewIndex,
                                                    };
                                                    ls.persistSidebarOpen =
                                                      !ls.persistSidebarOpen;
                                                    ls.expandSidebar =
                                                      ls.persistSidebarOpen;
                                                    setShowMainContainer(
                                                      !showMainContainer
                                                    );
                                                  }}
                                                >
                                                  {view.viewName}
                                                </Link>
                                              )}
                                              {view.href && (
                                                <a
                                                  target="_blank"
                                                  href={view.href}
                                                  className="view-title"
                                                >
                                                  {view.viewName}
                                                </a>
                                              )}
                                            </React.Fragment>
                                          )
                                      )}
                                    </List>
                                  </Accordion.Content>
                                )}
                              </Accordion>
                            </List.Item>
                          )
                        );
                      })}
                      {/* {sectionIndex === 0 &&
                      localStorage.getItem("grants").includes("tech_cert") ? (
                        <List.Item>
                          <h3
                            style={{
                              color: "#607D8B",
                              fontSize: "15px",
                              fontWeight: "200",
                              fontFamily: "Roboto, sans-serif",
                              transition: "opacity 300ms",
                              paddingLeft: "33px",
                            }}
                          >
                            <a
                              href="http://dev-tp.infokom.org/tzrem"
                              style={{
                                color: "#607D8B",
                              }}
                            >
                              {ls.expandSidebar
                                ? "Замеры технических параметров локомотивов"
                                : ""}
                            </a>
                          </h3>
                        </List.Item>
                      ) : (
                        ""
                      )} */}
                    </List>
                  )}
                </React.Fragment>
              )
            );
          })}
        </div>
        {showMainContainer ? (
          <div
            className={cn(
              "content-container",
              ls.expandSidebar && "sidebar-expand"
            )}
          >
            <div>{children}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
);

const NotificationBell = ({
  className,
  isNewMessages,
  notificationChat,
  to,
  ...props
}) => {
  return (
    <Link to={to} className={cn("menu-button")}>
      <div className="notification-bell">
        <Icon name="bell" {...props} />
        <span>{notificationChat}</span>
        {isNewMessages && <div className="new-messages" />}
      </div>
    </Link>
  );
};

const Message = ({
  className,
  isNewMessages,
  notificationChat,
  to,
  ...props
}) => {
  return (
    <Link to={to} className={cn("menu-button")}>
      <div className="notification-bell">
        <Icon name="conversation" {...props} />
        <span>{notificationChat}</span>
        {isNewMessages && <div className="new-messages" />}
      </div>
    </Link>
  );
};

const TaskboardList = ({
  className,
  isNewMessages,
  updateNotificationsStatus,
  notificationTask,
  to,
  ...props
}) => {
  return (
    <Link to={to} className={cn("menu-button")}>
      <div className="notification-bell">
        <Icon name="tasks" {...props} />
        <span>{notificationTask}</span>
        {isNewMessages && <div className="new-messages" />}
      </div>
    </Link>
  );
};

const MenuButton = ({ className, iconClassName, onClick, ...props }) => {
  return (
    <div className={cn(className, "menu-button")} onClick={onClick}>
      <Icon className={iconClassName} {...props} />
    </div>
  );
};

export default AppMenu;
