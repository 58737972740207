import React from 'react'
import {Table} from 'semantic-ui-react'

const ArmDetails = ({armInfo}) => {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Наименование начальной станции</Table.HeaderCell>
                    <Table.HeaderCell>Наименование конечной станции</Table.HeaderCell>
                    <Table.HeaderCell>Наименование контрольной станции</Table.HeaderCell>
                    <Table.HeaderCell>Вид тяги (1- Электрическая, 2- Теплотяга)</Table.HeaderCell>
                    <Table.HeaderCell>Норма времени с оборота (мин.)</Table.HeaderCell>
                    <Table.HeaderCell>Расстояние туда (км)</Table.HeaderCell>
                    <Table.HeaderCell>Расстояние обратно (км)</Table.HeaderCell>
                    <Table.HeaderCell>Весовая норма норма туда (т.)</Table.HeaderCell>
                    <Table.HeaderCell>Весовая норма обратно (т.)</Table.HeaderCell>
                    <Table.HeaderCell>Макс. время туда (мин.)</Table.HeaderCell>
                    <Table.HeaderCell>Макс. время обратно (мин.)</Table.HeaderCell>
                    <Table.HeaderCell>Участковая скорость туда (км/ч)</Table.HeaderCell>
                    <Table.HeaderCell>Участковая скорость обратно (км/ч)</Table.HeaderCell>
                    <Table.HeaderCell>Техн. скорость туда (км/ч)</Table.HeaderCell>
                    <Table.HeaderCell>Техн. скорость обратно (км/ч)</Table.HeaderCell>
                    <Table.HeaderCell>Пробежная норма туда (мин.)</Table.HeaderCell>
                    <Table.HeaderCell>Пробежная норма обратно (мин.)</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                { armInfo
                    ? armInfo.map( x => (
                    <Table.Row>
                        <Table.Cell>{x.stOtpName}</Table.Cell>
                        <Table.Cell>{x.stPribName}</Table.Cell>
                        <Table.Cell>{x.stCtlName}</Table.Cell>
                        <Table.Cell>{x.track}</Table.Cell>
                        <Table.Cell>{x.workOborot}</Table.Cell>
                        <Table.Cell>{x.distance12}</Table.Cell>
                        <Table.Cell>{x.distance21}</Table.Cell>
                        <Table.Cell>{x.weight}</Table.Cell>
                        <Table.Cell>{x.weight21}</Table.Cell>
                        <Table.Cell>{x.workTime12}</Table.Cell>
                        <Table.Cell>{x.workTime21}</Table.Cell>
                        <Table.Cell>{x.uchVel12}</Table.Cell>
                        <Table.Cell>{x.uchVel21}</Table.Cell>
                        <Table.Cell>{x.techV12}</Table.Cell>
                        <Table.Cell>{x.techV21}</Table.Cell>
                        <Table.Cell>{x.probNorm12}</Table.Cell>
                        <Table.Cell>{x.probNorm21}</Table.Cell>
                    </Table.Row>
                ))
                    : null
                }
            </Table.Body>
        </Table>
    )
}
export default ArmDetails