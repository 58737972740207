import React from 'react';
import FormField from "../../../notUsed/PlanWorkMobX/components/FormField";
import {Dropdown} from "semantic-ui-react";
import {formOptions} from "../../../../helpers/utils";
import appStyles from '../../../../css/App.module.scss'

const FormSearch = ({label, loading, list, textFieldName, onChange, disabled, value, noResultsMessage, readOnly, readOnlyValue}) => {
    return (
        <FormField label={label}>
            {readOnly ? (
                <Dropdown
                    fluid selection search selectOnNavigation={false}
                    value={0}
                    disabled={true}
                    className={appStyles.fixedDropdown}
                    options={formOptions([readOnlyValue])}
                />
            ) : (
                <Dropdown
                    fluid selection search selectOnNavigation={false}
                    loading={loading}
                    noResultsMessage={noResultsMessage}
                    value={value}
                    disabled={readOnly || disabled}
                    options={formOptions(list, textFieldName)}
                    onChange={(e, {value}) => {
                        onChange(list[value], value)
                    }}
                />
            )}
        </FormField>
    );
};
FormSearch.defaultProps = {
    onChange: () => {
    },
    readOnly: false
}

export default FormSearch;