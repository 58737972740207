import React, {useEffect, useState} from 'react';
import ekasut from "../../../../../api/ekasut";
import {Table} from "antd";

export const WorkPlaceTab = ({id}) => {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const columns = [
        {
            title: 'Наименование станции', children: [
                {dataIndex: 'stbName', title: 'начальной',},
                {dataIndex: 'steName', title: 'конечной',},
                {dataIndex: 'stcName', title: 'контрольной',},
            ]
        },
        {dataIndex: 'ispName', title: 'Вид использования',},
        {dataIndex: 'dateMash', title: 'Дата выдачи – машинистом',},
        {dataIndex: 'datePom', title: 'Дата выдачи – помощником',},
    ]


    useEffect(() => {
        setLoadingData(true)
        ekasut.persData.getPersonPolygons(id).then(r => {
            setData(r.data)
            setLoadingData(false)
        }).catch(err => {
            console.log(err)
            setLoadingData(false)
        })
    }, [])
    return (
        <Table loading={loadingData}
               columns={columns}
               dataSource={data}
               pagination={false}
               scroll={{x: 1200, y: 400}}
        />
    )
}