import React from 'react';
import TopHeader from "../../../notUsed/PlanWorkMobX/components/TopHeader";
import Content from "../../../notUsed/PlanWorkMobX/components/Content";
import AddLocRemarks from "./AddLocRemarks";
import {useHistory, useRouteMatch} from 'react-router-dom'
import {useStore} from "../StoreProvider";
import {observer} from "mobx-react-lite";
import {usePrompt} from "../../../../globalStore/useGlobalStore";
import AlignButton from "../components/AlignButton";

const AddLocRemarkForm = observer(() => {
    const history = useHistory()
    const match = useRouteMatch()
    const store = useStore()
    const prompt = usePrompt()

    return (<>
        <TopHeader header="Создание замечаний к локомотиву"/>
        <Content loading={false}>
            <AddLocRemarks/>

            <PreviewButton
                content="Предпросмотр"
                onClick={() => {
                    if (checkSelectedValues(store.selected)) {
                        history.push(match.path + "?preview")
                    } else {
                        prompt.show(
                            "Не все поля заполнены",
                            "Необходимо как минимум указать серию локомотива, номер и секцию для каждой выбранной группы замечаний. Комментарии не обязательны",
                            {ok: true}
                        )
                    }
                }}
            />
        </Content>
    </>);
})

const PreviewButton = ({...btnProps}) => (
    <AlignButton
        primary basic style={{marginRight: 0, marginTop: 30}} align="right"
        {...btnProps}
    />
)

const checkSelectedValues = (selected) => {
    for (let id in selected) {
        const sel = selected[id]
        if (sel.series === null || sel.number === null || sel.section === null) {
            return false
        }
        if (sel.remarks.length === 0) {
            return false
        }
    }
    return true
}



export default AddLocRemarkForm;