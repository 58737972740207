import React, { Component } from 'react'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import ReactDOM from 'react-dom'

export default class ComponentMarker extends Component {

	constructor(props) {
		super(props)
		// We will render React components into this div element
		this.htmlDiv = document.createElement('div')
		this.divIcon = L.divIcon({
			html: this.htmlDiv
		})
	}

	componentDidMount() {
		// Render React component into div element for marker
		ReactDOM.render(this.props.children, this.htmlDiv)
	}

	componentDidUpdate() {
		// If children changes we need to re render 
		ReactDOM.render(this.props.children, this.htmlDiv)
	}

	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this.htmlDiv)
	}

	render() {
		var {position, relX, relY, style} = this.props
		relX = relX === undefined ? 0 : relX
		relY = relY === undefined ? 0 : relY
		// 3px - position adjustment. Marker container have fixed width and height: 6px
		this.htmlDiv.setAttribute('style', `margin-left: ${relX+3}px; margin-top: ${relY+3}px;`)
		return (
			<Marker
				className={this.props.className}
				position={position}
				icon={this.divIcon}
				style={style}
				draggable={false}
				// onDragStart={(e, e1) => console.log(e.target._latlng)}
				// onDragEnd={(e, e1) => console.log(e.target._latlng)}
			/>
		)
	}
}
