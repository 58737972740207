import React from 'react';
import LocomotiveModal from '../../shared/LocomotiveModal'
import {observer, useLocalStore} from "mobx-react-lite";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import useQueryParams from "../../hooks/useQueryParams";
import {useLocation} from "react-router-dom";
import useHashParams from "../../hooks/useHashParams";

const Locomotive = () => {
    const params = useHashParams()

    const locId = params.get("id")

    console.log(params.get("sef"))

    const undefIfNull = (value) => value === null ? undefined : value

    return (
        <LocomotiveModal
            open={true}
            locId={undefIfNull(params.get("id"))}
            seriesId={undefIfNull(params.get("ser"))}
            number={undefIfNull(params.get("prs"))}
            sectionId={undefIfNull(params.get("zns"))}
        />
    );
}

export default Locomotive;