import React from 'react';

export default function Appointment(model) {
  const { targetedAppointmentData } = model.data;


  return (
    <div className="showtime-preview">
      <span>{`${targetedAppointmentData.text}      `}</span>
      <span>{`${targetedAppointmentData.nomSec}      `}</span>
      <span>{`${targetedAppointmentData.nameRepair}   `}</span>
      {/* <div>{`${targetedAppointmentData.startDate}`}</div> */}
    </div>
  );
}
