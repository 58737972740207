import React, { useEffect, useMemo, useState } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import GlobalPrompt from "./GlobalPrompt";
import NetworkErrorModal from "./NetworkErrorModal";
import init from "./init";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import AppMenu from "./AppMenu";
import Version from "../Version";
import Login from "./Login";
import axios from "axios";
import ekasut from "../../api/ekasut";
import dbSync from "../../api/dbSync";
import useGlobalStore from "../../globalStore/useGlobalStore";
import LocomotiveDislocation from "../LocomotiveDislocation/locomotiveDislocation";
import RecommendationSelectionLocomotive from "../RecommendationSelectionLocomotive";
import NsiWeightLength from "../NsiWeightLength";
import PlanRepairs from "../PlanRepairs";
import InvestigationLb from "../InvestigationLb";
import PlanWorkBrigades from "../PlanWorkBrigades";
import DispatchingControlAccess from "../DispatchingControlAccess";
import AuditControl from "../AuditControl";
import TransferToTr from "../TransferToTr";
import TransferFromTr from "../TransferFromTr";
import CardLocomotiveDepot from "../CardLocomotiveDepot";
import ReceiverRating from "../ReceiverRating";
import RatingsWorkLocomotives from "../RatingsWorkLocomotives";
import RatingsWorkBrigades from "../RatingsWorkBrigades";
import WorkingCapacityEtso from "../WorkingCapacityETSO";
import UsingPowerSld from "../UsingPowerSld";
import Notifications from "../Notifications";
import auth from "../../helpers/auth";
import ControlRoles from "../ControlRoles";
import ControlServices from "../ControlServices";
import Quiz from "../Quiz";
import ProcurementPlanning from "../ProcurementPlanning";
import IFrameView from "../../shared/IframeView";
import Locomotive from "../Locomotive";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import LocomotivesInCategories from "../LocomotivesInCatergories";
import PageNotFound from "../PageNotFound";
import GroupRiskLocomotives from "../GroupRiskLocomotives/GroupRiskLocomotives";
import MonitorAutomaticBind from "../MonitorAutomaticBind";
import NetJournalWork from "../notUsed/NetJournalPresence";
import CommissionInspection from "../CommissionInspection";
import { Taskboard } from "../Taskboard/index";
import { ContainerNews } from "../News/ContainerNews";
import { NewsDetails } from "../News/NewsDetails";
import { Chats } from "../Chats/Chats";
import { FullTask } from "../Taskboard/dashboardsComponents/FullTask/FullTask";
import { RegDepotOnScheme } from "../RegDepotOnScheme/RegDepotOnScheme";
import { GroupRisk } from "../GroupRiskLocomotives/GroupRisk";
import { Profile } from "../Profile/Profile";
import {
  HttpTransportType,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import moment from "moment";
import Kasant from "../Kasant/Kasant.js";
import LocomotivesConservation from "../LocomotivesConservation";
import Auth from "../Auth/Auth";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ReportsOBL from "../ReportsOBL/ReportsOBL.js";

init();

const NewApp = observer(() => {
  // const [activeChat, setActiveChat] = useState(false);

  // const [chatsLIst, setChatsLIst] = useState({
  //   activeChat: activeChat.chatId,
  //   data: [],
  // });
  // const [connection, setConnection] = useState();
  // const [connectionSetTimeOut, setConnectionSetTimeOut] = useState();
  // const [notificationChat, setNotificationChat] = useState(0);
  // const [connectionTask, setConnectionTask] = useState();
  // const [connectionTaskSetTimeOut, setConnectionTaskSetTimeOut] = useState();
  // const [notificationTask, setNotificationTask] = useState(0);

  useEffect(() => {
    const getDatePeriod = async () => {
      const result = await ekasut.commisionInspection.getPeriodOrder(
        localStorage.getItem("seasonYear"),
        localStorage.getItem("season")
      );
      const periodFrom = result?.data[0]?.dateBegPodgCo;
      const periodTo = result?.data[0]?.dateEndPodgCo;
      localStorage.setItem(
        "orderDateFrom",
        moment(periodFrom).format("DD.MM.YYYY")
      );
      localStorage.setItem(
        "orderDateTo",
        moment(periodTo).format("DD.MM.YYYY")
      );
    };

    getDatePeriod();
  }, []);

  // const getChatsList = async () => {
  //   try {
  //     const { data } = await ekasut.chats.getChatsList();
  //     setChatsLIst({ activeChat: activeChat.chatId, data });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const initChat = async () => {
  //   await getChatsList();
  //   try {
  //     const newConnection = await new HubConnectionBuilder()
  //       .withUrl(window.backendUrl + "/hubs/chat", {
  //         withCredentials: true,
  //         skipNegotiation: true,
  //         transport: HttpTransportType.WebSockets,
  //       })
  //       .withHubProtocol(new JsonHubProtocol())
  //       .configureLogging(LogLevel.Information)
  //       .build();
  //     setConnection(newConnection);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const initTask = async () => {
  //   await getChatsList();

  //   try {
  //     const newConnection = await new HubConnectionBuilder()
  //       .withUrl(window.backendUrl + "/hubs/notifications", {
  //         withCredentials: true,
  //         skipNegotiation: true,
  //         transport: HttpTransportType.WebSockets,
  //       })
  //       .withHubProtocol(new JsonHubProtocol())
  //       .configureLogging(LogLevel.Information)
  //       .build();
  //     setConnectionTask(newConnection);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // const startChat = async () => {
  //   try {
  //     await connection?.start();
  //     connection.invoke("LogInOnline");
  //     console.log("SignalR Connected.");
  //   } catch (err) {
  //     console.log(err);
  //     const timeOut = setTimeout(startChat, 5000);
  //     setConnectionSetTimeOut(timeOut);
  //   }
  // };

  // const startTask = async () => {
  //   try {
  //     await connectionTask?.start();
  //     // connectionTask.invoke("LogInOnline");
  //     console.log("SignalR Connected.");
  //   } catch (err) {
  //     console.log(err);
  //     const timeOut = setTimeout(startTask, 5000);
  //     setConnectionTaskSetTimeOut(timeOut);
  //   }
  // };

  // useEffect(() => {
  //   initChat();
  //   initTask();
  // }, []);

  // useEffect(() => {
  //   const getAllNotification = async () => {
  //     const res = await ekasut.chats.getAllNotification();
  //     setNotificationChat(res?.data.messageNotificationsCount);
  //     setNotificationTask(res?.data.taskNotificationsCount);
  //   };

  //   getAllNotification();
  // }, []);

  // useEffect(() => {
  //   if (connection) {
  //     startChat();

  //     connection.on("ReceiveMessageNotifications", (mes) => {
  //       setNotificationChat(mes);
  //     });
  //   }

  //   // return () => {
  //   //   if (connection) {
  //   //     connection.stop();
  //   //   }
  //   //   if (connectionSetTimeOut) {
  //   //     clearTimeout(connectionSetTimeOut);
  //   //   }
  //   // };
  // }, [connection]);

  // useEffect(() => {
  //   if (connectionTask) {
  //     startTask();

  //     connectionTask.on("ReceiveTaskNotification", (mes) => {
  //       setNotificationTask(mes);
  //     });
  //   }
  // }, [connectionTask]);

  const ls = useLocalStore(() => ({
    loggedIn: false,
    hasNewNotifications: false,
  }));

  const globalStore = useGlobalStore();
  const history = useHistory();

  // token can be undefined
  const initApi = (token) => {
    if (token) {
      // support old code
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    ekasut.init({ baseURL: window.backendUrl, token });
    dbSync.init({ baseURL: window.dbsyncUrl, token });
    ekasut.addErrorListener((error) => (globalStore.modalError.error = error));
    dbSync.addErrorListener((error) => (globalStore.modalError.error = error));
  };

  const redirectToAuthorizationPage = () => {
    window.location = `${window.backendUrl}/Auth/Login?fromUrl=${window.location.href}`;
  };

  if (!ekasut.isInitialized() || !dbSync.isInitialized()) {
    initApi();
  }

  const mainMenu = useMemo(() => {
    const mainMenu = [
      {
        header: "Управление процессами",
        groups: [
          {
            groupName: "Парк локомотивов",
            icon: "train",
            views: [
              {
                viewName: "Диспетчеризация процессов эксплуатации",
                show: false,
              },
              { viewName: "Управление состояниями локомотивов", show: false },
              {
                viewName: "Рекомендации по подбору локомотивов",
                roles: "shoulder_garter",
                to: "/recommendation-selection-locomotive",
              },
              {
                viewName: "Управление нормативами веса и длины",
                roles: "nsi_weight_length",
                to: "/nsi-weight-length",
              },
              {
                viewName: "Комиссионый осмотр",
                roles: "komm_osmotr",
                to: "/commission-inspection",
              },
              {
                viewName: "Модуль ТЧД",
                roles: "tchd_view",
                to: "/tchd_view",
              },
              {
                viewName: "Расследование неплановых ремонтов",
                roles: "invest_view",
                to: "/invest_view",
              },
              {
                viewName: "Консервация локомотивов",
                // roles: "locomotives-conservation",
                to: "/locomotives-conservation",
              },
              {
                viewName: "Замеры технических параметров локомотивов",
                roles: "tech_cert",
                to: "/tzrem",
              },
            ],
          },
          {
            groupName: "Сервисное обслуживание",
            icon: "wrench",
            views: [
              {
                viewName: "Планирование ремонтов",
                roles: "Remont_plans",
                to: "/plan-repairs",
              },
              { viewName: "Диспетчеризация процессов ремонта", show: false },
              {
                viewName: "Ведение замеров и диагностических параметров",
                show: false,
              },
              { viewName: "Приемка из сервисного обслуживания", show: false },
            ],
          },
          {
            groupName: "Локомотивные бригады",
            icon: "user",
            views: [
              {
                viewName:
                  "Диспетчеризация процессов работы локомотивных бригад",
                roles: "Incident_investigation",
                to: "/investigation-lb",
              },
              {
                viewName: "Планирование работы локомотивных бригад",
                roles: "brigades",
                href: window.brigades,
              },
              // {
              //     viewName: "Планирование и ведение работы с бригадами",
              //     roles: "lk_tchmi_work_plan",
              //     to: "/plan-work-brigades"
              // },
              {
                viewName: "Журнал явок",
                roles: "online_turnout_log",
                to: "/net-journal-work",
              },
            ],
          },
          {
            groupName: "Обеспечивающие процессы",
            icon: "factory",
            views: [
              { viewName: "Деповское хозяйство", show: false },
              {
                viewName: "Ведение цен и ценообразование",
                roles: [
                  "start_price_formation",
                  "finish_price_formation",
                  "approval_purchases",
                ],
                to: "/procurement-planning",
              },
              {
                viewName: "Менеджер задач",
                roles: ["taskboard", "NSI_taskboard"],
                to: "/taskboard",
              },
            ],
          },
          {
            groupName: "Безопасность движения",
            icon: "shield alternate",
            views: [
              {
                viewName: "Диспетчеризация и управление допуском",
                roles: "dispatching-control-access",
                to: "/dispatching-control-access",
              },
              {
                viewName: "Управление и ведение группы риска локомотивов",
                show: false,
              },
              {
                viewName: "Управление аудитами",
                roles: "audit_defence",
                to: "/audit-control",
              },
              {
                viewName: "Управление нормативами личного участия",
                show: false,
              },
              {
                viewName: "Группа риска локомотивов",
                roles: "risk_tps",
                to: "/group-risk-locomotives",
              },
            ],
          },
        ],
      },
      {
        header: "Отчетность и аналитика",
        groups: [
          {
            groupName: "Статичные отчеты",
            icon: "map",
            views: [
              {
                viewName: "Запросы по дислокации локомотивов",
                roles: "map_view",
                to: "/locomotives-dislocation",
              },
              {
                viewName: "Отчет ОБЛ",
                roles: "wash_tpe",
                to: "/reports-obl",
              },
              {
                viewName: "Запросы по дислокации локомотивных бригад",
                show: false,
              },
              { viewName: "Группа риска локомотивных бригад", show: false },
              { viewName: "Инциденты в работе", show: false },
              {
                viewName: "Передача в ТОиР",
                roles: "repair_waiting",
                to: "/transfer-to-tr",
              },
              {
                viewName: "Выдача из ТОиР",
                roles: "repair_finishing",
                to: "/transfer-from-tr",
              },
              {
                viewName: "Рейтинг приемщика",
                roles: "examiner_rating",
                to: "/receiver-rating",
              },
              {
                viewName: "Карточка локомотивного депо",
                roles: "locomotive_depot_card",
                to: "/card-locomotive-depot",
              },
              {
                viewName: "Локомотивы в категориях",
                roles: "report_2",
                to: "/locomotives-in-categories",
              },
              {
                viewName: "Портал отчетности",
                roles: [
                  "Administrator",
                  "Admin_reports",
                  "Report01_bf",
                  "Report02_remont",
                  "Report03_equip",
                  "Report04_park",
                  "Report05_lb",
                  "Report06_fuel",
                  "Report07_loc",
                  "capacity_monitoring",
                  "order_execution",
                  "automated_garter_monitoring",
                ],
                to: "portal-report",
              },
              /*{viewName: "Количество НР", onClick: () => open(window.pentahoReportsUrl)},
                    {viewName: "Нарушение списка ЛБ", onClick: () => open(window.pentahoReportsUrl)},
                    {viewName: "Оборудование ТПС", onClick: () => open(window.pentahoReportsUrl)},
                    {viewName: "Переработка ЛБ", onClick: () => open(window.pentahoReportsUrl)},
                    {viewName: "Срабатывание БФ", onClick: () => open(window.pentahoReportsUrl)}*/
            ],
          },
          {
            groupName: "Частные отчеты",
            icon: "area graph",
            views: [
              {
                viewName: "Рейтинги и показатели работы локомотивов",
                roles: "manag_stat",
                to: "/ratings-work-locomotives/eprp",
              },
              {
                viewName: "Рейтинги и показатели работы локомотивных бригад",
                // roles: "order_execution",
                roles: "Rate_LB",
                to: "/ratings-work-brigades",
              },
              {
                viewName: "Работоспособность ЭТСО",
                roles: "ETSO_monitoring",
                to: "/working-capacity-etso",
              },
              {
                viewName: "Использование мощностей СЛД",
                roles: "capacity_monitoring",
                to: "/using-power-sld",
              },
              {
                viewName: "Мониторинг автоматизированной подвязки",
                roles: "automated_garter_monitoring",
                to: "/monitor-automatic-bind",
              },
              {
                viewName: "Депо приписки на схеме полигона",
                roles: "depo_prip_map",
                to: "/reg-depot-scheme",
              },
            ],
          },
          {
            groupName: "Динамические отчеты",
            icon: "flipboard",
            views: [
              {
                viewName: "Дашборд",
                roles: [
                  "Administrator",
                  "Admin_reports",
                  "Report01_bf",
                  "Report02_remont",
                  "Report03_equip",
                  "Report04_park",
                  "Report05_lb",
                  "Report06_fuel",
                  "Report07_loc",
                ],
                to: "/pentaho-dashboard",
              },
              {
                viewName: "Гибкая отчётность",
                roles: [
                  "Administrator",
                  "Admin_reports",
                  "Report01_bf",
                  "Report02_remont",
                  "Report03_equip",
                  "Report04_park",
                  "Report05_lb",
                  "Report06_fuel",
                  "Report07_loc",
                ],
                to: "/flexible-report",
              },
            ],
          },
          {
            groupName: "Прогнозные модели",
            icon: "clock",
            views: [
              {
                viewName: "Баланс парков локомотивов",
                // roles: "balance_park",
                roles: [
                  "bp_ct",
                  "bp_confirmation",
                  " bp_report",
                  "bp_ct",
                  "bp_confirmation",
                  "bp_requirement_view",
                  "bp_requirement_edit",
                  "bp_delivery_view",
                  "bp_delivery_edit",
                  "bp_writeoff_view",
                  "bp_writeoff_edit",
                  "bp_PSS_view",
                  "bp_PSS_edit",
                  "bp_renovation_view",
                  "bp_renovation_edit",
                ],
                to: "/balance-park",
              },
              {
                viewName: "Бюджет затрат на доп. работы",
                // roles: "bdop",
                roles: ["dr_read", "dr_edit"],
                to: "/bdop",
              },
              {
                viewName: "Линейное оборудование ОФ",
                // roles: "bdop",
                roles: ["lin_ob_ct"],
                to: "/lcomp",
              },
              {
                viewName: "Прогнозирование времени в НЭП",
                // roles: "bdop",
                roles: ["nep_all"],
                to: "/nep",
              },
            ],
          },
        ],
      },
      {
        header: "Помощь и справка",
        show: false,
        groups: [
          {
            groupName: "Обучение",
            icon: "book",
          },
          {
            groupName: "Руководство пользователя",
            icon: "question",
          },
          {
            groupName: "Список изменений в ЕК АСУТ",
            icon: "code branch",
          },
        ],
      },
    ];

    const hideViewsWithoutRoles = (item, userRoles) => {
      item.groups &&
        item.groups.forEach((group) => {
          group.views &&
            group.views.forEach((view) => {
              if (view.show || view.show === undefined) {
                if (typeof view.roles === "string") {
                  view.show = userRoles.includes(view.roles);
                  return;
                }
                if (view.roles instanceof Array) {
                  view.show = userRoles.some((role) =>
                    view.roles.includes(role)
                  );
                  return;
                }
              }
            });
        });
    };

    const hideItemAndGroupsWithoutViews = (item) => {
      if (item.show === true || item.show === undefined) {
        if (item.groups === undefined) {
          item.show = false;
        } else {
          item.groups.forEach((group) => {
            group.show =
              ((group.show === true || group.show === undefined) &&
                group.views &&
                group.views.every((view) => view.show === false)) ||
              group.views === undefined
                ? false
                : group.show;
          });
          // Hide menu items if all it's groups are hidden
          if (item.groups.every((group) => group.show === false)) {
            item.show = false;
          }
        }
      }
    };

    // Скрыть боковую навигацию, если нет ролей (чтобы отобразить отбратно, нужно закомментировать)
    const hideInaccessibleOrEmptySections = () => {
      mainMenu.forEach((item) => {
        hideViewsWithoutRoles(item, auth.getGrants());
        hideItemAndGroupsWithoutViews(item);
      });
    };

    hideInaccessibleOrEmptySections();

    return mainMenu;
  }, [ls.loggedIn]);

  const userMenu = useMemo(() => {
    const userMenu = [
      {
        content: "Просмотр профиля",
        show: false,
      },
      {
        content: "Нормативы личного участия",
        show: false,
      },
      {
        divider: true,
        show: false,
      },
      {
        content: "Управление сервисами",
        roles: "service_adm",
        to: "/control-services",
      },
      {
        content: "Управление ролями",
        roles: "roles_adm",
        to: "/control-roles",
      },
    ];

    const hideInaccessibleItems = () => {
      userMenu.forEach((item) => {
        if (item.show || item.show === undefined) {
          if (typeof item.roles === "string") {
            item.show = auth.getGrants().includes(item.roles);
          }
          if (item.roles instanceof Array) {
            item.show = auth
              .getGrants()
              .some((role) => item.roles.includes(role));
          }
        }
      });
    };

    hideInaccessibleItems();

    return userMenu;
  }, [ls.loggedIn]);

  const checkNewNotifications = () => {
    // return ekasut.notification.hasNew()
    //     .then(({data}) => {
    //         ls.hasNewNotifications = data.hasSvps || data.hasKasant
    //     })
    //     .catch((err) => {
    //         ls.hasNewNotifications = false
    //         return Promise.reject(err)
    //     })
  };

  // check new notifications on timer
  useEffect(() => {
    // fixme: if token is expired starts endless loop
    /*checkNewNotifications()
            .catch(catchNetworkErrors)*/

    const timerId = setInterval(() => {
      if (auth.loggedIn()) {
        // checkNewNotifications()
        //     .catch((err) => {
        //         clearTimeout(timerId)
        //         return catchNetworkErrors(err)
        //     })
      }
    }, 2000);

    // history.listen(() => {
    //     if (auth.loggedIn()) {
    //         checkNewNotifications()
    //             .catch((err) => {
    //                 clearTimeout(timerId)
    //                 return catchNetworkErrors(err)
    //             })
    //     }
    // })
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  // const updateNotificationsStatus = (notificationsList) => {
  //   if (connectionTask) {
  //     connectionTask
  //       .invoke("UpdateNotificationsStatus", notificationsList)
  //       .then((r) => {
  //         console.log("r", r);
  //       });
  //   }
  // };

  // if (window.testLogoutTimer > 0) {
  //   setTimeout(() => {
  //     console.log("LOGOUT");
  //     auth.logout();
  //   }, window.testLogoutTimer * 60000);
  // }

  return (
    <>
      <NetworkErrorModal />
      <GlobalPrompt />
      <Switch>
        {!ls.loggedIn && (
          /* this route works only if no one above is selected */
          <Route
            path="/"
            render={() => (
              <Login
                initApi={initApi}
                onLogged={() => {
                  ls.loggedIn = true;
                }}
                onError={(error) => {
                  ls.modalError = {
                    error,
                    onClose: redirectToAuthorizationPage,
                  };
                }}
                onInvalidToken={() => {
                  redirectToAuthorizationPage();
                }}
              />
            )}
          />
        )}
        {ls.loggedIn && (
          <AppMenu
            mainMenu={mainMenu}
            userMenu={userMenu}
            hasNewNotifications={ls.hasNewNotifications}
            routeBellTo="/notifications"
            routeLogoTo="/"
            onExit={() => {
              auth.logout();
            }}
            // notificationChat={notificationChat}
            // notificationTask={notificationTask}
            // setNotificationChat={setNotificationChat}
            // updateNotificationsStatus={updateNotificationsStatus}
          >
            <Switch>
              {/* Git hash was set in package.json for build script */}
              <Route exact path="/version" render={() => <Version />} />
              <Route exact path="/quiz" render={() => <Quiz />} />

              <Route
                exact
                path="/recommendation-selection-locomotive"
                render={() => <RecommendationSelectionLocomotive />}
              />
              <Route
                exact
                path="/nsi-weight-length"
                render={() => <NsiWeightLength />}
              />
              <Route
                exact
                path="/commission-inspection"
                render={() => <CommissionInspection />}
              />
              <Route
                exact
                path="/locomotives-conservation"
                render={() => <LocomotivesConservation />}
              />
              <Route
                exact
                path="/tchd_view"
                component={() => {
                  window.open(window.tchdView);
                  return null;
                }}
              />
              <Route
                exact
                path="/tzrem"
                component={() => {
                  window.open(window.tzrem);
                  return null;
                }}
              />
              <Route
                exact
                path="/invest_view"
                component={() => {
                  window.open(window.investView);
                  return null;
                }}
              />
              <Route
                exact
                path="/nep"
                component={() => {
                  window.open(window.nep);
                  return null;
                }}
              />
              <Route
                exact
                path="/nep"
                component={() => {
                  window.open("/nep");
                  return null;
                }}
              />
              <Route
                exact
                path="/plan-repairs"
                render={() => <PlanRepairs />}
              />
              <Route
                exact
                path="/investigation-lb"
                render={() => <InvestigationLb />}
              />
              <Route
                exact
                path="/net-journal-work"
                render={() => <NetJournalWork />}
              />
              <Route
                exact
                path="/plan-work-brigades(|/|/add-plan|/view-event|/edit-plan|/view-results|/edit-results|/add-results|/add-loc-remark|/view-loc-remark)"
                render={() => (
                  <PlanWorkBrigades rootPath="/plan-work-brigades" />
                )}
              />
              <Route
                exact
                path="/dispatching-control-access"
                render={() => <DispatchingControlAccess />}
              />
              <Route
                exact
                path="/audit-control"
                render={() => <AuditControl />}
              />
              {/* <Route
                exact
                path="/locomotives-dislocation"
                render={() => <LocomotiveDislocation />}
              /> */}
              <Route exact path="/reports-obl" render={() => <ReportsOBL />} />
              <Route
                exact
                path="/monitor-working-capacity"
                render={() => (
                  <div className="route-container">
                    <WorkingCapacityEtso />
                  </div>
                )}
              />
              <Route
                exact
                path="/group-risk-locomotives"
                render={() => <GroupRisk />}
              />
              <Route
                exact
                path="/transfer-to-tr"
                render={() => <TransferToTr />}
              />
              <Route
                exact
                path="/transfer-from-tr"
                render={() => <TransferFromTr />}
              />
              <Route
                exact
                path="/receiver-rating"
                render={() => <ReceiverRating />}
              />
              <Route
                exact
                path="/card-locomotive-depot"
                render={() => <CardLocomotiveDepot />}
              />
              <Route
                exact
                path="/ratings-work-locomotives/(eprp|nep|failures|ready)"
                render={(props) => (
                  <RatingsWorkLocomotives
                    {...props}
                    rootPath="/ratings-work-locomotives"
                  />
                )}
              />
              <Route
                exact
                path="/ratings-work-brigades"
                render={() => <RatingsWorkBrigades />}
              />
              <Route
                exact
                path="/working-capacity-etso"
                render={() => <WorkingCapacityEtso />}
              />
              <Route
                exact
                path="/using-power-sld"
                render={() => <UsingPowerSld />}
              />
              {/* <Route
                exact
                path="/notifications"
                render={() => (
                  <Notifications
                    updateNotificationsStatus={updateNotificationsStatus}
                  />
                )}
              /> */}
              <Route
                exact
                path="/control-roles"
                render={() => <ControlRoles />}
              />
              <Route
                exact
                path="/control-services"
                render={() => <ControlServices />}
              />
              <Route
                exact
                path="/procurement-planning"
                render={() => <ProcurementPlanning />}
              />
              <Route
                exact
                path="/portal-report"
                component={() => {
                  window.open(window.pentahoReportsUrl);
                  return null;
                }}
              />
              {/* <Route
                exact
                path="/balance-park"
                component={() => {
                  window.open(window.digitalDepotUrl);
                  return null;
                }}
              /> */}
              {/* <Route
                exact
                path="/lcomp"
                component={() => {
                  window.open(window.linObCt);
                  return null;
                }}
              /> */}
              <Route
                exact
                path="/lcomp"
                render={() => <IFrameView url={window.linObCt} key="lcomp" />}
              />
              <Route
                exact
                path="/balance-park"
                render={() => (
                  <IFrameView url={window.digitalDepotUrl} key="balance-park" />
                )}
              />
              <Route
                exact
                path="/pentaho-dashboard"
                render={() => (
                  <IFrameView
                    url={window.dashboardURL}
                    key="pentaho-dashboard"
                  />
                )}
              />
              <Route
                exact
                path="/flexible-report"
                render={() => (
                  <IFrameView
                    url={window.flexibleReportURL}
                    key="flexible-report"
                  />
                )}
              />
              <Route
                exact
                path="/budget-additional-work"
                render={() => (
                  <IFrameView
                    url={window.bdopURL}
                    key="budget-additional-work"
                  />
                )}
              />
              <Route
                exact
                path="/bdop"
                render={() => <IFrameView url={window.bdopURL} key="bdop" />}
              />
              {/* <Route
                exact
                path="/bdop"
                component={() => {
                  window.open(window.bdopURL);
                  return null;
                }}
              /> */}
              {/* <Route
                exact
                path="/pentaho-dashboard"
                component={() => {
                  window.open(window.dashboardURL);
                  return null;
                }}
              />
              <Route
                exact
                path="/flexible-report"
                component={() => {
                  window.open(window.flexibleReportURL);
                  return null;
                }}
              /> */}
              {/* <Route
                exact
                path="/bdop"
                component={() => {
                  window.open(window.bdopURL);
                  return null;
                }}
              /> */}
              <Route
                exact
                path="/line-tech"
                component={() => {
                  window.open("http://dev-ekasut.gvc.oao.rzd/lcomp");
                  return null;
                }}
              />

              <Route
                exact
                path="/ratings-work-brigades"
                component={() => {
                  window.open(window.brigades);
                  return null;
                }}
              />
              <Route
                exact
                path="/locomotives-in-categories"
                render={() => <LocomotivesInCategories />}
              />
              <Route
                exact
                path="/locomotives-in-categories"
                component={() => {
                  window.open(
                    "https://ekasut.gvc.oao.rzd/locomotives-in-categories"
                  );
                  return null;
                }}
              />
              <Route
                exact
                path="/monitor-automatic-bind"
                render={() => <MonitorAutomaticBind />}
              />
              <Route
                exact
                path="/reg-depot-scheme"
                render={() => <RegDepotOnScheme />}
              />
              <Route exact path="/locomotive" render={() => <Locomotive />} />
              {/* Should be last route, by default */}
              <Route exact path="/" render={() => <LocomotiveDislocation />} />
              <Route exact path="/taskboard" render={() => <Taskboard />} />
              <Route
                exact
                path="/taskboard/:groupId-:taskNumber"
                render={() => <FullTask />}
              />
              <Route exact path="/news" render={() => <ContainerNews />} />
              <Route exact path="/news/:id" render={() => <NewsDetails />} />
              <Route path="/profile/:id" render={() => <Profile />} />
              {/* <Route
                exact
                path="/chats"
                render={() => (
                  <Chats
                    initChat={initChat}
                    startChat={startChat}
                    activeChat={activeChat}
                    setActiveChat={setActiveChat}
                    chatsLIst={chatsLIst}
                    setChatsLIst={setChatsLIst}
                    connection={connection}
                    connectionTask={connectionTask}
                    setConnection={setConnection}
                    connectionSetTimeOut={connectionSetTimeOut}
                    setConnectionSetTimeOut={setConnectionSetTimeOut}
                  />
                )}
              /> */}
              <Route exact path="/kasant" render={() => <Kasant />} />
              <Route path="/" render={() => <PageNotFound />} />
              <Route path="/404" render={() => <PageNotFound />} />
            </Switch>
          </AppMenu>
        )}
      </Switch>
    </>
  );
});

const AppWithRouter = () => {
  // For able to call useHistory and other react-router hooks
  return (
    <BrowserRouter>
      <NewApp />
    </BrowserRouter>
  );
};

export default AppWithRouter;
