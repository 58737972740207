import React, {useEffect} from 'react';
import {Header, Message, Table} from "semantic-ui-react";
import {dateFormats} from "../../Dictionaries";
import {observer, useLocalStore} from "mobx-react-lite";
import moment from 'moment'
import TableRowsLoader from "../TableRowsLoader";
import {useStore} from "./StoreProvider";
import FromToForm from "./FromToForm";
import dbSync from '../../api/dbSync'
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {formatMoment} from "../../helpers/utils";

const StateHistory = observer(({detail, lastDateState}) => {
    const tab = useStore().tabs.get(detail.idObject)

    const validLastDateState = lastDateState.isValid() ? lastDateState : moment()

    const ls = useLocalStore(() => {
        return ({
            appliedFrom: null,
            appliedTo: null
        })
    })

    useEffect(() => {
        // Check access via api
        //dbSync.system.getCheckAccess().catch(catchNetworkErrors)
    }, [])

    return tab.stateHistoryMessage ? <Message content={tab.stateHistoryMessage}/> : (
            <div>
            <FromToForm
                initFrom={moment(validLastDateState).subtract(1, 'days').startOf('day')}
                initTo={validLastDateState.endOf('day')}
                onApply={(from, to) => {
                    if ((!(from.isSame(ls.appliedFrom) && to.isSame(ls.appliedTo)))) {
                        tab.fetchStateHistory({fromDate: from, toDate: to, prsLoc: detail.prsLoc, znsLoc: detail.znsLoc, serLoc: detail.serLoc}).catch(() => {})
                        ls.appliedFrom = from
                        ls.appliedTo = to
                    }
                }}
                loading={tab.stateHistoryLoading}
            />
            <Header
                style={{fontWeight: 400}}
                as="h5"
                content={ls.appliedFrom === null || ls.appliedTo === null ? "Дата не выбрана" :
                    `История состояний, ${ls.appliedFrom.format(dateFormats.upToDay)} - ${ls.appliedTo.format(dateFormats.upToDay)}`
                }
            />
            <Table celled structured compact unstackable className="compact-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center" rowSpan={2}>Дата</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan={2}>ТПЕ</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan={2}>Состояние</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan={2}>Дислокация</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" colSpan={2}>Пробег от ТО-2</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan={2}>Номер поезда</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" rowSpan={2}>Машинист</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">км</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">ч</Table.HeaderCell>
                        {/*<Table.HeaderCell className="center aligned" style={{width:160}}>Дата</Table.HeaderCell>*/}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <TableRowsLoader
                        colSpan={14}
                        messageIfLoading="Загрузка"
                        messageIfEmpty="Нет данных"
                        loading={tab.stateHistoryLoading}
                        rows={
                            tab.stateHistory.map((state, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell textAlign="right">{formatMoment(state.dateOp, dateFormats.upToMinute)}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.sostavn}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.naimSost}</Table.Cell>
                                    <Table.Cell textAlign="right">{getDislocation(state)}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.probegTo2Km}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.probegTo2Vr}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.nomPoezd}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.tnRabAsutr}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    />
                </Table.Body>
            </Table>
        </div>
    )
})

const getDislocation = (state) => {
    return state.naimDepo !== '0' && state.naimDepo !== null ? state.naimDepo :
        (state.beginNaimObject === state.endNaimObject ?
            state.endNaimObject : `${state.beginNaimObject}-${state.endNaimObject}`
        )
}

export default StateHistory;