import React from 'react';
import {observer, useLocalStore} from "mobx-react-lite";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import ekasut from "../../api/ekasut";
import dbSync from "../../api/dbSync";
import ModalError from "../../shared/ModalError";
import {BrowserRouter} from "react-router-dom";
import useGlobalStore from "../../globalStore/useGlobalStore";

/** Catch any network errors and show modal with message */
const NetworkErrorModal = observer(() => {
    const gs = useGlobalStore()

    return (
        <ModalError
            error={gs.modalError.error}
            onClose={() => {
                gs.modalError.onClose && gs.modalError.onClose()
                gs.modalError = {error: null, onClose: null}
            }}
        />
    );
})

export default NetworkErrorModal;