import React from 'react'
import ComponentMarker from "../../../shared/ComponentMarker";
import ButtonPopupRow from "../../../shared/ButtonPopupRow";
import cn from "classnames";
import appStyles from "../../../css/App.module.scss";
import TableForPopup from "../../RatingsWorkLocomotives/TableForPopup";
import styles from './BarrierMarker.module.scss'

export default function BarrierMarker({road, locCallback, brigCallback, callback, ...markerProps}) {
    return (
        <ComponentMarker
            {...markerProps}
        >
            <ButtonPopupRow
                className={cn(styles.gap, styles.buttonRow)}
                arrSettings={[
                    {
                        value: road.locAllCount,
                        callback: locCallback,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={road.roadName}
                                    data={[
                                        {
                                            text: 'Всего БФ по локомотивам',
                                            unit: '',
                                            value: road.locAllCount
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: road.locDisableCount,
                        callback: locCallback,
                        className: road.locDisableCount === 0 ? styles.disabledBarrierWithin : styles.disabledBarrierExceed,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={road.roadName}
                                    data={[
                                        {
                                            text: 'Отключено БФ по локомотивам',
                                            unit: '',
                                            value: road.locDisableCount
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                ]}
            />
            <ButtonPopupRow
                className={styles.buttonRow}
                arrSettings={[
                    {
                        value: road.brigAllCount,
                        callback: brigCallback,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={road.roadName}
                                    data={[
                                        {
                                            text: 'Всего БФ по бригадам',
                                            unit: '',
                                            value: road.brigAllCount
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                    {
                        value: road.brigDisableCount,
                        callback: brigCallback,
                        className: road.brigDisableCount === 0 ? styles.disabledBarrierWithin : styles.disabledBarrierExceed,
                        popupProps: {
                            className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                            content: (
                                <TableForPopup
                                    title={road.roadName}
                                    data={[
                                        {
                                            text: 'Отключено БФ по бригадам',
                                            unit: '',
                                            value: road.brigDisableCount
                                        },
                                    ]}
                                />
                            )
                        }
                    },
                ]}
            />
        </ComponentMarker>
    )
}