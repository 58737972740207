import React from 'react'
import {LayersControl, TileLayer, WMSTileLayer} from "react-leaflet";

export const layerNames = {
    rzd: "ПК ГИП РЖД",
    infragis: "Инфрагис",
    openStreetMap: "OpenStreetMap"
}

const LayerSelector = ({checkedLayerDef, position, extend}) => {
    position = position.toLowerCase()
    return (
        <LayersControl position={position}>
            <LayersControl.BaseLayer checked={checkedLayerDef === layerNames.rzd} name={layerNames.rzd}>
                <WMSTileLayer
                    url={ 'http://10.246.127.183:8082/geoserver/gwc/service/wms?' }
                    layers={['master:BaseMap_RZD']}
                    format="image/png"
                    srs="EPSG:3857"
                    transparent={true}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={checkedLayerDef === layerNames.infragis} name={layerNames.infragis}>
                <WMSTileLayer
                    url={ window.mapUrl + '/service?' }
                    layers={['site_polygon']}
                    format="image/png"
                    srs="EPSG:3857"
                    transparent={true}
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer checked={checkedLayerDef === layerNames.openStreetMap} name={layerNames.openStreetMap}>
                <TileLayer
                    attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer>
            {extend}
        </LayersControl>
    )
}
LayerSelector.defaultProps = {
    position: "topleft"
}
export default LayerSelector