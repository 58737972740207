import React, {useEffect} from 'react';
import styles from "../AddAuditView.module.scss"
import {Checkbox, Header, Modal, Button} from "semantic-ui-react";
import TextField from "../../../shared/fields/TextField";
import InputField from "../../../shared/fields/InputField";
import DropdownField from "../../../shared/fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import PredDropdown from "../PredDropdown";
import moment from 'moment'
import cn from 'classnames'
import ProcessSelector from "./ProcessSelector";
import catchNetworkErrors from "../../../api/catchNetworkErrors";
import ekasut from "../../../api/ekasut";

const AddAuditView = observer((
    {
        open, date = moment(), auditTypes, preds, predsLoading,
        onSave = () => null, onClose = () => null, auditTypesLoading
    }
) => {
    const defaultSave =  {
        isPlan: false,
        fio: "",
        pred: undefined,
        /* Todo: fixed until audit steps will adjust for other types */
        auditType: {code: 2, name: "Аудит СМБД"},
        processIds: []
    }

    const ls = useLocalStore(() => ({
        save: defaultSave,
        processes: null
    }))

    const closeHandler = () => {
        ls.save = defaultSave
        onClose()
    }

    useEffect(() => {
        if (open && ls.processes === null) {
        ekasut.audit.getProcesses()
            .then(({data}) => {
                ls.processes = data
            })
            .catch(catchNetworkErrors)
        }
    }, [open])


    return (
        <Modal open={open} size="small" className={cn(styles.component, "alt-semantic")} onClose={closeHandler}>
            <Header>
                <span>Планирование аудита</span>
                <TextField
                    className="audit-date"
                    label="Дата начала аудита"
                    content={<>
                        <span>{date.format("DD MMMM")}</span>
                        <span className="year">{` ${date.format("YYYY")} г.`}</span>
                    </>}
                />
            </Header>
            <Modal.Content>
                <div className="add-properties">
                    <div>
                        <InputField
                            label="Отвественный" inputClassName="input-underline"
                            onChange={(e, {value}) => {
                                ls.save.fio = value
                            }}
                        />
                        <PredDropdown
                            label="Предприятие"
                            preds={preds}
                            loading={predsLoading}
                            onChange={(pred) => {
                                ls.save.pred = pred
                            }}
                        />
                        <DropdownField
                            label="Тип"
                            loading={auditTypesLoading}
                            /* Todo: fixed until audit steps will adjust for other types */
                            value={1}
                            clearable dropdownClassName="dropdown-underline"
                            options={auditTypes.map((item, index) => ({text: item.name, key: item.code, value: index}))}
                            onChange={(e, {value}) => {
                                ls.save.auditType = auditTypes[value]
                            }}
                        />
                    </div>
                    <div>
                        <Checkbox
                            label="Внеплановый аудит"
                            onChange={(e, {checked}) => {
                                ls.save.isPlan = checked
                            }}
                        />
                        <ProcessSelector
                            processes={ls.processes}
                            onChange={(value) => {
                                ls.save.processIds = value
                            }}
                        />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="control-buttons">
                    <Button className="button-link" content="Отмена" onClick={closeHandler}/>
                    <Button
                        // todo: uncomment when api for processes will be ready
                        disabled={ls.save.fio.length < 3 || ls.save.pred === undefined || ls.save.auditType === undefined || ls.save.processIds.length === 0}
                        className="button-link"
                        content="Сохранить"
                        onClick={() => {
                            onSave(ls.save)
                        }}
                    />
                </div>
            </Modal.Actions>
        </Modal>
    );
})

export default AddAuditView;