import React, { useEffect, useMemo, useState } from "react";
import style from "./Info.module.scss";
import { ComInspInfo, MainCharts } from "./ComInspInfo/ComInspInfo";
import { SideFilter } from "./SideFilter/SideFilter";
import { Grid } from "semantic-ui-react";
import ekasut from "../../../api/ekasut";
import useApiMethod from "../../../hooks/useApiMethod";
import RequestsToCheck from "./ComInspInfo/RequestsToCheck/RequestsToCheck";
import { observer } from "mobx-react-lite";

const handleReportNetStaysData = (data = []) => {
  const newData = [];

  data.forEach(item => {
    const indexInNewData = newData.findIndex(newDataItem => item.kodRoad === newDataItem.kodRoad);
    if (indexInNewData === -1) {
      newData.push(item);
    } else {
      const prevItem = newData[indexInNewData];
      const currentItem = item;
      newData[indexInNewData] = {
        ...prevItem,
        txtRoadWaitStay: prevItem.txtRoadWaitStay?.trim() ? prevItem.txtRoadWaitStay : (currentItem.txtRoadWaitStay?.trim() ? currentItem.txtRoadWaitStay : "отсутствует"),
        txtRoadRepLength: prevItem.txtRoadRepLength?.trim() ? prevItem.txtRoadRepLength : (currentItem.txtRoadRepLength?.trim() ? currentItem.txtRoadRepLength : "отсутствует"),
        maxWaitSerName: prevItem.maxWaitSerName?.trim() ? prevItem.maxWaitSerName : (currentItem.maxWaitSerName?.trim() ? currentItem.maxWaitSerName : "отсутствует"),
        maxPerestSerName: prevItem.maxPerestSerName?.trim() ? prevItem.maxPerestSerName : (currentItem.maxPerestSerName?.trim() ? currentItem.maxPerestSerName : "отсутствует"),
        txtRoadAvgRepLength: prevItem.txtRoadAvgRepLength?.trim() ? prevItem.txtRoadAvgRepLength : (currentItem.txtRoadAvgRepLength?.trim() ? currentItem.txtRoadAvgRepLength : "отсутствует"),
        txtRoadAvgWaitStay: prevItem.txtRoadAvgWaitStay?.trim() ? prevItem.txtRoadAvgWaitStay : (currentItem.txtRoadAvgWaitStay?.trim() ? currentItem.txtRoadAvgWaitStay : "отсутствует"),
        roadWaitStay: prevItem.roadWaitStay ?? currentItem.roadWaitStay,
        roadRepLength: prevItem.roadRepLength ?? currentItem.roadRepLength,
        roadAvgRepLength: prevItem.roadAvgRepLength ?? currentItem.roadAvgRepLength,
        roadAvgWaitStay: prevItem.roadAvgWaitStay ?? currentItem.roadAvgWaitStay,
      };
    }
  });
  return newData;
}
const getReports = (kodFirm, year, season) =>
  Promise.all([
    ekasut.KOReports.getReportAtFails({
      pAccess: kodFirm,
      pYear: year,
      pSeason: season,
    }),
    ekasut.KOReports.getReportAtNeprep({
      pAccess: kodFirm,
      pYear: year,
      pSeason: season,
    }),
    // Promise.resolve([]),
    ekasut.KOReports.getReportLokoAxwKo({
      pAccess: kodFirm,
      pYear: year,
      pSeason: season,
    }),
    ekasut.KOReports.getReportAtFailsDay({
      pAccess: kodFirm,
      pYear: year,
      pSeason: season,
      pFromDay: 90,
    }),
    ekasut.KOReports.getReportAtNrDay({
      pAccess: kodFirm,
      pYear: year,
      pSeason: season,
      pFromDay: 90,
    }),
    ekasut.KOReports.getReportNetStays(),
  ]);
export const Info = observer(({}) => {
  const [dates, setDates] = useState({ year: null, season: null });
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [getReportsMethod, isLoading, reportsData] = useApiMethod(
    () => getReports(localStorage.kodFirm, dates.year, dates.season),
    () => {},
    {},
    (res) => ({
      reportAtFails: res[0]?.data,
      reportAtNeprep: res[1]?.data,
      reportLokoAxwKo: res[2]?.data,
      reportAtFailsDay: res[3]?.data,
      reportAtNrDay: res[4]?.data,
      reportNetStays: handleReportNetStaysData(res[5]?.data),
    })
  );

  useEffect(() => {
    if (dates.year && dates.season) {
      getReportsMethod().then(() => setIsDataLoaded(true));
    }
  }, [dates]);
  const filteredData = useMemo(
    () => ({
      reportAtFails: reportsData.reportAtFails?.filter((item) => item),
      reportAtNeprep: reportsData.reportAtNeprep?.filter((item) => item),
      reportLokoAxwKo: reportsData.reportLokoAxwKo?.filter((item) => item),
      reportAtFailsDay: reportsData.reportAtFailsDay?.filter((item) => item),
      reportAtNrDay: reportsData.reportAtNrDay?.filter((item) => item),
      reportNetStays: reportsData.reportNetStays?.filter((item) => item),
    }),
    [reportsData]
  );

  const [activeSpace, setActiveSpace] = useState({
    id: -1,
    name: "",
    space: <></>,
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const changeWorkSpace = (nameSpace) => {
    if (nameSpace === "Сведения") {
      setActiveIndex(0);
      setActiveSpace({
        id: 0,
        name: "Сведения",
        space: <MainCharts
          dates={dates}
          isLoading={isLoading}
          reportsData={filteredData}
          isDataLoaded={isDataLoaded}
         />,
      });
    } else if (nameSpace === "Запросы") {
      setActiveIndex(1);
      setActiveSpace({
        id: 1,
        name: "Запросы",
        space: <RequestsToCheck dates={dates} />,
      });
    }
  };
  return (
    <div className={style.infoMainDiv}>
      <div className={style.infoMainBody}>
        {/*{(filteredData.reportsAtFails?.length !== 0 || filteredData.reportAtNeprep?.length) && (*/}
        <ComInspInfo
          activeIndex={activeIndex}
          activeSpace={activeSpace}
          changeWorkSpace={changeWorkSpace}
          dates={dates}
          isLoading={isLoading}
          filteredData={filteredData}
          isDataLoaded={isDataLoaded}
        />
        {/*)}*/}
      </div>
      <Grid className={style.infoMainFilter}>
        <SideFilter dates={dates} setDates={setDates} activeSpace={activeSpace} changeWorkSpace={changeWorkSpace} />
      </Grid>
    </div>
  );
});
