import React, {useState, useEffect} from 'react'
import {Form, Button, Modal} from 'semantic-ui-react'
import useApiMethod from "../../../hooks/useApiMethod";
import CheckboxField from "../fields/CheckboxField";
import styles from '../index.module.scss'
import dbSync from '../../../api/dbSync'
import LoadingLabel from "../../../shared/LoadingLabel";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

export default function RegisterPersTechTraining({event}) {

    const [acceptedLoading, setAcceptedLoading] = useState(true)
    const [sendGetAccepted, , accepted, setAccepted] = useApiMethod(dbSync.worksPlan.getRates, undefined, null,
        ({data}) => {
            const employee = data.find(item => item.asutrID === event.persAsutrId)
            return employee === undefined ? null : employee.rateID === ACCEPT_CODE
        }
    )
    const [sendApply, applyLoading] = useApiMethod(dbSync.worksPlan.putRate, undefined)
    const [checkboxValue, setCheckboxValue] = useState(false)
    const [modal, setModal] = useState({open: false})


    useEffect(() => {
        sendGetAccepted(event.actionId, event.rd)
            .then((value) => {
                setCheckboxValue(value)
                setAcceptedLoading(false)
            })
            .catch((err) => {
                setAcceptedLoading(false)
                return catchNetworkErrors(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form>
            {
                acceptedLoading ? (
                    <LoadingLabel
                        style={{marginTop: 14}}
                        label='Загрузка результатов зачета'
                    />
                ) : (
                    <>
                        <CheckboxField
                            label='Признак зачета'
                            editable={true}
                            disabled={applyLoading}
                            value={checkboxValue === null ? false : checkboxValue}
                            onChange={(value) => {
                                setCheckboxValue(value)
                            }}
                        />
                        <Button
                            content='Изменить'
                            className={styles.bottomButton}
                            basic
                            style={{display: accepted === checkboxValue ? 'none' : 'block'}}
                            color='orange'
                            loading={applyLoading}
                            disabled={applyLoading || accepted === checkboxValue}
                            onClick={() => {
                                sendApply(event.rd, event.actionId, event.persAsutrId, getAcceptCode(checkboxValue))
                                    .then(() => {
                                        setModal({open: true})
                                        setAccepted(checkboxValue)
                                    })
                            }}
                        />
                    </>
                )
            }

            <Modal
                open={modal.open}
                onClose={() => setModal({open: false})}
            >
                <Modal.Header>
                    Оценка сорхранена
                </Modal.Header>
                <Modal.Content>
                    {event.fio}: {checkboxValue ? 'Зачет' : 'Не зачет'}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        onClick={() => setModal({open: false})}
                    >
                        Ок
                    </Button>
                </Modal.Actions>
            </Modal>
        </Form>
    )
}
const getAcceptCode = (accept) => {
    return accept ? ACCEPT_CODE : NOT_ACCEPTED_CODE
}
/* /api/tchmi/nsi/rates: [{"code": 10, "name": "Незачет"}, {"code": 3, "name": "Зачет"}, */
const ACCEPT_CODE = 3
const NOT_ACCEPTED_CODE = 10