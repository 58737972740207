import React, {useCallback, useEffect, useState} from 'react';
import style from "./AddPeople.module.scss";
import {Avatar, Button, Input, List, Select, Spin, Tag, Tooltip} from "antd";
import ekasut from "../../../../../../api/ekasut";
import demoAvatar from "../../../../../../assets/images/DemoAvatar.png";
import {CloseCircleOutlined} from '@ant-design/icons';


const {Search} = Input;
const {Option} = Select;
const roles = ['Админ', 'Пользователь', 'Гость']
const debounce = require('lodash.debounce');

export const AddPeople = ({defaultUsers = [], wSData, setSettingsList, settingsList}) => {

        const [usersList, setUsersList] = useState([]);
        const [selectedUsers, setSelectedUsers] = useState([]);
        const [optionUsers, setOptionUsers] = useState([]);
        const [loading, setLoading] = useState(false)
        const [userId, setUserId] = useState(localStorage.getItem('userId'))

        const deleteUser = (user) => {
            setSettingsList(usersList.filter(el => selectedUsers.filter(e => e !== user.id).includes(el.id)))
            setOptionUsers(usersList.filter(el => !selectedUsers.filter(e => e !== user.id).includes(el.id)))
            setSelectedUsers(selectedUsers.filter(el => el !== user.id))
        }

        const tagRender = (props) => {
            const {label, value, closable, onClose} = props;

            const onPreventMouseDown = event => {
                event.preventDefault();
                event.stopPropagation();
            };

            let labelInfo = usersList.find((e => e.id === value))

            return (
                // <Tag
                //     onMouseDown={onPreventMouseDown}
                //     closable={closable}
                //     onClose={onClose}
                //     style={{marginRight: 3}}
                // >
                //     {labelInfo.name}
                // </Tag>
                <span></span>
            );
        }
        const onSearchUser = useCallback(async (val, page = 1) => {
            try {
                setLoading(true)
                const {data} = await ekasut.taskboard.searchUser(val, wSData?.id, page)
                let modernData = data.data.map(e => ({
                    name: e.fio,
                    id: e.id,
                    roleId: 2
                }))
                if (wSData?.id) {
                    ekasut.taskboard.getUserInWGList(wSData?.id)
                        .then((selectedUsers) => {
                            let selectedIds = selectedUsers.data.map(e => e.id)
                            let options = modernData.map((el) => {
                                let findEl = selectedUsers.data.find((el2) => el.id === el2.id)
                                if (!!findEl) {
                                    return findEl
                                } else {
                                    return el
                                }
                            })
                            setUsersList(options)
                            setSelectedUsers(selectedIds)
                            setOptionUsers(modernData.filter(el => selectedIds.indexOf(el.id) === -1))
                            setSettingsList(selectedUsers.data)
                        })
                } else {
                    setUsersList(modernData)
                    setOptionUsers(modernData)
                }
                setLoading(false)
            } catch (e) {
                setLoading(false)
                console.log(e)
            }
        }, [wSData])

        const searchDebounce = debounce(onSearchUser, 1500)

        const selectedChange = selected => {
            setSelectedUsers(selected)
            setOptionUsers(usersList.filter(el => !selected.includes(el.id)))
            setSettingsList(usersList.filter(el => selected.includes(el.id)))
        };


        useEffect(() => {
            onSearchUser('')
            return () => {
                setSettingsList([])
            }
        }, [])

        return (
            <div>
                <h4>
                    Участники
                </h4>
                <Select
                    bordered
                    placeholder={'Выберите участников'}
                    tagRender={tagRender}
                    notFoundContent={ loading ? <Spin size="small" /> :'Нет пользователей'}
                    mode="multiple"
                    showArrow
                    onPopupScroll={(e)=>{
                        console.log(e.currentTarget())}}
                    loading={loading}
                    onSearch={searchDebounce}
                    value={selectedUsers}
                    onChange={selectedChange}
                    filterOption={false}
                    style={{width: '100%'}}
                >
                    { optionUsers.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                            <Avatar src={demoAvatar}/>{item.name}
                        </Select.Option>
                    ))}
                </Select>
                {settingsList.length ? <div className={style.userSelectedList}>
                    <List
                        onScroll={() => console.log("scroll")}
                        dataSource={settingsList}
                        renderItem={item => (
                            <List.Item key={item.id}>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar src={demoAvatar}/>
                                    }
                                    title={<div>{item.name}</div>}
                                    description={''}
                                />
                                {item.id == userId ? <div className={style.listActions}>
                                    <span className={style.userRole}>{roles[item.roleId - 1]}</span>
                                </div> : null}
                                {item.id != userId && wSData?.userRole === 1 || !wSData?.id ?
                                    <div className={style.listActions}>
                                        <Select defaultValue={item.roleId ? item.roleId : 2} style={{width: 120}}
                                                bordered={false}
                                                onChange={(value, option) => {
                                                    setSettingsList(settingsList.map(e => {
                                                        if (e.id === item.id) {
                                                            e.roleId = value
                                                            return e
                                                        } else {
                                                            return e
                                                        }
                                                    }))
                                                }}>
                                            <Option value={1}>Админ</Option>
                                            <Option value={2}>Пользователь</Option>
                                            <Option value={3}>Гость</Option>
                                        </Select>
                                        <CloseCircleOutlined onClick={() => {
                                            deleteUser(item)
                                        }}/>
                                    </div> : null}
                            </List.Item>
                        )}
                    >
                    </List>
                </div> : null}
            </div>
        );
    }
;