import React, {useEffect} from 'react';
import DropdownField from "../fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import ekasut from "../../api/ekasut";
import useDidFirstRender from "../../hooks/useDidFirstRender";

const DepotField = observer(({triggerLoad, ...fieldProps}) => {

    const ls = useLocalStore(() => ({
        loaded: false,
        options: null
    }))

    useDidFirstRender(() => {
        console.log('render first time')
    })

    useEffect(() => {
        if (!ls.loaded && triggerLoad === undefined ? true : triggerLoad) {
            ls.loaded = true

            ekasut.ekasutData.getCtTree().then(({data}) => {
                ls.options = data.map(pred => ({
                    value: pred.value,
                    key: pred.key,
                    text: pred.text,
                    className: pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
                    disabled: pred.level === 1
                }))
            })
        }
    }, [triggerLoad])

    return (
        <DropdownField
            selection clearable
            search={(options, query) => {
                const re = new RegExp(query, "i")

                const filteredOptions = options.filter(item => item.disabled || re.test(item.text))
                return filteredOptions.filter((item, index) => {
                    if (item.disabled) {
                        const nextOption = filteredOptions[index + 1]
                        return nextOption ? nextOption.disabled !== true : false
                    }
                    return true
                })
            }}
            options={ls.options === null ? [] : [...ls.options]}

            {...fieldProps}
            onChange={(e, props) => {
                const option = ls.options.find(item => item.value === props.value)
                fieldProps.onChange && fieldProps.onChange(e, {...props, value: option === undefined ? null : {name: option.text, id: option.value}})
            }}
        />
    );
})

export default DepotField;