import React, {useEffect, useState} from 'react';
import style from './LabelsList.module.scss';
import {List, Tag, Spin, Button, Input, Popconfirm} from "antd";
import ekasut from "../../../../../../api/ekasut";
import {LoadingOutlined} from "@ant-design/icons";
import {Icon, Popup} from "semantic-ui-react";
import {ColorPicker, useColor, toColor} from "react-color-palette";
import {UpdateItem} from "./UpdateItem";


const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

export const LabelsList = ({wSData}) => {

    const [tagsList, setTagsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [action, setAction] = useState(null)
    const [color, setColor] = useColor("hex", "#030303");
    const [tagName, setTagName] = useColor('');

    useEffect(() => {
        if (wSData?.id) {
            setLoading(true)
            getTagsList()
        }
    }, [])

    const getTagsList = () => {
        ekasut.taskboard.getLabelsByWG(wSData.id)
            .then(({data}) => {
                setTagsList(data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    const addNewTag = (id, name, color) => {
        ekasut.taskboard.createLabelInWG(id, {
            labelName: name,
            labelColorHex: color
        })
            .then(() => {
                setAction(null)
                getTagsList()
                setColor({
                    hex: "#000000",
                    rgb: {
                        r: 0,
                        g: 0,
                        b: 0
                    },
                    hsv: {
                        h: 0,
                        s: 0,
                        v: 0
                    }
                })
            })
            .catch((err) => {
                setAction(null)
            })
    }

    const editTag = (id, name, color) => {
        ekasut.taskboard.updateLabel(id, {
            labelName: name,
            labelColorHex: color
        })
            .then(() => {
                setAction(null)
                getTagsList()
            })
            .catch((err) => {
                setAction(null)
            })
    }

    const deleteTag = (id) => {
        ekasut.taskboard.deleteLabel(id)
            .then(() => {
                setAction(null)
                getTagsList()
            })
            .catch((err) => {
                setAction(null)
            })
    }

    return (
        <div className={style.tagsList}>
            <div className={style.header}>
                <h4> Тэги в <strong>{wSData?.workGroupName}</strong></h4>
                <Icon className={style.addTagIcon} name='plus' onClick={() => {
                    setAction({type: 'new', info: null})
                }}/>
            </div>
            {action?.type === 'new' ? <div className={style.addTagItem}>
                <Popup
                    on='click'
                    pinned
                    trigger={<div style={{
                        borderRadius: '3px',
                        marginLeft: '10px',
                        width: '15px',
                        height: '15px',
                        background: `${color.hex}`
                    }}/>}
                    content={<ColorPicker width={120} height={100} color={color} onChange={setColor} alpha
                                          hideHSV hideRGB hideHEX/>}
                />
                <Input width={200} autoFocus bordered={false} onChange={(e) => {
                    setTagName(e.target.value)
                }}/>
                <div className={style.editActions}>
                    <Icon name={'checkmark'} color={'green'} disabled={!tagName.length} onClick={() => {
                        addNewTag(wSData.id, tagName, color.hex)
                    }}/>
                    <Icon name={'delete'} color={'red'} onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setAction(null)
                    }}/>
                </div>
            </div> : null}
            {loading ? <div className={style.loadingList}>
                    <Spin indicator={antIcon}/>
                </div> :
                tagsList.length ? <div className={style.body}>

                    <List
                        dataSource={tagsList}
                        renderItem={item => (
                            <List.Item onClick={() => {
                                setTagName(item.labelName)
                                setAction({type: 'edit', info: item})
                            }} className={style.listItem} key={item.labelId + item.labelName}>
                                <List.Item.Meta
                                    title={action?.type === 'edit' && item.labelId === action.info.labelId ?
                                        <UpdateItem setAction={setAction} editTag={editTag} setTagName={setTagName}
                                                    action={action} tagName={tagName}/> :
                                        <Tag color={item.labelColorHex}>{item.labelName}</Tag>}
                                    description={''}
                                />
                                {action?.type === 'edit' && item.labelId === action.info.labelId ? null :
                                    <Popconfirm placement="left" title={`Удалить тэг ${item.labelName}`} okText="Да"
                                                cancelText="Нет"
                                                onConfirm={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    deleteTag(item.labelId)
                                                }}>
                                        <Icon className={style.delIcon} name={'trash alternate outline'} color={'red'}
                                              onClick={(e) => {
                                                  e.stopPropagation()
                                                  e.preventDefault()
                                              }}/>
                                    </Popconfirm>
                                }
                            </List.Item>
                        )}
                    >
                    </List></div> : <div className={style.emptyList}><Button onClick={() => {
                    setAction({type: 'new', info: null})
                }}>
                    Создать тэг
                </Button></div>}
        </div>
    );
}