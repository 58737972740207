import React, { useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Icon,
  Loader,
  Modal,
  Segment,
} from "semantic-ui-react";
import CommonModal from "../../../shared/CommonModal/CommonModal";
import ConservationOrdersAddingForm from "../ConservationOrdersAddingForm/ConservationOrdersAddingForm";
import ConservationOrdersTable from "../ConservationOrdersTable/ConservationOrdersTable";
import { getOrders } from "../locomotivesConservationUtils";
import locomotivesConservationStore from "../locomotivesConservationStore";
import { observer } from "mobx-react-lite";
import ekasut from "../../../api/ekasut";
import ConservationOrdersCandidates from "../ConservationOrdersCandidates/ConservationOrdersCandidates";
import Widget from "../../../shared/Widget";
import cn from "classnames";
import ConservationСonfirmList from "../ConservationСonfirmList/ConservationСonfirmList";

const ConservationOrders = () => {
  useEffect(() => {
    getOrders();
  }, []);
  const isAccessLevel1 = localStorage.accessLevel === "1";
  const isAccessLevel7 = localStorage.accessLevel === "7";
  const hasAccessToAddOrder = isAccessLevel1 || isAccessLevel7;
  const [modalSettings, setModalSettings] = useState({
    isOpened: false,
    isEditing: false,
  });
  const onRowClick = () => {
    setModalSettings({ isOpened: true, isEditing: true });
  };
  const setIsOpened = (isOpened) =>
    setModalSettings((prevState) => ({ ...prevState, isOpened }));
  const [selectedRow, setSelectedRow] = useState(null);
  const [initialEditingData, setInitialEditingData] = useState({});
  const [orderItemsLocomotives, setOrderItemsLocomotives] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const isTableLoading = locomotivesConservationStore.isOrdersTableLoading;
  const [showReady, setShowReady] = useState(false);

  const onEditClick = () => {
    setIsDataLoading(true);
    const currentOrder =
      locomotivesConservationStore.ordersTable.data[selectedRow];
    if (currentOrder) {
      const { orderId, planEndDate } = currentOrder;
      ekasut.locomotivesConservation
        .getOrderContaining(orderId)
        .then((response) => {
          setInitialEditingData({
            orderId,
            planEndDate,
            preds: response.data || [],
          });
          setModalSettings({ isOpened: true, isEditing: true });
        })
        .finally(() => setIsDataLoading(false));
    }
  };
  const onReadyStatusClick = (index) => {
    setIsDataLoading(true);
    const currentOrder = locomotivesConservationStore.ordersTable.data[index];
    if (currentOrder) {
      const { orderId, planEndDate } = currentOrder;

      ekasut.locomotivesConservation
        .getOrderContaining(orderId)
        .then((response) => {
          const orderItems = response.data || [];
          Promise.all(
            orderItems.map((item) =>
              ekasut.locomotivesConservation.getOrderLocomotivesString(
                item.orderItemId
              )
            )
          ).then((response) => console.log(response));
          setInitialEditingData({
            orderId,
            planEndDate,
            preds: response.data || [],
          });

          // setModalSettings({ isOpened: true, isEditing: true });
          setShowReady(true);
        })
        .finally(() => setIsDataLoading(false));
    }
  };

  return (
    <Segment>
      <div style={{ display: "flex", marginBottom: 14, gap: 20 }}>
        <Button
          disabled={!hasAccessToAddOrder}
          onClick={() => setModalSettings({ isOpened: true, isEditing: false })}
        >
          <Icon name="plus" />
          Добавить заказ
        </Button>
        <Button disabled={selectedRow === null} onClick={onEditClick}>
          Просмотр / редактирование заказа
        </Button>
      </div>
      <ConservationOrdersTable
        onRowClick={onRowClick}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        onReadyStatusClick={onReadyStatusClick}
      />
      <Modal
        open={showReady}
        onClose={() => setShowReady(false)}
        style={{ minWidth: "95%" }}
        className={cn("app shared locomotive-modal", "expand-loc-modal")}
      >
        <Widget
          header={"Подтверждение"}
          closeIcon
          onClose={() => setShowReady(false)}
          content={
            <ConservationСonfirmList initialEditingData={initialEditingData} />
          }
        />
      </Modal>
      {localStorage.getItem("accessLevel") === "2" ? (
        <CommonModal
          isOpened={modalSettings.isOpened}
          setIsOpened={setIsOpened}
          header={"Кандидаты на консервацию"}
          content={
            <div>
              <ConservationOrdersCandidates
                initialEditingData={initialEditingData}
              />
            </div>
          }
        />
      ) : (
        <CommonModal
          isOpened={modalSettings.isOpened}
          setIsOpened={setIsOpened}
          header={
            modalSettings.isEditing
              ? "Редактирование заказа"
              : "Добавление заказа"
          }
          content={
            <div>
              <ConservationOrdersAddingForm
                isEditing={modalSettings.isEditing}
                setIsOpened={setIsOpened}
                initialData={initialEditingData}
              />
            </div>
          }
        />
      )}
      <Dimmer active={isTableLoading || isDataLoading} inverted>
        <Loader>Загрузка</Loader>
      </Dimmer>
    </Segment>
  );
};

export default observer(ConservationOrders);
