import React, {useEffect, useState} from 'react';
import ekasut from "../../../../../api/ekasut";
import appStyles from "../../../../../css/App.module.scss";
import Control from "react-leaflet-control";
import {Header, Segment} from "semantic-ui-react";
import LayerSelector, {layerNames} from "../../../../../shared/LayerSelector";
import {Map, Marker} from "react-leaflet";
import useMapRef from "../../../../../hooks/useMapRef";
import useMapZoom from "../../../../../hooks/useMapZoom";
import useZoomLevel from "../../../../../hooks/useZoomLevel";
import useApiMethod from "../../../../../hooks/useApiMethod";
import moment from "moment";
import style from './MapTab.module.scss'
import {CircularProgress} from "@material-ui/core";

const mapSettings = {
    center: [57.778288, 79.126642],
    zoom: 4,
    minZoom: 4,
    maxZoom: 16,
    zoomControl: false
}

export const MapTab = ({asutrId}) => {

    const [mapRef, onMapRefChangeHandler] = useMapRef()
    const [zoom] = useMapZoom(mapSettings.zoom, mapRef)
    const [excelShow, setExcelShow] = useState(false)
    const [excelOptions, setExcelOptions] = useState([])
    const [zoomLevel] = useZoomLevel(zoom,
        [mapSettings.minZoom, 5],
        [6, mapSettings.maxZoom]
    )

    const [sendGetMarker, loadingMarker, marker,] = useApiMethod(ekasut.persData.getLocationByTabNum, undefined, false,)


    useEffect(() => {
        if (asutrId)
            sendGetMarker(asutrId)
    }, [])

    return (
        <div className={style.wrap}>
            {
                loadingMarker ?
                    <CircularProgress />
                    : <Map
                        className={appStyles.map}
                        {...mapSettings}
                        ref={onMapRefChangeHandler}
                    >

                        <Control position={'topright'}>
                            <Segment>
                                <Header as={'h4'}>
                                    Машинист на карте
                                </Header>
                            </Segment>
                        </Control>
                        <LayerSelector checkedLayerDef={layerNames.rzd}/>
                        {
                            marker &&
                            <Marker
                                title={`Дата ${marker?.dataDate ? moment(marker.dataDate).format('DD.MM.YYYY HH:mm:ss') : ''}`}
                                draggable={false}
                                position={[marker.lat, marker.lon]}>
                            </Marker>
                        }
                    </Map>
            }

        </div>
    )
}