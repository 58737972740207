import React, {useState} from 'react';
import style from './Subtasks.module.scss';
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons';
import {Checkbox, Input, Spin} from 'antd';
import ekasut from "../../../../../../api/ekasut";
import {sortableContainer, sortableElement, sortableHandle} from "react-sortable-hoc";
import {Icon} from "semantic-ui-react";
import {arrayMoveImmutable} from "array-move";
import {SubItem} from "./SubItem";

const loadIcon = <LoadingOutlined style={{fontSize: 24, marginLeft: '10px', color: 'rgba(0,0,0,0.5)'}} spin/>;

const DragHandle = sortableHandle(() => <Icon name='bars'/>);

const SortableContainer = sortableContainer(({children}) => {
    return <ul style={{listStyleType: 'none', paddingLeft: 0, marginBottom: 0}}>{children}</ul>;
});


export const Subtasks = ({taskId, addSubtask, setAddSubtask, taskData, setTaskData}) => {

        const [subContent, setSubContent] = useState('')
        const [loading, setLoading] = useState(false)
        const subs = taskData.subtasks

        const addingSubtask = () => {
            setLoading(true)
            ekasut.taskboard.postNewSubtask(taskId, subContent)
                .then(({data}) => {
                    setTaskData({
                        ...taskData,
                        subtasks: data
                    })
                    setLoading(false)
                    setAddSubtask(false)
                })
        }

        const onSortEnd = ({oldIndex, newIndex}) => {
            if(oldIndex === newIndex){
                return
            }
            let predList = subs
            let mutArray = arrayMoveImmutable(predList, oldIndex, newIndex).map((e, i) => ({
                ...e,
                position: i + 1,
            }))
            setTaskData({
                ...taskData,
                subtasks: mutArray
            })
            updateSubsArray(mutArray)

        }

        const updateSubsArray = (newStatusList) => {
            ekasut.taskboard.updateSubtasks(taskId, newStatusList)
                .then(({data}) => {
                    setTaskData({
                        ...taskData,
                        subtasks: data
                    })
                })
        }


        return (
            <div className={style.subtasksAria}>
                {addSubtask ? <div className={style.addSub}>
                    <Input autoFocus
                           disabled={loading}
                           onChange={(e) => {
                               setSubContent(e.target.value)
                           }
                           }/>
                    <span className={style.cancle} onClick={() => {
                        if (!loading) {
                            setAddSubtask(false)
                            setSubContent('')
                        }
                    }}>Отмена</span>
                    {loading ? <Spin indicator={loadIcon}/> :
                        <span className={subContent.length ? style.addSubDone : style.addSubDis} onClick={() => {
                            if (subContent.length) {
                                addingSubtask()
                                setSubContent('')
                            }
                        }}>Добавить</span>}
                </div> : null}
                {subs.length ?
                    <div className={style.subtasksList}>
                        <SortableContainer lockAxis={'y'} onSortEnd={onSortEnd} useDragHandle>
                            {subs.map((subtask, i) => {
                                return (<SubItem key={`item-${i}`} index={i} subtask={subtask} updateSubsArray={updateSubsArray} setTaskData={setTaskData}
                                                 taskData={taskData}/>)
                            })
                            }
                        < /SortableContainer>
                    </div> : !addSubtask && taskData.workGroupUserRoleId !== 3 ?
                        <div className={style.emptySubtasks}>
                            <div onClick={() => {
                                setAddSubtask(true)
                            }}>
                                <span>Создать подзадачу</span>
                                <PlusOutlined/>
                            </div>
                        </div> : !addSubtask ? <div className={style.emptySubtasks}>
                            Нет подзадач
                        </div> : null}
            </div>
        );
    }
;