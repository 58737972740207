/**
 * Help transform moment params into strings. If moment isn't valid, value will be set to undefined.
 * Transformation may applied only to direct params properties. Doesn't support URLSearchParams
 * @param config
 * @param config.momentFormatter    Define how to format moment dates in params
 * @param config.params             URL params
 *
 * @example
 *      ["from", "to"],
 *      [{paramName: "from", formatTo: "YYYY", paramName: "to", formatTo: "YYYY-MM-DD"}]
 *      {paramNames: ["from", "to"], formatTo: "YYYY-MM-DD"}
 *      "YYYY-MM-DD"
 */
export default function momentParamsFormatter(config) {
    const {momentFormatter, params} = config

    const apply = (paramName, formatTo) => {
        params[paramName] = params[paramName].isValid() ? params[paramName].format(formatTo) : undefined
    }

    // momentFormatter: "YYYY-MM-DD"
    if (typeof momentFormatter === 'string' || momentFormatter instanceof String) {
        Object.keys(params).forEach(key => {
            if (params[key] && params[key].isValid && params[key].isValid()) {
                apply(key, momentFormatter)
            }
        })
        // momentFormatter: {paramNames: ["from", "to"], formatTo: "YYYY-MM-DD"}
    } else if (momentFormatter && momentFormatter.paramNames) {
        const formatTo = momentFormatter.formatTo ? momentFormatter.formatTo : defaultFormat
        momentFormatter.paramNames.forEach(paramName => {
            apply(paramName, formatTo)
        })
        // momentFormatter: ["from", "to"] || [{paramName: "from", formatTo: "YYYY", paramName: "to", formatTo: "YYYY-MM-DD"}]
    } else if (momentFormatter && Array.isArray(momentFormatter)) {
        momentFormatter.forEach(f => {
            const paramName = f.paramName ? f.paramName : f
            const formatTo = f.formatTo ? f.formatTo : defaultFormat
            apply(paramName, formatTo)
        })
    }

    return config
}

const defaultFormat = "YYYY-MM-DD"