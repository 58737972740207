import React, {Component} from 'react'
import {DomEvent} from "leaflet";
import Control from 'react-leaflet-control'

// This component fix issue with click and scroll, when events consumed by map
export default class StaticControl extends Component {
    constructor(props) {
        super(props);
        this.container = null;
    }

    refContainer = (element) => {
        this.container = element;
        if (element) {
            DomEvent
                .disableClickPropagation(element)
                .disableScrollPropagation(element);
        }
    }

    render() {
        const {children, position} = this.props
        return (
            <Control position={position}>
                <div ref={this.refContainer}>
                    {children}
                </div>
            </Control>
        );
    }
}