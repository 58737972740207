import React, {useState} from 'react';
import cn from "classnames";
import {Modal} from "semantic-ui-react";
import Widget from "../../../../../../shared/Widget";
import {observer, useLocalStore} from "mobx-react-lite";

const CardModal = ({isOpened, setIsOpened, content}) => {

    const ls = useLocalStore(() => ({
        expand: false,
        toggleExpand() {
            this.expand = !this.expand;
        }
    }));
    return (
        <Modal
            open={isOpened}
            onClose={() => setIsOpened(false)}
            style={{minWidth: "95%"}}
            className={cn(
                "app shared locomotive-modal",
                "expand-loc-modal"
            )}
        >
            <Widget
                header=""
                closeIcon
                onClose={() => setIsOpened(false)}
                content={<div style={{minHeight: "300px"}}>{content}</div>}
            />
        </Modal>
    );
};

export default observer(CardModal);