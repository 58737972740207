import React, { useEffect, useState } from "react";
import { DropzoneAreaBase, DropzoneArea } from "material-ui-dropzone";
import { DateTimeInput } from "semantic-ui-calendar-react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import style from "./News.module.scss";
import ekasut from "../../api/ekasut";
import moment from "moment";
import { Button, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";

const filter = createFilterOptions();

const defaultInitData = {
  coverImageId: "",
  initDateTime: "",
  initValueCategory: "",
  initFileObjects: [],
  initFileObjectsImage: [],
  initTitle: "",
  initContent: "",
  newsId: false,
  edit: false,
};

export const AddNewsModal = ({
  openModal,
  closeModal,
  initNews = () => {},
  initData = defaultInitData,
}) => {
  const {
    initDateTime,
    initValueCategory,
    initFileObjects,
    initFileObjectsImage,
    initTitle,
    initContent,
    newsId,
    edit,
    coverImageId,
  } = initData;

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);

  const [inpVal, setInpVal] = useState("");

  const [dateTime, setDateTime] = useState();

  const [valueCategory, setValueCategory] = useState(
    initValueCategory ? initValueCategory : null
  );

  const [fileObjects, setFileObjects] = useState(
    initFileObjects ? initFileObjects : []
  );

  const [fileObjectsImage, setFileObjectsImage] = useState(
    initFileObjectsImage ? initFileObjectsImage : []
  );

  const [title, setTitle] = useState(initTitle ? initTitle : "");

  const [errorTitle, setErrorTitle] = useState(false);

  const [content, setContent] = useState(initContent ? initContent : "");

  const [errorContent, setErrorContent] = useState(false);

  const [loadingCreatNews, setLoadingCreatNews] = useState(false);
  const [loadingChangeNews, setLoadingChangeNews] = useState(false);

  const [readOnlyDate, setReadOnlyDate] = useState(false);

  const clearField = () => {
    setTitle("");
    setContent("");
    setValueCategory(null);
    setDateTime("");
    setFileObjectsImage([]);
    setFileObjects([]);
    setErrorTitle(false);
    setErrorContent(false);
  };

  const createNews = async () => {
    if (title.trim() && content.trim()) {
      setLoadingCreatNews(true);
      const category = categoryOptions.find(
        (el) => el.name === valueCategory?.name
      )
        ? {
            categoryId: categoryOptions.find(
              (el) => el.name === valueCategory?.name
            ).code,
          }
        : { categoryName: valueCategory?.name };

      try {
        let coverImageIdData;
        if (fileObjectsImage.length) {
          coverImageIdData = await ekasut.file.postFile(
            fileObjectsImage[0]?.file
          );
        }
        let filesData = [];
        if (fileObjects.length) {
          filesData = await Promise.all(
            fileObjects.map(async (el) => {
              const res = await ekasut.file.postFile(el.file);
              return res?.data;
            })
          );
        }

        await ekasut.news.createNews({
          ...category,
          title,
          content,
          publishDate: dateTime ? dateTime.format("YYYY-MM-DDTHH:mm:ss") : "",
          coverImageId: coverImageIdData?.data,
          files: filesData,
        });
        initNews();
        closeModal();
        clearField();
        setLoadingCreatNews(false);
      } catch (e) {
        console.log(e);
        closeModal();
        clearField();
        setLoadingCreatNews(false);
      }
    } else {
      setErrorTitle(!title);
      setErrorContent(!content);
    }
  };

  const changeNews = async () => {
    if (title.trim() && content.trim() && newsId) {
      setLoadingChangeNews(true);
      const category = categoryOptions.find(
        (el) => el.name === valueCategory?.name
      )
        ? {
            categoryId: categoryOptions.find(
              (el) => el.name === valueCategory?.name
            ).code,
          }
        : { categoryName: valueCategory?.name };

      try {
        let coverImageIdData;

        if (fileObjectsImage[0]?.data !== initFileObjectsImage[0]?.data) {
          if (coverImageId) {
            await ekasut.file.deleteFile(coverImageId);
          }
          if (fileObjectsImage.length) {
            coverImageIdData = await ekasut.file.postFile(
              fileObjectsImage[0]?.file
            );
            coverImageIdData = coverImageIdData.data;
          }
        } else {
          coverImageIdData = coverImageId;
        }

        let filesData = await Promise.all(
          fileObjects.map(async (el) => {
            const findEl = initFileObjects.find(
              (initEl) => initEl.data === el.data
            );
            if (findEl) {
              return findEl.fileId;
            } else {
              try {
                const fileId = await ekasut.file.postFile(el?.file);
                return fileId.data;
              } catch (e) {
                console.log(e);
                return el;
              }
            }
          })
        );

        const deleteIdFile = initFileObjects.filter(
          (el) => !filesData.find((e) => e === el.fileId)
        );

        await Promise.all(
          deleteIdFile.map(async (el) => {
            return await ekasut.file.deleteFile(el.fileId);
          })
        );

        await ekasut.news.changeNews(newsId, {
          ...category,
          title,
          content,
          publishDate: dateTime ? dateTime.format("YYYY-MM-DDTHH:mm:ss") : "",
          coverImageId: coverImageIdData,
          files: filesData,
        });
        console.log("filesData", filesData);

        initNews();
        closeModal();
        clearField();
        setLoadingChangeNews(false);
      } catch (e) {
        console.log(e);
        closeModal();
        clearField();
        setLoadingChangeNews(false);
      }
    } else {
      setErrorTitle(!title);
      setErrorContent(!content);
    }
  };

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, "day").endOf("day");
  }

  function disabledDateTime(current) {
    if (
      current?.get("date") === moment().get("date") &&
      current?.get("hour") === moment().get("hour")
    ) {
      return {
        disabledHours: () => range(0, 24).splice(0, moment().get("hour")),
        disabledMinutes: () =>
          range(0, 24).splice(0, moment().get("minute") + 1),
      };
    }
    if (current?.get("date") === moment().get("date")) {
      return {
        disabledHours: () => range(0, 24).splice(0, moment().get("hour")),
      };
    }
  }

  useEffect(() => {
    if (openModal) {
      if (initDateTime) {
        setDateTime(moment(initDateTime, "DD.MM.YYYY HH:mm:ss"));
        setReadOnlyDate(
          moment() >= moment(initDateTime, "DD.MM.YYYY HH:mm:ss")
        );
      }

      setLoadingCategory(true);
      ekasut.news
        .getCategory()
        .then((r) => {
          if (r.status === 200) {
            setCategoryOptions(r.data);
          }
          setLoadingCategory(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingCategory(false);
        });
      setTitle(initTitle);
      setContent(initContent);
      setValueCategory(initValueCategory);
      setFileObjectsImage(initFileObjectsImage);
      setFileObjects(initFileObjects);
    }
  }, [openModal]);

  return (
    <Dialog
      onClose={() => {
        if (!loadingChangeNews && !loadingCreatNews) {
          closeModal();
          clearField();
        }
      }}
      open={openModal}
      fullWidth
      className={style.modalNews}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        {edit ? "Редактирование новости" : "Создание новости"}
      </DialogTitle>
      <DialogContent dividers>
        <div className={style.itemField}>
          <DropzoneAreaBase
            filesLimit={1}
            acceptedFiles={["image/*"]}
            dropzoneText={"Перетащите картинку или кликните сюда"}
            onAdd={(fileObjs) => {
              setFileObjectsImage(fileObjs);
            }}
            onDelete={(fileObj) => {
              setFileObjectsImage((prevState) =>
                prevState.filter((el) => el.data !== fileObj.data)
              );
            }}
            fileObjects={fileObjectsImage}
            maxFileSize={5000000}
            dropzoneClass={style.dropPhotoClass}
          />
        </div>
        <div className={style.itemField}>
          <TextField
            label="Название"
            fullWidth
            disabled={loadingCreatNews || loadingChangeNews}
            variant={"outlined"}
            error={errorTitle}
            onFocus={() => setErrorTitle(false)}
            helperText={"Обязательное поле"}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div className={style.itemField}>
          <TextField
            multiline
            fullWidth
            disabled={loadingCreatNews || loadingChangeNews}
            error={errorContent}
            helperText={"Обязательное поле"}
            minRows={5}
            onFocus={() => setErrorContent(false)}
            variant={"outlined"}
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            label={"Содержание"}
          />
        </div>
        <div className={style.itemField}>
          <Autocomplete
            disabled={loadingCreatNews || loadingChangeNews}
            loading={loadingCategory}
            value={valueCategory}
            inputValue={inpVal}
            onInputChange={(e, val) => {
              if (val.length < 16) {
                setInpVal(val);
              }
            }}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValueCategory({
                  name: newValue,
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValueCategory({
                  name: newValue.inputValue,
                });
              } else {
                setValueCategory(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== "") {
                if (
                  !categoryOptions.find(
                    (el) => el.name === params.inputValue.trim()
                  )
                ) {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Новая категория "${params.inputValue}"`,
                  });
                }
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={categoryOptions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.name;
            }}
            renderOption={(option) => option.name}
            style={{ backgroundColor: "white", width: "100%" }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Категория" variant={"outlined"} />
            )}
          />
        </div>
        <div className={style.itemFileField}>
          <DropzoneAreaBase
            filesLimit={3}
            useChipsForPreview
            dropzoneText={"Перетащите файлы или кликните сюда"}
            onAdd={(fileObjs) => {
              if (!fileObjects.find((el) => el.data === fileObjs[0].data)) {
                setFileObjects((prevState) => [...prevState, ...fileObjs]);
              }
            }}
            onDelete={(fileObj) => {
              setFileObjects((prevState) =>
                prevState.filter((el) => el.data !== fileObj.data)
              );
            }}
            fileObjects={fileObjects}
            dropzoneClass={style.dropFileClass}
          />
        </div>
        <div className={style.itemField}>
          <DatePicker
            locale={locale}
            value={dateTime}
            format={"DD.MM.YYYY HH:mm:ss"}
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            disabled={readOnlyDate || loadingCreatNews || loadingChangeNews}
            placeholder="Дата публикации (необязательно)"
            onChange={(date) => {
              if (
                date.format("DD.MM.YYYY HH:mm:ss") ===
                  moment()
                    .set("minute", 0)
                    .set("second", 0)
                    .format("DD.MM.YYYY HH:mm:ss") &&
                date.get("minute") === 0
              ) {
                setDateTime(
                  moment()
                    .set("minute", moment().get("minute") + 5)
                    .set("second", 0)
                );
              } else {
                setDateTime(date);
              }
            }}
            showTime={{
              defaultValue: moment()
                .set("hour", moment().get("hour") + 1)
                .set("minute", 0)
                .set("second", 0),
            }}
            renderExtraFooter={() => (
              <Button
                onClick={() =>
                  setDateTime(
                    moment()
                      .set("hour", moment().get("hour") + 1)
                      .set("minute", 0)
                      .set("second", 0)
                  )
                }
                type={"link"}
                className={style.toDay}
              >
                Сегодня
              </Button>
            )}
            showNow={false}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeModal();
            clearField();
          }}
        >
          Отмена
        </Button>
        {edit ? (
          <Button
            onClick={changeNews}
            loading={loadingChangeNews}
            type="primary"
          >
            Изменить
          </Button>
        ) : (
          <Button
            onClick={createNews}
            loading={loadingCreatNews}
            type="primary"
          >
            Создать
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
