import React, {useEffect} from 'react';
import AuditStepsWidget from "./AuditStepsWidget";
import {auditSteps} from "./AuditSteps";
import {observer, useLocalStore} from "mobx-react-lite";
import styles from './PrepareAuditPlan.module.scss'
import LocalizedCalendar from "../../shared/LocalizedCalendar";
import {Views} from "react-big-calendar";
import {Button, Header, Icon} from "semantic-ui-react";
import moment from 'moment';
import {toJS} from "mobx";
import CalendarForMonth from "../../shared/CalendarForMonth";
import cn from 'classnames'
import IconInfo from "../../shared/IconInfo";
import {capitalize} from "../../helpers/utils";
import AddEventModal from "./AddEventModal";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import ekasut from "../../api/ekasut";
import {usePrompt} from "../../globalStore/useGlobalStore";

const PrepareAuditPlan = observer(({onClose = () => null, audit = {}, onComplete, onView, editable, step, viewStep, display}) => {

    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        date: moment(),
        hoursFrom: moment().startOf("day").hours(8),
        hoursTo: moment().startOf("day").hours(19),
        addModal: {open: false, dateStart: null, dateEnd: null, responsible: "", eventName: "", processId: null, processOwner: "", payload: {}},
        events: [],
        deletedEventIds: [],
        wasFirstDisplay: false
    }))

    useEffect(() => {
        if (display && !ls.wasFirstDisplay) {
            ls.wasFirstDisplay = true
            updateCalendar()
        }
    }, [display, ls.wasFirstDisplay])

    const updateCalendar = () => {
        ekasut.audit.getPlanEvents({auditId: audit.auditId})
            .then(({data}) => {
                ls.events = data.map(item => ({
                    id: item.eventId,
                    title: `${item.eventName} (${item.processName}, ${item.processOwner})`,
                    start: moment(item.dateStart).toDate(),
                    eventName: item.eventName,
                    end: moment(item.dateEnd).toDate(),
                    responsible: item.objects,
                    processId: item.processId,
                    processOwner: item.processOwner
                }))
            })
            .catch(catchNetworkErrors)
    }

    const saveAndComplete = (complete) => {
        prompt.show("Сохранение добавленных мероприятий", undefined, {loading: true})
        Promise.all(
            ls.events.filter(event => event.id === undefined).map(event => ekasut.audit.postPlanEvent({
                auditId: audit.auditId,
                data: {
                    dateStart: moment(event.start).format(),
                    dateEnd: moment(event.end).format(),
                    eventName: event.eventName,
                    objects: event.responsible,
                    processId: event.processId,
                    processOwner: event.processOwner
                }
            }))
        )
            .then(() => {
                return Promise.all(ls.deletedEventIds.map(eventId => ekasut.audit.deletePlanEvent({eventId})))
            })
            .then(() => {
                if (complete) {
                    onComplete()
                } else {
                    ls.deletedEventIds = []
                    updateCalendar()
                    prompt.show("Мероприятия сохранены", "", {ok: true})
                }

            })
            .catch((err) => {
                prompt.close()
                return catchNetworkErrors(err)
            })
    }

    const createEvent = (values) => {
        ls.addModal.open = false
        ls.events.push({
            id: undefined,
            title: `${values.eventName} (${values.processName}, ${values.processOwner})`,
            eventName: values.eventName,
            start: values.dateStart.toDate(),
            end: values.dateEnd.toDate(),
            responsible: values.responsible,
            processId: values.processId,
            processOwner: values.processOwner
        })
    }

    const checkForm = () => {
        return true /*ls.events.length !== 0*/
    }

    if (!display) {
        return false
    }

    return (
        <AuditStepsWidget
            /*exportButtonText="Экспорт плана аудита в Word"*/
            displayButtons={{cancel: true, exportDoc: false, save: true, end: true}}
            onClose={onClose}
            onCancel={onClose}
            step={step}
            viewStep={viewStep}
            onView={onView}
            className={cn(styles.component)}
            onSave={() => {
                saveAndComplete(false)
            }}
            onEnd={() => {
                if (checkForm()) {
                    prompt.show("Вы действительно хотите завершить этап?", "", {yesNo: true, onApply: () => saveAndComplete(true)})
                } else {
                    prompt.show("Завершение этапа не выполнено", "Не все обязательные поля заполнены", {ok: true})
                }
            }}
        >
            <div className="content">
                <LocalizedCalendar
                    className="calendar-plan"
                    startAccessor="start"
                    endAccessor="end"
                    events={[...ls.events]}
                    /* Provide this prop or get error in log */
                    onNavigate={() => {

                    }}
                    //selectable={true}
                    onSelectEvent={(event) => {
                        ls.addModal = {
                            open: true,
                            editable: false,
                            dateStart: moment(event.start),
                            dateEnd: moment(event.end),
                            responsible: event.responsible,
                            eventName: event.eventName,
                            payload: {eventId: event.id},
                            processId: event.processId,
                            processOwner: event.processOwner
                        }
                    }}
                    toolbar={false}
                    date={ls.date.toDate()}
                    min={ls.hoursFrom.toDate()}
                    max={ls.hoursTo.toDate()}
                    defaultView={Views.DAY}
                    views={[Views.DAY]}
                />
                <div className="right-container">
                    <DayNavigator
                        date={ls.date}
                        onChange={(date) => ls.date = date}
                        className="calendar-navigator"
                    />
                    <CalendarForMonth
                        month={ls.date}
                        dayOnClick={(day) => {
                            ls.date = day
                        }}
                        daysOptions={[{
                            day: ls.date,
                            fill: 'red'
                        }]}
                    />
                    <Header as="h5" content={audit.typeName}/>
                    <div className="info-group">
                        <IconInfo text={capitalize(ls.date.format("dddd, DD MMMM"))} icon="calendar alternate outline"/>
                        <IconInfo text={`${ls.hoursFrom.format("H:mm")} - ${ls.hoursTo.format("H:mm")}`} icon="clock outline"/>
                        <IconInfo text={audit.managerFio} icon="user"/>
                    </div>
                    {editable && (
                        <div>
                            <Button
                                content="Добавить пункт плана" basic icon="add"
                                className="button-basic-without-borders"
                                onClick={() => ls.addModal = {
                                    open: true,
                                    dateStart: moment(ls.date).startOf("day").hours(8),
                                    dateEnd: moment(ls.date).startOf("day").hours(10),
                                    eventName: "",
                                    responsible: "",
                                    payload: {},
                                    processId: null,
                                    processOwner: ""
                                }}
                            />
                        </div>
                    )}
                    <AddEventModal
                        {...ls.addModal}
                        showDeleteButton={editable}
                        onSave={(completed, values) => {
                            if (completed) {
                                createEvent(values)
                            } else {
                                prompt.show("Ошибка ввода", "Не все поля заполнены или указан неверный диапазон дат", {ok: true})
                            }
                        }}
                        processes={audit.processes}
                        onDelete={() => {
                            if (ls.addModal.payload.eventId) {
                                ls.deletedEventIds.push(ls.addModal.payload.eventId)
                            }
                            const eventIndex = ls.events.findIndex(event => event.id === ls.addModal.payload.eventId)
                            if (eventIndex !== -1) {
                                ls.events.splice(eventIndex, 1)
                            }
                            ls.addModal.open = false
                        }}
                        onClose={() => ls.addModal.open = false}
                    />
                </div>
            </div>
        </AuditStepsWidget>
    );
})

const DayNavigator = ({date, onChange, className}) => {
    return <div className={cn(styles.dayNavigator, className)}>
        <Icon name="left arrow" onClick={() => onChange(moment(date).add(-1, "day"))}/>
        <Icon name="right arrow" onClick={() => onChange(moment(date).add(1, "day"))}/>
        <span onClick={() => onChange(moment())}>СЕГОДНЯ</span>
    </div>
}

export default PrepareAuditPlan;