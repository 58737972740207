import React, {useEffect, useState} from 'react';
import {Comment,} from 'semantic-ui-react'
import moment from "moment";
import style from "./CommentItem.module.scss";
import {Button, CardContent, IconButton, TextField} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import {Popconfirm} from "antd";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export const CommentItem = (props) => {
    const {
        img = '', addedOn, userName,
        content, children, commentsActions = true,
        sendComment, id, userId, updatedOn, deletedOn,
        editCommentHandler, newsId, deleteCommentHandler, userRole, taskUserRole
    } = props


    const [date, setDate] = useState('');
    const [textDate, setTextDate] = useState('');

    let addedOnValue = ''

    let inputDate = moment(date.split('.')[0]).set('hour', 0).set('minute', 0).set('second', 0);


    let todaysDate = moment().set('hour', 0).set('minute', 0).set('second', 0);


    if (inputDate.format('DD.MM.YYYY HH:mm:ss') === todaysDate.format('DD.MM.YYYY HH:mm:ss')) {
        addedOnValue = textDate + 'сегодня в ' + moment(date).format('HH:mm:ss')
    } else if (inputDate.format('DD.MM.YYYY HH:mm:ss') === moment().subtract(1, 'days').format('DD.MM.YYYY HH:mm:ss')) {
        addedOnValue = textDate + 'вчера в ' + moment(date).format('HH:mm:ss')
    } else {
        addedOnValue = moment(date).format('DD.MM.YYYY HH:mm:ss')
    }


    const [comment, setComment] = useState('');

    const [editMode, setEditMode] = useState(false);

    const [editCommentValue, setEditCommentValue] = useState('');

    const [activeComment, setActiveComment] = useState(false);

    const handlerSendComment = () => {
        if (comment.trim()) {
            sendComment(comment, id)
            setComment('')
            setActiveComment(false)
        }
    }

    const saveEditComment = () => {
        editCommentHandler(id, editCommentValue.trim())
        setEditCommentValue(content)
        setEditMode(false)
    }

    const deleteComment = () => {
        deleteCommentHandler(id)
    }

    useEffect(() => {
        if (deletedOn) {
            setDate(deletedOn)
            setTextDate(' ')
        } else if (updatedOn) {
            setDate(updatedOn)
            setTextDate('Изменен ')
        } else {
            setDate(addedOn)
            setTextDate('')
        }
    }, [updatedOn, addedOn, deletedOn])

    useEffect(() => {
        setEditCommentValue(content)
    }, [content])

    return (
        <Comment>
            {deletedOn ? null : (img ? <Comment.Avatar src={img}/> : null)}
            <Comment.Content>
                <div className={style.commentTitle}>
                    <div className={style.head}>
                        {!img && <AccountCircleIcon/>}
                        <Comment.Author as='a'>{deletedOn ? 'Комментарий удален' : `${userName}`}</Comment.Author>
                        <Comment.Metadata>
                            <div>{`${addedOnValue} ${deletedOn ? '' : userRole === 1 ? 'Админ' : userRole === 3 ? 'Гость' : ''}`}</div>
                        </Comment.Metadata>
                    </div>
                    {deletedOn ? null
                        : <div className={style.actionsCommentTitle}>
                            {userId == localStorage.getItem('userId') ?
                                <IconButton size={"small"} onClick={() => setEditMode(true)}>
                                    <EditIcon fontSize={"small"} color={"primary"}/>
                                </IconButton> : null}
                            {userId == localStorage.getItem('userId') || taskUserRole === 1 ? <Popconfirm
                                title="Вы точно хотите удалить комментарий?"
                                onConfirm={deleteComment}
                                okText="Да"
                                cancelText="Нет"
                            >
                                <IconButton size={"small"}>
                                    <DeleteForeverIcon fontSize={"small"} color={"primary"}/>
                                </IconButton>
                            </Popconfirm> : null}

                        </div>
                    }
                </div>
                {
                    deletedOn ? null
                        : <>
                            <Comment.Text>{editMode ? <>
                                    <TextField multiline
                                               autoFocus
                                               fullWidth
                                               minRows={2}
                                               variant={"outlined"}
                                               value={editCommentValue}
                                               onChange={(e) => {
                                                   setEditCommentValue(e.target.value)
                                               }}
                                    />
                                    <div className={style.editActions}>
                                        <Button onClick={() => {
                                            setEditMode(false)
                                            setEditCommentValue(content)
                                        }}>Отмена</Button>
                                        <Button color="primary" onClick={saveEditComment}>Сохранить</Button>
                                    </div>
                                </>
                                : <span style={{whiteSpace: 'pre-line'}}>{content}</span>}</Comment.Text>
                            {
                                commentsActions && !editMode ? (
                                        activeComment ? <ClickAwayListener onClickAway={() => setActiveComment(false)}>
                                                <div className={style.mainComment}>
                                                    <TextField multiline
                                                               autoFocus
                                                               fullWidth
                                                               minRows={2}
                                                               variant={"outlined"}
                                                               value={comment}
                                                               onChange={(e) => {
                                                                   setComment(e.target.value)
                                                               }}
                                                               label={'Комментарий'}/>
                                                    <IconButton onClick={handlerSendComment}>
                                                        <SendIcon color={"primary"}/>
                                                    </IconButton>
                                                </div>
                                            </ClickAwayListener>
                                            : <Comment.Actions>
                                                <Comment.Action><span
                                                    onClick={() => {
                                                        if (!editMode)
                                                            setActiveComment(true)
                                                    }}>Ответить</span></Comment.Action>
                                            </Comment.Actions>
                                    )

                                    : null
                            }
                        </>
                }
            </Comment.Content>
            {
                children?.length ? <Comment.Group>
                    {children.map(el => <CommentItem key={el.id} {...el}
                                                     deleteCommentHandler={deleteCommentHandler}
                                                     editCommentHandler={editCommentHandler}
                                                     commentsActions={false}/>)}
                </Comment.Group> : null
            }

        </Comment>
    )
}