import React, { useEffect, useState } from "react";
import { sortableElement, sortableHandle } from "react-sortable-hoc";
import moduleStyle from "./StatusList.module.scss";
import style from "../modalView.module.scss";
import { Icon, Popup } from "semantic-ui-react";
import { DelStatus } from "./DelStatus";
import { ColorPicker, useColor } from "react-color-palette";
import { Input, Tooltip } from "antd";
import ekasut from "../../../../../api/ekasut";

const DragHandle = sortableHandle(() => (
  <Icon style={{ marginLeft: "10px", marginBottom: "5px" }} name="bars" />
));

export const StatusItem = ({
  index,
  e,
  key,
  statusList,
  templData,
  initStatuses,
  arrLength,
}) => {
  const [editNameStatus, setEditNameStatus] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [newStatusName, setNewStatusName] = useState(e.statusName);
  const [color, setColor] = useColor("hex", e.statusColorHex);
  const [loading, setLoading] = useState(false);

  const updateStatus = (newName, status) => {
    setLoading(true);
    let newStatusArr = [...statusList].map((el) => {
      if (el.statusId === status.statusId) {
        return {
          ...el,
          statusColorHex: color.hex,
          statusName: newName ? newName : el.statusName,
        };
      } else {
        return el;
      }
    });
    ekasut.nsiTaskManager
      .updateTemp({
        templateId: templData.code,
        templateName: templData.name,
        statuses: newStatusArr,
      })
      .then(() => {
        initStatuses(templData.code);
        setLoading(false);
        setEditNameStatus(false);
      })
      .catch(() => {
        setLoading(false);
        setEditNameStatus(false);
      });
  };

  useEffect(() => {
    console.log(editNameStatus);
  }, [editNameStatus]);

  // const SortableItem = sortableElement(({ e }) => (
  //   <li className={moduleStyle.drugLi}>
  //     <div className={style.statusCatList}>
  //       <DragHandle />
  //       {editNameStatus ? (
  //         <div className={style.actionsIcons}>
  //           <Icon
  //             name={"checkmark"}
  //             color={"green"}
  //             onClick={() => {
  //               updateStatus(newStatusName, e);
  //             }}
  //           />
  //           <Icon
  //             name={"delete"}
  //             color={"red"}
  //             onClick={() => {
  //               setEditNameStatus(false);
  //             }}
  //           />
  //         </div>
  //       ) : null}
  //       {editNameStatus ? (
  //         <Popup
  //           className={style.palette}
  //           on="click"
  //           pinned
  //           trigger={
  //             <div
  //               style={{
  //                 borderRadius: "3px",
  //                 marginLeft: "10px",
  //                 width: "15px",
  //                 height: "10px",
  //                 background: `${color.hex}`,
  //               }}
  //             />
  //           }
  //           content={
  //             <ColorPicker
  //               width={220}
  //               height={150}
  //               color={color}
  //               onChange={setColor}
  //               alpha
  //               hideHSV
  //               hideRGB
  //               hideHEX
  //             />
  //           }
  //         />
  //       ) : (
  //         <div
  //           className={style.colorState}
  //           style={{ background: e.statusColorHex }}
  //         />
  //       )}
  //       {editNameStatus ? (
  //         <Input
  //           style={{ paddingLeft: "5px" }}
  //           onChange={(e) => {
  //             setNewStatusName(e.target.value);
  //           }}
  //           value={newStatusName}
  //           autoFocus
  //           bordered={false}
  //         />
  //       ) : (
  //         <span style={{ color: e.statusColorHex }}>{e.statusName}</span>
  //       )}
  //       {!editNameStatus ? (
  //         <div className={style.editIcons}>
  //           <Icon
  //             name={"pencil"}
  //             color={"black"}
  //             onClick={() => {
  //               console.log("pencil clicked");
  //               setEditNameStatus(true);
  //             }}
  //           />
  //           <Tooltip
  //             title={arrLength === 1 ? "Нельзя удалить последний статус" : ""}
  //           >
  //             <Icon
  //               name={"trash alternate outline"}
  //               color={"red"}
  //               disabled={arrLength === 1}
  //               onClick={() => {
  //                 setOpenDel(true);
  //               }}
  //             />
  //           </Tooltip>
  //         </div>
  //       ) : null}
  //     </div>
  //   </li>
  // ));

  return (
    <>
      <DelStatus
        initStatuses={initStatuses}
        templData={templData}
        openDel={openDel}
        setOpenDel={setOpenDel}
        status={e}
        statusList={statusList}
      />
      <li className={moduleStyle.drugLi}>
        <div className={style.statusCatList}>
          <DragHandle />
          {editNameStatus ? (
            <div className={style.actionsIcons}>
              <Icon
                name={"checkmark"}
                color={"green"}
                onClick={() => {
                  updateStatus(newStatusName, e);
                }}
              />
              <Icon
                name={"delete"}
                color={"red"}
                onClick={() => {
                  setEditNameStatus(false);
                }}
              />
            </div>
          ) : null}
          {editNameStatus ? (
            <Popup
              className={style.palette}
              on="click"
              pinned
              trigger={
                <div
                  style={{
                    borderRadius: "3px",
                    marginLeft: "10px",
                    width: "15px",
                    height: "10px",
                    background: `${color.hex}`,
                  }}
                />
              }
              content={
                <ColorPicker
                  width={220}
                  height={150}
                  color={color}
                  onChange={setColor}
                  alpha
                  hideHSV
                  hideRGB
                  hideHEX
                />
              }
            />
          ) : (
            <div
              className={style.colorState}
              style={{ background: e.statusColorHex }}
            />
          )}
          {editNameStatus ? (
            <Input
              style={{ paddingLeft: "5px" }}
              onChange={(e) => {
                setNewStatusName(e.target.value);
              }}
              value={newStatusName}
              autoFocus
              bordered={false}
            />
          ) : (
            <span style={{ color: e.statusColorHex }}>{e.statusName}</span>
          )}
          {!editNameStatus ? (
            <div className={style.editIcons}>
              <Icon
                name={"pencil"}
                color={"black"}
                onClick={() => {
                  console.log("pencil clicked");
                  setEditNameStatus(true);
                }}
              />
              <Tooltip
                title={arrLength === 1 ? "Нельзя удалить последний статус" : ""}
              >
                <Icon
                  name={"trash alternate outline"}
                  color={"red"}
                  disabled={arrLength === 1}
                  onClick={() => {
                    setOpenDel(true);
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </li>
    </>
  );
};
