import React, {useCallback, useEffect, useState} from 'react';
import styles from "./AddPurchaseView.module.scss"
import {Checkbox, Header, Modal, Button, Icon} from "semantic-ui-react";
import TextField from "../../shared/fields/TextField";
import InputField from "../../shared/fields/InputField";
import DropdownField from "../../shared/fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import moment from 'moment'
import cn from 'classnames'
import Widget from "../../shared/Widget";
import ProcessViewButtons from "./ProcessViewButtons";
import ekasut from "../../api/ekasut";

const AddResponsibleView = observer((
    {
        open, purchase,
        onSaveOrEnd = () => null,
        onClose = () => null, purchaseTypesLoading
    }
) => {

    const [fioList, setFioList] = useState([])

    const ls = useLocalStore(() => ({
        responsibleFio: ""
    }))

    useEffect(() => {
        if (open) {
            ls.responsibleFio = purchase.responsibleFio === null ? "" : purchase.responsibleFio
        }
        ekasut.purchases.getResponsibleFIO()
            .then(res => {
                setFioList(res.data.map(fio => ({text: fio, value: fio})))
            })
    }, [open])

    const closeHandler = () => {
        ls.responsibleFio = ""
        onClose()
    }

    return (
        <Modal open={open} size="tiny" className={cn(styles.component, "alt-semantic")} onClose={closeHandler}>
            <Widget
                header="Выбор ответственного"
                footer={(
                    <ProcessViewButtons
                        onEnd={() => onSaveOrEnd(ls, purchase, true)}
                        onSave={() => onSaveOrEnd(ls, purchase, false)}
                        onClose={closeHandler}
                        disabledEnd={ls.responsibleFio === ""}
                        showSave={false}
                    />
                )}
            >
                <div className="add-properties">
                    <div>
                        <DropdownField
                            label="ФИО (Фамилия и инициалы)"
                            search fluid allowAdditions
                            options={fioList}
                            value={ls.responsibleFio}
                            onChange={(e, {value}) => {
                                ls.responsibleFio = value
                            }}
                            onAddItem={(e, {value}) => {
                                let defaultFIOList = fioList
                                defaultFIOList.push({text: value, value: value})
                                setFioList(defaultFIOList)
                            }}
                        />
                        {/*<InputField*/}
                        {/*    label="ФИО"*/}
                        {/*    inputClassName="input-underline"*/}
                        {/*    value={ls.responsibleFio}*/}
                        {/*    onChange={(e, {value}) => {*/}
                        {/*        ls.responsibleFio = value*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </div>
                </div>
            </Widget>
        </Modal>
    );
})

export default AddResponsibleView;
