import React from 'react';

class ResourceCell extends React.PureComponent {
  render() {
    return (
      <div className="dx-template-wrapper">
        
      </div>
    );
  }
}

export default ResourceCell;
