import React, {useEffect, useState} from 'react'
import {formOptions} from "../../helpers/utils";
import DelayedSearch from "../NsiWeightLength/DelayedSearch";
import cn from "classnames";
import appStyles from "../../css/App.module.scss";

// list: [{text, id, ...payload}}]
export default function EventPicker({list, label, onChange, editable, defaultIndex,...props}) {
    const [options, setOptions] = useState([])
    const [index, setIndex] = useState(defaultIndex)

    useEffect(() => {
        setOptions(formOptions(list, 'text'))
    }, [list])

    return (
        <>
            <label>{label}</label>
            <DelayedSearch
                fluid
                options={options}
                className={cn(!editable && appStyles.fixedDropdown)}
                disabled={!editable}
                value={editable ? index : defaultIndex}
                loading={false}
                onChange={(e, {value: index}) => {
                    setIndex(index)
                    onChange(list[index], index)
                }}
            />
        </>
    )
}
EventPicker.defaultProps = {
    label: ''
}