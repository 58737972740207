import React, {useEffect, useState} from 'react'
import {Table, Button, Popup, Segment, Icon} from 'semantic-ui-react'
import AppModal from "../../shared/AppModal";

import MonthViolationResult from './MonthViolationResult'
import * as moment from 'moment';

const MonthlyViolations = ({overwork, period, fireMonthly}) => {
    const [resultOpen, setResultOpen] = useState(false)
    const [violationInfo, setViolationInfo] = useState(false)

    useEffect(() => {
        if(!resultOpen) {
            fireMonthly()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultOpen])

    return (
        <>
            {overwork.length > 0
                ? (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Наименование депо</Table.HeaderCell>
                                <Table.HeaderCell>ФИО</Table.HeaderCell>
                                <Table.HeaderCell>ТН ЕК АСУТР</Table.HeaderCell>
                                <Table.HeaderCell>Должность</Table.HeaderCell>
                                <Table.HeaderCell>Наименование основного вида движения</Table.HeaderCell>
                                <Table.HeaderCell>Номер колонны</Table.HeaderCell>
                                <Table.HeaderCell>Кол-во недоработки в минутах</Table.HeaderCell>
                                <Table.HeaderCell>Кол-во переработки в минутах</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {overwork.map((x, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        { !x.investigationResult
                                            ? (
                                                <Popup content='Добавить расследование' trigger={
                                                    <Button icon='add' onClick={() => {
                                                        setResultOpen(true)
                                                        setViolationInfo({
                                                            depot: x.depotName,
                                                            driver: `${x.lastName} ${x.firstName} ${x.patrName}`,
                                                            number: x.tabnomEkasutr,
                                                            post: x.postStr,
                                                            depotId: x.depotOkdl6,
                                                            period,

                                                        })
                                                    }}/>
                                                } />
                                            )
                                            : (
                                                <Popup
                                                    header={moment(x.investigationDate).format("YYYY.MM.DD HH:mm:ss")}
                                                    content={<span dangerouslySetInnerHTML={{__html: x.investigationResult.replace(/\n/gi, '<br/>').replace(/ /gi, '&nbsp;')}}></span>}
                                                    trigger={<Button style={{width: '38px'}} onClick={() => {
                                                        setResultOpen(true)
                                                        setViolationInfo({
                                                            depot: x.depotName,
                                                            driver: `${x.lastName} ${x.firstName} ${x.patrName}`,
                                                            number: x.tabnomEkasutr,
                                                            post: x.postStr,
                                                            depotId: x.depotOkdl6,
                                                            period,
                                                            investigationResult: x.investigationResult
                                                        })
                                                    }} animated>
                                                        <Button.Content hidden><Icon name='pencil' /></Button.Content>
                                                        <Button.Content style={{width: '10px', marginLeft: '-10px'}} visible><Icon name='info' /></Button.Content>
                                                    </Button>} />
                                            )}
                                    </Table.Cell>
                                    <Table.Cell>{x.depotName}</Table.Cell>
                                    <Table.Cell>{x.lastName} {x.firstName} {x.patrName}</Table.Cell>
                                    <Table.Cell>{x.tabnomEkasutr}</Table.Cell>
                                    <Table.Cell>{x.postStr}</Table.Cell>
                                    <Table.Cell>{x.moveName}</Table.Cell>
                                    <Table.Cell>{x.colNum}</Table.Cell>
                                    <Table.Cell>{x.tNed}</Table.Cell>
                                    <Table.Cell>{x.tSv}</Table.Cell>
                                </Table.Row>
                            ))}

                        </Table.Body>
                    </Table>
                )
                : 'Нет данных'
            }

            <AppModal
                open={resultOpen}
                onClose={() => setResultOpen(false)}
            >
                <Segment>
                    <MonthViolationResult
                        details={violationInfo}
                        setResultOpen={setResultOpen}
                    />
                </Segment>
            </AppModal>

        </>
    )
}
export default MonthlyViolations