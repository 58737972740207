
export const getFilterParams = (filters, area) => {
    // Use URLSearchParams for proper converting array to URL query string
    const params = new URLSearchParams()

    // Shorten query string length
    for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
            const value = filters[key]
            // Don't include filters that doesn't change response result
            if ((Array.isArray(value) && value.length > 0) ||
                (typeof value === "string" && value !== "") ||
                (typeof value === "boolean" && value !== false) ||
                (typeof value === "number")
            ) {
                params.append(key, value)
            }
        }
    }

    if (area) {
        params.set("startLat", area.startLat)
        params.set("startLon", area.startLon)
        params.set("endLat", area.endLat)
        params.set("endLon", area.endLon)
    }

    return params
}
