import React, {useState, useCallback, useEffect} from 'react';
import {Menu, Popover, Button, Dropdown, Popconfirm, Divider, Spin} from 'antd';
import {FolderOpenOutlined, EllipsisOutlined, PlusOutlined, SettingOutlined, LoadingOutlined} from '@ant-design/icons';
import style from "../index.module.scss"
import ekasut from "../../../api/ekasut";
import {initBoard, getUsersTasks, initList} from "../dashboardsLogic/board";
import {WSMore} from "./Modals/WSMore/WSMore";
import {AddItem} from "./Modals/AddItem";
import {DeleteGroup} from "./Modals/DeleteGroup/DeleteGroup";
import {Labels} from "./Modals/Labels/Labels";
import {board} from "../TaskListState";
import {Icon} from "semantic-ui-react";
import auth from '../../../helpers/auth';
import {getAppGrants} from "../../../helpers/utils";

const {SubMenu} = Menu;
const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

const Squad = ({color}) => (
    <div className={style.squadIcon} style={{backgroundColor: `${color}`}}></div>
)

const Circle = ({color}) => (
    <div className={style.circleIcon} style={{backgroundColor: `${color}`}}></div>
)


export const SideBarBoard = ({setNsiOpen}) => {

    const [openDel, setOpenDel] = useState(false)
    const [tree, setTree] = useState([])
    const [loading, setLoading] = useState(false)
    const [addFolder, setAddFolder] = useState(false)
    const [addList, setAddList] = useState(false)
    const [addIdParent, setAddIdParent] = useState(false)
    const [disableMenu, setDisableMenu] = useState(false)
    const [openKeys, setOpenKeys] = React.useState([]);
    const [rootSubmenuKeys, setRootSubmenuKeys] = React.useState([]);
    const [wSMoreOpen, setWSMoreOpen] = useState(false)
    const [labelsOpen, setLabelsOpen] = useState(false)
    const [wSData, setWSData] = useState(null)

    const leaveGroup = () => {
        ekasut.taskboard.leaveGroup(addIdParent.parentGroupId)
            .then(() => {
                updateTree()
                if (board.workGruopId === addIdParent.parentGroupId) board.boardItems = null
                setAddIdParent(false)
            })
    }

    const editWSMenu = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`addFolderWS`} disabled={addList || addFolder}>
            <span onClick={() => {
                setAddFolder(addIdParent.id)
                setDisableMenu(false)
            }}>
                Добавить папку
            </span>
        </Menu.Item>
        <Menu.Item key={`addListWS`} disabled={addList || addFolder}>
            <span onClick={() => {
                setAddList(addIdParent.id)
                setDisableMenu(false)
            }}>
              Добавить список
            </span>
        </Menu.Item>
        <Menu.Item key={`allTasksWS`} onClick={() => {
            board.allWGTasks = true
            initList({
                id: addIdParent.parentGroupId,
                nameBoard: addIdParent.actionGroupName,
                workGruopRoleId: addIdParent.workGruopRoleId
            })
            setAddIdParent(false)
            setDisableMenu(false)
        }}>
            Посмотреть все задачи
        </Menu.Item>
        <Menu.Item key={`labelsWS`} onClick={() => {
            setLabelsOpen(true)
            setDisableMenu(false)
          }}
          disabled={!auth.getGrants().includes("NSI_taskboard")}
        >
            Редактировать тэги
        </Menu.Item>
        <Menu.Item key={`moreWS`}>
            <a onClick={() => {
                setWSMoreOpen('more')
                setDisableMenu(false)
            }}>
                Подробнее
            </a>
        </Menu.Item>
        <Menu.Item key={`deleteWS`} danger>
            <a onClick={() => {
                setOpenDel(true)
                setDisableMenu(false)
            }}>Удалить</a>
        </Menu.Item>
        <Menu.Item key={`quitWSList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>
    const editUserWSMenu = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`addListWS`} disabled={addList || addFolder}>
            <span onClick={() => {
                setAddList(addIdParent.id)
                setDisableMenu(false)
            }}>
              Добавить список
            </span>
        </Menu.Item>
        <Menu.Item key={`allTasksWS`} onClick={() => {
            board.allWGTasks = true
            initList({
                id: addIdParent.parentGroupId,
                nameBoard: addIdParent.actionGroupName,
                workGruopRoleId: addIdParent.workGruopRoleId
            })
            setAddIdParent(false)
            setDisableMenu(false)
        }}>
            Посмотреть все задачи
        </Menu.Item>
        <Menu.Item key={`labelsWS`} onClick={() => {
            setLabelsOpen(true)
            setDisableMenu(false)
        }}>
            Редактировать тэги
        </Menu.Item>
        <Menu.Item key={`moreWS`}>
            <a onClick={() => {
                setWSMoreOpen('more')
                setDisableMenu(false)
            }}>
                Подробнее
            </a>
        </Menu.Item>
        <Menu.Item key={`quitWSList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>

    const editFolderMenu = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`addListF`} disabled={addList || addFolder}>
            <span onClick={() => {
                setAddList(addIdParent.id)
                setDisableMenu(false)
            }}>
            Добавить список
            </span>
        </Menu.Item>
        <Menu.Item key={`allTasksF`} onClick={() => {
            board.allWGTasks = true
            initList({
                id: addIdParent.parentGroupId,
                nameBoard: addIdParent.actionGroupName,
                workGruopRoleId: addIdParent.workGruopRoleId
            })
            setAddIdParent(false)
            setDisableMenu(false)
        }}>
            Посмотреть все задачи
        </Menu.Item>
        <Menu.Item key={`moreF`} onClick={() => {
            setWSMoreOpen('more')
            setDisableMenu(false)
        }}>
            Подробнее
        </Menu.Item>
        <Menu.Item key={`deleteF`} danger>
            <a onClick={() => {
                setOpenDel(true)
                setDisableMenu(false)
            }}>Удалить</a>
        </Menu.Item>
        <Menu.Item key={`quitFList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>

    const editUserFolderMenu = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`addListF`} disabled={addList || addFolder}>
            <span onClick={() => {
                setAddList(addIdParent.id)
                setDisableMenu(false)
            }}>
            Добавить список
            </span>
        </Menu.Item>
        <Menu.Item key={`allTasksF`} onClick={() => {
            board.allWGTasks = true
            initList({
                id: addIdParent.parentGroupId,
                nameBoard: addIdParent.actionGroupName,
                workGruopRoleId: addIdParent.workGruopRoleId
            })
            setAddIdParent(false)
            setDisableMenu(false)
        }}>
            Посмотреть все задачи
        </Menu.Item>
        <Menu.Item key={`moreF`} onClick={() => {
            setWSMoreOpen('more')
            setDisableMenu(false)
        }}>
            Подробнее
        </Menu.Item>
        <Menu.Item key={`quitFList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>


    const editListMenu = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`moreL`} onClick={() => {
            setWSMoreOpen('more')
            setDisableMenu(false)
        }}>
            Подробнее
        </Menu.Item>
        <Menu.Item key={`deleteL`} danger>
            <a onClick={() => {
                setOpenDel(true)
                setDisableMenu(false)
            }}>Удалить</a>
        </Menu.Item>
        <Menu.Item key={`quitList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>
    const userListMenu = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`moreL`} onClick={() => {
            setWSMoreOpen('more')
            setDisableMenu(false)
        }}>
            Подробнее
        </Menu.Item>
        <Menu.Item key={`quitList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>

    const editGuestMenuList = <Menu className={style.sideDropMenu}>
        <Menu.Item key={`allTasksGuest`} onClick={() => {
            board.allWGTasks = true
            initList({
                id: addIdParent.parentGroupId,
                nameBoard: addIdParent.actionGroupName,
                workGruopRoleId: addIdParent.workGruopRoleId
            })
            setAddIdParent(false)
            setDisableMenu(false)
        }}>
            Посмотреть все задачи
        </Menu.Item>
        <Menu.Item key={`quitGMList`} onClick={() => {
            leaveGroup()
            setDisableMenu(false)
        }}>
            <Icon name='sign-out alternate'/> Покинуть группу
        </Menu.Item>
    </Menu>

    useEffect(() => {
        updateTree()
    }, [])

    const updateTree = () => {
        setLoading(true)
        ekasut.taskboard.getBoardTree()
            .then(({data}) => {
                setRootSubmenuKeys(initRootSubmenuKeys(data))
                setTree(data)
                setLoading(false)
            })
            .catch(() => {
                setTree([])
                setLoading(false)
            })
    }

    const initRootSubmenuKeys = (arr) => {
        let result = []
        for (let i = 0; i < arr.length; i++) {
            result.push(`ws${i}`)
            arr[i].children.forEach((e, fi) => {
                if (e.workGroupTypeId === 2) {
                    result.push(`ws${i}-f${fi}`)
                }
            })
        }
        return result
    }

    const addNewList = (newListName) => {
        ekasut.taskboard.postPartsOfSidebar({
            workGroupName: newListName,
            iconName: 'circle',
            iconColorHex: "#555",
            parentGroupId: addIdParent.parentGroupId,
            workGroupTypeId: 3,
            templateId: addIdParent.templateId,
        }).then((data) => {
            setAddList(false)
            updateTree()
        }).catch(() => {
            setAddList(false)
            updateTree()
        })
    }

    const addNewFolder = (newFolderName) => {
        ekasut.taskboard.postPartsOfSidebar({
            workGroupName: newFolderName,
            iconName: 'folder open outline',
            iconColorHex: "#555",
            parentGroupId: addIdParent.parentGroupId,
            workGroupTypeId: 2,
            templateId: addIdParent.templateId,
        }).then((data) => {
            setAddFolder(false)
            updateTree()
        }).catch(() => {
            setAddFolder(false)
            updateTree()
        })
    }

    const checkRole = (role, level) => {
        if (role === null) {
            return <Menu className={style.sideDropMenu}>
                <Menu.Item key={`allTasksNull`} onClick={() => {
                    board.allWGTasks = true
                    initList({
                        id: addIdParent.parentGroupId,
                        nameBoard: addIdParent.actionGroupName,
                        workGruopRoleId: addIdParent.workGruopRoleId
                    })
                    setAddIdParent(false)
                    setDisableMenu(false)
                }}>
                    Посмотреть все задачи
                </Menu.Item>
            </Menu>
        } else if (role === 3) {
            return editGuestMenuList
        } else if (role === 2) {
            return level === 'WS' ? editUserWSMenu : level === 'F' ? editUserFolderMenu : userListMenu
        } else if (role === 1) {
            return level === 'WS' ? editWSMenu : level === 'F' ? editFolderMenu : editListMenu
        }
    }

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (!disableMenu) {
            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                setOpenKeys(keys);
            } else {
                setOpenKeys(prevState => latestOpenKey ? [...prevState, latestOpenKey] : []);
            }
        }
        if (disableMenu && rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
            setOpenKeys(keys);
            setDisableMenu(false)
        }
    };
    const canAddTask = getAppGrants().includes("NSI_taskboard");

    return (<div className={style.sidebarList}>
            <DeleteGroup openDel={openDel} setOpenDel={setOpenDel} groupData={addIdParent}
                         setAddIdParent={setAddIdParent} updateTree={updateTree}/>
            <WSMore setWSMoreOpen={setWSMoreOpen} setAddIdParent={setAddIdParent} wSMoreOpen={wSMoreOpen}
                    wSData={wSData} setWSData={setWSData}
                    updateTree={updateTree}/>
            <Labels wSData={wSData} setWSData={setWSData} labelsOpen={labelsOpen} setLabelsOpen={setLabelsOpen}/>
            <Menu
                openKeys={openKeys} onOpenChange={onOpenChange}
                style={{width: 256}}
                mode="inline"
            >
                <Menu.Item key={`sets-${Math.random()}`} icon={<SettingOutlined/>} onClick={({key}) => {
                    setNsiOpen(true)
                }} disabled={!canAddTask}>Настройка шаблонов</Menu.Item>
                <Menu.Item 
                  key={`addws-${Math.random()}`} 
                  icon={<PlusOutlined/>} 
                  onClick={({key}) => {
                    setWSMoreOpen('new')
                  }}
                  disabled={!auth.getGrants().includes("NSI_taskboard")}
                >Добавить пространство</Menu.Item>
                <Divider style={{margin: 0}} key={`add-space`}/>
                {/*Уровень рабочих пространств*/}
                {loading ? <div className={style.sidebarLoader}>
                    <Spin indicator={antIcon}/>
                </div> : tree && tree.length ? tree.map((e, wi) => (
                    <SubMenu
                        icon={<><Dropdown
                            onVisibleChange={(visible) => {
                                if (visible) {
                                    setWSData(e)
                                    setDisableMenu(true)
                                    setAddIdParent(
                                        {
                                            id: `ws${wi}`,
                                            parentGroupId: e.id,
                                            templateId: e.templateId,
                                            actionGroupName: e.workGroupName,
                                            workGruopRoleId: e.userRole
                                        }
                                    )
                                } else {
                                    setDisableMenu(false)
                                }
                            }}
                            overlay={checkRole(e.userRole, 'WS')}
                            trigger={['contextMenu']}>
                            <div className={style.editDiv}></div>
                        </Dropdown><Squad color={e.iconColorHex ? e.iconColorHex : '#ff50fc'}/></>}
                        key={`ws${wi}`}
                        title={e.workGroupName}>
                        {addFolder === `ws${wi}` || addList === `ws${wi}` ? <AddItem
                            index={wi}
                            level={'ws'}
                            type={addFolder === `ws${wi}` ? 'addFolder' : 'addList'}
                            onBlur={() => {
                                addFolder === `ws${wi}` ? setAddFolder(false) : setAddList(false)
                            }}
                            onPressEnter={(e) => {
                                if (addFolder === `ws${wi}`) {
                                    if (e.target.value.trim().length) addNewFolder(e.target.value)
                                } else {
                                    if (e.target.value.trim().length) addNewList(e.target.value)
                                }
                            }}
                        /> : null}
                        {/*Уровень папок*/}
                        {e.children.length ? e.children.map((e, fi) => (
                            <React.Fragment key={`ws${wi}-fi${fi}-fragment`}>
                                {e.workGroupTypeId === 3 ? <Menu.Item
                                        icon={<><Dropdown
                                            onVisibleChange={(visible) => {
                                                if (visible) {
                                                    setWSData(e)
                                                    setDisableMenu(true)
                                                    setAddIdParent(
                                                        {
                                                            id: `ws${wi}-l${fi}`,
                                                            parentGroupId: e.id,
                                                            templateId: e.templateId,
                                                            actionGroupName: e.workGroupName,
                                                            workGruopRoleId: e.userRole
                                                        }
                                                    )
                                                } else {
                                                    setDisableMenu(false)
                                                }
                                            }}
                                            overlay={checkRole(e.userRole, 'L')}
                                            trigger={['contextMenu']}>
                                            <div className={style.editDiv}></div>
                                        </Dropdown> <Circle color={e.iconColorHex ? e.iconColorHex : '#ff50fc'}/></>}
                                        key={`ws${wi}-l${fi}`}
                                        onClick={({key}) => {
                                            board.allWGTasks = false
                                            initBoard({
                                                id: e.id,
                                                nameBoard: e.workGroupName,
                                                workGruopRoleId: e.userRole
                                            })
                                        }}>{e.workGroupName} </Menu.Item> :
                                    <SubMenu
                                        icon={<><Dropdown
                                            onVisibleChange={(visible) => {
                                                if (visible) {
                                                    setWSData(e)
                                                    setDisableMenu(true)
                                                    setAddIdParent(
                                                        {
                                                            id: `fi${fi}`,
                                                            parentGroupId: e.id,
                                                            templateId: e.templateId,
                                                            actionGroupName: e.workGroupName,
                                                            workGruopRoleId: e.userRole
                                                        }
                                                    )
                                                } else {
                                                    setDisableMenu(false)
                                                }
                                            }}
                                            overlay={checkRole(e.userRole, 'F')}
                                            trigger={['contextMenu']}>
                                            <div className={style.editDiv}></div>
                                        </Dropdown><FolderOpenOutlined
                                            style={{color: e.iconColorHex ? e.iconColorHex : '#000'}}/></>}
                                        key={`ws${wi}-f${fi}`}
                                        title={e.workGroupName}
                                    >
                                        {addList === `fi${fi}` ? <AddItem
                                            index={fi}
                                            level={'f'}
                                            type={'addList'}
                                            onBlur={() => {
                                                setAddList(false)
                                            }}
                                            onPressEnter={(e) => {
                                                if (e.target.value.trim().length) addNewList(e.target.value)
                                            }}
                                        /> : null}
                                        {/*Уровень листов*/}
                                        {e.children.length ? e.children.map((e, li) => (
                                                <Menu.Item
                                                    icon={<><Dropdown
                                                        onVisibleChange={(visible) => {
                                                            if (visible) {
                                                                setWSData(e)
                                                                setDisableMenu(true)
                                                                setAddIdParent(
                                                                    {
                                                                        id: `ws${wi}-f${fi}-l${li}`,
                                                                        parentGroupId: e.id,
                                                                        templateId: e.templateId,
                                                                        actionGroupName: e.workGroupName,
                                                                        workGruopRoleId: e.userRole
                                                                    }
                                                                )
                                                            } else {
                                                                setDisableMenu(false)
                                                            }
                                                        }}
                                                        overlay={checkRole(e.userRole, 'L')}
                                                        trigger={['contextMenu']}>
                                                        <div className={style.editDiv}></div>
                                                    </Dropdown> <Circle
                                                        color={e.iconColorHex ? e.iconColorHex : '#ff50fc'}/></>}
                                                    key={`ws${wi}-f${fi}-l${li}`}
                                                    onClick={({key}) => {
                                                        board.allWGTasks = false
                                                        initBoard({
                                                            id: e.id,
                                                            nameBoard: e.workGroupName,
                                                            workGruopRoleId: e.userRole
                                                        })
                                                    }}>{e.workGroupName} </Menu.Item>)) :
                                            <Menu.Item
                                                key={`ws${wi}-f${fi}-empty`}
                                                disabled
                                            >{addList === `fi${fi}` ? null : 'Папка пуста'}</Menu.Item>}
                                    </SubMenu>
                                }
                            </React.Fragment>
                        )) : <Menu.Item
                            key={`ws${wi}-empty`}
                            disabled
                        >Нет элементов</Menu.Item>}
                    </SubMenu>
                )) : null}
                <Divider style={{margin: 0}} key={`divider-my-tasks`}/>
                {!loading ? <Menu.Item key={`my-tasks`} onClick={({key}) => {
                    getUsersTasks({categoryId: 1, pageNumber: 1})
                }}>Мои задачи</Menu.Item> : null}
            </Menu>
        </div>
    );
}
