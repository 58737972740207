import React, {useState} from 'react'
import styles from '../index.module.scss'
import {Input} from "semantic-ui-react";
import appStyles from '../../../css/App.module.scss'

export default function TextField({label, defText, editable, onChange, value}) {

    const [ucValue, setUcValue] = useState(defText)

    return (
        <div className={styles.formField}>
            <label>{label}</label>
            <Input
                fluid
                className={appStyles.fixedInput}
                value={value === undefined ? ucValue : (value === null ? '' : value)}
                disabled={!editable}
                onChange={(e, {value}) => {
                    if (value === undefined) {
                        setUcValue(value)
                    }
                    onChange(value)
                }}
            />
        </div>
    )
}