import React, { useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import InputField from "../../../../shared/fields/InputField";
import DropdownField from "../../../../shared/fields/DropdownField";
import styles from "../OrganizationInspection.module.scss";
import ekasut from "../../../../api/ekasut";
import moment from "moment";
import { Input, Select, Typography } from "antd";
import { useEffect } from "react";
const { Option } = Select;

const AddTask = ({ setAddTask, addTask, pred, periodFilter, tasksArr, getTaskArr }) => {
  const [ser, setSer] = useState([]);
  const [CT, setCT] = useState([]);
  const [addSer, setAddSer] = useState(null);
  const [addName, setAddName] = useState(null);
  const [addNewName, setAddNewName] = useState(null);
  const [selectError, setSelectError] = useState("");
  const [display, setDisplay] = useState("none");

  useEffect(() => {
    const getSer = async () => {
      const res = await ekasut.commisionInspection.getSerForAxwork(
        localStorage.getItem("kodFirm")
      );
      setSer(res?.data);
    };

    const getCT = async () => {
      const res = await ekasut.commisionInspection.getCTAxwork(
        localStorage.seasonYear,
        localStorage.season
      );
      setCT(res?.data);
    };

    getSer();
    getCT();
  }, []);

  const insertAxwork = async () => {
    const res = await ekasut.commisionInspection.postAxrok(
      addNewName ? addNewName : addName,
      localStorage.seasonYear,
      localStorage.season,
      localStorage.levFirm,
      localStorage.kodFirm,
      addSer,
      1
    );
    getTaskArr()
  };

  const selectAdditionalWork = (e) => {
    if (e === tasksArr.filter(task => task?.nameAxwork === e)[0]?.nameAxwork) {
      setSelectError('error')
      setDisplay('block')
    } else {
      setSelectError('')
      setDisplay('none')
      setAddName(e)
    }
  };

  return (
    <Modal
      className={styles.addModal}
      onClose={() => setAddTask(false)}
      onOpen={() => setAddTask(true)}
      open={addTask}
      size={"mini"}
    >
      <Modal.Header>Добавление работы</Modal.Header>
      <Modal.Content>
        <Typography.Title
          level={5}
          style={{ fontWeight: 400, marginTop: "10px" }}
        >
          Серия
        </Typography.Title>
        <Select
          showSearch
          placeholder="АБВ0123 ..."
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          style={{
            width: "100%",
          }}
          onChange={(e) => setAddSer(e)}
        >
          {ser.map((e) => (
            <Option key={e?.kodSer} value={e?.kodSer}>
              {e?.serName}
            </Option>
          ))}
        </Select>
        <Typography.Title
          level={5}
          style={{ fontWeight: 400, marginTop: "10px" }}
        >
          Наименование{" "}
          <span style={{ display: display, color: "red" }}>
            Работа уже добавлена
          </span>
        </Typography.Title>
        <Select
          showSearch
          placeholder="Покраска ..."
          status={selectError}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          style={{
            width: "100%",
          }}
          onChange={selectAdditionalWork}
        >
          {CT.map((e) => (
            <Option key={e?.idAxwork} value={e?.nameAxwork}>
              {e?.nameAxwork}
            </Option>
          ))}
        </Select>
        <Typography.Title
          level={5}
          style={{ fontWeight: 400, marginTop: "10px" }}
        >
          Новое наименовение
        </Typography.Title>
        <Input type="text" onChange={(e) => setAddNewName(e.target.value)} />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            setAddTask(false);
          }}
        >
          Отменить
        </Button>
        <Button
          className="button-primary"
          disabled={!addName || selectError ? true : false}
          onClick={() => {
            insertAxwork();
            setAddTask(false)
          }}
        >
          Добавить
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddTask;
