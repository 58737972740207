import React, {useState} from 'react'
import styles from '../index.module.scss'
import appStyles from '../../../css/App.module.scss'
import {SingleDatePicker} from 'react-dates'
import cn from 'classnames'

export default function DatePickerField({editable, value, label, onChange}) {
    const [focus, setFocus] = useState(false)

    return (
        <div className={cn(styles.formField, !editable && styles.fixedSingleDatePicker, appStyles.fixDatePicker)}>
            <label>{label}</label>
            <SingleDatePicker
                date={value}
                disabled={!editable}
                focused={focus}
                noBorder={true}
                block={true}
                placeholder='Дата'
                /* broken with block */
                /* showDefaultInputIcon={true} */
                numberOfMonths={1}
                onDateChange={(date) => {
                    onChange(date)
                }}
                onFocusChange={({focused}) => setFocus(focused)}
            />
        </div>
    )
}
DatePickerField.defaultProps = {
    value: null
}