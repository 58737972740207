import React, { useRef, useState } from "react";
import { observer, useLocalStore } from "mobx-react-lite";
import { StoreProvider, useStore } from "./StoreProvider";
import appStyles from "../../../css/App.module.scss";
import stylePol2 from "./PolygonScheme2.module.scss";
import LayerSelector, { layerNames } from "../../../shared/LayerSelector";
import {
  CircleMarker,
  LayerGroup,
  LayersControl,
  Map,
  Popup,
} from "react-leaflet";
import { Loader } from "semantic-ui-react";
import useDidFirstRender from "../../../hooks/useDidFirstRender";
// import L from "leaflet";
import * as L from "leaflet";
import "leaflet.markercluster";
import LoadingControl from "../../../shared/LoadingControl";
import auth from "../../../helpers/auth";
import settings from "../../../helpers/settings";
import PredLocomotivesTable from "./PredLocomotivesTable";
import LocomotiveModal from "../../../shared/LocomotiveModal";
import DepoInfo from "./DepoInfo";
import catchNetworkErrors from "../../../api/catchNetworkErrors";
import { toJS } from "mobx";
import FloatButton from "../../../shared/FloatButton";
import { getFilterParams } from "./functions";
import ekasut from "../../../api/ekasut";
import apiWorker from "../../../api/apiWorker";
import { downloadBlob } from "../../../helpers/utils";
import Control from "react-leaflet-control";
import { Typography } from "@material-ui/core";

const PolygonScheme2 = observer(
  ({ visibleFilters, filter, onFilter, onTable }) => {
    // console.log();
    const store = useStore();
    const mapRef = useRef({});
    const [numberOfTPS, setNumberOfTPS] = useState(0);

    const ls = useLocalStore(() => ({
      pieLayer: null,
      cluster: undefined,
      showNotifications: settings.getNotifications(auth),
      popup: { open: false, header: "", coords: [0, 0], locomotives: [] },
      locModal: { open: false, locId: null },
      depoModal: { open: false, depoInfo: null },
    }));

    const [loadingFile, setLoadingFile] = useState(false);

    const [markers, setMarkers] = useState({
      stations: [],
      tches: [],
      slds: [],
    });
    // on first render getMap return undefined, after that on didMount getMap has a value
    const getMap = () => mapRef.current.leafletElement;

    const updateCluster = (filters) => {
      console.log(filters);
      return store.clusterPointsApi
        .fetch(
          getFilterParams(filters, {
            startLat: 0,
            startLon: 0,
            endLat: 360,
            endLon: 360,
          })
        )
        .then((clusterPoints) => {
          if (getMap().hasLayer(ls.cluster)) {
            getMap().removeLayer(ls.cluster);
          }
          ls.cluster = createClusterGroup(clusterPoints || []);
          setNumberOfTPS(clusterPoints.length);
          // Event hires on click on default marker (not on a cluster)
          ls.cluster.on("click", (e) =>
            getMap().setView(e.latlng, store.CLUSTER_MAX_ZOOM)
          );
          controlClusterDependOnZoom(
            getMap(),
            ls.cluster,
            store.CLUSTER_MAX_ZOOM
          );
        })
        .catch(catchNetworkErrors);
    };

    const updatePoints = (filters, area) => {
      // console.log('update points', filters)
      const isReqStations =
        getMap().getZoom() >=
        Math.min(
          store.STATION_MIN_ZOOM,
          store.TCHE_MIN_ZOOM,
          store.SLD_MIN_ZOOM
        );
      const isReqPie = getMap().getZoom() >= store.PIE_MIN_ZOOM;

      return Promise.all([
        isReqStations ? store.stTcheSldApi.fetch(area) : undefined,
        isReqPie
          ? store.pieApi.fetch(getFilterParams(filters, area))
          : undefined,
      ])
        .then(([stData = [], pieData = []]) => {
          setMarkers(createStTcheSldMarkers(stData, getMap().getZoom(), store));
          // Delete all pie layers, user can make several request simultaneously
          getMap().eachLayer((layer) => {
            if (layer.name === "pie_layer") {
              getMap().removeLayer(layer);
            }
          });
          // Check zoom second time because it may changed
          if (getMap().getZoom() >= store.PIE_MIN_ZOOM) {
            const pieLayer = createPieLayer(
              pieData,
              getMap(),
              (item, locomotives, color) => {
                if (locomotives) {
                  ls.popup = {
                    open: true,
                    coords: [item.lat, item.lon],
                    locomotives: locomotives,
                    header: item.dislocationName + " - " + color,
                  };
                }
              }
            );
            pieLayer.name = "pie_layer";
            getMap().addLayer(pieLayer);
          }
        })
        .catch(() => {});
    };

    useDidFirstRender(() => {
      updateCluster(filter)
        .then(() => {
          if (ls.cluster) {
            getMap().on("zoomend", () =>
              controlClusterDependOnZoom(
                getMap(),
                ls.cluster,
                store.CLUSTER_MAX_ZOOM
              )
            );
          }
        })
        .catch(catchNetworkErrors);

      // Calls on zoom start
      getMap().on("zoom", (e) => {
        if (getMap().getZoom() < store.PIE_MIN_ZOOM) {
          getMap().eachLayer((layer) => {
            if (layer.name === "pie_layer") {
              getMap().removeLayer(layer);
            }
          });
        }
        const nextMarkers = { ...markers };

        if (
          getMap().getZoom() < store.STATION_MIN_ZOOM &&
          markers.stations.length !== 0
        ) {
          nextMarkers.stations = [];
        }
        if (
          getMap().getZoom() < store.TCHE_MIN_ZOOM &&
          markers.tches.length !== 0
        ) {
          nextMarkers.tches = [];
        }
        if (
          getMap().getZoom() < store.SLD_MIN_ZOOM &&
          markers.slds.length !== 0
        ) {
          nextMarkers.slds = [];
        }
        setMarkers(nextMarkers);
      });
      // Calls when position is changed (including zoom)
      getMap().on("moveend", () => {
        updatePoints(toJS(filter), getArea(getMap()));
      });
    });

    const showDepoInfo = (id) => {
      ekasut.oldReqs
        .depoMap(id)
        .then(({ data }) => {
          ls.depoModal = { open: true, depoInfo: data };
        })
        .catch(catchNetworkErrors);
    };

    return (
      <div>
        <Map
          className={appStyles.map}
          ref={(ref) => (mapRef.current = ref)}
          center={[57.778288, 79.126642]}
          zoom={4}
          minZoom={4}
          maxZoom={16}
          zoomControl={true}
        >
          <LayerSelector
            checkedLayerDef={layerNames.rzd}
            position="topright"
            extend={[
              // Automatically add/remove layer. Checked prop is a default state,
              // LayersControl doesn't work inside other components, even with react fragments <>
              <LayersControl.Overlay
                name="Станции"
                checked={true}
                key="stations"
                zIndex={20}
              >
                <LayerGroup children={markers.stations} zIndex={20} />
              </LayersControl.Overlay>,
              <LayersControl.Overlay name="СЛД" checked={false} key="sld">
                <LayerGroup children={markers.slds} />
              </LayersControl.Overlay>,
              <LayersControl.Overlay name="ТЧЭ" checked={false} key="tche">
                <LayerGroup children={markers.tches} />
              </LayersControl.Overlay>,
            ]}
          />
          <Control position={"bottomleft"}>
            <div className={stylePol2.numberOfTPS}>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                Кол-во ТПЕ: {numberOfTPS}
              </span>
            </div>
          </Control>
          <LoadingControl
            text="Загрузка точек"
            loading={
              store.clusterPointsApi.loading ||
              store.stTcheSldApi.loading ||
              store.pieApi.loading
            }
            position="topleft"
          />
          <LoadingControl
            text="Загрузка отчета..."
            loading={loadingFile}
            position="topleft"
          />
          {ls.popup.open && (
            <Popup
              isOpen
              position={[...ls.popup.coords]}
              onClose={() => (ls.popup.open = false)}
              autoPan={false}
              maxWidth={1100}
              minWidth={1100}
              className="tpe-popup"
            >
              <Typography variant="h4" component="h2" align="center">
                СЛД-24 Петрозаводск (АО "ЛТС")
              </Typography>
              <PredLocomotivesTable
                header={ls.popup.header}
                onCellClick={(loc, isLocCell) => {
                  if (isLocCell && loc.kodDepo) {
                    showDepoInfo(loc.kodDepo);
                  } else {
                    ls.locModal = { open: true, locId: loc.tpeId };
                  }
                }}
                locomotives={ls.popup.locomotives}
              />
            </Popup>
          )}
        </Map>
        <LocomotiveModal
          open={ls.locModal.open}
          locId={ls.locModal.locId}
          onClose={() => {
            ls.locModal = { open: false, locId: null };
          }}
          onError={(err) => {}}
        />
        <DepoInfo
          open={ls.depoModal.open}
          depoInfo={ls.depoModal.depoInfo}
          onClose={() => {
            ls.depoModal = { open: false, depoInfo: null };
          }}
        />
        <FloatButton
          main={{
            text: "",
            rotate: true,
            icon: "angle left",
          }}
          actions={[
            {
              text: "Excel",
              icon: "file excel outline",
              onClick: () => {
                setLoadingFile(true);
                let params = {
                  CoordsSearch: filter.CoordsSearch,
                  stanName: filter.stanName,
                  LocNum: filter.LocNum,
                  LocSer: filter.LocSer,
                  DepoPrip: filter.DepoPrip,
                  Park: filter.Park,
                  TyagaType: filter.TyagaType,
                  VidDv: filter.VidDv,
                  Probeg: filter.Probeg,
                  RepairType: filter.RepairType,
                  SecCount: filter.SecCount,
                  VidOtklNorm: filter.VidOtklNorm,
                  isWorkOnWait: filter.isWorkOnWait,
                  isSick: filter.isSick,
                  notInOperativeRepair: filter.notInOperativeRepair,
                  WaitMoreThen: filter.WaitMoreThen,
                  IsLocVSC: filter.IsLocVSC,
                };
                Object.keys(params).forEach((key) => {
                  if (
                    params[key] == null ||
                    params[key] == [] ||
                    params[key] == ""
                  ) {
                    delete params[key];
                  }
                  if (Array.isArray(params[key])) {
                    params[key] = params[key].join(",");
                  }
                });

                apiWorker(ekasut.ekasutData.getLocoExcel)
                  .fetch(params)
                  .then((res) => {
                    downloadBlob("locomotives_dislocation_report.xlsx", res);
                  })
                  .catch(catchNetworkErrors)
                  .finally(() => {
                    setLoadingFile(false);
                  });
              },
            },
            {
              text: "Настройка фильтров",
              icon: "filter",
              onClick: () => {
                onFilter();
              },
            },
            {
              text: "К таблице",
              icon: "table",
              onClick: () => {
                onTable();
              },
            },
          ]}
        />
      </div>
    );
  }
);

const createPieLayer = (data, map, onClick) => {
  const layer = L.layerGroup();

  data.forEach((item, index) => {
    const zoom = map.getZoom();
    const { yellowCount = 0, redCount = 0, greenCount = 0 } = item;
    const total = yellowCount + redCount + greenCount;

    let markerRadius = zoom + Math.round(total / (zoom - 1));
    if (zoom >= 14) {
      markerRadius *= 1.5;
    }
    markerRadius = markerRadius > 30 ? 30 : markerRadius;

    const options = {
      data: { yellow: yellowCount, red: redCount, green: greenCount },
      fillOpacity: 0.9,
      weight: 1,
      color: "#000000",
      radius: markerRadius,
      stroke: true,

      chartOptions: {
        yellow: {
          displayName: `${item.dislocationName}`,
          fillColor: "#EEDFA0",
          tpeInfo: item.yellow,
        },
        red: {
          displayName: `${item.dislocationName}`,
          fillColor: "#F09A9A",
          tpeInfo: item.red,
        },
        green: {
          displayName: `${item.dislocationName}`,
          fillColor: "#00F081",
          tpeInfo: item.green,
        },
      },
    };

    // console.log("test");
    const pieMarker = new L.PieChartMarker(
      new L.LatLng(item.lat, item.lon),
      options
    );

    pieMarker.on("click", (e, e1) => {
      const color = e.sourceTarget.options.key;
      let colorName;
      switch (e.sourceTarget.options.key) {
        case "yellow":
          colorName = "Жёлтый";
          break;
        case "red":
          colorName = "Красный";
          break;
        case "green":
          colorName = "Зелёный";
          break;
        default:
          break;
      }
      onClick(item, item[color], colorName);
    });

    layer.addLayer(pieMarker);
  });

  return layer;
};

const createStTcheSldMarkers = (data, zoom, zooms) => {
  const stations = [];
  const tches = [];
  const slds = [];
  const showStations = zoom >= zooms.STATION_MIN_ZOOM;
  const showTches = zoom >= zooms.TCHE_MIN_ZOOM;
  const showSlds = zoom >= zooms.SLD_MIN_ZOOM;

  data.forEach((item, index) => {
    const isRem = item.rem && item.rem.length > 0;
    const isExpl = item.expl && item.expl.length > 0;

    const MarkerPopup = ({ subList }) => {
      return (
        <Popup>
          <strong>
            {item.name} ({item.esr})
          </strong>
          {subList && (
            <>
              <br />
              {subList.join(", ")}
            </>
          )}
        </Popup>
      );
    };

    if (showStations) {
      stations.push(
        <CircleMarker
          key={"station_" + index}
          center={[item.lat, item.lon]}
          color="red"
          radius={2 + Math.round(zoom / 3)}
        >
          <MarkerPopup />
        </CircleMarker>
      );
    }
    if (showTches && isRem) {
      tches.push(
        <CircleMarker
          key={"tche_" + index}
          center={[item.lat, item.lon]}
          color="#a82f61"
          fillOpacity={0.8}
          radius={3}
        >
          <MarkerPopup subList={item.rem.map((r) => r.name)} />
        </CircleMarker>
      );
    }
    if (showSlds && isExpl) {
      slds.push(
        <CircleMarker
          key={"sld_" + index}
          center={[item.lat, item.lon]}
          color="#a82f61"
          fillOpacity={0.8}
          radius={3}
        >
          <MarkerPopup subList={item.expl.map((e) => e.name)} />
        </CircleMarker>
      );
    }
  });

  return { stations, tches, slds };
};
const getArea = (map) => {
  const southWest = map.getBounds().getSouthWest();
  const northEast = map.getBounds().getNorthEast();
  return {
    endLat: northEast.lat,
    endLon: northEast.lng,
    startLat: southWest.lat,
    startLon: southWest.lng,
  };
};

const controlClusterDependOnZoom = (map, cluster, maxZoom) => {
  if (map.getZoom() >= maxZoom && map.hasLayer(cluster)) {
    map.removeLayer(cluster);
  }

  if (map.getZoom() < maxZoom && !map.hasLayer(cluster)) {
    map.addLayer(cluster);
  }
};

const createClusterGroup = (points) => {
  const cluster = L.markerClusterGroup({
    chunkedLoading: true,
    spiderfyOnMaxZoom: false,
    iconCreateFunction: function (cluster) {
      // Cluster icon
      return L.divIcon({
        html: "<div><span>" + cluster.getChildCount() + "</span></div>",
        className: "marker-cluster marker-cluster-small",
        iconSize: new L.Point(40, 40),
      });
    },
  });

  points.forEach((item, index) => {
    const marker = L.marker(L.latLng(item.lat, item.lon), { title: index });
    marker.number = index;
    cluster.addLayer(marker);
  });
  return cluster;
};

const Index = (props) => (
  <StoreProvider>
    <PolygonScheme2 {...props} />
  </StoreProvider>
);

export default Index;
