import HC_exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";

const localizeHighcharts = () => {
    Highcharts.setOptions({
        global: {
            timezoneOffset: -3 * 60
        },
        lang: {
            months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
                'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            weekdays: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
            shortMonths: ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль',
                'Авг', 'Сент', 'Окт', 'Ноябрь', 'Дек'],
            contextButtonTitle: 'Меню графика',
            printChart: 'Распечатать график',
            downloadPNG: 'Загрузить изображение PNG',
            downloadJPEG: 'Загрузить изображение JPEG',
            downloadPDF: 'Загрузить документ PDF',
            downloadSVG: 'Загрузить документ SVG',
            exportButtonTitle: 'Экспорт графика',
            loading: 'Загрузка...',
            printButtonTitle: 'Распечатать график',
            resetZoom: 'Сброс увеличения',
            resetZoomTitle: 'Сброс уровня масштабирования до 1:1',
            thousandsSep: ' ',
            decimalPoint: ','
        }
    });
}

export default localizeHighcharts