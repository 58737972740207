import React from 'react';
import {DateInput} from 'semantic-ui-calendar-react';
import moment from 'moment'

const LabeledDateInput = ({label, withoutContainer, onChange, dateFormat, date, ...inputOptions}) => {
    const labeledDateInput = (<>
        <label style={{display: 'block', marginBottom: 5}}>{label}</label>
        <DateInput
            dateFormat={dateFormat}
            value={date !== undefined ? date.format(dateFormat) : undefined}
            onChange={(e, data) => {
                onChange(moment(data.value, dateFormat), e)
            }}
            {...inputOptions}
        />
    </>)

    return withoutContainer ? labeledDateInput : <div>{labeledDateInput}</div>
};
LabeledDateInput.defaultProps = {
    withoutContainer: false,
    dateFormat: "DD.MM.YYYY",
    date: {format: () => null}
}
export default LabeledDateInput;