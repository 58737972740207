import React, {useState } from 'react'
import { Modal } from 'semantic-ui-react'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import './PointModal.scss'
import DetailsTable from './DetailsTable'
import {dateFormats} from "../../../Dictionaries";
import {useHistory, useLocation} from 'react-router-dom'

const PointModal = React.memo(({isShow, onClose, details, pointName, from, to, tractionFilter, isLineChart}) => {
	const [chartDetails, setChartDetails] = useState({isShow: false})

	const location = useLocation()
	const history = useHistory()

	let failureCountChart = null
	let detailsTable = null

	const handleRowClick = (detail) => {
		const query = new URLSearchParams()
		query.set("ser", detail.kodSer)
		query.set("zns", detail.znsLoc)
		query.set("prs", detail.prsLoc)
		history.replace(`${location.pathname}?${query}`)
	}

	if (isShow) {
		if (isLineChart) {
			let detailsData = details.map((detail) => {
				return {
					x: moment(detail.date).valueOf(),
					y: detail.cnt,
					date: detail.date
				}
			})
			detailsData.sort((a,b) => {
				return a.x > b.x ? 1 : -1
			})
			let detailsOptions = Highcharts.merge(detailsChartInitOptions, {
				title: {
					text: formTitle(pointName, from, to, tractionFilter)
				},
				series: [{
					data: detailsData,
					animation: false
				}],
				plotOptions: {
					line: {
						cursor: 'pointer',
						point: {
							events: {
								click: (e) => {
									e.stopImmediatePropagation()
									let chartDetails = details.find(detail => detail.date === e.point.date)
									if (chartDetails.locs !== null) {
										setChartDetails({isShow: true, details: chartDetails.locs})
									}
								}
							}
						}
					}
				},
			})

			if (chartDetails.isShow) {
				let tableTitle = formTitle(pointName, from, to, tractionFilter)
				detailsTable = <DetailsTable details={chartDetails.details} title={tableTitle} onRowClick={handleRowClick}/>
			} else {
				detailsTable = null
				failureCountChart = (
					<HighchartsReact
						highcharts={Highcharts}
						options={detailsOptions}
					/>
				)
			}
		} else {
			if (details.length !== 0) {
				let tableTitle = formTitle(pointName, from, to, tractionFilter)
				detailsTable = <DetailsTable details={details} title={tableTitle} onRowClick={handleRowClick}/>
			}
		}
	} else { // not isShow
		failureCountChart = null
	}

	const handleCloseChart = () => {
		onClose()
	}
	const handleCloseDetails = () => {
		setChartDetails({isShow: false})
		// onClose()
	}
	return isLineChart ?
		<Modal
			open={isShow}
			className='pr-point-details-chart'
			size={'small'}
			onClose={handleCloseChart}
			basic
		>
			<Modal.Content>
				<Modal
					open={chartDetails.isShow}
					className='pr-point-details-chart'
					size={'large'}
					onClose={handleCloseDetails}
					basic
				>
					<Modal.Content>
						{detailsTable}
					</Modal.Content>
				</Modal>
				{failureCountChart}
			</Modal.Content>
		</Modal>
		:
		<Modal
			open={isShow}
			className='pr-point-details-chart'
			size={'large'}
			onClose={handleCloseChart}
			basic
		>
			<Modal.Content>
				{detailsTable}
			</Modal.Content>
		</Modal>
})
export default PointModal

const detailsChartInitOptions = {
	title: {
		text: ''
	},
	series: [{
		name: 'hello',
	}],
	chart: {
		type: 'line',
		//backgroundColor: 'rgba(0,0,0,0)',
		animation: false,
	},
	exporting: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	xAxis: {
		title: {
			text: 'Время'
		},
		type: 'datetime',
		labels: {
			formatter: function() {
				return moment(this.value).format("DD.MM");
			},
			tickInterval: 24 * 3600 * 1000 * 2, // 2 days
			rotation: 0 // -34
		},
	},
	yAxis: {
		title: {
			text: 'Количество отказов'
		},
		allowDecimals: false,
	},
	legend: {
		enabled: false
	},
	plotOptions: {
		line: {
			cursor: 'pointer',
			point: {
				events: {
					//click: (e) => {
					// Fix: Modal calls onClose when it opens
					//   https://github.com/Semantic-Org/Semantic-UI-React/issues/2493
					// e.stopImmediatePropagation()
					// return true
					//}
				}
			}
		}
	},
	tooltip: {
		formatter: function () {
			return (
				`Время: ${moment(this.x).format("DD.MM.YYYY")}<br />
				 Количество отказов: ${this.y}`
			)
		},
		// shadow: false
	}
}

const formTitle = (pointName, from, to, tractionFilter) => {
	if ((from === null) || (to === null)) {
		return 'Time was not defined'
	}
	let tractionText
	if (tractionFilter.ac && tractionFilter.dc && tractionFilter.ice) {
		tractionText = ''
	} else {
		let arr = []
		tractionFilter.ac && arr.push('Эл. переменного тока')
		tractionFilter.dc && arr.push('Эл. постоянного тока')
		tractionFilter.ice && arr.push('Тепловозы')
		tractionText = `(${arr.join(', ')})`
	}

	return `${pointName} от ${moment(from).format(dateFormats.upToMinute)} до ${moment(to).format(dateFormats.upToMinute)} ${tractionText}`
}