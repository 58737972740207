import React, { useEffect, useState } from "react";
import style from "./FilesArea.module.scss";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ekasut from "../../../../../api/ekasut";
import catchNetworkErrors from "../../../../../api/catchNetworkErrors";
import useFileDownload from "../../../../../hooks/useFileDownload";
import { Icon } from "semantic-ui-react";
import { DownloadModal } from "./DownloadModal/DownloadModal";

export const FilesArea = ({ tasksFiles, taskData, setTaskData }) => {
  console.log(tasksFiles);
  const [download, InputDownload] = useFileDownload();
  const [openDownload, setOpenDownload] = useState(false);

  const downloadFile = (fileId, name) => {
    ekasut.file
      .getFile(fileId)
      .then(({ data }) => {
        download(data, name);
      })
      .catch(catchNetworkErrors);
  };

  return (
    <div className={style.attachedFile}>
      <InputDownload />
      <DownloadModal
        setTaskData={setTaskData}
        files={tasksFiles}
        taskData={taskData}
        openDownload={openDownload}
        setOpenDownload={setOpenDownload}
      />
      {taskData.workGroupUserRoleId !== 3 ? (
        <Icon
          name={"download"}
          onClick={() => {
            setOpenDownload(true);
          }}
          className={style.downloadIcon}
        />
      ) : null}
      Прикрепленные файлы:
      {tasksFiles.length ? (
        <div>
          {" "}
          {console.log(tasksFiles, "asdas")}
          {tasksFiles.map((el) => (
            <Button
              key={el.fileId}
              style={{ textTransform: "none", marginRight: 10 }}
              color="primary"
              size="small"
              download={el.fileName}
              onClick={() => {
                downloadFile(el.fileId, el.fileName);
              }}
              target={"_blank"}
              startIcon={<SaveIcon />}
            >
              {el.fileName}
            </Button>
          ))}{" "}
        </div>
      ) : (
        <span> 0</span>
      )}
    </div>
  );
};
