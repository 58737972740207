import React from 'react'
import {Grid, Header, Modal, Segment, Statistic} from "semantic-ui-react";
import AppModal from "./AppModal";

export default function RepairPositionsModal({data, loading, ...modalProps}) {
    const stallHelper = (stalls) => {
        const stallColors = ['green', 'closed', 'grey', 'yellow', 'red'];
        return stalls.map((stall, j) => {
            if (stall.title || stall.description) {
                const prepare = stall.title.split(/\r\n/gi).map(el => <p>{el}</p>)
                const trigger = (
                    <Grid.Column
                        style={{cursor: 'pointer', fontSize: '0.75em'}}
                        color={stallColors[stall.state]}
                    >
                        {prepare}
                    </Grid.Column>
                )
                return (
                    <Modal trigger={trigger}>
                        <Modal.Header>{prepare}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {stall.description}
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>)
            } else {
                return <Grid.Column color={stallColors[stall.state]}>{stall.num}</Grid.Column>
            }
        })
    }

    const fillEmptiness = (length) => {
        let table = []

        for (let i = 0; i < length; i++) {
            table.push(<Grid.Column className='empty'>&nbsp;</Grid.Column>)
        }
        return table
    }

    return (
        <AppModal
            loading={loading}
            size={'large'}
            {...modalProps}
        >
            <Segment>
                <Grid centered padded columns='two'>
                    <Grid.Row textAlign='center'>
                        <Grid.Column width={3}>
                            <Header as='h3'>{data.depoName}</Header>
                            <Header as='h3'>Ремонтные позиции</Header>
                            <Statistic.Group>
                                <Statistic horizontal>
                                    <Statistic.Value>{data.free}</Statistic.Value>
                                    <Statistic.Label>Свободно</Statistic.Label>
                                </Statistic>
                                <Statistic horizontal>
                                    <Statistic.Value>{data.total}</Statistic.Value>
                                    <Statistic.Label>Всего</Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        </Grid.Column>
                        <Grid.Column width={13} style={{maxWidth: '1000px'}}>
                            <Grid columns='equal' className='ditches-grid'>
                                {data.ditches.map((ditch, i) =>
                                    <Grid.Row key={i} textAlign='center'>
                                        <Grid.Column textAlign='right'
                                                     style={{width: 90, padding: '14px 5px'}}>{ditch.name}</Grid.Column>
                                        {stallHelper(ditch.stalls)}
                                        {fillEmptiness(data.maxStalls - ditch.stalls.length)}
                                    </Grid.Row>
                                )}
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </AppModal>
    )
}