import React, {useEffect, useState} from 'react';
import style from './CommentBlock.module.scss'
import {Button, Divider, IconButton, TextField, Typography} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import moment from "moment";
import {Comment} from "semantic-ui-react";
import {CommentItem} from "./CommentItem/CommentItem";
import ekasut from "../../../../../api/ekasut";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

export const CommentBlock = ({taskData}) => {

        const [comments, setComments] = useState([])
        const [comText, setComText] = useState([])
        const [loading, setLoading] = useState(false)


        const sendNewComment = (comment, id) => {

            let body = id ? {
                parentId: id,
                taskId: taskData.taskId,
                content: comment
            } : {
                taskId: taskData.taskId,
                content: comment
            }
            ekasut.taskboard.postNewTaskComment(body)
                .then(({data}) => {
                    setComText('')
                    updateComments()
                })
        }

        const editCommentHandler = (comId, content) => {
            ekasut.taskboard.updateTaskComment({commentId: comId, content})
                .then(({data}) => {
                    updateComments()
                })
        }

        const deleteCommentHandler = (comId) => {
            ekasut.taskboard.deleteTaskComment({commentId: comId})
                .then(({data}) => {
                    updateComments()
                })
        }

        useEffect(() => {
            if (taskData.hasComments) {
                updateComments()
            }
        }, [])

        const updateComments = () => {
            setLoading(true)
            ekasut.taskboard.getTaskComments({workGroupId: taskData.workGroupId, taskNumber: taskData.taskNumber})
                .then(({data}) => {
                    setLoading(false)
                    setComments(data)
                })
        }


        return (
            <div className={style.commentBlock}>
                {loading ? <div className={style.comLoading}>
                    <Spin indicator={antIcon} tip='Загрузка...'/>
                </div> : null}
                {comments.length ? <div className={style.comList}>< Comment.Group>
                    {
                        comments.map(el => <CommentItem key={el.id}
                                                        {...el}
                                                        taskUserRole={taskData.workGroupUserRoleId}
                                                        deleteCommentHandler={deleteCommentHandler}
                                                        editCommentHandler={editCommentHandler}
                                                        sendComment={sendNewComment}/>)
                    }
                </Comment.Group></div> : null}
                <div className={style.mainComment}>
                    <TextField multiline
                               fullWidth
                               rows={2}
                               variant={"outlined"}
                               value={comText}
                               onChange={(e) => {
                                   setComText(e.target.value)
                               }}
                               onKeyPress={(event) => {
                                   if (event.key === 'Enter' && !event.shiftKey) {
                                       sendNewComment(comText, false)

                                   }
                               }}
                               label={'Комментарий'}/>
                    <span className={style.comDesc}>Enter - Отправить комментарий, Shift + Enter - Новая строка</span>
                    {/*<IconButton disabled={!comText.length} onClick={() => {*/}
                    {/*    sendNewComment(comText, false)*/}
                    {/*    setComText('')*/}
                    {/*}}>*/}
                    {/*    <SendIcon color={"primary"}/>*/}
                    {/*</IconButton>*/}
                </div>
            </div>
        );
    }
;