import React from 'react';
import {Dropdown} from "semantic-ui-react";

const LabeledDropdown = ({label, withoutContainer, ...dropdownOptions}) => {
    const labeledDropdown = (<>
        <label style={{display: 'block', marginBottom: 5}}>{label}</label>
        <Dropdown selection {...dropdownOptions}/>
    </>)
    return withoutContainer ? labeledDropdown : <div>{labeledDropdown}</div>
}
LabeledDropdown.defaultProps = {
    withoutContainer: false
}

export default LabeledDropdown;