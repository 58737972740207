import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Badge, Space, Table, Select, Button, Checkbox } from "antd";
import { Dropdown, Menu } from "semantic-ui-react";
import ekasut from "../../../../../api/ekasut";
import style from "../ComInspInfo.module.scss";
import { useEffect } from "react";
import moment from "moment/moment";

const RequestsToCheck = () => {

  const [dropdownValue, setDropdownValue] = useState("Приказы");
  const [ordersToRewrite, setOrdersToRewrite] = useState([]);
  const [protToRewrite, setProtToRewrite] = useState([]);
  
  const requests = async() => {
    const [orders, prots] = await Promise.all([
      ekasut.koReporst.getRewriteOrder(
        {
          pYear: localStorage.seasonYear,
          pType: localStorage.season,
          pIsRepeat: 0
        }
      ),
      ekasut.koReporst.getRwriteProt(
        {
          pYear: localStorage.seasonYear,
          pType: localStorage.season,
          
        }
      )
    ]) 

    setOrdersToRewrite(orders?.data)
    setProtToRewrite(prots?.data)
  }

  useEffect(() => {
    localStorage.setItem("seasonYear", moment(new Date()).format("YYYY"))
    localStorage.setItem("season", 2)
    requests()
  }, [])

  const setUncheckOrder = async(rgOrder) => {
    const response = await ekasut.commisionInspection.setUncheckOrder({ RGorder: rgOrder })
    requests()
  }

  const setUncheckProt = async(rgOrder) => {
    const response = await ekasut.commisionInspection.setUncheckProt({RGorder: rgOrder.value, RGsection: rgOrder.rgSec})
    requests()
  }

  const columnsProt = [
    {
      title: "Дорога",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Депо",
      dataIndex: "firmName",
      key: "firmName",
    },
    {
      title: "Серия",
      dataIndex: "tpeSerName",
      key: "tpeSerName",
    },
    {
      title: "Секция",
      dataIndex: "nomSec",
      key: "nomSec",
    },
    {
      title: "№ протокола",
      dataIndex: "numProt",
      key: "numProt",
    },
    {
      title: "Дата",
      dataIndex: "dateProt",
      key: "dateProt",
    },
    {
      title: "Отметка",
      dataIndex: "check",
      key: "check",
      render: check => (
        <Button 
          // checked={check.value} 
          onClick={() => {
            console.log(check);
            dropdownValue === "Приказы" ?
            setUncheckOrder(check.value):
            setUncheckProt(check)
          }}
        >Убрать подпись</Button>
      )
    },
  ];

  const columnsOrder = [
    {
      title: "Дорога",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Депо",
      dataIndex: "firmName",
      key: "firmName",
    },
    {
      title: "№ приказа",
      dataIndex: "numOrder",
      key: "numOrder",
    },
    {
      title: "Дата приказа",
      dataIndex: "dateOrder",
      key: "dateOrder",
    },
    {
      title: "Отметка",
      dataIndex: "check",
      key: "check",
      render: check => (
        <Button 
          // checked={check.value} 
          onClick={() => {
            console.log(check);
            dropdownValue === "Приказы" ?
            setUncheckOrder(check.value):
            setUncheckProt(check)
          }}
        >Убрать подпись</Button>
      )
    },
  ];


  const data = dropdownValue === "Приказы" ? ordersToRewrite.map((order, id) => {
    console.log(order);
    return {
      id,
      name: order.roadName,
      key: id,
      firmName: order.firmName,
      numOrder: order.numOrder,
      dateOrder: moment(order.dateOrder).format("YYYY-MM-DD"),
      check: {id: id, value: order.rgOrder, numProt: order.numProt, rgSec: order.rgSec}
    }
  }):
  protToRewrite.map((prot, id) => {
    return {
      id,
      name: prot.roadName,
      key: id,
      tpeSerName: prot.tpeSerName,
      dateProt: moment(prot.dateProt).format("YYYY-MM-DD"),
      firmName: prot.firmName,
      numProt: prot.numProt,
      nomSec: prot.nomSec,
      check: {id: id, value: prot.rgOrder, numProt: prot.numProt, rgSec: prot.rgSec}
    }
  })

  return (
    <>
      <div className={style.header}>
        <span>Списки для снятия подписей</span>
        <Menu compact>
          <Dropdown
            simple
            item
            // defaultValue={'Нарастающий отчет с начала КО'}
            // text={'Нарастающий отчет с начала КО'}
            options={[
              {
                text: "Приказы",
                value: "Приказы",
              },
              { text: "Протоколы", value: "Протоколы" },
            ]}
            value={dropdownValue}
            onChange={(e, { value }) => {
              setDropdownValue(value);
            }}
          />
        </Menu>
      </div>
      <div>
        <Table
          columns={dropdownValue === "Приказы" ? columnsOrder : columnsProt }
          dataSource={data}
        />
      </div>
    </>
  );
};
export default RequestsToCheck;
