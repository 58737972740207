import React, {useState} from 'react';
import {TextArea} from "semantic-ui-react";
import FormField from "./FormField";

const TextField = ({label, maxSymbolCount, text, onChange}) => {
    let [inText, setInText] = useState("")

    if (text !== undefined) {
        inText = text
        setInText = () => {}
    }

    return (
        <FormField label={label}>
            <TextArea
                value={inText}
                onChange={(e, {value}) => {
                    if (value.length < maxSymbolCount) {
                        setInText(value)
                        onChange(value)
                    }
                }}
            />
        </FormField>
    );
};
TextField.defaultProps = {
    maxSymbolCount: 1000
}

export default TextField;