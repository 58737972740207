import apiWorker from "../../../api/apiWorker";
import ekasut from "../../../api/ekasut";

export default function () {
  return {
    STATION_MIN_ZOOM: 9,
    TCHE_MIN_ZOOM: 6,
    SLD_MIN_ZOOM: 6,
    PIE_MIN_ZOOM: 9,
    CLUSTER_MAX_ZOOM: 9,
    // todo: wrap in options
    clusterPointsApi: apiWorker(ekasut.map.getLocomotives),
    stTcheSldApi: apiWorker(ekasut.map.getStations),
    pieApi: apiWorker(ekasut.map.getLocomotiveDetail),
  };
}
