import React from 'react';
import JustifyContainer from "../JustifyContainer";
import LabeledDateInput from "../LabeledDateInput";
import {Button} from "semantic-ui-react";
import {observer, useLocalStore} from "mobx-react-lite";

const FromToForm = observer(({initFrom, initTo, onApply, loading}) => {
    const ls = useLocalStore(() => ({
        from: initFrom,
        to: initTo,
    }))

    return (
        <JustifyContainer
            justifyContent="flex-start"
            alignItems="flex-end"
            gapRight="norm"
        >
            <LabeledDateInput
                label="Начало"
                placeholder="Дата"
                iconPosition="left"
                closable
                /*dateFormat={dateFormats.upToDay}*/
                date={ls.from}
                onChange={(date) => {
                    ls.from = date
                }}
            />
            <LabeledDateInput
                label="Окончание"
                placeholder="Дата"
                iconPosition="left"
                closable
                /*dateFormat={dateFormats.upToDay}*/
                date={ls.to}
                onChange={(date) => {
                    ls.to = date
                }}
            />
            <Button
                style={{marginBottom: 1}}
                content="Применить"
                loading={loading}
                disabled={loading}
                onClick={() => {
                    onApply(ls.from, ls.to)
                }}
            />
        </JustifyContainer>
    );
})

export default FromToForm;