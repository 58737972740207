import React from 'react';
import cn from "classnames";
import {Dropdown} from "semantic-ui-react";
import TextField from "./TextField";
import './DropdownField.scss'

const DropdownField = ({label, editable = true, noEditValue, textFieldPlaceholder, placeholder, className, fluid, dropdownClassName, ...dropdownProps}) => {

    if (!editable) {
        const isValueDefined = noEditValue !== "" && noEditValue !== null && noEditValue !== undefined
        return (
            <TextField
                label={label}
                content={isValueDefined ? noEditValue : textFieldPlaceholder}
                className={cn(className, "app-field", !isValueDefined && "text-field-placeholder")}
            />
        )
    }

    return (
        <div className={cn("app field dropdown-field", fluid && "fluid", className)}>
            <label>{label}</label>
            <Dropdown className={dropdownClassName} {...dropdownProps} selection fluid placeholder={placeholder || "Не выбрано "} />
        </div>
    );
};


export default DropdownField;