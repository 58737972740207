import React, {useState, useEffect} from 'react'
import {Map} from "react-leaflet";
import useApiMethod from "../../../hooks/useApiMethod";
import dbSync from '../../../api/dbSync'
import LayerSelector, {layerNames} from "../../../shared/LayerSelector";
import appStyles from '../../../css/App.module.scss'
import BarrierMarker from "./BarrierMarker";
import AppModal from "../../../shared/AppModal";
import DynamicTable from "./DynamicTable";
import {Header, Segment} from "semantic-ui-react";
import styles from './index.module.scss'
import LocTabs from "./LocTabs";
import Control from 'react-leaflet-control'
import FilterStatus from "./FilterStatus";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

export default function MonitorBarrierFunctions({hideSidebars}) {
    const [markers, setMarkers] = useState([])
    const [locDetailsOpen, setLocDetailsOpen] = useState(false)
    const [brigDetailsOpen, setBrigDetailsOpen] = useState(false)
    const [locServiceDetails, setLocServiceDetails] = useState(defaultDetails)
    const [locExeDetails, setLocExeDetails] = useState(defaultDetails)
    const [brigDetails, setBrigDetails] = useState(defaultDetails)
    const [roadName, setRoadName] = useState(null)
    const [statusFilter, setStatusFilter] = useState([])
    const [appliedStatusFilter, setAppliedStatusFilter] = useState([])

    const [sendGetRoadMap] = useApiMethod(dbSync.logControl.getChecksRoadMap, undefined, [])

    useEffect(() => {
        sendGetRoadMap()
            .then((data) => {
                setMarkers(data.map(road => (
                    <BarrierMarker
                        key={road.roadId}
                        position={[road.lat, road.lon]}
                        road={road}
                        locCallback={() => {
                            setLocServiceDetails(road.locServiceDetail)
                            setLocExeDetails(road.locExeplDetail)
                            setRoadName(road.roadName)
                            setStatusFilter([])
                            setAppliedStatusFilter([])
                            setLocDetailsOpen(true)
                        }}
                        brigCallback={() => {
                            setBrigDetails(road.brigDetail)
                            setRoadName(road.roadName)
                            setStatusFilter([])
                            setAppliedStatusFilter([])
                            setBrigDetailsOpen(true)
                        }}
                    />
                )))
            }).catch(catchNetworkErrors)
    }, [sendGetRoadMap])

    return (
        <div onClick={hideSidebars}>
            <Map
                className={appStyles.map}
                {...mapSettings}

            >
                <LayerSelector checkedLayerDef={layerNames.rzd}/>
                {markers}

                <Control
                    position={'topright'}
                >
                    <Segment>
                        <Header as={'h4'}>
                            Мониторинг работы барьерных функций
                        </Header>
                    </Segment>
                </Control>

                <AppModal
                    loading={false}
                    open={brigDetailsOpen}
                    onClose={() => setBrigDetailsOpen(false)}
                    size={'fullscreen'}
                    style={{top: '4%'}}
                >
                    <Segment className={styles.segmentFixedSize}>
                        <Header as={'h4'}>{roadName}</Header>
                        <FilterStatus
                            filter={statusFilter}
                            onApply={filter => setAppliedStatusFilter(filter)}
                            onChanged={(filter) => {
                                setStatusFilter(filter)
                            }}
                        />
                        <DynamicTable
                            bodyDetails={brigDetails.body}
                            headerDetails={brigDetails.header}
                            filterStatus={appliedStatusFilter}
                        />
                    </Segment>
                </AppModal>

                <AppModal
                    loading={false}
                    open={locDetailsOpen}
                    onClose={() => setLocDetailsOpen(false)}
                    size={'fullscreen'}
                    style={{top: '4%'}}
                >
                    <Segment>
                        <Header as={'h4'}>{roadName}</Header>
                        <LocTabs
                            tcheTable={
                                <>
                                    <FilterStatus
                                        filter={statusFilter}
                                        onApply={filter => setAppliedStatusFilter(filter)}
                                        onChanged={(filter) => {
                                            setStatusFilter(filter)
                                        }}
                                    />
                                    <div className={styles.tabFixedSize}>
                                        <DynamicTable
                                            headerDetails={locExeDetails.header}
                                            bodyDetails={locExeDetails.body}
                                            filterStatus={appliedStatusFilter}
                                        />
                                    </div>
                                </>
                            }
                            sldTable={
                                <>
                                    <FilterStatus
                                        filter={statusFilter}
                                        onApply={filter => setAppliedStatusFilter(filter)}
                                        onChanged={filter => {
                                            setStatusFilter(filter)
                                        }}
                                    />
                                    <div className={styles.tabFixedSize}>
                                        <DynamicTable
                                            headerDetails={locServiceDetails.header}
                                            bodyDetails={locServiceDetails.body}
                                            filterStatus={appliedStatusFilter}
                                        />
                                    </div>
                                </>
                            }
                        />
                    </Segment>
                </AppModal>
            </Map>
        </div>
    )
}
const defaultDetails = {
    header: [],
    body: []
}
const mapSettings = {
    center: [57.778288, 79.126642],
    zoom: 4,
    minZoom: 4,
    maxZoom: 16,
    zoomControl: false
}