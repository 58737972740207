import L from 'leaflet' // eslint-disable-line no-unused-vars
import 'leaflet.markercluster'
import 'leaflet-dvf'

// Most of the code taken from library
// https://github.com/humangeo/leaflet-dvf/blob/master/src/leaflet.dvf.chartmarkers.js
const fixPieChartMarker = () => {
    L.PieChartMarker = L.PieChartMarker.extend({
        _bindMouseEvents: function (chartElement) {
            var self = this;
            var tooltipOptions = this.options.tooltipOptions;

            chartElement.on('mouseover', function (e) {
                var currentOptions = this.options;
                var key = currentOptions.key;
                var value = currentOptions.value;
                var layerPoint = e.layerPoint;
                var x = layerPoint.x - this._point.x;
                var y = layerPoint.y - this._point.y;
                var iconSize = currentOptions.iconSize;
                var newX = x;
                var newY = y;
                var newPoint;
                var offset = 5;

                if (iconSize) {
                    newX = x < 0 ? iconSize.x - x + offset : -x - offset;
                    newY = y < 0 ? iconSize.y - y + offset : -y - offset;

                    newPoint = new L.Point(newX, newY);
                }

                var legendOptions = {};
                var displayText = currentOptions.displayText ? currentOptions.displayText(value) : value;

                legendOptions[key] = {
                    name: currentOptions.displayName,
                    value: displayText
                };

                var icon = new L.LegendIcon(legendOptions, currentOptions, {
                    className: 'leaflet-div-icon',
                    iconSize: tooltipOptions ? tooltipOptions.iconSize : iconSize,
                    iconAnchor: newPoint || new L.Point(-5, 0)
                });

                currentOptions.marker = new L.Marker(self._latlng, {
                    icon: icon
                });

                currentOptions = self._highlight(currentOptions);

                this.initialize(self._latlng, currentOptions);
                this.redraw();
                // comment to fix error on hover
                // this.setStyle(currentOptions);

                self.addLayer(currentOptions.marker);
            });

            chartElement.on('mouseout', function (e) {
                var currentOptions = this.options;

                currentOptions = self._unhighlight(currentOptions);

                this.initialize(self._latlng, currentOptions);
                this.redraw();
                // comment to fix error on hover
                // this.setStyle(currentOptions);

                self.removeLayer(currentOptions.marker);
            });
        }
    });
}
export default fixPieChartMarker

