import React, {useEffect, useState} from 'react'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const OrderDetails = (
    {details = {}}
) => {
    const [options, setOptions] = useState(initOptions)

    useEffect(() => {
        const categories = []
        const data = []

        categories.push(categoryNames.order)
        data.push({y: details.nzd})

        categories.push(categoryNames.brigades)
        data.push({
            y: details.zakazD,
            planGr: details.planGr,
            planGrCor: details.planGrCor,
            planDr: details.planDr,
            planViz: details.planViz
        })

        categories.push(categoryNames.pass)
        data.push({
            y: details.planPass,
        })

        categories.push(categoryNames.delivered)
        data.push({
            y: details.zasD,
            zasGr: details.zasGr,
            zasDr: details.zasDr,
            zasViz: details.zasViz
        })

        categories.push(categoryNames.left)
        data.push({
            y: details.factD,
            factGr: details.factGr,
            factDr: details.factDr,
            factPass: details.factPass
        })

        const addOptions = {
            title: {
                text: `${details.name}, Детализация выполнения заказов`
            },
            series: [{ data }],
            xAxis: { categories }
        }

        setOptions(Highcharts.merge(initOptions, addOptions))
    }, [details])
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}
export default OrderDetails
const categoryNames = {
    order: 'Наряд заказ',
    brigades: 'Потребное количество ЛБ',
    pass: 'Заявлено пасс',
    delivered: 'Заставлено',
    left: 'Уехало',
}

const initOptions = {
    chart: {
        type: 'column',
        animation: false,
    },
    title: {
        text: '',
    },
    exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    series: [],
    xAxis: {
        type: 'category',
        categories: [''],
        title: {
            text: ''
        },
        labels: {
            style: {
                fontSize: '14px'
            }
        }
    },
    yAxis: {
        allowDecimals: false,
        title: {
            text: ''
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        formatter: function(e) {
            let tooltipContent
            const options = this.point.options
            switch(this.x) {
                case categoryNames.order: {
                    tooltipContent = ''
                    break
                }
                case categoryNames.brigades: {
                    tooltipContent = `
                        Груз: ${options.planGr}<br/>
                        Груз. коррект: ${options.planGrCor}<br/>
                        В др. видах. Движ.: ${options.planDr}<br/>
                        На вызове: ${options.planViz}
                    `
                    break
                }
                case categoryNames.pass: {
                    tooltipContent = ''
                    break
                }
                case categoryNames.delivered: {
                    tooltipContent = `
                        Груз: ${options.zasGr}<br/>
                        В др. видах. Движ.: ${options.zasDr}<br/>
                        На вызове: ${options.zasViz}
                    `
                    break
                }
                case categoryNames.left: {
                    tooltipContent = `
                        Груз: ${options.factGr}<br/>
                        Груз. в др. видах . движ.: ${options.factDr}<br/>
                        Пасс.: ${options.factPass}
                    `
                    break
                }
                default: {
                    throw new Error()
                }
            }
            return (
                `<b>${this.x}: ${this.y}</b><br/> ${tooltipContent}`
            )
        }
    },
    plotOptions: {
        column: {
            animation: false,
            maxPointWidth: 40
        }
    },
}