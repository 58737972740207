import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "semantic-ui-react";
import ekasut from "../../api/ekasut";
import './style.css'
import {useHistory} from "react-router-dom";

const Notifications = ({ updateNotificationsStatus }) => {
  const history = useHistory();

  console.log(history)
  const [notificationList, setNotificationList] = useState([]);
  const [currentNotification, setCurrentNotification] = useState([]);

  useEffect(() => {
    const getChatNotificationList = async () => {
      const res = await ekasut.chats.getChatNotificationList();
      setNotificationList(res?.data);
    };

    getChatNotificationList();
  }, []);

  const updateNotificationCount = (e) => {
    console.log(e);
    updateNotificationsStatus([e]);
  };

  const updateAllNotificationCount = (e) => {
    console.log(e)
    updateNotificationsStatus(e);
  }

  return (
    <div>
      <Button className="read_all" style={{ marginLeft: '40px', marginBottom: "40px"}} onClick={() => updateAllNotificationCount(notificationList.map(e => e.taskNotificationId))}>Прочитать все</Button>
      <ul>
        {notificationList.map((e, i) => {
          return (
            <li
              className="task_element"
              key={i}
              onClick={() => {
                updateNotificationCount(e.taskNotificationId)
                history.push(e.url)
                // window.location.href = 'https://www.youtube.com/'
              }}
            >
              <div style={{ marginRight: "10px", color: '#d14c4c' }}>
                {moment(e.createDate).format("YYYY-DD-MM")}
              </div>
              <div style={{ color: '#818181' }}>{e.notificationMessage}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Notifications;
