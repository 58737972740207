import React, {useEffect, useState} from 'react';
import {Header, Button, Popup, Grid, Icon} from 'semantic-ui-react'
import styles from "./ColumnFilter.module.scss";
import {DatePicker} from "antd";
import {ConfigProvider} from "antd";
import locale from "antd/es/locale/ru_RU";
import 'moment/locale/ru';

const applyThreeHoursToDate = (initialDate) => new Date(initialDate.getTime() + Math.abs(initialDate.getTimezoneOffset() * 60000));

const ColumnFilter = ({
                          uniqueColValues = [], colName, changeSelectedRows = () => {
    }, title
                      }) => {
    const [choseValues, setChoseValues] = useState(uniqueColValues);
    const [datePickerMomentValue, setDatePickerMomentValue] = useState([]);
    const [datePickerStringValue, setDatePickerStringValue] = useState([]);
    const isDateAndTimeFormat = colName === "failDate" || colName === "txtDateNr" || colName === "dateFail";
    const isDateFormat = colName === "dateRepair" ||
        colName === "planDateRepair" || colName === "dataNr" ||
        colName === "dateKo" || colName === "dateWaitR" || colName === "dateRepairBegin" || colName === "dateRepairFinish";
    const isDataCol = isDateAndTimeFormat || isDateFormat;

    const handleToggleClick = (item) => {
        const itemIndex = choseValues.findIndex(choseItem => choseItem === item);
        if (itemIndex === -1) {
            setChoseValues([...choseValues, item]);
        } else {
            setChoseValues([...choseValues.slice(0, itemIndex), ...choseValues.slice(itemIndex + 1)]);
        }
    }
    useEffect(() => {
        if (!isDataCol) {
            changeSelectedRows(colName, choseValues);
        }
    }, [choseValues]);
    useEffect(() => {
        if (isDataCol) {
            if (datePickerStringValue.length) {
                changeSelectedRows(colName, generateNewDateCols());
            } else {
                changeSelectedRows(colName, uniqueColValues);
            }
        }

    }, [datePickerStringValue]);
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const props = isDataCol ? {
        open: isPopupOpened
    } : {};
    const generateNewDateCols = () => {
        const startDate = new Date(datePickerStringValue[0]);
        const finishDate = new Date(datePickerStringValue[1]);
        const newColValues = uniqueColValues.filter(item => {
            const itemYear = item.slice(6, 10);
            const itemMonth = item.slice(3, 5);
            const itemDay = item.slice(0, 3);
            const currentDateItem = applyThreeHoursToDate(new Date(`${itemYear}-${itemMonth}-${itemDay}`));

            return currentDateItem >= startDate && currentDateItem <= finishDate;
        })
        return newColValues;

    }
    const clearDateState = () => {
        setDatePickerMomentValue([]);
        setDatePickerStringValue([]);
    }
    return (
        <ConfigProvider locale={locale}>
            <Popup trigger={<Button compact size='small' onClick={() => setIsPopupOpened(!isPopupOpened)}>{title} <Icon
                name='filter' size={"small"}/></Button>} on='click' position='bottom center' {...props}>
                {isDataCol ? (
                    <div>
                        <div className={styles.closeButtonWrapper}>
                            <Button icon onClick={() => setIsPopupOpened(!isPopupOpened)} className={styles.closeButton}>
                                <Icon name='close'/>
                            </Button>
                        </div>
                        <div className={styles.dateWrapper}>
                            <DatePicker.RangePicker
                                placement={"topLeft"}
                                className={styles.rangePicker}
                                value={datePickerMomentValue}
                                onChange={(momentDates, stringDates) => {
                                    setDatePickerMomentValue(momentDates);
                                    setDatePickerStringValue(stringDates);
                                }}
                            />
                            <Button compact onClick={clearDateState} >
                                Сброс
                            </Button>
                        </div>
                    </div>

                ) : (
                    <div className={styles.list}>
                        {uniqueColValues.map((item) => {
                            return (
                                <Button className={styles.item} size='mini' key={item}
                                        style={{background: choseValues.includes(item) ? "#d4f5ff" : "#e7e7e7"}}
                                        onClick={() => handleToggleClick(item)}>{item}</Button>
                            );
                        })}
                    </div>
                )}
                {/*<Grid centered divided columns={3}>*/}
                {/*    <Grid.Row textAlign='center'>*/}
                {/*        <Grid.Column textAlign='center'>*/}
                {/*            <Header as='h4'>Basic Plan</Header>*/}
                {/*            <p>*/}
                {/*                <b>2</b> projects, $10 a month*/}
                {/*            </p>*/}
                {/*            <Button>Choose</Button>*/}
                {/*        </Grid.Column>*/}
                {/*    </Grid.Row>*/}
                {/*    <Grid.Column textAlign='center'>*/}
                {/*        <Header as='h4'>Business Plan</Header>*/}
                {/*        <p>*/}
                {/*            <b>5</b> projects, $20 a month*/}
                {/*        </p>*/}
                {/*        <Button>Choose</Button>*/}
                {/*    </Grid.Column>*/}
                {/*    <Grid.Column textAlign='center'>*/}
                {/*        <Header as='h4'>Premium Plan</Header>*/}
                {/*        <p>*/}
                {/*            <b>8</b> projects, $25 a month*/}
                {/*        </p>*/}
                {/*        <Button>Choose</Button>*/}
                {/*    </Grid.Column>*/}
                {/*</Grid>*/}
            </Popup>
        </ConfigProvider>
    );
};

export default ColumnFilter;