import {useLocalStore} from "mobx-react-lite";
import moment from 'moment'
import {addFetchFunctional, getDecadeEnd, getDecadeStart} from "../../../helpers/utils";
import ekasut from '../../../api/ekasut'

export default function () {

    const s = useLocalStore(() => ({
        depotDecadeForm: {
            tche: {id: null, name: "Не выбрано"},
            sld: {id: null, name: "Не выбрано"},
            date: getDecadeStart(moment()),
            isValid() {
                return this.tche.id !== null && this.sld.id !== null && this.date.isValid()
            }
        },
        plan: {
            tche: {id: null, name: "Не выбрано"},
            sld: {id: null, name: "Не выбрано"},
            startDate: getDecadeStart(moment()),
            endDate: getDecadeEnd(moment()),
            selected: null,
            selectedRow: null,
            isValid() {
                return this.tcheSldIsValid() && this.datesIsValid()
            },
            tcheSldIsValid() {
                return this.tche.id !== null && this.sld.id !== null
            },
            datesIsValid() {
                return this.startDate.isValid() && this.endDate.isValid()
            }
        },
        refreshTable: () => {
            s.plan.selected = null
            s.plan.selectedRow = null
            s.plan.tche = {...s.depotDecadeForm.tche}
            s.plan.sld = {...s.depotDecadeForm.sld}
            s.plan.startDate = moment(s.depotDecadeForm.date)
            s.plan.endDate = getDecadeEnd(moment(s.depotDecadeForm.date))
            s.fetchLocomotives(
                s.depotDecadeForm.date,
                s.depotDecadeForm.tche.id,
                s.depotDecadeForm.sld.id,
                false // isApproved
            )
        },
        showEditModal: false,
        showAddModal: false,
        showApprovePlanModal: false,
        ...addFetchFunctional("tcheOptions", ekasut.ekasutData.getCtTree,
            {defValue: [], parser: ({data}) => filterDepotLevel(2, data)}),
        ...addFetchFunctional("sldOptions", ekasut.ekasutData.getServiceTree,
            {defValue: [], parser: ({data}) => filterDepotLevel(1, data)}),
        ...addFetchFunctional("locomotives", ekasut.planRepairs.getLocomotives,
            {defValue: {locomotives: []}, parser: parseLocomotives}),
        ...addFetchFunctional("approvedLocomotives", ekasut.planRepairs.getLocomotives,
            {defValue: {locomotives: []}, parser: parseLocomotives}),
        ...addFetchFunctional("excel", ekasut.planRepairs.getLocomotivesExcel, {parser: res => res}),
        ...addFetchFunctional("repairTypes", ekasut.ekasutData.getRepairTypes, {defValue: []}),
        ...addFetchFunctional("series", ekasut.locomotivesNsi.getSerTps, {defValue: []}),
        ...addFetchFunctional("searchLocomotives", ekasut.planRepairs.getSearchLocomotives, {defValue: []}),
        ...addFetchFunctional("addLocomotives", ekasut.planRepairs.putLocomotive),
        ...addFetchFunctional("addLocomotive", ekasut.planRepairs.postLocomotive),
        ...addFetchFunctional("removeLocomotive", ekasut.planRepairs.deleteLocomotive),
        ...addFetchFunctional("setPlanStatus", ekasut.planRepairs.postPlanStatus),

    }))
    return s
}

const parseLocomotives = (res) => {
    if (res.status === 204) {
        return {
            locomotives: [],
            statusId: 0
        }
    }
    if (res.data.statusId === null) {
        res.data.statusId = 0
    }
    return res.data
}

const filterDepotLevel = (level, list) => {
    const filteredList = []
    list.forEach(item => {
        if (item.level === level) {
            filteredList.push(item)
        }
    })
    return filteredList
}
