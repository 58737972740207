// eslint-disble
import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Table, Header} from "semantic-ui-react";
import styles from "../TableFirstCard/TableFirstCard.module.scss";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {replaceNullStateWithNoDataString} from "../TableFourthCard/TableFourthCard";
import {filterData, sortReducer} from "../TableFirstCard/TableFirstCard";

const TableFirstCardDay = ({data: tableData}) => {
    const [state, dispatch] = useReducer(sortReducer, {
        column: null,
        data: replaceNullStateWithNoDataString(tableData),
        direction: null,
    });
    const { column, data, direction } = state;
    const [filteredData, setFilteredData] = useState(data);



    const filterData1 = useMemo(() => filterData(data, "roadName"), []);
    const filterData2 = useMemo(() => filterData(data, "depoName"), []);
    const filterData3 = useMemo(() => filterData(data, "serName"), []);
    const filterData4 = useMemo(() => filterData(data, "nomSec"), []);
    const filterData5 = useMemo(() => filterData(data, "idKind"), []);
    const filterData6 = useMemo(() => filterData(data, "dateFail"), []);
    const filterData7 = useMemo(() => filterData(data, "objectName"), []);
    const filterData8 = useMemo(() => filterData(data, "reasonName"), []);
    const filterData9 = useMemo(() => filterData(data, "firmNameRepair"), []);
    const filterData10 = useMemo(() => filterData(data, "remName"), []);
    const filterData11 = useMemo(() => filterData(data, "dateRepair"), []);

    const [selectedRowsInFilters, setSelectedRowsInFilters] = useState({
        roadName: filterData1,
        depoName: filterData2,
        serName: filterData3,
        nomSec: filterData4,
        idKind: filterData5,
        dateFail: filterData6,
        objectName: filterData7,
        reasonName: filterData8,
        firmNameRepair: filterData9,
        remName: filterData10,
        dateRepair: filterData11,
    });

    useEffect(() => {
        // if (filteredData !== data) {
        //     setFilteredData(data);
        // }
        const newFilteredData = data
            .filter(item => selectedRowsInFilters.roadName.includes(item.roadName))
            .filter(item => selectedRowsInFilters.depoName.includes(item.depoName))
            .filter(item => selectedRowsInFilters.serName.includes(item.serName))
            .filter(item => selectedRowsInFilters.nomSec.includes(item.nomSec))
            .filter(item => selectedRowsInFilters.idKind.includes(item.idKind))
            .filter(item => selectedRowsInFilters.dateFail.includes(item.dateFail))
            .filter(item => selectedRowsInFilters.objectName.includes(item.objectName))
            .filter(item => selectedRowsInFilters.reasonName.includes(item.reasonName))
            .filter(item => selectedRowsInFilters.firmNameRepair.includes(item.firmNameRepair))
            .filter(item => selectedRowsInFilters.remName.includes(item.remName))
            .filter(item => selectedRowsInFilters.dateRepair.includes(item.dateRepair));

        setFilteredData(newFilteredData);
    }, [data, selectedRowsInFilters]);

    const changeSelectedRows = (colName, newData) => {
        setSelectedRowsInFilters(prevState => ({
            ...prevState,
            [colName]: newData,
        }));
    }
    return (
        <div>
            <Header as='h2'>Количество отказов после проведения комиссионного осмотра за сутки</Header>
            <div>
                <Header as='h3'>Фильтрация</Header>
                <div style={{display: "flex", gap: "5px"}} className={styles.filtersWrapper}>
                    <ColumnFilter colName='roadName' title="Дорога" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData1}/>
                    <ColumnFilter colName='depoName' title="Депо" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData2}/>
                    <ColumnFilter colName='serName' title="Серия" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData3}/>
                    <ColumnFilter colName='nomSec' title="Номер" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData4}/>
                    <ColumnFilter colName='idKind' title="Категория отказа" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData5}/>
                    <ColumnFilter colName='dateFail' title="Дата отказа" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData6}/>
                    <ColumnFilter colName='objectName' title="Группа оборудования" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData7}/>
                    <ColumnFilter colName='reasonName' title="Наименование отказа" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData8}/>
                    <ColumnFilter colName='firmNameRepair' title="Предприятие ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData9}/>
                    <ColumnFilter colName='remName' title="Ремонт" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData10}/>
                    <ColumnFilter colName='dateRepair' title="Дата ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData11}/>
                </div>
            </div>

            <Table celled>
                <Table.Header className={styles.header}>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell
                            sorted={column === 'roadName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'roadName' })}
                        >Дорога приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'firmPrip' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmPrip' })}
                        >Депо приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'serName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serName' })}
                        >Серия</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'nomSec' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nomSec' })}
                        >Номер</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'idKind' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'idKind' })}
                        >Категория отказа</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'dateFail' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateFail' })}
                        >Дата отказа</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'objectName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'objectName' })}
                        >Группа оборудования</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'reasonName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'reasonName' })}
                        >Наименование отказа</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'firmNameRepair' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmNameRepair' })}
                        >Предприятие ремонта</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'remName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'remName' })}
                        >Ремонт</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'dateRepair' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateRepair' })}
                        >Дата ремонта</Table.HeaderCell>
                        {/* <Table.HeaderCell>
                Отметка о проведении <br /> цикловых работ
              </Table.HeaderCell> */}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {filteredData?.map((item, index) => (
                        <Table.Row
                            key={index}
                            textAlign={"center"}
                            onClick={() => {

                            }}
                        >
                            <Table.Cell>{item.roadName}</Table.Cell>
                            <Table.Cell>{item.depoName}</Table.Cell>
                            <Table.Cell>{item.serName}</Table.Cell>
                            <Table.Cell>{item.nomSec}</Table.Cell>
                            <Table.Cell>{item.idKind}</Table.Cell>
                            <Table.Cell>{item.dateFail}</Table.Cell>
                            <Table.Cell>{item.objectName}</Table.Cell>
                            <Table.Cell>{item.reasonName}</Table.Cell>
                            <Table.Cell>{item.firmNameRepair}</Table.Cell>
                            <Table.Cell>{item.remName}</Table.Cell>
                            <Table.Cell>{item.dateRepair}</Table.Cell>

                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TableFirstCardDay;