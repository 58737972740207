import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
// todo: port from decade to operative
import AddRepair from '../../components/AddRepair'
import {useStore} from '../StoreProvider'
import {Modal} from 'semantic-ui-react'
import catchNetworkErrors from "../../../../api/catchNetworkErrors";

const AddPlanModal = observer(() => {
    const store = useStore()

    useEffect(() => {
        if (store.showAddModal) {
            store.fetchRepairTypesIfNeed()
            store.fetchSeriesIfNeed()
        }
    }, [store, store.showAddModal])

    const refreshPlan = () => {
        store.showAddModal = false
        store.fetchPlanAndLocomotives(store.tche.id, store.startDate)
    }

    return (
        <Modal
            open={store.showAddModal}
            closeIcon style={{top: '14%'}}
            onClose={() => {
                store.showAddModal = false
            }}

        >
            <Modal.Content>
                <Modal.Description>
                    <AddRepair
                        tche={store.tche}
                        sld={store.sld}
                        initialDate={store.initialDate}
                        maxDate={store.endDate}
                        minDate={store.startDate}
                        repairTypes={store.repairTypes.map(t => ({text: t.text, id: t.key}))}
                        series={store.series.map(s => ({text: s.text, id: s.key}))}
                        locomotives={store.searchLocomotives}
                        locomotivesLoading={store.searchLocomotivesLoading}
                        searchLocomotives={(seriesId, locNumber, sectionsCount, tcheId) => {
                            store.fetchSearchLocomotives(seriesId, locNumber, sectionsCount, tcheId)
                        }}
                        addToPlan={(tche, sld, locs, repairType, series, secCount, date) => {
                            const locoData = {
                                planDate: store.startDate.format("YYYY-MM-DD"),
                                repairTypeId: repairType.id,
                                secCount: repairType.id !== 15 ? 0 : secCount,
                                planRepairPredKod: sld.id,
                                locomotives: repairType.id !== 15 ?
                                    locs.map(loc => ({
                                        repDate: date.format("YYYY-MM-DDTHH:mm"),
                                        locSer: loc.serLoc,
                                        locNum: loc.znsLoc,
                                        locSec: loc.prsLoc
                                    })) : [{
                                        repDate: date.format("YYYY-MM-DDTHH:mm"),
                                        locSer: series.id,
                                        locNum: 0,
                                        locSec: 0
                                    }]
                            }
                            store.fetchAddLocomotives(tche.id, sld.id, locoData)
                                .then(() => refreshPlan()).catch(catchNetworkErrors)
                        }}
                        addToPlanLoading={store.addLocomotivesLoading}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
})

export default AddPlanModal