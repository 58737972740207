import {Button, Icon} from "semantic-ui-react";
import React from "react";
import styles from './ButtonFiles.module.scss'
import cn from 'classnames'
import LabelAndButton from "./LabelAndButton";

/*
files: [
    {
        name,
        loading,
        key,
        onClose
    },
    ...
]
 */
const ButtonFiles = ({files, onChange, openFile, displayAttachButton, error, onClose}) => {
    return (
        <>
            {files.map((file, index) => {
                const {name, loading, key, faint} = file

                return (
                    <LabelAndButton
                        key={key}
                        text={name}
                        icon="close"
                        loading={loading}
                        style={{opacity: faint ? 0.3 : 1}}
                        onClick={() => {
                            if (!loading) {
                                onClose(file, index)
                            }
                        }}
                    />
                )
            })}
            <div/>

            <Button.Group basic className={cn(styles.attachFiles, error && styles.errorButton)}>
                <Button
                    icon labelPosition='left'
                    disabled={!displayAttachButton}
                    onClick={() => {
                        openFile()
                    }}
                >
                    <Icon name='attach'/>
                    Прикрепить документ
                </Button>
            </Button.Group>

        </>
    )
}
export default ButtonFiles
ButtonFiles.defaultProps = {
    openFile: () => {
    }
}