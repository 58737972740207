import React, { useEffect, useMemo, useState } from "react";
import { Form, Button, Icon, Message } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { isConservationFieldValid } from "../locomotivesConservationUtils";
import {
  conservationSelectError,
  conservationNumberError,
  conservationStringError,
  currentConservationDepartmentName,
  currentConservationMovingTypeName,
  currentConservationPullTypeName,
  currentConservationPurposeName,
  currentConservationQuantityName,
} from "../locomotivesConservationConstants";
import locomotivesConservationStore from "../locomotivesConservationStore";
import ekasut from "../../../api/ekasut";

const { Select, Input, Group, Dropdown } = Form;

const ConservationOrdersAddingRow = observer(
  ({ groupValues, setGroupValues, needRenderLabel }) => {
    const {
      currentDepartment,
      currentQuantity,
      currentMovingType,
      currentPullType,
      currentPurpose,
    } = groupValues;
    console.log(currentDepartment);
    const {
      departmentList,
      movementTypeList,
      pullTypeList,
      isDepartmentListLoading,
      isMovementTypeListLoading,
      isPullTypeListLoading,
    } = locomotivesConservationStore;

    const rebuildedDepartmentList = useMemo(() => {
      const isAccessLevel7 = localStorage.accessLevel === "7"; // ТЧэ
      const isAccessLevel1 = localStorage.accessLevel === "1"; // ЦТ
      const newDepartmentList = [];
      if (isAccessLevel7) {
        for (const department of departmentList) {
          const className = department.children.length
            ? "root-pred"
            : "child-pred-" + 0;

          newDepartmentList.push({
            value: department.predId,
            key: department.predId,
            text: department.name,
            disabled: true,
            className,
          });
          for (const childDepartment of department.children) {
            const className = childDepartment.children.length
              ? "root-pred"
              : "child-pred-" + 1;

            newDepartmentList.push({
              value: childDepartment.predId,
              key: childDepartment.predId,
              text: childDepartment.name,
              className,
            });
          }
        }
      }
      if (isAccessLevel1) {
        const ourDirectionPredId = +localStorage.predId;
        const ourDirection = departmentList.find(
          ({ predId }) => predId === ourDirectionPredId
        );
        if (ourDirection?.children?.length) {
          newDepartmentList.push(...ourDirection.children);
        }
      }
      return newDepartmentList;
    }, [departmentList]);
    const rebuildedMovementTypeList = useMemo(
      () =>
        movementTypeList.map((item) => ({
          key: item.code,
          value: item.code,
          text: item.name,
        })),
      [movementTypeList]
    );
    const rebuildedPullTypeList = useMemo(
      () =>
        pullTypeList.map((item) => ({
          key: item.code,
          value: item.code,
          text: item.name,
        })),
      [pullTypeList]
    );

    const onChange = (name, value) => {
      console.log(name, value);
      setGroupValues((prevGroupState) => ({
        ...prevGroupState,
        [name]: {
          value,
          isError: !isConservationFieldValid(name, value),
        },
      }));
    };

    const [orderData, setOrderData] = useState({
      predId: null,
      vdvingTypeId: null,
      tractionTypeId: null,
    });
    const [orderFieldsCorrect, setOrderFieldsCorrect] = useState(true);

    const checkAvailableLoco = async () => {
      try {
        const data = await ekasut.locomotivesConservation.checkAvailableLoco({
          predId: orderData.predId,
          vdvingTypeId: orderData.vdvingTypeId,
          tractionTypeId: orderData.tractionTypeId,
        });
        console.log(data);
        data.data ? setOrderFieldsCorrect(true) : setOrderFieldsCorrect(false);
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      console.log(orderData);
      orderData.tractionTypeId && checkAvailableLoco();
    }, [orderData.tractionTypeId]);

    return (
      <Group>
        <Select
          value={currentDepartment.value}
          options={rebuildedDepartmentList}
          loading={isDepartmentListLoading}
          search
          label={needRenderLabel ? "Подразделение" : null}
          placeholder="Подразделение"
          width={3}
          error={currentDepartment.isError ? conservationSelectError : false}
          onChange={(e, { value }) => {
            onChange(currentConservationDepartmentName, value);
            setOrderData({ ...orderData, predId: value });
          }}
        />
        {console.log(rebuildedDepartmentList)}
        <Input
          label={needRenderLabel ? "Количество" : null}
          placeholder="Количество"
          width={2}
          error={currentQuantity.isError ? conservationNumberError : false}
          value={currentQuantity.value}
          onChange={(e, { value }) =>
            onChange(currentConservationQuantityName, value)
          }
        />
        <Select
          value={currentMovingType.value}
          options={rebuildedMovementTypeList}
          loading={isMovementTypeListLoading}
          search
          disabled={orderData.predId ? false : true}
          label={needRenderLabel ? "Вид движения" : null}
          placeholder="Вид движения"
          width={3}
          error={!orderFieldsCorrect && "Не совпадает с видом тяги"}
          onChange={(e, { value }) => {
            onChange(currentConservationMovingTypeName, value);
            setOrderData({ ...orderData, vdvingTypeId: value });
          }}
        />
        <Select
          value={currentPullType.value}
          options={rebuildedPullTypeList}
          loading={isPullTypeListLoading}
          search
          disabled={orderData.vdvingTypeId ? false : true}
          label={needRenderLabel ? "Вид тяги" : null}
          placeholder="Вид тяги"
          width={3}
          error={!orderFieldsCorrect && "Не совпадает с видом движения"}
          onChange={(e, { value }) => {
            onChange(currentConservationPullTypeName, value);
            setOrderData({ ...orderData, tractionTypeId: value });
          }}
        />
        {console.log(orderFieldsCorrect)}
        <Input
          label={needRenderLabel ? "Причина" : null}
          placeholder="Причина"
          width={5}
          error={currentPurpose.isError ? conservationStringError : false}
          value={currentPurpose.value}
          onChange={(e, { value }) =>
            onChange(currentConservationPurposeName, value)
          }
        />
      </Group>
    );
  }
);

export default ConservationOrdersAddingRow;
