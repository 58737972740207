import React from 'react';
import {observer} from "mobx-react-lite";
import {Message, Table} from "semantic-ui-react";
import moment from "moment";
import styles from './ElectronicPassport.module.scss'
import {dateFormats} from "../../Dictionaries";

const ElectronicPassport = observer(({tab}) => {
    const getDeviceClass = (lvl) => {
        if (lvl === 1) {
            return styles.lvl1
        }
        if (lvl === 2) {
            return styles.lvl2
        }
        if (lvl === 3) {
            return styles.lvl3
        }
    }

    return tab.electricPassportMessage ? <Message content={tab.electricPassportMessage}/> : (
        <Table celled structured striped className="compact-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">Наименование</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">№</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Вид ремонта</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Дата посл. рем.</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Предприятие ремонта</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {tab.electricPassport.map((device, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>
                            <div className={getDeviceClass(device.lvl)}>
                                {device.objectNom}
                            </div>
                        </Table.Cell>
                        <Table.Cell>{device.objNom}</Table.Cell>
                        <Table.Cell textAlign="center">{device.sNazvanie}</Table.Cell>
                        <Table.Cell textAlign="center">
                            {device.remVpskDate === null ? null : moment(device.remVpskDate).format(dateFormats.upToDay)}
                        </Table.Cell>
                        <Table.Cell>{device.prDprName}</Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
})

export default ElectronicPassport;