import React, {useEffect, useState} from 'react';
import ekasut from "../../../../../api/ekasut";
import {Table} from "antd";

export const ManagementRightsTab = ({id}) => {

    const [data, setData] = useState([]);

    const [loadingData, setLoadingData] = useState(false);

    const columns = [
        {dataIndex: 'pravoStr', title: 'Тип прав управления',},
        {dataIndex: 'giveDatePravo', title: 'Дата выдачи',},
    ]


    useEffect(() => {
        setLoadingData(true)
        ekasut.persData.getPersonLicenses(id).then(r => {
            setData(r.data)
            setLoadingData(false)
        }).catch(err => {
            console.log(err)
            setLoadingData(false)
        })
    }, [])
    return (
        <Table loading={loadingData}
               columns={columns}
               dataSource={data}
               pagination={false}
               scroll={{x: 1200, y: 400}}
        />
    )
}