import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './RoadMaintenanceChart.scss'
import ekasut from '../../../api/ekasut'
import moment from 'moment'
import { Modal } from 'semantic-ui-react'
import FreightPerHour from './FreightPerHour'
import locomotiveServices from '../LocomotiveServices';
import PassengerPerHour from './PassengerPerHour';
import SwitcherPerHour from './SwitcherPerHour'
import { appendPlusToPositiveNumber } from '../../../helpers/utils'

export default class RoadMaintenanceChart extends Component {
	state = {
		isShowModal: false,
		selectedHistoryIndex: -1,
	}

	isFirst = true

	constructor(props) {
		super(props)
		const me = this
		this.historyChartOptions = { 
			title: {
				text: ''
			},
			series: [{
				name: 'План ЭП',
				data: [],
				visible: false,		
				color: '#808080'
			},
			{
				name: 'Факт ЭП',
				data: [],
				visible: false,
				color: '#00C60D'
			},
			{
				name: 'Факт РП',
				data: [],
				visible: false,
				color: '#0026FF'
			},
			{
				name: 'ЭП / РП',
				data: [],
				visible: true,
				color: '#FF0000'
			},
			{
				name: 'Факт НЭП',
				data: [],
				visible: false,
				color: '#FF006E'
			}],
			chart: {
				type: 'line',
				backgroundColor: 'rgba(0,0,0,0)',
			},
			exporting: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			xAxis: {
				title: {
					text: 'Время'
				},
				type: 'datetime',
				labels: {
					formatter: function() {
						return moment(this.value).format('HH:mm');
					},
					rotation: 0 // -34
				},
			},
			yAxis: {
				title: {
					text: 'Тяговые единицы'
				},
	
			},
			plotOptions: {
				series: {
					visible: false,
				},
				line: {
					cursor: 'pointer',
					point: {
						events: {
							click: (e) => {
								// Fix: Modal calls onClose when it opens
								//   https://github.com/Semantic-Org/Semantic-UI-React/issues/2493
								e.stopImmediatePropagation()
								// don't open modal if locomotive series was clicked
								if(e.point.series.index < 5) {
									me.setState({
										isShowModal: true,
										selectedHistoryIndex: e.point.index,
										selectedTimeMs: e.point.x
									})
								}
							}
						}
					}
				}
			},
			tooltip: {
				// backgroundColor: 'rgba(255,255,255,.5)',
				// borderColor: '#afa',
				// borderWidth: 3,
				formatter: function () {
					return (
						`<b>${this.series.name}: ${this.y}</b><br />
						 Время: ${moment(this.x).format('DD.MM.YYYY HH:mm')} <br />
						 Изменение ЭП за час: ${appendPlusToPositiveNumber(this.point.epDiff)} <br />
						 Изменение РП за час: ${appendPlusToPositiveNumber(this.point.rpDiff)} <br />
						 Изменение НЭП за час: ${appendPlusToPositiveNumber(me.props.service === locomotiveServices.FREIGHT ? this.point.kol31Diff : this.point.kol30Diff)} <br />`
					)
				},
				shadow: false
			}
		}
	}

	

	getSubtitle = (service) => {
		var n = -1
		if (service === locomotiveServices.FREIGHT) {
			n = 32
		} else if (service === locomotiveServices.PASSENGER) {
			n = 38
		} else if (service === locomotiveServices.SWITCHING) {
			n = 36
		}
		return  `<span style="font-size: 14px;">По данным ТО-${n} ВЦ</span>`
	}

	getTitle = (roadName, isRoad) => {
		let title
		if (roadName === undefined) {
			title = 'Содержание парка локомотивов'	
		} else {
			if (isRoad) {
				title = `Содержание дороги: ${roadName}`
			} else {
				title = `Содержание депо: ${roadName}`
			}
		}
		return title
	}

	getServiceName(service, v) {
		if (service === locomotiveServices.FREIGHT) {
			return v === 0 ? 'грузовых' : 'грузовые'
		}
		if (service === locomotiveServices.PASSENGER) {
			return v === 0 ? 'пассажирских' : 'пассажирские'
		}
		if (service === locomotiveServices.SWITCHING) {
			return v === 0 ? 'маневровых' : 'маневровые'
		}
	}

	getFilterText(filter) {
		if (filter.ac && filter.dc && filter.ice) {
			return ''
		} else {
			const filterTextList = []
			if (filter.ice) {
				filterTextList.push('тепловозы')
			}
			if (filter.ac) {
				filterTextList.push('эл. переменного тока')
			}
			if (filter.dc) {
				filterTextList.push('эл. постоянного тока')
			}
			if (filter.acDc) {
				filterTextList.push('эл. двойного питания')
			}
			return `(${filterTextList.join(', ')})`
		}
	}

	getModalHeader = (roadName, isRoad, ms, service, filter) => {
		let date = moment(ms).format('DD:MM:YYYY HH:mm')

		let name
		if (roadName === null) {
			name =`По сети`
		} else {
			name = roadName
		}
		// например: грузовых
		const usesText = this.getServiceName(service, 0)

		return `${name}. Содержание ${usesText} локомотивов за прошедший час, на ${date} ${this.getFilterText(filter)}`
	}

	updateHistoryChartOptions = (history, service, tractionFilter) => {
		// We need to allow highcharts at first to load the predefined chart options
		// Just for the visible flag working correctly...
		if (this.isFirst) {
			this.isFirst = false
			return 
		}

		function createSeriesPoint(x, y, el) {
			return {
				x, y,
				epDiff: el.epDiff,
				rpDiff: el.rpDiff,
				kol31Diff: el.kol31Diff,
				kol30Diff: el.kol30Diff
			}
		}

		let series =  []
		for (let i = 0; i < 5; i++) {
			series.push({data: []})
		}

		history.forEach((el) => {
			let time = moment(el.date).valueOf()
			series[0].data.push(createSeriesPoint(time, el.epPlan, el))
			series[1].data.push(createSeriesPoint(time, el.ep, el))
			series[2].data.push(createSeriesPoint(time, el.rp, el))
			series[3].data.push(createSeriesPoint(time, el.epToRp, el))
			series[4].data.push(createSeriesPoint(time, service === locomotiveServices.FREIGHT ? el.kol31 : el.kol30, el))
		})

		// Add locomotives group series
		const onlyOneFilterIsSelected = (filter) => {
			let count = 0
			if (filter.ac) {
				count++
			}
			if (filter.dc) {
				count++
			}
			if (filter.ice) {
				count++
			}
			return count === 1
		}

		const getFilteredLocomotivesMap = (tipElektr, isEnableFilter) => {
			let map = new Map()
			history.forEach((historyMoment) => {
				historyMoment.dataByUkrGroupSer.forEach((locomotive) => {
					if (!isEnableFilter || (locomotive.tipElektr === tipElektr)) {
						// some locomotive names are just white spaces, in this case i will use series number
						let locomotiveName = locomotive.ukrNameGroupSer.trim().length === 0 ? locomotive.ukrGroupSer : locomotive.ukrNameGroupSer.trim()

						if (!map.has(locomotiveName)) {
							map.set(locomotiveName, [])
						}
						locomotive.ms = moment(historyMoment.date).valueOf()
						map.get(locomotiveName).push(locomotive)
					}
				})
			})
			return map
		}

		if (onlyOneFilterIsSelected(tractionFilter)) {
			let selectedTipElektr = ekasut.convertTractionFilterToTypes(tractionFilter)[0]
			let map = getFilteredLocomotivesMap(selectedTipElektr, (service !== locomotiveServices.SWITCHING))

			// Sort history 
			map.forEach((locomotiveHistory) => {
				locomotiveHistory.sort((a,b) => {
					return a.ms - b.ms 
				})
			})

			map.forEach((locomotiveHistory, locomotiveName) => {
				let data = []
				locomotiveHistory.forEach((moment) => {
					data.push(createSeriesPoint(moment.ms, moment.epToRp, moment))
				})
				series.push({
					name: locomotiveName,
					data: data,
				})
			})
		}

		// Using merge, not just manualy change options, otherwise highcharts will not detect changes
		this.historyChartOptions = Highcharts.merge(this.historyChartOptions, {series})
	}

	loadRoadChart = (actual, dorKod, tipElektr) => {
		ekasut.getEpRoad(actual, dorKod, tipElektr)
			.then(response => {
				this.updateChartRoad(response.data)
			})
	}

	updateChartRoad = (data) => {
		if (data.length === 0) {
			return
		}

		var locomotiveNamesMap = new Map()

		for (let i = 0; i < data.length; i++) {
			for (let j = 0; j < data[i].dataByUkrGroupSer.length; j++) {
				var locomotive = data[i].dataByUkrGroupSer[j]

				if (!locomotiveNamesMap.has(locomotive.groupName)) {
					locomotiveNamesMap.set(locomotive.groupName, [])
				}
				
				locomotiveNamesMap.get(locomotive.groupName).push({
					x: moment(data[i].date).valueOf(),
					y: locomotive.epToRp, 
					ep: locomotive.ep,
					rp: locomotive.rp
				})
			}
		}

		var series = []
		locomotiveNamesMap.forEach((value, key) => {
			series.push({
				name: key,
				data: value
			})
		})

		this.roadChartOptions = this.getRoadChartOptions(data[0].dorName, series)
	}

	modalCloseHandler = () => {
		this.setState({
			isShowModal: false,
		})
	}

	componentDidMount() {
	}

	render() {
		const { history, roadName, isRoad, service, tractionFilter } = this.props
		this.updateHistoryChartOptions(history, service, tractionFilter)

		const modalContentStats = history.find(el => moment(el.date).valueOf() === this.state.selectedTimeMs)
		let modalContent
		if (service === locomotiveServices.FREIGHT) {
			modalContent = <FreightPerHour stats={modalContentStats}/>
		} else if (service === locomotiveServices.PASSENGER) {
			modalContent = <PassengerPerHour stats={modalContentStats}/>
		} else if (service === locomotiveServices.SWITCHING) {
			modalContent = <SwitcherPerHour stats={modalContentStats}/>
		}

		return (
			<div className='road-maintenance-chart'>
				<HighchartsReact
					highcharts={Highcharts}
					options={this.historyChartOptions}
				/>

				<Modal open={this.state.isShowModal} onClose={this.modalCloseHandler} className='road-maintenance-chart-modal'>
					<Modal.Header>
						{this.getModalHeader(roadName, isRoad,  this.state.selectedTimeMs, service, tractionFilter)}
					</Modal.Header>
					<Modal.Content>
						{modalContent}
					</Modal.Content>
				</Modal>
			</div>
		)
	}
}

