import {useEffect, useState} from 'react'

export default function useMapZoom(defZoom, mapRef) {
    const [zoom, setZoom] = useState(defZoom)

    useEffect(() => {
        if (mapRef === null) {
            return
        }
        mapRef.leafletElement.on('zoomend', () => {
            setZoom(mapRef.leafletElement.getZoom())
        })
        return () => {
            mapRef.leafletElement.off('zoomend')
        }
    }, [mapRef])

    return [zoom]
}
