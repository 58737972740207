import auth from "../../helpers/auth";

const unauthorizedAccess = (error) => {
  if (
    error.response &&
    ((error.response.data &&
      error.response.data["$type"] &&
      error.response.data["$type"].includes("UnauthorizedAccessException")) ||
      error.response.status === 401)
  ) {
    auth.logout();

    if (error.response.data["$type"].includes("UnauthorizedAccessException")) {
      Location.reload();
    }
  }

  return Promise.reject(error);
};

export default unauthorizedAccess;
