import React, {useState} from 'react';
import {Button, Icon, Modal} from "semantic-ui-react";
import InputField from "../../../../shared/fields/InputField";
import styles from "../OrganizationInspection.module.scss";

const AddDocumentChanges = ({addChanges, setAddChanges}) => {
    return (
        <Modal
            className={styles.addModal}
            onClose={() => setAddChanges(false)}
            onOpen={() => setAddChanges(true)}
            open={addChanges}
            size={'mini'}>
            <Modal.Header>Добавление изменения</Modal.Header>
            <Modal.Content>
                <InputField
                    placeholder={'Введите дату документа'}
                    label="Дата документа" fluid
                    onChange={(e, {value}) => {
                    }}
                />
                <InputField
                    placeholder={'Введите номер документа'}
                    label="Номер документа" fluid
                    onChange={(e, {value}) => {
                    }}
                />
                <Button className="button-link" basic>
                    <Icon name={'plus'}/>
                    Прикрепить документ
                </Button>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    setAddChanges(false)
                }}>
                    Отменить
                </Button>
                <Button className="button-primary" onClick={() => {
                    setAddChanges(false)
                }}>
                    Добавить
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default AddDocumentChanges;