import React, {useEffect, useMemo} from 'react';
import Widget from "../Widget";
import {Accordion, Button, Modal} from "semantic-ui-react";
import {observer, useLocalStore} from "mobx-react-lite";
import DropdownField from "../fields/DropdownField";
import './index.scss'
import {observable, toJS} from "mobx";

/** Suitable for filter rows in tables */
const FilterModal = observer(({open, onClose, data, header = "Фильтрация стобцов", groups = [], onApply, filter}) => {

    const ls = useLocalStore(() => ({
        selectedValues: observable.map()
    }))

    useEffect(() => {
        if (open) {
            ls.selectedValues = observable.map(filter)
        }
    }, [open])

    const groupedData = useMemo(
        () => {
            return groups.map((group) => {
                const extGroup = {...group} // title, property
                extGroup.values = [...new Set(data.map(item => item[group.property]))].filter(value => value && (value.trim() !== null))
                extGroup.options = extGroup.values.map((value) => ({text: value, key: value, value}))
                return extGroup
            })
        },
        [groups, data]
    )

    return (
        <Modal open={open} onClose={onClose} size="tiny" className="app components filter-modal alt-semantic">
            <Widget
                header={header}
                closeIcon
                onClose={onClose}
            >
                <div className="buttons">
                    <Button
                        content="Очистить"
                        className="button-link"
                        onClick={() => ls.selectedValues.clear()}
                    />
                    <Button
                        content="Применить"
                        color="blue"
                        onClick={() => {
                            onApply(ls.selectedValues)
                        }}
                    />
                </div>
                {groupedData.map(({title, options, property}, index) => (
                    <DropdownField
                        key={index}
                        label={title}
                        placeholder="Не выбрано"
                        fluid multiple selection search
                        options={options}
                        value={ls.selectedValues.has(property) ? [...ls.selectedValues.get(property)] : []}
                        onChange={(e, {value}) => {
                            ls.selectedValues.set(property, value)
                        }}
                    />
                ))}
            </Widget>
        </Modal>
    );
})

export default FilterModal;