import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Dimmer, Grid, Loader, Menu, Icon } from "semantic-ui-react";
import DropdownField from "../../../shared/fields/DropdownField";
import styles from "./OrganizationInspection.module.scss";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import InspectionChart from "./InspectionChart/InspectionChart";
import AdditionalWork from "./AdditionalWork/AdditionalWork";
import { useTransition, animated } from "react-spring";
import ekasut from "../../../api/ekasut";
import TextField from "../../../shared/fields/TextField";
import moment from "moment";
import { AddPlan } from "./addPlan/AddPlan";
import { Select, Space, Typography } from "antd";
import InventoryPark from "./InventoryPark/InventoryPark";

const OrganizationInspection = observer(() => {
  const { Option } = Select;

  const [activeIndex, setActiveIndex] = useState(0);
  const prevIndexRef = useRef(-1);
  const [season, setSeason] = useState("");
  const [year, setYear] = useState("");
  const [pred, setPred] = useState("");
  const [period, setPeriod] = useState();
  const [periodFilter, setPeriodFilter] = useState({
    typeCO: null,
    yearCO: false,
  });
  const [predFilterOptions, setPredFilterOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorKod, setErrorKod] = useState(false);
  const [activeSpace, setActiveSpace] = useState({
    id: -1,
    name: "",
    space: <></>,
  });

  const [datesPlan, setDatesPlan] = useState({});

  useEffect(() => {
    const getDatePeriod = async () => {
      const result = await ekasut.commisionInspection.getPeriodOrder(
        localStorage.getItem("seasonYear"),
        localStorage.getItem("season")
      );
      const periodFrom = result?.data[0]?.dateBegPodgCo;
      const periodTo = result?.data[0]?.dateEndPodgCo;
      localStorage.setItem(
        "orderDateFrom",
        moment(periodFrom).format("DD.MM.YYYY")
      );
      localStorage.setItem(
        "orderDateTo",
        moment(periodTo).format("DD.MM.YYYY")
      );
    };

    getDatePeriod();
  }, [year]);

  const transitions = useTransition(activeSpace, {
    from: {
      opacity: 0,
      transform:
        activeIndex > prevIndexRef.current
          ? `translate3d(0,100%,0)`
          : `translate3d(0,-100%,0)`,
    },
    enter: () => async (next) => {
      await next({ opacity: 1, transform: `translate3d(0,0%,0)` });
    },
    leave: {
      opacity: 0,
      transform:
        activeIndex > prevIndexRef.current
          ? `translate3d(0,-100%,0)`
          : `translate3d(0,100%,0)`,
    },
    config: {
      duration: 700,
    },
    onRest: () => {
      prevIndexRef.current = activeIndex;
    },
  });

  const initCOPeriod = () => {
    let nowMonth = moment().month() + 1;
    let nowYear = moment().year();
    setPeriod(
      nowMonth > 7 || nowMonth < 3 ? "Осень " + nowYear : "Весна " + nowYear
    );
    setPeriodFilter({
      typeCO: nowMonth > 7 || nowMonth < 3 ? 1 : 0,
      yearCO: nowYear,
    });
  };

  const changeWorkSpace = (nameSpace) => {
    if (nameSpace === "Общие сведения") {
      setActiveIndex(0);
      setActiveSpace({
        id: 0,
        name: "Общие сведения",
        space: (
          <GeneralInfo
            pred={pred}
            periodFilter={periodFilter}
            year={year}
            season={season}
            // datesPlan={datesPlan}
            // setDatesPlan={setDatesPlan}
          />
        ),
      });
    } else if (nameSpace === "Доп. работы") {
      setActiveIndex(1);
      setActiveSpace({
        id: 1,
        name: "Доп. работы",
        space: <AdditionalWork pred={pred} periodFilter={periodFilter} />,
      });
    } else if (nameSpace === "Инв. парк") {
      setActiveIndex(2);
      setActiveSpace({
        id: 2,
        name: "Инв. парк",
        space: <InventoryPark pred={pred} periodFilter={periodFilter} />,
      });
    } else if (nameSpace === "График") {
      setActiveIndex(3);
      setActiveSpace({
        id: 3,
        name: "График",
        space: <InspectionChart pred={pred} periodFilter={periodFilter} />,
      });
    } else if (nameSpace === "Планирование") {
      setActiveIndex(4);
      setActiveSpace({
        id: 4,
        name: "Планирование",
        space: <AddPlan periodFilter={periodFilter} />,
      });
    }
  };

  useEffect(() => {
    ekasut.commisionInspection.getListDepo(localStorage.kodFirm).then((res) => {
      setPredFilterOptions(
        res.data.map((e) => ({ text: e.firmName, value: e.kodDepo }))
      );
      if (
        res.data.filter(
          (e) => String(e.kodDepo) === localStorage.getItem("kodFirm")
        ).length
      ) {
        let result = res.data.filter(
          (e) => String(e.kodDepo) === localStorage.getItem("kodFirm")
        )[0];
        setPred({ text: result.firmName, value: result.kodDepo });
      } else if (localStorage.getItem("accessLevel") != 0) {
        setErrorKod(true);
      }
      if (localStorage.getItem("accessLevel") != 0) {
        initCOPeriod();
      } else {
        setPeriod(false);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (pred && periodFilter?.yearCO && year && season) {
      changeWorkSpace("Общие сведения");
    }
    return;
  }, [pred, periodFilter, year, season]);

  const currentYear = moment().year();

  return (
    <Grid columns={2} className={styles.organInspecGrid}>
      <Grid.Column className={"work-column"}>
        <Dimmer active={loading} inverted>
          <Loader inverted>Загрузка</Loader>
        </Dimmer>
        {errorKod ? (
          <div className={styles.chooseFirm}>Данных нет</div>
        ) : (!pred && !loading) || (!periodFilter?.yearCO && !loading) ? (
          <div className={styles.chooseFirm}>
            <Icon name={"train"} size={"large"} />
            Выберите предприятие
          </div>
        ) : (
          <Grid.Row className={styles.transitionRow}>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexFlow: "column wrap",
              }}
            >
              {transitions((style, item, key) => {
                const Space = ({ style }) => (
                  <animated.div key={key.id} style={{ ...style }}>
                    {item.space}
                  </animated.div>
                );
                return (
                  <Space key={item.id} style={{ ...style, flex: "1 1 auto" }} />
                );
              })}
            </div>
          </Grid.Row>
        )}
      </Grid.Column>
      <Grid.Column className={"filter-column"}>
        <Grid.Row className={styles.dropFilter}>
          <Typography.Title style={{ fontSize: "15px", paddingLeft: "7px" }}>
            Год и сезон проведения КО
          </Typography.Title>
          <Space style={{ margin: "0 0 15px 15px" }}>
            <Select
              style={{
                width: 120,
              }}
              placeholder="Сезон"
              onChange={(e) => {
                localStorage.setItem("season", e);
                setSeason(e);
              }}
            >
              <Option value="1">Весна</Option>
              <Option value="2">Осень</Option>
            </Select>
            <Select
              style={{
                width: 120,
              }}
              placeholder="Год"
              onChange={(e) => {
                localStorage.setItem("seasonYear", e);
                setYear(e);
              }}
            >
              <Option value={String(currentYear)}>{currentYear}</Option>
              <Option value={String(currentYear - 1)}>{currentYear - 1}</Option>
              <Option value={String(currentYear - 2)}>{currentYear - 2}</Option>
              <Option value={String(currentYear - 3)}>{currentYear - 3}</Option>
            </Select>
          </Space>
          <Grid.Row>
            {predFilterOptions.filter(
              (e) => String(e.value) === localStorage.getItem("kodFirm")
            ).length ? (
              <TextField
                className={styles.TextField}
                label="Предприятие"
                classNameContent="field-width"
                content={pred.text}
              />
            ) : (
              <DropdownField
                disabled={errorKod || loading}
                label="Предприятие"
                search
                fluid
                clearable
                options={predFilterOptions}
                value={pred}
                onChange={(e, { value }) => {
                  setPred(value);
                }}
              />
            )}
          </Grid.Row>
          <Grid.Row>
            <Menu pointing secondary vertical>
              {localStorage.accessLevel === "1" ? (
                ""
              ) : (
                <Menu.Item
                  disabled={
                    loading || errorKod || !pred || !periodFilter?.yearCO
                  }
                  content={
                    "Общие сведения об организации комиссионного осмотра"
                  }
                  active={activeSpace.name === "Общие сведения"}
                  onClick={() => {
                    if (activeSpace.id !== 0) {
                      changeWorkSpace("Общие сведения");
                    }
                  }}
                />
              )}

              <Menu.Item
                disabled={loading || errorKod || !pred || !periodFilter?.yearCO}
                content={"Перечень дополнительных работ в период КО"}
                active={activeSpace.name === "Доп. работы"}
                onClick={() => {
                  if (activeSpace.id !== 1) {
                    changeWorkSpace("Доп. работы");
                  }
                }}
              />
              {localStorage.accessLevel === "1" ||
              localStorage.accessLevel === "7" ? (
                ""
              ) : (
                <Menu.Item
                  disabled={
                    loading || errorKod || !pred || !periodFilter?.yearCO
                  }
                  content={"Инвентарный парк в приказе"}
                  active={activeSpace.name === "Инв. парк"}
                  onClick={() => {
                    if (activeSpace.id !== 2) {
                      changeWorkSpace("Инв. парк");
                    }
                  }}
                />
              )}
              {localStorage.accessLevel === "1" ||
              localStorage.accessLevel === "7" ? (
                ""
              ) : (
                <Menu.Item
                  disabled={
                    loading || errorKod || !pred || !periodFilter?.yearCO
                  }
                  content={"График осмотров локомотивов"}
                  active={activeSpace.name === "График"}
                  onClick={() => {
                    if (activeSpace.id !== 3) {
                      changeWorkSpace("График");
                    }
                  }}
                />
              )}

              {localStorage.getItem("accessLevel") == 0 ? (
                <Menu.Item
                  disabled={loading || !pred || !periodFilter?.yearCO}
                  content={"Планирование комиссионного осмотра"}
                  active={activeSpace.name === "Планирование"}
                  onClick={() => {
                    if (activeSpace.id !== 4) {
                      changeWorkSpace("Планирование");
                    }
                  }}
                />
              ) : null}
            </Menu>
          </Grid.Row>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
});

export default OrganizationInspection;
