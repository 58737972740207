import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {Segment} from "semantic-ui-react";
import moment from "moment";
import MaintenanceTable from "./MaintenanceTable";
import {getNepAnalyticsApi} from './NepLayout'
import AppModal from "../../../shared/AppModal";

const RepairChart = ({data, pointName, pointId, isRoad, visibilities, locType, traction, onRowClick}) => {
    const [options, setOptions] = useState(initOptions)
    const [maintenanceLoading, setMaintenanceLoading] = useState(false)
    const [showMaintenanceTable, setShowMaintenanceTable] = useState(false)
    const [maintenanceValues, setMaintenanceValues] = useState({})
    const [maintenanceData, setMaintenanceData] = useState(null)

    // update options
    useEffect(() => {
        if (!(data instanceof Array)) {
            return
        }
        const series = [
            {
                name: 'ТО/ТР',
                data: [],
                visible: visibilities[0]
            },
            {
                name: 'НР',
                data: [],
                visible: visibilities[1]
            },
            {
                name: 'Рекламация',
                data: [],
                visible: visibilities[2]
            },
        ]
        data.forEach((item) => {
            const date = moment(item.date)
            const ms = date.valueOf()
            series[0].data.push({
                y: item.trCnt,
                x: ms,
                date
            })
            series[1].data.push({
                y: item.nrCnt,
                x: ms,
                date
            })
            series[2].data.push({
                y: item.reklamCnt,
                x: ms,
                date
            })
        })
        const handleClick = (e) => {
            // setCountFailuresModal({isShow: true, details: e.point.details, group: 'series', category: e.point.category})
            // e.point.series.index
            ReactDOM.unstable_batchedUpdates(() => {
                setShowMaintenanceTable(true)
                setMaintenanceValues({
                    seriesIndex: e.point.series.index,
                    date: e.point.date
                })
            })
        }

        const addOptions = {
            title: {
                text: `${pointName}, Динамика проведения ремонтов`
            },
            series,
            plotOptions: {
                line: {
                    point: {
                        events: {
                            click: handleClick
                        }
                    }
                }
            },
        }
        setOptions(Highcharts.merge(initOptions, addOptions))
    }, [data, pointName, visibilities])

    // load data for next modal view: maintenance table
    useEffect(() => {
        if (showMaintenanceTable) {
            setMaintenanceLoading(true)
            const nepApi = getNepAnalyticsApi(locType)
            let getDetails
            if (isRoad) {
                if (maintenanceValues.seriesIndex === 0) {
                    getDetails = nepApi.getRoadTrDetail
                } else if (maintenanceValues.seriesIndex === 1) {
                    getDetails = nepApi.getRoadNrDetail
                } else if (maintenanceValues.seriesIndex === 2) {
                    getDetails = nepApi.getRoadClaimDetail
                } else {
                    throw new Error()
                }
            } else {
                if (maintenanceValues.seriesIndex === 0) {
                    getDetails = nepApi.getDepotTrDetail
                } else if (maintenanceValues.seriesIndex === 1) {
                    getDetails = nepApi.getDepotNrDetail
                } else if (maintenanceValues.seriesIndex === 2) {
                    getDetails = nepApi.getDepotClaimDetail
                } else {
                    throw new Error()
                }
            }
            getDetails(pointId, traction, maintenanceValues.date)
                .then((response) => {
                    setMaintenanceData(response.data)
                    setMaintenanceLoading(false)
                })
        }
    }, [showMaintenanceTable, locType, traction, maintenanceValues, isRoad, pointId])

    return (
        <Segment style={{width: "100%"}}>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <AppModal
                open={showMaintenanceTable}
                onClose={() => {
                    setShowMaintenanceTable(false)
                }}
                loading={maintenanceLoading}

            >
                <MaintenanceTable
                    pointName={pointName}
                    date={maintenanceValues.date}
                    data={maintenanceData}
                    onRowClick={onRowClick}
                />
            </AppModal>
        </Segment>
    )
}

export default RepairChart

const initOptions = {
    title: {
        text: ''
    },
    chart: {
        type: 'line',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    xAxis: {
        title: {
            text: 'Время'
        },
        type: 'datetime',
        labels: {
            formatter: function () {
                return moment(this.value).format('DD.MM');
            },
            rotation: 0 // -34
        },
    },
    yAxis: {
        title: {
            text: 'Тяговые единицы'
        },
    },
    plotOptions: {
        series: {
            cursor: 'pointer'
        }
    },
    tooltip: {
        formatter: function () {
            return (
                `${this.series.name}: ${this.y} ед.<br />
                 Дата: ${moment(this.x).format('DD.MM.YYYY')}`
            )
        },
    }
}