import React from 'react'
import {Dimmer, Loader} from "semantic-ui-react";
import './DashboardLoading.scss'

const DashboardLoading = ({loading}) => {
    return (
        <div className='pr-dashboard-loading'>
            <Dimmer active={loading} inverted>
                <Loader>Загрузка</Loader>
            </Dimmer>
        </div>
    )
}
export default DashboardLoading