
import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const branchFieldNames = [
    'dorName',
    'depoName',
    'nameGrupSer'
]

// openColumns: [2,3,4]
//   index - how deep in the tree: rzd->roads->depots->locGroups
//   value - index of the category

const createColumn = (item) => {
	return {
		y: item.nepFact,
		depoRemPlan: item.depoRemPlan,
		depoRemFact: item.depoRemFact,
		depoRemDelta: item.depoRemDelta,
		zavodRemPlan: item.zavodRemPlan,
		zavodRemFact: item.zavodRemFact,
		zavodRemDelta: item.zavodRemDelta
	}
}

const createAdditionalOptions = (categories, values, onClick) => {
	return {
		xAxis: {categories},
		series: [{
			data: values
		}],
		title: {
			text: ''
		},
		plotOptions: {
			column: {
				point: {
					events: {
						click: (e) => {
							onClick(e)
						}
					}
				}
			}
		}
	}
}

const DashboardChart = React.memo(({traction, rzdTree, openColumns, columnOnClick, showLocomotives, locomotives}) => {
	const [options, setOptions] = useState(initOptions)

	useEffect(() => {
		if (!showLocomotives) {
			let branch = rzdTree
			if (openColumns[0] !== undefined && branch[openColumns[0]] !== undefined) {
				branch = branch[openColumns[0]].byDepo
			}
			if (openColumns[1] !== undefined && branch[openColumns[1]] !== undefined) {
				branch = branch[openColumns[1]].byUkrGrupSer
			}
			let catFieldName = branchFieldNames[openColumns.length]
			const categories = []
			const values = []
			branch.forEach((road) => {
				categories.push(road[catFieldName].trim())
				values.push(createColumn(road))
			})

			const handleClick = (e) => {
				if (openColumns.length < 2) {
					// branches have different field names for ... names (dorName, depoName, ...)
					const name = branch[e.point.index][branchFieldNames[openColumns.length]]
					columnOnClick(branch, e.point.index, name)
				}
			}
			const newOptions = createAdditionalOptions(categories, values, handleClick)

			setOptions((prevOptions) => {
				return Highcharts.merge(prevOptions, newOptions)
			})
		}
	}, [rzdTree, openColumns, columnOnClick, showLocomotives])

	useEffect(() => {
		if (showLocomotives) {
			const categories = []
			const values = []
			locomotives.forEach((loc) => {
				categories.push(loc.nameGrupSer)
				values.push(createColumn(loc))
			})
			const newOptions = createAdditionalOptions(categories, values, () => {})
			setOptions((prevOptions) => {
				return Highcharts.merge(prevOptions, newOptions)
			})
		}
	}, [locomotives, showLocomotives])

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={options}
		/>
	)
})
export default DashboardChart

const initOptions = {
	chart: {
		zoomType: 'x',
		type: 'column',
		backgroundColor: 'rgba(0,0,0,0)',
		animation: false,
	},
	title: {
		text: '',
	},
	exporting: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	series: [],
	xAxis: {
		type: 'category',
		categories: [],
		title: {
			text: ''
		}
	},
	yAxis: {
		allowDecimals: false,
		title: {
			text: 'Тяговые единицы'
		}
	},
	legend: {
		enabled: false
	},
	tooltip: {
		formatter: function() {
			// I did'nt figure out how to add indentation for single line
			// may be class name will help or useHtml
			let point = this.point
			return (
			    `<b>${this.x}</b><br/>
				Деповский ремонт<br/>
				План: ${point.depoRemPlan}<br />
				Факт: ${point.depoRemFact}<br />
				Отклонение: ${point.depoRemDelta}<br />
				Заводской ремонт<br />
				План: ${point.zavodRemPlan}<br />
				Факт: ${point.zavodRemFact}<br />	
				Отклонение: ${point.zavodRemDelta}<br />`
			)
		}
	},
	plotOptions: {
		series: {
			cursor: 'pointer'
		},
		column: {
			maxPointWidth: 40
		}
	},
}