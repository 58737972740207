import React, {useEffect} from 'react';
import {Header, Modal, Tab, Segment, Table, Accordion} from 'semantic-ui-react'
import {observer, useLocalStore, Observer} from "mobx-react-lite";
import apiWorker from "../../api/apiWorker";
import ekasut from "../../api/ekasut";
import {formatMoment, groupToMap} from "../../helpers/utils";
import {dateFormats} from "../../Dictionaries";
import appStyles from '../../css/App.module.scss'
import TableRowsLoader from "../TableRowsLoader";
import cn from 'classnames'
import moment from 'moment'
import {observable} from "mobx";

// Component may work with tabNum or serLoc, znsLoc, prsLoc
const RouteDetailModal = observer(({open, tabNum, serLoc, znsLoc, prsLoc, yavkaDate, closedRouteDate, onClose}) => {
    const truncStrDate = (str) => {
        const m = moment(str)
        return m.isValid() ? m.format("YYYY-MM-DD") : "Дата не определена"
    }

    const ls = useLocalStore((source) => ({
        currentRoute: [],
        // grouped by createDate
        periodRoute: observable.map(),
        week: {
            from: moment().startOf("week"),
            to: moment().endOf("week")
        },
        month: {
            from: moment().startOf("month"),
            to: moment().endOf("month")
        },
        quarter: {
            from: moment().startOf("year"),
            to: moment().endOf("quarter")
        },
        isTabNum: () => source.tabNum !== undefined,
        isCurrentRouteLoading: () => ls.apiGetMash.loading || ls.apiGetLoc.loading,
        isPeriodRouteLoading: () => ls.apiGetLocPeriod.loading || ls.apiGetMashPeriod.loading,

        apiGetLoc: apiWorker(ekasut.tu152.getLoc, {onSuccess: (data) => ls.currentRoute = data}),
        apiGetMash: apiWorker(ekasut.tu152.getMash, {onSuccess: (data) => ls.currentRoute = data}),

        apiGetLocPeriod: apiWorker(ekasut.tu152.getLoc,
            {onSuccess: (data) => ls.periodRoute = groupToMap(data, "createDate", truncStrDate)}),
        apiGetMashPeriod: apiWorker(ekasut.tu152.getMash,
            {onSuccess: (data) => ls.periodRoute = groupToMap(data, "createDate", truncStrDate)}),
    }), {tabNum})

    const fetchCurrentRoute = (params) => ls.apiGetMash.fetch({tabNum, ...params})
    const fetchPeriodRoute = (params) => ls.apiGetLocPeriod.fetch({serLoc, znsLoc, prsLoc, ...params})
    // simulate onOpen event
    useEffect(() => {
        if (open) {
            fetchCurrentRoute({fromDate: yavkaDate, toDate: closedRouteDate}).catch(() => {})
            // By default tab with quarter is opened in tab
            fetchPeriodRoute({fromDate: ls.quarter.from, toDate: ls.quarter.to}).catch(() => {})
        }
    }, [open])

    const paneRender = () => (
        // Wrap Pane inside Observer for able to react on state changes
        <Observer>
            {() => (
                <Tab.Pane attached={false} loading={ls.isPeriodRouteLoading()}>
                    {ls.periodRoute.size === 0 ? "Нет данных за выбранный период" : (
                        <GroupedBasicInfo map={ls.periodRoute}/>
                    )}
                </Tab.Pane>
            )}
        </Observer>
    )

    // Same render components but different data
    const panes = [
        {menuItem: 'Квартал', render: paneRender,},
        {menuItem: 'Месяц', render: paneRender,},
        {menuItem: 'Неделя', render: paneRender,},
    ]

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header content="Сведения о приемке локомотива"/>
            <Modal.Content>
                <Header content="К текущему маршруту"/>
                {ls.isCurrentRouteLoading() ? <Segment loading style={{height: 100}}/> : (
                    <BasicInfo data={ls.currentRoute}/>
                )}
                <Header content="За период"/>
                <Tab
                    menu={{secondary: true, pointing: true}}
                    panes={panes}
                    onTabChange={(e, {activeIndex}) => {
                        ls.periodRoute.clear()
                        switch (activeIndex) {
                            // quarter
                            case 0:
                                fetchPeriodRoute({fromDate: ls.quarter.from, toDate: ls.quarter.to}).catch(() => {});
                                break;
                            case 1:
                                fetchPeriodRoute({fromDate: ls.month.from, toDate: ls.month.to}).catch(() => {});
                                break;
                            case 2:
                                fetchPeriodRoute({fromDate: ls.week.from, toDate: ls.week.to}).catch(() => {});
                                break;
                            default: /* should not be here */
                        }
                    }}
                />
            </Modal.Content>
        </Modal>
    );
})

const GroupedBasicInfo = observer(({map}) => {
    const ls = useLocalStore(() => ({
        activeIndex: -1
    }))

    const createTabs = () => {
        const tabs = []
        map.forEach((value, key) => tabs.push(
            <React.Fragment key={key}>
                <Accordion.Title
                    content={key} index={key}
                    active={ls.activeIndex === key}
                    onClick={(e, {index}) => ls.activeIndex = ls.activeIndex === index ? -1 : index}
                />
                <Accordion.Content active={ls.activeIndex === key}>
                    <BasicInfo data={value}/>
                </Accordion.Content>
            </React.Fragment>
        ))
        tabs.sort((a,b) => moment(a.key).diff(moment(b.key)))
        return tabs
    }

    return (
        <Accordion>
            {createTabs()}
        </Accordion>
    )
})

const BasicInfo = observer(({data = []}) => {
    const ls = useLocalStore(() => ({
        details: {open: false, remarks: [], items: []}
    }))

    return (<>
        <Table className={cn(data.length > 0 && appStyles.selectableTable)}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell content="Дата создания"/>
                    <Table.HeaderCell content="ФИО машиниста"/>
                    <Table.HeaderCell content="Принят/не принят"/>
                    <Table.HeaderCell content="Комментарий"/>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <TableRowsLoader
                    colSpan={4}
                    messageIfEmpty="Нет записей"
                    rows={data.map(((info, index) => (
                        <Table.Row
                            key={index}
                            onClick={() => ls.details = {open: true, items: info.items, remarks: info.remarks}}
                        >
                            <Table.Cell>{formatMoment(info.createDate, dateFormats.upToMinute)}</Table.Cell>
                            <Table.Cell>{info.mashName}</Table.Cell>
                            <Table.Cell>{info.isAccepted !== undefined ? (info.isAccepted ? "принят" : "не принят") : null}</Table.Cell>
                            <Table.Cell>{info.comment}</Table.Cell>
                        </Table.Row>
                    )))}
                />
            </Table.Body>
        </Table>
        <BasicInfoDetailsModal
            open={ls.details.open}
            items={ls.details.items}
            remarks={ls.details.remarks}
            onClose={() => ls.details = {open: false, remarks: [], items: []}}
        />
    </>)
})

const BasicInfoDetailsModal = observer(({open, items, remarks, onClose}) => {
    const ls = useLocalStore(() => ({
        activeTab: "none"
    }))

    const tabClickHandler = (e, {index}) => ls.activeTab = ls.activeTab === index ? -1 : index

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header content="Сведения о замечаниях и наличии инвентаря"/>
            <Modal.Content>
                <Accordion>
                    <Accordion.Title content="Замечания" index="remarks" active={ls.activeTab === "remarks"} onClick={tabClickHandler}/>
                    <Accordion.Content active={ls.activeTab === "remarks"}>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Замечание"/>
                                    <Table.HeaderCell content="Состояние рассмотрения"/>
                                    <Table.HeaderCell content="Кем изменялось"/>
                                    <Table.HeaderCell content="Дата изменения"/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <TableRowsLoader
                                    colSpan={4}
                                    messageIfEmpty="Нет замечаний"
                                    rows={remarks.map((item, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell>{item.remark}</Table.Cell>
                                            <Table.Cell>{item.checkState}</Table.Cell>
                                            <Table.Cell>{item.changeTabNum}</Table.Cell>
                                            <Table.Cell>{formatMoment(item.changeDate, dateFormats.upToMinute)}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                />
                            </Table.Body>
                        </Table>
                    </Accordion.Content>
                    <Accordion.Title content="Инвертарь" index="inv" active={ls.activeTab === "inv"} onClick={tabClickHandler}/>
                    <Accordion.Content active={ls.activeTab === "inv"}>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Наименование"/>
                                    <Table.HeaderCell content="Ед. измерения"/>
                                    <Table.HeaderCell content="Норма (кол-во)"/>
                                    <Table.HeaderCell content="Нет в наличии (кол-во)"/>
                                    <Table.HeaderCell content="Сломано (кол-во)"/>
                                    <Table.HeaderCell content="Не комплект (кол-во"/>
                                    <Table.HeaderCell content="Комментарий"/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <TableRowsLoader
                                    colSpan={4}
                                    messageIfEmpty="Нет инвертаря"
                                    rows={items.map((item, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell>{item.itemName}</Table.Cell>
                                            <Table.Cell>{item.itemUnit}</Table.Cell>
                                            <Table.Cell>{item.normCount}</Table.Cell>
                                            <Table.Cell>{item.notExist}</Table.Cell>
                                            <Table.Cell>{item.broken}</Table.Cell>
                                            <Table.Cell>{item.unComplited}</Table.Cell>
                                            <Table.Cell>{item.comment}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                />
                            </Table.Body>
                        </Table>
                    </Accordion.Content>
                </Accordion>
            </Modal.Content>
        </Modal>
    )
})


export default RouteDetailModal;