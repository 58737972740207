import React, {useEffect, useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import ekasut from "../../../../../api/ekasut";

export const DelStatus = ({openDel, setOpenDel, status, statusList, templData, initStatuses}) => {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const updateStatusArray = (newStatusArr) => {
        setLoading(true)
        ekasut.nsiTaskManager.updateTemp({
            templateId: templData.code,
            templateName: templData.name,
            statuses: newStatusArr
        })
            .then(() => {
                initStatuses(templData.code)
                setLoading(false)
                setOpenDel(false)
            })
            .catch(() => {
                setLoading(false)
            })

    }

    const deleteSatus = (status, statusList) => {
        let statusListActualCat = statusList.filter(e => e.categoryId === status.categoryId).sort((a, b) => a.position - b.position)
        let anotherStatuses = statusList.filter(e => e.categoryId !== status.categoryId)
        statusListActualCat.splice(statusListActualCat.findIndex(e => e.statusId === status.statusId), 1)

        updateStatusArray(anotherStatuses.concat(statusListActualCat.map((e, index) => ({
                ...e,
                position: index + 1
            }))
        ))
    }

    useEffect(() => {
        if (openDel && status?.statusId) {
            ekasut.nsiTaskManager.checkStatsuBeforeDel(status.statusId)
                .then(({data}) => {
                    console.log(data)
                    setMessage({tasksCount: data.tasksCount, statusName: data.nextStatus.statusName})
                })
                .catch(() => {

                })
        }
    }, [openDel])

    return (
        <Modal
            size={'mini'}
            open={openDel}
            onClose={() => {
                setOpenDel(false)
            }}
        >
            <Modal.Header>Удаление статуса</Modal.Header>
            <Modal.Content>
                <p>Вы действительно хотите удалить статус:
                    "<strong>{status?.statusName}</strong>"? {message?.tasksCount ?
                        <span>Все задачи ({message.tasksCount}) будут перенесены в статус "<strong>{message?.statusName}</strong>".</span> : null}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative loading={loading} onClick={() => {
                    setOpenDel(false)
                }}>
                    Нет
                </Button>
                <Button positive loading={loading} onClick={() => {
                    deleteSatus(status, statusList)
                }}>
                    Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
};