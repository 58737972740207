import React from 'react';
import cn from "classnames";
import {DateInput} from "semantic-ui-calendar-react/dist/inputs";
import moment from "moment";
import TextField from "./TextField";
import './DateField.scss'

const DateField = ({className, inputClassName, fluid, label, date, editable = true, textFieldPlaceholder, onChange = () => null, dateFormat = "YYYY.MM.DD", ...inputProps}) => {

    if (!editable) {
        const isDateDefined = date !== "" && date !== null && date !== undefined
        return (
            <TextField
                label={label}
                content={isDateDefined ? date.isValid() ? date.format(dateFormat) : "" : textFieldPlaceholder}
                className={cn(className, "app-field", !isDateDefined && "text-field-placeholder")}
                {...inputProps}
            />
        )
    }

    return (
        <div className={cn("app field date-field", fluid && "fluid", className)}>
            <label>{label}</label>
            <DateInput
                dateFormat={dateFormat}
                className={inputClassName}
                // "", null - controlled component
                value={(date === "" || date === null) ? "" : (date ? date.format(dateFormat) : undefined)}
                onChange={(e, data) => {
                    onChange(moment(data.value, dateFormat), e)
                }}
                {...inputProps}
            />
        </div>
    );
};

export default DateField;