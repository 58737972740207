import React from 'react';
import {padStartZero} from "../helpers/utils";

const LabeledTimeInput = ({label, time, maxTime, minTime, onChange, withoutContainer, ...restInputProps}) => {
    const labeledTimeInput = (<>
        <label style={{marginBottom: 5}}>{label}</label>
        <input
            /*style={{lineHeight: "14px"}}*/
            type="time"
            value={`${padStartZero(time.h, 2)}:${padStartZero(time.m, 2)}`}
            onChange={({target: {value}}) => {
                onChange(getHM(value))
            }}
            {...restInputProps}
        />
    </>)
    return withoutContainer ? labeledTimeInput : <div>{labeledTimeInput}</div>
};
LabeledTimeInput.defaultProps = {
    withoutContainer: false
}
const getHM = (str) => ({
    h: parseInt(str.substr(0, 2)),
    m: parseInt(str.substr(3, 5))
})

export default LabeledTimeInput;