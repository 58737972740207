import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "antd/dist/antd.css";
import {
  JsonHubProtocol,
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from "@microsoft/signalr";
import ekasut from "../../api/ekasut";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  TextField,
  IconButton,
  Button,
} from "@material-ui/core";
import { Comment, Dimmer, Loader } from "semantic-ui-react";
import Skeleton from "@material-ui/lab/Skeleton";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import moment from "moment";
import style from "./News.module.scss";
import PageNotFound from "../PageNotFound";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import axios from "axios";
import { AddNewsModal } from "./AddNewsModal";
import { message, Popconfirm } from "antd";
import { CommentApp } from "./CommentApp";
import { getAppGrants } from "../../helpers/utils";

export const NewsDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  const [connection, setConnection] = useState(null);

  const [connectionSetTimeOut, setConnectionSetTimeOut] = useState();

  const [initDataForModal, setInitDataForModal] = useState();

  const [news, setNews] = useState(null);
  const [loadingNews, setLoadingNews] = useState(true);

  const [image, setImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const [mainComment, setMainComment] = useState("");

  const [files, setFiles] = useState([]);

  const [comments, setComments] = useState([]);

  const [editNews, setEditNews] = useState(false);

  const [fileImageInitData, setFileImageInitData] = useState([]);
  const [filesInitData, setFilesInitData] = useState([]);

  const closeEditNews = () => {
    setEditNews(false);
  };

  // signal r

  useEffect(() => {
    if (id) {
      getNews();

      // Start the connection.
    } else {
      setLoadingNews(false);
    }
    const newConnection = new HubConnectionBuilder()
      .withUrl(window.backendUrl + "/hubs/ekasut/news/comments", {
        withCredentials: true,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      start();
    }
    return () => {
      if (connectionSetTimeOut) {
        clearTimeout(connectionSetTimeOut);
      }
      if (connection) {
        connection.stop();
      }
    };
  }, [connection]);

  async function start() {
    try {
      await connection.start();
      // connection.on('ReceiveAllComments', (message) => {
      //     console.log("message", message)
      //     setComments(message)
      // })
      connection.on("ReceiveComment", (comment) => {
        console.log("comment", comment);
        if (comment?.parentId) {
          setComments((prevState) =>
            prevState.map((el) => {
              if (el.id === comment.parentId) {
                el.children = [...el.children, comment];
              }
              return el;
            })
          );
        } else {
          setComments((prevState) => [...prevState, comment]);
        }
      });
      connection.on("ReceiveUpdatedComment", (comment) => {
        if (comment?.parentId) {
          setComments((prevState) =>
            prevState.map((el) => {
              if (el.id === comment.parentId) {
                el.children = el.children.map((child) => {
                  if (child.id === comment.id) {
                    child = comment;
                  }
                  return child;
                });
              }
              return el;
            })
          );
        } else {
          setComments((prevState) =>
            prevState.map((el) => {
              if (el.id === comment.id) {
                el = comment;
              }
              return el;
            })
          );
        }
      });
      connection.on("ReceiveDeletedComment", (comment) => {
        console.log("del", comment);
        if (comment?.parentId) {
          setComments((prevState) =>
            prevState.map((el) => {
              if (el.id === comment.parentId) {
                el.children = el.children.map((child) => {
                  if (child.id === comment.id) {
                    child = comment;
                  }
                  return child;
                });
              }
              return el;
            })
          );
        } else {
          setComments((prevState) =>
            prevState.map((el) => {
              if (el.id === comment.id) {
                el = comment;
              }
              return el;
            })
          );
        }
      });
      connection.onclose(async () => {
        console.log("close");
      });
      try {
        const r = await connection.invoke("AddToGroup", Number(id));
      } catch (e) {
        console.log(e);
      }
    } catch (err) {
      console.log(err);
      const timeOut = setTimeout(start, 5000);
      setConnectionSetTimeOut(timeOut);
    }
  }

  const deleteNews = () => {
    ekasut.news
      .deleteNews(id)
      .then((r) => {
        if (r.status === 200) {
          message.success(`Новость ${id} успешно удалена!`);
          history.push("/news");
        } else {
          message.error("Произошла ошибка при удалении новости!");
          history.push("/news");
        }
      })
      .catch((err) => {
        message.error("Произошла ошибка при удалении новости!");
        history.push("/news");
      });
  };

  const getNews = async () => {
    try {
      const resNewsData = await ekasut.news.getCurrentNews(id);
      const newsData = resNewsData?.data;
      try {
        if (newsData?.files?.length) {
          setFiles([]);
          setFilesInitData([]);
          const filesData = await Promise.all(
            newsData.files.map(async (el) => {
              const resFile = await ekasut.file.getFile(el.fileId);

              const bytes = new Uint8Array(resFile?.data);

              const blob = new Blob([bytes], {
                type: "application/octet-stream",
              });
              const urlCreator = window.URL || window.webkitURL;
              const url = urlCreator.createObjectURL(blob);

              const file = {
                fileName: el.fileName, //resFile.headers["content-disposition"].split(';')[1].split('=')[1].replaceAll(';', '').replaceAll('"', ''),
                url,
              };
              const fileInitData = new File([bytes], file.fileName, {
                type: "application/octet-stream",
              });

              setFilesInitData((prevState) => [
                ...prevState,
                {
                  file: fileInitData,
                  data: url,
                  fileId: el.fileId,
                },
              ]);

              return file;
            })
          );

          setFiles(filesData);
        } else {
          setFiles([]);
          setFilesInitData([]);
        }
      } catch (e) {
        console.log(e);
      }

      setLoadingNews(false);

      try {
        if (newsData?.coverImage?.fileId) {
          setLoadingImage(true);
          const imageData = await ekasut.file.getFile(
            newsData.coverImage.fileId
          );
          if (imageData?.status === 200) {
            const bytes = new Uint8Array(imageData?.data);
            const blob = new Blob([bytes], { type: "image/jpeg" });
            const fileName = newsData.coverImage.fileName; //imageData?.headers["content-disposition"].split(' ')[1].split('=')[1].replaceAll(';', '')
            const file = new File([bytes], fileName, {
              type: `image/${fileName.split(".")[1]}`,
            });
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(blob);
            setFileImageInitData([{ data: imageUrl, file }]);
            setImage(imageUrl);
          }
          setLoadingImage(false);
        } else {
          setImage("");
          setFileImageInitData([]);
        }
      } catch (e) {
        setLoadingImage(false);
      }

      try {
        const commentsData = await ekasut.news.getComments(id);
        commentsData.status === 200 ? console.log("yes") : console.log("no");
        setComments(commentsData?.data);
      } catch (e) {
        console.log(e);
      }

      setNews(newsData);
    } catch (e) {
      console.log(e);
      setLoadingNews(false);
    }
  };

  const sendMainComment = async () => {
    if (mainComment.trim()) {
      if (connection.connectionStarted) {
        try {
          await connection.send("SendComment", {
            newsId: Number(id),
            content: mainComment,
          });
          setMainComment("");
        } catch (e) {
          console.log(e);
        }
      } else {
        alert("No connection to server yet.");
      }
    } else {
      setMainComment(mainComment.trim());
    }
  };

  const sendCommentChildren = async (val, parentId) => {
    if (connection.connectionStarted) {
      try {
        await connection.invoke("SendComment", {
          newsId: Number(id),
          content: val,
          parentId,
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("No connection to server yet.");
    }
  };

  const editCommentHandler = async ({ newsId, commentId, content }) => {
    if (connection.connectionStarted) {
      try {
        await connection.invoke("UpdateComment", newsId, commentId, content);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteCommentHandler = async ({ newsId, commentId }) => {
    if (connection.connectionStarted) {
      try {
        await connection.invoke("DeleteComment", newsId, commentId);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (news) {
      setInitDataForModal({
        initDateTime: moment(news.publishDate).format("DD.MM.YYYY HH:mm:ss"),
        initValueCategory: { code: news.categoryId, name: news.categoryName },
        initFileObjects: filesInitData,
        initFileObjectsImage: fileImageInitData,
        initTitle: news.title,
        initContent: news.content,
        newsId: news.newsId,
        coverImageId: news.coverImage?.fileId,
        edit: true,
      });
    }
  }, [news]);

  const canEdit = getAppGrants().includes("news_edit");

  console.log(news, loadingNews);

  if (!news && !loadingNews) {
    return (
      <Dimmer active={true} inverted>
        <Loader inverted>Загрузка</Loader>
      </Dimmer>
    );
  }

  if (news && !loadingNews) {
    const {
      addedOn,
      authorId,
      authorName,
      categoryId,
      categoryName,
      commentsCount,
      content,
      coverImage,
      deletedOn,
      isDelayed,
      newsId,
      publishDate,
      title,
      updateOn,
    } = news;

    return (
      <div className={style.newsDetailsWrap}>
        <Button
          className={style.backButton}
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          onClick={() => history.push("/")}
        >
          Все новости
        </Button>
        <Card className={style.card}>
          {loadingImage && (
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ height: "300px" }}
            />
          )}
          {image && <CardMedia className={style.media} image={image} />}

          <CardContent>
            {categoryName && (
              <span className={style.category}> #{categoryName}</span>
            )}
            <div className={style.titleDetailsNews}>
              <Typography variant={"h6"} gutterBottom>
                {title}
              </Typography>
              {canEdit && (
                <div>
                  <Button color="primary" onClick={() => setEditNews(true)}>
                    Изменить
                  </Button>
                  <Popconfirm
                    title="Вы точно хотите удалить эту новость?"
                    onConfirm={deleteNews}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button color="primary">Удалить</Button>
                  </Popconfirm>
                </div>
              )}
            </div>

            <Typography variant={"body1"}>{content}</Typography>
            <div className={style.attachedFile}>
              Прикрепленные файлы:
              {files.length ? (
                <div>
                  {" "}
                  {files.map((el) => (
                    <Button
                      key={el.url}
                      style={{ textTransform: "none", marginRight: 10 }}
                      color="primary"
                      size="small"
                      download={el.fileName}
                      href={el.url}
                      target={"_blank"}
                      startIcon={<SaveIcon />}
                    >
                      {el.fileName}
                    </Button>
                  ))}{" "}
                </div>
              ) : (
                <span> 0</span>
              )}
            </div>

            <div className={style.description}>
              <div className={style.item}>
                <PersonOutlineOutlinedIcon />
                <Typography variant={"caption"}>{authorName}</Typography>
              </div>
              <div className={style.item}>
                <ScheduleOutlinedIcon />
                <Typography variant={"caption"}>
                  {moment(publishDate).format("DD.MM.YYYY HH:mm:ss")}
                </Typography>
              </div>
            </div>
            <Divider />
            <Comment.Group>
              {comments.map((el) => (
                <CommentApp
                  key={el.id}
                  {...el}
                  deleteCommentHandler={deleteCommentHandler}
                  editCommentHandler={editCommentHandler}
                  sendComment={sendCommentChildren}
                />
              ))}
            </Comment.Group>
            <div className={style.mainComment}>
              <TextField
                multiline
                fullWidth
                rows={2}
                variant={"outlined"}
                value={mainComment}
                onChange={(e) => {
                  setMainComment(e.target.value);
                }}
                label={"Комментарий"}
              />
              <IconButton onClick={sendMainComment}>
                <SendIcon color={"primary"} />
              </IconButton>
            </div>
          </CardContent>
        </Card>
        <AddNewsModal
          openModal={editNews}
          closeModal={closeEditNews}
          initData={initDataForModal}
          initNews={getNews}
        />
      </div>
    );
  } else {
    return (
      <Dimmer active={true} inverted>
        <Loader inverted>Загрузка</Loader>
      </Dimmer>
    );
  }
};
