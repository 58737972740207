import React from 'react';
import {Input} from "semantic-ui-react";

/** @deprecated
 * use InputField instead
 * */
const LabeledInput = ({label, withoutContainer, ...inputProps}) => {
    const labeledInput = (<>
        <label>{label}</label>
        <Input {...inputProps}/>
    </>)
    return withoutContainer ? labeledInput : <div>{labeledInput}</div>
};

export default LabeledInput;