import React from 'react';
import {Dimmer, Loader, Segment} from "semantic-ui-react";

const LoaderMessage = ({content = "Загрузка", basic}) => {
    return (
        <Segment basic={basic}>
            <Dimmer active inverted>
                <Loader inverted content={content}/>
            </Dimmer>
            <div  style={{height: 60}} />
        </Segment>
    );
};

export default LoaderMessage;