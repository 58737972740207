import React from 'react'
import {Table} from "semantic-ui-react";
import CellNotEmpty from "./CellNotEmpty";
import styles from './PersonDetails.module.scss'
import cn from 'classnames'

export default function PersonDetails({info}) {
    const getFormatterDate = (moment) => {
        return (moment === undefined) ? undefined : moment.format('DD.MM.YYYY')
    }

    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Контролируемый срок</Table.HeaderCell>
                    <Table.HeaderCell>Дата</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row className={cn(info.isDepartureRightExpired && styles.expired)}>
                    <Table.Cell>Срок действия права выезда на обслуживаемые участки</Table.Cell>
                    <CellNotEmpty>{getFormatterDate(info.expDepartureRight)}</CellNotEmpty>
                </Table.Row>
                <Table.Row className={cn(info.isElectricalSafetyExpired && styles.expired)}>
                    <Table.Cell>Истечение срока допуска по ЭБ</Table.Cell>
                    <CellNotEmpty>{getFormatterDate(info.expElectricalSafety)}</CellNotEmpty>
                </Table.Row>
                <Table.Row className={cn(info.isMedicalCommissionExpired && styles.expired)}>
                    <Table.Cell>Истечение срока медкомиссии</Table.Cell>
                    <CellNotEmpty>{getFormatterDate(info.expMedicalCommission)}</CellNotEmpty>
                </Table.Row>
                <Table.Row className={cn(info.isRepeatInOHSAndSPExpired && styles.expired)}>
                    <Table.Cell>Истечение срока повторного инструктажа по ОТ и ТБ</Table.Cell>
                    <CellNotEmpty>{getFormatterDate(info.expRepeatInOSHAndSP)}</CellNotEmpty>
                </Table.Row>
                <Table.Row className={cn(info.isOHSExmExpired && styles.expired)}>
                    <Table.Cell>Истечение срока сдачи экзамена по ОТ</Table.Cell>
                    <CellNotEmpty>{getFormatterDate(info.expOSHExm)}</CellNotEmpty>
                </Table.Row>
                <Table.Row className={cn(info.isPsychophysiologicalExmExpired && styles.expired)}>
                    <Table.Cell>Истечение срока ПФО</Table.Cell>
                    <CellNotEmpty>{getFormatterDate(info.expPsychophysiologicalExm)}</CellNotEmpty>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}