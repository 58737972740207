import React from 'react';
import style from './OperationsOfBarrierFunctions.module.scss';
import { Table } from "antd";
import useOperationsTableData from "./useOperationsTableData";
import {MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import {onCell, onCellCenter, onHeaderCell, tableBorderStyle} from "../../../shared/onAntdCells";
const { Column, ColumnGroup } = Table;



const OperationsOfBarrierFunctions = ({info}) => {



 const {loading, data} = useOperationsTableData({objectId: info.idObject})

  const expandable =  {
     expandIcon: ({expanded, onExpand, record}) => {
       if (!record.children) {
         return null;
       }
       return expanded ? (
         <MinusCircleTwoTone
           onClick={e => onExpand(record, e)}
           style={{margin: '3px'}}
         />
       ) : (
         <PlusCircleTwoTone
           onClick={e => onExpand(record, e)}
           style={{margin: '3px'}}
         />
       )
     },
  }

  return (
    <Table
      dataSource={data}
      loading={loading}
      bordered
      pagination={false}
      scroll={{ y: 350 }}
      style={tableBorderStyle}
      expandable={expandable}
    >

      <Column
        className={style.column}
        title="Наименование"
        dataIndex="objectNom"
        key="objectNom"
        onCell={onCell}
        onHeaderCell={onHeaderCell}
        width='400px'
      />
      <Column
        className={style.column}
        title="№ оборудования"
        dataIndex="objNom"
        key="objNom"
        onCell={onCellCenter}
        onHeaderCell={onHeaderCell}
        width='200px'
      />
      <ColumnGroup
        title="Сервисное обслуживание"
        onHeaderCell={onHeaderCell}
        onCell={onCell}
      >
        <Column
          title="Вид последнего ТО/ТР"
          dataIndex="sNazvanie"
          key="sNazvanie"
          onHeaderCell={onHeaderCell}
          onCell={onCellCenter}
        />
        <Column
          title="Дата последнего ТО/ТР"
          dataIndex="remVpskDate"
          key="remVpskDate"
          onHeaderCell={onHeaderCell}
          onCell={onCellCenter}
        />
        <Column
          title="Место последнего ТО/ТР"
          dataIndex="prDprName"
          key="prDprName"
          onHeaderCell={onHeaderCell}
          onCell={onCellCenter}
        />
      </ColumnGroup>
    </Table>
  );
};

export default OperationsOfBarrierFunctions;