import React, { useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import styles from "../OrganizationInspection.module.scss";
import AddTask from "./AddTask";
import ekasut from "../../../../api/ekasut";
import addWorkStyle from "./AdditionalWork.module.scss";
import SeriesList from "./SeriesList";
import { Space, Table, Tag } from "antd";
import { log } from "@antv/g2plot/lib/utils";

const AdditionalWork = ({ pred, periodFilter }) => {
  const [addTask, setAddTask] = useState(false);
  const [seriesListOpen, setSeriesListOpen] = useState(false);
  const [itemsList, setItemsList] = useState();
  const [tasksArr, setTasksArr] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTaskArr = async () => {
    const response = await ekasut.commisionInspection.getAdditionalWork({
      pTypeCO: localStorage.season,
      pYearCO: localStorage.seasonYear,
      pKodFirm: localStorage.kodFirm,
    });
    setTasksArr(response?.data);
  };

  useEffect(() => {
    getTaskArr();
  }, []);

  const columns = [
    {
      title: "Наименовение",
      dataIndex: "nameAxwork",
      key: "nameAxwork",
    },
    {
      title: "Уровень",
      dataIndex: "levFirm",
      key: "levFirm",
    },
    {
      title: "Серия",
      dataIndex: "serName",
      key: "serName",
    },
  ];

  const dataCT = tasksArr
    .filter((e) => e?.levFirm === "ЦТ")
    .map((e) => {
      return {
        key: e?.rn,
        nameAxwork: e?.nameAxwork,
        levFirm: e?.levFirm,
        serName: e?.serName,
      };
    });

  const dataT = tasksArr
    .filter((e) => e?.levFirm === "Т")
    .map((e) => {
      return {
        key: e?.rn,
        nameAxwork: e?.nameAxwork,
        levFirm: e?.levFirm,
        serName: e?.serName,
      };
    });

  const dataDepo = tasksArr
    .filter((e) => e?.levFirm === "ТЧЭ")
    .map((e) => {
      return {
        key: e?.rn,
        nameAxwork: e?.nameAxwork,
        levFirm: e?.levFirm,
        serName: e?.serName,
      };
    });

  return (
    <div>
      <span className={styles.nameSpace}>
        Перечень дополнительных работ в период КО
      </span>
      <Segment className={styles.workSegment}>
        {/* <Dimmer active={loading} inverted>
                    <Loader inverted>Загрузка</Loader>
                </Dimmer> */}
        <Grid className={styles.addWorkGrid}>
          <Grid.Column floated={"left"}>
            <Grid.Row>
              <Header
                as="h4"
                content="Доп. работы назначенные ЦТ"
                className={styles.addHeader}
              />
              <Table columns={columns} dataSource={dataCT} pagination={false} />
              {localStorage.kodFirm === "1111" && (
                <Button
                  disabled={localStorage.isActual === "1" ? true : false}
                  className="button-link"
                  basic
                  onClick={() => {
                    setAddTask(true);
                    localStorage.setItem("levFirm", 1);
                  }}
                >
                  <Icon name={"plus"} />
                  Добавить дополнительные работы
                </Button>
              )}
            </Grid.Row>

            <Grid.Row className='axwork-row'>
              {localStorage.kodFirm !== 1111 || localStorage.accessLevel === "7" && (
                <>
                  <Header
                    as="h4"
                    content="Доп. работы назначенные Т"
                    className={styles.addHeader}
                  />
                  <Table
                    columns={columns}
                    dataSource={dataT}
                    pagination={false}
                  />
                  {localStorage.kodFirm !== 1111 ||
                    (localStorage.kodFirm !== 30121 && (
                      <Button
                        disabled={localStorage.isActual === "1" ? true : false}
                        className="button-link"
                        basic
                        onClick={() => {
                          setAddTask(true);
                          localStorage.setItem("levFirm", 2);
                        }}
                      >
                        <Icon name={"plus"} />
                        Добавить дополнительные работы
                      </Button>
                    ))}
                </>
              )}
            </Grid.Row>

            {localStorage.kodFirm === "30121" && (
              <Grid.Row className='axwork-row'>
                <Header
                  as="h4"
                  content="Доп. работы назначенные Депо"
                  className={styles.addHeader}
                />
                <Table
                  columns={columns}
                  dataSource={dataDepo}
                  pagination={false}
                />
                <Button
                  disabled={localStorage.isActual === "1" ? true : false}
                  className="button-link"
                  basic
                  onClick={() => {
                    setAddTask(true);
                    localStorage.setItem("levFirm", 3);
                  }}
                >
                  <Icon name={"plus"} />
                  Добавить дополнительные работы
                </Button>
              </Grid.Row>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
      <AddTask
        setAddTask={setAddTask}
        addTask={addTask}
        periodFilter={periodFilter}
        pred={pred}
        tasksArr={tasksArr}
        getTaskArr={getTaskArr}
      />
      <SeriesList
        seriesListOpen={seriesListOpen}
        setSeriesListOpen={setSeriesListOpen}
        items={itemsList}
      />
    </div>
  );
};

export default AdditionalWork;
