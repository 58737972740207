import React from 'react'
import mainStyles from '../index.module.scss'
import {Icon, List} from "semantic-ui-react";
import styles from './EmployeesInColField.module.scss'
import cn from 'classnames'

// employees: [{fio, rateText, checked, ...}]
export default function EmployeesInColField(
    {
        employees, colNumber, onEdit, onInfo
    }
) {
    return (
        <div className={mainStyles.formField}>
            <label>Колонна {colNumber}</label>
            <List celled className={styles.list}>
                {employees.map((employee, index) => (
                    <List.Item
                        key={index}
                        className={cn(styles.listItem, employees[index].checked && styles.checkedItem)}
                    >
                        <div onClick={() => onEdit(employee)}>
                            <div>{employee.fullName}</div>
                            <div className={styles.rate}>{employee.rateText}</div>
                        </div>
                        <div onClick={() => onInfo(employee)}>
                            <Icon name='info' color='grey'/>
                        </div>
                    </List.Item>
                ))}
            </List>
        </div>
    )
}
EmployeesInColField.defaultProps = {
    employees: [],
    onEdit: () => {
    },
    onInfo: () => {
    },
    isEditIcons: false,
    colNumber: 'Номер колонны не определен'
}