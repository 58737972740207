import React, { useState } from "react";
import style from "./Header.module.scss";
import { Input, Spin } from "antd";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import ekasut from "../../../../../api/ekasut";
import { LoadingOutlined } from "@ant-design/icons";
import { DelTaskModal } from "./DelTaskModal/DelTaskModal";

const loadIcon = (
  <LoadingOutlined
    style={{ fontSize: 20, marginLeft: "10px", color: "rgba(0,0,0,0.5)" }}
    spin
  />
);

export const Header = ({ taskData, setTaskData }) => {
  const [showIcons, setShowIcons] = useState(false);
  const [editNameTask, setEditNameTask] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDel, setOpenDel] = useState(false);

  const updateTitle = () => {
    setLoading(true);
    ekasut.taskboard
      .updateTaskDescTitle(
        taskData.workGroupId,
        taskData.taskNumber,
        taskData.content,
        newTitle
      )
      .then(({ data }) => {
        setTaskData(data);
        setEditNameTask(false);
        setLoading(false);
      });
  };

  return (
    <div className={style.taskHeaderTitle}>
      <DelTaskModal
        openDel={openDel}
        setOpenDel={setOpenDel}
        taskdata={taskData}
        setShowIcons={setShowIcons}
      />
      <div
        className={style.taskHeaderActions}
        onMouseEnter={() => {
          if (taskData.workGroupUserRoleId !== 3) {
            setShowIcons(true);
          }
        }}
        onMouseLeave={() => {
          if (taskData.workGroupUserRoleId !== 3) {
            setShowIcons(false);
          }
        }}
      >
        {editNameTask ? (
          <Input
            disabled={loading}
            size={"large"}
            defaultValue={taskData.title}
            autoFocus
            bordered={false}
            onChange={(e) => {
              setNewTitle(e.target.value);
            }}
          />
        ) : (
          <span>{taskData.title}</span>
        )}
        {showIcons && !editNameTask ? (
          <div>
            <Icon
              name={"pencil"}
              color={"black"}
              onClick={() => {
                setEditNameTask(true);
              }}
            />
            <Icon
              name={"trash alternate outline"}
              color={"red"}
              onClick={() => {
                setOpenDel(true);
              }}
            />
          </div>
        ) : null}
        {editNameTask ? (
          <>
            <Icon
              name={"checkmark"}
              color={"green"}
              onClick={() => {
                updateTitle();
              }}
            />
            <Icon
              name={"delete"}
              color={"red"}
              onClick={() => {
                setEditNameTask(false);
              }}
            />
          </>
        ) : null}
        {loading ? <Spin indicator={loadIcon} /> : null}
      </div>
      <div className={style.taskHeaderDesc}>
        {`${taskData.workGroupName} #${taskData.taskNumber} cоздано ${moment(
          taskData.addedOn
        ).format("DD.MM.YYYY")} ${taskData.creatorName}`}
      </div>
    </div>
  );
};
