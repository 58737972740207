import React from 'react'
import AddPlan from "../components/AddPlan";
import TopHeader from '../components/TopHeader'
import Content from '../components/Content'
import {observer} from "mobx-react-lite";

const AddPlanToDate = observer(() => {

    return <>
        <TopHeader>
            Добавление плана
        </TopHeader>
        <Content>
            <AddPlan

            />
        </Content>
    </>
})

export default AddPlanToDate