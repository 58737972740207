import React, {useEffect, useState} from 'react';
import {Dimmer, Grid, Header, Loader, Segment} from "semantic-ui-react";
import styles from "../OrganizationInspection.module.scss";
import DateField from "../../../../shared/fields/DateField";
import moment from "moment";
import {Button} from "antd";
import style from './AddPlan.module.scss'
import ekasut from "../../../../api/ekasut";

export const AddPlan = ({periodFilter}) => {

    const [preparationDate, setPreparationDate] = useState({begin: null, end: null})
    const [inspDate, setInspDate] = useState({begin: null, end: null})
    const [analysisDate, setAnalysisDate] = useState({begin: null, end: null})
    const [loading, setLoading] = useState(true)
    const [editMode, setEditMode] = useState(true)

    useEffect(() => {
        if (periodFilter.yearCO) {
            ekasut.commisionInspection.getPlan({pTypeCo: periodFilter.typeCO, pYear: periodFilter.yearCO})
                .then(({data}) => {
                    setPreparationDate({
                        begin: data[0].dateBegPodgCo ? moment(data[0].dateBegPodgCo) : null,
                        end: data[0].dateEndPodgCo ? moment(data[0].dateEndPodgCo) : null
                    })
                    setInspDate({
                        begin: data[0].dateBegCosm ? moment(data[0].dateBegCosm) : null,
                        end: data[0].dateEndCosm ? moment(data[0].dateEndCosm) : null
                    })
                    setAnalysisDate({
                        begin: data[0].dateBegResCo ? moment(data[0].dateBegResCo) : null,
                        end: data[0].dateEndResCo ? moment(data[0].dateEndResCo) : null
                    })
                    if (data[0].dateBegCosm && data[0].dateBegPodgCo && data[0].dateBegResCo && data[0].dateEndCosm && data[0].dateEndPodgCo && data[0].dateEndResCo) {
                        setEditMode(true)
                    }
                    setLoading(false)
                })
        }
    }, [periodFilter])

    return (
        <Segment className={style.Segment}>
            <Dimmer active={loading} inverted>
                <Loader inverted>Загрузка</Loader>
            </Dimmer>
            <Grid className={styles.genInfoGrid}>
                <Grid.Row className={styles.infoRowGrid}>
                    <Grid.Column>
                        <Header as="h3" content="Планирование комиссионного осмотра"
                                className={styles.infoHeader}/>

                        <Header content="Подготовка комиссионного осмотра"
                                className={styles.planKOheader}/>
                        <Grid.Row className={styles.rowPlanKO}>
                            <DateField
                                disabled={editMode}
                                label="Начало"
                                inputClassName="date-underline"
                                dateFormat={'DD.MM.YYYY'}
                                date={preparationDate.begin}
                                onChange={(date) => {
                                    setPreparationDate({begin: date, end: preparationDate.end})
                                }}
                                closable
                            />
                            <DateField
                                disabled={editMode}
                                label="Окончание"
                                inputClassName="date-underline"
                                dateFormat={'DD.MM.YYYY'}
                                date={preparationDate.end}
                                onChange={(date) => {
                                    setPreparationDate({begin: preparationDate.begin, end: date})
                                }}
                                closable
                            />
                        </Grid.Row>
                        <Header content="Проведение комиссионного осмотра"
                                className={styles.planKOheader}/>
                        <Grid.Row className={styles.rowPlanKO}>
                            <DateField
                                disabled={editMode}
                                label="Начало"
                                inputClassName="date-underline"
                                dateFormat={'DD.MM.YYYY'}
                                date={inspDate.begin}
                                onChange={(date) => {
                                    setInspDate({begin: date, end: inspDate.end})
                                }}
                                closable
                            />
                            <DateField
                                disabled={editMode}
                                label="Окончание"
                                inputClassName="date-underline"
                                dateFormat={'DD.MM.YYYY'}
                                date={inspDate.end}
                                onChange={(date) => {
                                    setInspDate({begin: date, end: inspDate.end})
                                }}
                                closable
                            />
                        </Grid.Row>
                        <Header content="Анализ результатов комиссионного осмотра"
                                className={styles.planKOheader}/>
                        <Grid.Row className={styles.rowPlanKO}>
                            <DateField
                                disabled={editMode}
                                label="Начало"
                                inputClassName="date-underline"
                                dateFormat={'DD.MM.YYYY'}
                                date={analysisDate.begin}
                                onChange={(date) => {
                                    setAnalysisDate({begin: date, end: analysisDate.end})
                                }}
                                closable
                            />
                            <DateField
                                disabled={editMode}
                                label="Окончание"
                                inputClassName="date-underline"
                                dateFormat={'DD.MM.YYYY'}
                                date={analysisDate.end}
                                onChange={(date) => {
                                    setAnalysisDate({begin: date, end: analysisDate.end})
                                }}
                                closable
                            />
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {editMode ? <Button onClick={() => {
                    setEditMode(false)
                }} className={style.saveBtn}>Редактировать</Button> :
                <Button className={style.saveBtn}
                        disabled={!preparationDate.begin || !preparationDate.end || !inspDate.begin || !inspDate.end || !analysisDate.begin || !analysisDate.end}> Запланировать</Button>}
        </Segment>
    );
};