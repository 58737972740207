import React, {useReducer, useEffect} from 'react'
import styles from "../index.module.scss";
import DelayedSearch from "../../NsiWeightLength/DelayedSearch";
import cn from "classnames";
import appStyles from "../../../css/App.module.scss";
import {formOptions} from "../../../helpers/utils";

const DelayedSearchField = (
    {
        editable, label, typeEnd, list, loading, onChange, defIndex,
        defList, placeholder, defValue
    }
) => {
    const [state, dispatch] = useReducer(DelayedSearchFieldReducer,
        {
            options: [],
            isListChanged: false,
            defList: defList
        }
    )

    useEffect(() => {
        dispatch({type: 'updateOptions', list: defValue !== undefined ? [defValue] : list})
    }, [list, defValue])

    return (
        <div className={styles.formField}>
            <label>{label}</label>
            <DelayedSearch
                fluid
                placeholder={placeholder}
                minCharacters={3}
                delay={1000}
                className={cn(!editable && appStyles.fixedDropdown)}
                disabled={!editable}
                options={state.options}
                typeEnd={typeEnd}
                loading={loading}
                defaultValue={defValue !== undefined ? 0 : defIndex}
                onChange={(e, {value: index}) => {
                    onChange(list[index])
                }}
            />
        </div>
    )
}
function DelayedSearchFieldReducer(state, action) {
    if (action.type === 'updateOptions') {
        if (state.defList !== undefined && !state.isListChanged) {
            return {
                options: formOptions(state.defList, 'text'),
                isListChanged: true
            }
        } else {
            return {
                options: formOptions(action.list, 'text'),
                isListChanged: true,
            }
        }
    }
}


export default DelayedSearchField