import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { Component } from "react";
import axios from "axios";
import auth from "./helpers/authNewAdm";
import {
  BrowserRouter as Router,
  Route,
  Link,
  withRouter,
  Switch,
} from "react-router-dom";

import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "semantic-ui-css-offline/semantic.min.css";
import "rc-tree/assets/index.css";
import "react-image-lightbox/style.css";
import "./css/App.scss";
import "react-month-picker/css/month-picker.css";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "./css/fixLibraries/dvf-lint-fix.css"; // v0.3.1
import "./css/polygon.scss"; // v0.3.1
import "./css/semantic-alteration.scss";
import "./shared/fields/field.scss";

import { Icon, Menu } from "semantic-ui-react";
import { Sidebar, Segment, Button } from "semantic-ui-react";
import { Popup, Dropdown } from "semantic-ui-react";
import { Image } from "semantic-ui-react";
import { Modal, Grid, Header, Message } from "semantic-ui-react";
import logo from "./assets/images/logo.png";

import TransferFromTr from "./views/TransferFromTr";
import Checklist from "./views/notUsed/LocomotiveChecklist/Checklist";
import ReceiverRating from "./views/ReceiverRating";
import TenDaySchedule from "./views/PlanRepairs/DecadePlan";
import PolygonScheme from "./views/LocomotiveDislocation/PolygonScheme2";
import Notifications from "./views/Notifications";
import Settings from "./views/notUsed/Settings";
import RatingsWorkLocomotives from "./views/RatingsWorkLocomotives";
import LocomotivesInCategories from "./views/LocomotivesInCatergories";
import Exceptions from "./views/notUsed/Exceptions";
import ControlRoles from "./views/ControlRoles";
import ControlServices from "./views/ControlServices";
import PlanWorkMobX from "./views/notUsed/PlanWorkMobX";
import OperativePlan from "./views/PlanRepairs/OperativePlan";
import Locomotive from "./views/Locomotive";

// don't need this anymore
// import EpNep from './views/EpNep'

import settings from "./helpers/settings";
import LogControlManagement from "./views/DispatchingControlAccess/BarriersManagement";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import RatingsWorkBrigades from "./views/RatingsWorkBrigades";
import CardLocomotiveDepot from "./views/CardLocomotiveDepot";
import NsiWeightLength from "./views/NsiWeightLength";
import MonitorBarrierFunctions from "./views/DispatchingControlAccess/MonitorBarriers";
import WorkingCapacityEtso from "./views/WorkingCapacityETSO";
import MonitorAutomaticBind from "./views/MonitorAutomaticBind";
import NetJournalWork from "./views/notUsed/NetJournalPresence";
import UsingPowerSld from "./views/UsingPowerSld";
import DriverInstructorProfile from "./views/notUsed/PlanWork";
import PlanWorkBrigades from "./views/PlanWorkBrigades";
import InvestigationLb from "./views/InvestigationLb";
import LocationTchmi from "./views/notUsed/LocationTcmi";
import RecommendationSelectionLocomotive from "./views/RecommendationSelectionLocomotive";
import init from "./views/App/init";
import AuditControl from "./views/AuditControl";
import ProcurementPlanning from "./views/ProcurementPlanning";
import ModalError from "./shared/ModalError";
import ekasut from "./api/ekasut";
import dbSync from "./api/dbSync";
import storeContext from "./globalStore/storeContext";
import PromptModal from "./shared/PromptModal";
import { Observer } from "mobx-react-lite";
import Quiz from "./views/Quiz";
import Version from "./views/Version";
import Login from "./views/App/Login";
import appStyles from "./css/App.module.scss";
import cn from "classnames";
import TransferToTr from "./views/TransferToTr";
import GroupRiskLocomotives from "./views/GroupRiskLocomotives/GroupRiskLocomotives";

init();

// Translate to russia
HC_exporting(Highcharts);
Highcharts.setOptions({
  global: {
    timezoneOffset: -3 * 60,
  },
  lang: {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    weekdays: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
    shortMonths: [
      "Янв",
      "Февр",
      "Март",
      "Апр",
      "Май",
      "Июнь",
      "Июль",
      "Авг",
      "Сент",
      "Окт",
      "Ноябрь",
      "Дек",
    ],
    contextButtonTitle: "Меню графика",
    printChart: "Распечатать график",
    downloadPNG: "Загрузить изображение PNG",
    downloadJPEG: "Загрузить изображение JPEG",
    downloadPDF: "Загрузить документ PDF",
    downloadSVG: "Загрузить документ SVG",
    exportButtonTitle: "Экспорт графика",
    loading: "Загрузка...",
    printButtonTitle: "Распечатать график",
    resetZoom: "Сброс увеличения",
    resetZoomTitle: "Сброс уровня масштабирования до 1:1",
    thousandsSep: " ",
    decimalPoint: ",",
  },
});

Dropdown.defaultProps.noResultsMessage = "Нет результатов.";

// class LoginOld extends React.Component {
//   state = { redirectToReferrer: false, errorMessage: null };

//   componentDidMount() {
//     if (this.props.location.hash !== "") {
//       auth.ssoLogin(this.props.location.hash, (loggedIn, errorMessage) => {
//         if (loggedIn) {
//           // to set notifications for current user:

//           return this.setState({ redirectToReferrer: true });
//         } else {
//           return this.setState({ errorMessage: errorMessage });
//         }
//       });
//     } else {
//       window.location = `${window.backendUrl}/Auth/Login?fromUrl=${window.location.href}`;
//     }
//   }

//   render() {
//     // const { from } = this.props.location.state || { from: { pathname: "/" } };
//     const { redirectToReferrer } = this.state;

//     // because I don't know how to do it better right now
//     if (redirectToReferrer) {
//       // window.location.reload()

//       const helper = () => (document.location.href = "/");

//       settings.getNotificationsFromServer(axios, helper);
//     }

//     return (
//       <div className="signin">
//         <Modal
//           open={true}
//           closeOnDimmerClick={false}
//           style={{ width: 450, padding: 15 }}
//         >
//           <Modal.Content>
//             <Modal.Description>
//               <Grid
//                 textAlign="center"
//                 style={{ height: "100%" }}
//                 verticalAlign="middle"
//               >
//                 <Grid.Column style={{ maxWidth: 450 }}>
//                   <Header as="h2" color="teal" textAlign="center">
//                     <Image src={logo} style={{ width: 268 }} />
//                   </Header>
//                   {this.state.errorMessage ? (
//                     <Message style={{ backgroundColor: "rgb(239, 222, 222)" }}>
//                       {this.state.errorMessage}
//                     </Message>
//                   ) : null}
//                 </Grid.Column>
//               </Grid>
//             </Modal.Description>
//           </Modal.Content>
//         </Modal>
//       </div>
//     );
//   }
// }

class MainMenu extends React.Component {
  state = {
    menu: [
      {
        group: "Основное",
        icon: "home",
        children: [
          /*
                    {
                        link: "/help",
                        visibilityKeys: "help_view",
                        title: "Помощь",
                        hasFilters: false
                    },
                    */
          {
            link: "/settings",
            visibilityKeys: "map_view",
            title: "Настройки",
            hasFilters: false,
          },
          {
            link: window.brigades,
            visibilityKeys: "brigades",
            title: "Бригады",
            static: true,
            hasFilters: false,
          },
        ],
      },
      {
        group: "Анализ информации БС ТПС",
        icon: "subway",
        children: [
          {
            link: "/polygon-scheme",
            visibilityKeys: "map_view",
            title: "Схема полигона",
            hasExcel: true,
          },
          // don't need this anymore
          /*{
                        link: "/ep-nep",
                        visibilityKeys: "manag_stat",
                        title: "ЭП и НЭП",
                        hasExcel: true,
                        hasFilters: false
                    },*/
          {
            link: "/polygon-ratings/eprp",
            visibilityKeys: "manag_stat",
            title: "Рейтинги и показатели",
            hasFilters: false,
            hasExcel: false,
          },
        ],
      },
      {
        group: "Блок управления персоналом",
        icon: "user outline",
        children: [
          {
            link: "/order-execution",
            visibilityKeys: "order_execution",
            title: "Выполнение наряд-заказа",
            hasFilters: false,
          },
          {
            link: "/net-journal-work",
            visibilityKeys: "online_turnout_log",
            title: "Сетевой журнал явок",
            hasFilters: false,
          },
          {
            link: "/monitor-automatic-bind",
            visibilityKeys: "automated_garter_monitoring",
            title: "Мониторинг автоматизированной подвязки",
            hasFilters: false,
          },
          {
            link: "/brigades-infringements-incidents-investigation",
            visibilityKeys: "Incident_investigation",
            title: "Расследование инцидентов и нарушений в работе ЛБ",
            hasFilters: false,
          },
          {
            link: "/location-tchmi",
            visibilityKeys: "Location_TCHMI",
            title: "Местоположение ТЧМИ",
            hasFilters: false,
          },
        ],
      },
      {
        group: "Приёмка продукции",
        icon: "thumbs up",
        children: [
          {
            link: "/set-to-repair",
            visibilityKeys: "repair_waiting",
            title: "Передача в ТО/ТР",
          },
          {
            link: "/get-from-repair",
            visibilityKeys: "repair_finishing",
            title: "Выдача из ТО/ТР",
          },
          {
            link: "/checklist",
            visibilityKeys: "checklist_view",
            title: "Чек-лист локомотива",
            hasFilters: false,
          },
          {
            link: "/rating",
            visibilityKeys: "examiner_rating",
            title: "Рейтинг приёмщика",
            hasExcel: true,
          },
        ],
      },
      {
        group: "Цифровое депо",
        icon: "industry",
        children: [
          {
            link: window.digitalDepotUrl,
            visibilityKeys: "balance_park",
            static: true,
            title: "Расчёт баланса парка",
          },
          {
            link: "/monitor-working-capacity",
            visibilityKeys: "ETSO_monitoring",
            title: "Мониторинг работоспособности ЭТСО",
            hasFilters: false,
          },
          {
            link: "/loc-tug-segment-binding",
            visibilityKeys: "shoulder_garter",
            title: "Рекомендации по подбору локомотивов",
            hasFilters: false,
          },
          {
            link: "/audit-control",
            visibilityKeys: "audit_defence",
            title: "Управление аудитами",
            hasFilters: false,
            hasExcel: false,
          },
        ],
      },
      {
        group: "Управление сервисом",
        icon: "wrench",
        children: [
          {
            link: "/operative-plan",
            visibilityKeys: "Operative_plan",
            title: "Оперативный график",
            hasFilters: false,
            hasExcel: false,
          },
          {
            link: "/ten-day-schedule",
            visibilityKeys: "decade_planning_adm",
            title: "Декадное планирование",
            hasFilters: false,
          },
          {
            link: "/monitor-power",
            visibilityKeys: "capacity_monitoring",
            title: "Мониторинг использования мощностей СЛД",
            hasFilters: false,
          },
        ],
      },
      {
        group: "Оперативная отчётность",
        icon: "bar chart",
        children: [
          {
            link: window.pentahoReportsUrl,
            visibilityKeys:
              "report_1,report_2,report_3,report_4,report_5,report_6,report_adm",
            static: true,
            title: "Портал отчётности ЕК АСУТ",
          },
          {
            link: "/locs-in-categories",
            visibilityKeys: "report_2",
            title: "Локомотивы в категориях",
            hasFilters: true,
          },
          {
            link: "/risk-tps",
            visibilityKeys: "risk_tps",
            title: "ТПС группы риска",
            hasExcel: true,
          },
        ],
      },
      {
        group: "Базовый блок",
        icon: "setting",
        children: [
          {
            link: "/roles-management",
            visibilityKeys: "roles_adm",
            title: "Управление ролями",
            hasFilters: false,
          },
          {
            link: "/services-management",
            visibilityKeys: "service_adm",
            title: "Управление сервисами",
            hasFilters: false,
          },
          {
            link: "/log-control-management",
            visibilityKeys: "barrier_adm",
            title: "Управление барьерными функциями",
            hasFilters: false,
          },
          {
            link: "/monitor-barrier-functions",
            visibilityKeys: "barrier_monitoring",
            title: "Мониторинг работы барьерных функций",
            hasFilters: false,
          },
          {
            link: "/locomotives-weight-length",
            visibilityKeys: "nsi_weight_length",
            title: "НСИ о весе и длине поездов",
            hasFilters: false,
          },
          {
            link: "/depot-card",
            visibilityKeys: "locomotive_depot_card",
            title: "Карточка локомотивного депо",
            hasFilters: false,
          },
          {
            link: "/procurement-planning",
            visibilityKeys: "approval_purchases",
            title: "Ведение процессов ценообразования",
            hasFilters: false,
            hasExcel: false,
          },
        ],
      },
      {
        group: "Личный кабинет машиниста инструктора",
        icon: "user outline",
        children: [
          {
            link: "/driver-instructor-profile",
            visibilityKeys: "lk_tchmi_profile",
            title: "Мой профиль",
            hasFilters: false,
            hasExcel: false,
          },
          // todo: replace with mobx version
          {
            link: "/driver-instructor-plan",
            visibilityKeys: "lk_tchmi_work_plan",
            title: "План работ",
            hasFilters: false,
            hasExcel: false,
          },
          /*                    {
                                            link: '/plan-work-mobx',
                                            visibilityKeys: 'lk_tchmi_work_plan',
                                            title: 'План работ (mobx)',
                                            hasFilters: false,
                                            hasExcel: false
                                        },*/
        ],
      },
    ],
  };

  goToReferrer(element) {
    if (element.static) {
      // window.location.href = element.link
      window.location.replace(element.link);
    } else {
      this.props.hideMenuButtons(element.hasFilters, element.hasExcel);
      this.props.hideSidebars();
    }
  }

  grants = auth.getGrants();

  // to prevent draw useless buttons on the top after 'f5'
  componentDidMount() {
    const { location } = this.props;
    let matches = [];

    this.state.menu.forEach(function (e) {
      matches = matches.concat(
        e.children.filter(function (c) {
          return c.link === location.pathname;
        })
      );
    });

    // console.log(matches[0] || "Not found");
    if (matches[0]) {
      // fixme: rerender twice
      this.props.hideMenuButtons(matches[0].hasFilters, matches[0].hasExcel);
    }
  }

  render() {
    const { location } = this.props;

    let differenceHelper = function (a, b) {
      let ret = [];
      for (let i in a) {
        if (b.indexOf(a[i]) > -1) {
          ret.push(a[i]);
        }
      }
      return ret;
    };

    // FIXME: "[].concat.apply([],..." is very expensive, need to refactor

    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        width="wide"
        visible={this.props.visibleMenu}
        vertical
        inverted
      >
        {this.state.menu.map((group, index) => {
          return differenceHelper(
            [].concat.apply(
              [],
              group.children.map((el) => el.visibilityKeys.split(","))
            ),
            this.grants
          ).length > 0 ||
            differenceHelper(
              group.children.map((el) => el.visibilityKeys.split(",")),
              "help_view"
            ).length > 0 ? (
            <Menu.Item
              key={index}
              className={group.collapse ? "menu-item-collapse" : ""}
            >
              <Menu.Header
                onClick={() => {
                  const menu = [...this.state.menu];
                  menu[index] = {
                    ...this.state.menu[index],
                    collapse: !this.state.menu[index].collapse,
                  };
                  this.setState({ menu });
                }}
              >
                <Icon name={group.icon} />
                &nbsp;&nbsp;{group.group}&nbsp;&nbsp;{}
              </Menu.Header>

              {!group.collapse &&
                group.children.map((element, idx) => {
                  return (
                    <Menu.Menu key={idx}>
                      {differenceHelper(
                        element.visibilityKeys.split(","),
                        this.grants
                      ).length > 0 || element.visibilityKeys === "help_view" ? (
                        location.pathname === element.link ? (
                          <Menu.Item as="a" className="item hidden">
                            {element.title}
                          </Menu.Item>
                        ) : element.static ? (
                          <a href={element.link}>
                            <Menu.Item
                              as={Button}
                              style={{ textAlign: "left" }}
                              active
                              content={element.title}
                            />
                          </a>
                        ) : (
                          <Link
                            to={element.link}
                            onClick={() => {
                              this.props.hideMenuButtons(
                                element.hasFilters,
                                element.hasExcel
                              );
                              this.props.hideSidebars();
                            }}
                          >
                            <Menu.Item
                              as={Button}
                              style={{ textAlign: "left" }}
                              active
                              content={element.title}
                            />
                          </Link>
                        )
                      ) : null}
                    </Menu.Menu>
                  );
                })}
            </Menu.Item>
          ) : null;
        })}
      </Sidebar>
    );
  }
}

// Create a new component that is "connected" (to borrow redux
// terminology) to the router.
const MainMenuWithRouter = withRouter(MainMenu);

class App extends Component {
  state = {
    visibleMenu: false,
    visibleFilters: false,
    filterInMenu: true,
    excelInMenu: true,
    excelExecution: false,
    error: null,
    errorOnClose: null,
    loggedIn: false,
  };

  toggleVisibilityMenu = () =>
    this.setState({ visibleMenu: !this.state.visibleMenu });
  toggleVisibilityFilters = () =>
    this.setState({ visibleFilters: !this.state.visibleFilters });
  hideSidebars = () => {
    if (this.state.visibleMenu) {
      this.setState({ visibleMenu: false, visibleFilters: false });
    }
  };
  // if hasFilters is undefined - we will show 'filter' button on header's right corner
  hideMenuButtons = (hasFilters, hasExcel) =>
    this.setState({
      filterInMenu: hasFilters === undefined ? true : hasFilters,
      excelInMenu: hasExcel === undefined ? false : hasExcel,
    });

  excelExecutionOn = () => this.setState({ excelExecution: true });
  excelExecutionOff = () => this.setState({ excelExecution: false });

  goToMain() {
    window.location = window.location.origin;
  }

  componentDidMount = () => {
    // For work with api without token!
    ekasut.init({ baseURL: window.backendUrl });
    dbSync.init({ baseURL: window.dbsyncUrl });
    ekasut.addErrorListener((error) => this.setState({ error }));
    dbSync.addErrorListener((error) => this.setState({ error }));
  };

  static contextType = storeContext;

  render() {
    const {
      visibleMenu,
      visibleFilters,
      filterInMenu,
      excelInMenu,
      excelExecution,
    } = this.state;

    let filterMenuItem, excelMenuItem;

    if (filterInMenu) {
      filterMenuItem = (
        <Menu.Item>
          <Popup
            trigger={
              <Button
                onClick={this.toggleVisibilityFilters}
                circular
                icon="filter"
              />
            }
            content="Фильтр"
          />
        </Menu.Item>
      );
    } else {
      filterMenuItem = null;
    }

    if (excelInMenu) {
      excelMenuItem = (
        <Menu.Item>
          <Popup
            trigger={
              <Button
                onClick={this.excelExecutionOn}
                circular
                loading={excelExecution}
                disabled={excelExecution}
                icon={"file excel"}
              />
            }
            content="Excel"
          />
        </Menu.Item>
      );
    } else {
      excelMenuItem = null;
    }

    return (
      <div className={cn(appStyles.compatibleOldMenu, "hello")}>
        <Router>
          <Observer>
            {() => (
              <PromptModal
                {...this.context.prompt.props}
                onDecline={() => {
                  this.context.prompt.props.onDecline();
                  this.context.prompt.close();
                }}
                onClose={() => {
                  this.context.prompt.props.onClose();
                  this.context.prompt.close();
                }}
              />
            )}
          </Observer>
          <ModalError
            error={this.state.error}
            onClose={() => {
              if (this.state.errorOnClose) {
                this.state.errorOnClose();
              }
              this.setState({
                error: null,
                errorOnClose: null,
              });
            }}
          />

          <Switch>
            <Route exact path="/version" render={() => <Version />} />
            <Route exact path="/quiz" render={() => <Quiz />} />
            {this.state.loggedIn && (
              <Route path="/">
                <div className="main-container">
                  <Menu className="top">
                    <Menu.Item>
                      <Button
                        onClick={this.toggleVisibilityMenu}
                        circular
                        icon="sidebar"
                      />
                    </Menu.Item>
                    <Menu.Item onClick={this.hideSidebars}>
                      <Image
                        src={logo}
                        size="small"
                        style={{ width: 155 }}
                        onClick={() => this.goToMain()}
                      />
                    </Menu.Item>

                    <Menu.Menu position="right">
                      {filterMenuItem}
                      {excelMenuItem}
                      <Menu.Item>
                        <Popup
                          trigger={
                            <Link to="/login">
                              <Button
                                onClick={() => auth.logout()}
                                circular
                                icon="sign out"
                              />
                            </Link>
                          }
                          content="Выход"
                        />
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                  <Sidebar.Pushable as={Segment} className="sidebar-container">
                    <MainMenuWithRouter
                      visibleMenu={visibleMenu}
                      hideMenuButtons={this.hideMenuButtons}
                      hideSidebars={this.hideSidebars}
                    />

                    {auth.loggedIn() ? (
                      <div>
                        <Route
                          exact
                          path="/"
                          render={() => (
                            <div className="route-container">
                              <PolygonScheme
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                                excelExecution={excelExecution}
                                excelExecutionOff={this.excelExecutionOff}
                              />
                            </div>
                          )}
                        />
                        <Route
                          path="/locomotive"
                          render={() => <Locomotive />}
                        />
                        <Route
                          exact
                          path="/set-to-repair"
                          render={() => (
                            <div className="route-container">
                              <TransferToTr
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/get-from-repair"
                          render={() => (
                            <div className="route-container">
                              <TransferFromTr
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/checklist"
                          render={() => (
                            <div className="route-container">
                              <Checklist hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/rating"
                          render={() => (
                            <div className="route-container">
                              <ReceiverRating
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                                excelExecution={excelExecution}
                                excelExecutionOff={this.excelExecutionOff}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/ten-day-schedule"
                          render={() => (
                            <div className="route-container">
                              <TenDaySchedule
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />

                        <Route
                          exact
                          path="/polygon-scheme"
                          render={() => (
                            <div className="route-container">
                              <PolygonScheme
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                                excelExecution={excelExecution}
                                excelExecutionOff={this.excelExecutionOff}
                              />
                            </div>
                          )}
                        />

                        {/*don't need this anymore*/}
                        {/*<Route exact path="/ep-nep"
                                   render={()=><EpNep visibleFilters={visibleFilters} hideSidebars={this.hideSidebars}/>}/>*/}

                        <Route
                          exact
                          path="/notifications-list"
                          render={() => (
                            <div className="route-container">
                              <Notifications hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/settings"
                          render={() => (
                            <div className="route-container">
                              <Settings hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          path="/polygon-ratings"
                          render={(props) => (
                            <div className="route-container">
                              <RatingsWorkLocomotives
                                {...props}
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/locs-in-categories"
                          render={() => (
                            <div className="route-container">
                              <LocomotivesInCategories
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                                toggleVisibilityFilters={
                                  this.toggleVisibilityFilters
                                }
                              />
                            </div>
                          )}
                        />

                        <Route
                          exact
                          path="/roles-management"
                          render={() => (
                            <div className="route-container">
                              <ControlRoles hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/services-management"
                          render={() => (
                            <ControlServices hideSidebars={this.hideSidebars} />
                          )}
                        />
                        <Route
                          exact
                          path="/log-control-management"
                          render={() => (
                            <div className="route-container">
                              <LogControlManagement
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/risk-tps"
                          render={() => (
                            <div>
                              <GroupRiskLocomotives
                                visibleFilters={visibleFilters}
                                hideSidebars={this.hideSidebars}
                                excelExecution={excelExecution}
                                excelExecutionOff={this.excelExecutionOff}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/exceptions"
                          render={() => (
                            <div className="route-container">
                              <Exceptions />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/locomotives-weight-length"
                          render={() => (
                            <div className="route-container">
                              <NsiWeightLength
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/depot-card"
                          render={() => (
                            <div className="route-container">
                              <CardLocomotiveDepot
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/order-execution"
                          render={() => (
                            <div className="route-container">
                              <RatingsWorkBrigades
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/monitor-barrier-functions"
                          render={() => (
                            <div className="route-container">
                              <MonitorBarrierFunctions
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/net-journal-work"
                          render={() => (
                            <div className="route-container">
                              <NetJournalWork
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/monitor-working-capacity"
                          render={() => (
                            <div className="route-container">
                              <WorkingCapacityEtso
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/monitor-automatic-bind"
                          render={() => (
                            <div className="route-container">
                              <MonitorAutomaticBind
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/brigades-infringements-incidents-investigation"
                          render={() => (
                            <div className="route-container">
                              <InvestigationLb
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/monitor-power"
                          render={() => (
                            <div className="route-container">
                              <UsingPowerSld hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/driver-instructor-profile"
                          render={() => (
                            <div className="">
                              <DriverInstructorProfile
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          path="/driver-instructor-plan"
                          render={() => (
                            <div className="">
                              <PlanWorkBrigades
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          path="/plan_work"
                          render={() => (
                            <div className="route-container">
                              <PlanWorkMobX />
                            </div>
                          )}
                        />
                        <Route
                          exact
                          path="/location-tchmi"
                          render={() => (
                            <div className="route-container">
                              <LocationTchmi hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          path="/loc-tug-segment-binding"
                          render={() => (
                            <div className="route-container">
                              <RecommendationSelectionLocomotive
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                        <Route
                          path="/operative-plan"
                          render={() => (
                            <div className="route-container">
                              <OperativePlan hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          path="/audit-control"
                          render={() => (
                            <div className="route-container">
                              <AuditControl hideSidebars={this.hideSidebars} />
                            </div>
                          )}
                        />
                        <Route
                          path="/procurement-planning"
                          render={() => (
                            <div className="route-container">
                              <ProcurementPlanning
                                hideSidebars={this.hideSidebars}
                              />
                            </div>
                          )}
                        />
                      </div>
                    ) : (
                      <Route path="/" component={Login} />
                    )}
                  </Sidebar.Pushable>
                </div>
              </Route>
            )}
            <Route
              path="/"
              render={() => (
                <Login
                  initApi={(token) => {
                    // support old code
                    axios.defaults.headers.common[
                      "Authorization"
                    ] = `Bearer ${token}`;
                    ekasut.init({ baseURL: window.backendUrl, token });
                    dbSync.init({ baseURL: window.dbsyncUrl, token });
                    ekasut.addErrorListener((error) =>
                      this.setState({ error })
                    );
                    dbSync.addErrorListener((error) =>
                      this.setState({ error })
                    );
                  }}
                  onLogged={(token) => {
                    // todo: add token for axios instances, not globally (all requests in App should use ekasut or dbSync)
                    axios.defaults.headers.common[
                      "Authorization"
                    ] = `Bearer ${token}`; // support old code
                    /* ekasut.init({baseURL: window.backendUrl, token})
                                    dbSync.init({baseURL: window.dbsyncUrl, token})*/
                    this.setState({ loggedIn: true });
                  }}
                  onError={(error) => {
                    this.setState({
                      error,
                      errorOnClose: () =>
                        (window.location = `${window.backendUrl}/Auth/Login?fromUrl=${window.location.href}`),
                    });
                  }}
                  onInvalidToken={() => {
                    window.location = `${window.backendUrl}/Auth/Login?fromUrl=${window.location.href}`;
                  }}
                />
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
