import React from "react";

export const onHeaderCell = () => {
  return {
    style: {
      background: 'none',
      fontSize: '12px',
      fontWeight: '700',
      padding: '8px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textAlign: 'center'
    }
  }
}

export const onCell = () => {
  return {
    style: {
      background: 'none',
      fontSize: '12px',
      padding: '8px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
  }
}

export const onCellCenter = () => {
  return {
    style: {
      background: 'none',
      fontSize: '12px',
      padding: '8px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textAlign: 'center'
    }
  }
}

export const onCellSvg = () => {
  return {
    style: {
      background: 'none',
      fontSize: '12px',
      padding: '8px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      textAlign: 'center'
    }
  }
}

export const changeDateForText = (text) => {
  return (
    <>
      {new Date(text).toLocaleString()}
    </>
  )
}

export const tableBorderStyle = {
  border: '1px solid black',
  borderRadius: '1px'
}