import React, {useEffect, useMemo} from 'react';
import AuditStepsWidget from "./AuditStepsWidget";
import DropdownField from "../../shared/fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {Dropdown, Input, Table} from "semantic-ui-react";
import TextAreaAutosize from 'react-textarea-autosize'
import './CheckProcesses.scss'
import {toJS} from "mobx";
import {deepClone} from "../../helpers/utils";
import {getProcessQuestions} from "./functions";
import {usePrompt} from "../../globalStore/useGlobalStore";

const CheckProcesses = observer(({onClose, onComplete, audit = {}, onView, editable, step, viewStep, display}) => {

    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        processId: null,

        processes: [],
        initialProcesses: [],
        wasFirstDisplay: false,
        // data from api
        grades: [], // оценки
        reasons: [], // несоотвествия
    }))

    const getQuestions = () => {
        return ekasut.audit.getQuestions({auditId: audit.auditId})
            .then(({data}) => {
                const restructuredData = data.map(item => ({
                    processId: item.processId,
                    processName: item.processName,
                    questions: getProcessQuestions(item)
                }))
                ls.processes = restructuredData
                ls.initialProcesses = restructuredData
            }).catch(catchNetworkErrors)
    }

    useEffect(() => {
        if (display && !ls.wasFirstDisplay) {
            ls.wasFirstDisplay = true
            getQuestions()
            ekasut.audit.getNsiGrades()
                .then(({data}) => ls.grades = data).catch(catchNetworkErrors)
            ekasut.audit.getNsiReasons().then(({data}) => ls.reasons = data).catch(catchNetworkErrors)
        }
    }, [display, ls.wasFirstDisplay])

    const checkForm = () => {
        for (let i = 0; i < ls.processes.length; i++) {
            for (let j = 0; j < ls.processes[i].questions.length; j++) {
                const question = ls.processes[i].questions[j]
                if ((question.description === "" || question.description === null) &&
                    question.gradeId === null && question.reasonId === null) {
                    continue
                }
                if (question.description === "" || question.gradeId === null || question.reasonId === null) {
                    return false
                }
            }
        }
        return true
    }
    const save = (complete) => {
        prompt.show("Сохранение результатов проверки", "", {loading: true})
        const changedQuestions = findChangedQuestions(ls.initialProcesses, ls.processes)
        const deleteQuestions = changedQuestions.filter(item =>
            (item.description === "" || item.description === null) && item.gradeId === null && item.reasonId === null
        )
        const postQuestions = changedQuestions.filter(item =>
            (item.description !== "" || item.description !== null) && item.gradeId !== null && item.reasonId !== null
        )

        const postReq = () => postQuestions.length > 0 ? ekasut.audit.postQuestions({
            auditId: audit.auditId,
            data: postQuestions.map(question => ({
                questionId: question.questionId,
                gradeId: question.gradeId,
                description: question.description,
                reasonId: question.reasonId
            }))
        }) : new Promise((resolve) => resolve())


        postReq()
            .then(() => {
                return deleteQuestions.length > 0 ? ekasut.audit.deleteQuestions({
                    auditId: audit.auditId,
                    questionIds: deleteQuestions.map(question => (question.questionId))
                }) : new Promise((resolve) => resolve())
            })
            .then(() => {
                return getQuestions()
            })
            .then(() => {
                if (complete) {
                    onComplete()
                } else {
                    prompt.show("Результаты проверки сохранены", "", {ok: true})
                }
            })
            .catch((err) => {
                prompt.close()
                return catchNetworkErrors(err)
            })
    }

    const processOptions = useMemo(() =>
            ls.processes.map((item, index) => ({key: item.processId, value: item.processId, text: item.processName})),
        [ls.processes])

    /** Arrays should have same order */
    const findChangedQuestions = (processesA, processesB) => {
        const diff = []
        processesA.forEach((processA, processIndex) => {
            const processB = processesB[processIndex]
            processA.questions.forEach((questionA, questionIndex) => {
                const questionB = processB.questions[questionIndex]

                // null and "" is both mean empty data
                const changed = (v1, v2) => (v1 === null || v1 === "") && (v2 === null || v2 === "") ? false : v1 !== v2

                if (changed(questionA.description, questionB.description)
                    || changed(questionA.gradeId, questionB.gradeId)
                    || changed(questionA.reasonId, questionB.reasonId)
                ) {
                    console.log('changed', toJS(questionB, {recurseEverything: true}))
                    diff.push(questionB)
                }
            })
        })
        return diff
    }

    if (!display) {
        return false
    }

    console.log('render', toJS(ls, {recurseEverything: true}))

    return (
        <AuditStepsWidget
            className="app check-processes"
            onClose={onClose}
            onCancel={onClose}
            onView={onView}
            step={step}
            viewStep={viewStep}
            /*exportButtonText="Экспорт чек-листа в Word"*/
            displayButtons={{cancel: true, exportDoc: false, save: true, end: true}}
            onSave={() => {
                if (checkForm()) {
                    save(false)
                } else {
                    prompt.show("Завершение этапа не выполнено", "Все поля должны быть заполнены", {ok: true})
                }
            }}
            onEnd={() => {
                if (checkForm()) {
                    prompt.show("Вы действительно хотите завершить этап?", "",
                        {yesNo: true, onApply: () => save(true)}
                    )
                } else {
                    prompt.show("Завершение этапа не выполнено", "Все поля должны быть заполнены", {ok: true})
                }
            }}
        >
            <DropdownField
                label="Процесс" options={processOptions} value={ls.processIndex}
                clearable dropdownClassName="dropdown-underline dropdown-wrap-menu"
                onChange={(e, {value}) => {
                    ls.processId = value !== "" ? ls.processes.find(item => item.processId === value).processId : null
                }}
            />
            {ls.processId !== null && (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell content="Критерий"/>
                            <Table.HeaderCell content="Способ оценки"/>
                            <Table.HeaderCell content="Выполнения критерия" width={3}/>
                            <Table.HeaderCell content="Причины несоответствия" width={3}/>
                            <Table.HeaderCell content="Оценка" width={3}/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {ls.processes.find(item => item.processId === ls.processId).questions.map((question, index) => (
                            <Table.Row key={question.questionId}>
                                <Table.Cell>{question.criterionName}</Table.Cell>
                                <Table.Cell>{question.question}</Table.Cell>
                                <Table.Cell>
                                    {editable && (
                                        <TextAreaAutosize
                                            // on focus out
                                            onBlur={(e) => {
                                                question.description = e.currentTarget.value
                                            }}
                                            placeholder="Ответ" className="answer"
                                            // Limit height of area. Scroll may appear before specified amount of rows (but close to it)
                                            maxRows={10}
                                            defaultValue={question.description === null ? "" : question.description}
                                        />
                                    )}
                                    {!editable && question.description}
                                </Table.Cell>
                                <Table.Cell>
                                    {editable && (
                                        <Dropdown
                                            placeholder="Ответ"
                                            options={ls.reasons.map(item => ({key: item.code, value: item.code, text: item.name}))}
                                            className="dropdown-wrap-menu reason"
                                            value={question.reasonId} clearable
                                            onChange={(e, {value}) => {
                                                question.reasonId = value === "" ? null : ls.reasons.find(item => item.code === value).code
                                            }}
                                        />
                                    )}
                                    {!editable && question.reasonName}
                                </Table.Cell>
                                <Table.Cell>
                                    {editable && (
                                        <Dropdown
                                            placeholder="Оценка"
                                            options={ls.grades.map(grade => ({
                                                key: grade.gradeId,
                                                value: grade.gradeId,
                                                text: grade.gradeName
                                            }))}
                                            clearable
                                            className="dropdown-wrap-menu grade"
                                            value={question.gradeId}
                                            onChange={(e, {value}) => {
                                                question.gradeId = value === "" ? null : ls.grades.find(item => item.gradeId === value).gradeId
                                            }}
                                        />
                                    )}
                                    {!editable && question.gradeName}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
        </AuditStepsWidget>
    );
})


export default CheckProcesses;