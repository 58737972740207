module.exports = {
    getAutoRefresh(auth) {
        return this.getSettings(auth).autoRefresh === true
    },

    getAutoRefreshPeriod(auth) {
        const defaultValue = 10
        return this.getSettings(auth).autoRefreshDelay || defaultValue
    },

    setNotifications(value) {
        localStorage.notifications = value
    },

    getNotifications(auth) {
        return this.getSettings(auth).useNotification === true
    },

    getNotificationsFromServer(axios, helper) {
        axios.get(`${window.backendUrl}/api/roles/config`)
            .then(function (response) {
                if (response.status !== 200) {
                    return;
                }

                localStorage.setItem('settings', JSON.stringify(response.data));

                // redirect after login to root
                if (helper) {
                    helper();
                }
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
                // console.log(2)
            });

    },

    setNotificationsToServer(axios, settings, helper) {
        axios.post(`${window.backendUrl}/api/roles/config`, settings)
            .then(function (response) {
                if (response.status !== 200) {
                    return;
                }

                if (helper) {
                    helper();
                }

            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
                // console.log(2)
            });
    },

    getSettings(auth) {
        if (localStorage.settings) {
            let settings = JSON.parse(localStorage.settings)
            // preprocess to cancel 'ПРИЗНАК ПРЕВЫШЕНИЯ ТЕМПЕРАТУРЫ ВОДЫ ИЛИ МАСЛА'
            settings.filter.toShowSvps[2] = false

            return settings
        } else {
            // Why logout here ?
            // auth.logout()
        }
    }
}