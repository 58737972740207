import React, { useEffect, useState } from "react";
import style from "../modalView.module.scss";
import {
  Button,
  Dropdown,
  Popup,
  Icon,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { ColorPicker } from "react-color-palette";
// import { sortableContainer } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import ekasut from "../../../../../api/ekasut";
import { StatusItem } from "./StatusItem";

// const SortableContainer = sortableContainer(({ children }) => {
//   return (
//     <ul style={{ listStyleType: "none", paddingLeft: 0, marginBottom: 0 }}>
//       {children}
//     </ul>
//   );
// });

export const StatusList = ({
  statusList,
  addStatus,
  setAddStatus,
  oldStatusList,
  setOldStatusList,
  newStatusName,
  setNewStatusName,
  updateStatusArray,
  loaded,
  statusListName,
  addStatusType,
  color,
  setColor,
  CatID,
  initStatuses,
  templData,
}) => {
  const [statArray, setStatArray] = useState([]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    let predList = statArray;
    setStatArray(arrayMoveImmutable(statArray, oldIndex, newIndex));
    let mutArray = arrayMoveImmutable(predList, oldIndex, newIndex).map(
      (e, i) => ({
        categoryId: e.categoryId,
        position: i + 1,
        statusColorHex: e.statusColorHex,
        statusId: e.statusId,
        statusName: e.statusName,
        templateId: e.templateId,
      })
    );
    let newList = statusList.filter((e) => {
      if (e.categoryId !== CatID) {
        return e;
      }
    });
    newList.push(...mutArray);
    updatePosition(newList);
  };

  const updatePosition = (newStatusList) => {
    ekasut.nsiTaskManager
      .updateTemp({
        templateId: templData.code,
        templateName: templData.name,
        statuses: newStatusList,
      })
      .then(() => {
        initStatuses(templData.code);
      });
  };

  useEffect(() => {
    setStatArray(
      statusList
        .filter((e) => {
          if (e.categoryId === CatID) {
            return e;
          }
        })
        .sort((a, b) => {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        })
    );
  }, [statusList]);

  useEffect(() => {
    console.log("test");
  });

  return (
    <div>
      <h4>{statusListName}</h4>
      <div style={{ position: "relative" }}>
        {statArray.map((e, i, array) => {
          return (
            <StatusItem
              initStatuses={initStatuses}
              templData={templData}
              statusList={statusList}
              key={`item-${i}`}
              myKey={`item-${i}`}
              arrLength={array.length}
              index={i}
              e={e}
              loaded={loaded}
            />
          );
        })}
        {!loaded && (
          <Dimmer active inverted>
            <Loader inverted>Загрузка</Loader>
          </Dimmer>
        )}
      </div>
      {/* <SortableContainer onSortEnd={onSortEnd} useDragHandle> */}

      {/* </SortableContainer> */}

      {addStatus === addStatusType ? (
        <div className={style.statusCatList}>
          <Popup
            on="click"
            pinned
            trigger={
              <div
                style={{
                  borderRadius: "3px",
                  marginLeft: "10px",
                  width: "10px",
                  height: "10px",
                  background: `${color.hex}`,
                }}
              />
            }
            content={
              <ColorPicker
                width={220}
                height={150}
                color={color}
                onChange={setColor}
                alpha
                hideHSV
                hideRGB
                hideHEX
              />
            }
          />
          <Dropdown
            className={style.dropAddStat}
            placeholder="Название"
            additionLabel={"Добавить "}
            search
            fluid
            allowAdditions
            selection
            options={oldStatusList}
            value={newStatusName}
            onChange={(e, { value, text }) => {
              if (value.length < 16) {
                setNewStatusName(value);
              }
            }}
            onAddItem={(e, { value }) => {
              let defaultList = oldStatusList;
              defaultList.push({ text: value, value: value });
              setOldStatusList(defaultList);
            }}
          />

          <div></div>
        </div>
      ) : null}
      {addStatus === addStatusType ? (
        <div className={style.addStatusActionBox}>
          <Button
            className={style.addStatusAction}
            basic
            content={"Отменить"}
            size={"tiny"}
            onClick={() => {
              setNewStatusName("");
              setAddStatus(false);
            }}
          />
          <Button
            className={style.addStatusAction}
            basic
            content={"Добавить"}
            size={"tiny"}
            onClick={() => {
              updateStatusArray();
            }}
          />
        </div>
      ) : null}

      {addStatus !== addStatusType ? (
        <Button
          className={style.addStatus}
          basic
          content={"Добавить статус"}
          icon="plus"
          size={"tiny"}
          onClick={() => {
            setAddStatus(addStatusType);
          }}
        />
      ) : null}
    </div>
  );
};
