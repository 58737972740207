import React, {useEffect, useState} from 'react';
import style from './WSMore.module.scss';
import AppModal from "../../../../../shared/AppModal";
import {Popup, Segment} from "semantic-ui-react";
import {Button, Input} from "antd";
import {ColorPicker, useColor} from "react-color-palette";
import {AddPeople} from "./AddPeople/AddPeople";
import {AddTemp} from "./AddTemp/AddTemp";
import ekasut from "../../../../../api/ekasut";

export const WSMore = ({wSMoreOpen, setWSMoreOpen, wSData, setWSData, updateTree, setAddIdParent}) => {

    const [settingsUsersList, setSettingsUsersList] = useState([])
    const [color, setColor] = useColor("hex", wSData?.iconColorHex ? wSData.iconColorHex : "#030303");
    const [selectedTemp, setSelectedTemp] = useState([]);
    const [nameWS, setNameWS] = useState('');
    const [loading, setLoading] = useState(false)
    const [editName, setEditName] = useState(false)

    useEffect(() => {
        if (wSData?.id && wSMoreOpen !== 'new' && wSMoreOpen) {
            setLoading(true)
            setNameWS(wSData.workGroupName)
            ekasut.taskboard.getGroupDetail(wSData.id)
                .then(({data}) => {
                    setLoading(false)
                })
            setSelectedTemp({id: wSData.templateId})
        }
    }, [wSMoreOpen])

    const createWorkSpace = () => {
        if (wSData?.id && wSMoreOpen !== 'new') {
            clearData()
            return
        }
        setLoading(true)
        ekasut.taskboard.postPartsOfSidebar({
            workGroupName: nameWS,
            iconName: "circle",
            iconColorHex: color.hex,
            workGroupTypeId: 1,
            templateId: selectedTemp.id,
            users: settingsUsersList
        }).then(() => {
            updateTree()
            setLoading(false)
            clearData()
        })
            .catch((err) => {
                setLoading(false)
                clearData()
            })
    }

    const updateWorkSpace = () => {
        setLoading(true)
        ekasut.taskboard.updateGroupInfo(wSData.id, {
            workGroupName: nameWS,
            iconName: "circle",
            iconColorHex: color.hex,
            users: settingsUsersList
        }).then(() => {
            setLoading(false)
            updateTree()
            clearData()
        }).then(() => {
            setLoading(false)
            clearData()
        })
    }

    const clearData = () => {
        setSelectedTemp({id: null, statuses: []})
        setSettingsUsersList([])
        setNameWS('')
        setColor({
            hex: "#000000",
            rgb: {
                r: 0,
                g: 0,
                b: 0
            },
            hsv: {
                h: 0,
                s: 0,
                v: 0
            }
        })
        setAddIdParent(false)
        setWSData(false)
        setWSMoreOpen(false)
    }

    return (
        <AppModal
            open={!!wSMoreOpen}
            onClose={clearData}
            //loading={personInfoLoading}
            className={style.modalWSMore}
        >
            <Segment style={{borderRadius: '14px', padding: 0, height: '100%'}}>
                <div className={style.headerWS}>
                    {wSMoreOpen !== 'new' && wSData ? <div>
                        <Popup
                            disabled={wSData?.userRole !== 1}
                            on='click'
                            pinned
                            trigger={<div style={{
                                borderRadius: '3px',
                                marginLeft: '10px',
                                width: '10px',
                                height: '10px',
                                background: `${color.hex}`
                            }}/>}
                            content={<ColorPicker width={120} height={100} color={color} onChange={setColor} alpha
                                                  hideHSV hideRGB hideHEX/>}
                        />
                        {editName ? <Input autoFocus defaultValue={nameWS} size={'middle'} bordered={false}
                                           placeholder={'Введите название'} onChange={(e) => {
                            setNameWS(e.target.value)
                        }} onBlur={() => {
                            setEditName(false)
                        }}/> : <div className={style.groupName} onClick={() => {
                            if (wSData?.userRole === 1) {
                                setEditName(true)
                            }
                        }}>
                            {nameWS}
                        </div>}
                    </div> : <div>
                        <Popup
                            on='click'
                            pinned
                            trigger={<div style={{
                                borderRadius: '3px',
                                marginLeft: '10px',
                                width: '10px',
                                height: '10px',
                                background: `${color.hex}`
                            }}/>}
                            content={<ColorPicker width={120} height={100} color={color} onChange={setColor} alpha
                                                  hideHSV hideRGB hideHEX/>}
                        />
                        <Input autoFocus size={'middle'} bordered={false}
                               placeholder={'Введите название'} onChange={(e) => {
                            setNameWS(e.target.value)
                        }}/></div>}
                </div>
                <div className={wSData?.id ? style.modalOneView : style.modalGrid}>
                    <AddPeople settingsList={settingsUsersList} setSettingsList={setSettingsUsersList} wSData={wSData}/>
                    {wSData?.id ? null :
                        <AddTemp selectedTemp={selectedTemp} setSelectedTemp={setSelectedTemp} wSData={wSData}/>}
                </div>
                <div className={style.footerWS}>
                    {!wSData?.id ? <Button loading={loading} shape="round" type="primary" ghost
                                           disabled={  !selectedTemp?.id || !nameWS.trim().length}
                                           onClick={createWorkSpace}>
                        Создать
                    </Button> : wSData?.userRole === 1 ?
                        <Button loading={loading} shape="round" type="primary" ghost
                                disabled={!settingsUsersList.length || !selectedTemp?.id || !nameWS.trim().length}
                                onClick={updateWorkSpace}>
                            Сохранить
                        </Button> : null}

                </div>
            </Segment>
        </AppModal>
    );
};