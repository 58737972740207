import React, {useRef, useMemo} from 'react'
import Picker from "react-month-picker";
import styles from './MonthPickerBar.module.scss'
import moment from 'moment'
import {dates} from "../../Dictionaries";
import {padStartZero} from "../../helpers/utils";


export default function MonthPickerBar({onChange, year, month}) {

    const picker = useRef(null)
    const date = moment(`${year}-${padStartZero(month+1, 2)}-01`)
    const range = useMemo(() => {
        const currMoment = moment()
        // {from: {year: 2014, month: 8}, to: {year: 2015, month: 5}}
        const isLastMonth = currMoment.month() === 11
        // Picker cannot disable months without range mode
        return {
            from: {
                year: currMoment.year() - 1,
                month: currMoment.month() + 1, // +1 - month picker start month with 1, moment with 0
            },
            to: {
                year: isLastMonth ? currMoment.year() + 1 : currMoment.year(),
                month: isLastMonth ? 1 : currMoment.month() + 2
            }
        }
    }, [])

    return (
        <div className={styles.topHeader}>
            <Picker
                ref={picker}
                years={{min: range.from.year, max:range.to.year}}
                value={{
                    year: date.year(),
                    month: date.month()+1
                }}
                lang={dates.shortenedMonth}

                onChange={(year, month) => {
                    if (year !== date.year() || (month !== (date.month() + 1))) {
                        const nextDate = moment(date)
                        nextDate.set({month: month - 1, year})
                        onChange({year, month: month - 1})
                    }
                }}
            >
                <div
                    className={styles.monthBox}
                    onClick={() => {
                        picker.current.show()
                    }}
                >
                    {date.format("MMMM, YYYY")}
                </div>
            </Picker>
        </div>
    )
}
MonthPickerBar.defaultProps = {
    onChange: () => {}
}