import React from 'react';
import {Button, Icon, Segment} from "semantic-ui-react";
import cn from 'classnames'
import style from './AuditStepsWidget.module.scss'
import AuditSteps, {auditSteps} from "./AuditSteps";

const AuditStepsWidget = (
    {
        content, exportButtonText = "Экспорт", displayButtons = {cancel: true, exportDoc: true, save: true, end: true}, step,
        onClose = () => null, onCancel = () => null, onExport = () => null, onSave = () => null, onEnd = () => null, children,
        className, onView = () => null, viewStep
    }
) => {
    return (
        <Segment.Group className={cn("widget", style.component, className)}>
            <Segment className="steps-container">
                {/*<div className="steps-title">Этапы проведения аудита</div>*/}
                <AuditSteps
                    step={step}
                    viewStep={viewStep}
                    onView={onView}
                />
            </Segment>
            {/* Should not be first element or last element, css styles depend on first or last child */}
            <Icon name="close" className="close-button" onClick={onClose}/>
            <Segment>{content || children}</Segment>
            {displayButtons.exportDoc || displayButtons.cancel || displayButtons.end || displayButtons.save ? (
                <Segment className="control-buttons">
                    <div className="left-buttons">
                        {displayButtons.cancel && <Button content="Отмена" className="button-link" onClick={onCancel}/>}
                    </div>
                    <div className="right-buttons">
                        {displayButtons.exportDoc && <Button content={exportButtonText} className="button-link" onClick={onExport}/>}
                        {viewStep === null ? (<>
                            {displayButtons.save && <Button content="Сохранить" className="button-link" onClick={onSave}/>}
                            {displayButtons.end && <Button content="Завершить этап" onClick={onEnd} className="button-primary"/>}
                        </>) : (
                            <Button content="Далее" className="button-link" onClick={() => onView(viewStep + 1, (viewStep + 1) === step)}/>
                        )}
                    </div>
                </Segment>
            ) : null}

        </Segment.Group>
    );
};

export default AuditStepsWidget;