import React from 'react';
import {Button} from "semantic-ui-react";
import './ProcessViewButtons.scss'

const ProcessViewButtons = ({
                                urgentlySubmit,
                                onClose,
                                disabledEnd,
                                disabledSave,
                                onSave,
                                showSave = true,
                                onEnd,
                                showUrgSub = false
                            }) => {
    return (
        <div className="app process-view-buttons">
            <Button content="Отмена" className="button-link" onClick={onClose}/>
            {showUrgSub && <Button content="Отозвать" className="button-link" style={{color:'red'}} onClick={urgentlySubmit}/>}
            {showSave && <Button content="Сохранить" className="button-link" disabled={disabledSave} onClick={onSave}/>}
            <Button content="Завершить" className="button-link" disabled={disabledEnd} onClick={onEnd}/>
        </div>
    );
};

export default ProcessViewButtons;