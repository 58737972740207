import React from 'react'
import { Table } from 'semantic-ui-react'
import './FailureTable.scss'

export default function FailureTable({failures, category, title, fieldName, firstCol, cellOnClick}) {
	return (
		<Table celled className='pr-failure-table' selectable>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell colSpan={2}>{title + category}</Table.HeaderCell>
				</Table.Row>
				<Table.Row>
					<Table.HeaderCell>{firstCol}</Table.HeaderCell>
					<Table.HeaderCell>Количество отказов</Table.HeaderCell>
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{failures.map((fail, index) => {
					return (
						<Table.Row key={index} onClick={() => cellOnClick(fail.detail)}>
							<Table.Cell>{fail[fieldName]}</Table.Cell>
							<Table.Cell>
								{fail.cnt}
							</Table.Cell>
						</Table.Row>
					)
				})}
			</Table.Body>
		</Table>
	)
}
