import React, {useCallback, useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import style from "../boardComponents.module.scss";
import {CardActions, CardHeader, IconButton, Typography} from "@material-ui/core";
import ekasut from "../../../../api/ekasut";
import {addNewTaskBoard} from "../../dashboardsLogic/board";
import {Input, Spin, Select, TreeSelect} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import Button from '@material-ui/core/Button';
import {useSnapshot} from "valtio";
import {board} from "../../TaskListState";

const antIcon = <LoadingOutlined style={{fontSize: 16}} spin/>;
const {Option} = Select;
const {TreeNode} = TreeSelect;

export const AddTaskCard = ({setAddTask, activeBoard, section}) => {

    const [loading, setLoading] = useState(false)
    const [selectedList, setSelectedList] = useState(undefined)
    const [taskName, setTaskName] = useState('')
    const [listsOptions, setListsOptions] = useState([])
    const boardState = useSnapshot(board)

    const onChange = (value) => {
        setSelectedList(value);
    };

    const CustomTreeSelect = ({treeOptions}) => (
        <TreeSelect
            showArrow={false}
            style={{width: '100%'}}
            value={selectedList}
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            placeholder="Выберите список"
            allowClear
            onChange={onChange}
        >

            {treeOptions.map((el) => (
                el.workGroupTypeId === 3 ?
                    <TreeNode value={el.id} title={el.workGroupName} key={el.id}/> :
                    <TreeNode value={el.id} title={el.workGroupName} key={el.id} selectable={false}>
                        {el.children.map((list) => (
                            <TreeNode key={list.id} value={list.id} title={list.workGroupName}/>
                        ))}
                    </TreeNode>
            ))}

        </TreeSelect>
    )


    const createNewTask = (e) => {
        let title = boardState.allWGTasks ? e : e.target.value
        if (!title.trim().length) {
            setAddTask(false)
            return
        }

        setLoading(true)

        let body = boardState.groupType === 'priority' ? {
            workGroupId: boardState.allWGTasks ? selectedList : boardState.workGruopId,
            title: title,
            priorityId: section.fieldId,
        } : {
            workGroupId: boardState.allWGTasks ? selectedList : boardState.workGruopId,
            title: title,
            statusId: section.fieldId,
        }

        ekasut.taskboard.addTask({...body})
            .then(({data}) => {
                setAddTask(false)
                addNewTaskBoard(section.fieldId, data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setAddTask(false)
            })
    }

    useEffect(() => {
        if (boardState.allWGTasks) {
            ekasut.taskboard.getListsForCreateTask(boardState.workGruopId)
                .then(({data}) => {
                    setListsOptions(data)
                })
        }
    }, [])


    return (
        <Card
            className={style.cardTask}>
            <CardHeader
                style={{marginLeft: '8px', padding: '10px 0px 0 5px'}}
                className={style.taskHeader}
                title={<div className={style.addTaskCart}>
                    {boardState.allWGTasks && !loading ? <CustomTreeSelect treeOptions={listsOptions}/> : null}
                    {!loading ? <Input
                        disabled={loading}
                        className={boardState.allWGTasks ? style.inputAddFullTask : style.inputAddTask}
                        autoFocus
                        bordered={false}
                        placeholder='Название'
                        onChange={(e) => {
                            if (boardState.allWGTasks) {
                                setTaskName(e.target.value)
                            }
                        }}
                        onPressEnter={boardState.allWGTasks ? null : createNewTask}
                        onBlur={() => {
                            if (!boardState.allWGTasks) setAddTask(false)
                        }}/> : null}
                    {loading ? <Spin indicator={antIcon} style={{fontSize: 16, color: 'rgba(0,0,0,0.64)'}}/> : null}
                </div>}
            >
            </CardHeader>

            {boardState.allWGTasks ? <CardActions>
                <Button disabled={loading} size="small" onClick={() => {
                    setAddTask(false)
                }}>
                    Отмена
                </Button>
                <Button disabled={loading} size="small" onClick={() => {
                    createNewTask(taskName)
                }}>
                    Добавить
                </Button>
            </CardActions> : null}
        </Card>
    );
};