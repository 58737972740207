import React, {useState} from 'react';
import {Tab, Tabs,} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import {MainTab} from "./MainTab/MainTab";
import {LocoElectronicPassport} from "./LocoEletronicPassport/LocoElectronicPassport";
import OnBoardDiagnostics from "./OnBoardDiagnostics/OnBoardDiagnostics";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}


export const LocoDetailsTabsNew = ({info =[]}) => {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
      >
        <Tab label="Основное"/>
        <Tab label="История КО"/>
        <Tab label="История НЭП локомотива"/>
        <Tab label="Бортовая диагностика и барьерные функции"/>
        <Tab label="Электронный паспорт локомотива"/>
      </Tabs>

      <SwipeableViews
        axis={'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <MainTab info={info}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={3}>
          <OnBoardDiagnostics info={info}/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <LocoElectronicPassport info={info}/>
        </TabPanel>
      </SwipeableViews>
    </>
  );
}