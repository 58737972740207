import React from 'react';
import {Tab} from "semantic-ui-react";
import OperativePlan from './OperativePlan';
import DecadePlan from './DecadePlan';

const PlanRepairs = () => {
    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            defaultActiveIndex={0}
            panes={[{
                menuItem: "Оперативный график",
                render: () => (
                    <OperativePlan />
                )
            }, {
                menuItem: "Декадное планирование",
                render: () => (
                    <DecadePlan />
                )
            }]}
        />
    );
};

export default PlanRepairs;