import { useCallback, useState, useRef } from 'react'

/** @deprecated Method requires rethinking & testing */
export default function useApiMethod(
    apiMethod,
    errorHandler,
    defData = null,
    defResConverter = res => res.data,
) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(defData)
    const responseConverter = useRef(defResConverter)

    const method = useCallback(function () {
        setLoading(true)
        // invoke api method with all passed arguments
        const promise = apiMethod.apply(this, arguments)
            .then((response) => {
                const convertedRes = responseConverter.current(response)
                setData(convertedRes)
                setLoading(false)
                return convertedRes
            })
        promise.catch((error) => {
            setLoading(false)
            if (errorHandler instanceof Function) {
                errorHandler(error)
            }
        })
        return promise
    }, [apiMethod, errorHandler])

    return [method, loading, data, setData]
}
