import { useDebugValue, useEffect, useState } from "react"
import ekasut from "../../../api/ekasut"

export const useKasantMessages = () => {
  const [kasantaMessages, setKasantaMessages] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  const getKasantaMessages = async() => {
    try {
      const {data} = await ekasut.notification.checknewDetail()
      setKasantaMessages(data)
      setIsLoaded(true)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getKasantaMessages()
  }, [])

  useDebugValue(kasantaMessages ?? "Loading...")

  return {kasantaMessages, isLoaded}
}