import React from "react";
import cn from "classnames";
import styles from "./index.module.scss";
import { Tab } from "semantic-ui-react";
import { observer, useLocalStore } from "mobx-react-lite";
import OrganizationInspection from "./OrganizationInspection/OrganizationInspection";
import Inspection from "./Inspection/Inspection";
import { Info } from "./Info/Info";
import { ComInspInfo } from "./Info/ComInspInfo/ComInspInfo";

const CommissionInspection = observer(({ hideSidebars }) => {
  return (
    <div
      className={cn(styles.commissionControl, "alt-semantic")}
      onClick={hideSidebars}
    >
      <Tab
        menu={{ secondary: true, pointing: true }}
        defaultActiveIndex={0}
        panes={[
          {
            menuItem: "Сводная информация",
            render: () => (
              <div className="commission-container">
                {/* <ComInspInfo /> */}
                <Info />
              </div>
            ),
          },
          {
            menuItem:
              localStorage.kodFirm == "1111"      ||
              localStorage.kodFirm == "48300"     ||
              localStorage.kodFirm == "220125"    ||
              localStorage.kodFirm == "895711"    ||
              localStorage.kodFirm == "92040185"  ||
              localStorage.kodFirm == "92040333"  ||
              localStorage.kodFirm == "92040335"  ||
              localStorage.kodFirm == "92040337"  ||
              localStorage.kodFirm == "92040339"  ||
              localStorage.kodFirm == "92040341"  ||
              localStorage.kodFirm == "92040343"  ||
              localStorage.kodFirm == "92040345"  ||
              localStorage.kodFirm == "92040347"  ||
              localStorage.kodFirm == "92040349"  ||
              localStorage.kodFirm == "92040351"  ||
              localStorage.kodFirm == "92040353"  ||
              localStorage.kodFirm == "599002017" ||
              localStorage.kodFirm == "599002320"
              ? "" : "Осмотр локомотивов",
            render: () => (
              <div className="commission-container">
                <Inspection />
              </div>
            ),
          },
          {
            menuItem: "Организация осмотра",
            render: () => (
              <div className="commission-container">
                <OrganizationInspection />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
});

export default CommissionInspection;
