import React from 'react';
import style from "./OnBoardDiagnostics.module.scss";
import {useGetOnBoardDiagnostics} from "./hook/useGetOnBoardDiagnostics";
import {Table} from "antd";
import {CheckOutlined } from "@ant-design/icons";
import {
  changeDateForText,
  onCell,
  onCellCenter,
  onCellSvg,
  onHeaderCell,
  tableBorderStyle
} from "../../shared/onAntdCells";


const { Column } = Table;


const OnBoardDiagnostics = ({info}) => {

  const {data, loading} = useGetOnBoardDiagnostics(info.idObject)



  return (
    <div className={style.wrap}>
      <div className={style.column}>
        <div className={style.itemColumn}>
          <div className={style.title}>
            Срабатывания барьерных функций
          </div>
          <div
            style={{width: '600px'}}
          >
            <Table
              dataSource={data}
              loading={loading}
              bordered
              pagination={false}
              width='600px'
              style={tableBorderStyle}
            >
              <Column
                title="Барьерная функция"
                dataIndex="checkTypeName"
                key="checkTypeName"
                width='300px'
                onCell={onCell}
                onHeaderCell={onHeaderCell}
              />
              <Column
                title="Результат проверки"
                dataIndex="checkResult"
                key="checkResult"
                width='30px'
                render={(text) => {
                  const color = text === 1 ? 'blue' : 'red';
                  return (
                      <>
                        {
                          <CheckOutlined
                            style={{
                              color,
                              textAlign: 'center',
                              fontSize: '16px'
                            }}
                        />}
                      </>
                    )
                  }
                }
                onCell={onCellSvg}
                onHeaderCell={onHeaderCell}
              />
              <Column
                title="Дата проверки"
                dataIndex="checkDate"
                key="checkDate"
                width='70px'
                onCell={onCellCenter}
                onHeaderCell={onHeaderCell}
                render={changeDateForText}
              />
              <Column
                title="Место срабатывания"
                dataIndex="depoName"
                key="depoName"
                width='100px'
                onCell={onCellCenter}
                onHeaderCell={onHeaderCell}
              />
            </Table>

          </div>
        </div>
      </div>
      <div className={style.column}>
        <div className={style.itemColumn}>
          <div className={style.title}>
            Новая таблица
          </div>
          <div
            style={{width: '600px'}}
          >
            <Table
              bordered
              style={tableBorderStyle}
            >

            </Table>
          </div>
        </div>
      </div>
    </div>
  )
};

export default OnBoardDiagnostics;