import React from 'react';
import {Modal} from "semantic-ui-react";
import {StoreProvider, useStore} from "./StoreProvider";
import {observer} from "mobx-react-lite";
import AddLocRemarkForm from "./observers/AddLocRemarkForm";
import PreviewLocRemarksForm from "./observers/PreviewLocRemarksForm";
import ViewLocRemarksForm from "./observers/ViewLocRemarksForm";

// entire component was made as an observer to reduce waste renders caused by parent
const LocRemarks = observer(() => {
    const store = useStore()

    return <>
        {store.mode === "view" && (
            <ViewLocRemarksForm />
        )}
        {store.mode === "add" && (
            <AddLocRemarkForm/>
        )}
        {store.mode === "preview" && (
            <PreviewLocRemarksForm/>
        )}
    </>
})

const Index = ({actionId, actionRd, asutrId, mode, remarksData}) => {

    const initOptions = {actionId, actionRd, asutrId,}

    const inheritProps = {mode, remarksData}

    return (
        <StoreProvider initOptions={initOptions} inheritProps={inheritProps}>
            <LocRemarks />
        </StoreProvider>
    )
}
Index.defaultProps = {}


export default Index;