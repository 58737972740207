import React, {useState} from 'react';
import { observer } from "mobx-react-lite";
import { useStore } from "../StoreProvider";
import JustifyContainer from "../../../../shared/JustifyContainer";
import { Button } from "semantic-ui-react";
import ekasut from "../../../../api/ekasut";
import catchNetworkErrors from "../../../../api/catchNetworkErrors";

const EditButtons = observer(() => {
    const store = useStore()
    const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false)

    const handleRemoveClick = () => {
        setIsDeleteButtonLoading(true)
        ekasut.operativePlanRepairs.deleteLocomotive(store.selectedPlanItem.locoPlanId)
            .then(() => {
                store.selectedRow = null
                setIsDeleteButtonLoading(false)
                store.fetchPlanAndLocomotives(store.tche.id, store.startDate)
            })
            .catch((error) => {
                store.selectedRow = null
                setIsDeleteButtonLoading(false)
                return catchNetworkErrors(error)
            })
    }

    return (
        <JustifyContainer gapRight="norm">
            {store.plan.showRedactButton &&  (
                <>
                    <Button
                        content="Добавить"
                        disabled={!store.sld.id || !store.tche.id || store.planAndLocomotivesLoading}
                        onClick={() => {
                            store.showAddModal = true
                        }}
                    />
                    <Button
                        content="Редактировать"
                        disabled={(store.selectedRow === null) || !store.sld.id || !store.tche.id || store.planAndLocomotivesLoading}
                        onClick={() => {
                            store.showEditModal = true
                        }}
                    />
                    <Button
                        content="Удалить"
                        disabled={(store.selectedRow === null) || !store.sld.id || !store.tche.id || store.planAndLocomotivesLoading}
                        loading={isDeleteButtonLoading}
                        onClick={handleRemoveClick}
                    />
                </>
            )}
        </JustifyContainer>
    )
})

export default EditButtons;