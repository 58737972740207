import React from 'react'
import {Loader} from "semantic-ui-react";

export default function LoadingLabel({label, style, size, loading}) {
    return (
        <label style={{display: 'inline-block', height: 34, ...style}}>
            {label}
            {loading && (
                <Loader inline active size={size} style={{marginLeft: 10, color: "black"}}/>
            )}
        </label>
    )
}
LoadingLabel.defaultProps = {
    size: "small",
    loading: true
}