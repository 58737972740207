import fixPieChartMarker from "./extendLeaflet";
import styleDefaultMarker from "./styleDefaultMarker";
import localizeHighcharts from "./localizeHighcharts";
import localizeSemantic from "./semanticLocalization";

// Import css for libraries
import 'semantic-ui-css-offline/semantic.min.css';
// import 'react-tiny-fab/dist/styles.css';
import 'react-dates/lib/css/_datepicker.css'
import "react-dates/initialize";
import 'rc-tree/assets/index.css';
import 'react-image-lightbox/style.css'
import 'react-month-picker/css/month-picker.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

// Import App css
import '../../../css/fixLibraries/dvf-lint-fix.css' // v0.3.1
import '../../../css/polygon.scss' // v0.3.1
import '../../../css/App.scss';

/** Abstract away from App complex library initializations */
const init = () => {
    styleDefaultMarker()
    fixPieChartMarker()
    localizeHighcharts()
    localizeSemantic()
    // HC_exporting(Highcharts)
}

export default init