import React from "react";
import PolygonFilter from "./PolygonFilter";
import { observer, useLocalStore } from "mobx-react-lite";
import PolygonScheme from "./PolygonScheme2";
import { toJS } from "mobx";
import PolygonTable from "./PolygonTable";

const LocomotiveDislocation = observer(() => {
  const ls = useLocalStore(() => ({
    show: "filter", // || "map" || "table"
    filter: null,
  }));

  return (
    <>
      <PolygonFilter
        show={ls.show === "filter"}
        onApply={(filter, { showOnMap }) => {
          ls.show = showOnMap ? "map" : "table";
          ls.filter = filter;
        }}
        onMap={() => {
          ls.show = "map";
        }}
        onTable={() => {
          ls.show = "table";
        }}
      />
      {ls.show === "map" && (
        <PolygonScheme
          onFilter={() => {
            ls.show = "filter";
          }}
          onTable={() => {
            ls.show = "table";
          }}
          filter={toJS(ls.filter, { recurseEverything: true })}
        />
      )}
      {ls.show === "table" && (
        <PolygonTable
          onFilter={() => {
            ls.show = "filter";
          }}
          onMap={() => {
            ls.show = "map";
          }}
          filter={toJS(ls.filter, { recurseEverything: true })}
        />
      )}
    </>
  );
});

export default LocomotiveDislocation;
