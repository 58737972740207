import React from 'react';
import styles from './FilterAuditTypes.module.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import cn from 'classnames'

/**
 * @param audits: [{text, color, defaultActive}]
 */
const FilterAuditTypes = observer(({auditTypes, onChange = () => null, loading}) => {



    return (
        <div className={styles.component}>
            {auditTypes.map((audit, index) => (
                <div
                    className="audit"
                    key={index}
                    onClick={() => {
                        onChange(audit, index)
                    }}
                >
                    <div className={cn("circle", audit.active ? `circle-fill-${audit.color}` : `circle-outline-${audit.color}`)}/>
                    <div className={cn("audit-text", audit.active && "active")}>{audit.name}</div>
                </div>
            ))}
        </div>
    );
})


export default FilterAuditTypes;