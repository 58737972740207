import React from 'react'
import {Table, Dimmer, Loader} from 'semantic-ui-react'

const TableRowsLoader = ({loading, loaderSize, messageIfEmpty, messageIfLoading, children, rows, colSpan}) => {
    children = rows !== undefined ? rows : children

    return (
        loading ? (
            <Table.Row>
                <Table.Cell colSpan={colSpan}>
                    <Dimmer inverted active style={{padding: 40, position: "relative"}}>
                        <Loader size={loaderSize}>{messageIfLoading}</Loader>
                    </Dimmer>
                </Table.Cell>
            </Table.Row>
        ) : (React.Children.count(children) === 0) ? (
            <Table.Row>
                <Table.Cell colSpan={colSpan} textAlign="center">
                    {messageIfEmpty}
                </Table.Cell>
            </Table.Row>
        ) : (
            children
        )
    )
}
TableRowsLoader.defaultProps = {
    loaderSize: "medium"
}

export default TableRowsLoader