import React, { useEffect, useState } from "react";
import AppTable, { convertToDate } from "../../shared/AppTable";
import { Container } from "@material-ui/core";
import ekasut from "../../api/ekasut";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DropzoneDialogBase } from "material-ui-dropzone";
import SaveIcon from "@material-ui/icons/Save";
import useFileDownload from "../../hooks/useFileDownload";
import catchNetworkErrors from "../../api/catchNetworkErrors";

export default function DeviceTable({ data }) {
  //Сделать перерендер после отправки через use state data

  const [open, setOpen] = React.useState(false);
  const [fileObject, setFileObject] = useState([]);
  const [download, InputDownload] = useFileDownload();
  const [loader, setLoader] = useState(false);

  const downloadFile = async (file) => {
    console.log(file);
    try {
      const fileId = await ekasut.file.postFile(file[0].file);
      await ekasut.mek.postTerminalFile(data[0].terminalId, fileId?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const saveFile = async (fileId, name) => {
    setLoader(true);
    const res = await ekasut.file.getFile(fileId);
    download(res?.data, name);
    setLoader(false);
  };

  const dialogTitle = () => (
    <>
      <span>Загрузка файла</span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <Container>
      <div className="file-navigation">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Загрузить файл
        </Button>
        <InputDownload />
        {data[0].fileName ? (
          <Button
            key={fileObject.fileId}
            disabled={loader}
            className="save-btn"
            style={{ textTransform: "none", marginRight: 10 }}
            color="primary"
            size="small"
            download={fileObject.fileName}
            onClick={() => {
              saveFile(data[0].fileId, data[0].fileName);
            }}
            target={"_blank"}
            startIcon={<SaveIcon />}
          >
            {loader ? "Загрузка ..." : "Прикрепленный файл"} {data[0].fileName}
          </Button>
        ) : (
          <span></span>
        )}
        <DropzoneDialogBase
          dialogTitle={dialogTitle()}
          fileObjects={fileObject}
          cancelButtonText={"Отменить"}
          submitButtonText={"Сохранить"}
          filesLimit={1}
          getFileAddedMessage={(fileName) => {
            return `Файл ${fileName} успешно загружен`;
          }}
          getFileRemovedMessage={(fileName) => {
            return `Файл ${fileName} успешно удален`;
          }}
          useChipsForPreview
          dropzoneText={"Перетащите файл или кликните сюда"}
          maxFileSize={5000000}
          open={open}
          onAdd={(newFileObj) => {
            console.log("onAdd", newFileObj);
            setFileObject(newFileObj);
          }}
          onDelete={(deleteFileObj) => {
            console.log("onDelete", deleteFileObj);
          }}
          onClose={() => setOpen(false)}
          onSave={() => {
            console.log("onSave", fileObject);
            setOpen(false);
            downloadFile(fileObject);
          }}
        />
      </div>
      <AppTable structure={tableStructure} data={data} option={0} />
    </Container>
  );
}

const tableStructure = [
  { header: "ИД устройства терминала", field: "deviceId" },
  { header: "ИД терминала", field: "terminalId" },
  { header: "Тип устройства", field: "deviceType" },
  { header: "VID/PID", field: "pid" },
  { header: "ИД изготовителя", field: "vid" },
  {
    header: "Состояние",
    field: "isConnected",
    converter: (val) => (val === true ? "Подключен" : "Отключен"),
  },
  { header: "Наименование устройства", field: "description" },
  {
    header: "Дата последнего обновления",
    field: "updateTime",
    converter: convertToDate,
  },
];
