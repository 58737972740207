import React, {useRef, useEffect} from 'react';
import {Button, Feed, Input, Message, Segment, Transition} from "semantic-ui-react";
import {observer, useAsObservableSource, useLocalStore} from "mobx-react-lite";
import {addFetchFunctional, formatMoment} from "../../../helpers/utils";
import {dateFormats} from "../../../Dictionaries";
import {runInAction} from "mobx";
import JustifyContainer from "../../../shared/JustifyContainer";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

/**
 * messages - `[{fio, msg, msgDate, msgId}]`
 */
const Chat = observer((
    {date, tcheId, sldId, updateEveryMs, apiGetNewMessages, apiPostMessage, disabled,
        messagesListMinHeight, messagesListMaxHeight}
) => {
    const obs = useAsObservableSource({tcheId, sldId, date})
    const end = useRef(null)
    const ls = useLocalStore(() => ({
        warn: {
            show: false,
            text: ""
        },
        lastMsgId: undefined,
        currentTcheId: undefined,
        currentSldId: undefined,
        messages: [],
        inputText: "",
        sendMessage: () => {
            const text = ls.inputText
            ls.inputText = ""
            return ls.fetchPostMessage(obs.date, ls.currentTcheId, ls.currentSldId, text)
                .then(data => {
                    end.current.scrollIntoView()
                    return data
                }).catch(catchNetworkErrors)
        },
        _continueRefresh: true,
        startRefresh: () => {
            ls._continueRefresh = true
            const periodicUpdateMessages = () => {
                if (ls.currentTcheId && ls.currentSldId) {
                    ls.fetchNewMessages(obs.date, ls.currentTcheId, ls.currentSldId, ls.lastMsgId)
                        .then(({data}) => {
                            if (data && data.length > 0) {
                                runInAction(() => {
                                    ls.lastMsgId = data[data.length - 1].msgId
                                    ls.messages = ls.messages.concat(data)
                                })
                            }
                            if (ls._continueRefresh) {
                                /* Async task is finished, so i free to create next one */
                                setTimeout(periodicUpdateMessages, updateEveryMs)
                            }
                        }).catch(catchNetworkErrors)
                } else {
                    setTimeout(periodicUpdateMessages, updateEveryMs)
                }
            }
            periodicUpdateMessages()
        },
        stopRefresh: () => {
           ls._continueRefresh = false
        },
        resetChat: () => {
            ls.lastMsgId = undefined
            ls.messages = []
            ls.currentTcheId = obs.tcheId
            ls.currentSldId = obs.sldId
        },
        ...addFetchFunctional("newMessages", apiGetNewMessages, {defValue: [], parser: messagesParser}),
        ...addFetchFunctional("postMessage", apiPostMessage)
    }))

    useEffect(() => {
        ls.resetChat()
    }, [ls, tcheId, sldId])

    useEffect(() => {
        /*if (!disabled) {*/
        ls.startRefresh()
        /*}*/
        return ls.stopRefresh
    }, [ls])

    return (
        <Segment>
            <Transition
                visible={ls.warn.show}
                animation='scale'
                duration={500}
            >
                <Message
                    onDismiss={() => ls.warn.show = false}
                    warning
                    content={ls.warn.text}
                />
            </Transition>

            <Feed style={{minHeight: messagesListMinHeight, maxHeight: messagesListMaxHeight, overflow: 'auto'}}>
                {ls.messages.map((msg, index) => (
                    <Feed.Event key={index}>
                        <Feed.Content >
                            <Feed.Summary>
                                <Feed.User>{msg.fio}</Feed.User>
                                <Feed.Date>{formatMoment(msg.msgDate, dateFormats.upToMinute)}</Feed.Date>
                            </Feed.Summary>
                            <Feed.Extra text>{msg.msg}</Feed.Extra>
                        </Feed.Content>
                    </Feed.Event>
                ))}
                <div
                    style={{float: "left", clear: "both"}}
                    ref={(el) => {
                        end.current = el
                    }}
                />
            </Feed>
                <Input
                    fluid
                    disabled={disabled}
                    placeholder='текст'
                    value={ls.inputText}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            ls.sendMessage()
                        }
                    }}
                    onChange={(e, {value}) => ls.inputText = value}
                />

                <JustifyContainer
                    justifyContent="space-between"
                    gapRight="small"
                    style={{marginTop: 10}}
                >
                    <Button
                        icon="refresh"
                        disabled={disabled}
                        onClick={() => {
                            ls.resetChat()
                        }}
                    />
                    <Button
                        content="Отправить"
                        type='submit'
                        disabled={disabled || ls.postMessageLoading}
                        onClick={() => {
                            end.current.scrollIntoView()
                            ls.sendMessage()
                        }}
                    />
                </JustifyContainer>
        </Segment>
    );
})
Chat.defaultProps = {
    messagesListMinHeight: 400,
    messagesListMaxHeight: 650,

}

const messagesParser = (response) => {
    if (response.status === 204) {
        return {data: []}
    }
    return response
}

export default Chat;