import React from 'react';
import PromptModal from "../../shared/PromptModal";
import useGlobalStore from "../../globalStore/useGlobalStore";
import {observer} from "mobx-react-lite";

/**  */
const GlobalPrompt = observer(() => {
    const gs = useGlobalStore()
    return (
        <PromptModal
            {...gs.prompt.props}
            onDecline={() => {
                gs.prompt.props.onDecline && gs.prompt.props.onDecline()
                gs.prompt.close()
            }}
            onClose={() => {
                gs.prompt.props.onClose && gs.prompt.props.onClose()
                gs.prompt.close()
            }}
        />
    )
})

export default GlobalPrompt;