import React, {useState} from 'react';
import style from './Cards.module.scss'
import {TinyColumn} from '@ant-design/charts';
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Divider} from "antd";
import SwipeableViews from "react-swipeable-views";
import {MainTab} from "../../../Inspection/LocoDetails/Tabs/MainTab/MainTab";
import {Tab, Tabs, styled} from "@material-ui/core";
import {ColumnIndicator} from "./ColumnIndicator/ColumnIndicator";
import {ColumnDescription} from "./ColumnDescription/ColumnDescription";
import ChatDropdownButton from "./CardDropdownButton/ChatDropdownButton";
import TableThirdCardWaiting from "./TableThirdCardWaiting/TableThirdCardWaiting";
import ekasut from "../../../../../api/ekasut";
import TableThirdCardRepair from "./TableThirdCardRepair/TableThirdCardRepair";
import {Dimmer, Loader} from "semantic-ui-react";

const preds = [{
  title: 'ОКТ Т',
  lastVal: 25,
  curVal: 82,
}, {
  title: 'КЛГ Т',
  lastVal: 45,
  curVal: 122,
}, {
  title: 'МОСК Т',
  lastVal: 55,
  curVal: 62,
}, {
  title: 'ГОРЬК Т',
  lastVal: 75,
  curVal: 74,
}, {
  title: 'СЕВ Т',
  lastVal: 86,
  curVal: 113,
}, {
  title: 'С-КАВ Т',
  lastVal: 99,
  curVal: 120,
}, {
  title: 'Ю-ВОСТ Т',
  lastVal: 64,
  curVal: 81,
}, {
  title: 'ПРИВ Т',
  lastVal: 34,
  curVal: 91,
}, {
  title: 'КБШ Т',
  lastVal: 74,
  curVal: 121,
}, {
  title: 'СВЕРД Т',
  lastVal: 34,
  curVal: 171,
}, {
  title: 'Ю-УР Т',
  lastVal: 34,
  curVal: 121,
}, {
  title: 'З-СИБ Т',
  lastVal: 38,
  curVal: 101,
}, {
  title: 'КРАС Т',
  lastVal: 64,
  curVal: 141,
}, {
  title: 'В-СИБ Т',
  lastVal: 44,
  curVal: 71,
}, {
  title: 'ЗАБ Т',
  lastVal: 84,
  curVal: 101,
}, {
  title: 'ДВОСТ Т',
  lastVal: 94,
  curVal: 111,
}
]

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({theme}) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#333333',
  fontFamily: [
    'normal normal medium 8px/10px Roboto'
  ].join(','),
  '&:hover': {
    color: '#709AC2',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#709AC2',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#709AC2',
  },
}));

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#709AC2',
  },
});

const getReportRoadStays = (currentKodRoad) => ekasut.KOReports.getReportRoadStays({KodRoad: currentKodRoad});

export const CardThree = ({data, setIsModalOpened, setModalContent, setIsThirdReportLoading}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onReportClick = (reportData) => {
    setIsThirdReportLoading(true);
    try {
      getReportRoadStays(reportData.kodRoad)
        .then(response => {
          if (response.status === 200) {
            setIsModalOpened(true);
            if (value === 0) {
              setModalContent(<TableThirdCardWaiting data={response.data}/>);
            }
            if (value === 1) {
              setModalContent(<TableThirdCardRepair data={response.data}/>);
            }
          }
        })
        .finally(() => setIsThirdReportLoading(false));
    } catch {}
  };


  return (
    <div className={style.card}>
      <div className={style.cardHeader}>
        <span>ПРОСТОЙ ЛОКОМОТИВОВ В ПЕРИОД КО</span>
      </div>
      <div className={style.cardThreeBody}>
        <AntTabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          // indicatorColor="inherit"
        >
          <AntTab label="Простой в ожидании ремонта"/>
          <AntTab label="Простой в ремонте"/>
        </AntTabs>
        {data?.length ? (
          <SwipeableViews
            axis={'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0}>
              <div className={style.DepoColumn}>
                <ColumnIndicator data={data} onReportClick={onReportClick} index={0}/>
              </div>
              <Divider/>
              {/*<ColumnDescription/>*/}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className={style.DepoColumn}>
                <ColumnIndicator data={data} onReportClick={onReportClick} index={1}/>
              </div>
              <Divider/>
              {/*<ColumnDescription/>*/}
            </TabPanel>
          </SwipeableViews>
        ) : (
          <p style={{marginTop: 20}}>Нет данных</p>
        )}


      </div>
    </div>
  );
};