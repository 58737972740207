import {Dropdown} from "semantic-ui-react"
import React, {useState, useEffect} from "react"
import './DropdownBoldChanges.scss'



export default function DropdownBoldChanges({options, defaultValue, onChange, direction, upward, onClick}) {
    const [value, setValue] = useState(defaultValue)
    const [valueIsDefault, setValueIsDefault] = useState(true)

    useEffect(() => {
        setValueIsDefault(true)
    }, [defaultValue])

    return (
        <Dropdown
            upward={upward}
            direction={direction}
            className={"lcm-dropdown-bold-changes " + (valueIsDefault ? "default-value" : "not-default-value")}
            options={options}
            value={value}
            onClick={onClick}
            onChange={(e, next) => {
                setValueIsDefault(next.value === defaultValue)
                setValue(next.value)
                onChange(next.value, defaultValue)
            }}
        />
    )
}
