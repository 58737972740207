import React from 'react';
import cn from 'classnames'
import styles from './index.module.scss'
import {Tab} from "semantic-ui-react";
import {observer, useLocalStore} from "mobx-react-lite";
import PlanAudits from "./PlanAudits";
import ManageAudit from "./ManageAudit";
import EventsForAuditResults from "./EventsForAuditResults";

const AuditControl = observer(({hideSidebars}) => {
    return (
        <div className={cn(styles.auditControl, 'alt-semantic')} onClick={hideSidebars}>
            <Tab
                menu={{ secondary: true, pointing: true }}
                defaultActiveIndex={0}
                panes={[{
                    menuItem: "Планирование аудитов",
                    render: () => (
                        <div className="audit-container">
                            <PlanAudits />
                        </div>
                    )
                }, {
                    menuItem: "Ведение аудита",
                    render: () => (
                        <div className="audit-container">
                            <ManageAudit/>
                        </div>
                    )
                }, {
                    menuItem: "Мероприятия по результатам аудита",
                    render: () => (
                        <EventsForAuditResults/>
                    )
                }]}
            />
        </div>
    );
})

export default AuditControl;