import React from 'react'
import DropdownField from "../fields/DropdownListField";
import {Form} from 'semantic-ui-react'

export default function PersInColsInfo({employee}) {
    return (
        <Form>
            <Field
                label='Сотрудник'
                value={employee.fullName}
            />
            <Field
                label='Табельный номер'
                value={employee.asutrId}
            />
            <Field
                label='Должность'
                value={employee.postStr}
            />
            <Field
                label='Оценка'
                value={employee.rateText}
            />
            {/* Признака пока нет
                <Field
                    label='Признак получения инфы смежной системой'
                    value={'Ghbp'}
                />
            */}
        </Form>
    )
}
const Field = ({label, value}) => (
    <DropdownField
        label={label}
        editable={false}
        list={[{text: value}]}
        value={0}
    />
)