import React from 'react'
import {Tab} from "semantic-ui-react";

export default function LocTabs({tcheTable, sldTable}) {
    return (
        <Tab
            panes={[
                {
                    menuItem: 'ТЧЭ',
                    render: () => <Tab.Pane>{tcheTable}</Tab.Pane>
                },
                {
                    menuItem: 'СЛД',
                    render: () => <Tab.Pane>{sldTable}</Tab.Pane>
                }
            ]}

            renderActiveOnly={true}
/*            panes={[
                {
                    menuItem: 'ТЧЭ',
                    pane: {
                        key: 'tche',
                        content: tcheTable
                    },
                },
                {
                    menuItem: 'СЛД',
                    pane: {
                        key:'sld',
                        content: sldTable
                    }
                }
            ]}*/
        />
    )
}