import React, {useCallback, useEffect, useState} from 'react';
import style from './ColumnIndicator.module.scss';
import useApiMethod from "../../../../../../hooks/useApiMethod";
import ekasut from "../../../../../../api/ekasut";

export const ColumnIndicator = ({data, onReportClick, index}) => {
  const [currentKodRoad, setCurrentKodRoad] = useState(null);
  const currentKodRoadPromise = useCallback(() => () => ekasut.KOReports.getReportRoadStays({KodRoad: currentKodRoad}), [currentKodRoad]);
  const [getCurrentRoadData, isLoading, currentData] = useApiMethod(currentKodRoadPromise);
  // const [lastHeight, setLastHeight] = useState()
  // const [currentHeight, setCurrentHeight] = useState()
  // const [deviderHeight, setDeviderHeight] = useState()
  // const [fullHeight, setFullHeight] = useState()
  const [maxValue, setMaxValue] = useState(-1)
  const [valuesArr, setValuesArr] = useState([])

  const initValues = (lastYearValue, currentYearValue, dataItem) => {
    // let perOfHeight = (lastYearValue + currentYearValue) / 100
    let perOfHeight = 1
    let fullHeightCol = (lastYearValue + currentYearValue) / (maxValue / 100)
    let current = (currentYearValue / maxValue) * 100;
    let last = Math.floor(lastYearValue / perOfHeight) - 2.5
    let devider = 100 - current - last
    // setFullHeight(fullHeightCol)
    // setCurrentHeight(current)
    // setLastHeight(last)
    // setDeviderHeight(devider)
    return {
      last: lastYearValue,
      current: currentYearValue,
      fullHeight: fullHeightCol,
      lastHeight: last,
      deviderHeight: devider,
      currentHeight: current,
      ...dataItem
    }
  }

  const initMaxValue = (arr) => {
    return Math.max(...arr.map((e) => index === 0 ? e.roadWaitStay : e.roadRepLength))
  }

  useEffect(() => {
    if (maxValue !== -1) {
      setValuesArr(data.map((e, i) => (initValues(0, index === 0 ? e.roadWaitStay : e.roadRepLength, e))))
    } else {
      setMaxValue(initMaxValue(data))
    }
  }, [maxValue])

  return (
    <div className={style.chartAria}>
      {/*<div className={style.legend}>*/}
      {/*    <div className={style.legendColor} style={{backgroundColor: '#709AC2'}}></div>*/}
      {/*    <span>Натуральные величины</span>*/}
      {/*    <div className={style.legendColor} style={{backgroundColor: '#757575'}}></div>*/}
      {/*    <span>Отклонение от аналогичного периода прошлого года</span>*/}
      {/*</div>*/}
      <div className={style.columnAria}>
        {valuesArr.map((e, i) => (
          <div key={i} className={style.singleCol} onClick={() => onReportClick(data[i])}>
            <div className={style.hoverCard}>
              {/*<div style={{backgroundColor: '#757575'}}></div>*/}
              <div>
                {index === 0 ? "Сум. простой в ОЖ.ТО/ТР по дороге: " : "Сум. простой в ТО/ТР по дороге: "}
                <span style={{fontWeight: 700}}>{(index === 0 ? e.txtRoadWaitStay : e.txtRoadRepLength) ?? ""}</span>
              </div>
              <div>
                {index === 0 ? "Серия с большим наипростоем в ОЖ.ТО/ТР по дороге: " : "Серия с наибольшим простоем в ТО/ТР по дороге: "}
                <span style={{fontWeight: 700}}>{(index === 0 ? e.maxWaitSerName : e.maxPerestSerName) ?? ""}</span>
              </div>
              <div>
                {index === 0 ? "Среднее время простоя в ОЖ.ТО/ТР по дороге: " : "Среднее время простоя в ТО/ТР по дороге: "}
                <span style={{fontWeight: 700}}>{(index === 0 ? e.txtRoadAvgWaitStay : e.txtRoadAvgRepLength) ?? ""}</span>
              </div>
              {/*<span>{e.last}</span>*/}
              {/*<div>*/}
              {/*    <div style={{backgroundColor: '#709AC2'}}></div>*/}
              {/*    <span>{e.current}</span>*/}
              {/*</div>*/}
            </div>
            <div className={style.column}> {/*style={{height: `${e.fullHeight}px`}}*/}
              {/*<div className={style.lastValue} style={{height: `${e.lastHeight}%`}}></div>*/}
              {/*<div className={style.devider} style={{height: `${e.deviderHeight}%`}}></div>*/}
              <div className={style.currentValue} style={{height: `${e.currentHeight}%`}}></div>
            </div>
            <span className={style.title}>{e.roadName}</span>
          </div>
        ))}
      </div>
    </div>
  );
  }
;