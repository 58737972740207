/** Cut stack trace from ssbOb error message */
const ssbDbExceptionCutStackTrace = (error) => {
    if (error.response && error.response.data && error.response.data.message &&
        error.response.data.message.startsWith('SspMain.SspDbException')) {
        const arrayIndex = error.response.data.message.search('--->')
        if (arrayIndex >= 0) {
            error.response.data.message = error.response.data.message.substring(0, arrayIndex)
        }
    }

    return Promise.reject(error)
}

export default ssbDbExceptionCutStackTrace