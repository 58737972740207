import React from 'react';
import {StoreProvider, useStore} from "./StoreProvider";
import {Grid} from "semantic-ui-react";
import cn from 'classnames'
import styles from './index.module.scss'
import * as moment from "moment";
import JustifyContainer from "../../../shared/JustifyContainer";
import appStyles from '../../../css/App.module.scss'
import OperativeTable from "./components/OperativeTable";
import LeftButtons from "./components/LeftButtons";
import EditButtons from "./components/EditButtons";
import ApproveRejectButtons from "./components/ApproveRejectButtons";
import AddPlanModal from './components/AddPlanModal';
import EditPlanModal from './components/EditPlanModal';
import TcheSldHeader from './components/TcheSldHeader'
import Chat from "../components/Chat";
import ekasut from "../../../api/ekasut";
import {observer} from "mobx-react-lite";
import SldTche from "./components/SldTche";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

const OperativePlan = observer(() => {
    const store = useStore()
    return (
        <div className={cn(styles.centralize)}>
            {/*<OperativeHeader/>*/}
            <Grid>
                <Grid.Column width={12} className={appStyles.verticalGapNorm}>
                    <JustifyContainer justifyContent="flex-start" gapRight="norm">
                        <SldTche />
                    </JustifyContainer>
                    <JustifyContainer justifyContent="space-between" gapRight="large">
                        <LeftButtons/>
                        <EditButtons/>
                    </JustifyContainer>
                    <TcheSldHeader/>
                    <OperativeTable/>
                    <div style={{textAlign: "right"}}>
                        <ApproveRejectButtons/>
                    </div>
                </Grid.Column>
                <Grid.Column width={4} textAlign='center'>
                    <PlanChat/>
                </Grid.Column>
            </Grid>
            <AddPlanModal/>
            <EditPlanModal
                startDate={store.startDate}
                endDate={store.endDate}
            />
        </div>
    )
})

const Index = () => {
    return (
        <StoreProvider>
            <OperativePlan/>
        </StoreProvider>
    )
}

const PlanChat = observer(() => {
    const store = useStore()
    return (
        <Chat
            date={moment()}
            updateEveryMs={1000}
            tcheId={store.selectedTche.id}
            sldId={store.selectedSld.id}
            disabled={!store.plan.showChat || (store.selectedTche.id === null || store.selectedSld.id === null)}
            apiGetNewMessages={(date, tcheId, sldId, lastMsgId) => (
                ekasut.planRepairs.getChat(date, tcheId, sldId, lastMsgId, 2)
                    .then(res => {
                        if (Array.isArray(res.data)) {
                            res.data = res.data.filter(
                                d => d.msgDate ? store.startDate.isBefore(d.msgDate) : false
                            )
                        }
                        return res
                    }).catch(catchNetworkErrors)
            )}
            apiPostMessage={(date, tcheId, sldId, text) => (
                ekasut.planRepairs.postChat(date, tcheId, sldId, 2 , text)
            )}
        />
    )
})

export default Index;