import React, {useEffect} from 'react';
import AuditStepsWidget from "./AuditStepsWidget";
import {auditSteps} from "./AuditSteps";
import {Button, Icon, Table} from "semantic-ui-react";
import cn from 'classnames'
import './CheckCompleted.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";

const CheckCompleted = observer(({onClose, onView, editable, step, viewStep, display, audit}) => {

    const ls = useLocalStore(() => ({
        wasFirstDisplay: false,
        auditInfo: null
    }))

    useEffect(() => {
        if (display && !ls.wasFirstDisplay) {
            ls.wasFirstDisplay = true
            ekasut.audit.getAuditInfo({auditId: audit.auditId})
                .then(({data}) => {
                    ls.auditInfo = data
                })
                .catch(catchNetworkErrors)
        }
    }, [display, ls.wasFirstDisplay, audit])

    const getPercentage = (value) => {
        return parseFloat((value*100).toFixed(2))
    }

    if (!display) {
        return false
    }

    return (
        <AuditStepsWidget
            className={cn("app", "check-completed")}
            onClose={onClose}
            onCancel={onClose}
            onView={onView}
            step={step}
            viewStep={viewStep}
            displayButtons={false}
        >
            <Icon name="check circle" size="massive" color="green" className="complete-icon"/>
            <div className="complete-text">Проверка завершена</div>
            {ls.auditInfo !== null && (
                <div className="statistics">
                    <div className="stat-text">{`Количество вопросов: ${ls.auditInfo.cntQuestion}`}</div>
                    <div className="stat-text">{`Количество ответов: ${ls.auditInfo.cntAnswer}`}</div>
                    <div className="stat-text">{`Прогресс: ${getPercentage(ls.auditInfo.progress)}%`}</div>
                    {ls.auditInfo.processes.length > 0 && (
                        <Table className="stat-table">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Процессс"/>
                                    <Table.HeaderCell content="Вопросов"/>
                                    <Table.HeaderCell content="Ответов"/>
                                    <Table.HeaderCell style={{whiteSpace: "nowrap"}} content="Прогресс, %"/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {ls.auditInfo.processes.map(process => (
                                    <Table.Row key={process.processId}>
                                        <Table.Cell content={process.processName}/>
                                        <Table.Cell content={process.questions}/>
                                        <Table.Cell content={process.answers}/>
                                        <Table.Cell content={getPercentage(process.progress)}/>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </div>
            )}
            <div className="buttons">
                {/*<Button content="Экспорт результатов аудита" className="button-link"/>*/}
                {/*<Button content="Просмотр реузльтатов аудита" className="button-link"/>*/}
            </div>
        </AuditStepsWidget>
    );
})

export default CheckCompleted;