import { DatePicker, Input } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import style from "./HeaderAddProt.module.scss";

export const HeaderAddProt = ({
  info,
  protocolNum,
  setProtocolNum,
  currentDateOp,
  currentSostName,
  commisarMarks,
  currentProtDate,
  setCurrentProtDate,
  secProtAwinfo,
}) => {
  useEffect(() => {
    setProtocolNum(commisarMarks?.length && commisarMarks[0].numProt);
  }, [commisarMarks]);

  useEffect(() => {
    setCurrentProtDate(commisarMarks?.length && moment(commisarMarks[0].dateProt))
  }, [])

  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current > moment().startOf("day").add(1, "day") ||
      current < moment(currentDateOp).endOf("day").subtract(1, "day")
    );
  };

  return (
    <div className={style.header}>
      <div className={style.nameInfo} style={{ display: "flex" }}>
        <div className={style.fioWrap}>
          <h2>{info?.serNum}</h2>
        </div>
        <span>Протокол комиссионного осмотра</span>
        <div
          style={{ margin: "0 100px", textAlign: "center", display: "flex" }}
        >
          <div style={{ marginBottom: "20px" }}>
            Номер протокола:{" "}
            <Input
              type="text"
              style={{ width: "150px" }}
              onChange={(e) => setProtocolNum(e.target.value)}
              value={protocolNum}
            />
          </div>
          <div style={{ marginBottom: "20px" }}>
            Дата протокола:
            <DatePicker
              style={{ width: "150px" }}
              disabledDate={disabledDate}
              onChange={(e) => setCurrentProtDate(e)}
              value={
                secProtAwinfo.length && secProtAwinfo[0].dateProt
                  ? moment(secProtAwinfo[0].dateProt)
                  : currentProtDate
              }
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            Вид ремонта:{" "}
            <Input
              type="text"
              style={{ width: "150px" }}
              value={currentSostName}
              contentEditable={false}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            Дата ремонта:
            <DatePicker
              style={{ width: "150px" }}
              type="number"
              value={moment(currentDateOp)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
