import React, {useEffect, useState} from 'react'
import styles from './ButtonPopupRow.module.scss'
import {Popup} from "semantic-ui-react";
import cn from 'classnames'

const ButtonPopupRow = ({className, arrSettings, rowPopupProps, rowProps}) => {
    const [buttons, setButtons] = useState([])

    useEffect(() => {
        const newButtons = []
        for (let i = 0; i < arrSettings.length; i++) {
            const {value, callback, popupProps, buttonProps, className} = arrSettings[i]

            let buttonClassName
            if (rowProps === undefined || rowProps.onClick === undefined) {
                buttonClassName = cn(styles.button, callback ? styles.clickable : styles.cursorAuto, className)
            } else {
                buttonClassName = cn(styles.button, className)
            }

            const button = (
                <div
                    key={i}
                    className={buttonClassName}
                    onClick={callback}
                    {...buttonProps}
                >
                    <div>
                        {value}
                    </div>
                </div>
            )
            const popup = (
                <Popup
                    key={i}
                    trigger={button}
                    {...popupProps}
                />
            )

            newButtons.push(popupProps ? popup : button)

            setButtons(newButtons)
        }
    }, [arrSettings, rowProps])

    return (
        rowPopupProps ? (
            <Popup
                trigger={
                    <div
                        className={cn(className, styles.buttonsRow, rowProps && rowProps.onClick && styles.clickable)} {...rowProps}>
                        {buttons}
                    </div>
                }
                {...rowPopupProps}
            />
        ) : (
            <div
                className={cn(className, styles.buttonsRow, rowProps && rowProps.onClick && styles.clickable)} {...rowProps}>
                {buttons}
            </div>
        )
    )
}
ButtonPopupRow.defaultProps = {
    values: [],
    callbacks: []
}
export default ButtonPopupRow