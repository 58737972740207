import React, {useState} from 'react';
import style from "../modalView.module.scss";
import {Icon, Menu, Modal, Button} from "semantic-ui-react";
import {Input, Tooltip} from "antd";
import ekasut from "../../../../../api/ekasut";

export const TemplItem = ({
                              deleteTempl,
                              editTemp,
                              templ,
                              getStatuses,
                              setTemplateOpen,
                              setAddStatus,
                              setActiveTemp,
                              activeTemp,
                              statusList,
                              initTemp
                          }) => {

        const [editNameTemp, setEditNameTemp] = useState(false)
        const [newNameTemp, setNewNameTemp] = useState('')
        const [showIcons, setShowIcons] = useState(false)
        const [openDel, setOpenDel] = useState(false)

        const updateTempName = () => {
            if (newNameTemp.length) {
                ekasut.nsiTaskManager.updateTemp({
                    templateId: templ.code,
                    templateName: newNameTemp,
                    statuses: statusList
                })
                    .then(() => {
                        setEditNameTemp(false)
                        initTemp()
                    })
            }
        }

        return (
            <div className={style.tempItem} onMouseEnter={() => {
                setShowIcons(true)
            }} onMouseLeave={() => {
                setShowIcons(false)
            }}>
                <Modal
                    size={'mini'}
                    open={openDel}
                    onClose={() => {
                        setOpenDel(false)
                        setShowIcons(false)
                    }}
                >
                    <Modal.Header>Удаление шаблона</Modal.Header>
                    <Modal.Content>
                        <p>Вы действительно хотите удалить шаблон: "<strong>{templ.name}</strong>"</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={() => {
                            setOpenDel(false)
                            setShowIcons(false)
                        }}>
                            Нет
                        </Button>
                        <Button positive onClick={() => {
                            deleteTempl(templ)
                            setOpenDel(false)
                            setShowIcons(false)
                        }}>
                            Да
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Menu.Item
                    className={style.tempList}
                    active={activeTemp === templ.code}
                    color={'blue'}
                    onClick={() => {
                        if (!editTemp) {
                            setTemplateOpen(true)
                            getStatuses(templ.code)
                            setActiveTemp(templ.code)
                            setAddStatus(false)
                        }
                    }}
                > {editNameTemp ?
                    <Input maxLength={15} defaultValue={templ.name} autoFocus bordered={false} onChange={(e) => {
                        setNewNameTemp(e.target.value)
                    }}/> : <span>{templ.name}</span>}</Menu.Item>
                {showIcons && !editNameTemp ? <div>
                    <Icon name={'pencil'} color={'black'} onClick={() => {
                        setEditNameTemp(true)
                    }
                    }/>

                    <Tooltip title={templ.isUse ? 'Шаблон используется' : ''}>
                        <Icon disabled={templ.isUse} name={'trash alternate outline'} color={'red'} onClick={() => {
                            setOpenDel(true)
                        }}/>
                    </Tooltip>
                </div> : null}
                {editNameTemp ? <div>
                    <Icon name={'checkmark'} color={'green'} onClick={() => {
                        updateTempName()
                    }
                    }/>
                    <Icon name={'delete'} color={'red'} onClick={() => {
                        setEditNameTemp(false)
                    }
                    }/>
                </div> : null}
            </div>
        );
    }
;