import React, { useEffect, useCallback, useState } from 'react'
import JournalTable from "./JournalTable";
import useApiMethod from "../../../hooks/useApiMethod";
import ekasut from '../../../api/ekasut'
import styles from './index.module.scss'
import JournalFilter from "./JournalFilter";
import usePagination from "../../../hooks/usePagination";
import cn from 'classnames'

export default function NetJournalWork({ hideSidebars }) {
    const [message, setMessage] = useState('Для отображения данных выберите предприятие')

    const [sendGetLocSeries, locSeriesLoading, locSeries] = useApiMethod(ekasut.locomotivesNsi.getSerTpsAsutt, undefined, [],
        ({ data }) => {
            return data.map(item => ({ id: item.key, name: item.text }))
        }
    )
    const [sendGetDepartureSt, departureStLoading, departureSt] = useApiMethod(ekasut.ekasutData.getNsiStan, undefined, [],
        ({ data }) => {
            return data.map(item => ({ id: item.stKod, name: item.vname }))
        }
    )
    const [sendGetArrivalSt, arrivalStLoading, arrivalSt] = useApiMethod(ekasut.ekasutData.getNsiStan, undefined, [],
        ({ data }) => {
            return data.map(item => ({ id: item.stKod, name: item.vname }))
        }
    )
    const [sendGetJournal, journalLoading, journal] = useApiMethod(ekasut.jy.getJy, undefined, [])

    const [sendGetDepots, depotsLoading, depots] = useApiMethod(ekasut.jy.getNsiDepot, undefined, [],
        res => {
            return res.data.map(item => ({ id: item.kodFirm, name: item.name }))
        }
    )
 const [Pagination, journalPage] = usePagination(journal, 10, 1) 

    useEffect(() => {
        sendGetLocSeries()
    }, [sendGetLocSeries, sendGetDepots])

    

    const handleFilterOnChange = useCallback(filter => {
        // Depots field required
        if (filter.depots.length === 0) {
            setMessage('Для отображения данных выберите предприятие')
            return
        }
        setMessage(null)
        sendGetJournal(
            filter.dateFrom, filter.depots, filter.departureSt, filter.arrivalSt,
            filter.traction, filter.locSeries, filter.lbUse
        )
    }, [sendGetJournal])

    return (
        <div className={cn(styles.container)} onClick={hideSidebars}>
            <JournalFilter
                className={styles.filter}
                locSeries={locSeries}
                locSeriesLoading={locSeriesLoading}
                onChange={handleFilterOnChange}
                departureSt={departureSt}
                departureStLoading={departureStLoading}
                departureStTypeEnd={text => sendGetDepartureSt(text)}
                arrivalSt={arrivalSt}
                arrivalStLoading={arrivalStLoading}
                arrivalStTypeEnd={text => sendGetArrivalSt(text)}
                stMinChar={3}
                stSearchDelay={1000}
                depots={depots}
                depotsLoading={depotsLoading}
                depotsTypeEnd={text => { sendGetDepots(text) }}
            />
            <div className={styles.tableContainer}>
                <JournalTable
                    message={message}
                    journal={journalPage}
                    loading={journalLoading}
                />
            </div>
            {message === null && !journalLoading && (
                <div className={styles.paginationContainer}>
                    <Pagination />
                </div>
            )}
        </div>
    )
}