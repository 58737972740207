import React from 'react';
import {observer, useLocalStore} from "mobx-react-lite";
import {Header, Message, Table} from "semantic-ui-react";
import {dateFormats} from "../../Dictionaries";
import moment from 'moment'
import FromToForm from "./FromToForm";
import TableRowsLoader from "../TableRowsLoader";
import styles from './FuelEnergyResourses.module.scss'

const FuelEnergyResources = observer(({lastDate, detail, tab}) => {
    const ls = useLocalStore(() => ({
        appliedFrom: null,
        appliedTo: null
    }))

    
    const feRes = tab.fuelEnergyRes !== null ? tab.fuelEnergyRes.periods[0] : {}
    
    const validLastDate = lastDate !== undefined && lastDate.isValid() ? lastDate : moment()

    return !feRes ? <Message content={'Нет данных'}/> : tab.fuelEnergyResMessage ? <Message content={tab.fuelEnergyResMessage}/> : (
        <div>
            <Table collapsing basic='very' celled className={styles.briefInfo}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Время</Table.Cell>
                        <Table.Cell>{moment(feRes.end).format(dateFormats.upToMinute)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Плотность, кг/м³</Table.Cell>
                        <Table.Cell>{feRes.endDensity}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Масса, кг</Table.Cell>
                        <Table.Cell>{feRes.endKg}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Объем, л</Table.Cell>
                        <Table.Cell>{feRes.endL}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <FromToForm
                initFrom={moment(validLastDate).subtract(1, 'days').startOf('day')}
                initTo={moment(validLastDate).endOf('day')}
                onApply={(from, to) => {
                    if (!(from.isSame(ls.appliedFrom) && (to.isSame(ls.appliedTo)))) {
                        ls.appliedTo = to
                        ls.appliedFrom = from
                        tab.fetchFuelEnergyHistory(detail.idObject, from, to).catch(() => {})
                    }
                }}
                loading={tab.fuelEnergyHistoryLoading}
            />
            <Header
                style={{fontWeight: 400}}
                as="h5"
                content={ls.appliedFrom === null || ls.appliedTo === null ? "Дата не выбрана" :
                    `История расходов, ${ls.appliedFrom.format(dateFormats.upToDay)} - ${ls.appliedTo.format(dateFormats.upToDay)}`
                }
            />
            <Table celled structured compact unstackable className="compact-table">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">Дата состояния</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Наименование состояния</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">Расход</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <TableRowsLoader
                        colSpan={3}
                        messageIfLoading="Загрузка"
                        messageIfEmpty="Нет данных"
                        loading={tab.fuelEnergyHistoryLoading}
                        rows={
                            tab.fuelEnergyHistory.map((state, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell
                                        textAlign="right">{state.dateOp === undefined ? "Нет данных" : moment(state.dateOp).format(dateFormats.upToMinute)}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.sostName === undefined ? "Нет данных" : state.sostName}</Table.Cell>
                                    <Table.Cell textAlign="right">{state.sumDiff === undefined ? "Нет данных" : state.sumDiff}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    />
                </Table.Body>
            </Table>
        </div>
    )
})

export default FuelEnergyResources;