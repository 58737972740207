import React, {useEffect, useState, useCallback} from 'react'
import BrigadesMap from "./BrigadesMap";
import ekasut from "../../api/ekasut";
import moment from 'moment'
import axios from 'axios'
import zoomLevels from "../ZoomLevels";
import {formatRotation} from "./functions";
import catchNetworkErrors from "../../api/catchNetworkErrors";


const RatingsWorkBrigades = ({hideSidebars}) => {
    const [stats, setStats] = useState(null)
    const [dailyOrders, setDailyOrders] = useState({fact: [], plan: []})
    const [dailyOrdersLoading, setDailyOrdersLoading] = useState(false)
    const [dailyOrdersName, setDailyOrdersName] = useState('')
    const [points, setPoints] = useState({roads: [], depots: []})
    const [pointDetails, setPointDetails] = useState([])
    const [pointDetailsLoading, setPointDetailsLoading] = useState(false)

    // Load rzd stats
    useEffect(() => {
        ekasut.planLb.getRzdGauge()
            .then((response) => {
                setStats(parseRzd(response))
            })
            .catch(catchNetworkErrors)
    }, [])

    const loadDashboard = (id, name, zoomLevel) => {
        setDailyOrders({fact: [], plan: []})
        setDailyOrdersLoading(true)
        let getDashboard
        if (id === undefined) {
            setDailyOrdersName('По всей сети')
            getDashboard = ekasut.planLb.getDashboardRzd
        } else if (zoomLevel === zoomLevels.road) {
            getDashboard = ekasut.planLb.getDashboardRoad
            setDailyOrdersName(name)
        } else {
            getDashboard = ekasut.planLb.getDashboardDepot
            setDailyOrdersName(name)
        }
        getDashboard(id)
            .then((response) => {
                setDailyOrders(parseDashboard(response))
                setDailyOrdersLoading(false)
            })
            .catch(catchNetworkErrors)
    }

    // Load values for dashboard charts
    useEffect(() => {
        loadDashboard()
    }, [])

    // Load points
    useEffect(() => {
        axios.all([
            ekasut.planLb.getRoad(),
            ekasut.planLb.getDepot()
        ])
            .then(([roadRes, depotRes]) => {
                setPoints(parseRoadsAndDepots(roadRes, depotRes))
            })
            .catch(catchNetworkErrors)
    }, [])

    const loadPointDetails = useCallback((id, zoomLevel) => {
        let getDetails
        let unifyResData
        if (zoomLevel === zoomLevels.road) {
            getDetails = ekasut.planLb.getRoadDetails
            unifyResData = unifyRoads
        } else {
            getDetails = ekasut.planLb.getDepotDetails
            unifyResData = unifyDepots
        }
        setPointDetailsLoading(true)
        setPointDetails([])
        getDetails(id)
            .then((response) => {
                setPointDetailsLoading(false)
                setPointDetails(unifyResData(response.data))
            })
            .catch(catchNetworkErrors)
    }, [])

    const loadSelectedDashboard = (id, name, zoomLevel) => {
        loadDashboard(id, name, zoomLevel)
    }

    return (
        <div onClick={hideSidebars}>
            <BrigadesMap
                stats={stats}
                dailyOrders={dailyOrders}
                dailyOrdersLoading={dailyOrdersLoading}
                dailyOrdersName={dailyOrdersName}
                points={points}
                showPointDetailsOnClick={loadPointDetails}
                pointDetailsLoading={pointDetailsLoading}
                pointDetails={pointDetails}
                selectPointOnClick={loadSelectedDashboard}
            />
        </div>
    )
}
export default RatingsWorkBrigades

const unifyRoads = (data) => {
    for (let i = 0; i < data.length; i++) {
        data[i].name = data[i].dorName
        data[i].lat = data[i].dorLat
        data[i].lon = data[i].dorLon
        data[i].id = data[i].dorKod
    }
    return data
}
const unifyDepots = (data) => {
    for (let i = 0; i < data.length; i++) {
        data[i].name = data[i].depoName
        data[i].lat = data[i].depoLat
        data[i].lon = data[i].depoLon
        data[i].id = data[i].kodFirm
    }
    return data
}
const parseRzd = ({data}) => {
    return {
        leadTimeActual: data.factD,
        rotationDecline: formatRotation(data.timeDiff),
        timeAsPassenger: data.factPass,
        fullRotationActual: data.turnFullFact,
        fullRotationPlan: data.turnFullPlan,
        leadTimeDecline: data.zakazDDiff,
        leadTimePlan: data.zakazD
    }
}
const parseRoadsAndDepots = (roadRes, depotRes) => {
    const getPointStats = (point) => {
        return {
            leadTimePlan: point.zakazD,
            leadTimeActual: point.factD,
            planBrigadesCurrentDay: point.zasD,
            fullRotationActual: point.turnFullFact,
            fullRotationPlan: point.turnFullPlan,
            fullRotationAverage: point.turnFullFactAvr,
            rotationDecline: null,
            usedRest: point.order4,
            usedFromRotation: point.order5,
            canceledBrigades: point.order11,
            departureAsPassenger: point.order3,
            departureWithStops: point.order1,
            secondRest: point.order6,
            prolongationWorkTime: point.order15,
            prolongationRest: point.order2,
            workStaff: point.yavkaCnt,
            workStaffWithErrors: point.violYavkaCnt
        }
    }
    const roads = roadRes.data.map((road) => ({
        id: road.dorKod,
        name: road.dorName,
        lat: road.dorLat,
        lon: road.dorLon,
        ...getPointStats(road)
    }))
    const depots = depotRes.data.map((depot) => ({
        id: depot.kodFirm,
        name: depot.depoName,
        lat: depot.depoLat,
        lon: depot.depoLon,
        ...getPointStats(depot)
    }))
    return {roads, depots}
}
const parseDashboard = ({data}) => {
    const fact = []
    const plan = []
    data.forEach((item) => {
        const data = moment(item.planDate)
        fact.push({
            data,
            value: item.factD
        })
        plan.push({
            data,
            value: item.zakazD
        })
    })
    return {fact, plan}
}