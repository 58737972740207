import React from 'react'
import {List, Modal} from "semantic-ui-react";
import styles from "./MultipleSelectList.module.scss";

export default function ModalList({label, list, onClick, ...modalProps}) {
    return (
        <Modal
            style={{top: '4%'}}
            {...modalProps}
        >
            <Modal.Header>
                {label}
            </Modal.Header>
            <Modal.Content>
                <List selection divided className={styles.list} size='big'>
                    {list.map((item, index) => (
                        <List.Item
                            key={index}
                            onClick={(e, e1) => {
                                onClick(list[index])
                            }}
                        >
                            {item.text}
                        </List.Item>
                    ))}
                </List>
            </Modal.Content>
        </Modal>
    )
}