import React from 'react';
import mainStyles from "../index.module.scss";
import DelayedSearch from "../../../NsiWeightLength/DelayedSearch";
import {formOptions} from "../../../../helpers/utils";

const EventPicker = ({label, onChange}) => {
    return <>
        <div className={mainStyles.formField}>
            <label>{label}</label>
            <DelayedSearch
                fluid
                options={eventTypesOptions}
                loading={false}
                onChange={(e, {value: index}) => {
                    onChange(eventTypes[index])
                }}
            />
        </div>
    </>
}

export const FORM_VERSION = {
    employee: "employee",
    cols: "cols",
    employeeOrCols: "employeeOrCols"
}
const eventTypes = [
    {id: 1, text: 'Контрольно-заключительная поездка', formVersion: FORM_VERSION.employee},
    {id: 2, text: 'Контрольно-инструкторная поездка', formVersion: FORM_VERSION.employee},
    {id: 3, text: 'Целевая поездка', formVersion: FORM_VERSION.employee},
    {id: 5, text: 'Техническое занятие', formVersion: FORM_VERSION.employeeOrCols},
    {id: 6, text: 'Производственное совещание', formVersion: FORM_VERSION.cols},
]


const eventTypesOptions = formOptions(eventTypes, 'text')

export default EventPicker