import React, {useState, useRef} from 'react'
import { Tab, Form, Segment, Icon, Label, Button, Menu } from 'semantic-ui-react'
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'
import moment from 'moment'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";

import MonthlyViolations from './MonthlyViolations'
import OperationalIncidents from './OperationalIncidents'

const InvestigationLb = ({hideSidebars}) => {
    const [period, setPeriod] = useState(init)
    const [selectedPeriod, setSelectedPeriod] = useState(init)
    const pickAMonth = useRef(null)

    let pickerLang = {
        months: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
        , from: 'С', to: 'По'
    }

    let makeText = m => {
        if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
        return '?'
    }

    let handleAMonthChange = () => {
        pickAMonth.current.dismiss()
    }

    let handleAMonthDismiss = (value) => {
        if (!!value.month) {
            setPeriod(value)
        }
    }
    const [sendGetOverwork, overworkLoading, overwork] =
        useApiMethod(ekasut.incidents.getOverwork, undefined, [])
    const [sendGetOperationalIncidents, operationalIncidentsLoading, operationalIncidents] =
        useApiMethod(ekasut.incidents.getIncidents, undefined, [])

    let rowsCountHelper = (length) => {
        return length > 0 ? <Label color='teal'>{length}</Label> : ''
    }

    let fireMonthly = () => {
        sendGetOverwork(period)
    }

    let fireOperational = () => {
        sendGetOperationalIncidents({...period, typeId: '18,20'})
    }

    const panes = [
        {
            menuItem: (
                <Menu.Item key='monthly'>
                    Ежемесячные нарушения{rowsCountHelper(overwork.length)}
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}>
                <MonthlyViolations overwork={overwork} period={selectedPeriod} fireMonthly={fireMonthly}/>
            </Tab.Pane>,
        },
        {
            menuItem: (
                <Menu.Item key='operational'>
                    Оперативные инциденты{rowsCountHelper(operationalIncidents.length)}
                </Menu.Item>
            ),
            render: () => <Tab.Pane attached={false}>
                <OperationalIncidents incidents={operationalIncidents} fireOperational={fireOperational}/>
            </Tab.Pane>,
        }
    ]

    return (
        <Segment onClick={hideSidebars}>
            <Form>
                <Form.Group widths='equal'>
                    <Picker
                        ref={pickAMonth}
                        value={period}
                        lang={pickerLang.months}
                        onChange={handleAMonthChange}
                        onDismiss={handleAMonthDismiss}
                    >
                        <Button.Group>
                            <Button onClick={()=>{pickAMonth.current.show()}}>
                                <Icon name='calendar outline' />{makeText(period)}
                            </Button>
                            <Button
                                loading={overworkLoading || operationalIncidentsLoading}
                                onClick={() => {
                                    sendGetOverwork(period)
                                    sendGetOperationalIncidents({...period, typeId: '18,20'})
                                    setSelectedPeriod(period)
                                }}>
                                Применить
                            </Button>
                        </Button.Group>
                    </Picker>
                </Form.Group>
            </Form>
            <Form.Field>

            </Form.Field>

            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Segment>
    )
}
export default InvestigationLb

const init = {year: moment().year(), month: moment().month() + 1 }