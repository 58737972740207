import React from 'react';
import createStore from "./createStore";

export const storeContext = React.createContext(undefined)

const StoreProvider = ({children, ...props}) => {
    const store = createStore(props)

    return (
        <storeContext.Provider
            value={store}
            children={children}
        />
    )
};

export const useStore = () => React.useContext(storeContext)

export default StoreProvider;