import React, {useEffect, useMemo} from 'react';
import {Pagination, Popup, Table} from "semantic-ui-react";
import PurchaseActionCell from "./PurchaseActionCell";
import {formatMoment, formatPrice} from "../../helpers/utils";
import {observer, useLocalStore} from "mobx-react-lite";
import './PurchasesTable.scss'
import {purchaseStatuses, purchaseTypes} from "./constants";
import {observable, toJS} from "mobx";
import {dateFormats} from "../../Dictionaries";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import TextField from "../../shared/fields/TextField";
import cn from 'classnames'
import moment from 'moment';

const PurchasesTable = observer((
    {
        purchases,
        onPlanPurchase,
        onRowClick,
        onComplete,
        onAddPurchase,
        onAddResponsible,
        onReject,
        onPreliminaryCompletion,
        roles
    }
) => {

    const ls = useLocalStore(() => ({
        activePage: 1,
    }))

    const PAGE_ROWS = 10
    const getTotalPages = () => Math.ceil(purchases.length / PAGE_ROWS)
    const getPage = (events) => {
        return events.slice((ls.activePage - 1) * PAGE_ROWS, ls.activePage * PAGE_ROWS)
    }

    const checkExceeded = (purchase) => {
        if (purchase.statusName !== 'Завершено') {
            if (purchase.dateDiffPlanDetailExceeded && roles.includes('start_price_formation')) {
                return {background: 'rgba(235, 58, 52,0.4)'}
            } else if (purchase.dateDiffDetailtCloseExceeded && roles.includes('finish_price_formation')) {
                return {background: 'rgba(235, 58, 52,0.4)'}
            } else {
                return
            }
        } else {
            return
        }
    }

    useEffect(() => {
        ls.activePage = 1
    }, [purchases])

    const getStatusPopupContent = (purchase) => {
        switch (purchase.statusId) {
            case purchaseStatuses.planned: {
                return (
                    <TextField
                        label="Дата формирования заявки"
                        content={formatMoment(purchase.submitDate, dateFormats.upToMinute)}
                    />
                )
            }
            case purchaseStatuses.work: {
                return (
                    <TextField
                        label="Взяли в работу"
                        content={formatMoment(purchase.detailSubmitDate, dateFormats.upToMinute)}
                    />
                )
            }
            case purchaseStatuses.definedExecutor: {
                return (
                    <React.Fragment>
                        <TextField
                            key="date"
                            label="Дата определения исполнителя"
                            content={formatMoment(purchase.responsibleSetDate, dateFormats.upToMinute)}
                        />
                        <TextField
                            key="fio"
                            label="ФИО исполнителя"
                            content={purchase.responsibleFio}
                        />
                    </React.Fragment>
                )
            }
            case purchaseStatuses.preliminaryCompletion:
            case purchaseStatuses.completed: {
                return (
                    <React.Fragment>
                        <TextField
                            key="date"
                            label="Дата отправки протокола"
                            content={formatMoment(purchase.resultDoc, dateFormats.upToMinute)}
                        />
                        <TextField
                            key="number"
                            label="Номер протокола"
                            content={purchase.resultDocNum}
                        />
                        <TextField
                            key="comment"
                            label="Комментарий"
                            content={purchase.resultDescription}
                        />
                    </React.Fragment>
                )
            }
            default:
                return null
        }
    }

    const isPurchaseExceeded = (purchase) => {
        return purchase.DateDiffDetailtCloseExceeded || purchase.DateDiffPlanDetailExceeded
    }

    const getTextOrDash = (value) => {
        return value === false || value === undefined || value === null || value === "" ? "-" : value
    }

    return (
        <div className="app purchases-table">
            <Table textAlign="center" unstackable selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell content="ID"/>
                        {roles.includes('start_price_formation') || roles.includes('finish_price_formation') ?
                            <Table.HeaderCell/> : null}
                        <Table.HeaderCell content="Тип закупки"/>
                        <Table.HeaderCell content="Наименование закупки"/>
                        <Table.HeaderCell content="Поставщик"/>
                        <Table.HeaderCell content="Планируемая дата подачи документов"/>
                        <Table.HeaderCell content="Лимит закупки, руб"/>
                        <Table.HeaderCell content="Дата ввода плана"/>
                        <Table.HeaderCell content="Статус"/>
                        <Table.HeaderCell content="Ответственный за подачу заявки"/>
                        <Table.HeaderCell content="Ответственный за рассмотрение заявки"/>
                        <Table.HeaderCell content="Период согласования, сут"/>
                        <Table.HeaderCell content="Отклонение при подаче заявки, сут"/>
                        <Table.HeaderCell content="Отклонение при рассмотрении заявки, сут"/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getPage(purchases).map((purchase, index) => (
                        <Table.Row key={index} className={cn(isPurchaseExceeded(purchase) && "row-exceeded-purchase")}
                                   style={checkExceeded(purchase)}>
                            <Table.Cell onClick={() => onRowClick(purchase)} content={purchase.purchaseId}/>
                            {roles.includes('start_price_formation') || roles.includes('finish_price_formation') ?
                                <Table.Cell>
                                    <PurchaseActionCell
                                        purchase={purchase}
                                        roles={roles}
                                        onAddPurchase={() => onAddPurchase(purchase)}
                                        onAddResponsible={() => onAddResponsible(purchase)}
                                        onReject={() => onReject(purchase)}
                                        onPlanPurchase={() => onPlanPurchase(purchase)}
                                        onPreliminaryCompletion={() => onPreliminaryCompletion(purchase)}
                                        onComplete={() => onComplete(purchase)}
                                    />
                                </Table.Cell> : null}
                            <Table.Cell onClick={() => onRowClick(purchase)} content={purchase.typeName}/>
                            <Popup size='mini' position='top center'
                                   trigger={<Table.Cell className="name-cell" onClick={() => onRowClick(purchase)}
                                                        content={purchase.purchaseName.split(' ').slice(0, 5).join(' ')}/>}><Popup.Content>{purchase.purchaseName}</Popup.Content></Popup>
                            <Table.Cell onClick={() => onRowClick(purchase)} content={purchase.objProvider}/>
                            <Table.Cell onClick={() => onRowClick(purchase)}
                                        content={formatMoment(purchase.planDocDate, "DD MMM YYYY г.")}/>
                            <Table.Cell onClick={() => onRowClick(purchase)}
                                        content={formatPrice(purchase.priceLimit)}/>
                            <Table.Cell onClick={() => onRowClick(purchase)}
                                        content={formatMoment(purchase.createDate, "DD MMM YYYY г.")}/>
                            <Popup
                                position='top center'
                                trigger={
                                    <Table.Cell
                                        onClick={() => onRowClick(purchase)} content={purchase.statusName}
                                    />
                                }
                                content={purchase.statusId === purchaseStatuses.rejectPurchase ? purchase.resultDescription : getStatusPopupContent(purchase)}
                                disabled={!(purchase.statusId === purchaseStatuses.planned
                                    || purchase.statusId === purchaseStatuses.work
                                    || purchase.statusId === purchaseStatuses.definedExecutor
                                    || purchase.statusId === purchaseStatuses.preliminaryCompletion
                                    || purchase.statusId === purchaseStatuses.completed
                                    || purchase.statusId === purchaseStatuses.rejectPurchase
                                )}
                            />
                            <Table.Cell content={getTextOrDash(purchase.buyerFio)}
                                        onClick={() => onRowClick(purchase)}/>
                            <Table.Cell content={getTextOrDash(purchase.responsibleFio)}
                                        onClick={() => onRowClick(purchase)}/>
                            <Table.Cell
                                content={getTextOrDash(purchase.detailSubmitDate && purchase.resultSubmitDate &&
                                    moment(purchase.resultSubmitDate).diff(moment(purchase.detailSubmitDate), "days")
                                )}
                                onClick={() => onRowClick(purchase)}
                            />
                            <Table.Cell
                                // number type (double)
                                content={getTextOrDash(purchase.dateDiffDetailtClose)}
                                onClick={() => onRowClick(purchase)}
                            />
                            <Table.Cell
                                content={getTextOrDash(purchase.dateDiffPlanDetail)}
                                onClick={() => onRowClick(purchase)}
                            />
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {purchases.length > PAGE_ROWS && (
                <div className="pagination-container">
                    <Pagination
                        activePage={ls.activePage}
                        totalPages={getTotalPages()}
                        onPageChange={(e, {activePage}) => {
                            ls.activePage = activePage
                        }}
                    />
                </div>
            )}
        </div>
    );
})

export default PurchasesTable;