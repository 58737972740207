import React from 'react';
import {Button, Form} from "semantic-ui-react";
import LabeledDropdown from "../../../shared/LabeledDropdown";
import LabeledText from "../../../shared/LabeledText";
import {formOptions} from "../../../helpers/utils";
import {observer} from "mobx-react-lite";
import {dateFormats} from "../../../Dictionaries";
import LabeledDateInput from "../../../shared/LabeledDateInput";
import LabeledTimeInput from "../../../shared/LabeledTimeInput";
import cn from "classnames";
import moment from "moment";
import styles from './EdirRepair.module.scss'
import useDidFirstRender from "../../../hooks/useDidFirstRender";

const SECTIONS_COUNT_MAX = 4


/**
 * repairTypes:
 *      > `{name, id}`
 *
 * sections:
 *      > `{count, onChange}`
 */
const EditRepair = observer((
    {date, sld, series, repairType, sections, onSave, loading, dateOnChange, startDate, endDate, repairTypes, repairTypeOnChange}
) => {
    const isDateCorrect = () => date.isAfter(startDate) && date.isBefore(endDate) && date.isAfter(moment())

    return (
        <Form loading={loading}>
            <Form.Group widths="equal">
                <Form.Field>
                    {dateOnChange ? (
                        <LabeledDateInput
                            label="Дата постановки" withoutContainer
                            date={date}
                            closable
                            minDate={startDate}
                            maxDate={endDate}
                            onChange={(d) => {
                                dateOnChange(d.hours(date.hours()).minutes(date.minutes()).seconds(date.seconds()))
                            }}
                        />
                    ) : (
                        <LabeledText
                            label="Дата постановки" withoutContainer
                            text={date ? date.format(dateFormats.upToDay) : "Не задана"}
                        />
                    )}
                </Form.Field>
                {dateOnChange && (
                    <Form.Field>
                        <LabeledTimeInput
                            className={cn(!isDateCorrect() && styles.incorrectTime)}
                            label="Время постановки"
                            withoutContainer
                            time={{h: date ? date.hours() : 0, m: date ? date.minutes() : 0}}
                            onChange={({h, m}) => {
                                dateOnChange(moment(date).hours(h).minutes(m))
                            }}
                        />
                    </Form.Field>
                )}
                <Form.Field>
                    {repairTypes ? (
                        <LabeledDropdown
                            label="Вид ремонта" withoutContainer
                            options={repairTypes.slice()}
                            value={repairType.id}
                            onChange={(e, {value}) => {
                                const rtOption = repairTypes.find((rt) => rt.key === value)
                                repairTypeOnChange({id: rtOption.key, name: rtOption.text})
                            }}
                        />
                    ) : (
                        <LabeledText
                            label="Вид ремонта" withoutContainer
                            text={repairType.name}
                        />
                    )}
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <LabeledText
                        label="Место проведения" withoutContainer
                        text={sld.name}
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field>
                    <LabeledText
                        label="Количество секций" withoutContainer
                        text={sections.count}
                    />
                </Form.Field>
                <Form.Field>
                    <LabeledText
                        label="Серия" withoutContainer
                        text={series.name}
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group>
                <Form.Field>
                    <Button
                        disabled={!isDateCorrect()}
                        content="Сохранить"
                        onClick={() => {
                            onSave(sld, repairType, date, sections, series)
                        }}
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    )
})

const createArr = (from, max) => {
    const arr = []
    for (let i = from; i <= max; i++) {
        arr.push(i)
    }
    return arr
}

export default EditRepair;