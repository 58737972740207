import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Grid, Header, Icon, Loader, Segment, Table} from "semantic-ui-react";
import ekasut from '../../../../../api/ekasut';
import styles from "../../OrganizationInspection.module.scss";

export const ComList = ({setAddComMember, comList, orderPeople, setOrderPeople, getOrderPeople}) => {

    const [loading, setLoading] = useState(true)
    const [orderUUID, setOrderUUID] = useState('')

    const removeComisar = async(rgPpl) => {
      const response = await ekasut.commisionInspection.delPeopleOrder({pRgPpl: rgPpl})
      response.status === 200 && getOrderPeople(orderUUID)
    }

    useEffect(() => {
        setTimeout(setLoading(false, 2000))
        const getOrderUUID = async() => {
          const response = await ekasut.commisionInspection.getOrderFirm(localStorage.getItem('kodFirm'), localStorage.getItem('seasonYear'), localStorage.getItem('season'))
          setOrderUUID(response?.data[0]?.rgOrder)
          localStorage.setItem('orderUUID', response?.data[0]?.rgOrder)
        }

        getOrderUUID()
        getOrderPeople(orderUUID)
    }, [orderUUID])
    
    return (
        <Segment className={styles.workSegment}>
            <Dimmer active={loading} inverted>
                <Loader inverted>Загрузка</Loader>
            </Dimmer>
            <Grid className={styles.genInfoGrid}>
                <Grid.Row className={styles.infoRowGrid}>
                    <Grid.Column>
                        <Header as="h3" content="Сведения о составе комиссии КО"
                                className={styles.infoHeader}/>
                        <Table celled textAlign={'center'}>
                          <Table.Header>
                              <Table.Row>
                                  <Table.HeaderCell>Роль</Table.HeaderCell>
                                  <Table.HeaderCell>Ф.И.О.</Table.HeaderCell>
                                  <Table.HeaderCell>Должность</Table.HeaderCell>
                                  <Table.HeaderCell>Подразделение</Table.HeaderCell>
                                  <Table.HeaderCell>Ответственность</Table.HeaderCell>
                                  <Table.HeaderCell>Действия</Table.HeaderCell>
                              </Table.Row>
                          </Table.Header>

                          <Table.Body>
                              {orderPeople.map(e => (
                                <Table.Row key={e?.rgPpl}>
                                  <Table.Cell>{e?.roleName}</Table.Cell>
                                  <Table.Cell>{e?.fio}</Table.Cell>
                                  <Table.Cell>{e?.profName}</Table.Cell>
                                  <Table.Cell>{e?.itemName}</Table.Cell>
                                  <Table.Cell>{e?.itemName}</Table.Cell>
                                  <Table.Cell><Button onClick={() => removeComisar(e?.rgPpl)}>❌</Button></Table.Cell>
                                </Table.Row>
                              ))}
                          </Table.Body>
                        </Table>
                        <Button disabled={localStorage.isActual === "1" ? true : false} className="button-link" basic onClick={() => {
                            setAddComMember(true)
                        }}>
                            <Icon name={'plus'}/>
                            Добавить члена комиссии
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};