import React, {useState} from 'react'
import {Map} from "react-leaflet";
import appStyles from "../../../css/App.module.scss";
import {Button, Header, Icon, Popup, Segment} from "semantic-ui-react";
import LayerSelector, {layerNames} from "../../../shared/LayerSelector";
import useApiMethod from "../../../hooks/useApiMethod";
import ekasut from "../../../api/ekasut";
import DelayedSearch from "../../NsiWeightLength/DelayedSearch";
import {formOptions} from "../../../helpers/utils";
import ComponentMarker from "../../../shared/ComponentMarker";
import useMapRef from "../../../hooks/useMapRef";
import TableForPopup from "../../RatingsWorkLocomotives/TableForPopup";
import cn from 'classnames'
import moment from 'moment'
import {dateFormats} from "../../../Dictionaries";
import AppModal from "../../../shared/AppModal";

export default function LocationTchmi({hideSidebars}) {
    const [mapRef, onMapRefChangeHandler] = useMapRef()
    const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(null)

    const [sendGetEmployees, employeesLoading, employees] = useApiMethod(ekasut.tchmiNsi.getPeople, undefined, [],
        ({data}) => data.map(item => ({...item, fio: `${item.lastName} ${item.firstName} ${item.patrName}`}))
    )

    const [sendGetPersonLocation, personLocationLoading, personLocation, setPersonLocation] = useApiMethod(ekasut.persData.getLocationByTabNum, undefined, null,
        ({data}) => data === '' ? null : {...data, dataDate: moment(data.dataDate)})

    const [messageModal, setMessageModal] = useState({open: false})

    const selectedEmployee = employees[selectedEmployeeIndex] !== undefined ? employees[selectedEmployeeIndex] : {}

    return (
        <div onClick={hideSidebars} className='route-container'>
            <Map
                className={appStyles.map}
                ref={onMapRefChangeHandler}
                {...mapSettings}
            >
                {personLocation !== null && (
                    <PersonMarker
                        position={[personLocation.lat, personLocation.lon]}
                        fio={selectedEmployee.fio}
                        date={personLocation.dataDate}
                        asutrId={selectedEmployee.asutrId}
                    />
                )}
                <LayerSelector checkedLayerDef={layerNames.rzd}/>
            </Map>
            <Segment
                style={{width: 485, position: 'absolute', top: 65, right: 30}}
                className={appStyles.halfTransparentSegment}
            >
                <Header as={'h4'} textAlign="center">
                    Текущее местоположение ТЧМИ по данным мобильных устройств
                </Header>
                <label>Поиск сотрудника на карте</label>
                <div style={{display: 'flex'}}>
                    <DelayedSearch
                        fluid
                        disabled={personLocationLoading}
                        {...searchConstProps}
                        options={formOptions(employees, 'fio')}
                        loading={employeesLoading}
                        value={selectedEmployeeIndex}
                        typeEnd={text => sendGetEmployees(text, ekasut.tchmiNsi.dolj.instructor)}
                        onChange={(e, {value: index}) => {
                            setSelectedEmployeeIndex(index)
                            sendGetPersonLocation(employees[index].asutrId)
                                .then(person => {
                                    if (person !== null) {
                                        mapRef.leafletElement.setView([person.lat, person.lon], 10)
                                    } else {
                                        setMessageModal({open: true, message: `Позиция сотрудника: ${employees[index].fio} - не известна`})
                                    }
                                })
                        }}
                    />
                    <Button
                        style={{marginLeft: 10}}
                        icon='erase'
                        basic
                        loading={personLocationLoading}
                        disabled={personLocationLoading}
                        onClick={() => {
                            setSelectedEmployeeIndex(null)
                            setPersonLocation(null)
                        }}
                    />
                    <AppModal
                        open={messageModal.open}
                        onClose={() => setMessageModal({open: false})}
                        message={messageModal.message}
                    />
                </div>
            </Segment>
        </div>
    )
}
const PersonMarker = ({position, fio, date, asutrId}) => {
    return (
        <ComponentMarker
            position={position}
            relX={-14}
            relY={-20}
        >
            <Popup
                trigger={<Icon name='street view' size='big'/>}
                className={cn(appStyles.popupFixWidth, appStyles.paddingZero)}
                content={(
                    <TableForPopup
                        title={fio}
                        hideUndefNullValues={false}
                        data={[{
                            text: 'ТН ЕК АСУТР',
                            unit: '',
                            value: asutrId
                        }, {
                            text: 'Дата/время получения координаты',
                            unit: '',
                            value: date.format(dateFormats.upToMinute)
                        }]}
                    />
                )}
            />
        </ComponentMarker>
    )
}
PersonMarker.defaultProps = {
    date: {
        format: () => {
        }
    }
}

const searchConstProps = {
    delay: 1000,
    minCharacters: 3,
}
const mapSettings = {
    center: [57.778288, 79.126642],
    zoom: 4,
    minZoom: 4,
    maxZoom: 16,
    zoomControl: false
}