import React from 'react';
import {observer} from "mobx-react-lite";
import {Table} from "semantic-ui-react";
import {formatDateStr} from "../../helpers/utils";
import mileageStyles from './AboutMileage.module.scss'

const AboutMileage = observer(({detail}) => {
    const d = detail

    return (
        <Table celled structured compact unstackable className="compact-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center" rowSpan={2}>Вид</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" rowSpan={2}>Дата</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" rowSpan={2}>Место</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" colSpan={2}>Пробег</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" colSpan={2}>Норматив пробега</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">км</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">сут./час</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">км</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">сут./час</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Row
                    type="Постройка"
                    dateStr={d.datePostr}
                    place={d.naimPredpPostr}
                    mileageKm={d.probPostrKm}
                    mileageExcessKmSign={d.priznProbPostrKm}
                    mileageDh={d.probPostrVr}
                    mileageExcessDhSign={d.priznProbPostrVr}
                    normMileageKm={d.normProbPostrKm}
                    normMileageDh={d.normProbPoVrrKm}
                />
                <Row
                    type="КР"
                    dateStr={d.dateKr}
                    place={d.naimPredpKr}
                    mileageKm={d.probKrKm}
                    mileageExcessKmSign={d.priznProbKrKm}
                    mileageDh={d.probKrVr}
                    mileageExcessDhSign={d.priznProbKrVr}
                    normMileageKm={d.normProbKrKm}
                    normMileageDh={d.normProbKrVr}
                />
                <Row
                    type="СР"
                    dateStr={d.dateSr}
                    place={d.naimPredpSr}
                    mileageKm={d.probSrKm}
                    mileageExcessKmSign={d.priznProbSrKm}
                    mileageDh={d.probSrVr}
                    mileageExcessDhSign={d.priznProbSrVr}
                    normMileageKm={d.normProbSrKm}
                    normMileageDh={d.normProbSrVr}
                />
                <Row
                    type="ТР-3"
                    dateStr={d.dateTr3}
                    place={d.naimPredpTr3}
                    mileageKm={d.probTr3Km}
                    mileageExcessKmSign={d.priznProbTr3Km}
                    mileageDh={d.probTr3Vr}
                    mileageExcessDhSign={d.priznProbTr3Vr}
                    normMileageKm={d.normProbTr3Km}
                    normMileageDh={d.normProbTr3Vr}
                />
                <Row
                    type="ТР-2"
                    dateStr={d.dateTr2}
                    place={d.naimPredpTr2}
                    mileageKm={d.probTr2Km}
                    mileageExcessKmSign={d.priznProbTr2Km}
                    mileageDh={d.probTr2Vr}
                    mileageExcessDhSign={d.priznProbTr2Vr}
                    normMileageKm={d.normProbTr2Km}
                    normMileageDh={d.normProbTr2Vr}
                />
                <Row
                    type="ТР-1"
                    dateStr={d.dateTr1}
                    place={d.naimPredpTr1}
                    mileageKm={d.probTr1Km}
                    mileageExcessKmSign={d.priznProbTr1Km}
                    mileageDh={d.probTr1Vr}
                    mileageExcessDhSign={d.priznProbTr1Vr}
                    normMileageKm={d.normProbTr1Km}
                    normMileageDh={d.normProbTr1Vr}
                />
                <Row
                    type="ТО-3"
                    dateStr={d.dateTo3}
                    place={d.naimPredpTo3}
                    mileageKm={d.probTo3Km}
                    mileageExcessKmSign={d.priznProbTo3Km}
                    mileageDh={d.probTo3Vr}
                    mileageExcessDhSign={d.priznProbTo3Vr}
                    normMileageKm={d.normProbTo3Km}
                    normMileageDh={d.normProbTo3Vr}
                />
                <Row
                    type="ТО-2"
                    dateStr={d.dateTo2}
                    dateUpTo="minute"
                    place={d.naimPredpTo2}
                    mileageKm={d.probTo2Km}
                    mileageExcessKmSign={d.priznProbTo2Km}
                    mileageDh={d.probTo2Vr}
                    mileageExcessDhSign={d.priznProbTo2Vr}
                    normMileageKm={d.normProbTo2Km}
                    normMileageDh={d.normProbTo2Vr}
                />
            </Table.Body>
        </Table>
    );
})

const getClassHighlightRerun = (excessId) => {
    return (excessId === 1 ? mileageStyles.rerunWarning : (excessId === 2 ? mileageStyles.rerunNegative : mileageStyles.rerunPositive))
}

const Row = ({type, dateStr, place, mileageKm, mileageExcessKmSign, mileageDh, mileageExcessDhSign, normMileageKm, normMileageDh, dateUpTo}) => (
    <Table.Row>
        <Table.Cell>{type}</Table.Cell>
        <Table.Cell textAlign="center" className={mileageStyles.date}>{formatDateStr(dateStr, dateUpTo)}</Table.Cell>
        <Table.Cell>{place}</Table.Cell>
        <Table.Cell textAlign="right" className={getClassHighlightRerun(mileageExcessKmSign)}>{mileageKm}</Table.Cell>
        <Table.Cell textAlign="right" className={getClassHighlightRerun(mileageExcessDhSign)}>{mileageDh}</Table.Cell>
        <Table.Cell textAlign="right">{normMileageKm}</Table.Cell>
        <Table.Cell textAlign="right">{normMileageDh}</Table.Cell>
    </Table.Row>
)
Row.defaultProps = {
    dateUpTp: "day"
}

export default AboutMileage;