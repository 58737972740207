import React, {useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Header, Modal} from "semantic-ui-react";
import Widget from "../../shared/Widget";
import InputField from "../../shared/fields/InputField";
import {observer, useLocalStore} from "mobx-react-lite";
import ProcessViewButtons from "./ProcessViewButtons";
import DateField from "../../shared/fields/DateField";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import FilesList from "./FilesList";
import {runInAction, toJS} from "mobx";
import DropdownField from "../../shared/fields/DropdownField";
import TextField from "../../shared/fields/TextField";
import './AddPurchaseDetailsView.scss'
import SearchField from "../../shared/fields/SearchField";
import {regex} from "../../helpers/utils";
import moment from 'moment';
import cn from 'classnames'
import {formatPrice, parsePrice} from "../../helpers/utils";
import UrgentlySubmiteModal from "./UrgentlySubmiteModal";

const AddPurchaseDetailsProductView = observer((
    {
        open, purchase = {}, onSaveOrEnd = () => null, onClose = () => null, editable, updateAvailablePurchases
    }
) => {

    const [openUrgentlySubmit, setOpenUrgentlySubmit] = useState(false)

    const defaultValues = {
        description: "", // Комментарий
        codeSkmtr: "", // Код СКМТР
        planCount: "", // Плановое количество
        contractNum: "", // Номер договора
        contractor: "", // Контрагент
        draft: "", // Чертеж
        isGroup: false, // Вид закупки
        letterDate: "", // Дата письма
        letterNum: "", // Номер письма
        priceOffer: "", // Ценовое предложение
        priceSum: "", // Сумма хакупки
        productName: "", // Наименование товара
        productProvider: "", // Поставщик
        responsible: "" // Ответственный
    }

    const ls = useLocalStore(() => ({
        values: defaultValues,
        files: [],
        productsSkmtr: [],
        contractors: [],
        wasFirstRender: false,
    }))

    const updateFileList = () => {
        const updateFiles = () => {
            ekasut.purchases.getFiles({purchaseId: purchase.purchaseId})
                .then(({data}) => {
                    runInAction(() => {
                        data.forEach((item) => {
                            const file = ls.files.find(file => file.typeId === item.ftId)
                            // File with that file type is exist and do not uploaded by user
                            if (file && file.file === null) {
                                file.attached = true
                                file.file = null
                                file.fileId = item.files[0].fileId
                                file.isUploaded = true
                                file.name = item.files[0].fileName
                            }
                        })
                    })
                })
        }
        if (ls.fetchFileTypesLoaded) {
            updateFiles()
        }
        if (!ls.fetchFileTypesStarted) {
            ls.fetchFileTypesStarted = true
            ekasut.purchases.getFileTypes()
                .then(({data}) => {
                    runInAction(() => {
                        ls.fetchFileTypesLoaded = true
                        ls.files = data.map(item => ({
                            // Don't change
                            description: item.name,
                            typeId: item.code,
                            note: item.description,
                            // Will change
                            attached: false,
                            file: null,
                            fileId: null,
                            isUploaded: false,
                            name: "",
                        }))
                    })
                })
                .then(updateFiles)
                .catch(catchNetworkErrors)
        }
    }

    useEffect(() => {
        if (open) {
            ls.save = defaultValues
            // reset files
            ls.files.forEach(file => {
                file.attached = false
                file.file = null
                file.fileId = null
                file.isUploaded = false
                file.name = ""
            })
            updateFileList()
        }
        if (open && purchase.hasDetail) {
            ekasut.purchases.getPurchaseProduct({purchaseId: purchase.purchaseId})
                .then(({data}) => {
                    ls.values = {
                        description: data.description,
                        codeSkmtr: data.codeSkmtr !== null ? data.codeSkmtr.toString() : "",
                        planCount: data.planCount,
                        contractNum: data.contractNum,
                        contractor: data.contractor || "",
                        draft: data.draft,
                        isGroup: data.isGroup,
                        letterDate: data.letterDate && moment(data.letterDate),
                        letterNum: data.letterNum,
                        priceOffer: formatPrice(data.priceOffer),
                        priceSum: formatPrice(data.priceSum),
                        productName: data.productName ? data.productName : purchase.purchaseName,
                        productProvider: data.productProvider,
                        responsible: data.buyerFio || "",
                    }
                })
                .catch(catchNetworkErrors)
        }
    }, [open])

    useEffect(() => {
        if (open && !ls.wasFirstRender) {
            ls.wasFirstRender = true
            ekasut.purchases.getNamesWithSkmtr()
                .then(({data}) => ls.productsSkmtr = data)
                .catch(catchNetworkErrors)
            ekasut.purchases.getContractors()
                .then(({data}) => ls.contractors = data)
                .catch(catchNetworkErrors)
        }
    }, [open])

    const closeHandler = () => {
        ls.values = defaultValues
        onClose()
    }


    const purchaseTypeOptions = useMemo(() => purchaseTypes.map((t, i) =>
        ({text: t.name, value: t.isGroup, key: i})), [purchaseTypes])


    const productNamesResults = useMemo(
        () => ls.productsSkmtr.map((pr, index) => ({
            title: pr.productName,
            description: "код: " + pr.codeSkmtr.toString(),
            name: pr.productName,
            code: pr.codeSkmtr.toString(),
            key: index
        })).filter(pr => pr.name.indexOf(ls.values.productName) !== -1),
        [ls.productsSkmtr, ls.values.productName]
    )

    const skmtrResults = useMemo(
        () => ls.productsSkmtr.map((pr, index) => ({
            title: pr.productName,
            description: "код: " + pr.codeSkmtr.toString(),
            name: pr.productName,
            code: pr.codeSkmtr.toString(),
            key: index
        })).filter(pr => pr.code.indexOf(ls.values.codeSkmtr) !== -1),
        [ls.productsSkmtr, ls.values.codeSkmtr]
    )

    const contractorsResults = useMemo(
        () => ls.contractors.map((contr, index) => ({
            title: contr.contractor,
            name: contr.contractor,
            key: index
        })).filter(contr => contr.name.indexOf(ls.values.contractor) !== -1),
        [ls.contractors, ls.values.contractor]
    )

    const saveHandler = (purchase, isEnd) => {
        onSaveOrEnd(
            {
                ...ls.values,
                files: ls.files,
                priceOffer: parsePrice(ls.values.priceOffer),
                priceSum: parsePrice(ls.values.priceSum)
            },
            purchase,
            isEnd
        )
    }

    const SKMTR_CODE_MAX_LENGTH = 10


    return (
        <Modal open={open} /*size="tiny"*/ className="alt-semantic app procurement-planning add-purchase-details-view"
               onClose={closeHandler}>
            <UrgentlySubmiteModal openUrgentlySubmit={openUrgentlySubmit}
                                  setOpenUrgentlySubmit={setOpenUrgentlySubmit}
                                  purchase={purchase}
                                  closeHandler={closeHandler}
                                  updateAvailablePurchases={updateAvailablePurchases}
            />
            <Widget
                closeIcon
                onClose={closeHandler}
                header={editable ? "Добавление заявки (Товара)" : "Просмотр заявки (Товара)"}
                footer={editable && (
                    <ProcessViewButtons
                        showUrgSub
                        urgentlySubmit={() => {
                            setOpenUrgentlySubmit(true)
                        }}
                        onEnd={() => saveHandler(purchase, true)}
                        onSave={() => saveHandler(purchase, false)}
                        onClose={closeHandler}
                        disabledEnd={
                            ls.values.description === ""
                            || ls.values.isGroup === ""
                            || moment(purchase.planDocDate) > moment().endOf('day')
                            || ls.values.letterNum === ""
                            || ls.values.responsible === ""
                            || (ls.values.isGroup ? false : (
                                ls.values.codeSkmtr === ""
                                || ls.values.draft === ""
                                || ls.values.contractNum === ""
                                || ls.values.productProvider === ""
                                || ls.values.contractor === ""
                                || ls.values.priceOffer === ""
                                || ls.values.planCount === ""
                                || ls.values.priceSum === ""
                            ))
                            || ls.files.filter((file) => file.typeId !== 7 && file.typeId !== 6 && file.typeId !== 9).some(file => !(file.fileId !== null || file.file !== null))
                        }
                    />
                )}
            >
                <div className="content-container ">
                    <div className="input-fields">
                        <TextField
                            label="Категория"
                            content={purchase.isPlan ? 'Плановая' : 'Внеплановая'}
                        />
                        <DropdownField
                            label="Вид закупки"
                            textFieldPlaceholder="Не определен"
                            editable={editable}
                            noEditValue={purchaseTypes.find(t => t.isGroup === ls.values.isGroup).name}
                            options={purchaseTypeOptions}
                            value={ls.values.isGroup}
                            dropdownClassName="dropdown-underline"
                            onChange={(e, {value}) => {
                                ls.values.isGroup = value
                            }}
                        />
                        <TextField
                            label="Наименование товара"
                            classNameContent="field-width"
                            content={purchase.purchaseName}
                        />
                        {!ls.values.isGroup && (<>
                            <SearchField
                                label="Код СКМТР"
                                searchClassName={cn("underline search-wrap-results", skmtrResults.length === 0 && "hide-search-results")}
                                textFieldPlaceholder="Не определен"
                                editable={editable}
                                value={ls.values.codeSkmtr}
                                results={skmtrResults}
                                onResultSelect={(e, {result}) => {
                                    ls.values.codeSkmtr = result.code
                                    ls.values.productName = result.name
                                }}
                                onSearchChange={(e, {value}) => {
                                    if (regex.naturalNumber.test(value) && value.length <= SKMTR_CODE_MAX_LENGTH) {
                                        ls.values.codeSkmtr = value
                                    }
                                }}
                            />
                            <InputField
                                label="Чертеж" inputClassName="input-underline"
                                textFieldPlaceholder="Не определен"
                                className="underline-field"
                                editable={editable}
                                value={ls.values.draft}
                                onChange={(e, {value}) => {
                                    ls.values.draft = value
                                }}
                            />
                            <InputField
                                label="№ Договора" inputClassName="input-underline"
                                textFieldPlaceholder="Не определен"
                                editable={editable}
                                value={ls.values.contractNum}
                                onChange={(e, {value}) => {
                                    ls.values.contractNum = value
                                }}
                            />
                            <InputField
                                label="Поставщик" inputClassName="input-underline"
                                textFieldPlaceholder="Не определен"
                                editable={editable}
                                value={ls.values.productProvider}
                                onChange={(e, {value}) => {
                                    ls.values.productProvider = value
                                }}
                            />
                            <SearchField
                                label="Контрагент"
                                searchClassName={cn("underline search-wrap-results", contractorsResults.length === 0 && "hide-search-results")}
                                textFieldPlaceholder="Не определен"
                                noResultsMessage={null}
                                editable={editable}
                                value={ls.values.contractor}
                                results={contractorsResults}
                                onResultSelect={(e, {result}) => {
                                    ls.values.contractor = result.name
                                }}
                                onSearchChange={(e, {value}) => {
                                    ls.values.contractor = value
                                }}
                            />
                            <InputField
                                label="Ценовое предложение, руб" inputClassName="input-underline"
                                textFieldPlaceholder="Не определено"
                                editable={editable}
                                value={ls.values.priceOffer}
                                onChange={(e, {value}) => {
                                    const number = parsePrice(value)
                                    if (number !== null) {
                                        ls.values.priceOffer = formatPrice(number)
                                    }
                                }}
                            />
                            <InputField
                                label="Плановое количество" inputClassName="input-underline"
                                textFieldPlaceholder="Не определен"
                                editable={editable}
                                value={ls.values.planCount}
                                onChange={(e, {value}) => {
                                    if (regex.naturalNumber.test(value)) {
                                        ls.values.planCount = value
                                    }
                                }}
                            />
                            <InputField
                                label="Сумма закупки, руб" inputClassName="input-underline"
                                textFieldPlaceholder="Не определена"
                                editable={editable}
                                value={ls.values.priceSum}
                                onChange={(e, {value}) => {
                                    const number = parsePrice(value)
                                    if (number !== null) {
                                        ls.values.priceSum = formatPrice(number)
                                    }
                                }}
                            />
                        </>)}
                        <DateField
                            date={ls.values.letterDate}
                            editable={editable}
                            textFieldPlaceholder="Не определено"
                            label="Дата письма" inputClassName="date-underline" closable
                            onChange={(date) => {
                                ls.values.letterDate = date
                            }}
                        />
                        <InputField
                            value={ls.values.letterNum}
                            editable={editable}
                            textFieldPlaceholder="Не определено"
                            label="Номер письма" inputClassName="input-underline"
                            onChange={(e, {value}) => {
                                ls.values.letterNum = value
                            }}
                        />
                        <InputField
                            label="Комментарий" inputClassName="input-underline"
                            textFieldPlaceholder="Не определен"
                            editable={editable}
                            value={ls.values.description}
                            onChange={(e, {value}) => {
                                ls.values.description = value
                            }}
                        />
                        <InputField
                            label="Ответственный" inputClassName="input-underline"
                            textFieldPlaceholder="Не определен"
                            editable={editable}
                            value={ls.values.responsible}
                            onChange={(e, {value}) => {
                                ls.values.responsible = value
                            }}
                        />
                    </div>
                    <div className="file-list-container">
                        <Header as="h4" content="Список файлов"/>
                        <FilesList
                            editable={editable}
                            files={ls.files.map(item => ({
                                ...item,
                                onAttached: (e, file) => {
                                    item.attached = true
                                    item.file = file
                                    item.fileId = null
                                    item.isUploaded = false
                                    item.name = file.name
                                }
                            }))}
                        />
                    </div>
                </div>
            </Widget>
        </Modal>
    );
})

const purchaseTypes = [{isGroup: false, name: "Одиночная"}, {isGroup: true, name: "Групповая"}]

export default AddPurchaseDetailsProductView;