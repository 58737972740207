import React, {useState} from 'react';
import style from "./LabelsList.module.scss";
import {Icon, Popup} from "semantic-ui-react";
import {ColorPicker, useColor} from "react-color-palette";
import {Input} from "antd";

export const UpdateItem = ({setTagName, editTag, setAction, tagName, action}) => {

    const [color, setColor] = useColor("hex", action.info.labelColorHex);

    return (
        <div className={style.editTagItem}>
            <Popup
                on='click'
                pinned
                trigger={<div style={{
                    borderRadius: '3px',
                    marginLeft: '10px',
                    width: '15px',
                    height: '15px',
                    background: `${color.hex}`
                }}/>}
                content={<ColorPicker width={120} height={100} color={color}
                                      onChange={setColor} alpha
                                      hideHSV hideRGB hideHEX/>}
            />
            <Input defaultValue={action.info.labelName} width={200} autoFocus
                   bordered={false} onChange={(e) => {
                setTagName(e.target.value)
            }}/>
            <div className={style.editActions}>
                <Icon name={'checkmark'} color={'green'}
                      disabled={!tagName.trim().length} onClick={() => {
                    editTag(action.info.labelId, tagName, color.hex)
                }
                }/>
                <Icon name={'delete'} color={'red'} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setAction(null)
                }
                }/>
            </div>
        </div>
    );
};