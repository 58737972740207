import React, {useEffect, useState} from 'react';
import style from "../ControlBar.module.scss";
import {Avatar, List, Select, Tag} from "antd";
import demoAvatar from "../../../../../../assets/images/DemoAvatar.png";
import {Icon} from "semantic-ui-react";
import ekasut from "../../../../../../api/ekasut";

export const Assignees = ({item, taskData, setTaskData}) => {

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [usersList, setUsersList] = useState([])
    const [optionList, setOptionList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])

    useEffect(() => {
        getUserList()
    }, [])

    const tagRender = (props) => {
        const {label, value, closable, onClose} = props;

        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };

        let labelInfo = usersList.find((e => e.value === value))

        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{marginRight: 3}}
            >
                {labelInfo?.label}
            </Tag>
        );
    }

    const initDefaultsUsers = (data, list) => {
        let selectedUsers = data.map((el) => (el.code))
        setSelectedUsers(selectedUsers)
        setOptionList(list.filter(el => !selectedUsers.includes(el.value)))
    }

    const selectedChange = selected => {
        setSelectedUsers(selected)
        setOptionList(usersList.filter(el => !selected.includes(el.value)))
    };

    const getUserList = () => {
        ekasut.taskboard.getUserInWGList(taskData.workGroupId)
            .then(({data}) => {
                let users = data.map((el) => ({
                    value: el.id,
                    label: el.name,
                }))
                setUsersList(users)
                initDefaultsUsers(taskData.assignees, users)
            })
    }

    const updateAssignees = () => {
        ekasut.taskboard.updateTaskAssignees(taskData.workGroupId, taskData.taskNumber, selectedUsers)
            .then(({data}) => {
                setLoading(false)
                setEditMode(false)
                setTaskData(data)
            })
    }

    return (
        <>
            <List.Item.Meta
                title={<div className={style.rowTitle}><span>{item.title}</span>
                    {taskData.workGroupUserRoleId !== 3 ? <>
                        {editMode ? <div className={style.actionBtn}>
                            <Icon name={'checkmark'}
                                  color={'green'} onClick={() => {
                                updateAssignees()
                            }}/>
                            <Icon name={'delete'} color={'red'} onClick={() => {
                                setEditMode(false)
                            }
                            }/></div> : taskData.status.categoryId !== 3 ? <span
                            onClick={() => {
                                setEditMode(true)
                            }}
                            className={style.editBtn}>ред.</span> : null}
                    </> : null}
                </div>}
                description={
                    editMode ?
                        <Select
                            tagRender={tagRender}
                            notFoundContent={'Нет пользователей'}
                            loading={loading}
                            mode="multiple"
                            showArrow
                            value={selectedUsers}
                            onChange={selectedChange}
                            style={{width: '100%'}}
                        >
                            {optionList.map(item => (
                                <Select.Option key={item.value} value={item.value}>
                                    <Avatar src={demoAvatar}/>{item.label}
                                </Select.Option>
                            ))}
                        </Select>
                        : <>
                            {item.desc.length ? <div
                                className={style.barArrDesc}>
                                {item.desc.map((el, i, arr) => (
                                    <div key={i} className={style.barAssig}>
                                        <Avatar
                                            src={demoAvatar}/>
                                        {el.name}
                                    </div>
                                ))}</div> : <span>Нет</span>
                            }
                        </>
                }
            />
        </>
    )
}