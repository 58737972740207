import React, {useEffect} from 'react';
import {Button, Message, Modal, Table} from "semantic-ui-react";
import Widget from "../../shared/Widget";
import './EventDetailsModal.scss'
import TextField from "../../shared/fields/TextField";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import {extractQuestions, getProcessQuestions} from "./functions";
import {observer, useLocalStore} from "mobx-react-lite";
import {toJS} from "mobx";

const EventDetailsModal = observer(({onClose, open, event}) => {
    const ls = useLocalStore(() => ({
        questions: null
    }))
    useEffect(() => {
        if (open) {
            ekasut.audit.getQuestionsForAdvice({adviceId: event.adviceId})
                .then(({data}) => {
                    ls.questions = extractQuestions(data)
                })
                .catch(catchNetworkErrors)
        } else {
            ls.questions = null
        }
    }, [open])

    return (
        <Modal onClose={onClose} open={open} className="app audit-control event-details-modal alt-semantic">
            <Widget
                onClose={onClose} closeIcon
                header="Информация о мероприятии"
                footer={(
                    <div className="footer">
                        <Button content="OK" onClick={onClose} className="button-link"/>
                    </div>
                )}
            >
                <div className="general-info">
                    <TextField
                        label="Мероприятие"
                        content={event.eventName}
                    />
                    <TextField
                        label="Рекомендация"
                        content={event.adviceName}
                    />
                </div>
                {ls.questions !== null && ls.questions.length === 0 && <Message warning content="Мероприятие не связано с критериями"/>}
                {ls.questions !== null && ls.questions.length > 0 && (
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content="Критерий"/>
                                <Table.HeaderCell content="Вопрос"/>
                                <Table.HeaderCell content="Ответ"/>
                                <Table.HeaderCell content="Несоответствие"/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {ls.questions.map((question) => (
                                <Table.Row key={question.questionId}>
                                    <Table.Cell content={question.criterionName}/>
                                    <Table.Cell content={question.question}/>
                                    <Table.Cell content={question.gradeName}/>
                                    <Table.Cell content={question.reasonName}/>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Widget>
        </Modal>
    );
})

export default EventDetailsModal;