import styles from "../index.module.scss";
import appStyles from "../../../css/App.module.scss";
import {Checkbox, Icon} from "semantic-ui-react";
import React from "react";

export default function CheckboxField({label, defChecked, onChange, editable, value, disabled}) {
    return (
        <div className={styles.formField}>
            <label className={appStyles.displayBlock}>{label}</label>
            {editable ? (
                <Checkbox
                    toggle
                    className={appStyles.displayBlock}
                    defaultChecked={defChecked}
                    checked={value}
                    disabled={disabled}
                    onChange={(e, {checked}) => {
                        onChange(checked)
                    }}
                />
            ) : (
                <Icon
                    className={appStyles.displayBlock}
                    name={defChecked ? 'check' : 'close'}
                    size='large'
                    color={defChecked ? 'green' : 'red'}
                />

            )}
        </div>
    )
}
CheckboxField.defaultProps = {
    onChange: () => {}
}