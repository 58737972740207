import React from 'react';
import {Accordion, Dimmer, Icon, Loader, Segment} from "semantic-ui-react";
import {observer} from "mobx-react-lite";
import LoadingLabel from "../LoadingLabel";
import styles from './StyledAccordion.module.scss'
import cn from 'classnames'
import LoaderMessage from "../LoaderMessage";
/**
 *
 * @param panels: [{title, content, loading, onPanelOpen, key}]
 */
const StyledAccordion = observer(({panels, activeIndex, onPanelClick, className}) => {

    return (
        <Accordion className={cn(styles.accordion, className)}>
            {panels.map((panel, index) => {
                const key = panel.key === undefined ? index : panel.key
                const open = activeIndex === index
                return (
                    <React.Fragment key={key}>
                        <Accordion.Title
                            className={cn(index === panels.length - 1 && "lastTitle", open && "open")}
                            active={index === activeIndex}
                            index={0}
                            onClick={() => {
                                if (!open && panel.onOpen !== undefined) {
                                    panel.onOpen()
                                }
                                onPanelClick(open ? -1 : index)
                            }}
                        >
                            <Icon name='dropdown' />
                            {panel.title}
                        </Accordion.Title>
                        <Accordion.Content
                            active={index === activeIndex}
                        >
                            {panel.loading ? (
                                <LoaderMessage content="Получение информации"/>
                                // <LoadingLabel label="Получение информации" loading={true}/>
                            ) : panel.content}
                        </Accordion.Content>
                    </React.Fragment>
                )
            })}
        </Accordion>
    );
})

export default StyledAccordion;