/*eslint no-unused-vars: "off"*/
import React from 'react'
import moment from 'moment'
import {runInAction} from "mobx";
import {FETCH_STATUS} from "../views/notUsed/PlanWorkMobX/store";
import {dateFormats} from "../Dictionaries";

export const appendPlusToPositiveNumber = (val) => (val > 0 ? '+' : '') + val

export const padStart = (num = "", targetLength, padString) => num.toString().padStart(targetLength, padString)

export const padStartZero = (num = "", targetLength = 2) => padStart(num, targetLength, '0')

export const fakePromise = (obj, ms = 1000) => {
    return new Promise(resolve => setTimeout(() => resolve({data: obj}), ms))
}

export const regex = {
    naturalNumber: /^(|\d+)$/,
    positiveRealNumbers: /^(?:[1-9]\d*|0)?(?:\.\d+)?$/,
    /** Currency value, can be used for inputs.
     *
     * regex: positive, allow two digits after the comma, can be empty
     *
     * Consider that backend mainly accept numbers with point, while russian number written with comma
     */
    currency: /(^[0-9]*(,|(,[0-9]{1,2})?))$/,
}

/** Format number to a price (russian format): 12 345 678,19 <br/>
 * Adopted for user input. <br/>
 * Don't support e <br>
 * Limits max number of digits (truncate) */
export const formatPrice = (number) => {
    if (number === null || number === "" || isNaN(number)) {
        return ""
    }

    let trNumber = number.toString()

    let dotIndex = trNumber.indexOf(".")
    // Trunc fraction to 2 digits. (NumberFormat have option maximumFractionDigits, but it will round number)
    trNumber = dotIndex !== -1 ? trNumber.substr(0, dotIndex + 1 + 2) : trNumber
    // Trunc result number to max number that will not be round (999 999 999 999 999 | 9 999 999 999 999,99)
    trNumber = trNumber.substr(0, dotIndex === -1 ? 15 : 16)
    const lastCharIsDot = /^.*\.$/

    return new Intl.NumberFormat("ru-RU").format(trNumber) + (lastCharIsDot.test(trNumber) ? "," : "")
}

/** Convert formatted price to a string number. <br/>
 * For example string "12 345 678,19" converts to: "12345678,19"
 * @return parsed string (may be parsed to number) or, if resulting string is not a number - null */
export const parsePrice = (str) => {
    // Remove spaces and replace commas with dots
    const strValue = str.replace(/\s/g, "").replace(",", ".")
    return isNaN(strValue) ? null : strValue
}

export const replaceNull = (value, replace = "") => value === null ? replace : value

export const momentIfDateElseNull = (str) => {
    if (typeof str === "string") {
        const date = moment(str)
        if (date.isValid()) {
            return date
        }
    }

    return null
}

/** object shouldn't contain functions or recursive links */
export const deepClone = (aObject) => {
    if (!aObject) {
        return aObject;
    }
    let v;
    let bObject = Array.isArray(aObject) ? [] : {};
    for (const k in aObject) {
        v = aObject[k];
        bObject[k] = (typeof v === "object") ? deepClone(v) : v;
    }
    return bObject;
}

export const formatMoment = (obj = "", format) => {
    const date = moment(obj)
    return date.isValid() ? date.format(format) : null
}

export const momentIfStr = (str) => {
    return typeof str === "string" ? moment(str) : moment("")
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const getFullName = (first, last, patr) => {
    let full = ""
    if (!(first.length === 0 && last.length === 0 && patr.length === 0)) {
        const dushIfEmptyStr = (str) => (str.length === 0 ? "-" : str)
        first = dushIfEmptyStr(first)
        last = dushIfEmptyStr(last)
        patr = dushIfEmptyStr(patr)
        full = first + last + patr
    }
    return full
}

/**
 * Преобразует список строк или список объектов у которых есть поле со строкой в options для компонента semantic
 */
export const formOptions = (arr, textName, addOptions = () => {
}) => (
    arr.map((item, index) => ({
        text: textName !== undefined ? item[textName] : item,
        key: index,
        value: index,
        ...addOptions(item, index)
    }))
)

export async function promiseSequence(creators, cb) {
    for (const creator of creators) {
        const result = await creator()
        cb(result)
    }
}

export const downloadBlob = (name, data) => {
    const {type, size} = data
    const url = window.URL.createObjectURL(new Blob([data], {type, size}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    link.click();
}

export const groupToMap = (arr, groupBy, keyConverter = orig => orig) => {
    const map = new Map()
    arr.forEach((item) => {
        const key = keyConverter(item[groupBy])
        if (!map.has(key)) {
            map.set(key, [])
        }
        map.get(key).push(item)
    })
    return map
}

export const quartalStartAtMonth = (quartal) => {
    return [0, 3, 6, 9][quartal]
}

export const getDecadeStartDay = (date) => {
    let startDay
    if (date.date() < 11) {
        startDay = 1
    } else if (date.date() < 21) {
        startDay = 11
    } else {
        startDay = 21
    }
    return startDay
}

export const getDecadeEndDay = (date) => {
    let endDay
    if (date.date() < 11) {
        endDay = 10
    } else if (date.date() < 21) {
        endDay = 20
    } else {
        endDay = date.daysInMonth()
    }
    return endDay
}

export const resetHMS = (date, toLast) => (
    toLast ? date.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0}) :
        date.set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999})
)

export const getDecadeStart = date => resetHMS(moment(date).date(getDecadeStartDay(date)))

export const getDecadeEnd = date => resetHMS(moment(date).date(getDecadeEndDay(date)))

export const decadeStartAtDay = (decade) => {
    return [1, 11, 21][decade]
}

export const decadeEndAtDay = (decade, dayInMonth) => {
    return [10, 20, dayInMonth][decade]
}

export const createArrayFromTo = (from, to, format = val => val) => {
    const array = []
    for (let i = from; i <= to; i++) {
        array.push(format(i))
    }
    return array
}

export const getLastYears = (numberOfYears, currentYear) => {
    const years = []
    for (let year = currentYear - numberOfYears; year <= currentYear; year++) {
        years.push(year)
    }
    return years
}

export function isAllDefined() {
    for (let i = 0; i < arguments.length; i++) {
        if (arguments[i] === undefined) {
            return false
        }
    }
    return true
}

export const getValuesByIndexes = (allValues, indexes) => {
    const values = []
    if (indexes === undefined) {
        return values
    }
    indexes.forEach(index => {
        if (allValues[index] !== undefined) {
            values.push(allValues[index])
        }
    })
    return values
}

// positive, without any symbols including signs
export const isNumber = val => /^\d+$/.test(val)

/**
 * @deprecated use apiWorker instead
 */
export const addFetchFunctional = (name, api, {
    parser = ({data}) => data, defValue = null, errorHandler = () => {
    }
} = {}) => {
    /*const {parser, defValue, errorHandler} = options*/
    const error = name + "Error"
    const fetch = "fetch" + capitalize(name)
    const fetchIfNeed = "fetch" + capitalize(name) + "IfNeed"
    const status = name + "Status"
    const loaded = name + "Loaded"
    const loading = name + "Loading"

    return {
        [name]: defValue,
        [status]: FETCH_STATUS.idle,
        [error]: null,
        [loaded]: false,
        [loading]: false,
        [fetch](...args) {
            this[status] = FETCH_STATUS.loading
            this[loaded] = false
            this[loading] = true
            /*console.log('fetch request', name)*/
            return api(...args)
                .then((res) => {
                    /*console.log('fetch success', name)*/
                    runInAction(() => {
                        this[status] = FETCH_STATUS.success
                        this[loaded] = true
                        this[loading] = false
                        this[name] = parser(res)
                        return this[name]
                    })
                    return this[name]
                })
                .catch(error => {
                    runInAction(() => {
                        this[loaded] = true
                        this[loading] = false
                        this[status] = FETCH_STATUS.error
                        this[error] = error
                        errorHandler(error)
                    })
                    return Promise.reject(error)
                })
        },
        [fetchIfNeed](...args) {
            if ((this[status] !== FETCH_STATUS.success) && (this[status] !== FETCH_STATUS.loading)) {
                return this[fetch](...args)
            } else {
                return Promise.resolve()
            }
        }
    }
}


export const formatDateStr = (str, upTo) => {
    const m = momentIfStr(str)
    let dateFormat
    if (upTo === "minute") {
        dateFormat = dateFormats.upToMinute
    } else {
        dateFormat = dateFormats.upToDay
    }

    return m.isValid() ? m.format(dateFormat) : null
}

export const replaceAt = (str, index, replacement) => {
    return str.substr(0, index) + replacement + str.substr(index + replacement.length);
}

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g
const reHasRegExpChar = RegExp(reRegExpChar.source)

// function from lodash
export const escapeRegExp = (string) => {
    return (string && reHasRegExpChar.test(string))
        ? string.replace(reRegExpChar, '\\$&')
        : (string || '')
}

export const getAppGrants = () => localStorage.grants.split(",") || [];