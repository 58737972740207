import React, {useState} from 'react';
import style from './TasksCounter.module.scss';
import {listLoader} from '../AnalyticsList/AnalyticsList'
import {analyticsState} from "../AnalyticsState";

export const TasksCounter = ({analyticsData, loadAnalyticsBoard}) => {

    const {totalTasksCount, activeTasksCount, doneTasksCount, closedTasksCount} = analyticsData
    const [selectedCnt, setSelectedCnt] = useState(null)

    const downloadList = (cats, name) => {
        if (document.querySelector('#listTopPosition')) {
            document.querySelector('#listTopPosition').scrollIntoView()
        }
        loadAnalyticsBoard(cats)
        listLoader(cats, 'categoryId', {name: name}, null, false, cats)
        setSelectedCnt(name)
    }

    return (
        <div className={style.counterAria}>
            <div className={style.totalCnt} onClick={() => {
                downloadList('', 'Всего')
            }}>
                <span>
                    {totalTasksCount}
                </span>
            </div>
            <div className={style.fullInfo}>
                <div className={selectedCnt === 'Активные' ? style.selected : ''} onClick={() => {
                    downloadList(1, 'Активные')
                }}>
                    <span>Активны</span>
                    {activeTasksCount}
                </div>
                <div className={selectedCnt === 'Выполненные' ? style.selected : ''} onClick={() => {
                    downloadList(2, 'Выполненные')
                }}>
                    <span>Выполнены</span>
                    {doneTasksCount}
                </div>
                <div className={selectedCnt === 'Закрытые' ? style.selected : ''} onClick={() => {
                    downloadList(3, 'Закрытые')
                }}>
                    <span>Закрыты</span>
                    {closedTasksCount}
                </div>
            </div>
        </div>
    );
};