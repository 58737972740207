import React, {useEffect, useMemo, useState} from 'react';
import {Button, Modal, Segment} from "semantic-ui-react";
import Widget from "../../shared/Widget";
import './AddEventModal.scss'
import cn from 'classnames'
import DateField from "../../shared/fields/DateField";
import InputField from "../../shared/fields/InputField";
import moment from 'moment';
import DropdownField from "../../shared/fields/DropdownField";
import TimeField from "../../shared/fields/TimeField";
import {observer, useLocalStore} from "mobx-react-lite";

/**
 *
 * @param onSave - function (completed, values)
 *
 * @type {React.FunctionComponent<{readonly onSave?: *, readonly onChange?: *, readonly open?: *}>}
 */
const AddEventModal = observer((
    {
        open, onSave, onClose, editable = true, dateStart = null, dateEnd = null, showDeleteButton,
        responsible = "", eventName = "", onDelete = () => null, processId = null, processOwner = "", processes,
    }
) => {

    const getDefaultValues = () => ({
        eventName,
        responsible,
        dateStart,
        hoursStart: dateStart ? dateStart.hours() : 8,
        hoursEnd: dateEnd ? dateEnd.hours() : 10,
        minutesStart: dateStart ? dateStart.minutes() : 0,
        minutesEnd: dateEnd ? dateEnd.minutes() : 0,
        dateEnd,
        processId,
        processOwner
    })
    const ls = useLocalStore(() => ({
        ...getDefaultValues(),
        processes
    }))

    const resetStore = () => {
        Object.assign(ls, getDefaultValues())
    }

    useEffect(() => {
        // reset props
        if (open) {
            resetStore()
        }
    }, [open])

    const processOptions = useMemo(() => ls.processes.map((process, index) =>
        ({text: process.processName, value: process.processId, key: process.processId})), [ls.processes])


    const findProcessName = (processId) => {
        const process = processes.find(pr => pr.processId === processId)
        if (process) {
            return process.processName
        }

        return ""
    }

    const saveHandler = () => {
        const dateStart = moment(ls.dateStart).hours(ls.hoursStart).minutes(ls.minutesStart)
        const dateEnd = moment(ls.dateEnd).hours(ls.hoursEnd).minutes(ls.minutesEnd)

        const values = {
            eventName: ls.eventName,
            responsible: ls.responsible,
            dateStart,
            dateEnd,
            processName: findProcessName(ls.processId),
            processId: ls.processId,
            processOwner: ls.processOwner
        }

        onSave(true, values)
    }

    return (
        <Modal
            className={cn("alt-semantic", "add-event-modal", "app")}
            open={open}
            onClose={onClose}
            size="tiny"
        >
            <Widget
                header={editable ? "Добавить мероприятие" : "Просмотр мероприятия"}
                closeIcon
                onClose={onClose}
                footer={(
                    <div className={editable ? "buttons" : "one-button"}>
                        {editable && (<>
                            <Button content="Отмена" className="button-link" onClick={onClose}/>
                            <Button
                                content="Сохранить" className="button-link" onClick={saveHandler}
                                disabled={ls.eventName === "" || ls.responsible === "" || (ls.dateStart ? !ls.dateStart.isValid() : true)
                                    || (ls.dateEnd ? !ls.dateEnd.isValid() : true) || dateStart.isAfter(dateEnd) || ls.processId === null
                                    || ls.processOwner === ""}
                            />
                        </>)}
                        {showDeleteButton && !editable && (
                            <Button content="Удалить" color="red" basic onClick={onDelete}/>
                        )}
                    </div>
                )}
            >
                <div className="inputs">
                    <InputField
                        editable={editable}
                        label="Мероприятие"
                        value={ls.eventName}
                        fluid onChange={(e, {value}) => ls.eventName = value}
                        inputClassName="input-underline"
                    />
                    <InputField
                        editable={editable}
                        label="Ответственный"
                        value={ls.responsible}
                        fluid onChange={(e, {value}) => ls.responsible = value}
                        inputClassName="input-underline"
                    />
                </div>
                <div className="inputs">
                    <DropdownField
                        editable={editable}
                        label="Процесс"
                        dropdownClassName="dropdown-underline"
                        fluid
                        noEditValue={findProcessName(processId)}
                        value={ls.processId}
                        options={processOptions}
                        onChange={(e, {value}) => {
                            ls.processId = ls.processes.find(pr => pr.processId === value).processId
                        }}
                    />
                    <InputField
                        editable={editable}
                        label="Владелец процесса"
                        value={ls.processOwner}
                        fluid onChange={(e, {value}) => ls.processOwner = value}
                        inputClassName="input-underline"
                    />
                </div>
                {/*<div className="inputs">
                    <InputField
                        label="Процесс"
                    />
                    <InputField
                        label="Владелец процесса"
                    />
                </div>*/}
                <div className="inputs">
                    <DateField
                        editable={editable}
                        inputClassName="date-underline"
                        label="Дата начала"
                        fluid closable
                        date={ls.dateStart}
                        maxDate={ls.dateEnd}
                        onChange={date => {
                            ls.dateStart = date
                        }}
                    />
                    <TimeField
                        editable={editable}
                        label="Время" fluid
                        hours={ls.hoursStart}
                        minutes={ls.minutesStart}
                        onChange={(hours, minutes) => {
                            ls.hoursStart = hours
                            ls.minutesStart = minutes
                        }}
                    />
                </div>
                <div className="inputs">
                    <DateField
                        editable={editable}
                        inputClassName="date-underline"
                        label="Дата окончания"
                        minDate={ls.dateStart}
                        date={ls.dateEnd}
                        onChange={date => ls.dateEnd = date}
                        fluid closable
                    />
                    <TimeField
                        editable={editable}
                        label="Время" fluid
                        hours={ls.hoursEnd}
                        minutes={ls.minutesEnd}
                        onChange={(hours, minutes) => {
                            ls.hoursEnd = hours
                            ls.minutesEnd = minutes
                        }}
                    />
                </div>

            </Widget>
        </Modal>
    );
})

export default AddEventModal;