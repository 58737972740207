import React from 'react'
import {Dimmer, Loader, Table} from "semantic-ui-react";
import './TableForPopup.scss'
import cn from 'classnames'

/**
 * data: [{text, value, unit}
 */
export default function TableForPopup({
                                          title,
                                          data,
                                          isLoading,
                                          subtitle,
                                          className,
                                          hideUndefNullValues,
                                      }) {

    const getRows = () => (
        !data ? null : data.map((el, index) => {
            let {text, value, unit} = el
            if ((value === undefined) || (value === null)) {
                if (hideUndefNullValues) {
                    return null
                } else {
                    value = '-'
                }
            }

            return (
                <Table.Row key={index}>
                    <Table.Cell singleLine>{text}</Table.Cell>
                    <Table.Cell singleLine>{value + ' ' + unit}</Table.Cell>
                </Table.Row>
            )
        })
    )


    const getLoading = () => (
        <Table.Row>
            <Table.Cell colSpan={2}>
                <Dimmer active inverted className='popup-dimmer'>
                    <Loader>Загрузка</Loader>
                </Dimmer>
            </Table.Cell>
        </Table.Row>
    )

    let tableBody
    if (isLoading) {
        tableBody = getLoading()
    } else {
        tableBody = getRows()
    }

    return (
        <Table compact className={cn('pr-table-for-popup', className)} >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                        {title}<br/>
                        <span className='subtitle'>{subtitle}</span>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {tableBody}
            </Table.Body>
        </Table>
    )
}
TableForPopup.defaultProps = {
    isLoading: false,
    hideUndefNullValues: true,
}