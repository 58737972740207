import React from 'react';
import Control from "react-leaflet-control";
import {Dimmer, Loader} from "semantic-ui-react";

const LoadingControl = ({position, loading, text}) => (
    <Control position={position}>
        <Dimmer active={loading} inverted style={{width: 100, height: 100, background: "none"}}>
            <Loader content={text} style={{fontSize: 14}}/>
        </Dimmer>
    </Control>
)

export default LoadingControl;