import React from 'react';
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import {capitalize} from "../../helpers/utils";
import moment from "moment";
import './index.scss'
import cn from 'classnames'

const calendarLocalizer = momentLocalizer(moment)

/** Localized Calendar (library) with formatted dates */
const LocalizedCalendar = ({className, ...calendarProps}) => {
    return (
        <Calendar
            className={cn("app-big-calendar", className)}
            localizer={calendarLocalizer}
            startAccessor="start"
            endAccessor="end"
            formats={{
                dayRangeHeaderFormat: ({ start, end }, culture, local) =>
                    `${capitalize(local.format(start, "MMMM D"))} -
                    ${capitalize(local.format(end, "MMMM D"))}`,
                // `${capitalize(moment(start).format("MMMM D"))} -
                // ${capitalize(moment(end).format("MMMM D"))}`,
                dayFormat: (date, culture, localizer) =>
                    localizer.format(date, "DD ddd").toUpperCase(),
                dayHeaderFormat: (date, culture, local) =>
                    capitalize(local.format(date, "dddd MMM DD"))
            }}
            messages={{
                allDay: "Весь день",
                previous: "Назад",
                next: "Вперед",
                today: "Сегодня",
                month: "Месяц",
                week: "Неделя",
                day: "День",
                agenda: "Повестка дня",
                date: "Дата",
                time: "Время",
                event: "Событие",
                noEventsInRange: "В этом диапазоне нет событий",
                // showMore: () => {}, // ??? docs: onShowMore Callback fired when a +{count} more is clicked
            }}
            {...calendarProps}
        />
    );
};

export default LocalizedCalendar;