import React from 'react';
import {Input} from "semantic-ui-react";
import cn from 'classnames'
import TextField from "./TextField";
import './InputField.scss'
import './field.scss'

const InputField = ({label, className, inputClassName, placeholder = "Не выбрано", editable = true, textFieldPlaceholder,  ...inputProps}) => {

    if (!editable) {
        const isValueDefined = inputProps.value !== "" && inputProps.value !== null && inputProps.value !== undefined
        return (
            <TextField
                label={label}
                content={isValueDefined ? inputProps.value : textFieldPlaceholder}
                className={cn(className, "app-field", !isValueDefined && "text-field-placeholder")}
                {...inputProps}
            />
        )
    }

    return (
        <div className={cn("app input-field field", className, inputProps.fluid && "fluid")}>
            <label>{label}</label>
            <Input className={inputClassName} {...inputProps} placeholder={placeholder}/>
        </div>
    );
};

export default InputField;