import React, { Component } from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import { Button } from 'semantic-ui-react'
import { Modal, Form, Dropdown, Input } from 'semantic-ui-react'
import { Segment, Radio } from 'semantic-ui-react'


class RoleAddingModal extends Component {
    state = {
        checked: false,
        errorOccured: false,
        isLoading: false,
        params: {}
    }

    toggle = () => this.setState({ checked: !this.state.checked })

    componentDidMount() {}

    inputSetter(parameterName, value) {
        const {params} = this.state

        if (value) {
            params[parameterName] = value;
        } else {
            delete params[parameterName];
        }

        this.setState({params: params});
    }

    addRole() {
        var me = this;
        const {params, checked} = this.state
        // not empty object
        if (!(Object.keys(params).length === 0 && params.constructor === Object)) {
            if (!checked) {
                delete params.roleAccess
            }

            me.setState({isLoading: true})

            axios.put(`${window.backendUrl}/api/roles/roles`, params)
                .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    } else {
                        me.setState({isLoading: false});
                        me.props.onClose();
                    }
                }
            )
                .catch(function (err) {
                    if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                        auth.logout()
                    }
                    me.setState({isLoading: false, errorOccured: true, errorCode: err});
                });

        }

    }

    render() {
        const {params} = this.state

        return (
            <Modal className='add-role' closeIcon size='tiny' open={this.props.addRoleModalOpen} onClose={this.props.onClose} centered={false}>
                <Modal.Header>Добавить роль</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Form>
                            <Segment compact>
                                <Radio toggle label='роль для уровня доступа' onChange={this.toggle} checked={this.state.checked} />
                            </Segment>

                            { (!this.state.checked)
                                ? (<Form.Field>
                                    <label>Описание</label>
                                    <Input placeholder='Название' onChange={(e, { value }) => {this.inputSetter('roleDesc', value)}}/>
                                </Form.Field>)
                                : (<Form.Field>
                                    <label>Предприятие</label>
                                    <Dropdown label='Предприятие' placeholder='Предприятие' scrolling search
                                              selection options={this.props.preds} className='role-preds'
                                              onChange={(e, {value, options}) => {
                                                  this.setState({
                                                      params: {
                                                          ...params,
                                                          roleAccess: value,
                                                          roleDesc: options.filter(item => item.value === value)[0].text
                                                      }
                                                  });
                                              }}/>
                                </Form.Field>)
                            }


                            <Form.Field>
                                <label>Имя</label>
                                <Input placeholder='role_name'
                                       onChange={(e, { value }) => {this.inputSetter('roleName', value)}}/>
                            </Form.Field>

                            <Button type='submit' disabled={(Object.keys(params).length === 0 && params.constructor === Object)} onClick={() => {this.addRole()}}>Подтвердить</Button>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

export default RoleAddingModal;