import React from 'react';
import styles from './JustifyContainer.module.scss'
import cn from 'classnames'

// rightGap: "small, ,large,"
const JustifyContainer = ({justifyContent, gapRight, alignItems, wrap, children, style, className, ...props}) => {
    const classGap = getGapClass(gapRight)

    return (
        <div style={{display: "flex", justifyContent, alignItems, flexWrap: wrap ? "wrap" : "nowrap", ...style}} {...props} className={cn(className, classGap)}>
            {children}
        </div>
    );
};
const getGapClass = (rightGap) => {
    switch (rightGap) {
        case "small": {
            return styles.rightGapSmall
        }
        case "large": {
            return styles.rightGapLarge
        }
        case "norm": {
            return styles.rightGapNorm
        }
        default:
            return undefined
    }
}


export default JustifyContainer;