import React, { useEffect } from "react";
import { Tab } from "semantic-ui-react";
import ConservationOrders from "./ConservationOrders/ConservationOrders";
import LocomotivesOnConservation from "./LocomotivesOnConservation/LocomotivesOnConservation";
import locomotivesConservationStore from "./locomotivesConservationStore";
import ekasut from "../../api/ekasut";

const getDepartments = () => {
  locomotivesConservationStore.setIsDepartmentListLoading(true);
  ekasut.locomotivesConservation
    .getDepartments()
    .then((res) => locomotivesConservationStore.setDepartmentList(res.data))
    .finally(() =>
      locomotivesConservationStore.setIsDepartmentListLoading(false)
    );
};
const getTractionTypes = () => {
  locomotivesConservationStore.setIsPullTypeListLoading(true);
  ekasut.locomotivesConservation
    .getTractionTypes()
    .then((res) => locomotivesConservationStore.setPullTypeList(res.data))
    .finally(() =>
      locomotivesConservationStore.setIsPullTypeListLoading(false)
    );
};
const getVdvigTypes = () => {
  locomotivesConservationStore.setIsMovementTypeListLoading(true);
  ekasut.locomotivesConservation
    .getVdvigTypes()
    .then((res) => locomotivesConservationStore.setMovementTypeList(res.data))
    .finally(() =>
      locomotivesConservationStore.setIsMovementTypeListLoading(false)
    );
};

const LocomotivesConservation = () => {
  useEffect(() => {
    getDepartments();
    getVdvigTypes();
    getTractionTypes();
  }, []);

  const panes = [
    {
      menuItem: "Заказы на консервацию",
      render: () => <ConservationOrders />,
    },
    {
      menuItem: "Локомотивы на консервации",
      render: () => <LocomotivesOnConservation />,
    },
  ];

  return <Tab menu={{ secondary: true, pointing: true }} panes={panes} />;
};

export default LocomotivesConservation;
