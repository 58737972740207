import React from 'react';
import {observer} from "mobx-react-lite";
import {useStore} from "../StoreProvider";
import JustifyContainer from "../../../../shared/JustifyContainer";
import {Button} from "semantic-ui-react";
import moment from 'moment';
import {downloadBlob} from "../../../../helpers/utils";
import {usePrompt} from "../../../../globalStore/useGlobalStore";

const LeftButtons = observer(() => {
    const store = useStore()
    const prompt = usePrompt()

    const handleExcelClick = () => {
        store.fetchExcel(moment(), store.selectedTche.id, store.selectedSld.id)
            .then(res => {
                if (res.status === 204) {
                    prompt.show(
                        "Excel не загружен",
                        `Нет данных по оперативному графику ( ${store.selectedTche.name} - ${store.selectedSld.name} )`,
                        {ok: true}
                    )
                }
                if (res.status !== 200) {
                    return;
                }
                downloadBlob("locomotives-detail.xlsx", res.data)
            }).catch(() => {})
    }
    const handlePdfClick = () => {
        store.fetchPdf(moment(), store.selectedTche.id, store.selectedSld.id)
            .then(res => {
                if (res.status === 204) {
                    prompt.show(
                        "PDF не загружен",
                        `Нет данных по оперативному графику ( ${store.selectedTche.name} - ${store.selectedSld.name} )`,
                        {ok: true}
                    )
                }
                if (res.status !== 200) {
                    return;
                }
                downloadBlob("locomotives-detail.pdf", res.data)
            }).catch(() => {})
    }
    const handleRefreshClick = () => {
        if (!Number.isInteger(store.selectedTche.id)) {
            prompt.show(
                "Не определен ТЧЭ",
                `id: ${store.selectedTche.id}, name: ${store.selectedTche.name}`,
                {ok: true}
            )
            return
        }
        if (!Number.isInteger(store.selectedSld.id)) {
            prompt.show(
                "Не определен СЛД",
                `id: ${store.selectedSld.id}, name: ${store.selectedSld.name}`,
                {ok: true}
            )
            return
        }
        store.tche = store.selectedTche
        store.sld = store.selectedSld
        store.isLocomotiveSelected = false
        if (store.isTcheSld()) {
            store.selectedRow = null
            store.fetchPlanAndLocomotives(store.tche.id, store.startDate)
        }
    }

    return (
        <JustifyContainer justifyContent="flex-start" gapRight="norm">
            <Button
                content="Обновить"
                disabled={store.planAndLocomotivesLoading}
                onClick={handleRefreshClick}
            />
            <Button
                content="PDF"
                disabled={store.selectedTche.id === null || store.selectedSld.id === null || store.pdfLoading}
                onClick={handlePdfClick}
                loading={store.pdfLoading}
            />
            <Button
                content="Excel"
                disabled={store.selectedTche.id === null || store.selectedSld.id === null || store.excelLoading}
                onClick={handleExcelClick}
                loading={store.excelLoading}
            />
        </JustifyContainer>
    )
})

export default LeftButtons;