import React, {useState} from 'react';
import style from './Cards.module.scss'
import {Pie} from '@ant-design/charts';
import {Divider} from 'antd';
import {ChartDescription} from "./ChartDescription/ChartDescription";
import ChatDropdownButton from "./CardDropdownButton/ChatDropdownButton";
import TableFourthCard from "./TableFourthCard/TableFourthCard";
import classNames from "classnames";

const data = [
    {
        type: 'Запас',
        value: 2800,
    },
    {
        type: 'Резерв',
        value: 1300,
    },
    {
        type: 'Повторный КО',
        value: 3200,
    },
    {
        type: 'Подлежит',
        value: 12200,
    },
];
const config = {
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.8,
    width: 380,
    height: 180,

    label: false,
    interactions: [{type: 'element-selected'}, {type: 'element-active'}],
    statistic: {
        title: {
            offsetY: -4,
            customHtml: '<div style="width:243.1875px;;font-size:1em;line-height:inherit;">19 500</div>',
            style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: 18,
                color: '#333333',
                fontWeight: 'bold'
            },
        },
        content: {
            offsetY: 4,
            customHtml: '<div style="width:121.59375px;;line-height:inherit;">Единиц ТПС</div> ',
            style: {
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: 14,
                color: '#33333399',
                fontWeight: 'normal'
            },
        },
    },
};

export const CardFour = ({data = [], setIsModalOpened, setModalContent}) => {

    const firstItem = (data && data[0]) || {};


    const onReportClick = () => {
        setIsModalOpened(true);
        setModalContent(<TableFourthCard data={data}/>);
    };
    return (
        <div className={style.card}>
            <ChatDropdownButton onReportClick={onReportClick}/>

            <div className={style.cardHeader}>
                <span>ЛОКОМОТИВЫ, ПОДЛЕЖАЩИЕ КО</span>
            </div>
            {data.length > 0 ? (
                <>
                    <div className={style.cardHorizontBody}
                         style={{justifyContent: "center", alignItems: "center", marginBottom: "15px", marginTop: "10px"}}>
                        <div className={style.shortInfo}>
                            <div className={style.dayCard}>
                                <p className={style.description}>Всего секций на КО</p>
                                <p style={{color: "black"}}>{firstItem.allSecKo}</p>
                            </div>
                        </div>
                    </div>
                    <div className={style.cardHorizontBody}
                         style={{justifyContent: "space-between", alignItems: "center"}}>
                        <div className={style.shortInfo}>
                            <div className={style.thirdCard}>
                                <p>Всего доп. работ</p>
                                <p style={{color: 'rgb(246, 192, 34)'}}>{firstItem.cntAwByKo}</p>
                            </div>
                            <div className={style.target}>
                                <p style={{color: '#78C799'}}>{firstItem.percAw}%</p>
                                выполненных доп. работ
                            </div>
                        </div>
                        <div className={style.shortInfo}>

                            <div className={style.thirdCard}>
                                <p>Кол-во выполненных доп. работ</p>
                                <p style={{color: 'rgb(246, 192, 34)'}}>{firstItem.cntRunAxw}</p>
                            </div>
                            <div className={style.target}>
                                <p style={{color: "#E84E3E"}}>{(100 - firstItem.percAw) || ""}%</p>
                                невыполненных доп. работ
                            </div>


                        </div>


                        {/*<div className={style.pie}>*/}
                        {/*    <Pie {...config} />*/}
                        {/*</div>*/}
                        {/*<Divider/>*/}
                        {/*<div className={style.locosState}>*/}
                        {/*    <ChartDescription/>*/}
                        {/*</div>*/}
                    </div>
                </>
            ) : (
                <p>Нет данных</p>
            )}
        </div>
    );
};