import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Icon, Message, Tab, Table } from "semantic-ui-react";
import { momentIfStr } from "../../helpers/utils";
import { dateFormats } from "../../Dictionaries";
import moment from "moment";
import { Checkbox } from "semantic-ui-react";
import ekasut from "../../api/ekasut";

const FutureDropDown = observer(({ tab, detail }) => {
  const bfs = tab.barrierFunctions;

  const [locDetails, setLocDetails] = useState([]);
  console.log(detail);
  const getVSCTestOnClick = async () => {
    const response = await ekasut.map.getVSCTest({
      locType: detail.serLoc,
      locNum: detail.znsLoc,
      locSec: detail.prsLoc,
    });

    console.log(response?.data);

    setLocDetails(response?.data[0]);
  };

  useEffect(() => {
    getVSCTestOnClick();
  }, []);

  return locDetails ? (
    <Table structured className="compact-table">
      <Table.Header>
        <Table.Row style={{ display: "flex" }}>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Тест тяги
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Тест рекуперации
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Тест пневматики
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Тек-я v. ПО СВЛ ТР
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Мин-я v. ПО СВЛ ТР
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Акту-я v. ПО СВЛ ТР
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Тек-я v. ПО УСАВП
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Мин-я v. ПО УСАВП
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Акту-я v. ПО УСАВП
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px", textAlign: "center" }}>
            Комп-ая готовность к ВСЦ
          </Table.HeaderCell>
          {/* Нет данных из СВЛ ТР */}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {console.log(locDetails)}
        {locDetails.testRecovery && (
          <Table.Row>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.testThrust === 0 && "Пройден"}
              {locDetails.testThrust === 1 && "Не пройден"}
              {locDetails.testThrust === 2 && "Не пройден"}
              {locDetails.testThrust === 3 && "Не пройден"}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.testRecovery === 0 && "Пройден"}
              {locDetails.testRecovery === 1 && "Не пройден"}
              {locDetails.testRecovery === 2 && "Не пройден"}
              {locDetails.testRecovery === 3 && "Не пройден"}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.testPneumatics === 0 && "Пройден"}
              {locDetails.testPneumatics === 1 && "Не пройден"}
              {locDetails.testPneumatics === 2 && "Не пройден"}
              {locDetails.testPneumatics === 3 && "Не пройден"}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.verSvltr}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.minVerSvltr}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.verSvltrValid === 1 && "Актуальная"}
              {locDetails.verSvltrValid === 0 && "Не актуальная"}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.verUsavp}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.minVerUsavp}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.verUsavpValid === 1 && "Актуальная"}
              {locDetails.verUsavpValid === 0 && "Не актуальная"}
            </Table.Cell>
            <Table.Cell
              style={{
                width: "150px",
                textAlign: "center",
                display: "inline-block",
              }}
            >
              {locDetails.complVsc === 0 && (
                <Icon disabled name="close" color="red" />
              )}
              {locDetails.complVsc === 1 && <Checkbox />}
            </Table.Cell>
          </Table.Row>
        )}
        {/* <Table.Cell>{locDetails.test_recovery}</Table.Cell>
        <Table.Cell>{locDetails.test_thrust}</Table.Cell>
        <Table.Cell>{locDetails.test_pneumatics}</Table.Cell>
        <Table.Cell>{locDetails.ver_svltr}</Table.Cell>
        <Table.Cell>{locDetails.min_ver_svltr}</Table.Cell>
        <Table.Cell>{locDetails.ver_svltr_valid}</Table.Cell>
        <Table.Cell>{locDetails.ver_usavp}</Table.Cell>
        <Table.Cell>{locDetails.min_ver_usavp}</Table.Cell>
        <Table.Cell>{locDetails.ver_usavp_valid}</Table.Cell> */}
        {/* <BarrierFunctionRow
          text="Замеры бандажей колёсных пар в ходе ТО/ТР"
          bf={bfs[1]}
        />
        <BarrierFunctionRow
          text="Замеры бандажей колёсных пар за 30 суток"
          bf={bfs[2]}
        />
        <BarrierFunctionRow text="Сроки поверок УБ" bf={bfs[3]} />
        <BarrierFunctionRow
          text="Наличие УБ для работы в два лица"
          bf={bfs[4]}
        />
        <BarrierFunctionRow
          text="Наличие УБ для работы в одно лицо"
          bf={bfs[6]}
        />
        <BarrierFunctionRow text="Наличие предписаний ФГП ВО ЖДТ" bf={bfs[7]} />
        <BarrierFunctionRow
          text="Наличие Акта технического состояния"
          bf={bfs[5]}
        /> */}
      </Table.Body>
    </Table>
  ) : (
    "Нет данных из СВЛ ТР"
  );
});

const BarrierFunctionRow = ({ text, bf }) => {
  let date = bf !== undefined ? momentIfStr(bf.checkDate) : moment("");

  return (
    <Table.Row>
      <Table.Cell>{text}</Table.Cell>
    </Table.Row>
  );
};

export default FutureDropDown;
