import React, {useState} from 'react';
import {sortableElement, sortableHandle} from "react-sortable-hoc";
import style from "./Subtasks.module.scss";
import {Checkbox, Dropdown, Input, Menu, Spin, Tooltip} from "antd";
import ekasut from "../../../../../../api/ekasut";
import {Icon} from "semantic-ui-react";
import {LoadingOutlined} from "@ant-design/icons";
import {DeleteModal} from "./DeleteModal/DeleteModal";
import moment from "moment";

const loadIcon = <LoadingOutlined style={{fontSize: '1em', margin: '0 10px 0 0px', color: 'rgba(0,0,0,0.5)'}} spin/>;
const DragHandle = sortableHandle(() => <Icon name='bars'/>);

export const SubItem = ({key, index, subtask, setTaskData, taskData, updateSubsArray}) => {

    const [loading, setLoading] = useState(false)
    const [editSub, setEditSub] = useState(false)
    const [openDel, setOpenDel] = useState(false)

    const editMenu = <Menu className={style.editSubMenu}>
        <Menu.Item key={`${index}-sub-menu`} onClick={() => {
            setEditSub(true)
        }}>
            Изменить
        </Menu.Item>
        <Menu.Item key={`${index}-sub-menu`} color={'red'} onClick={() => {
            setOpenDel(true)
        }}>
            Удалить
        </Menu.Item>
    </Menu>

    const changeCheckbox = (subtaskId, isDone) => {
        setLoading(true)
        ekasut.taskboard.updateSubtask({subtaskId, isDone})
            .then(({data}) => {
                let oldArr = taskData.subtasks
                oldArr = oldArr.map((el) => {
                    if (el.subtaskId === data.subtaskId) {
                        el = data
                    }
                    return el
                })
                setTaskData({
                    ...taskData,
                    subtasks: oldArr
                })
                setLoading(false)
            })
    }

    const updateSubDescription = (newDesc) => {
        let newSubs = [...taskData.subtasks]
        let newSubtask = {
            ...subtask,
            isDone: false,
            content: newDesc
        }
        const subToMoveIndex = taskData.subtasks.findIndex(sub => sub.subtaskId === subtask.subtaskId);
        newSubs.splice(subToMoveIndex, 1, newSubtask);

        updateSubsArray(newSubs)

    }

    const SortableItem = sortableElement(({subtask}) => (
        <li className={style.drugLi}>
            <DeleteModal openDel={openDel} setOpenDel={setOpenDel} updateSubsArray={updateSubsArray} subtask={subtask}
                         taskdata={taskData}/>
            <div className={style.drugContainer}>
                {taskData.workGroupUserRoleId === 3 ? null : <DragHandle/>}
                {loading ? <Spin indicator={loadIcon}/> : null}
                <Dropdown
                    // disabled={!checkRole(e.userRole)}
                    overlay={editMenu}
                    trigger={['contextMenu']}>

                    <p>
                        {editSub ?
                            <div className={style.editSubInput}>
                                <Input.TextArea autoFocus
                                    //bordered={false}
                                       multiple
                                       defaultValue={subtask.content}
                                       onBlur={() => {
                                           setEditSub(false)
                                       }}
                                       onPressEnter={(data) => {
                                           if (data.key === 'Enter' && !data.shiftKey) {
                                               updateSubDescription(data.target.value)
                                               setEditSub(false)

                                           }
                                       }}
                                />
                            </div> :
                            <Checkbox
                                checked={subtask.isDone}
                                disabled={loading || editSub || taskData.workGroupUserRoleId === 3}
                                onChange={() => {
                                    changeCheckbox(subtask.subtaskId, !subtask.isDone)
                                }}
                            >
                                <p style={{whiteSpace: 'pre-line'}}>{subtask.content}</p>
                            </Checkbox>}
                        {subtask.isDoneUserName ? <div className={style.updateInfo}>
                            {subtask.isDone ? <span className={style.subsAction}>Выполнил: </span> :
                                <span className={style.subsAction}>Изменил: </span>}
                            <Tooltip placement="topRight"
                                     title={subtask.isDoneAt ? `Обновлено: ${moment(subtask.isDoneAt).format('DD.MM.YYYY H:m')}` : null}>
                                <span className={style.subtuskUpdater}>{subtask.isDoneUserName}</span>
                            </Tooltip>

                        </div> : null}
                    </p>
                </Dropdown>
            </div>
        </li>
    ));

    return (
        <>
            <SortableItem key={key} index={index} subtask={subtask} disabled={taskData.workGroupUserRoleId === 3}/>
        </>
    );
}