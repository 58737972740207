import React, { useEffect, useState } from "react";
import { Map } from "react-leaflet";
import appStyles from "../../css/App.module.scss";
import LayerSelector, { layerNames } from "../../shared/LayerSelector";
import Control from "react-leaflet-control";
import { Header, Segment } from "semantic-ui-react";
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import TerminalMarker from "./TerminalMarker";
import useMapZoom from "../../hooks/useMapZoom";
import useMapRef from "../../hooks/useMapRef";
import useZoomLevel, { zoomLevels } from "../../hooks/useZoomLevel";
import AppModal from "../../shared/AppModal";
import TerminalTable from "./TerminalTable";
import DeviceTable from "./DeviceTable";

export default function WorkingCapacityEtso({ hideSidebars }) {
  const [mapRef, onMapRefChangeHandler] = useMapRef();
  const [terminalOpen, setTerminalOpen] = useState(false);
  const [deviceOpen, setDeviceOpen] = useState(false);

  const [zoom] = useMapZoom(mapSettings.zoom, mapRef);
  const [zoomLevel] = useZoomLevel(
    zoom,
    [mapSettings.minZoom, 5],
    [6, mapSettings.maxZoom]
  );
  const [roadMarkers, setRoadMarkers] = useState([]);
  const [depotMarkers, setDepotMarkers] = useState([]);
  const [sendGetRoads] = useApiMethod(
    ekasut.mek.getRoads,
    undefined,
    [],
    ({ data }) => {
      return data.map((item) => ({
        lat: item.dorLat,
        lon: item.dorLon,
        name: item.dorName,
        id: item.dorKod,
        totalTerms: item.allCnt,
        activeTerms: item.activeCnt,
      }));
    }
  );
  const [sendGetDepots] = useApiMethod(
    ekasut.mek.getDepots,
    undefined,
    [],
    ({ data }) => {
      return data.map((item) => ({
        lat: item.depoLat,
        lon: item.depoLon,
        name: item.depoName,
        depotId: item.depoCode,
        stationId: item.stationCode,
        totalTerms: item.allCnt,
        activeTerms: item.activeCnt,
      }));
    }
  );

  const [sendGetTerminal, terminalLoading, terminal] = useApiMethod(
    ekasut.mek.getTerminalsInDepot,
    undefined,
    []
  );
  const [sendGetDevice, deviceLoading, device] = useApiMethod(
    ekasut.mek.getDevicesInTerminal,
    undefined,
    []
  );

  useEffect(() => {
    sendGetRoads().then((roads) => {
      setRoadMarkers(createMarkers(roads));
    });
    sendGetDepots().then((depots) => {
      setDepotMarkers(
        createMarkers(depots, (depot) => {
          const depotIdIsValid = Number.isInteger(depot.depotId);
          sendGetTerminal(
            depotIdIsValid ? null : depot.stationId,
            depotIdIsValid ? depot.depotId : null
          );
          setTerminalOpen(true);
        })
      );
    });
  }, [sendGetDepots, sendGetRoads, sendGetTerminal]);

  return (
    <div>
      <Map
        className={appStyles.map}
        {...mapSettings}
        ref={onMapRefChangeHandler}
        onClick={hideSidebars}
      >
        <LayerSelector checkedLayerDef={layerNames.rzd} />
        <Control position={"topright"}>
          <Segment>
            <Header as={"h4"}>Мониторинг работоспособности ЭТСО</Header>
          </Segment>
        </Control>
        <AppModal
          open={terminalOpen}
          onClose={() => setTerminalOpen(false)}
          loading={terminalLoading}
          size={"large"}
        >
          <TerminalTable
            data={terminal}
            rowOnClick={(item) => {
              setDeviceOpen(true);
              sendGetDevice(item.terminalId);
            }}
          />
        </AppModal>

        <AppModal
          open={deviceOpen}
          loading={deviceLoading}
          size={"large"}
          onClose={() => {
            setDeviceOpen(false);
          }}
        >
          <DeviceTable data={device} />
        </AppModal>

        {zoomLevel === zoomLevels.road ? roadMarkers : depotMarkers}
      </Map>
    </div>
  );
}
const mapSettings = {
  center: [57.778288, 79.126642],
  zoom: 4,
  minZoom: 4,
  maxZoom: 16,
  zoomControl: false,
};
const createMarkers = (points, callback) => {
  return points.map((point, index) => (
    <TerminalMarker
      key={index}
      callback={callback === undefined ? undefined : () => callback(point)}
      pointName={point.name}
      activeTerms={point.activeTerms}
      totalTerms={point.totalTerms}
      position={[point.lat, point.lon]}
    />
  ));
};
