import React from 'react'
import {Form, List} from "semantic-ui-react";
import {dateFormats} from "../../../Dictionaries";


export default function DriverInstructorForm({loading, data}) {
    return (
        <Form loading={loading}>
            <List>
                <TextLine header='ФИО' content={data.fio} loading={loading}/>
                <TextLine header='ТН ЕКАСУТР' content={data.tchmiAsutrId} loading={loading}/>
                <TextLine header='Наименование депо приписки' content={data.depotName} loading={loading}/>
                <TextLine header='Класс квалификации' content={data.tchmiClass} loading={loading}/>
                <TextLine header='Колонна' content={data.tchmiColnum} loading={loading}/>
                <TextLine
                    header='Дата назначения на должность'
                    loading={loading}
                    content={data.tchmiPostDate !== undefined ? data.tchmiPostDate.format(dateFormats.upToDay) : undefined}
                />
            </List>
        </Form>
    )
}

const TextLine = ({header, content, loading}) => {
    return (
        <List.Item>
            <List.Header>{header}</List.Header>
            {loading ? '...' : (
                content === undefined ? 'Нет данных' : content
            )}
        </List.Item>
    )
}