import React from 'react';
import {observer} from "mobx-react-lite";
import TextField from "../../../notUsed/PlanWorkMobX/components/TextField";
import {useStore} from "../StoreProvider";

const Comment = observer(({label, selKey}) => {
    const store = useStore()
    return (
        <TextField
            label={label}
            maxSymbolCount={1000}
            onChange={text => store.selected[selKey].comment = text}
            text={store.selected[selKey].comment}
        />
    );
})

export default Comment;