import React from "react";
import {useLocalStore} from "mobx-react-lite";
import createStore from "./createStore";
import storeContext from "./storeContext";

const StoreProvider = ({children}) => {
    const store = useLocalStore(() => createStore())
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export default StoreProvider