import React, {useEffect, useState} from 'react'
import {Table, Button, Popup, Segment, Icon} from 'semantic-ui-react'
import * as moment from "moment";
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import AppModal from "../../shared/AppModal";
import OperationalIncidentDetails from './OperationalIncidentDetails'
import OperationalIncidentResult from './OperationalIncidentResult'

const OperationalIncidents = ({incidents, fireOperational}) => {
    const [detailsOpen, setDetailsOpen] = useState(false)

    const [resultOpen, setResultOpen] = useState(false)
    const [incidentInfo, setIncidentInfo] = useState(false)

    useEffect(() => {
        if (!resultOpen) {
            fireOperational()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultOpen])

    const [sendGetDetails, detailsLoading, details] = useApiMethod(ekasut.incidents.getDetails, undefined, [])

    let processDetailsRequest = (item) => {
        sendGetDetails({'RD': item.routeRd, 'ID': item.routeId})
        setDetailsOpen(true)
    }

    return (
        <>
            {incidents.length > 0
                ? (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>Наименование депо</Table.HeaderCell>
                                <Table.HeaderCell>Сведения о бригаде</Table.HeaderCell>
                                <Table.HeaderCell>Время явки</Table.HeaderCell>
                                <Table.HeaderCell>Наименование инцидента</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {incidents.map((x, index) => (
                                <Table.Row key={index}>
                                    <Table.Cell>
                                        {!x.detailDescription
                                            ? (
                                                <Popup
                                                    content='Добавить расследование'
                                                    trigger={
                                                        <Button icon='add' onClick={() => {
                                                            setResultOpen(true)
                                                            setIncidentInfo({
                                                                depot: x.routeDepoName,
                                                                meta: x.description,
                                                                routeId: x.routeId,
                                                                routeRd: x.routeRd,
                                                                typeId: x.typeId
                                                            })
                                                        }}/>
                                                    }/>
                                            )
                                            : (
                                                <Popup
                                                    header={moment(x.detailCreateDate).format("YYYY.MM.DD HH:mm:ss")}
                                                    content={<span
                                                        dangerouslySetInnerHTML={{__html: x.detailDescription.replace(/\n/gi, '<br/>').replace(/ /gi, '&nbsp;')}}></span>}
                                                    trigger={<Button style={{width: '38px'}} onClick={() => {
                                                        setResultOpen(true)
                                                        setIncidentInfo({
                                                            depot: x.routeDepoName,
                                                            meta: x.description,
                                                            routeId: x.routeId,
                                                            routeRd: x.routeRd,
                                                            typeId: x.typeId,
                                                            detailDescription: x.detailDescription
                                                        })
                                                    }} animated>
                                                        <Button.Content hidden><Icon name='pencil'/></Button.Content>
                                                        <Button.Content style={{width: '10px', marginLeft: '-10px'}}
                                                                        visible><Icon name='info'/></Button.Content>
                                                    </Button>}/>
                                            )}
                                    </Table.Cell>
                                    <Table.Cell style={{cursor: 'pointer'}} onClick={() => {
                                        processDetailsRequest(x)
                                    }}>{x.routeDepoName}</Table.Cell>
                                    <Table.Cell style={{cursor: 'pointer'}} onClick={() => {
                                        processDetailsRequest(x)
                                    }}>
                                        <span
                                            dangerouslySetInnerHTML={{__html: x.description.replace(/\n/gi, '<br/>').replace(/ /gi, '&nbsp;')}}></span>
                                    </Table.Cell>
                                    <Table.Cell style={{cursor: 'pointer'}} onClick={() => {
                                        processDetailsRequest(x)
                                    }}>{x.sysData ? moment(x.sysData).format("DD.MM.YYYY HH:mm") : null}</Table.Cell>
                                    <Table.Cell style={{cursor: 'pointer'}} onClick={() => {
                                        processDetailsRequest(x)
                                    }}>{x.typeCaption}</Table.Cell>

                                </Table.Row>
                            ))}

                        </Table.Body>
                    </Table>
                )
                : 'Нет данных'
            }

            <AppModal
                loading={detailsLoading}
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
            >
                <Segment>
                    <OperationalIncidentDetails
                        details={details}
                    />
                </Segment>
            </AppModal>

            <AppModal
                open={resultOpen}
                onClose={() => setResultOpen(false)}
            >
                <Segment>
                    <OperationalIncidentResult
                        details={incidentInfo}
                        setResultOpen={setResultOpen}
                    />
                </Segment>
            </AppModal>
        </>
    )
}
export default OperationalIncidents