import React, {useState, useEffect} from 'react'
import {Map, ZoomControl} from "react-leaflet";
import {Form, Radio, Button, Icon} from 'semantic-ui-react'
import Control from "react-leaflet-control";
import './index.scss'
import EpRpLayout from './EpRpLayout/EpRpLayout';
import TechFailuresLayout from './TechFailureLayout/TechFailuresLayout';
import ReadyToWorkLayout from './ReadyToWorkLayout/ReadyToWorkLayout';
import NepLayout from './NepLayout/NepLayout';
import {useFleetStats} from "./FleetStats";
import {Route, useHistory} from "react-router-dom";
import LayerSelector, {layerNames} from "../../shared/LayerSelector";
import cn from 'classnames'
import appStyles from '../../css/App.module.scss'
import StaticControl from "../../shared/StaticControl";
import AnalyticalReferenceModal from "./AnalyticalReferenceModal";
import AppModal from "../../shared/AppModal";

const fleetStatsFilter = {
    ac: true,
    dc: true,
    ice: true
}
const layouts = {
    EP_RP: 'eprp',
    TECH_FAILURES: 'failures',
    READY_TO_WORK: 'ready',
    NEP: 'nep',
}

const mapSettings = {
    INIT_POS: [57.778288, 79.126642],
    INIT_ZOOM: 4,
    MIN_ZOOM: 4,
    MAX_ZOOM: 16,
}


const urls = {
    EPRP: 'eprp',
    NEP: 'nep',
    FAILURES: 'failures',
    READY: 'ready'
}

const getLayout = (pathName) => {
    const arr = pathName.split('/')

    if (arr[arr.length - 1] === '') {
        arr.pop()
    }

    if (arr.length !== 3) {
        return null
    }
    const name = arr[2]
    switch (name) {
        case urls.EPRP: {
            return layouts.EP_RP
        }
        case urls.READY: {
            return layouts.READY_TO_WORK
        }
        case urls.FAILURES: {
            return layouts.TECH_FAILURES
        }
        case urls.NEP: {
            return layouts.NEP
        }
        default: {
            return null
        }
    }
}

export default function RatingsWorkLocomotives({hideSidebars, match, location, rootPath}) {
    const [layout, setLayout] = useState(getLayout(location.pathname))
    const [mapRef, setMapRef] = useState(null)
    const [zoom, setZoom] = useState(mapSettings.INIT_ZOOM)
    const [stats] = useFleetStats(fleetStatsFilter)
    const [loading, setLoading] = useState(false)
    const [analyticalReferenceModal, setAnalyticalReferenceModal] = useState({open: false})

    const history = useHistory()

    const handleRadioGroupChange = (e, {value}) => {
        setLayout(value)
        history.push(rootPath + "/" + value)
        mapRef.leafletElement.setView(mapSettings.INIT_POS, mapSettings.INIT_ZOOM)
    }

    useEffect(function subscribeOnZoomEnd() {
        if (mapRef === null) {
            return
        }
        mapRef.leafletElement.on('zoomend', () => {
            setZoom(mapRef.leafletElement.getZoom())
        })
        return () => {
            mapRef.leafletElement.off('zoomend')
        }
    }, [mapRef])

    const resetMap = () => {
        mapRef.leafletElement.setView(mapSettings.INIT_POS, mapSettings.INIT_ZOOM)
    }

    return (
        // <Sidebar.Pusher>
        <div onClick={hideSidebars}>
            <Map
                // Save reference to the map
                ref={(ref) => {
                    // Save reference only once. Library will change it over time with null,
                    // but will back to old reference
                    if (ref !== null && mapRef === null) {
                        setMapRef(ref)
                    }
                }}
                className={cn('polygon-ratings', appStyles.map)}
                minZoom={mapSettings.MIN_ZOOM}
                maxZoom={mapSettings.MAX_ZOOM}
                center={mapSettings.INIT_POS}
                zoom={mapSettings.INIT_ZOOM}
                zoomControl={false}
            >
                <Control position='topleft' className="pr-main-layout-control">
                    <Form>
                        <Form.Field>
                            <Radio
                                label='Выполнение ЭП/РП'
                                name='radioGroup'
                                value={layouts.EP_RP}
                                checked={layout === layouts.EP_RP}
                                onChange={handleRadioGroupChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='НЭП'
                                name='radioGroup'
                                value={layouts.NEP}
                                checked={layout === layouts.NEP}
                                onChange={handleRadioGroupChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label='Отказы ТС' // Технические средства
                                name='radioGroup'
                                value={layouts.TECH_FAILURES}
                                checked={layout === layouts.TECH_FAILURES}
                                onChange={handleRadioGroupChange}
                            />
                        </Form.Field>
                        {/*<Form.Field>*/}
                        {/*    <Radio*/}
                        {/*        label='Выполнение КГЭ' // Готовность к эксплуатации*/}
                        {/*        name='radioGroup'*/}
                        {/*        value={layouts.READY_TO_WORK}*/}
                        {/*        checked={layout === layouts.READY_TO_WORK}*/}
                        {/*        onChange={handleRadioGroupChange}*/}
                        {/*    />*/}
                        {/*</Form.Field>*/}
                    </Form>
                </Control>

                <ZoomControl position="bottomleft"/>

                <LayerSelector
                    position="bottomleft"
                    checkedLayerDef={layerNames.rzd}
                />

                <StaticControl position="topleft">
                    <Button
                        basic
                        className="analytical-reference-button"
                        onClick={() => setAnalyticalReferenceModal({open: true})}
                    >
                        <Icon name="file alternate outline" size="large"/>
                    </Button>

                </StaticControl>

                {/*Prevent from warning: You tried to redirect to the same route you're currently on*/}
                {/*Instead for navigation you can use react-router new feature: useHistory*/}
                {/*{prevLayout !== layout && (<>
                    {layout === layouts.EP_RP && <Redirect to={`${match.path}/${urls.EPRP}`}/>}
                    {layout === layouts.TECH_FAILURES && <Redirect to={`${match.path}/${urls.FAILURES}`}/>}
                    {layout === layouts.READY_TO_WORK && <Redirect to={`${match.path}/${urls.READY}`}/>}
                    {layout === layouts.NEP && <Redirect to={`${match.path}/${urls.NEP}`}/>}
                </>)}*/}

                <Route exact path={`${rootPath}/${urls.EPRP}`} render={() => (
                    <EpRpLayout
                        zoom={zoom} resetMap={resetMap} stats={stats}
                        onLoad={() => setLoading(true)}
                        onLoaded={() => setLoading(false)}
                    />
                )}/>
                <Route exact path={`${rootPath}/${urls.FAILURES}`} render={() => (
                    <TechFailuresLayout
                        zoom={zoom} resetMap={resetMap} stats={stats}
                        onLoad={() => setLoading(true)}
                        onLoaded={() => setLoading(false)}
                    />
                )}/>
                <Route exact path={`${rootPath}/${urls.READY}`} render={() => (
                    <ReadyToWorkLayout
                        zoom={zoom} resetMap={resetMap} stats={stats}
                        onLoad={() => setLoading(true)}
                        onLoaded={() => setLoading(false)}
                    />
                )}/> 
                <Route exact path={`${rootPath}/${urls.NEP}`} render={() => (
                    <NepLayout
                        zoom={zoom}
                        resetMap={resetMap}
                        stats={stats}
                        onLoad={() => setLoading(true)}
                        onLoaded={() => setLoading(false)}
                    />
                )}/>
            </Map>
            <AnalyticalReferenceModal
                open={analyticalReferenceModal.open}
                onClose={() => setAnalyticalReferenceModal({open: false})}
            />
            <AppModal
                open={loading}
                loading={true}
                loadingMessage="Загрузка"
            />
        </div>
    )
}