import React, {useRef} from 'react'

/** Select file from user. Try to use FileAttachButton first
 * @return HiddenInput - should be rendered<br/>
 *         onClick - get user file event handler
 * */
export default function useFileInput(onChange = () => null) {
    const ref = useRef(null)
    const fileSelectHandler = useRef(() => null)

    const HiddenInput = ({multiple = true}) => (
        <input
            type='file'
            ref={ref}
            multiple={multiple}
            style={{display: 'none'}}
            onChange={(e, e2, e3) => {
                onChange(e)
                fileSelectHandler.current(e)
            }}
        />
    )

    const onClick = (onFileSelect) => {
        ref.current.click()
        if (onFileSelect) {
            fileSelectHandler.current = onFileSelect
        }
    }
    return [HiddenInput, onClick]
}