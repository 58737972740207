import {padStartZero} from "../../helpers/utils";

export const formatRotation = (rotation) => {
    if (typeof rotation === 'string') {
        // split string at '.' or ':'
        let str
        const arr = rotation.split(/[.:]/)
        if (arr.length === 4) {
            const days = parseInt(arr[0])
            const hours = days*24 + parseInt(arr[1])
            arr.shift()
            arr[0] = hours.toString()
            str = arr.join(':')
        } else {
            str = rotation
        }

        return str.substring(0, str.length - 3)
    } else {
        return null
    }
}

export const isDeclineWithinPercent = (percent, plan, fact) => {
    const allowDecline = plan * (percent / 100)

    return fact >= (plan - allowDecline) && (fact <= (plan + allowDecline))
}

export const convertRotationToSeconds = (str) => {
    const arr = str.split(/[.:]/).map(s => parseInt(s))

    let seconds
    if (arr.length === 2) {
        seconds = arr[1]*60 + arr[0]*60*60
    } else if (arr.length === 3) {
        seconds = arr[2] + arr[1]*60 + arr[0]*60*60
    } else if (arr.length === 4) {
        seconds = arr[3] + arr[2]*60 + arr[1]*60*60 + arr[0]*24*60*60
    }
    return seconds
}

export const convertSecondsToRotation = (sec) => {
    const hours = Math.floor(sec/60/60)
    const minutes = Math.floor((sec - hours*60*60)/60 )
    const seconds = sec - hours*60*60 - minutes*60
    const arr = [
        padStartZero(hours, 2),
        padStartZero(minutes, 2),
        padStartZero(seconds, 2)
    ]
    return arr.join(':')
}