import React, {useEffect, useState} from 'react';
import Tree, { TreeNode } from 'rc-tree'
import './RailwayTree.scss'



function RailwayTree({tree, onSelect, className}) {
    const [treeNodes, setTreeNodes] = useState(null)
    const [expandedKeys, setExpandedKeys] = useState([])
    const [selectedKeys, setSelectedKeys] = useState(null)
    useEffect(() => {
        if (!tree) {
            return
        }
        setTreeNodes(createTreeNodes(tree))
        // root - rzd
        const listOnlyRoot = [tree[0].id.toString()]
        setExpandedKeys(listOnlyRoot)
    }, [tree])

    const handleOnSelect = (selected, event) => {
        // if user unselect
        if (selected.length === 0) {
            return
        }
        const nodeProps = event.node.props
        setSelectedKeys(selected)
        onSelect(nodeProps.id, nodeProps.level, nodeProps.name)
    }

    const handleOnExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys)
    }

    const switcherIcon = (obj) => {
        if (obj.isLeaf) {
            return null
        }

        return getSvgIcon(trianglePath,
            { cursor: 'pointer', backgroundColor: 'white' },
            { transform: `rotate(${obj.expanded ? 180 : 90}deg)` });
    }

    return (
        <Tree
            className={"lcm-railway-tree " + className}
            onExpand={handleOnExpand}
            expandedKeys={expandedKeys}
            showIcon={false}
            onSelect={handleOnSelect}
            selectedKeys={selectedKeys}
            switcherIcon={switcherIcon}
        >
            {treeNodes}
        </Tree>
    )
}

export default RailwayTree;

const createTreeNodes = data => {
    return data.map((item) => {
        return (
            <TreeNode
                key={item.id}
                title={item.name}
                // Save in props for easier access on select
                id={item.id}
                name={item.name}
                level={item.level}
            >
                {item.children ? createTreeNodes(item.children) : null}
            </TreeNode>
        );
    });
};

const createTrianglePath = (size) => {
    const width = 100
    const height = 100

    const p1 = {
        x: width/2,
        y: width/2 - size.height
    }
    const p2 = {
        x: width/2 + size.width,
        y: height/2 + size.height
    }
    const p3 = {
        x: width/2 - size.width,
        y: height/2 + size.height
    }
    return `M ${p1.x},${p1.y} ${p2.x},${p2.y} ${p3.x},${p3.y} z`
}

const trianglePath = createTrianglePath({height: 25, width: 30})

const getSvgIcon = (path, iStyle = {}, style = {}) => {
    return (
        <i style={iStyle}>
            <svg
                viewBox="0 0 100 100"
                width="1em"
                height="1em"
                fill="#777"
                style={{ verticalAlign: '-.125em', ...style }}
            >
                <path d={path} />
            </svg>
        </i>
    );
}