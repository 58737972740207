import React, {useState} from 'react';
import style from './Labels.module.scss';
import AppModal from "../../../../../shared/AppModal";
import {Popup, Segment} from "semantic-ui-react";
import {Button, Input} from "antd";
import {LabelsList} from "./LabelsList/LabelsList";

export const Labels = ({setLabelsOpen, labelsOpen, wSData, setWSData}) => {


    return (
        <AppModal
            open={labelsOpen}
            onClose={() => {
                setWSData(false)
                setLabelsOpen(false)
            }}
            //loading={personInfoLoading}
            className={style.modalWSMore}
        >
            <Segment style={{borderRadius: '14px', padding: 0, height: '100%'}}>
                <div className={style.headerWS}>
                    <h3>Редактирование тэгов</h3>
                </div>
                <div className={style.modalGrid}>
                    <LabelsList wSData={wSData}/>
                </div>
            </Segment>
        </AppModal>
    );
};