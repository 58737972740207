import React, { useEffect, useMemo } from "react";
import { Button, Modal } from "semantic-ui-react";
import Widget from "../../../shared/Widget";
import "./TemplateModal.scss";
import InputField from "../../../shared/fields/InputField";
import { observer, useLocalStore } from "mobx-react-lite";

const TemplateModal = observer(({ open, onSave, onClose, templateName }) => {
  const ls = useLocalStore(() => ({
    templateName: "",
  }));

  useEffect(() => {
    ls.templateName = templateName;
  }, [templateName]);

  return (
    <Modal
      open={open}
      size="mini"
      className="app polygon-scheme template-modal"
    >
      <Widget
        header="Сохранить фильтр"
        onClose={onClose}
        footer={
          <div className="buttons">
            <Button
              content="Отмена"
              className="button-link"
              onClick={onClose}
            />
            <Button
              content="Сохранить"
              className="button-primary"
              onClick={() => onSave(ls.templateName)}
            />
          </div>
        }
      >
        <InputField
          fluid
          placeholder="Название шаблона"
          onChange={(e, { value }) => (ls.templateName = value)}
          value={ls.templateName}
        />
      </Widget>
    </Modal>
  );
});

export default TemplateModal;
