import React, {useEffect, useLayoutEffect, useState} from 'react';
import style from './StatusAndPriorityChart.module.scss';
import {Pie} from "@ant-design/charts";
import {listLoader} from '../AnalyticsList/AnalyticsList'
import {analyticsState} from "../AnalyticsState";

export const StatusAndPriorityChart = ({analyticsData}) => {

    const {statusTasks} = analyticsData

    var config = {
        appendPadding: 0,
        data: statusTasks,
        angleField: 'tasksCount',
        colorField: 'colorHex',
        color: ({colorHex}) => {
            return colorHex;
        },
        radius: 0.6,
        legend: false,
        label: {
            type: 'spider',
            labelHeight: 28,
            formatter: (datum) => {
                return `${datum.name}`;
            },
        },

        interactions: [],
        tooltip: {
            formatter: (datum) => {
                return {
                    name: statusTasks.filter(e => e.colorHex === datum.colorHex)[0].name,
                    value: datum.tasksCount + ` зад.`
                };
            },
        }

    };

    return (
        <>
            {statusTasks.length ? <div className={style.sAndPChartAria}>
                <Pie  {...config} onEvent={(plot, e) => {
                    if (e.type === "plot:click" && e.data) {
                        if (document.querySelector('#listTopPosition')) {
                            document.querySelector('#listTopPosition').scrollIntoView()
                        }
                        listLoader(e.data.data.id, 'statusId', e.data.data, null, false, analyticsState.categoryId)
                    }
                }}/>
            </div> : <div className={style.noData}>Нет задач</div>}
        </>)
}