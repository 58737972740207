import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Header, Table} from "semantic-ui-react";
import {filterData, sortReducer} from "../TableFirstCard/TableFirstCard";
import styles from "../TableFirstCard/TableFirstCard.module.scss";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {replaceNullStateWithNoDataString} from "../TableFourthCard/TableFourthCard";

const TableThirdCardWaiting = ({data: tableData}) => {
    const [state, dispatch] = useReducer(sortReducer, {
        column: null,
        data: replaceNullStateWithNoDataString(tableData),
        direction: null,
    })
    const { column, data, direction } = state
    const [filteredData, setFilteredData] = useState(data);

    const filterData1 = useMemo(() => filterData(data, "roadName"), []);
    const filterData2 = useMemo(() => filterData(data, "depoName"), []);
    const filterData3 = useMemo(() => filterData(data, "serName"), []);
    const filterData4 = useMemo(() => filterData(data, "nomSec"), []);
    const filterData5 = useMemo(() => filterData(data, "txtWaitStay"), []);
    const filterData6 = useMemo(() => filterData(data, "dateWaitR"), []);
    const filterData7 = useMemo(() => filterData(data, "dateRepairBegin"), []);
    const filterData8 = useMemo(() => filterData(data, "serTxtWaitStay"), []);
    const filterData9 = useMemo(() => filterData(data, "nameRepair"), []);
    const filterData10 = useMemo(() => filterData(data, "firmName"), []);

    const [selectedRowsInFilters, setSelectedRowsInFilters] = useState({
        roadName: filterData1,
        depoName: filterData2,
        serName: filterData3,
        nomSec: filterData4,
        txtWaitStay: filterData5,
        dateWaitR: filterData6,
        dateRepairBegin: filterData7,
        serTxtWaitStay: filterData8,
        nameRepair: filterData9,
        firmName: filterData10,
    });

    useEffect(() => {
        // if (filteredData !== data) {
        //     setFilteredData(data);
        // }
        const newFilteredData = data
            .filter(item => selectedRowsInFilters.roadName.includes(item.roadName))
            .filter(item => selectedRowsInFilters.depoName.includes(item.depoName))
            .filter(item => selectedRowsInFilters.serName.includes(item.serName))
            .filter(item => selectedRowsInFilters.nomSec.includes(item.nomSec))
            .filter(item => selectedRowsInFilters.txtWaitStay.includes(item.txtWaitStay))
            .filter(item => selectedRowsInFilters.dateWaitR.includes(item.dateWaitR))
            .filter(item => selectedRowsInFilters.dateRepairBegin.includes(item.dateRepairBegin))
            .filter(item => selectedRowsInFilters.serTxtWaitStay.includes(item.serTxtWaitStay))
            .filter(item => selectedRowsInFilters.nameRepair.includes(item.nameRepair))
            .filter(item => selectedRowsInFilters.firmName.includes(item.firmName))
        setFilteredData(newFilteredData);
    }, [data, selectedRowsInFilters]);

    const changeSelectedRows = (colName, newData) => {
        setSelectedRowsInFilters(prevState => ({
            ...prevState,
            [colName]: newData,
        }));
    }

    return (
        <div>
            <Header as='h2'>Простой в ожидании в период проведения КО</Header>
            <div>
                <Header as='h3'>Фильтрация</Header>
                <div style={{display: "flex", gap: "5px"}} className={styles.filtersWrapper}>
                    <ColumnFilter colName='roadName' title="Дорога" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData1}/>
                    <ColumnFilter colName='depoName' title="Депо" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData2}/>
                    <ColumnFilter colName='serName' title="Серия" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData3}/>
                    <ColumnFilter colName='nomSec' title="Номер" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData4}/>
                    <ColumnFilter colName='txtWaitStay' title="Время простоя в ОЖ.ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData5}/>
                    <ColumnFilter colName='dateWaitR' title="Дата начала ОЖ.ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData6}/>
                    <ColumnFilter colName='dateRepairBegin' title="Дата начала ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData7}/>
                    <ColumnFilter colName='serTxtWaitStay' title="Общий простой по серии в ОЖ.ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData8}/>
                    <ColumnFilter colName='nameRepair' title="Состояние после ОЖ.ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData9}/>
                    <ColumnFilter colName='firmName' title="Предприятие ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData10}/>
                </div>
            </div>

            <Table celled>
                <Table.Header className={styles.header}>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell
                            sorted={column === 'roadName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'roadName' })}
                        >Дорога приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'depoName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'depoName' })}
                        >Депо приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'serName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serName' })}
                        >Серия</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'nomSec' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nomSec' })}
                        >Номер</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'txtWaitStay' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'txtWaitStay' })}
                        >Время простоя в ОЖ.ТО/ТР</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'dateWaitR' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateWaitR' })}
                        >Дата начала ОЖ.ТО/ТР</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'dateRepairBegin' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateRepairBegin' })}
                        >Дата начала ТО/ТР</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'serTxtWaitStay' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serTxtWaitStay' })}
                        >Общий простой по серии в ОЖ.ТО/ТР</Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === 'nameRepair' ? direction : null}
                          onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nameRepair' })}
                        >Состояние после ОЖ.ТО/ТР</Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === 'firmName' ? direction : null}
                          onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmName' })}
                        >Предприятие ремонта</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {filteredData?.map((item, index) => (
                        <Table.Row
                            key={index}
                            textAlign={"center"}
                            onClick={() => {

                            }}
                        >
                            <Table.Cell>{item.roadName}</Table.Cell>
                            <Table.Cell>{item.depoName}</Table.Cell>
                            <Table.Cell>{item.serName}</Table.Cell>
                            <Table.Cell>{item.nomSec}</Table.Cell>
                            <Table.Cell>{item.txtWaitStay === "-" ? "0м." : item.txtWaitStay}</Table.Cell>
                            <Table.Cell>{item.dateWaitR}</Table.Cell>
                            <Table.Cell>{item.dateRepairBegin}</Table.Cell>
                            <Table.Cell>{item.serTxtWaitStay === "-" ? "0м." : item.serTxtWaitStay}</Table.Cell>
                            <Table.Cell>{item.nameRepair}</Table.Cell>
                            <Table.Cell>{item.firmName}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TableThirdCardWaiting;