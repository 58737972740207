import {runInAction} from "mobx";

export const STATUS = {
    idle: "idle",
    loading: "loading",
    success: "success",
    error: "error"
}

/** @deprecated Don't recommend using it. Try to call api methods directly, or rewrite and test this solution */
export default function (
    api,
    options = {},
) {
    const {
        onSuccess = () => null,
        onError = () => null,
        parser = ({data}) => data,
        defValue
    } = options

    return {
        status: STATUS.idle,
        error: null,
        loaded: false,
        loading: false,
        fetch(...args) {
            runInAction(() => {
                this.status = STATUS.loading
                this.loaded = false
                this.loading = true
            })
            return api(...args)
                .then((res) => {
                    const value = res ? parser(res) : defValue
                    runInAction(() => {
                        this.status = STATUS.success
                        this.loaded = true
                        this.loading = false
                        onSuccess(value)
                    })
                    return value
                })
                .catch(error => {
                    runInAction(() => {
                        this.loaded = true
                        this.loading = false
                        this.status = STATUS.error
                        this.error = error
                        onError(error)
                    })
                    return Promise.reject(error)
                })
        },
        /** @deprecated Doesn't work properly */
        fetchIfNeed(...args) {
            if ((this.status !== STATUS.success) && (this.status !== STATUS.loading)) {
                return this.fetch(...args)
            }
            return Promise.resolve()
        }
    }
}