import React, {useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import ekasut from "../../api/ekasut";
import {usePrompt} from "../../globalStore/useGlobalStore";
import catchNetworkErrors from "../../api/catchNetworkErrors";

const UrgentlySubmiteModal = ({
                                  openUrgentlySubmit,
                                  setOpenUrgentlySubmit,
                                  purchase,
                                  closeHandler,
                                  updateAvailablePurchases
                              }) => {

    const prompt = usePrompt()

    const urgSubmit = () => {
        prompt.show("Отзыв заявки...", "", {loading: true})
        ekasut.purchases.postUrgentlySubmit({purchaseId: purchase.purchaseId})
            .then(() => {
                closeHandler()
                prompt.close()
                updateAvailablePurchases()
            })
            .catch((error) => {
                prompt.close()
                return catchNetworkErrors(error)
            })
    }

    return (
        <Modal
            size={'mini'}
            open={openUrgentlySubmit}
            onClose={() => {
                setOpenUrgentlySubmit(false)
            }}
        >
            <Modal.Header>Отзыв заявки</Modal.Header>
            <Modal.Content>
                <p>Вы хотите отозвать заявку?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    setOpenUrgentlySubmit(false)
                }}>
                    Нет
                </Button>
                <Button primary onClick={() => {
                    urgSubmit()
                    setOpenUrgentlySubmit(false)
                }}>
                    Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default UrgentlySubmiteModal;