const parseHash = (hash) => {
    hash = hash[0] === "#" ? hash.substr(1) : hash

    return hash.split('&').reduce((res, item) => {
        const parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
    }, {});
}

export default parseHash