import React, {useEffect, useState} from "react";
import ekasut from "../../../../../../../api/ekasut";
import {normalizeData} from "./normalizeAntd";

const useOperationsTableData = ({objectId}) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    getEp()
  }, [objectId])



  const getEp = async () => {
    let res;
    try {
      setLoading(true)
      res = await ekasut.ekasutData.getEp(objectId)
      setData(normalizeData(res))
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  return {
    loading,
    data,
    error
  };
};

export default useOperationsTableData;