import moment from "moment";
import React from "react";

export default function AppointmentTooltip(model) {
  const { targetedAppointmentData } = model.data;

  return (
    <div className="movie-tooltip">
      <div className="movie-info" style={{ display: "flex", alignItems: "center" }}>
          <div 
            style={{ 
              width: "100px", 
              height: "38px", 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center",
              background: "#709ac2", 
              color: "#fff", 
              fontWeight: 700,
              marginRight: "10px"
            }}
          >
            {`${targetedAppointmentData.nameRepair}`}
          </div>
          <div>
            <div>{`${targetedAppointmentData.text} `} {` ${targetedAppointmentData.nomSec}`}</div>
            <div>{`${moment(targetedAppointmentData.startDate).format("YYYY-DD-MM")}`}</div>
          </div>
      </div>
    </div>
  );
}
