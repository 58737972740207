import React, {useEffect, useState} from 'react';
import style from "../ControlBar.module.scss";
import {List, Select, Tag} from "antd";
import ekasut from "../../../../../../api/ekasut";
import {Icon} from "semantic-ui-react";

export const Labels = ({item, taskData, setTaskData}) => {

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [labelsList, setLabelsList] = useState([])
    const [optionList, setOptionList] = useState([])
    const [selectedItems, setSelectedItems] = useState([])

    useEffect(() => {
        getLabels()
    }, [])

    const tagRender = (props) => {
        const {label, value, closable, onClose} = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };

        let labelInfo = labelsList.find((e => e.value === value))

        return (
            <Tag
                color={labelInfo?.color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{marginRight: 3}}
            >
                {labelInfo.label}
            </Tag>
        );
    }

    const initDefaultsLabels = (data, list) => {
        let selectedLabels = data.map((el) => (el.labelId))
        setSelectedItems(selectedLabels)
        setOptionList(list.filter(el => !selectedLabels.includes(el.value)))
    }
    const selectedChange = selected => {
        setSelectedItems(selected)
        setOptionList(labelsList.filter(el => !selected.includes(el.value)))
    };

    const getLabels = () => {
        ekasut.taskboard.getLabelsByWG(taskData.workGroupId)
            .then(({data}) => {
                let labels = data.map((el) => ({
                    value: el.labelId,
                    label: el.labelName,
                    color: el.labelColorHex,
                }))
                setLabelsList(labels)
                setOptionList(labels)
                initDefaultsLabels(taskData.labels, labels)
            })
    }

    const updateLabels = () => {
        setLoading(true)
        ekasut.taskboard.updateTaskLabels(taskData.workGroupId, taskData.taskNumber, selectedItems)
            .then(({data}) => {
                setLoading(false)
                setEditMode(false)
                setTaskData(data)
            })
    }

    return (
        <>
            <List.Item.Meta
                title={<div className={style.rowTitle}><span>{item.title}</span>
                    {taskData.workGroupUserRoleId !== 3 ? <>
                        {editMode ?
                            <div className={style.actionBtn}><Icon name={'checkmark'} color={'green'} onClick={() => {
                                updateLabels()
                            }}/> <Icon name={'delete'} color={'red'} onClick={() => {
                                initDefaultsLabels(taskData.labels, labelsList)
                                setEditMode(false)
                            }
                            }/></div> : <span
                                onClick={() => {
                                    setEditMode(true)
                                }}
                                className={style.editBtn}>ред.</span>}
                    </> : null}
                </div>}
                description={
                    editMode ?
                        <Select
                            notFoundContent={'Нет данных'}
                            loading={loading}
                            mode="multiple"
                            showArrow
                            tagRender={tagRender}
                            value={selectedItems}
                            onChange={selectedChange}
                            style={{width: '100%'}}
                        >
                            {optionList.map((item, i) => (
                                <Select.Option key={item.value} value={item.value}>
                                    <span style={{color: `${item.color}`}}>{item.label}</span>
                                </Select.Option>
                            ))}
                        </Select>
                        : <>
                            {item.desc.length ? <div className={style.barArrDesc}>
                                {item.desc.map((el, i) => (
                                    <div key={i}
                                         style={{backgroundColor: `${el.labelColorHex}`}}
                                         className={style.labelsDiv}>{el.labelName}
                                    </div>))}
                            </div> : <span>Нет</span>}
                        </>
                }
            />
        </>
    )
}