import React from 'react';
import style from './HeaderPersonDetails.module.scss';
import {Tag} from "antd";

export const HeaderPersonDetails = ({info,additional}) => {
    return (
        <div className={style.header}>
            <div className={style.avatar}>
                <div>No Avatar</div>
            </div>
            <div className={style.nameInfo}>
                <div className={style.fioWrap}>
                    <h2>{info?.fio}</h2>
                    <span>({info?.kodFirm})</span>
                    <div className={style.tags}>
                        {/*<Tag color="#FFC107">БД</Tag>*/}
                        {/*<Tag color="#4BAF50">ОТ</Tag>*/}
                    </div>
                </div>
                <p>{additional?.postStr}</p>
                <span>-</span>
            </div>
            <div className={style.depotInfo}>
                <h2> </h2>
                <p>{additional.depoName}</p>
                <span>-</span>
            </div>
        </div>
    )
}