import React, {useState} from 'react';
import {Skeleton, Space} from 'antd';
import Card from '@material-ui/core/Card';
import style from './SkeletonBoardItem.module.scss';
import {CardHeader} from "@material-ui/core";
import CardContent from '@material-ui/core/CardContent';

export const SkeletonBoardItem = ({fieldId}) => {

    return (
        <React.Fragment key={`${fieldId}-skel`}>
            <Card
                className={style.cardTaskStatic}>
                <CardHeader
                    className={style.taskHeader}
                    title={<Skeleton.Button style={{width: '100px', height: '14px'}} shape={'round'} active
                                            size={'small'}/>}
                />

                <CardContent className={style.taskContent}>
                    <div className={style.descAndAvatar}>
                        <Skeleton.Button active style={{width: '140px', height: '12px'}} shape={'round'}
                                         size={'small'}/>
                        <Skeleton.Avatar active size={'small'} shape={'circle'}/>
                    </div>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}