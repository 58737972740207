export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Дата',
    dataIndex: 'eventDate',
    key: 'eventDate',
  },
  {
    title: 'Серия',
    dataIndex: 'locSerName',
    key: 'locSerName',
  },
  {
    title: 'Номер',
    dataIndex: 'locNum',
    key: 'locNum',
  },
  {
    title: 'Текст',
    dataIndex: 'eventName',
    key: 'eventName',
  }
];