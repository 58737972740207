import React from 'react';
import LocoDetailsHeaderItem from "./LocoDetailsHeaderItem";
import style from './LocoDetailsHeaderList.module.scss'

const LocoDetailsHeaderList = ({fullLoco, changeActiveItem, trainInfo}) => {


  return (
    <div className={style.container}>
      {fullLoco?.map((item, i) =>
        <LocoDetailsHeaderItem
          info={item}
          key={i}
          index={i}
          activeItem={trainInfo}
          changeActiveItem={changeActiveItem}
      />)}
    </div>
  );
};

export default LocoDetailsHeaderList;