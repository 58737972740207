import React, { PureComponent, Component} from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import { Segment, List, Form, Header, Dimmer, Loader } from 'semantic-ui-react'
import { Icon, Menu, Button, Input, Popup, Modal } from 'semantic-ui-react'


class ControlServices extends PureComponent {
    state = {
        errorOccured: false,
        isLoading: false,
        params: {},
        modules: []
    }

    componentDidMount() {
        let me = this;

        me.setState({isLoading: true})

        this.loadModules();
    }

    loadModules () {
        let me = this;

        axios.get(`${window.dbsyncUrl}/api/modules`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                }

                me.setState({modules: response.data});
            }
        )
            .catch(function (err) {
                if (err.response?.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    render() {
        const { modules } = this.state

        return (
            <Segment>

                {modules.map((item, index) => (<Segment key={index} className='management'>

                    <Menu text className='roles-management'>
                        <Menu.Item header>{item.description}</Menu.Item>
                    </Menu>

                    <ServicesManagementModuleConnections loadModules={this.loadModules} module={item} />


                </Segment>))}
            </Segment>
        )
    }
}

class ServicesManagementModuleConnections extends Component {
    state = {
        errorOccured: false,
        isLoading: false,
        params: {},
        connections: []
    }

    componentDidMount() {
        let me = this;
        const { module } = me.props

        me.setState({isLoading: true})

        this.loadConnections(module.code);
    }

    loadConnections (id) {
        let me = this;

        axios.get(`${window.dbsyncUrl}/api/Connections?module=${id}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    me.setState({connections: response.data, isLoading: false});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    handleClose = () => this.setState({connectionToEdit: null, showVerification: false})

    inputSetter(parameterName, value) {
        const {params} = this.state

        if (value) {
            params[parameterName] = value;
        } else {
            delete params[parameterName];
        }

        this.setState({params: params});
    }

    editConnection (connection) {
        const me = this;
        const {params} = this.state;
        const { module } = me.props

        axios.post(`${window.dbsyncUrl}/api/Connections`, {...connection, connectionString: params.newConnString})
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    } else {
                        me.loadConnections(module.code);
                        me.handleClose();
                    }


                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorInfo: err.response});
            });
    }

    checkConnection(connection) {
        const me = this;
        const { module } = me.props

        me.setState({isLoading: true})

        axios.get(`${window.dbsyncUrl}/api/Connections/verification/${module.code}?connectionString=${connection.connectionString}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    } else {
                        me.setState({isLoading: false, verification: response.data, showVerification: true})
                    }


                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorInfo: err.response});
            });
    }

    closeErrorModal = () => this.setState({ errorOccured: false })

    render() {
        const { hideSidebars } = this.props
        const { params, connections, connectionToEdit, errorInfo, isLoading, verification, showVerification } = this.state

        return (
            <div onClick={hideSidebars}>
                <List selection divided >
                    {connections.map((connection, i) =>
                                <List.Item key={i} >

                                    <List.Content floated='left'>
                                        <List.Header as='span'>{connection.description}</List.Header>
                                        {connection.name}
                                    </List.Content>

                                    <List.Content floated='right'>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <Input style={{width: 950}} value={connection.connectionString.replace(/ub-spd-tunnel-srv.transinfocom.ru/, '*********************************')} />
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <ConnectionStatus connection={connection.code}/>
                                        <span>&nbsp;&nbsp;&nbsp;</span>
                                        <Button.Group>
                                            <Popup
                                                trigger={<Button onClick={e => this.setState({connectionToEdit: connection, params: {newConnString: connection.connectionString}})}  circular icon='pencil' />}
                                                content='Изменить'
                                            />
                                            <Popup
                                                trigger={<Button onClick={() => this.checkConnection(connection)}  circular icon='refresh' />}
                                                content='Проверить'
                                            />
                                        </Button.Group>

                                    </List.Content>

                                </List.Item>
                            )}

                    { connectionToEdit ? (
                        <Modal closeIcon size='large' open={connectionToEdit} onClose={this.handleClose}>
                            <Modal.Header>Изменение строки соединения</Modal.Header>
                            <Modal.Content scrolling>
                                <Modal.Description>
                                    <Form>
                                        <Form.Field>
                                            <Input placeholder='Название' value={params.newConnString}
                                                   onChange={(e, {value}) => {
                                                       this.inputSetter('newConnString', value)
                                                   }}/>
                                        </Form.Field>

                                       <Button type='submit' onClick={() => {this.editConnection(connectionToEdit)}}>Подтвердить</Button>
                                    </Form>
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    ) : null}

                    { showVerification ? (
                        <Modal closeIcon basic size='small' open={showVerification} onClose={this.handleClose}>
                            <Modal.Header>
                                {verification ? "Успешно" : "Неуспешно"}
                            </Modal.Header>
                        </Modal>
                    ) : null}

                    { errorInfo
                        ? (<Modal open={this.state.errorOccured} closeOnDimmerClick={false} basic size='small'>
                            <Header icon='warning sign' content={`Ошибка ${errorInfo.status}`} />
                            <Modal.Content>{(errorInfo && errorInfo.data) ? errorInfo.data.Message : null}</Modal.Content>
                            <Modal.Actions>

                                <Button onClick={this.closeErrorModal} inverted>
                                    <Icon name='checkmark' /> Закрыть
                                </Button>
                            </Modal.Actions>
                        </Modal>)
                        : null }

                    {isLoading
                        ? (
                            <Dimmer active inverted>
                                <Loader inverted size='massive' content='Загрузка'/>
                            </Dimmer>
                        )
                        : null}

                </List>
            </div>
        )
    }
}

class ConnectionStatus extends Component {
    state = {
        errorOccured: false,
        isLoading: false,
        params: {},
        status: []
    }

    componentDidMount() {
        let me = this;
        const { connection } = me.props

        this.loadStatus(connection);
    }

    loadStatus (id) {
        let me = this;

        me.setState({isLoading: true})

        axios.get(`${window.dbsyncUrl}/api/Connections/status/${id}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    me.setState({status: response.data, isLoading: false});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }



    render() {
        const { status } = this.state

        return (
            <Icon circular name={status.state ? 'check' : 'cancel'} color={status.state ? 'green' : 'red'}/>
        )
    }
}

export default ControlServices;