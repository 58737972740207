import React, {useState} from 'react';
import style from './MainTaskArea.module.scss'
import {Subtasks} from "./Subtasks/Subtasks";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {Input, Spin} from "antd";
import ekasut from "../../../../../api/ekasut";

const loadIcon = <LoadingOutlined style={{fontSize: 24, marginLeft: '10px', color: 'rgba(0,0,0,0.5)'}} spin/>;

export const MainTaskArea = ({taskData, setTaskData}) => {

    const [addSubtask, setAddSubtask] = useState()
    const [editDesc, setEditDesc] = useState(false)
    const [descContent, setDescContent] = useState(taskData.content ? taskData.content : '')
    const [loading, setLoading] = useState(false)


    const updateDesc = () => {
        setLoading(true)
        ekasut.taskboard.updateTaskDescTitle(taskData.workGroupId, taskData.taskNumber, descContent, taskData.title)
            .then(({data}) => {
                    setEditDesc(false)
                    setTaskData(data)
                    setLoading(false)
                }
            )
    }


    return (
        <div className={style.mainTaskArea}>
            <div className={style.desc}>
                <div className={style.descHeader}>
                    <h4>Описание</h4>
                    {taskData.content && taskData.workGroupUserRoleId !== 3 ? <div onClick={() => {
                        setEditDesc(true)
                    }}>РЕДАКТИРОВАТЬ</div> : null}
                </div>
                {editDesc ? <> <Input.TextArea loading={loading} multiple value={descContent}
                                               onChange={(e) => {
                                                   setDescContent(e.target.value)
                                               }} autoSize={{minRows: 2, maxRows: 4}} autoFocus/>
                    <span className={style.cancle} onClick={() => {
                        if (!loading) {
                            setEditDesc(false)
                        }
                    }}>Отмена</span>
                    {loading ? <Spin indicator={loadIcon}/> :
                        <span className={style.addSubDone} onClick={() => {
                            updateDesc()
                        }}>Сохранить</span>} </> : taskData.content ?
                    <p className={style.pDesc} style={{whiteSpace: 'pre-line'}}>{taskData.content}</p> :
                    <div className={taskData.workGroupUserRoleId !== 3 ? style.addDesc : style.emptyDesc}>
                        {taskData.workGroupUserRoleId !== 3 ? <div onClick={() => {
                            setEditDesc(true)
                        }}>
                            <span>Добавить описание</span>
                            <PlusOutlined/>
                        </div> : <div>
                            Нет описания
                        </div>}
                    </div>}
            </div>
            <div className={style.subtasks}>
                <div className={style.subtasksHeader}>
                    <h4>Подзадачи</h4>
                    {taskData.subtasks.length && taskData.workGroupUserRoleId !== 3 ? <div onClick={() => {
                        setAddSubtask(true)
                    }}>ДОБАВИТЬ <PlusOutlined/></div> : null}
                </div>
                <Subtasks taskId={taskData.taskId} taskData={taskData}
                          setAddSubtask={setAddSubtask}
                          addSubtask={addSubtask}
                          setTaskData={setTaskData}/>
            </div>
        </div>
    )
}