import React, {useEffect} from 'react'
import EventsBlock from "./EventsBlock";
import moment from 'moment'
import MonthPickerBar from "./MonthPickerBar";
import EventsGrid from "./EventsGrid";
import styles from './index.module.scss'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import {padStartZero, groupToMap} from "../../helpers/utils";

export default function CalendarView({onAdd, onClick, month, year, onDateChange}) {
    const [sendGetPlanes, , planes] = useApiMethod(ekasut.tchmi.getPlanDetail, undefined, new Map(),
        ({data}) => {
            for (let i = 0; i < data.length; i++) {
                data[i].fio = `${data[i].persLastName} ${data[i].persFirstName} ${data[i].persPatrName}`
            }
            return groupToMap(data, 'planDate', (dateStr) => {
                return moment(dateStr).format("YYYY, MM")
            })
        }
    )

    useEffect(() => {
        sendGetPlanes()
    }, [sendGetPlanes])

    const formEventBlocks = () => {
        if (planes.size === 0) {
            return []
        }
        const startMonth = moment(`${year}-${padStartZero(month+1, 2)}-01`)
        const planesForMonth = planes.get(startMonth.format("YYYY, MM"))
        const eventBlocks = []
        for (let i = 0; i < startMonth.daysInMonth(); i++) {
            const day = moment(startMonth)
            day.set({date: i + 1})
            const events = []
            if (planesForMonth !== undefined) {
                planesForMonth.forEach(plan => {
                    if (moment(plan.planDate).date() === day.date()) {
                        events.push(plan)
                    }
                })
            }
            eventBlocks.push(
                <EventsBlock
                    key={`e-${i}`}
                    date={day}
                    events={events}
                    onAdd={() => {
                        onAdd(day)
                    }}
                    onClick={(event) => {
                        onClick(event)
                    }}
                />
            )
        }
        return eventBlocks
    }

    return (
        <div>
            <MonthPickerBar
                year={year}
                month={month}
                onChange={({year, month}) => {
                    formEventBlocks(year, month)
                    onDateChange(year, month)
                }}
            />
            <div className={styles.gridContainer}>
                <EventsGrid>
                    {formEventBlocks()}
                </EventsGrid>
            </div>
        </div>
    )
}
CalendarView.defaultProps = {
    month: moment().month(),
    year: moment().year(),
    onDateChange: () => {}
}