import React from "react"
import { Button } from "semantic-ui-react";
import { purchaseStatuses } from "./constants";

const PurchaseActionCell = ({ purchase, roles, onAddPurchase, onAddResponsible, onPreliminaryCompletion, onComplete, onPlanPurchase, onReject }) => {

    if (!purchase.submitDate && purchase.statusId === purchaseStatuses.planPurchase && roles.includes('start_price_formation')) {
        return <Button content="Запланировать закупку" className="button-link" onClick={onPlanPurchase} />
    }
    if (purchase.submitDate && (purchase.statusId === purchaseStatuses.planned || purchase.statusId === purchaseStatuses.rejectPurchase) && roles.includes('start_price_formation')) {
        return (<Button content="Добавить закупку" className="button-link" onClick={onAddPurchase} />)
    }
    if (purchase.hasDetail && purchase.statusId === purchaseStatuses.work && roles.includes('finish_price_formation')) {
        return (<Button content="Назначить ответственного" className="button-link" onClick={onAddResponsible} /> )
    }
    if (purchase.responsibleSetDate && purchase.statusId === purchaseStatuses.definedExecutor && roles.includes('finish_price_formation')) {
        return (<>
            <Button content="Предварительное завершение" className="button-link" onClick={onPreliminaryCompletion} />
            <Button content="Отклонить" className="button-link" onClick={onReject} />
        </>)
    }
    if (purchase.responsibleSetDate && purchase.statusId === purchaseStatuses.preliminaryCompletion && roles.includes('finish_price_formation')) {
        return (<>
            <Button content="Завершить обработку" className="button-link" onClick={onComplete} />
            <Button content="Отклонить" className="button-link" onClick={onReject} />
        </>)
    }


    return null
}

export default PurchaseActionCell
