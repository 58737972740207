import React, { useState }from 'react'
import { Table } from 'semantic-ui-react'
import moment from 'moment'
import './DetailsTable.scss'
import LocomotiveModal from '../../../shared/LocomotiveModal'

export default function DetailsTable({details, title, onRowClick}) {
	const [locModal, setLocModal] = useState({open: false})
	const handleRowClick = (detail, index) => {
		onRowClick(detail)
		setLocModal({
			open: true,
			seriesId: detail.kodSer,
			number: detail.znsLoc,
			sectionId: detail.prsLoc
		})
	}

	let tableRows = details.map((detail, index) => {
		return (
			<Table.Row key={index} onClick={() => handleRowClick(detail, index)}>
				<Table.Cell>{detail.dorName}</Table.Cell>
				<Table.Cell>{detail.depoName}</Table.Cell>
				<Table.Cell>{detail.serName}</Table.Cell>
				<Table.Cell>{detail.znsLoc + detail.prsCharLoc}</Table.Cell>
				<Table.Cell>{moment(detail.dtNotification).format('DD.MM HH.mm')}</Table.Cell>
				<Table.Cell>{detail.idKind}</Table.Cell>
				<Table.Cell>{detail.characterName}</Table.Cell>
				<Table.Cell>{detail.objectName}</Table.Cell>
			</Table.Row>
		)
	})

	return (
		<>
			<LocomotiveModal
				open={locModal.open}
				seriesId={locModal.seriesId}
				number={locModal.number}
				sectionId={locModal.sectionId}
				onClose={() => setLocModal({open: false})}
				onError={(err) => {
					setLocModal({open: false})
				}}
			/>
			<Table celled selectable className={'pr-details-table'}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell className="center aligned" colSpan='8'>{title}</Table.HeaderCell>
					</Table.Row>
					<Table.Row>
						<Table.HeaderCell className="center aligned">Дорога</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Депо</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Серия</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Номер</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Дата</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Категория</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Характер</Table.HeaderCell>
						<Table.HeaderCell className="center aligned">Группа оборудования</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{tableRows}
				</Table.Body>
			</Table>
		</> 
	)
}
DetailsTable.defaultProps = {
	onRowClick: () => {}
}
