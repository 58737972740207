import { useAsObservableSource, useLocalStore } from "mobx-react-lite";
import { addFetchFunctional } from "../../helpers/utils";
import ekasut from "../../api/ekasut";
import dbSync from "../../api/dbSync";
import { observable } from "mobx";

// todo: rewrite logic with tabs, very hard to understand what is going on here
export default function (props) {
  const obsProps = useAsObservableSource(props);

  const s = useLocalStore(() => ({
    locId: obsProps.locId,
    serLoc: obsProps.serLoc,
    znsLoc: obsProps.znsLoc,
    prsLoc: obsProps.prsLoc,
    sectionId: obsProps.sectionId,
    number: obsProps.number,
    seriesId: obsProps.seriesId,
    isLocIdMode: () => s.locId !== undefined && s.locId !== null,
    ...addFetchFunctional("details", () => apiGetTpeDetail(s), {
      parser: ({ data }) => parseDetails(data, s),
      defValue: [],
    }),
    tabs: observable.map(),
  }));
  return s;
}

const apiGetTpeDetail = (s) => {
  return s.isLocIdMode()
    ? ekasut.ekasutData.getTpeDetailByIdTpe(s.locId)
    : ekasut.ekasutData.getTpeDetailBySection(
        s.serLoc,
        s.znsLoc,
        s.prsLoc
      );
};

const handle500 = (err, store, name) => {
  // Now i'm wronly assume that status = 500 means empty data
  if (err.response.status === 500) {
    // Create meaningful response instead of server
    store[name + "Message"] = err.response.data.message;
    store[name + "Status"] = "success";
  }
  if (err.response.status === 400) {
    store[name + "Message"] = err.response.data.toString();
    store[name + "Status"] = "success";
  }
};

const parseBarrierFunctions = ({ data }) => {
  const ids = {};
  data.forEach((it) => (ids[it.checkType] = it));
  return ids;
};

const parseDetails = (data, s) => {
  const details = data.map((it) => ({
    ...it,
    locImageDegree: 0,
    locImageURL: `${window.backendUrl}/api/photo/${it.idObject}`,
  }));

  s.tabs.clear();
  data.forEach((item) => {
    s.tabs.set(item.idObject, {
      activePanelIndex: -1, // for accordion
      locImageURL: `${window.backendUrl}/api/photo/${item.idObject}`,
      ...addFetchFunctional("stateHistory", ekasut.ekasutData.getSostHistory, {
        defValue: [],
        errorHandler: (err) =>
          handle500(err, s.tabs.get(item.idObject), "stateHistory"),
      }),
      ...addFetchFunctional("onBoardDiagnostic", ekasut.ekasutData.getSvps, {
        defValue: {},
        errorHandler: (err) =>
          handle500(err, s.tabs.get(item.idObject), "onBoardDiagnostic"),
      }),
      ...addFetchFunctional("fuelEnergyRes", ekasut.ekasutData.getFuel, {
        defValue: null,
        errorHandler: (err) =>
          handle500(err, s.tabs.get(item.idObject), "fuelEnergyRes"),
      }),
      ...addFetchFunctional("fuelEnergyHistory", ekasut.svpsCheck.getFuel, {
        defValue: [],
        errorHandler: (err) =>
          handle500(err, s.tabs.get(item.idObject), "fuelEnergyHistory"),
      }),
      ...addFetchFunctional("electricPassport", ekasut.ekasutData.getEp, {
        defValue: [],
        errorHandler: (err) =>
          handle500(err, s.tabs.get(item.idObject), "electricPassport"),
      }),
      ...addFetchFunctional(
        "barrierFunctions",
        ekasut.ekasutData.getLogControls,
        {
          defValue: {},
          parser: parseBarrierFunctions,
          errorHandler: (err) =>
            handle500(err, s.tabs.get(item.idObject), "barrierFunctions"),
        }
      ),
      ...addFetchFunctional("routeList", ekasut.ekasutData.getRoute, {
        defValue: [],
        errorHandler: (err) =>
          handle500(err, s.tabs.get(item.idObject), "routeList"),
      }),
    });
  });

  return details;
};
