/** Transform response blob error to json when response type defined as blob */
const responseBlobErrorToJson = (error) => {
    /* If response type is blob and blob contains json */
    if (error.request && error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf('json') !== -1) {
        // Promise to update error message and transform data to JSON object
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                // result contains string
                error.response.data = JSON.parse(reader.result)
                reject(error)
            }
            // If json in blob is corrupted (unlikely)
            reader.onerror = () => {
                error.response.data = {}
                error.message = "Cannot read response data"
                error.message = "Received blob object isn't valid"
                reject(error)
            }
            // read blob as string
            reader.readAsText(error.response.data)
        })
    }

    // Rejected promise without changes
    return Promise.reject(error)
}

export default responseBlobErrorToJson