import React from 'react'
import RoadMaintenancePerHour from './RoadMaintenancePerHour';

export default function FreightPerHour({stats}) {

	const nepCategories = [
		'Деповский ремонт',
		'Неплановый ремонт',
		'Заводской ремонт',
		'Рекламация',
		'Резерв ЦТ',
		'Перес, и тех. операции',
		'Ожидание исключения',
	]

	const nrpCategories = [
		'Простои в ОЖ.РАБ. (грузовые)',
		'Простои с бригадой (грузовые)',
		'Длительные простои (грузовые)',
		'ТО-2/экипировка (грузовые)',
		'Перемещение (грузовые)',
		'Простои в ОЖ.РАБ. (остальные)',
		'Длительные простои (остальные)',
		'Перемещение (остальные)',
		'ТО-2/экипировка (остальные)',
		'Резерв ЦД (все)',	
	]

	const nepData = [
		stats.toTrDiff,
		stats.kol35Diff,
		stats.kol38Diff,
		stats.kol41Diff,
		stats.kol32Diff,
		stats.kol42Diff,
		stats.kol43Diff
	]

	const nrpData = [
		stats.kol26Diff,
		stats.kol49Diff,
		stats.kol27Diff,
		stats.kol23Diff,
		stats.kol25Diff,
		stats.kol45Diff,
		stats.kol46Diff,
		stats.kol30Diff,
		stats.kol28Diff,
		stats.kol47Diff,
	]

	const nepValue = stats.kol31Diff
	const nrpValue = stats.kol22Diff

	return (
		<RoadMaintenancePerHour
			nepCategories={nepCategories}
			nrpCategories={nrpCategories}
			nepData={nepData}
			nrpData={nrpData}
			nepValue={nepValue}
			nrpValue={nrpValue}
			nepHeight={300}
			nrpHeight={380}
		/>
	)
}
