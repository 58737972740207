import React from 'react';
import {Tab} from "semantic-ui-react";
import LogControlManagement from "./BarriersManagement";
import MonitorBarrierFunctions from "./MonitorBarriers";

const DispatchingControlAccess = () => {
    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            defaultActiveIndex={0}
            panes={[{
                menuItem: "Управление барьерными функциями",
                render: () => (
                    <LogControlManagement />
                )
            }, {
                menuItem: "Мониторинг барьерных функций",
                render: () => (
                    <MonitorBarrierFunctions />
                )
            }]}
        />
    );
};

export default DispatchingControlAccess;