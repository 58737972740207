import React, {useState, useEffect} from 'react'
import {Tab, Table} from "semantic-ui-react";
import appStyles from '../../css/App.module.scss'
import styles from './WorkStaffDetails.module.scss'
import CellNotEmpty from "./CellNotEmpty";

export default function WorkStaffDetails({pointDetails, workersInfo, workerOnClick}) {
    const [panes, setPanes] = useState([])

    useEffect(() => {
        setPanes([
            {
                menuItem: 'Приказы',
                render: getTabRender(
                    <Orders
                        pointDetails={pointDetails}
                    />
                )
            },
            {
                menuItem: 'С нарушение контрольных сроков',
                render: getTabRender(
                    <Violations
                        workersInfo={workersInfo}
                        workerOnClick={workerOnClick}
                    />
                )
            },
        ])
    }, [pointDetails, workersInfo, workerOnClick])

    return (
        <Tab
            panes={panes}
        />
    )
}


const getTabRender = (content) => {
    return () => (
        <Tab.Pane>
            {content}
        </Tab.Pane>
    )
}

const Orders = ({pointDetails}) => {
    return (
        <Table compact className={styles.ordersTable}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Наименование приказа</Table.HeaderCell>
                    <Table.HeaderCell>Кол-во</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Использ. по сокр. Отдыху</Table.Cell>
                    <CellNotEmpty>{pointDetails.usedRest}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Использ. с оборота</Table.Cell>
                    <CellNotEmpty>{pointDetails.usedFromRotation}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Отмена ЛБ</Table.Cell>
                    <CellNotEmpty>{pointDetails.canceledBrigades}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Отправление пассажиром</Table.Cell>
                    <CellNotEmpty>{pointDetails.departureAsPassenger}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Отправление с пересидкой</Table.Cell>
                    <CellNotEmpty>{pointDetails.departureWithStops}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Второй отдых</Table.Cell>
                    <CellNotEmpty>{pointDetails.secondRest}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Продление рабочего времени</Table.Cell>
                    <CellNotEmpty>{pointDetails.prolongationWorkTime}</CellNotEmpty>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Продление отдыха</Table.Cell>
                    <CellNotEmpty>{pointDetails.prolongationRest}</CellNotEmpty>
                </Table.Row>

            </Table.Body>
        </Table>
    )
}



const shrinkFullName = (first, last, patronymic) => {
    const correctEmpty = (str) => {
        return str === null || str === undefined || str === '' ? '-' : str
    }
    return correctEmpty(last) + ' ' + correctEmpty(first)[0] + '.' + correctEmpty(patronymic)[0] + '.'
}

const Violations = ({workersInfo, workerOnClick}) => {
    return (
        <Table compact selectable className={appStyles.selectableTable}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Депо приписки</Table.HeaderCell>
                    <Table.HeaderCell>Фамилия И.О.</Table.HeaderCell>
                    <Table.HeaderCell>Наименование должности</Table.HeaderCell>
                    <Table.HeaderCell>Вид движения</Table.HeaderCell>
                    <Table.HeaderCell>Статус</Table.HeaderCell>
                    <Table.HeaderCell>Приком.</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {workersInfo.map((info, index) => (
                    <Table.Row key={index} onClick={() => workerOnClick(info)}>
                        <CellNotEmpty>{info.regDepotName}</CellNotEmpty>
                        <CellNotEmpty>{shrinkFullName(info.firstName, info.lastName, info.patronymicName)}</CellNotEmpty>
                        <CellNotEmpty>{info.postName}</CellNotEmpty>
                        <CellNotEmpty>{info.locTypeName}</CellNotEmpty>
                        <CellNotEmpty>{info.statusName}</CellNotEmpty>
                        <CellNotEmpty>{info.isBusinessTrip ? 'Да' : 'Нет'}</CellNotEmpty>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    )
}