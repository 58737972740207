import React from 'react';
import style from './PersonDetailsNew.module.scss';
import {HeaderPersonDetails} from "./HeaderPersonDetails/HeaderPersonDetails";
import {PersonDetailsTabs} from "./Tabs/PersonDetailsTabs";


export const PersonDetailsNew = ({info,additional}) => {
    return (
        <div className={style.wrap}>
            <HeaderPersonDetails info={info} additional={additional}/>
            <div className={style.body}>
                <PersonDetailsTabs info={info} additional={additional}/>
            </div>
        </div>
    )
}