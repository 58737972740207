import React, {useEffect, useState} from 'react';
import {Dimmer, Dropdown, Loader, Menu} from 'semantic-ui-react';
import style from './UserTaskList.module.scss'
import {List, Avatar, Skeleton, Divider, Spin, Popover} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import PersonIcon from '@material-ui/icons/Person';
import {Link} from "react-router-dom";
import {board} from '../../TaskListState'
import ekasut from "../../../../api/ekasut";
import {LoadingOutlined} from "@ant-design/icons";
import {AssigneePopover} from '../AssigneePopover/AssigneePopover'

const loadIcon = <LoadingOutlined style={{fontSize: 16, color: 'rgba(0,0,0,0.5)'}} spin/>;

const optionSort = [
    {key: 1, text: 'Дата обновления 🠗', value: 'updated_on_desc'},
    {key: 2, text: 'Дата обновления 🠕', value: 'updated_on'},
    {key: 4, text: 'Дата создания 🠗', value: 'added_on_desc'},
    {key: 3, text: 'Дата создания 🠕', value: 'added_on'},
    {key: 6, text: 'Плановая дата начала 🠗', value: 'plan_start_date_desc'},
    {key: 5, text: 'Плановая дата начала 🠕', value: 'plan_start_date'},
    {key: 8, text: 'Плановая дата завершения 🠗', value: 'plan_end_date_desc'},
    {key: 7, text: 'Плановая дата завершения 🠕', value: 'plan_end_date'},
    {key: 9, text: 'Прикрепленные файлы', value: 'has_files'},
    {key: 10, text: 'Наличие комментариев', value: 'has_comments'},
]

export const UserTaskList = ({listItems, loadingTasksList}) => {

    const [activeItem, setActiveItem] = useState('Активные')
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [taskList, setTaskList] = useState(listItems)
    const [hasMoreCnt, setHasMoreCnt] = useState(taskList.activeCount)
    const [sortValue, setSortValue] = useState('updated_on_desc')

    const {activeCount, allCount, closedCount, doneCount, taskPage} = taskList

    const changeItem = (e, {name}) => {
        board.userListLoading = true

        setActiveItem(name)
        loadFirstPage(name, sortValue)

    }

    const loadFirstPage = (name, sort = null) => {

        let id = name === 'Активные' ? 1 : name === 'Выполненные' ? 2 : name === 'Закрытые' ? 3 : ''
        let query = sort ? `&sortBy=${sort}` : ''

        if (document.querySelector('#firstItem')) {
            document.querySelector('#firstItem').scrollIntoView()
        }
        ekasut.taskboard.getUsersTasks({categoryId: id, pageNumber: 1, queryString: query})
            .then(({data}) => {
                board.userListItems = data
                board.userListLoading = false
                setTaskList(data)
                setPage(1)
                setHasMoreCnt(name === 'Активные' ? activeCount : name === 'Выполненные' ? doneCount : name === 'Закрытые' ? closedCount : allCount)
            })
    }

    const downloadMore = (page) => {
        if (loading) {
            return;
        }
        let arr = board.userListItems
        let query = sortValue ? `&sortBy=${sortValue}` : ''
        setLoading(true)
        let id = activeItem === 'Активные' ? 1 : activeItem === 'Выполненные' ? 2 : activeItem === 'Закрытые' ? 3 : ''
        ekasut.taskboard.getUsersTasks({categoryId: id, pageNumber: page + 1, queryString: query})
            .then(({data}) => {
                arr.taskPage.data.push(...data.taskPage.data)
                setTaskList(arr)
                setLoading(false)
                setPage(page + 1)
            })
            .catch(() => {
                setLoading(false);
            });
    }

    return (
        <div className={style.taskList} id={'mainAria'}>
            <Menu pointing secondary className={style.menuHeader}>
                <div className={style.loader} style={loadingTasksList ? {} : {display: 'none'}}>
                    <Spin indicator={loadIcon}/>
                </div>
                <Menu.Item
                    name='Активные'
                    active={activeItem === 'Активные'}
                    onClick={changeItem}
                    disabled={activeCount === 0}
                >Активные <div className={style.itemCnt}>{activeCount}</div>
                </Menu.Item>
                <Menu.Item
                    name='Выполненные'
                    active={activeItem === 'Выполненные'}
                    onClick={changeItem}
                    disabled={doneCount === 0}
                >Выполненные
                    <div className={style.itemCnt}>{doneCount}</div>
                </Menu.Item>
                <Menu.Item
                    name='Закрытые'
                    active={activeItem === 'Закрытые'}
                    onClick={changeItem}
                    disabled={closedCount === 0}
                >Закрытые
                    <div className={style.itemCnt}>{closedCount}</div>
                </Menu.Item>
                <Menu.Item
                    name='Все'
                    active={activeItem === 'Все'}
                    onClick={changeItem}
                    disabled={allCount === 0}
                >Все
                    <div className={style.itemCnt}>{allCount}</div>
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item
                    >
                        <span className={style.sortDropAria}>
                    Сортировка:{' '}
                            <Dropdown inline options={optionSort} value={sortValue}
                                      className={style.sortDrop} onChange={(event, data) => {
                                setSortValue(data.value)
                                loadFirstPage(activeItem, data.value)
                            }}/>
                    </span>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            {taskPage.data.length ? <InfiniteScroll
                    dataLength={taskPage.data.length}
                    next={() => {
                        downloadMore(page)
                    }}
                    hasMore={taskPage.data.length < hasMoreCnt}
                    loader={<Skeleton className={style.skeletonItem} avatar paragraph={{rows: 1}} active/>}
                    endMessage={<Divider plain>Задач больше нет 🤐</Divider>}
                    scrollableTarget="mainAria"
                >
                    <div id={'firstItem'}></div>
                    <List
                        style={{padding: '0px 5%'}}
                        dataSource={taskPage.data}
                        renderItem={item => (
                            <List.Item key={`t-id-${item.taskId}`}>
                                <List.Item.Meta
                                    title={<div className={style.taskTitle}><Link
                                        to={`taskboard/${item.workGroupId}-${item.taskNumber}`}>{item.title}</Link>
                                        <div className={style.statusDiv}
                                             style={{
                                                 backgroundColor: `${item.status.statusColorHex}`,
                                             }}>{item.status.statusName}</div>
                                        {item.workGroupUserRoleId === 3 ?
                                            <div className={style.statusDiv}
                                                 style={{
                                                     backgroundColor: '#e0e0e0',
                                                     color: 'black'
                                                 }}>Гость</div>
                                            : null}
                                    </div>}
                                    description={`${item.workGroupName} #${item.taskNumber} создано ${moment(item.addedOn).format('DD.MM.YYYY')} ${item.creatorName}`}
                                />
                                <div className={style.itemRightContent}>
                                    <div>
                                        {/*{activeItem === 'Все' ?*/}
                                        {/*    <span className={}>{item.status.categoryId === 1 ? 'Активна' : item.status.categoryId === 2 ? 'Выполнена' : 'Закрыта'}</span> : null}*/}
                                        {item.hasComments || item.hasFiles || item.subtasksCount || item.assignees.length ?
                                            <div className={style.itemComments}>

                                                {item.hasFiles ? <InsertDriveFileIcon
                                                    style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}

                                                {item.hasComments ?
                                                    <ChatBubbleIcon style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}

                                                {item.subtasksCount ?
                                                    <div className={style.subsCnt}><FormatListBulletedIcon style={{
                                                        fontSize: '1.1em',
                                                        color: '#a1a1a4'
                                                    }}/>{item.subtasksCount}</div> : null}

                                                {item.assignees.length === 1 ?
                                                    <div className={style.subsCnt}><PersonIcon style={{
                                                        fontSize: '1.1em',
                                                        color: '#a1a1a4'
                                                    }}/>{item.assignees[0].name}
                                                    </div> : item.assignees.length > 1 ?
                                                        <AssigneePopover assignees={item.assignees}/> : null
                                                }
                                            </div> : null}

                                        <div className={style.labelsList}>
                                            {item.labels ? item.labels.map((e, i) => (
                                                <div key={i} className={style.labelsDiv}
                                                     style={{
                                                         backgroundColor: `${e.labelColorHex}`,
                                                     }}>{e.labelName}</div>
                                            )) : null}
                                        </div>
                                    </div>

                                </div>
                            </List.Item>
                        )}>
                        <div className={style.dimmerAria}>
                            <Dimmer active={loadingTasksList}/>
                        </div>
                    </List>

                </InfiniteScroll> :
                <div className={style.emptyList}>
                    Нет задач
                </div>}
        </div>
    );
};