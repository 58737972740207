import {addFetchFunctional} from "../../../helpers/utils";
import ekasut from "../../../api/ekasut";

const createStore = ({actionId, actionRd, asutrId}) => {
    let _lastSelectedKey = 0
    let _countSelected = 1

    return {
        error: null,
        actionId, actionRd, asutrId,
        inheritProps({mode, remarksData = []}) {
            this.mode = mode
            this.selectedData = remarksData
        },
        maxNumberOfSelectedItems: 4,
        selected: {
            [_lastSelectedKey++]: {
                key: _lastSelectedKey - 1, // ^_^
                series: null,
                number: null,
                section: null,
                seriesIndex: null,
                numberIndex: null,
                sectionIndex: null,
                remarks: [],
                comment: "",
                ...addFetchFunctional("numberList", ekasut.locomotivesNsi.getZnsTps, {parser: parseLocNumbers, defValue: []}),
                ...addFetchFunctional("sectionList", ekasut.locomotivesNsi.getPrsTps, {parser: parseSections, defValue: []}),
            }
        },
        calcLength() {
            return Object.keys(this.selected).length
        },
        isSelectedMax() {
            return _countSelected >= this.maxNumberOfSelectedItems
        },
        selectedCount() {
            return _countSelected
        },
        resetAllSelected() {
            for (let id in this.selected) {
                const sel = this.selected[id]
                sel.series = null
                sel.number = null
                sel.section = null
                sel.seriesIndex = null
                sel.numberIndex = null
                sel.sectionIndex = null
                sel.numberList = []
                sel.sectionList = []
                sel.remarks = []
                sel.comment = ""
            }
        },
        addSelectedItem(selProps) {
            if (!this.isSelectedMax()) {
                this.selected[_lastSelectedKey] = {
                    series: null,
                    number: null,
                    section: null,
                    seriesIndex: null,
                    numberIndex: null,
                    sectionIndex: null,
                    key: _lastSelectedKey,
                    remarks: [],
                    comment: "",
                    ...addFetchFunctional("numberList", ekasut.locomotivesNsi.getZnsTps, {parser: parseLocNumbers, defValue: []}),
                    ...addFetchFunctional("sectionList", ekasut.locomotivesNsi.getPrsTps, {parser: parseSections, defValue: []}),
                    ...selProps,
                }
                _countSelected++
                return _lastSelectedKey++
            }
        },
        removeSelected(key) {
            _countSelected--
            delete this.selected[key]
        },
        usedSections: new Map(),
        ...addFetchFunctional("saveRemarks", ekasut.tchmi.postKp, {}),
        ...addFetchFunctional("series", ekasut.locomotivesNsi.getSerTps, {parser: parseSeries, defValue: []}),
        ...addFetchFunctional("remarks", ekasut.tchmiNsi.getKp, {parser: parseRemarks, defValue: []})
    }
};

export const parseSelectedForRead = (data, remarks) => {
    const selected = {}
    data.forEach((item, index) => {
        selected[index] = {
            key: index,
            series: {id: item.serLoc, name: item.serName},
            number: item.znsLoc,
            section: {id: item.prsLoc, name: item.prsCharLoc},
            remarks: item.notes.map(n => remarks.findIndex(r => r.id === n.id)),
            comment: item.comment
        }
    })

    return selected
}

const parseSeries = ({data}) => data.map(item => ({
    id: item.value,
    name: item.text
}))

const parseRemarks = ({data}) => data.map(item => ({
    id: item.id,
    text: item.caption
}))
const parseLocNumbers = ({data}) => data

const parseSections = ({data}) => {
    const sections = []
    for (let item in data) {
        sections.push({id: parseInt(item), name: data[item]})
    }
    return sections
}

export default createStore;