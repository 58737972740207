import React, { useEffect, useState } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import styles from "../OrganizationInspection.module.scss";
import { GanttChart } from "./GanttChart/GanttChart";
import moment from "moment";
import ekasut from "../../../../api/ekasut";
import { EditRepairDate } from "./EditRepairDate/EditRepairDate";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";

// import { employees, data } from "./data.js";
import DataCell from "./DataCell.js";
import ResourceCell from "./ResourceCell.js";
import { text } from "body-parser";
import { Apartment } from "@material-ui/icons";
import Appointment from "./Appointment";
import AppointmentTooltip from "./AppointmentTooltip";
import { Select, Calendar, Alert, Col, Radio, Row, Typography } from "antd";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import style from "./InspectionChart.module.scss";
import calendLocale from "antd/es/date-picker/locale/ru_RU";

loadMessages(ruMessages);
locale(navigator.language || navigator.browserLanguage);

const InspectionChart = ({ pred, periodFilter }) => {
  const { Option } = Select;
  const { yearCO, typeCO } = periodFilter;
  const [chartData, setChartData] = useState([{}, {}]);
  const [loading, setLoading] = useState(false);
  const [editView, setEditView] = useState(false);
  const [currentKORange, setCurrentKORange] = useState([]);
  const [calendPlan, setCalendPlan] = useState([]);
  const [open, setOpen] = useState(false);
  const [idObject, setIdObject] = useState(null);
  const [currentLoco, setCurrentLoco] = useState({});
  const [repairsPlan, setRepairsPlan] = useState([]);
  const [currentSec, setCurrentSec] = useState({});
  const [updSection, setUpdSection] = useState(null);
  const [repair, setRepair] = useState({
    repairTypeId: null,
    repairDt: null,
  });
  const [alertShow, setAlertShow] = useState(false);
  const [chooseDateColor, setChooseDateColor] = useState({
    id: null,
    bol: false,
  });

  const currentDate = new Date();
  const views = ["month"];

  const calendarHeader = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];
    const current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }
    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i} className="month-item">
          {months[i]}
        </Select.Option>,
      );
    }
    const year = value.year();
    const month = value.month();
    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      );
    }
    return (
      <div
        style={{
          padding: 8,
        }}
      >
        <Typography.Title level={4}></Typography.Title>
        <Row gutter={8} style={{flexDirection: "row-reverse"}}>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              value={month}
              onChange={(newMonth) => {
                const now = value.clone().month(newMonth);
                onChange(now);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
          <Col><span className={style.calendarYear}>{moment(currentDate).format("YYYY")}</span></Col>
        </Row>
      </div>
    );
  }

  const repairTypes = [
    { id: 18, name: "ТР-3" },
    { id: 19, name: "ТР-2" },
    { id: 20, name: "ТР-1" },
    { id: 21, name: "ТО-3" },
    { id: 24, name: "ТР-1 + ТО-4" },
    { id: 25, name: "ТР-2 + ТО-4" },
    { id: 69, name: "ТР-3 + ТО-4" },
    { id: 70, name: "ТО-3 + ТО-4" },
  ];

  const getPeriodOrder = async () => {
    const res = await ekasut.commisionInspection.getPeriodOrder(
      localStorage.seasonYear,
      localStorage.season
    );
    setCurrentKORange(res?.data);
  };

  const getCalendPlan = async () => {
    const res = await ekasut.commisionInspection.getCalendPlan({
      pKodFirm: localStorage.kodFirm,
      pYear: localStorage.seasonYear,
      pType: localStorage.season,
      pIsRepeat: 0,
    });

    setCalendPlan(res?.data);
    return res;
  };

  const getRepairsPlan = async () => {
    const res = await ekasut.commisionInspection.getRepairs({
      pIdObject: currentSec.idObject,
    });
    setRepairsPlan(res?.data);
  };

  const updSetInfo = async (repair) => {
    if (updSection || repair.repairTypeId) {
      setChooseDateColor({
        id: null,
        bol: false,
      });
      const res = await ekasut.commisionInspection.updSecInfo({
        pRGSec: currentSec.rgSec,
        pAction: 0,
        pKodRepair: repair.repairTypeId,
        pDateRepair: moment(repair.repairDt).format("YYYY-MM-DD"),
        pIdReason: currentSec.reason ? currentSec.reason : 0,
        pYearSpis: currentSec.yearSpis ? currentSec.yearSpis : 0,
        pSignYear: currentSec.signYear ? currentSec.signYear : 0,
        // pWhoUpdate:
      });

      if (res?.status === 200) {
        getCalendPlan();
        setUpdSection(null);
        setRepair({
          repairTypeId: null,
          repairDt: null,
        });

        setOpen(false);
        setAlertShow(true);

        setTimeout(() => {
          setAlertShow(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    getPeriodOrder();
    getCalendPlan();
    // ekasut.commisionInspection.getSectionsPlan({pKodFirm: localStorage.getItem('kodFirm')})
    //     .then(({data}) => {
    //         setChartData(data.map((el, i) => (
    //             {
    //                 elements: [{
    //                     start: new Date(el.dateRepair),
    //                     end: new Date(new Date(el.dateRepair).valueOf() + 1000 * 60 * 60 * 24 - 1),
    //                     train: String(el.serName + '-' + el.serLoc),
    //                     id: `tr${i}-s${i}`,
    //                     title: String(el.nameRepair),
    //                     style: {
    //                         backgroundColor: "#0BB4C1",
    //                         color: "#000000",
    //                         borderRadius: "4px",
    //                         boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
    //                         textTransform: "capitalize"
    //                     }
    //                 }],
    //                 id: `tr${i}-s${i}-r${el.kodRepair}`,
    //                 isOpen: false,
    //                 title: String(el.serName + '-' + el.serLoc)
    //             }
    //         )))
    //         setLoading(false)
    //     })
  }, []);

  useEffect(() => {
    getRepairsPlan();
  }, [currentSec]);

  const data = calendPlan
    .map((e) => {
      if (e.dateRepair) {
        return {
          text: e.serName,
          nomSec: e.nomSec,
          startDate: moment(e.dateRepair).add(1, "days").format("YYYY-MM-DD"),
          endDate: moment(e.dateRepair).add(1, "days").format("YYYY-MM-DD"),
          nameRepair: e.nameRepair,
          idObject: e.idObject,
        };
      }
    })
    .filter((e) => e);

  // useEffect(() => {
  //   calendPlan.map((e) => {
  //     e.idObject === idObject && setCurrentLoco(e);
  //   });
  // }, [currentSec]);

  return (
    <div>
      <span className={styles.nameSpace}>График осмотров локомотивов</span>
      {/*<Segment className={styles.workSegmentChart}>*/}
      <div style={{ display: "flex" }}>
        <Select
          defaultValue="Поиск секций"
          style={{
            width: 300,
            marginRight: "20px",
          }}
          showSearch
          listHeight={570}
          open={true}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(e) => {
            const currentSec = JSON.parse(e);
            setCurrentSec(currentSec);
            setOpen(!open);
          }}
        >
          {calendPlan.map((e, i) => (
            <Option
              key={i}
              value={JSON.stringify(e)}
              style={
                e.dateRepair
                  ? {
                      background: "#74a374",
                      color: "#fff",
                      padding: "5px",
                      marginBottom: "5px",
                    }
                  : { padding: "5px", marginBottom: "5px" }
              }
            >
              <span
                className={style.calendPlanItem}
              >{`${e.serName} ${e.nomSec}`}</span>
              <span className={style.calendPlanItem}>{`${
                e.nameRepair ? e.nameRepair : ""
              }`}</span>
              <span className={style.calendPlanItem}>{`${
                e.dateRepair ? moment(e.dateRepair).format("YYYY-MM-DD") : ""
              }`}</span>
            </Option>
          ))}
        </Select>
        <div className={styles.workSegmentChart}>
          <Dimmer active={loading} inverted>
            <Loader inverted>Загрузка</Loader>
          </Dimmer>
          {console.log(data)}

          <Scheduler
            timeZone="America/Los_Angeles"
            dateSerializationFormat="yyyy-MM-ddTHH:mm:ssZ"
            dataSource={data}
            appointmentComponent={Appointment}
            appointmentTooltipComponent={AppointmentTooltip}
            defaultCurrentView="month"
            defaultCurrentDate={currentDate}
            height={600}
            width={1000}
            startDayHour={9}
            onAppointmentFormOpening={(e) => (e.cancel = true)}
          />
          {/* {chartData.length ?
          <GanttChart yearCO={yearCO} typeCO={typeCO} chartData={chartData} setEditView={setEditView}/> :
        <div className={styles.noData}>{loading ? null : 'Нет данных'}</div>} */}
          {alertShow && (
            <Alert
              style={{ width: "100%", textAlign: "center" }}
              message="Добавлено"
              type="success"
            />
          )}
        </div>
      </div>
      {/*</Segment>*/}
      <EditRepairDate editView={editView} setEditView={setEditView} />
      <Modal onClose={() => setOpen(false)} open={open}>
        <Modal.Header>
          Постановка на ремонт -{" "}
          {`${currentSec?.serName} ${currentSec?.nomSec}`}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Header>Выбор:</Header>
            <p>Плановые даты для выбора</p>
            <ul className={style.downloadedRepair}>
              {repairsPlan.map((e, i) => (
                <div
                  className={style.container}
                  style={
                    !chooseDateColor.bol && moment(currentSec.dateRepair).format("YYYY-MM-DD") ===
                      moment(e.repairDt).format("YYYY-MM-DD") ||
                    (chooseDateColor.bol && chooseDateColor.id === i)
                      ? {
                          background: "#709ac2",
                          color: "#fff",
                          fontWeight: "600",
                        }
                      : { background: "transparent" }
                  }
                  key={i}
                  onClick={() => {
                    setUpdSection(e);
                    setChooseDateColor({
                      id: i,
                      bol: true,
                    });
                  }}
                >
                  <p>{e.nameRepair}</p>
                  <p>{moment(e.repairDt).format("YYYY-MM-DD")}</p>
                </div>
              ))}
            </ul>
            <p>Ручной выбор даты</p>
            <div className={style.customRepairBlock}>
              <Select
                defaultValue="Выберите тип ремонта"
                style={{ width: 300 }}
                onChange={(e) =>
                  setRepair({
                    ...repair,
                    repairTypeId: e,
                  })
                }
              >
                {repairTypes.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
                <Option value="ТР-01">ТР-01</Option>
              </Select>
              <div className={style.customRepairDate}>
                <Calendar
                  fullscreen={false}
                  locale={calendLocale}
                  className="custom-calendar"
                  headerRender={calendarHeader}
                  onChange={(e) =>
                    setRepair({
                      ...repair,
                      repairDt: moment(e).format("YYYY-MM-DD"),
                    })
                  }
                />
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              setOpen(false);
              setChooseDateColor({
                id: null,
                bol: false,
              });
            }}
          >
            Закрыть
          </Button>
          <Button
            content="Подтвердить"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              updSection ? updSetInfo(updSection) : updSetInfo(repair);
            }}
            positive
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default InspectionChart;
