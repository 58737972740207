import React, { PureComponent } from 'react';
import axios from 'axios';
import auth from '../../../helpers/auth'

import {Grid, Segment, Form, Radio, List} from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'

import settings from '../../../helpers/settings'

class Settings extends PureComponent {
    state = {
        settings: {}
    }

    componentDidMount() {
        this.loadSettings()
    }

    loadSettings() {
        const me = this
        const helper = () => me.setState({settings: settings.getSettings(auth)})
        settings.getNotificationsFromServer(axios, helper)
    }

    handleChange = (e, { value }) => {
        const { settings } = this.state
        this.setState({settings: {...settings, autoRefreshDelay: value}})
    }

    toggleAutoRefresh = () => {
        const { settings } = this.state
        let newValue = !settings.autoRefresh
        this.setState({settings: {...settings, autoRefresh: newValue}})
    }

    toggleNotifications = () => {
        const { settings } = this.state
        let newValue = !settings.useNotification
        this.setState({settings: {...settings, useNotification: newValue}})
    }

    kasantChange = () => {
        const { settings } = this.state
        let newValue = !settings.filter.toShowKasant
        this.setState({settings: {...settings, filter:{ ...settings.filter, toShowKasant: newValue}}})
    }

    svpsChange = (parameter) => {
        const { settings } = this.state
        let newValue = !settings.filter.toShowSvps[parameter]
        this.setState({settings: {...settings, filter:{ ...settings.filter, toShowSvps: {...settings.filter.toShowSvps, [parameter]: newValue}}}})
    }

    componentDidUpdate() {
        const viewSettings = this.state.settings

        settings.setNotificationsToServer(axios, viewSettings, this.loadSettings)
    }

    render () {
        const { hideSidebars } = this.props
        const { settings } = this.state

        return (
            <Grid divided className={'route-container'} onClick={hideSidebars}>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <Segment>
                            <List divided verticalAlign='middle'>
                                <List.Item>
                                    <List.Content>
                                        <Radio toggle label='Автообновление карты' checked={settings.autoRefresh}
                                               onChange={this.toggleAutoRefresh}/>
                                    </List.Content>
                                </List.Item>
                            </List>

                            { settings.autoRefresh
                                ? (<Form>
                                    <Form.Field>
                                        <Radio
                                            label='10 минут'
                                            name='radioGroup'
                                            value={10}
                                            checked={settings.autoRefreshDelay === 10}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label='20 минут'
                                            name='radioGroup'
                                            value={20}
                                            checked={settings.autoRefreshDelay === 20}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Radio
                                            label='30 минут'
                                            name='radioGroup'
                                            value={30}
                                            checked={settings.autoRefreshDelay === 30}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Field>
                                </Form>)
                                : null}
                        </Segment>

                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <List divided verticalAlign='middle'>
                                <List.Item>

                                    <List.Content><Radio toggle label='Уведомлять о событиях' checked={settings.useNotification}
                                                         onChange={this.toggleNotifications}/></List.Content>
                                </List.Item>
                            </List>

                            {settings.useNotification
                                ? (<Form>
                                    <Form.Field>
                                        <Checkbox label='КАСАНТ' checked={settings.filter.toShowKasant} onChange={this.kasantChange}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Срабатывание защит в цепи КВ' checked={settings.filter.toShowSvps[1]} onChange={() => this.svpsChange(1)}/>
                                    </Form.Field>

                                    {/*<Form.Field>
                                        <Checkbox hidden label='ПРИЗНАК ПРЕВЫШЕНИЯ ТЕМПЕРАТУРЫ ВОДЫ ИЛИ МАСЛА' checked={settings.filter.toShowSvps[2]} onChange={() => this.svpsChange(2)}/>
                                    </Form.Field>*/}

                                    <Form.Field>
                                        <Checkbox label='Пониженное давление масла' checked={settings.filter.toShowSvps[3]} onChange={() => this.svpsChange(3)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Сброс нагрузки' checked={settings.filter.toShowSvps[4]} onChange={() => this.svpsChange(4)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Переход на аварийное возбуждение' checked={settings.filter.toShowSvps[5]} onChange={() => this.svpsChange(5)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Срабатывание защиты по максимальному току ВУ, внутреннему КЗ и тормозному току' checked={settings.filter.toShowSvps[6]} onChange={() => this.svpsChange(6)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Срабатывание пожарной сигнализации' checked={settings.filter.toShowSvps[7]} onChange={() => this.svpsChange(7)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Отклонение температуры масла на выходе из дизеля' checked={settings.filter.toShowSvps[8]} onChange={() => this.svpsChange(8)}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox label='Отклонение температуры воды в горячем контуре охлаждения' checked={settings.filter.toShowSvps[9]} onChange={() => this.svpsChange(9)}/>
                                    </Form.Field>
                                </Form>)
                                : null}

                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default Settings