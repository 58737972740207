import React, {useMemo} from 'react';
import {Button, Dropdown, Message, Modal, Table} from "semantic-ui-react";
import Widget from "../../shared/Widget";
import DropdownField from "../../shared/fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import ekasut from "../../api/ekasut";
import './index.scss'
import {toJS} from "mobx";

const Quiz = observer(() => {

    const ls = useLocalStore(() => ({
        roads: null,
        road: null,
        depot: null,
        surveys: null
    }))

    useDidFirstRender(() => {
        ekasut.questionnaire.getTerminals()
            .then(({data}) => ls.roads = data)
            .catch(catchNetworkErrors)
    })

    const roadsOptions = useMemo(() => ls.roads === null ? [] : ls.roads.map(road => ({
        text: road.roadName, value: road.roadId, key: road.roadId
    })), [ls.roads])

    const depotsOptions = useMemo(() => !ls.road ? [] : ls.road.depos.map(depot => ({
        text: depot.depoName, value: depot.depoId, key: depot.depoId
    })), [ls.road])

    return (
        <Modal
            open={true}
            size="large"
            className="app quiz alt-semantic"
        >
            <Widget
                header="Анкентирование"
            >
                <div className="filters">
                    <DropdownField
                        label="Дорога"
                        selection search clearable
                        value={ls.road && ls.road.roadId}
                        options={roadsOptions}
                        onChange={(e, {value}) => {
                            ls.road = value ? ls.roads.find(road => road.roadId === value) : null
                            ls.surveys = null
                            ls.depot = null
                        }}
                    />
                    <DropdownField
                        label="Предприятие"
                        placeholder={ls.road ? "" : "Выберите дорогу"}
                        selection search clearable
                        options={depotsOptions}
                        value={ls.depot && ls.depot.depoId}
                        onChange={(e, {value}) => {
                            ls.depot = value ? ls.road.depos.find(depot => depot.depoId === value) : null
                            ls.surveys = null
                            if (ls.depot && ls.depot.terminals[0]) {
                                ekasut.questionnaire.getLink({
                                    roadId: ls.road.roadId,
                                    depoId: ls.depot.depoId,
                                    terminalId: ls.depot.terminals[0].terminalId
                                })
                                    .then(({data}) => ls.surveys = data)
                                    .catch(catchNetworkErrors)
                            }
                        }}
                    />
                </div>
                {!(ls.depot && ls.road) && (
                    <Message content="Выберите дорогу и предприятие" />
                )}
                {ls.depot && (
                    ls.depot.terminals.length === 0 && <Message content="У предприятия нет терминалов" warning/>
                )}
                {ls.surveys !== null && (
                    ls.surveys.length === 0 && (
                        <Message content="Ссылки не найдены" warning/>
                    )
                    || ls.surveys.length > 0 && (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Наименование"/>
                                    <Table.HeaderCell content="Описание"/>
                                    <Table.HeaderCell content="Ссылка"/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {ls.surveys.map(survey => (
                                    <Table.Row key={survey.surveyID}>
                                        <Table.Cell content={survey.messageMain}/>
                                        <Table.Cell content={survey.messageWarning}/>
                                        <Table.Cell>
                                            <Button
                                                basic
                                                content={survey.surveyLink}
                                                onClick={() => {
                                                    window.open(survey.surveyLink, "_blank")
                                                }}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )
                )}
            </Widget>
        </Modal>
    );
})

export default Quiz;