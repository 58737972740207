import React from 'react'
import MonthPickerHeaderBar from './MonthPickerHeaderBar'
import EventGrid from "./EventGrid";

const CalendarDashboard = ({year, month, onChange, events, onAdd, onView}) => {
    return (
        <>
            <MonthPickerHeaderBar
                onChange={onChange}
                month={month}
                year={year}
            />
            <EventGrid
                events={events}
                year={year}
                month={month}
                onAdd={onAdd}
            />
        </>
    )
}
CalendarDashboard.defaultProps = {
    year: 1991,
    month: 4,
    onChange: () => {
    },
    onAdd: () => {
    },
    onView: () => {
    },
    events: {},
}

export default CalendarDashboard