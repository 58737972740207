import React from 'react';
import {Step} from "semantic-ui-react";
import cn from 'classnames'
import './AuditSteps.scss'

export const auditSteps = {
    prepareOrder: 1,
    formCheckList: 2,
    prepareAuditPlan: 3,
    checkProcesses: 4,
    developRecommendations: 5,
    summarize: 6,
}

/**
 *
 * @param c
 * @param c.step
 * @param c.onView       (step, isActive) => {}
 */
const AuditSteps = ({step, onView, viewStep}) => {

    const getStepProps = (auditStep) => {
        return {
            disabled: step < auditStep,
            active: step === auditStep,
            link: step >= auditStep,
            className: cn(viewStep === auditStep && "view-step"),
            onClick: () => {
                onView(auditStep, step === auditStep)
            }
        }
    }

    return (
        <Step.Group className="app audit-control audit-steps">
            <Step {...getStepProps(auditSteps.prepareOrder)}>
                <Step.Content>
                    <Step.Description content="Подготовка приказа"/>
                </Step.Content>
            </Step>
            <Step {...getStepProps(auditSteps.formCheckList)}>
                <Step.Content>
                    <Step.Description content="Формирование чек листа"/>
                </Step.Content>
            </Step>
            <Step {...getStepProps(auditSteps.prepareAuditPlan)}>
                <Step.Content>
                    <Step.Description content="Подготовка плана аудита"/>
                </Step.Content>
            </Step>
            <Step {...getStepProps(auditSteps.checkProcesses)}>
                <Step.Content>
                    <Step.Description content="Проверка процессов"/>
                </Step.Content>
            </Step>
            <Step {...getStepProps(auditSteps.developRecommendations)}>
                <Step.Content>
                    <Step.Description content="Разработка рекомендаций повышению эффективности"/>
                </Step.Content>
            </Step>
            <Step {...getStepProps(auditSteps.summarize)}>
                <Step.Content>
                    <Step.Description content="Итоги"/>
                </Step.Content>
            </Step>
        </Step.Group>
    );
};

export default AuditSteps;