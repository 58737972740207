import {addFetchFunctional, momentIfDateElseNull} from "../../../helpers/utils";
import ekasut from "../../../api/ekasut";
import moment from 'moment'
import {useLocalStore} from "mobx-react-lite";
import auth, {user} from "../../../helpers/auth";

export default function () {
    const store = useLocalStore(() => ({
        showAddModal: false,
        showEditModal: false,
        error: null,
        isUserTche: auth.getUser() === user.TCHE,
        isUserSld: auth.getUser() === user.SLD,
        initialDate: moment().add(1, "hours").minutes(0).seconds(0),
        startDate: moment().hours(0).minutes(0).seconds(0),
        endDate: moment().add(2, "day").hours(23).minutes(59).seconds(59),
        isLocomotiveSelected: () => false,
        selectedRow: null,
        tche: {id: null, name: str.dev.notSelected},
        sld: {id: null, name: str.dev.notSelected},
        isTcheSldValid: () => store.tche.id !== null && store.sld.id !== null,
        selectedTche: {id: null, name: str.dev.notSelected},
        selectedSld: {id: null, name: str.dev.notSelected},
        selectedPlanItem: {},
        locomotives: [],
        plan: defPlan,
        refreshTable: () => store.fetchPlanAndLocomotives(store.tche.id, store.startDate),
        isTcheSld: () => store.tche.id !== null && store.sld.id !== null,
        ...addFetchFunctional("pdf", ekasut.operativePlanRepairs.getLocomotivesPdf, {parser: res => res}),
        ...addFetchFunctional("excel", ekasut.operativePlanRepairs.getLocomotivesExcel, {parser: res => res}),
        ...addFetchFunctional("approvePlan", planId => ekasut.operativePlanRepairs.postApprove(planId, true),
            {parser: res => res}),
        ...addFetchFunctional("rejectPlan", planId => ekasut.operativePlanRepairs.postApprove(planId, false),
            {parser: res => res}),
        ...addFetchFunctional("tches", ekasut.ekasutData.getCtTree, {defValue: []}),
        ...addFetchFunctional("slds", ekasut.ekasutData.getServiceTree, {defValue: []}),
        ...addFetchFunctional("planAndLocomotives", ekasut.operativePlanRepairs.getLocomotivesByKodFirm,
            {parser: ({data}) => parsePlanAndLocomotives(data, store), defValue: []}),
        ...addFetchFunctional("repairTypes", ekasut.ekasutData.getRepairTypes, {defValue: []}),
        ...addFetchFunctional("series", ekasut.locomotivesNsi.getSerTps, {defValue: []}),
        ...addFetchFunctional("searchLocomotives", ekasut.planRepairs.getSearchLocomotives, {defValue: []}),
        ...addFetchFunctional("addLocomotives", ekasut.operativePlanRepairs.putLocomotive),
        ...addFetchFunctional("addLocomotive", ekasut.operativePlanRepairs.postLocomotive),
        ...addFetchFunctional("relatedFirm", ekasut.planRepairs.getRelatedFirm),
        ...addFetchFunctional("predChildren", ekasut.predNsi.getPredChildren),
    }))
    return store
}

const str = {
    dev: {
        notSelected: "[ Не выбрано ]"
    },
    noPlans: "Нет планов"
}

const parsePlanAndLocomotives = (data, store) => {
    data = preparePlanData(data)
    store.plan = planParser(data)
    if (data.locomotiveRepairs) {
        store.locomotives = locomotivesParser(data.locomotiveRepairs)
    } else {
        store.locomotives = []
    }

}

const defPlan = {
    id: 55,
    showChat: false,
    showApproveButton: false,
    showRedactButton: auth.getIsSld(),
    signers: {
        sldSign: null,
        sldSignDate: null,
        sldSignFio: null,
        sldSignPost: null,
        tcheSign: null,
        tcheSignDate: null,
        tcheSignFio: null,
        tcheSignPost: null,
    }
}

const preparePlanData = (data) => {
    if (data === "") {
        data = {
            planId: null,
            statusId: null,
            statusName: str.noPlans,
            wasApproved: false,
            locomotives: []
        }
    }
    return data
}

const planParser = (data) => {
    return {
        id: data.planId,
        showChat: data.showChat,
        showApproveButton: data.showApproveButton,
        //  to prevent 204 hidden case
        showRedactButton: typeof data.showRedactButton === "boolean" ? data.showRedactButton : auth.getIsSld(),
        signers: data.signers
    }
}

const locomotivesParser = data => data.map(it => ({
    ...it,
    planDate: momentIfDateElseNull(it.planDate),
    repDatePlan: momentIfDateElseNull(it.repDatePlan),
    dateOp: momentIfDateElseNull(it.dateOp)
}))