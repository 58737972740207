import React, { useEffect, useState } from "react";
import style from "./HeaderLocoDetails.module.scss";
import { Tag } from "antd";
import { Button, Icon } from "semantic-ui-react";
import moment from "moment";
import ekasut from "../../../../../api/ekasut";

export const HeaderLocoDetails = ({ info, setAddProt, currentObjId }) => {
  const photo = false;

  const [dateRange, setDateRange] = useState({});

  const currentSeason = moment().month() + 1 < 7 ? 1 : 2;

  const getDatePeriod = async () => {
    try {
      const result = await ekasut.commisionInspection.getPeriodOrder(
        moment().year(),
        currentSeason
      );
      setDateRange(result?.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getDatePeriod();
  }, []);

  return (
    <div className={style.depotInfo}>
      {console.log(dateRange)}
      {localStorage.grants.includes("komm_osmotr") &&
        (localStorage.accessLevel === "2" ||
          localStorage.accessLevel === "3") &&
        moment().isBetween(
          moment(dateRange.dateBegPodgCo).format("YYYY"),
          moment(dateRange.dateEndPodgCo).format("YYYY")
        ) && (
          <Button
            className={style.addProtBtn}
            onClick={() => {
              setAddProt(true);
            }}
          >
            Добавить протокол
          </Button>
        )}
    </div>
  );
};