import React, { useEffect, useState } from "react";
import { Header, Table } from "semantic-ui-react";
import styles from "./DynamicTable.module.scss";
import moment from "moment";

export default function DynamicTable({
  headerDetails,
  bodyDetails,
  filterStatus,
}) {
  const [headerRows, setHeaderRows] = useState([]);
  const [bodyRows, setBodyRows] = useState([]);
  const [activeRow, setActiveRow] = useState(null);
  const [message, setMessage] = useState(null);
  // const [headerStyle, setHeaderStyle] = useState({ })

  useEffect(() => {
    setActiveRow(null);
  }, [headerDetails, bodyDetails]);

  useEffect(() => {
    if (headerDetails.length === 0) {
      setMessage("Нет данных");
      return;
    }
    // Filter table
    let filteredHeaderDetails;
    let filteredBodyDetails;

    if (filterStatus.length > 0) {
      const filteredHeaderColDetails = [];
      const filteredBodyColDetails = [...bodyDetails];
      for (let i = 0; i < filteredBodyColDetails.length; i++) {
        filteredBodyColDetails[i] = { ...filteredBodyColDetails[i] };
        filteredBodyColDetails[i].columns = [];
      }

      headerDetails.forEach((headerDetail, headerIndex) => {
        let includeColumn = false;
        for (let i = 0; i < bodyDetails.length; i++) {
          includeColumn =
            filterStatus.find(
              (status) =>
                status === bodyDetails[i].columns[headerIndex].typeUseName
            ) !== undefined;
          if (includeColumn) {
            break;
          }
        }
        if (includeColumn) {
          filteredHeaderColDetails.push(headerDetail);
          filteredBodyColDetails.forEach((control, index) => {
            control.columns.push(bodyDetails[index].columns[headerIndex]);
          });
        }
      });
      filteredHeaderDetails = filteredHeaderColDetails;
      filteredBodyDetails = [];
      filteredBodyColDetails.forEach((control, index) => {
        let includeRow = false;
        for (let i = 0; i < control.columns.length; i++) {
          includeRow =
            filterStatus.find(
              (status) => status === control.columns[i].typeUseName
            ) !== undefined;
          if (includeRow) {
            break;
          }
        }
        if (includeRow) {
          filteredBodyDetails.push(control);
        }
      });
    } else {
      filteredHeaderDetails = headerDetails;
      filteredBodyDetails = bodyDetails;
    }

    if (filteredHeaderDetails.length === 0) {
      setMessage("В таблице нет записей с заданным в фильтре статусом");
      return;
    }

    // Headers
    const newHeaderRows = [];
    const headerCells = [];
    const subheaderCells = [];
    headerCells.push(
      <Table.HeaderCell key={"controls-header"}>
        Наименование БФ
      </Table.HeaderCell>
    );
    subheaderCells.push(<Table.HeaderCell key={"controls-subheader"} />);
    filteredHeaderDetails.forEach((detail, index) => {
      headerCells.push(
        <Table.HeaderCell key={"header-" + index} colSpan={2}>
          {detail.depoName}
        </Table.HeaderCell>
      );
      subheaderCells.push(
        <Table.HeaderCell key={"header-status-" + index}>
          Статус
        </Table.HeaderCell>
      );
      subheaderCells.push(
        <Table.HeaderCell key={"header-date-" + index}>
          Дата установки
        </Table.HeaderCell>
      );
    });
    newHeaderRows.push(<Table.Row key={"header"}>{headerCells}</Table.Row>);
    newHeaderRows.push(
      <Table.Row key={"subheader"}>{subheaderCells}</Table.Row>
    );
    // Body
    const newBodyRows = [];
    filteredBodyDetails.forEach((control, index) => {
      const bodyCells = [];
      bodyCells.push(
        <Table.Cell key={"control-" + index}>{control.controlName}</Table.Cell>
      );
      control.columns.forEach((detail, colIndex) => {
        if (filteredHeaderDetails[colIndex].depoId !== detail.depoId) {
          console.error(`cell depot id doesnt match with header corresponding id, headerDepotId: ${filteredHeaderDetails[colIndex].depoId},
                    cellDepotId: ${detail.depoId}`);
        }
        const isDetailPassFilter =
          filterStatus.length === 0
            ? true
            : filterStatus.find((status) => status === detail.typeUseName) !==
              undefined;
        bodyCells.push(
          <Table.Cell key={"status-" + index + "." + colIndex}>
            {isDetailPassFilter ? detail.typeUseName : ""}
          </Table.Cell>
        );
        bodyCells.push(
          <Table.Cell key={"date-" + index + "." + colIndex}>
            {isDetailPassFilter
              ? detail.editDate !== null && detail.editDate !== undefined
                ? moment(detail.editDate).format("DD.MM.YYYY HH:mm")
                : "-"
              : ""}
          </Table.Cell>
        );
      });
      const currentIndex = newBodyRows.length;
      newBodyRows.push(
        <Table.Row
          key={"row-" + index}
          onClick={() => setActiveRow(currentIndex)}
          active={activeRow === currentIndex}
        >
          {bodyCells}
        </Table.Row>
      );
    });

    setMessage(null);
    setHeaderRows(newHeaderRows);
    setBodyRows(newBodyRows);
  }, [headerDetails, bodyDetails, activeRow, filterStatus]);

  return message === null ? (
    <Table compact celled collapsing fixed selectable className={styles.table}>
      <Table.Header>{headerRows}</Table.Header>
      <Table.Body>{bodyRows}</Table.Body>
    </Table>
  ) : (
    <Header as={"h4"}>{message}</Header>
  );
}
