import React, { useState, useEffect, useCallback } from "react";
import style from "./Chats.module.scss";
import { Badge, Button, Dropdown, Input, Menu, Spin, Checkbox } from "antd";
import { ItemChatList } from "./ItemChatList/ItemChatList";
import { Message } from "./Message/Message";
import {
  DownCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ekasut from "../../api/ekasut";
import { ModalCreateChat } from "./ModalCreateChat/ModalCreateChat";
import {
  HttpTransportType,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import axios from "axios";
import { IconButton, TextField } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { ReplayIcon } from "./replayIcon";
import { ModalForwardedMessage } from "./ModalForwardedMessage/ModalForwardedMessage";
import { ForwardIcon } from "./forwardIcon";
import { ItemUser } from "./ItemUser/ItemUser";
import moment from "moment";
import { useHistory } from "react-router-dom";

const antIcon = <LoadingOutlined style={{ fontSize: 46 }} spin />;
const { Search } = Input;

export const defaultAvatar = (
  name = "Без имени",
  userId,
  history,
  group = false
) => {
  const regex = new RegExp("[а-дА-Дa-dA-D]");
  const regex1 = new RegExp("[е-иЕ-Иe-hE-H]");
  const regex2 = new RegExp("[й-нЙ-Нi-lI-L]");
  const regex3 = new RegExp("[о-тО-Тm-pM-P]");
  const regex4 = new RegExp("[у-чУ-Чq-tQ-T]");
  const regex5 = new RegExp("[ш-ьШ-Ьu-xU-X]");
  const regex6 = new RegExp("[э-яЭ-Яy-zY-Z]");

  let defaultName =
    group && !name
      ? "Гр"
      : name
      ? name?.split(" ")?.length > 1
        ? name?.split(" ")[0]?.charAt(0) + name?.split(" ")[1]?.charAt(0)
        : name?.charAt(0)
      : "Б-И";

  if (regex.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#fc2929" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }
  if (regex1.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#bc7c00" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }
  if (regex2.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#f8d800" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }
  if (regex3.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#1aa91a" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }
  if (regex4.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#2d82ff" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }
  if (regex5.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#6810ac" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }
  if (regex6.test(defaultName?.charAt(0))) {
    return (
      <div
        style={{ backgroundColor: "#ee82ee" }}
        className={"defaultAvatar"}
        onClick={() => history.push(`/profile/${userId}`)}
      >
        {defaultName}
      </div>
    );
  }

  return (
    <div
      style={{ backgroundColor: "#ee82ee" }}
      className={"defaultAvatar"}
      onClick={() => history.push(`/profile/${userId}`)}
    >
      {defaultName}
    </div>
  );
};

export const Chats = ({
  activeChat,
  setActiveChat,
  chatsLIst,
  setChatsLIst,
  connection,
  connectionTask,
}) => {
  const [visibleBtnScroll, setVisibleBtnScroll] = useState(false);

  // const [connection, setConnection] = useState();
  const [loadingConnect, setLoadingConnect] = useState(false);
  // const [connectionSetTimeOut, setConnectionSetTimeOut] = useState();

  // const [activeChat, setActiveChat] = useState(false);
  const [editChat, setEditChat] = useState(false);
  // const [chatsLIst, setChatsLIst] = useState({
  //   activeChat: activeChat.chatId,
  //   data: [],
  // });
  const [createChatModal, setCreateChatModal] = useState(false);

  const [messages, setMessages] = useState({
    activeChat: activeChat.chatId,
    data: [],
  });
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [paginationMessage, setPaginationMessage] = useState({});

  const [message, setMessage] = useState("");
  const [editMessage, setEditMessage] = useState(false);
  const [focusedMessageField, setFocusedMessageField] = useState(false);

  const [checkList, setCheckList] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [reply, setReply] = useState(false);

  const [modalForwardedMessage, setModalForwardedMessage] = useState(false);
  const [forwardedMessage, setForwardedMessage] = useState([]);

  const [typeList, setTypeList] = useState([]);
  const [timeOutTyping, setTimeOutTyping] = useState();
  const [typing, setTyping] = useState(false);

  const getChatsList = async () => {
    try {
      const { data } = await ekasut.chats.getChatsList();
      setChatsLIst({ activeChat: activeChat.chatId, data });
    } catch (e) {
      console.log(e);
    }
  };

  const onSearch = () => {};

  const selectMessage = (id) => {
    setCheckList(true);
    setSelectedMessages([id]);
    goToMessage(id);
  };

  const goToMessage = (id) => {
    const findEl = document.querySelector(`[data-messageid="${id}"]`);
    if (findEl) {
      findEl.scrollIntoView({ behavior: "smooth" });
    }
  };
  const addActiveSelectClass = (id) => {
    const findEl = document.querySelector(`[data-messageid="${id}"]`);
    if (findEl) {
      findEl.classList.add("activeSelect");
      setTimeout(() => {
        findEl.classList.remove("activeSelect");
      }, 2000);
    }
  };

  const goBottomScroll = () => {
    const bottomScrollBlock = document?.querySelector("#bottomScrollBlock");
    if (bottomScrollBlock) {
      bottomScrollBlock.scrollIntoView({ behavior: "smooth" });
      setChatsLIst((prevState) => ({
        activeChat: prevState?.activeChat,
        data: prevState.data.map((el) => {
          if (prevState?.activeChat === el.chatId) {
            if (el?.chatUnreadMessages && el?.chatId) {
              readMessage(el.chatUnreadMessages, el.chatId);
              el.chatUnreadMessages = [];
            }
          }
          return el;
        }),
      }));
    }
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      setMessages({ activeChat: false, data: [] });
      setReply(false);
      setForwardedMessage([]);
      setActiveChat(false);
    }
  }, []);

  const keyDownHandler = (e) => {
    if (e.keyCode == 13 && e.shiftKey) {
    } else if (e.keyCode == 13) {
      e.preventDefault();
      if (editMessage) {
        editMessageHandler();
      } else {
        sendMessage();
      }
    }
  };

  const getMessages = async ({ id = false, goToMessage = false } = {}) => {
    setLoadingMessages(true);
    try {
      const r = await ekasut.chats.getChatMessage({
        chatId: activeChat.chatId,
        pageNumber: id ? 1 : paginationMessage.pageNumber + 1,
        messageId: id,
      });
      if (r.status === 200) {
        const { data, ...restData } = r.data;
        setPaginationMessage({ ...restData });
        setMessages({
          activeChat: activeChat?.chatId,
          data: goToMessage ? data : [...messages.data, ...data.reverse()],
        });
      }
      setLoadingMessages(false);
    } catch (e) {
      console.log(e);
      setLoadingMessages(false);
    }
  };

  const onScrollMessagesList = (e) => {
    if (
      e.target.scrollHeight - Math.abs(e.target.scrollTop) <
      e.target.clientHeight + 2
    ) {
      if (
        paginationMessage.totalPages > paginationMessage.pageNumber &&
        !loadingMessages
      ) {
        getMessages();
      }
    }
    if (e.target.scrollTop < -100) {
      if (!visibleBtnScroll) {
        setVisibleBtnScroll(true);
      }
    } else {
      if (visibleBtnScroll) {
        setVisibleBtnScroll(false);
      }
    }
  };

  const countOnlineUsers = () => {
    const countAll = activeChat.chatUserList.length;
    const countOnlineUsers = activeChat.chatUserList.filter(
      (el) => el.isOnline
    ).length;

    const menu = (
      <Menu style={{ width: 350 }}>
        {console.log(activeChat)}
        {activeChat.chatUserList.map((el) => (
          <Menu.Item key={el.userId}>
            <ItemUser {...el} />
          </Menu.Item>
        ))}
      </Menu>
    );

    if (countAll < 3) {
      if (countOnlineUsers > 1) {
        return (
          <Button type={"link"} className="ant-dropdown-link">
            в сети
          </Button>
        );
      } else {
        const lastOnline = activeChat.chatUserList.filter(
          (el) => el.isOnline
        ).lastOnlineDate;
        return `был(а) ${moment(lastOnline).format("DD.MM.YYYY HH:mm:ss")}`;
      }
    } else {
      return (
        <Dropdown overlay={menu}>
          <Button type={"link"} className="ant-dropdown-link">
            {countAll +
              " участников" +
              (countOnlineUsers ? `, ${countOnlineUsers} в сети` : "")}
          </Button>
        </Dropdown>
      );
    }
  };

  const startChatMethods = async () => {
    await Promise.all(
      chatsLIst.data.map(async (el) => {
        try {
          return await connection.invoke("AddToGroup", el?.chatId);
        } catch (e) {
          console.log(e);
        }
      })
    );
    connection.on("ReceiveOnline", (e) => {
      setChatsLIst((prevState) => ({
        activeChat: prevState.activeChat,
        data: prevState.data.map((el) => {
          if (el.chatUserList.find((f) => f.userId === e.userId)) {
            el.chatUserList.find((f) => f.userId === e.userId).isOnline =
              e.isOnline;
            el.chatUserList.find((f) => f.userId === e.userId).lastOnlineDate =
              e.lastOnlineDate;
          }
          return el;
        }),
      }));
    });

    connection.on("ReceiveTyping", (userTyping) => {
      setTypeList((prevState) => {
        const fEl = prevState.find((el) => el.userId === userTyping.userId);
        if (fEl) {
          return prevState.map((e) => {
            if (e.userId === userTyping.userId) {
              e = userTyping;
            }
            return e;
          });
        } else {
          return [...prevState, userTyping];
        }
      });
    });
    connection.on("ReceiveMessage", addMessage);

    connection.on("ReceiveReadMessage", (idMes, chatId, status) => {
      setChatsLIst((prevState) => ({
        activeChat: prevState.activeChat,
        data: prevState.data.map((el) => {
          if (el.chatId === chatId) {
            el.chatUnreadMessages = el.chatUnreadMessages.filter(
              (e) => e !== idMes
            );
          }
          return el;
        }),
      }));
      setMessages((prevState) => {
        return {
          activeChat: prevState?.activeChat,
          data: prevState?.data?.map((el) => {
            if (el.messageId === idMes) {
              el.status = status;
            }
            return el;
          }),
        };
      });
    });
    connection.on("ReceiveDeletedMessage", (mesId) => {
      mesId.forEach((mes) => {
        setMessages((prevState) => ({
          activeChat: prevState.activeChat,
          data: prevState.data.filter((el) => el.messageId !== mes),
        }));
      });
      getChatsList();
    });

    connection.on("ReceiveEditedMessage", (mes) => {
      setMessages((prevState) => ({
        activeChat: prevState?.activeChat,
        data: prevState?.data.map((el) => {
          if (el.messageId === mes.messageId) {
            return mes;
          }
          return el;
        }),
      }));
      setMessage("");
      setEditMessage(false);
    });

    connection.on("ReceiveNewChat", (chat) => {
      setChatsLIst((prevState) => {
        const findEl = prevState.data.find((el) => el.chatId === chat.chatId);
        if (findEl) {
          return {
            activeChat: prevState.activeChat,
            data: prevState.data.map((el) => {
              if (el.chatId === chat.chatId) {
                return chat;
              }
              return el;
            }),
          };
        } else {
          connection.invoke("AddToGroup", chat?.chatId);
          return {
            activeChat: prevState.activeChat,
            data: [chat, ...prevState.data],
          };
        }
      });
    });
    // connection.onclose(async () => {
    //   console.log("close");
    // });
    connection.invoke("LogInOnline");
    // console.log("SignalR Connected.");
  };

  const createChat = ({ usersId, chatName }) => {
    if (connectionTask) {
      connection.invoke("AddChat", { usersId, chatName }).then((r) => {
        console.log("r", r);
      });
    }
  };

  const updateNotificationsStatus = ({ notificationsList }) => {
    if (connectionTask) {
      connectionTask
        .invoke("UpdateNotificationsStatus", { notificationsList })
        .then((r) => {
          console.log("r", r);
        });
    }
  };

  const editChatHandler = ({ usersId, chatName, chatId }) => {
    if (connection) {
      connection.invoke("EditChat", { usersId, chatName, chatId }).then((r) => {
        console.log("r", r);
      });
    }
  };

  const deleteChat = () => {
    ekasut.chats.deleteChat(activeChat.chatId).then((r) => {
      getChatsList();
      setActiveChat(false);
    });
  };

  const deleteMessage = (mesId) => {
    if (connection && activeChat) {
      connection.invoke("DeleteMessage", activeChat.chatId, mesId);
    }
  };

  const addMessage = (mes, temporaryId) => {
    setChatsLIst((prevState) => {
      const newList = prevState.data.filter((el) => el.chatId !== mes.chatId);
      let updateItem = prevState.data.find((el) => el.chatId === mes.chatId);
      if (mes.userId != localStorage.getItem("userId")) {
        if (updateItem?.chatUnreadMessages?.length) {
          updateItem.chatUnreadMessages = [
            ...updateItem.chatUnreadMessages,
            mes.messageId,
          ];
        } else {
          updateItem.chatUnreadMessages = [mes.messageId];
        }
      } else {
        goBottomScroll();
      }
      updateItem.lastMessage = mes;
      newList.unshift(updateItem);
      return { activeChat: prevState.activeChat, data: newList };
    });
    setMessages((prevState) => {
      if (prevState?.activeChat === mes?.chatId) {
        if (temporaryId && mes.userId == localStorage.getItem("userId")) {
          return {
            activeChat: prevState.activeChat,
            data: prevState?.data.map((el) => {
              if (temporaryId == el.temporaryId) {
                return mes;
              } else {
                return el;
              }
            }),
          };
        } else {
          return {
            activeChat: prevState.activeChat,
            data: [mes, ...prevState?.data],
          };
        }
      }
    });
  };

  const sendMessage = () => {
    if (connection && activeChat) {
      if (message.trim() || reply || forwardedMessage.length) {
        if (forwardedMessage.length) {
          forwardedMessage.forEach((el, i) => {
            const time = Date.now();
            setMessages((prevState) => ({
              activeChat: prevState.activeChat,
              data: [
                {
                  temporaryId: `forwardedMessage${i + time}`,
                  chatId: activeChat?.chatId,
                  content: "",
                  replyMessageId: el?.messageId,
                  replyMessageContent: el?.content
                    ? el?.content
                    : el.replyMessageContent,
                  replyUserFio: el?.userFio ? el?.userFio : el.replyUserFio,
                  IsForwardedMessage: true,
                  userId: localStorage.getItem("userId"),
                },
                ...prevState?.data,
              ],
            }));
            connection.invoke("SendMessage", {
              temporaryId: `forwardedMessage${i + time}`,
              chatId: activeChat?.chatId,
              content: "",
              replyMessageId: el?.messageId,
              replyMessageContent: el?.content
                ? el?.content
                : el.replyMessageContent,
              replyUserFio: el?.userFio ? el?.userFio : el.replyUserFio,
              IsForwardedMessage: true,
              userId: localStorage.getItem("userId"),
            });
          });
          if (message.trim()) {
            setMessages((prevState) => ({
              activeChat: prevState.activeChat,
              data: [
                {
                  temporaryId: `message${Date.now()}`,
                  chatId: activeChat?.chatId,
                  content: message,
                  replyMessageId: null,
                  replyMessageContent: null,
                  replyUserFio: null,
                  IsForwardedMessage: false,
                  userId: localStorage.getItem("userId"),
                },
                ...prevState?.data,
              ],
            }));
            connection.invoke("SendMessage", {
              chatId: activeChat?.chatId,
              content: message,
              temporaryId: `message${Date.now()}`,
            });
          }
        } else {
          setMessages((prevState) => ({
            activeChat: prevState.activeChat,
            data: [
              {
                temporaryId: `message${Date.now()}`,
                chatId: activeChat?.chatId,
                content: message,
                replyMessageId: reply?.messageId,
                replyMessageContent: reply?.content,
                replyUserFio: reply?.userFio,
                IsForwardedMessage: false,
                userId: localStorage.getItem("userId"),
              },
              ...prevState?.data,
            ],
          }));
          connection.invoke("SendMessage", {
            temporaryId: `message${Date.now()}`,
            chatId: activeChat?.chatId,
            content: message,
            replyMessageId: reply?.messageId,
            replyMessageContent: reply?.content,
            replyUserFio: reply?.userFio,
            IsForwardedMessage: false,
          });
        }
        setForwardedMessage([]);
        setMessage("");
        setReply(false);
      }
    }
  };
  const readMessage = (messagesId, chatId) => {
    if (connection) {
      connection.invoke("ReadMessage", messagesId, chatId);
    }
  };
  const editMessageHandler = () => {
    if (connection && message.trim()) {
      connection.invoke("EditMessage", activeChat.chatId, editMessage, message);
    }
  };

  const typingMessage = () => {
    const fun = () => {
      setTyping(false);
      const typingUser = activeChat.chatUserList.find(
        (el) => el.userId == localStorage.getItem("userId")
      );
      connection.invoke("TypingUser", {
        userId: typingUser.userId,
        chatId: activeChat.chatId,
        isTyping: false,
        userName: typingUser.userFio,
      });
    };

    const timeOut = setTimeout(fun, 1500);

    setTimeOutTyping(timeOut);

    if (!typing) {
      const typingUser = activeChat.chatUserList.find(
        (el) => el.userId == localStorage.getItem("userId")
      );
      setTyping(true);
      connection.invoke("TypingUser", {
        userId: typingUser.userId,
        chatId: activeChat.chatId,
        isTyping: true,
        userName: typingUser.userFio,
      });
    }
  };

  // const init = async () => {
  //   await getChatsList();
  //   try {
  //     const newConnection = await new HubConnectionBuilder()
  //       .withUrl(window.backendUrl + "/hubs/chat", {
  //         withCredentials: true,
  //         skipNegotiation: true,
  //         transport: HttpTransportType.WebSockets,
  //       })
  //       .withHubProtocol(new JsonHubProtocol())
  //       .configureLogging(LogLevel.Information)
  //       .build();
  //     setConnection(newConnection);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  useEffect(() => {
    if (activeChat) {
      setLoadingConnect(true);
      ekasut.chats
        .getChatMessage({
          chatId: activeChat.chatId,
          pageNumber: 1,
          messageId: false,
        })
        .then((r) => {
          if (r.status === 200) {
            const { data, ...restData } = r.data;
            setPaginationMessage({ ...restData });
            setMessages({ activeChat: activeChat?.chatId, data: data });
            setChatsLIst((prevState) => ({
              activeChat: prevState?.activeChat,
              data: prevState.data.map((el) => {
                if (prevState?.activeChat === el.chatId) {
                  if (el?.chatUnreadMessages && el?.chatId) {
                    readMessage(el.chatUnreadMessages, el.chatId);
                    el.chatUnreadMessages = [];
                  }
                }
                return el;
              }),
            }));
          }
          setLoadingConnect(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingConnect(false);
        });
    }
  }, [activeChat]);

  useEffect(() => {
    if (connection) {
      setTimeout(() => {
        startChatMethods();
      }, 1000);
    }

    // return () => {
    //   if (connection) {
    //     connection.stop();
    //   }
    //   if (connectionSetTimeOut) {
    //     clearTimeout(connectionSetTimeOut);
    //   }
    // };
  }, [connection]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    const messageWrap = document?.querySelector("#messageWrap");
    if (messages?.data?.length && messageWrap?.scrollTop > -100) {
      goBottomScroll();
    }
  }, [messages]);

  // useEffect(() => {
  //   initChat();
  // }, []);

  const menuActiveChat = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setEditChat({
            chatId: activeChat.chatId,
            chatName: activeChat.chatName,
            selectedUser: activeChat.chatUserList.map((el) => ({
              id: el.userId,
              fio: el.userFio,
            })),
          });
          setCreateChatModal(true);
        }}
      >
        Изменить чат
      </Menu.Item>
      <Menu.Item key="2" onClick={deleteChat}>
        Удалить чат
      </Menu.Item>
      <Menu.Item key="3">Очистить историю</Menu.Item>
    </Menu>
  );

  return (
    <div className={style.container}>
      <div className={style.chatsList}>
        <div className={style.head}>
          <Search
            placeholder="Поиск"
            onSearch={onSearch}
            enterButton
            className={style.searchInput}
          />
          <Button
            className={style.addBtn}
            type={"primary"}
            onClick={() => setCreateChatModal(true)}
            icon={<PlusOutlined />}
          />
        </div>
        {chatsLIst.data.map((el, i) => (
          <ItemChatList
            key={i}
            {...el}
            isOnline={chatsLIst?.data[i]?.chatUserList[i]?.isOnline}
            lastOnlineDate={chatsLIst?.data[i]?.chatUserList[i]?.lastOnlineDate}
            getChatsList={getChatsList}
            activeChat={activeChat.chatId}
            setActiveChat={setActiveChat}
            onClick={() => {
              setReply(false);
              setForwardedMessage([]);
              setCheckList(false);
              setActiveChat(el);
              setChatsLIst((prevState) => ({
                activeChat: el?.chatId,
                data: prevState?.data,
              }));
              setMessages((prevState) => ({
                activeChat: el?.chatId,
                data: prevState?.data,
              }));
            }}
          />
        ))}
      </div>
      <div className={style.messageBox}>
        {activeChat ? (
          loadingConnect ? (
            <div className={style.loadWrap}>
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <>
              <div className={style.head}>
                <IconButton
                  onClick={() => {
                    setMessages({ activeChat: false, data: [] });
                    setReply(false);
                    setForwardedMessage([]);
                    setActiveChat(false);
                  }}
                >
                  <KeyboardBackspaceIcon color={"primary"} />
                </IconButton>
                <span>
                  {activeChat.chatName}
                  <div className={style.countUsers}>{countOnlineUsers()}</div>
                </span>
                <Dropdown overlay={menuActiveChat} trigger={["click"]}>
                  <Button type="link" icon={<MoreOutlined />} />
                </Dropdown>
                <div
                  className={
                    style.headSelectedMessage +
                    " " +
                    (checkList ? style.active : "")
                  }
                >
                  <div>
                    <Button
                      type={"primary"}
                      onClick={() => setModalForwardedMessage(true)}
                    >
                      Переслать
                    </Button>
                    <Button
                      type={"primary"}
                      onClick={() => {
                        deleteMessage(selectedMessages);
                        setCheckList(false);
                        setSelectedMessages([]);
                      }}
                    >
                      Удалить
                    </Button>
                  </div>
                  <Button
                    type={"link"}
                    onClick={() => {
                      setCheckList(false);
                      setSelectedMessages([]);
                      goBottomScroll();
                    }}
                  >
                    Отмена
                  </Button>
                </div>
              </div>

              <Checkbox.Group
                style={{ width: "100%" }}
                className={style.messageWrap}
                id={"messageWrap"}
                value={selectedMessages}
                onChange={(val) => setSelectedMessages(val)}
                onScroll={onScrollMessagesList}
              >
                <div id={"bottomScrollBlock"} />
                {messages?.data?.map((el, i) => {
                  const prevEl = messages.data.find(
                    (el, index) => index === i + 1
                  );
                  if (prevEl && prevEl.userId === el.userId) {
                    if (checkList) {
                      return (
                        <Checkbox value={el.messageId} key={el.messageId}>
                          <Message
                            {...el}
                            getMessages={getMessages}
                            setSelectedMessages={setSelectedMessages}
                            setEditMessage={setEditMessage}
                            isGroup={activeChat.chatUserList.length > 2}
                            deleteMessage={deleteMessage}
                            nextEl={true}
                            setReply={setReply}
                            setMessage={setMessage}
                            selectMessage={selectMessage}
                            checkList={checkList}
                            setFocusedMessageField={setFocusedMessageField}
                          />
                        </Checkbox>
                      );
                    }
                    return (
                      <Message
                        key={el.messageId}
                        {...el}
                        getMessages={getMessages}
                        setSelectedMessages={setSelectedMessages}
                        setReply={setReply}
                        setEditMessage={setEditMessage}
                        isGroup={activeChat.chatUserList.length > 2}
                        deleteMessage={deleteMessage}
                        nextEl={true}
                        selectMessage={selectMessage}
                        setMessage={setMessage}
                        checkList={checkList}
                        setFocusedMessageField={setFocusedMessageField}
                      />
                    );
                  } else {
                    if (checkList) {
                      return (
                        <Checkbox value={el.messageId} key={el.messageId}>
                          <Message
                            {...el}
                            getMessages={getMessages}
                            setSelectedMessages={setSelectedMessages}
                            setReply={setReply}
                            selectMessage={selectMessage}
                            setEditMessage={setEditMessage}
                            isGroup={activeChat.chatUserList.length > 2}
                            deleteMessage={deleteMessage}
                            setMessage={setMessage}
                            checkList={checkList}
                            setFocusedMessageField={setFocusedMessageField}
                          />
                        </Checkbox>
                      );
                    }

                    return (
                      <Message
                        key={i}
                        deleteMessage={deleteMessage}
                        {...el}
                        getMessages={getMessages}
                        setSelectedMessages={setSelectedMessages}
                        setReply={setReply}
                        selectMessage={selectMessage}
                        setFocusedMessageField={setFocusedMessageField}
                        setEditMessage={setEditMessage}
                        setMessage={setMessage}
                        checkList={checkList}
                        isGroup={activeChat.chatUserList.length > 2}
                      />
                    );
                  }
                })}
                {loadingMessages && (
                  <div className={style.loadMessage}>
                    <Spin indicator={antIcon} />
                  </div>
                )}
              </Checkbox.Group>

              <Badge
                count={
                  chatsLIst?.data?.find(
                    (el) => el?.chatId == activeChat?.chatId
                  )?.chatUnreadMessages?.length
                }
                style={{ backgroundColor: "#52c41a" }}
                className={
                  style.bottomBtn +
                  " " +
                  (visibleBtnScroll ? style.visible : "")
                }
              >
                <Button
                  type={"link"}
                  onClick={goBottomScroll}
                  icon={<DownCircleOutlined />}
                />
              </Badge>
              {forwardedMessage.length ? (
                <div className={style.forwardedMessage}>
                  <div className={style.icon}>
                    <ForwardIcon />
                  </div>
                  <div>
                    {forwardedMessage.length > 1 ? (
                      <span>
                        <span className={style.count}>
                          {forwardedMessage.length}
                        </span>{" "}
                        пересланных сообщений
                      </span>
                    ) : (
                      <span>
                        <span className={style.count}>1</span> пересланное
                        сообщение
                      </span>
                    )}
                  </div>
                  <IconButton
                    size={"small"}
                    onClick={() => {
                      setForwardedMessage([]);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : null}
              {reply ? (
                <div className={style.reply}>
                  <div className={style.replyWrap}>
                    <div className={style.icon}>
                      <ReplayIcon />
                    </div>
                    <div
                      className={style.replyContent}
                      onClick={() => {
                        goToMessage(reply.messageId);
                        addActiveSelectClass(reply.messageId);
                      }}
                    >
                      <Button type={"link"}>{reply.userFio}</Button>
                      <span className={style.text}>{reply.content}</span>
                    </div>
                  </div>
                  <IconButton
                    size={"small"}
                    onClick={() => {
                      setReply(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : null}
              {typeList.length ? (
                <div>
                  {typeList
                    .filter((e) => e.isTyping)
                    .map((el) => (
                      <div className={style.typeListItem}>
                        {el.userName + " печатает..."}
                      </div>
                    ))}
                </div>
              ) : null}

              <div className={style.actionWrap}>
                <TextField
                  multiline
                  onKeyDown={keyDownHandler}
                  autoFocus
                  focused={focusedMessageField}
                  fullWidth
                  maxRows={5}
                  label={editMessage ? "Редактирование сообщения" : false}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    clearTimeout(timeOutTyping);
                    typingMessage();
                  }}
                  variant={"outlined"}
                  placeholder={
                    editMessage
                      ? "Редактирование сообщения"
                      : "Написать сообщение..."
                  }
                />
                {editMessage ? (
                  <div>
                    <IconButton
                      size={"small"}
                      onClick={() => {
                        setMessage("");
                        setEditMessage(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <IconButton onClick={editMessageHandler} size={"small"}>
                      <DoneIcon color={"primary"} />
                    </IconButton>
                  </div>
                ) : (
                  <IconButton onClick={sendMessage}>
                    <SendIcon color={"primary"} />
                  </IconButton>
                )}
              </div>
            </>
          )
        ) : (
          <div className={style.chatNotSelected}>Чат не выбран</div>
        )}
      </div>
      <ModalCreateChat
        createChatModal={createChatModal}
        setCreateChatModal={setCreateChatModal}
        createChat={createChat}
        initData={editChat}
        editChatHandler={editChatHandler}
      />
      <ModalForwardedMessage
        modalForwardedMessage={modalForwardedMessage}
        setModalForwardedMessage={setModalForwardedMessage}
        setActiveChat={setActiveChat}
        setCheckList={setCheckList}
        setForwardedMessage={setForwardedMessage}
        selectedMessages={selectedMessages}
        setSelectedMessages={setSelectedMessages}
        setReply={setReply}
        messages={messages}
      />
    </div>
  );
};
