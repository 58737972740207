import React from 'react';
import {observer, useLocalStore} from "mobx-react-lite";
import {Icon} from "semantic-ui-react";
import styles from './LocPicture.module.scss'
import cn from 'classnames'
import Lightbox from "react-image-lightbox";
import logo from '../../assets/images/logo.png'
import {useStore} from "./StoreProvider";

const canvas = document.createElement('canvas')
const context2d = canvas.getContext('2d')



const LocPicture = observer(({detail, className}) => {

    const store = useStore()
    const tab = store.tabs.get(detail.idObject)

    const ls = useLocalStore(() => ({
        _lightboxDisabled: false,
        _showLightbox: false,
        isShowLightbox: () => ls._showLightbox,
        showLightbox: val =>  {
            if (!ls.isLightboxDisabled()) {
                ls._showLightbox = val
            }
        },
        disableLightbox: () => {
            ls._lightboxDisabled = true
            ls._showLightbox = false
        },
        enableLightbox: () => {
            ls._lightboxDisabled = false
        },
        loaded: false,
        isLightboxDisabled: () => ls._lightboxDisabled
    }))
    /*const dataUrl = this.isUseDataUrl(tabIndex, sourceImg) ? locImageInfo[tabIndex].dataUrl : sourceImg*/

    return (
        <div className={cn(styles.container, (ls.isLightboxDisabled() || ls.loaded) && styles.containerWithImage, className)}>
            <img
                alt="Фотография секции локомотива"
                onClick={() => {
                    ls.showLightbox(true)
                }}
                src={tab.locImageURL}
                onError={(e) => {
                    tab.locImageURL = logo
                    ls.disableLightbox()
                }}
                onLoad={(e, v) => {
                    ls.loaded = true
                }}
                className={cn(styles.image, !ls.isLightboxDisabled() && styles.loadedImage)}
            />
            {ls.isShowLightbox() && (
                <Lightbox
                    mainSrc={tab.locImageURL}
                    onCloseRequest={() => {
                        ls.showLightbox(false)
                    }}
                />
            )}
            {!ls.isLightboxDisabled() && (
                <div className={styles.controls}>
                    <Icon
                        name='redo' bordered link
                        onClick={() => {
                            rotateImage(tab.locImageURL, +90, url => tab.locImageURL = url)
                        }}
                    />
                    <Icon
                        name='undo' bordered link
                        onClick={() => {
                            rotateImage(tab.locImageURL, -90, url => tab.locImageURL = url)
                        }}
                    />
                </div>
            )}
        </div>
    );
})

// Rotation here is a bit complicated
// Many libraries with image magnifier don't support image rotation,
// just changing styles with transform will result in incorrect positioning
// Solution: get different image urls if rotation applied
// Backend doesn't support rotation, so instead of url im using base64, it will work as well
const rotateImage = (imageUrl, degree, onRotated) => {
    if (degree < 0) {
        degree = 360 + degree // convert negative degree to equal positive
    }

    const image = new Image()
    image.crossOrigin = "Anonymous";
    image.src = imageUrl
    image.onload = () => {
        let width
        let height
        if ((degree === 0) || (degree === 180)) {
            width = image.width
            height = image.height
        }
        if ((degree === 90) || (degree === 270)) {
            // Swap height with width
            width = image.height
            height = image.width
        }
        canvas.height = height
        canvas.width = width

        context2d.save()
        context2d.translate(width / 2, height / 2)
        context2d.rotate(degree * Math.PI / 180)
        context2d.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height);
        context2d.restore()
        onRotated(canvas.toDataURL())
    }
}

export default LocPicture;