import React, { useEffect, useState } from "react";
import style from "./SideFilter.module.scss";
import TextField from "../../../../shared/fields/TextField";
import DropdownField from "../../../../shared/fields/DropdownField";
import moment from "moment";
import { Menu, Grid } from "semantic-ui-react";
import ekasut from "../../../../api/ekasut";
import { Select, Space, Typography } from "antd";

const { Option } = Select;

export const SideFilter = ({
  dates,
  setDates,
  activeSpace,
  changeWorkSpace,
}) => {
  const currentYear = moment().year();
  const setYear = (value) => {
    setDates((prevDates) => ({ ...prevDates, year: value }));
  };
  const setSeason = (value) => {
    setDates((prevDates) => ({ ...prevDates, season: value }));
  };

  const [pred, setPred] = useState("");
  const [period, setPeriod] = useState();
  const [periodFilter, setPeriodFilter] = useState({
    typeCO: null,
    yearCO: false,
  });
  const [predFilterOptions, setPredFilterOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorKod, setErrorKod] = useState(false);

  const initCOPeriod = () => {
    let nowMonth = moment().month() + 1;
    let nowYear = moment().year();
    setPeriod(
      nowMonth > 7 || nowMonth < 3 ? "Осень " + nowYear : "Весна " + nowYear
    );
    setPeriodFilter({
      typeCO: nowMonth > 7 || nowMonth < 3 ? 1 : 0,
      yearCO: nowYear,
    });
  };

  useEffect(() => {
    ekasut.commisionInspection.getListDepo(localStorage.kodFirm).then((res) => {
      setPredFilterOptions(
        res.data.map((e) => ({ text: e.firmName, value: e.kodDepo }))
      );
      if (
        res.data.filter(
          (e) => String(e.kodDepo) === localStorage.getItem("kodFirm")
        ).length
      ) {
        let result = res.data.filter(
          (e) => String(e.kodDepo) === localStorage.getItem("kodFirm")
        )[0];
        setPred({ text: result.firmName, value: result.kodDepo });
      } else if (localStorage.getItem("accessLevel") != 0) {
        setErrorKod(true);
      }
      if (localStorage.getItem("accessLevel") != 0) {
        initCOPeriod();
      } else {
        setPeriod(false);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Grid.Column className={"filter-column"}>
      <Grid.Row className={style.dropFilter}>
        {/*{!period ? <TextField*/}
        {/*        className={style.TextField}*/}
        {/*        label="Год и сезон проведения КО"*/}
        {/*        classNameContent="field-width"*/}
        {/*        content={period}*/}
        {/*    /> :*/}
        {/*    <DropdownField*/}
        {/*        disabled={false}*/}
        {/*        label="Год и сезон проведения КО"*/}
        {/*        search fluid clearable*/}
        {/*        options={[{text: 'Весна ' + moment().year(), value: 0}, {*/}
        {/*            text: 'Осень ' + moment().year(),*/}
        {/*            value: 1*/}
        {/*        }]}*/}
        {/*        value={periodFilter?.typeCO}*/}
        {/*        onChange={(e, {value}) => {*/}
        {/*            if (value === 0) {*/}
        {/*                setPeriodFilter({*/}
        {/*                    typeCO: value,*/}
        {/*                    yearCO: moment().year()*/}
        {/*                })*/}
        {/*            } else {*/}
        {/*                setPeriodFilter({*/}
        {/*                    typeCO: value,*/}
        {/*                    yearCO: moment().year()*/}
        {/*                })*/}
        {/*            }*/}
        {/*        }}*/}
        {/*    />}*/}
        <Grid.Row>
          <Typography.Title style={{ fontSize: "15px", paddingLeft: "7px" }}>
            Год и сезон проведения КО
          </Typography.Title>
          <Space style={{ margin: "0 0 15px 15px" }}>
            <Select
              style={{
                width: 120,
              }}
              placeholder="Сезон"
              onChange={(e) => {
                localStorage.setItem("season", e);
                setSeason(e);
              }}
            >
              <Option value="1">Весна</Option>
              <Option value="2">Осень</Option>
            </Select>
            <Select
              style={{
                width: 120,
              }}
              placeholder="Год"
              onChange={(e) => {
                localStorage.setItem("seasonYear", e);
                setYear(e);
              }}
            >
              <Option value={String(currentYear)}>{currentYear}</Option>
              <Option value={String(currentYear - 1)}>{currentYear - 1}</Option>
              <Option value={String(currentYear - 2)}>{currentYear - 2}</Option>
              <Option value={String(currentYear - 3)}>{currentYear - 3}</Option>
            </Select>
          </Space>
        </Grid.Row>
        <Grid.Row>
          {predFilterOptions.filter(
            (e) => String(e.value) === localStorage.getItem("kodFirm")
          ).length ? (
            <TextField
              className={style.TextField}
              label="Предприятие"
              classNameContent="field-width"
              content={pred.text}
            />
          ) : (
            <DropdownField
              disabled={errorKod || loading}
              label="Предприятие"
              search
              fluid
              clearable
              options={predFilterOptions}
              value={pred}
              onChange={(e, { value }) => {
                setPred(value);
              }}
            />
          )}
        </Grid.Row>

        <Grid.Row>
          <Menu pointing secondary vertical>
            <Menu.Item
              // disabled={loading || errorKod || !pred || !periodFilter?.yearCO}
              content={"Сведения о ходе комиссионного осмотра"}
              active={activeSpace.name === "Сведения"}
              onClick={() => {
                if (activeSpace.id !== 0) {
                  changeWorkSpace("Сведения");
                }
              }}
            />
            {localStorage.accessLevel == 2 || localStorage.accessLevel == 1 ? (
              ""
            ) : (
              <Menu.Item
                // disabled={
                //   loading || errorKod || !pred || !periodFilter?.yearCO
                // }
                content={"Списки для снятия подписей"}
                active={activeSpace.name === "Запросы"}
                onClick={() => {
                  if (activeSpace.id !== 1) {
                    changeWorkSpace("Запросы");
                  }
                }}
              />
            )}

            {/*<Menu.Item*/}
            {/*    disabled={loading || errorKod || !pred || !periodFilter?.yearCO}*/}
            {/*    content={'Надежность технических средств в период комиссионного осмотра'}*/}
            {/*    active={false}*/}
            {/*    onClick={() => {*/}

            {/*    }}*/}
            {/*/>*/}
            {/*<Menu.Item*/}
            {/*    disabled={loading || errorKod || !pred || !periodFilter?.yearCO}*/}
            {/*    content={'Показатель 1, ед. изм'}*/}
            {/*    active={false}*/}
            {/*    onClick={() => {*/}

            {/*    }}*/}
            {/*/>*/}
            {/*<Menu.Item*/}
            {/*    disabled={loading || errorKod || !pred || !periodFilter?.yearCO}*/}
            {/*    content={'Показатель 2, ед. изм'}*/}
            {/*    active={false}*/}
            {/*    onClick={() => {*/}

            {/*    }}*/}
            {/*/>*/}
            {/*<Menu.Item*/}
            {/*    disabled={loading || errorKod || !pred || !periodFilter?.yearCO}*/}
            {/*    content={'Показатель 3, ед. изм'}*/}
            {/*    active={false}*/}
            {/*    onClick={() => {*/}

            {/*    }}*/}
            {/*/>*/}
          </Menu>
        </Grid.Row>
      </Grid.Row>
    </Grid.Column>
  );
};
