import React from 'react';
import {Icon} from "semantic-ui-react";
import styles from './index.module.scss'

/** Text with icon ahead */
const IconInfo = ({icon, text}) => {
    return (
        <div className={styles.component}>
            <Icon name={icon} size="large"/>
            <span>{text}</span>
        </div>
    );
};

export default IconInfo;