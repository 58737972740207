import React from 'react';
import cn from 'classnames'
import './TextField.scss'

const TextField = ({ label, content, className, classNameContent, fluid }) => {
    return (
        <div className={cn("app field text-field", className, fluid && "fluid")}>
            <label>{label}</label>
            <div>
                <span className={classNameContent}>{content}</span>
            </div>
        </div>
    );
};

export default TextField;