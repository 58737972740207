import React, { Component } from 'react'
import { Popup, PopupHeader, PopupContent } from 'semantic-ui-react'
import './ProgressBarWithPopup.scss'

export default class ProgressBarWithPopup extends Component {
	static defaultProps = {
		value: 30,
		maxValue: 100, // has meaning only if filled is false
		leftValue: '',
		width: 70,
		height: 18,
		textAlign: 'right',
		fontSize: 16,
		popupClassName: '',
		progressClassName: '',
		filled: false, // fill entire progress bar with color if true
	}

	defColorPicker = (percent) => {
		var color

		if (percent < 53.92) {
			color = '#21ba45' // Green
		} else if (percent < 72) {
			color = '#fbbd08' // Yellow
		} else {
			color = '#db2828' // Red
		}

		return color
	}

	render() {
		var { value, maxValue, popupContent, popupHeader, 
			leftValue, colorPicker, width, height, color, filled, progressClassName,
			textAlign, fontSize, onClick, popupClassName, leftValueOnClick, valueOnClick} = this.props

		var percent = value / maxValue * 100
		
		let progressBarColor
		if (color === undefined) {
			progressBarColor = colorPicker === undefined ? this.defColorPicker(percent) : colorPicker(percent, value, maxValue)

		} else {
			progressBarColor = color
		}

		let progressWidth = filled ? width : width*percent/100
		progressWidth = progressWidth > width ? width : progressWidth

		let leftClass = 'pbwp-traction-type' + ((leftValueOnClick !== undefined) ? ' pbwp-clickable-traction-type' : '')
		let valueClass = 'pbwp-value' + ((valueOnClick !== undefined) ? ' pbwp-clickable-value' : '')

		var progressBar = (
			<div className={'progress-bar-with-popup ' + progressClassName} style={{width, height}} onClick={onClick}>
				<div className='pbwp-progress' style={{width: progressWidth, backgroundColor: progressBarColor, height}} />
				<p className={leftClass} style={{lineHeight: height+'px', fontSize, height, width: width/2}} onClick={leftValueOnClick}>{leftValue}</p>
				<p className={valueClass} style={{lineHeight: height+'px', textAlign, fontSize, height, width: width/2}} onClick={valueOnClick}>{value}</p>
			</div>
		)

		return (
			((popupContent === undefined) && (popupHeader === undefined)) ? 
				progressBar :
				<Popup trigger={progressBar} position={'top center'} className={'progress-bar-popup ' + popupClassName} >
					{(popupHeader !== undefined) && <PopupHeader>{popupHeader}</PopupHeader>}
					{(popupContent !== undefined) && <PopupContent>{popupContent}</PopupContent>}
				</Popup>
		)
	}
}