import React from 'react';
import {Divider} from "semantic-ui-react";
import styles from "../observers/SearchSerNumSec.module.scss";
import FormSearch from "./FormSearch";
import MultipleSelectList from "../../fields/MultipleSelectList";
import TextField from "../../fields/TextField";

const ViewLocRemarks = ({selected, remarks}) => {
    // for efficiency check only first item
    if (selected.length > 0 && selected[0].series === null) return null

    return (
        selected.map((sel, index) => {
            const isLast = index === selected.length - 1
            return (
                <React.Fragment key={sel.key}>
                    <div className={styles.searchLocContainer}>
                        <FormSearch
                            label="Серия"
                            readOnly={true}
                            readOnlyValue={sel.series.name}
                        />
                        <FormSearch
                            label="Номер"
                            readOnly={true}
                            readOnlyValue={sel.number}
                        />
                        <FormSearch
                            label="Секция"
                            readOnly={true}
                            readOnlyValue={sel.section.name}
                        />
                    </div>
                    {sel.remarks.length > 0 && (
                        <MultipleSelectList
                            label="Замечания к состоянию локомотива"
                            hideBoxes={true}
                            editable={false}
                            list={remarks}
                            values={sel.remarks}
                        />
                    )}
                    {sel.comment !== "" && (
                        <TextField
                            label="Коментарии"
                            maxSymbolCount={1000}
                            editable={false}
                            value={sel.comment}
                        />
                    )}
                    {!isLast && <Divider/>}
                </React.Fragment>
            )
        })
    )
}

export default ViewLocRemarks;