import React from 'react'
import styles from './Content.module.scss'
import {Form} from "semantic-ui-react";
import cn from 'classnames'

const Content = ({children, loading, showContent}) => {
    return (
        <div className={styles.mobileContainer}>
            <Form className={cn(styles.form)} loading={loading}>
                {showContent && (
                    children
                )}
                {!showContent && loading && (
                    <div style={{height: 200}} />
                )}
            </Form>
        </div>
    )
}
Content.defaultProps = {
    showContent: true
}
export default Content