import React, {useState, useEffect} from 'react'
import {Button} from "semantic-ui-react";
import styles from './index.scss'
import cn from 'classnames'


// buttons: [{key, ...props}]
// toggleButtonByDefault: 'key'
export default function ToggleButton({buttons, toggleButtonByDefault, groupProps, onToggle, value, className}) {
    const [toggle, setToggle] = useState(toggleButtonByDefault)

    useEffect(() => {
        if (value !== undefined) {
            setToggle(value)
        }
    }, [value])

    return (
        <Button.Group className={cn(className, styles.toggleButton)} {...groupProps} >
            {buttons.map(button => {
                const {key, className, onClick, ...props} = button
                const isToggle = toggle === key
                return (
                    <Button
                        {...props}
                        key={key}
                        basic={!isToggle}
                        className={cn(isToggle ? styles.toggle : styles.notToggle, className)}
                        onClick={() => {
                            if (value === undefined) {
                                setToggle(key)
                            }
                            if (key !== toggle) {
                                if (onClick !== undefined) {
                                    onClick()
                                }
                                onToggle(button)
                            }
                        }}
                    />
                )
            })}
        </Button.Group>
    )
}
ToggleButton.defaultProps = {
    onToggle: () => {
    }
}
