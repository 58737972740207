import React, { useCallback, useEffect } from 'react';
import styles from "./AddPurchaseView.module.scss"
import { Checkbox, Header, Modal, Button, Icon, Grid } from "semantic-ui-react";
import InputField from "../../shared/fields/InputField";
import { observer, useLocalStore } from "mobx-react-lite";
import moment from 'moment'
import cn from 'classnames'
import DateField from "../../shared/fields/DateField";
import Widget from "../../shared/Widget";
import ProcessViewButtons from "./ProcessViewButtons";
import AttachFileButton from "../../shared/AttachFileButton";

const AddResultSubmitView = observer((
    {
        open, purchase,
        onSaveOrEnd = () => null,
        onClose = () => null,
        isPreliminary = false
    }
) => {
    const defaultSave = {
        docDate: moment(),
        docNum: '',
        description: ""
    }

    const [showFile, setShowFile] = React.useState({ file: null, fileId: null, fileName: null })
    useEffect(() => {
        if (open) {
            ls.save = {
                description: purchase.resultDescription === null ? "" : purchase.resultDescription,
                docDate: purchase.resultDoc === null ? "" : moment(purchase.resultDoc),
                docNum: purchase.resultDocNum === null ? "" : purchase.resultDocNum
            }
            ls.file = {
                file: null,
                fileId: purchase.resultFileId,
                fileName: purchase.resultFileName
            }
        }
    }, [open])

    const ls = useLocalStore(() => ({
        save: defaultSave,
        file: { file: null, fileId: null, fileName: null }
    }))
    const closeHandler = () => {
        ls.save = defaultSave
        onClose()
    }

    return (
        <Modal open={open} size="tiny" className={cn(styles.component, "alt-semantic")} onClose={closeHandler}>
            <Widget
                header={isPreliminary ? "Предварительное завершение комиссии" : "Завершение комиссии"}
                footer={(
                    <ProcessViewButtons
                        disabledEnd={
                            ls.save.description === ""
                            || (ls.save.docDate ? !ls.save.docDate.isValid() : false)
                            || ls.save.docNum === ""
                            || (showFile.file === null && showFile.fileId === null)
                        }
                        showSave={false}
                        onClose={() => { closeHandler(); setShowFile({ file: null, fileId: null, fileName: null }) }}
                        onEnd={() => { onSaveOrEnd({ ...ls.save, isPreliminary, file: ls.file }, purchase, true); setShowFile({ file: null, fileId: null, fileName: null }) }}
                    />
                )}
            >
                <div className="add-properties">
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <InputField
                                    label="Комментарий"
                                    value={ls.save.description}
                                    inputClassName="input-underline"
                                    onChange={(e, { value }) => {
                                        ls.save.description = value
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <DateField
                                    label="Дата протокола" closable
                                    inputClassName="date-underline"
                                    date={ls.save.docDate || ""}
                                    onChange={(date) => {
                                        ls.save.docDate = date
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <InputField
                                    label="Номер протокола"
                                    inputClassName="input-underline"
                                    value={ls.save.docNum}
                                    onChange={(e, { value }) => {
                                        ls.save.docNum = value
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h4" content="Файл" />
                                <AttachFileButton
                                    isAttachAnotherFile={true}
                                    onAttached={(e, file) => {
                                        ls.file = { file, fileId: null, fileName: null }
                                        setShowFile({ file, fileId: null, fileName: null })
                                    }}
                                    file={showFile.file}
                                    fileId={showFile.fileId}
                                    fileName={showFile.fileName}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Widget>
        </Modal>
    );
})

export default AddResultSubmitView;
