import React, {useEffect, useLayoutEffect, useState} from 'react';
import style from './AssigneeChart.module.scss';
import {Pie} from '@ant-design/charts';
import {listLoader} from '../AnalyticsList/AnalyticsList'
import {analyticsState} from "../AnalyticsState";
import {useSnapshot} from "valtio";

export const AssigneeChart = ({analyticsData}) => {

    const {assigneeTasks} = analyticsData
    const [enableSelect, setEnableSelect] = useState(true)

    var config = {
        width: 250,
        appendPadding: 0,
        data: assigneeTasks,
        angleField: 'tasksCount',
        colorField: 'name',
        radius: 0.5,
        legend: false,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: `{name}`,
        },
        interactions: [],
        tooltip: {
            formatter: (datum) => {
                return {name: datum.name, value: datum.tasksCount + ` зад.`};
            },
        }
    };

    return (
        <>
            {assigneeTasks.length ? <div className={style.assigneeChartAria}>
                <Pie
                    {...config}
                    onEvent={(plot, e) => {
                        if (e.type === "plot:click" && e.data) {
                            if (document.querySelector('#listTopPosition')) {
                                document.querySelector('#listTopPosition').scrollIntoView()
                            }
                            listLoader(e.data.data.id, 'assigneeId', e.data.data, null, false, analyticsState.categoryId)
                        }
                    }}/>
            </div> : <div className={style.noData}>Нет задач</div>}
        </>)
}