import React, {useState, useEffect, useCallback} from 'react'
import {Map} from "react-leaflet";
import LayerSelector, {layerNames} from "../../shared/LayerSelector";
import styles from './BrigadesMap.module.scss'
import Control from 'react-leaflet-control'
import {Header, Segment} from "semantic-ui-react";
import BrigadeStats from "./BrigadeStats";
import appStyles from '../../css/App.module.scss'
import cn from 'classnames'
import DailyOrderChart from "./DailyOrderChart";
import ComponentMarker from "../../shared/ComponentMarker";
import PointInfo from "./PointInfo";
import AppModal from "../../shared/AppModal";
import OrderDetails from "./OrderDetails";
import zoomLevels from '../ZoomLevels'
import BackArrowButton from "../../shared/BackArrowButton";
import FullRotationDynamicChart from "./FullRotationDynamicChart";
import CollapsibleWrap from "../../shared/CollapsibleWrap";
import WorkStaffDetails from "./WorkStaffDetails";
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import {workerStatus} from "./enums";
import PersonDetails from "./PersonDetails";
import moment from 'moment'

const BrigadesMap = (
    {
        stats, dailyOrders, points, showPointDetailsOnClick, pointDetails, pointDetailsLoading,
        selectPointOnClick, dailyOrdersName, dailyOrdersLoading
    }
) => {
    const [mapRef, setMapRef] = useState(null)
    const [markers, setMarkers] = useState(null)
    const [zoomLevel, setZoomLevel] = useState(zoomLevels.road)
    const [orderDetailsOpen, setOrderDetailsOpen] = useState(false)
    const [showBackButton, setShowBackButton] = useState(false)
    const [isCollapse, setIsCollapse] = useState(false)
    const [workStaffDetailsOpen, setWorkStaffDetailsOpen] = useState(false)
    const [selectedPoint, setSelectedPoint] = useState(null)
    const [error, setError] = useState(null)
    const [personInfoOpen, setPersonInfoOpen] = useState(false)

    const [roadHistoryModal, setRoadHistoryModal] = useState({open: false, name: ''})
    const [depotHistoryModal, setDepotHistoryModal] = useState({open: false, name: ''})

    const [sendGetAvailableWorkersInfo, availableWorkersInfoLoading, availableWorkersInfo] = useApiMethod(ekasut.persData.getAvailableWorkersInfo, undefined, [],
        useCallback(({data}) => {
            return data.map(item => {
                const status = workerStatus.getById(item.sost)
                return {
                    asutrId: item.asutrId,                      // Уникальный табельный номер из АСУТР - точно идентифицирует человека
                    id: item.id,                                // Табельный номер из АСУТ
                    statusId: status.id,
                    statusName: status.text,
                    roadId: item.roadKod,
                    depotId: item.rd,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    patronymicName: item.patrName,
                    postId: item.post,
                    postName: item.postStr,
                    isPermit: item.dopusk,
                    isBusinessTrip: item.isKomandirovka,
                    firmId: item.kodFirm,
                    locTypeName: item.moveStr,
                    locTypeId: item.move,
                    regDepotName: item.depoName
                }
            })
        }, [])
    )

    const [sendGetPersonInfo, personInfoLoading, personInfo] = useApiMethod(ekasut.persData.getPersonInfo, undefined, {},
        useCallback(({data}) => {
            const toDate = (str) => {
                return typeof str !== 'string' ? undefined : moment(str)
            }

            return {
                fullName: data.fio,
                expDepartureRight: toDate(data.dopWorkPolygons), // exp - expiration
                expElectricalSafety: toDate(data.nextEb),
                expMedicalCommission: toDate(data.nextMed),
                expRepeatInOSHAndSP: toDate(data.nextTb), // SP - safety precautions, OSH - Occupational safety and health, In - instruction
                expOSHExm: toDate(data.checkOt), // Exm - examination
                expPsychophysiologicalExm: toDate(data.nextPsih),

                isDepartureRightExpired: data.dopWorkPolygonsExpired, // exp - expiration
                isElectricalSafetyExpired: data.nextEbExpired,
                isMedicalCommissionExpired: data.nextMedExpired,
                isRepeatInOHSAndSPExpired: data.nextTbExpired, // SP - safety precautions, OHS - Occupational safety and health, In - instruction
                isOHSExmExpired: data.checkOtExpired, // Exm - examination
                isPsychophysiologicalExmExpired: data.nextPsihExpired,

            }
        }, [])
    )

    const [sendGetRoadHistory, roadHistoryLoading, roadHistory] = useApiMethod(ekasut.planLb.getRoadHistory, undefined, [],
        ({data}) => {
            data.forEach(item => item.name = item.dorName)
            return data
        }
    )
    const [sendGetDepotHistory, depotHistoryLoading, depotHistory] = useApiMethod(ekasut.planLb.getDepoHistory, undefined, [],
        ({data}) => {
            data.forEach(item => item.name = item.depoName)
            return data
        }
    )

    const [sendGetBarriers, , , setBarriers] = useApiMethod(ekasut.checks.getStatistics, undefined, [],
        ({data}) => data.map(item => ({
            count: item.count,
            id: item.checkCdmvid,
            name: item.caption
        })
    ))

    useEffect(() => {
        sendGetBarriers()
    }, [sendGetBarriers])

    // Subscribe on zoom end
    useEffect(() => {
        if (mapRef === null) {
            return
        }
        mapRef.leafletElement.on('zoomend', () => {
            setZoomLevel(mapRef.leafletElement.getZoom() > 6 ? zoomLevels.depot : zoomLevels.road)
        })
        return () => {
            mapRef.leafletElement.off('zoomend')
        }
    }, [mapRef])

    // update markers
    useEffect(() => {
        const roadsOrDepots = zoomLevel === zoomLevels.road ? points.roads : points.depots
        const newMarkers = roadsOrDepots.map((point) => {
            return (
                <ComponentMarker
                    key={point.id}
                    position={[point.lat, point.lon]}
                >
                    <PointInfo
                        pointName={point.name}
                        {...point}
                        leadTimePlanOnClick={() => {
                            showPointDetailsOnClick(point.id, zoomLevel)
                            setOrderDetailsOpen(true)
                        }}
                        leadTimeActualOnClick={() => {
                            setBarriers([])
                            sendGetBarriers(point.id)
                            selectPointOnClick(point.id, point.name, zoomLevel)
                            setShowBackButton(true)
                        }}
                        planBrigadesCurrentDayOnClick={() => {
                            showPointDetailsOnClick(point.id, zoomLevel)
                            setOrderDetailsOpen(true)
                        }}
                        fullRotationActualOnClick={() => {
                            if (zoomLevel === zoomLevels.road) {
                                sendGetRoadHistory(point.id)
                                setRoadHistoryModal({open: true, name: point.name})
                            } else {
                                sendGetDepotHistory(point.id)
                                setDepotHistoryModal({open: true, name: point.name})
                            }
                        }}
                        workStaffOnClick={() => {
                            setWorkStaffDetailsOpen(true)
                            setSelectedPoint(point)
                            const roadId = zoomLevel === zoomLevels.road ? point.id : undefined
                            const depotId = zoomLevel === zoomLevels.depot ? point.id : undefined
                            sendGetAvailableWorkersInfo(roadId, depotId)
                        }}
                    />
                </ComponentMarker>
            )
        })
        setMarkers(newMarkers)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points, zoomLevel, selectPointOnClick, showPointDetailsOnClick, sendGetAvailableWorkersInfo])

    const resetMap = () => {
        mapRef.leafletElement.setView(mapInitSettings.center, mapInitSettings.zoom)
    }

    const workerOnClickHandler = useCallback((info) => {
        setPersonInfoOpen(true)
        sendGetPersonInfo(info.id /*, info.depotId*/)
            .catch(() => {
                setPersonInfoOpen(false)
            })
    }, [sendGetPersonInfo])

    return (
        <Map
            ref={(ref) => {
                // Save reference only once. Library will change it over time with null, but will back to old reference
                if (ref !== null && mapRef === null) {
                    setMapRef(ref)
                }
            }}
            className={appStyles.map}
            zoomControl={false}
            center={mapInitSettings.center}
            zoom={mapInitSettings.zoom}
            minZoom={mapInitSettings.minZoom}
            maxZoom={mapInitSettings.maxZoom}
        >
            <LayerSelector checkedLayerDef={layerNames.rzd}/>
            <Control position='topright'>
                <Segment className={cn(styles.dashboard, appStyles.backgroundTransitionNone)}>
                    <BrigadeStats {...stats} />
                    <CollapsibleWrap
                        title={dailyOrdersLoading ? 'Загрузка...' : dailyOrdersName}
                        onClick={() => setIsCollapse(!isCollapse)}
                        isCollapse={isCollapse}
                    >
                        <BackArrowButton
                            style={{
                                visibility: showBackButton ? 'visible' : 'hidden',
                                position: 'absolute',
                                zIndex: 11
                            }}
                            onClick={() => {
                                resetMap()
                                setBarriers([])
                                sendGetBarriers()
                                selectPointOnClick()
                                setShowBackButton(false)
                            }}
                        />
                        <DailyOrderChart {...dailyOrders} />
                        {/* todo: hidden chart until receive request to show it */}
                        {/* <BarrierFunctionChart barriers={barriers} /> */}
                    </CollapsibleWrap>
                </Segment>
            </Control>

            {markers}

            <AppModal
                loading={pointDetailsLoading}
                open={orderDetailsOpen}
                onClose={() => setOrderDetailsOpen(false)}
            >
                <Segment>
                    <OrderDetails
                        details={pointDetails[pointDetails.length - 1]}
                    />
                </Segment>
            </AppModal>

            <AppModal
                loading={roadHistoryLoading}
                open={roadHistoryModal.open}
                onClose={() => setRoadHistoryModal({open: false})}
            >
                <Segment>
                    <FullRotationDynamicChart
                        details={roadHistory}
                        name={roadHistoryModal.name}
                    />
                </Segment>
            </AppModal>

            <AppModal
                loading={depotHistoryLoading}
                open={depotHistoryModal.open}
                onClose={() => setDepotHistoryModal({open: false})}
            >
                <Segment>
                    <FullRotationDynamicChart
                        details={depotHistory}
                        name={depotHistoryModal.name}
                    />
                </Segment>
            </AppModal>

            <AppModal
                open={workStaffDetailsOpen}
                onClose={() => setWorkStaffDetailsOpen(false)}
                loading={availableWorkersInfoLoading}
                size={'large'}
                style={{top: '6%'}}
            >
                <Segment.Group>
                    <Segment>
                        <Header as={'h4'}>{selectedPoint !== null ? selectedPoint.name : ''}</Header>
                    </Segment>
                    <Segment>
                        <WorkStaffDetails
                            pointDetails={selectedPoint}
                            workersInfo={availableWorkersInfo}
                            workerOnClick={workerOnClickHandler}
                        />
                    </Segment>
                </Segment.Group>
            </AppModal>
            <AppModal
                open={personInfoOpen}
                onClose={() => setPersonInfoOpen(false)}
                loading={personInfoLoading}
                size={'small'}
            >
                <Segment.Group>
                    <Segment>
                        <Header as='h4'>
                            {personInfo.fullName}
                        </Header>
                    </Segment>
                    <Segment>
                        <PersonDetails
                            info={personInfo}
                        />
                    </Segment>
                </Segment.Group>
            </AppModal>
        </Map>
    )
}
export default BrigadesMap

const mapInitSettings = {
    center: [57.778288, 79.126642],
    zoom: 4,
    minZoom: 4,
    maxZoom: 16
}