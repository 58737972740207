import React, { useEffect, useState } from "react";
import {
  Icon,
  Grid,
  Header,
  Button,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import styles from "../OrganizationInspection.module.scss";
import InputField from "../../../../shared/fields/InputField";
import DropdownField from "../../../../shared/fields/DropdownField";
import AttachFileButton from "../../../../shared/AttachFileButton";
import { useLocalStore } from "mobx-react-lite";
import { render } from "react-dom";
import AddComMember from "./AddComMember";
import AddDocumentChanges from "./AddDocumentChanges";
import DateField from "../../../../shared/fields/DateField";
import apiWorker from "../../../../api/apiWorker";
import ekasut from "../../../../api/ekasut";
import { Planning } from "./GeneralInfoParts/Planning";
import { Docs } from "./GeneralInfoParts/Docs";
import { ComList } from "./GeneralInfoParts/ComList";

const GeneralInfo = ({
  pred,
  periodFilter,
  year,
  season,
  datesPlan,
  setDatesPlan,
}) => {
  const ls = useLocalStore(() => ({
    comList: [],
    document: {},
    items: [],
    profs: [],
    roleks: [],
  }));

  const [addComMember, setAddComMember] = useState(false);
  const [addChanges, setAddChanges] = useState(false);
  const [orderPeople, setOrderPeople] = useState([]);

  const getOrderPeople = async(orderUUID) => {
    const response = await ekasut.commisionInspection.getOrderPeople(orderUUID)
    setOrderPeople(response?.data)
  }

  const comList = [
    <Table.Row key={1}>
      <Table.Cell>Пред. комиссии</Table.Cell>
      <Table.Cell>Иванов И.И.</Table.Cell>
      <Table.Cell>Ведущий инжернер</Table.Cell>
      <Table.Cell>ТЧЭ-1</Table.Cell>
      <Table.Cell>Дизель</Table.Cell>
    </Table.Row>,
    <Table.Row key={2}>
      <Table.Cell>Зам. пред. комиссии</Table.Cell>
      <Table.Cell>Иванов К.И.</Table.Cell>
      <Table.Cell>Инжернер-технолог</Table.Cell>
      <Table.Cell>ТЧЭ-1</Table.Cell>
      <Table.Cell>Системы пожаротушения</Table.Cell>
    </Table.Row>,
  ];

  return (
    <div>
      <span className={styles.nameSpace}>
        Общие сведения об организации комиссионного осмотра
      </span>
      {localStorage.accessLevel == 1 || localStorage.accessLevel == 2 ? (
        ""
      ) : (
        <Planning
          periodFilter={periodFilter}
          year={year}
          season={season}
          datesPlan={datesPlan}
          setDatesPlan={setDatesPlan}
        />
      )}
      {localStorage.accessLevel == 7 ? (
        ""
      ) : (
        <>
          <Docs
            setAddChanges={setAddChanges}
            period={periodFilter}
            pred={pred}
            orderPeople={orderPeople}
            setOrderPeople={setOrderPeople}
          />
          <ComList
            comList={comList}
            setAddComMember={setAddComMember}
            orderPeople={orderPeople}
            setOrderPeople={setOrderPeople}
            getOrderPeople={getOrderPeople}
          />
        </>
      )}

      <AddComMember
        addComMember={addComMember}
        setAddComMember={setAddComMember}
        ls={ls}
        getOrderPeople={getOrderPeople}
      />
      <AddDocumentChanges
        addChanges={addChanges}
        setAddChanges={setAddChanges}
      />
    </div>
  );
};

export default GeneralInfo;
