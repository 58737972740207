import React from "react";
import {useLocalStore} from "mobx-react-lite";
import createStore from "./createStore";

export const storeContext = React.createContext()

export const StoreProvider = ({children, initOptions, inheritProps}) => {

    const store = useLocalStore(() => createStore(initOptions))
    store.inheritProps(inheritProps)

    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => (React.useContext(storeContext))
