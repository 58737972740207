import React, {useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import ekasut from "../../../../../../api/ekasut";
import {useHistory} from "react-router-dom";
import {getUsersTasks} from "../../../../dashboardsLogic/board";

export const DelTaskModal = ({openDel, setOpenDel, setShowIcons, taskdata}) => {

    const history = useHistory()

    const deleteTask = () => {
        ekasut.taskboard.deleteTask(taskdata.workGroupId, taskdata.taskNumber)
            .then(({data}) => {
                getUsersTasks({categoryId: 1, pageNumber: 1})
                setOpenDel(false)
                setShowIcons(false)
                history.push('/taskboard')
            })
    }

    return (
        <Modal
            size={'mini'}
            open={openDel}
            onClose={() => {
                setOpenDel(false)
                setShowIcons(false)
            }}
        >
            <Modal.Header>Удаление задачи</Modal.Header>
            <Modal.Content>
                <p>Вы действительно хотите удалить задачу: "<strong>{taskdata.title}</strong>"?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => {
                    setOpenDel(false)
                    setShowIcons(false)
                }}>
                    Нет
                </Button>
                <Button positive onClick={() => {
                    deleteTask()
                }}>
                    Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
};