import React, {useState} from 'react';
import style from './AssigneePopover.module.scss';
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from '@material-ui/icons/Person';
import {Popover} from "antd";

export const AssigneePopover = ({assignees}) => {

    const content = (
        <div>
            {assignees.map(e => (
                <p key={e.code}>{e.name}</p>
            ))}
        </div>
    )

    return (
        <Popover
            title={assignees.length > 1 ? 'Исполнители' : 'Исполнитель'}
            content={content}
            placement="left"
            trigger="hover"
            className={style.assigneePopover}>
            {assignees.length > 1 ? <GroupIcon style={{
                fontSize: '1.1em',
                color: '#a1a1a4'
            }}/> : <PersonIcon style={{
                fontSize: '1.1em',
                color: '#a1a1a4'
            }}/>}
            {assignees.length}
        </Popover>
    );
};