export const tableHeaders = {

    level1: {
        part1: [
            { name: '№', row: '5', col: '1' },
            { name: 'Дирекция', row: '5', col: '1' },
            { name: 'Ф.И.О. ТЧПЛ', row: '5', col: '1' },
            { name: 'Закрепленное депо', row: '5', col: '1' },
            { name: 'Рейтинг приёмщика', row: '5', col: '1' },
            { name: 'Количество случаев', row: '5', col: '1' },
            { name: 'Неплановых ремонтов', row: '5', col: '1' },
            { name: 'Приёмка локомотивов со всех видов ремонта (секции)', row: '1', col: '14' }],
        part2: [
            { name: 'Количество локомотивов, не принятых с 1 предъявления', row: '2', col: '3' },
            { name: 'Количество локомотивов, не принятых со 2 и более предъявлений', row: '2', col: '3' },
            { name: 'Приемка линейного оборудования', row: '5', col: '1' },
            { name: 'Количество запретов на некачественно отремонтированную продукцию', row: '5', col: '1' },
            { name: 'Количество отказавшего оборудования (принятого лично приемщиком)', row: '5', col: '1' }],
        part3: [
            { name: 'Выполнено проверок и выявлено замечаний', row: '1', col: '16' },
            { name: 'Количество приостановок работ в цехах по нарушению технологии ремонта', row: '5', col: '1' }],
        part4: [
            { name: 'Количество письменных предписаний', row: '1', col: '16' }]
    },
    level2: {
        part1: [
            { col: '4', row: '1', name: 'Всего секций, в т.ч.' },
            { col: '1', row: '4', name: 'ТО-2' },
            { col: '1', row: '4', name: 'ТО-3' },
            { col: '1', row: '4', name: 'ТО-4' },
            { col: '1', row: '4', name: 'ТО-5 в/и' },
            { col: '1', row: '4', name: 'ТР-1' },
            { col: '1', row: '4', name: 'ТР-2' },
            { col: '1', row: '4', name: 'ТР-3' },
            { col: '1', row: '4', name: 'СР' },
            { col: '1', row: '4', name: 'МОД' },
            { col: '1', row: '4', name: 'НЕПЛ, РЕКЛ' }],
        part3: [
            { col: '1', row: '4', name: 'Всего проверок:' },
            { col: '1', row: '4', name: 'Всего замечаний:' },
            { col: '2', row: '3', name: 'Соблюдение технологических процессов при производстве ТО и ремонта локомотивов' },
            { col: '2', row: '3', name: 'Контроль над организацией работ в ПТОЛ' },
            { col: '2', row: '3', name: 'Участие во входном контроле поступающих в СЛД узлов, оборудования локомотивов' },
            { col: '8', row: '1', name: 'В цехах' }],
        part4: [
            { col: '2', row: '1', name: 'ЯКУ-12' },
            { col: '2', row: '1', name: 'Предписания в СЛД' },
            { col: '2', row: '1', name: 'Всего' },
            { col: '7', row: '1', name: 'В том числе по причинам' },
            { col: '1', row: '4', name: 'Причины невыполнения предписаний (расписать каждое )' },
            { col: '1', row: '4', name: 'Количество привлечений к ответсвенности' },
            { col: '1', row: '4', name: 'Количество поощрений' }]
    },
    level3: {
        part1: [
            { col: '1', row: '3', name: 'Тепловозы' },
            { col: '1', row: '3', name: 'Маневровые' },
            { col: '1', row: '3', name: 'Электровозы' },
            { col: '1', row: '3', name: 'Всего Σ к.9 - к.18' }],
        part2: [
            { col: '1', row: '3', name: 'Всего секций' },
            { col: '1', row: '3', name: 'Тепловозы' },
            { col: '1', row: '3', name: 'Электровозы' },
            { col: '1', row: '3', name: 'Всего секций' },
            { col: '1', row: '3', name: 'Тепловозы' },
            { col: '1', row: '3', name: 'Электровозы' }],
        part3: [
            { col: '2', row: '2', name: 'Количество проверенных цехов и отделений' },
            { col: '1', row: '2', name: 'По соблюдению технологии' },
            { col: '2', row: '1', name: 'Контроль обеспечения процесса необходимыми зап. частями и оборудованием' },
            { col: '1', row: '2', name: 'По мерительному инструменту' },
            { col: '1', row: '2', name: 'По укомплектованию штата' },
            { col: '1', row: '2', name: 'Прочие' }],
        part4: [
            { col: '1', row: '3', name: 'Выдано' },
            { col: '1', row: '3', name: 'Исполнено' },
            { col: '1', row: '3', name: 'Выдано' },
            { col: '1', row: '3', name: 'Исполнено' },
            { col: '1', row: '3', name: 'Выдано' },
            { col: '1', row: '3', name: 'Исполнено' },
            { col: '1', row: '3', name: 'Невыполнение цикловых работ на соответствие нормативно-технической документации' },
            { col: '1', row: '3', name: 'Неисправность или отсутствие технологического оборудования в цехе, участке' },
            { col: '1', row: '3', name: 'Наличие документации на рабочем месте' },
            { col: '1', row: '3', name: 'Нарушения связанные с выполнением работ по неразрушающему контролю и диагностики' },
            { col: '1', row: '3', name: 'Низкая квалификация исполнителей работ' },
            { col: '1', row: '3', name: 'Отсутствие мерительного инструмента' },
            { col: '1', row: '3', name: 'Прочие' }]
    },
    level4: {
        part3: [
            { col: '1', row: '1', name: 'Зап. части' },
            { col: '1', row: '1', name: 'Оборудование' },
        ]
    },
    level5: {
        part3: [
            { col: '1', row: '1', name: 'Проверка' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Проверка' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Проверка' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Проверка' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '2', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Замечание' },
            { col: '1', row: '1', name: 'Замечание' },
        ]
    }
}