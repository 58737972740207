import { action, decorate, observable } from "mobx";

class LocomotivesConservationStore {
  departmentList = [];
  movementTypeList = [];
  pullTypeList = [];
  setDepartmentList = (list) => (this.departmentList = list);
  setMovementTypeList = (list) => (this.movementTypeList = list);
  setPullTypeList = (list) => (this.pullTypeList = list);

  isDepartmentListLoading = false;
  isMovementTypeListLoading = false;
  isPullTypeListLoading = false;
  setIsDepartmentListLoading = (isLoading) =>
    (this.isDepartmentListLoading = isLoading);
  setIsMovementTypeListLoading = (isLoading) =>
    (this.isMovementTypeListLoading = isLoading);
  setIsPullTypeListLoading = (isLoading) =>
    (this.isPullTypeListLoading = isLoading);

  ordersTable = {
    data: [],
    pageNumber: 1,
    pageSize: 20,
    totalPages: 0,
    totalRecords: 0,
  };
  isOrdersTableLoading = false;
  setOrdersTable = (list) => (this.ordersTable = list);
  setIsOrdersTableLoading = (isLoading) => {
    this.isOrdersTableLoading = isLoading;
  };
}
// todo: не забывай прописывать свойства в decorate!!!
decorate(LocomotivesConservationStore, {
  departmentList: observable,
  movementTypeList: observable,
  pullTypeList: observable,
  setDepartmentList: action,
  setMovementTypeList: action,
  setPullTypeList: action,

  isDepartmentListLoading: observable,
  isMovementTypeListLoading: observable,
  isPullTypeListLoading: observable,
  setIsDepartmentListLoading: action,
  setIsMovementTypeListLoading: action,
  setIsPullTypeListLoading: action,

  ordersTable: observable,
  isOrdersTableLoading: observable,
  setOrdersTable: action,
  setIsOrdersTableLoading: action,
});

const locomotivesConservationStore = new LocomotivesConservationStore();

export default locomotivesConservationStore;
