import React from 'react'
import RoadMaintenancePerHour from './RoadMaintenancePerHour';

export default function SwitcherPerHour({stats}) {

	const nepCategories = [
		'Деповский ремонт',
		'Неплановый ремонт',
		'Заводской ремонт',
		'Рекламация',
		'Перес, и тех. операции',
		'Ожидание исключения',
	]

	const nrpCategories = [
		'ТО-2, экипировка',
		'Перемещение',
		'Ожидание работы по станции',
		'Ожидание работы по депо',
		'В операциях с лок. бригадами',
		'Операции резерв ЦД'
	]

	const nepData = [
		stats.kol33Diff,
		stats.kol34Diff,
		stats.kol38Diff,
		stats.kol40Diff,
		stats.kol42Diff,
		stats.kol43Diff,
	]

	const nrpData = [
		stats.kol23Diff,
		stats.kol25Diff,
		stats.kol26Diff,
		stats.kol27Diff,
		stats.kol29Diff,
		stats.kol28Diff,
	]

	const nepValue = stats.kol30Diff
	const nrpValue = stats.kol23Diff

	return (
		<RoadMaintenancePerHour
			nepCategories={nepCategories}
			nrpCategories={nrpCategories}
			nepData={nepData}
			nrpData={nrpData}
			nepValue={nepValue}
			nrpValue={nrpValue}
			nepHeight={280}
			nrpHeight={280}
		/>
	)
}
