import React from 'react';
import {Button} from "semantic-ui-react";
import useFileInput from "../../hooks/useFileInput";
import './index.scss'
import useFileDownload from "../../hooks/useFileDownload";
import {observer} from "mobx-react-lite";
import ekasut from "../../api/ekasut";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import cn from 'classnames'
import {usePrompt} from "../../globalStore/useGlobalStore";

/** Button for download & attach file.<br/>
 * Notes:
 *  - Doesn't allow attach file with size more than 30MB.
 *  - Work either with file or fileId
 * @param isAttachAnotherFile - allow attach another file if file already selected
 * @param fileId - file id for remote file, require fileName
 * @param fileName - file name for fileId
 * @param file - local file
 * @param onAttached - after user selected local file
 * */
const AttachFileButton = observer((
    {onAttached = () => null, file = null, fileId = null, fileName = null, className, isAttachAnotherFile = true}
) => {

    const [InputFile, getUserFile] = useFileInput()
    const [download, InputDownload] = useFileDownload()
    const prompt = usePrompt()

    const attachFile = () => {
        getUserFile((e) => {
            const file = e.target.files[0]
            if (file) {
                // restrict file size 30Mb
                if (file.size > 3e7) {
                    prompt.show(`Не удалось выбрать файл`, `Размер файла "${file.name}" превышает допустимый (30МБ)`, {ok: true})
                } else {
                    onAttached(e, file)
                }
            }
        })
    }

    const getFileName = () => {
        return file ? file.name : fileName
    }

    const downloadFile = () => {
        const name = getFileName()
        if (file) {
            download(file, name)
        } else if (fileId) {
            ekasut.file.getFile(fileId)
                .then(({data}) => {
                    download(data, name)
                })
                .catch(catchNetworkErrors)
        }
    }

    return (
        <div className={cn("app attach-file-button", className)}>
            <InputFile/>
            <InputDownload/>
            {(file !== null || fileId !== null) ? (
                <Button
                    basic
                    content={file ? file.name : fileName}
                    className="attached-button"
                    icon={isAttachAnotherFile ? "attach" : undefined}
                    labelPosition="right"
                    onClick={(e) => {
                        if (e.target.classList.contains("attach")) {
                            attachFile()
                        } else {
                            downloadFile()
                        }
                    }}
                />
            ) : (
                <Button
                    content="Прикрепить"
                    onClick={() => {
                        attachFile()
                    }}
                    basic icon="attach" labelPosition="right"
                />
            )}
        </div>
    )
})

export default AttachFileButton;