import React, {useEffect, useState, useCallback} from 'react'
import ReactDOM from 'react-dom'
import ComponentMarker from '../../../shared/ComponentMarker';
import ekasut from '../../../api/ekasut'
import Control from 'react-leaflet-control'
import {Segment, Divider, Button, Icon} from 'semantic-ui-react'
import TractionFilterSelector from '../TractionFilterSelector';
import FleetStats from '../FleetStats';
import ProgressBarWithPopup from '../../../shared/ProgressBarWithPopup';
import FailureChartsAndModal from './FailureChartsAndModal';
import './TechFailuresLayout.scss'
import PointModal from './PointModal';
import TimeRangeSelector, {timeRanges, getFromTo} from '../TimeRangeSelector'
import CollapsibleWrap from '../../../shared/CollapsibleWrap';
import {useZoomLevel, zoomLevels} from '../ZoomLevel';
import TableForPopup from "../TableForPopup";
import ModalLocURL from '../ModalLocUrl'
import useDidFirstRender from "../../../hooks/useDidFirstRender";
import catchNetworkErrors from "../../../api/catchNetworkErrors";
// Styles in polygon ratings

export default function TechFailuresLayout({zoom, resetMap, stats, onLoad, onLoaded}) {
    // --- States ---
    const zoomLevel = useZoomLevel(zoom, 5)
    const [timeRange, setTimeRange] = useState(getTimeRangeFromHash(window.location.hash))
    const [time, setTime] = useState(getFromTo(timeRange))
    const [points, setPoints] = useState({roads: [], depots: []})
    const [tractionFilter, setTractionFilter] = useState({ac: true, dc: true, ice: true})
    const [markers, setMarkers] = useState([])

    const [isShowPointsModal, setIsShowPointsModal] = useState(false)
    const [pointsModalDetails, setPointsModalDetails] = useState([])
    const [dashboard, setDashboard] = useState({})
    const [zoomLevelSelected, setZoomLevelSelected] = useState(zoomLevels.ROADS)
    const [pointIdSelected, setPointIdSelected] = useState(-1)
    const [pointNameSelected, setPointNameSelected] = useState(null)
    const [pointName, setPointName] = useState(null)
    const [chartsTitle, setChartsTitle] = useState(text.CHARTS_LOADING)
    const [isMinimizeChart, setIsMinimizeChart] = useState(false)
    const [detailsShowChart, setDetailsShowChart] = useState(false)
    const [error, setError] = useState(null)

    useEffect(function isShowLineChartInModalDetails() {
        setDetailsShowChart(timeRange !== timeRanges.DAY)
    }, [timeRange])

    useEffect(function updateMarkers() {
        let newMarkers = []

        let arr = zoomLevel === zoomLevels.ROADS ? points.roads : points.depots
        for (let i = 0; i < arr.length; i++) {
            let point = arr[i]

            const findCatGrValue = (point, catName) => {
                let catGr = point.catGr.find((catGr) => catGr.name === catName)
                return catGr === undefined ? undefined : catGr.value
            }
            let roadName = point.name
            let progressBarColor
            if (point.hasProgress === null) {
                progressBarColor = '#aaaaaa'
            } else {
                progressBarColor = point.hasProgress ? '#21ba45' : '#db2828'
            }
            newMarkers.push(
                <ComponentMarker
                    position={[point.lat, point.lon]}
                    key={point.name}
                    relX={-25}
                    relY={-25}
                >
                    <ProgressBarWithPopup
                        leftValue={point.count}
                        filled={true}
                        value={<Icon name='chart bar'/>}
                        color={progressBarColor}
                        progressClassName='pr-tech-failures-layout-progress'
                        popupClassName='pr-tech-failures-layout-popup'
                        popupContent={
                            <TableForPopup
                                title={roadName}
                                isLoading={false}
                                data={[
                                    {
                                        text: 'Категория 1',
                                        value: findCatGrValue(point, '1'),
                                        unit: 'отк.'
                                    },
                                    {
                                        text: 'Категория 2',
                                        value: findCatGrValue(point, '2'),
                                        unit: 'отк.'
                                    },
                                    {
                                        text: 'Категория 3',
                                        value: findCatGrValue(point, '3'),
                                        unit: 'отк.'
                                    },
                                    {
                                        text: 'Норматив',
                                        value: point.delta,
                                        unit: 'отк.'
                                    }
                                ]}
                            />
                        }
                        leftValueOnClick={() => {
                            ReactDOM.unstable_batchedUpdates(() => {
                                setPointName(roadName)
                                setIsShowPointsModal(true)
                                loadRoadDetails(point.id, roadName)
                            })
                        }}
                        valueOnClick={() => {
                            ReactDOM.unstable_batchedUpdates(() => {
                                setDashboard({})
                                setZoomLevelSelected(zoomLevel)
                                setPointIdSelected(point.id)
                                setPointNameSelected(roadName)
                                setIsMinimizeChart(false)
                                loadDashboard(tractionFilter, time.from, time.to, zoomLevel, roadName, point.id)
                            })
                        }}
                    />
                </ComponentMarker>
            )
        }
        setMarkers(newMarkers)
    }, [zoomLevel, points])

    useDidFirstRender(() => {
        onLoad()
        Promise.all([
            loadDashboard(tractionFilter, time.from, time.to, zoomLevel),
            loadPoints(tractionFilter, time.from, time.to)
        ]).finally(onLoaded)
    })


    const loadDashboard = (filter, from, to, zoomLevel, pointName, pointId) => {
        /*api.kasantAnalytics.cancel()*/
        if (!filter.ac && !filter.dc && !filter.ice) {
            setDashboard({})
            return Promise.resolve()
        }

        setChartsTitle(text.CHARTS_LOADING)
        if (pointId === -1 || (pointId === undefined)) {
            return ekasut.kasantAnalytics.getRzdDashboard(from, to, filter)
                .then(response => {
                    setDashboard(response.data)
                    setChartsTitle('Отказы по сети')
                }).catch(catchNetworkErrors)
        } else {
            let apiGetDashboard = zoomLevel === zoomLevels.ROADS ?
                () => ekasut.kasantAnalytics.getRoadDashboard(pointId, from, to, filter) :
                () => ekasut.kasantAnalytics.getDepoDashboard(pointId, from, to, filter)

            return apiGetDashboard().then(response => {
                setDashboard(response.data)
                if (pointName) {
                    setChartsTitle('Отказы: ' + pointName)
                } else {
                    setChartsTitle('Нет данных')
                }
            }).catch(catchNetworkErrors)
        }
    }

    const loadPoints = (filter, from, to) => {
        if (!filter.ac && !filter.dc && !filter.ice) {
            setMarkers([])
            return
        }

        setChartsTitle(text.CHARTS_LOADING)
        setPoints({
            roads: [],
            depots: []
        })
        /*api.kasantAnalytics.cancel()*/
        return Promise.all([
            ekasut.kasantAnalytics.getRoads(from, to, filter),
            ekasut.kasantAnalytics.getDepos(from, to, filter)
        ]).then(results => {
            setPoints({
                roads: results[0].data,
                depots: results[1].data
            })
        }).catch(catchNetworkErrors)
    }

    const loadRoadDetails = (pointId) => {
        /*api.kasantAnalytics.cancel()*/
        let apiGetDetails
        let apiGetRoadDetails
        let apiGetDepoDetails
        if (timeRange === timeRanges.DAY) {
            apiGetRoadDetails = ekasut.kasantAnalytics.getRoadDetails
            apiGetDepoDetails = ekasut.kasantAnalytics.getDepoDetails
            apiGetDepoDetails = ekasut.kasantAnalytics.getDepoDetails
        } else {
            apiGetRoadDetails = ekasut.kasantAnalytics.getRoadHistoryDetails
            apiGetDepoDetails = ekasut.kasantAnalytics.getDepoHistoryDetails
        }
        if (zoomLevel === zoomLevels.ROADS) {
            apiGetDetails = apiGetRoadDetails
        } else {
            apiGetDetails = apiGetDepoDetails
        }
        setPointsModalDetails([])
        apiGetDetails(pointId, time.from, time.to, tractionFilter)
            .then(response => {
                setPointsModalDetails(response.data)
            }).catch(catchNetworkErrors)
    }

    const handleTimeRangeChange = (value, from, to) => {
        window.location.hash = '#' + value
        setTimeRange(value)
        setDashboard({})
        setTime({from, to})
        onLoad()
        Promise.all([
            loadDashboard(tractionFilter, from, to, zoomLevelSelected, pointNameSelected, pointIdSelected),
            loadPoints(tractionFilter, from, to)
        ]).finally(onLoaded)
    }

    const handleFilterChanged = (filter) => {
        setDashboard({})
        setTractionFilter(filter)
        onLoad()
        Promise.all([
            loadDashboard(filter, time.from, time.to, zoomLevelSelected, pointNameSelected, pointIdSelected),
            loadPoints(filter, time.from, time.to)
        ]).finally(onLoaded)
    }

    const returnBackToRzd = () => {
        setZoomLevelSelected(zoomLevels.ROADS)
        setPointIdSelected(-1)
        setDashboard({})
        setPointNameSelected(null)
        resetMap()
        loadDashboard(tractionFilter, time.from, time.to, zoomLevel)
            .finally(onLoaded)
    }

    const handlePointModalClose = useCallback(() => {
        setIsShowPointsModal(false)
    }, [])

    return (
        <>
            <PointModal
                isShow={isShowPointsModal}
                onClose={handlePointModalClose}
                details={pointsModalDetails}
                isLineChart={detailsShowChart}
                from={time.from}
                to={time.to}
                pointName={pointName}
                tractionFilter={tractionFilter}
            />

            <Control position='topright'>
                <Segment className='half-transparent-background pr-tech-failures-layout-control'>
                    <TimeRangeSelector
                        onChange={handleTimeRangeChange}
                        timeRange={timeRange}
                    />

                    <Divider/>
                    <FleetStats
                        stats={stats}
                    />

                    <CollapsibleWrap title={chartsTitle}
                                     isCollapse={isMinimizeChart}
                                     onClick={(isCollapse) => setIsMinimizeChart(!isCollapse)}>

                        {pointIdSelected !== -1 &&
                        <Button icon labelPosition='left' size='mini' basic className='return-back-to-rzd'
                                onClick={returnBackToRzd}>
                            Назад
                            <Icon name='left arrow'/>
                        </Button>
                        }

                        <FailureChartsAndModal dashboard={dashboard}/>

                        <Divider/>
                    </CollapsibleWrap>

                    <TractionFilterSelector
                        filter={tractionFilter}
                        onChange={handleFilterChanged}
                        groupElectricLocomotives={false}
                    />
                </Segment>
            </Control>

            {markers}
            <ModalLocURL onError={setError}/>
        </>
    )
}

// How to fix highchart #15 error:
//    write axis x values to categories (not in series)

// I don't put constants inside functional component, because reference to object
// will change after each render and react will show warning if i using this inside useEffect
// it will shows that i don't put it inside sensitivy array. But if i put it will cause infinite re render
// to fix that i can use useMemo, use global vars, attach vars to function or don't use this constant inside useEffect
const text = {
    CHARTS_LOADING: 'Загрузка...'
}

const getTimeRangeFromHash = (hash) => {
    // remove first # symbol
    hash = hash.substring(1)
    switch (hash) {
        case 'day': {
            return timeRanges.DAY
        }
        case 'month': {
            return timeRanges.MONTH
        }
        case 'year': {
            return timeRanges.YEAR
        }
        default: {
            return timeRanges.DAY
        }
    }
}


