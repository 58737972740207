import React, { useEffect, useState } from 'react'
import { Pagination } from "semantic-ui-react";

export default function usePagination(list, rowsPerPage, defaultActivePage) {
    const totalPages = Math.ceil(list.length / rowsPerPage)
    const [activePage, setActivePage] = useState(defaultActivePage)
    const [pageFromList, setPageFromList] = useState(getPage(list, rowsPerPage, defaultActivePage))

    useEffect(() => {
        setPageFromList(getPage(list, rowsPerPage, activePage))
    }, [activePage, list, rowsPerPage])

    const pagination = () => (
        <Pagination
            totalPages={totalPages}
            activePage={activePage}
            onPageChange={(e, { activePage }) => {
                setActivePage(activePage)
            }}
        />
    )
    return [pagination, pageFromList]
}

const getPage = (list, rowsPerPage, activePage) => {
    const from = rowsPerPage * (activePage - 1)
    const to = from + rowsPerPage
    return list.slice(from, to)
}