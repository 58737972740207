import React, {useState, useCallback} from 'react'
import {Accordion, Icon, Grid, Header, Table, Segment, Divider} from 'semantic-ui-react'
import styles from './DepotDetails.module.scss'
import cn from "classnames";
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import AppModal from "../../shared/AppModal";
import ArmDetails from './ArmDetails'
import PersDataByBrigadeType from './PersDataByBrigadeType'
import LabeledInput from "../../shared/LabeledInput";

/*
info: {
    kodFirm, depotId, depoName, lat, lon, isRestHome, moskDif,
    allCount, countGruz, countHoz, countMan, countPass, mashPass,
    pomPass, mashGruz, pomGruz, mashMan, pomMan, mashHoz, pomHoz,
}
 */
const DepotDetails = ({info}) => {
    const [activeIndex, setActiveIndex] = useState(null)
    const [armInfoOpen, setArmInfoOpen] = useState(false)
    const [persDataInfoOpen, setPersDataInfoOpen] = useState(false)
    const [brigadeType, setBrigadeType] = useState(null)
    const [findSeries, setFindSeries] = useState("")

    const [sendGetArmInfo, armInfoLoading, armInfo] = useApiMethod(ekasut.checks.getArm, undefined, null,
        useCallback(({data}) => {
            setArmInfoOpen(true)
            return data
        }, [])
    )
    const [sendGetPersDataInfo, persDataInfoLoading, persDataInfo] = useApiMethod(ekasut.persData.getWorkersInfo, undefined, null,
        useCallback(({data}) => {
            setPersDataInfoOpen(true)
            return data
        }, [])
    )
    const [sendGetSerInfo, , serInfo] = useApiMethod(ekasut.checks.getSer, undefined, null)
    const [sendGetArmsInfo, , armsInfo] = useApiMethod(ekasut.checks.getArms, undefined, null)


    let handleClick = (e, titleProps) => {
        const {index} = titleProps
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex)

        if (index === 0 && serInfo === null && typeof serInfo === "object") {
            sendGetSerInfo(info.depotId)
        }
        if (index === 3 && armsInfo === null && typeof armsInfo === "object") {
            sendGetArmsInfo(info.depotId)
        }
    }

    let getSeriesGridRows = () => {
        if (!serInfo || serInfo.length === 0) {
            return null
        } else {
            // will find string contain findSeries value with case insensitive
            const regExp = new RegExp(findSeries, 'i')
            const filteredSerInfo = serInfo.filter(s => regExp.test(s.serName))

            if (filteredSerInfo.length === 0) {
                return (
                    <Grid.Row>
                        <Grid.Column width={16}>
                            Не найдено ни одной серии
                        </Grid.Column>
                    </Grid.Row>
                )
            }

            let i, j, temparray, chunk = initSettings.serColumnsPerRow, finalGridParts = [];
            for (i = 0, j = filteredSerInfo.length; i < j; i += chunk) {
                temparray = filteredSerInfo.slice(i, i + chunk);
                finalGridParts.push(temparray)
            }

            return finalGridParts.map((x, index) => (
                <Grid.Row key={index}>
                    {x.map((y, i) => (
                        <Grid.Column key={i} style={{color: 'black'}}>{y.serName}</Grid.Column>
                    ))}
                </Grid.Row>
            ))
        }
    }

    let brigadeTypesHelper = (depotInfo) => {
        let brigadeTypes = [
            {
                key: 'mashPass',
                name: 'Машинистов пассажирского движения (МП)',
                move: '1',
                post: '1',
                isMash: true
            },
            {
                key: 'pomPass',
                name: 'Помощников пассажирского движения (ПП)',
                move: '1',
                post: '2',
                isMash: false
            },
            {
                key: 'mashGruz',
                name: 'Машинистов грузового+выводного движения (МГ+В)',
                move: '3,5',
                post: '1',
                isMash: true
            },
            {
                key: 'pomGruz',
                name: 'Помощников грузового+выводного движения (ПГ+В)',
                move: '3,5',
                post: '2',
                isMash: false
            },
            {
                key: 'mashMan',
                name: 'Машинистов маневрового движения (ММ)',
                move: '4',
                post: '1',
                isMash: true
            },
            {
                key: 'pomMan',
                name: 'Помощников маневрового движения (ПМ)',
                move: '4',
                post: '2',
                isMash: false
            },
            {
                key: 'mashHoz',
                name: 'Машинистов хоз. движения (МХ)',
                move: '6',
                post: '1',
                isMash: true
            }
        ]

        return brigadeTypes.map((x, index) => (
            <Table.Row key={index}
                       className={cn(styles.selectedBrigadeType)}
                       onClick={() => {
                           setBrigadeType({...x, count: depotInfo[x.key]})
                           sendGetPersDataInfo(null, info.kodFirm, x.move, null, x.isMash)
                       }}
            >
                <Table.Cell>{x.name}</Table.Cell>
                <Table.Cell>{depotInfo[x.key]}</Table.Cell>
            </Table.Row>
        ))
    }

    return (
        <>
            <Header as='h2'>{info.depoName}</Header>

            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h3'>Комната отдыха - {info.isRestHome ? 'Есть' : 'Нет'}</Header>
                    </Grid.Column>
                    <Grid.Column>
                        <Header as='h3' textAlign="right">Разница с МСК - {info.moskDif}</Header>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />
            <Accordion className={styles.accordion}>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleClick}
                >
                    <Icon name='dropdown'/>
                    Обслуживаемые серии
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                    <LabeledInput
                        label="Найти серию "
                        value={findSeries}
                        icon="filter"
                        onChange={(e, {value}) => setFindSeries(value)}
                    />
                    <Grid columns={initSettings.serColumnsPerRow} celled className={styles.seriesGrid}>
                        {getSeriesGridRows(serInfo)}
                    </Grid>
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    onClick={handleClick}
                >
                    <Icon name='dropdown'/>
                    Количество работников ЛБ
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                    <Table>
                        <Table.Body>
                            {brigadeTypesHelper(info)}
                        </Table.Body>
                    </Table>
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 2}
                    index={2}
                    onClick={handleClick}
                >
                    <Icon name='dropdown'/>
                    Количество явок за прошлые сутки ({info.allCount})
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Количество явок в пассажирском движении</Table.Cell>
                                <Table.Cell>{info.countPass}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Количество явок в грузовом и выводном движениях</Table.Cell>
                                <Table.Cell>{info.countGruz}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Количество явок в маневровом движении</Table.Cell>
                                <Table.Cell>{info.countMan}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>Количество явок в хозяйственном. движении</Table.Cell>
                                <Table.Cell>{info.countHoz}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Accordion.Content>

                <Accordion.Title
                    active={activeIndex === 3}
                    index={3}
                    onClick={handleClick}
                >
                    <Icon name='dropdown'/>
                    Плечи
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Отправление</Table.HeaderCell>
                                <Table.HeaderCell>Прибытие</Table.HeaderCell>
                                <Table.HeaderCell>Контрольная</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {armsInfo
                                ? armsInfo.map(i => (
                                    <Table.Row style={{cursor: 'pointer'}} onClick={() => {
                                        sendGetArmInfo(i.stOtp, i.stPrib, i.stCtl)
                                    }}>
                                        <Table.Cell>{i.stOtpName}</Table.Cell>
                                        <Table.Cell>{i.stPribName}</Table.Cell>
                                        <Table.Cell>{i.stCtlName}</Table.Cell>
                                    </Table.Row>
                                ))
                                : null}
                        </Table.Body>
                    </Table>
                </Accordion.Content>
            </Accordion>

            <AppModal
                open={armInfoOpen}
                onClose={() => setArmInfoOpen(false)}
                loading={armInfoLoading}
                size='fullscreen'
            >
                <ArmDetails armInfo={armInfo}/>
            </AppModal>

            <AppModal
                open={persDataInfoOpen}
                onClose={() => setPersDataInfoOpen(false)}
                loading={persDataInfoLoading}
                size='large'
            >
                <Segment>
                    <PersDataByBrigadeType
                        depoName={info.depoName}
                        brigadeType={brigadeType}
                        persDataInfo={persDataInfo}
                    />
                </Segment>

            </AppModal>
        </>
    )
}
export default DepotDetails

const initSettings = {
    serColumnsPerRow: 5
}