import React from 'react';
import style from './HeaderLocoDetailsNew.module.scss';
import {Button, Icon} from "semantic-ui-react";
import LocoDetailsHeaderList from "./LocoDetailsHeaderList";

export const HeaderLocoDetailsNew = ({info, setAddProt, trainInfo, changeActiveItem}) => {
  const photo = false

  return (
    <div className={style.header}>
      <div className={style.wrapper}>
        <div className={style.avatar}>
          {photo ? <img
              alt='avatar'
              src='https://lh3.googleusercontent.com/proxy/mMB1vRqcPOyzeB9VUWiUIVJYloCYjZGO3iSFT6sdC89oaVyS8SuJvc0GsXwfT3emFgOhyEKrnFeIk7Z4IfpMzm3c9P5ctzicvBf9RgZR2Oxla-C_nA'/> :
            <div className={style.addPhoto}><Icon name={'plus'}/><span>Добавить</span><span>фото</span></div>}
        </div>
        <LocoDetailsHeaderList fullLoco={info} changeActiveItem={changeActiveItem} trainInfo={trainInfo}/>
      </div>
      <div className={style.depotInfo}>
        <Button className={style.addProtBtn} onClick={() => {
          setAddProt(true)
        }}>Добавить протокол</Button>
      </div>
    </div>
  )
}