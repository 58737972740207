import React from 'react'
import styles from './BrigadeStats.module.scss'
import cn from 'classnames'
import {Popup} from "semantic-ui-react";
import appStyles from '../../css/App.module.scss'
import RangeCircleBar from "../../shared/RangeCircleBar";
import moment from 'moment'
import {formatRotation, isDeclineWithinPercent} from "./functions";

/**
 * @param props - props
 * @param props.fullRotationPlan - Полный оборот (норма)
 * @param props.fullRotationActual - Полный оборот (факт)
 * @param props.rotationDecline - Превышение нормы оборота
 * @param props.timeAsPassenger - Время следования пассажиром
 * @param props.leadTimeActual - Выполнение заказа Д по факту
 * @param props.leadTimePlan - Выполнение заказа Д по плану
 * @param props.leadTimeDecline - Отклонение от плана
 */
const BrigadeStats = (
    {
        leadTimeActual, timeAsPassenger, rotationDecline, fullRotationActual,
        fullRotationPlan, leadTimePlan, leadTimeDecline
    }
) => {

    return (
        <div>
            <div className={styles.row}>
                <div className={cn(styles.cell, styles.title)}>
                    Выполнение заказа Д
                </div>
                <div className={cn(styles.cell, styles.title)}>
                    Время следования пассажиром
                </div>
                <div className={cn(styles.cell, styles.title)}>
                    Превышение нормы оборота
                </div>
            </div>
            <div className={styles.row}>
                <Popup
                    className={appStyles.popupFixWidth}
                    basic
                    trigger={
                        <div className={styles.cell}>
                            <PlanFactBar
                                fact={leadTimeActual}
                                plan={leadTimePlan}
                                description={'ЛБ'}
                            />
                        </div>
                    }
                    content={
                        <>
                            Заказ Д: {leadTimePlan}<br/>
                            Уехало: {leadTimeActual}<br/>
                            Отклонение: {leadTimeDecline}
                        </>
                    }
                />
                <div className={styles.cell}>
                    <RangeCircleBar
                        value={timeAsPassenger}
                        description={'Часов'}
                        color={'#db2828'}
                        min={0}
                        max={1000}
                        from={0}
                        to={timeAsPassenger}
                    />
                </div>
                <Popup
                    className={appStyles.popupFixWidth}
                    basic
                    trigger={
                        <div className={styles.cell}>
                            <RotationBar
                                fact={fullRotationActual}
                                plan={fullRotationPlan}
                                description={'Часов'}
                            />
                        </div>
                    }
                    content={
                        <div>
                            Полный оборот (норма): {formatRotation(fullRotationPlan)}<br/>
                            Полный оборот (факт): {formatRotation(fullRotationActual)}<br/>
                            Отклонение от нормы оборота: {rotationDecline}
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default BrigadeStats
BrigadeStats.defaultProps = {
    timeAsPassenger: null,
    fullRotationActual: null,
    fullRotationPlan: null,
    leadTimeActual: null,
    leadTimePlan: null
}



const RotationBar = ({plan, fact, description}) => {
    // not clear way, but
    const startOfDay = moment().startOf('day')
    // Ideally i want to set clear the same year month and day that automatically set in startOfDay and vars below
    // But it seems to work fine, because moment().isSame(moment()) === true
    let planSeconds
    if (plan === null) {
        planSeconds = null
    } else {
        planSeconds = moment(plan, 'HH:mm:ss').diff(startOfDay, 'seconds')
    }
    let factSeconds
    if (fact === null) {
        factSeconds = null
    } else {
        factSeconds = moment(fact, 'HH:mm:ss').diff(startOfDay, 'seconds')
    }

    return (
        <PlanFactBar
            plan={planSeconds}
            fact={factSeconds}
            description={description}
            value={formatRotation(fact)}
            secondValue={formatRotation(plan)}
        />
    )
}

const PlanFactBar = ({plan, fact, description, value, secondValue}) => {

    if (value === undefined) {
        value = fact
    }
    if (secondValue === undefined) {
        secondValue = plan
    }

    return (
        <RangeCircleBar
            min={plan === null ? -100 : 0}
            max={plan === null ? 100 : plan * 2}

            from={plan === null ? 0 : plan}
            to={fact}

            value={value}
            secondValue={secondValue}

            description={description}


            color={isDeclineWithinPercent(5, plan, fact) ? '#21ba45' : '#db2828'}
        />
    )
}