import React, {useEffect, useMemo} from 'react';
import StoreProvider, {useStore} from "./StoreProvider";
import cn from 'classnames'
import styles from './index.module.scss'
import {Button, Grid, Header, Modal, Popup, Table} from "semantic-ui-react";
import appStyles from '../../../css/App.module.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import LabeledDropdown from "../../../shared/LabeledDropdown";
import moment from 'moment'
import {createArrayFromTo, downloadBlob, formatMoment} from "../../../helpers/utils";
import JustifyContainer from "../../../shared/JustifyContainer";
import TableRowsLoader from "../../../shared/TableRowsLoader";
import {dateFormats} from "../../../Dictionaries";
import AddRepair from "../components/AddRepair";
import EditRepair from "../components/EditRepair";
import auth from "../../../helpers/auth";
import Chat from "../components/Chat";
import ekasut from "../../../api/ekasut";
import {toJS} from "mobx";

const DecadePlan = () => {
    return (
        <div className={cn(styles.decadePlan)}>
            <Grid>
                <Grid.Column width={12} className={appStyles.verticalGapNorm}>
                    <DepotDecadeForm/>
                    <JustifyContainer justifyContent="space-between" gapRight="large">
                        <JustifyContainer justifyContent="flex-start" gapRight="norm">
                            <RefreshButton/>
                            <DownloadExcelButton/>
                        </JustifyContainer>
                        <JustifyContainer justifyContent="flex-start" gapRight="norm">
                            <AssignedPlanButton/>
                            <EditButtons/>
                        </JustifyContainer>
                    </JustifyContainer>
                    <PlanHeader/>
                    <PlanTable/>
                    <JustifyContainer justifyContent="flex-end" gapRight="norm">
                        <AssignTcheButton/>
                        <AssignSldButtons/>
                    </JustifyContainer>
                </Grid.Column>
                <Grid.Column width={4}>
                    <PlanChat/>
                </Grid.Column>
            </Grid>
            <AddPlanModal/>
            <EditPlanModal/>
            <ApprovedPlanModal/>
        </div>
    )
}

const Index = () => {
    return (
        <StoreProvider>
            <DecadePlan/>
        </StoreProvider>
    )
}

const statuses = {
    predefined: 0, // Отсутствует / Не назначен / null
    assignedTche: 1, // Подписан ТЧЭ
    acceptedSld: 2, // Согласован СЛД
    rejectedSld: 3, // Отклонен СЛД
    adjustment: 4, // Корректировка
}

const DepotDecadeForm = observer(() => {
    const store = useStore()
    // {tche: {id, name}, sld: {id, name}, decade: moment}
    const values = store.depotDecadeForm
    const monthOptions = useMemo(
        () => createArrayFromTo(0, 11, m => ({text: m + 1, value: m})),
        []
    )
    const yearOptions = useMemo(
        () => createArrayFromTo(
            moment().year() - 2,
            moment().year() + 2,
            y => ({text: y, value: y})
        ),
        []
    )

    useEffect(() => {
        store.fetchTcheOptionsIfNeed()
        store.fetchSldOptionsIfNeed()
    }, [store])

    return (
        <Grid columns='equal' className={styles.depotDecadeForm}>
            <Grid.Row>
                <Grid.Column>
                    <LabeledDropdown
                        withoutContainer
                        fluid
                        label="ТЧЭ"
                        search selection
                        options={store.tcheOptions.slice()}
                        loading={store.tcheOptionsLoading}
                        value={values.tche.id}
                        onChange={(e, {value}) => {
                            const tche = store.tcheOptions.find(v => v.key === value)
                            values.tche = {
                                id: tche.key,
                                name: tche.text
                            }
                        }}
                    />
                </Grid.Column>
                <Grid.Column>
                    <LabeledDropdown
                        label="СЛД"
                        search selection fluid
                        options={store.sldOptions.slice()}
                        loading={store.sldOptionsLoading}
                        value={values.sld.id}
                        onChange={(e, {value}) => {
                            const sld = store.sldOptions.find(v => v.key === value)
                            values.sld = {id: sld.key, name: sld.text}
                        }}
                    />
                </Grid.Column>
                <Grid.Column>
                    <LabeledDropdown
                        label="Декада" fluid
                        options={[
                            {text: 1, value: 1},
                            {text: 2, value: 11},
                            {text: 3, value: 21}
                        ]}
                        value={values.date.date()}
                        onChange={(e, {value}) => {
                            values.date = moment(values.date).date(value)
                        }}
                    />
                </Grid.Column>
                <Grid.Column>
                    <LabeledDropdown
                        label="Месяц"
                        fluid
                        options={monthOptions}
                        value={values.date.month()}
                        onChange={(e, {value}) => {
                            values.date = moment(values.date).month(value)
                        }}
                    />
                </Grid.Column>
                <Grid.Column>
                    <LabeledDropdown
                        label="Год"
                        fluid
                        options={yearOptions}
                        value={values.date.year()}
                        onChange={(e, {value}) => {
                            values.date = moment(values.date).year(value)
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
})

const RefreshButton = observer(() => {
    const store = useStore()

    return (
        <Button
            content="Обновить"
            disabled={store.locomotivesLoading}
            loading={store.locomotivesLoading}
            onClick={() => {
                if (store.depotDecadeForm.isValid()) {
                    store.refreshTable()
                }
            }}
        />
    )
})

const DownloadExcelButton = observer(() => {
    const store = useStore()

    return (
        <Button
            content="Excel"
            disabled={
                store.excelLoading || !store.plan.isValid() ||
                store.locomotives.locomotives.length === 0 || store.locomotivesLoading
            }
            loading={store.excelLoading}
            onClick={() => {
                store.fetchExcel(store.plan.startDate, store.plan.tche.id, store.plan.sld.id, false)
                    .then(response => {
                        if (response.status !== 200) {
                            return;
                        }
                        downloadBlob("locomotive-detail", response.data)
                    }).catch(() => {})
            }}
        />
    )
})

const AssignedPlanButton = observer(() => {
    const store = useStore()
    const show = store.locomotives.wasApproved && !store.locomotivesLoading

    return show && (
        <Button
            basic
            content="Показать согласованный план"
            onClick={() => {
                store.showApprovePlanModal = true

            }}
        />
    )
})

const EditButtons = observer(() => {
    const store = useStore()
    const statusId = store.locomotives.statusId

    let show = store.plan.isValid() &&
        (auth.getIsTche() && (statusId === statuses.predefined || statusId === statuses.adjustment || statusId === statuses.rejectedSld))

    return (show && <>
        <Button
            content="Добавить"
            disabled={store.locomotivesLoading || store.removeLocomotiveLoading}
            onClick={() => {
                store.showAddModal = true
            }}
        />
        <Button
            content="Редактировать"
            disabled={store.plan.selected === null || store.removeLocomotiveLoading}
            onClick={() => {
                store.showEditModal = true
            }}
        />
        <Button
            content="Удалить"
            loading={store.removeLocomotiveLoading}
            disabled={store.removeLocomotiveLoading || store.plan.selected === null}
            onClick={() => {
                store.fetchRemoveLocomotive(store.plan.selected.locoPlanId)
                    .then(store.refreshTable).catch(() => {})
            }}
        />
    </>)
})

const AssignTcheButton = observer(() => {
    const store = useStore()
    const statusId = store.locomotives.statusId
    const showAssign = auth.getIsTche() && !store.locomotivesLoading &&
        (statusId === statuses.rejectedSld || statusId === statuses.predefined || statusId === statuses.adjustment)
    const showCorrect = (auth.getIsTche() || auth.getIsSld()) && !store.locomotivesLoading &&
        (statusId === statuses.acceptedSld)

    return showAssign ? (
        <Button
            content="Подписать"
            primary
            loading={store.setPlanStatusLoading}
            disabled={store.locomotives.locomotives.length === 0 || store.setPlanStatusLoading || store.locomotivesLoading}
            onClick={() => {
                store.fetchSetPlanStatus(store.plan.startDate, store.plan.tche.id, store.plan.sld.id, statuses.assignedTche)
                    .then(store.refreshTable).catch(() => {})
            }}
        />
    ) : showCorrect && (
        <Button
            content="Корректировка"
            basic color="red"
            loading={store.setPlanStatusLoading}
            disabled={store.locomotives.locomotives.length === 0 || store.setPlanStatusLoading || store.locomotivesLoading}
            onClick={() => {
                store.fetchSetPlanStatus(store.plan.startDate, store.plan.tche.id, store.plan.sld.id, statuses.adjustment)
                    .then(store.refreshTable).catch(() => {})
            }}
        />
    )
})

const AssignSldButtons = observer(() => {
    const store = useStore()
    const statusId = store.locomotives.statusId
    const show = store.plan.isValid() && !store.locomotivesLoading &&
        (auth.getIsSld() && statusId === statuses.assignedTche)

    return show && (
        <>
            <Button
                content="Принять"
                positive
                disabled={store.setPlanStatusLoading}
                onClick={() => {
                    store.fetchSetPlanStatus(store.plan.startDate, store.plan.tche.id, store.plan.sld.id, statuses.acceptedSld)
                        .then(store.refreshTable).catch(() => {})
                }}
            />
            <Button
                content="Отклонить"
                negative
                disabled={store.setPlanStatusLoading}
                onClick={() => {
                    store.fetchSetPlanStatus(store.plan.startDate, store.plan.tche.id, store.plan.sld.id, statuses.rejectedSld)
                        .then(store.refreshTable).catch(() => {})
                }}
            />
        </>
    )
})

const PlanHeader = observer(() => {
    const store = useStore()

    return (
        <Header
            content={store.plan.tcheSldIsValid() ?
                `${store.plan.tche.name}, ${store.plan.sld.name}` :
                "ТЧЭ-СЛД не выбрано"
            }
            subheader={
                store.plan.datesIsValid() ?
                    `${store.plan.startDate.format(dateFormats.upToDay)} - 
                        ${store.plan.endDate.format(dateFormats.upToDay)}` :
                    "Неверный формат даты"
            }
        />
    )
})

const PlanTable = observer(() => {
    const store = useStore()

    return store.plan.isValid() && (
        <LocomotivesTable
            locomotives={store.locomotives}
            loading={store.locomotivesLoading}
            selectedRow={store.plan.selectedRow}
            onSelectRow={(index, value) => {
                store.plan.selectedRow = index
                store.plan.selected = value
            }}
        />
    )
})

const LocomotivesTable = ({locomotives, loading, selectedRow, onSelectRow}) => {
    return (
        <Table selectable celled compact unstackable>
            <Table.Header>
                {locomotives.statusName &&
                <Table.Row>
                    <Table.HeaderCell colSpan={8} style={{textAlign: 'center'}}>
                        {'Статус: ' + locomotives.statusName}
                    </Table.HeaderCell>
                </Table.Row>
                }
                <Table.Row>
                    <Table.HeaderCell>п/п</Table.HeaderCell>
                    <Table.HeaderCell>Подразделение</Table.HeaderCell>
                    <Table.HeaderCell>Серия</Table.HeaderCell>
                    <Table.HeaderCell>Номер / Кол-во секций</Table.HeaderCell>
                    <Table.HeaderCell>Место проведения Сервисного обслуживания (ремонтное
                        предприятие)</Table.HeaderCell>
                    <Table.HeaderCell>Пробег на дату составления</Table.HeaderCell>
                    <Table.HeaderCell>Вид ремонта</Table.HeaderCell>
                    <Table.HeaderCell>Дата постановки (число)</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <TableRowsLoader
                    loading={loading}
                    colSpan={8}
                    messageIfLoading="Загрузка"
                    messageIfEmpty="На эту декаду ничего не запланировано"
                >
                    {/* API return object that contains array of locomotives */}
                    {locomotives.locomotives.map((item, index) => (
                        <Table.Row
                            style={{cursor: 'pointer'}}
                            active={index === selectedRow}
                            key={index}
                            onClick={() => {
                                onSelectRow(index, {...locomotives.locomotives[index]})
                            }}
                        >
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{item.depoPripName}</Table.Cell>
                            <Table.Cell>{item.serName}</Table.Cell>
                            <Popup
                                content={item.secCnt !== 0 ? "Количество секций" : "Номер локомотива"}
                                trigger={
                                    <Table.Cell>
                                        {item.secCnt !== 0 ? item.secCnt : `${item.locNum}${item.prsCharLoc ? item.prsCharLoc : ''}`}
                                    </Table.Cell>
                                }
                            />
                            <Table.Cell>{item.planRepPredName}</Table.Cell>
                            <Table.Cell>{item.probeg}</Table.Cell>
                            <Table.Cell>{item.repName}</Table.Cell>
                            <Table.Cell>{formatMoment(item.repDatePlan, dateFormats.upToDay)}</Table.Cell>
                        </Table.Row>
                    ))}
                </TableRowsLoader>
            </Table.Body>
        </Table>
    )
}
LocomotivesTable.defaultProps = {
    onSelectRow: () => {
    },
    selectedRow: null
}

const PlanChat = observer(() => {
    const store = useStore()

    // todo: Чат не обновляется при смене декады, думаю Chat стоит сделать контролируемым
    return (
        <Chat
            date={store.plan.startDate}
            updateEveryMs={1000}
            tcheId={store.plan.tche.id}
            sldId={store.plan.sld.id}
            disabled={!store.plan.tcheSldIsValid()}
            apiGetNewMessages={(date, tcheId, sldId, lastMsgId) => (
                ekasut.planRepairs.getChat(date, tcheId, sldId, lastMsgId, 1)
            )}
            apiPostMessage={(date, tcheId, sldId, text) => (
                ekasut.planRepairs.postChat(date, tcheId, sldId, 1, text)
            )}
        />
    )
})

const AddPlanModal = observer(() => {
    const store = useStore()

    useEffect(() => {
        if (store.showAddModal) {
            store.fetchRepairTypesIfNeed()
            store.fetchSeriesIfNeed()
        }
    }, [store, store.showAddModal])

    return (
        <Modal
            open={store.showAddModal}
            closeIcon style={{top: '6%'}}

            onClose={() => {
                store.showAddModal = false
            }}
        >
            <Modal.Content>
                <Modal.Description>
                    <AddRepair
                        tche={store.plan.tche}
                        sld={store.plan.sld}
                        initialDate={moment().isBefore(store.plan.startDate) ?
                            moment(store.plan.startDate) :
                            moment().add(1, "hours").minutes(0)
                        }
                        minDate={store.plan.startDate}
                        maxDate={store.plan.endDate}
                        repairTypes={store.repairTypes.map(t => ({text: t.text, id: t.key}))}
                        series={store.series.map(s => ({text: s.text, id: s.key}))}
                        locomotives={store.searchLocomotives}
                        locomotivesLoading={store.searchLocomotivesLoading}
                        searchLocomotives={(seriesId, locNumber, sectionsCount, tcheId) => {
                            store.fetchSearchLocomotives(seriesId, locNumber, sectionsCount, tcheId)
                        }}
                        addToPlan={(tche, sld, locs, repairType, series, secCount, date) => {
                            const locoData = {
                                planDate: date.format("YYYY-MM-DDTHH:mm"),
                                repairTypeId: repairType.id,
                                secCount: repairType.id !== 15 ? 0 : secCount,
                                planRepairPredKod: sld.id,
                                locomotives: repairType.id !== 15 ?
                                    locs.map(loc => ({
                                        repDate: date.format("YYYY-MM-DDTHH:mm"),
                                        locSer: loc.serLoc,
                                        locNum: loc.znsLoc,
                                        locSec: loc.prsLoc
                                    })) : [{
                                        repDate: date.format("YYYY-MM-DDTHH:mm"),
                                        locSer: series.id,
                                        locNum: 0,
                                        locSec: 0
                                    }]
                            }
                            store.fetchAddLocomotives(store.plan.startDate, tche.id, sld.id, locoData)
                                .then(() => {
                                    store.showAddModal = false
                                    store.refreshTable()
                                }).catch(() => {})
                        }}
                        addToPlanLoading={store.addLocomotivesLoading}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
})


const EditPlanModal = observer(() => {
    const store = useStore()
    const selPlan = store.plan.selected

    const ls = useLocalStore(() => ({
        date: moment(),
        repairType: {id: null, name: ""}
    }))

    const TO_2 = 15

    useEffect(() => {
        if (store.showEditModal && selPlan) {
            ls.date = moment(selPlan.repDatePlan)
            ls.repairType = {id: selPlan.repType, name: selPlan.repName}
            if (ls.repairType.id !== TO_2) {
                store.fetchRepairTypesIfNeed()
            }
        }
    }, [toJS(store.showEditModal)])

    return (
        <Modal
            open={store.showEditModal}
            closeIcon size='small' style={{top: '6%'}}
            onClose={() => store.showEditModal = false}
        >
            <Modal.Content>
                <Modal.Description>
                    {selPlan && (
                        <EditRepair
                            loading={store.addLocomotiveLoading}
                            date={ls.date}
                            dateOnChange={((date) => ls.date = date)}
                            startDate={store.plan.startDate}
                            endDate={store.plan.endDate}
                            sld={{
                                id: selPlan.planRepPredId,
                                name: selPlan.planRepPredName
                            }}
                            repairType={ls.repairType}
                            repairTypes={ls.repairType.id !== TO_2 ?
                                // Pass to repair type dropdown all types except TO_2
                                store.repairTypes.filter(rt => rt.key !== TO_2) :
                                // Disable repair type dropdown if repairType is TO_2
                                undefined}
                            repairTypeOnChange={(repairType) => ls.repairType = repairType}
                            series={{
                                id: selPlan.locSer,
                                name: selPlan.serName
                            }}
                            sections={{count: selPlan.secCnt}}
                            onSave={(sld, repairType, date, sections, series) => {
                                store.fetchAddLocomotive(
                                    store.plan.startDate,
                                    selPlan.locoPlanId,
                                    {
                                        planDate: date.format(),
                                        repairTypeId: repairType.id,
                                        secCount: sections.count,
                                        locSer: series.id,
                                        locNum: selPlan.locNum,
                                        locSec: selPlan.prsLoc,
                                        probeg: selPlan.probeg,
                                        planRepairPredKod: sld.id,
                                    }
                                )
                                    .then(() => {
                                        store.showEditModal = false
                                        store.refreshTable()
                                    })
                                    .catch(() => {
                                    })
                            }}
                        />
                    )}
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
})

const ApprovedPlanModal = observer(() => {
    const store = useStore()

    useEffect(() => {
        if (store.showApprovePlanModal) {
            store.approvedLocomotives = {locomotives: []}
            store.fetchApprovedLocomotives(store.plan.startDate, store.plan.tche.id, store.plan.sld.id, true)
        }
    }, [store, store.showApprovePlanModal])

    return (
        <Modal
            closeIcon
            open={store.showApprovePlanModal}
            size='large'
            basic
            onClose={() => store.showApprovePlanModal = false}
        >
            <Modal.Content>
                <Modal.Description>
                    <LocomotivesTable
                        locomotives={store.approvedLocomotives}
                        loading={store.approvedLocomotivesLoading}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
})

export default Index;