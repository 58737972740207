import React, {useState, useEffect} from 'react'
import moment from "moment";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

/***
 * @param props - props
 * @param props.fact - Фактическое количетсов ЛБ
 * @param props.plan - Количество ЛБ по заказу Д
 */
const DailyOrderChart = ({fact, plan}) => {
    const [options, setOptions] = useState(initOptions)

    useEffect(() => {
        const factData = []
        const planData = []
        // fact length and plan length should be equal
        for (let i = 0; i < fact.length || i < plan.length; i++) {
            const planValue = plan[i].value
            const factValue = fact[i].value
            const planDate = plan[i].data.valueOf()
            const factDate = fact[i].data.valueOf()
            factData.push({
                x: factDate,
                y: factValue,
                fact: factValue,
                plan: planValue
            })
            planData.push({
                x: planDate,
                y: planValue,
                fact: factValue,
                plan: planValue
            })
        }
        const addOptions = {
            series: [
                {
                    name: 'Заказ Д',
                    data: planData,
                    color: '#414141',
                },
                {
                    name: 'Уехало',
                    data: factData,
                    color: '#59b4ff'
                }
            ]
        }
        setOptions(Highcharts.merge(initOptions, addOptions))
    }, [fact, plan])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}
export default DailyOrderChart

const initOptions = {
    title: {
        text: 'Выполнение суточного наряд заказа'
    },
    chart: {
        type: 'line',
        backgroundColor: 'rgba(0,0,0,0)',
        height: 350,
    },
    exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    xAxis: {
        title: {
            text: 'Время'
        },
        type: 'datetime',
        labels: {
            formatter: function () {
                return moment(this.value).format('HH:mm');
            },
            rotation: 0 // -34
        },
    },
    yAxis: {
        title: {
            text: 'Количество ЛБ'
        },
    },
    tooltip: {
        formatter: function () {
            return (
                `Заказ Д: ${this.point.plan} ед.<br />
                 Уехало: ${this.point.fact} ед.<br />
                 Дата: ${moment(this.x).format('DD.MM.YYYY HH:mm')}`
            )
        },
    }
}