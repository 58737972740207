

const barrierFunctions = {
  '1': "Замеры бандажей колёсных пар в ходе ТО/ТР",
  '2': "Замеры бандажей колёсных пар за 30 суток",
  '3': "Сроки поверок УБ",
  '4': "Наличие УБ для работы в одно лицо",
  '5': "Наличие Акта технического состояния",
  '6': "Наличие УБ для работы в два лица",
  '7': "Наличие предписаний ФГП ВО ЖДТ",
}
export function sliceFetchData (data) {

  const slicedFetchData = []

  for (const el of data) {
    if (el.checkType < 8) {
      const checkTypeName = barrierFunctions[el.checkType]
      const newElement = {...el, key: el.checkType, checkTypeName}
      slicedFetchData.push(newElement)
    }
  }

  return slicedFetchData
}