import React from "react";
import { observer } from "mobx-react-lite";
import { Grid, Header, Label, Message } from "semantic-ui-react";
import { dateFormats } from "../../Dictionaries";
import moment from "moment";
import styles from "./OnBoardDiagnostic.module.scss";
import { toJS } from "mobx";

const OnBoardDiagnostic = observer(({ tab }) => {
  const diagnostic = tab.onBoardDiagnostic;

  const getColorAndStatus = (value) => {
    if (value) {
      if (typeof value === "string") {
        return {
          statusColor: "yellow",
          status: `Зафиксировано ${moment(value).format(
            dateFormats.upToSeconds
          )}`,
        };
      } else {
        if (value[1]) {
          return {
            statusColor: "yellow",
            status: `Зафиксировано ${moment(value[1]).format(
              dateFormats.upToSeconds
            )}`,
          };
        }
        if (value[2]) {
          return {
            statusColor: "red",
            status: `Датчик требует проверки или обновления ПО ${moment(
              value[2]
            ).format(dateFormats.upToSeconds)}`,
          };
        }
      }
      return {
        statusColor: "red",
        status: `Зафиксировано ${moment(value).format(
          dateFormats.upToSeconds
        )}`,
      };
    }

    return {
      statusColor: "",
      status: "Нет данных",
    };
  };

  return tab.onBoardDiagnosticMessage !== undefined ? (
    <Message content={tab.onBoardDiagnosticMessage} />
  ) : (
    <>
      <Grid padded className={styles.grid}>
        <GridRowWithDiagnostic
          name="Срабатывание защит в цепи КВ"
          {...getColorAndStatus(diagnostic.kvOn)}
        />
        <GridRowWithDiagnostic
          name="Пониженное давление масла"
          {...getColorAndStatus(diagnostic.oilLow)}
        />
        <GridRowWithDiagnostic
          name="Сброс нагрузки"
          {...getColorAndStatus(diagnostic.loadDrop)}
        />
        <GridRowWithDiagnostic
          name="Переход на аварийное возбуждение"
          {...getColorAndStatus(diagnostic.emergencyExcitement)}
        />
        <GridRowWithDiagnostic
          name="Срабатывание защиты по максимальному току ВУ, внутреннему КЗ и тормозному току"
          {...getColorAndStatus(diagnostic.currentProtection)}
        />
        <GridRowWithDiagnostic
          name="Срабатывание пожарной сигнализации"
          {...getColorAndStatus(diagnostic.fireAlarm)}
        />
        <GridRowWithDiagnostic
          name="Отклонение температуры масла на выходе из дизеля"
          {...getColorAndStatus(diagnostic.oilTemp)}
        />
        <GridRowWithDiagnostic
          name="Отклонение температуры воды в горячем контуре охлаждения"
          {...getColorAndStatus(diagnostic.waterTemp)}
        />

        <Grid.Row>
          <span style={{ fontStyle: "italic", fontSize: "12px" }}>
            Данные с борта собраны за трое суток.
          </span>
        </Grid.Row>
      </Grid>
    </>
  );
});

const GridRowWithDiagnostic = ({ name, statusColor, status }) => {
  return (
    <Grid.Row columns={2}>
      <Grid.Column>
        <Header as="h5">{name}</Header>
      </Grid.Column>
      <Grid.Column>
        {statusColor === "" ? (
          status
        ) : (
          <Label color={statusColor}>{status}</Label>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

export default OnBoardDiagnostic;
