import locomotivesConservationStore from "./locomotivesConservationStore";
import ekasut from "../../api/ekasut";

export const isConservationFieldValid = (name, value) => {
  if (name === "currentQuantity") {
    return /^\d+$/.test(value);
  } else {
    return !!String(value).trim();
  }
};

export const getOrders = (pageNumber) => {
  locomotivesConservationStore.setIsOrdersTableLoading(true);
  ekasut.locomotivesConservation
    .getOrdersTable(pageNumber)
    // new Promise(res => setTimeout(() => res([]), 1000))
    .then((res) => locomotivesConservationStore.setOrdersTable(res.data))
    .finally(() => locomotivesConservationStore.setIsOrdersTableLoading(false));
};
