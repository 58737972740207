import React, {PureComponent} from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import {Icon, Table, Menu} from 'semantic-ui-react'
import {Segment, Button} from 'semantic-ui-react'
import {Popup, Input, Dropdown} from 'semantic-ui-react'
import {Form} from 'semantic-ui-react'
import {Modal, Grid, Header} from 'semantic-ui-react'
import {Statistic} from 'semantic-ui-react'
import {Progress} from 'semantic-ui-react'
import FloatButton from "../../shared/FloatButton";
import SkeletonPage from "../../shared/SkeletonPage/SkeletonPage";

import * as moment from 'moment';
import 'moment/locale/ru';

import {waitRep, motionModeOptions, mileageOptions} from '../../Dictionaries'
import ekasut from "../../api/ekasut";

class TransferToTr extends React.Component {
    state = {
        activePage: 1,
        modalOpen: false,
        errorOccured: false,
        sectionsResp: [],
        isLoading: false,
        modalLoading: false,
        params: {
            hasFails: false
        },
        series: [],
        depos: [],
        disloc: [],
        status: [],
        repairDepos: [],
        repairTypes: [],
        filterShow: false,
        allTable: [],
        onPageLimit: 100
    }

    park = null // filter

    constructor(props) {
        super(props);
        this.filterClear = this.filterClear.bind(this);
        this.filterStateChange = this.filterStateChange.bind(this);
        this.submitFilters = this.submitFilters.bind(this);
        this.overMileageFilters = this.overMileageFilters.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    filterStateChange() {
        this.setState({filterShow: !this.state.filterShow})
    }

    filterClear() {
        this.setState({sectionsResp: this.state.allTable, params: {}, onPageLimit: 100})
    }

    componentDidMount() {
        const me = this;
        let params = {};

        document.querySelector('.content-container').addEventListener('scroll', () => {
            if (document.querySelector('.content-container').scrollTop + document.querySelector('.content-container').clientHeight >= document.querySelector('.content-container').scrollHeight) {
                this.setState({onPageLimit: this.state.onPageLimit + 100})
            }
        })

        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
        me.setState({isLoading: true})

        ekasut.oldReqs.repairReport(queryString)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        me.setState({isLoading: false, errorOccured: true, errorCode: response.status});
                        return;
                    }
                    me.setState({
                        sectionsResp: response.data,
                        allTable: response.data,
                        isLoading: false,
                        disloc: response.data.map(e => e.dislocName).filter((item, pos) => {
                            return response.data.map(e => e.dislocName).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                        status: response.data.map(e => e.sostName).filter((item, pos) => {
                            return response.data.map(e => e.sostName).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                        depos: response.data.map(e => e.depoPripName).filter((item, pos) => {
                            return response.data.map(e => e.depoPripName).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                        series: response.data.map(e => e.locSerName).filter((item, pos) => {
                            return response.data.map(e => e.locSerName).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                    });
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err.response.data.message});
            });
    }


    getDepoInfo(id) {
        var me = this;

        me.setState({modalLoading: true})

        ekasut.oldReqs.depoMap(id)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }
                    me.setState({depoInfo: response.data, modalLoading: false, modalOpen: true});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({modalLoading: false, errorOccured: true, errorCode: err.response.data.message});
            });
    }

    handleCloseModal = () => this.setState({modalOpen: false})

    overMileageFilters(typeOverMileage) {
        let array = this.state.sectionsResp
        array = array.filter(e => e.probRepName === typeOverMileage)
        this.setState({sectionsResp: array})
    }

    submitFilters() {
        const me = this;
        const {params} = this.state;
        let merged = {...params};
        let arrToFilter = me.state.allTable

        if (!merged.locNum && !merged.locSerName && !merged.dislocName && !merged.depoPripName && !merged.sostName) {
            me.setState({sectionsResp: me.state.allTable})
        }

        if (merged.locNum) {
            arrToFilter = arrToFilter.filter(e => e.locNum == merged.locNum)
            me.setState({sectionsResp: arrToFilter})
        }

        if (merged.locSerName && merged.locSerName.length > 0) {
            if (merged.locSerName.length > 1) {
                let arr = []
                for (let i = 0; i < merged.locSerName.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.locSerName === merged.locSerName[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            } else {
                arrToFilter = arrToFilter.filter(e => e.locSerName === merged.locSerName[0])
                me.setState({sectionsResp: arrToFilter})
            }
        }

        if (merged.dislocName && merged.dislocName.length > 0) {
            if (merged.dislocName.length > 1) {
                let arr = []
                for (let i = 0; i < merged.dislocName.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.dislocName === merged.dislocName[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            }
            arrToFilter = arrToFilter.filter(e => e.dislocName === merged.dislocName[0])
            me.setState({sectionsResp: arrToFilter})
        }

        if (merged.depoPripName && merged.depoPripName.length > 0) {
            if (merged.depoPripName.length > 1) {
                let arr = []
                for (let i = 0; i < merged.depoPripName.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.depoPripName === merged.depoPripName[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            }
            arrToFilter = arrToFilter.filter(e => e.depoPripName === merged.depoPripName[0])
            me.setState({sectionsResp: arrToFilter})
        }

        if (merged.sostName && merged.sostName.length > 0) {
            if (merged.sostName.length > 1) {
                let arr = []
                for (let i = 0; i < merged.sostName.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.sostName === merged.sostName[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            }
            arrToFilter = arrToFilter.filter(e => e.sostName === merged.sostName[0])
            me.setState({sectionsResp: arrToFilter})
        }

    }

    changePage(event, data) {
        this.setState({activePage: data.activePage})
    }

    stallHelper = (stalls) => {
        const stallColors = ['green', 'closed', 'grey', 'yellow', 'red'];

        return stalls.map((stall, j) => {
            if (stall.title || stall.description) {
                let prepare = stall.title.split(/\r\n/gi).map((el, i) => <p key={Date.now() + i}>{el}</p>)
                return (
                    <Modal key={j} trigger={<Grid.Column style={{cursor: 'pointer', fontSize: '0.75em'}}
                                                         color={stallColors[stall.state]}>{prepare}</Grid.Column>}>
                        <Modal.Header>{prepare}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {stall.description}
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>)
            } else {
                return <Grid.Column color={stallColors[stall.state]}>{stall.num}</Grid.Column>
            }
        })
    }

    // fill paddings in 'less stalls' ditches
    fillEmptiness = (length) => {
        let table = []

        for (let i = 0; i < length; i++) {
            table.push(<Grid.Column key={Date.now() + i} className='empty'>&nbsp;</Grid.Column>)
        }
        return table
    }

    closeErrorModal = () => this.setState({errorOccured: false})

    permissionDocumentRenderer(parameter, date, docNumber) {
        let value = null;
        switch (parameter) {
            case 0:
                value = <p>Отсутствует</p>;
                break;
            case 1:
                value = <p>Просрочен {moment(date).format("DD.MM.YYYY")}</p>;
                break;
            case 2:
                value = <p>{docNumber} до {moment(date).format("DD.MM.YYYY")}</p>;
                break;
            default:
                break;
        }

        return value
    }

    svpsFailsRenderer(fail) {
        let result = ''

        const svpsIndicator = {
            '1': 'Срабатывание защит КВ',
            '3': 'Пониженное давление масла',
            '4': 'Сброс нагрузки',
            '5': 'Переход на аварийное возбуждение',
            '6': 'Срабатывание защиты по макс.току',
            '7': 'Срабатывание пожарной сигнализации',
            '8': 'Отклонение t масла на выходе из дизеля',
            '9': 'Отклонение t воды в горячем контуре охлаждения'
        };

        result = svpsIndicator[fail.svpsIndicatorId]

        if (fail.svpsIndicatorValue === 2) {
            switch (fail.svpsIndicatorId) {
                case 8:
                    result = 'Датчик t масла требует поверки или обновления ПО';
                    break;
                case 9:
                    result = 'Датчик t воды требует поверки или обновления ПО';
                    break;
                default:
                    break;
            }
        }

        return result
    }

    /*set park from children component*/
    handleToUpdate(someArg) {
        this.park = someArg
    }

    render() {
        var me = this,
            lis = [];

        if (this.state.sectionsResp.length > 0) {
            var target = this.state.sectionsResp.slice((this.state.activePage - 1) * this.state.onPageLimit, this.state.activePage * this.state.onPageLimit)

            target.forEach(function (item, index) {
                var rerunLevel = null;
                var warningType = null;

                switch (item.probType) {
                    case 1:
                        warningType = {borderRadius: '5px', background: '#eedfa0'}
                        break;
                    case 2:
                        warningType = {borderRadius: '5px', background: '#f09a9a'}
                        break;
                    case 3:
                        warningType = {borderRadius: '5px', background: '#a3c293'}
                        break;
                    default:
                        break;
                }

                var section = `${item.locNum} ${item.locSec}`;

                lis.push(<Table.Row key={index}>
                    <Table.Cell
                        textAlign='center'>{((me.state.activePage - 1) * me.state.onPageLimit) + (index + 1)}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.locSerName}</Table.Cell>
                    <Table.Cell textAlign='center'>{section}</Table.Cell>

                    <Table.Cell textAlign='center'>{item.depoPripName}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.vdName}</Table.Cell>
                    <Table.Cell textAlign='center'>
                        {(item.dislocName && item.dislocCode)
                            ? <span className="depo-href" onClick={e => {
                                me.getDepoInfo(item.dislocCode)
                            }}>{item.dislocName}</span>
                            : item.dislocName
                        }
                    </Table.Cell>
                    <Table.Cell textAlign='center'>{item.sostName}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{moment(item.sostDate).format("DD.MM.YYYY HH:mm")}</Table.Cell>
                    <Table.Cell className="center aligned">{item.waitRepTime}</Table.Cell>
                    <Table.Cell>{(item.waitRepType)
                        ? waitRep[item.waitRepType]
                        : null}
                    </Table.Cell>
                    <Table.Cell className={`center aligned`}>
                        <div className='table-filter' onClick={() => {
                            if (item.probType !== 0) {
                                me.overMileageFilters(item.probRepName)
                            }
                        }} style={warningType}>
                            {(item.probType !== 0) ? (item.probRepName) : null}
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        {item.svpsFails
                            ? item.svpsFails.filter(i => i.svpsIndicatorId !== 2).map(fail => me.svpsFailsRenderer(fail)).join('; ')
                            : null
                        }
                    </Table.Cell>
                    <Table.Cell className="center aligned">
                        {(item.isInRisk) ? (
                            <Popup className='stall-info' trigger={<Icon size='large' name='attention'/>}>
                                <Popup.Header>Последний ТО/ТР</Popup.Header>
                                <Popup.Content>
                                    <p>{moment(item.riskDateRepairFinish).format("DD.MM.YYYY HH:mm:ss")} {item.riskRepairKindName}</p>
                                    {item.riskHasKo ? (<p>(с комиссионным осмотром)</p>) : null}
                                    <p><b>Кол-во НР:</b> {item.riskNeplanCount}</p>
                                    <p><b>Кол-во отказов:</b> {item.riskKasantCount}</p>
                                    <p><b>Первой категории:</b> {item.riskKasant1KindCount}</p>
                                </Popup.Content>
                            </Popup>

                        ) : null}
                    </Table.Cell>
                    <Table.Cell className="center aligned">{(item.planRepDate)
                        ? moment(item.planRepDate).format("DD.MM.YYYY HH:mm")
                        : null
                    }</Table.Cell>
                    <Table.Cell className="center aligned">{item.planRepName}</Table.Cell>
                    <Table.Cell className="center aligned">
                        {(item.planDepoName && item.planDepoCode) ? (
                            <span className="depo-href" onClick={e => {
                                me.getDepoInfo(item.planDepoCode)
                            }}>{item.planDepoName}</span>
                        ) : null}

                    </Table.Cell>
                    <Table.Cell className="center aligned">
                        {(item.busyDitchCnt != null && item.allDitchCnt != null) ? (
                            <Progress indicating value={item.busyDitchCnt} total={item.allDitchCnt}
                                      className='sld-load'>
                                {item.busyDitchCnt + ' / ' + item.allDitchCnt}
                            </Progress>
                        ) : null}
                    </Table.Cell>
                    <Table.Cell className="center aligned">
                        {(item.planDepoName && item.planDepoCode) ? (
                            <Popup className='depot-docs' trigger={<Icon size='large'
                                                                         name={(item.klPriz === 2 && item.toPriz === 2 && item.akPriz === 2) ? 'check' : 'attention'}/>}>
                                <Popup.Header>Аттестат аккредитации на выполнение диагностики:</Popup.Header>
                                <Popup.Content>
                                    {me.permissionDocumentRenderer(item.klPriz, item.dateEndKl, item.nomSvKl)}
                                </Popup.Content>
                                <Popup.Header>Свидетельство на присвоение клейма:</Popup.Header>
                                <Popup.Content>
                                    {me.permissionDocumentRenderer(item.toPriz, item.dateEndTo, item.nomSvTo)}
                                </Popup.Content>
                                <Popup.Header>Сертификат на выполнение ТО/ТР:</Popup.Header>
                                <Popup.Content>
                                    {me.permissionDocumentRenderer(item.akPriz, item.dateEndAk, item.nomSvAk)}
                                </Popup.Content>
                            </Popup>
                        ) : null}

                    </Table.Cell>
                    <Table.Cell>
                        {(item.calculatedDepotName && item.calculatedDepotCode) ? (
                            <span className="depo-href" onClick={e => {
                                me.getDepoInfo(item.calculatedDepotCode)
                            }}>{item.calculatedDepotName}</span>
                        ) : null}
                    </Table.Cell>
                    <Table.Cell className="center aligned">
                        {(item.calculatedBusyDitchCnt != null && item.calculatedAllDitchCnt != null) ? (
                            <Progress indicating value={item.calculatedBusyDitchCnt} total={item.calculatedAllDitchCnt}
                                      className='sld-load'>
                                {item.calculatedBusyDitchCnt + ' / ' + item.calculatedAllDitchCnt}
                            </Progress>
                        ) : null}
                    </Table.Cell>
                </Table.Row>);
            });
        }

        var params = this.state.params;
        var depoInfo = this.state.depoInfo;

        return (
            <div>
                <div style={{margin: '15px'}}>
                    <span style={{fontSize: '14pt', color: '#C1C8D8'}}>Передача в ТОиР</span>
                </div>
                {me.state.filterShow ? <Modal closeIcon size='mini' open={this.state.filterShow}
                                              onClose={me.filterStateChange}>
                        <Modal.Content>
                            <Modal.Description>
                                <Form>
                                    <Form.Field>
                                        <label>№ локомотива</label>
                                        <Input placeholder='№ локомотива'
                                               value={this.state.params.locNum ? this.state.params.locNum : ''}
                                               onChange={(e, {value}) => {
                                                   var num = parseInt(value, 10);
                                                   if (num) {
                                                       this.setState({params: {...params, locNum: num}});
                                                   } else {
                                                       delete params.locNum;
                                                       this.setState({params: {...params}});
                                                   }
                                               }
                                               }/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Серия</label>
                                        <Dropdown placeholder='Серия' fluid multiple search selection
                                                  options={this.state.series}
                                                  value={this.state.params.locSerName ? this.state.params.locSerName : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, locSerName: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Приписка</label>
                                        <Dropdown placeholder='Приписка' fluid multiple search selection
                                                  options={this.state.depos}
                                                  value={this.state.params.depoPripName ? this.state.params.depoPripName : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, depoPripName: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Дислокация</label>
                                        <Dropdown placeholder='Дислокация' fluid multiple search selection
                                                  options={this.state.disloc}
                                                  value={this.state.params.dislocName ? this.state.params.dislocName : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, dislocName: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Состояние</label>
                                        <Dropdown placeholder='Состояние' fluid multiple search selection
                                                  options={this.state.status}
                                                  value={this.state.params.sostName ? this.state.params.sostName : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, sostName: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <div style={{
                                        marginBottom: 10,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Button className="button-link" onClick={me.filterClear}>Сброс</Button>
                                        <Button className="button-primary" onClick={() => {
                                            me.submitFilters();
                                            me.filterStateChange()
                                        }}>Применить</Button>
                                    </div>
                                </Form> </Modal.Description> </Modal.Content></Modal> :
                    <div>{!me.state.isLoading ? <FloatButton
                        main={{
                            text: "",
                            rotate: true,
                            icon: "angle left",
                        }}
                        actions={[
                            {
                                text: "Настройка фильтров",
                                icon: "filter",
                                onClick: () => {
                                    me.filterStateChange()
                                }
                            },
                        ]}
                    /> : null}</div>}

                <Segment basic className={`report-container ${(this.state.isLoading ? 'loading-report' : '')}`}>

                    {this.state.modalLoading ? <SkeletonPage view={'ModalTransfer'}/> : null}

                    {this.state.isLoading ? (
                        <SkeletonPage view={'TransferTo'}/>
                    ) : (
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="center aligned" style={{width: 60}}
                                                      rowSpan='2'>Приоритет&nbsp;&nbsp;
                                        <Popup trigger={<Icon color='black' name='info circle'/>}>
                                            <Popup.Content style={{width: 480}}>Приоритет №1 - локомотивы, стоящие в
                                                ОЖ.ТО-2.</Popup.Content>
                                            <Popup.Content style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp; Приоритет №1.1
                                                – пассажирские, затем грузовые, затем остальные</Popup.Content>
                                            <Popup.Content
                                                style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Приоритет
                                                №1.2 - от младшей даты состояния к старшей.</Popup.Content>
                                            <Popup.Content style={{width: 480}}>Приоритет №2 - локомотивы, стоящие в
                                                ОЖ.ПЕР.РЕМ</Popup.Content>
                                            <Popup.Content style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp; Приоритет №2.1
                                                – имеющие плановую дату ремонта</Popup.Content>
                                            <Popup.Content
                                                style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Приоритет
                                                №2.2 - от младшей плановой даты ремонта к старшей.</Popup.Content>
                                            <Popup.Content style={{width: 480}}>Приоритет №3 - локомотивы, стоящие в
                                                ОЖ.ПЕР.РЕМ</Popup.Content>
                                            <Popup.Content style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp; Приоритет №3.1
                                                – не имеющие плановую дату ремонта</Popup.Content>
                                            <Popup.Content
                                                style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Приоритет
                                                №3.2 - от младшей даты состояния к старшей.</Popup.Content>
                                            <Popup.Content style={{width: 480}}>Приоритет №4 - локомотивы, стоящие в
                                                ОЖ.НЕПЛ.РЕМ</Popup.Content>
                                            <Popup.Content style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp; Приоритет №4.1
                                                – грузовые, затем пассажирские, затем остальные</Popup.Content>
                                            <Popup.Content
                                                style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Приоритет
                                                №4.2 - от младшей даты состояния к старшей.</Popup.Content>
                                            <Popup.Content style={{width: 480}}>Приоритет №5 - локомотивы, стоящие в
                                                остальных состояниях</Popup.Content>
                                            <Popup.Content style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp; Приоритет №5.1
                                                – сначала «больные», потом остальные</Popup.Content>
                                            <Popup.Content
                                                style={{width: 480}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Приоритет
                                                №5.2 - от младшей даты состояния к старшей.</Popup.Content>
                                        </Popup>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      colSpan='4'>Локомотив</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      colSpan='8'>Состояние</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" colSpan='5'>Плановый
                                        ремонт</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" colSpan='2'>Ближайшее место
                                        ремонта&nbsp;
                                        <Popup
                                            trigger={<Icon color='black' name='info circle'/>}
                                            content='Место ремонта рассчитывается автоматически. Осуществляется выбор сервисных депо, осуществляющих ремонт таких серий локомотивов в объёме, необходимом локомотиву по данным о перепробеге. Среди подходящих сервисных депо по длине участков пути определяется ближайшее.'
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell className="center aligned">Серия</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Номер</Table.HeaderCell>

                                    <Table.HeaderCell className="center aligned"
                                                      style={{width: 125}}>Приписка</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" style={{width: 70}}>Вид
                                        движения</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      style={{width: 110}}>Дислокация</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Состояние</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      style={{width: 70}}>Дата</Table.HeaderCell>
                                    <Table.HeaderCell
                                        className="center aligned">Простой<br/>в&nbsp;ОЖ.РЕМ</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Критерий<br/>простоя&nbsp;&nbsp;
                                        <Popup trigger={<Icon color='black' name='info circle'/>}>
                                            <Popup.Content style={{width: 270}}>Менее суток - допустимый</Popup.Content>
                                            <Popup.Content style={{width: 270}}>Менее трёх суток -
                                                длительный</Popup.Content>
                                            <Popup.Content style={{width: 270}}>Более трёх суток -
                                                сверхдлительный</Popup.Content>
                                        </Popup>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Перепробег</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Замечания по тех. состоянию&nbsp;
                                        <Popup
                                            trigger={<Icon color='black' name='info circle'/>}
                                            content='Перечень формируется по данным СВПС АСУТ-НБД-2 за трое суток'
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Группа риска</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      style={{width: 70}}>Дата</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Вид</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">СЛД</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      style={{width: 120}}>Загрузка</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" style={{width: 70}}>Разрешит. док-ты
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">СЛД</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      style={{width: 120}}>Загрузка</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {lis.length > 0 ? lis : <Table.Row>
                                    <Table.Cell className="center aligned" colSpan='20'>Нет элементов удовлетворяющих
                                        условиям поиска</Table.Cell>
                                </Table.Row>}
                            </Table.Body>
                        </Table>
                    )}

                    <Modal open={this.state.errorOccured} closeOnDimmerClick={false} basic size='small'>
                        <Header icon='warning sign' content={`Ошибка ${this.state.errorCode}`}/>
                        <Modal.Actions>

                            <Button onClick={this.closeErrorModal} inverted>
                                <Icon name='checkmark'/> Закрыть
                            </Button>
                        </Modal.Actions>
                    </Modal>

                    {(depoInfo) ? (
                        <Modal className='sld-loading' closeIcon size='large' open={this.state.modalOpen}
                               onClose={this.handleCloseModal}>
                            <Modal.Content scrolling>
                                <Modal.Description>
                                    <Grid centered padded columns='two'>
                                        <Grid.Row textAlign='center'>
                                            <Grid.Column width={3}>
                                                <Header as='h3'>{depoInfo.depoName}</Header>
                                                <Header as='h3'>Ремонтные позиции</Header>
                                                <Statistic.Group>
                                                    <Statistic horizontal>
                                                        <Statistic.Value>{depoInfo.free}</Statistic.Value>
                                                        <Statistic.Label>Свободно</Statistic.Label>
                                                    </Statistic>
                                                    <Statistic horizontal>
                                                        <Statistic.Value>{depoInfo.total}</Statistic.Value>
                                                        <Statistic.Label>Всего</Statistic.Label>
                                                    </Statistic>
                                                </Statistic.Group>
                                            </Grid.Column>
                                            <Grid.Column width={13} style={{maxWidth: '1000px'}}>
                                                <Grid columns='equal' className='ditches-grid'>
                                                    {depoInfo.ditches.map((ditch, i) =>
                                                        <Grid.Row key={i} textAlign='center'>
                                                            <Grid.Column textAlign='right' style={{
                                                                width: 90,
                                                                padding: '14px 5px'
                                                            }}>{ditch.name}</Grid.Column>
                                                            {this.stallHelper(ditch.stalls)}

                                                            {this.fillEmptiness(depoInfo.maxStalls - ditch.stalls.length)}

                                                        </Grid.Row>
                                                    )}
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    ) : null}
                </Segment>
            </div>
        )
    }
}

export default TransferToTr