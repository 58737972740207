import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Dropdown, Dimmer, Grid, Loader, Menu, Icon } from "semantic-ui-react";
import DropdownField from "../../../../shared/fields/DropdownField";
import styles from "../../OrganizationInspection/OrganizationInspection.module.scss";
import style from "../ComInspInfo/ComInspInfo.module.scss";
import GeneralInfo from "../../OrganizationInspection/GeneralInfo/GeneralInfo";
import InspectionChart from "../../OrganizationInspection/InspectionChart/InspectionChart";
import AdditionalWork from "../../OrganizationInspection/AdditionalWork/AdditionalWork";
import { useTransition, animated } from "react-spring";
import ekasut from "../../../../api/ekasut";
import TextField from "../../../../shared/fields/TextField";
import moment from "moment";
import { AddPlan } from "../../OrganizationInspection/addPlan/AddPlan";
import { Select, Space, Typography } from "antd";
import InventoryPark from "../../OrganizationInspection/InventoryPark/InventoryPark";
import { CardOne } from "./Cards/CardOne";
import { CardTwo } from "./Cards/CardTwo";
import { CardFour } from "./Cards/CardFour";
import { CardThree } from "./Cards/CardThree";
import RequestsToCheck from "./RequestsToCheck/RequestsToCheck";
import CardModal from "./Cards/CardModal/CardModal";
import cn from "classnames";

export const MainCharts = observer(
  ({ dates, isLoading, reportsData, isDataLoaded }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [isThirdReportLoading, setIsThirdReportLoading] = useState(false);

    const [dropdownValue, setDropdownValue] = useState(
      "Нарастающий отчет с начала КО"
    );
    const isFirstDropdownVariant =
      dropdownValue === "Нарастающий отчет с начала КО";
    const isSecondDropdownVariant = dropdownValue === "Посуточный отчет КО";

    return (
      <div className={style.comInspInfoBox}>
        <div className={style.header}>
          <span>Сведения о ходе комиссионного осмотра</span>
          <Menu compact>
            <Dropdown
              simple
              item
              // defaultValue={'Нарастающий отчет с начала КО'}
              // text={'Нарастающий отчет с начала КО'}
              options={[
                {
                  text: "Нарастающий отчет с начала КО",
                  value: "Нарастающий отчет с начала КО",
                },
                { text: "Посуточный отчет КО", value: "Посуточный отчет КО" },
              ]}
              value={dropdownValue}
              onChange={(e, { value }) => {
                setDropdownValue(value);
              }}
            />
          </Menu>
        </div>
        {(isLoading || isThirdReportLoading) && (
          <Dimmer active>
            <Loader content={"Пожалуйста, подождите. Загрузка..."} />
          </Dimmer>
        )}
        <div
          className={cn(
            isSecondDropdownVariant && style.gridAreaDaily,
            style.gridArea
          )}
        >
          {isDataLoaded || true ? (
            isFirstDropdownVariant ? (
              <>
                <div className={style.itemOne}>
                  <CardOne
                    dates={dates}
                    data={reportsData.reportAtFails}
                    setIsModalOpened={setIsModalOpened}
                    setModalContent={setModalContent}
                  />
                </div>
                <div className={style.itemTwo}>
                  <CardTwo
                    dates={dates}
                    data={reportsData.reportAtNeprep}
                    setIsModalOpened={setIsModalOpened}
                    setModalContent={setModalContent}
                  />
                </div>
                <div className={style.itemThree}>
                  <CardThree
                    data={reportsData.reportNetStays}
                    setIsModalOpened={setIsModalOpened}
                    setModalContent={setModalContent}
                    setIsThirdReportLoading={setIsThirdReportLoading}
                  />
                </div>
                <div className={style.itemFour}>
                  <CardFour
                    data={reportsData.reportLokoAxwKo}
                    setIsModalOpened={setIsModalOpened}
                    setModalContent={setModalContent}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={style.itemOne} style={{}}>
                  <CardOne
                    dates={dates}
                    data={reportsData.reportAtFailsDay}
                    setIsModalOpened={setIsModalOpened}
                    setModalContent={setModalContent}
                    isDayCard
                  />
                </div>
                <div
                  className={style.itemTwo}
                  style={{ gridColumn: 1, gridRow: 2 }}
                >
                  <CardTwo
                    dates={dates}
                    data={reportsData.reportAtNrDay}
                    setIsModalOpened={setIsModalOpened}
                    setModalContent={setModalContent}
                    isDayCard
                  />
                </div>
              </>
            )
          ) : (
            <div className={style.notSelected}>Выберите год и сезон</div>
          )}
        </div>
        <CardModal
          isOpened={isModalOpened}
          setIsOpened={setIsModalOpened}
          content={modalContent}
        />
      </div>
    );
  }
);

export const ComInspInfo = observer(
  ({
    activeSpace,
    changeWorkSpace,
    activeIndex,
    dates,
    isLoading,
    filteredData,
    isDataLoaded,
  }) => {
    const { Option } = Select;

    const prevIndexRef = useRef(-1);
    const [season, setSeason] = useState("");
    const [year, setYear] = useState("");
    const [pred, setPred] = useState("");
    const [period, setPeriod] = useState();
    const [periodFilter, setPeriodFilter] = useState({
      typeCO: null,
      yearCO: false,
    });
    const [predFilterOptions, setPredFilterOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorKod, setErrorKod] = useState(false);

    const [datesPlan, setDatesPlan] = useState({});

    useEffect(() => {
      const getDates = async () => {
        const result = await ekasut.commisionInspection.getPlan(season, year);
        setDatesPlan(result?.data[0]);
      };

      year && getDates();

      const getDatePeriod = async () => {
        const result = await ekasut.commisionInspection.getPeriodOrder(
          localStorage.getItem("seasonYear"),
          localStorage.getItem("season")
        );
        const periodFrom = result?.data[0]?.dateBegPodgCo;
        const periodTo = result?.data[0]?.dateEndPodgCo;
        localStorage.setItem(
          "orderDatePeriod",
          `${moment(periodFrom).format("DD.MM.YYYY")} - ${moment(
            periodTo
          ).format("DD.MM.YYYY")}`
        );
      };

      getDatePeriod();
    }, [year]);

    const transitions = useTransition(activeSpace, {
      from: {
        opacity: 0,
        transform:
          activeIndex > prevIndexRef.current
            ? `translate3d(0,100%,0)`
            : `translate3d(0,-100%,0)`,
      },
      enter: () => async (next) => {
        await next({ opacity: 1, transform: `translate3d(0,0%,0)` });
      },
      leave: {
        opacity: 0,
        transform:
          activeIndex > prevIndexRef.current
            ? `translate3d(0,-100%,0)`
            : `translate3d(0,100%,0)`,
      },
      config: {
        duration: 700,
      },
      onRest: () => {
        prevIndexRef.current = activeIndex;
      },
    });

    // const initCOPeriod = () => {
    //   let nowMonth = moment().month() + 1;
    //   let nowYear = moment().year();
    //   setPeriod(
    //     nowMonth > 7 || nowMonth < 3 ? "Осень " + nowYear : "Весна " + nowYear
    //   );
    //   setPeriodFilter({
    //     typeCO: nowMonth > 7 || nowMonth < 3 ? 1 : 0,
    //     yearCO: nowYear,
    //   });
    // };

    // useEffect(() => {
    //   ekasut.commisionInspection
    //     .getListDepo(localStorage.kodFirm)
    //     .then((res) => {
    //       setPredFilterOptions(
    //         res.data.map((e) => ({ text: e.firmName, value: e.kodDepo }))
    //       );
    //       if (
    //         res.data.filter(
    //           (e) => String(e.kodDepo) === localStorage.getItem("kodFirm")
    //         ).length
    //       ) {
    //         let result = res.data.filter(
    //           (e) => String(e.kodDepo) === localStorage.getItem("kodFirm")
    //         )[0];
    //         setPred({ text: result.firmName, value: result.kodDepo });
    //       } else if (localStorage.getItem("accessLevel") != 0) {
    //         setErrorKod(true);
    //       }
    //       if (localStorage.getItem("accessLevel") != 0) {
    //         initCOPeriod();
    //       } else {
    //         setPeriod(false);
    //       }
    //       setLoading(false);
    //     });
    // }, []);

    useEffect(() => {
      changeWorkSpace("Сведения");
    }, []);

    return (
      <div>
        {activeIndex === 0 ? (
          <MainCharts
            dates={dates}
            isLoading={isLoading}
            reportsData={filteredData}
            isDataLoaded={isDataLoaded}
          />
        ) : activeIndex === 1 ? (
          <RequestsToCheck />
        ) : null}
      </div>
    );
    // return (
    //   <Grid columns={2} className={styles.organInspecGrid}>
    //     <Grid.Column className={"work-column"}>
    //       {/* <Dimmer active={loading} inverted>
    //         <Loader inverted>Загрузка</Loader>
    //       </Dimmer> */}
    //       {errorKod ? (
    //         <div className={styles.chooseFirm}>Ошибка данных</div>
    //       ) : (!pred && !loading) || (!periodFilter?.yearCO && !loading) ? (
    //         <div className={styles.chooseFirm}>
    //           <Icon name={"train"} size={"large"} />
    //           Выберите предприятие
    //         </div>
    //       ) : (
    //         <Grid.Row className={styles.transitionRow}>
    //           <div
    //             style={{
    //               height: "100%",
    //               display: "flex",
    //               flexFlow: "column wrap",
    //             }}
    //           >
    //             {/* {transitions((style, item, key) => {
    //               const Space = ({ style }) => (
    //                 <animated.div key={key.id} style={{ ...style }}>
    //                   {item.space}
    //                 </animated.div>
    //               );
    //               return (
    //                 <Space
    //                   key={item.id}
    //                   style={{ ...style, flex: "1 1 auto" }}
    //                 />
    //               ); */}
    //             {/* })} */}
    //             {activeIndex === 0 ? (
    //               <MainCharts
    //                 dates={dates}
    //                 isLoading={isLoading}
    //                 reportsData={filteredData}
    //                 isDataLoaded={isDataLoaded}
    //               />
    //             ) : activeIndex === 1 ? (
    //               <RequestsToCheck />
    //             ) : null}
    //           </div>
    //         </Grid.Row>
    //       )}
    //     </Grid.Column>
    //
    //   </Grid>
    // );
  }
);
