import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Header, Table} from "semantic-ui-react";
import {filterData, sortReducer} from "../TableFirstCard/TableFirstCard";
import styles from "../TableFirstCard/TableFirstCard.module.scss";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {replaceNullStateWithNoDataString} from "../TableFourthCard/TableFourthCard";

const TableThirdCardRepair = ({data: tableData}) => {
  const [state, dispatch] = useReducer(sortReducer, {
    column: null,
    data: replaceNullStateWithNoDataString(tableData),
    direction: null,
  })
  const { column, data, direction } = state
  const [filteredData, setFilteredData] = useState(data);

  const filterData1 = useMemo(() => filterData(data, "roadName"), []);
  const filterData2 = useMemo(() => filterData(data, "depoName"), []);
  const filterData3 = useMemo(() => filterData(data, "serName"), []);
  const filterData4 = useMemo(() => filterData(data, "nomSec"), []);
  const filterData5 = useMemo(() => filterData(data, "nameRepair"), []);
  const filterData6 = useMemo(() => filterData(data, "firmName"), []);
  const filterData7 = useMemo(() => filterData(data, "txtRepLength"), []);
  const filterData8 = useMemo(() => filterData(data, "txtRepPerestay"), []);
  const filterData9 = useMemo(() => filterData(data, "dateRepairBegin"), []);
  const filterData10 = useMemo(() => filterData(data, "dateRepairFinish"), []);
  const filterData11 = useMemo(() => filterData(data, "serTxtRepLength"), []);
  const filterData12 = useMemo(() => filterData(data, "serTxtRepPerestay"), []);


  const [selectedRowsInFilters, setSelectedRowsInFilters] = useState({
    roadName: filterData1,
    depoName: filterData2,
    serName: filterData3,
    nomSec: filterData4,
    nameRepair: filterData5,
    firmName: filterData6,

    txtRepLength: filterData7,
    txtRepPerestay: filterData8,
    dateRepairBegin: filterData9,
    dateRepairFinish: filterData10,
    serTxtRepLength: filterData11,
    serTxtRepPerestay: filterData12,

  });

  useEffect(() => {
    // if (filteredData !== data) {
    //     setFilteredData(data);
    // }
    const newFilteredData = data
      .filter(item => selectedRowsInFilters.roadName.includes(item.roadName))
      .filter(item => selectedRowsInFilters.depoName.includes(item.depoName))
      .filter(item => selectedRowsInFilters.serName.includes(item.serName))
      .filter(item => selectedRowsInFilters.nomSec.includes(item.nomSec))
      .filter(item => selectedRowsInFilters.nameRepair.includes(item.nameRepair))
      .filter(item => selectedRowsInFilters.firmName.includes(item.firmName))

      .filter(item => selectedRowsInFilters.txtRepLength.includes(item.txtRepLength))
      .filter(item => selectedRowsInFilters.txtRepPerestay.includes(item.txtRepPerestay))
      .filter(item => selectedRowsInFilters.dateRepairBegin.includes(item.dateRepairBegin))
      .filter(item => selectedRowsInFilters.dateRepairFinish.includes(item.dateRepairFinish))
      .filter(item => selectedRowsInFilters.serTxtRepLength.includes(item.serTxtRepLength))
      .filter(item => selectedRowsInFilters.serTxtRepPerestay.includes(item.serTxtRepPerestay))
    setFilteredData(newFilteredData);
  }, [data, selectedRowsInFilters]);

  const changeSelectedRows = (colName, newData) => {
    setSelectedRowsInFilters(prevState => ({
      ...prevState,
      [colName]: newData,
    }));
  }

  return (
    <div>
      <Header as='h2'>Простой на ремонтах в период проведения КО</Header>
      <div>
        <Header as='h3'>Фильтрация</Header>
        <div style={{display: "flex", gap: "5px"}} className={styles.filtersWrapper}>
          <ColumnFilter colName='roadName' title="Дорога" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData1}/>
          <ColumnFilter colName='depoName' title="Депо" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData2}/>
          <ColumnFilter colName='serName' title="Серия" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData3}/>
          <ColumnFilter colName='nomSec' title="Номер" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData4}/>
          <ColumnFilter colName='nameRepair' title="Вид ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData5}/>
          <ColumnFilter colName='firmName' title="Предприятие ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData6}/>

          <ColumnFilter colName='txtRepLength' title="Время простоя в ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData7}/>
          <ColumnFilter colName='txtRepPerestay' title="Перепростой в ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData8}/>
          <ColumnFilter colName='dateRepairBegin' title="Дата начала ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData9}/>
          <ColumnFilter colName='dateRepairFinish' title="Дата окончания ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData10}/>
          <ColumnFilter colName='serTxtRepLength' title="Общий простой по серии в ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData11}/>
          <ColumnFilter colName='serTxtRepPerestay' title="Общий перепростой по серии в ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData12}/>

        </div>
      </div>

      <Table celled>
        <Table.Header className={styles.header}>
          <Table.Row textAlign={"center"}>
            <Table.HeaderCell
              sorted={column === 'roadName' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'roadName' })}
            >Дорога приписки</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'depoName' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'depoName' })}
            >Депо приписки</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'serName' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serName' })}
            >Серия</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'nomSec' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nomSec' })}
            >Номер</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'nameRepair' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nameRepair' })}
            >Вид ТО/ТР</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'firmName' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmName' })}
            >Предприятие ремонта</Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === 'txtRepLength' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'txtRepLength' })}
            >Время простоя в ТО/ТР</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'txtRepPerestay' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'txtRepPerestay' })}
            >Перепростой в ТО/ТР</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'dateRepairBegin' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateRepairBegin' })}
            >Дата начала ремонта</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'dateRepairFinish' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateRepairFinish' })}
            >Дата окончания ремонта</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'serTxtRepLength' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serTxtRepLength' })}
            >Общий простой по серии в ТО/ТР</Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'serTxtRepPerestay' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serTxtRepPerestay' })}
            >Общий перепростой по серии в ТО/ТР</Table.HeaderCell>

          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredData?.map((item, index) => (
            <Table.Row
              key={index}
              textAlign={"center"}
              onClick={() => {

              }}
            >
              <Table.Cell>{item.roadName}</Table.Cell>
              <Table.Cell>{item.depoName}</Table.Cell>
              <Table.Cell>{item.serName}</Table.Cell>
              <Table.Cell>{item.nomSec}</Table.Cell>
              <Table.Cell>{item.nameRepair}</Table.Cell>
              <Table.Cell>{item.firmName}</Table.Cell>

              <Table.Cell>{item.txtRepLength === "-" ? "0м." : item.txtRepLength}</Table.Cell>
              <Table.Cell>{item.txtRepPerestay === "-" ? "0м." : item.txtRepPerestay}</Table.Cell>
              <Table.Cell>{item.dateRepairBegin}</Table.Cell>
              <Table.Cell>{item.dateRepairFinish}</Table.Cell>
              <Table.Cell>{item.serTxtRepLength === "-" ? "0м." : item.serTxtRepLength}</Table.Cell>
              <Table.Cell>{item.serTxtRepPerestay === "-" ? "0м." : item.serTxtRepPerestay}</Table.Cell>

            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default TableThirdCardRepair;