import React from 'react';
import AreaHeader from "../../shared/headers/AreaHeader";
import AvailableAudits from "./AvailableAudits";
import AvailableAuditsTable from "./AvailableAuditsTable";
import {observer, useLocalStore} from "mobx-react-lite";
import apiWorker from "../../api/apiWorker";
import ekasut from "../../api/ekasut";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import moment from "moment";
import PrepareOrder from "./PrepareOrder";
import {auditSteps} from "./AuditSteps";
import PrepareAuditPlan from "./PrepareAuditPlan";
import CheckProcesses from "./CheckProcesses";
import DevelopRecommendations from "./DevelopRecommendations";
import CheckCompleted from "./CheckCompleted";
import FormCheckList from "./FormCheckList";
import {usePrompt} from "../../globalStore/useGlobalStore";

const ManageAudit = observer(() => {
    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        audits: [],
        from: moment().startOf("year"),
        to: moment().endOf("year"),
        apiAudits: apiWorker(ekasut.audit.getAuditList),
        audit: null,
        viewStepNum: null,
        stepNum: null,
    }))

    const updateAvailableAudits = () => {
        ls.audits = []
        ls.apiAudits.fetch({fromDate: ls.from, toDate: ls.to})
            .then((data) => ls.audits = data)
            .catch(catchNetworkErrors)
    }

    useDidFirstRender(() => {
        updateAvailableAudits()
    })

    const askForCancelStep = () => {

        prompt.show(
            "Вы действительно хотите выйти?",
            "Не сохраненные результаты будут удалены",
            {
                yesNo: true,
                onApply: () => {
                    ls.audit = null
                    ls.viewStepNum = null
                    prompt.close()
                    updateAvailableAudits()
                },
                onDecline: () => {
                    prompt.close()
                },
                onClose: () => {
                    prompt.close()
                }
            }
        )
    }

    const completeStep = (message, nextStep) => {
        ekasut.audit.postStep({auditId: ls.audit.auditId, stepNum: nextStep})
            .then(() => {
                prompt.show(message, "", {
                    ok: true,
                    onClose: () => {
                        prompt.close()
                        ls.stepNum = nextStep
                    }
                })
            })
            .catch(catchNetworkErrors)
    }

    return (<div style={{width: "100%"}}>
        <AreaHeader content="Доступные мне аудиты"/>

        {ls.audit === null && (
            <AvailableAuditsTable
                audits={ls.audits}
                auditsLoading={ls.apiAudits.loading}
                onClick={(audit) => {
                    ls.stepNum = audit.stepNum === null || audit.stepNum === 0 ? auditSteps.prepareOrder : audit.stepNum
                    ls.audit = audit
                }}
            />
        )}
        {ls.audit !== null && (<>
            <PrepareOrder
                display={(ls.viewStepNum ? ls.viewStepNum === auditSteps.prepareOrder : ls.stepNum === auditSteps.prepareOrder)}
                audit={ls.audit}
                onClose={askForCancelStep}
                onComplete={() => {
                    completeStep("Этап подготовки приказа завершен", auditSteps.formCheckList)
                }}
                step={ls.stepNum}
                viewStep={ls.viewStepNum}
                editable={ls.viewStepNum === null}
                onView={(step, isActive) => {
                    ls.viewStepNum = isActive ? null : step
                }}
            />
            <FormCheckList
                display={(ls.viewStepNum ? ls.viewStepNum === auditSteps.formCheckList : ls.stepNum === auditSteps.formCheckList)}
                audit={ls.audit}
                onClose={askForCancelStep}
                onComplete={() => {
                    completeStep("Этап формирования чек листа завершен", auditSteps.prepareAuditPlan)
                }}
                step={ls.stepNum}
                viewStep={ls.viewStepNum}
                editable={ls.viewStepNum === null}
                onView={(step, isActive) => {
                    ls.viewStepNum = isActive ? null : step
                }}
            />
            <PrepareAuditPlan
                display={(ls.viewStepNum ? ls.viewStepNum === auditSteps.prepareAuditPlan : ls.stepNum === auditSteps.prepareAuditPlan)}
                audit={ls.audit}
                onClose={askForCancelStep}
                onComplete={() => {
                    completeStep("Этап подготовки плана аудита завершен", auditSteps.checkProcesses)
                }}
                step={ls.stepNum}
                viewStep={ls.viewStepNum}
                editable={ls.viewStepNum === null}
                onView={(step, isActive) => {
                    ls.viewStepNum = isActive ? null : step
                }}
            />
            <CheckProcesses
                display={(ls.viewStepNum ? ls.viewStepNum === auditSteps.checkProcesses : ls.stepNum === auditSteps.checkProcesses)}
                audit={ls.audit}
                onClose={askForCancelStep}
                onComplete={() => {
                    completeStep("Этап проверки процессов завершен", auditSteps.developRecommendations)
                }}
                step={ls.stepNum}
                viewStep={ls.viewStepNum}
                editable={ls.viewStepNum === null}
                onView={(step, isActive) => {
                    ls.viewStepNum = isActive ? null : step
                }}
            />
            <DevelopRecommendations
                display={(ls.viewStepNum ? ls.viewStepNum === auditSteps.developRecommendations : ls.stepNum === auditSteps.developRecommendations)}
                audit={ls.audit}
                onClose={askForCancelStep}
                onComplete={() => {
                    completeStep("Этап разработки рекомендаций повышению эффективности завершен", auditSteps.summarize)
                }}
                step={ls.stepNum}
                viewStep={ls.viewStepNum}
                editable={ls.viewStepNum === null}
                onView={(step, isActive) => {
                    ls.viewStepNum = isActive ? null : step
                }}
            />
            <CheckCompleted
                display={(ls.viewStepNum ? ls.viewStepNum === auditSteps.summarize : ls.stepNum === auditSteps.summarize)}
                audit={ls.audit}
                onClose={() => {
                    ls.audit = null
                }}
                step={ls.stepNum}
                viewStep={ls.viewStepNum}
                editable={ls.viewStepNum === null}
                onView={(step, isActive) => {
                    ls.viewStepNum = isActive ? null : step
                }}
            />
        </>)}
    </div>)
})

export default ManageAudit;