import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {Segment} from "semantic-ui-react";

export default function RepairWaitChart({pointId, pointName, data}) {
	const [options, setOptions] = useState(initOptions)

	// update options
	useEffect(() => {
		const waitCount = []
		const categories = []
		data.forEach((item) => {
			categories.push(item.groupName)
			waitCount.push(item.waitCnt)
		})
		const addOptions = {
			title: {
				text: `${pointName}, В ожидании ремонта`
			},
			xAxis: {
				categories
			},
			series: [{
				data: waitCount
			}]
		}
		setOptions(Highcharts.merge(initOptions, addOptions))
	}, [data, pointName])

	return (
		<Segment style={{width: '100%'}}>
			<HighchartsReact
				highcharts={Highcharts}
				options={options}
			/>
		</Segment>
	)
}

const initOptions = {
	chart: {
		type: 'column',
		backgroundColor: 'rgba(0,0,0,0)',
		animation: false,
	},
	title: {
		text: '',
		widthAdjust: -200
	},
	exporting: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	xAxis: {
		type: 'category',
		categories: [],
		title: {
			text: 'Серия'
		}
	},
	yAxis: {
		min: 0,
		allowDecimals: false,
		title: {
			text: 'Количество'
		}
	},
	legend: {
		enabled: false
	},
	tooltip: {
		formatter: function() {
			return (
				`<b>${this.x}</b><br/>
				Сейчас в ожидании: ${this.y} ед.<br/>`
			)
		}
	},
	plotOptions: {
		column: {
			maxPointWidth: 40
		}
	},
	series: []
}