import React, {useState} from 'react'
import {Table} from "semantic-ui-react";
import moment from "moment";
import ModalError from "../../../shared/ModalError";
import './MaintenanceTable.scss'
import LocomotiveModal from '../../../shared/LocomotiveModal'

const MaintenanceTable = ({data, pointName, date, onRowClick}) => {
    const [locModal, setLocModal] = useState({open: false})

    const [error, setError] = useState(null)

    const handleRowClick = (item, index) => {
        onRowClick(item)
        setLocModal({
            open: true,
            seriesId: item.serLoc,
            number: item.znsLoc,
            sectionId: item.prsLoc
        })
    }

    return (
        <>
            <Table celled selectable compact className={'pr-maintenance-table'}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned" colSpan='5'>{pointName + ', ' + moment(date).format('DD.MM.YYYY')}</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell className="center aligned">Дорога приписки</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Депо приписки</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Локомотив</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Вид ремонта</Table.HeaderCell>
                        <Table.HeaderCell className="center aligned">Дата ремонта</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data instanceof Array && data.map((item, index) => {
                        return (
                            <Table.Row key={index} onClick={() => handleRowClick(item, index)}>
                                <Table.Cell>{item.dorName}</Table.Cell>
                                <Table.Cell>{item.depoName}</Table.Cell>
                                <Table.Cell>{item.sostavn}</Table.Cell>
                                <Table.Cell>{item.naimOper}</Table.Cell>
                                <Table.Cell>{moment(item.dateOp).format('DD.MM.YYYY HH:mm')}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>

            <LocomotiveModal
                open={locModal.open}
                seriesId={locModal.seriesId}
                number={locModal.number}
                sectionId={locModal.sectionId}
                onClose={() => setLocModal({open: false})}
                onError={(err) => {
                    setLocModal({open: false})
                    setError(err)
                }}
            />
        </>
    )
}
export default MaintenanceTable