import React from 'react'
import {observer} from "mobx-react-lite"
import {Icon, Message, Table} from "semantic-ui-react";
import {momentIfStr} from "../../helpers/utils";
import {dateFormats} from "../../Dictionaries";
import moment from 'moment'

const TriggerBarrierFunctions = observer(({tab}) => {
    const bfs = tab.barrierFunctions

    return tab.barrierFunctionsMessage ? <Message content={tab.barrierFunctionsMessage}/> : (
        <Table celled structured striped className="compact-table">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign='center'>Барьерная функция</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Результат проверки</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Дата проверки</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Депо</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <BarrierFunctionRow text="Замеры бандажей колёсных пар в ходе ТО/ТР" bf={bfs[1]}/>
                <BarrierFunctionRow text="Замеры бандажей колёсных пар за 30 суток" bf={bfs[2]}/>
                <BarrierFunctionRow text="Сроки поверок УБ" bf={bfs[3]}/>
                <BarrierFunctionRow text="Наличие УБ для работы в два лица" bf={bfs[4]}/>
                <BarrierFunctionRow text="Наличие УБ для работы в одно лицо" bf={bfs[6]}/>
                <BarrierFunctionRow text="Наличие предписаний ФГП ВО ЖДТ" bf={bfs[7]}/>
                <BarrierFunctionRow text="Наличие Акта технического состояния" bf={bfs[5]}/>
            </Table.Body>
        </Table>
    )

})

const BarrierFunctionRow = ({text, bf}) => {
    let date = bf !== undefined ? momentIfStr(bf.checkDate) : moment("")

    return (
        <Table.Row>
            <Table.Cell>{text}</Table.Cell>
            {bf !== undefined ? (<>
                <Table.Cell collapsing textAlign="center">
                    {bf.checkResult !== 0 ?
                        <Icon color="green" name="checkmark" size="large"/> :
                        <Icon color="red" name="x" size="large"/>
                    }
                </Table.Cell>
                <Table.Cell collapsing>{date.isValid() ? date.format(dateFormats.upToSeconds) : null}</Table.Cell>
                <Table.Cell>{bf.depoName}</Table.Cell>
            </>) : (
                <Table.Cell colSpan={3} textAlign="center">Нет данных</Table.Cell>
            )}
        </Table.Row>
    )
}

export default TriggerBarrierFunctions;