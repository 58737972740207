import React from 'react';
import ekasut from "../../../../api/ekasut";
import NetworkSearch from "../observers/NetworkSearch";

const SearchStation = ({label}) => (
    <NetworkSearch
        label={label}
        itemParser={item => ({
            id: item.stanId,
            name: item.vname
        })}
        displayFieldName="name"
        onChange={(station) => {

        }}
        apiMethod={text => ekasut.ekasutData.getNsiStan(text)}
    />
)
SearchStation.defaultProps = {
    label: "Станция"
}

export default SearchStation;