/* import { useSnapshot } from "valtio";
import { state } from "../state"; */

import {proxy} from "valtio";

const analyticsState = proxy({
    loadingList: false,
    taskList: null,
    id: null,
    filterType: null,
    categoryId: null,
    name: null,
});

export {analyticsState};