import React from 'react';
import cn from "classnames";
import {padStartZero} from "../../helpers/utils";
import TextField from "./TextField";
import './TimeField.scss'

const TimeField = ({className, label, hours = 0, minutes = 0, fluid, onChange = () => null, editable = true, ...inputProps}) => {

    if (!editable) {
        return (
            <TextField
                label={label}
                content={`${padStartZero(hours, 2)}:${padStartZero(minutes, 2)}`}
                className={className}
                {...inputProps}
            />
        )
    }

    return (
        <div className={cn("app time-field field", className, fluid && "fluid")}>
            <label>{label}</label>
            <div className="container">
                <input
                    /*style={{lineHeight: "14px"}}*/
                    type="time"
                    value={`${padStartZero(hours, 2)}:${padStartZero(minutes, 2)}`}
                    onChange={({target: {value}}) => {
                        const [hours, minutes] = value === "" ? [0,0] : value.split(":").map(val => parseInt(val))
                        onChange(hours, minutes)
                    }}
                    {...inputProps}
                />
                {/*<TimeInput
                    onTimeChange={(val) => {
                        if (onChange) {
                            const [hours, minutes] = val.split(":").map(val => parseInt(val))
                            onChange(hours, minutes)
                        }
                    }}
                    {...timeInputProps}
                />*/}
            </div>
        </div>
    );
};

export default TimeField;