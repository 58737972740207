import React from "react";
import style from "./MainTab.module.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Pie } from "@ant-design/charts";
import { SideBarCommissionInspectionModal } from "../SideBar/SideBarCommissionInspectionModal";

export const MainTab = ({ info }) => {
  const columnsWayList = [
    { field: "way", headerName: "Маршрут следования", width: 160 },
    { field: "period", headerName: "Период работы", width: 160 },
    { field: "driver", headerName: "Машинист", width: 160 },
    { field: "filese", headerName: "Файлы поездок", width: 160 },
  ];

  const columnsHistoryt = [
    { field: "date", headerName: "Дата", width: 410 },
    { field: "numOrder", headerName: "Реквизиты приказа", width: 255 },
  ];

  const rowsHistory = [
    {
      id: 1,
      date: "04.07.2021",
      numOrder: "453760644",
    },
    {
      id: 2,
      date: "13.08.2021",
      numOrder: "121672634",
    },
    {
      id: 3,
      date: "21.09.2021",
      numOrder: "165760344",
    },
    {
      id: 4,
      date: "13.10.2021",
      numOrder: "235746241",
    },
  ];

  const rowsWayList = [
    {
      id: 1,
      way: "Москва - Слюдянка",
      period: "10.08.2021-11.08.2021",
      driver: "Степанов Д.А.",
      filese: "Нет",
    },
    {
      id: 2,
      way: "Слюдянка - Находка",
      period: "12.08.2021-13.08.2021",
      driver: "Степанов Д.А.",
      filese: "Нет",
    },
    {
      id: 3,
      way: "Находка - Москва",
      period: "14.08.2021-15.08.2021",
      driver: "Степанов Д.А.",
      filese: "Нет",
    },
  ];

  const data = [
    {
      type: "Отказ",
      value: 27,
    },
    {
      type: "Погода",
      value: 25,
    },
    {
      type: "Время",
      value: 18,
    },
    {
      type: "Нарушения",
      value: 15,
    },
  ];
  const config = {
    appendPadding: 10,
    data: data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    width: 200,
    height: 150,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: 18,
        },
        content: "2021",
      },
    },
  };

  return (
    <div className={style.wrap}>
      <SideBarCommissionInspectionModal info={info} />
      <div className={style.column}>
        <div className={style.itemColumn}>
          <div className={style.title}>Маршрутные листы</div>
          <div style={{ height: 200, width: "100%" }}>
            <DataGrid
              disableColumnReorder
              disableColumnMenu
              rows={rowsWayList}
              columns={columnsWayList}
              hideFooter
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        </div>
        <div className={style.itemColumn}>
          <div className={style.title}>История состояний и пробегов</div>
          <div style={{ height: 250, width: "100%" }}>
            <DataGrid
              disableColumnReorder
              // disableColumnMenu
              rows={rowsHistory}
              columns={columnsHistoryt}
              disableColumnMenu={false}
              hideFooter
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        </div>
      </div>
      <div className={style.column}>
        <div className={style.itemColumn}>
          <div className={style.title}>Причины неисправностей</div>
          <Pie {...config} />
        </div>
        <div className={style.itemColumn}>
          <div className={style.title}>Срабатывания барьерных функций</div>
        </div>
      </div>
    </div>
  );
};
