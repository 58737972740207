import React, { useEffect, useState } from "react";
import style from "./BodyAddProt.module.scss";
import { Dropdown, Menu } from "semantic-ui-react";
import { Upload, message, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ekasut from "../../../../../api/ekasut";
import { DatePicker, Space, Input } from "antd";
import moment from "moment";
import downloadFile from "../../../../GroupRiskLocomotives/downloadFile";

export const BodyAddProt = ({
  currentObjId,
  currentSostName,
  currentSostKod,
  currentDateOp,
  sendingMarks,
  setSendingMarks,
  sendingAddWorkStatus,
  setSendingAddWorkStatus,
  sendingProtocolNumber,
  setSendingProtocolNumber,
  setProtocolNum,
  commisarMarks,
  setCommisarMarks,
  getSecProtAxwork,
  getSecProtInfo,
  getSld,
  openFile,
  setOpenFile,
  currentKeyForFile,
  setCurrentKeyForFile,
}) => {
  const [marks, setMarks] = useState([]);

  const year = new Date().getFullYear();
  const season = new Date().getMonth() < 9 ? 1 : 2;
  localStorage.setItem("seasonYear", year);
  localStorage.setItem("season", season);

  useEffect(() => {
    const getOcenka = async () => {
      const result = await ekasut.commisionInspection.getOcenka();
      setMarks(result?.data);
    };

    getOcenka();
  }, []);

  const postMark = async (e, value) => {
    console.log(e, value);
    const res = await ekasut.commisionInspection.postMark({
      pRGRating: e.rgRating ? e.rgRating : "",
      pRGProt: e.rgProt,
      pRGPplOrd: e.rgPpl,
      pOcenka: value,
    });

    getSecProtAxwork();
    getSecProtInfo();
  };

  return (
    <div className={style.bodyAddProt} style={{ width: "50%" }}>
      <div className={style.markAria}>
        <div className={style.columnsName}>
          <span>Оборудовние</span>
          <span>Член комиссии</span>
          <span>Оценка</span>
        </div>
        {commisarMarks?.map((e, i) => (
          <div key={i}>
            <p>{e?.itemName}</p>
            <p>{e?.fio}</p>
            <div style={{ width: "135px" }}>
              <Menu compact widths={16}>
                <Dropdown
                  simple
                  item
                  direction={"left"}
                  text={e.ocenka ? `${e.ocenka}` : "Не выбрана"}
                  options={marks.map((e) => ({
                    text: e.strOcenka,
                    value: e.intOcenka,
                  }))}
                  onChange={(el, { value }) => {
                    postMark(e, value);
                  }}
                />
              </Menu>
            </div>
          </div>
        ))}
        <label
          className="download order"
          style={{
            background: "#e0e1e2",
            color: "rgba(0,0,0,.8)",
            padding: "11px 21px",
            backgroundColor: "#cacbcd",
            borderRadius: ".28571429rem",
            fontWeight: "700",
          }}
        >
          Загрузить pdf
          <Input
            style={{ visibility: "hidden", position: "absolute" }}
            className="sender"
            type="file"
            accept=".pdf"
            onChange={(e) => {
              const fr = new FileReader();
              const file = document.querySelector(".sender").files[0];
              fr.readAsArrayBuffer(file);
              fr.onload = function () {
                const blob = new Blob([fr.result], {
                  type: "text/plain",
                });
                // let reader = new FileReader();
                // reader.readAsDataURL(blob); // конвертирует Blob в base64 и вызывает onload
                setOpenFile(blob);
              };
            }}
          />
        </label>
        {console.log(currentKeyForFile)}
        {currentKeyForFile && (
          <Button
            style={{ marginTop: "20px" }}
            onClick={() => downloadFile(currentKeyForFile, "Док")}
          >
            Просмотреть файл
          </Button>
        )}
      </div>
    </div>
  );
};
