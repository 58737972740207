import React from 'react';
import ekasut from "../../../../api/ekasut";
import NetworkSearch from "../observers/NetworkSearch";

const SearchEmployee = ({label}) => (
    <NetworkSearch
        label={label}
        itemParser={item => ({
            fullName: `${item.lastName} ${item.firstName} ${item.patrName}`
        })}
        displayFieldName="fullName"
        onChange={(employee) => {
        }}
        apiMethod={text => ekasut.tchmiNsi.getPeople(text)}
    />
)
SearchEmployee.defaultProps = {
    label: "Сотрудник"
}


export default SearchEmployee;