import React from 'react'
import {Button, Icon} from "semantic-ui-react";

const BackArrowButton = (props) => {
    return (
        <Button icon labelPosition='left' size='mini' basic {...props}>
            Назад
            <Icon name='left arrow' />
        </Button>
    )
}
export default BackArrowButton