import React, {useEffect} from 'react';
import {Button, Modal} from "semantic-ui-react";
import Widget from "../../../shared/Widget";
import TextAreaAutoSize from 'react-textarea-autosize'
import './CreateCriterionModal.scss'
import TextAreaField from "../../../shared/fields/TextAreaField";
import TextField from "../../../shared/fields/TextField";
import {observer, useLocalStore} from "mobx-react-lite";

const CreateCriterionModal = observer(({process, open, onClose, onCreate}) => {
    const ls = useLocalStore(() => ({
        criterion: ""
    }))

    useEffect(() => {
        if (open) {
            ls.criterion = ""
        }
    }, [open])

    return (
        <Modal open={open} size="tiny" onClose={onClose} className="app audit-control create-criterion-modal alt-semantic">
            <Widget
                header="Создание критерия"
                onClose={onClose}
                closeIcon
                footer={(
                    <div className="modal-buttons">
                        <Button content="Добавить" className="button-link" onClick={() => onCreate(ls.criterion)}/>
                    </div>
                )}
            >
                <TextField
                    label="Процесс"
                    content={process && process.processName}
                />
                <TextAreaField
                    label="Выберите критерий"
                    fluid
                    autoFocus
                    onChange={(e, {value}) =>  ls.criterion = value}
                />
            </Widget>
        </Modal>
    );
})

export default CreateCriterionModal;