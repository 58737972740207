import React from 'react'
import { useStore } from '../StoreProvider'
import {Header} from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import * as moment from 'moment';
import 'moment/locale/ru';
import {dateFormats} from "../../../../Dictionaries";

const TcheSldHeader = observer(() => {
    const store = useStore()
    const {signers} = store.plan


    return (
        <>
            <Header
                content={store.tche.id === null && store.sld.id === null ?
                "ТЧЭ и СЛД не выбрано" : <><span style={{marginRight: 40}}>{store.tche.name}</span><span>{store.sld.name}</span></>}
            />
            { signers && signers.sldSign && signers.sldSign === true
                ? (<Header>
                    Согласовано, исполнитель
                    - {signers.sldSignFio || 'Пользователь не определен'}
                    /{signers.sldSignPost || 'Должность не определена'} {' '}
                    - {moment(signers.sldSignDate).format(dateFormats.upToSeconds)}
                </Header>)
                : null
            }
            { signers && typeof signers.tcheSign === "boolean"
                ? (<Header>
                    {signers.tcheSign ? 'Согласовано' : 'Отклонено'}, заказчик
                    - {signers.tcheSignFio || 'Пользователь не определен'}
                    /{signers.tcheSignPost || 'Должность не определена'} {' '}
                    - {moment(signers.tcheSignDate).format(dateFormats.upToSeconds)}
                </Header>)
                : null
            }

        </>
    )
})

export default TcheSldHeader