import {useEffect, useState} from 'react'

export const zoomLevels = {
    ROADS: 'roads',
    DEPOS: 'depos'
}

export function useZoomLevel(zoom, depoLevel) {
    const [zoomLevel, setZoomLevel] = useState(zoomLevels.ROADS)

    useEffect(() => {
        if (zoom > depoLevel) {
            setZoomLevel(zoomLevels.DEPOS)
        } else {
            setZoomLevel(zoomLevels.ROADS)
        }
    }, [zoom, depoLevel])

    return zoomLevel
}
