import React, {useEffect} from 'react';
import DropdownField from "../fields/DropdownField";
import {observer, useLocalStore} from "mobx-react-lite";
import ekasut from "../../api/ekasut";
import {runInAction} from "mobx";
import './index.scss'

const MultiplePredsField = observer(({triggerLoadOptions, onChange, ...dropdownProps}) => {


    const ls = useLocalStore(() => ({
        options: null,
        optionsLoaded: false,
        preds: null
    }))

    const getOptions = (selectedIds) => {
        const options = []
        ls.preds.forEach(pred => {
            if (selectedIds.includes(pred.id)) {
                options.push({
                    value: pred.id + "-disabled",
                    key: pred.id + "-disabled",
                    text: pred.name,
                    className: pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
                    disabled: true
                })
            }
            options.push({
                value: pred.id,
                key: pred.id,
                text: pred.name,
                className: pred.level === 1 ? "root-pred" : "child-pred-" + (pred.level - 1),
                disabled: false
            })
        })
        return options
    }

    useEffect(() => {
        if (!ls.loaded && triggerLoadOptions === undefined ? true : triggerLoadOptions) {
            ls.loaded = true

            ekasut.ekasutData.getCtTree().then(({data}) => {
                runInAction(() => {
                    ls.preds = data.map(item => ({id: item.key, name: item.text, level: item.level, disabled: false}))
                    ls.options = getOptions(ls.preds)
                })

                ls.options = getOptions([])
            })
        }
    }, [triggerLoadOptions])

    return (
        <DropdownField
            className="app multiple-preds-filed"
            loading={!ls.loaded}
            selection clearable search multiple
            options={ls.options === null ? [] : [...ls.options]}
            onChange={(e, props) => {
                ls.options = getOptions(props.value)
                onChange && onChange(e, props)
            }}
            {...dropdownProps}

        />
    );
})

export default MultiplePredsField;