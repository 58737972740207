import React, {useState, useEffect} from 'react'
import {Dropdown, Modal, List, Button} from "semantic-ui-react";
import {formOptions} from "../../../helpers/utils";

import cn from 'classnames'
import styles from '../index.module.scss'
import appStyles from '../../../css/App.module.scss'
import fieldStyles from './MultipleSelectList.module.scss'

export default function MultipleSelectList({list, label, loading, editable, onChange, values, hideBoxes}) {
    let [indexes, setIndexes] = useState([])

    if (values !== undefined) {
        indexes = values
        setIndexes = () => {}
    }

    const [listModal, setListModal] = useState({open: false})

    return (
        <div className={styles.formField}>
            <label>{label}</label>
            {(editable && indexes.length !== 0) || (!editable) ? (
                <Dropdown
                    disabled={true}
                    className={cn(appStyles.dropdownFullWidthItems, appStyles.fixedDropdown, hideBoxes && styles.boxShadowLabelNone)}
                    fluid multiple loading={false} selection
                    options={formOptions(list, 'text')}
                    basic compact
                    value={indexes}
                />
            ) : null}
            {editable && (
                <div>
                    <Button
                        fluid loading={loading} basic compact
                        content='Выберите из списка'
                        onClick={() => {
                            setListModal({open: true})
                        }}
                    />
                </div>
            )}
            <ModalSelectList
                label={label}
                list={list}
                open={listModal.open}
                indexesBeforeOpen={indexes}
                onClose={() => {
                    setListModal({open: false})
                }}
                onChange={(indexes) => {
                    setIndexes(indexes)
                    onChange(indexes)
                }}
            />
        </div>
    )
}
MultipleSelectList.defaultProps = {
    list: [],
    label: '',
    hideBoxes: false
}
const ModalSelectList = ({open, onClose, label, list, indexesBeforeOpen, onChange}) => {
    const [indexes, setIndexes] = useState([])

    useEffect(() => {
        if (open) {
            setIndexes(indexesBeforeOpen)
        }
    }, [open, indexesBeforeOpen])

    return (
        <Modal
            open={open}
            onClose={() => {
                onChange(indexes)
                onClose()
            }}
        >
            <Modal.Header>
                {label}
            </Modal.Header>
            <Modal.Content>
                <List selection divided className={fieldStyles.list}>
                    {list.map((item, index) => (
                        <List.Item
                            key={index}
                            className={cn(indexes.includes(index) && fieldStyles.selectedItem)}
                            onClick={(e, e1) => {
                                let next = [...indexes]
                                const iOfIndex = indexes.findIndex(i => i === index)
                                if (iOfIndex >= 0) {
                                    next.splice(iOfIndex, 1)
                                } else {
                                    next.push(index)
                                }
                                setIndexes(next)
                            }}
                        >
                            {item.text}
                        </List.Item>
                    ))}
                    <List.Item>
                        <Button
                            basic floated='right'
                            content='Ок'
                            onClick={() => {
                                onChange(indexes)
                                onClose()
                            }}
                        />
                    </List.Item>
                </List>
            </Modal.Content>
        </Modal>
    )
}