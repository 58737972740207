import React from "react";
import {useLocalStore} from "mobx-react-lite";
import {createStore} from "./store";

export const storeContext = React.createContext()

export const StoreProvider = ({ children }) => {
    const store = useLocalStore(createStore)
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => (React.useContext(storeContext))
