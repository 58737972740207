import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, CardMedia } from "@material-ui/core";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import style from "./News.module.scss";
import moment from "moment";
import ekasut from "../../api/ekasut";
import { useHistory, Link } from "react-router-dom";

export const News = ({
  setValueCategory,
  addedOn,
  authorId,
  authorName,
  categoryId,
  categoryName,
  commentsCount,
  content,
  coverImage,
  deletedOn,
  files,
  isDelayed,
  newsId,
  publishDate,
  title,
  updateOn,
}) => {
  const history = useHistory();

  const setCategory = () => {
    setValueCategory({ name: categoryName, code: categoryId });
  };
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (coverImage) {
      ekasut.file.getFile(coverImage.fileId).then((r) => {
        if (r.status === 200) {
          const bytes = new Uint8Array(r.data);
          const blob = new Blob([bytes], { type: "image/jpeg" });
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob);
          setImage(imageUrl);
        }
      });
    }
  }, []);
  return (
    <Card className={style.card}>
      {image && (
        <CardMedia
          onClick={() => history.push(`/news/${newsId}`)}
          className={style.media}
          image={image}
        />
      )}

      <CardContent className={style.cardContent}>
        <Link to={`/news/${newsId}`} className={style.linkBody} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          {categoryName && (
            <span onClick={setCategory} className={style.category}>
              {" "}
              #{categoryName}
            </span>
          )}
          <Typography
            variant={"h6"}
            gutterBottom
            onClick={() => history.push(`/news/${newsId}`)}
          >
            {title}
          </Typography>
          <Typography
            onClick={() => history.push(`/news/${newsId}`)}
            className={style.contentBody}
            variant={"body1"}
          >
            {content}
          </Typography>
          <div
            onClick={() => history.push(`/news/${newsId}`)}
            className={style.attachedFile}
          >
            Прикрепленные файлы: <span>{files.length}</span>
          </div>
          <div
            onClick={() => history.push(`/news/${newsId}`)}
            className={style.countComments}
          >
            <span>Комментариев:</span> {commentsCount}
          </div>
        </div>

        <div>
          <div className={style.description}>
            <div className={style.item}>
              <PersonOutlineOutlinedIcon />
              <Typography variant={"caption"}>{authorName}</Typography>
            </div>
            <div className={style.item}>
              <ScheduleOutlinedIcon />
              <Typography variant={"caption"}>
                {moment(publishDate).format("DD.MM.YYYY HH:mm:ss")}
              </Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
