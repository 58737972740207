import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { MenuList, MenuItem } from "@material-ui/core";
import { News } from "./News";
import style from "./News.module.scss";
import { AddNewsModal } from "./AddNewsModal";
import ekasut from "../../api/ekasut";
import { UpOutlined } from "@ant-design/icons";
import { getAppGrants } from "../../helpers/utils";

const PUBLIC = "Опубликованные";
const DEFER = "Отложенные";

export const ContainerNews = () => {
  const [mount, setMount] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([{ name: "Все" }]);

  const [valueCategory, setValueCategory] = useState(categoryOptions[0]);

  const [publications] = useState([
    localStorage.grants.includes("news_edit") ? "Любой" : "",
    PUBLIC,
    localStorage.grants.includes("news_edit") ? DEFER : "",
  ]);
  const [activePublic, setActivePublic] = useState("Опубликованные");

  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);
  const [btnScrollActive, setBtnScrollActive] = useState(false);

  const [showFilters, setShowFilters] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const [news, setNews] = useState([]);

  const initNews = () => {
    ekasut.news.getCategory().then((r) => {
      setCategoryOptions([{ name: "Все" }, ...r.data]);
    });
    ekasut.news.getNews().then((r) => setNews(r.data.data));
  };

  const goToTop = () => {
    const topDiv = document.querySelector("#top");
    if (topDiv) {
      topDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (mount) {
      ekasut.news
        .getNews({
          categoryId: valueCategory?.code,
        })
        .then((r) => {
          setNews(r.data.data);
          setTotalPage(r.data.totalPages);
        });
    }
  }, [valueCategory]);

  useEffect(() => {
    initNews();
    setMount(true);
    document.querySelector("#mainContainerNews").parentElement.style.padding =
      "0";
  }, []);

  const canEdit = getAppGrants().includes("news_edit");
  return (
    <>
      <div className="filters-container">
        <Button
          className="filters-button rounded"
          onClick={() => setShowFilters(!showFilters)}
        >
          Фильтры
        </Button>
      </div>
      <div style={{ overflow: "hidden" }} id={"mainContainerNews"}>
        <div
          className={style.container}
          onScroll={(e) => {
            if (e.target.scrollTop > 100) {
              if (!btnScrollActive) {
                setBtnScrollActive(true);
              }
            } else {
              if (btnScrollActive) {
                setBtnScrollActive(false);
              }
            }
            if (
              e.target.scrollHeight - e.target.scrollTop <
              e.target.clientHeight + 2
            ) {
              if (!loading && totalPage >= page) {
                setLoading(true);
                ekasut.news
                  .getNews({
                    categoryId: valueCategory?.code,
                    pageNumber: page,
                  })
                  .then((r) => {
                    setNews((prevState) => [...prevState, ...r.data.data]);
                    setTotalPage(r.data.totalPages);
                    setLoading(false);
                    setPage(page + 1);
                  })
                  .catch((err) => setLoading(false));
              }
            }
          }}
        >
          <div className={style.containerNews}>
            <div id={"top"} />
            {news
              .filter((e) => {
                const d1 = Date.now();
                const d2 = new Date(e.publishDate);
                if (activePublic === PUBLIC) {
                  return d1 > d2;
                }
                if (activePublic === DEFER) {
                  return d2 > d1;
                }
                return e;
              })
              .map((el) => (
                <News
                  key={el.newsId}
                  {...el}
                  setNews={setNews}
                  setValueCategory={setValueCategory}
                />
              ))}
            {loading ? (
              <div className="loadingWrap">
                <div className="loadingBlock">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : null}
          </div>

          {showFilters ? (
            <div
              className={style.actions}
              id="newsHeader"
              style={{ maxWidth: 500 }}
            >
              <div
                className="close-filters"
                onClick={() => setShowFilters(!showFilters)}
              >
                ❌
              </div>
              {canEdit && (
                <Button
                  style={{ width: "100%", margin: 0 }}
                  animated
                  primary
                  onClick={() => setOpenModal(true)}
                >
                  <Button.Content visible>Добавить</Button.Content>
                  <Button.Content hidden>
                    <Icon name="add" />
                  </Button.Content>
                </Button>
              )}
              <MenuList className={style.menuCategory}>
                <span style={{ fontWeight: "bold" }}>Тип публикации:</span>
                {publications.map((el) => (
                  <MenuItem
                    key={el}
                    className={activePublic === el ? style.activeMenuItem : ""}
                    onClick={() => setActivePublic(el)}
                  >
                    {el}
                  </MenuItem>
                ))}
              </MenuList>
              <MenuList className={style.menuCategory}>
                <span style={{ fontWeight: "bold" }}>Категории:</span>
                {categoryOptions.map((el) => (
                  <MenuItem
                    key={el.name + el?.code}
                    className={
                      valueCategory.name === el.name ? style.activeMenuItem : ""
                    }
                    onClick={() => setValueCategory(el)}
                  >
                    {el.name}
                  </MenuItem>
                ))}
              </MenuList>
              {/*<Autocomplete*/}
              {/*    disableClearable*/}
              {/*    options={categoryOptions}*/}
              {/*    renderOption={(option) => option.name}*/}
              {/*    style={{width:'200px'}}*/}
              {/*    value={valueCategory}*/}
              {/*    onChange={(event, newValue) => {*/}
              {/*        setValueCategory(newValue)*/}
              {/*    }}*/}
              {/*    getOptionLabel={(option) => option.name}*/}
              {/*    renderInput={(params) => (*/}
              {/*        <TextField*/}
              {/*            {...params}*/}
              {/*            label="Категория"*/}
              {/*            InputProps={{ ...params.InputProps, type: 'search' }}*/}
              {/*        />*/}
              {/*    )}*/}
              {/*/>*/}
            </div>
          ) : (
            ""
          )}
        </div>
        <AddNewsModal
          openModal={openModal}
          closeModal={closeModal}
          initNews={initNews}
        />
        <Button
          type="primary"
          id={"buttonToTop"}
          onClick={goToTop}
          size={"large"}
          shape="circle"
          className={btnScrollActive ? "active" : ""}
          icon={<UpOutlined />}
        />
      </div>
    </>
  );
};
