import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import styles from './SearchSerNumSec.module.scss'
import {useStore} from "../StoreProvider";
import FormSearch from "../components/FormSearch";
import {runInAction} from "mobx";

const SearchSerNumSec = observer(({selKey: selectedKey}) => {
    const store = useStore()
    const selected = store.selected[selectedKey]

    useEffect(() => {
        store.fetchSeriesIfNeed()
    }, [store])


    let sectionNoResult = "Нет результатов"
    // form section list that will not include already selected sections
    // in other components (for same series and number)
    const sectionList = (() => {
        // don't change logic if series and number wasn't selected
        if (selected.series === null || selected.number === null) {
            // actually in this case array below always will be empty
            return selected.sectionList
        }

        // find already used sections for current series and number
        let usedSections = []
        for(let selKey in store.selected) {
            // skip current series, number, section
            if (selKey !== selectedKey) {
                const sel = store.selected[selKey]
                // add used section to array if series and number are equal to current values
                if (sel.series !== null && sel.series.id === selected.series.id &&
                    sel.number === selected.number &&
                    sel.section !== null
                ) {
                    usedSections.push(sel.section)
                }
            }
        }

        // array with not used sections
        const freeSections = []
        selected.sectionList.forEach((sec, index) => {
            if (!usedSections.find(s => s.id === sec.id)) {
                if (selected.sectionIndex === index) {
                    // we need to redefine section index from original array to new
                    selected.sectionIndex = freeSections.length
                }
                freeSections.push(sec)
            }
        })

        // deselect section if it was selected incorrect
        if (selected.section !== null) {
            if (!freeSections.find(s => s.id === selected.section.id)) {
                selected.section = null
                selected.sectionIndex = null
            }
        }

        if (usedSections.length > 0 && freeSections.length === 0) {
            sectionNoResult = "Секции закончились"
        }

        return freeSections
    })()

    return (
        <div className={styles.searchLocContainer}>
            <FormSearch
                label="Серия"
                list={store.series}
                value={selected.seriesIndex}
                textFieldName="name"
                loading={!store.seriesLoaded}
                onChange={(series, index) => {
                    // I can't explain why here occur a lot of waste renders if I'm not using runInAction.
                    // It happens when user select ВЛ80с where lot of numbers
                    runInAction(() => {
                        selected.seriesIndex = index
                        selected.series = series
                        selected.number = null
                        selected.section = null
                        selected.sectionIndex = null
                        selected.numberIndex = null
                        selected.numberList = []
                        selected.sectionList = []
                        selected.fetchNumberList(series.id)
                    })
                }}
            />
            <FormSearch
                label="Номер"
                list={selected.numberList}
                value={selected.numberIndex}
                loading={selected.series !== null && selected.numberListLoading}
                onChange={(number, index) => {
                    selected.numberIndex = index
                    selected.number = number
                    selected.section = null
                    selected.sectionIndex = null
                    selected.sectionList = []
                    selected.fetchSectionList(selected.series.id, number)
                }}
            />
            <FormSearch
                label="Секция"
                list={sectionList}
                value={selected.sectionIndex}
                textFieldName="name"
                noResultsMessage={sectionNoResult}
                loading={selected.series !== null && selected.number !== null && selected.sectionListLoading}
                onChange={(section, index) => {
                    selected.sectionIndex = index
                    selected.section = section
                }}
            />
        </div>
    );
})


export default SearchSerNumSec;