import React from "react";
import { observer } from "mobx-react-lite";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import parseHash from "../../utils/parseHash";
import auth from "../../helpers/auth";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

/**
 * @param p
 * @param p.onInvalidToken - fires if token is not defined through hash, not valid or expired
 * */
const Login = observer(({ onLogged, onInvalidToken, onError, initApi }) => {
  const history = useHistory();
  const location = useLocation();

  useDidFirstRender(() => {
    // prompt.show("Подключение к ЕКАСУТ", "", {loading: true})
    const hashValues = parseHash(decodeURI(location.hash));

    if (hashValues.error) {
      // do not use replaceAll for support older browsers
      onError({ message: hashValues.error.split("+").join(" ") });
      return;
    }
    const uncheckedToken = hashValues.token || auth.getToken();
    const token = auth.applyToken(uncheckedToken) ? uncheckedToken : undefined;
    if (!token || auth.getTokenExp() * 1000 < moment().valueOf()) {
      onInvalidToken();
      return;
    }
    if (hashValues.token) {
      // todo: remove only token from path
      history.replace("#");
    }

    initApi(token);

    if (auth.getSettings()) {
      onLogged(token);
    } else {
      console.log(auth.getSettings());
      onLogged(token);
      auth
        .loadUserSettings()
        .then(() => {
          // prompt.close()
          onLogged(token);
        })
        .catch((err) => {
          // prompt.close()
          onError(err);
          return catchNetworkErrors(err);
        });
    }
  });
  return null;
});

export default Login;
