import React, { useEffect, useState } from "react";
import { Form, Button, Icon } from "semantic-ui-react";
import { Popconfirm, notification } from "antd";
import moment from "moment";

import ConservationOrdersAddingRow from "../ConservationOrdersAddingRow/ConservationOrdersAddingRow";
import {
  getOrders,
  isConservationFieldValid,
} from "../locomotivesConservationUtils";
import styles from "./ConservationOrdersAddingForm.module.scss";
import DateField from "../../../shared/fields/DateField";
import ekasut from "../../../api/ekasut";

const initialFieldState = { isError: false, value: "" };
const initialGroupState = {
  currentDepartment: initialFieldState,
  currentQuantity: initialFieldState,
  currentMovingType: initialFieldState,
  currentPullType: initialFieldState,
  currentPurpose: initialFieldState,
};

const isValid = (formValues) => {
  const skipKeys = ["orderItemId", "completeDate", "predName"];
  let isValid = true;
  formValues.forEach((groupValues) => {
    console.log(groupValues);
    Object.entries(groupValues).forEach(([key, valueObj]) => {
      if (
        !skipKeys.includes(key) &&
        !isConservationFieldValid(key, valueObj.value)
      ) {
        isValid = false;
      }
    });
  });
  return isValid;
};
const validateFields = (formValues, setFormValues) => {
  formValues.forEach((groupValues) => {
    Object.entries(groupValues).forEach(([key, valueObj]) =>
      console.log(key, valueObj.value)
    );
  });
  setFormValues((prevFormValues) => {
    return prevFormValues.map((groupValues) => {
      const newGroupValues = {};
      Object.entries(groupValues).forEach(([key, valueObj]) => {
        newGroupValues[key] = {
          ...valueObj,
          isError: !isConservationFieldValid(key, valueObj?.value),
        };
      });
      return newGroupValues;
    });
  });
};
const onSubmit = (
  formValues,
  setFormValues,
  dateValue,
  isEditing,
  setIsOpened,
  orderId
) => {
  const isFormValid = isValid(formValues);
  if (isFormValid) {
    //submit
    if (!dateValue) {
      notification["error"]({
        message: "Предупреждение",
        description: "Установите планируемую дату окончания.",
        duration: 2,
      });
      return;
    }
    const preds = formValues.map((item) => ({
      predId: item.currentDepartment.value,
      locsCount: +item.currentQuantity.value,
      vdvingType: item.currentMovingType.value,
      tractionType: item.currentPullType.value,
      comment: item.currentPurpose.value,
      orderItemId: item.orderItemId,
      // completeDate: item.completeDate,
      // predName: item.predName
    }));
    const body = {
      planEndDate: new Date(Date.parse(dateValue) + 10800000)?.toISOString(), // added 3hours to local ISO
      preds,
    };

    //Перепроверить логику
    try {
      if (isEditing) {
        ekasut.locomotivesConservation.editOrder(orderId, body);
      } else {
        const postOrderLoc = async () => {
          await ekasut.locomotivesConservation.postOrder(body);
          getOrders();
        };
        postOrderLoc();
      }
    } catch {}
    // getOrders();
    setIsOpened(false);
  } else {
    validateFields(formValues, setFormValues);
  }
};

const convertEditingData = (data) =>
  data.preds?.map((item) => ({
    currentDepartment: { isError: false, value: item.predId },
    currentQuantity: { isError: false, value: item.locsCount },
    currentMovingType: { isError: false, value: item.vdvingType },
    currentPullType: { isError: false, value: item.tractionType },
    currentPurpose: { isError: false, value: item.comment },
    orderItemId: item.orderItemId,
    // completeDate: item.completeDate,
    // predName: item.predName,
  }));

const ConservationOrdersAddingForm = ({
  initialData = {},
  isEditing,
  setIsOpened,
}) => {
  const { orderId } = initialData;
  const [canSend, setCanSend] = useState(!isEditing);
  const [formValues, setFormValues] = useState(
    isEditing ? convertEditingData(initialData) : [initialGroupState]
  );
  const [dateValue, setDateValue] = useState(
    isEditing ? moment(initialData.planEndDate) : null
  );
  const addRow = () =>
    setFormValues((prevState) => [...prevState, initialGroupState]);
  const deleteRow = (index) => {
    if (formValues.length > 1) {
      setFormValues((prevState) => [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1),
      ]);
      return;
    }
    notification["error"]({
      message: "Предупреждение",
      description: "Нельзя удалить последнюю строку заказа.",
      duration: 2,
    });
  };
  const removeOrder = () => {
    try {
      ekasut.locomotivesConservation
        .deleteOrder(orderId)
        .then(() => getOrders());
    } catch {}
  };

  return (
    <Form
      onSubmit={() =>
        onSubmit(
          formValues,
          setFormValues,
          dateValue,
          isEditing,
          setIsOpened,
          orderId
        )
      }
    >
      {formValues.map((groupValues, index) => {
        const setGroupValues = (newGroupValueCb) =>
          setFormValues((prevState) => {
            const newState = [...prevState];
            newState[index] = newGroupValueCb(prevState[index]);
            return newState;
          });
        return (
          <div className={styles.rowWrapper} key={index}>
            <div className={styles.rowForm}>
              <ConservationOrdersAddingRow
                groupValues={groupValues}
                setGroupValues={setGroupValues}
                needRenderLabel={index === 0}
              />
            </div>
            <Button
              icon
              className={styles.deleteButton}
              basic
              type={"button"}
              onClick={() => deleteRow(index)}
            >
              <Icon name="delete" />
            </Button>
          </div>
        );
      })}
      <div className={styles.buttonsWrapper}>
        <Button onClick={addRow} type={"button"} basic>
          Добавить строку
        </Button>

        <div className={styles.dateAndSendWrapper} style={{ gap: 100 }}>
          {isEditing && (
            <>
              <Button
                type={"button"}
                basic
                color={"black"}
                disabled={canSend}
                onClick={() => setCanSend(true)}
              >
                Редактировать
              </Button>
              <Popconfirm
                placement="topRight"
                title={"Подтвердите отмену заказа"}
                onConfirm={removeOrder}
                okText="Да"
                cancelText="Нет"
              >
                <Button type={"button"} basic color={"red"}>
                  Отозвать
                </Button>
              </Popconfirm>
            </>
          )}
          <DateField
            dateFormat={"DD.MM.YYYY"}
            label={"Планирумая дата окончания"}
            className={styles.date}
            date={dateValue}
            onChange={(newDateValue) => setDateValue(newDateValue)}
          />
          <Button type={"submit"} primary disabled={!canSend}>
            Отправить
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ConservationOrdersAddingForm;
