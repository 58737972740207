import React, {useEffect, useRef} from "react";
import style from './DepotMarker.module.scss'
import useApiMethod from "../../../hooks/useApiMethod";
import ekasut from "../../../api/ekasut";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import {Table} from "semantic-ui-react";
import {Marker, Popup} from "react-leaflet";
import {divIcon} from 'leaflet';
import moment from "moment";

const loadIcon = <LoadingOutlined style={{fontSize: '24px', margin: '0 10px 0 0px', color: 'rgba(0,0,0,0.5)'}} spin/>;

export default function DepotMarker(
    {
        dorPrip, pointName, id, onPointClick, index, ...markerProps
    }
) {
    const parentMarker = useRef()
    const [open, setOpen] = React.useState(false)


    const [sendGetDepotDetail, depotDetailLoading, depotDetail] = useApiMethod(ekasut.regDepotsOnScheme.getDepotInfo, undefined, [])


    useEffect(() => {
        document.querySelector(`#depot-${index}`).addEventListener('click', (e) => {
            parentMarker.current.leafletElement._popup.options.autoClose = false
            parentMarker.current.leafletElement._popup.options.closeOnEscapeKey = false
            parentMarker.current.leafletElement._popup.options.closeOnClick = false;
            parentMarker.current.leafletElement._popup.options.keepInView = true
            parentMarker.current.leafletElement._popup.options.minWidth = 150
            parentMarker.current.leafletElement.openPopup()
            sendGetDepotDetail(id,dorPrip)
                .then(() => {
                    // parentMarker.current.leafletElement.openPopup()
                })
        })

    }, [])


    return (
        <Marker
            ref={parentMarker}
            draggable={false}
            {...markerProps}
            icon={divIcon({
                className: style.marker,
                html: `<div id="depot-${index}">${pointName ? pointName.split(' ')[0] : moment().valueOf()}</div>`
            })}>
            <Popup autoClose={false}>
                <div className={style.popupContainer}>
                    {depotDetailLoading ? <Spin indicator={loadIcon}/> : !depotDetail?.locomotiveSerAndCounts?.length ?
                        <span className={style.noData}>Нет данных</span> :
                        <Table compact className={'pr-table-for-popup'}>
                            <Table.Header className={style.tableHeader}>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={4}>
                                        {pointName}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body className={style.tableBody}>
                                {depotDetail.locomotiveSerAndCounts.map((e, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell singleLine>{e.nameSer}</Table.Cell>
                                        <Table.Cell singleLine>{e.locomotiveCount + ' ' + 'ед.'}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>}
                </div>
            </Popup>

        </Marker>
    )
}