import React from 'react';
import './EventsForAuditResults.scss'
import AreaHeader from "../../shared/headers/AreaHeader";
import Widget from "../../shared/Widget";
import {Accordion, Button, Checkbox, Pagination, Table} from "semantic-ui-react";
import {observer, useLocalStore} from "mobx-react-lite";
import useDidFirstRender from "../../hooks/useDidFirstRender";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import ekasut from "../../api/ekasut";
import moment from 'moment';
import {extractQuestions} from "./functions";
import {formatMoment, groupToMap} from "../../helpers/utils";
import AddEventForInconsistencies from "./AddEventForInconsistencies";
import {toJS} from "mobx";
import {dateFormats} from "../../Dictionaries";
import useFileInput from "../../hooks/useFileInput";
import AttachFileButton from "../../shared/AttachFileButton";
import EventDetailsModal from "./EventDetailsModal";
import {usePrompt} from "../../globalStore/useGlobalStore";

const EventsForAuditResults = observer(() => {
    const PAGE_ROWS = 10

    const prompt = usePrompt()
    const ls = useLocalStore(() => ({
        from: moment().startOf("year"),
        to: moment().add(1, "year").startOf("year"),
        advices: null,
        expandAdvice: null,
        events: null,
        addModal: {open: false, advices: []},
        selectedAdvices: [],
        audits: [],
        activePage: 1,
        eventDetailsModal: {open: false, event: {}}
    }))

    const [DownloadInput, getUserFile] = useFileInput()


    const filterAdvices = (advices, events) => {
        return advices.filter(advice => !events.some(event => event.adviceId === advice.id))
    }

    useDidFirstRender(() => {
        ekasut.audit.getAuditList({fromDate: ls.from, toDate: ls.to})
            // Save audit list
            .then(({data: audits}) => {
                ls.audits = audits
                return {audits}
            })
            // Get events
            .then(({audits}) => (
                Promise.all(audits.map(audit => ekasut.audit.getCorrectiveEvents({auditId: audit.auditId})))
                    .then((responses) => {
                        const events = []
                        ls.activePage = 1
                        responses.forEach(({data}, index) => {
                            data.forEach((event) => {
                                // Multiply each event to each advice
                                event.advices.forEach((advice) => {
                                    events.push({
                                        ...event,
                                        advices: undefined,
                                        adviceId: advice.code,
                                        adviceName: advice.name,
                                        auditId: audits[index].auditId
                                    })
                                })
                            })
                        })
                        ls.events = events
                        return {events, audits}
                    })
            ))
            // Get recommendation list
            .then(({events, audits}) => (
                Promise.all(audits.map(audit => ekasut.audit.getAdvices({auditId: audit.auditId,})))
                    .then((responses) => {
                        const advices = []
                        responses.forEach(({data}, index) => {
                            data.forEach(advice => {
                                advices.push({
                                    auditId: audits[index].auditId,
                                    id: advice.code,
                                    name: advice.name,
                                    reasons: null
                                })
                            })
                        })

                        ls.advices = filterAdvices(advices, events)
                    })
            ))
            .catch(catchNetworkErrors)
    })

    const groupByReasons = (questions) => {
        const groupedMap = groupToMap(questions, "reasonId")
        const groupedArr = []
        groupedMap.forEach((value, key) => groupedArr.push({
            id: key,
            name: value[0].reasonName,
            questions: value
        }))
        return groupedArr
    }

    const getTotalPages = () => Math.ceil(ls.events.length / PAGE_ROWS)
    const getPage = (events) => {
        return events.slice((ls.activePage - 1) * PAGE_ROWS, ls.activePage * PAGE_ROWS)
    }


    const showEventDetails = (event) => {
        ls.eventDetailsModal = {open: true, event}
    }
    return (
        <div className="app events-for-audit-results">
            <DownloadInput
                multiple={false}
            />
            <EventDetailsModal
                open={ls.eventDetailsModal.open}
                onClose={() => ls.eventDetailsModal.open = false}
                event={ls.eventDetailsModal.event}
            />
            <AddEventForInconsistencies
                open={ls.addModal.open}
                onClose={() => {
                    ls.addModal.open = false
                    ls.addModal.advices = []
                }}
                advices={ls.addModal.advices}
                onAdd={(data) => {
                    const map = groupToMap(ls.selectedAdvices, "auditId")
                    const advicesGroupByAuditId = [...map.keys()].map(auditId => ({
                        auditId, advices: map.get(auditId)
                    }))
                    ls.addModal = {open: false, advices: []}
                    Promise.all(advicesGroupByAuditId.map(item =>
                        ekasut.audit.postCorrectiveEvents({
                            auditId: item.auditId,
                            data: {
                                eventDate: data.date.format(),
                                eventName: data.eventName,
                                fio: data.fio,
                                resultName: data.result,
                                advices: item.advices.map(advice => advice.id)
                            }
                        }))
                    )
                        .then((responses) => {
                            responses.forEach(({data: eventId}, index) => {
                                const auditAdvices = advicesGroupByAuditId[index]
                                const newEvents = []
                                auditAdvices.advices.forEach(advice => {
                                    newEvents.push({
                                        adviceId: advice.id,
                                        adviceName: advice.name,
                                        auditId: auditAdvices.auditId,
                                        eventDate: data.date,
                                        eventId: eventId,
                                        eventName: data.eventName,
                                        fileId: null,
                                        fio: data.fio,
                                        resultName: data.result
                                    })
                                })
                                ls.events = [...ls.events, ...newEvents]
                                ls.selectedAdvices = []
                                ls.advices = filterAdvices(ls.advices, ls.events)
                            })
                            // updateEventTable(ls.audits)
                        })
                        .catch(catchNetworkErrors)
                }}
            />
            {ls.advices !== null && ls.advices.length > 0 && (
                <div className="left-group">
                    <AreaHeader content="Поступившие рекомендации"/>
                    <Widget
                        footer={(
                            <Button
                                content="Добавить мероприятия к выделенным рекомендациям"
                                basic className="button-basic-without-borders" icon="plus"
                                onClick={() => {
                                    if (ls.selectedAdvices.length === 0) {
                                        prompt.show("Выберите как минимум одну рекомендацию", "", {ok: true})
                                    } else {
                                        ls.addModal = {
                                            open: true,
                                            advices: ls.selectedAdvices
                                        }
                                    }
                                }}
                            />
                        )}
                    >
                        <Accordion className="advices">
                            {ls.advices.map((advice, adviceIndex) => (
                                <React.Fragment key={adviceIndex}>
                                    <Accordion.Title
                                        content={
                                            <Checkbox
                                                label={advice.name}
                                                checked={!!ls.selectedAdvices.find(adv => adv.id === advice.id)}
                                                onChange={(e, {checked}) => {
                                                    if (checked) {
                                                        ls.selectedAdvices.push(advice)
                                                    } else {
                                                        ls.selectedAdvices.splice(
                                                            ls.selectedAdvices.findIndex(adv => adv.id === advice.id), 1)
                                                    }
                                                }}
                                            />
                                        }
                                        active={ls.expandAdvice === adviceIndex} index={adviceIndex}
                                        onClick={(e) => {
                                            if (e.target.classList.contains("dropdown")) {
                                                if (advice.reasons === null) {
                                                    ekasut.audit.getQuestionsForAdvice({adviceId: advice.id})
                                                        .then(({data: processes}) => {
                                                            const questions = extractQuestions(processes)
                                                            advice.reasons = groupByReasons(questions)
                                                        })
                                                }
                                                ls.expandAdvice = ls.expandAdvice === adviceIndex ? null : adviceIndex
                                            }
                                        }}
                                    />
                                    <Accordion.Content
                                        className="reasons"
                                        active={ls.expandAdvice === adviceIndex}
                                    >
                                        {advice.reasons !== null && (
                                            advice.reasons.length === 0 ? "Несоответствия отсутствуют" : (
                                                advice.reasons.map((reason, reasonIndex) => (
                                                    <div key={reasonIndex}>{reason.name}</div>
                                                )))
                                        )}
                                    </Accordion.Content>
                                </React.Fragment>
                            ))}
                        </Accordion>
                    </Widget>
                </div>
            )}
            <div className="plan-events">
                <AreaHeader content="Запланированные мероприятия"/>
                <Widget>
                    <Table selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content="Рекомендация"/>
                                <Table.HeaderCell content="Мероприятие"/>
                                <Table.HeaderCell content="Срок"/>
                                <Table.HeaderCell content="Ответственный"/>
                                <Table.HeaderCell content="Результат"/>
                                <Table.HeaderCell content="Документ"/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {ls.events !== null && (
                                ls.events.length === 0
                                    ? <Table.Row><Table.Cell content="Не запланировано ни одного мероприятия" colSpan={6}/></Table.Row>
                                    : (getPage(ls.events).map((event, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell content={event.adviceName} onClick={() => showEventDetails(event)}/>
                                            <Table.Cell content={event.eventName} onClick={() => showEventDetails(event)}/>
                                            <Table.Cell content={formatMoment(event.eventDate, dateFormats.upToMinute)} onClick={() => showEventDetails(event)}/>
                                            <Table.Cell content={event.fio} onClick={() => showEventDetails(event)}/>
                                            <Table.Cell content={event.resultName} onClick={() => showEventDetails(event)}/>
                                            <Table.Cell content={(
                                                <AttachFileButton
                                                    fileId={event.fileId}
                                                    fileName={event.fileName}
                                                    isAttachAnotherFile={false}
                                                    onAttached={(e, file) => {
                                                        prompt.show("Сохранение файла", "", {loading: true})
                                                        ekasut.file.postFile(file)
                                                            .then(({data: fileId}) => {
                                                                ekasut.audit.postEventResult({eventId: event.eventId, fileId})
                                                                    .then(() => {
                                                                        prompt.show("Файл сохранен", "", {ok: true})
                                                                        event.fileId = fileId
                                                                        event.fileName = file.name
                                                                    })
                                                                    .catch((err) => {
                                                                        prompt.close()
                                                                        return catchNetworkErrors(err)
                                                                    })
                                                            })
                                                            .catch((err) => {
                                                                prompt.close()
                                                                return catchNetworkErrors(err)
                                                            })
                                                    }}
                                                />
                                            )}/>
                                        </Table.Row>
                                    )))
                            )}
                        </Table.Body>
                    </Table>
                    {ls.events !== null && ls.events.length > PAGE_ROWS && (
                        <div className="pagination-container">
                            <Pagination
                                activePage={ls.activePage}
                                totalPages={getTotalPages()}
                                onPageChange={(e, {activePage}) => {
                                    ls.activePage = activePage
                                }}
                            />
                        </div>
                    )}
                </Widget>
            </div>
        </div>
    );
})

export default EventsForAuditResults;