import React, {useState} from 'react'
import {Map} from "react-leaflet";
import LayerSelector, {layerNames} from "../../shared/LayerSelector";
import styles from './DepotsMap.module.scss'
import cn from "classnames";
import appStyles from "../../css/App.module.scss";
import ComponentMarker from "../../shared/ComponentMarker";
import {Segment, Header, Icon, Popup} from 'semantic-ui-react'
import AppModal from "../../shared/AppModal";
import DepotDetails from "./DepotDetails";
import Control from "react-leaflet-control";

const DepotsMap = ({depots}) => {
    const [mapRef, setMapRef] = useState(null)
    const [depotDetailsOpen, setDepotDetailsOpen] = useState(false)
    const [target, setTarget] = useState(null)


    return (
        <Map
            ref={(ref) => {
                // Save reference only once. Library will change it over time with null, but will back to old reference
                if (ref !== null && mapRef === null) {
                    setMapRef(ref)
                }
            }}
            className={appStyles.map}
            zoomControl={false}
            center={mapInitSettings.center}
            zoom={mapInitSettings.zoom}
            minZoom={mapInitSettings.minZoom}
            maxZoom={mapInitSettings.maxZoom}
        >
            <LayerSelector checkedLayerDef={layerNames.rzd}/>

            <Control position={'topright'}>
                <Segment>
                    <Header as={'h4'}>
                        Карточка эксплуатационного локомотивного депо
                    </Header>
                </Segment>
            </Control>

            <AppModal
                open={depotDetailsOpen}
                onClose={() => setDepotDetailsOpen(false)}
                size={'tiny'}
            >
                <Segment>
                    <DepotDetails info={target}/>
                </Segment>
            </AppModal>

            {/* Markers */}
            {depots.map((point, index) => (
                <ComponentMarker
                    key={index}
                    position={[point.lat, point.lon]}
                >
                    <Popup
                        trigger={
                            <Icon
                                name='warehouse'
                                circular
                                className={cn(styles.depotLabel)}
                                onClick={() => {
                                    setTarget(point)
                                    setDepotDetailsOpen(true)
                                }}
                            />
                        }
                    >
                        <Popup.Content>
                            {point.depoName}
                        </Popup.Content>
                    </Popup>
                </ComponentMarker>
            ))}
        </Map>
    )
}
export default DepotsMap


const mapInitSettings = {
    center: [57.778288, 79.126642],
    zoom: 4,
    minZoom: 4,
    maxZoom: 16
}