import React, {useState} from 'react';
import {List} from "antd";
import style from "../ControlBar.module.scss";
import moment from "moment";

export const UpdatedOn = ({item, taskData}) => {

    return (
        <>
            <List.Item.Meta
                title={<div className={style.rowTitle}><span>{item.title}</span></div>}
                description={<>
                    <span>{item.desc !== 'Нет' ? moment(item.desc).format('DD.MM.YYYY HH:mm') : item.desc}</span>
                    {taskData.updatedOnUserName ? <span>{` (${taskData.updatedOnUserName})`}</span> : null}
                </>}
            />
        </>
    )
}