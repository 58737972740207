import React, { useEffect, useState } from "react";
import {
  Modal,
  Menu,
  Button,
  Input,
  Popup,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import style from "./modalView.module.scss";
import { Input as InputANT } from "antd";
import AddIcon from "@material-ui/icons/Add";
import ekasut from "../../../../api/ekasut";
import catchNetworkErrors from "../../../../api/catchNetworkErrors";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { TemplItem } from "./CatsNSIComponents/TemplItem";
import { StatusList } from "./CatsNSIComponents/StatusList";

export const CatsNSI = ({ nsiOpen, setNsiOpen }) => {
  const [templateOpen, setTemplateOpen] = useState(false);
  const [editTemp, setEditTemp] = useState(false);
  const [newTempName, setNewTempName] = useState("");
  const [tempList, setTempList] = useState([]);
  const [activeTemp, setActiveTemp] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [newTemp, setNewTemp] = useState(false);
  const [addStatus, setAddStatus] = useState(false);
  const [newStatusName, setNewStatusName] = useState("");
  const [color, setColor] = useColor("hex", "#000000");
  const [oldActiveStatusList, setOldActiveStatusList] = useState([]);
  const [oldDoneStatusList, setOldDoneStatusList] = useState([]);
  const [oldCloseStatusList, setOldCloseStatusList] = useState([]);
  const [loaded, setLoaded] = useState(true)

  useEffect(() => {
    if (nsiOpen) {
      initTemplates();
      initOldActiveStatuses();
      initOldDoneStatuses();
      initOldCloseStatuses();
    }
  }, [nsiOpen]);

  const addNewTemp = () => {
    if (newTempName.length) {
      ekasut.nsiTaskManager
        .postNewTemp({
          templateName: newTempName,
          statuses: [],
        })
        .then(() => {
          initTemplates();
          setNewTempName("");
        });
    }
  };

  const updateStatusArray = () => {
    setLoaded(false)
    let preList = statusList;
    let catID = addStatus === "active" ? 1 : addStatus === "done" ? 2 : 3;
    if (newStatusName.length) {
      let cntPos = preList.filter(
        (status) => status.categoryId === catID
      ).length;
      preList.push({
        categoryId: catID,
        position: cntPos + 1,
        statusColorHex: color.hex,
        statusName: newStatusName,
      });
      setAddStatus(false);
      setNewStatusName("");
      ekasut.nsiTaskManager
        .updateTemp({
          templateId: activeTemp,
          templateName: tempList.filter((temp) => temp.code === activeTemp)[0]
            .name,
          statuses: preList,
        })
        .then((data) => {
          getStatuses(activeTemp);
          setColor({
            hex: "#000000",
            rgb: {
              r: 0,
              g: 0,
              b: 0,
            },
            hsv: {
              h: 0,
              s: 0,
              v: 0,
            },
          });
          initTemplates(false);
          data.status === 200 && setLoaded(true)
        });
    }
  };

  const deleteTempl = (templ) => {
    ekasut.nsiTaskManager.deleteTempl({ templateId: templ.code }).then(() => {
      setEditTemp(false);
      initTemplates();
      setTemplateOpen(false);
    });
  };

  const initTemplates = (update = true) => {
    ekasut.nsiTaskManager
      .getTemplatesAll()
      .then(({ data }) => {
        setTempList(
          data.map((e) => ({
            ...e,
            code: e.id,
          }))
        );
        if (update) {
          setTemplateOpen(false);
          setActiveTemp("");
        }
      })
      .catch(catchNetworkErrors)
      .finally(() => {});
  };

  const initOldActiveStatuses = () => {
    ekasut.nsiTaskManager
      .getStatuses({ categoryId: 1 })
      .then(({ data }) => {
        setOldActiveStatusList(
          data.map((status) => ({ text: status, value: status }))
        );
      })
      .catch(catchNetworkErrors)
      .finally(() => {});
  };

  const initOldDoneStatuses = () => {
    ekasut.nsiTaskManager
      .getStatuses({ categoryId: 2 })
      .then(({ data }) => {
        setOldDoneStatusList(
          data.map((status) => ({ text: status, value: status }))
        );
      })
      .catch(catchNetworkErrors)
      .finally(() => {});
  };

  const initOldCloseStatuses = () => {
    ekasut.nsiTaskManager
      .getStatuses({ categoryId: 3 })
      .then(({ data }) => {
        setOldCloseStatusList(
          data.map((status) => ({ text: status, value: status }))
        );
      })
      .catch(catchNetworkErrors)
      .finally(() => {});
  };

  const getStatuses = (code) => {
    ekasut.nsiTaskManager
      .getTemplateStatuses({
        id: code,
      })
      .then(({ data }) => {
        setStatusList(data.statuses);
      })
      .catch(catchNetworkErrors)
      .finally(() => {});
  };

  return (
    <div className={style.modalNSI}>
      <Modal
        open={nsiOpen}
        onClose={() => {
          setTemplateOpen(false);
          setNewTemp(false);
          setActiveTemp("");
          setNsiOpen(false);
        }}
        closeIcon
        size={"small"}
        style={{ maxWidth: "500px", maxHeight: "450px" }}
      >
        <Modal.Content className={style.nsiContent}>
          <div className={style.templates}>
            <Menu text vertical>
              <Menu.Item header className={style.templateHeader}>
                Шаблоны
              </Menu.Item>
              {tempList.map((e, i) => (
                <TemplItem
                  key={i}
                  templ={e}
                  initTemp={initTemplates}
                  statusList={statusList}
                  editTemp={editTemp}
                  deleteTempl={deleteTempl}
                  i={i}
                  setActiveTemp={setActiveTemp}
                  getStatuses={getStatuses}
                  setAddStatus={setAddStatus}
                  setTemplateOpen={setTemplateOpen}
                  activeTemp={activeTemp}
                />
              ))}
              {newTemp ? (
                <Menu.Item className={style.newTempInput}>
                  <InputANT
                    maxLength={15}
                    placeholder="Название"
                    autoFocus
                    onChange={(e) => {
                      setNewTempName(e.target.value);
                    }}
                  />
                  <div>
                    <Button
                      type="submit"
                      size="mini"
                      disabled={newTempName.length === 0}
                      onClick={() => {
                        setNewTemp(false);
                        addNewTemp();
                      }}
                      color={"blue"}
                    >
                      Создать
                    </Button>
                    <Button
                      type="submit"
                      size="mini"
                      onClick={() => {
                        setNewTemp(false);
                        setNewTempName("");
                      }}
                    >
                      Отмена
                    </Button>
                  </div>
                </Menu.Item>
              ) : null}
            </Menu>
            {!newTemp ? (
              <Button
                className={style.addTemplate}
                basic
                content={"Создать шаблон"}
                icon="plus"
                size={"tiny"}
                onClick={() => {
                  setNewTemp(true);
                }}
              />
            ) : null}
          </div>
          {templateOpen ? (
            <div className={style.templateItem}>
              <StatusList
                setAddStatus={setAddStatus}
                addStatus={addStatus}
                statusList={statusList}
                statusListName={"Статусы активных задач"}
                newStatusName={newStatusName}
                setNewStatusName={setNewStatusName}
                addStatusType={"active"}
                oldStatusList={oldActiveStatusList}
                setOldStatusList={setOldActiveStatusList}
                updateStatusArray={updateStatusArray}
                loaded={loaded}
                color={color}
                setColor={setColor}
                CatID={1}
                initStatuses={getStatuses}
                templData={{
                  name: tempList.filter((temp) => temp.code === activeTemp)[0]
                    .name,
                  code: activeTemp,
                }}
              />

              <StatusList
                setAddStatus={setAddStatus}
                addStatus={addStatus}
                statusList={statusList}
                statusListName={"Cтатусы выполненных задач"}
                newStatusName={newStatusName}
                setNewStatusName={setNewStatusName}
                addStatusType={"done"}
                oldStatusList={oldDoneStatusList}
                setOldStatusList={setOldDoneStatusList}
                updateStatusArray={updateStatusArray}
                loaded={loaded}
                color={color}
                setColor={setColor}
                CatID={2}
                initStatuses={getStatuses}
                templData={{
                  name: tempList.filter((temp) => temp.code === activeTemp)[0]
                    .name,
                  code: activeTemp,
                }}
              />

              <StatusList
                setAddStatus={setAddStatus}
                addStatus={addStatus}
                statusList={statusList}
                statusListName={"Статусы закрытых задач"}
                newStatusName={newStatusName}
                setNewStatusName={setNewStatusName}
                addStatusType={"close"}
                oldStatusList={oldCloseStatusList}
                setOldStatusList={setOldCloseStatusList}
                updateStatusArray={updateStatusArray}
                loaded={loaded}
                color={color}
                setColor={setColor}
                CatID={3}
                initStatuses={getStatuses}
                templData={{
                  name: tempList.filter((temp) => temp.code === activeTemp)[0]
                    .name,
                  code: activeTemp,
                }}
              />
            </div>
          ) : null}
        </Modal.Content>
      </Modal>
    </div>
  );
};
