import React, { PureComponent } from 'react';
import axios from 'axios';
import auth from "../../../helpers/auth";
import {
    Button,
    Dimmer,
    Dropdown,
    Form, Header, Icon,
    Input,
    Loader,
    Modal,
    Segment,
    Sidebar,
    Table
} from "semantic-ui-react";
import * as moment from "moment";


class Checklist extends PureComponent {
    state = {
        params: {},
        series: [],
        sections: [
            {key: 0, value: 0, text: 'Нет'},
            {key: 1, value: 1, text: 'А'},
            {key: 2, value: 2, text: 'Б'},
            {key: 3, value: 3, text: 'В'},
            {key: 4, value: 4, text: 'Г'},
        ],
        reason: null

    }

    reasons = [
        {key: null, value: null, text: 'Нет данных'},
        {key: 0, value: 0, text: 'Не проверил'},
        {key: 1, value: 1, text: 'Не подтвердилось'},
        {key: 2, value: 2, text: 'Причина устранена'}
    ]

    componentDidMount() {
        const me = this;

        axios.get(`${window.backendUrl}/api/nsi/locomotives/ser-tps`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    me.setState({series: response.data});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err.response.data.message});
            });
    }

    submitFilters() {
        const me = this;

        let { params } = this.state;

        this.props.hideSidebars();
        me.setState({isLoading: true})

        axios.get(`${window.backendUrl}/api/data/checklist/${params.ser}/${params.num}/${params.sectionIndex}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    me.setState({sectionEventsResp: response.data, activePage: 1, isLoading: false});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err.response.data.message});
            });
    }

    closeErrorModal = () => this.setState({ errorOccured: false })

    svpsFailsRenderer(fail) {
        let postfix = ''
        const svpsIndicator = {
            '1': 'Срабатывание защит в цепи КВ',
            '2': 'ПРИЗНАК ПРЕВЫШЕНИЯ ТЕМПЕРАТУРЫ ВОДЫ ИЛИ МАСЛА',
            '3': 'Пониженное давление масла',
            '4': 'Сброс нагрузки',
            '5': 'Переход на аварийное возбуждение',
            '6': 'Срабатывание защиты по максимальному току ВУ, внутреннему КЗ и тормозному току',
            '7': 'Срабатывание пожарной сигнализации',
            '8': 'Отклонение температуры масла на выходе из дизеля',
            '9': 'Отклонение температуры воды в горячем контуре охлаждения'
        };

        if (fail.svpsIndicatorId === 8 || fail.svpsIndicatorId === 9) {
            switch (fail.svpsIndicatorValue) {
                case 1:
                    postfix = ', отклонение'; break;
                case 2:
                    postfix = ', требует проверки/обновления ПО'; break;
                default:
                    break;

            }
        }

        return svpsIndicator[fail.svpsIndicatorId] + postfix
    }

    generateEventTable(event) {
        const me = this;
        let lis = []

        // remove '2' event svps
        let result = event.kasantEvents.concat(event.svpsEvents.filter(i => i.svpsIndicatorId !== 2).map(i => ({...i, failureName: me.svpsFailsRenderer(i)})))

        result.filter(e => (me.state.reason === null && (e.checkResult === null || e.checkResult === undefined)) || (e.checkResult === me.state.reason)).forEach((item, index) => {
                lis.push(<Table.Row key={index}>
                    <Table.Cell className="center aligned" style={{width: 125}}>{moment(item.dateRepairFinish).format("DD.MM.YYYY HH:mm")}</Table.Cell>
                    <Table.Cell style={{width: 800}}>{item.failureName}</Table.Cell>
                    <Table.Cell className="center aligned" style={{width: 125}}>
                        {/*maxEventDate - svps date*/}
                        {item.maxEventDate ? moment(item.maxEventDate).format("DD.MM.YYYY HH:mm:ss") : moment(item.eventDate).format("DD.MM.YYYY HH:mm:ss")}
                    </Table.Cell>
                    <Table.Cell className="center aligned">{item.eventCount}</Table.Cell>
                    <Table.Cell>{item.checkResult}</Table.Cell>
                    <Table.Cell>{item.checkDate}</Table.Cell>
                    <Table.Cell>{item.checkFio}</Table.Cell>
                </Table.Row>)
            }
        )

        return (<Table celled structured striped>
            <Table.Header>

                <Table.Row>
                    <Table.HeaderCell className="center aligned">Дата окончания<br/>ТО/ТР</Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">Событие</Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">Дата события</Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">Количество повторений</Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">Результат проверки</Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">Дата проверки</Table.HeaderCell>
                    <Table.HeaderCell className="center aligned">ФИО проверяющего</Table.HeaderCell>


                </Table.Row>
            </Table.Header>

            <Table.Body>
                {lis}
            </Table.Body>
        </Table>)
    }

    render() {
        const { hideSidebars } = this.props
        let { params, isLoading, sectionEventsResp } = this.state

        return (
            <div onClick={hideSidebars}>
                <Sidebar.Pusher onClick={this.props.hideSidebars}>
                    <Segment>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <Dropdown placeholder='Серия' fluid search selection options={this.state.series}
                                              onChange={(e, { value }) => {this.setState({ params: { ...params, ser: value} }); } }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input placeholder='№ локомотива'
                                           onChange={(e, { value }) => {
                                               var num = parseInt(value, 10);
                                               if(num) {
                                                   this.setState({ params: { ...params, num: num} });
                                               } else {
                                                   delete params.num;
                                                   this.setState({ params: { ...params} }); }
                                           }
                                           }/>
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown placeholder='Секция' fluid search selection options={this.state.sections}
                                              onChange={(e, { value }) => { this.setState({ params: { ...params, sectionIndex: value} }); } }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown placeholder='Результат проверки' fluid search selection options={this.reasons}
                                              onChange={(e, { value }) => { this.setState({ reason: value }); } }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Button type='submit' onClick={() => this.submitFilters()}>Применить</Button>
                                </Form.Field>

                            </Form.Group>

                        </Form>
                    </Segment>

                    <Segment basic className={`${isLoading ? 'loading-report' : ''}`}>


                        { isLoading ? (
                            <Dimmer active inverted>
                                <Loader inverted size='massive' content='Загрузка'/>
                            </Dimmer>
                        ) : (
                            sectionEventsResp && sectionEventsResp.events
                                        ? (
                                        sectionEventsResp.events.map((event, i) => (
                                            <Segment className='checklist-event' inverted key={i}>
                                                <Segment as='h3'>Проверка локомотива на {event.repairName} от {moment(event.dateRepairFinish).format("DD.MM.YYYY HH:mm")}</Segment>
                                                {this.generateEventTable(event)}
                                            </Segment>
                                        ))
                                    )
                                    : null

                        )}

                        <Modal open={this.state.errorOccured} closeOnDimmerClick={false} basic size='small'>
                            <Header icon='warning sign' content={`Ошибка ${this.state.errorCode}`} />
                            {/*<Modal.Content></Modal.Content>*/}
                            <Modal.Actions>

                                <Button onClick={this.closeErrorModal} inverted>
                                    <Icon name='checkmark' /> Закрыть
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    </Segment>
                </Sidebar.Pusher>
            </div>
        )
    }
}

export default Checklist;