import React, { Component } from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import { Button } from 'semantic-ui-react'
import { Modal, Form, Input } from 'semantic-ui-react'


class FunctionAddingModal extends Component {
    state = {
        errorOccured: false,
        isLoading: false,
        params: {}
    }


    componentDidMount() {}

    inputSetter(parameterName, value) {
        const {params} = this.state

        if (value) {
            params[parameterName] = value;
        } else {
            delete params[parameterName];
        }

        this.setState({params: params});
    }

    addFunction() {
        var me = this;
        const {params} = this.state
        // not empty object
        if (!(Object.keys(params).length === 0 && params.constructor === Object)) {
            me.setState({isLoading: true})

            axios.put(`${window.backendUrl}/api/roles/functions`, params)
                .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    } else {
                        me.setState({isLoading: false});
                        me.props.onClose();
                    }


                }
            )
                .catch(function (err) {
                    if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                        auth.logout()
                    }
                    me.setState({isLoading: false, errorOccured: true, errorCode: err});
                });

        }

    }

    render() {
        const {params} = this.state

        return (
            <Modal closeIcon size='tiny' open={this.props.addFunctionModalOpen} onClose={this.props.onClose}>
                <Modal.Header>Добавить функцию</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Form>
                            <Form.Field>
                                <label>Описание</label>
                                <Input placeholder='Название' onChange={(e, { value }) => {this.inputSetter('funcDesc', value)}}/>
                            </Form.Field>

                            <Form.Field>
                                <label>Имя</label>
                                <Input placeholder='func_name'
                                       onChange={(e, { value }) => {this.inputSetter('funcName', value)}}/>
                            </Form.Field>

                            <Button type='submit' disabled={(Object.keys(params).length === 0 && params.constructor === Object)} onClick={() => {this.addFunction()}}>Подтвердить</Button>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}

export default FunctionAddingModal;