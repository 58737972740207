import React from 'react'
import {Grid} from "semantic-ui-react";


export default function EventsGrid({children}) {
    return (
        <Grid doubling columns={5} divided='vertically'>
            {React.Children.map(children, child => (
                <Grid.Column>
                    {child}
                </Grid.Column>
            ))}
        </Grid>
    )
}