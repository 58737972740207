import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Control from "react-leaflet-control";
import { Button, Divider, Icon, Segment } from "semantic-ui-react";
import FleetStats from "../FleetStats";
import CollapsibleWrap from "../../../shared/CollapsibleWrap";
import RoadMaintenanceChart from "./RoadMaintenanceChart";
import { useZoomLevel, zoomLevels } from "../ZoomLevel";
import TractionFilterSelector from "../TractionFilterSelector";
import locomotiveServices from "../LocomotiveServices";
import ComponentMarker from "../../../shared/ComponentMarker";
import BarSetWithPopup from "../../../shared/BarSetWithPopup";
import ekasut from "../../../api/ekasut";
import TableForPopup from "../TableForPopup";
import usePrevious from "../../../hooks/usePrevious";
import './EpRpLayout.scss'
import DashboardLoading from "../DashboardLoading";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

export default function EpRpLayout({ zoom, resetMap, stats, onLoad, onLoaded }) {
    const [chartIsLoading, setChartIsLoading] = useState(true)
    const [chartIsCollapse, setChartIsCollapse] = useState(false)
    const [filter, setFilter] = useState(initFilter)
    const [uses, setUses] = useState(initUses)
    const [markers, setMarkers] = useState([])
    const [chartTitle, setChartTitle] = useState('По всей сети')
    const [selectedPoint, setSelectedPoint] = useState(initSelectedPoint)
    const [tooltipsIsLoading, setTooltipsIsLoading] = useState(true)

    const [points, setPoints] = useState({
        roads: [],
        depots: [],
    })
    const [tooltips, setTooltips] = useState({
        roads: [],
        depots: []
    })
    const level = useZoomLevel(zoom, 5)
    const [history, setHistory] = useState([])

    const prevPoints = usePrevious(points)
    const prevFilter = usePrevious(filter)
    const prevLevel = usePrevious(level)
    const prevUses = usePrevious(uses)
    const prevTooltipsIsLoading = usePrevious(tooltipsIsLoading)

    const handleClickOnPoint = useCallback((filter, id, name, level, uses) => {
        ekasut.to36Analytics.cancel()
        ekasut.to38Analytics.cancel()
        ekasut.epRpAnalytics.cancel()

        setSelectedPoint({ id, name, level })
        setChartTitle(name)
        setChartIsLoading(true)
        setChartIsCollapse(false)
        /*onLoad()*/
        loadHistory(id, filter, uses, level)
        /*.finally(() => {
            onLoaded()
        })*/
    }, [])

    const handleUsesChange = (uses) => {
        ekasut.to36Analytics.cancel()
        ekasut.to38Analytics.cancel()
        ekasut.epRpAnalytics.cancel()

        setUses(uses)
        setPoints({ roads: [], depots: [] })
        setHistory([])

        let correctFilter = filter
        if (uses === locomotiveServices.SWITCHING) {
            correctFilter = { ac: false, dc: false, ice: true }
        } else if (!filter.ac && !filter.dc && !filter.ice) {
            setChartIsLoading(false)
            setHistory([])
            return
        }
        onLoad()
        Promise.all([
            loadHistory(selectedPoint.id, correctFilter, uses, level),
            loadPointsAndTooltips(correctFilter, uses, true)
        ]).finally(() => {
            onLoaded()
        })
    }

    const returnBackToRzd = () => {
        resetMap()
        setSelectedPoint(initSelectedPoint)
        setChartTitle('По всей сети')
        /*onLoad()*/
        loadHistory(-1, initFilter, initUses)
        /*.finally(() => {
            onLoaded()
        })*/
    }

    const handleFilterChange = (filter) => {
        ekasut.to36Analytics.cancel()
        ekasut.to38Analytics.cancel()
        ekasut.epRpAnalytics.cancel()

        setFilter(filter)

        if (!filter.ac && !filter.dc && !filter.ice && !filter.acDc && (uses !== locomotiveServices.SWITCHING)) {
            setChartIsLoading(false)
            setHistory([])
            return
        }
        onLoad()
        Promise.all([
            loadHistory(selectedPoint.id, filter, uses, level),
            loadPointsAndTooltips(filter, uses, false)
        ]).finally(() => {
            onLoaded()
        })
    }

    useEffect(function loadInit() {
        setChartIsLoading(true)
        /*
        api.to36Analytics.cancel()
        api.to38Analytics.cancel()
        api.epRpAnalytics.cancel()
        api.nrTooltipsAnalytics.cancel()
        */
        onLoad()
        Promise.all([
            loadPointsAndTooltips(initFilter, initUses, true),
            loadHistory(-1, initFilter, initUses)
        ]).finally(() => {
            onLoaded()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadPointsAndTooltips = (filter, uses, isLoadTooltips) => {
        if (isLoadTooltips) {
            setTooltipsIsLoading(true)
            setTooltips({
                roads: [],
                depots: []
            })
        }
        const apiByUses = getApiByUses(uses)
        return Promise.all([
            apiByUses.getRoad(filter),
            apiByUses.getDepot(filter),
            isLoadTooltips ? ekasut.nrTooltipsAnalytics.getRoad(uses) : undefined,
            isLoadTooltips ? ekasut.nrTooltipsAnalytics.getDepo(uses) : undefined,
        ]).then(results => {
            ReactDOM.unstable_batchedUpdates(() => {
                setPoints({
                    roads: fixRoadsResponse(results[0].data, uses),
                    depots: fixDepotsResponse(results[1].data, uses)
                })
                if (isLoadTooltips) {
                    setTooltips({
                        roads: results[2].data,
                        depots: results[3].data
                    })
                    setTooltipsIsLoading(false)
                }
            })
        }).catch(catchNetworkErrors)
    }

    const loadHistory = (id, filter, uses, level) => {
        const apiHistory = getApiHistory(uses, level, id)
        setChartIsLoading(true)
        setHistory([])
        return apiHistory(filter, id)
            .then(results => {
                setChartIsLoading(false)
                // if response is empty
                if (results.status === 204) {
                    return
                }
                setHistory(results.data)
            }).catch(catchNetworkErrors)
    }


    useEffect(function updateMarkers() {

        // hack, prevent from too many updates
        if ((prevFilter === filter) && (prevLevel === level) &&
            (prevPoints === points) && (prevUses === uses) &&
            (prevTooltipsIsLoading === tooltipsIsLoading)) {
            return
        }
        let correctFilter = filter
        if (uses === locomotiveServices.SWITCHING) {
            correctFilter = { ac: false, dc: false, ice: true }
        }
        const mapByName = getFilteredPointsMapGroupedByName(selectPoints(points, level), correctFilter)
        const newMarkers = []
        mapByName.forEach((road) => {
            // Sort for displaying progress bars in the same order
            road.sort((a, b) => {
                return a.tipElektr - b.tipElektr
            })
            // Find tooltips for current road

            const tooltipsForRoad = selectTooltips(tooltips, level).find(val => val.kod === road[0].kod)
            const barsProps = []
            road.forEach(roadStats => {
                const tooltip = tooltipsForRoad !== undefined ? // if tooltips was loaded
                    tooltipsForRoad.byTipElektr.find(val => val.tipElektr === roadStats.tipElektr) :
                    undefined
                barsProps.push(formBarProps(roadStats, tooltip, tooltipsIsLoading))
            })
            // Position and name here are the same for all elements
            if (road[0].lat === null){
                road[0].lat = 0
            }
                const roadPos = [road[0].lat, road[0].lon]
            const roadName = road[0].name
            const roadId = road[0].kod
            newMarkers.push(
                <ComponentMarker position={roadPos} key={roadName} className='component-marker'>
                    <BarSetWithPopup
                        onClick={() => {
                            handleClickOnPoint(correctFilter, roadId, roadName, level, uses)
                        }}
                        barsProps={barsProps}
                    />
                </ComponentMarker>
            )
        })
        setMarkers(newMarkers)
    }, [prevFilter, filter, prevLevel, level, prevPoints, points,
        prevUses, uses, tooltips, tooltipsIsLoading, handleClickOnPoint,
        prevTooltipsIsLoading])

    return (
        <>
            <Control position='topright' className='pr-ep-rp-layout-main'>
                <Segment className='half-transparent-background '>
                    <FleetStats
                        stats={stats}
                        service={uses}
                        serviceOnChange={handleUsesChange}
                    />
                    <CollapsibleWrap
                        title={chartTitle}
                        onClick={() => setChartIsCollapse(!chartIsCollapse)}
                        isCollapse={chartIsCollapse}
                    >
                        {selectedPoint.id !== -1 && (
                            <Button icon labelPosition='left' size='mini' basic className='return-back-to-rzd'
                                onClick={returnBackToRzd}>
                                Назад
                                <Icon name='left arrow' />
                            </Button>
                        )}
                        <DashboardLoading
                            loading={chartIsLoading}
                        />
                        <RoadMaintenanceChart
                            history={history}
                            isRoad={selectedPoint.level === zoomLevels.ROADS}
                            roadName={selectedPoint.name}
                            service={uses}
                            tractionFilter={filter}
                        />
                        {uses !== locomotiveServices.SWITCHING && (
                            <Divider className='divider-below-chart' />
                        )}
                    </CollapsibleWrap>

                    {uses !== locomotiveServices.SWITCHING && (
                        <TractionFilterSelector
                            showAcDc={uses === locomotiveServices.PASSENGER}
                            filter={filter}
                            onChange={handleFilterChange}
                            groupElectricLocomotives={false}
                        />
                    )}
                </Segment>
            </Control>

            <Control position='bottomleft' className='pr-ep-rp-layout-hint half-transparent-background'>

                <span className='traction-type'> Т</span> - тепловозы,
                    <span className='traction-type'> ≈</span> - электр.пер.тока,
                    <span className='traction-type'> =</span> - электр.пост.тока,
                    <span className='traction-type'> Д</span> - электр.двойного питания
            </Control>

            {markers}
        </>
    )
}


const initFilter = {
    ac: true,
    dc: true,
    ice: true,
    acDc: true
}

const isTipElektrEnabledInFilter = (tipElektr, filter) => {
    switch (tipElektr) {
        case ekasut.tractionTypes.AC: {
            return filter.ac
        }
        case ekasut.tractionTypes.DC: {
            return filter.dc
        }
        case ekasut.tractionTypes.ICE: {
            return filter.ice
        }
        case ekasut.tractionTypes.AC_DC: {
            return filter.acDc
        }
        default: {
            return false
        }
    }
}

const getFilteredPointsMapGroupedByName = (roads, filter) => {
    const map = new Map()
    roads.forEach((el) => {
        if ((filter === undefined) || isTipElektrEnabledInFilter(el.tipElektr, filter)) {
            if (!map.has(el.name)) {
                map.set(el.name, [])
            }
            map.get(el.name).push(el)
        }
    })
    return map
}

const formBarProps = (stats, tooltip, tooltipsIsLoading) => {
    const { epToRp, name, delta } = stats

    let text
    let symbol

    switch (stats.tipElektr) {
        case ekasut.tractionTypes.AC: {
            text = 'Электровозы переменного тока'
            symbol = '≈'
            break
        }
        case ekasut.tractionTypes.DC: {
            text = 'Электровозы постоянного тока'
            symbol = '='
            break
        }
        case ekasut.tractionTypes.ICE: {
            text = 'Тепловозы'
            symbol = 'T'
            break
        }
        case ekasut.tractionTypes.AC_DC: {
            text = 'Электровозы двойного питания'
            symbol = 'Д'
            break
        }
        default: {
            console.error('Unknown traction type')
            return {}
        }
    }
    const props = {
        value: epToRp,
        maxValue: 2.5,
        leftValue: symbol + ' ' + delta,
        popupClassName: 'pr-ep-rp-layout-progress-popup ',
        colorPicker: (percent, value, maxValue) => {
            let color
            if (value < 1.4154) {
                color = '#21ba45'
            } else if (value < 1.4828) {
                color = '#fbbd08'
            } else {
                color = '#db2828'
            }
            return color
        },
        width: 100,
        popupContent: (
            (tooltip !== undefined ?
                <TableForPopup
                    title={name}
                    subtitle={text}
                    isLoading={false}
                    data={[
                        {
                            text: 'Отказы за текущие сутки',
                            value: tooltip.failCnt,
                            unit: 'ед.'
                        },
                        {
                            text: 'Готовность к эксплуатации',
                            value: tooltip.kge,
                            unit: ''
                        },
                        {
                            text: 'В неплановом ремонте',
                            value: tooltip.nrCurrent,
                            unit: 'тяг.ед.'
                        },
                        {
                            text: 'Средний перепростой на ТО/ТР',
                            value: tooltip.avgPereprostHours,
                            unit: 'ч.'
                        },
                        {
                            text: 'Средний простой в ОЖ.РЕМ',
                            value: tooltip.avgRepWaitHours,
                            unit: 'ч.'
                        }
                    ]}
                />
                :
                //	Show only if tooltip was loaded and it is undefined
                <TableForPopup
                    title={name}
                    subtitle={text}
                    isLoading={tooltipsIsLoading}
                    // To avoid
                    data={[{
                        text: 'Подробная информация отсутствует',
                        value: '',
                        unit: ''
                    }]}
                />
            )
        )
    }
    return props
}
const fixDepotsResponse = (depots, uses) => {
    // Add manually new field, api response doesn't have tipElektr for switcher
    if (uses === locomotiveServices.SWITCHING) {
        depots.forEach((depot) => {
            depot.tipElektr = ekasut.tractionTypes.ICE
        })
    }
    return depots
}
const fixRoadsResponse = (roads, uses) => {
    if (uses === locomotiveServices.SWITCHING) {
        roads.forEach((road) => {
            road.tipElektr = ekasut.tractionTypes.ICE
        })
    }
    return roads
}
const getApiByUses = (uses) => {
    let apiByUses
    switch (uses) {
        case locomotiveServices.SWITCHING: {
            apiByUses = ekasut.to36Analytics
            break
        }
        case locomotiveServices.PASSENGER: {
            apiByUses = ekasut.to38Analytics
            break
        }
        case locomotiveServices.FREIGHT: {
            apiByUses = ekasut.epRpAnalytics
            break
        }
        default: {
            apiByUses = null
        }
    }
    return apiByUses
}
const getApiHistory = (uses, level, id) => {
    const apiByUses = getApiByUses(uses)
    let apiHistory
    if (id === -1) {
        apiHistory = (filter) => apiByUses.getRzdHistory(filter)
    } else {
        if (level === zoomLevels.ROADS) {
            apiHistory = (filter, id) => apiByUses.getRoadHistory(id, filter)
        } else {
            apiHistory = (filter, id) => apiByUses.getDepoHistory(id, filter)
        }
    }
    return apiHistory
}
const selectTooltips = (tooltips, level) => {
    return level === zoomLevels.ROADS ? tooltips.roads : tooltips.depots
}
const selectPoints = (points, level) => {
    return level === zoomLevels.ROADS ? points.roads : points.depots
}
const initUses = locomotiveServices.FREIGHT
const initSelectedPoint = {
    id: -1,
    name: null,
    level: null
}