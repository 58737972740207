import React from 'react';
import {observer} from "mobx-react-lite";
import styles from './BaseInfo.module.scss'
import cn from 'classnames'
import {momentIfStr} from "../../helpers/utils";
import {dateFormats} from "../../Dictionaries";
import { HeaderLocoDetails } from '../../views/CommissionInspection/Inspection/LocoDetails/HeaderLocoDetails/HeaderLocoDetails';

const BaseInfo = observer(({detail, className, info, setAddProt}) => {
    return (
        <div className={cn(className, styles.fields)}>
            
            <Field name="Приписка" value={detail.vname} required/>
            <Field name="Движение" value={detail.naimVidDv} required/>
            <Field name="Дислокация" value={detail.disloc} required/>
            <Field name="Состояние" value={detail.naimSost} required/>
            <Field name="Дата состояния" value={detail.dateOp} valueToString={toStringDateStr} required/>
            <Field name="Номер поезда" value={detail.nomPoezd} valueToString={toStringNumber}/>
            <Field name="Вес, т." value={detail.vesBr} valueToString={toStringNumber}/>
            <Field name="Норма веса" value={detail.nVes} valueToString={toStringNumber}/>
            <Field name="Крит. норма" value={detail.nVesKr} valueToString={toStringNumber}/>
            <Field name="Длина" value={detail.uslDlPoezd} valueToString={toStringNumber}/>
            <Field
                name="Маршрут"
                value={detail.direction}
                valueToString={(direction) => {
                    direction = toStringAndTrim(direction)
                    if (direction !== "") {
                        const dirs = direction.split('->')
                        return <>
                            {dirs.map((it, index) => {
                                it = it.trim()
                                const isLast = dirs.length - 1 === index
                                return <React.Fragment key={index}>{it}{!isLast ? " ->" : ""}<br/></React.Fragment>
                            })}
                        </>
                    }
                    return null
                }}
                checker={value => value !== null}
            />
            <Field name="Машинист" value={detail.driver}/>
            <Field name="План. оконч. рем." value={detail.planReleaseDate} valueToString={toStringDateStr}/>
            <Field
                name="Группа риска"
                value={getGroupRiskStr(detail.sickNeplanCount, detail.sickKasantCount, detail.sickKind1Count)}
            />
            
        </div>
    )
})

// in old DB where data come from 0 means null
const toStringNumber = val => toStringAndTrim(val === 0 ? null : val)

const toStringDateStr = val => {
    const m = momentIfStr(val)
    return m.isValid() ? m.format(dateFormats.upToMinute) : ""
}

const getGroupRiskStr = (nep, kas, kind) => {
    if (nep || kas || kind) {
        return `НР - ${toStringAndTrim(nep)}, ОТС - ${toStringAndTrim(kas)} (1 кат. - ${toStringAndTrim(kind)})`
    }
    return ""
}

const toStringAndTrim = (value) => (
    (value !== undefined && value !== null) ? value.toString().trim() : ""
)

const Field = ({name, value, required, valueToString, checker}) => {
    value = valueToString === undefined ? toStringAndTrim(value) : valueToString(value)
    const isValid = checker === undefined ? value !== "" : checker(value)

    return (
        required || isValid ? (
            <div className={styles.field}>
                <div>
                    {name.toUpperCase() + ":"}
                </div>
                <div className={cn(!isValid && styles.noInfo)}>
                    {isValid ? value : "Нет информации"}
                </div>
            </div>
        ) : null
    )
}
Field.defaultProps = {
    required: false
}

export default BaseInfo;