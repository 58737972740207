import React from 'react';
import {Icon} from "semantic-ui-react";
import {Fab, Action} from 'react-tiny-fab'
import styles from './index.scss'
import cn from 'classnames'
import { color } from 'highcharts';

/**
 * main: {text, rotate, icon, onClick}
 *
 * actions: [{text, icon, onClick}]
 */
const FloatButton = (
    {main, actions, backgroundColor}
) => {
    return (
        <div className={cn("app shared float-button", main.rotate ? "rotate" : "not-rotate")}>
            <Fab
                mainButtonStyles={{backgroundColor}}
                position={{
                    bottom: 100,
                    right: 100,
                }}
                icon={<Icon name={main.icon} style={{ color: main.iconColor}}/>}
                event="hover"
                key={-1}
                alwaysShowTitle={false}
                text={main.text}
                onClick={main.onClick}
            >
                {actions.reverse().map((act, index) => (
                    <Action
                        text={act.text}
                        onClick={act.onClick}
                        key={index}
                        style={{backgroundColor}}
                    >
                        <Icon name={act.icon}/>
                    </Action>
                ))}
            </Fab>
        </div>
    );
};
FloatButton.defaultProps = {
    main: {
        rotate: true,
        text: "Main buttton",
        icon: "angle left",
        iconColor: "#ffffff",
        onClick: () => {
        }
    },
    actions: [],
    backgroundColor: "#709ac2"
}
export default FloatButton;