import React from 'react';
import style from './LocoElectronicPassport.module.scss';
import {Pie} from '@ant-design/charts';
import OperationsOfBarrierFunctions from "./OperationsOfBarrierFunctions/OperationsOfBarrierFunctions";
import {SideBarCommissionInspectionModal} from "../SideBar/SideBarCommissionInspectionModal";


const data = [
  {
    type: 'Отказ',
    value: 27,
  },
  {
    type: 'Погода',
    value: 25,
  },
  {
    type: 'Время',
    value: 18,
  },
  {
    type: 'Нарушения',
    value: 15,
  },
];
const config = {
  appendPadding: 10,
  data: data,
  angleField: 'value',
  colorField: 'type',
  radius: 1,
  innerRadius: 0.6,
  width: 200,
  height: 150,
  label: {
    type: 'inner',
    offset: '-50%',
    content: '{value}',
    style: {
      textAlign: 'center',
      fontSize: 14,
    },
  },
  interactions: [{type: 'element-selected'}, {type: 'element-active'}],
  statistic: {
    title: false,
    content: {
      style: {
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 18,
      },
      content: '2021',
    },
  },
};


export const LocoElectronicPassport = ({info}) => {

  return (
    <div className={style.wrap}>
      <div className={style.column}>
        <div className={style.itemColumn}>
          <div className={style.title}>
            Устройства и оборудование секции
          </div>
          <div
          >
            <OperationsOfBarrierFunctions info={info}/>
          </div>
        </div>
      </div>
    </div>
  )
}