import React from 'react';
import {Button, Dimmer, Loader, Modal, Segment} from "semantic-ui-react";
import cn from 'classnames'
import styles from './index.module.scss'
import {toJS} from "mobx";

/**
 */
const PromptModal = ({loading, header, subheader, open, onApply, onDecline, onClose, yesNo, ok}) => {
    return (
        <Modal
            className={cn("alt-semantic", styles.component)}
            closeIcon={!loading} basic open={open} size="small" onClose={!loading ? onClose : undefined}
        >
            <Modal.Content>
                <Segment className={cn("widget")}>
                    <div className="prompt-header">{header}</div>
                    <div className="prompt-subheader">{subheader}</div>
                    {loading && (
                        <Segment className="prompt-loading">
                            <Dimmer active inverted>
                                <Loader />
                            </Dimmer>
                        </Segment>
                    )}
                    {yesNo && !loading && (
                        <div className="prompt-yes-no">
                            <Button className="button-link" content="Да" onClick={onApply}/>
                            <Button className="button-link" content="Нет" onClick={onDecline}/>
                        </div>
                    )}
                    {ok && !loading && (
                        <div className="prompt-ok">
                            <Button className={cn("button-link")} content="ОК" onClick={onClose}/>
                        </div>
                    )}
                </Segment>
            </Modal.Content>
        </Modal>
    );
};

/** Make control PromptModal easier. Created with intent to use in mobx store */
export const promptModel = (defHeader = "", defSubheader = "", defOpen = false) => {
    return {
        // Props that controls PromptModal component. Should be supplied to PromptModal
        props: {
            header: defHeader,
            subheader: defSubheader,
            open: defOpen,
            onApply: () => null,
            onDecline: () => null,
            loading: false,
            yesNo: false,
            ok: false,
            onClose: () => null,
        },
        // open prompt
        show (header, subheader = "",
              {loading = false, onClose = () => null, yesNo = false, ok = false, onApply = () => null, onDecline = () => null} = {}) {
            this.props = {header, subheader, loading, yesNo, onApply, onDecline, ok, open: true, onClose}
        },
        // close prompt
        close () {
            this.props.open = false
        }
    }
}

export default PromptModal;