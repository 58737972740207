import React from 'react';
import styles from './index.module.scss'
import moment from 'moment'
import CalendarForMonth from "../CalendarForMonth";

/**
 *
 * @param year - moment date
 * @param dayOnClick - call with clicked day
 * @param daysOptions - [{day, fill, outline}]
 * @returns {*}
 * @constructor
 */
const CalendarForYear = ({year, dayOnClick, daysOptions}) => {
    return (
        <div className={styles.calendarForYear}>
            <div className="months-row">
                <CalendarForMonth
                    month={moment().month(0)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(1)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(2)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(3)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
            </div>
            <div className="months-row">
                <CalendarForMonth
                    month={moment().month(4)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(5)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(6)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(7)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
            </div>
            <div className="months-row">
                <CalendarForMonth
                    month={moment().month(8)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(9)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(10)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
                <CalendarForMonth
                    month={moment().month(11)}
                    dayOnClick={dayOnClick}
                    daysOptions={daysOptions}
                />
            </div>
            {/*{getMonths(startOfYear, endOfYear).map((startOfMonth, index) => (
                <CalendarForMonth
                    key={index}
                    month={startOfMonth}
                />
            ))}*/}
        </div>
    );
}

export default CalendarForYear;