import React from 'react';
import {Grid, Header, Modal, Statistic} from "semantic-ui-react";

const DepoInfo = ({open, onClose, depoInfo}) => {
    if (depoInfo === null) {
        return null
    }

    const stallHelper = (stalls) => {
        const stallColors = ['green', 'closed', 'grey', 'yellow', 'red'];

        return stalls.map((stall, j) => {
            if (stall.title || stall.description) {
                let prepare = stall.title.split(/\r\n/gi).map(el => <p>{el}</p>)
                return (
                    <Modal trigger={<Grid.Column style={{cursor: 'pointer', fontSize: '0.75em'}}
                                                 color={stallColors[stall.state]}>{prepare}</Grid.Column>}>
                        <Modal.Header>{prepare}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {stall.description}
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>)
            } else {
                return <Grid.Column color={stallColors[stall.state]}>{stall.num}</Grid.Column>
            }
        })
    }

    const fillEmptiness = (length) => {
        let table = []

        for (let i = 0; i < length; i++) {
            table.push(<Grid.Column className='empty'>&nbsp;</Grid.Column>)
        }
        return table
    }

    return (
        <Modal open={open} closeIcon size='large' onClose={onClose}>
            <Modal.Content>
                <Modal.Description>
                    <Grid centered padded columns='two'>
                        <Grid.Row textAlign='center'>
                            <Grid.Column width={2}>
                                <Header as='h3'>{depoInfo.depoName}</Header>
                                <Header as='h3'>Ремонтные позиции</Header>
                                <Statistic.Group>
                                    <Statistic horizontal>
                                        <Statistic.Value>{depoInfo.free}</Statistic.Value>
                                        <Statistic.Label>Свободно</Statistic.Label>
                                    </Statistic>
                                    <Statistic horizontal>
                                        <Statistic.Value>{depoInfo.total}</Statistic.Value>
                                        <Statistic.Label>Всего</Statistic.Label>
                                    </Statistic>
                                </Statistic.Group>
                            </Grid.Column>
                            <Grid.Column width={14} style={{maxWidth: '1000px'}}>
                                <Grid columns='equal' className='ditches-grid'>
                                    {depoInfo.ditches.map((ditch, i) => (
                                        <Grid.Row key={i} textAlign='center'>
                                            <Grid.Column textAlign='right' style={{width: 90, padding: '14px 5px'}}>
                                                {ditch.name}
                                            </Grid.Column>
                                            {stallHelper(ditch.stalls)}
                                            {fillEmptiness(depoInfo.maxStalls - ditch.stalls.length)}
                                        </Grid.Row>
                                    ))}
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
};

export default DepoInfo;