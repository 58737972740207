import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { appendPlusToPositiveNumber } from '../../../helpers/utils'

export default class RoadMaintenancePerHour extends Component {
	COLOR_RED = '#db2828' // red
	COLOR_GREEN = '#21ba45' // green

	constructor(props) {
		super(props)

		const { nepCategories, nrpCategories, nepHeight, nrpHeight } = props
		this.chartOptions = {
			chart: {
				type: 'bar',
				animation: false,
				marginLeft: 240,
				width: 600,
			},
			title: {
				text: ''
			},
			exporting: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			series: [],
			plotOptions: {
				series: {
					groupPadding: 0,
					pointWidth: 20,
					showInLegend: false, 
				}
			},
			yAxis: {
				title: {
					text: 'Тяговые единицы'
				}, 
				allowDecimals: false,
			},
			tooltip: {
				formatter: function () {
					return (
						`${this.x}: ${appendPlusToPositiveNumber(this.y)}`
					)
				}
			}
		}
	
		this.nepChartOptions = Highcharts.merge(this.chartOptions, {
			chart: {
				height: nepHeight,
			},
			xAxis: {
				categories: nepCategories
			}
		})
	
		this.nrpChartOptions = Highcharts.merge(this.chartOptions, {
			chart: {
				height: nrpHeight,
			},
			xAxis: {
				categories: nrpCategories
			}
		})
	}

	

	getColorizeData = (data) => {
		return data.map((el) => {
			return {y: el, color: this.getColorForVal(el)}
		})
	}

	getColorForVal(val) {
		return val > 0 ? this.COLOR_RED : this.COLOR_GREEN
	}

	updateNepChartOptions = (data, value) => {
		data = this.getColorizeData(data)

		this.nepChartOptions.series = [{ data, animation: false, }]
		this.nepChartOptions.title.text = `Изменения НЭП - всего 
			<span style='color: ${this.getColorForVal(value)}'>${appendPlusToPositiveNumber(value)}</span>`
	}

	updateNrpChartOptinos = (data, value) => {
		data = this.getColorizeData(data)

		this.nrpChartOptions.series = [{ data, animation: false, }]
		this.nrpChartOptions.title.text = `Изменения НРП - всего 
			<span style='color: ${this.getColorForVal(value)}'>${appendPlusToPositiveNumber(value)}</span>`
	}


	render() {
		var { nepData, nrpData, nepValue, nrpValue} = this.props

		this.updateNepChartOptions(nepData, nepValue)
		this.updateNrpChartOptinos(nrpData, nrpValue)

		return (
			<>
				<HighchartsReactOptions options={this.nepChartOptions} />
				<HighchartsReactOptions options={this.nrpChartOptions} />
			</>
		)
	}
}

var HighchartsReactOptions = (props) => {
	return (
		<HighchartsReact
			className='chart'
			highcharts={Highcharts}
			options={props.options}
		/>
	)
}