import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App.js";
import registerServiceWorker from "./helpers/registerServiceWorker";
import axios from "axios";

// IE 11 fix
import "core-js/modules/es.array.find";
// Chrome 45 fix
import "core-js/modules/es.array.includes";
// IE 11 fix
import "core-js/modules/es.object.assign";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.promise";
import "core-js/modules/es.promise.finally";
import "core-js/modules/web.url-search-params";
import StoreProvider from "./globalStore/StoreProvider";
import NewApp from "./views/App";
import { unregister as unregisterServiceWorker } from "./helpers/registerServiceWorker";
// test
axios
  .get(`/config.json`)
  .then((response) => {
    if (response.status === 200) {
      let { data } = response;
      if (typeof data === "string") {
        data = JSON.parse(data);
      }

      window.backendUrl = data.backendUrl;
      window.dbsyncUrl = data.dbsyncUrl;
      window.pentahoReportsUrl = data.pentahoReportsUrl;
      window.mapUrl = data.mapUrl;
      window.digitalDepotUrl = data.digitalDepotUrl;
      window.brigades = data.brigades;
      window.dashboardURL = data.dashboard;
      window.flexibleReportURL = data.flexibleReportURL;
      window.tchdView = data.tchdView;
      window.investView = data.investView;
      window.tzrem = data.tzrem;
      window.linObCt = data.linObCt;
      window.bdopURL = data.bdopURL;
      window.nep = data.nep;
      window.testLogoutTimer = data.testLogoutTimer;

      ReactDOM.render(
        <StoreProvider>
          <NewApp />
        </StoreProvider>,
        document.getElementById("root")
      );
      unregisterServiceWorker();
    }
  })
  .catch(function (err) {
    console.log("Error :-S", err);
  });
