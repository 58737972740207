import React, { useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import ekasut from "../../../api/ekasut";
import style from "./ModalForwardedMessage.module.scss";
import { defaultAvatar } from "../Chats";

const { Search } = Input;

export const ModalForwardedMessage = ({
  modalForwardedMessage,
  setModalForwardedMessage,
  setActiveChat,
  setCheckList,
  setReply,
  messages,
  setForwardedMessage,
  selectedMessages,
  setSelectedMessages,
}) => {
  const [chatList, setChatList] = useState([]);

  const getChatsList = async () => {
    try {
      const { data } = await ekasut.chats.getChatsList();
      setChatList(data);
    } catch (e) {
      console.log(e);
    }
  };

  const onSearch = () => {};

  const cancel = () => {
    setModalForwardedMessage(false);
  };

  useEffect(() => {
    if (modalForwardedMessage) {
      getChatsList();
    }
  }, [modalForwardedMessage]);

  return (
    <Modal
      title="Выберите получателя ..."
      visible={modalForwardedMessage}
      onCancel={cancel}
      footer={[
        <Button key="back" onClick={cancel}>
          Отмена
        </Button>,
      ]}
    >
      <Search
        placeholder="Поиск пользователя"
        onSearch={onSearch}
        enterButton
      />
      <div className={style.box}>
        {chatList.map((el) => (
          <div
            className={style.item}
            key={"forward" + el.chatId}
            onClick={() => {
              setReply(false);
              setActiveChat(el);
              setForwardedMessage(
                messages?.data?.filter((el) =>
                  selectedMessages?.find((e) => e === el?.messageId)
                )
              );
              setSelectedMessages([]);
              setCheckList(false);
              setModalForwardedMessage(false);
            }}
          >
            <div className={style.avatar}>
              {el.avatar ? (
                <img
                  src={el.avatar}
                  alt={el.chatName}
                  className={style.avatarImg}
                />
              ) : (
                defaultAvatar(el.chatName, el.chatUserList.length > 2)
              )}
            </div>
            <div className={style.content}>
              <span>{el.chatName}</span>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
