import React, {useState} from 'react';
import {List, Select} from "antd";
import style from "../ControlBar.module.scss";
import ekasut from "../../../../../../api/ekasut";

const {Option} = Select;


const priorityOptions = [{name: 'Низкий', value: 1}, {name: 'Средний', value: 2}, {
    name: 'Высокий',
    value: 3
}, {name: 'Нет', value: 0}]

const priorityArr = ['Низкий', 'Средний', 'Высокий']

export const Priority = ({item, taskData, setTaskData}) => {

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)

    const updatePriroty = (priority) => {
        setLoading(true)
        ekasut.taskboard.updateTaskPriority(taskData.workGroupId, taskData.taskNumber, priority)
            .then(({data}) => {
                setLoading(false)
                setEditMode(false)
                setTaskData(data)
            })
    }


    return (
        <>
            <List.Item.Meta
                title={<div className={style.rowTitle}><span>{item.title}</span>
                    {taskData.workGroupUserRoleId !== 3 ? <>
                    <span
                        onClick={() => {
                            setEditMode(true)
                        }}
                        className={style.editBtn}>ред.</span>
                    </> : null}
                </div>}
                description={
                    editMode ? <Select autoFocus loading={loading} defaultValue={item.desc} style={{width: 150}}
                                       onBlur={() => {
                                           setEditMode(false)
                                       }}
                                       onChange={(value) => {
                                           updatePriroty(value)
                                       }}>
                            {priorityOptions.map((el, i) => (
                                <Option key={i} value={el.value}>{el.name}</Option>
                            ))}
                        </Select> :
                        <span>{item.desc !== 'Нет' ? priorityArr[item.desc - 1] : item.desc}</span>}
            />
        </>
    )
}