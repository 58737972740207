import React, {useRef} from 'react'
import {Dropdown} from "semantic-ui-react";

const DelayedSearch = ({options, delay, minCharacters, typeEnd, onSearchChange, ...selectProps}) => {
    const timerId = useRef(null)

    return (
        <Dropdown
            search={(options) => {
                return options
            }}
            selection
            options={options}
            minCharacters={minCharacters}
            onSearchChange={(e, {searchQuery}) => {
                if (onSearchChange !== undefined) {
                    onSearchChange(e, {searchQuery})
                }
                if (searchQuery.length < minCharacters) {
                    return
                }
                clearTimeout(timerId.current)
                timerId.current = setTimeout(() => {
                    typeEnd(searchQuery)
                }, delay)
            }}
            {...selectProps}
        />
    )
}
export default DelayedSearch
DelayedSearch.defaultProps = {
    typeEnd: () => {}
}