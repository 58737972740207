import React, {useEffect, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {Task} from './Task';
import Card from '@material-ui/core/Card';
import {Button, CardHeader, IconButton, Popover} from '@material-ui/core';
import style from "../boardComponents.module.scss";
import {AddTaskCard} from "./AddTaskCard";
import {useHistory} from "react-router-dom";
import {Divider} from "antd";
import InfiniteScroll from 'react-infinite-scroller';
import {SkeletonBoardItem} from "../SkeletonBoardItem/SkeletonBoardItem";
import ekasut from "../../../../api/ekasut";
import {moreTasks} from "../../dashboardsLogic/board";
import {board} from "../../TaskListState";

const getItemStyle = (isDragging, draggableStyle) => {
    return {
        padding: 8,
        marginBottom: 8,
        ...draggableStyle
    };
};

export const Column = ({section, activeBoard, addTask, setAddTask, parentId}) => {

        const [parentEl, setParentEl] = useState(false);
        const [loading, setLoading] = useState(false);

        const history = useHistory()

        const [page, setPage] = useState(1)


        const downloadMore = (page) => {
            if (loading) {
                return
            }

            setLoading(true)
            moreTasks(section.fieldId, board.workGruopId, board.sortBordType, board.groupType, page + 1)
            setPage(page + 1)
        }
        useEffect(() => {
            setParentEl(document.body.querySelector(parentId))
        }, [])

        useEffect(() => {
            setLoading(false)
        }, [section.taskPage.data])

        return (
            <div key={`${section.fieldId}-inside-col`}>
                {addTask === section.fieldId ?
                    <AddTaskCard setAddTask={setAddTask} activeBoard={activeBoard} section={section}/> : null}
                <InfiniteScroll
                    loadMore={() => {
                        downloadMore(page)
                    }}
                    hasMore={section.taskPage.data.length < section.taskPage.totalRecords}
                    loader={<SkeletonBoardItem fieldId={section.fieldId}/>}
                    endmessage={<Divider plain>Задач больше нет 🤐</Divider>}
                    useWindow={false}
                    getScrollParent={() => parentEl}
                >
                    {section.taskPage.data.map((task, index) => {
                        return (
                            <React.Fragment key={`${task.taskNumber}-${task.workGroupId}-fragment`}>
                                {task.workGroupUserRoleId !== 3 ?
                                    <Draggable draggableId={`${task.taskNumber}-${task.workGroupId}`} key={task.taskNumber}
                                               index={index}>
                                        {(provided, snapshot) => (
                                            <Card
                                                onClick={() => {
                                                    history.push(`/taskboard/${task.workGroupId}-${task.taskNumber}`)
                                                }}
                                                className={style.cardTask}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <CardHeader
                                                    className={style.taskHeader}
                                                    title={task.title}
                                                />
                                                <Task task={task}/>
                                            </Card>
                                        )}
                                    </Draggable> :
                                    <Card
                                        onClick={() => {
                                            history.push(`/taskboard/${task.workGroupId}-${task.taskNumber}`)
                                        }}
                                        className={style.cardTaskStatic}
                                    >
                                        <CardHeader
                                            className={style.taskHeader}
                                            title={task.title}
                                        />
                                        <Task task={task}/>
                                    </Card>}
                            </React.Fragment>
                        )
                    })}
                </InfiniteScroll>
            </div>
        )
    }
;