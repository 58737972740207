import React, { useState } from "react";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import styles from "./ReportsOBL.module.css"; // Import your CSS module
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import OBLMap from "./Map/OBLMap";

const data = [
  {
    region: "Октябрьская",
    completion: 70,
    totalTO2: 34,
    needsWashing: 23,
    washed: 18,
  },
  {
    region: "Московская",
    completion: 17,
    totalTO2: 30,
    needsWashing: 30,
    washed: 0,
  },
  {
    region: "Октябрьская",
    completion: 70,
    totalTO2: 34,
    needsWashing: 23,
    washed: 18,
  },
  {
    region: "Московская",
    completion: 17,
    totalTO2: 30,
    needsWashing: 30,
    washed: 0,
  },
];

const options = {
  chart: {
    type: "column",
    height: "200px",
    backgroundColor: "rgba(0,0,0,0)",
  },
  title: {
    text: "",
  },
  exporting: {
    enabled: false,
  },
  xAxis: {
    title: {
      text: null, // Remove the x-axis label
    },
    labels: {
      enabled: false, // Hide x-axis labels
    },
    visible: false,
  },
  yAxis: {
    title: {
      text: null, // Remove the y-axis label
    },
    labels: {
      enabled: false, // Hide y-axis labels
    },
    visible: false,
  },
  legend: {
    enabled: false, // Hide the legend
  },
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        format: "{y}", // Display the actual value on top of each bar
      },
    },
  },
  series: [
    {
      data: [2, 5, 7],
    },
  ],
};

const RegionData = ({ regionData, handleOnClick }) => (
  <div className={styles.block} onClick={handleOnClick}>
    <h3 className={styles.title}>{regionData.region}</h3>
    <p className={styles.executeContent}>
      Выполнение: {regionData.completion}%
    </p>

    <Grid className={styles.info}>
      <Grid className={styles.chartBlock} xs={6}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Grid>
      <Grid className={styles.additionalInfo}>
        <p className={styles.content}>
          Всего на ТО-2: {regionData.totalTO2} (сек)
        </p>
        <p className={styles.content}>
          Подлежит обмывке: {regionData.needsWashing} (сек)
        </p>
        <p className={styles.content}>Обмыто: {regionData.washed} (сек)</p>
      </Grid>
    </Grid>
  </div>
);

const ReportsOBL = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [showOBLMap, setShowOBLMap] = useState(false);

  const handleOnClick = () => {
    setShowOBLMap(!showOBLMap);
  };

  const tabContents = [
    <Grid className={styles.tabContents} container spacing={2}>
      {data.map((regionData) => (
        <Grid item xs={3} key={regionData.region}>
          <RegionData regionData={regionData} handleOnClick={handleOnClick} />
        </Grid>
      ))}
    </Grid>,
    <div>
      <h1>Анализ работы организации</h1>
      <p>
        В данном разделе будет отображаться информация об анализе работы
        организации.
      </p>
    </div>,
  ];

  return (
    <>
      <Tabs value={activeTab} onChange={(event, value) => setActiveTab(value)}>
        <Tab label="Учет факта обмывки локомотивов" />
        <Tab label="Анализ работы организации" />
      </Tabs>
      {!showOBLMap ? (
        <>
          <Typography variant="h4" component="h2" align="center">
            Отчет от 01.02.2024 по 10.02.2024
          </Typography>
          {tabContents[activeTab]}
        </>
      ) : (
        <OBLMap />
      )}
    </>
  );
};

export default ReportsOBL;
