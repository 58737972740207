import React, {useState} from 'react';
import style from "../ControlBar.module.scss";
import {List, DatePicker, Space} from "antd";
import moment from "moment";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import ekasut from "../../../../../../api/ekasut";
import {Icon} from "semantic-ui-react";

const {RangePicker} = DatePicker;

export const PlanDate = ({item, taskData, setTaskData}) => {

    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [dates, setDates] = useState([])

    const updateDates = () => {
        setLoading(true)
        ekasut.taskboard.updateTaskDate(taskData.workGroupId, taskData.taskNumber, dates[0], dates[1])
            .then(({data}) => {
                setLoading(false)
                setEditMode(false)
                setTaskData(data)
            })
    }

    return (
        <>
            <List.Item.Meta
                title={<div className={style.rowTitle}><span>{item.title}</span>
                    {taskData.workGroupUserRoleId !== 3 ? <>
                        {editMode ? <div className={style.actionBtn}><Icon disabled={!dates.length} name={'checkmark'}
                                                                           color={'green'} onClick={() => {
                            updateDates()
                        }
                        }/> <Icon name={'delete'} color={'red'} onClick={() => {
                            setEditMode(false)
                        }
                        }/></div> : <span
                            onClick={() => {
                                setEditMode(true)
                            }}
                            className={style.editBtn}>ред.</span>}
                    </> : null}
                </div>}
                description={
                    editMode ? <Space direction="vertical" size={12}>
                            <RangePicker
                                loading={loading}
                                onChange={(dates, dateStrings) => {
                                    setDates([dates[0].format('YYYY-MM-DDTHH:mm:ss'), dates[1].format('YYYY-MM-DDTHH:mm:ss')])
                                }}
                                locale={locale}
                                autoFocus
                                allowClear={false}
                                allowEmpty={[false, false]}
                                format={['D.MM.YYYY', 'D.MM.YYYY']}
                                defaultValue={[taskData.planStartDate ? moment(item.planStartDate) : moment(), taskData.planEndDate ? moment(item.planEndDate) : moment().add(1, 'days')]}/>
                        </Space> :
                        <span>{`${item.planStartDate !== 'Нет' ? moment(item.planStartDate).format('DD.MM.YYYY') : item.planStartDate} - ${item.planEndDate !== 'Нет' ? moment(item.planEndDate).format('DD.MM.YYYY') : item.planEndDate}`}</span>}
            />
        </>
    )
}