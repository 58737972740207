import React, {PureComponent} from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import {Icon, Table, Popup} from 'semantic-ui-react'
import {Segment, Button} from 'semantic-ui-react'
import {Input, Dropdown} from 'semantic-ui-react'
import {Form} from 'semantic-ui-react'
import {Modal, Grid, Header} from 'semantic-ui-react'
import {Statistic} from 'semantic-ui-react'
import FloatButton from "../../shared/FloatButton";
import SkeletonPage from "../../shared/SkeletonPage/SkeletonPage";

import * as moment from 'moment';
import 'moment/locale/ru';

import {motionModeOptions, pereprostOptions} from '../../Dictionaries'
import ekasut from "../../api/ekasut";


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

class TransferFromTr extends React.Component {
    state = {
        activePage: 1,
        modalOpen: false,
        errorOccured: false,
        sectionsResp: [],
        isLoading: false,
        modalLoading: false,
        params: {},
        series: [],
        depos: [],
        disloc: [],
        status: [],
        repairDepos: [],
        repairTypes: [],
        filterShow: false,
        allTable: [],
        onPageLimit: 100
    }
    durationFormat = "y[г] M[м] d[д] HH[ч] mm[м]"


    constructor(props) {
        super(props);
        this.filterClear = this.filterClear.bind(this);
        this.filterStateChange = this.filterStateChange.bind(this);
        this.nameSostFilters = this.nameSostFilters.bind(this);
        this.submitFilters = this.submitFilters.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    filterStateChange() {
        this.setState({filterShow: !this.state.filterShow})
    }

    filterClear() {
        this.setState({sectionsResp: this.state.allTable, params: {}, onPageLimit: 100})
    }

    componentDidMount() {
        var me = this;
        var params = {};

        document.querySelector('.content-container').addEventListener('scroll', () => {
            if (document.querySelector('.content-container').scrollTop + document.querySelector('.content-container').clientHeight >= document.querySelector('.content-container').scrollHeight) {
                this.setState({onPageLimit: this.state.onPageLimit + 100})
            }
        })

        var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

        me.setState({isLoading: true})

        ekasut.oldReqs.fromRepairReport(queryString)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        me.setState({isLoading: false, errorOccured: true, errorCode: response.status});

                        return;
                    }
                    me.setState({
                        sectionsResp: response.data,
                        allTable: response.data,
                        isLoading: false,
                        disloc: response.data.map(e => e.naimDepo).filter((item, pos) => {
                            return response.data.map(e => e.naimDepo).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                        status: response.data.map(e => e.naimSost).filter((item, pos) => {
                            return response.data.map(e => e.naimSost).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                        depos: response.data.map(e => e.naimDepoPrip).filter((item, pos) => {
                            return response.data.map(e => e.naimDepoPrip).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                        series: response.data.map(e => e.serName).filter((item, pos) => {
                            return response.data.map(e => e.serName).indexOf(item) == pos
                        }).map((e, i) => {
                            return {text: e, value: e, key: i}
                        }),
                    });
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }

                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }

    getDepoInfo(id) {
        var me = this;

        me.setState({modalLoading: true})

        ekasut.oldReqs.depoMap(id)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    me.setState({depoInfo: response.data, modalLoading: false, modalOpen: true});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err.response.data.message});
            });
    }

    handleClose = () => this.setState({modalOpen: false})

    nameSostFilters(typeSost) {
        let array = this.state.sectionsResp
        array = array.filter(e => e.naimSost === typeSost)
        this.setState({sectionsResp: array})
    }

    submitFilters() {
        const me = this;
        const {params} = this.state;
        let merged = {...params};
        let arrToFilter = me.state.sectionsResp

        if (!merged.locNum && !merged.serName && !merged.naimDepo && !merged.naimDepoPrip && !merged.naimSost) {
            me.setState({sectionsResp: me.state.allTable})
        }

        if (merged.locNum) {
            arrToFilter = arrToFilter.filter(e => RegExp(merged.locNum).exec(e.locNum))
            me.setState({sectionsResp: arrToFilter})
        }
        if (merged.serName && merged.serName.length > 0) {
            if (merged.serName.length > 1) {
                let arr = []
                for (let i = 0; i < merged.serName.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.serName === merged.serName[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            } else {
                arrToFilter = arrToFilter.filter(e => e.serName === merged.serName[0])
                me.setState({sectionsResp: arrToFilter})
            }
        }
        if (merged.naimDepo && merged.naimDepo.length > 0) {
            if (merged.naimDepo.length > 1) {
                let arr = []
                for (let i = 0; i < merged.naimDepo.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.naimDepo === merged.naimDepo[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            }
            arrToFilter = arrToFilter.filter(e => e.naimDepo === merged.naimDepo[0])
            me.setState({sectionsResp: arrToFilter})
        }
        if (merged.naimDepoPrip && merged.naimDepoPrip.length > 0) {
            if (merged.naimDepoPrip.length > 1) {
                let arr = []
                for (let i = 0; i < merged.naimDepoPrip.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.naimDepoPrip === merged.naimDepoPrip[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            }
            arrToFilter = arrToFilter.filter(e => e.naimDepoPrip === merged.naimDepoPrip[0])
            me.setState({sectionsResp: arrToFilter})
        }
        if (merged.naimSost && merged.naimSost.length > 0) {
            if (merged.naimSost.length > 1) {
                let arr = []
                for (let i = 0; i < merged.naimSost.length; i++) {
                    arr.push(...arrToFilter.filter(e => e.naimSost === merged.naimSost[i]))
                }
                arrToFilter = arr
                me.setState({sectionsResp: arrToFilter})
            }
            arrToFilter = arrToFilter.filter(e => e.naimSost === merged.naimSost[0])
            me.setState({sectionsResp: arrToFilter})
        }

    }

    changePage(event, data) {
        this.setState({activePage: data.activePage})
    }

    stallHelper = (stalls) => {
        const stallColors = ['green', 'closed', 'grey', 'yellow', 'red'];

        return stalls.map((stall, j) => {
            if (stall.title || stall.description) {
                let prepare = stall.title.split(/\r\n/gi).map(el => <p>{el}</p>)
                return (
                    <Modal key={j} trigger={<Grid.Column style={{cursor: 'pointer', fontSize: '0.75em'}}
                                                         color={stallColors[stall.state]}>{prepare}</Grid.Column>}>
                        <Modal.Header>{prepare}</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                {stall.description}
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>)
            } else {
                return <Grid.Column color={stallColors[stall.state]}>{stall.num}</Grid.Column>
            }
        })
    }

    // fill paddings in 'less stalls' ditches
    fillEmptiness = (length) => {
        let table = []

        for (let i = 0; i < length; i++) {
            table.push(<Grid.Column className='empty'>&nbsp;</Grid.Column>)
        }
        return table
    }

    closeErrorModal = () => this.setState({errorOccured: false})

    render() {
        var me = this,
            lis = [];

        if (this.state.sectionsResp.length > 0) {
            var target = this.state.sectionsResp.slice((this.state.activePage - 1) * this.state.onPageLimit, this.state.activePage * this.state.onPageLimit)

            target.forEach(function (item, index) {
                let section = `${item.locNum}`;

                lis.push(<Table.Row key={index}>

                    <Popup className='stall-info' trigger={<Table.Cell
                        textAlign='center'>{((me.state.activePage - 1) * me.state.onPageLimit) + (index + 1)}</Table.Cell>}>
                        <Popup.Content>
                            <p><b>План:</b> {item.planEp}</p>
                            <p><b>Факт:</b> {item.factEp}</p>
                        </Popup.Content>
                    </Popup>

                    <Table.Cell textAlign='center'>{item.serName}</Table.Cell>
                    <Table.Cell textAlign='center'>{section}</Table.Cell>

                    <Table.Cell textAlign='center'>{item.naimDepoPrip}</Table.Cell>
                    <Table.Cell textAlign='center'>{item.naimVidDv}</Table.Cell>
                    <Table.Cell textAlign='center'>
                        {<span className="depo-href" onClick={e => {
                            me.getDepoInfo(item.kodDepo)
                        }}>{item.naimDepo}</span>}
                    </Table.Cell>
                    <Table.Cell className="center aligned">

                        <Grid columns={2} padded divided>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    {item.stoiloNo}
                                </Grid.Column>
                                <Grid.Column textAlign='center'>
                                    {item.ditchNo}
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>
                    </Table.Cell>
                    <Table.Cell onClick={() => {
                        if (item.naimSost) {
                            me.nameSostFilters(item.naimSost)
                        }
                    }} className="center aligned table-filter">{item.naimSost}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{moment.duration(item.repairAwait).format(me.durationFormat)}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{moment(item.dateRepairBegin).format("DD.MM.YYYY HH:mm")}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{moment.duration(item.waitInRepair).format(me.durationFormat)}</Table.Cell>
                    <Table.Cell className="center aligned">{item.timeN}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{item.pereprostTime ? moment.duration(item.pereprostTime).format(me.durationFormat) : null}</Table.Cell>
                    <Table.Cell className="center aligned">{item.pereprostReason}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{item.predictOutDate ? moment(item.predictOutDate).format("DD.MM.YYYY HH:mm:ss") : null}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{item.dateReadyToAcc ? moment(item.dateReadyToAcc).format("DD.MM.YYYY HH:mm:ss") : null}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{item.dateFirstPresentation ? moment(item.dateFirstPresentation).format("DD.MM.YYYY HH:mm:ss") : null}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{item.acceptTimeSum ? moment.duration(item.acceptTimeSum).format(me.durationFormat) : null}</Table.Cell>
                    <Table.Cell
                        className="center aligned">{item.acceptLagSum ? moment.duration(item.acceptLagSum).format(me.durationFormat) : null}</Table.Cell>
                    <Table.Cell className="center aligned">{item.remCount}</Table.Cell>

                </Table.Row>);
            });
        }

        var params = this.state.params;
        var depoInfo = this.state.depoInfo;

        return (
            <div>
                <div style={{margin: '15px'}}>
                    <span style={{fontSize: '14pt', color: '#C1C8D8'}}>Выдача из ТОиР</span>
                </div>
                {me.state.filterShow ? <Modal closeIcon size='mini' open={this.state.filterShow}
                                              onClose={me.filterStateChange}>
                        <Modal.Content>
                            <Modal.Description>
                                <Form>
                                    <Form.Field>
                                        <label>№ локомотива</label>
                                        <Input placeholder='№ локомотива'
                                               value={this.state.params.locNum ? this.state.params.locNum : ''}
                                               onChange={(e, {value}) => {
                                                   var num = parseInt(value, 10);
                                                   if (num) {
                                                       this.setState({params: {...params, locNum: num}});
                                                   } else {
                                                       delete params.locNum;
                                                       this.setState({params: {...params}});
                                                   }
                                               }
                                               }/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Серия</label>
                                        <Dropdown placeholder='Серия' fluid multiple search selection
                                                  options={this.state.series}
                                                  value={this.state.params.serName ? this.state.params.serName : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, serName: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Приписка</label>
                                        <Dropdown placeholder='Приписка' fluid multiple search selection
                                                  options={this.state.depos}
                                                  value={this.state.params.naimDepoPrip ? this.state.params.naimDepoPrip : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, naimDepoPrip: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Депо</label>
                                        <Dropdown placeholder='Депо' fluid multiple search selection
                                                  options={this.state.disloc}
                                                  value={this.state.params.naimDepo ? this.state.params.naimDepo : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, naimDepo: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Вид</label>
                                        <Dropdown placeholder='Вид' fluid multiple search selection
                                                  options={this.state.status}
                                                  value={this.state.params.naimSost ? this.state.params.naimSost : []}
                                                  onChange={(e, {value}) => {
                                                      this.setState({params: {...params, naimSost: value}});
                                                  }}
                                        />
                                    </Form.Field>
                                    <div style={{
                                        marginBottom: 10,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end'
                                    }}>
                                        <Button className="button-link" onClick={me.filterClear}>Сброс</Button>
                                        <Button className="button-primary" onClick={() => {
                                            me.submitFilters();
                                            me.filterStateChange()
                                        }}>Применить</Button>
                                    </div>
                                </Form> </Modal.Description> </Modal.Content></Modal> :
                    <div> {!me.state.isLoading ? <FloatButton
                        main={{
                            text: "",
                            rotate: true,
                            icon: "angle left",
                        }}
                        actions={[
                            {
                                text: "Настройка фильтров",
                                icon: "filter",
                                onClick: () => {
                                    me.filterStateChange()
                                }
                            },
                        ]}
                    /> : null}</div>}


                <Segment basic className={`report-container ${(this.state.isLoading ? 'loading-report' : '')}`}>

                    {this.state.modalLoading ? <SkeletonPage view={'ModalTransfer'}/> : null}

                    {this.state.isLoading ? (
                        <SkeletonPage view={'TransferFrom'}/>
                    ) : (
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="center aligned" style={{width: 60}}
                                                      rowSpan='2'>№</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned"
                                                      colSpan='4'>Локомотив</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" colSpan='10'>Ремонт</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" colSpan='5'>Приемка</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell className="center aligned">Серия</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Номер</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Приписка</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Вид движения</Table.HeaderCell>

                                    <Table.HeaderCell className="center aligned">Депо</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Ремонтная позиция</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Вид</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Простой в ожидании</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Дата начала</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Простой в ремонте</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Норма простоя, ч</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Перепростой</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Причина перепростоя</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Прогнозируемая дата
                                        выдачи</Table.HeaderCell>

                                    <Table.HeaderCell className="center aligned">Готовность к приемке</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Первое предъявление</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Общее время устранения
                                        замечаний</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Общее время ожидания
                                        приемки</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Количество
                                        предъявлений</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {lis.length > 0 ? lis : <Table.Row>
                                    <Table.Cell className="center aligned" colSpan='20'>Нет элементов удовлетворяющих
                                        условиям поиска</Table.Cell>
                                </Table.Row>}
                            </Table.Body>
                        </Table>
                    )}

                    <Modal open={this.state.errorOccured} closeOnDimmerClick={false} basic size='small'>
                        <Header icon='warning sign' content={`Ошибка ${this.state.errorCode}`}/>
                        <Modal.Actions>

                            <Button onClick={this.closeErrorModal} inverted>
                                <Icon name='checkmark'/> Закрыть
                            </Button>
                        </Modal.Actions>
                    </Modal>

                    {(depoInfo) ? (
                        <Modal className='sld-loading' closeIcon size='large' open={this.state.modalOpen}
                               onClose={this.handleClose}>
                            <Modal.Content scrolling>
                                <Modal.Description>
                                    <Grid centered padded columns='two'>
                                        <Grid.Row textAlign='center'>
                                            <Grid.Column width={3}>
                                                <Header as='h3'>{depoInfo.depoName}</Header>
                                                <Header as='h3'>Ремонтные позиции</Header>
                                                <Statistic.Group>
                                                    <Statistic horizontal>
                                                        <Statistic.Value>{depoInfo.free}</Statistic.Value>
                                                        <Statistic.Label>Свободно</Statistic.Label>
                                                    </Statistic>
                                                    <Statistic horizontal>
                                                        <Statistic.Value>{depoInfo.total}</Statistic.Value>
                                                        <Statistic.Label>Всего</Statistic.Label>
                                                    </Statistic>
                                                </Statistic.Group>
                                            </Grid.Column>
                                            <Grid.Column width={13} style={{maxWidth: '1000px'}}>
                                                <Grid columns='equal' className='ditches-grid'>
                                                    {depoInfo.ditches.map((ditch, i) =>
                                                        <Grid.Row key={i} textAlign='center'>
                                                            <Grid.Column textAlign='right' style={{
                                                                width: 90,
                                                                padding: '14px 5px'
                                                            }}>{ditch.name}</Grid.Column>
                                                            {this.stallHelper(ditch.stalls)}

                                                            {this.fillEmptiness(depoInfo.maxStalls - ditch.stalls.length)}

                                                        </Grid.Row>
                                                    )}
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Modal.Description>
                            </Modal.Content>
                        </Modal>
                    ) : null}
                </Segment>
            </div>
        )
    }
}

export default TransferFromTr