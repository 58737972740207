import React, { useEffect, useMemo, useState } from "react";
import { Table } from "semantic-ui-react";
import FloatButton from "../../../shared/FloatButton";
import { observer, useLocalStore } from "mobx-react-lite";
import ekasut from "../../../api/ekasut";
import { formatMoment } from "../../../helpers/utils";
import { dateFormats } from "../../../Dictionaries";
import usePagination from "../../../hooks/usePagination";
import LoaderMessage from "../../../shared/LoaderMessage";
import LocomotiveModal from "../../../shared/LocomotiveModal";
import "./PolygonTable.scss";
import { getFilterParams } from "./functions";
import { downloadBlob } from "../../../helpers/utils";
import apiWorker from "../../../api/apiWorker";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

const PolygonTable = observer(({ filter, onFilter, onMap }) => {
  const ls = useLocalStore(() => ({
    data: null,
    locomotiveModal: { open: false, locId: null },
  }));

  useEffect(() => {
    ekasut.ekasutData
      .getTpeDetailTable(getFilterParams(filter))
      .then(({ data }) => {
        ls.data = data;
      });
  }, [filter]);

  // useMemo for fix exceeded renders
  const [Pagination, page] = usePagination(
    useMemo(() => ls.data || [], [ls.data]),
    8,
    1
  );
  const [loadingFile, setLoadingFile] = useState(false);

  return (
    <div className="alt-semantic app polygon-scheme polygon-table">
      <LocomotiveModal
        open={ls.locomotiveModal.open}
        locId={ls.locomotiveModal.locId}
        onClose={() => {
          ls.locomotiveModal = { open: false, locId: null };
        }}
        onError={(err) => {}}
      />
      {loadingFile ? <LoaderMessage content="Загрузка отчета..." /> : null}
      {ls.data === null && (
        <LoaderMessage content="Загрузка списка локомотивов" />
      )}
      {ls.data !== null && (
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell content="Локомотив" />
              <Table.HeaderCell content="Приписка" />
              <Table.HeaderCell content="Состояние" />
              <Table.HeaderCell content="Операция" />
              <Table.HeaderCell content="Дата и время" />
              <Table.HeaderCell content="Пробег с ТО-2" />
              <Table.HeaderCell content="Дата ТО-2" />
              <Table.HeaderCell content="Место ТО-2" />
              <Table.HeaderCell content="Машинист" />
              {/*<Table.HeaderCell content="Комментарий"/>*/}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {console.log(page)}
            {page.map((item, index) => (
              <Table.Row
                key={index}
                onClick={() =>
                  (ls.locomotiveModal = { open: true, locId: item.tpeId })
                }
              >
                <Table.Cell>
                  <img
                    key={"img-" + item.idObject}
                    src={`${window.backendUrl}/api/photo/${item.idObject}/min`}
                    className="loc-icon"
                    alt="locomotive icon"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </Table.Cell>
                <Table.Cell content={item.tpeName} />
                <Table.Cell content={item.depoPrip} />
                <Table.Cell content={item.sostName} />
                <Table.Cell content={item.sostName} />
                <Table.Cell
                  content={formatMoment(item.dateOp, dateFormats.upToMinute)}
                />
                <Table.Cell content={item.overTo2Km} />
                <Table.Cell
                  content={formatMoment(item.to2Date, dateFormats.upToMinute)}
                />
                <Table.Cell content={item.to2Place} />
                <Table.Cell content={item.driver} />
                {/*<Table.Cell content={'?'}/>*/}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={10}>
                <Pagination />
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      <FloatButton
        main={{
          text: "",
          rotate: true,
          icon: "angle left",
        }}
        actions={[
          {
            text: "Excel",
            icon: "file excel outline",
            onClick: () => {
              setLoadingFile(true);
              let params = {
                CoordsSearch: filter.CoordsSearch,
                stanName: filter.stanName,
                LocNum: filter.LocNum,
                LocSer: filter.LocSer,
                DepoPrip: filter.DepoPrip,
                Park: filter.Park,
                TyagaType: filter.TyagaType,
                VidDv: filter.VidDv,
                Probeg: filter.Probeg,
                RepairType: filter.RepairType,
                SecCount: filter.SecCount,
                VidOtklNorm: filter.VidOtklNorm,
                isWorkOnWait: filter.isWorkOnWait,
                isSick: filter.isSick,
                notInOperativeRepair: filter.notInOperativeRepair,
                WaitMoreThen: filter.WaitMoreThen,
              };
              Object.keys(params).forEach((key) => {
                if (
                  params[key] == null ||
                  params[key] == [] ||
                  params[key] == ""
                ) {
                  delete params[key];
                }
                if (Array.isArray(params[key])) {
                  params[key] = params[key].join(",");
                }
              });

              apiWorker(ekasut.ekasutData.getLocoExcel)
                .fetch(params)
                .then((res) => {
                  console.log(res);
                  downloadBlob("locomotives_dislocation_report.xlsx", res);
                })
                .catch(catchNetworkErrors)
                .finally(() => {
                  setLoadingFile(false);
                });
            },
          },
          {
            text: "Настройка фильтров",
            icon: "filter",
            onClick: () => {
              onFilter();
            },
          },
          {
            text: "К карте",
            icon: "map",
            onClick: () => {
              onMap();
            },
          },
        ]}
      />
    </div>
  );
});

export default PolygonTable;
