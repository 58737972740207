import React, { useEffect, useState } from "react";
import style from "./FullTask.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ekasut from "../../../../api/ekasut";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import moment from "moment";
import { ControlBar } from "./ControlBar/ControlBar";
import { CommentBlock } from "./CommentBlock/CommentBlock";
import { MainTaskArea } from "./MainTaskArea/MainTaskArea";
import SaveIcon from "@material-ui/icons/Save";
import { FilesArea } from "./FilesArea/FilesArea";
import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Header } from "./Header/Header";
import { Status } from "./Status/Status";

const loadIcon = (
  <LoadingOutlined
    style={{ fontSize: 24, marginLeft: "10px", color: "rgba(0,0,0,0.5)" }}
    spin
  />
);

export const FullTask = ({}) => {
  const history = useHistory();
  const { groupId, taskNumber } = useParams();

  const [loading, setLoading] = useState(true);
  const [taskData, setTaskData] = useState(false);
  const [errResponse, setErrResponse] = useState(false);

  console.log(taskData);

  useEffect(() => {
    ekasut.taskboard
      .getTasksDetail(groupId, taskNumber)
      .then(({ data }) => {
        setTaskData(data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          history.push(`/404`);
        } else {
          setErrResponse(true);
          setLoading(false);
        }
      });
  }, []);

  if (errResponse) {
    return (
      <div className={style.errView}>
        <span>Во время обработки запроса произошла ошибка</span>
        <span
          onClick={() => history.push("/taskboard")}
          className={style.backBtn}
        >
          Вернуться назад
        </span>
      </div>
    );
  }

  return (
    <>
      <Dimmer active={loading} inverted>
        <Loader inverted>Загрузка</Loader>
      </Dimmer>

      {!loading ? (
        <div className={style.fullTaskArea}>
          <Button
            className={style.backButton}
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => history.push("/taskboard")}
          >
            Назад
          </Button>
          <div className={style.taskBox}>
            <div className={style.taskHeader}>
              <Header taskData={taskData} setTaskData={setTaskData} />
              <Status taskData={taskData} setTaskData={setTaskData} />
            </div>
            <MainTaskArea taskData={taskData} setTaskData={setTaskData} />
            <FilesArea
              tasksFiles={taskData.files}
              taskData={taskData}
              setTaskData={setTaskData}
            />
            <Divider />

            <CommentBlock taskData={taskData} />
          </div>
          <div className={style.controlBar}>
            <ControlBar taskData={taskData} setTaskData={setTaskData} />
          </div>
        </div>
      ) : null}
    </>
  );
};
