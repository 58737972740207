import React from 'react';

const LabeledText = ({withoutContainer, label, text}) => {
    const labeledText = (<>
        <label>{label}</label>
        <div style={{padding: "12px 14px", lineHeight: "14px"}}>
            {text}
        </div>
    </>)

    return withoutContainer ? labeledText : <div>{labeledText}</div>
};
LabeledText.defaultProps = {
    withoutContainer: false
}
export default LabeledText;