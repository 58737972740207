import React, {useEffect, useState} from 'react';
import Timeline from '../../../../../shared/TimelineChart/index'
import {START_YEAR, NUM_OF_YEARS, NUM_OF_TRACKS} from './constants'
import {buildTimebar, buildTrack} from './builders'
import {fill} from './utils'
//import 'react-timelines/lib/css/style.css'
import '../../../../../shared/TimelineChart/scss/style.scss'
import './GanttChart.scss'

const now = new Date()

//typeCO: 1 - Осень  0 - Весна
const timebar = buildTimebar(2, 2022)

const MIN_ZOOM = 2
const MAX_ZOOM = 20

export const GanttChart = ({typeCO = 2, yearCO = 2022, chartData = [], setEditView}) => {

    const [open, setOpen] = useState(false)
    const [zoom, setZoom] = useState(28)
    const [start, setStart] = useState(new Date(yearCO, 1))
    const [end, setEnd] = useState(new Date(yearCO, 8))

    useEffect(() => {
        if (typeCO === 1) {
            setStart(new Date(yearCO, 7))
            setEnd(new Date(yearCO + 1, 1))
        }
    }, [])

    const clickElement = element => {
        setEditView(element)
    }

    const handleToggleOpen = () => {
        setOpen(!open)
    }

    const handleZoomIn = () => {
        setZoom(Math.min(zoom + 1, MAX_ZOOM))
    }

    const handleZoomOut = () => {
        setZoom(Math.max(zoom - 1, MIN_ZOOM))
    }

    const handleToggleTrackOpen = track => {

    }

    const testData = []
  // asdfas
    for (let i = 0; i < 10; i += 1) {
        testData.push({
            elements: [{
                start: new Date(2022, 10, i, 0, 0, 0),
                id: `tr${i}-t${i}`,
                title: 'ТР-3',
                train: `ВЛ10-12${i}`,
                numSec: `${i}`,
                style: {
                    backgroundColor: "#0BB4C1",
                    color: "#000000",
                    borderRadius: "4px",
                    boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
                    textTransform: "capitalize"
                }
            }],
            id: `tr${i}`,
            isOpen: false,
            title: `ВЛ10-12${i}`
        })
    }

    return (
        <div className={'ganttChartDiv'}>
            {chartData.length ? <Timeline
                scale={{
                    start,
                    end,
                    zoom,
                    zoomMin: MIN_ZOOM,
                    zoomMax: MAX_ZOOM,
                }}
                isOpen={open}
                // toggleOpen={handleToggleOpen}
                //zoomIn={handleZoomIn}
                //zoomOut={handleZoomOut}
                clickElement={clickElement}
                clickTrackButton={track => {

                }}
                timebar={timebar}
                tracks={testData}
                now={now}
                toggleTrackOpen={handleToggleTrackOpen}
                enableSticky
                scrollToNow
            /> : null}
        </div>
    );
};