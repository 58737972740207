import React, {useState, useEffect} from 'react';
import {Button, Input, Modal, Tag, Tooltip} from "antd";
import style from "./ModalCreateChat.module.scss";
import ekasut from "../../../api/ekasut";


const {Search} = Input;

export const ModalCreateChat = ({
                                    createChatModal,
                                    setCreateChatModal,
                                    createChat,
                                    initData = false,
                                    editChatHandler
                                }) => {


    const [chatName, setChatName] = useState('');
    const [searchUsers, setSearchUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);


    const addUser = (el) => () => {
        setSelectedUser(prevState => [...prevState, el])
    }

    const onSearchUser = async (val) => {
        try {
            const {data} = await ekasut.chats.searchUser(val)
            setSearchUsers(data)
            setUser(data)
        } catch (e) {
            console.log(e)
        }
    }

    const changeChatHandler = () => {
        if (initData){
            editChatHandler({chatId:initData?.chatId,usersId:selectedUser.map(el => el.id),chatName})
        }
        cancel()
    }

    const createChatHandler = async () => {
        if (selectedUser.length) {
            try {
                createChat({
                    usersId: selectedUser.map(el => el.id),
                    chatName: chatName ? chatName : undefined,
                })
                cancel()
            } catch (e) {
                console.log(e)
            }
        }
    }


    const cancel = () => {
        setUser([])
        setSearchUsers([])
        setSelectedUser([])
        setCreateChatModal(false)
    }

    useEffect(() => {
        setUser(searchUsers.filter(e => !selectedUser.find(el => el.id === e.id)))
    }, [selectedUser, searchUsers])

    useEffect(() => {
        if (createChatModal) {
            onSearchUser('')
        }
    }, [createChatModal]);

    useEffect(() => {
        if (initData) {
            setChatName(initData.chatName)
            setSelectedUser(initData.selectedUser)
        }
    }, [initData])

    return (
        <Modal title={initData ? "Редактирование чата" : "Создание чата"}
               visible={createChatModal}
               width={900}
               onOk={initData ? changeChatHandler : createChatHandler}
               onCancel={cancel}
               okText={initData ? 'Изменить' : 'Создать'}
               cancelText={'Отмена'}
        >
            <div className={style.nameWrap}>
                <span>Название чата:</span>
                <Input placeholder={'необязательно'} value={chatName} onChange={(e) => setChatName(e.target.value)}/>
            </div>
            <div className={style.users}>
                <span>
                    <Tooltip title="Обязательное поле (Выберете участника чата)">
                        <span style={{color: "red", cursor: "default"}}>* </span>
                    </Tooltip>
                     Участники: {selectedUser.length ? '' : 0}
                </span>
                <div className={style.selectedUserWrap}>
                    {selectedUser.map(el => <Tag key={el.id}
                                                 closable
                                                 onClose={() => setSelectedUser(prevState => prevState.filter(e => e.id !== el.id))}
                    >
                        {el.fio}
                    </Tag>)}
                </div>
            </div>
            <Search placeholder="Поиск пользователя" onSearch={onSearchUser} enterButton/>
            <div className={style.searchUserWrap}>
                {user.map(el => <div key={el.id} className={style.searchUser}>
                    <span>{el.fio}</span>
                    <Button type={"primary"}
                            onClick={addUser(el)}>Добавить</Button>
                </div>)}
            </div>
        </Modal>
    )
}