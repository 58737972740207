import React, { useState } from "react";
import style from "./AddProt.module.scss";
import { HeaderAddProt } from "./HeaderAddProt/HeaderAddProt";
import { BodyAddProt } from "./BodyAddProt/BodyAddProt";
import { FooterAddProt } from "./FooterAddProt/FooterAddProt";
import { Checkbox, Input, Space, Table, Tag } from "antd";
import { useEffect } from "react";
import ekasut from "../../../../api/ekasut";

export const AddProt = ({
  info,
  currentObjId,
  setAddProt,
  addProt,
  currentSostKod,
  currentSostName,
  currentDateOp,
  currentIsDate,
  sldPark,
  getSld
}) => {
  const [secProtAwinfo, setSecProtAwinfo] = useState([]);
  const [sendingMarks, setSendingMarks] = useState([]);
  const [sendingAddWorkStatus, setSendingAddWorkStatus] = useState([]);
  const [sendingProtocolNumber, setSendingProtocolNumber] = useState([]);

  const [protocolNum, setProtocolNum] = useState(null);
  const [protocolDate, setProtocolDate] = useState(null);
  const [repairСlassification, setRepairСlassification] = useState(null);
  const [repairDate, setRepairDate] = useState(null);
  const [commisarMarks, setCommisarMarks] = useState([]);
  const [currentProtDate, setCurrentProtDate] = useState(null);
  const [currentAddWorkUUID, setCurrentAddWorkUUID] = useState(null);
  const [isRegistrProt, setIsRegistrProt] = useState(null)

  const year = new Date().getFullYear();
  const season = new Date().getMonth() < 9 ? 1 : 2;
  localStorage.setItem("seasonYear", year);
  localStorage.setItem("season", season);

  const getSecProtInfo = async () => {
    const result = await ekasut.commisionInspection.getSecProtInfo(
      currentObjId,
      year,
      season,
      0
    );
    setCommisarMarks(result?.data);
    setCurrentKeyForFile(result?.data[0]?.fileName)
    setIsRegistrProt(result?.data[0]?.isRegistr)
  };

  //1
  const postNewAddWorkStatus = async(check) => {
    const res = await ekasut.commisionInspection.postAddWork({
      pRGProt: commisarMarks?.length && commisarMarks[0].rgProt,
      pRGAxwork: secProtAwinfo?.length && secProtAwinfo[check.id]?.rgActAwPer,
      pCheckRun: + !check.value,
    })

    return res
  }

  //2
  const getSecProtAxwork = async () => {
    const res = await ekasut.commisionInspection.getSecProtAxwork(
      localStorage.kodFirm,
      currentObjId,
      localStorage.seasonYear,
      localStorage.season,
      0
    );
    setSecProtAwinfo(res?.data);
  }; 

  // написать нормально ...
  const changeCheckboxValue = async(check) => {
    const res = await postNewAddWorkStatus(check)
    res?.status === 200 ? getSecProtAxwork():
    alert("fail")
  }

  useEffect(() => {
    getSecProtAxwork();
    getSecProtInfo();
  }, []);

  

  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Серия",
      dataIndex: "ser",
      key: "ser",
    },
    {
      title: "Отметка",
      dataIndex: "check",
      key: "check",
      render: check => (
        <Checkbox 
          checked={check.value} 
          onChange={() => changeCheckboxValue(check)}
        />
      )
    },
  ];

  const data = secProtAwinfo.map((e, i) => {
    return {
      key: i,
      name: e.nameAxwork,
      ser: e.serLoc,
      check: {id: i, value: e.checkOfRun}
    };
  });

  const [openFile, setOpenFile] = useState(null);
  const [currentKeyForFile, setCurrentKeyForFile] = useState(null);

  const sendFileToServer = async() => {
    const response = await ekasut.file.postFile(openFile)
    setCurrentKeyForFile(response?.data)
  }

  return (
    <div className={style.wrap}>
      <HeaderAddProt
        info={info}
        commisarMarks={commisarMarks}
        protocolNum={protocolNum}
        setProtocolNum={setProtocolNum}
        currentDateOp={currentDateOp}
        currentSostName={currentSostName}
        currentProtDate={currentProtDate}
        setCurrentProtDate={setCurrentProtDate}
        secProtAwinfo={secProtAwinfo}
        getSld={getSld}
      />
      <div className={style.body} style={{ display: "flex" }}>
        {commisarMarks.length && 
        commisarMarks[0].numProt === null ? "Чтобы изменить оценку, добавьте протокол":
        <BodyAddProt
          currentObjId={currentObjId}
          currentSostKod={currentSostKod}
          currentSostName={currentSostName}
          currentDateOp={currentDateOp}
          sendingMarks={sendingMarks}
          setSendingMarks={setSendingMarks}
          sendingAddWorkStatus={sendingAddWorkStatus}
          setSendingAddWorkStatus={setSendingAddWorkStatus}
          sendingProtocolNumber={sendingProtocolNumber}
          setSendingProtocolNumber={setSendingProtocolNumber}
          setProtocolNum={setProtocolNum}
          commisarMarks={commisarMarks}
          setCommisarMarks={setCommisarMarks}
          getSecProtAxwork={getSecProtAxwork}
          getSecProtInfo={getSecProtInfo}
          getSld={getSld}
          openFile={openFile}
          setOpenFile={setOpenFile}
          currentKeyForFile={currentKeyForFile}
          setCurrentKeyForFile={setCurrentKeyForFile}
        />
        }
        <Table columns={columns} dataSource={data} />
      </div>
      <FooterAddProt
        setAddProt={setAddProt}
        addProt={addProt}
        sendingMarks={sendingMarks}
        sendingAddWorkStatus={sendingAddWorkStatus}
        sendingProtocolNumber={sendingProtocolNumber}
        protocolNum={protocolNum}
        currentSostName={currentSostName}
        currentDateOp={currentDateOp}
        currentIsDate={currentIsDate}
        commisarMarks={commisarMarks}
        setProtocolNum={setProtocolNum}
        sldPark={sldPark}
        currentSostKod={currentSostKod}
        currentProtDate={currentProtDate}
        setCurrentProtDate={setCurrentProtDate}
        getSld={getSld}
        secProtAwinfo={secProtAwinfo}
        getSecProtAxwork={getSecProtAxwork}
        getSecProtInfo={getSecProtInfo}
        sendFileToServer={sendFileToServer}
        currentKeyForFile={currentKeyForFile}
        openFile={openFile}
        setOpenFile={setOpenFile}
        setCurrentKeyForFile={setCurrentKeyForFile}
        isRegistrProt={isRegistrProt}
      />
    </div>
  );
};
