import React, { useDebugValue, useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import { useKasantMessages } from "./hooks/useKasantaMessages";
import { columns } from "./tableUtils/columns";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";

const Kasant = () => {
  const { kasantaMessages, isLoaded } = useKasantMessages();

  const data = kasantaMessages.map((e, i) => ({
    key: i,
    eventDate: e.eventDate,
    locSerName: e.locSerName,
    locNum: e.locNum,
    eventName: e.eventName,
  }));

  if (!isLoaded)
    return (
      <Dimmer active inverted>
        <Loader size="large">Подождите, идет загрузка данных</Loader>
      </Dimmer>
    );

  return (
    <Table
      showHeader={false}
      pagination={{ position: ["topLeft"] }}
      columns={columns}
      dataSource={data}
    />
  );
};

export default Kasant;
