import React, {useState} from 'react'
import styles from "../index.module.scss";
import {DateRangePicker} from "react-dates";
import cn from 'classnames'
import appStyles from '../../../css/App.module.scss'

export default function DateRangeField({label, onChange, editable, startDate, endDate, ...pickerProps}) {
    const [focused, setFocused] = useState(null)

    return (
        <div className={cn(styles.formField, appStyles.fixDatePicker)}>
            <label>{label}</label>
            <DateRangePicker
                onDatesChange={({startDate, endDate}) => {
                    onChange(startDate, endDate)
                }}
                onFocusChange={(focused) => {
                    setFocused(focused)
                }}
                startDatePlaceholderText='Дата'
                endDatePlaceholderText='Дата'
                disabled={!editable}
                numberOfMonths={1}
                minimumNights={0}
                noBorder={true}
                block={true}
                focusedInput={focused}
                startDateId={startDateId}
                endDateId={endDateId}
                startDate={startDate}
                endDate={endDate}
                {...pickerProps}
            />
        </div>
    )
}
const startDateId = 'startDate'
const endDateId = 'endDate'
DateRangeField.defaultProps = {
    editable: true,
    startDate: null,
    endDate: null,
}