import React, { PureComponent } from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import { Grid, Segment, List } from 'semantic-ui-react'
import { Button, Icon } from 'semantic-ui-react'
import { Menu } from 'semantic-ui-react'
import { Input, Popup } from 'semantic-ui-react'

import RoleAddingModal from './RoleAddingModal'
import RoleEditingModal from './RoleEditingModal'
import FunctionAddingModal from './FunctionAddingModal'
import FunctionEditingModal from './FunctionEditingModal'


class ControlRoles extends PureComponent {
    state = {
        addRoleModalOpen: false,
        editRoleModalOpen: false,
        editingRole: {},
        addFunctionModalOpen: false,
        editFunctionModalOpen: false,
        selectedFunction: {},
        errorOccured: false,
        isLoading: false,
        params: {},
        roles: [],
        preds: [],
        selectedRole: null,
        functions: [],
        availableFunctions: [],
        currentRoleFunctions: []
    }

    componentDidMount() {
        var me = this;

        me.setState({isLoading: true})

        this.loadRoles();

        this.loadFunctions();

        axios.get(`${window.backendUrl}/api/data/service-tree`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                }

                me.setState({
                    preds: response.data.map(pred => ({
                        value: pred.value,
                        key: pred.key,
                        text: pred.text,
                        className: (pred.level === 0) ? 'root-pred' : 'child-pred-' + (pred.level)
                    }))
                });
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    loadRoles () {
        var me = this;

        axios.get(`${window.backendUrl}/api/roles/roles`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                }

                me.setState({roles: response.data});
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    loadFunctions () {
        var me = this;

        axios.get(`${window.backendUrl}/api/roles/functions`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                }

                me.setState({functions: response.data, availableFunctions: response.data});
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    filterAvailableFunctions(alreadyUsedFunctions) {
        const usedIds = alreadyUsedFunctions.map(el => el.funcId);

        return this.state.functions.filter(e=> usedIds.indexOf(e.funcId) < 0)
    }

    roleClick(role) {
        var me = this;
        this.setState({selectedRole: (role === this.state.selectedRole) ? null : role})

        if (role !== this.state.selectedRole) {
            me.setState({isLoading: true})

            axios.get(`${window.backendUrl}/api/roles/roles-functions?roles=${role.roleId}`)
                .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    var roleFunctions = response.data[0].functions;

                    me.setState({currentRoleFunctions: roleFunctions, availableFunctions: me.filterAvailableFunctions(roleFunctions), isLoading: false});
                }
            ).catch(() => {})
        } else {
            this.setState({availableFunctions: this.state.functions})
        }




        // отфильтровать доступные функции
        // добавить уже существующие в список
    }

    roleDeleteClick(id, e) {
        // prevent parent click
        e.stopPropagation();
        var me = this;

        this.setState({selectedRole: null})

        axios.delete(`${window.backendUrl}/api/roles/roles?roleId=${id}`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                } else {
                    me.setState({isLoading: false});
                    me.loadRoles();
                    me.loadFunctions();
                }
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }

    roleEditClick(role, e) {
        // prevent parent click
        e.stopPropagation();

        this.setState({editRoleModalOpen: true, editingRole: role})
    }

    functionDeleteClick(id) {
        var me = this;

        this.setState({selectedRole: null})

        axios.delete(`${window.backendUrl}/api/roles/functions?funcId=${id}`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                } else {
                    me.setState({isLoading: false});
                    me.loadFunctions();
                }
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }

    functionEditClick(func) {
        this.setState({editFunctionModalOpen: true, selectedFunction: func})
    }

    functionAddToRoleClick(func, indexInAvailable) {
        var me = this;
        const {availableFunctions, selectedRole, currentRoleFunctions} = this.state

        var newAvailableFunctions = availableFunctions.filter((x,i) => (i !== indexInAvailable ))
        var newCurrentRoleFunctions = [...currentRoleFunctions, ...[func]]

        axios.post(`${window.backendUrl}/api/roles/roles-functions`, {roleId: selectedRole.roleId, functions: newCurrentRoleFunctions.map(item => item.funcId)})
            .then(
            function (response) {
                if (response.status !== 200) {
                    return;
                } else {
                    me.setState({
                        availableFunctions: newAvailableFunctions,
                        currentRoleFunctions: newCurrentRoleFunctions
                    })
                }
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    functionRemoveFromRoleClick(func, indexInAvailable) {
        var me = this;
        const {availableFunctions, selectedRole, currentRoleFunctions} = this.state

        var newCurrentRoleFunctions = currentRoleFunctions.filter((x,i) => (i !== indexInAvailable ))
        var newAvailableFunctions = [...availableFunctions, ...[func]]

        axios.post(`${window.backendUrl}/api/roles/roles-functions`, {roleId: selectedRole.roleId, functions: newCurrentRoleFunctions.map(item => item.funcId)})
            .then(
            function (response) {
                if (response.status !== 200) {
                    /*console.log('Looks like there was a problem. Status Code: ' +
                     response.status);*/


                    return;
                } else {
                    me.setState({
                        availableFunctions: newAvailableFunctions,
                        currentRoleFunctions: newCurrentRoleFunctions
                    })
                }
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });

    }

    render() {
        const { hideSidebars } = this.props
        const {roles, availableFunctions, selectedRole, currentRoleFunctions} = this.state

        return (
            <div onClick={hideSidebars}>
                <Grid columns={2} divided>
                    <Grid.Row stretched>
                        <Grid.Column>

                            <Segment className='management roles-parent'>

                                <Menu text className='roles-management'>
                                    <Menu.Item header>Роли</Menu.Item>

                                    <Menu.Menu position='right'>
                                        <Menu.Item>
                                            <Input icon='search' placeholder='Найти...'/>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Popup
                                                trigger={<Button onClick={() => {this.setState({addRoleModalOpen: true})}} circular icon='add' />}
                                                content='Добавить'
                                                />
                                        </Menu.Item>
                                    </Menu.Menu>
                                </Menu>

                                <List selection divided  className='roles'>
                                    {roles.map((role, i) =>
                                            <List.Item key={i}
                                                className={'action-buttons-item ' +  (role === selectedRole ? 'selected' : '')}
                                                onClick={() => this.roleClick(role)}>

                                                <List.Content floated='left'>
                                                    <List.Header as='span'>{role.roleDesc}</List.Header>
                                                    {role.roleName}
                                                </List.Content>

                                                <List.Content floated='right'>
                                                    <Button.Group>
                                                        <Button icon onClick={e => this.roleEditClick(role, e)}>
                                                            <Icon name='pencil'/>
                                                        </Button>
                                                        <Button icon onClick={e => this.roleDeleteClick(role.roleId, e)}>
                                                            <Icon name='delete'/>
                                                        </Button>
                                                    </Button.Group>
                                                </List.Content>

                                            </List.Item>
                                    )}
                                </List>
                            </Segment>

                            {selectedRole
                                ? (
                                <Segment className='management roles-parent'>

                                    <Menu text className='roles-management'>
                                        <Menu.Item header>Функции в роли - {selectedRole.roleDesc}</Menu.Item>
                                    </Menu>

                                    <List selection divided className='role-functions'>
                                        {currentRoleFunctions.map((func, i) =>
                                                <List.Item className='action-buttons-item' key={i}>
                                                    <List.Content floated='left'>
                                                        <List.Header as='span'>{func.funcDesc}</List.Header>
                                                        {func.funcDesc}
                                                    </List.Content>

                                                    <List.Content floated='right'>
                                                        <Button icon onClick={() => this.functionRemoveFromRoleClick(func, i)}>
                                                            <Icon name='angle right'/>
                                                        </Button>
                                                    </List.Content>
                                                </List.Item>
                                        )}
                                    </List>
                                </Segment>
                                ) : <Segment className='management roles-parent'></Segment>}

                        </Grid.Column>
                        <Grid.Column>
                            <Segment className='management'>

                                <Menu text className='roles-management'>
                                    <Menu.Item header>Функции</Menu.Item>

                                    <Menu.Menu position='right'>
                                        <Menu.Item>
                                            <Input icon='search' placeholder='Найти...'/>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Popup
                                                trigger={<Button onClick={() => {this.setState({addFunctionModalOpen: true})}} circular icon='add' />}
                                                content='Добавить'
                                                />
                                        </Menu.Item>
                                    </Menu.Menu>
                                </Menu>

                                <List className='functions' selection divided>
                                    {availableFunctions.map((func, i) =>
                                            <List.Item className='action-buttons-item' key={i}>
                                                <List.Content floated='left'>
                                                    <List.Header as='span'>{func.funcDesc}</List.Header>
                                                    {func.funcName}
                                                </List.Content>

                                                <List.Content floated='right'>
                                                    { selectedRole
                                                        ? (<Button icon onClick={() => this.functionAddToRoleClick(func, i)}>
                                                            <Icon name='angle left'/>
                                                        </Button>)
                                                        : null }

                                                    <Button.Group>
                                                        <Button icon onClick={() => this.functionEditClick(func)}>
                                                            <Icon name='pencil'/>
                                                        </Button>
                                                        <Button icon onClick={() => this.functionDeleteClick(func.funcId)}>
                                                            <Icon name='delete'/>
                                                        </Button>
                                                    </Button.Group>
                                                </List.Content>
                                            </List.Item>
                                    )}

                                </List>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <RoleAddingModal
                    addRoleModalOpen={this.state.addRoleModalOpen}
                    onClose={() => {this.loadRoles();this.setState({addRoleModalOpen: false});}}
                    preds={this.state.preds}
                    />

                <RoleEditingModal
                    editRoleModalOpen={this.state.editRoleModalOpen} editingRole={this.state.editingRole}
                    onClose={(roleEdited) => {this.loadRoles();this.setState({selectedRole: (roleEdited.roleId ? roleEdited : null), editRoleModalOpen: false});}}
                    preds={this.state.preds}
                    />

                <FunctionAddingModal
                    addFunctionModalOpen={this.state.addFunctionModalOpen}
                    onClose={() => {this.loadFunctions();this.setState({addFunctionModalOpen: false});}}
                    />

                <FunctionEditingModal
                    editFunctionModalOpen={this.state.editFunctionModalOpen} selectedFunction={this.state.selectedFunction}
                    onClose={() => {this.loadFunctions();this.setState({editFunctionModalOpen: false});}}
                    />
            </div>
        )
    }
}

export default ControlRoles;