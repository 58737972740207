import React from 'react';
import styles from './index.module.scss'
import moment from "moment";
import {groupToMap} from "../../helpers/utils";
import {dateFormats} from "../../Dictionaries";
import cn from "classnames";

const CalendarForMonth = ({month, dayOnClick = () => null, daysOptions = []}) => {
    const startOfMonth = moment(month).startOf("month")
    const endOfMonth = moment(month).endOf("month")
    const to = moment(endOfMonth).endOf("week")
    const from = moment(startOfMonth).startOf("week")

    const mapDaysOptions = daysOptions && groupToMap(daysOptions, "day", day => day.format(dateFormats.upToDay))

    const getDayClass = (day) => {
        let className = cn("day", day.isBefore(startOfMonth) && "day-old", day.isAfter(endOfMonth) && "day-new")

        if (day.isBefore(startOfMonth) || day.isAfter(endOfMonth)) {
            return className
        }

        const arr = mapDaysOptions && mapDaysOptions.get(day.format(dateFormats.upToDay))
        const dayOptions = arr && arr[0]

        if (dayOptions) {
            if (dayOptions.fill) {
                className = cn(className, "day-filled",`day-fill-${dayOptions.fill}`)
            }
            if (dayOptions.outline) {
                className = cn(className, `day-outline-${dayOptions.outline}`)
            }
            if (day.isSame(moment(), 'day')) {
            }
        }

        return className
    }

    const getWeeks = (from, to) => {
        const weeks = []
        const week = moment(from)
        do {
            weeks.push(moment(week))
            week.add(1, "week")
        } while (week.isBefore(to))
        return weeks
    }

    const getDays = (from, to) => {
        const days = []
        for (let day = moment(from); day.isBefore(to); day.add(1, "day")) {
            days.push(moment(day))
        }
        return days
    }

    return (
        <div className={styles.component}>
            <table>
                <thead>
                <tr>
                    <th className="month-title" colSpan={7}>{month.format("MMMM").toUpperCase()}</th>
                </tr>
                <tr>
                    <th className="day-header">ПН</th>
                    <th className="day-header">ВТ</th>
                    <th className="day-header">СР</th>
                    <th className="day-header">ЧТ</th>
                    <th className="day-header">ПТ</th>
                    <th className="day-header">СБ</th>
                    <th className="day-header">ВС</th>
                </tr>
                </thead>
                <tbody>
                {getWeeks(from, to).map((startOfWeek, index) => (
                    <tr key={index}>
                        {getDays(startOfWeek, moment(startOfWeek).endOf("week")).map((day, i) => (
                            <th
                                className={getDayClass(day)}
                                key={i}
                                onClick={() => {
                                    if (day.isSameOrAfter(startOfMonth) && day.isSameOrBefore(endOfMonth)){
                                        const options = mapDaysOptions.get(day.format(dateFormats.upToDay))
                                        dayOnClick(day, options ? options : [])
                                    }
                                }}
                            >
                                {day.date()}
                            </th>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default CalendarForMonth;