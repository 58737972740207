import {getLastYears} from "./helpers/utils";
import moment from 'moment'

export const waitRep = [
    null,
    'Допустимый',
    'Длительный',
    'Сверхдлительный'
]

export const motionModeOptions = [
    {key: 10, value: 10, text: 'ПАСС'},
    {key: 20, value: 20, text: 'ГРУЗ'},
    {key: 40, value: 40, text: 'ХОЗ'},
    {key: 50, value: 50, text: 'МАНВ'},
    {key: 70, value: 70, text: 'ПРОЧЕЕ'}
]

export const mileageOptions = [
    /*{ key: 3, value: 3, text: 'Зелёный' },*/
    {key: 1, value: 1, text: 'Жёлтый'},
    {key: 2, value: 2, text: 'Красный'}
]

export const pereprostOptions = [
    {key: 0, value: 0, text: 'менее 24ч'},
    {key: 1, value: 1, text: '24ч – 96ч'},
    {key: 2, value: 2, text: 'более 96ч'}
]

export const carFleetOptions = [
    {key: 1, value: 1, text: 'ЭП'},
    {key: 2, value: 2, text: 'НЭП'},
    {key: 3, value: 3, text: 'НЕПЛ'},
    {key: 4, value: 4, text: 'ЗАВОД'},
    {key: 5, value: 5, text: 'РЕЗЕРВ'},
    {key: 6, value: 6, text: 'ЗАПАС'},
    {key: 7, value: 7, text: 'АРЕНДА'}
]

export const motionTypeOptions = [
    {key: '1', value: '1', text: 'ЭЛЕКТРОВОЗЫ ПЕР.Т.'},
    {key: '6', value: '6', text: 'ЭЛЕКТРОВОЗЫ ПОСТ.Т'},
    {key: '2,9', value: '2,9', text: 'ТЕПЛОВОЗЫ'},
    {key: '3', value: '3', text: 'ПАРОВОЗЫ'},
    {key: '11', value: '11', text: 'ГАЗОТУРБОВОЗЫ'}
]

// should not be more 9 elements
// api method /api/map/locomotives, field park for EP, require array of 2 digit numbers `${typeOfJob}${typeOfEp}`
export const typeOfJobOptions = [
    {key: 0, value: 0, text: 'Пассажирский'},
    {key: 4, value: 4, text: 'Грузовой'},
    {key: 5, value: 5, text: 'Перед, вывоз, сборн'},
    {key: 6, value: 6, text: 'Хозяйственный'},
    {key: 7, value: 7, text: 'Спец.маневровый'},
    {key: 8, value: 8, text: 'Проч.маневровый'},
    {key: 9, value: 9, text: 'Прочий'}
]

// should not be more 9 elements
// api method /api/map/locomotives, field park for EP, require array of 2 digit numbers `${typeOfJob}${typeOfEp}`
export const parkTypeEpOptions = [
    {key: 1, value: 1, text: 'Рабочий (на путях ОАО «РЖД»)'},
    {key: 2, value: 2, text: 'Рабочий (на путях стран СНГ)'},
    {key: 3, value: 3, text: 'Рабочий (ож.раб. и тех.операции)'},
    {key: 4, value: 4, text: 'Нерабочий (ож.раб. или простой)'},
    {key: 5, value: 5, text: 'Нерабочий (тех.операции)'},
    {key: 6, value: 6, text: 'Нерабочий (оперативный резерв ЦД)'},
    {key: 7, value: 7, text: 'Нерабочий (регул. без участия в тяге)'},
    {key: 8, value: 8, text: 'Нерабочий (ТО-2 и экипировка)'},
    {key: 9, value: 9, text: 'Нерабочий (перемещение для ТО)'},
    {key: 0, value: 0, text: 'Не включенные в наличный'}
]

export const parkTypeNepOptions = [
    {key: 11, value: 11, text: 'Стационарные установки'},
    {key: 12, value: 12, text: 'Плановый ремонт на заводе'},
    {key: 13, value: 13, text: 'Перемещение исправных'},
    {key: 14, value: 14, text: 'Испытания'},
    {key: 15, value: 15, text: 'Ож. исключения из инвентаря'},
    {key: 16, value: 16, text: 'Технологический резерв ЦТ'},
    {key: 17, value: 17, text: 'ТО-2 и экипировка НЭП'},
    {key: 18, value: 18, text: 'Простой исправных'},
    {key: 22, value: 22, text: 'Модернизация на заводе'},
    {key: 23, value: 23, text: 'Ответственное хранение'},
    {key: 24, value: 24, text: 'Плановый ремонт в депо'},
    {key: 25, value: 25, text: 'Тех. обслуживание в депо'},
    {key: 26, value: 26, text: 'Неплановый ремонт в депо'},
    {key: 27, value: 27, text: 'Модернизация в депо'},
    {key: 33, value: 33, text: 'Пересылка в непл. ремонт'},
    {key: 34, value: 34, text: 'Рекламация'},
    {key: 35, value: 35, text: 'Ож. отпр. и перес. для ТО/ТР'},
    {key: 36, value: 36, text: 'Обкатка, пробная поездка'},
    {key: 38, value: 38, text: 'Аренда на путях необщ.п.'},
    {key: 39, value: 39, text: 'Консервация'},
]

export const sections = [
    {key: 1, value: 1, text: 1},
    {key: 2, value: 2, text: 2},
    {key: 3, value: 3, text: 3},
    {key: 4, value: 4, text: 4}
]

export const weightStandardOptions = [
    {key: 1, value: 1, text: 'неполновесный'},
    {key: 2, value: 2, text: 'неполносоставный'},
    {key: 3, value: 3, text: 'тяжеловесный'},
    {key: 4, value: 4, text: 'тяжеловесный, длинносоставный'},
    {key: 5, value: 5, text: 'повышенного веса'},
    {key: 6, value: 6, text: 'длинносоставный'},
    {key: 7, value: 7, text: 'повышенной длины'},
    {key: 8, value: 8, text: 'порожний длинносоставный'},
    {key: 9, value: 9, text: 'порожний повышенной длины'},
    {key: 10, value: 10, text: 'полносоставный'},
    {key: 11, value: 11, text: 'полновесный'},
    {key: 12, value: 12, text: 'груженый > 6 тыс.т, < 350 осей'},
    {key: 13, value: 13, text: 'груженый > 6 тыс.т, > 350 осей'}
]

export const workOnWaitList = [
    {text: 'Ожидание работы', id: 105},
    {text: 'Ожидание передачи в ТО/ТР', id: 535},
    {text: 'Ожидание постановки в НР', id: 543},
    {text: 'Ожидание постановки в ТО-2', id: 106}
]
export const parkTypes = [
    {text: "Все", id: "all"},
    {text: "ЭП", id: "ep"},
    {text: "НЭП", id: "nep"}
]

export const dates = {
    months: [
        'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
    ],
    shortenedMonth: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    decades: ['Первая', 'Вторая', 'Третья'],
    quartals: ['I', 'II', 'III', 'IV'],
    lastTenYears: (() => (getLastYears(10, moment().year())))()
}

export const dateFormats = {
    upToDay: 'DD.MM.YYYY',
    upToMinute: 'DD.MM.YYYY HH:mm',
    upToSeconds: 'DD.MM.YYYY HH:mm:ss'
}