import React from 'react';
import cn from 'classnames'
import {observer, useLocalStore} from "mobx-react-lite";
import Main from "./Main";

const ProcurementPlanning = observer(({hideSidebars}) => {
    return (
        <div className={cn('alt-semantic')} onClick={hideSidebars}>
            <Main />
        </div>
    );
})

export default ProcurementPlanning;
