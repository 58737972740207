import React, {useEffect, useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import DateField from "../../../../../shared/fields/DateField";
import moment from "moment";
import style from './EditRepairDate.module.scss'

export const EditRepairDate = ({setEditView, editView}) => {

    const [begin, setBegin] = useState(null)
    const [end, setEnd] = useState(null)
    const photo = false

    const formateDate = (date) => {
        return moment(moment(date).format('DD.MM.YYYY'), 'DD.MM.YYYY')
    }

    useEffect(() => {
        if (editView) {
            setEnd(formateDate(editView.end))
            setBegin(formateDate(editView.start))
        }

    }, [editView])
    return (
        <Modal
            // className={styles.editView}
            onClose={() => setEditView(false)}
            open={!!editView}
            size={'mini'}>
            <Modal.Header>Изменение даты</Modal.Header>
            <Modal.Content>
                <div className={style.info}>
                    <div className={style.photo}>
                        {photo ? <img
                                src='https://lh3.googleusercontent.com/proxy/mMB1vRqcPOyzeB9VUWiUIVJYloCYjZGO3iSFT6sdC89oaVyS8SuJvc0GsXwfT3emFgOhyEKrnFeIk7Z4IfpMzm3c9P5ctzicvBf9RgZR2Oxla-C_nA'/> :
                            <div>Нет фото</div>}
                    </div>
                    <div className={style.desc}>
                        <div className={style.trainName}><span>Серия-Номер:</span>{editView.train}</div>
                        <div className={style.trainName}><span>Номер секции:</span>{editView.numSec}</div>
                        <div className={style.kodRepair}><span>Тип:</span>{editView.title}</div>
                    </div>
                </div>
                <DateField
                    //disabled={editMode}
                    fluid
                    label="Начало"
                    inputClassName="date-underline"
                    dateFormat={'DD.MM.YYYY'}
                    date={begin}
                    onChange={(date) => {
                        console.log(date)
                        setBegin(date)
                    }}
                    closable
                />
                <DateField
                    // disabled={editMode}
                    fluid
                    label="Конец"
                    inputClassName="date-underline"
                    dateFormat={'DD.MM.YYYY'}
                    date={end}
                    onChange={(date) => {
                        setEnd(date)
                    }}
                    closable
                />

            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    setEditView(false)
                }}>
                    Отменить
                </Button>
                <Button className="button-primary" onClick={() => {
                    setEditView(false)
                }}>
                    Изменить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};