import React, {useEffect} from 'react'
import {observer, useLocalStore} from "mobx-react-lite"
import {useStore} from "../StoreProvider"
import auth, {ACCESS_LEVELS, user} from "../../../../helpers/auth"
import LabeledDropdown from "../../../../shared/LabeledDropdown";
import styles from './SldTche.module.scss'
import LabeledText from "../../../../shared/LabeledText";
import {escapeRegExp} from "../../../../helpers/utils";

const SldTche = observer(() => {
    const store = useStore()

    const ls = useLocalStore(() => ({
        tcheSearchQuery: "",
        tches: [],
    }))

    useEffect(() => {
        if (auth.getAccessLevel() === ACCESS_LEVELS.tcheSld) {
            if (auth.getUser() === user.SLD) {
                store.fetchRelatedFirm()
                    .then((data) => {
                        store.selectedSld = {
                            id: data.userFirmKodFirm,
                            name: data.userFirmName
                        }
                        store.selectedTche = {
                            id: data.relatedFirmKodFirm,
                            name: data.relatedFirmName
                        }
                        store.tche = store.selectedTche
                        store.sld = store.selectedSld
                        store.refreshTable()
                    }).catch(() => {})
            }
            if (auth.getUser() === user.TCHE) {
                store.fetchRelatedFirm()
                    .then((data) => {
                        store.selectedTche = {
                            id: data.userFirmKodFirm,
                            name: data.userFirmName
                        }
                        store.selectedSld = {
                            id: data.relatedFirmKodFirm,
                            name: data.relatedFirmName
                        }
                        store.tche = store.selectedTche
                        store.sld = store.selectedSld
                        store.refreshTable()
                    }).catch(() => {})
            }
        }

        if (auth.getAccessLevel() === ACCESS_LEVELS.CT || auth.getAccessLevel() === ACCESS_LEVELS.T) {
            store.fetchPredChildren(auth.getKodFirm())
                .then(data => {
                    ls.tches = getTches(data)
                }).catch(() => {})
        }
    }, [store, ls])

    const tcheOptions = ls.tches.map(tche => ({
        key: tche.level === TCHE_LEVEL ? tche.kodFirm : tche.name,
        text: tche.name,
        value: tche.level === TCHE_LEVEL ? tche.kodFirm : tche.name,
        disabled: tche.disabled
    }))

    return (
        (auth.getAccessLevel() === ACCESS_LEVELS.CT) && (<>
            <LabeledDropdown
                label="ТЧЭ"
                placeholder="Не выбрано"
                selection
                className={styles.hierarchyDropdown}
                options={tcheOptions}
                search={() => tcheOptions.filter(item =>
                    (!item.disabled && new RegExp(escapeRegExp(ls.tcheSearchQuery), "i").test(item.text)))
                }
                onSearchChange={(e, {searchQuery}) => {
                    ls.tcheSearchQuery = searchQuery
                }}
                loading={store.tchesLoading}
                value={store.selectedTche.id}
                onChange={(e, {value}) => {
                    const tche = ls.tches.find(tche => tche.kodFirm === value)
                    // Prevent from accidentally select road by user, i guess semantic's dropdown
                    // shouldn't allow do that, but actually in not obvious way i can:
                    // search value, select some, then click on dropdown and deselect
                    if (tche.level !== TCHE_LEVEL) {
                        return
                    }
                    store.selectedTche = {id: tche.kodFirm, name: tche.name}
                    store.selectedSld = {id: null, name: "Загрузка смежного СЛД"}
                    store.fetchRelatedFirm(tche.kodFirm)
                        .then(data => {
                            store.selectedSld = {id: data.relatedFirmKodFirm, name: data.relatedFirmName}
                        })
                        .catch(() => {
                            store.selectedSld = {id: null, name: "[ Не загружено ]"}
                        })
                }}
            />
            <LabeledText
                label="СЛД"
                text={store.selectedSld.name}
            />
        </>)
    )
})

const ROAD_LEVEL = 1
const TCHE_LEVEL = 2

const getTches = (data) => {
    const options = []
    data.forEach((road) => {
        options.push({
            name: road.road,
            id: road.road,
            level: ROAD_LEVEL,
            disabled: true,
        })
        road.pred.forEach(pred => {
            options.push({
                name: pred.predName,
                id: pred.predId,
                level: TCHE_LEVEL,
                kodFirm: pred.kodFirm
            })
        })
    })
    return options
}

export default SldTche;