import React, {PureComponent} from 'react';
import axios from 'axios';
import auth from '../../helpers/auth'
import {Icon, Table} from 'semantic-ui-react'
import {Segment, Button} from 'semantic-ui-react'
import {Dropdown} from 'semantic-ui-react'
import {Form} from 'semantic-ui-react'
import {Modal, Header} from 'semantic-ui-react'

import * as moment from 'moment';
import 'moment/locale/ru';
import LabeledDateInput from "../../shared/LabeledDateInput";
import FloatButton from '../../shared/FloatButton';
import SkeletonPage from '../../shared/SkeletonPage/SkeletonPage';
import {tableHeaders} from './tableInfo.js'

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

class ReceiverRating extends PureComponent {
    state = {
        activePage: 1,
        modalOpen: false,
        errorOccured: false,
        repairOpened: false,
        ratingResp: [],
        isLoading: false,
        params: {fromDate: moment().add(-1, 'month'), toDate: moment()},
        repairDepos: [],
        filterShow: false,
        onPageLimit: 100,
        dorsList: [],
        fullDorsList: '',
        actualHeaders: tableHeaders,
        partsShow: 1,
    }

    durationFormat = "y[г] M[м] d[д] HH[ч] mm[м]"

    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.filterStateChange = this.filterStateChange.bind(this);
        this.submitFilters = this.submitFilters.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    filterStateChange() {
        this.setState({filterShow: !this.state.filterShow})
    }

    componentDidMount() {
        var me = this;

        document.querySelector('.content-container').addEventListener('scroll', () => {
            if (document.querySelector('.content-container').scrollTop + document.querySelector('.content-container').clientHeight >= document.querySelector('.content-container').scrollHeight) {
                this.setState({onPageLimit: this.state.onPageLimit + 100})
            }
        })

        const {params} = this.state;

        me.setState({isLoading: true})

        axios.get(`${window.backendUrl}/api/data/service-tree`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    me.setState({
                        repairDepos: response.data.filter(i => i.level === 0).map(pred => ({
                            value: pred.value,
                            key: pred.key,
                            text: pred.text,
                            className: (pred.level === 0) ? 'root-pred' : 'child-pred-' + (pred.level)
                        }))
                    });

                    let dorsID = response.data.filter(i => i.level === 0).map(dors => ("dors=" + dors.key)).join('&')
                    me.setState({fullDorsList: dorsID})

                    const clone = {...params}
                    clone.toDate = clone.toDate.format('YYYY-MM-DD')
                    clone.fromDate = clone.fromDate.format('YYYY-MM-DD')
                    let queryString = Object.keys(clone).map(key => key + '=' + clone[key]).join('&')
                    me.setState({params: {dors: dorsID, ...params}})

                    axios.get(`${window.backendUrl}/api/data/kotik?${dorsID}&${queryString}`)
                        .then(
                            function (response) {
                                if (response.status !== 200) {
                                    me.setState({isLoading: false, errorOccured: true, errorCode: response.status});

                                    return;
                                }
                                me.setState({
                                    ratingResp: response.data,
                                    isLoading: false,
                                    submittedParams: {clone, dorsID}
                                });
                            }
                        )
                        .catch(function (err) {
                            if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                                auth.logout()
                            }
                            me.setState({isLoading: false, errorOccured: true, errorCode: err});
                        });
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                console.log('Error :-S', err);
            });
    }

    handleClose = () => this.setState({modalOpen: false})

    submitFilters() {
        const me = this;
        const {params} = this.state
        const clone = {...params}

        let dorsID = clone.dors.length > 0 ? clone.dors : me.state.fullDorsList
        delete clone.dors
        clone.toDate = clone.toDate.format('YYYY-MM-DD')
        clone.fromDate = clone.fromDate.format('YYYY-MM-DD')

        let queryString = Object.keys(clone).map(key => key + '=' + clone[key]).join('&')


        this.props.hideSidebars();
        me.setState({isLoading: true})

        axios.get(`${window.backendUrl}/api/data/kotik?${dorsID}&${queryString}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }
                    me.setState({
                        ratingResp: response.data,
                        activePage: 1,
                        isLoading: false,
                        submittedParams: {clone, dorsID}
                    });
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }

    executeExcel() {
        const me = this;
        const {submittedParams} = this.state;

        let queryString = Object.keys(submittedParams.clone).map(key => key + '=' + submittedParams[key]).join('&')

        axios.get(`${window.backendUrl}/api/data/rating/excel?${submittedParams.dorsID}&${queryString}`, {
            responseType: 'blob', // important
        })
            .then(
                function (response) {
                    if (response.status !== 200) {
                        return;
                    }

                    const {type, size} = response.data

                    const url = window.URL.createObjectURL(new Blob([response.data], {type, size}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'rating');
                    document.body.appendChild(link);
                    link.click();

                    me.props.excelExecutionOff()
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }
                me.props.excelExecutionOff()
            });
    }

    componentDidUpdate() {
        if (this.props.excelExecution) {
            this.executeExcel()
        }
    }

    changePage(event, data) {
        this.setState({activePage: data.activePage})
    }

    closeErrorModal = () => this.setState({errorOccured: false})
    closeRepairModal = () => this.setState({repairOpened: false})
    closeKasantModal = () => this.setState({kasantOpened: false})

    handleChange = (event, {name, value}) => {
        const {params} = this.state
        if (params.hasOwnProperty(name)) {
            this.setState({params: {...params, [name]: value}});
        }
    }

    getRepairInfo(info, isNP, isPreviousPeriod) {
        const me = this
        const {submittedParams} = this.state
        let startDate = null
        let endDate = null

        if (submittedParams.month === '') {
            startDate = moment().year(parseInt(submittedParams.year, 10) - (isPreviousPeriod ? 1 : 0)).startOf('month').format('YYYY-MM-DD')
            endDate = moment().year(parseInt(submittedParams.year, 10) + 1 - (isPreviousPeriod ? 1 : 0)).startOf('month').format('YYYY-MM-DD')
        } else {
            const month = parseInt(submittedParams.month, 10) - (isPreviousPeriod ? 1 : 0)

            startDate = moment().year(submittedParams.year).month(month - 1).startOf('month').format('YYYY-MM-DD')
            endDate = moment().year(submittedParams.year).month(month).startOf('month').format('YYYY-MM-DD')
        }

        const uriPart = isNP ? 'rating-detail-np' : 'rating-detail-p'
        const requestObject = {startDate, endDate}

        let queryString = Object.keys(requestObject).map(key => key + '=' + requestObject[key]).join('&')

        me.setState({isLoading: true})

        axios.get(`${window.backendUrl}/api/data/${uriPart}/${info.userId}?${queryString}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        me.setState({isLoading: false, errorOccured: true, errorCode: response.status});

                        return;
                    }

                    me.setState({repairResp: response.data, isLoading: false, repairOpened: true, repairDetails: info});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }

                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }

    getKasantInfo(info, isPreviousPeriod) {
        const me = this
        const {submittedParams} = this.state
        let startDate = null
        let endDate = null

        if (submittedParams.month === '') {
            startDate = moment().year(submittedParams.year - (isPreviousPeriod ? 1 : 0)).startOf('month').format('YYYY-MM-DD')
            endDate = moment().year(submittedParams.year + 1 - (isPreviousPeriod ? 1 : 0)).startOf('month').format('YYYY-MM-DD')
        } else {
            const month = parseInt(submittedParams.month, 10) - (isPreviousPeriod ? 1 : 0)

            startDate = moment().year(submittedParams.year).month(month - 1).startOf('month').format('YYYY-MM-DD')
            endDate = moment().year(submittedParams.year).month(month).startOf('month').format('YYYY-MM-DD')
        }

        const requestObject = {startDate, endDate}

        let queryString = Object.keys(requestObject).map(key => key + '=' + requestObject[key]).join('&')

        me.setState({isLoading: true})

        axios.get(`${window.backendUrl}/api/data/rating-detail-kasant/${info.userId}?${queryString}`)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        me.setState({isLoading: false, errorOccured: true, errorCode: response.status});

                        return;
                    }

                    me.setState({kasantResp: response.data, isLoading: false, kasantOpened: true, kasantDetails: info});
                }
            )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }

                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }


    render() {
        let me = this;
        let lis = [];
        let lisMod = [];

        const helper = (field) => Math.round((field) * 100) / 100

        if (this.state.ratingResp.length > 0) {
            var target = this.state.ratingResp.slice((this.state.activePage - 1) * this.state.onPageLimit, this.state.activePage * this.state.onPageLimit)

            target.forEach(function (item, index) {
                if (me.state.partsShow === 1) {
                    lis.push(<Table.Row key={index}>
                        <Table.Cell className="center aligned">{index + 1}</Table.Cell>

                        <Table.Cell className="center aligned">{item.dorName}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fio}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fixedDepot}</Table.Cell>
                        <Table.Cell className="center aligned">{item.rating}</Table.Cell>
                        <Table.Cell className="center aligned">{item.allRepairCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nepTotalCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.teplSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.manevrsCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.electrsSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.totalSecCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.to2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to4}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to5}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr1}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sr}</Table.Cell>
                        <Table.Cell className="center aligned">{item.mod}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nep}</Table.Cell>
                    </Table.Row>);
                } else if (me.state.partsShow === 2) {
                    lis.push(<Table.Row key={index}>
                        <Table.Cell className="center aligned">{index + 1}</Table.Cell>

                        <Table.Cell className="center aligned">{item.dorName}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fio}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fixedDepot}</Table.Cell>
                        <Table.Cell className="center aligned">{item.rating}</Table.Cell>
                        <Table.Cell className="center aligned">{item.allRepairCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nepTotalCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.teplSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.manevrsCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.electrsSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.totalSecCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.to2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to4}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to5}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr1}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sr}</Table.Cell>
                        <Table.Cell className="center aligned">{item.mod}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nep}</Table.Cell>

                        <Table.Cell
                            className="center aligned">{item.notAcceptFromFirstPresentationCountTotal}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.teplvNotAcceptFromFirstPresentationCount}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.electNotAcceptFromFirstPresentationCount}</Table.Cell>

                        <Table.Cell
                            className="center aligned">{item.notAcceptFromOthersPresentationCountTotal}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.teplvNotAcceptFromOthersPresentationCount}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.electNotAcceptFromOthersPresentationCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.lineareQuipmentAccept}</Table.Cell>
                        <Table.Cell className="center aligned">{item.denideLineareQuipment}</Table.Cell>
                        <Table.Cell className="center aligned">{item.failedLineareQuipment}</Table.Cell>
                    </Table.Row>);
                } else if (me.state.partsShow === 3) {
                    lis.push(<Table.Row key={index}>
                        <Table.Cell className="center aligned">{index + 1}</Table.Cell>

                        <Table.Cell className="center aligned">{item.dorName}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fio}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fixedDepot}</Table.Cell>
                        <Table.Cell className="center aligned">{item.rating}</Table.Cell>
                        <Table.Cell className="center aligned">{item.allRepairCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nepTotalCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.teplSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.manevrsCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.electrsSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.totalSecCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.to2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to4}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to5}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr1}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sr}</Table.Cell>
                        <Table.Cell className="center aligned">{item.mod}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nep}</Table.Cell>

                        <Table.Cell
                            className="center aligned">{item.notAcceptFromFirstPresentationCountTotal}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.teplvNotAcceptFromFirstPresentationCount}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.electNotAcceptFromFirstPresentationCount}</Table.Cell>

                        <Table.Cell
                            className="center aligned">{item.notAcceptFromOthersPresentationCountTotal}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.teplvNotAcceptFromOthersPresentationCount}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.electNotAcceptFromOthersPresentationCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.lineareQuipmentAccept}</Table.Cell>
                        <Table.Cell className="center aligned">{item.denideLineareQuipment}</Table.Cell>
                        <Table.Cell className="center aligned">{item.failedLineareQuipment}</Table.Cell>

                        <Table.Cell className="center aligned">{item.sldAndWshopCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sldAndWshopRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.toAndRepairComplianceCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.toAndRepairComplianceRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.ptolControlCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.ptolControlRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.incomingEquipmentCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.incomingEquipmentRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.totalWshopCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.totalWshopRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.technologyAdherenceRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.ensuringProcessSparePartsRemarkCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.ensuringProcessEquipmentRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.measuringToolsRemarkCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.staffingRemarkCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.othersRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.repairTechnologyCheckStops}</Table.Cell>
                    </Table.Row>);
                } else if (me.state.partsShow === 4) {
                    lis.push(<Table.Row key={index}>
                        <Table.Cell className="center aligned">{index + 1}</Table.Cell>

                        <Table.Cell className="center aligned">{item.dorName}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fio}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fixedDepot}</Table.Cell>
                        <Table.Cell className="center aligned">{item.rating}</Table.Cell>
                        <Table.Cell className="center aligned">{item.allRepairCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nepTotalCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.teplSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.manevrsCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.electrsSecCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.totalSecCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.to2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to4}</Table.Cell>
                        <Table.Cell className="center aligned">{item.to5}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr1}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr2}</Table.Cell>
                        <Table.Cell className="center aligned">{item.tr3}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sr}</Table.Cell>
                        <Table.Cell className="center aligned">{item.mod}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nep}</Table.Cell>

                        <Table.Cell
                            className="center aligned">{item.notAcceptFromFirstPresentationCountTotal}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.teplvNotAcceptFromFirstPresentationCount}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.electNotAcceptFromFirstPresentationCount}</Table.Cell>

                        <Table.Cell
                            className="center aligned">{item.notAcceptFromOthersPresentationCountTotal}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.teplvNotAcceptFromOthersPresentationCount}</Table.Cell>
                        <Table.Cell
                            className="center aligned">{item.electNotAcceptFromOthersPresentationCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.lineareQuipmentAccept}</Table.Cell>
                        <Table.Cell className="center aligned">{item.denideLineareQuipment}</Table.Cell>
                        <Table.Cell className="center aligned">{item.failedLineareQuipment}</Table.Cell>

                        <Table.Cell className="center aligned">{item.sldAndWshopCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sldAndWshopRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.toAndRepairComplianceCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.toAndRepairComplianceRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.ptolControlCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.ptolControlRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.incomingEquipmentCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.incomingEquipmentRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.totalWshopCheckCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.totalWshopRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.technologyAdherenceRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.ensuringProcessSparePartsRemarkCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.ensuringProcessEquipmentRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.measuringToolsRemarkCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.staffingRemarkCount}</Table.Cell>
                        <Table.Cell className="center aligned">{item.othersRemarkCount}</Table.Cell>

                        <Table.Cell className="center aligned">{item.repairTechnologyCheckStops}</Table.Cell>


                        <Table.Cell className="center aligned">{item.jaku12Prescriptions}</Table.Cell>
                        <Table.Cell className="center aligned">{item.jaku12FixedPrescriptions}</Table.Cell>

                        <Table.Cell className="center aligned">{item.sldPrescriptions}</Table.Cell>
                        <Table.Cell className="center aligned">{item.sldFixedPrescriptions}</Table.Cell>

                        <Table.Cell className="center aligned">{item.prescriptionsTotal}</Table.Cell>
                        <Table.Cell className="center aligned">{item.fixedPrescriptionsTotal}</Table.Cell>

                        <Table.Cell className="center aligned">{item.cycleWorkCheck}</Table.Cell>
                        <Table.Cell className="center aligned">{item.equipmentWorkshopCheck}</Table.Cell>
                        <Table.Cell className="center aligned">{item.docsAvailabilityCheck}</Table.Cell>
                        <Table.Cell className="center aligned">{item.nonDestructiveTestingCheck}</Table.Cell>
                        <Table.Cell className="center aligned">{item.workerLowSkillCheck}</Table.Cell>
                        <Table.Cell className="center aligned">{item.noMeasuringToolCheck}</Table.Cell>
                        <Table.Cell className="center aligned">{item.anotherCheck}</Table.Cell>

                        <Table.Cell className="center aligned">{item.reasonsForNonCompliance}</Table.Cell>
                        <Table.Cell className="center aligned">{item.prosecutions}</Table.Cell>
                        <Table.Cell className="center aligned">{item.rewards}</Table.Cell>
                    </Table.Row>);
                }
            });
        }

        const {params, repairDetails, repairResp, repairOpened, kasantDetails, kasantResp, kasantOpened} = this.state;

        return (
            <div>
                <div style={{position: 'relative'}}>
                    <span style={{fontSize: '14pt', color: '#C1C8D8', paddingLeft: '15px'}}>Рейтинг приемщика</span>
                    {this.state.partsShow > 1 ? <Button onClick={() => {
                        this.setState({partsShow: this.state.partsShow - 1})
                    }} size={'tiny'} style={this.state.partsShow < 4 ? {
                        right: '120px',
                        position: 'absolute'
                    } : {right: '0px', position: 'absolute'}}>Свернуть</Button> : null}
                    {this.state.partsShow < 4 && !this.state.isLoading ? <Button onClick={() => {
                        this.setState({partsShow: this.state.partsShow + 1}); /*  document.querySelector('.content-container').scrollTo(899, 0) */
                    }} size={'tiny'} style={{right: '0px', position: 'absolute'}}>Развернуть</Button> : null}
                </div>
                {me.state.filterShow ? <Modal closeIcon size='mini' open={this.state.filterShow}
                                              onClose={me.filterStateChange}>
                    <Modal.Content>
                        <Modal.Description>
                            <Form>
                                <Form.Field>
                                    <LabeledDateInput
                                        closable={true}
                                        closeOnMouseLeave={true}
                                        label="Дата начала периода"
                                        date={this.state.params.fromDate}
                                        onChange={(date) => {
                                            let beginPeriod = date
                                            let endPeriod = this.state.params.toDate

                                            if (beginPeriod.isAfter(endPeriod)) {
                                                beginPeriod = endPeriod
                                            }


                                            this.setState({
                                                params: {
                                                    ...params,
                                                    fromDate: beginPeriod,
                                                    toDate: endPeriod
                                                }
                                            })
                                        }}
                                    />

                                    <LabeledDateInput
                                        closable={true}
                                        closeOnMouseLeave={true}
                                        label="Дата окончания периода"
                                        date={this.state.params.toDate}
                                        onChange={(date) => {
                                            let endPeriod = date
                                            let beginPeriod = this.state.params.fromDate

                                            if (endPeriod.isBefore(beginPeriod)) {
                                                endPeriod = beginPeriod
                                            }

                                            this.setState({
                                                params: {
                                                    ...params,
                                                    fromDate: beginPeriod,
                                                    toDate: endPeriod
                                                }
                                            })
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Предприятие</label>
                                    <Dropdown placeholder='Предприятие' fluid multiple search selection
                                              options={this.state.repairDepos}
                                              value={this.state.dorsList ? this.state.dorsList : []}
                                              onChange={(e, {value}) => {
                                                  this.setState({
                                                      params: {
                                                          ...params,
                                                          dors: value.map(dors => ("dors=" + dors)).join('&')
                                                      }
                                                  });
                                                  this.setState({dorsList: value})
                                              }}
                                    />
                                </Form.Field>

                                <Button type='submit' onClick={() => {
                                    this.submitFilters();
                                    this.filterStateChange()
                                }}>Применить</Button>
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                </Modal> : <div> {!me.state.isLoading ? <FloatButton
                    main={{
                        text: "",
                        rotate: true,
                        icon: "angle left",
                    }}
                    actions={[
                        {
                            text: "Настройка фильтров",
                            icon: "filter",
                            onClick: () => {
                                this.filterStateChange()
                            }
                        },
                    ]}
                /> : null}</div>
                }
                <Segment basic className={`report-container ${(this.state.isLoading ? 'loading-report' : '')}`}>

                    {this.state.isLoading ? (
                        <SkeletonPage view={'ReceiverRating'}/>
                    ) : (
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    {this.state.actualHeaders.level1.part1.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    ))}
                                    {this.state.partsShow > 1 ? this.state.actualHeaders.level1.part2.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                    {this.state.partsShow > 2 ? this.state.actualHeaders.level1.part3.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                    {this.state.partsShow > 3 ? this.state.actualHeaders.level1.part4.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                </Table.Row>
                                <Table.Row>
                                    {this.state.actualHeaders.level2.part1.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    ))}
                                    {this.state.partsShow > 2 ? this.state.actualHeaders.level2.part3.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                    {this.state.partsShow > 3 ? this.state.actualHeaders.level2.part4.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                </Table.Row>
                                <Table.Row>
                                    {this.state.actualHeaders.level3.part1.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    ))}
                                    {this.state.partsShow > 1 ? this.state.actualHeaders.level3.part2.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                    {this.state.partsShow > 2 ? this.state.actualHeaders.level3.part3.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                    {this.state.partsShow > 3 ? this.state.actualHeaders.level3.part4.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                </Table.Row>
                                <Table.Row>
                                    {this.state.partsShow > 2 ? this.state.actualHeaders.level4.part3.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}

                                </Table.Row>
                                <Table.Row>
                                    {this.state.partsShow > 2 ? this.state.actualHeaders.level5.part3.map((e) => (
                                        <Table.HeaderCell className="center aligned" rowSpan={e.row}
                                                          colSpan={e.col}>{e.name}</Table.HeaderCell>
                                    )) : null}
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {lis.length > 0 ? lis : <Table.Row>
                                    <Table.Cell className="center aligned" colSpan='61'>Нет элементов удовлетворяющих
                                        условиям поиска</Table.Cell>
                                </Table.Row>}
                            </Table.Body>
                        </Table>
                    )}

                    <Modal open={this.state.errorOccured} closeOnDimmerClick={false} basic size='small'>
                        <Header icon='warning sign' content={`Ошибка ${this.state.errorCode}`}/>
                        {/*<Modal.Content></Modal.Content>*/}
                        <Modal.Actions>

                            <Button onClick={this.closeErrorModal} inverted>
                                <Icon name='checkmark'/> Закрыть
                            </Button>
                        </Modal.Actions>
                    </Modal>

                    {(repairDetails && repairOpened)
                        ? (<Modal centered={false} className={'employee-repairs'} open={repairOpened} closeIcon
                                  closeOnDimmerClick={true} onClose={this.closeRepairModal}>
                            <Header content={`Приёмщик: ${repairDetails.fio}, ${repairDetails.predName}`}/>
                            <Modal.Content>
                                <Table celled structured striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="center aligned">Депо</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Локомотив</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Вид</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Начало</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Окончание</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {repairResp.map((item, index) => (<Table.Row key={index}>
                                            <Table.Cell className="center aligned">{item.predName}</Table.Cell>
                                            <Table.Cell
                                                className="center aligned">{item.serName} {item.znsLoc}{item.prsCharLoc}</Table.Cell>
                                            <Table.Cell className="center aligned">{item.sostName}</Table.Cell>
                                            <Table.Cell
                                                className="center aligned">{moment(item.dateRepairBegin).format("YYYY.MM.DD HH:mm")}</Table.Cell>
                                            <Table.Cell
                                                className="center aligned">{item.dateRepairFinish ? moment(item.dateRepairFinish).format("YYYY.MM.DD HH:mm") : null}</Table.Cell>
                                        </Table.Row>))}
                                    </Table.Body>
                                </Table>
                            </Modal.Content>
                        </Modal>)
                        : null
                    }

                    {(kasantDetails && kasantOpened)
                        ? (<Modal centered={false} className={'employee-repairs'} open={kasantOpened} closeIcon
                                  size={'large'}
                                  closeOnDimmerClick={true} onClose={this.closeKasantModal}>
                            <Header content={`Приёмщик: ${kasantDetails.fio}, ${kasantDetails.predName}`}/>
                            <Modal.Content scrolling>
                                <Table celled structured striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="center aligned"
                                                              style={{width: 130}}>Локомотив</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned" style={{width: 150}}>Дата
                                                отказа</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Категория
                                                отказа</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Причина
                                                отказа</Table.HeaderCell>
                                            <Table.HeaderCell className="center aligned">Последствия
                                                отказа</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {kasantResp.map((item, index) => (<Table.Row key={index}>
                                            <Table.Cell
                                                className="center aligned">{item.serName} {item.znsLoc}{item.prsCharLoc}</Table.Cell>
                                            <Table.Cell
                                                className="center aligned">{moment(item.dtNotification).format("YYYY.MM.DD HH:mm")}</Table.Cell>
                                            <Table.Cell className="center aligned">{item.characterName}</Table.Cell>
                                            <Table.Cell className="center aligned">{item.reasonName}</Table.Cell>
                                            <Table.Cell className="center aligned">{item.consequenceName}</Table.Cell>
                                        </Table.Row>))}
                                    </Table.Body>
                                </Table>
                            </Modal.Content>
                        </Modal>)
                        : null
                    }
                </Segment>
            </div>
        )
    }
}

ReceiverRating.defaultProps = {
    hideSidebars: () => null
}

export default ReceiverRating