import React, {useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import {useHistory} from "react-router-dom";

export const DeleteModal = ({openDel, setOpenDel, taskdata, updateSubsArray, subtask}) => {


    return (
        <Modal
            size={'mini'}
            open={openDel}
            onClose={() => {
                setOpenDel(false)
            }}
        >
            <Modal.Header>Удаление подзадачи</Modal.Header>
            <Modal.Content>
                <p>Вы действительно хотите удалить подзадачу?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={() => {
                    setOpenDel(false)
                }}>
                    Нет
                </Button>
                <Button positive onClick={() => {
                    let newSubs = [...taskdata.subtasks]
                    const subToMoveIndex = taskdata.subtasks.findIndex(sub => sub.subtaskId === subtask.subtaskId);
                    newSubs.splice(subToMoveIndex, 1)
                    updateSubsArray(newSubs)
                    setOpenDel(false)
                }}>
                    Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
};