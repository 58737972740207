import React, {useEffect, useState} from 'react'
import {Button, Table, Loader, Dropdown} from 'semantic-ui-react'
import moment from 'moment'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";

const RecommendationSelectionLocomotive = ({hideSidebars}) => {
    const [tableMessage, setTableMessage] = useState({show: false})

    const [sendGetJyForLocFind, jyForLocFindLoading, jyForLocFind] = useApiMethod(ekasut.jy.getJyForLocFind, undefined, [])

    useEffect(() => {
        sendGetJyForLocFind()
            .then((jyForLocFind) => {
                setTableMessage({show: jyForLocFind.length === 0, message: 'Нет данных'})
            })
    }, [sendGetJyForLocFind])

    return (
        <>
            {
                jyForLocFindLoading ? (
                    <Loader active inline='centered'/>
                ) : (
                    <Table celled structured striped size='small' className='loc-states' onClick={hideSidebars} unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="center aligned">ТН машиниста</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">ФИО машиниста</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">Станция отправления</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">Станция прибытия</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">Контрольная станция</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">Явка (Дата/время - до
                                    минут)</Table.HeaderCell>
                                <Table.HeaderCell className="center aligned">Локомотив</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {jyForLocFind.map((item, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell className="right aligned">{item.mashTabNum}</Table.Cell>
                                    <Table.Cell className="right aligned">{item.mashFio}</Table.Cell>
                                    <Table.Cell className="right aligned">{item.stOtpr}</Table.Cell>
                                    <Table.Cell className="right aligned">{item.stPrib}</Table.Cell>
                                    <Table.Cell className="right aligned">{item.stCtl}</Table.Cell>
                                    <Table.Cell
                                        className="right aligned">{item.t1 ? moment(item.t1).format("DD.MM.YY HH:mm") : null}</Table.Cell>
                                    {/*<Table.Cell className="right aligned">{item.sostavn}{item.usedEkasutLoco}</Table.Cell>*/}
                                    <LocomotiveCell
                                        appearanceForLoc={item}
                                        sendGetJyForLocFind={sendGetJyForLocFind}
                                    />
                                </Table.Row>
                            )}
                            {tableMessage.show && (
                                <Table.Row>
                                    <Table.Cell
                                        style={{height: 60}}
                                        className='center aligned'
                                        colSpan={7}
                                    >
                                        {tableMessage.message}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                )
            }
        </>
    )
}
export default RecommendationSelectionLocomotive

const LocomotiveCell = ({appearanceForLoc, sendGetJyForLocFind}) => {
    return (
        <Table.Cell className="center aligned">
            {
                appearanceForLoc.sostavn === null ? (
                    <NoRecommend
                        appearanceForLoc={appearanceForLoc}
                        sendGetJyForLocFind={sendGetJyForLocFind}
                    />
                ) : (
                    appearanceForLoc.usedEkasutLoco ? (
                        <EkAsutApproved
                            appearanceForLoc={appearanceForLoc}
                            sendGetJyForLocFind={sendGetJyForLocFind}
                        />
                    ) : appearanceForLoc.sostavn
                )
            }
        </Table.Cell>
    )
}

const NoRecommend = ({appearanceForLoc, sendGetJyForLocFind}) => {
    const [selected, setSelected] = useState(null)

    const [sendGetLocsForRoute, locsForRouteLoading, locsForRoute] = useApiMethod(ekasut.jy.getLocsForRoute, undefined, [])
    const [sendPutLocsForRoute, puttingLocForRouteLoading] = useApiMethod(ekasut.jy.putLocsForRoute, undefined, [], ({data}) => {
        sendGetJyForLocFind()
        return data
    })

    return (
        <>
            {
                locsForRoute.length > 0
                    ? (
                        <Dropdown
                            search selection
                            options={[...locsForRoute].map((x, i) => (
                                {key: x.idObject, value: x.idObject, text: `${x.sostavn}  (ТО-2: ${x.to2StName})`}
                            ))}
                            onChange={(e, {value}) => {
                                setSelected(value)
                            }}
                        />
                    )
                    : (
                        <Button loading={locsForRouteLoading} onClick={() => {
                            sendGetLocsForRoute({
                                'otrId': appearanceForLoc.otrKod,
                                'stPribId': appearanceForLoc.stPribId,
                                'routeLength': appearanceForLoc.routeLength,
                                'trackId': appearanceForLoc.trackId,
                            })
                        }}>
                            Подобрать
                        </Button>
                    )
            }

            {
                selected
                    ? (
                        <Button loading={puttingLocForRouteLoading} onClick={() => {
                            sendPutLocsForRoute({
                                'routeRd': appearanceForLoc.routeRd,
                                'routeId': appearanceForLoc.routeId,
                                'idObject': selected,
                            })
                        }}>
                            Отправить
                        </Button>
                    )
                    : null
            }

        </>

    )
}

const EkAsutApproved = ({appearanceForLoc, sendGetJyForLocFind}) => {
    const [sendDeleteLocsForRoute, deletingLocForRouteLoading] = useApiMethod(ekasut.jy.deleteLocsForRoute, undefined, [], ({data}) => {
        sendGetJyForLocFind()
        return data
    })

    return (
        <>
            <span>{appearanceForLoc.sostavn}</span>
            <Button
                loading={deletingLocForRouteLoading}
                icon='delete'
                onClick={() => {
                    sendDeleteLocsForRoute({
                        'routeRd': appearanceForLoc.routeRd,
                        'routeId': appearanceForLoc.routeId,
                    })
                }}
            />
        </>
    )

}