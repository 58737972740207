import {Modal} from "semantic-ui-react";
import LoadingContent from "../../views/RatingsWorkLocomotives/NepLayout/LoadingContent";
import React from "react";
import cn from 'classnames'
import styles from './index.module.scss'

/** @deprecated use Widget with Modal or Prompt */
const AppModal = ({loading, children, className, message, loadingMessage, ...modalProps}) => {
    return (
        <Modal
            // Blur effect causing show up DateInput popup and locomotive picture under dimmer, don't use it
            /*dimmer='blurring'*/
            basic
            className={cn(styles.modal, className)}
            {...modalProps}
        >
            <Modal.Content>
                <LoadingContent
                    showLoader={loading}
                    loaderClassName={styles.loader}
                    message={message}
                    loadingMessage={loadingMessage}
                >
                    {children}
                </LoadingContent>
            </Modal.Content>
        </Modal>
    )
}
export default AppModal