import React, {useEffect, useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import DropdownField from "../../../../shared/fields/DropdownField";
import InputField from "../../../../shared/fields/InputField";
import styles from "../OrganizationInspection.module.scss";
import ekasut from "../../../../api/ekasut";
import {useLocalStore} from "mobx-react-lite";
import { Input, Select } from 'antd';

const { Option } = Select;

const AddComMember = ({setAddComMember, addComMember, getOrderPeople}) => {

    const [depoOptions, setDepoOptions] = useState([])
    const [items, setItems] = useState([])
    const [profs, setProfs] = useState([])
    const [roleks, setRoleks] = useState([])
    const [bio, setBio] = useState('')
    const [orderFirm, setOrderFirm] = useState('')
    const [connection, setConnection] = useState('')
    const [selectProfs, setSelectProfs] = useState(null)
    const [selectRolek, setSelectRolek] = useState(null)
    const [selectDepo, setSelectDepo] = useState(null)
    const [selectItem, setSelectItem] = useState(null)

    useEffect(() => {
      let cleanupFunction = false

      try {
        const getAllData = async() => {  
          const [rolek, prof, depos, item] = await Promise.all([
            ekasut.commisionInspection.getRoleks(),
            ekasut.commisionInspection.getProfs(),
            ekasut.commisionInspection.getDeposForAddCom(localStorage.getItem('kodFirm')),
            ekasut.commisionInspection.getItem()
          ])
          
          if (addComMember && !cleanupFunction) {
            setRoleks(rolek?.data)
            setProfs(prof?.data)
            setDepoOptions(depos?.data)
            setItems(item?.data)
          }
        }
  
        const getOrderUUID = async() => {
          const response = await ekasut.commisionInspection.getOrderFirm(localStorage.getItem('kodFirm'), localStorage.getItem('seasonYear'), localStorage.getItem('season'))
          if (!cleanupFunction) setOrderFirm(response?.data[0]?.rgOrder)
        }
  
        getOrderUUID()
        getAllData()
      } catch (error) {
        console.log(error.message);
      }
      
      return () => cleanupFunction = true
    }, [addComMember])

    const bioChange = (e) => {
      setBio(e.target.value)
    }

    const connectionChange = (e) => {


      setConnection(e.target.value)
    }

    const sendComissarData = async() => {
      const response = await ekasut.commisionInspection.postPeopleOrder(selectDepo, orderFirm, bio, selectProfs, selectRolek, selectItem, connection)
      response.status === 200 && getOrderPeople(localStorage.orderUUID)
    }

    return (
        <Modal
            className={styles.addModal}
            onClose={() => setAddComMember(false)}
            open={addComMember}
            size={'mini'}>
            <Modal.Header>Добавление члена комиссии</Modal.Header>
            <Modal.Content>
                <span style={{padding: '0 7px', fontSize: '12px', color: 'black'}}>Ф.И.О.</span>
                <Input 
                  value={bio}
                  onChange={bioChange}
                />

                <span style={{padding: '0 7px', fontSize: '12px', color: 'black'}}>способ связи с комиссаром(тлф/почта)</span>
                <Input 
                  value={connection}
                  onChange={connectionChange}
                />

                <span style={{padding: '0 7px', fontSize: '12px', color: 'black'}}>Роль</span><br />
                <Select style={{ width: '100%' }} onChange={e => setSelectRolek(e)}>
                  {roleks?.map(e => <Option key={e.kodRoleK}>{e.rolekName}</Option>)}
                </Select><br />

                <span style={{padding: '0 7px', fontSize: '12px', color: 'black'}}>Должность</span><br />
                <Select style={{ width: '100%' }} onChange={e => setSelectProfs(e)}>
                  {profs?.map(e => <Option key={e.kodProf}>{e.profName}</Option>)}
                </Select><br />

                <span style={{padding: '0 7px', fontSize: '12px', color: 'black'}}>Подразделение</span><br />
                <Select style={{ width: '100%' }} onChange={e => setSelectDepo(e)}>
                  {depoOptions?.map(e => <Option key={e.kodFirm}>{e.firmName}</Option>)}
                </Select><br />
                
                <span style={{padding: '0 7px', fontSize: '12px', color: 'black'}}>Ответственность</span><br />
                <Select style={{ width: '100%' }} onChange={e => setSelectItem(e)}>
                  {items?.map(e => <Option key={e.kodItem}>{e.itemName}</Option>)}
                </Select>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    setAddComMember(false)
                }}>
                    Отменить
                </Button>
                <Button className="button-primary" onClick={() => {
                    setAddComMember(false)
                    sendComissarData()
                }}>
                    Добавить
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default AddComMember;