import React from 'react'
import { Radio, Grid} from 'semantic-ui-react'
import moment from 'moment'

export default function TimeRangeSelector({timeRange, onChange}) {

	const handleChange = (e, {value}) => {
		let {from, to} = getFromTo(value)
		onChange(value, from, to)
	}

	return (
		<Grid>
			<Grid.Row columns={3} textAlign='center'>
				<Grid.Column>
					<Radio
						label='Текущая дата' 
						value={timeRanges.DAY}
						checked={timeRange === timeRanges.DAY}
						onChange={handleChange}
					/>
				</Grid.Column>
				<Grid.Column >
					<Radio
						label='Текущий месяц'
						value={timeRanges.MONTH}
						checked={timeRange === timeRanges.MONTH}
						onChange={handleChange}
					/>
				</Grid.Column>
				<Grid.Column >
					<Radio
						label='Текущий год'
						value={timeRanges.YEAR}
						checked={timeRange === timeRanges.YEAR}
						onChange={handleChange}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	)
}

export const timeRanges = {
	DAY: 'day',
	MONTH: 'month',
	YEAR: 'year'
}

export const getFromTo = (timeRange) => {
	let to = moment().toDate()
	to.setHours(23,59,59,0)
	let from = moment(to).toDate()

	from.setHours(0,0,0,0)

	if (timeRange === timeRanges.MONTH) {
		from.setDate(1)
	}
	if (timeRange === timeRanges.YEAR) {
		from.setDate(1)
		from.setMonth(0)
	}

	return {from, to}
}
