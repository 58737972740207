import React from 'react';
import {Button, Icon} from "semantic-ui-react";

const RoundButtonWithIcon = ({iconName, ...buttonProps}) => (
    <Button icon circular basic style={{margin: 0}} {...buttonProps}>
        <Icon name={iconName} color='grey'/>
    </Button>
)
RoundButtonWithIcon.defaultProps = {
    iconName: "plus"
}

export default RoundButtonWithIcon;