
/** Get questions from on process */
export const getProcessQuestions = (process) => {
    const questions = []
    process.criterions.forEach((criterion) => {
        criterion.questions.forEach((question, questIndex) => {
            questions.push({
                criterionId: criterion.criterionId,
                criterionName: criterion.criterionName,
                questionId: question.questionId,
                question: question.question,
                gradeId: question.gradeId,
                gradeName: question.gradeName,
                description: question.description,
                reasonId: question.reasonId,
                reasonName: question.reasonName,
                hasAdvice: question.hasAdvice
            })
        })
    })
    return questions
}

/** Get questions from response data */
export const extractQuestions = (processes) => {
    const questions = []
    processes.forEach(process => questions.push(...getProcessQuestions(process)))

    return questions
}

/** Restructure processes, remove one nesting layer: criterions */
export const processesParser = (processes) => {
    return processes.map(item => ({processId: item.processId, processName: item.processName, questions: getProcessQuestions(item)}))
}

