import React, {useState} from 'react'
import {Button, Dropdown} from "semantic-ui-react";
import {decadeEndAtDay, decadeStartAtDay, formOptions, quartalStartAtMonth} from "../../helpers/utils";
import {dates} from "../../Dictionaries";
import cn from 'classnames'
import styles from './DataFilter.module.scss'
import moment from 'moment'

export default function DataFilter({onChange, onClear}) {
    const [values, setValues] = useState(initValues)
    const [disabled, setDisabled] = useState(initDisabled)

    const updateValues = (newValues) => {
        const nextValues = {...values, ...newValues}

        let nextDisabled = {}
        if (nextValues.year === null) {
            nextDisabled = initDisabled
        } else {
            if (nextValues.month !== null) {
                nextDisabled.month = false
                nextDisabled.quartal = true
                nextDisabled.decade = false
            } else if (nextValues.quartal !== null) {
                nextDisabled.month = true
                nextDisabled.quartal = false
                nextDisabled.decade = true
            } else {
                nextDisabled.month = false
                nextDisabled.quartal = false
                nextDisabled.decade = true
            }
        }

        if (nextValues.year !== null) {
            const from = moment()
            const to = moment()

            from.set({hour:0, minute:0, second:0, millisecond:0})
            to.set({hour: 23, minute: 59, seconds: 59, millisecond: 999})

            from.set({year: dates.lastTenYears[nextValues.year]})
            to.set({year: from.year()})

            if (nextValues.month !== null) {
                from.set({month: nextValues.month})
                to.set({month: nextValues.month})
                if (nextValues.decade !== null) {
                    from.set({date: decadeStartAtDay(nextValues.decade)})
                    to.set({date: decadeEndAtDay(nextValues.decade, to.daysInMonth())})
                } else {
                    from.set({date: 1})
                    to.set({date: to.daysInMonth()})
                }
            } else if (nextValues.quartal !== null) {
                from.set({month: quartalStartAtMonth(nextValues.quartal), date: 1})
                to.set({month: from.month() + 2})
                to.set({date: to.daysInMonth()})
            } else {
                from.set({month: 0, date: 1})
                to.set({month: 11})
                to.set({date: to.daysInMonth()})
            }
            onChange(from, to)
        }

        setDisabled(nextDisabled)
        setValues(nextValues)
    }

    const getDropdownStyle = (disabled, value) => {
        return cn(styles.dropdown, !disabled && (value === null) && styles.canSelect)
    }

    return (
        <>
            <label>Год</label>
            <Dropdown
                fluid selection
                placeholder={'Год'}
                className={getDropdownStyle(false, values.year)}
                options={yearOptions}
                value={values.year}
                onChange={(e, {value}) => {
                    updateValues({year: value})
                }}
            />
            <div className={styles.quartalAndMonth}>
                <div>
                    <label className={cn(disabled.quartal && styles.disabledLabel)}>Квартал</label>
                    <Dropdown
                        fluid selection
                        disabled={disabled.quartal}
                        className={getDropdownStyle(disabled.quartal, values.quartal)}
                        placeholder={'Квартал'}
                        options={quartalsOptions}
                        value={values.quartal}
                        onChange={(e, {value}) => {
                            updateValues({quartal: value})
                        }}
                    />
                </div>
                <div>
                    <label className={cn(disabled.month && styles.disabledLabel)}>Месяц</label>
                    <Dropdown
                        fluid selection
                        disabled={disabled.month}
                        className={getDropdownStyle(disabled.month, values.month)}
                        placeholder={'Месяц'}
                        options={monthOptions}
                        value={values.month}
                        onChange={(e, {value}) => {
                            updateValues({month: value})
                        }}
                    />

                    <label className={cn(disabled.decade && styles.disabledLabel)}>Декада</label>
                    <Dropdown
                        fluid selection
                        disabled={disabled.decade}
                        className={getDropdownStyle(disabled.decade, values.decade)}
                        placeholder={'Декада'}
                        value={values.decade}
                        options={decadeOptions}
                        onChange={(e, {value}) => {
                            updateValues({decade: value})
                        }}
                    />
                </div>
            </div>

            <Button
                basic
                icon={'erase'}
                onClick={() => {
                    updateValues(initValues)
                    onClear()
                }}
            />
        </>
    )
}

const initDisabled = {
    quartal: true,
    month: true,
    decade: true,
}
const initValues = {
    year: null,
    quartal: null,
    month: null,
    decade: null
}
const decadeOptions = formOptions(dates.decades)
const monthOptions = formOptions(dates.months)
const yearOptions = formOptions(dates.lastTenYears)
const quartalsOptions = formOptions(dates.quartals)