import React from 'react';
import {observer} from "mobx-react-lite";
import {Button, Divider} from "semantic-ui-react";
import SearchSerNumSec from "./SearchSerNumSec";
import MultiSelRemarks from "./MultiSelRemarks";
import Comment from "./Comment";
import JustifyContainer from "../../../../shared/JustifyContainer";
import RoundButtonWithIcon from "../components/RoundButtonWithIcon";
import {useStore} from "../StoreProvider";

const AddLocRemarks = observer(() => {

    const store = useStore()
    const selectedKeys = Object.keys(store.selected)
    return (
        selectedKeys.map((key, index) => {
            const isLastItem = store.selectedCount() - 1 === index
            const isSingleItem = store.selectedCount() === 1
            return (
                <React.Fragment key={key}>
                    <SearchSerNumSec
                        selKey={key}
                    />
                    <MultiSelRemarks
                        label="Замечания к состоянию локомотива"
                        selKey={key}
                    />
                    <Comment
                        label="Комментарии"
                        selKey={key}
                    />
                    <JustifyContainer justifyContent="space-between">
                        <Button
                            basic color="red"
                            content="Удалить"
                            onClick={() => {
                                if (isSingleItem) {
                                    store.resetAllSelected()
                                } else {
                                    store.removeSelected(key)
                                }
                            }}
                        />
                        {isLastItem && !store.isSelectedMax() && (
                            <RoundButtonWithIcon
                                iconName="plus"
                                onClick={() => {
                                    const sel = store.selected[key]
                                    store.addSelectedItem({
                                        series: sel.series,
                                        number: sel.number,
                                        /*section: sel.section,*/
                                        seriesIndex: sel.seriesIndex,
                                        numberIndex: sel.numberIndex,
                                        /*sectionIndex: sel.sectionIndex,*/
                                        numberList: sel.numberList,
                                        sectionList: sel.sectionList
                                    })
                                }}
                            />
                        )}
                    </JustifyContainer>

                    {!isLastItem && (
                        <Divider/>
                    )}
                </React.Fragment>
            )
        })
    )
})


export default AddLocRemarks;