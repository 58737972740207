import React, {useEffect} from 'react'
import CalendarDashboard from "../components/CalendarDashboard"
import {observer} from "mobx-react-lite";
import {useStore} from "../StoreProvider";
import {useHistory} from 'react-router-dom'


const EventsCalendar = observer(() => {
    const store = useStore()
    const history = useHistory()

    useEffect(() => {
        store.fetchEventsIfNeed()
    }, [store])

    return (
        <CalendarDashboard
            year={store.year}
            month={store.month}
            events={store.events}
            onAdd={(date) => {
                history.push(`/plan_work/add_plan?date=${date.format("YYYY-MM-DD")}`)
            }}
            onChange={(year, month) => {
                store.setCalendarMonth(year, month)
            }}
        />
    )
})

export default EventsCalendar