import React from 'react';
import {Icon, Segment} from "semantic-ui-react";
import cn from 'classnames'
import styles from './index.module.scss'
import AuditSteps from "../../views/AuditControl/AuditSteps";

const Widget = (
    {
        className, closeIcon, expandIcon = false, onExpand = () => null,
        onClose = () => null, content, children, header, footer
    }
) => {
    return (
        <div className={styles.component}>
            {closeIcon && <Icon name="close" className="close-button top-right-button" onClick={onClose}/>}
            {expandIcon && <Icon name="expand" className="expand-button top-right-button" onClick={onExpand}/>}
            <Segment.Group className={cn("widget", className)}>
                {header && <Segment className="widget-header">{header}</Segment>}
                <Segment>{content || children}</Segment>
                {footer && <Segment>{footer}</Segment>}
            </Segment.Group>
        </div>
    );
};

export default Widget;