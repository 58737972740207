import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import LocomotiveModal from "../../shared/LocomotiveModal";

// Show modal with locomotive card for first render if url contain proper params
const ModalLocURL = React.memo(({onError = () => null}) => {

    const location = useLocation()

    const [modal, setModal] = useState((() => {
        const check = (val) => Number.isInteger(val) && (val >= 0)
        const params = new URLSearchParams(location.search)
        const ser = parseInt(params.get("ser"))
        const zns = parseInt(params.get("zns"))
        const prs = parseInt(params.get("prs"))
        let result = {}
        if (check(ser) && check(zns) && check(prs)) {
            result = {show: true, loc: {ser, zns, prs}}
        } else {
            result = {show: false, loc: {}}
        }
        return result
    })())


    return (
        <LocomotiveModal
            open={modal.show}
            seriesId={modal.loc.ser}
            number={modal.loc.zns}
            sectionId={modal.loc.prs}
            onClose={() => {
                setModal({show: false, loc: {}})
            }}
            onError={err => {
                setModal({show: false, loc: {}})
                onError(err)
            }}
        />
    )
})
export default ModalLocURL