import React from 'react';
import {MoreOutlined, EllipsisOutlined, UserOutlined} from '@ant-design/icons';
import { Button, Dropdown, Space, Menu } from 'antd';
import {Icon} from "semantic-ui-react";

const ChatDropdownButton = ({onReportClick}) => {
    const handleMenuClick = (props) => console.log(props);

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={[
                {
                    label: 'Доп кнопка',
                    key: '1',
                    icon: <UserOutlined />,
                },
                {
                    label: '2nd menu item',
                    key: '2',
                    icon: <UserOutlined />,
                },
                {
                    label: '3rd menu item',
                    key: '3',
                    icon: <UserOutlined />,
                },
            ]}
        />
    );

    return (
        <div style={{position: "absolute", top: "-10px", right: "-10px"}}>
            {/*<Dropdown.Button onClick={handleButtonClick} overlay={menu}>*/}
            {/*    Отчет*/}
            {/*</Dropdown.Button>*/}
            <Button onClick={onReportClick}>Отчет</Button>

        </div>
    );
};

export default ChatDropdownButton;