import React, {useState, useMemo, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid, Modal } from 'semantic-ui-react'
import './FailureChartsAndModal.scss'
import FailureTable from './FailureTable';
import DetailsTable from './DetailsTable';
import {useLocation, useHistory} from 'react-router-dom'

export default function FailureChartsAndModal({dashboard}) {
	const [countFailuresModal, setCountFailuresModal] = useState({isShow: false})

	const location = useLocation()
	const history = useHistory()
	const mainChartOptions = useMemo(() => ({
		chart: {
			type: 'column',
			backgroundColor: 'rgba(0,0,0,0)',
			animation: false,
			height: 250,
		},
		title: {
			text: 'Title',
			style: {
				fontSize: 15
			}
		},
		exporting: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		xAxis: {
			type: 'category',
			categories: [],
			labels: {
				rotation: -90,
				style: {
					fontSize: '13px',
					fontFamily: 'Verdana, sans-serif'
				}
			},
			title: {
				text: ''
			}
		},
		yAxis: {
			min: 0,
			allowDecimals: false,
			title: {
				text: ''
			}
		},
		legend: {
			enabled: false
		},
		tooltip: {
			pointFormat: 'Суммарное количество отказов: <b>{point.y:.0f}</b>'
		},
		plotOptions: {
			series: {
				cursor: 'pointer'
			},
			column: {
				maxPointWidth: 40
			}
		},
		series: [{
			name: '',
			data: [],
			animation: false,
		}]
	}), [])
	const seriesInitChartOptions = useMemo(() => Highcharts.merge(mainChartOptions, {
		chart: {
			height: 250,
			zoomType: 'x'
		},
		title: {
			text: 'Серии ТПС'
		},
		plotOptions: {
			column: {
				point: {
					events: {
						click: (e) => {
							setCountFailuresModal({isShow: true, details: e.point.details, group: 'series', category: e.point.category})
						}
					}
				}
			}
		},
	}), [mainChartOptions])
	const devicesInitChartOptions = useMemo(() => Highcharts.merge(mainChartOptions, {
		chart: {
			height: 250,
			zoomType: 'x',
		},
		title: {
			text: 'Группы оборудования'
		},
		xAxis: {
			labels: {
				rotation: -45,
			},
		},
		plotOptions: {
			column: {
				point: {
					events: {
						click: (e) => {
							setCountFailuresModal({isShow: true, details: e.point.details, group: 'devices', category: e.point.category})
						}
					}
				}
			}
		},
	}), [mainChartOptions])
	const categoriesInitChartOptions = useMemo(() => Highcharts.merge(mainChartOptions, {
		chart: {
			height: 250,
		},
		title: {
			text: 'Категории'
		},
		xAxis: {
			labels: {
				rotation: 0,
			}
		},
		plotOptions: {
			column: {
				point: {
					events: {
						click: (e) => {
							setCountFailuresModal({isShow: true, details: e.point.details, group: 'categories', category: e.point.category})
						}
					}
				}
			}
		},
	}), [mainChartOptions])

	const [seriesChartOptions, setSeriesChartOptions] = useState(seriesInitChartOptions)
	const [categoriesChartOptions, setCategoriesChartOptions] = useState(categoriesInitChartOptions)
	const [devicesChartOptions, setDevicesChartOptions] = useState(devicesInitChartOptions)
	const [detailsByCountModal, setDetailsByCountModal] = useState({isShow: false, details: []})

	useEffect(function updateChart() {
		if (dashboard === undefined) {
			return
		}

		const getUpdatedOptions = (initOptions, data, categories) => {
			return Highcharts.merge(initOptions, {
				series: [{
					data,
				}],
				xAxis: {
					categories 
				} 
			})
		}

		let series = getDataAndCategoriesFromDashboard(dashboard.seriesDashboard,
			'serGroupName', 'cnt', 'bySer', 'cnt', false)
		let categories = getDataAndCategoriesFromDashboard(dashboard.categoriesDashboard,
			'catName', 'cnt', 'detail', 'catName', true)
		let devices = getDataAndCategoriesFromDashboard(dashboard.devicesDashboard,
			'objectName', 'cnt', 'detail', 'cnt', false)

		let seriesOptions = getUpdatedOptions(seriesInitChartOptions, series.data, series.categories)
		let categoriesOptions = getUpdatedOptions(categoriesInitChartOptions, categories.data, categories.categories)
		let devicesOptions = getUpdatedOptions(devicesInitChartOptions, devices.data, devices.categories)

		setSeriesChartOptions(seriesOptions)
		setCategoriesChartOptions(categoriesOptions)
		setDevicesChartOptions(devicesOptions)
	}, [dashboard, seriesInitChartOptions, categoriesInitChartOptions, devicesInitChartOptions])

	const handleClickOnFailureCount = (details, title) => {
		setDetailsByCountModal({
			isShow: true,
			details,
			title
		})
	}

	let countFailuresTable
	if (countFailuresModal.isShow) {
		if (countFailuresModal.group === 'series') {
			countFailuresTable = (
				<FailureTable
					title='Укрупненная группа серий: '
					firstCol='Серия'
					failures={countFailuresModal.details}
					category={countFailuresModal.category}
					fieldName='serName'
					cellOnClick={(details) => handleClickOnFailureCount(details, `Серия ${details[0].serName}`)}
				/>
			)
		} else if (countFailuresModal.group === 'categories') {
			countFailuresTable = (
				<FailureTable
					title='Категория: '
					firstCol='Дорога'
					failures={countFailuresModal.details}
					category={countFailuresModal.category}
					fieldName='name'
					cellOnClick={(details) => handleClickOnFailureCount(details, `Категория: ${countFailuresModal.category}, ${details[0].dorName}`)}
				/>
			)
		} else if (countFailuresModal.group === 'devices') {
			countFailuresTable = (
				<FailureTable
					title='Группа оборудования: '
					firstCol='Дорога'
					failures={countFailuresModal.details}
					category={countFailuresModal.category}
					fieldName='name'
					cellOnClick={(details) => handleClickOnFailureCount(details, `Группа оборудования: ${countFailuresModal.category}, ${details[0].dorName}`)}
				/>
			)
		} else {
			countFailuresTable = null
		}
	}

	let detailsTable
	if (detailsByCountModal.isShow) {
		let details = detailsByCountModal.details
		let title = detailsByCountModal.title
		detailsTable = (
			<DetailsTable
				details={details}
				title={title}
				onRowClick={(detail) => {
					const query = new URLSearchParams()
					query.set("ser", detail.kodSer)
					query.set("zns", detail.znsLoc)
					query.set("prs", detail.prsLoc)
					history.replace(`${location.pathname}?${query}`)
				}}
			/>
		)
	}

	const handleCountFailuresClose = () => {
		//setDetailsByCountModal({isShow: false})
		setCountFailuresModal({isShow: false})
	}

	const handleDetailsClose = () => {
		setDetailsByCountModal({isShow: false})
	}

	return (
		<>
			<Modal
				open={countFailuresModal.isShow}
				className='pr-failure-charts-modal'
				size='mini'
				onClose={handleCountFailuresClose}
				basic
			>
				<Modal.Content>
					<Modal
						open={detailsByCountModal.isShow}
						className='pr-failure-charts-modal'
						size='large'
						onClose={handleDetailsClose}
						basic
					>
						<Modal.Content>
							{detailsTable}
						</Modal.Content>
					</Modal>
					{countFailuresTable}
				</Modal.Content>
			</Modal>

			<Grid className='pr-failure-charts-grid'>
				<Grid.Row>
					<Grid.Column width={16}>
						<HighchartsReact
							highcharts={Highcharts}
							options={seriesChartOptions}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={5}>
						<HighchartsReact
							highcharts={Highcharts}
							options={categoriesChartOptions}
						/>
					</Grid.Column>
					<Grid.Column width={11}>
						<HighchartsReact
							highcharts={Highcharts}
							options={devicesChartOptions}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	)
}

const getDataAndCategoriesFromDashboard = (dashboard, categoryName, valueName, detaisName, sortBy, isUp) => {
	if (!dashboard) {
		return {
			data: [],
			categories: []
		}
	}

	let sortedList = [...dashboard]
	let sortByField = valueName === sortBy ? valueName : (categoryName === sortBy ? categoryName : undefined)
	if (sortByField !== undefined) {
		sortedList.sort((a,b) => {
			return (a[sortByField] > b[sortByField] ? 1 : -1) * (isUp ? 1 : -1)
		})
	}

	let data = [], categories = []
	sortedList.forEach((el) => {
		data.push({y: el[valueName], details: el[detaisName]})
		categories.push(el[categoryName])
	})
	return {data, categories}
}