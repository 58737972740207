import React from 'react'
import {Input} from "semantic-ui-react";

const rgxDigits = /^[0-9\b]+$/;

export default function InputDigits({onChange, ...props}) {
    return (
        <Input
            {...props}
            onChange={(e, {value}) => {
                if (value === '' || rgxDigits.test(value)) {
                    onChange(e, {value})
                }
            }}
        />

    )
}