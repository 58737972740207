import React from 'react';
import TopHeader from "../../../notUsed/PlanWorkMobX/components/TopHeader";
import Content from "../../../notUsed/PlanWorkMobX/components/Content";
import ViewLocRemarks from "../components/ViewLocRemarks";
import AlignButton from "../components/AlignButton";
import {observer} from "mobx-react-lite";
import {useStore} from "../StoreProvider";
import {getValuesByIndexes} from "../../../../helpers/utils";
import {useHistory} from 'react-router-dom'
import catchNetworkErrors from "../../../../api/catchNetworkErrors";
import {usePrompt} from "../../../../globalStore/useGlobalStore";

const PreviewLocRemarksForm = observer(() => {
    const store = useStore()
    const history = useHistory()
    const prompt = usePrompt()

    return (<>
        <TopHeader header="Предпросмотр замечаний перед сохранением"/>
        <Content loading={store.saveRemarksLoading}>
            <ViewLocRemarks
                selected={Object.values(store.selected)}
                remarks={store.remarks}
            />

            <AlignButton
                primary style={{marginRight: 0, marginTop: 30}} align="right"
                content="Сохранить"
                onClick={() => {
                    store.fetchSaveRemarks(getRemarksPayload(
                        store.selected, store.remarks, store.actionId, store.actionRd, store.asutrId
                    )).then(() => {
                        prompt.show("Замечания добавлены", "", {ok: true, onClose: () => history.push("/driver-instructor-plan")})
                    }).catch(catchNetworkErrors)
                }}
            />
        </Content>
    </>)
})

const getRemarksPayload = (selected, remarks, actionId, actionRd, tchmiAsutrId) => {
    const payload = []
    for (let id in selected) {
        const sel = selected[id]
        payload.push({
            actionId,
            actionRD: actionRd,
            tchmiAsutrId,
            serLoc: sel.series.id,
            znsLoc: sel.number,
            prsLoc: sel.section.id,
            comment: sel.comment,
            notes: getValuesByIndexes(remarks, sel.remarks).map(item => item.id)
        })
    }
    return payload
}


export default PreviewLocRemarksForm;