import React, {useEffect, useState} from 'react';
import {Avatar, List} from "antd";
import style from "./ControlBar.module.scss";
import moment from "moment";
import demoAvatar from "../../../../../assets/images/DemoAvatar.png";
import {Assignees} from "./BarParts/Assignees";
import {PlanDate} from "./BarParts/PlanDate";
import {Labels} from "./BarParts/Labels";
import {Priority} from "./BarParts/Priority";
import {UpdatedOn} from "./BarParts/UpdatedOn";

export const ControlBar = ({taskData, setTaskData}) => {

    const barLabels = ['assignees', 'labels', 'priorityId', 'updatedOn']
    const barDataLabels = ['Исполнители', 'Тэги', 'Приоритет', 'Последнее изменение']

    const [barData, setBarData] = useState([])

    const updateBarData = (data) => {
        let dataArr = barLabels.map((el, i) => ({
            title: barDataLabels[i],
            desc: data[el] ? data[el] : 'Нет',
        }))
        dataArr.splice(2, 0, {
            title: 'Плановые даты',
            planEndDate: data['planEndDate'] ? data['planEndDate'] : 'Нет',
            planStartDate: data['planStartDate'] ? data['planStartDate'] : 'Нет',
        })
        setBarData(dataArr)
    }

    useEffect(() => {
        updateBarData(taskData)
    }, [taskData])

    return (
        <>{barData.length ?
            <List
                itemLayout="horizontal"
                dataSource={barData}
                renderItem={item => (
                    <List.Item>
                        {item.title === 'Исполнители' ?
                            <Assignees item={item} taskData={taskData} setTaskData={setTaskData}/> : null}
                        {item.title === 'Плановые даты' ?
                            <PlanDate item={item} taskData={taskData} setTaskData={setTaskData}/> : null}
                        {item.title === 'Тэги' ?
                            <Labels item={item} taskData={taskData} setTaskData={setTaskData}/> : null}
                        {item.title === 'Приоритет' ?
                            <Priority item={item} taskData={taskData} setTaskData={setTaskData}/> : null}
                        {item.title === 'Последнее изменение' ?
                            <UpdatedOn item={item} taskData={taskData}/> : null}
                    </List.Item>
                )}
            /> : null}
        < />
    )
}