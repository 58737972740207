import React from 'react'
import {observer} from "mobx-react-lite";
import {Button} from "semantic-ui-react";
import {useStore} from '../StoreProvider';
import JustifyContainer from "../../../../shared/JustifyContainer";
import auth from "../../../../helpers/auth";
import {usePrompt} from "../../../../globalStore/useGlobalStore";

const ApproveRejectButtons = observer(() => {
    const store = useStore()
    const prompt = usePrompt()

    const refreshTable = () => {
        store.fetchPlanAndLocomotives(store.tche.id, store.startDate)
    }

    const handleApprove = () => {
        const approve = () => store.fetchApprovePlan(store.plan.id).then(() => refreshTable()).catch(() => {})

        if (auth.getIsTche()) {
            prompt.show(
                "Подтверждение",
                "Вы действительно хотите согласовать график? Это действие нельзя будет отменить",
                {yesNo: true, onApply: approve}
            )
        } else {
            approve()
        }
    }

    const handleReject = () => {
        store.fetchRejectPlan(store.plan.id)
            .then(() => refreshTable()).catch(() => {})
    }

    return (
        <>
            {store.plan.showApproveButton
                ? (<JustifyContainer gapRight="norm">
                    <Button
                        primary
                        disabled={store.approvePlanLoading || store.rejectPlanLoading || store.planAndLocomotivesLoading}
                        loading={store.approvePlanLoading}
                        content="Согласовать"
                        onClick={handleApprove}
                    />
                    {store.isUserTche
                        ? (<Button
                            disabled={store.approvePlanLoading || store.rejectPlanLoading || store.planAndLocomotivesLoading}
                            loading={store.rejectPlanLoading}
                            color='yellow'
                            content="Отклонить"
                            onClick={handleReject}
                        />)
                        : null
                    }
                </JustifyContainer>)
                : null}
        </>
    )
})
export default ApproveRejectButtons