import React, { useState } from "react";
import { Segment, Table } from "semantic-ui-react";
import moment from "moment";
import appStyles from "../../css/App.module.scss";
import cn from "classnames";
import downloadFile from "../../views/GroupRiskLocomotives/downloadFile";
import ekasut from "../../api/ekasut";
import useFileDownload from "../../hooks/useFileDownload";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import styles from "./etso.module.css";

/** @deprecated just use semantic Table component */
export default function AppTable({
  structure,
  data,
  rowOnClick,
  clickable,
  rowClassNameGetter,
  option,
}) {
  const [download, InputDownload] = useFileDownload();
  const [loader, setLoader] = useState(false);
  const [isVisible, setIsVisible] = useState({
    activeInTenDays: false,
    doesntActive: false,
  });

  const ACTIVE = "activeInTenDays";
  const NONACTIVE = "doesntActive";

  const toggleVisibility = (value) => {
    // setIsVisible((prev) => !prev);
    setIsVisible((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const saveFile = async (fileId, name) => {
    setLoader(true);
    const res = await ekasut.file.getFile(fileId);
    download(res?.data, name);
    setLoader(false);
  };

  console.log(isVisible);

  return (
    <>
      {option === 1 ? (
        <>
          <Table
            selectable={clickable}
            className={cn(clickable && appStyles.selectableTable)}
          >
            <Table.Header>
              <Table.Row>
                {structure.map((desc, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {desc.header}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!data[0].isActive && (
                <Table.Row>
                  <Table.Cell collapsing={true} active={false}>
                    Нет данных
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
              {data
                .filter((e) => e.isActive)
                .map((item, dataIndex) => (
                  <Table.Row
                    key={`row-${dataIndex}`}
                    onClick={
                      rowOnClick !== undefined
                        ? () => rowOnClick(item, dataIndex)
                        : undefined
                    }
                    className={rowClassNameGetter(item)}
                  >
                    {item.isActive
                      ? structure.map((desc, descIndex) => (
                          <Table.Cell
                            key={`row-${dataIndex}_cell-${descIndex}`}
                          >
                            {getValue(
                              item,
                              desc,
                              loader,
                              saveFile,
                              InputDownload
                            )}
                          </Table.Cell>
                        ))
                      : "Нет данных"}
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Button
            className={styles.showHideBtn}
            onClick={() => toggleVisibility(ACTIVE)}
          >
            Активны в течение 10 дней
          </Button>
          {isVisible.activeInTenDays && (
            <Table
              selectable={clickable}
              className={cn(clickable && appStyles.selectableTable)}
            >
              <Table.Header>
                <Table.Row>
                  {structure.map((desc, index) => {
                    return (
                      <Table.HeaderCell key={index}>
                        {desc.header}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data[0].isActive && (
                  <Table.Row>
                    <Table.Cell collapsing={true} active={false}>
                      Нет данных
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                )}
                {isVisible.activeInTenDays &&
                  data
                    .filter((e) => !e.isActive && e.isEnable)
                    .map((item, dataIndex) => (
                      <Table.Row
                        key={`row-${dataIndex}`}
                        onClick={
                          rowOnClick !== undefined
                            ? () => rowOnClick(item, dataIndex)
                            : undefined
                        }
                        className={rowClassNameGetter(item)}
                      >
                        {structure.map((desc, descIndex) => (
                          <Table.Cell
                            key={`row-${dataIndex}_cell-${descIndex}`}
                          >
                            {getValue(
                              item,
                              desc,
                              loader,
                              saveFile,
                              InputDownload
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
          )}
          <br />
          <br />
          <Button
            className={styles.showHideBtn}
            onClick={() => toggleVisibility(NONACTIVE)}
          >
            Неактивны в течение 10 дней
          </Button>
          {isVisible.doesntActive && (
            <Table
              selectable={clickable}
              className={cn(clickable && appStyles.selectableTable)}
            >
              <Table.Header>
                <Table.Row>
                  {structure.map((desc, index) => {
                    return (
                      <Table.HeaderCell key={index}>
                        {desc.header}
                      </Table.HeaderCell>
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data[0].isEnable && (
                  <Table.Row>
                    <Table.Cell collapsing={true} active={false}>
                      Нет данных
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                )}
                {isVisible.doesntActive &&
                  data
                    .filter((e) => !e.isActive && !e.isEnable)
                    .map((item, dataIndex) => (
                      <Table.Row
                        key={`row-${dataIndex}`}
                        onClick={
                          rowOnClick !== undefined
                            ? () => rowOnClick(item, dataIndex)
                            : undefined
                        }
                        className={rowClassNameGetter(item)}
                      >
                        {structure.map((desc, descIndex) => (
                          <Table.Cell
                            key={`row-${dataIndex}_cell-${descIndex}`}
                          >
                            {getValue(
                              item,
                              desc,
                              loader,
                              saveFile,
                              InputDownload
                            )}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
          )}
        </>
      ) : (
        <>
          <Table
            selectable={clickable}
            className={cn(clickable && appStyles.selectableTable)}
          >
            <Table.Header>
              <Table.Row>
                {structure.map((desc, index) => {
                  return (
                    <Table.HeaderCell key={index}>
                      {desc.header}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data[0].isEnable && (
                <Table.Row>
                  <Table.Cell collapsing={true} active={false}>
                    Нет данных
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
              {data
                .filter((e) => !e.isActive && !e.isEnable)
                .map((item, dataIndex) => (
                  <Table.Row
                    key={`row-${dataIndex}`}
                    onClick={
                      rowOnClick !== undefined
                        ? () => rowOnClick(item, dataIndex)
                        : undefined
                    }
                    className={rowClassNameGetter(item)}
                  >
                    {structure.map((desc, descIndex) => (
                      <Table.Cell key={`row-${dataIndex}_cell-${descIndex}`}>
                        {getValue(item, desc, loader, saveFile, InputDownload)}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
}
AppTable.defaultProps = {
  rowClassNameGetter: () => "",
};
const getValue = (obj, desc, loader, saveFile, InputDownload) => {
  // console.log(obj);
  if (obj[desc.field] === undefined) {
    console.error(`В объекте ${obj} не найдено поля с именем ${desc.field}`);
    return null;
  }
  if (desc.converter === undefined) {
    return desc.field === "fileId" && obj[desc.field] ? (
      <>
        <InputDownload />
        <Button
          key={obj.fileId}
          disabled={loader}
          className="save-btn"
          style={{ textTransform: "none", marginRight: 10 }}
          color="primary"
          size="small"
          download={obj.fileName}
          onMouseEnter={(e) => console.log(e.currentTarget)}
          onClick={(e) => {
            e.stopPropagation();
            saveFile(obj.fileId, obj.fileName);
          }}
          target={"_blank"}
          startIcon={<SaveIcon />}
        >
          {loader ? "Загрузка ..." : "Прикрепленный файл"} {obj.fileName}
        </Button>
      </>
    ) : (
      // <Button
      //   style={{ marginTop: "20px" }}
      //   onClick={(e) => {
      //     e.stopPropagation();
      //     downloadFile(obj[desc.field], "Док");
      //   }}
      // >
      //   Просмотреть файл
      // </Button>
      obj[desc.field]
    );
  } else {
    return desc.converter(obj[desc.field]);
  }
};
export const convertToDate = (str) => {
  if (typeof str === "string") {
    return moment(str).format("YYYY.MM.DD HH:mm");
  }
  return null;
};
export const convertToYesNo = (val) => {
  if (val === true) {
    return "Да";
  } else if (val === false) {
    return "Нет";
  } else {
    return null;
  }
};
