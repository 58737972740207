import React, { useEffect } from "react";
import { Pagination, Table, Icon, Dimmer, Loader } from "semantic-ui-react";
import locomotivesConservationStore from "../locomotivesConservationStore";
import { observer } from "mobx-react-lite";
import { getOrders } from "../locomotivesConservationUtils";
import styles from "./ConservationOrdersTable.module.scss";

const ConservationOrdersTable = observer(
  ({ selectedRow, setSelectedRow, onReadyStatusClick }) => {
    const ordersTable = locomotivesConservationStore.ordersTable || {};
    const tableData = ordersTable?.data || [];

    return (
      <div className="app purchases-table">
        <Table textAlign="center" unstackable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content="№ Заказа" width={2} />
              <Table.HeaderCell content="Описание заказа" />
              <Table.HeaderCell
                content="Планируемая дата окончания"
                width={2}
              />
              <Table.HeaderCell content="Статус" width={2} />
              <Table.HeaderCell content="Признак готовности" width={2} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map((item, index) => (
              <Table.Row
                key={index}
                onClick={() =>
                  setSelectedRow(selectedRow === index ? null : index)
                }
                active={selectedRow === index}
              >
                <Table.Cell>{item.orderId}</Table.Cell>
                <Table.Cell>{item.comment}</Table.Cell>
                <Table.Cell>{`${item.planEndDate.slice(
                  8,
                  10
                )}.${item.planEndDate.slice(5, 7)}.${item.planEndDate.slice(
                  0,
                  4
                )}`}</Table.Cell>
                <Table.Cell>
                  {item.status === 1
                    ? "Действующий"
                    : item.status === 4
                    ? "Отозван"
                    : "Не определен"}
                </Table.Cell>

                <Table.Cell
                  onClick={(e) => {
                    e.stopPropagation();
                    onReadyStatusClick(index);
                  }}
                  className={styles.readyStatusCell}
                >
                  {item.status === 1 ? (
                    <Icon
                      name={"times circle outline"}
                      color={"red"}
                      size={"large"}
                    />
                  ) : (
                    <Icon
                      name={"check circle outline"}
                      color={"green"}
                      size={"large"}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="pagination-container">
          <Pagination
            activePage={ordersTable.pageNumber || 0}
            totalPages={ordersTable.totalPages || 0}
            onPageChange={(e, { activePage }) => {
              getOrders(activePage);
            }}
          />
        </div>
      </div>
    );
  }
);

export default ConservationOrdersTable;
