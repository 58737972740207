import React from 'react'
import {Dropdown} from "semantic-ui-react";
import styles from './FilterStatus.module.scss'

export default function FilterStatus({onChanged, filter, onApply}) {
    return (
        <div className={styles.container}>
            <Dropdown
                className={styles.dropdown}
                multiple selection search
                options={statusOptions}
                value={filter}
                onChange={(e, {value}) => {
                    onChanged(value)
                    onApply(value)
                }}
            />
        </div>
    )
}
const statuses = [
    'Отключен',
    'Жесткий',
    'Сигнальный',
    'Различный'
]
const statusOptions = statuses.map((val, index) => ({text: val, value: val, key: index}))
