
const colors = {
  "1": "green",
  "4": "blue",
  "3": "orange",
  "2": "red",
  "5": "grey"
}

const getAuditColor = (typeId) => colors[typeId]

export default colors
export {getAuditColor}