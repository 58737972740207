import React, { useEffect, useState } from "react";
import { Dropdown, Input, Select, Label, Button } from "semantic-ui-react";
import ekasut from "../../../api/ekasut";
import styles from "./ConservationOrdersCandidates.module.scss";
import { Alert, DatePicker, Space } from "antd";
import { style } from "@material-ui/system";

const ConservationOrdersCandidates = ({ initialEditingData }) => {
  const orderItems = initialEditingData.preds[0];

  const [locomotives, setLocomotives] = useState([]);
  const [selectedLocSer, setSelectedLocSer] = useState(null);
  const [selectedLocNum, setSelectedLocNum] = useState(null);
  const [locProps, setLocProps] = useState(
    Array.from(Array(orderItems.locsCount)).map((e, i) => {
      return {
        locSeriesId: null,
        locNumber: null,
        locSectionId: null,
        locPlanRepDate: null,
      };
    })
  );

  const [locNums, setLocNums] = useState(
    Array.from(Array(orderItems.locsCount)).map((e, i) => {
      return null;
    })
  );
  const [locNum, setLocNum] = useState(null);
  const [locSec, setLocSec] = useState(null);
  const [locSecs, setLocSecs] = useState([]);
  const [locDate, setLocDate] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [orderPredLocs, setOrderPredLocs] = useState(null);

  const locOptionsLocSer = locomotives.map((e) => {
    return {
      key: e.serLoc,
      text: e.serLoc,
      value: e.serLoc,
    };
  });

  const getOrdersLoc = async () => {
    try {
      const locomotives = await ekasut.locomotivesConservation.getOrderLocs(
        initialEditingData.orderId
      );

      setOrderPredLocs(
        locomotives?.data?.find(
          (obj) => obj.predId === initialEditingData.preds[0].predId
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getLocomotives = async () => {
    try {
      const locomotives = await ekasut.locomotivesConservation.getLocomotives({
        orderId: initialEditingData.orderId,
      });
      setLocomotives(locomotives.data[0]?.availableLocomotives);
    } catch (error) {
      console.error(error);
    }
  };

  const postLocData = async () => {
    const checkedLocProps = locProps.filter((e) => {
      if (e.locSeriesId) return e;
    });

    try {
      await ekasut.locomotivesConservation.postLocData(
        orderItems.orderItemId,
        checkedLocProps
      );
      setShowSuccessAlert(true);
    } catch (error) {
      setShowErrorAlert(true);
      console.error(error);
    }
  };

  const handleLocSerClick = (i, data) => {
    const locPropsCopy = locProps.slice();
    locPropsCopy[i] = {
      locSeriesId: data,
      locNumber: locProps[i].locNumber,
      locSectionId: locProps[i].locSectionId,
      locPlanRepDate: locProps[i].locPlanRepDate,
    };
    setLocProps(locPropsCopy);
  };

  const handleLocNumClick = (i, data) => {
    const locPropsCopy = locProps.slice();
    locPropsCopy[i] = {
      locSeriesId: locProps[i].locSeriesId,
      locNumber: data,
      locSectionId: locProps[i].locSectionId,
      locPlanRepDate: locProps[i].locPlanRepDate,
    };
    setLocProps(locPropsCopy);
  };

  const handleLocSecClick = (i, data) => {
    const locPropsCopy = locProps.slice();
    locPropsCopy[i] = {
      locSeriesId: locProps[i].locSeriesId,
      locNumber: locProps[i].locNumber,
      locSectionId: data,
      locPlanRepDate: locProps[i].locPlanRepDate,
    };
    setLocProps(locPropsCopy);
  };

  const handleDateClick = (i, data) => {
    const locPropsCopy = locProps.slice();
    locPropsCopy[i] = {
      locSeriesId: locProps[i].locSeriesId,
      locNumber: locProps[i].locNumber,
      locSectionId: locProps[i].locSectionId,
      locPlanRepDate: data,
    };
    setLocProps(locPropsCopy);
  };

  useEffect(() => {
    getOrdersLoc();
    getLocomotives();
  }, []);

  useEffect(() => {
    setLocNum(
      locomotives
        .map((e, i) => {
          if (e.serLoc === selectedLocSer.value) {
            return e.znsLocGroups.map((innerE) => {
              return {
                key: innerE.znsLoc,
                text: innerE.znsLoc,
                value: innerE.znsLoc,
              };
            });
          }
        })
        .filter((item) => item !== undefined && item !== null)
    );
  }, [selectedLocSer]);

  useEffect(() => {
    const locNumsCopy = locNums.slice();
    locNumsCopy[currentRow] = locNum;
    setLocNums(locNumsCopy);
  }, [locNum]);

  useEffect(() => {
    setLocSec(
      locomotives
        .map((e) => {
          if (e.serLoc === selectedLocSer.value) {
            return e.znsLocGroups
              .map((innerE) => {
                if (innerE.znsLoc === selectedLocNum) {
                  return innerE.prsLocArray.map((nestedE) => {
                    return {
                      key: nestedE.prsLoc,
                      text: nestedE.prsLoc,
                      value: nestedE.prsLoc,
                    };
                  });
                }
              })
              .filter((item) => item !== undefined && item !== null);
          }
        })
        .filter((item) => item !== undefined && item !== null)
    );
  }, [selectedLocNum]);

  useEffect(() => {
    const locSecsCopy = locSecs.slice();
    locSecsCopy[currentRow] = locSec;
    setLocSecs(locSecsCopy);
  }, [locSec]);

  // useEffect(() => {
  //   console.log(locProps);
  // }, [locProps])

  useEffect(() => {
    setTimeout(() => {
      setShowSuccessAlert(false);
    }, 2000);
  }, [showSuccessAlert]);

  useEffect(() => {
    setTimeout(() => {
      setShowErrorAlert(false);
    }, 2000);
  }, [showErrorAlert]);

  return (
    <div className={styles.locOrders_container}>
      {orderPredLocs
        ? orderPredLocs.orderItemDtos.map((e) => (
            <ul className={styles.added_loco_in_orders}>
              <li className={styles.added_loco_li}>
                <Label className={styles.locomotives_label} basic>
                  Серия локомотива
                </Label>
                <div style={{ textAlign: "center" }}>{e.locSeriesId}</div>
              </li>
              <li className={styles.added_loco_li}>
                <Label className={styles.locomotives_label} basic>
                  № локомотива
                </Label>
                <div style={{ textAlign: "center" }}>{e.locNumber}</div>
              </li>
              <li className={styles.added_loco_li}>
                <Label className={styles.locomotives_label} basic>
                  Секция локомотива
                </Label>
                <div style={{ textAlign: "center" }}>{e.locSectionId}</div>
              </li>
            </ul>
          ))
        : Array.from(Array(orderItems.locsCount)).map((e, i) => {
            return (
              <ul key={i} className={styles.locomotives_ul}>
                <li className={styles.locomotives_ul.li}>
                  <div>
                    <Label className={styles.locomotives_label} basic>
                      Серия локомотива
                    </Label>
                    <Dropdown
                      placeholder="Выберите"
                      fluid
                      selection
                      onChange={(_, data) => {
                        setSelectedLocSer({ value: data.value });
                        setCurrentRow(i);
                        handleLocSerClick(i, data.value);
                      }}
                      options={locOptionsLocSer}
                    />
                  </div>

                  <div>
                    <Label className={styles.locomotives_label} basic>
                      № локомотива
                    </Label>
                    {locNums[i] && locNums[i].map((e) => e) ? (
                      <Dropdown
                        placeholder="Выберите"
                        fluid
                        selection
                        onChange={(_, data) => {
                          setSelectedLocNum(data.value);
                          handleLocNumClick(i, data.value);
                        }}
                        options={locNums[i].map((e) => e)[0]}
                      />
                    ) : (
                      <Dropdown
                        placeholder="Выберите"
                        fluid
                        selection
                        disabled
                      />
                    )}
                  </div>

                  <div>
                    <Label className={styles.locomotives_label} basic>
                      Секция локомотива
                    </Label>
                    {locSecs[i] && locSecs[i].map((e) => e) ? (
                      <Dropdown
                        placeholder="Выберите"
                        fluid
                        selection
                        onChange={(_, data) => {
                          handleLocSecClick(i, data.value);
                        }}
                        options={locSecs[i].map((e) => e)[0].map((e) => e)[0]}
                      />
                    ) : (
                      <Dropdown
                        placeholder="Выберите"
                        fluid
                        selection
                        disabled
                      />
                    )}
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Label className={styles.locomotives_label} basic>
                      Дата
                    </Label>
                    <DatePicker
                      onChange={(_, dateStrings) => {
                        handleDateClick(i, dateStrings);
                      }}
                    />
                  </div>
                </li>
              </ul>
            );
          })}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        {!orderPredLocs && <Button onClick={postLocData}>Отправить</Button>}
        {showSuccessAlert ? (
          <Alert
            style={{ width: "300px" }}
            message="Успешно сохраненно"
            type="success"
          />
        ) : (
          ""
        )}
        {showErrorAlert ? (
          <Alert
            style={{ width: "300px" }}
            message="Не все поля заполннены"
            type="error"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ConservationOrdersCandidates;
