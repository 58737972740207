import React, {useState, useEffect} from 'react'
import ComponentMarker from '../../../shared/ComponentMarker';
import './ReadyToWorkLayout.scss'
import TimeRangeSelector, {timeRanges, getFromTo} from '../TimeRangeSelector'
import {Segment, Divider, Button, Icon} from 'semantic-ui-react'
import Control from 'react-leaflet-control'
import ekasut from '../../../api/ekasut'
import axios from 'axios'
import BarSetWithPopup from '../../../shared/BarSetWithPopup'
import TractionFilterSelector from '../TractionFilterSelector'
import FleetStats from '../FleetStats'
import KgeChart from './KgeChart'
import CollapsibleWrap from '../../../shared/CollapsibleWrap'
import {useZoomLevel, zoomLevels} from '../ZoomLevel';
import TableForPopup from "../TableForPopup";
import useDidFirstRender from "../../../hooks/useDidFirstRender";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

const formBarProps = (stats, timeRange, name) => {
    let kge = stats.kgeFact
    let roadName = stats.name
    var symbol
    var text
    switch (stats.tg) {
        // Для данного layout'a AC (значение = 1) объединяет переменный и постоянный ток
        case ekasut.tractionTypes.AC: {
            symbol = 'Э'
            text = 'Электровозы'
            break
        }
        case ekasut.tractionTypes.ICE: {
            symbol = 'T'
            text = 'Тепловозы'
            break
        }
        default: {
            console.error('Unknown traction type')
            return {}
        }
    }
    var props = {
        value: kge,
        maxValue: 1,
        popupHeader: roadName,
        leftValue: symbol,
        width: 80,
        popupClassName: 'pr-ready-to-work-progress-popup',
        popupContent: timeRange !== timeRanges.DAY ?
            <TableForPopup
                isLoading={false}
                title={name}
            /> :
            <TableForPopup
                title={name}
                subtitle={text}
                data={[
                    {
                        text: 'НР по вине ОАО «РЖД»',
                        value: stats.vrNeplanRemRzd,
                        unit: 'ч.'
                    },
                    {
                        text: 'НР по вине СК',
                        value: stats.vrGarantRemZav,
                        unit: 'ч.'
                    },
                    {
                        text: 'Админ. издержек всего',
                        value: stats.vrAdmIzd,
                        unit: 'ч.'
                    },
                    {
                        text: 'Админ. издержек по ОЖ.ПЕР.ТО/ТР',
                        value: stats.vrAdmPrem,
                        unit: 'ч.'
                    },
                    {
                        text: 'Админ. издержек по ПЕРЕМ. в рем., предпр.',
                        value: stats.vrAdmPrerem,
                        unit: 'ч.'
                    },
                    {
                        text: 'В работоспособном сост.',
                        value: stats.vrRabSost,
                        unit: 'ч.'
                    },
                    {
                        text: 'В ТО/ТР',
                        value: stats.vrPlanObsl,
                        unit: 'ч.'
                    },
                ]}
            />
    }
    return props
}


export default function ReadyToWorkLayout({zoom, resetMap, stats, onLoad, onLoaded}) {
    const zoomLevel = useZoomLevel(zoom, 5)
    const [timeRange, setTimeRange] = useState(timeRanges.DAY)
    const [points, setPoints] = useState({roads: [], depos: []})
    const [markers, setMarkers] = useState([])
    const [tractionFilter, setTractionFilter] = useState({ice: true, electric: true})
    const [history, setHistory] = useState({zoomLevel: zoomLevels.ROADS, data: []})
    const [selectedPoint, setSelectedPoint] = useState({id: -1, level: zoomLevels.ROADS})
    const [chartTitle, setChartTitle] = useState('По сети')
    const [isHideChart, setIsHideChart] = useState(false)

    useEffect(function updateMarkers() {
        let newMarkers = []
        var arr
        var fieldGroup
        if (zoomLevel === zoomLevels.ROADS) {
            arr = points.roads
            fieldGroup = 'dataByTipElektr'
        } else {
            arr = points.depos
            fieldGroup = 'dataByTipElektr'
        }

        for (let i = 0; i < arr.length; i++) {
            let point = arr[i]
            let props = []
            let filtredByTraction = point[fieldGroup].filter(el => {
                let iceId = tractionFilter.ice ? 0 : -1
                let electricId = tractionFilter.electric ? 1 : -1
                return ((iceId === el.tg) || (electricId === el.tg))
            })
            if (filtredByTraction.length === 0) {
                continue
            }
            let sortedByTip = filtredByTraction
            sortedByTip.sort((a, b) => {
                return a.tg - b.tg
            })
            sortedByTip.forEach((byTip) => {
                props.push(formBarProps(byTip, timeRange, point.name))
            })

            newMarkers.push(
                <ComponentMarker
                    position={[point.lat, point.lon]}
                    key={point.name}
                    relX={-25}
                    relY={-25}
                >
                    <BarSetWithPopup
                        barsProps={props}
                        onClick={() => {
                            if (timeRange !== timeRanges.DAY) {
                                const selPoint = {id: point.kod, level: zoomLevel, name: point.name}
                                setIsHideChart(false)
                                setSelectedPoint(selPoint)
								/*onLoad()*/
                                loadHistory(timeRange, selPoint)
									/*.finally(() => {
										onLoaded()
									})*/
                            }
                        }}
                    />
                </ComponentMarker>
            )
        }
        setMarkers(newMarkers)
    }, [zoomLevel, points, timeRange, tractionFilter])

	useDidFirstRender(() => {
		const {from, to} = getFromTo(timeRange)
		onLoad()
		loadPoints(timeRange, from, to)
			.finally(onLoaded)
	})

    const loadHistory = (tRange, selPoint) => {
        if (tRange === timeRanges.DAY) {
            return Promise.resolve()
        }
        let apiGetHistory
        if (selPoint.id === -1) {
            apiGetHistory = ekasut.to33Analytics.getRzdHistory
        } else {
            if (selPoint.level === zoomLevels.ROADS) {
                apiGetHistory = (isYear) => ekasut.to33Analytics.getRoadHistory(selPoint.id, isYear)
            } else {
                apiGetHistory = (isYear) => ekasut.to33Analytics.getDepoHistory(selPoint.id, isYear)
            }
        }
        // selected zoom level may change before history loaded
        let savedZoomLevel = selPoint.level
        setHistory({data: []})
		setChartTitle("Загрука...")
        return apiGetHistory(tRange === timeRanges.YEAR)
            .then(response => {
                setHistory({data: response.data, zoomLevel: savedZoomLevel})
				setChartTitle(selPoint.name ? selPoint.name : "По сети")
            })
			.catch(catchNetworkErrors)
    }

    const loadPoints = (tRange, from, to) => {
        // Every 15 day of the month kge approved
        // For month and year show approved kge
        // for day - not approved
        let approvedKge = tRange !== timeRanges.DAY
        //setChartsTitle(text.CHARTS_LOADING)
        setPoints({roads: [], depos: []})
        return axios.all([
            ekasut.to33Analytics.getRoads(approvedKge, from, to),
            ekasut.to33Analytics.getDepos(approvedKge, from, to)
        ]).then(results => {
            setPoints({
                roads: results[0].data,
                depos: results[1].data
            })
        }).catch(catchNetworkErrors)
    }

    const handleTimeRangeChange = (value, from, to) => {
        setPoints({roads: [], depos: []})
        setTimeRange(value)
        onLoad()
        Promise.all([
            loadHistory(value, selectedPoint),
            loadPoints(value, from, to)
        ]).finally(() => {
        	onLoaded()
		})
    }

    const returnBackToRzd = () => {
    	const selPoint = {level: zoomLevels.ROADS, id: -1}
        setSelectedPoint(selPoint)
        resetMap()
		/*onLoad()*/
		loadHistory(timeRange, selPoint)
			/*.finally(() => {
				onLoaded()
			})*/
    }

    return (
        <>
            <Control position='topright'>
                <Segment className='half-transparent-background pr-ready-to-work-layout-control'>
                    <TimeRangeSelector
                        onChange={handleTimeRangeChange}
                        timeRange={timeRange}
                    />
                    <Divider/>
                    <FleetStats
                        stats={stats}
                    />

                    {timeRange !== timeRanges.DAY &&
                    <CollapsibleWrap title={chartTitle}
                                     onClick={isCollapse => setIsHideChart(!isCollapse)}
                                     isCollapse={isHideChart}
                    >
                        {selectedPoint.id !== -1 &&
                        <Button icon labelPosition='left' size='mini' basic className='return-back-to-rzd'
                                onClick={returnBackToRzd}>
                            Назад
                            <Icon name='left arrow'/>
                        </Button>
                        }
                        <KgeChart
                            history={history.data}
                            zoomLevel={history.zoomLevel}
                        />

                    </CollapsibleWrap>
                    }
                    {((timeRange === timeRanges.DAY) || !isHideChart) && <Divider/>}
                    <TractionFilterSelector
                        onChange={(filter) => {
                            setTractionFilter(filter)
                        }}
                        filter={tractionFilter}
                        groupElectricLocomotives={true}
                    />
                </Segment>
            </Control>

            {markers}
        </>
    )
}
