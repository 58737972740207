import React, { useCallback, useEffect } from 'react';
import styles from "./AddPurchaseView.module.scss"
import { Checkbox, Header, Modal, Button, Icon, Grid } from "semantic-ui-react";
import TextField from "../../shared/fields/TextField";
import InputField from "../../shared/fields/InputField";
import DropdownField from "../../shared/fields/DropdownField";
import { observer, useLocalStore } from "mobx-react-lite";
import moment from 'moment'
import cn from 'classnames'
import DateField from "../../shared/fields/DateField";
import Widget from "../../shared/Widget";
import ProcessViewButtons from "./ProcessViewButtons";

const AddResultRejectView = observer((
    {
        open, purchase,
        onSaveOrEnd = () => null,
        onClose = () => null
    }
) => {
    const defaultSave = {
        docDate: moment(),
        docNum: '',
    }

    const ls = useLocalStore(() => ({
        save: defaultSave,
    }))

    const closeHandler = () => {
        ls.save = defaultSave
        onClose()
    }
    return (
        <Modal open={open} size="tiny" className={cn(styles.component, "alt-semantic")} onClose={closeHandler}>
            <Widget
                header="Отклонить"
                footer={(
                    <ProcessViewButtons
                        showSave={false}
                        onEnd={() => onSaveOrEnd(ls.save, purchase, true)}
                        onClose={closeHandler}
                        disabledEnd={!ls.save.docDate.isValid() || ls.save.docNum === "" || ls.save.description === '' || !ls.save.description}
                    />
                )}
            >
                <div className="add-properties">
                    <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column>
                                <InputField
                                    label="Комментарий"
                                    inputClassName="input-underline"
                                    onChange={(e, { value }) => {
                                        ls.save.description = value
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <DateField
                                    label="Дата письма" closable
                                    inputClassName="date-underline"
                                    date={ls.save.docDate || ""}
                                    onChange={(date) => {
                                        ls.save.docDate = date
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <InputField
                                    label="Номер письма"
                                    inputClassName="input-underline"
                                    onChange={(e, { value }) => {
                                        ls.save.docNum = value
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Widget>
        </Modal>
    );
})

export default AddResultRejectView;
