

export const purchaseStatuses = {
    /** Пранирование закупки (сохранение) */
    planPurchase: 0,
    /** Редактируется */
    edit: 1,
    /** Запланировано */
    planned: 2,
    /** В работе */
    work: 3,
    /** Определен исполнитель */
    definedExecutor: 4,
    /** Отклонение заявки */
    rejectPurchase: 5,
    /** Возвращено на доработку */
    rework: 6,
    /** Завершено */
    completed: 7,
    /** Предварительное завершение */
    preliminaryCompletion: 8
}

export const purchaseTypes = {
    /** Товар */
    product: 1,
    /** Услуга */
    service: 2,
}