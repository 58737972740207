import React, {useEffect, useState} from 'react';
import style from './Status.module.scss';
import ekasut from "../../../../../api/ekasut";
import {Select} from 'antd';

const {Option, OptGroup} = Select;

export const Status = ({taskData, setTaskData}) => {

    const [loading, setLoading] = useState(false)
    const [editStatus, setEditStatus] = useState(false)
    const [statusList, setStatusList] = useState(null)

    const getStatuses = () => {
        setLoading(true)
        ekasut.taskboard.getStatusInWG(taskData.workGroupId)
            .then(({data}) => {
                setStatusList(data)
                setLoading(false)
            })
    }

    const updateStatus = (status) => {
        setLoading(true)
        ekasut.taskboard.updateTaskStatus(taskData.workGroupId, taskData.taskNumber, status)
            .then(({data}) => {
                setTaskData(data)
                setLoading(false)
                setEditStatus(false)
            })
    }

    return (<>
            {editStatus ?
                <Select autoFocus loading={loading} defaultValue={taskData.status.statusId} style={{width: 200}}
                        onBlur={() => {
                            setEditStatus(false)
                        }}
                        onChange={(value, option) => {
                            updateStatus(value)
                        }}>
                    {statusList ?
                        <OptGroup label="АКТИВНЫE">{statusList.filter((el) => el.categoryId === 1).map((el, i) => (
                            <Option key={el.statusId} value={el.statusId}>{el.statusName}</Option>
                        ))}</OptGroup> : null}
                    {statusList ?
                        <OptGroup label="ВЫПОЛНЕННЫЕ">{statusList.filter((el) => el.categoryId === 2).map((el, i) => (
                            <Option key={el.statusId} value={el.statusId}>{el.statusName}</Option>
                        ))}</OptGroup> : null}
                    {statusList ? <OptGroup
                        label="ЗАКРЫТЫЕ">{statusList.filter((el) => el.categoryId === 3).map((el, i) => (
                        <Option key={el.statusId} value={el.statusId}>{el.statusName}</Option>
                    ))}</OptGroup> : null}
                </Select> :
                <div onClick={() => {
                    if (taskData.workGroupUserRoleId !== 3) {
                        getStatuses()
                        setEditStatus(true)
                    }
                }}
                     style={{backgroundColor: `${taskData.status.statusColorHex}`}}
                     className={taskData.workGroupUserRoleId !== 3 ? style.taskHeaderStatus : style.taskHeaderStatusDisable}>
                    {taskData.status.statusName}
                </div>}
        </>
    )
}