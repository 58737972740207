import React from 'react'
import {StoreProvider} from "./StoreProvider";
import EventsCalendar from "./observers/EventsCalendar";
import {Switch, Route, useRouteMatch} from 'react-router-dom'
import AddPlanToDate from "./observers/AddPlanToDate";

const Index = () => {
    const match = useRouteMatch()
    return (
        <StoreProvider>
            <Switch>
                <Route path={match.path + "/calendar"}>
                    <EventsCalendar />
                </Route>
                <Route path={match.path + "/add_plan"}>
                    <AddPlanToDate />
                </Route>
                {/* Should be last child (route) */}
                <Route>
                    Page not found
                </Route>
            </Switch>
        </StoreProvider>
    )
}

export default Index
