import React from "react";
import createStore from "./createStore";

export const storeContext = React.createContext('OperativePlan')

export const StoreProvider = ({children, props}) => {
    const store = createStore(props)
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}
StoreProvider.defaultProps = {
    initOptions: {}
}

export const useStore = () => (React.useContext(storeContext))
