import React, {useCallback, useState} from 'react'
import useApiMethod from "../../hooks/useApiMethod";
import ekasut from "../../api/ekasut";
import MonitorPowerTable from "./MonitorPowerTable";
import styles from './index.module.scss'
import cn from 'classnames'
import DataFilter from "./DataFilter";
import LoadingContent from "../RatingsWorkLocomotives/NepLayout/LoadingContent";
import RepairPositionsModal from "../../shared/RepairPositionsModal";

export default function UsingPowerSld({hideSidebars}) {
    const [repairPosOpen, setRepairPosOpen] = useState(false)
    const [sendGetTreeData, treeDataLoading, treeData] = useApiMethod(ekasut.monitoring.getErtoDepot, undefined, [])
    const [sendGetRepairPos, repairPosLoading, repairPos] = useApiMethod(ekasut.ekasutData.getDepotMap, undefined, {
        ditches: []
    })
    const [filterIsSelected, setFilterIsSelected] = useState(false)

    const onDepotClickHandler = useCallback((e) => {
        setRepairPosOpen(true)

        sendGetRepairPos(e.id)
    }, [sendGetRepairPos])

    return (
        <div onClick={hideSidebars} className={cn(styles.container)}>
            <div>
                <LoadingContent
                    showLoader={treeDataLoading}
                    inverted={true}
                    message={filterIsSelected ? (treeData.length === 0 ? 'За выбранный период нет данных' : '') : 'Для отображения данных выберите год'}
                >
                    <MonitorPowerTable
                        onDepotClick={onDepotClickHandler}
                        roads={treeData}
                    />
                </LoadingContent>
            </div>
            <div>
                <DataFilter
                    onChange={(from, to) => {
                        sendGetTreeData(from, to)
                        setFilterIsSelected(true)
                    }}
                    onClear={() => {
                        setFilterIsSelected(false)
                    }}
                />
            </div>
            <RepairPositionsModal
                loading={repairPosLoading}
                data={repairPos}
                onClose={() => setRepairPosOpen(false)}
                open={repairPosOpen}
            />
        </div>
    )
}