import React, {useState} from 'react';
import style from './Cards.module.scss'
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import classNames from "classnames";
import ChatDropdownButton from "./CardDropdownButton/ChatDropdownButton";
import TableSecondCard from "./TableSecondCard/TableSecondCard";

import TableSecondCardDay from "./TableSecondCardDay/TableSecondCardDay";

const customData = [274, 337, 81, 497, 516, 219, 269, 274, 337, 81, 497, 219, 269, 274, 337, 81, 497, 366, 219, 269];

export const CardTwo = ({dates, data = [], setIsModalOpened, setModalContent, isDayCard = false}) => {
    const seasonString = dates.season === "2" ? "Осень" : dates.season === "1" ? "Весна" : "";
    const yearString = dates.year || "";
    const isTche = localStorage.accessLevel === "2";
    const isCseTe = localStorage.accessLevel === "7";

    const prevYearString = String(+yearString - 1);
    let config = {
        data: customData,
        height: 80,
        width: 180,
        columnWidthRatio: 0.8,
    };
    const firstItem = (data && data[0]) || {};
    const isRed = firstItem.prevPercNr < firstItem.percNr;

    const onReportClick = () => {
        setIsModalOpened(true);
        if (!isDayCard) {
            setModalContent(<TableSecondCard data={data}/>);
        } else {
            setModalContent(<TableSecondCardDay data={data}/>);
        }
    };
    return (
        <div className={style.card}>
            <ChatDropdownButton onReportClick={onReportClick}/>

            <div className={style.cardHeader}>
                <span>КОЛИЧЕСТВО НЕПЛАНОВЫХ РЕМОНТОВ</span>
                {isDayCard && (
                    <span> ЗА СУТКИ</span>
                )}
            </div>
            {data.length > 0 ? (
                <div className={style.cardHorizontBody} style={isDayCard ? {"justifyContent": "flex-start", "alignItems": "center", height: "calc(100% - 20px)"} : {}}>
                    {isDayCard && !isTche && !isCseTe && (
                        <div className={style.shortInfo}>
                            <div className={style.dayCard}>
                                <p>Кол-во НР по дороге</p>
                                <p style={{color: 'rgb(246, 192, 34)'}}>{firstItem.cntNrRoad}</p>

                            </div>
                        </div>
                    )}
                    {isDayCard && isCseTe && (
                        <div className={style.shortInfo}>
                            <div className={style.dayCard}>
                                <p>Кол-во НР по сети</p>
                                <p style={{color: 'rgb(246, 192, 34)'}}>{firstItem.cntNrRzd}</p>
                            </div>
                        </div>
                    )}
                    {isDayCard && isTche && (
                        <div className={style.shortInfo}>
                            <div className={style.dayCard}>
                                <p>Кол-во НР по депо</p>
                                <p style={{color: 'rgb(246, 192, 34)'}}>{firstItem.cntNrDepo}</p>
                            </div>
                        </div>
                    )}
                    {!isDayCard && (
                        <>
                            <div className={style.shortInfo}>
                                <div className={style.bigCnt}>
                                    {seasonString + " " + prevYearString}
                                </div>
                                <div className={classNames(style.bigCnt, style.failsCount)}>
                                    {firstItem.prevAllNr}
                                    <span>НР</span>
                                </div>
                                <div className={style.target}>
                                    <p style={{color: '#43425D'}}>{firstItem.prevPercNr}%</p>
                                    <p>НР</p>
                                </div>
                                <div className={style.target}>
                                    <p>{firstItem.prevAllSecNr}</p>
                                    <p>Секции с НР</p>
                                </div>
                            </div>
                            <div className={style.shortInfo}>
                                <div className={style.bigCnt}>
                                    {seasonString + " " + yearString}
                                </div>
                                <div className={classNames(style.bigCnt, style.failsCount)}>
                                    {firstItem.allNr}
                                    <span>НР</span>
                                </div>
                                <div className={style.target}>
                                    {isRed ? (
                                        <><ArrowUpOutlined style={{color: '#E84E3E'}}/> <p
                                            style={{color: '#E84E3E'}}>{firstItem.percNr}%</p></>
                                    ) : (
                                        <><ArrowDownOutlined style={{color: '#78C799'}}/> <p
                                            style={{color: '#78C799'}}>{firstItem.percNr}%</p></>
                                    )}
                                    <p>НР</p>
                                </div>
                                <div className={style.target}>
                                    <p>{firstItem.allSecNr}</p>
                                    <p>Секции с НР</p>
                                </div>
                                <div className={style.target}>
                                    <p>{firstItem.allSecKo}</p>
                                    <p>Всего секций</p>
                                </div>
                            </div>
                        </>
                    )}
                    {/*<div className={style.tinyColumn}>*/}
                    {/*<TinyColumn {...config} />*/}
                    {/*</div>*/}
                </div>
            ) : (
                <p>Нет данных</p>
            )}

        </div>
    );
};