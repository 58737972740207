import axios from "axios";
import locomotiveServices from "../views/RatingsWorkLocomotives/LocomotiveServices";
import moment from "moment";
import momentParamsFormatter from "./interceptors/momentFormatter";
import responseBlobErrorToJson from "./interceptors/responseBlobErrorToJson";
import unauthorizedAccess from "./interceptors/unauthorizedAccecss";
import dateFormats from "./dateFormats";
import appendToken from "./interceptors/appendToken";

const CancelToken = axios.CancelToken;

let a = undefined;

const ekasut = {
  addErrorListener: (onError) =>
    a.interceptors.response.use(null, (error) => {
      onError(error);
      return Promise.reject(error);
    }),
  init: ({ baseURL, token }) => {
    a = axios.create({
      baseURL,
      validateStatus(status) {
        return status <= 302; // Reject only if the status code is greater than 300
      },
    });
    a.defaults.withCredentials = false;
    a.interceptors.request.use(appendToken(token), null);
    a.interceptors.request.use(momentParamsFormatter);
    a.interceptors.response.use(null, responseBlobErrorToJson);
    a.interceptors.response.use(null, unauthorizedAccess);
  },
  isInitialized: () => !!a,
  authCheck: {
    checkSession: () => a.get(`/Account/CheckSession`),
  },
  notification: {
    getCheckNew: (params) => a.get(`/api/notifications/checknew`, { params }),
    hasNew: () => a.get(`/api/notifications/has-new`),
    checknewDetail: () => a.get(`/api/notifications/checknew-detail`),
  },
  profile: {
    getUserInfo: (userId) => a.get(`/api/roles/info?userId=${userId}`),
    postPhoto: (data) =>
      a.post(`/api/files`, data, {
        headers: { "content-type": "multipart/form-data" },
      }),
    setPhotoIdToUser: (id) => a.post(`/api/roles/photo?id=${id}`),
  },
  chats: {
    getChatsList: () => a.get("/api/chats"),
    createChat: ({ usersId, chatName }) =>
      a.post("/api/chats", { usersId, chatName }),
    deleteChat: (chatId) => a.delete(`/api/chats/${chatId}`),
    searchUser: (val) => a.get(`/api/chats/users?searchLine=${val}`),
    getChatMessage: ({ chatId, pageNumber, messageId }) =>
      a.get(
        `/api/chats/messages?chatId=${chatId}&pageNumber=${pageNumber}&pageSize=20` +
          (messageId ? `&messageId=${messageId}` : "")
      ),
    getChatNotificationList: () => a.get(`/api/notifications/list`),
    getAllNotification: () => a.get(`/api/notifications`),
  },
  news: {
    getCategory: () => a.get("/api/ekasut/news/categories"),
    getNews: ({ pageNumber = 1, categoryId = false } = {}) =>
      a.get(
        `/api/ekasut/news?pageNumber=${pageNumber}&${
          categoryId ? "categoryId=" + categoryId + "&" : ""
        }`
      ),
    getCurrentNews: (newsId) => a.get(`/api/ekasut/news/${newsId}`),
    createNews: (payload) => a.post("/api/ekasut/news", { ...payload }),
    changeNews: (newsId, payload) =>
      a.put(`/api/ekasut/news/${newsId}`, { ...payload }),
    deleteNews: (id) => a.delete(`/api/ekasut/news/${id}`),
    getComments: (newsId) => a.get(`/api/ekasut/news/${newsId}/comments`),
  },
  audit: {
    getAuditList: (params) =>
      a.get(`/api/audit`, { params, momentFormatter: dateFormats.upToDay }),
    postCreateAudit: ({ auditInfo }) =>
      a.post(`/api/audit/create`, {
        ...auditInfo,
        auditDate: auditInfo.auditDate.format(),
      }),
    deleteAudit: ({ auditId }) => a.delete(`/api/audit/${auditId}/remove`),
    postApproveAudit: ({ auditId, isApproved }) =>
      a.post(`/api/audit/${auditId}/approved/${isApproved}`),
    getNsiPreds: () => a.get(`/api/audit/nsi/preds`),
    getNsiTypes: () => a.get(`/api/audit/nsi/types`),
    getNsiRoles: () => a.get(`/api/audit/nsi/roles`),
    getAuditInfo: ({ auditId }) => a.get(`/api/audit/${auditId}/info`),
    postAuditOrder: ({ auditId, data }) =>
      a.post(`/api/audit/${auditId}/order`, data),
    postEmployee: ({ auditId, data }) =>
      a.post(`/api/audit/${auditId}/employee`, data),
    deleteEmployee: ({ employeeId }) =>
      a.delete(`/api/audit/employee/${employeeId}`),
    postStep: ({ auditId, stepNum }) =>
      a.post(`/api/audit/${auditId}/step/${stepNum}`),
    getStep: ({ auditId }) => a.get(`/api/audit/${auditId}/step`),
    postPlanEvent: ({ auditId, data }) =>
      a.post(`/api/audit/${auditId}/plan/events`, data),
    deletePlanEvent: ({ eventId }) =>
      a.delete(`/api/audit/plans/events/${eventId}`),
    getPlanEvents: ({ auditId }) => a.get(`/api/audit/${auditId}/plan/events`),
    getQuestions: ({ auditId, forAdvices = false }) =>
      a.get(`/api/audit/${auditId}/questions`, { params: { forAdvices } }),
    postQuestions: ({ auditId, data }) =>
      a.post(`/api/audit/${auditId}/questions`, data),
    deleteQuestions: ({ auditId, questionIds }) =>
      a.delete(`/api/audit/${auditId}/questions`, {
        params: { questionIds: questionIds.join(",") },
      }),
    getNsiGrades: () => a.get(`/api/audit/nsi/grades`),
    getNsiReasons: () => a.get(`/api/audit/nsi/reasons`),
    getAdvices: ({ auditId }) => a.get(`/api/audit/${auditId}/advices`),
    postAdvice: ({ auditId, adviceName, questions }) =>
      a.post(`/api/audit/${auditId}/advices`, {
        adviceName,
        questions,
      }),
    getQuestionsForAdvice: ({ adviceId }) =>
      a.get(`/advices/${adviceId}/questions`),
    getCorrectiveEvents: ({ auditId }) =>
      a.get(`/api/audit/${auditId}/corrective/events`),
    postCorrectiveEvents: ({ auditId, data }) =>
      a.post(`/api/audit/${auditId}/corrective/events`, data),
    postEventResult: ({ eventId, fileId }) =>
      a.post(`/corrective/events/${eventId}/result`, undefined, {
        params: { fileId },
      }),
    getProcesses: () => a.get(`/api/audit/nsi/processes`),
    postCriterion: ({ processId, auditId, data }) =>
      a.post(`/process/${processId}/criterion`, data, { params: { auditId } }),
    deleteCriterion: ({ criterionId, auditId }) =>
      a.delete(`/api/audit/criterion/${criterionId}`, { params: { auditId } }),
  },
  commisionInspection: {
    getOrderPeople: (pRGOrder) =>
      a.get(`/api/KO/get-order-people?pRGOrder=${pRGOrder}`),
    postPeopleOrder: (pAccess, pRGOrder, pFIO, pKodProf, pRole, pItem, pLink) =>
      a.post(
        `/api/KO/iu-people-order?pAccess=${pAccess}&pRGOrder=${pRGOrder}&pFIO=${pFIO}&pKodProf=${pKodProf}&pRole=${pRole}&pItem=${pItem}&pLink=${pLink}`
      ),
    getOrderFirm: (firmExp, yearCO, typeCO, isRepeat) =>
      a.get(
        `/api/KO/get-order-firm?FirmExp=${firmExp}&YearCO=${yearCO}&TypeCO=${typeCO}&isRepeat=${isRepeat}`
      ),
    getPeriodOrder: (pYearCO, pTypeCO) =>
      a.get(`/api/KO/get-period-order?pYearCO=${pYearCO}&pTypeCO=${pTypeCO}`),
    postCOOrder: (
      NumOrder,
      DateOrder,
      FirmExp,
      FirmRep,
      IsAct,
      ECFName,
      isRepeat,
      YearCO,
      TypeCO,
      IsRewrite
    ) =>
      a.post(
        `/api/KO/ins-upd-order?NumOrder=${NumOrder}&DateOrder=${DateOrder}&FirmExp=${FirmExp}&FirmRep=${FirmRep}&IsAct=${IsAct}&ECFName=${ECFName}&isRepeat=${isRepeat}&YearCO=${YearCO}&TypeCO=${TypeCO}&IsRewrite=${IsRewrite}`
      ),
    getListDepo: (pacclevel) =>
      a.get(`/api/KO/get-list-depo?pacclevel=${pacclevel}`),
    getItem: () => a.get(`/api/ko/get-co-items`),
    getDocs: ({ pTypeCo, pYear, pFirmExpl }) =>
      a.get(
        `/api/KO/get-co-ord-firm?pTypeCo=${pTypeCo}&pYear=${pYear}&pFirmExpl=${pFirmExpl}`
      ),
    getPeople: () => a.get(`/api/KO/get-co-people`),
    getRoleks: () => a.get(`/api/KO/get-co-roleks`),
    getProfs: () => a.get(`/api/KO/get-co-profs`),
    getDeposForFilter: () => a.get(`/api/KO/get-co-depots`),
    getDeposForAddCom: (pKodFirm) =>
      a.get(`/api/KO/get-co-smegnic?pKodFirm=${pKodFirm}`),
    addPlan: (params) => a.post(`/api/KO/iu-co-period`, null, { params }),
    getPlan: (pTypeCo, pYear) =>
      a.get(`/api/KO/get-co-period?pTypeCO=${pTypeCo}&pYearCO=${pYear}`),
    getSectionsPlan: ({ pKodFirm }) =>
      a.get(`/api/KO/get-co-calend?pKodFirm=${pKodFirm}`),
    addAdditionalWork: (params) => a.get(`/api/KO/iu-co-axwork`, params),
    getAdditionalWork: ({ pTypeCO, pYearCO, pKodFirm }) =>
      a.get(
        `/api/KO/get-list-axwork?pTypeCO=${pTypeCO}&pYearCO=${pYearCO}&pKodFirm=${pKodFirm}`
      ),
    getSerForAxwork: (pAccess) =>
      a.get(`/api/KO/get-ser-for-axwork?pAccess=${pAccess}`),
    getCTAxwork: (pYearCO, pTypeCO) =>
      a.get(`/api/KO/get-ct-axwork-firm?pYearCO=${pYearCO}&pTypeCO=${pTypeCO}`),

    postAxrok: (
      pNameAxwork,
      pYearCO,
      pTypeCo,
      pLevFirm,
      pKodFirm,
      pKodSer,
      pIsActual
    ) =>
      a.post(
        `/api/KO/ins-upd-axwork?pNameAxwork=${pNameAxwork}&pYearCO=${pYearCO}&pTypeCo=${pTypeCo}&pLevFirm=${pLevFirm}&pKodFirm=${pKodFirm}&pKodSer=${pKodSer}&pIsActual=${pIsActual}`
      ),
    delPeopleOrder: (params) => a.delete(`/api/KO/del-ppl-order`, { params }),
    getOrderSection: (pKodFirm, pYear, pType, pIsRepeat) =>
      a.get(
        `/api/KO/get-order-section?pKodFirm=${pKodFirm}&pYear=${pYear}&pType=${pType}&pIsRepeat=${pIsRepeat}`
      ),
    getReasonExcl: () => a.get(`/api/KO/get-reason-excl`),
    postObjectReason: (pRGOrder, pRGSec, pIdObject, pIdReason) =>
      a.post(
        `/api/KO/set-object-reason?pRGOrder=${pRGOrder}&pRGSec=${pRGSec}&pIdObject=${pIdObject}&pIdReason=${pIdReason}`
      ),
    getSldPark: (pFirmSLD) =>
      a.get(`/api/KO/get-sld-park?pFirmSLD=${pFirmSLD}`),
    getSecProtInfo: (pIdObject, pYearCo, pTypeCo, plsRepeat) =>
      a.get(
        `/api/KO/get-sec-prot-info?pIdObject=${pIdObject}&pYearCo=${pYearCo}&pTypeCo=${pTypeCo}&plsRepeat=${plsRepeat}`
      ),
    getOcenka: () => a.get(`/api/KO/get-ocenka`),
    getSecProtAxwork: (pKodFirm, pIdObject, pYearCO, pTypeCO, pIsRepeat) =>
      a.get(
        `/api/KO/get-sec-prot-axwork?pKodFirm=${pKodFirm}&pIdObject=${pIdObject}&pYearCO=${pYearCO}&pTypeCO=${pTypeCO}&pIsRepeat=${pIsRepeat}`
      ),
    postProtocol: (params) =>
      a.post(`/api/KO/iu-protocol`, null, { params: params }),
    postMark: (params) =>
      a.post(`/api/KO/iu-prot-ocenka`, null, { params: params }),
    postAddWork: (params) =>
      a.post(`/api/KO/iu-prot-axwork`, null, { params: params }),
    getListAxwork: (params) =>
      a.get(`/api/KO/get-list-axwork`, null, { params: params }),
    getCalendPlan: (params) =>
      a.get(`/api/KO/get-calend-plan`, { params: params }),
    setUncheckOrder: (params) =>
      a.post(`/api/KOReports/set-uncheck-order`, null, { params }),
    setUncheckProt: (params) =>
      a.post(`/api/KOReports/set-uncheck-prot`, null, { params }),
    getRepairs: (params) => a.get(`/api/KO/get-repairs-plan`, { params }),
    updSecInfo: (params) => a.post(`/api/KO/upd-sec-info`, null, { params }),
  },
  KOReports: {
    getReportAtFails: (params) =>
      a.get(`/api/KOReports/report-at-fails`, { params }),
    getReportAtNeprep: (params) =>
      a.get(`/api/KOReports/report-at-neprep`, { params }),
    getReportLokoAxwKo: (params) =>
      a.get(`/api/KOReports/report-loko-axw-ko`, { params }),
    getReportAtFailsDay: (params) =>
      a.get(`/api/KOReports/report-at-fails-day`, { params }),
    getReportAtNrDay: (params) =>
      a.get(`/api/KOReports/report-at-nr-day`, { params }),
    getReportRoadStays: (params) =>
      a.get(`/api/KOReports/report-road-stays`, { params }),
    getReportNetStays: (params) =>
      a.get(`/api/KOReports/report-net-stays`, { params }),
  },
  locomotivesConservation: {
    getDepartments: () => a.get(`/api/nsi/pred/tree/tche/access`),
    getTractionTypes: () => a.get(`/api/nsi/locomotives/traction-types`),
    getVdvigTypes: () => a.get(`/api/nsi/locomotives/vdvig-types`),
    getOrdersTable: (pageNumber) =>
      a.get(
        `/api/conservation/orders${
          typeof pageNumber === "number" ? "?pageNumber=" + pageNumber : ""
        }`
      ),
    postOrder: (body) => a.post(`/api/conservation/orders`, body),
    editOrder: (orderId, body) =>
      a.put(`/api/conservation/orders/${orderId}`, body),
    deleteOrder: (orderId) => a.delete(`/api/conservation/orders/${orderId}`),
    getOrderContaining: (orderId) =>
      a.get(`/api/conservation/orders/${orderId}/items`),
    postOrder1: (body, params) =>
      a.post(`/api/KO/iu-protocol`, body, { params: params }),
    getLocomotives: (params) =>
      a.get(`/api/conservation/get-locomotives`, { params }),
    postLocData: (itemId, body) =>
      a.post(`api/conservation/orders/items/${itemId}/locs`, body),
    getMonitoring: (params) =>
      a.get(`api/conservation/monitor/locs`, { params }),
    checkAvailableLoco: (params) =>
      a.get(`api/conservation/check-available-locomotives`, { params }),
    getOrderLocomotivesString: (orderItemId) =>
      a.get(`api/conservation/orders/items/${orderItemId}/locs`),
    getOrderLocs: (orderId) =>
      a.get(`api/conservation/orders/orders/${orderId}/locs`),
    orderConfirm: (orderId, body) =>
      a.put(`api/conservation/orders/${orderId}/confirm`, body),
  },
  purchases: {
    getPurchasesList: (params) =>
      a.get(`/api/purchases`, { params, momentFormatter: dateFormats.upToDay }),
    getNsiTypes: () => a.get(`/api/purchases/nsi/types`),
    postCreatePurchase: (purchaseInfo) =>
      a.post(`/api/purchases`, purchaseInfo),
    postSubmitPurchase: ({ purchaseId }) =>
      a.post(`/api/purchases/${purchaseId}/submit`),
    postCreatePurchaseService: ({ purchaseId, purchaseInfo }) =>
      a.post(`/api/purchases/${purchaseId}/service`, purchaseInfo),
    getPurchaseService: ({ purchaseId }) =>
      a.get(`/api/purchases/${purchaseId}/service`),
    postCreatePurchaseProduct: ({ purchaseId, purchaseInfo }) =>
      a.post(`/api/purchases/${purchaseId}/product`, purchaseInfo),
    getPurchaseProduct: ({ purchaseId }) =>
      a.get(`/api/purchases/${purchaseId}/product`),
    postSubmitPurchaseService: ({ purchaseId }) =>
      a.post(`/api/purchases/${purchaseId}/service/submit`),
    postSubmitPurchaseProduct: ({ purchaseId }) =>
      a.post(`/api/purchases/${purchaseId}/product/submit`),
    postCreatePurchaseResponsible: ({ purchaseId, purchaseInfo }) =>
      a.post(`/api/purchases/${purchaseId}/responsible-person`, purchaseInfo),
    postCreatePurchaseResult: ({ purchaseId, purchaseInfo }) =>
      a.post(`/api/purchases/${purchaseId}/result`, purchaseInfo),
    postSubmitPurchaseResult: ({ purchaseId }) =>
      a.post(`/api/purchases/${purchaseId}/result/submit`),
    postFile: ({ purchaseId, fileId, ftId }) =>
      a.post(`/api/purchases/${purchaseId}/files/${fileId}/type/${ftId}`),
    getFiles: ({ purchaseId }) => a.get(`/api/purchases/${purchaseId}/files`),
    getFileTypes: () => a.get(`/api/purchases/nsi/file-types`),
    postResultFile: ({ purchaseId, fileId }) =>
      a.post(`/api/purchases/${purchaseId}/result/file/${fileId}`),
    getNamesWithSkmtr: () => a.get(`/api/purchases/product/names-with-skmtr`),
    getContractors: () => a.get(`/api/purchases/product/сontractors`),
    getResponsibleFIO: () => a.get(`/api/purchases/nsi/responsible-fio`),
    postUrgentlySubmit: ({ purchaseId }) =>
      a.post(`/api/purchases/${purchaseId}/result/urgently-submit`),
    getExcelPurchase: (params) =>
      a.get(`api/purchases/excel`, { params, responseType: "blob" }),
  },
  koReporst: {
    getRewriteOrder: (params) =>
      a.get(`/api/KOReports/get-rewrite-order`, { params }),
    getRwriteProt: (params) =>
      a.get(`/api/KOReports/get-rewrite-prot`, { params }),
  },
  nepReports: {
    getReserv: (params) => a.get(`/api/reports/nep/reserv`, { params }),
    getReservWithOverrun: (params) =>
      a.get(`/api/reports/nep/reserv-with-overrun`, { params }),
    getOverrunExcel: (params) =>
      a.get(`/api/reports/nep/reserv-with-overrun/excel`, {
        params,
        responseType: "blob",
      }),
    getViolChain: (params) =>
      a.get(`/api/reports/nep/reserv/viol-chain`, { params }),
    getRemToNr: (params) => a.get(`/api/reports/nep/rem-to-nr`, { params }),
    getNepToNpChains: (params) =>
      a.get(`/api/reports/nep/nep-to-ep-chains`, { params }),
  },
  users: {
    getRolesConfig: () => a.get("/api/roles/config"),
    postRolesConfig: (params) => a.post("/api/roles/config", params),
  },
  nsiTaskManager: {
    getTemplates: () => a.get("/api/task-manager/nsi/templates"),
    getTemplatesAll: () => a.get("/api/task-manager/nsi/templates/all"),
    getStatuses: ({ categoryId }) =>
      a.get(`/api/task-manager/nsi/categories/${categoryId}/statuses`),
    getTemplateStatuses: ({ id }) =>
      a.get(`/api/task-manager/nsi/templates/${id}/statuses`),
    postNewTemp: (params) => a.post("/api/task-manager/nsi/templates", params),
    updateTemp: ({ templateId, ...params }) =>
      a.put(`/api/task-manager/nsi/templates/${templateId}`, params),
    deleteTempl: ({ templateId }) =>
      a.delete(`/api/task-manager/nsi/templates/${templateId}`),
    checkStatsuBeforeDel: (statusId) =>
      a.get(`/api/task-manager/nsi/status/${statusId}/tasks`),
  },
  taskboard: {
    leaveGroup: (workGroupId) =>
      a.delete(`/api/task-manager/work-groups/${workGroupId}/members/leave`),
    getUsersForWG: (workGroupId) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}/users`),
    getListsForCreateTask: (workGroupId) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}/children`),
    deleteGroupOnSidebar: (workGroupId) =>
      a.delete(`/api/task-manager/work-groups/${workGroupId}`),
    getGroupDetail: (workGroupId) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}`),
    analytics: (workGroupId, queryString) =>
      a.get(
        `/api/task-manager/analytics/work-group/${workGroupId}${queryString}`
      ),
    getBoardTree: () => a.get(`/api/task-manager/work-groups`),
    getBoard: (workSpaceId, queryString) =>
      a.get(
        `/api/task-manager/work-groups/${workSpaceId}/boards${queryString}`
      ),
    getList: (workSpaceId, queryString) =>
      a.get(`/api/task-manager/work-groups/${workSpaceId}/lists${queryString}`),
    getUserInWGList: (workGroupId) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}/members`),
    // ${searchLine ?'searchLine=' + searchLine :''}&workGroupId=${workGroupId}&pageNumber=${pageNumber}`
    searchUser: (searchLine, workGroupId, pageNumber = 1) =>
      a.get(`/api/task-manager/work-groups/search-users?`, {
        params: { searchLine, workGroupId, pageNumber },
      }),
    getTasksDetail: (workGroupId, taskNumber) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}`),
    getUsersTasks: ({ categoryId, pageNumber, queryString = "" }) =>
      a.get(
        `/api/task-manager/tasks?categoryId=${categoryId}&pageNumber=${pageNumber}${queryString}`
      ),

    getLabelsByWG: (workGroupId) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}/labels`),
    updateLabel: (labelId, payload) =>
      a.put(`/api/task-manager/labels/${labelId}`, { ...payload }),
    deleteLabel: (labelId) => a.delete(`/api/task-manager/labels/${labelId}`),
    createLabelInWG: (workGroupId, payload) =>
      a.post(`/api/task-manager/work-groups/${workGroupId}/labels`, {
        ...payload,
      }),

    postPartsOfSidebar: (payload) =>
      a.post(`/api/task-manager/work-groups`, payload),
    updateGroupInfo: (workGroupId, payload) =>
      a.put(`/api/task-manager/work-groups/${workGroupId}`, { ...payload }),
    addTask: ({ workGroupId, ...payload }) =>
      a.post(`/api/task-manager/work-groups/${workGroupId}/tasks`, payload),
    deleteTask: (workGroupId, taskNumber) =>
      a.delete(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}`
      ),
    updateTaskDate: (workGroupId, taskNumber, planStartDate, planEndDate) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/plan-dates`,
        {
          planStartDate,
          planEndDate,
        }
      ),
    updateTaskFiles: (workGroupId, taskNumber, ids) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/files`,
        { ids: ids }
      ),
    updateSubtasks: (taskId, subtasks) =>
      a.put(`/api/task-manager/tasks/${taskId}/subtasks`, subtasks),
    updateTaskPriority: (workGroupId, taskNumber, priorityId) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/priority/${priorityId}`
      ),
    updateTaskLabels: (workGroupId, taskNumber, labels) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/labels`,
        { ids: labels }
      ),
    updateTaskStatus: (workGroupId, taskNumber, statusId) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/status/${statusId}`
      ),
    updateTaskAssignees: (workGroupId, taskNumber, assignees) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/assignees`,
        { ids: assignees }
      ),
    updateTaskDescTitle: (workGroupId, taskNumber, desc, title) =>
      a.put(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}`,
        {
          title: title,
          content: desc,
        }
      ),
    getStatusInWG: (workGroupId) =>
      a.get(`/api/task-manager/work-groups/${workGroupId}/statuses`),
    getTaskComments: ({ workGroupId, taskNumber }) =>
      a.get(
        `/api/task-manager/work-groups/${workGroupId}/tasks/${taskNumber}/comments`
      ),
    deleteTaskComment: ({ commentId }) =>
      a.delete(`/api/task-manager/comments/${commentId}`),
    updateTaskComment: ({ commentId, content }) =>
      a.put(`/api/task-manager/comments/${commentId}`, { content }),
    postNewTaskComment: (payload) =>
      a.post(`/api/task-manager/comments`, payload),
    updateSubtask: ({ subtaskId, isDone }) =>
      a.put(`/api/task-manager/subtasks/${subtaskId}?isDone=${isDone}`),
    postNewSubtask: (taskId, content) =>
      a.post(`/api/task-manager/tasks/${taskId}/subtasks`, { content }),
  },
  epReports: {
    getCargoUsedAsPass: ({ roadId, ...params }) =>
      a.get(`/api/reports/ep/cargo-used-as-pass/${roadId}`, { params }),
    getWaitingInDepo: (params) =>
      a.get("/api/reports/ep/waiting-in-depo", {
        params,
        momentFormatter: dateFormats.upToDay,
      }),
  },
  questionnaire: {
    getLink: ({ roadId, depoId, terminalId }) =>
      a.get(`/api/questionnaire/${roadId}/${depoId}/${terminalId}/links`),
    getTerminals: () => a.get("/api/questionnaire/terminals"),
  },
  epRpAnalytics: {
    source: CancelToken.source(),
    getRzd: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/ep/rzd`, {
        params,
        cancelToken: this.source.token,
      });
    },
    getRoad: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/ep/road`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => removeNotValidRoads(response));
    },
    getDepot: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/ep/depo`, {
        params,
        cancelToken: this.source.token,
      });
    },
    getRzdHistory: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/ep/rzd/history`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixHistory(response));
    },
    getRoadHistory: function (dorKod, tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/ep/dor/${dorKod}/history`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixHistory(response));
    },
    getDepoHistory: function (depoKod, tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/ep/depo/${depoKod}/history`, {
        params,
        cancelToken: this.source.token,
      });
    },
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
  },
  tu152: {
    getMash: ({ tabNum, ...params }) =>
      a.get(`/api/tu152/mash/${tabNum}`, {
        params,
        momentFormatter: {
          paramNames: ["fromDate", "toDate"],
          formatTo: dateFormats.upToMinutes,
        },
      }),
    getLoc: ({ serLoc, znsLoc, prsLoc, ...params }) =>
      a.get(`/api/tu152/loc/${serLoc}/${znsLoc}/${prsLoc}`, {
        params,
        momentFormatter: {
          paramNames: ["fromDate", "toDate"],
          formatTo: dateFormats.upToMinutes,
        },
      }),
  },
  to36Analytics: {
    source: CancelToken.source(),
    getRzd: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/to36/rzd`, {
        params,
        cancelToken: this.source.token,
      });
    },
    getRoad: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/to36/road`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => removeNotValidRoads(response));
    },
    getDepot: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/to36/depo`, {
        params,
        cancelToken: this.source.token,
      });
    },
    getRzdHistory: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/to36/rzd/history`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixHistory(response));
    },
    getRoadHistory: function (dorKod, tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/to36/dor/${dorKod}/history`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixHistory(response));
    },
    getDepoHistory: function (depoKod, tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/to36/depo/${depoKod}/history`, {
        params,
        cancelToken: this.source.token,
      });
    },
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
  },
  map: {
    getStationsSource: null,
    getLocomotivesSource: null,
    getLocomotives: (params) => {
      if (ekasut.map.getLocomotivesSource) {
        ekasut.map.getLocomotivesSource.cancel();
      }
      ekasut.map.getLocomotivesSource = CancelToken.source();
      return a.get(`/api/map/locomotives`, {
        params,
        cancelToken: ekasut.map.getLocomotivesSource.token,
      });
    },
    getStations: (params) => {
      if (ekasut.map.getStationsSource) {
        ekasut.map.getStationsSource.cancel();
      }
      ekasut.map.getStationsSource = CancelToken.source();
      return a.get(`/api/map/stan`, {
        params,
        cancelToken: ekasut.map.getStationsSource.token,
      });
    },
    getLocomotiveDetail: (params) =>
      a.get(`/api/map/locomotives-detail`, { params }),
    getPathHistory: ({ serLoc, znsLoc, prsLoc, daysBack }) =>
      a.get(
        `/api/map/locomotive/path-history/${serLoc}/${znsLoc}/${prsLoc}/${daysBack}`
      ),
    getVSCTest: (params) => a.get("api/map/VSCTest", { params: params }),
  },
  to38Analytics: {
    source: CancelToken.source(),
    getRzd: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/to38/rzd`, {
        params,
        cancelToken: this.source.token,
      });
    },
    getRoad: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/to38/road`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => removeNotValidRoads(response));
    },
    getDepot: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/to38/depo`, {
        params,
        cancelToken: this.source.token,
      });
    },
    getRzdHistory: function (tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/to38/rzd/history`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixHistory(response));
    },
    getRoadHistory: function (dorKod, tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a
        .get(`/api/statistic/to38/dor/${dorKod}/history`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixHistory(response));
    },
    getDepoHistory: function (depoKod, tractionFilter) {
      let params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        tractionFilter
      );
      return a.get(`/api/statistic/to38/depo/${depoKod}/history`, {
        params,
        cancelToken: this.source.token,
      });
    },
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
  },
  nrTooltipsAnalytics: {
    source: CancelToken.source(),
    getRoad: function (uses) {
      let params = ekasut._appendLocomotiveUses(new URLSearchParams(), uses);
      return a
        .get(`/api/statistic/nr/tooltip/road`, {
          params,
          cancelToken: this.source.token,
        })
        .then((response) => fixRoadTooltips(response));
    },
    getDepo: function (uses) {
      let params = ekasut._appendLocomotiveUses(new URLSearchParams(), uses);
      return a.get(`/api/statistic/nr/tooltip/depo`, {
        params,
        cancelToken: this.source.token,
      });
    },
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
  },
  operativePlanRepairs: {
    getLocomotives: (planDate, tche, sld, isApproved) =>
      a.get(
        `/api/planrep/operative/locomotives/${tche}/${sld}/${planDate.format(
          dateFormats.upToDay
        )}`,
        { params: { isApproved } }
      ),
    getLocomotivesByKodFirm: (kodFirm, planDate) =>
      a.get(
        `/api/planrep/operative/locomotives/${kodFirm}/${planDate.format(
          dateFormats.upToDay
        )}`
      ),
    putLocomotive: (tche, sld, locoData) =>
      a.put(`/api/planrep/operative/locomotive/${tche}/${sld}`, locoData),
    deleteLocomotive: (locoPlanId) =>
      a.delete(`/api/planrep/operative/locomotive/${locoPlanId}`),
    postLocomotive: (planDate, locoPlanId, locoData) =>
      a.post(`/api/planrep/operative/locomotive/${locoPlanId}`, {
        ...locoData,
        planDate: planDate.format(dateFormats.upToMinutes),
      }),
    postApprove: (locoPlanId, approve) =>
      a.post(`/api/planrep/operative/approve-plan/${locoPlanId}/${approve}`),
    getLocomotivesExcel: (planDate, tcheId, sldId) =>
      a.get(
        `/api/planrep/operative/locomotives/${tcheId}/${sldId}/${planDate.format(
          "YYYY-MM-DD"
        )}/excel`,
        {
          responseType: "blob", // important
        }
      ),
    getLocomotivesPdf: (planDate, tcheId, sldId) =>
      a.get(
        `/api/planrep/operative/locomotives/${tcheId}/${sldId}/${planDate.format(
          "YYYY-MM-DD"
        )}/pdf`,
        {
          responseType: "blob", // important
        }
      ),
  },
  nrAnalytics: {
    getRoad: (filter) =>
      a.get(`/api/statistic/nr/road`, {
        params: ekasut._appendTractionFilter(new URLSearchParams(), filter),
      }),
    getDepo: (filter) =>
      a.get(`/api/statistic/nr/depo`, {
        params: ekasut._appendTractionFilter(new URLSearchParams(), filter),
      }),
    getRoadHistory: (id, filter) => {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      params.append("dorId", id);
      return a.get(`/api/statistic/nr/history/road`, { params });
    },
    getDepoHistory: (id, filter) => {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      params.append("depoId", id);
      return a.get(`/api/statistic/nr/history/depo`, { params });
    },
    getDetail: (date, roadId, depotId) => {
      let params = new URLSearchParams();
      params.append("date", date);
      params.append("dorKod", roadId);
      if (depotId !== undefined) {
        params.append("kodFirm", depotId);
      }
      return a.get(`/api/statistic/nr/detail`, { params });
    },
  },
  to32NepAnalytics: {
    source: CancelToken.source(),
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
    getRzd: function (filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      return a.get(`/api/statistic/to32/nep/rzd`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getRoad: function (id, filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      if (id) {
        params.append("dorKod", id);
      }
      return a.get(`/api/statistic/to32/nep/road`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getDepot: function (id, filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      if (id) {
        params.append("depoKod", id);
      }
      return a.get(`/api/statistic/to32/nep/depo`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getRoadWaitDetail: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to32/nep/graph/road/${id}/detail/wait`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getDepotWaitDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to32/nep/graph/depo/${id}/detail/wait`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getRoadTrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to32/nep/graph/road/${id}/detail/tr`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getDepotTrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));

      return a.get(`/api/statistic/to32/nep/graph/depo/${id}/detail/tr`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getRoadNrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to32/nep/graph/road/${id}/detail/nr`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getDepotNrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to32/nep/graph/depo/${id}/detail/nr`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getRoadClaimDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to32/nep/graph/road/${id}/detail/reklam`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getDepotClaimDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to32/nep/graph/depo/${id}/detail/reklam`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getRoadGraph: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to32/nep/graph/road/${id}`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
    getDepotGraph: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to32/nep/graph/depo/${id}`, {
        params,
        cancelToken: ekasut.to32NepAnalytics.source.token,
      });
    },
  },
  to36NepAnalytics: {
    source: CancelToken.source(),
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
    getRzd: function (filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      return a.get(`/api/statistic/to36/nep/rzd`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getRoad: function (id, filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      if (id) {
        params.append("dorKod", id);
      }
      return a.get(`/api/statistic/to36/nep/road`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getDepot: function (id, filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      if (id) {
        params.append("depoKod", id);
      }
      return a.get(`/api/statistic/to36/nep/depo`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getRoadWaitDetail: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to36/nep/graph/road/${id}/detail/wait`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getDepotWaitDetail: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to36/nep/graph/depo/${id}/detail/wait`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getRoadTrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to36/nep/graph/road/${id}/detail/tr`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getDepotTrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));

      return a.get(`/api/statistic/to36/nep/graph/depo/${id}/detail/tr`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getRoadNrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to36/nep/graph/road/${id}/detail/nr`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getDepotNrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to36/nep/graph/depo/${id}/detail/nr`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getRoadClaimDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to36/nep/graph/road/${id}/detail/reklam`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getDepotClaimDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to36/nep/graph/depo/${id}/detail/reklam`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getRoadGraph: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to36/nep/graph/road/${id}`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
    getDepotGraph: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to36/nep/graph/depo/${id}`, {
        params,
        cancelToken: ekasut.to36NepAnalytics.source.token,
      });
    },
  },
  to38NepAnalytics: {
    source: CancelToken.source(),
    cancel: function () {
      this.source.cancel("Operation was canceled by user");
      this.source = CancelToken.source();
    },
    getRzd: function (filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      return a.get(`/api/statistic/to38/nep/rzd`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getRoad: function (id, filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      if (id) {
        params.append("dorKod", id);
      }
      return a.get(`/api/statistic/to38/nep/road`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getDepot: function (id, filter) {
      let params = ekasut._appendTractionFilter(new URLSearchParams(), filter);
      if (id) {
        params.append("depoKod", id);
      }
      return a.get(`/api/statistic/to38/nep/depo`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getRoadWaitDetail: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to38/nep/graph/road/${id}/detail/wait`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getDepotWaitDetail: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to38/nep/graph/depo/${id}/detail/wait`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getRoadTrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to38/nep/graph/road/${id}/detail/tr`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getDepotTrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));

      return a.get(`/api/statistic/to38/nep/graph/depo/${id}/detail/tr`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getRoadNrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to38/nep/graph/road/${id}/detail/nr`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getDepotNrDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to38/nep/graph/depo/${id}/detail/nr`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getRoadClaimDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to38/nep/graph/road/${id}/detail/reklam`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getDepotClaimDetail: (id, traction, date) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      params.append("date", date.format("YYYY-MM-DD"));
      return a.get(`/api/statistic/to38/nep/graph/depo/${id}/detail/reklam`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getRoadGraph: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to38/nep/graph/road/${id}`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
    getDepotGraph: (id, traction) => {
      const params = ekasut._appendTractionFilter(
        new URLSearchParams(),
        traction
      );
      return a.get(`/api/statistic/to38/nep/graph/depo/${id}`, {
        params,
        cancelToken: ekasut.to38NepAnalytics.source.token,
      });
    },
  },
  kasantAnalytics: {
    source: CancelToken.source(),
    cancel: () => {
      ekasut.kasantAnalytics.source.cancel();
      ekasut.kasantAnalytics.source = CancelToken.source();
    },
    getRoads: function (from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/road`, {
        params: params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getDepos: function (from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/depo`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getRoadDetails: function (id, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/road/${id}`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getDepoDetails: function (id, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/depo/${id}`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getRzdDashboard: function (from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/dashboard/rzd`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getDepoDashboard: function (id, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/dashboard/depo/${id}`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getRoadDashboard: function (id, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/dashboard/road/${id}`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getRoadHistoryDetails: function (id, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/history/road/${id}`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
    getDepoHistoryDetails: function (id, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      return a.get(`/api/statistic/kasant/history/depo/${id}`, {
        params,
        cancelToken: ekasut.kasantAnalytics.source.token,
      });
    },
  },
  to33Analytics: {
    getRoads: function (approvedkge, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      params.append("approvedKge", approvedkge);
      return a.get(`/api/statistic/kge/road`, { params: params });
    },
    getDepos: function (approvedkge, from, to, traction, uses) {
      let params = ekasut._appendFromToTractionUses(
        new URLSearchParams(),
        from,
        to,
        traction,
        uses
      );
      params.append("approvedKge", approvedkge);
      return a.get(`/api/statistic/kge/depo`, { params: params });
    },
    getRoadHistory: function (id, isYear) {
      let params = new URLSearchParams();
      params.append("dorPrip", id);
      params.append("yearHistory", isYear);
      return a.get(`/api/statistic/kge/road/history`, { params });
    },
    getDepoHistory: function (id, isYear) {
      let params = new URLSearchParams();
      params.append("kodFirm", id);
      params.append("yearHistory", isYear);
      return a.get(`/api/statistic/kge/depo/history`, { params });
    },
    getRzdHistory: function (isYear) {
      let params = new URLSearchParams();
      params.append("yearHistory", isYear);
      return a.get(`/api/statistic/kge/rzd/history`, { params });
    },
  },
  locomotivesNsi: {
    getSerTps: () => a.get(`/api/nsi/locomotives/ser-tps`),
    getSerTpsAsutt: () => a.get(`/api/nsi/locomotives/ser-tps/asutt`),
    getZnsTps: (ser_loc) =>
      a.get(`/api/nsi/locomotives/zns-tps`, { params: { ser_loc } }),
    getPrsTps: (ser_loc, zns_loc) =>
      a.get(`/api/nsi/locomotives/prs-tps`, { params: { ser_loc, zns_loc } }),
    getTypes: () => a.get(`/api/nsi/repairs/types`),
  },
  ekasutData: {
    getTpeDetailBySection: (locser, locnum, locsec) =>
      a.get(`/api/data/tpe-detail/${locser}/${locnum}/${locsec}`),
    getTpeDetailByIdTpe: (idtpe) => a.get(`/api/data/tpe-detail/${idtpe}`),
    getRepairTypes: () => a.get(`/api/nsi/repairs/types`),
    getServiceTree: () => a.get(`/api/data/service-tree`),
    getCtTree: () => a.get(`/api/data/ct-tree`),
    getDepotMap: (depotId) =>
      a.get(`/api/data/depo-map`, { params: { id: depotId } }),
    getEp: (objectId) => a.get(`/api/data/ep`, { params: { objectId } }),
    getNsiStan: (str) => {
      const params = new URLSearchParams();
      params.append("name", str);
      return a.get(`/api/data/nsi/stan`, { params });
    },
    getSvps: (series, locnum, section) =>
      a.get(`/api/data/svps/${series}/${locnum}/${section}`),
    getFuel: (locomotives) => a.post(`/api/data/fuel`, locomotives),
    getLogControls: (idObject) => a.get(`/api/data/log-controls/${idObject}`),
    getRoute: (ser, num, sec) => a.get(`/api/data/route/${ser}/${num}/${sec}`),
    getSostHistory: ({ fromDate, toDate, serLoc, znsLoc, prsLoc }) =>
      a.get(`/api/data/loc/${serLoc}/${znsLoc}/${prsLoc}/sost/history`, {
        params: { fromDate, toDate },
        momentFormatter: dateFormats.full,
      }),
    getTpeDetailTable: (params) =>
      a.get("/api/data/tpe-detail/table", { params }),
    getSickExcel: (params, bool) =>
      a.get(`/api/data/sick/excel?isRemission=${bool}`, {
        params,
        responseType: "blob",
      }),
    getLocoExcel: (params) =>
      a.get("/api/map/locomotives-detail/excel", {
        params,
        responseType: "blob",
      }),
  },
  svpsCheck: {
    getFuel: (idObject, fromDate, toDate) =>
      a.get(`/api/svps-check/fuel`, {
        params: { idObject, fromDate, toDate },
        momentFormatter: dateFormats.upToSeconds,
      }),
  },
  planRepairs: {
    getLocomotive: (decadeDate, tcheId, sldId) => {
      const params = new URLSearchParams();
      params.append("decadeDate", decadeDate.format("YYYY-MM-DD"));
      params.append("tche", tcheId);
      params.append("sld", sldId);
      return a.get(`/api/planrep/decade/locomotive`, { params });
    },
    putLocomotive: (decadeDate, tcheId, sldId, locoData) => {
      const params = new URLSearchParams();
      params.append("decadeDate", decadeDate.format("YYYY-MM-DD"));
      params.append("tche", tcheId);
      params.append("sld", sldId);
      return a.put(`/api/planrep/decade/locomotive`, locoData, { params });
    },
    postLocomotive: (decadeDate, locoPlanId, locoData) => {
      const params = new URLSearchParams();
      params.append("decadeDate", decadeDate.format("YYYY-MM-DD"));
      params.append("locoPlanId", locoPlanId);
      return a.post(`/api/planrep/decade/locomotive`, locoData, { params });
    },
    deleteLocomotive: (locoPlanId) => {
      const params = new URLSearchParams();
      params.append("locoPlanId", locoPlanId);
      return a.delete(`/api/planrep/decade/locomotive`, { params });
    },
    getLocomotives: (decadeDate, tcheId, sldId, isApproved) => {
      const params = new URLSearchParams();
      params.append("decadeDate", decadeDate.format("YYYY-MM-DD"));
      params.append("tche", tcheId);
      params.append("sld", sldId);
      if (isApproved !== undefined) {
        params.append("isApproved", isApproved);
      }
      return a.get(`/api/planrep/decade/locomotives`, { params });
    },
    getLocomotivesExcel: (decadeDate, tcheId, sldId, isApproved) => {
      const params = new URLSearchParams();
      params.append("decadeDate", decadeDate.format("YYYY-MM-DD"));
      params.append("tche", tcheId);
      params.append("sld", sldId);
      if (isApproved !== undefined) {
        params.append("isApproved", isApproved);
      }
      return a.get(`/api/planrep/decade/locomotives/excel`, {
        params,
        responseType: "blob",
      });
    },
    getSearchLocomotives: (seriesId, number, section, registryDepot) => {
      const params = new URLSearchParams();
      if (seriesId) {
        params.append("locSer", seriesId);
      }
      if (number) {
        params.append("locNum", number);
      }
      if (section) {
        params.append("locSec", section);
      }
      if (registryDepot) {
        params.append("depoPrip", registryDepot);
      }
      return a.get(`/api/planrep/search-loco`, { params });
    },
    getChat: (planDate, tche, sld, lastMsgId, planTypeId) => {
      return a.get(`/api/planrep/chat`, {
        params: {
          planDate: planDate.format("YYYY-MM-DD"),
          tche,
          sld,
          planTypeId,
          lastMsgId,
        },
        cancelToken: new CancelToken((c) => {
          ekasut.planRepairs.cancelGetChat = c;
        }),
      });
    },
    cancelGetChat: () => {},
    postChat: (date, tcheId, sldId, planTypeId, msg) => {
      const params = new URLSearchParams();
      params.append("planDate", date.format("YYYY-MM-DD"));
      params.append("tche", tcheId);
      params.append("sld", sldId);
      params.append("planTypeId", planTypeId);
      return a.post(`/api/planrep/chat`, JSON.stringify(msg), {
        params,
        headers: { "Content-Type": "application/json" },
      });
    },
    postPlanStatus: (date, tcheId, sldId, status) => {
      const params = new URLSearchParams();
      params.append("decadeDate", date.format("YYYY-MM-DD"));
      params.append("tche", tcheId);
      params.append("sld", sldId);
      params.append("status", status);
      return a.post(`/api/planrep/decade/plan-status`, null, { params });
    },
    getRelatedFirm: (kodFirm) =>
      a.get(`/api/planrep/related-firm`, { params: { kodFirm } }),
  },
  checks: {
    getDepots: () => a.get(`/api/checks/depo`),
    getArm: (stOtp, stPrib, stCtl) =>
      a.get(`/api/checks/arms`, { params: { stOtp, stPrib, stCtl } }),
    getSer: (depoId) => a.get(`/api/checks/depo/ser/${depoId}`),
    getArms: (depoId) => a.get(`/api/checks/depo/arms/${depoId}`),
    getStatistics: (id) => a.get(`/api/checks/statistics`, { params: { id } }),
  },
  planLb: {
    getRzd: () => a.get(`/api/planlb/dashboard/rzd`),
    getRzdGauge: () => a.get(`/api/planlb/dashboard/rzd/gauge`),
    getDashboardRzd: () => a.get(`/api/planlb/dashboard/rzd`),
    getDashboardRoad: (id) => a.get(`/api/planlb/dashboard/road/${id}`),
    getDashboardDepot: (id) => a.get(`/api/planlb/dashboard/depo/${id}`),
    getRoad: () => a.get(`/api/planlb/road`),
    getDepot: () => a.get(`/api/planlb/depo`),
    getRoadDetails: (id) => a.get(`/api/planlb/road/${id}`),
    getDepotDetails: (id) => a.get(`/api/planlb/depo/${id}`),
    getRoadHistory: (road) => a.get(`/api/planlb/history/road/${road}`),
    getDepoHistory: (depo) => a.get(`/api/planlb/history/depo/${depo}`),
  },
  weightLimits: {
    getWeights: (
      uolBegin,
      uolEnd,
      esrBegin,
      esrEnd,
      tractionId,
      typeId,
      locId,
      allowWeight,
      allowLength
    ) =>
      a.get(`/api/weights`, {
        params: ekasut.weightLimits.createParams(
          uolBegin,
          uolEnd,
          esrBegin,
          esrEnd,
          tractionId,
          typeId,
          locId,
          allowWeight,
          allowLength
        ),
      }),
    deleteWeight: (
      uolBegin,
      uolEnd,
      esrBegin,
      esrEnd,
      tractionId,
      typeId,
      locId
    ) =>
      a.delete(`/api/weights`, {
        params: ekasut.weightLimits.createParams(
          uolBegin,
          uolEnd,
          esrBegin,
          esrEnd,
          tractionId,
          typeId,
          locId
        ),
      }),
    postWeight: (
      uolBegin,
      uolEnd,
      esrBegin,
      esrEnd,
      tractionId,
      typeId,
      locId,
      allowWeight,
      allowLength,
      files
    ) =>
      a.post(
        `/api/weights`,
        ekasut.weightLimits.createDataParams(
          uolBegin,
          uolEnd,
          esrBegin,
          esrEnd,
          tractionId,
          typeId,
          locId,
          allowWeight,
          allowLength,
          files
        )
      ),
    getFiles: (uolBegin, uolEnd, esrBegin, esrEnd, traction, typeId, locId) =>
      a.get(`/api/weights/files`, {
        params: ekasut.weightLimits.createParams(
          uolBegin,
          uolEnd,
          esrBegin,
          esrEnd,
          traction,
          typeId,
          locId
        ),
      }),
    createDataParams: (
      uolBegin,
      uolEnd,
      esrBegin,
      esrEnd,
      tractionId,
      typeId,
      locId,
      allowWeight,
      allowLength,
      files
    ) => {
      return {
        uolBegin: uolBegin,
        uolEnd: uolEnd,
        beginEsr: esrBegin,
        endEsr: esrEnd,
        tjaga: tractionId,
        kodVdvig: typeId,
        kodSer: locId,
        weightN: allowWeight,
        lengthN: allowLength,
        files: files,
      };
    },
    postOrder: ({ data }) => a.post(`/api/weights/order`, data),
    deleteOrder: ({ orderId }) => a.delete(`/api/weights/order/${orderId}`),
    createParams: (
      uolBegin,
      uolEnd,
      esrBegin,
      esrEnd,
      tractionId,
      typeId,
      locId,
      allowWeight,
      allowLength
    ) => {
      const params = new URLSearchParams();
      append(params, "uolBegin", uolBegin);
      append(params, "uolEnd", uolEnd);
      append(params, "beginEsr", esrBegin);
      append(params, "endEsr", esrEnd);
      append(params, "tjaga", tractionId);
      append(params, "kodVdvig", typeId);
      append(params, "kodSer", locId);
      append(params, "weightN", allowWeight);
      append(params, "lengthN", allowLength);
      // append(params, 'files', fileId)
      return params;
    },
    attachFile: ({ orderId, fileId }) =>
      a.post(`/api/weights/order/${orderId}/file/${fileId}`),
  },
  predNsi: {
    getPredChildren: (kodFirm) =>
      a.get(`/api/nsi/pred/children`, { params: { kodFirm } }),
  },
  jy: {
    getJy: (
      date,
      depots,
      departureSt,
      arrivalSt,
      traction,
      locSeries,
      lbUse
    ) => {
      let helper = (v) => {
        return v.length > 0 ? v.join(",") : null;
      };
      return a.get(`/api/jy`, {
        params: {
          date: date !== undefined ? date.format("YYYY-MM-DD") : date,
          depos: helper(depots),
          stOtpr: helper(departureSt),
          stPrib: helper(arrivalSt),
          track: helper(traction),
          ser: helper(locSeries),
          isp: helper(lbUse),
        },
      });
    },
    getNsiDepot: (name) => {
      return a.get(`/api/jy/nsi/depo`, {
        params: {
          name: name,
        },
      });
    },
    getJyForLocFind: (predId) => {
      return a.get(`/api/jy/jy-for-loc-find`, {
        params: {
          predId: predId,
        },
      });
    },
    getLocsForRoute: (params) => {
      return a.get(`/api/jy/locs-for-route`, {
        params: {
          ...params,
        },
      });
    },
    putLocsForRoute: (params) => {
      return a.put(`/api/jy/locs-for-route`, undefined, {
        params: {
          ...params,
        },
      });
    },
    deleteLocsForRoute: (params) => {
      return a.delete(`/api/jy/locs-for-route`, {
        params: {
          ...params,
        },
      });
    },
    getYavkaList: (tabNum, fromDate, toDate) =>
      a.get(`/api/jy/${tabNum}`, {
        params: {
          fromDate: fromDate.format(),
          toDate: toDate.format(),
        },
      }),
  },
  mek: {
    getRoads: () => a.get(`/api/mek/terminals/road`),
    getDepots: () => a.get(`/api/mek/terminals/depo`),
    getTerminalsInDepot: (stanId, depoId) =>
      a.get(`/api/mek/terminals`, { params: { stanId, depoId } }),
    getDevicesInTerminal: (terminalId) =>
      a.get(`/api/mek/terminals/devices/${terminalId}`),
    postTerminalFile: (terminalId, fileId) =>
      a.post(`/api/mek/terminals/add-file/${terminalId}/file/${fileId}`),
  },
  monitoring: {
    getRoads: () => a.get(`/api/monitoring/roads`),
    getDepots: () => a.get(`/api/monitoring/depos`),
    getPlanDetailRoad: (roadId) =>
      a.get(`/api/monitoring/plan-detail/road`, { params: { roadId: roadId } }),
    getPlanDetailDepot: (depotId) =>
      a.get(`/api/monitoring/plan-detail/depo`, {
        params: { depoId: depotId },
      }),
    getErrorDetailRoad: (roadId) =>
      a.get(`/api/monitoring/error-detail/road`, {
        params: { roadId: roadId },
      }),
    getErrorDetailDepot: (depotId) =>
      a.get(`/api/monitoring/error-detail/depo`, {
        params: { depoId: depotId },
      }),
    getErtoDepot: (start, end) =>
      a.get(`/api/monitoring/erto/depo`, {
        params: {
          start: ekasut._formatData(start),
          end: ekasut._formatData(end),
        },
      }),
  },
  regDepotsOnScheme: {
    getDepots: () => a.get(`/api/data/depots`),
    getDepotInfo: (depoPrip, dorPrip) =>
      a.get(`/api/data/depots/locs?depoPrip=${depoPrip}&dorPrip=${dorPrip}`),
    getExcelDepotInfo: () =>
      a.get(`/api/data/depots-excel`, { responseType: "blob" }),
  },
  file: {
    postFile: (file) => {
      console.log(file);
      const formData = new FormData();
      formData.append("file", file, file.name);
      return a.post(`/api/files`, formData, {
        headers: {
          "content-type": "text/plain",
        },
      });
    },
    deleteFile: (id) => a.delete(`/api/files`, { params: { id } }),
    getFile: (id) =>
      a.get(`/api/files`, { params: { id }, responseType: "arraybuffer" }),
  },
  persData: {
    getWorkersInfo: (roadId, depotId, move, post, isMash) =>
      a.get(`/api/pers-data/info`, {
        params: { road: roadId, depo: depotId, move, post, isMash },
      }),
    getAvailableWorkersInfo: (roadId, depotId, move, post, isMash) =>
      a.get(`/api/pers-data/yavk-info`, {
        params: { road: roadId, depo: depotId, move, post, isMash },
      }),
    getPersonInfo: (workerId /*, rdId */) =>
      a.get(`/api/pers-data/detail/${workerId}`),
    getPersonPolygons: (id) => a.get(`/api/pers-data/polygons/${id}`),
    getPersonLicenses: (id) => a.get(`/api/pers-data/prava/${id}`),
    getPersonLocation: (asutrId) => a.get(`/api/pers-data/location/${asutrId}`),
    getPersonDict: (firmCode) =>
      a.get(`/api/pers-data/col-num/dict`, { params: { kodFirm: firmCode } }),
    putLocation: (lat, lon) =>
      a.put(`/api/pers-data/location`, undefined, { params: { lat, lon } }),
    getLocationByTabNum: (tabNum) => a.get(`/api/pers-data/location/${tabNum}`),
    getSearchByCol: (col) =>
      a.get(`/api/pers-data/search-by-col`, { params: { col } }),
  },
  tchmi: {
    getPlanDetail: () => a.get(`/api/tchmi/detail`),
    getPlan: () => a.get(`/api/tchmi/plan`),
    getRates: () => a.get(`/api/tchmi/nsi/rates`),
    getFormular: (tabNum, rd, kodFirm) =>
      a.get(`/api/tchmi/formular`, { params: { tabNum, rd, kodFirm } }),
    getMashRoutes: (tabNum, from, to) =>
      a.get(`/api/tchmi/mash/${tabNum}/routes`, {
        params: {
          from: from.format(),
          to: to.format(),
        },
      }),
    postKp: (payload) => a.post(`/api/tchmi/kp`, payload),
    getKp: (actionId, actionRd) =>
      a.get(`/api/tchmi/kp`, { params: { actionId, actionRd } }),
  },
  tchmiNsi: {
    dolj: {
      driver: 1841, // Машинист
      helper: 2211, // Помощник
      instructor: 5177, // Инструктор
    },
    getPeople: (fio, dolj) =>
      a.get(`/api/tchmi/nsi/people`, { params: { fio, dolj } }),
    getKip: () => a.get(`/api/tchmi/nsi/kip`),
    getNotes: () => a.get(`/api/tchmi/nsi/notes`),
    getMeasures: () => a.get(`/api/tchmi/nsi/measures`),
    getKp: () => a.get(`/api/tchmi/nsi/kp`),
  },
  incidents: {
    getIncidents: (filters) =>
      a.get(`/api/incidents`, { params: { ...filters } }),
    postEkasutDetail: (filters) =>
      a.post(`/api/incidents/ekasut/detail`, { ...filters }),
    getOverwork: (filters) =>
      a.get(`/api/incidents/overwork`, { params: { ...filters } }),
    postOverwork: (filters) =>
      a.post(`/api/incidents/overwork`, { ...filters }),
    getDetails: (filters) =>
      a.get(`/api/incidents/detail`, { params: { ...filters } }),
  },
  oldReqs: {
    fromRepairReport: (queryString) =>
      a.get(`/api/data/from-repair-report?${queryString}`),
    depoMap: (id) => a.get(`/api/data/depo-map?id=${id}`),
    repairReport: (queryString) =>
      a.get(`/api/data/repair-report?${queryString}`),
    grSick: (queryString) => a.get(`/api/data/sick?${queryString}`),
    grSickIsRemission: (queryString) =>
      a.get(`/api/data/sick?${queryString}&isRemission=${true}`),
    sickDetailNr: (queryString) =>
      a.get(`/api/data/sick-detail/nr/${queryString}`),
    sickDetailKas: (queryString) =>
      a.get(`/api/data/sick-detail/kasant/${queryString}`),
    addSickRemission: (payload) =>
      a.post(`/api/data/add-sick-remission`, payload),
    addSick: (payload) => a.post(`/api/data/add-sick/`, payload),
    delSick: (payload) => a.post(`/api/data/del-sick`, payload),
    removedSick: (queryString) =>
      a.get(`/api/data/removed-sick?${queryString}`),
    mapLocomotives: (queryString) =>
      a.get(`/api/map/locomotives?${queryString}`),
    mapLinks: (queryString) => a.get(`/api/map/links?${queryString}`),
    mapStan: (queryString) => a.get(`/api/map/stan?${queryString}`),
    locoDetails: (queryString) =>
      a.get(`/api/map/locomotives-detail?${queryString}`),
    locoDetailsExcel: (queryString) =>
      a.get(`/api/map/locomotives-detail/excel?${queryString}`, {
        responseType: "blob", // important
      }),
    mapLocos: (queryString) => a.get(`/api/map/locomotives?${queryString}`),
  },
};

ekasut.tractionTypes = {
  ICE: 0, // Internal combustion engine
  AC: 1, // Alternating current
  DC: 2, // Direct current
  AC_DC: 3, // Support both current
};
const removeNotValidRoads = (response) => {
  const roads = response.data ? response.data : [];
  const fixedRoads = [];
  const isRoadValid = (road) =>
    road.kod && road.lat && road.lon && Number(road.kod) !== 9999;
  roads.forEach((road) => {
    if (isRoadValid(road)) {
      fixedRoads.push(road);
    } else {
      console.log("received not valid road", road);
    }
  });
  response.data = fixedRoads;
  return response;
};
const fixRoadTooltips = (response) => {
  const tooltips = response.data;
  const fixedTooltips = [];
  const isValidTooltip = (tooltip) =>
    tooltip.kod && Number(tooltip.kod) !== 9999;
  tooltips.forEach((tooltip) => {
    if (isValidTooltip(tooltip)) {
      fixedTooltips.push(tooltip);
    }
  });
  response.data = fixedTooltips;
  return response;
};
const fixHistory = (response) => {
  const history = response.data;
  const isValidLocomotive = (loc) => {
    return (
      loc.ukrGroupSer &&
      Number(loc.ukrGroupSer) !== 9999 &&
      loc.ukrNameGroupSer &&
      loc.ukrNameGroupSer.trim() !== ""
    );
  };
  history.forEach((historyMoment) => {
    const fixedGroupSer = [];
    if (historyMoment.dataByUkrGroupSer) {
      historyMoment.dataByUkrGroupSer.forEach((loc) => {
        if (isValidLocomotive(loc)) {
          fixedGroupSer.push(loc);
        } else {
          console.log("received not valid ukr group series", loc);
        }
      });
    }
    historyMoment.dataByUkrGroupSer = fixedGroupSer;
  });
  return response;
};

const append = (params, name, value) => {
  if (value === undefined) {
    return;
  }
  params.append(name, value);
};
ekasut._formatData = (m) => {
  return m.format("YYYY-MM-DDTHH:mm:ss");
};
ekasut._appendFromTo = (params, from, to) => {
  if (from !== undefined) {
    params.append("from", moment(from).format());
  }
  if (to !== undefined) {
    params.append("to", moment(to).format());
  }
  return params;
};
ekasut._appendFromToTractionUses = (params, from, to, traction, uses) => {
  ekasut._appendFromTo(params, from, to);
  ekasut._appendTractionFilter(params, traction);
  ekasut._appendLocomotiveUses(params, uses);
  return params;
};
ekasut._appendLocomotiveUses = (params, uses) => {
  if (uses === undefined) {
    return params;
  }
  var str = "";
  switch (uses) {
    case locomotiveServices.PASSENGER: {
      str = "10,11";
      break;
    }
    case locomotiveServices.SWITCHING: {
      str = "50";
      break;
    }
    case locomotiveServices.FREIGHT: {
      str = "20,21,23,24,25";
      break;
    }
    default: {
      return;
    }
  }
  params.append("vidDv", str);
  return params;
};
ekasut._appendTractionFilter = (params, tractionFilter) => {
  if (tractionFilter === undefined) {
    return params;
  }
  let field = "tipElektr";
  if (tractionFilter.ice) {
    params.append(field, ekasut.tractionTypes.ICE);
  }
  if (tractionFilter.electric) {
    params.append(field, ekasut.tractionTypes.DC);
    params.append(field, ekasut.tractionTypes.AC);
  } else {
    if (tractionFilter.dc) {
      params.append(field, ekasut.tractionTypes.DC);
    }
    if (tractionFilter.ac) {
      params.append(field, ekasut.tractionTypes.AC);
    }
    if (tractionFilter.acDc) {
      params.append(field, ekasut.tractionTypes.AC_DC);
    }
  }
  return params;
};
ekasut.convertTractionFilterToTypes = (filter) => {
  let arr = [];
  if (filter.ac) {
    arr.push(ekasut.tractionTypes.AC);
  }
  if (filter.dc) {
    arr.push(ekasut.tractionTypes.DC);
  }
  if (filter.ice) {
    arr.push(ekasut.tractionTypes.ICE);
  }
  return arr;
};
export default ekasut;
