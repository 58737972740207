import React, {useState} from 'react';
import {FolderOpenOutlined} from "@ant-design/icons";
import style from "../../index.module.scss";
import {Input, Menu} from "antd";
import {Icon} from "semantic-ui-react";

const {SubMenu} = Menu;

export const AddItem = ({onBlur, onPressEnter, type, level, index}) => {

    if (type === 'addFolder') {
        return (
            <SubMenu
                icon={<><FolderOpenOutlined/>
                    <div className={style.addInput}>
                        <Input maxLength={25} autoFocus bordered={false}
                               onBlur={onBlur}
                               onPressEnter={onPressEnter}
                        />
                    </div>
                </>}
                key={`${level}${index}-${type}-add`}
            ></SubMenu>
        );
    } else if (type === 'addList') {
        return (
            <Menu.Item
                icon={<><Icon name='circle' color='black' size='small'/>
                    <div className={style.addInput}>
                        <Input maxLength={25} autoFocus bordered={false}
                               onBlur={onBlur}
                               onPressEnter={onPressEnter}
                        />
                    </div>
                </>}
                key={`${level}${index}-${type}-add`}
            ></Menu.Item>
        );
    }
}