import React, { useEffect, useState } from 'react'
import { Dimmer, Loader, Table } from "semantic-ui-react";
import appStyles from '../../../css/App.module.scss'
import moment from 'moment'

const JournalTable = React.memo(({ journal, message, loading }) => {
    const [headerRows, setHeaderRows] = useState([])
    const [bodyRows, setBodyRows] = useState([])
    const [activeRow, setActiveRow] = useState(null)

    useEffect(() => {
        const headerCells = []
        const subheaderCells = []
        tableStructure.forEach((tsItem, index) => {
            if (tsItem.subHeaders !== undefined) {
                headerCells.push(
                    <Table.HeaderCell
                        textAlign={'center'}
                        key={'h' + index}
                        colSpan={tsItem.subHeaders.length}
                    >
                        {tsItem.headerName}
                    </Table.HeaderCell>
                )
                tsItem.subHeaders.forEach((sh, shIndex) => {
                    subheaderCells.push(
                        <Table.HeaderCell textAlign={'center'} key={'sh' + index + '.' + shIndex}>{sh.headerName}</Table.HeaderCell>
                    )
                })
            } else {
                headerCells.push(
                    <Table.HeaderCell textAlign={'center'} key={'h' + index} rowSpan={2}>{tsItem.headerName}</Table.HeaderCell>
                )
            }
        })
        setHeaderRows(
            [
                <Table.Row key={'header-cells'}>{headerCells}</Table.Row>,
                <Table.Row key={'subheader-cells'}>{subheaderCells}</Table.Row>
            ]
        )
    }, [])

    useEffect(() => {
        const nextBodyRows = []

        journal.forEach((note, index) => {
            const cells = []
            let cellCounter = 0
            tableStructure.forEach(tsItem => {
                const formTableCell = (item) => {
                    let content

                    if ((note[item.fieldName] === '') || (note[item.fieldName] === undefined) || (note[item.fieldName] === null)) {
                        if (item.handleEmpty) {
                            content = item.handleEmpty(note)
                        } else {
                            content = ''
                        }
                    } else {
                        content = item.fieldCreator(note[item.fieldName])
                    }

                    return (
                        <Table.Cell key={`row-${index}_cell-${cellCounter++}`}>
                            {content}
                        </Table.Cell>
                    )
                }
                if (tsItem.subHeaders !== undefined) {
                    tsItem.subHeaders.forEach(tsSubItem => {
                        cells.push(formTableCell(tsSubItem))
                    })
                } else {
                    cells.push(formTableCell(tsItem))
                }
            })
            nextBodyRows.push(
                <Table.Row key={index} active={activeRow === index} onClick={() => {
                    setActiveRow(index)
                }}>
                    {cells}
                </Table.Row>
            )
        })

        setBodyRows(nextBodyRows)
    }, [journal, activeRow])

    return (
        message !== null ? (
            <label>{message}</label>
        ) : (
            loading ? (
                <Dimmer inverted active style={{ padding: 80, zIndex: 10 }}>
                    <Loader size='medium'>Загрузка...</Loader>
                </Dimmer>
            ) : (
                <Table compact celled selectable
                    className={appStyles.fixComplexTableHeaderBorder}
                >
                    <Table.Header>
                        {headerRows}
                    </Table.Header>
                    <Table.Body>
                        {bodyRows.length > 0 ? bodyRows : <Table.Cell className="center aligned" colSpan='56'>Нет элементов удовлетворяющих условиям поиска</Table.Cell>}
                    </Table.Body>
                </Table>
            )
        )
    )

})
export default JournalTable

const formatDateStr = (str) => {
    return moment(str).format('DD.MM.YYYY HH:mm')
}
const formatYesNo = (val) => {
    return val ? 'Да' : 'Нет'
}
const formatHaveNo = (val) => {
    return val ? 'Есть' : 'Нет'
}
const tableStructure = [
    { headerName: 'Уч ном ММ', fieldName: 'routeAccId', fieldCreator: val => val },
    { headerName: 'Место явки', fieldName: 'yavkPlace', fieldCreator: val => val },
    { headerName: 'Место сдачи', fieldName: 'sdachPlace', fieldCreator: val => val },
    { headerName: 'План ТЧМИ', fieldName: 'kip', fieldCreator: val => formatYesNo(val) },
    { headerName: 'Разн', fieldName: 'razn', fieldCreator: val => val ? 'Разнесено' : 'Не разнесено' },
    { headerName: 'СО', fieldName: 'disableHomeRestCheck', fieldCreator: val => formatYesNo(val) },
    { headerName: 'Отмена', fieldName: 'aborted', fieldCreator: val => val },
    {
        headerName: 'Бригада',
        subHeaders: [
            {
                headerName: 'Маш', fieldName: 'mashName',
                fieldCreator: (val) => val,
                handleEmpty: node => node.mashTabNum
            },
            {
                headerName: 'Пом', fieldName: 'pomName',
                fieldCreator: val => val,
                handleEmpty: node => node.pomTabNum
            },
        ]
    },
    { headerName: 'Раб П', fieldName: 'tripWorkTime', fieldCreator: val => val },
    { headerName: 'Доп мед', fieldName: 'dopMed', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Доп инстр', fieldName: 'dopInstr', fieldCreator: val => formatDateStr(val) },
    {
        headerName: 'Маршрут',
        subHeaders: [
            { headerName: 'Отпр', fieldName: 'stOtpr', fieldCreator: val => val },
            { headerName: 'Приб', fieldName: 'stPrib', fieldCreator: val => val },
            { headerName: 'Контр', fieldName: 'stCtl', fieldCreator: val => val },
        ]
    },
    { headerName: 'Вид тяги', fieldName: 'track', fieldCreator: val => val },
    { headerName: 'ПО', fieldName: 'restHome', fieldCreator: val => formatYesNo(val) },
    { headerName: 'Явка', fieldName: 't1', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Нач раб', fieldName: 'beginTeamWork', fieldCreator: val => formatDateStr(val) },
    { headerName: 'КП Отпр', fieldName: 'kpOtpr', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Отпр', fieldName: 'otpr', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Приб', fieldName: 'prib', fieldCreator: val => formatDateStr(val) },
    { headerName: 'КП Приб', fieldName: 'kpPrib', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Сдача', fieldName: 't2', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Раб >>', fieldName: 'routeRabForKRest', fieldCreator: val => val },
    { headerName: 'Отд ПО', fieldName: 'restOuter', fieldCreator: val => val },
    { headerName: '№ П', fieldName: 'trainNum', fieldCreator: val => val },
    { headerName: 'Исп', fieldName: 'isp', fieldCreator: val => val },
    { headerName: 'ТПС', fieldName: 'tpe', fieldCreator: val => val },
    { headerName: 'Примечания', fieldName: 'notes', fieldCreator: val => val },
    { headerName: 'Добавлена', fieldName: 'inserted', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Доб польз', fieldName: 'insertLogin', fieldCreator: val => val },
    { headerName: 'Доб комп', fieldName: 'insertHost', fieldCreator: val => val },
    { headerName: 'Изменена', fieldName: 'updated', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Изм польз', fieldName: 'updateLogin', fieldCreator: val => val },
    { headerName: 'Изм комп', fieldName: 'updateHost', fieldCreator: val => val },
    { headerName: 'Приёмка ТПС', fieldName: 'tpeAceptPlace', fieldCreator: val => val },
    { headerName: 'Сдача ТПС', fieldName: 'tpeRejectPlace', fieldCreator: val => val },
    { headerName: 'Пр ТПС', fieldName: 't1Tpe', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Сд ТПС', fieldName: 't2Tpe', fieldCreator: val => formatDateStr(val) },
    { headerName: '№ РПДА/РПРТ', fieldName: 'rpdtaId', fieldCreator: val => val },
    { headerName: '+Т', fieldName: 'thirds', fieldCreator: val => formatHaveNo(val) },
    { headerName: 'СглПС', fieldName: 'soglPsih', fieldCreator: val => formatYesNo(val) },
    { headerName: 'СглТЧ', fieldName: 'soglTch', fieldCreator: val => formatYesNo(val) },
    { headerName: 'Депо явки', fieldName: 'alienDepot', fieldCreator: val => val },
    { headerName: 'Раб >', fieldName: 'routeRab', fieldCreator: val => val },
    { headerName: 'К отд', fieldName: 'kRest', fieldCreator: val => val },
    { headerName: 'Пр отд', fieldName: 'overRest', fieldCreator: val => val },
    { headerName: 'Н', fieldName: 'violation', fieldCreator: val => formatHaveNo(val) },
    { headerName: 'ЧС', fieldName: 'extremelyEvent', fieldCreator: val => formatHaveNo(val) },
    { headerName: 'Рег маш', fieldName: 'regMash', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Рег пом', fieldName: 'regPom', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Закр', fieldName: 'zakr', fieldCreator: val => val },
    { headerName: 'Отпр. план', fieldName: 'totprCbdgr', fieldCreator: val => formatDateStr(val) },
    { headerName: 'Корр по с 15', fieldName: 'service15', fieldCreator: val => val },
    { headerName: 'АПВО', fieldName: 'zakazId', fieldCreator: val => val },
]

