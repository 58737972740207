import ekasut from "../../api/ekasut";

async function downloadFile(id, fileName) {
  console.log(id);
  try {
    const resFile = await ekasut.file.getFile(id);

    const bytes = new Uint8Array(resFile?.data);

    const blob = new Blob([bytes], { type: "application/octet-stream" });
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    console.log(blob);

    const urlCreator = window.URL || window.webkitURL;
    const url = urlCreator.createObjectURL(blob);

    const link = document.createElement("a");
    console.log(link);
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (e) {
    console.log(e);
  }
}

export default downloadFile;
