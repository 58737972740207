import moment from "moment";
import EventBlock from "./EventBlock";
import styles from "../index.module.scss";
import React from "react";
import {Grid} from "semantic-ui-react";
import {padStartZero} from "../../../../helpers/utils";

export default function EventGrid({events, year, month, onAdd, onClick}) {
    const eventsByDays = {}
    for (let id in events) {
        const date = events[id].planDate
        const ymd = date.format("YYYY-MM-DD")
        // filter out useless dates that will not display
        if (year === date.year() && month === (date.month() + 1)) {
            if (eventsByDays[ymd] === undefined) {
                eventsByDays[ymd] = []
            }
            eventsByDays[ymd].push(events[id])
        }
    }

    const date = moment(`${year}-${padStartZero(month, 2)}-01`)
    const daysInMonth = Array.from({length: date.daysInMonth()}, (v, i) => ++i)

    return (
        <div className={styles.gridContainer}>
            <Grid doubling columns={5} divided='vertically'>
                {daysInMonth.map(day => {
                    const ymd = `${year}-${padStartZero(month, 2)}-${padStartZero(day, 2)}`
                    const events = eventsByDays[ymd] !== undefined ? eventsByDays[ymd] : []
                    const date = moment(ymd)
                    return (
                        <Grid.Column key={ymd}>
                            <EventBlock
                                date={date}
                                events={events}
                                onClick={onClick}
                                onAdd={() => onAdd(date)}
                            />
                        </Grid.Column>
                    )
                })}
            </Grid>
        </div>
    )
}
EventGrid.defaultProps = {
    events: {},
    year: 1991,
    month: 4,
    onAdd: () => {},
    onClick: () => {}
}