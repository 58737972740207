// eslint-disble
import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Table, Header} from "semantic-ui-react";
import styles from "./TableFirstCard.module.scss";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {replaceNullStateWithNoDataString} from "../TableFourthCard/TableFourthCard";

export function sortReducer(state, action) {
    switch (action.type) {
        case 'CHANGE_SORT':
            if (state.column === action.column) {
                return {
                    ...state,
                    data: state.data.slice().reverse(),
                    direction:
                        state.direction === 'ascending' ? 'descending' : 'ascending',
                }
            }
            return {
                column: action.column,
                data: state.data.sort((a, b) => {
                    const firstItem = a[action.column];
                    const secondItem = b[action.column];
                    if (!isNaN(+firstItem) && !isNaN(+secondItem)) {
                        return +firstItem - +secondItem;
                    }
                    if (firstItem > secondItem) {
                        return 1;
                    }
                    if (firstItem < secondItem) {
                        return -1;
                    }
                    return 0;
                }),
                direction: 'ascending',

            }
        default:
            throw new Error()
    }
}
export const filterData = (propData, key) => {
    const arr = [];
    propData.forEach(item => {
        if (!arr.includes(item[key])) {
            arr.push(item[key]);
        }
    });
    return arr;
}
const TableFirstCard = ({data: tableData}) => {
    const [state, dispatch] = useReducer(sortReducer, {
        column: null,
        data: replaceNullStateWithNoDataString(tableData),
        direction: null,
    });
    const { column, data, direction } = state;
    const [filteredData, setFilteredData] = useState(data);



    const filterData1 = useMemo(() => filterData(data, "roadName"), []);
    const filterData2 = useMemo(() => filterData(data, "firmPrip"), []);
    const filterData3 = useMemo(() => filterData(data, "serName"), []);
    const filterData4 = useMemo(() => filterData(data, "nomSec"), []);
    const filterData5 = useMemo(() => filterData(data, "categFail"), []);
    const filterData6 = useMemo(() => filterData(data, "failDate"), []);
    const filterData7 = useMemo(() => filterData(data, "firmNameRepair"), []);
    const filterData8 = useMemo(() => filterData(data, "remName"), []);
    const filterData9 = useMemo(() => filterData(data, "dateRepair"), []);

    const [selectedRowsInFilters, setSelectedRowsInFilters] = useState({
        roadName: filterData1,
        firmPrip: filterData2,
        serName: filterData3,
        nomSec: filterData4,
        categFail: filterData5,
        failDate: filterData6,
        firmNameRepair: filterData7,
        remName: filterData8,
        dateRepair: filterData9,
    });

    useEffect(() => {
        // if (filteredData !== data) {
        //     setFilteredData(data);
        // }
        const newFilteredData = data
            .filter(item => selectedRowsInFilters.roadName.includes(item.roadName))
            .filter(item => selectedRowsInFilters.firmPrip.includes(item.firmPrip))
            .filter(item => selectedRowsInFilters.serName.includes(item.serName))
            .filter(item => selectedRowsInFilters.nomSec.includes(item.nomSec))
            .filter(item => selectedRowsInFilters.categFail.includes(item.categFail))
            .filter(item => selectedRowsInFilters.failDate.includes(item.failDate))
            .filter(item => selectedRowsInFilters.firmNameRepair.includes(item.firmNameRepair))
            .filter(item => selectedRowsInFilters.remName.includes(item.remName))
            .filter(item => selectedRowsInFilters.dateRepair.includes(item.dateRepair));
        setFilteredData(newFilteredData);
    }, [data, selectedRowsInFilters]);

    const changeSelectedRows = (colName, newData) => {
        setSelectedRowsInFilters(prevState => ({
            ...prevState,
            [colName]: newData,
        }));
    }
    return (
        <div>
            <Header as='h2'>Количество отказов после проведения комиссионного осмотра</Header>
            <div>
                <Header as='h3'>Фильтрация</Header>
                <div style={{display: "flex", gap: "5px"}} className={styles.filtersWrapper}>
                    <ColumnFilter colName='roadName' title="Дорога" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData1}/>
                    <ColumnFilter colName='firmPrip' title="Депо" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData2}/>
                    <ColumnFilter colName='serName' title="Серия" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData3}/>
                    <ColumnFilter colName='nomSec' title="Номер" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData4}/>
                    <ColumnFilter colName='categFail' title="Категория отказа" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData5}/>
                    <ColumnFilter colName='failDate' title="Дата отказа" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData6}/>
                    <ColumnFilter colName='firmNameRepair' title="Предприятие ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData7}/>
                    <ColumnFilter colName='remName' title="Ремонт" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData8}/>
                    <ColumnFilter colName='dateRepair' title="Дата ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData9}/>
                </div>
            </div>

            <Table celled>
                <Table.Header className={styles.header}>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell
                            sorted={column === 'roadName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'roadName' })}
                        >Дорога приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'firmPrip' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmPrip' })}
                        >Депо приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'serName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serName' })}
                        >Серия</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'nomSec' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nomSec' })}
                        >Номер</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'categFail' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'categFail' })}
                        >Категория отказа</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'failDate' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'failDate' })}
                        >Дата отказа</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'objectName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'objectName' })}
                        >Экипажная часть и механическое оборудование локомотива, МВПС</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'reasonName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'reasonName' })}
                        >Разрушение опорного подшипника тягового редуктора</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'firmNameRepair' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'firmNameRepair' })}
                        >Предприятие ремонта</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'remName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'remName' })}
                        >Ремонт</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'dateRepair' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateRepair' })}
                        >Дата ремонта</Table.HeaderCell>
                        {/* <Table.HeaderCell>
                Отметка о проведении <br /> цикловых работ
              </Table.HeaderCell> */}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {filteredData?.map((item, index) => (
                        <Table.Row
                            key={index}
                            textAlign={"center"}
                            onClick={() => {

                            }}
                        >
                            <Table.Cell>{item.roadName}</Table.Cell>
                            <Table.Cell>{item.firmPrip}</Table.Cell>
                            <Table.Cell>{item.serName}</Table.Cell>
                            <Table.Cell>{item.nomSec}</Table.Cell>
                            <Table.Cell>{item.categFail}</Table.Cell>
                            <Table.Cell>{item.failDate}</Table.Cell>
                            <Table.Cell>{item.objectName}</Table.Cell>
                            <Table.Cell>{item.reasonName}</Table.Cell>
                            <Table.Cell>{item.firmNameRepair}</Table.Cell>
                            <Table.Cell>{item.remName}</Table.Cell>
                            <Table.Cell>{item.dateRepair}</Table.Cell>

                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TableFirstCard;