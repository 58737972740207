import React, { useEffect, useState, useMemo } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import DelayedSearch from "../../NsiWeightLength/DelayedSearch";
import { formOptions } from "../../../helpers/utils";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import styles from "./JournalFilter.module.scss";
import cn from "classnames";

export default function JournalFilter({
  onChange,
  locSeries,
  locSeriesLoading,
  departureSt,
  departureStLoading,
  departureStTypeEnd,
  arrivalSt,
  arrivalStLoading,
  arrivalStTypeEnd,
  stMinChar,
  stSearchDelay,
  className,
  depots,
  depotsLoading,
  depotsTypeEnd,
}) {
  const [locSeriesOptions, setLocSeriesOptions] = useState([]);
  const [departureStOptions, setDepartureStOptions] = useState([]);
  const [arrivalOptions, setArrivalOptions] = useState([]);
  const [depotOptions, setDepotOptions] = useState([]);
  const lbUseOptions = useMemo(() => formOptions(list.lbUse, "name"), []);
  const tractionOptions = useMemo(() => formOptions(list.traction, "name"), []);

  const [filter, setFilter] = useState(init.filter);
  const [values, setValues] = useState(init.values);
  const [showFilters, setShowFilters] = useState(false);

  const mergeFilter = (newFilters) => {
    const mergedFilters = { ...filter, ...newFilters };
    setFilter(mergedFilters);
    return mergedFilters;
  };
  const mergeValues = (newValues) => {
    setValues({ ...values, ...newValues });
  };

  useEffect(() => {
    setDepotOptions(formOptions(depots, "name"));
  }, [depots]);
  useEffect(() => {
    setLocSeriesOptions(formOptions(locSeries, "name"));
  }, [locSeries]);
  useEffect(() => {
    setDepartureStOptions(formOptions(departureSt, "name"));
  }, [departureSt]);
  useEffect(() => {
    setArrivalOptions(formOptions(arrivalSt, "name"));
  }, [arrivalSt]);

  return (
    <div className={styles.jornalContainer}>
      <Button
        className={styles.filterButton}
        onClick={() => setShowFilters(!showFilters)}
      >
        Фильтры
      </Button>
      {showFilters && (
        <div className={styles.filterContainer}>
          <div
            className={styles.filtersClose}
            onClick={() => setShowFilters(!showFilters)}
          >
            ❌
          </div>
          <div>
            <label style={{ fontWeight: "bold" }}>По предприятию</label>
            <DelayedSearch
              placeholder={"Обязательное поле"}
              options={depotOptions}
              loading={depotsLoading}
              minCharacters={stMinChar}
              delay={stSearchDelay}
              typeEnd={depotsTypeEnd}
              value={values.depot}
              onChange={(e, { value: index }) => {
                const nextFilter = mergeFilter({ depots: [depots[index].id] });
                mergeValues({ depot: index });
                onChange(nextFilter);
              }}
            />
          </div>
          <div>
            <label>Дата, от</label>
            <DateInput
              className={styles.dateInput}
              name={"date"}
              /*placeholder={'Дата, от'}*/
              iconPosition={"left"}
              dateFormat={"DD.MM.YYYY"}
              value={values.dateFrom}
              onChange={(e, { value: strDate }) => {
                const nextFilter = mergeFilter({
                  dateFrom: moment(strDate, "DD.MM.YYYY"),
                });
                mergeValues({ dateFrom: strDate });
                onChange(nextFilter);
              }}
            />
          </div>

          <div>
            <label>Серия локомотива</label>
            <Dropdown
              multiple
              selection
              loading={locSeriesLoading}
              options={locSeriesOptions}
              value={values.locSeries}
              onChange={(e, { value: indexes }) => {
                const nextFilter = mergeFilter({
                  locSeries: indexes.map((index) => locSeries[index].id),
                });
                mergeValues({ locSeries: indexes });
                onChange(nextFilter);
              }}
            />
          </div>

          <div>
            <label>Вид использования ЛБ</label>
            <Dropdown
              multiple
              selection
              options={lbUseOptions}
              value={values.lbUse}
              onChange={(e, { value: indexes }) => {
                const nextFilter = mergeFilter({
                  lbUse: indexes.map((index) => list.lbUse[index].id),
                });
                mergeValues({ lbUse: indexes });
                onChange(nextFilter);
              }}
            />
          </div>
          <div>
            <label>Вид тяги</label>
            <Dropdown
              multiple
              selection
              options={tractionOptions}
              value={values.traction}
              onChange={(e, { value: indexes }) => {
                const nextFilter = mergeFilter({
                  traction: indexes.map((index) => list.traction[index].id),
                });
                mergeValues({ traction: indexes });
                onChange(nextFilter);
              }}
            />
          </div>
          <div>
            <label>Станция отправления</label>
            <DelayedSearch
              /*placeholder={'Станция отправления'}*/
              options={departureStOptions}
              loading={departureStLoading}
              minCharacters={stMinChar}
              delay={stSearchDelay}
              typeEnd={departureStTypeEnd}
              value={values.departureSt}
              onChange={(e, { value: index }) => {
                const nextFilter = mergeFilter({
                  departureSt: [departureSt[index].id],
                });
                mergeValues({ departureSt: index });
                onChange(nextFilter);
              }}
            />
          </div>
          <div>
            <label>Станция прибытия</label>
            <DelayedSearch
              /*placeholder={'Станция прибытия'}*/
              options={arrivalOptions}
              loading={arrivalStLoading}
              minCharacters={stMinChar}
              delay={stSearchDelay}
              typeEnd={arrivalStTypeEnd}
              value={values.arrivalSt}
              onChange={(e, { value: index }) => {
                const nextFilter = mergeFilter({
                  arrivalSt: [arrivalSt[index].id],
                });
                mergeValues({ arrivalSt: index });
                onChange(nextFilter);
              }}
            />
          </div>
          <div className={styles.buttonContainer}>
            <Button
              basic
              icon={"erase"}
              onClick={() => {
                setFilter(init.filter);
                setValues(init.values);
                onChange(init.filter);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
JournalFilter.defaultProps = {
  onChange: () => {},
  locSeriesLoading: false,
  stMinChar: 3,
  stSearchDelay: 1000,
};
const init = {
  filter: {
    locSeries: [],
    arrivalSt: [],
    departureSt: [],
    lbUse: [],
    traction: [],
    depots: [],
    dateFrom: undefined,
  },
  values: {
    locSeries: [],
    arrivalSt: null,
    departureSt: null,
    lbUse: [],
    traction: [],
    depot: null,
    dateFrom: "",
  },
};
const list = {
  traction: [
    {
      name: "Электровозы",
      id: 1,
    },
    {
      name: "Тепловозы",
      id: 2,
    },
  ],
  lbUse: [
    {
      name: "Граф груз п",
      id: 1,
    },
    {
      name: "Сб груз п",
      id: 2,
    },
    {
      name: "Сдв груз п",
      id: 3,
    },
    {
      name: "Груз п",
      id: 4,
    },
    {
      name: "Граф пасс п",
      id: 5,
    },
    {
      name: "Пасс п",
      id: 6,
    },
    {
      name: "Приг лок т",
      id: 7,
    },
    {
      name: "Приг",
      id: 8,
    },
    {
      name: "Хоз и раб/Технич п",
      id: 9,
    },
    {
      name: "Передат/Вывод",
      id: 10,
    },
    {
      name: "Толкач",
      id: 11,
    },
    {
      name: "Маневр раб",
      id: 12,
    },
    {
      name: "Пут маш",
      id: 13,
    },
    {
      name: "СМ",
      id: 14,
    },
    {
      name: "Снег техн",
      id: 15,
    },
    {
      name: "Груз дл/тяж",
      id: 16,
    },
    {
      name: "Резервом",
      id: 100,
    },
    {
      name: "Пассаж",
      id: 101,
    },
    {
      name: "Под депо",
      id: 102,
    },
    {
      name: "На вызове",
      id: 103,
    },
    {
      name: "Сопровождение/Пересылка",
      id: 104,
    },
    {
      name: "В одно лицо",
      id: 200,
    },
    {
      name: "Прогрев",
      id: 203,
    },
  ],
};
