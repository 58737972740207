import React, { useState } from "react";
import { Button, Dropdown, Input, Modal } from "semantic-ui-react";
import { message } from "antd";
import ekasut from "../../api/ekasut";
import useFileDownload from "../../hooks/useFileDownload";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { DropzoneDialogBase } from "material-ui-dropzone";

export const AddLocoModal = ({ visible, onClose, series, getData, data }) => {
  const [seriesSelected, setSeriesSelected] = useState();
  const [errSeries, setErrSeries] = useState(false);

  const [numLoco, setNumLoco] = useState();
  const [errNum, setErrNum] = useState(false);

  const [secLoco, setSecLoco] = useState();
  const [errSec, setErrSec] = useState(false);

  const [unscheduledRepairs, setUnscheduledRepairs] = useState();
  const [errUnscheduledRepairs, setErrUnscheduledRepairs] = useState(false);

  const [firstCategory, setFirstCategory] = useState();
  const [errFirstCategory, setErrFirstCategory] = useState(false);

  const [numbersOfFailure, setNumbersOfFailure] = useState();
  const [errNumbersOfFailure, setErrNumbersOfFailure] = useState(false);

  const secLocoOption = [
    {
      text: 0,
      value: 0,
    },
    {
      text: 1,
      value: 1,
    },
    {
      text: 2,
      value: 2,
    },
    {
      text: 3,
      value: 3,
    },
    {
      text: 4,
      value: 4,
    },
  ];

  const addLoco = () => {
    console.log(fileId);
    if (seriesSelected && numLoco && secLoco >= 0) {
      ekasut.oldReqs
        .addSick({
          locSer: seriesSelected,
          locNum: Number(numLoco),
          locSec: secLoco,
          neplanCount: unscheduledRepairs,
          kind1Count: firstCategory,
          kasantCount: numbersOfFailure,
          fileId: fileId,
        })
        .then((r) => {
          if (r.status === 200) {
            message.error(r.data);
            message.error("Неизвестная ошибка!");
          } else if (r.status === 201) {
            message.success("Локомотив успешно добавлен!");
            getData();
            onClose();
          } else {
            message.error("Неизвестная ошибка!");
          }
        })
        .catch((err) => {
          message.error(err.message);
        });
    } else {
      if (!seriesSelected) {
        setErrSeries(true);
      }
      if (!numLoco) {
        setErrNum(true);
      }
      if (secLoco < 0) {
        setErrSec(true);
      }
    }
  };

  const [open, setOpen] = React.useState(false);
  const [fileObject, setFileObject] = useState([]);
  const [download, InputDownload] = useFileDownload();
  const [loader, setLoader] = useState(false);
  const [fileId, setFileId] = useState(null);

  const downloadFile = async (file) => {
    console.log(file);
    try {
      const fileId = await ekasut.file.postFile(file[0].file);
      setFileId(fileId?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const saveFile = async (fileId, name) => {
    setLoader(true);
    const res = await ekasut.file.getFile(fileId);
    download(res?.data, name);
    setLoader(false);
  };

  const dialogTitle = () => (
    <>
      <span>Загрузка файла</span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <Modal
      open={visible}
      onClose={() => onClose()}
      closeOnDimmerClick={false}
      closeIcon
      size={"small"}
    >
      <Modal.Header style={{ textAlign: "center" }}>
        Добавление локомотива в группу риска
      </Modal.Header>
      <Modal.Content style={{ textAlign: "center" }}>
        <div style={{ marginBottom: 15 }}>
          <label
            style={{ marginRight: 15, width: 130, display: "inline-block" }}
          >
            Серия локомотива
          </label>
          <Dropdown
            placeholder="Серия локомотива"
            search
            selection
            onFocus={() => setErrSeries(false)}
            error={errSeries}
            options={series}
            value={seriesSelected}
            onChange={(e, { value }) => setSeriesSelected(value)}
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <label
            style={{ marginRight: 15, width: 130, display: "inline-block" }}
          >
            Номер локомотива
          </label>
          <Input
            style={{ width: 194 }}
            value={numLoco}
            error={errNum}
            onFocus={() => setErrNum(false)}
            placeholder="Номер локомотива"
            onChange={(e, data) => {
              setNumLoco(data.value.replace(/\D+/g, ""));
            }}
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <label
            style={{ marginRight: 15, width: 130, display: "inline-block" }}
          >
            Секция локомотива
          </label>
          <Dropdown
            error={errSec}
            onFocus={() => setErrSec(false)}
            placeholder="Секция локомотива"
            selection
            options={secLocoOption}
            value={secLoco}
            onChange={(e, { value }) => setSecLoco(value)}
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <label
            style={{ marginRight: 15, width: 130, display: "inline-block" }}
          >
            Кол-во НР
          </label>
          <Input
            style={{ width: 194 }}
            value={unscheduledRepairs}
            error={errUnscheduledRepairs}
            onFocus={() => setErrUnscheduledRepairs(false)}
            placeholder="Кол-во НР"
            onChange={(e, data) => {
              setUnscheduledRepairs(data.value.replace(/\D+/g, ""));
            }}
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <label
            style={{ marginRight: 15, width: 130, display: "inline-block" }}
          >
            Количество отказов 1 категории
          </label>
          <Input
            style={{ width: 194 }}
            value={firstCategory}
            error={errFirstCategory}
            onFocus={() => setErrFirstCategory(false)}
            placeholder="Количество отказов 1 категории"
            onChange={(e, data) => {
              setFirstCategory(data.value.replace(/\D+/g, ""));
            }}
          />
        </div>
        <div>
          <label
            style={{ marginRight: 15, width: 130, display: "inline-block" }}
          >
            Количество отказов
          </label>
          <Input
            style={{ width: 194 }}
            value={numbersOfFailure}
            error={errNumbersOfFailure}
            onFocus={() => setErrNumbersOfFailure(false)}
            placeholder="Количество отказов"
            onChange={(e, data) => {
              setNumbersOfFailure(data.value.replace(/\D+/g, ""));
            }}
          />
        </div>

        <div className="file-navigation" style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Загрузить файл
          </Button>
          <InputDownload />
          {data[0]?.fileName ? (
            <Button
              key={fileObject.fileId}
              disabled={loader}
              className="save-btn"
              style={{ textTransform: "none", marginRight: 10 }}
              color="primary"
              size="small"
              download={fileObject.fileName}
              onClick={() => {
                saveFile(data[0].fileId, data[0].fileName);
              }}
              target={"_blank"}
              startIcon={<SaveIcon />}
            >
              {loader ? "Загрузка ..." : "Прикрепленный файл"}{" "}
              {data[0].fileName}
            </Button>
          ) : (
            <span></span>
          )}
          <DropzoneDialogBase
            dialogTitle={dialogTitle()}
            fileObjects={fileObject}
            cancelButtonText={"Отменить"}
            submitButtonText={"Сохранить"}
            filesLimit={1}
            getFileAddedMessage={(fileName) => {
              return `Файл ${fileName} успешно загружен`;
            }}
            getFileRemovedMessage={(fileName) => {
              return `Файл ${fileName} успешно удален`;
            }}
            useChipsForPreview
            dropzoneText={"Перетащите файл или кликните сюда"}
            maxFileSize={5000000}
            open={open}
            onAdd={(newFileObj) => {
              console.log("onAdd", newFileObj);
              setFileObject(newFileObj);
            }}
            onDelete={(deleteFileObj) => {
              console.log("onDelete", deleteFileObj);
            }}
            onClose={() => setOpen(false)}
            onSave={() => {
              console.log("onSave", fileObject);
              setOpen(false);
              downloadFile(fileObject);
            }}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>Отмена</Button>
        <Button primary onClick={addLoco}>
          Добавить
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
