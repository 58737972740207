import React from 'react';
import {
    Button,
    Checkbox,
    Dimmer,
    Form,
    Loader,
    Message, Popup,
    Radio,
    Segment,
    Table
} from "semantic-ui-react";
import LabeledDropdown from "../../../shared/LabeledDropdown";
import LabeledDateInput from "../../../shared/LabeledDateInput";
import {observer, useLocalStore} from "mobx-react-lite";
import LabeledText from "../../../shared/LabeledText";
import moment from 'moment'
import {formOptions, getValuesByIndexes} from "../../../helpers/utils";
import LabeledTimeInput from "../../../shared/LabeledTimeInput";
import LabeledInput from "../../../shared/LabeledInput";
import TableRowsLoader from "../../../shared/TableRowsLoader";
import appStyles from '../../../css/App.module.scss'
import styles from '../OperativePlan/components/AddRepair.module.scss'
import cn from 'classnames'
import {observable} from 'mobx'

const AddRepair = observer((
    {
        sld, tche, initialDate, minDate, maxDate, repairTypes, repairTypesLoading,
        series, seriesLoading, searchLocomotives, locomotives, locomotivesLoading,
        addToPlan, addToPlanLoading, restrictSelectedDate
    }
) => {
    const ls = useLocalStore(() => ({
        date: moment(initialDate),
        repairType: {id: null, text: "[ Тип ремонта не задан ]"},
        checkedLocomotives: observable.map(),
        sectionCount: null,
        selectedSeries: {id: null}
    }))

    return (
        <>
            <Dimmer active={addToPlanLoading} className={styles.formLoader} inverted>
                <Loader>Добавление локомотивов в план</Loader>
            </Dimmer>
            <Form>
                <Form.Group>
                    <Form.Field>
                        <LabeledText
                            label="Место проведения"
                            text={sld.name}
                            withoutContainer
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field>
                        <LabeledDateInput
                            label="Дата"
                            iconPosition="left"
                            closable
                            minDate={minDate}
                            maxDate={maxDate}
                            date={ls.date}
                            onChange={(date) => {
                                date.hours(ls.date.hours())
                                date.minutes(ls.date.minutes())
                                ls.date = date
                            }}
                            withoutContainer
                        />

                    </Form.Field>
                    <Form.Field>
                        <Popup
                            content={restrictSelectedDate ? "Время постановки локомотива в план не может быть меньше текущего времени" : null}
                            // fixme: prop "disabled" doesn't work in semantic 0.79.1
                            disabled={true}
                            trigger={
                                <LabeledTimeInput
                                    className={cn(restrictSelectedDate && ls.date.isBefore(moment()) && styles.incorrectTime)}
                                    label="Время постановки"
                                    withoutContainer
                                    time={{h: ls.date.hours(), m: ls.date.minutes()}}
                                    onChange={({h, m}) => {
                                        ls.date = moment(ls.date).hours(h).minutes(m)
                                    }}
                                />
                            }
                        />
                    </Form.Field>
                    <Form.Field>
                        <LabeledDropdown
                            label="Вид ремонта"
                            loading={repairTypesLoading}
                            style={{zIndex: 1003}}
                            options={formOptions(repairTypes, "text")}
                            onChange={(e, {value}) => {
                                if ((repairTypes[value].id === TO2_ID && ls.repairType.id !== TO2_ID) ||
                                    (repairTypes[value].id !== TO2_ID && ls.repairType.id === TO2_ID)) {
                                    ls.selectedSeries = {id: null}
                                    ls.checkedLocomotives.clear()
                                    ls.sectionCount = null
                                }
                                ls.repairType = repairTypes[value]

                            }}
                            withoutContainer
                        />
                    </Form.Field>
                </Form.Group>
                {ls.repairType.id === null && (
                    <Message content="Выберите тип ремонта"/>
                )}
                {ls.repairType.id !== null && (
                    <Segment>
                        {ls.repairType.id !== TO2_ID && (
                            <LocomotiveCheckList
                                store={ls}
                                sld={sld}
                                tche={tche}
                                series={series}
                                seriesLoading={seriesLoading}
                                locomotives={locomotives}
                                searchLocomotives={searchLocomotives}
                                locomotivesLoading={locomotivesLoading}
                            />
                        )}
                        {ls.repairType.id === TO2_ID && (
                            <LocomotiveSections
                                store={ls}
                                series={series}
                                seriesLoading={seriesLoading}
                            />
                        )}
                    </Segment>
                )}
                <div style={{textAlign: "right"}}>
                    <AddToPlanButton
                        store={ls}
                        restrictSelectedDate={restrictSelectedDate}
                        addToPlan={addToPlan}
                        tche={tche}
                        sld={sld}
                        date={ls.date}
                        addToPlanLoading={addToPlanLoading}
                    />
                </div>
            </Form>
        </>
    );
})
AddRepair.defaultProps = {
    initialDate: moment(),
    minDate: moment(),
    maxDate: moment().add(7, "days"),
    series: [],
    repairTypes: [],
    seriesLoading: false,
    repairTypesLoading: false,
    restrictSelectedDate: true
}

const TO2_ID = 15
const SECTIONS_COUNT_MAX = 4


const AddToPlanButton = observer((
    {store, addToPlan, tche, sld, addToPlanLoading, date, restrictSelectedDate}
) => {
    return (
        <Button
            content="Добавить в план"
            loading={addToPlanLoading}
            disabled={
                (addToPlanLoading || store.repairType.id === null ||
                    (restrictSelectedDate && store.date.isBefore(moment()))) ||
                (store.repairType.id !== TO2_ID ?
                        store.checkedLocomotives.size === 0 :
                        (store.sectionCount === null || store.selectedSeries.id === null)
                )
            }
            onClick={() => {
                addToPlan(tche, sld, Array.from(store.checkedLocomotives.values()),
                    store.repairType, store.selectedSeries, store.sectionCount, store.date)
            }}
        />
    )
})

const LocomotiveSections = observer(({store, series, seriesLoading}) => {
    const createArr = (from, max) => {
        const arr = []
        for (let i = from; i <= max; i++) {
            arr.push(i)
        }
        return arr
    }

    return (
        <Form.Group widths='equal'>
            <Form.Field>
                <LabeledDropdown
                    withoutContainer
                    label="Количество секций"
                    options={formOptions(createArr(1, SECTIONS_COUNT_MAX))}
                    onChange={(e, {value}) => {
                        store.sectionCount = value + 1
                    }}
                />
            </Form.Field>
            <Form.Field>
                <LabeledDropdown
                    label="Серия"
                    fluid search selection
                    withoutContainer
                    loading={seriesLoading}
                    options={formOptions(series, "text")}
                    onChange={(e, {value}) => {
                        store.selectedSeries = series[value]
                    }}
                />
            </Form.Field>
        </Form.Group>
    )
})

const LocomotiveCheckList = observer((
    {
        tche, sld, series, seriesLoading, searchLocomotives,
        locomotives, locomotivesLoading, store
    }
) => {

    const ls = useLocalStore(() => ({
        isAllPark: false,
        selectedSeries: [],
        locNumber: undefined,
        showOnlyCheckedLocomotives: false,
        findProps: {
            series: [],
            tche: {id: null},
            isAllPark: false,
            wasSearch: false
        }
    }))

    return (
        <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <Radio
                        label={tche.name}
                        name='radioGroup'
                        checked={!ls.isAllPark}
                        // triggered only by user action
                        onChange={() => {
                            ls.isAllPark = false
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label='Весь парк'
                        name='radioGroup'
                        checked={ls.isAllPark}
                        onChange={() => {
                            ls.isAllPark = true
                        }}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <LabeledDropdown
                        label="Серия"
                        fluid multiple search selection
                        /* More than at the table headers below */
                        style={{zIndex: 1003}}
                        withoutContainer
                        options={formOptions(series, "text")}
                        loading={seriesLoading}
                        onChange={(e, {value}) => {
                            ls.selectedSeries = getValuesByIndexes(series, value)
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <LabeledInput
                        label="№ локомотива"
                        withoutContainer
                        onChange={(e, {value}) => {
                            ls.locNumber = value
                        }}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        style={{marginTop: 24}}
                        content="Найти"
                        loading={locomotivesLoading}
                        disabled={locomotivesLoading}
                        onClick={() => {
                            ls.findProps = {
                                series: [...ls.selectedSeries],
                                wasSearch: true,
                                isAllPark: ls.isAllPark,
                                locNumber: ls.locNumber,
                                tche: {...tche}
                            }
                            searchLocomotives(
                                ls.selectedSeries.map(it => it.id),
                                ls.locNumber,
                                undefined,
                                ls.isAllPark ? undefined : tche.id
                            )
                        }}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    {!ls.findProps.wasSearch && <Message content="Поиск локомотивов"/>}
                    {ls.findProps.wasSearch && (
                        <>
                            {/* todo: table should be able to display only checked locomotives */}
                            {/*<Radio
                                style={{marginTop: 10, marginBottom: 10}}
                                toggle
                                label="Отобразить только выбранные локомотивы"
                                checked={ls.showOnlyCheckedLocomotives}
                                onChange={(e, {checked}) => {
                                    ls.showOnlyCheckedLocomotives = checked
                                }}
                            />*/}
                            <LocomotiveListHeader {...ls.findProps} style={{marginBottom: 5}}/>
                            <div style={{maxHeight: 360, overflowY: "auto"}}>
                                <Table celled unstackable className={appStyles.tableStickyHeader}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell/>
                                            <Table.HeaderCell>Дорога приписки</Table.HeaderCell>
                                            <Table.HeaderCell>Депо приписки</Table.HeaderCell>
                                            <Table.HeaderCell>Серия</Table.HeaderCell>
                                            <Table.HeaderCell>Номер</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <TableRowsLoader
                                            messageIfEmpty={"По заданным критериям локомотивы не найдены"}
                                            messageIfLoading="Загрузка списка локомотивов"
                                            loading={locomotivesLoading}
                                            colSpan={5}
                                        >
                                            {locomotives.map((locomotive, index) => {
                                                return (
                                                    <Table.Row key={index}>
                                                        <Table.Cell collapsing>
                                                            <Checkbox
                                                                checked={store.checkedLocomotives.has(index)}
                                                                onChange={() => {
                                                                    if (store.checkedLocomotives.has(index)) {
                                                                        store.checkedLocomotives.delete(index)
                                                                    } else {
                                                                        store.checkedLocomotives.set(index, locomotives[index])
                                                                    }
                                                                }}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell>{locomotive.dorPripName}</Table.Cell>
                                                        <Table.Cell>{locomotive.depoPrip}</Table.Cell>
                                                        <Table.Cell>{locomotive.serName}</Table.Cell>
                                                        <Table.Cell>{locomotive.znsLoc} {locomotive.prsCharLoc}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </TableRowsLoader>
                                    </Table.Body>
                                </Table>
                            </div>
                        </>
                    )}
                </Form.Field>
            </Form.Group>
        </>
    )
})

const LocomotiveListHeader = ({isAllPark, tche, series, locNumber, style}) => {
    let text
    if (isAllPark) {
        text = "По всему парку"
    } else {
        text = tche.name
    }
    if (locNumber !== undefined && locNumber !== "") {
        text += `, № ${locNumber}`
    }
    if (series.length !== 0) {
        text += ` (${series.map(i => i.text).join(', ')})`
    }

    return <div style={style}>{text}</div>
}

export default AddRepair;