import ekasut from "../../../api/ekasut";
import {addFetchFunctional, getFullName, momentIfDateElseNull} from "../../../helpers/utils";
import moment from "moment";

export const FETCH_STATUS = {
    idle: "idle",
    loading: "loading",
    success: "success",
    error: "error"
}

export function createStore() {
    return {
        year: moment().year(),
        month: moment().month(),
        setCalendarMonth(y, m) {
            this.year = y
            this.month = m
        },
        ...addFetchFunctional("events", ekasut.tchmi.getPlanDetail, planDetailParser, null),
    }
}

const planDetailParser = ({plan}) => {
    const events = {}
    plan.forEach(p => {
        const event = events[p.actionId]
        if (event === undefined) {
            events[p.actionId] = {
                id: p.actionId,
                type: p.actionType,
                name: p.actionTypeStr,
                beginDate: momentIfDateElseNull(p.beginDate),
                endDate: momentIfDateElseNull(p.endDate),
                orderDate: momentIfDateElseNull(p.orderDate),
                orderText: p.orderText,
                rd: p.rd,
                driverAsutrId: p.persAsutrId,
                driverCol: p.persColnum,
                driverFirstName: p.persFirstName,
                driverId: p.persId,
                driverLastName: p.persLastName,
                driverPatrName: p.persPatrName,
                driverPost: p.persPostStr,
                driverFullName: getFullName(p.persFirstName, p.persLastName, p.persPatrName),
                roadId: p.roadId,
                depotId: p.depotId,
                beginStName: p.stBeginStr,
                endStName: p.stEndStr,
                instructorAsutrId: p.tchmiAsutrId,
                isApproved: p.zachet,
                comment: p.theme,
                planDate: moment(p.planDate),
                cols: p.cols,
            }
        } else {
            console.error("Duplicate event: ", event, p)
        }
    })
    return events
}