import React, {useEffect, useState, useCallback, useReducer} from 'react'
import './NepLayout.scss'
import locomotiveServices from "../LocomotiveServices";
import {useZoomLevel, zoomLevels} from "../ZoomLevel";
import ekasut from "../../../api/ekasut";
import {Checkbox, Container, Segment} from "semantic-ui-react";
import FleetStats from "../FleetStats";
import TractionFilterSelector from "../TractionFilterSelector";
import ComponentMarker from "../../../shared/ComponentMarker";
import DashboardChart from "./DashboardChart";
import BarSetWithPopup from "../../../shared/BarSetWithPopup";
import TableForPopup from "../TableForPopup";
import CollapsibleWrap from "../../../shared/CollapsibleWrap";
import BackArrowButton from "../../../shared/BackArrowButton";
import RepairWaitChart from "./RepairWaitChart";
import RepairChart from "./RepairChart";
import SearchCheckboxList from "../../../shared/SearchCheckboxList";
import StaticControl from "../../../shared/StaticControl";
import DashboardLoading from "../DashboardLoading";
import AppModal from "../../../shared/AppModal";
import {useLocation, useHistory} from 'react-router-dom'
import ModalLocURL from "../ModalLocUrl";
import {observer} from "mobx-react-lite";
import useDidFirstRender from "../../../hooks/useDidFirstRender";
import catchNetworkErrors from "../../../api/catchNetworkErrors";

const selectPoints = (zoomLevel, roads, depots) => {
    return zoomLevel === zoomLevels.ROADS ? roads : depots
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'update-points': {
            const {roads, depots} = action
            const points = selectPoints(state.zoomLevel, roads, depots)
            return {...state, points, roads, depots}
        }
        case 'set-zoom-level': {
            const {zoomLevel} = action
            const points = selectPoints(zoomLevel, state.roads, state.depots)
            return {...state, zoomLevel, points}
        }
        case 'set-traction': {
            const {traction} = action
            return {...state, traction}
        }
        case 'set-loc-type': {
            const {locType} = action
            return {...state, locType}
        }
        case 'set-rzd-tree': {
            const {rzdTree} = action
            return {...state, rzdTree}
        }
        case 'set-locomotives': {
            const {locomotives} = action
            return {...state, locomotives}
        }
        default: {
            throw new Error()
        }
    }
}

const actions = {
    updatePoints: (roads, depots) => ({type: 'update-points', roads, depots}),
    setZoomLevel: (zoomLevel) => ({type: 'set-zoom-level', zoomLevel}),
    setLocType: (locType) => ({type: 'set-loc-type', locType}),
    setTraction: (traction) => ({type: 'set-traction', traction}),
    setRzdTree: (rzdTree) => ({type: 'set-rzd-tree', rzdTree}),
    setLocomotives: (locomotives) => ({type: 'set-locomotives', locomotives})
}

const initRoads = []
const initDepots = []
const initState = {
    roads: initRoads,
    depots: initDepots,
    // put new array here '[]' will cause unnecessary state changes at first
    points: selectPoints(zoomLevels.ROADS, initRoads, initDepots),
    traction: {ac: true, dc: true, ice: true},
    locType: locomotiveServices.FREIGHT,
    rzdTree: [],
    locomotives: []
}

const NepLayout = observer(({zoom, stats, onLoad, onLoaded}) => {
    const [state, dispatch] = useReducer(reducer, initState)
    const zoomLevel = useZoomLevel(zoom, 6)
    const [markers, setMarkers] = useState(null)
    const [isCollapse, setIsCollapse] = useState(false)

    const [dashboardOpenColumns, setDashboardOpenColumns] = useState([])
    const [dashboardShowLocomotives, setDashboardShowLocomotives] = useState(false)
    const [locomotiveOptions, setLocomotiveOptions] = useState([])
    const [selectedLocomotives, setSelectedLocomotives] = useState([])
    const [showRepairWait, setShowRepairWait] = useState(false)
    const [showRepairChart, setShowRepairChart] = useState(false)
    const [selectedPoint, setSelectedPoint] = useState({name: null, id: null})
    const [waitChartLoading, setWaitChartLoading] = useState(true)
    const [waitChartData, setWaitChartData] = useState(null)
    const [repairChartLoading, setRepairChartLoading] = useState(true)
    const [repairChartData, setRepairChartData] = useState(null)
    const [repairChartVisibilities, setRepairChartVisibilities] = useState([])
    const [dashboardLoading, setDashboardLoading] = useState(true)

    const location = useLocation()
    const history = useHistory()

    const {traction, points, locType, rzdTree, locomotives} = state

    useEffect(() => {
        dispatch(actions.setZoomLevel(zoomLevel))
    }, [zoomLevel])

    useDidFirstRender(() => {
        onLoad()
        Promise.all([
            loadPoints(traction, locType),
            loadDashboardChart(traction, locType)
        ]).finally(onLoaded)
    })

    const loadPoints = (traction, locType) => {
        if (isEachTractionUnselect(traction) && (locType !== locomotiveServices.SWITCHING)) {
            setDashboardLoading(true)
            return Promise.resolve()
        }
        const nepAnalyticsApi = getNepAnalyticsApi(locType)

        return Promise.all([
            nepAnalyticsApi.getRoad(null, traction),
            nepAnalyticsApi.getDepot(null, traction),
        ]).then(([roadsRes, depotsRes]) => {
            const roads = unifyRoads(roadsRes.data)
            const depots = unifyDepots(depotsRes.data)
            dispatch(actions.updatePoints(roads, depots))
        }).catch(catchNetworkErrors)
    }

    // Load rzd tree
    const loadDashboardChart = () => {
        dispatch(actions.setRzdTree([]))
        setSelectedLocomotives([])
        const tractionUnselect = isEachTractionUnselect(traction)

        setDashboardLoading(true)
        const nepAnalyticsApi = getNepAnalyticsApi(locType)
        const allTraction = {ac: true, ice: true, dc: true}


        return Promise.all([
            tractionUnselect && (locType !== locomotiveServices.SWITCHING) ? undefined : nepAnalyticsApi.getRzd(traction),
            nepAnalyticsApi.getRzd(allTraction)
        ]).then((results) => {
            setDashboardLoading(false)
            if (!tractionUnselect || (locType === locomotiveServices.SWITCHING)) {
                dispatch(actions.setRzdTree(results[0].data.byDirections))
            }
            dispatch(actions.setLocomotives(results[1].data.byUkrGrupSer))
        }).catch((err) => {
            setDashboardLoading(false)
            return catchNetworkErrors(err)
        })

    }


    // Reset checked locomotives
    useEffect(() => {
        setLocomotiveOptions([])
    }, [locType])

    // Update options for locomotive list
    useEffect(() => {
        setLocomotiveOptions(
            locomotives.map((item) => ({
                key: item.kodUkrGrupSer,
                value: item.kodUkrGrupSer,
                text: item.nameGrupSer.trim()
            }))
        )
    }, [locomotives])

    // Load data for repair chart
    useEffect(() => {
        if (showRepairChart) {
            setRepairChartLoading(true)
            const nepAnalyticsApi = getNepAnalyticsApi(locType)
            let getGraph
            if (selectedPoint.dorPrip !== undefined) {
                getGraph = nepAnalyticsApi.getRoadGraph
            } else {
                getGraph = nepAnalyticsApi.getDepotGraph
            }
            getGraph(selectedPoint.id, traction)
                .then(({data}) => {
                    setRepairChartLoading(false)
                    if (data.responseStatus === 204) {
                        throw new Error(data.message)
                    }
                    setRepairChartData(data)
                })
                .catch((error) => {
                    setShowRepairChart(false)
                    return catchNetworkErrors(error)
                })
        }
    }, [showRepairChart, locType, traction, selectedPoint])

    // Load locomotive wait column chart
    useEffect(() => {
        if (showRepairWait) {
            setWaitChartLoading(true)
            const nepAnalyticsApi = getNepAnalyticsApi(locType)
            let getDetail
            if (selectedPoint.dorPrip !== undefined) {
                getDetail = nepAnalyticsApi.getRoadWaitDetail
            } else {
                getDetail = nepAnalyticsApi.getDepotWaitDetail
            }
            getDetail(selectedPoint.id, traction)
                .then(({data}) => {
                    setWaitChartData(data)
                    setWaitChartLoading(false)
                })
                .catch((error) => {
                    setShowRepairWait(false)
                    return catchNetworkErrors(error)
                })
        }
    }, [showRepairWait, locType, selectedPoint, traction])

    // Update markers
    useEffect(() => {
        let newMarkers = []
        points.forEach((point) => {
            const props = createBarSetProps(
                point,
                () => {
                    setSelectedPoint(point)
                    setRepairChartVisibilities([true, true, false])
                    setShowRepairChart(true)
                },
                () => {
                    setSelectedPoint(point)
                    setRepairChartVisibilities([true, true, true])
                    setShowRepairChart(true)
                },
                () => {
                    setSelectedPoint(point)
                    setShowRepairWait(true)
                }
            )
            newMarkers.push(
                <ComponentMarker
                    key={point.id}
                    position={[point.lat, point.lon]}
                >
                    <BarSetWithPopup
                        barsProps={props}
                    />
                </ComponentMarker>
            )
        })
        setMarkers(newMarkers)
    }, [points])

    const getTitle = () => {
        return [...['По всей сети'], ...dashboardOpenColumns.map((col) => col.name)].join(' > ')
    }

    const openDashboardColumn = useCallback((branch, index, name) => {
        const nextOpenColumns = [...dashboardOpenColumns]
        nextOpenColumns.push({
            name,
            index
        })
        setDashboardOpenColumns(nextOpenColumns)

    }, [dashboardOpenColumns])

    const backDashboardColumn = useCallback(() => {
        const nextOpenColumns = [...dashboardOpenColumns]
        nextOpenColumns.pop()
        setDashboardOpenColumns(nextOpenColumns)
    }, [dashboardOpenColumns])

    const handleCheckedLocomotive = (index, checked) => {
        const newLocomotiveOptions = [...locomotiveOptions]
        newLocomotiveOptions[index].checked = checked
        setLocomotiveOptions(newLocomotiveOptions)
        // locomotiveOptions and locomotives are sync
        setSelectedLocomotives(
            locomotives.filter((loc, index) => newLocomotiveOptions[index].checked)
        )
    }

    useEffect(() => {
        setDashboardOpenColumns([])
    }, [traction])

    return (<>
        <StaticControl position='topright'>
            <Segment className='half-transparent-background pr-nep-layout-control'>
                <FleetStats
                    stats={stats}
                    service={locType}
                    serviceOnChange={(locType) => {
                        cancelAll()
                        dispatch(actions.setLocType(locType))
                        setMarkers([])
                        setDashboardOpenColumns([])
                        onLoad()
                        Promise.all([
                            loadPoints(traction, locType),
                            loadDashboardChart(traction, locType)
                        ]).finally(() => {
                            onLoaded()
                        })
                    }}
                />

                <CollapsibleWrap
                    title={getTitle()}
                    isCollapse={isCollapse}
                    onClick={() => setIsCollapse(!isCollapse)}
                >
                    {dashboardOpenColumns.length === 0 ?
                        <Container textAlign={'right'}>
                            <Checkbox
                                style={{margin: '3.5px 10px'}}
                                toggle
                                label={'По локомотивам'}
                                onChange={(e, {checked}) => setDashboardShowLocomotives(!dashboardShowLocomotives)}
                            />
                        </Container>
                        :
                        <BackArrowButton
                            style={{visibility: (dashboardOpenColumns.length === 0) ? 'hidden' : 'visible'}}
                            onClick={backDashboardColumn}
                        />
                    }
                    <DashboardLoading
                        loading={dashboardLoading}
                    />
                    <DashboardChart
                        traction={traction}
                        rzdTree={rzdTree}
                        locomotives={selectedLocomotives}
                        showLocomotives={dashboardShowLocomotives}
                        openColumns={dashboardOpenColumns.map((openCol) => openCol.index)}
                        columnOnClick={openDashboardColumn}
                    />
                </CollapsibleWrap>

                {(locType !== locomotiveServices.SWITCHING) && (!dashboardShowLocomotives) &&
                <TractionFilterSelector
                    filter={traction}
                    groupElectricLocomotives={false}
                    onChange={(traction) => {
                        cancelAll()
                        dispatch(actions.setTraction(traction))
                        setMarkers([])
                        onLoad()
                        Promise.all([
                            loadPoints(traction, locType),
                            loadDashboardChart(traction, locType)
                        ]).finally(onLoaded)
                    }}
                />
                }
                {dashboardShowLocomotives && !isCollapse &&
                <SearchCheckboxList
                    style={{paddingTop: 20}}
                    height={120}
                    columns={4}
                    options={locomotiveOptions}
                    inputPlaceholder="Выберите наименования групп серий..."
                    holdSelected={true}
                    changeOption={handleCheckedLocomotive}
                />
                }
            </Segment>
        </StaticControl>

        {markers}

        <AppModal
            open={showRepairChart}
            onClose={() => {
                setShowRepairChart(false)
            }}
            loading={repairChartLoading}
        >
            <RepairChart
                pointName={selectedPoint.name}
                pointId={selectedPoint.id}
                data={repairChartData}
                visibilities={repairChartVisibilities}
                locType={locType}
                isRoad={selectedPoint.dorPrip !== undefined}
                onRowClick={detail => {
                    const query = new URLSearchParams()
                    query.set("ser", detail.serLoc)
                    query.set("zns", detail.znsLoc)
                    query.set("prs", detail.prsLoc)
                    history.replace(`${location.pathname}?${query}`)
                }}
            />
        </AppModal>

        <AppModal
            open={showRepairWait}
            onClose={() => {
                setShowRepairWait(false)
            }}
            loading={waitChartLoading}
        >
            <RepairWaitChart
                pointName={selectedPoint.name}
                pointId={selectedPoint.id}
                data={waitChartData}
            />
        </AppModal>
        <ModalLocURL />
    </>)
})

const isEachTractionUnselect = (traction) => {
    return !traction.ac && !traction.ice && !traction.dc
}

const cancelAll = () => {
    ekasut.to32NepAnalytics.cancel()
    ekasut.to38NepAnalytics.cancel()
    ekasut.to36NepAnalytics.cancel()
}

const createBarSetProps = (point, repairOnClick, claimOnClick, waitOnClick) => {
    let barSetProps = []
    let props = {
        filled: true,
        color: 'rgba(105, 191, 255, 0.53)',
        popupClassName: 'pr-nep-layout-progress-bar-popup',
        width: 70,
    }
    let nepProps = {
        ...props,
        valueOnClick: claimOnClick,
        value: point.reklamKol,
        leftValue: point.nepFact,
        leftValueOnClick: repairOnClick,
        popupContent:
            <TableForPopup
                title={point.name}
                isLoading={false}
                subtitle={''}
                data={[
                    {
                        text: 'Количество НЭП',
                        value: point.nepFact,
                        unit: 'ед.',
                    },
                    {
                        text: 'Из них в НР',
                        value: point.nrKol,
                        unit: 'ед.',
                    },
                    {
                        text: 'Рекламация',
                        value: point.reklamKol,
                        unit: 'ед.',
                    },
                    {
                        text: 'План НЭП',
                        value: point.nepPlan,
                        unit: 'ед.'
                    },
                    {
                        text: 'Сейчас в ожидании',
                        value: point.waitCnt,
                        unit: 'ед.'
                    }
                ]}
            />
    }

    let repairWait = {
        ...props,
        value: point.waitCnt,
        leftValue: 'Ож',
        valueOnClick: waitOnClick,
        popupContent:
            <TableForPopup
                title={point.name}
                isLoading={false}
                subtitle={''}
                data={[
                    {
                        text: 'Сейчас в ожидании',
                        value: point.waitCnt,
                        unit: 'ед.',
                    }
                ]}
            />
    }

    barSetProps.push(nepProps)
    barSetProps.push(repairWait)

    return barSetProps
}

const unifyRoads = (roads) => {
    return roads.map((road) => ({
        ...road,
        id: road.dorPrip,
        name: road.dorName.trim(),
        lat: road.dorLat,
        lon: road.dorLon
    }))
}

const unifyDepots = (depots) => {
    return depots.map((depot) => ({
        ...depot,
        id: depot.depoKod,
        name: depot.depoName.trim(),
        lat: depot.depoLat,
        lon: depot.depoLon
    }))
}

export const getNepAnalyticsApi = (locType) => {
    let apiGroup
    switch (locType) {
        case locomotiveServices.FREIGHT: {
            apiGroup = ekasut.to32NepAnalytics
            break
        }
        case locomotiveServices.PASSENGER: {
            apiGroup = ekasut.to38NepAnalytics
            break
        }
        case locomotiveServices.SWITCHING: {
            apiGroup = ekasut.to36NepAnalytics
            break
        }
        default: {
            throw new Error()
        }
    }
    return apiGroup
}

export default NepLayout