import {useEffect, useState} from 'react'

export const zoomLevels = {
    depot: 'depot',
    road: 'road'
}

const getZoomLevel = (zoom, roadsRange, depotsRange) => {
    if (zoom >= roadsRange[0] && zoom <= roadsRange[1]) {
        return zoomLevels.road
    }
    if (zoom >= depotsRange[0] && zoom <= depotsRange[1]) {
        return zoomLevels.depot
    }
}

export default function useZoomLevel(zoom, defRoadsRange, defDepotsRange) {
    const [depotsRange] = useState(defDepotsRange)
    const [roadsRange] = useState(defRoadsRange)
    const [zoomLevel, setZoomLevel] = useState(getZoomLevel(zoom, defRoadsRange, defDepotsRange))

    useEffect(() => {
        setZoomLevel(getZoomLevel(zoom, roadsRange, depotsRange))
    }, [zoom, depotsRange, roadsRange])

    return [zoomLevel]
}