import {formOptions} from "../../helpers/utils";

const eventTypes = [
    {text: 'Контрольно-инструкторная поездка', id: 2},
    {text: 'Контрольно-заключительная поездка', id: 1},
    {text: 'Целевая поездка', id: 3},
    {text: 'Техническое занятие', id: 5},
    {text: 'Производственное совещание', id: 6},
]
const eventTypeOptions = formOptions(eventTypes, 'text')

export {eventTypes, eventTypeOptions}