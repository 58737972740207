import React from 'react'
import RoadMaintenancePerHour from './RoadMaintenancePerHour';

export default function PassengerPerHour({stats}) {

	const nepCategories = [
		'Деповский ремонт',
		'Неплановый ремонт',
		'Заводской ремонт',
		'Рекламация',
		'Перес, и тех. операции',
		'Ожидание исключения',
	]

	const nrpCategories = [
		'ТО-2, экипировка',
		'Перемещение',
		'Ожидание работы',
		'Приёмка/сдача в депо и на станции',
		'Ожидание более 12 часов'
	]

	const nepData = [
		stats.kol32Diff,
		stats.kol33Diff,
		stats.kol37Diff,
		stats.kol39Diff,
		stats.kol42Diff,
		stats.kol43Diff,
	]

	const nrpData = [
		stats.kol24Diff,
		stats.kol26Diff,
		stats.kol27Diff,
		stats.kol28Diff,
		stats.kol29Diff,
	]

	const nepValue = stats.kol30Diff
	const nrpValue = stats.kol23Diff

	return (
		<RoadMaintenancePerHour
			nepCategories={nepCategories}
			nrpCategories={nrpCategories}
			nepData={nepData}
			nrpData={nrpData}
			nepValue={nepValue}
			nrpValue={nrpValue}
			nepHeight={280}
			nrpHeight={250}
		/>
	)
}
