import React, { useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Grid,
  Header,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import styles from "../../OrganizationInspection.module.scss";
import InputField from "../../../../../shared/fields/InputField";
import moment from "moment";
import DateField from "../../../../../shared/fields/DateField";
import ekasut from "../../../../../api/ekasut";
import {
  Alert,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Tabs,
} from "antd";
import downloadFile from "../../../../GroupRiskLocomotives/downloadFile";

const { Option } = Select;
const { TabPane } = Tabs;

export const Docs = ({
  setAddChanges,
  pred,
  period,
  orderPeople,
  setOrderPeople
}) => {
  const [docsDate, setDocsDate] = useState();
  const [docsNum, setDocsNum] = useState();
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [smegnic, setSmegnic] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [firmSelect, setFirmSelect] = useState(null);
  const [openFile, setOpenFile] = useState(null);
  const [fileUUID, setFileUUID] = useState(null);
  const [repeat, setRepeat] = useState(0);
  const [requestToEdit, setRequestToEdit] = useState(0);
  const [alertShow, setAlertShow] = useState(false);
  const [orderExist, setOrderExist] = useState(false);
  const [division, setDivision] = useState("");
  const [currentKeyForFile, setCurrentKeyForFile] = useState(null);
  const [subscribe, setSubscribe] = useState(0)
  const [isDownload, setIsDownload] = useState(false)
  const [customSub, setCustomSub] = useState(0)

  useEffect(() => {
    let cleanupFunction = false;

    try {
      if (period && pred) {
        ekasut.commisionInspection
          .getDocs({
            pTypeCo: period.typeCO,
            pYear: period.yearCO,
            pFirmExpl: pred?.value ? pred.value : pred,
          })
          .then(({ data }) => {
            // console.log({data})
            if (!cleanupFunction) {
              setDocs(data);
              setLoading(false);
            }
          });

        const checkOrderExist = async () => {
          const response = await ekasut.commisionInspection.getOrderFirm(
            localStorage.getItem("kodFirm"),
            localStorage.getItem("seasonYear"),
            localStorage.getItem("season")
          );
          if (response?.status == 200) {
            
            if (!cleanupFunction) {
              if (response?.data[0]?.actual == 0) {
                localStorage.setItem('isActual', response?.data[0]?.actual)
              } else {
                localStorage.setItem('isActual', 1)
              }

              setOrderNumber(response?.data[0]?.numOrder);
              setOrderDate(moment(response?.data[0]?.dateOrder));
              setFirmSelect(response?.data[0]?.firmRep);
              // setRepeat(response?.data[0]?.isRepeat);
              setDivision(response?.data[0]?.firmRepName);
              setCurrentKeyForFile(response?.data[0]?.fileName);
              setSubscribe(response?.data[0]?.actual)
            }
          }
        };

        const getSmegnic = async () => {
          const response = await ekasut.commisionInspection.getDeposForAddCom(
            localStorage.getItem("kodFirm")
          );
          if (!cleanupFunction) setSmegnic(response.data);
        };

        getSmegnic();

        checkOrderExist();

        // const checkFirmExist = async() => {
        //   const response = await ekasut.commisionInspection.getOrderFirm(localStorage.getItem('kodFirm'), localStorage.getItem('seasonYear'), localStorage.getItem('season'))
        //   response.status == 200 && setOrderExist(true)
        // }
      }
    } catch (error) {
      console.log(error.message);
    }

    return () => (cleanupFunction = true);
  }, [period, pred]);

  const selectFirmChange = (value) => {
    setFirmSelect(value);
  };

  const sendFileToServer = async () => {
    const response = await ekasut.file.postFile(openFile);
    response.status === 200 && setIsDownload(false)
    setCurrentKeyForFile(response?.data);
  };

  useEffect(() => {
    openFile && sendFileToServer()
  }, [openFile])

  const createDatePeriod = async(actual) => {
    const response = await ekasut.commisionInspection.postCOOrder(
      orderNumber,
      orderDate.format("YYYY-MM-DD"),
      localStorage.getItem("kodFirm"),
      firmSelect,
      actual === undefined || actual === 0 ? subscribe : actual,
      currentKeyForFile,
      0,
      localStorage.getItem("seasonYear"),
      localStorage.getItem("season"),
      requestToEdit
    );

    response.status === 200 && getOrder()
  }

  const getOrder = async() => {
    const getResponse = await ekasut.commisionInspection.getOrderFirm(
      localStorage.getItem("kodFirm"),
      localStorage.getItem("seasonYear"),
      localStorage.getItem("season"),
      0
    );
    if (getResponse?.status == 200) {
      setOrderDate(moment(getResponse?.data[0]?.dateOrder));
      setFirmSelect(getResponse?.data[0]?.firmRep);
      // setRepeat(getResponse?.data[0]?.isRepeat);
      setOrderNumber(getResponse?.data[0]?.numOrder);
      setSubscribe(getResponse?.data[0]?.actual)

      setAlertShow(true);
      setTimeout(() => setAlertShow(false), 2000);
    }
  }

  const sendOrderData = async (actual) => {
    openFile && sendFileToServer()

    createDatePeriod(actual)

    // getOrder()
  };

  // useEffect(() => {
  //   currentKeyForFile && sendOrderData();
  // }, [currentKeyForFile]);

  useEffect(() => {
    console.log(subscribe);
  }, [subscribe])

  useEffect(() => {
    subscribe == 1 && createDatePeriod()
  }, [requestToEdit])

  return (
    <Segment className={styles.workSegment}>
      <Dimmer active={loading} inverted>
        <Loader inverted>Загрузка</Loader>
      </Dimmer>
      <Grid className={styles.genInfoGrid}>
        <Header
          as="h3"
          content="Документ о проведении осмотра"
          className={styles.infoHeader}
        />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Основной приказ" key="1">
            <Grid.Row>
              <Typography.Title level={5}>
                Допустимый период создания приказа{" "}
                <span style={{ marginLeft: "10px" }}>
                  {`${localStorage.getItem("orderDateFrom")} - ${localStorage.getItem("orderDateTo")}`}
                </span>
              </Typography.Title>
            </Grid.Row>
            <Grid.Row columns={3} className={styles.infoRowGrid}>
              <Space direction="horizontal">
                <Input
                  placeholder="Номер приказа"
                  style={{ width: "220px", marginRight: "8px" }}
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
                <DatePicker
                  placeholder="Дата приказа"
                  value={orderDate}
                  style={{ width: "220px", height: "32px", paddingTop: "10px" }}
                  onChange={(e) => setOrderDate(e)}
                />
                <br />
                <Select
                  placeholder="Выберите предприятие"
                  value={division}
                  style={{ width: "220px", marginTop: "8px" }}
                  onChange={selectFirmChange}
                >
                  {smegnic?.map((e) => (
                    <Option key={e.kodFirm}>{e.firmName}</Option>
                  ))}
                </Select>
                <br />
                <label className="download order" style={{
                  background: "#e0e1e2",
                  color: "rgba(0,0,0,.8)",
                  padding: "11px 21px",
                  backgroundColor: "#cacbcd",
                  borderRadius: ".28571429rem",
                  fontWeight: "700"
                }}>
                  Загрузить pdf
                  <Input
                    disabled={isDownload}
                    style={{ visibility: "hidden", position: "absolute" }}
                    className="sender"
                    type="file"
                    accept=".pdf"
                    onChange={(e) => {
                      const fr = new FileReader();
                      const file = document.querySelector(".sender").files[0];
                      fr.readAsArrayBuffer(file);
                      fr.onload = function () {
                        const blob = new Blob([fr.result], {
                          type: "text/plain",
                        });
                        // let reader = new FileReader();
                        // reader.readAsDataURL(blob); // конвертирует Blob в base64 и вызывает onload
                        setOpenFile(blob);
                        setIsDownload(true)
                      };
                    }}
                  />
                </label>
                {currentKeyForFile && (
                  <Button
                    style={{ marginLeft: "20px" }}
                    disabled={isDownload}
                    onClick={() => {
                      setIsDownload(true)
                      downloadFile(currentKeyForFile, "Док")
                      setTimeout(() => {
                        setIsDownload(false)
                      }, 1500)
                    }}
                  >
                    Просмотреть файл
                  </Button>
                )}
              </Space>
              <Space>
                <Button
                  disabled={
                    orderNumber &&
                    orderNumber &&
                    currentKeyForFile &&
                    orderPeople.length > 0 &&
                    subscribe == 0
                      ? false
                      : true
                  }
                  style={{ marginTop: "10px" }}
                  onClick={() => sendOrderData(1)}
                >
                  Подписать приказ
                </Button>
                <br />
                <Button
                  disabled={subscribe == 1 ? false : true}
                  style={{ marginTop: "10px" }}
                  onClick={() => setRequestToEdit(1)}
                >
                  Запрос на редактирование
                </Button>
                <Button
                  disabled={subscribe == 1 ? true : false}
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    sendOrderData();
                  }}
                >
                  Сохранить
                </Button>
                {alertShow && (
                  <Alert
                    style={{ width: "300px" }}
                    message="Приказ добавлен"
                    type="success"
                  />
                )}
              </Space>
            </Grid.Row>
          </TabPane>
          <TabPane tab="" key="2">
            <Grid.Row>
              <Typography.Title level={5}>
                Допустимый период создания приказа{" "}
                <span style={{ marginLeft: "10px" }}>
                  {localStorage.getItem("orderDatePeriod")}
                </span>
              </Typography.Title>
            </Grid.Row>
            <Grid.Row columns={3} className={styles.infoRowGrid}>
              <Space direction="horizontal">
                <Input
                  placeholder="Номер приказа"
                  style={{ width: "220px", marginRight: "8px" }}
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                />
                <DatePicker
                  placeholder="Дата приказа"
                  value={orderDate}
                  style={{ width: "220px", height: "32px", paddingTop: "10px" }}
                  onChange={(e) => setOrderDate(e)}
                />
                <br />
                <Select
                  placeholder="Выберите предприятие"
                  value={division}
                  style={{ width: "220px", marginTop: "8px" }}
                  onChange={selectFirmChange}
                >
                  {smegnic?.map((e) => (
                    <Option key={e.kodFirm}>{e.firmName}</Option>
                  ))}
                </Select>
                <br />
                <Input
                  className="sender"
                  type="file"
                  onChange={(e) => {
                    const fr = new FileReader();
                    const file = document.querySelector(".sender").files[0];
                    fr.readAsArrayBuffer(file);
                    fr.onload = function () {
                      const blob = new Blob([fr.result], {
                        type: "text/plain",
                      });
                      // let reader = new FileReader();
                      // reader.readAsDataURL(blob); // конвертирует Blob в base64 и вызывает onload
                      setOpenFile(blob);
                    };
                  }}
                />
              </Space>
              <Space>
                <Button
                  disabled={!orderExist}
                  style={{ marginTop: "10px" }}
                  onClick={() => setSubscribe(!subscribe)}
                >
                  Подписать приказ
                </Button>
                <br />
                <Button
                  disabled={!orderExist}
                  style={{ marginTop: "10px" }}
                  onClick={() => setRequestToEdit(!requestToEdit)}
                >
                  Запрос на редактирование
                </Button>
                <Button
                  style={{ marginTop: "10px" }}
                  onClick={() => {
                    sendOrderData();
                  }}
                >
                  Сохранить
                </Button>
                {alertShow && (
                  <Alert
                    style={{ width: "300px" }}
                    message="Приказ добавлен"
                    type="success"
                  />
                )}
              </Space>
            </Grid.Row>
          </TabPane>
        </Tabs>
      </Grid>
    </Segment>
  );
};
