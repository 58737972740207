import React, {useEffect, useState} from "react";
import ekasut from "../../../../../../../api/ekasut";
import {sliceFetchData} from "../lib/sliceFetchData";



export const useGetOnBoardDiagnostics = (objectId) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    getEp()
  }, [objectId])

  const getEp = async () => {
    let res;
    try {
      setLoading(true)
      res = await ekasut.ekasutData.getLogControls(objectId)

      setData(sliceFetchData(res.data))
      setLoading(false)
    } catch (err) {
      setError(err)
      setLoading(false)
    }
  }

  return {
    loading,
    data,
    error
  };
};

