import React, {useState, useCallback, useEffect, useRef} from 'react';
import {Paper, Grid, Typography, Button, IconButton} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {Column} from './Column';
import {moveTask} from "../../dashboardsLogic/board";
import style from "../boardComponents.module.scss"
import AddIcon from '@material-ui/icons/Add';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {board} from "../../TaskListState";

const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

const getListStyle = (isDraggingOver) => {
    return ({
        // background: isDraggingOver ? 'rgba(193, 200, 216, 0.35)' : 'rgba(255, 255, 255, 0.15)',
        background: isDraggingOver ? 'transperent' : 'transperent',
        padding: 8,
        minHeight: 500,
    });
}


export const BoardArea = ({activeBoard, loadingTasksBoard}) => {

    const [addTask, setAddTask] = useState(false)

    const onDragEnd = useCallback(event => {
        const {source, destination, draggableId: taskId} = event;
        moveTask(taskId.split('-')[0], source, destination, activeBoard)
    }, [activeBoard]);

    useEffect(() => {
        setAddTask(false)
    }, [activeBoard])

    useEffect(() => {
        if (loadingTasksBoard) {
            document.querySelector(`#scrollWrap-${activeBoard.boardItems[0].fieldId}`).scrollIntoView()
        }
    }, [loadingTasksBoard])


    return (
        <Box p={2} className={style.taskAriaBox} id={'boardBox'}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing={3} className={style.taskAria}>
                    <div className={style.loadingAria} style={loadingTasksBoard ? {} : {display: 'none'}}>
                        <Spin indicator={antIcon} tip='Загрузка...'/>
                    </div>
                    {activeBoard.boardItems.map((section, index) => {
                        return (
                            <Grid item key={section.fieldId} style={{
                                height: '100%'
                            }}>
                                <Paper
                                    id={'paper'}
                                    className={style.paperTextArea}
                                    style={{maxWidth: 250, minWidth: 250, boxShadow: 'none'}}>
                                    <div className={style.paperHeader}
                                         style={{borderBottom: `4px solid ${section.fieldColorHex}`}}>
                                        <Typography variant='subtitle1'
                                                    style={{color: `${section.fieldColorHex}`}}>{section.fieldName}
                                        </Typography>
                                        <div className={style.cntDiv}>
                                            <ViewAgendaIcon color="action" fontSize="small"/>
                                            <span
                                                className={style.tasksCnt}>{section.taskPage.totalRecords}</span>
                                            <Button disabled={!!addTask || board.workGruopRoleId === 3}
                                                    onClick={() => {
                                                        setAddTask(section.fieldId)
                                                    }}>
                                                <AddIcon/>
                                            </Button>
                                        </div>
                                    </div>
                                    <Droppable droppableId={String(section.fieldId)} key={section.fieldId}>
                                        {(provided, snapshot) => (
                                            <div
                                                id={`scrollWrap-${section.fieldId}`}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={getListStyle(snapshot.isDraggingOver)}>
                                                {loadingTasksBoard ? null :
                                                    <Column section={section} activeBoard={activeBoard}
                                                            addTask={addTask}
                                                            setAddTask={setAddTask}
                                                            parentId={`#scrollWrap-${section.fieldId}`}/>}
                                                {provided.placeholder}
                                                {section.taskPage.data.length > 0 && board.workGruopRoleId !== 3 ?
                                                    <Button disabled={!!addTask} onClick={() => {
                                                        setAddTask(section.fieldId)
                                                    }}>
                                                        <AddIcon/>
                                                        Добавить задачу
                                                    </Button> : null}
                                            </div>
                                        )}
                                    </Droppable>
                                </Paper>
                            </Grid>
                        )
                    })}
                </Grid>
            </DragDropContext>
        </Box>
    );
}