import React from 'react'
import AppTreeTable from "../../shared/AppTreeTable";
import styles from './MonitorPowerTable.module.scss'

export default function MonitorPowerTable({roads, onDepotClick}) {

    return (
        <AppTreeTable
            headers={convertHeaders(headers)}
            data={convertRoadsToStructure(roads)}
            onGroupNameClick={onDepotClick}
            groupDataRowClassNameCreator={({isOverWork}) => {
                return isOverWork ? styles.isOverWork : styles.isNotOverWork
            }}
        />
    )
}

const convertHeaders = (headers) => {
    return headers.map(text => ({
        value: text,
    }))
}

const convertRoadsToStructure = (roads) => {
    const result = []
    roads.forEach(road => {
        let depots = undefined
        if (road.depos !== undefined) {
            depots = road.depos.map(depot => {
                let series = undefined
                if (depot.series !== undefined) {
                    series = depot.series.map(ser => {
                        let repairTypes = undefined
                        if (ser.repairKindes !== undefined) {
                            repairTypes = ser.repairKindes.map(repair => {
                                return {
                                    values: [repair.repairName, repair.factCnt, repair.planCnt],
                                    isOverWork: repair.isOverWork
                                }
                            })
                        }
                        return {
                            groupName: ser.serName,
                            groupData: repairTypes,
                            id: ser.serLoc,
                        }
                    })
                }
                return {
                    groupName: depot.depoName,
                    children: series,
                    id: depot.kodFirm,
                    clickable: true,
                }
            })
        }
        result.push({
            groupName: road.dorName,
            children: depots,
            id: road.dorKod,
        })
    })

    return result
}
const headers = [
    'Дорога/Слд/Серия',
    'Виды ремонта',
    'Норма',
    'Факт'
]