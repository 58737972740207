import React from 'react';
import {Tab, Tabs,} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';
import {MainTab} from "./MainTab/MainTab";
import {TimingTab} from "./TimingTab/TimingTab";
import ekasut from "../../../../api/ekasut";
import {WorkPlaceTab} from "./WorkPlaceTab/WorkPlaceTab";
import {ManagementRightsTab} from "./ManagementRightsTab/ManagementRightsTab";
import {MapTab} from "./MapTab/MapTab";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}


export const PersonDetailsTabs = ({info, additional}) => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);

    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                centered
            >
                <Tab label="Основное"/>
                <Tab label="Контролируемые сроки"/>
                <Tab label="Заключения на виды и места работ"/>
                <Tab label="Права управления"/>
                <Tab label="Координаты"/>
            </Tabs>

            <SwipeableViews
                axis={'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0}>
                    <MainTab/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TimingTab info={info}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <WorkPlaceTab id={info?.id}/>
                </TabPanel>
                <TabPanel value={value} index={3}>
                   <ManagementRightsTab id={info?.id}/>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <MapTab asutrId={additional?.asutrId}/>
                </TabPanel>
            </SwipeableViews>
        </>
    );
}