import React, {useCallback, useState} from 'react'
import {Button, Form, Segment, Select} from "semantic-ui-react";
import styles from './SaveForm.module.scss'
import useFileInput from "../../hooks/useFileInput";
import {formOptions} from "../../helpers/utils";
import DelayedSearch from "./DelayedSearch";
import InputDigits from "../../shared/InputDigits";
import ButtonFiles from "./ButtonFiles";
import LabelAndButton from "./LabelAndButton";
import cn from 'classnames'
import appStyles from '../../css/App.module.scss'

/*
uol/esr: { // Перегон, Участок
    begin: { // Станция начала
        options // Опции для Dropdown
             [{text, key, value, id, name},...]
        update // обновление опций (при вводе текста)
        loading // загрузка опций
    }
    end: { // Станция конца
        ...
    }
}
loc: {
  options,
  update,
  loading,
  defIndex
}

uploadedFiles - файлы прикрепленные к записи на серверы

 */
const SaveForm = (
    {
        cancel, uol, esr, loc, searchDelay, searchMinCharacters, onSave,
        defTraction, defLocTypeIndex, defAllowWeight, defAllowLength, loading,
        title, maxNumberFiles, approvedFiles, onFilesAttach, onFileDetach,
        attachedFiles, onExceededFilesLimit, onFileDownload, fixed
    }
) => {

    const initError = {
        allowLength: false,
        allowWeight: false,
        uolBegin: false,
        uolEnd: false,
        esrBegin: false,
        esrEnd: false,
        locId: false,
        locType: false,
        traction: false,
        files: false,
    }

    const [error, setError] = useState({...initError})
    const handleAddNewFiles = useCallback((e) => {
        const newUniqueFiles = []
        const files = e.target.files
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const associateWrapper = attachedFiles.find(wrapper => wrapper.name === file.name)
            if (associateWrapper === undefined) {
                newUniqueFiles.push(file)
            }
        }
        if (maxNumberFiles >= (newUniqueFiles.length + attachedFiles.length)) {
            onFilesAttach(newUniqueFiles)
        } else {
            onExceededFilesLimit(maxNumberFiles)
        }
    }, [onFilesAttach, attachedFiles, maxNumberFiles, onExceededFilesLimit])

    const [HiddenInput, openFile] = useFileInput(handleAddNewFiles)
    const [allowWeight, setAllowWeight] = useState(defAllowWeight)
    const [allowLength, setAllowLength] = useState(defAllowLength)

    const [sendingParams, setSendingParams] = useState({
        uolBegin: getDefId(uol.begin),
        uolEnd: getDefId(uol.end),
        esrBegin: getDefId(esr.begin),
        esrEnd: getDefId(esr.end),
        locId: getDefId(loc),
        traction: defTraction,
        locType: defLocTypeIndex,
        allowLength: defAllowLength,
        allowWeight: defAllowWeight,
    })

    const setParamFromData = (paramName, data) => {
        const index = data.value
        const select = data.options[index]
        const params = {...sendingParams}
        params[paramName] = select.id
        setSendingParams(params)
    }

    const checkForm = () => {
        const newError = {
            allowWeight: !parseInt(sendingParams.allowWeight),
            allowLength: !parseInt(sendingParams.allowLength),
            uolBegin: sendingParams.uolBegin === undefined,
            uolEnd: sendingParams.uolEnd === undefined,
            esrBegin: sendingParams.esrBegin === undefined,
            esrEnd: sendingParams.esrEnd === undefined,
            locId: sendingParams.locId === undefined,
            locType: sendingParams.locType === undefined,
            traction: sendingParams.traction === undefined,
            files: attachedFiles.length === 0
        }

        const formCorrect = !(
            newError.allowWeight || newError.allowLength || newError.uolBegin ||
            newError.uolEnd || newError.esrBegin || newError.esrEnd || newError.locId ||
            newError.locType || newError.files
        )

        if (!formCorrect) {
            setError(newError)
        }
        return formCorrect
    }

    return (
        <Segment>
            <h3 className={styles.title}>{title}</h3>
            <Form className={styles.form} loading={loading}>
                <Form.Group inline>
                    <Form.Field error={error.uolBegin}>
                        <label>Перегон</label>
                        <DelayedSearch
                            placeholder='Станция начала перегона'
                            disabled={fixed.uol.begin}
                            className={cn(styles.stationSelect, fixed.uol.begin && appStyles.fixedDropdown)}
                            options={uol.begin.options}
                            minCharacters={searchMinCharacters}
                            delay={searchDelay}
                            typeEnd={uol.begin.update}
                            loading={uol.begin.loading}
                            defaultValue={uol.begin.defIndex}
                            onSearchChange={() => {
                                setError({...error, uolBegin: false})
                            }}
                            onChange={(e, data) => {
                                setParamFromData('uolBegin', data)
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={error.uolEnd}>
                        <label>-</label>
                        <DelayedSearch
                            placeholder='Станция конка перегона'
                            disabled={fixed.uol.end}
                            className={cn(styles.stationSelect, fixed.uol.end && appStyles.fixedDropdown)}
                            options={uol.end.options}
                            minCharacters={searchMinCharacters}
                            delay={searchDelay}
                            typeEnd={uol.end.update}
                            loading={uol.end.loading}
                            defaultValue={uol.end.defIndex}
                            onSearchChange={() => {
                                setError({...error, uolEnd: false})
                            }}
                            onChange={(e, data) => {
                                setParamFromData('uolEnd', data)
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group inline>
                    <Form.Field error={error.esrBegin}>
                        <label>Участок</label>
                        <DelayedSearch
                            disabled={fixed.esr.begin}
                            placeholder='Станция начала участка'
                            className={cn(styles.stationSelect, fixed.esr.begin && appStyles.fixedDropdown)}
                            options={esr.begin.options}
                            minCharacters={searchMinCharacters}
                            delay={searchDelay}
                            typeEnd={esr.begin.update}
                            loading={esr.begin.loading}
                            defaultValue={esr.begin.defIndex}
                            onChange={(e, data) => {
                                setParamFromData('esrBegin', data)
                            }}
                            onSearchChange={() => {
                                setError({...error, esrBegin: false})
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={error.esrEnd}>
                        <label>-</label>
                        <DelayedSearch
                            disabled={fixed.esr.end}
                            placeholder='Станция конца участка'
                            className={cn(styles.stationSelect, fixed.esr.end && appStyles.fixedDropdown)}
                            options={esr.end.options}
                            minCharacters={searchMinCharacters}
                            delay={searchDelay}
                            typeEnd={esr.end.update}
                            loading={esr.end.loading}
                            defaultValue={esr.begin.defIndex}
                            onChange={(e, data) => {
                                setParamFromData('esrEnd', data)
                            }}
                            onSearchChange={() => {
                                setError({...error, esrEnd: false})
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths={'equal'}>
                    <Form.Field error={error.locId}>
                        <label>Серия</label>
                        <Select
                            search
                            disabled={fixed.loc}
                            className={cn(fixed.loc && appStyles.fixedDropdown)}
                            options={loc.options}
                            delay={searchDelay}
                            loading={loc.loading}
                            defaultValue={loc.defIndex}
                            onClick={() => {
                                setError({...error, locId: false})
                            }}
                            onChange={(e, data) => {
                                setParamFromData('locId', data)

                            }}
                        />
                    </Form.Field>
                    <Form.Field error={error.traction}>
                        <label>Тяга</label>
                        <Select
                            disabled={fixed.traction}
                            className={cn(fixed.traction && appStyles.fixedDropdown)}
                            options={tractionOptions}
                            defaultValue={defTraction !== undefined ? defTraction - 1 : undefined}
                            onChange={(e, data) => {
                                setParamFromData('traction', data)
                            }}
                            onClick={() => {
                                setError({...error, traction: false})
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={error.locType}>
                        <label>Вид движения</label>
                        <Select
                            disabled={fixed.locType}
                            className={cn(fixed.locType && appStyles.fixedDropdown)}
                            options={locTypeOptions}
                            defaultValue={defLocTypeIndex}
                            onChange={(e, data) => {
                                setParamFromData('locType', data)
                            }}
                            onClick={() => {
                                setError({...error, locType: false})
                            }}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field error={error.allowWeight}>
                        <label>Допустимый вес</label>
                        <InputDigits
                            value={allowWeight}
                            onChange={(e, {value}) => {
                                setSendingParams({...sendingParams, allowWeight: value})
                                setAllowWeight(value)
                                if (error.allowWeight) {
                                    setError({...error, allowWeight: false})
                                }
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={error.allowLength}>
                        <label>Допустимая длина</label>
                        <InputDigits
                            value={allowLength}
                            onChange={(e, {value}) => {
                                setSendingParams({...sendingParams, allowLength: value})
                                setAllowLength(value)
                                if (error.allowLength) {
                                    setError({...error, allowLength: false})
                                }
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                {approvedFiles.length > 0 && (
                    <>
                        Прикрепленные к записи файлы (при сохранении будут заменены на новые)
                        <Segment color={'red'}>
                            {approvedFiles.map((file, index) => {
                                return (
                                    <LabelAndButton
                                        key={index}
                                        loading={false}
                                        icon={'download'}
                                        text={file.fileName}
                                        onClick={() => {
                                            onFileDownload(file)
                                        }}
                                    />
                                )
                            })}
                        </Segment>
                    </>
                )}
                Новые файлы
                <Segment color={'green'}>
                    <ButtonFiles
                        openFile={openFile}
                        error={error.files}
                        files={attachedFiles}
                        displayAttachButton={attachedFiles.length < maxNumberFiles}
                        onClose={onFileDetach}
                    />
                </Segment>

                <HiddenInput/>

                <div className={styles.buttons}>
                    <Button
                        onClick={() => {
                            cancel()
                        }}
                    >
                        Отмена
                    </Button>
                    <Button
                        positive
                        onClick={() => {
                            if (checkForm()) {
                                onSave(sendingParams, attachedFiles)
                            }
                        }}
                    >
                        Сохранить
                    </Button>
                </div>
            </Form>
        </Segment>
    )
}
export default SaveForm

const getDefId = ({options, defIndex}) => {
    if ((defIndex === undefined) || (options[defIndex] === undefined)) {
        return undefined
    }
    return options[defIndex].id
}


// id = index + 1
const traction = [
    'Одинарная',
    'Двойная',
    'Тройная'
]
// id = index
const locTypes = [
    'Грузовой',
    'Пассажирский',
    'Почтово-багажный'
]
const tractionOptions = formOptions(traction, undefined, (item, index) => ({id: index + 1}))
const locTypeOptions = formOptions(locTypes, undefined, (item, index) => ({id: index}))
