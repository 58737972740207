import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, Modal } from "semantic-ui-react";
import TrafficStandardsTable from "./TrafficStandardsTable";
import ekasut from "../../api/ekasut";
import AppModal from "../../shared/AppModal";
import SaveForm from "./SaveForm";
import styles from "./index.module.scss";
import { formOptions } from "../../helpers/utils";
import DelayedSearch from "./DelayedSearch";
import useApiMethod from "../../hooks/useApiMethod";
import ModalInfo from "./ModalInfo";
import useFileDownload from "../../hooks/useFileDownload";
import cn from "classnames";
import catchNetworkErrors from "../../api/catchNetworkErrors";
import OrderWeightLengthModal from "./OrderWeightLengthModal";
import useForceUpdate from "../../hooks/useForceUpdate";

const NsiWeightLength = ({ hideSidebars }) => {
  const [saveFormOpen, setSaveFormOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [askDelete, setAskDelete] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [filterValues, setFilterValues] = useState(initFilterValues);
  const [isEditForm, setIsEditForm] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [saveConfirmation, setSaveConfirmation] = useState(false);
  const [saveFormLoading, setSaveFormLoading] = useState(false);
  const [approvedFiles, setApprovedFiles] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [exceededFilesMsgOpen, setExceededFilesMsgOpen] = useState(false);
  const [saveFileToUser, HiddenLink] = useFileDownload();
  const [changeStandardModal, setChangeStandardModal] = useState({
    open: false,
  });
  const [checkedRows, setCheckedRows] = useState({});
  const forceUpdate = useForceUpdate();
  const [showFilters, setShowFilters] = useState(false);

  const resConverters = useMemo(
    () => ({
      locOptions: (res) =>
        formOptions(res.data, "text", (item) => ({
          name: item.text,
          id: item.key,
        })),
      stations: (res) =>
        res.data.map((item) => ({ id: item.esr, name: item.vname })),
    }),
    []
  );

  const [sendGetStandards, standardsLoading, standards, setStandards] =
    useApiMethod(ekasut.weightLimits.getWeights, undefined, []);
  const [sendDeleteStandard, deleteStandardLoading] = useApiMethod(
    ekasut.weightLimits.deleteWeight,
    undefined
  );
  const [sendSaveStandard] = useApiMethod(
    ekasut.weightLimits.postWeight,
    undefined
  );
  const [sendGetStandardFiles] = useApiMethod(
    ekasut.weightLimits.getFiles,
    undefined
  );
  const [sendDeleteFile] = useApiMethod(ekasut.file.deleteFile, undefined);
  const [sendGetFile] = useApiMethod(ekasut.file.getFile, undefined);
  const [sendGetLocOptions, locOptionsLoading, locOptions] = useApiMethod(
    ekasut.locomotivesNsi.getSerTpsAsutt,
    undefined,
    [],
    resConverters.locOptions
  );

  const useStationsObj = () => {
    // stations: [{name, id}]
    const [update, loading, stations, setStations] = useApiMethod(
      ekasut.ekasutData.getNsiStan,
      undefined,
      [],
      resConverters.stations
    );
    // station: {name, id}
    const [defaultStation, setDefaultStation] = useState(null);
    // if index === undefined - dropdown empty
    const [defIndex, setDefIndex] = useState(undefined);

    useEffect(() => {
      if (defaultStation !== null) {
        setStations([defaultStation]);
        setDefIndex(0);
      } else {
        setDefIndex(undefined);
        setStations([]);
      }
    }, [defaultStation, setStations]);

    const options = formOptions(stations, "name", ({ name, id }) => ({
      name,
      id,
    }));

    return {
      update,
      loading,
      setDefaultStation,
      defIndex,
      options,
    };
  };

  /* --- Form objects --- */
  const useUolEsr = () => {
    return {
      uol: {
        begin: useStationsObj(),
        end: useStationsObj(),
      },
      esr: {
        begin: useStationsObj(),
        end: useStationsObj(),
      },
    };
  };
  const formUolEsr = useUolEsr();
  const filter = useUolEsr();

  const standard =
    standards[selectedIndex] !== undefined
      ? standards[selectedIndex]
      : {
          kodSer: undefined,
          tjaga: undefined,
          kodVdvig: undefined,
          weightN: undefined,
          lengthN: undefined,
        };

  const formLoc = {
    update: sendGetLocOptions,
    loading: locOptionsLoading,
    options: locOptions,
    defIndex: isEditForm
      ? locOptions.findIndex((item) => item.id === standard.kodSer)
      : undefined,
  };

  const searchDelay = 1000;
  const searchMinCharacters = 3;
  const maxNumberFiles = 5;
  const somethingIsLoading = deleteStandardLoading;

  /* --- Effects --- */
  // get loc options on mount
  useEffect(() => {
    sendGetLocOptions();
  }, [sendGetLocOptions]);

  const updateStandards = () => {
    setSelectedIndex(null);
    setCheckedRows({});
    sendGetStandards(
      filterParams.uolBegin,
      filterParams.uolEnd,
      filterParams.esrBegin,
      filterParams.esrEnd
    ).catch(catchNetworkErrors);
  };

  // update standards if filter is changed
  useEffect(() => {
    updateStandards();
  }, [filterParams, sendGetStandards]);

  /* --- Methods --- */
  const getIdFromData = (data) => {
    return data.options[data.value].id;
  };
  const addFilterId = (filterName, id) => {
    const params = { ...filterParams };
    params[filterName] = id;
    setFilterParams(params);
  };
  const setFilterValue = (filterName, value) => {
    const values = { ...filterValues };
    values[filterName] = value;
    setFilterValues(values);
  };
  const clearFilter = (filterName) => {
    setFilterValues({ ...filterValues, [filterName]: null });
    setFilterParams({ ...filterParams, [filterName]: undefined });
    setActivePage(1);
  };

  /* --- Handlers --- */
  const onFilesAttachHandler = useCallback((files) => {
    const newContainers = [];
    // Supposed to that file names is unique, so don't need to check this again
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      newContainers.push({
        file: file,
        name: file.name,
        key: file.name,
        loading: true,
        faint: false,
        id: null,
      });
    }

    setAttachedFiles((containers) => [...containers, ...newContainers]);

    for (let j = 0; j < files.length; j++) {
      const file = files[j];
      ekasut.file
        .postFile(file)
        .then((response) => {
          const fileId = response.data;
          // i use functional set form, because i need last attachedFiles
          setAttachedFiles((attachedFiles) => {
            const associateWrapIndex = attachedFiles.findIndex(
              (wrap) => wrap.name === file.name
            );
            if (associateWrapIndex !== -1) {
              const newWrappers = [...attachedFiles];
              newWrappers[associateWrapIndex].loading = false;
              newWrappers[associateWrapIndex].id = fileId;
              return newWrappers;
            }
            return attachedFiles;
          });
        })
        .catch((error) => {
          // Remove file from list that not successfully loaded
          setAttachedFiles((files) => {
            const newFiles = [...files];
            newFiles.splice(
              files.findIndex((f) => f.name === file.name),
              1
            );
            return newFiles;
          });
        });
    }
  }, []);
  const onFileDetachHandler = useCallback(
    (file, index) => {
      setAttachedFiles((attachedFiles) => {
        const newFiles = [...attachedFiles];
        newFiles[index].loading = true;
        newFiles[index].faint = true;
        return newFiles;
      });
      sendDeleteFile(file.id).then(() => {
        setAttachedFiles((attachedFiles) => {
          const attachedFileIndex = attachedFiles.findIndex(
            (wrap) => wrap.name === file.name
          );
          if (attachedFileIndex !== -1) {
            const newFiles = [...attachedFiles];
            newFiles.splice(attachedFileIndex, 1);
            return newFiles;
          } else {
            return attachedFiles;
          }
        });
      });
    },
    [sendDeleteFile]
  );

  const onExceededFilesLimitHandler = useCallback(() => {
    setExceededFilesMsgOpen(true);
  }, []);
  const onSaveStandardHandler = (params, files) => {
    const p = params;
    const ids = files.map((file) => file.id);
    setSelectedIndex(null);
    setSaveFormLoading(true);
    sendSaveStandard(
      p.uolBegin,
      p.uolEnd,
      p.esrBegin,
      p.esrEnd,
      p.traction,
      p.locType,
      p.locId,
      p.allowWeight,
      p.allowLength,
      ids
    )
      .then(() => {
        setSaveFormLoading(false);
        setSaveFormOpen(false);
        setSaveConfirmation(true);
      })
      .catch((error) => {
        setSaveFormLoading(false);
        return catchNetworkErrors(error);
      });
  };
  const onDeleteStandardHandler = () => {
    const st = standards[selectedIndex];
    setAskDelete(false);
    setSelectedIndex(null);
    sendDeleteStandard(
      st.uolBegin,
      st.uolEnd,
      st.beginEcp,
      st.endEcp,
      st.tjaga,
      st.kodVdvig,
      st.kodSer
    );
    const newStandards = [...standards];
    newStandards.splice(selectedIndex, 1);
    setStandards(newStandards);
    setCheckedRows({});
    setSelectedIndex(null);
  };
  const onFileDownloadHandler = (file) => {
    sendGetFile(file.fileId).then((response) => {
      saveFileToUser(response, file.fileName);
    });
  };

  return (
    <div className={cn(styles.container)} onClick={hideSidebars}>
      <HiddenLink />
      <OrderWeightLengthModal
        open={changeStandardModal.open}
        details={checkedRows}
        onClose={() => {
          setChangeStandardModal({ open: false });
        }}
        onApply={(data) => {
          let savedOrderId = null;
          ekasut.weightLimits
            .postOrder({
              data: {
                orderNum: null,
                orderDateBegin: data.beginDate.format(),
                orderDateEnd: data.endDate.format(),
                orderRegDate: data.regDate.format(),
                details: Object.keys(checkedRows).map((key) => {
                  const item = checkedRows[key];
                  return {
                    beginEcp: item.beginEcp,
                    endEcp: item.endEcp,
                    kodVdvig: item.kodVdvig,
                    lengthN: data.length,
                    weightN: data.weight,
                    serLoc: item.kodSer,
                    tjaga: item.tjaga,
                    uolBegin: item.uolBegin,
                    uolEnd: item.uolEnd,
                  };
                }),
              },
            })
            .then(({ data: orderId }) => {
              savedOrderId = orderId;
              return ekasut.file.postFile(data.file);
            })
            .then(({ data: fileId }) =>
              ekasut.weightLimits.attachFile({ orderId: savedOrderId, fileId })
            )
            .then(() => {
              setChangeStandardModal({ open: false });
              updateStandards();
            })
            .catch(catchNetworkErrors);
        }}
      />
      {showFilters && (
        <div className={styles.filters}>
          <div
            className={styles.filtersClose}
            onClick={() => setShowFilters(!showFilters)}
          >
            ❌
          </div>
          <DelayedSearch
            placeholder="Станция начала перегона"
            options={filter.uol.begin.options}
            loading={filter.uol.begin.loading}
            minCharacters={searchMinCharacters}
            delay={searchDelay}
            typeEnd={(text) => filter.uol.begin.update(text)}
            className={styles.select}
            value={filterValues.uolBegin}
            onChange={(e, data) => {
              addFilterId("uolBegin", getIdFromData(data));
              setFilterValue("uolBegin", data.value);
              setSelectedIndex(null);
              setActivePage(1);
            }}
            onKeyDown={(e) => {
              if (e.target.value === "") {
                if (e.key === "Delete" || e.key === "Backspace") {
                  clearFilter("uolBegin");
                }
              }
            }}
          />
          <DelayedSearch
            placeholder="Станция конца перегона"
            options={filter.uol.end.options}
            loading={filter.uol.end.loading}
            minCharacters={searchMinCharacters}
            delay={searchDelay}
            typeEnd={(text) => filter.uol.end.update(text)}
            className={styles.select}
            value={filterValues.uolEnd}
            onChange={(e, data) => {
              addFilterId("uolEnd", getIdFromData(data));
              setFilterValue("uolEnd", data.value);
              setSelectedIndex(null);
              setActivePage(1);
            }}
            onKeyDown={(e) => {
              if (e.target.value === "") {
                if (e.key === "Delete" || e.key === "Backspace") {
                  clearFilter("uolEnd");
                }
              }
            }}
          />
          <label />
          <DelayedSearch
            placeholder="Станция начала участка"
            options={filter.esr.begin.options}
            loading={filter.esr.begin.loading}
            minCharacters={searchMinCharacters}
            delay={searchDelay}
            typeEnd={(text) => filter.esr.begin.update(text)}
            className={styles.select}
            value={filterValues.esrBegin}
            onChange={(e, data) => {
              addFilterId("esrBegin", getIdFromData(data));
              setFilterValue("esrBegin", data.value);
              setSelectedIndex(null);
              setActivePage(1);
            }}
            onKeyDown={(e) => {
              if (e.target.value === "") {
                if (e.key === "Delete" || e.key === "Backspace") {
                  clearFilter("esrBegin");
                }
              }
            }}
          />
          <DelayedSearch
            placeholder="Станция конца участка"
            options={filter.esr.end.options}
            loading={filter.esr.end.loading}
            minCharacters={searchMinCharacters}
            delay={searchDelay}
            typeEnd={(text) => filter.esr.end.update(text)}
            className={styles.select}
            value={filterValues.esrEnd}
            onChange={(e, data) => {
              addFilterId("esrEnd", getIdFromData(data));
              setFilterValue("esrEnd", data.value);
              setSelectedIndex(null);
              setActivePage(1);
            }}
            onKeyDown={(e) => {
              if (e.target.value === "") {
                if (e.key === "Delete" || e.key === "Backspace") {
                  clearFilter("esrEnd");
                }
              }
            }}
          />
          <Button
            basic
            icon={"erase"}
            style={{ margin: "0 20px" }}
            onClick={() => {
              setFilterValues(initFilterValues);
              setFilterParams({});
              setActivePage(1);
            }}
          />
        </div>
      )}
      <div className={styles.controls}>
        <Button
          disabled={somethingIsLoading}
          onClick={() => {
            setIsEditForm(false);
            formUolEsr.uol.begin.setDefaultStation(null);
            formUolEsr.uol.end.setDefaultStation(null);
            formUolEsr.esr.begin.setDefaultStation(null);
            formUolEsr.esr.end.setDefaultStation(null);
            setApprovedFiles([]);
            setSaveFormOpen(true);
          }}
        >
          Добавить
        </Button>
        <Button
          disabled={selectedIndex === null || somethingIsLoading}
          onClick={() => {
            formUolEsr.uol.begin.setDefaultStation({
              id: standard.uolBegin,
              name: standard.uolBeginName,
            });
            formUolEsr.uol.end.setDefaultStation({
              id: standard.uolEnd,
              name: standard.uolEndName,
            });
            formUolEsr.esr.begin.setDefaultStation({
              id: standard.beginEcp,
              name: standard.beginName,
            });
            formUolEsr.esr.end.setDefaultStation({
              id: standard.endEcp,
              name: standard.endName,
            });
            setApprovedFiles([]);
            setAttachedFiles([]);
            setIsEditForm(true);
            setSaveFormOpen(true);
            setSaveFormLoading(true);
            const st = standards[selectedIndex];
            sendGetStandardFiles(
              st.uolBegin,
              st.uolEnd,
              st.beginEcp,
              st.endEcp,
              st.tjaga,
              st.kodVdvig,
              st.kodSer
            ).then((response) => {
              setSaveFormLoading(false);
              setApprovedFiles(response);
            });
          }}
        >
          Редактировать
        </Button>
        <Button
          disabled={selectedIndex === null || somethingIsLoading}
          loading={deleteStandardLoading}
          onClick={() => {
            setAskDelete(true);
          }}
        >
          Удалить
        </Button>
        <Button
          icon={"refresh"}
          style={{ marginLeft: 0 }}
          onClick={() => {
            setSelectedIndex(null);
            sendGetStandards(
              filterParams.uolBegin,
              filterParams.uolEnd,
              filterParams.esrBegin,
              filterParams.esrEnd
            );
          }}
        />
        <Button
          content="Изменить нормативы"
          disabled={Object.keys(checkedRows).length === 0}
          onClick={() => {
            setChangeStandardModal({ open: true });
          }}
        />
        <Button
          content="Удалить приказ"
          disabled={
            selectedIndex === null || standards[selectedIndex].orderId === null
          }
          onClick={() => {
            ekasut.weightLimits
              .deleteOrder({ orderId: standards[selectedIndex].orderId })
              .then(() => {
                // Mutate object, it's okay here... i hope
                standards[selectedIndex].orderId = null;
                standards[selectedIndex].orderFileId = null;
                standards[selectedIndex].orderRegDate = null;
                standards[selectedIndex].orderDateBegin = null;
                standards[selectedIndex].orderDateEnd = null;
                setStandards(standards);
                // Hack for wrong implementation useApiMethod, setStandards doesn't update state
                forceUpdate();
              })
              .catch(catchNetworkErrors);
          }}
        />
        <Button
          className={styles.filterButton}
          onClick={() => setShowFilters(!showFilters)}
        >
          Фильтры
        </Button>
      </div>

      <TrafficStandardsTable
        loading={standardsLoading}
        data={standards}
        activePage={activePage}
        rowsInPage={18}
        activeIndex={selectedIndex}
        onSelectItem={(index) => {
          setSelectedIndex(index);
        }}
        onPageChanged={(page) => {
          setActivePage(page);
          setSelectedIndex(null);
        }}
        checkedRows={checkedRows}
        onCheckRow={(rowData, rowIndex) => {
          const clone = { ...checkedRows };
          if (clone[rowIndex] === undefined) {
            clone[rowIndex] = rowData;
          } else {
            delete clone[rowIndex];
          }
          setCheckedRows(clone);
        }}
      />
      <AppModal
        style={{ top: "4%" }}
        loading={false}
        open={saveFormOpen}
        onClose={() => {
          setSaveFormOpen(false);
        }}
      >
        <SaveForm
          cancel={() => {
            setSaveFormOpen(false);
          }}
          maxNumberFiles={maxNumberFiles}
          title={isEditForm ? "Редактировать запись" : "Добавить запись"}
          loading={saveFormLoading}
          searchDelay={searchDelay}
          searchMinCharacters={searchMinCharacters}
          uol={formUolEsr.uol}
          esr={formUolEsr.esr}
          loc={formLoc}
          defTraction={isEditForm ? standard.tjaga : undefined}
          defLocTypeIndex={isEditForm ? standard.kodVdvig : undefined}
          defAllowLength={isEditForm ? standard.lengthN : ""}
          defAllowWeight={isEditForm ? standard.weightN : ""}
          approvedFiles={approvedFiles}
          onFilesAttach={onFilesAttachHandler}
          onFileDetach={onFileDetachHandler}
          onExceededFilesLimit={onExceededFilesLimitHandler}
          onFileDownload={onFileDownloadHandler}
          attachedFiles={attachedFiles}
          onSave={onSaveStandardHandler}
          fixed={
            isEditForm
              ? {
                  uol: {
                    begin: true,
                    end: true,
                  },
                  esr: {
                    begin: true,
                    end: true,
                  },
                  loc: true,
                  traction: true,
                  locType: true,
                }
              : { uol: {}, esr: {} }
          }
        />
      </AppModal>
      <Modal open={askDelete} size="tiny" onClose={() => setAskDelete(false)}>
        <Modal.Content>Вы действительно хотите удалить форму?</Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAskDelete(false)}>Нет</Button>
          <Button negative onClick={onDeleteStandardHandler}>
            Да
          </Button>
        </Modal.Actions>
      </Modal>

      <ModalInfo
        open={exceededFilesMsgOpen}
        onClose={() => setExceededFilesMsgOpen(false)}
        content={`Суммарное количество файлов не должно превышать ${maxNumberFiles}`}
        header={"Превышен лимит файлов"}
      />
      <ModalInfo
        open={saveConfirmation}
        onClose={() => {
          setSaveConfirmation(false);
          sendGetStandards(
            filterParams.uolBegin,
            filterParams.uolEnd,
            filterParams.esrBegin,
            filterParams.esrEnd
          );
        }}
        content={"Запись успешно добавлена в таблицу"}
      />
    </div>
  );
};
export default NsiWeightLength;

const initFilterValues = {
  uolBegin: null,
  uolEnd: null,
  esrBegin: null,
  esrEnd: null,
};
