import React, {useState, useEffect} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { zoomLevels } from '../ZoomLevel'

const initTractionOptions = { 
	title: {
		text: ''
	},
	series: [],
	chart: {
		type: 'line',
		backgroundColor: 'rgba(0,0,0,0)',
		animation: false,
	},
	exporting: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	xAxis: {
		title: {
			text: 'Время'
		},
		type: 'datetime',
		labels: {
			formatter: function() {
				return moment(this.value).format("DD.MM.YYYY");
			},
			rotation: 0 // -34
		},
	},
	yAxis: {
		title: {
			text: 'Коэффициент'
		},
		allowDecimals: false,
	},
	legend: {
		enabled: true
	},
	tooltip: {
		formatter: function () {
			return (
				`<b>${this.series.name}</b><br />
				Время: ${moment(this.x).format("DD.MM.YYYY")}<br />
				КГЭ факт.: ${this.y}<br />
				КГЭ план: ${this.point.kgePlan}`
			)
		},
	}
}

export default function KgeChart({history, zoomLevel}) {
	const [chartOptions, setChartOptions] = useState(initTractionOptions)

	useEffect(function updateChartOptions() {
		if (zoomLevel === zoomLevels.ROADS) {
			const extractData = (history, tgArr) => {
				const arr = []
				tgArr.forEach(() => arr.push([]))
				history.forEach((historyMoment) => {

					tgArr.forEach((tg, index) => {
						let momentForTg = historyMoment.dataByTipElektr.find((data) => data.tg === tg)
						if (momentForTg !== undefined) {
							let ms = moment(historyMoment.reportDate).valueOf()
							arr[index].push({
								x: ms,
								kgePlan: momentForTg.kgePlan,
								y: momentForTg.kgeFact,
							})
						}
					})
				})
				return arr
			}

			// 0 - тепловозы, 1 - электровозы
			let [locomotive, electric] = extractData(history, [0,1])

			setChartOptions(Highcharts.merge(initTractionOptions, {
				series: [{
					name: 'Тепловозы',
					data: locomotive
				}, {
					name: 'Электровозы',
					data: electric
				}]
			}))
		} else {
			let groupsHistory = new Map()
			history.forEach(moment => {
				moment.dataByKodGrOdn.forEach(byGroup => {
					let name = byGroup.nameGrOdn
					if (!groupsHistory.has(name)) {
						groupsHistory.set(name, [])
					}
					let groupHistory = groupsHistory.get(name)
					groupHistory.push({
						date: moment.reportDate,
						...byGroup
					})
				})
			})
			let series = []
			groupsHistory.forEach((groupHistory, name) => {
				series.push({
					name,
					data: groupHistory.map(m => ({
						x: moment(m.date).valueOf(),
						y: m.kgeFact,
						...m
					}))
				})
			})

			series.forEach(s => {
				s.data.sort((a,b) => a.x - b.x)
			})
			setChartOptions(Highcharts.merge(initTractionOptions, {
				series
			}))
		}
	}, [history, zoomLevel])

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={chartOptions}
		/>
	)
}
