import React from 'react';
import cn from "classnames";
import {Input, Search} from "semantic-ui-react";
import TextField from "./TextField";
import './SearchField.scss'

const SearchField = ({className, label, fluid, searchClassName, textFieldPlaceholder = "", editable = true, ...searchProps}) => {

    if (!editable) {
        const isValueDefined = searchProps.value !== "" &&  searchProps.value !== null &&  searchProps.value !== undefined
        return (
            <TextField
                label={label}
                content={isValueDefined ?  searchProps.value : textFieldPlaceholder}
                className={cn(className, "app-field", !isValueDefined && "text-field-placeholder")}
            />
        )
    }

    return (
        <div className={cn("app field search-field", fluid && "fluid", className)}>
            <label>{label}</label>
            <Search
                className={searchClassName}
                {...searchProps}
            />
        </div>
    );
};

export default SearchField;