import {useState} from 'react'

export default function useMapRef() {
    const [mapRef, setMapRef] = useState(null)

    const onMapRefChangeHandler = ref => {
        if (ref !== null && mapRef === null) {
            setMapRef(ref)
        }
    }

    return [mapRef, onMapRefChangeHandler]
}