import React, {useState} from 'react';
import {Button, Icon, Modal} from "semantic-ui-react";
import styles from "../OrganizationInspection.module.scss";

const SeriesList = ({setSeriesListOpen, seriesListOpen, items}) => {

    return (
        <Modal
            className={styles.addModal}
            onClose={() => setSeriesListOpen(false)}
            onOpen={() => setSeriesListOpen(true)}
            open={seriesListOpen}
            size={'mini'}
            closeIcon>
            <Modal.Header>Список серий</Modal.Header>
            <Modal.Content>
                <span>{items}</span>
            </Modal.Content>
        </Modal>
    );
}

export default SeriesList;