import React, {useEffect, useState} from 'react';
import style from './ListArea.module.scss';
import {Dimmer, Loader} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import {Avatar, List, Skeleton, Divider, Spin} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import {initList} from "../../dashboardsLogic/board";
import ekasut from "../../../../api/ekasut";
import {board} from "../../TaskListState";
import {LoadingOutlined} from '@ant-design/icons';
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PersonIcon from "@material-ui/icons/Person";
import {AssigneePopover} from "../AssigneePopover/AssigneePopover";

const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

export const ListArea = ({loadingList, activeBoard}) => {

    const [pageValue, setPageValue] = useState(1)
    const [loading, setLoading] = useState(false)

    const loadMoreData = (page) => {

        if (loading || pageValue >= board.fullResponse.taskPage.totalPages || loadingList) {
            return;
        }
        let queryString = `?${'pageNumber=' + Number(page + 1)}&${board.categoryListId ? 'categoryId=' + board.categoryListId : ''}&${board.sortListId ? 'sortBy=' + board.sortListId : ''}`
        setLoading(true)
        ekasut.taskboard.getList(board.workGruopId, queryString)
            .then(({data}) => {
                let arr = board.boardItems
                arr.push(...data.taskPage.data)
                setPageValue(page + 1)
                board.boardItems = arr
                setLoading(false)
            })
    };

    useEffect(() => {
        setPageValue(1)
    }, [board.categoryListId])

    if (!board.boardItems.length) {
        return (
            <div className={style.emptyList}>
                Нет задач
            </div>
        )
    }


    return (
        <div id="scrollableDiv" className={style.taskList}>
            <div className={style.loadingAria} style={loadingList ? {} : {display: 'none'}}>
                <Spin indicator={antIcon} tip='Загрузка...'/>
            </div>
            <InfiniteScroll
                dataLength={board.boardItems.length}
                next={() => {
                    loadMoreData(pageValue)
                }}
                hasMore={pageValue < board.fullResponse.taskPage.totalPages}
                loader={<Skeleton className={style.skeletonItem} avatar paragraph={{rows: 1}} active/>}
                endMessage={<Divider plain>Задач больше нет 🤐</Divider>}
                scrollableTarget="scrollableDiv"
            >
                <div id={'listAriaTopBar'}></div>
                <List
                    style={{padding: '0px 5%'}}
                    dataSource={activeBoard.boardItems}
                    renderItem={item => (
                        <List.Item key={item.taskId}>
                            <List.Item.Meta
                                title={<div className={style.taskTitle}><Link
                                    to={`taskboard/${item.workGroupId}-${item.taskNumber}`}>{item.title}</Link>
                                    <div className={style.statusDiv}
                                         style={{
                                             backgroundColor: `${item.status.statusColorHex}`,
                                         }}>{item.status.statusName}</div>
                                </div>}
                                description={`${item.workGroupName} #${item.taskNumber} создано ${moment(item.addedOn).format('DD.MM.YYYY')} ${item.creatorName}`}
                            />
                            <div className={style.itemRightContent}>
                                <div>
                                    {/*{activeItem === 'Все' ?*/}
                                    {/*    <span className={}>{item.status.categoryId === 1 ? 'Активна' : item.status.categoryId === 2 ? 'Выполнена' : 'Закрыта'}</span> : null}*/}
                                    {item.hasComments || item.hasFiles || item.subtasksCount || item.assignees.length ?
                                        <div className={style.itemComments}>

                                            {item.hasFiles ? <InsertDriveFileIcon
                                                style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}

                                            {item.hasComments ?
                                                <ChatBubbleIcon style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}

                                            {item.subtasksCount ?
                                                <div className={style.subsCnt}><FormatListBulletedIcon style={{
                                                    fontSize: '1.1em',
                                                    color: '#a1a1a4'
                                                }}/>{item.subtasksCount}</div> : null}

                                            {item.assignees.length === 1 ?
                                                <div className={style.subsCnt}><PersonIcon style={{
                                                    fontSize: '1.1em',
                                                    color: '#a1a1a4'
                                                }}/>{item.assignees[0].name}
                                                </div> : item.assignees.length > 1 ?
                                                    <AssigneePopover assignees={item.assignees}/> : null
                                            }
                                        </div> : null}

                                    <div className={style.labelsList}>
                                        {item.labels ? item.labels.map((e, i) => (
                                            <div key={i} className={style.labelsDiv}
                                                 style={{
                                                     backgroundColor: `${e.labelColorHex}`,
                                                 }}>{e.labelName}</div>
                                        )) : null}
                                    </div>
                                </div>

                            </div>
                        </List.Item>
                    )}
                >
                    {/*{this.state.loading && this.state.hasMore && (*/}
                    {/*    <div className="demo-loading-container">*/}
                    {/*        <Spin/>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </List>
            </InfiniteScroll>
        </div>
    );
};