import React from 'react';
import style from "./SideBarCommissionInspectionModal.module.scss";

export const SideBarCommissionInspectionModal = ({info}) => {



  return (
    <div className={style.column}>
      <div className={style.itemColumn}>
        <div className={style.title}>
          Общая информация
        </div>
        <div className={style.rowItem}>
          <h4>Предприятие приписки</h4>
          <p>{info?.vname}</p>
        </div>
        <div className={style.rowItem}>
          <h4>Дорога приписки </h4>
          <p>{'Октябрьская дорога'}</p>
        </div>
        <div className={style.rowItem}>
          <h4>Дата постройки</h4>
          <p>{(new Date(info?.datePostr).toLocaleDateString())}</p>
        </div>
        <div className={style.rowItem}>
          <h4>Обслуживающая компания</h4>
          <p>{info?.naimPredpPostr}</p>
        </div>
        <div className={style.rowItem}>
          <h4>Вид движения</h4>
          <p>{info?.naimVidDv}</p>
        </div>
      </div>
      <div className={style.itemColumn}>
        <div className={style.title}>
          Сведения о КО
        </div>
        <div className={style.rowItem}>
          <h4>Ожидаемая дата КО</h4>
          <p>12.11.2021</p>
        </div>
        <div className={style.rowItem}>
          <h4>Чек-лист</h4>
          <p>--------</p>
          <p>--------</p>
          <p>--------</p>
          <p>--------</p>
        </div>
      </div>
    </div>
  );
}
