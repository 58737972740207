import React, {useMemo} from 'react';
import {Button, Form, Grid, Header, List, Message, Modal, TextArea} from "semantic-ui-react";
import cn from 'classnames'
import DropdownField from "../../shared/fields/DropdownField";
import InputField from "../../shared/fields/InputField";
import styles from './PrepareOrderForm.module.scss'
import {observer, useLocalStore} from "mobx-react-lite";
import {formOptions} from "../../helpers/utils";
import PredDropdown from "./PredDropdown";
import DateField from "../../shared/fields/DateField";


const PrepareOrderForm = observer((
    {processes = [], orderDate, orderNum, onChange = () => null, auditInfoLoading, leader, team, editable, roles, preds}
) => {
    const ls = useLocalStore(() => ({
        descriptionModal: {open: false, text: "", onClose: () => null, fio: ""}
    }))

    // Except audit leader
    const memberRoles = roles.filter(role => role.code !== 1)
    const memberRolesOptions = formOptions(memberRoles, "name")

    const valuesChanged = (values = {}) => onChange({
        processes, // read only
        orderDate: values.orderDate || orderDate,
        orderNum: values.orderNum || orderNum,
        leader: values.leader || leader,
        team: values.team || team
    })


    return (
        <div className={cn(styles.component)}>
            <Grid columns={5}>
                <Grid.Row columns='equal'>
                    <Grid.Column><Header content="Выбранные процессы" className="group-header"/></Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        {processes.length === 0 && (
                            <Message warning content="Процессы не выбраны"/>
                        )}
                        {processes.length > 0 && (
                            <List divided className="processes">
                                {processes.map(process => <List.Item key={process.processId} content={process.processName}/>)}
                            </List>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="group-headers-row">
                    <Grid.Column><Header content="Реквизиты документа" className="group-header"/></Grid.Column>
                    <Grid.Column className="expand-grid-column-60">
                        <Header content="Заполняется после регистрации в ЕАСД" className="group-header"/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <DateField
                            inputClassName="date-underline"
                            label="Дата документа" fluid closable
                            editable={editable}
                            date={orderDate || ""}
                            loading={auditInfoLoading}
                            onChange={(date) => {
                                valuesChanged({orderDate: date})
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <InputField
                            label="Номер документа" fluid
                            editable={editable}
                            value={orderNum || ""}
                            inputClassName="input-underline"
                            loading={auditInfoLoading}
                            onChange={(e, {value}) => {
                                valuesChanged({orderNum: value})
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className="group-headers-row">
                    <Grid.Column><Header content="Сведения о руководителе группы аудита" className="group-header"/></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <InputField
                            label="Ф.И.О. руководителя группы аудита" fluid
                            editable={editable}
                            value={leader.fio}
                            inputClassName="input-underline"
                            onChange={(e, {value}) => {
                                valuesChanged({leader: {...leader, fio: value}})
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <InputField
                            label="Должность руководителя группы" fluid
                            editable={editable}
                            inputClassName="input-underline"
                            value={leader.position}
                            onChange={(e, {value}) => {
                                valuesChanged({leader: {...leader, position: value}})
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <PredDropdown
                            label="Подразделение руководителя" fluid
                            editable={editable}
                            preds={preds}
                            value={leader.predId || null}
                            onChange={(pred) => {
                                valuesChanged({
                                    leader: {...leader, predId: pred && pred.predId, predName: pred && pred.predName}
                                })
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column/>
                    <Grid.Column>
                        <Button
                            content="Дополнительные сведения"
                            className={cn("button-dashed-link", "additional-button")}
                            onClick={() => {
                                ls.descriptionModal = {
                                    open: true,
                                    text: leader.description,
                                    onClose: (text) => {
                                        valuesChanged({leader: {...leader, description: text}})
                                    },
                                    fio: leader.fio
                                }
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns='equal' className="group-headers-row">
                    <Grid.Column><Header content="Сведения о членах группы аудита" className="group-header"/></Grid.Column>
                </Grid.Row>
                {team.map(((member, index) => (
                    // You need to be careful with keys here when or if user will be allowed to delete any members
                    <Grid.Row verticalAlign='bottom' key={index}>
                        <Grid.Column>
                            <InputField
                                label="Ф.И.О. члена группы аудита" fluid
                                value={member.fio}
                                editable={editable}
                                inputClassName="input-underline"
                                onChange={(e, {value}) => {
                                    const cTeam = [...team]
                                    cTeam[index] = {...member, fio: value}
                                    valuesChanged({team: cTeam})
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <InputField
                                label="Должность" fluid
                                editable={editable}
                                inputClassName="input-underline"
                                value={member.position}
                                onChange={(e, {value}) => {
                                    const cTeam = [...team]
                                    cTeam[index] = {...member, position: value}
                                    valuesChanged({team: cTeam})
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <PredDropdown
                                label="Подразделение" fluid
                                preds={preds}
                                editable={editable}
                                value={member.predId}
                                onChange={(pred) => {
                                    const cTeam = [...team]
                                    cTeam[index] = {...member, predId: pred && pred.predId, predName: pred && pred.predName}
                                    valuesChanged({team: cTeam})
                                }}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <DropdownField
                                label="Роль" fluid clearable
                                editable={editable}
                                options={memberRolesOptions} dropdownClassName="dropdown-underline"
                                noEditValue={member.roleName}
                                value={memberRoles.findIndex(r => r.code === member.roleId)}
                                onChange={(e, {value}) => {
                                    const cloneTeam = [...team]
                                    cloneTeam[index] = {...member, roleId: memberRoles[value] && memberRoles[value].code}
                                    valuesChanged({team: cloneTeam})
                                }}
                            />
                        </Grid.Column>
                        {(editable || member.description !== "") && (
                            <Grid.Column>
                                <Button
                                    content="Дополнительные сведения"
                                    className={cn("button-dashed-link", "additional-button")}
                                    onClick={() => {
                                        ls.descriptionModal = {
                                            open: true,
                                            text: member.description,
                                            onClose: (text) => {
                                                const cTeam = [...team]
                                                cTeam[index] = {...member, description: text}
                                                valuesChanged({team: cTeam})
                                            },
                                            fio: member.fio
                                        }
                                    }}
                                />
                            </Grid.Column>
                        )}
                    </Grid.Row>
                )))}
            </Grid>
            {editable && (
                <Button
                    content="Добавить члена аудита группы"
                    basic icon="add"
                    onClick={() => {
                        const cTeam = [...team]
                        cTeam.push({fio: "", position: "", description: "", pred: undefined, roleId: undefined, employeeId: undefined})
                        valuesChanged({team: cTeam})
                    }}
                />
            )}
            <Modal
                open={ls.descriptionModal.open}
                onClose={() => {
                    ls.descriptionModal.open = false
                    ls.descriptionModal.onClose(ls.descriptionModal.text)
                }}
                className={cn("alt-semantic", styles.component)}
            >
                <Modal.Header className="description-modal-header">
                    <span>Дополнительные сведения</span>
                    <span>{ls.descriptionModal.fio}</span>
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        {editable && (
                            <TextArea
                                placeholder="Вы можете в свободной форме указать здесь дополнительные сведения о человеке"
                                value={ls.descriptionModal.text}
                                onChange={(e, {value}) => {
                                    ls.descriptionModal.text = value
                                }}
                            />
                        )}
                        {!editable && (
                            <span>{ls.descriptionModal.text}</span>
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="OK" basic
                        onClick={() => {
                            ls.descriptionModal.open = false
                            ls.descriptionModal.onClose(ls.descriptionModal.text)
                        }}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
})

export default PrepareOrderForm;