import React from 'react'
import ComponentMarker from "../../shared/ComponentMarker";
import ButtonPopupRow from "../../shared/ButtonPopupRow";
import styles from './TerminalMarker.module.scss'
import cn from "classnames";
import appStyles from "../../css/App.module.scss";
import TableForPopup from "../RatingsWorkLocomotives/TableForPopup";

export default function TerminalMarker({pointName, totalTerms, activeTerms, callback, ...markerProps}) {
     return (
         <ComponentMarker
             {...markerProps}
         >
             <ButtonPopupRow
                 className={styles.buttonRow}
                 arrSettings={[
                     {
                         value: totalTerms,
                         callback: callback,
                         popupProps: {
                             className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                             content: (
                                 <TableForPopup
                                     title={pointName}
                                     data={[
                                         {
                                             text: 'Терминалов всего',
                                             unit: 'ед.',
                                             value: totalTerms
                                         },
                                     ]}
                                 />
                             )
                         }
                     },
                     {
                         value: activeTerms,
                         callback: callback,
                         popupProps: {
                             className: cn(appStyles.paddingZero, appStyles.popupFixWidth),
                             content: (
                                 <TableForPopup
                                     title={pointName}
                                     data={[
                                         {
                                             text: 'Количество активных терминалов',
                                             unit: 'ед.',
                                             value: activeTerms
                                         },
                                     ]}
                                 />
                             )
                         }
                     }
                 ]}
             />
         </ComponentMarker>
     )
}