import React, { Component } from 'react'
import ProgressBarWithPopup from './ProgressBarWithPopup'
import './BarSetWithPopup.scss'

export default class BarSetWithPopup extends Component {
	render() {
		var { barsProps, onClick } = this.props

		if (barsProps === undefined) {
			return null;
		}
		
		var key = 0
		var bars = barsProps.map((props) => (
			<ProgressBarWithPopup 
				value={props.value}
				maxValue={props.maxValue} 
				popupContent={props.popupContent} 
				popupHeader={props.popupHeader}
				leftValue={props.leftValue}
				key={key++}
				width={props.width}
				height={19}
				fontSize={16}
				popupClassName={props.popupClassName}
				filled={props.filled}
				color={props.color}
				onClick={props.onClick}
				colorPicker={props.colorPicker}
				valueOnClick={props.valueOnClick}
				leftValueOnClick={props.leftValueOnClick}
			/>
		))

		return (
			<div className='bar-set-with-popup' onClick={onClick !== undefined ? onClick : () => {}}>
				{bars}
			</div>
		)
	}
}