import React, {useEffect, useState} from 'react';
import style from './AddTemp.module.scss';
import {Select, Popover, Spin} from 'antd';
import ekasut from "../../../../../../api/ekasut";
import {LoadingOutlined} from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
const {Option} = Select;

export const AddTemp = ({selectedTemp, setSelectedTemp, wSData}) => {

    const [templs, setTempls] = useState([])
    const [loading, setLoading] = useState(false)
    const [statusList, setStatusList] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [loadingSelectedTemp, setLoadingSelectedTemp] = useState(false)

    useEffect(() => {
        setLoading(true)
        ekasut.nsiTaskManager.getTemplates()
            .then(({data}) => {
                setTempls(data)
                if(wSData){
                    loadStatusList(wSData.templateId, 'selectTempl')
                }
                setLoading(false)
            })
    }, [])

    const content = (
        <div className={style.statusList}>
            {!loadingStatus && statusList.length ? statusList.map((e, i) => (<div key={`${i}-div`}>
                <h4 className={style.titleList}>
                    {e.title}
                </h4>
                {e.list.map((el, index) => (
                    <p key={`${index}-p`} className={style.parag}>
                        <div className={style.colorBlock} style={{backgroundColor: `${el.statusColorHex}`}}/>
                        {el.statusName}
                    </p>))}
            </div>)) : !loadingStatus && !statusList.length ? <p>Шаблон пуст</p> :
                <Spin indicator={antIcon} style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}}/>}
        </div>
    );

    const loadStatusList = (id, typeReq = 'details') => {
        if (typeReq === 'details') {
            setLoadingStatus(true)
        } else {
            setLoadingSelectedTemp(true)
        }
        ekasut.nsiTaskManager.getTemplateStatuses({id: id})
            .then(({data}) => {
                const arr = []
                if (data) {
                    let active = data.statuses.filter(e => e.categoryId === 1)
                    arr.push({title: 'Активные', list: active})
                    let done = data.statuses.filter(e => e.categoryId === 2)
                    arr.push({title: 'Выполненные', list: done})
                    let close = data.statuses.filter(e => e.categoryId === 3)
                    arr.push({title: 'Закрытые', list: close})
                }
                if (typeReq === 'details') {
                    setStatusList(arr)
                } else {
                    setSelectedTemp({id: id, statuses: arr})
                }
                setLoadingStatus(false)
                setLoadingSelectedTemp(false)
            })
            .catch((err) => {
                setLoadingStatus(false)
                setLoadingSelectedTemp(false)
            })
    }

    return (
        <div className={style.tempAria}>
            <div className={style.header}>
                <h4>
                    Шаблон
                </h4>
                <Select placeholder={'Выберите шаблон'} style={{width: '200px'}} loading={loading} bordered={false}
                        onChange={(value, option) => {
                            if (value) {
                                loadStatusList(value, 'selectTempl')
                            }
                        }}>
                    {templs.length && templs.map(e => (
                        <Option key={e.code}>
                            <Popover placement="right" content={content}
                                     title={e.name}
                                     onVisibleChange={(visible) => {
                                         if (visible) {
                                             loadStatusList(e.code)
                                         }
                                     }}>
                                <div className={style.popoverStatus}>
                                    {e.name}
                                </div>
                            </Popover>
                        </Option>
                    ))}
                </Select>
            </div>
            {selectedTemp.statuses?.length ? <div className={style.selectedTemp}>
                {!loadingStatus && selectedTemp.statuses?.length ? selectedTemp.statuses.map((e, i) => (<div key={`${i}-div`}>
                    <h4 className={style.titleList}>
                        {e.title}
                    </h4>
                    {e.list.map((el, index) => (
                        <p key={`${index}-p`} className={style.parag}>
                            <div className={style.colorBlock} style={{backgroundColor: `${el.statusColorHex}`}}/>
                            {el.statusName}
                        </p>))}
                </div>)) : !loadingStatus && !selectedTemp.statuses?.length ? <p>Шаблон пуст</p> :
                    <div className={style.spinner}>
                        <Spin indicator={antIcon} style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}}/>
                    </div>}
            </div> : null}
        </div>
    )
}