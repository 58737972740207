import React from 'react'
import {Button} from "semantic-ui-react";
import styles from './LabelAndButton.module.scss'
import cn from 'classnames'

export default function LabelAndButton({icon, loading, text, className, onClick, ...props}) {
    return (
        <div className={cn(styles.container, className)} {...props}>
            <label>
                {text}
            </label>
            <Button
                basic circular icon={icon}
                loading={loading}
                onClick={() => {
                    if (!loading) {
                        onClick()
                    }
                }}
            />
        </div>
    )
}