import React, {useEffect, useMemo, useReducer, useState} from 'react';
import {Header, Table} from "semantic-ui-react";
import styles from "../TableFirstCard/TableFirstCard.module.scss";
import ColumnFilter from "../ColumnFilter/ColumnFilter";
import {filterData, sortReducer} from "../TableFirstCard/TableFirstCard";

const americanFormatDateStrings = ["dateRepair", "planDateRepair"];
const ISOFormatDateStrings = ["dataNr", "dateKo", "dateWaitR", "dateRepairBegin", "dateRepairFinish"];

export const replaceNullStateWithNoDataString = (data) => {
    return data.map(row => {
        const newRow = {};
        Object.keys(row).forEach(key => {
            newRow[key] = (row[key] === null || (typeof row[key] === "string" && !row[key].trim())) ? "-" : row[key];
            if (americanFormatDateStrings.concat(ISOFormatDateStrings).includes(key) && row[key] !== null) {
                const itemYear = row[key].slice(0, 4);
                const itemMonth = row[key].slice(5, 7);
                const itemDay = row[key].slice(8, 10);
                newRow[key] = `${itemDay}.${itemMonth}.${itemYear}`
            }

        });
        return newRow;
    })
}
const TableFourthCard = ({data: tableData}) => {
    const [state, dispatch] = useReducer(sortReducer, {
        column: null,
        data: replaceNullStateWithNoDataString(tableData),
        direction: null,
    });
    const { column, data, direction } = state
    const [filteredData, setFilteredData] = useState(data);
    const filterData1 = useMemo(() => filterData(data, "roadName"), []);
    const filterData2 = useMemo(() => filterData(data, "depoName"), []);
    const filterData3 = useMemo(() => filterData(data, "serName"), []);
    const filterData4 = useMemo(() => filterData(data, "nomSec"), []);
    const filterData5 = useMemo(() => filterData(data, "planRepName"), []);
    const filterData6 = useMemo(() => filterData(data, "planDateRepair"), []);
    const filterData7 = useMemo(() => filterData(data, "repName"), []);
    const filterData8 = useMemo(() => filterData(data, "dateKo"), []);


    const [selectedRowsInFilters, setSelectedRowsInFilters] = useState({
        roadName: filterData1,
        depoName: filterData2,
        serName: filterData3,
        nomSec: filterData4,
        planRepName: filterData5,
        planDateRepair: filterData6,
        repName: filterData7,
        dateKo: filterData8,
    });

    useEffect(() => {
        // if (filteredData !== data) {
        //     setFilteredData(data);
        // }
        const newFilteredData = data
            .filter(item => selectedRowsInFilters.roadName.includes(item.roadName))
            .filter(item => selectedRowsInFilters.depoName.includes(item.depoName))
            .filter(item => selectedRowsInFilters.serName.includes(item.serName))
            .filter(item => selectedRowsInFilters.nomSec.includes(item.nomSec))
            .filter(item => selectedRowsInFilters.planRepName.includes(item.planRepName))
            .filter(item => selectedRowsInFilters.planDateRepair.includes(item.planDateRepair))
            .filter(item => selectedRowsInFilters.repName.includes(item.repName))
            .filter(item => selectedRowsInFilters.dateKo.includes(item.dateKo))
        // fix that!
        setFilteredData(newFilteredData);
    }, [data, selectedRowsInFilters]);

    const changeSelectedRows = (colName, newData) => {
        setSelectedRowsInFilters(prevState => ({
            ...prevState,
            [colName]: newData,
        }));
    }

    return (
        <div>
            <Header as='h2'>Процент выполнения комиссионного осмотра от планового количества локомотивов</Header>

            <div>
                <Header as='h3'>Фильтрация</Header>
                <div style={{display: "flex", gap: "5px"}} className={styles.filtersWrapper}>
                    <ColumnFilter colName='roadName' title="Дорога" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData1}/>
                    <ColumnFilter colName='depoName' title="Депо" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData2}/>
                    <ColumnFilter colName='serName' title="Серия" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData3}/>
                    <ColumnFilter colName='nomSec' title="Номер" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData4}/>
                    <ColumnFilter colName='planRepName' title="Плановый ремонт" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData5}/>
                    <ColumnFilter colName='planDateRepair' title="Дата планового ремонта" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData6}/>
                    <ColumnFilter colName='repName' title="Фактический ремонт" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData7}/>
                    <ColumnFilter colName='dateKo' title="Дата фактического ТО/ТР" changeSelectedRows={changeSelectedRows} uniqueColValues={filterData8}/>
                </div>
            </div>

            <Table celled>
                <Table.Header className={styles.header}>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell
                            sorted={column === 'roadName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'roadName' })}
                        >Дорога приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'depoName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'depoName' })}
                        >Депо приписки</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'serName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'serName' })}
                        >Серия</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'nomSec' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'nomSec' })}
                        >Номер</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'planRepName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'planRepName' })}
                        >Плановый ремонт</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'planDateRepair' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'planDateRepair' })}
                        >Дата планового ремонта</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'repName' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'repName' })}
                        >Фактический ремонт</Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={column === 'dateKo' ? direction : null}
                            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'dateKo' })}
                        >Дата фактического ТО/ТР</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {filteredData?.map((item, index) => {
                        return (
                            <Table.Row
                                key={index}
                                textAlign={"center"}
                                onClick={() => {

                                }}
                            >
                                <Table.Cell>{item.roadName}</Table.Cell>
                                <Table.Cell>{item.depoName}</Table.Cell>
                                <Table.Cell>{item.serName}</Table.Cell>
                                <Table.Cell>{item.nomSec}</Table.Cell>
                                <Table.Cell>{item.planRepName}</Table.Cell>
                                <Table.Cell>{item.planDateRepair}</Table.Cell>
                                <Table.Cell>{item.repName}</Table.Cell>
                                <Table.Cell>{item.dateKo}</Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </div>
    );
};

export default TableFourthCard;