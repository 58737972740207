import React from 'react';
import cn from "classnames";
import TextAreaAutosize from 'react-textarea-autosize'
import './TextAreaField.scss'

const TextAreaField = ({className, label, fluid, onChange = () => null, ...textAreaProps}) => {
    return (
        <div className={cn("app field text-area-field", className, fluid && "fluid")}>
            <label>{label}</label>
            <div className="textarea-container">
                <TextAreaAutosize {...textAreaProps} onChange={(e) => onChange(e, {value: e.currentTarget.value})}/>
            </div>
        </div>
    );
};

export default TextAreaField;