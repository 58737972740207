import React from 'react';
import {Button} from "semantic-ui-react";

const AlignButton = ({align, containerStyle, ...buttonProps}) => {
    return (
        <div style={{textAlign: align, ...containerStyle}}>
            <Button
                style={{marginRight: 0}}
                {...buttonProps}
            />
        </div>
    );
};

export default AlignButton;