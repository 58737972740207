import React from 'react'
import {Dimmer, Header, Loader, Segment} from "semantic-ui-react";
import styles from './LoadingContent.module.scss'

const LoadingContent = ({showLoader, children, inverted, loaderClassName, message, loadingMessage}) => {

    return (
        showLoader ? (
            <Dimmer active={true} className={styles.transparent} inverted={inverted}>
                <Loader className={loaderClassName} size="large">{loadingMessage}</Loader>
            </Dimmer>
        ) : ((message !== undefined) && (message !== null) && (message !== '')) ? (
            <Segment className={styles.message}>
                <Header  as={'h4'}>{message}</Header>
            </Segment>
        ) : (
            children === undefined ? '' : children
        )
    )
}
LoadingContent.defaultProps = {
    loadingMessage: 'Загрузка'
}

export default LoadingContent