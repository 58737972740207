import React, {useEffect, useState} from 'react';
import style from './AnalyticsList.module.scss';
import {Avatar, Divider, List, Skeleton, Spin} from "antd";
import InfiniteScroll from 'react-infinite-scroll-component';
import demoAvatar from "../../../../../assets/images/DemoAvatar.png";
import {Link} from "react-router-dom";
import moment from "moment";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ekasut from "../../../../../api/ekasut";
import {board} from "../../../TaskListState";
import {analyticsState} from "../AnalyticsState";
import {LoadingOutlined} from "@ant-design/icons";
import {useSnapshot} from "valtio"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PersonIcon from "@material-ui/icons/Person";
import {AssigneePopover} from "../../AssigneePopover/AssigneePopover";


const antIcon = <LoadingOutlined style={{fontSize: 30, color: 'rgba(0,0,0,0.64)'}} spin/>;

export const listLoader = (id, filterType, fullInfo, pageNumber = null, more = false, category) => {

    if (!more) {
        analyticsState.loadingList = true
    }
    let query = `?${filterType}=${id}${pageNumber ? '&pageNumber=' + pageNumber : ''}${category ? '&categoryId=' + category : ''}`
    ekasut.taskboard.getList(board.workGruopId, query)
        .then(({data}) => {
            if (more) {
                let pastArr = [...analyticsState.taskList.data]
                pastArr = {
                    ...data.taskPage,
                    data: pastArr.concat(data.taskPage.data)
                }
                analyticsState.taskList = pastArr
                analyticsState.filterType = filterType
                analyticsState.id = id
                analyticsState.name = fullInfo
            } else {
                analyticsState.taskList = {...data.taskPage}
                analyticsState.filterType = filterType
                analyticsState.id = id
                analyticsState.name = fullInfo.name
                analyticsState.categoryId = category ? category : null
            }
            analyticsState.loadingList = false

        })
}

export const AnalyticsList = ({}) => {

    const [loading, setLoading] = useState(false)
    const listAn = useSnapshot(analyticsState)

    const loadMoreData = (list) => {
        if (loading) {
            return
        }
        setLoading(true)
        listLoader(list.id, list.filterType, list.name, list.taskList.pageNumber + 1, true, list.categoryId)
    }

    useEffect(() => {
        setLoading(false)
    }, [listAn.taskList])

    if (listAn.loadingList) {
        return (
            <div className={style.loader}>
                <Spin indicator={antIcon}/>
            </div>
        )
    } else if (listAn.taskList === null) {
        return (<div className={style.loader}>Выберите статус или исполнителя</div>)
    }

    return (
        <div className={style.analyticsList} id='aList'>
            <div className={style.listHeader}>Список
                задач <span>{listAn.name} ({listAn.taskList.totalRecords})</span></div>
            {listAn.taskList.data.length ? <InfiniteScroll
                dataLength={listAn.taskList.data.length}
                next={() => {
                    loadMoreData(listAn)
                }}
                hasMore={listAn.taskList.pageNumber < listAn.taskList.totalPages}
                loader={<Skeleton key={`skel-${Math.random()}`} className={style.skeletonItem} avatar
                                  paragraph={{rows: 1}} active/>}
                endMessage={<Divider plain>Задач больше нет 🤐</Divider>}
                scrollableTarget="aList"
            >
                <div id={'listTopPosition'}></div>
                <List
                    style={{padding: '0px 5%'}}
                    dataSource={listAn.taskList.data}
                    renderItem={item => (
                        <List.Item key={item.taskId}>
                            <List.Item.Meta
                                avatar={
                                    <Avatar src={demoAvatar}/>
                                }
                                title={<div className={style.taskTitle}><Link
                                    to={`taskboard/${item.workGroupId}-${item.taskNumber}`}>{item.title}</Link>
                                    <div className={style.statusDiv}
                                         style={{
                                             backgroundColor: `${item.status.statusColorHex}`,
                                         }}>{item.status.statusName}</div>
                                </div>}
                                description={`${item.workGroupName} #${item.taskNumber} создано ${moment(item.addedOn).format('DD.MM.YYYY')} ${item.creatorName}`}
                            />
                            <div className={style.itemRightContent}>
                                <div>
                                    {item.hasComments || item.hasFiles || item.subtasksCount || item.assignees.length ?
                                        <div className={style.itemComments}>

                                            {item.hasFiles ? <InsertDriveFileIcon
                                                style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}

                                            {item.hasComments ?
                                                <ChatBubbleIcon style={{fontSize: '1.1em', color: '#a1a1a4'}}/> : null}

                                            {item.subtasksCount ?
                                                <div className={style.subsCnt}><FormatListBulletedIcon style={{
                                                    fontSize: '1.1em',
                                                    color: '#a1a1a4'
                                                }}/>{item.subtasksCount}</div> : null}

                                            {item.assignees.length ?
                                                <AssigneePopover assignees={item.assignees}/> : null
                                            }
                                        </div> : null}

                                    <div className={style.labelsList}>
                                        {item.labels ? item.labels.map((e, i) => (
                                            <div key={i} className={style.labelsDiv}
                                                 style={{
                                                     backgroundColor: `${e.labelColorHex}`,
                                                 }}>{e.labelName}</div>
                                        )) : null}
                                    </div>
                                </div>

                            </div>
                        </List.Item>
                    )}
                >
                </List>
            </InfiniteScroll> : <div className={style.loader} style={{height: '80%'}}>Нет задач</div>}
        </div>
    );
};