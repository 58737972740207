import React, {useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import ekasut from "../../../../../api/ekasut";
import {board} from "../../../TaskListState";
import {useSnapshot} from "valtio";

export const DeleteGroup = ({
                                openDel, setOpenDel, groupData,
                                updateTree, setAddIdParent
                            }) => {

    const [loading, setLoading] = useState()
    const boardState = useSnapshot(board)

    const deleteGroup = () => {
        setLoading(true)
        ekasut.taskboard.deleteGroupOnSidebar(groupData.parentGroupId)
            .then(() => {
                if (boardState.workGruopId === groupData.parentGroupId) {
                    board.boardItems = null
                }
                updateTree()
                setLoading(false)
                setAddIdParent(false)
                setOpenDel(false)
            })
            .catch(() => {
                updateTree()
                setLoading(false)
                setAddIdParent(false)
                setOpenDel(false)
            })
    }

    return (
        <Modal
            size={'mini'}
            open={openDel}
            onClose={() => {
                setOpenDel(false)
                setAddIdParent(false)
            }}
        >
            <Modal.Header>Удаление</Modal.Header>
            <Modal.Content>
                <p>Вы действительно хотите удалить: "<strong>{groupData.actionGroupName}</strong>"?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button loading={loading} negative onClick={() => {
                    setOpenDel(false)
                }}>
                    Нет
                </Button>
                <Button loading={loading} positive onClick={() => {
                    deleteGroup()
                }}>
                    Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
};