import React, {useState, useEffect} from 'react'
import styles from '../index.module.scss'
import {Dropdown} from "semantic-ui-react";
import {dateFormats} from "../../../Dictionaries";
import moment from 'moment'
import { formOptions } from '../../../helpers/utils';
import DateRangeField from "./DateRangeField";


export default function YavkaRange({label, defFrom, defTo, onRefresh, yavkaList, yavkaListLoading, onYavkaChange}) {
    const [dates, setDates] = useState({startDate: defFrom, endDate: defTo})

    const [yavkaIndex, setYavkaIndex] = useState(null)

    useEffect(() => {
        if (defFrom !== undefined && defTo !== undefined) {
            onRefresh(defFrom, defTo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (<>
        <DateRangeField
            label={label}
            startDate={dates.startDate}
            endDate={dates.endDate}
            isOutsideRange={() => false}
            editable={true} // yavkaListLoading
            onChange={(startDate, endDate) => {
                setDates({startDate, endDate})
                setYavkaIndex(null)
                onYavkaChange(null)
                if (startDate !== null && endDate !== null) {
                    onRefresh(startDate, endDate)
                }
            }}
        />
        <div className={styles.formField}>
            <div style={{display: 'flex'}}>
                <Dropdown
                    selection fluid
                    value={yavkaIndex}
                    loading={yavkaListLoading}
                    placeholder='Выберите явку из списка'
                    disabled={yavkaListLoading}
                    onChange={(e, {value}) => {
                        setYavkaIndex(value)
                        onYavkaChange(yavkaList[value])
                    }}
                    options={formOptions(yavkaList.map(item => item.t1.format(dateFormats.upToMinute)))}
                />
            </div>
        </div>
    </>)
}
YavkaRange.defaultProps = {
    onDateChange: () => {
    },
    onRefresh: () => {
    },
}
const convertStrToFromToDate = (str, formatDate) => {
    let isValid = true
    const datesArr = str.split('-').map(item => {
        const date = moment(item.trim(), formatDate)
        if (!date.isValid()) {
            isValid = false
        }
        return date
    })
    return ((datesArr.length === 2) && isValid) ? datesArr : []
}

export {convertStrToFromToDate}