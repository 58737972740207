import React, { Component } from 'react';
import axios from 'axios';
import auth from '../../../helpers/auth'
import {Table, Icon, Dimmer, Loader} from 'semantic-ui-react'
import { Pagination, Modal, Grid } from 'semantic-ui-react'
import { Card, Accordion } from 'semantic-ui-react'
import * as moment from 'moment';
import 'moment/locale/ru';


class Exceptions extends Component {
    state = {
        activePage: 1,
        errorOccured: false,
        isLoading: false,
        modalOpen: false,
        activeIndex: null,
        exceptions: [],
        errorMeta: { error: [] }
    }

    constructor(props) {
        super(props);

        // This binding is necessary to make `this` work in the callback
        this.changePage = this.changePage.bind(this);
        // this.tableRowClickFunc = this.tableRowClickFunc.bind(this);
    }

    onPageLimit = 100

    componentDidMount() {
        var me = this;

        me.setState({ isLoading: true });

        axios.get(`${window.backendUrl}/api/Exceptions/getall?showFixed=false`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    me.setState({isLoading: false, errorOccured: true, errorCode: response.status});

                    return;
                }

                me.setState({exceptions: response.data, isLoading: false});
            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }

                me.setState({isLoading: false, errorOccured: true, errorCode: err.response});
            });
    }

    tableRowClickFunc(id) {
        var me = this;
        me.setState({isLoading: true});

        axios.get(`${window.backendUrl}/api/Exceptions/get/${id}`)
            .then(
            function (response) {
                if (response.status !== 200) {
                    me.setState({isLoading: false, errorOccured: true, errorCode: response.status});

                    return;
                }

                const { data } = response
                let list = [],
                    current = data.error;

                while (current != null)
                {
                    list.push(current);
                    current = current.InnerException;
                }

                // redefine error - from tree to list
                data.error = list

                me.setState({errorMeta: data, isLoading: false, modalOpen: true});

            }
        )
            .catch(function (err) {
                if (err.response.data['$type'].includes("UnauthorizedAccessException")) {
                    auth.logout()
                }

                me.setState({isLoading: false, errorOccured: true, errorCode: err});
            });
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    handleClose = () => this.setState({ modalOpen: false })

    helper = (field) => {
        var me = this;

        switch (typeof field) {
            case 'string':
                return <span dangerouslySetInnerHTML={{__html: field.replace(/\n/gi, '<br/>').replace(/ /gi, '&nbsp;')}}></span>;
            case 'number':
                return field;
            case 'object':
                if (field !== null) {

                    field = (<Table celled>
                        <Table.Body>
                            {
                                Object.keys(field).map(function(key, index) {
                                    return ( <Table.Row key={index}>
                                        <Table.Cell>{key}</Table.Cell>
                                        <Table.Cell>
                                            { me.helper(field[key])}
                                        </Table.Cell>
                                    </Table.Row>)
                                })
                            }
                        </Table.Body>
                    </Table>)
                }

                return field;
            default:
                return null;
        }
    }

    changePage(event, data) {
        this.setState({activePage: data.activePage})
    }

    render() {
        const { activeIndex, errorMeta } = this.state
        var me = this;
        var lis = []

        if (this.state.exceptions.length > 0) {
            const target = this.state.exceptions.slice((this.state.activePage-1) * this.onPageLimit, this.state.activePage * this.onPageLimit)

            target.forEach(function (item, index) {
                lis.push(
                    <Table.Row key={item.id} style={{cursor: 'pointer'}} onClick={() => me.tableRowClickFunc(item.id)}>
                        <Table.Cell className="right aligned">{item.id}</Table.Cell>
                        <Table.Cell className="center aligned">
                            <Icon size='large' name={item.isFixed ? 'checkmark' : 'exclamation'}/>
                        </Table.Cell>
                        <Table.Cell
                            className="center aligned">{moment(item.date).format("YYYY.MM.DD HH:mm:ss")}</Table.Cell>
                        <Table.Cell>{item.source}</Table.Cell>
                        <Table.Cell>{item.message}</Table.Cell>
                    </Table.Row>
                );
            });
        }

        return (
            <div>
                {this.state.isLoading
                    ? (
                        <Dimmer active inverted style={{height: 500}}>
                            <Loader inverted size='massive' content='Загрузка'/>
                        </Dimmer>
                    )
                    : (
                        <Table celled structured striped size='small'>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className="center aligned" style={{width:60}}>ID</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" style={{width:50}}></Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned" style={{width:160}}>Дата</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Источник</Table.HeaderCell>
                                    <Table.HeaderCell className="center aligned">Сообщение</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {lis}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell className="center aligned" colSpan='19'>
                                        <Pagination size='small' activePage={this.state.activePage} defaultActivePage={1} totalPages={Math.ceil(this.state.exceptions.length / this.onPageLimit)} onPageChange={this.changePage}/>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    )
                }


                <Modal closeIcon size='fullscreen' open={this.state.modalOpen}
                       onClose={this.handleClose}>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <Grid columns='two'>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        <Card>
                                            <Card.Content>
                                                <Card.Header>{errorMeta.requestString}</Card.Header>
                                                <Card.Meta>{moment(errorMeta.date).format("YYYY.MM.DD HH:mm:ss")}</Card.Meta>
                                                <Card.Description>id: {errorMeta.id}</Card.Description>
                                                <Card.Description>isFixed: {errorMeta.isFixed}</Card.Description>
                                                <Card.Description>​message: {errorMeta.message}</Card.Description>
                                                <Card.Description>​requestBody: {errorMeta.requestBody}</Card.Description>
                                                <Card.Description>​source: {errorMeta.source}</Card.Description>
                                                <Card.Description>​user: {errorMeta.user}</Card.Description>
                                                <Card.Description>​userComment: {errorMeta.userComment}</Card.Description>
                                            </Card.Content>
                                        </Card>

                                    </Grid.Column>
                                    <Grid.Column textAlign='left' width={14} style={{maxWidth: '900px'}}>
                                        {
                                            errorMeta.error.map(function(item, index) {
                                                return (
                                                    <Accordion>
                                                        <Accordion.Title active={activeIndex === index} index={index}
                                                                         onClick={me.handleClick}>
                                                            <Icon name='dropdown'/>
                                                            {item['ClassName']}
                                                        </Accordion.Title>
                                                        <Accordion.Content active={activeIndex === index} style={{width: '900px', oveflow: 'auto'}}>
                                                            <Table celled>
                                                                <Table.Body>
                                                                    {
                                                                        Object.keys(item).map(function(key, index) {
                                                                            if(key === 'InnerException') {
                                                                                return null
                                                                            }
                                                                            return ( <Table.Row key={index}>
                                                                                <Table.Cell>{key}</Table.Cell>
                                                                                <Table.Cell>
                                                                                    { me.helper(item[key])}
                                                                                </Table.Cell>
                                                                            </Table.Row>)
                                                                        })
                                                                    }
                                                                </Table.Body>
                                                            </Table>
                                                        </Accordion.Content>
                                                    </Accordion>
                                                )
                                            })
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default Exceptions