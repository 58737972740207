import React from "react";
import AppTable, { convertToYesNo, convertToDate } from "../../shared/AppTable";
import styles from "./TerminalTable.module.scss";

export default function TerminalTable({ data, rowOnClick }) {
  return (
    <AppTable
      data={data}
      rowOnClick={rowOnClick}
      structure={tableStructure}
      clickable
      rowClassNameGetter={(dataItem) =>
        dataItem.isActive ? "" : styles.notActiveDevice
      }
      option={1}
    />
  );
}

const tableStructure = [
  { header: "ИД Терминала", field: "terminalId" },
  { header: "ИД Версии клиента", field: "clientVersionId" },
  { header: "Версия клиента", field: "clientVersion" },
  {
    header: "Версия клиента поддерживается",
    field: "isClientVersionSupported",
    converter: convertToYesNo,
  },
  { header: "ИД актуальная версия", field: "actualVersionId" },
  { header: "Актуальная версия", field: "actualVersion" },
  { header: "Наименование дороги", field: "dorName" },
  { header: "Наименование депо", field: "depoName" },
  /*{header: 'Код станции', field: 'stationCode'},*/
  {
    header: "Выключен администратором",
    field: "isEnable",
    converter: (val) => convertToYesNo(!val),
  },
  {
    header: "Последний выход на связь",
    field: "lastPing",
    converter: convertToDate,
  },
  { header: "Последний IP", field: "lastIp" },
  { header: "Фотография", field: "fileId" },
];
